import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import USStateEnum from '../../../../assets/js/USStateEnum';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import { getAddress, reactGAEvent, reactGAPageview } from '../../../../helper';
import {
  changeGreenSkyApplicationInfoValue,
  changePage,
  submitMindfire,
} from '../../../../redux/actions/index';
import Disclosure from '../Components/Disclosure';
import { GreenSkyFlowPageNumber } from './GreenSkyFlow';

const Review = props => {
  const {
    greenSkyApplicationInformation,
    pqi,
    dispatchChangePage,
    dispatchChangeGreenSkyApplicationInfoValue,
    children,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    // applicantAddress,
    app,
    isCoAppMarried,
  } = props;

  const isStateOfWisconsin = USStateEnum.Wisconsin.value === pqi.state;

  const propertyAddress = pqi.street
    ? getAddress({
        street: pqi.street,
        city: pqi.city,
        state: pqi.state,
        zip: pqi.zipcode,
      })
    : 'No address';

  const primaryApplicantAddress = greenSkyApplicationInformation.isSameAsInstalationAddress
    ? propertyAddress
    : getAddress({
        street: greenSkyApplicationInformation.primaryAddressStreet,
        city: greenSkyApplicationInformation.primaryAddressCity,
        state: greenSkyApplicationInformation.primaryAddressState,
        zip: greenSkyApplicationInformation.primaryAddressZipCode,
      });

  const coApplicantAddress = greenSkyApplicationInformation.hasCoApp
    ? getAddress({
        street: greenSkyApplicationInformation.coAppStreet,
        city: greenSkyApplicationInformation.coAppCity,
        state: greenSkyApplicationInformation.coAppState,
        zip: greenSkyApplicationInformation.coAppZipCode,
      })
    : 'No address';

  useEffect(() => {
    reactGAPageview({
      path: '/ca_gsconfirminfo',
      trackerNames: [],
      title: 'ca_gsconfirminfo',
      did,
      dealerUserId,
    });
    dispatchChangeGreenSkyApplicationInfoValue('isReview', false);
    // eslint-disable-next-line
  }, []);

  const editPage = page => {
    dispatchChangeGreenSkyApplicationInfoValue('isReview', true);
    dispatchChangePage(page);
  };

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={
            greenSkyApplicationInformation.hasCoApp
              ? isStateOfWisconsin
                ? isCoAppMarried
                  ? GreenSkyFlowPageNumber.CoApplicantSpouse
                  : GreenSkyFlowPageNumber.CoApplicantMarried
                : GreenSkyFlowPageNumber.CoAppGrossAnnualIncome
              : GreenSkyFlowPageNumber.HasCoApplicant
          }
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <Row className="review">
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col xs="8" lg="9">
                  <h5>Applicant’s Information</h5>
                </Col>
                {!app && (
                  <Col xs="4" lg="3">
                    <Button
                      className="edit"
                      clickHandler={() => editPage(GreenSkyFlowPageNumber.LoanInformation)}
                      title="Edit"
                    />
                  </Col>
                )}
              </Row>
              <Row>
                <Col xs="6">
                  <b>Name</b>
                  {(pqi.firstName ? pqi.firstName + ' ' : '') +
                    (pqi.middleName ? pqi.middleName + ' ' : '') +
                    (pqi.lastName || '')}
                </Col>
                <Col xs="6">
                  <b>Phone Number</b>
                  <NumberFormat
                    value={greenSkyApplicationInformation.appMobileNumber || pqi.phoneNumber}
                    format="(###) ###-####"
                    displayType="text"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <b>Email Address</b>
                  <span className="text-break">
                    {greenSkyApplicationInformation.appEmail || pqi.email || 'N/A'}
                  </span>
                </Col>
                <Col xs="6">
                  <b>Date of Birth</b>
                  {pqi.birthday || 'N/A'}
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <b>Full SSN</b>
                  <NumberFormat value={pqi.ssnLastDigits} format="xxxxx####" displayType="text" />
                </Col>
                <Col xs="6">
                  <b>Loan Amount</b>
                  <NumberFormat
                    value={greenSkyApplicationInformation.borrowedAmount}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={'$'}
                    displayType="text"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <b>Applicant’s Address</b>
                  {primaryApplicantAddress}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="review">
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col xs="8" lg="9">
                  <h5>Applicant’s Income Information</h5>
                </Col>
                {!app && (
                  <Col xs="4" lg="3">
                    <Button
                      className="edit"
                      clickHandler={() => editPage(GreenSkyFlowPageNumber.AppGrossAnnualIncome)}
                      title="Edit"
                    />
                  </Col>
                )}
              </Row>
              <Row>
                <Col xs="6">
                  <b>Gross Annual Income</b>
                  <NumberFormat
                    value={greenSkyApplicationInformation.appGrossAnnualIncome}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={'$'}
                    displayType="text"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="review">
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col xs="8" lg="9">
                  <h5>Co-Applicant’s Information</h5>
                </Col>
                {!app && (
                  <Col xs="4" lg="3">
                    <Button
                      className="edit"
                      clickHandler={() =>
                        editPage(
                          isStateOfWisconsin
                            ? GreenSkyFlowPageNumber.ApplicantMarried
                            : GreenSkyFlowPageNumber.HasCoApplicant,
                        )
                      }
                      title={greenSkyApplicationInformation.hasCoApp ? 'Edit' : 'Add'}
                    />
                  </Col>
                )}
              </Row>
              {greenSkyApplicationInformation.hasCoApp && (
                <>
                  <Row>
                    <Col xs="6">
                      <b>Name</b>
                      {(greenSkyApplicationInformation.coAppFirstName
                        ? greenSkyApplicationInformation.coAppFirstName + ' '
                        : '') +
                        (greenSkyApplicationInformation.coAppMiddleInitial
                          ? greenSkyApplicationInformation.coAppMiddleInitial + ' '
                          : '') +
                        (greenSkyApplicationInformation.coAppLastName || '')}
                    </Col>
                    <Col xs="6">
                      <b>Phone Number</b>
                      <NumberFormat
                        value={greenSkyApplicationInformation.coAppPhoneNumber}
                        format="(###) ###-####"
                        displayType="text"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">
                      <b>Email Address</b>
                      <span className="text-break">
                        {greenSkyApplicationInformation.coAppEmail || 'N/A'}
                      </span>
                    </Col>
                    <Col xs="6">
                      <b>Date of Birth</b>
                      {greenSkyApplicationInformation.coAppDoB || 'N/A'}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">
                      <b>Full SSN</b>
                      <NumberFormat
                        value={greenSkyApplicationInformation.coAppSsn.substring(5)}
                        format="xxxxx####"
                        displayType="text"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <b>Co-Applicant’s Address</b>
                      {coApplicantAddress}
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
          {greenSkyApplicationInformation.hasCoApp && (
            <Row className="review">
              <Col>
                <Row className="align-items-center justify-content-between">
                  <Col xs="8" lg="9">
                    <h5>Co-Applicant’s Income Information</h5>
                  </Col>
                  {!app && (
                    <Col xs="4" lg="3">
                      <Button
                        className="edit"
                        clickHandler={() => editPage(GreenSkyFlowPageNumber.CoAppGrossAnnualIncome)}
                        title="Edit"
                      />
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col xs="6">
                    <b>Gross Annual Income</b>
                    <NumberFormat
                      value={greenSkyApplicationInformation.coAppGrossAnnualIncome}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix={'$'}
                      displayType="text"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <Button
                type="button"
                title="Next"
                clickHandler={() => {
                  reactGAEvent({
                    category: 'Button',
                    action: 'Click',
                    label: 'Next',
                    did,
                    dealerUserId,
                  });
                  dispatchChangePage(GreenSkyFlowPageNumber.ApplicationConsent);
                  dispatchSubmitMindfire({
                    email: pqi.email,
                    lastPage: '9023',
                    dealerUserId: dealerUserId,
                    did: did,
                  });
                }}
              />
              <Disclosure />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  app: state.softPull.applicationSubmitDTO,
  // applicantAddress: state.greenSkyApplicationInformation.applicantAddress,
  greenSkyApplicationInformation: state.greenSkyApplicationInformation,
  pqi: state.preQualificationInformation,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  isCoAppMarried: state.greenSkyApplicationInformation.isCoAppMarried,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeGreenSkyApplicationInfoValue: (key, value) =>
    dispatch(changeGreenSkyApplicationInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

Review.propTypes = {
  greenSkyApplicationInformation: PropTypes.object,
  pqi: PropTypes.object,
  dispatchChangePage: PropTypes.func,
  dispatchChangeGreenSkyApplicationInfoValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Review);
