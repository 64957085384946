import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { useVSPixelScript } from '../../assets/hooks';
import Button from '../../components/UI/Button/CustomButton';
import { getIsOptimus } from '../../config/settings';
import { getAppTier, reactGAEvent } from '../../helper';
import { changePage, finishMicrofApplication, submitMindfire } from '../../redux/actions';
import { MicrofFlowPageNumber } from './MicrofFlow';
import MicrofWorkingHours from './MicrofWorkingHours';
import logo_sf_call from '../../assets/img/logo-sf-call.png';
import { withRouter } from 'react-router-dom';

const isOptimus = getIsOptimus();

const ApplicationReceived = props => {
  const {
    dealerUserId,
    did,
    dispatchSubmitMindfire,
    email,
    microfOnly,
    dispatchChangePage,
    financeProduct,
    dispatchFinishMicrofApplication,
    history,
  } = props;

  useVSPixelScript();

  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs="12" md="8" className="text-center">
          <h4>Thank you! We have received your application.</h4>
        </Col>
      </Row>
      <br />
      <Row className="justify-content-center">
        <Col xs="12" md="8" className="text-center">
          <h4>Applicant:</h4>
        </Col>
      </Row>
      <br />
      <Row className="justify-content-center">
        <Col xs="12" md="8" className="text-center">
          <span className="fs-18">
            A post-application interview is required by phone to verify your information:
          </span>

          <Row className="mt-15">
            <Col sm="1" />
            <Col sm="10">
              <ul className="app-received-list">
                <li>
                  Microf will reach out in the next few minutes to start the post-application
                  interview. If the application was submitted outside of regular business hours, a
                  Microf representative will call the following business day.
                </li>
                <li>You can start the process immediately by calling the number below.</li>
              </ul>
            </Col>
            <Col sm="1" />
          </Row>
        </Col>
      </Row>
      <>
        <Row>
          <Col className="text-center">
            <a href="tel:855-642-7631">
              <img src={logo_sf_call} alt="Call" className="logo-sf-call-small" />
            </a>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4 className="text-center fs-12">
              Call here
              <br />
              or
              <br /> dial{' '}
              <a href="tel:855-642-7631" className="sf-tel">
                <u>855-642-7631</u>
              </a>{' '}
              to
              <br /> complete homeowner interview
            </h4>
          </Col>
        </Row>
      </>

      <Row className="justify-content-center mt-5">
        <Col xs="12" md="6">
          <Button
            title={'Close Application'}
            clickHandler={() => {
              reactGAEvent({
                category: 'Button',
                action: 'Click',
                label: 'Close Application',
                dealerUserId: dealerUserId,
                did: did,
              });
              dispatchSubmitMindfire({
                email: email,
                lastPage: '4126',
                dealerUserId: dealerUserId,
                did: did,
              });

              dispatchFinishMicrofApplication(history);
            }}
          />
        </Col>
      </Row>
      <MicrofWorkingHours />
    </Container>
  );
};

const mapStateToProps = state => ({
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  microfOnly: state.microfInformation.microfOnly,
  financeProduct: state.softPull.applicationSubmitDTO?.financeProduct,
});

const mapDispatchToProps = dispatch => ({
  dispatchSubmitMindfire: mindFireRequest => dispatch(submitMindfire(mindFireRequest)),
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchFinishMicrofApplication: history => dispatch(finishMicrofApplication(history)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ApplicationReceived),
);
