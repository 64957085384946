import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Field, Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Col, Row, FormGroup, Alert } from 'reactstrap';
import {
  changeAuthValue,
  submitMindfire,
  endLoading,
  passwordRecovery,
} from '../../redux/actions/index';
import Button from '../../components/UI/Button/CustomButton';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import Password from '../../components/UI/Form/Password/Password';
import { PASSWORD_CONFIRM_TOOLTIP_TEXT, PASSWORD_TOOLTIP_TEXT } from '../../constants/tooltipTexts';
import { getAddedFrom } from '../../config/settings';

const SetYourPassword = props => {
  const { auth, dispatchChangeValue, dispatchPasswordRecovery, history } = props;

  useEffect(() => {
    if (queryString.parse(window.location.search).token) {
      if (queryString.parse(window.location.search).isGreenSky) {
        dispatchChangeValue(
          'isGreenSky',
          queryString.parse(window.location.search).isGreenSky === 'true',
        );
      } else {
        dispatchChangeValue('isGreenSky', false);
      }
      dispatchChangeValue('passwordToken', queryString.parse(window.location.search).token);
    } else {
      history.push('/Login');
    }
    // eslint-disable-next-line
  }, []);

  const SetYourPasswordForm = ({ values, errors, touched }) => (
    <Form autoComplete="off">
      <Field
        component={Password}
        label="Password"
        name="password"
        id="password"
        tooltipText={PASSWORD_TOOLTIP_TEXT}
        type="password"
        icon="eye"
      />
      <Field
        component={Password}
        label="Confirm Password"
        name="confirmPassword"
        id="confirmPassword"
        tooltipText={PASSWORD_CONFIRM_TOOLTIP_TEXT}
        type="password"
        icon="eye"
      />
      <FormGroup>
        <b>Password Policy:</b>
        <ol>
          <li>Minimum length is 8 characters.</li>
          <li>Must have at least one numeric character.</li>
          <li>Must have at least three letters (lowercase and uppercase).</li>
          <li>Password must be sufficiently unique.</li>
        </ol>
      </FormGroup>
      {auth.passSet ? (
        <>
          {auth.passSetMessage === 'Success' ? (
            <Alert color="success" />
          ) : auth.passSetMessage === 'PASSWORD_POLICY_FAILURE' ? (
            <Alert color="danger">
              The password does not satisfy the password policy requirements.
            </Alert>
          ) : auth.passSetMessage === 'PASSWORD_TOKEN_INVALID' ? (
            <Alert color="danger">
              Your password reset token is invalid. Make sure you copied the link correctly. If you
              attempted to reset your password multiple times, make sure you are using the link
              provided in the most recent email.
            </Alert>
          ) : auth.passSetMessage === 'PASSWORD_TOKEN_EXPIRED' ? (
            <Alert color="danger">Your password token has expired.</Alert>
          ) : auth.passSetMessage === 'PASSWORD_UNKNOWN_ERROR' ? (
            <Alert color="danger">
              An unknown error occurred attempting to change the password. Please contact your
              Microf support team if the problem persists.
            </Alert>
          ) : null}
        </>
      ) : null}
      <Row className="align-items-center">
        <Col sm={6}>
          <Button
            className="login-button btn btn-primary btn-lg btn-block"
            type="submit"
            title="Set Password"
            disabled={auth.passSet && auth.passSetMessage === 'Success'}
          />
        </Col>
        <Col sm={6}>
          <FormGroup className="d-flex justify-content-center">
            <a href="/Login">Back to Login</a>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );

  const SetYourPasswordFormFormik = withFormik({
    mapPropsToValues: () => ({
      password: auth.candidatePassword || '',
      confirmPassword: auth.candidatePassword || '',
    }),

    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, 'Password has to be at least 8 characters.')
        .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,255}$/, 'Insert a valid password.')
        .test('3Letters', 'Insert a valid password.', val => {
          const alpha = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
          let charNo = 0;
          if (!!val) {
            for (var i = 0; i < val.length; i++) {
              if (alpha.indexOf(val.charAt(i)) > -1) {
                charNo++;
              }
            }
          }
          return charNo >= 3;
        })
        .required('Please enter a password.'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Password must match.')
        .required('Please enter a password.'),
    }),

    handleSubmit: values => {
      const passwordDTO = {
        email: null,
        token: auth.passwordToken,
        candidatePassword: values.password,
        existingPassword: null,
        addedFrom: getAddedFrom(),
      };
      dispatchPasswordRecovery(passwordDTO, history);
    },
  })(SetYourPasswordForm);

  return (
    <div className="login-form">
      <h1>
        <b>Set Your Password</b>
      </h1>
      <p>Please enter your new password.</p>
      <SetYourPasswordFormFormik />
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangeValue: (key, value) => dispatch(changeAuthValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchEndLoading: () => dispatch(endLoading()),
  dispatchPasswordRecovery: (data, history) => dispatch(passwordRecovery(data, history)),
});

SetYourPassword.propTypes = {
  dispatchChangeValue: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SetYourPassword),
);
