import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { ftlSelectProgram } from '../../../../redux/actions';

const Approved = ({ history, loanDoc, application, data }) => {
  const isNewVersion = data.ftlInformation.newVersion === true;

  const back = () => {
    history.push('/portal/applications-under-review');
  };

  return (
    <>
      <Row className="justify-content-end">
        <Col sm="12" xs="12" md="auto">
          <Button
            type="submit"
            color="secondary"
            className="rounded-pill w-100 mt-1"
            outline
            onClick={() => {
              back();
            }}
          >
            Back
          </Button>
        </Col>
        {!data.ftlInformation.generatedPaperworkSuccess && !isNewVersion && (
          <Col sm="12" xs="12" md="auto">
            <Button
              type="submit"
              color="primary"
              className="rounded-pill w-100 mt-1"
              outline
              onClick={() => {
                loanDoc(true);
              }}
            >
              Create Loan Documents
            </Button>
          </Col>
        )}
      </Row>
    </>
  );
};

const mapStateToProps = state => ({
  ftlOptionsResponse: state.softPull.ftlOptionsResponse,
});

const mapDispatchToProps = dispatch => ({
  dispatchSelectProgram: (appId, optionId) => dispatch(ftlSelectProgram(appId, optionId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Approved);
