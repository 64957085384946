import React from 'react';
import AccountEmail from './AccountEmail';
import AccountPassword from './AccountPassword';
import UserAddress from './UserAddress';
import UserInfo from './UserInfo';
import UserLastInfo from './UserLastInfo';

const PreQualificationFlow = [
  <AccountEmail>
    <h4>Let’s start with your email address.</h4>
  </AccountEmail>,
  <AccountPassword>
    <h4>Choose a password that you can remember.</h4>
  </AccountPassword>,
  <UserInfo>
    <h4>What’s your name and number?</h4>
  </UserInfo>,
  <UserAddress>
    <h4>What is the Property Address where the services will be completed?</h4>
  </UserAddress>,
  <UserLastInfo />,
];

export default PreQualificationFlow;

export const AtWaterPreQualificationFlowPageNumber = {
  AccountEmail: 0,
  AccountPassword: 1,
  UserInfo: 2,
  UserAddress: 3,
  UserLastInfo: 4,
};
