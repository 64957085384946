import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import goDaddy from '../../assets/img/godaddy.jpg';
import pciCompliant from '../../assets/img/PCI_Compliant-Icon.jpg';
import BackBtn from '../../components/UI/BackButton/BackButton';
import Button from '../../components/UI/Button/CustomButton';
import CustomInput from '../../components/UI/Form/Input/CustomInput';
import CurrencyFormat from '../../components/UI/Form/NumberFormat/CurrencyFormat';
import { getIsOptimus } from '../../config/settings';
import {
  APPBANKACCOUNTNUMBER_TOOLTIP_TEXT,
  APPBANKACCOUNTROUTINGNUMBER_TOOLTIP_TEXT,
  APPMONTHLYNETINCOME_TOOLTIP_TEXT,
} from '../../constants/tooltipTexts';
import { calculateMonthlyNetIncome, reactGAEvent, reactGAPageview } from '../../helper';
import { changeMicrofInfoValue, changePage, submitMindfire } from '../../redux/actions/index';
import { MicrofFlowPageNumber } from './MicrofFlow';

const AppIncomeInfo = props => {
  const {
    appMonthlyNetIncome,
    appBankAccountNumber,
    appBankAccountRoutingNumber,
    dispatchChangePage,
    dispatchChangeValue,
    children,
    did,
    dealerUserId,
    dispatchSubmitMindfire,
    email,
    isReview,
    pqi,
  } = props;

  const isOptimus = getIsOptimus();

  useEffect(() => {
    reactGAPageview({
      path: '/ca_consumerbankinfo',
      trackerNames: [],
      title: 'ca_consumerbankinfo',
      did,
      dealerUserId,
    });

    // eslint-disable-next-line
  }, []);

  const AppIncomeInfoForm = () => (
    <Form autoComplete="off">
      <Field
        component={CurrencyFormat}
        label={'Monthly Net Income (or Benefits) Amount'}
        name="appMonthlyNetIncome"
        id="appMonthlyNetIncome"
        tooltipText={APPMONTHLYNETINCOME_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={CustomInput}
        label="Your Bank Account Routing Number"
        name="appBankAccountRoutingNumber"
        id="appBankAccountRoutingNumber"
        tooltipText={APPBANKACCOUNTROUTINGNUMBER_TOOLTIP_TEXT}
        type="password"
        icon="lock"
        number
        pattern="\d*"
        maxLength={9}
      />
      <p className="info">
        Microf uses security measures that comply with federal law, including data encryption
        technology, to protect your personal information from unauthorized access and use.
      </p>
      <Field
        component={CustomInput}
        label="Your Bank Account Number"
        name="appBankAccountNumber"
        id="appBankAccountNumber"
        tooltipText={APPBANKACCOUNTNUMBER_TOOLTIP_TEXT}
        type="password"
        icon="lock"
        pattern="\d*"
        maxLength={25}
      />
      <p className="info">Click on the padlocks to read hidden text</p>
      <Button type="submit" title="Continue" />
    </Form>
  );

  const AppIncomeInfoFormFormik = withFormik({
    mapPropsToValues: () => ({
      appMonthlyNetIncome: appMonthlyNetIncome
        ? appMonthlyNetIncome
        : pqi?.grossAmount
        ? calculateMonthlyNetIncome(pqi?.grossAmount)
        : '',
      appBankAccountNumber: appBankAccountNumber || '',
      appBankAccountRoutingNumber: appBankAccountRoutingNumber || '',
    }),

    validationSchema: Yup.object({
      appMonthlyNetIncome: Yup.number()
        .required('Please enter your Monthly Net Income.')
        .moreThan(0, 'Amount should be greater than 0.'),
      appBankAccountNumber: Yup.string().required('Please enter your Bank Account Number.'),
      appBankAccountRoutingNumber: Yup.string()
        .min(9, 'Please enter just 9 digits.')
        .max(9, 'Please enter just 9 digits.')
        .required('Please enter your Bank Account Routing Number.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Continue',
        did,
        dealerUserId,
      });
      dispatchChangeValue('appMonthlyNetIncome', values.appMonthlyNetIncome);
      dispatchChangeValue('appBankAccountNumber', values.appBankAccountNumber);
      dispatchChangeValue('appBankAccountRoutingNumber', values.appBankAccountRoutingNumber);
      dispatchChangePage(
        isReview ? MicrofFlowPageNumber.Review : MicrofFlowPageNumber.HasCoApplicant,
      );
      dispatchSubmitMindfire({
        email: email,
        lastPage: '4082',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(AppIncomeInfoForm);

  return (
    <Container>
      <Row>
        <BackBtn changePage={dispatchChangePage} page={MicrofFlowPageNumber.LeaseAmount} />
        <Col sm="5">
          {children}
          <Row>
            <Col xs="6">
              <img src={goDaddy} alt="godady" width="100%" />
            </Col>
            <Col xs="6">
              <img src={pciCompliant} alt="pci-compliant" width="100%" />
            </Col>
          </Row>
        </Col>
        <Col sm="6">
          <AppIncomeInfoFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  pqi: state.preQualificationInformation,
  isReview: state.microfInformation.isReview,
  appMonthlyNetIncome: state.microfInformation.appMonthlyNetIncome,
  appBankAccountNumber: state.microfInformation.appBankAccountNumber,
  appBankAccountRoutingNumber: state.microfInformation.appBankAccountRoutingNumber,
  aquaInformation: state.aquaInformation,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) => dispatch(changeMicrofInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

AppIncomeInfo.propTypes = {
  appMonthlyNetIncome: PropTypes.number,
  appBankAccountNumber: PropTypes.string,
  appBankAccountRoutingNumber: PropTypes.string,
  aquaInformation: PropTypes.object,
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppIncomeInfo);
