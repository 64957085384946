import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import Input from '../../../../components/UI/Form/Input/CustomInput';
import {
  changePage,
  changeAtWaterApplicationInfoValue,
  submitMindfire,
} from '../../../../redux/actions/index';
import { reactGAPageview, reactGAEvent, isNoreplyEmail } from '../../../../helper';
import { AtWaterFlowPageNumber } from './AtWaterFlow';
import CustomPhoneNumber from '../../../../components/UI/Form/PhoneNumber/PhoneNumber';

const UserInfo = props => {
  const {
    email,
    coAppFirstName,
    coAppMiddleInitial,
    coAppLastName,
    dispatchChangePage,
    dispatchChangeAtWaterApplicationInfoValue,
    children,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    atWaterApplicationInformation,
    coAppEmail,
    coAppPhoneNumber,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_gscoappinfo1',
      trackerNames: [],
      title: 'ca_gscoappinfo1',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const UserInfoForm = () => (
    <Form>
      <Field
        component={Input}
        label="First Name"
        name="coAppFirstName"
        id="coAppFirstName"
        tooltipText={''}
        type="text"
      />
      <Field
        component={Input}
        label="Middle Initial"
        name="coAppMiddleInitial"
        id="coAppMiddleInitial"
        tooltipText={''}
        type="text"
        maxLength="2"
      />
      <Field
        component={Input}
        label="Last Name"
        name="coAppLastName"
        id="coAppLastName"
        tooltipText={''}
        type="text"
      />
      <Field
        component={CustomPhoneNumber}
        label="Phone Number (Mobile Preferred)"
        name="coAppPhoneNumber"
        id="coAppPhoneNumber"
        tooltipText={''}
      />
      <Field
        component={Input}
        label="Email Address"
        name="coAppEmail"
        id="coAppEmail"
        tooltipText={''}
        type="email"
      />
      <Button type="submit" title="Continue" />
    </Form>
  );

  const UserInfoFormFormik = withFormik({
    mapPropsToValues: () => ({
      coAppFirstName: coAppFirstName || atWaterApplicationInformation.coAppFirstName || '',
      coAppMiddleInitial:
        coAppMiddleInitial || atWaterApplicationInformation.coAppMiddleInitial || '',
      coAppLastName: coAppLastName || atWaterApplicationInformation.coAppLastName || '',
      coAppEmail: coAppEmail || atWaterApplicationInformation.coAppEmail || '',
      coAppPhoneNumber: coAppPhoneNumber || atWaterApplicationInformation.coAppPhoneNumber || '',
    }),

    validationSchema: Yup.object({
      coAppFirstName: Yup.string()
        .matches(/^(?=.*[a-zA-Z ])([a-zA-Z ]+)$/, 'Special characters are not allowed.')
        .required('Please enter your first name.'),
      coAppMiddleInitial: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .max(2, 'Middle initial is too long.'),
      coAppLastName: Yup.string()
        .matches(/^(?=.*[a-zA-Z ])([a-zA-Z ]+)$/, 'Special characters are not allowed.')
        .required('Please enter your last name.'),
      coAppEmail: Yup.string()
        .email('Please enter a valid email.')
        .required('Please enter an email.')
        .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),
      coAppPhoneNumber: Yup.string()
        .test(
          'required',
          "Please enter the Co-Applicant's phone number.",
          val => val !== '+' && val,
        )
        .test('len', 'Invalid phone number', val => val && val.match(/\d/g).join('').length === 10),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Continue',
        did,
        dealerUserId,
      });
      dispatchChangeAtWaterApplicationInfoValue('coAppFirstName', values.coAppFirstName.trim());
      dispatchChangeAtWaterApplicationInfoValue(
        'coAppMiddleInitial',
        values.coAppMiddleInitial.trim(),
      );
      dispatchChangeAtWaterApplicationInfoValue('coAppLastName', values.coAppLastName.trim());
      dispatchChangeAtWaterApplicationInfoValue(
        'coAppPhoneNumber',
        values.coAppPhoneNumber.match(/\d/g).join(''),
      );
      dispatchChangeAtWaterApplicationInfoValue('coAppEmail', values.coAppEmail.trim());
      dispatchChangePage(AtWaterFlowPageNumber.CoApplicantIdentificationInformation);
      dispatchSubmitMindfire({
        email: email,
        lastPage: '9017',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(UserInfoForm);

  return (
    <Container>
      <Row>
        <BackBtn changePage={dispatchChangePage} page={AtWaterFlowPageNumber.HasCoApplicant} />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <UserInfoFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  state: state.preQualificationInformation.state,
  coAppFirstName: state.greenSkyApplicationInformation.coAppFirstName,
  coAppMiddleInitial: state.greenSkyApplicationInformation.coAppMiddleInitial,
  coAppLastName: state.greenSkyApplicationInformation.coAppLastName,
  isCoAppSpouse: state.greenSkyApplicationInformation.isCoAppSpouse,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  atWaterApplicationInformation: state.atWaterApplicationInformation,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeAtWaterApplicationInfoValue: (key, value) =>
    dispatch(changeAtWaterApplicationInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

UserInfo.propTypes = {
  coAppFirstName: PropTypes.string,
  coAppMiddleInitial: PropTypes.string,
  coAppLastName: PropTypes.string,
  dispatchChangePage: PropTypes.func,
  dispatchChangeGreenSkyApplicationInfoValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserInfo);
