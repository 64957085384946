import React from 'react';
import Consent from './Consent';
import ProgramInformation from './ProgramInformation';
import IdentificationInformation from './IdentificationInformation';
import AppPrimaryAddress from './AppPrimaryAddress';
import EmploymentInformation from './EmploymentInformation';
import HasCoApplicant from './HasCoApplicant';
import CoApplicantName from './CoApplicantName';
import CoApplicantIdentificationInformation from './CoApplicantIdentificationInformation';
import CoApplicantAddress from './CoApplicantAddress';
import CoApplicantEmploymentInformation from './CoApplicantEmploymentInformation';
import ApplicationConsent from './ApplicationConsent';
import ApplicationResult from './ApplicationResult';
import ApplicationReceived from './ApplicationReceived';

const AtWaterFlow = [
  <Consent />,
  <ProgramInformation>
    <h4>Please enter the Program Information</h4>
  </ProgramInformation>,
  <IdentificationInformation>
    <h4>Enter your identification information</h4>
  </IdentificationInformation>,
  <AppPrimaryAddress>
    <h4>Enter your current address</h4>
  </AppPrimaryAddress>,
  <EmploymentInformation>
    <h4>Enter your employment and citizenship information</h4>
  </EmploymentInformation>,
  <HasCoApplicant>
    <h4>Do you wish to add a Co-Applicant?</h4>
  </HasCoApplicant>,
  <CoApplicantName>
    <h4>Co-Applicant, please enter your name and contact information</h4>
  </CoApplicantName>,
  <CoApplicantIdentificationInformation>
    <h4>Co-Applicant - Enter your identification information</h4>
  </CoApplicantIdentificationInformation>,
  <CoApplicantAddress>
    <h4>Co-Applicant - Enter your address</h4>
  </CoApplicantAddress>,
  <CoApplicantEmploymentInformation>
    <h4>Co-Applicant - Enter your employment and citizenship information</h4>
  </CoApplicantEmploymentInformation>,
  <ApplicationConsent />,
  <ApplicationResult />,
  <ApplicationReceived />,
];

export default AtWaterFlow;

export const AtWaterFlowPageNumber = {
  Consent: 0,
  ProgramInformation: 1,
  IdentificationInformation: 2,
  AppPrimaryAddress: 3,
  EmploymentInformation: 4,
  HasCoApplicant: 5,
  CoApplicantName: 6,
  CoApplicantIdentificationInformation: 7,
  CoApplicantAddress: 8,
  CoApplicantEmploymentInformation: 9,
  ApplicationConsent: 10,
  ApplicationResult: 11,
  ApplicationReceived: 12,
};
