import React from 'react';
import { Col, Row } from 'reactstrap';
import userRoleEnum from '../../../assets/js/UserRoleEnum';
import Button from '../../../components/UI/Button/CustomButton';

const ContractorUserView = props => {
  const {
    history,
    selectedContractorUser,
    setIsEditMode,
    setConfirmDelete,
    setChangePass,
    currentUser,
  } = props;

  return (
    <Col>
      <Row>
        <Col>
          <h1>Contractor User View</h1>
        </Col>
      </Row>
      <Row className="panel">
        <Col>
          <Row className="contractor-user-view-row border-bottom mt-3">
            <Col xs="6">
              <div>Name</div>
            </Col>
            <Col xs="6">
              <div>{selectedContractorUser.firstName + ' ' + selectedContractorUser.lastName}</div>
            </Col>
          </Row>
          <Row className="contractor-user-view-row border-bottom">
            <Col xs="6">
              <div>Role</div>
            </Col>
            <Col xs="6">
              <div>{userRoleEnum[selectedContractorUser.role].name}</div>
            </Col>
          </Row>
          <Row className="contractor-user-view-row">
            <Col xs="6">
              <div>Email</div>
            </Col>
            <Col xs="6">
              <div>{selectedContractorUser.email}</div>
            </Col>
          </Row>
          {selectedContractorUser.isNew ? (
            <Row className="contractor-user-view-row alert-success rounded">
              The account was successfully created. Account access instructions have been sent to
              the specified email address.
            </Row>
          ) : null}
          <Row className="contractor-user-view-row justify-content-end">
            <Button
              className={'inactive rounded-pill'}
              title={'Close'}
              clickHandler={() => {
                if (selectedContractorUser.isNew) {
                  history.push('/portal/dashboard');
                } else {
                  history.push('/portal/search-users');
                }
              }}
            />
            {currentUser?.role === userRoleEnum.DEALER_ADMIN.value ? (
              <Button
                className="rounded-pill"
                title="Change Password"
                clickHandler={() => {
                  setChangePass(true);
                }}
              />
            ) : null}
            <Button
              className={'rounded-pill'}
              title={'Edit'}
              clickHandler={() => {
                setIsEditMode(true);
              }}
            />
            <Button
              className={'btn-secondary rounded-pill'}
              title={'Delete'}
              clickHandler={() => {
                setConfirmDelete(true);
              }}
            />
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default ContractorUserView;
