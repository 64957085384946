import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row, Label } from 'reactstrap';
import * as Yup from 'yup';
import usStates from '../../../assets/js/USStateEnum';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import Input from '../../../components/UI/Form/Input/CustomInput';
import Select from '../../../components/UI/Form/Select/Select';
import {
  CITY_TOOLTIP_TEXT,
  STATE_TOOLTIP_TEXT,
  STREET_TOOLTIP_TEXT,
  UNIT_NUMBER_TOOLTIP_TEXT,
  ZIPCODE_TOOLTIP_TEXT,
} from '../../../constants/tooltipTexts';
import { changeApplicationValues, reactGAEvent, reactGAPageview } from '../../../helper';
import { changeFtlInfoValue, changePage, submitMindfire } from '../../../redux/actions/index';
import { FTLFlowPageNumber } from './FTLFlow';

const BillingAddress = props => {
  const {
    email,
    applicantStreet,
    applicantCity,
    applicantApartmentNumber,
    applicantZipcode,
    applicantState,
    street,
    city,
    zipcode,
    state,
    dispatchChangePage,
    dispatchChangeFtlInfoValue,
    children,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    isSameAddress,
    isReview,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_ftlbillingaddress',
      trackerNames: [],
      title: 'ca_ftlbillingaddress',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const BillingAddressForm = () => (
    <Form>
      <Label>
        <b>The address is the same as the applicant's.</b>
      </Label>
      <Row>
        <Col sm="6">
          <Button
            className={isSameAddress ? '' : 'inactive'}
            type="button"
            title="Yes"
            clickHandler={() => dispatchChangeFtlInfoValue('isSameAddress', true)}
          />
        </Col>
        <Col sm="6">
          <Button
            className={!isSameAddress ? '' : 'inactive'}
            type="button"
            title="No"
            clickHandler={() => dispatchChangeFtlInfoValue('isSameAddress', false)}
          />
        </Col>
      </Row>
      <Field
        disabled={isSameAddress}
        component={Input}
        label="Street"
        name="applicantStreet"
        id="applicantStreet"
        tooltipText={STREET_TOOLTIP_TEXT}
        type="text"
        maxLength={150}
      />
      <Field
        component={Input}
        label="Unit/Apt. Number"
        name="applicantApartmentNumber"
        id="applicantApartmentNumber"
        tooltipText={UNIT_NUMBER_TOOLTIP_TEXT}
        type="text"
      />
      <Row>
        <Col sm="6">
          <Field
            disabled={isSameAddress}
            component={Input}
            label="City"
            name="applicantCity"
            id="applicantCity"
            tooltipText={CITY_TOOLTIP_TEXT}
            type="text"
          />
        </Col>
        <Col sm="6">
          <Field
            disabled={isSameAddress}
            component={Select}
            label="State"
            name="applicantState"
            id="applicantState"
            tooltipText={STATE_TOOLTIP_TEXT}
            type="select"
            selectValues={usStates}
            selectFirstName="Select State"
          />
        </Col>
      </Row>
      <Field
        disabled={isSameAddress}
        component={Input}
        label="ZIP Code"
        name="applicantZipcode"
        id="applicantZipcode"
        tooltipText={ZIPCODE_TOOLTIP_TEXT}
        type="text"
        maxLength={10}
      />

      <Button type="submit" title="Continue" />
    </Form>
  );

  const BillingAddressFormFormik = withFormik({
    mapPropsToValues: () => ({
      applicantStreet: (isSameAddress && street) || applicantStreet || '',
      applicantCity: (isSameAddress && city) || applicantCity || '',
      applicantApartmentNumber: applicantApartmentNumber || '',
      applicantZipcode: (isSameAddress && zipcode) || applicantZipcode || '',
      applicantState: (isSameAddress && state) || applicantState || '',
    }),

    validationSchema: Yup.object({
      applicantStreet: Yup.string()
        .matches(/.*([a-zA-Z].*[0-9]|[0-9].*[a-zA-Z]).*/, {
          message: 'The street field must contain the street name and number',
          excludeEmptyString: true,
        })
        .required('Please enter a street.'),
      applicantCity: Yup.string().required('Please enter a city.'),
      applicantApartmentNumber: Yup.string(),
      applicantZipcode: Yup.string()
        .matches(/^\d{5}?$/, {
          message: 'Invalid zip code.',
          excludeEmptyString: true,
        })
        .required('Please enter a zip code.'),
      applicantState: Yup.string().required('Please select a state.'),
    }),

    handleSubmit: async values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Continue',
        did,
        dealerUserId,
      });
      changeApplicationValues(dispatchChangeFtlInfoValue, values);
      dispatchSubmitMindfire({
        email: email,
        lastPage: '12010',
        dealerUserId: dealerUserId,
        did: did,
      });

      dispatchChangePage(
        isReview
          ? FTLFlowPageNumber.IdentificationInformation
          : FTLFlowPageNumber.EmploymentInformation,
      );
    },
  })(BillingAddressForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={isReview ? FTLFlowPageNumber.Review : FTLFlowPageNumber.LoanAmount}
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <BillingAddressFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  isReview: state.ftlInformation.isReview,
  isSameAddress: state.ftlInformation.isSameAddress,
  applicantStreet: state.ftlInformation.applicantStreet,
  applicantApartmentNumber: state.ftlInformation.applicantApartmentNumber,
  applicantCity: state.ftlInformation.applicantCity,
  applicantZipcode: state.ftlInformation.applicantZipcode,
  applicantState: state.ftlInformation.applicantState,
  street: state.preQualificationInformation.street,
  city: state.preQualificationInformation.city,
  poBox: state.preQualificationInformation.poBox,
  zipcode: state.preQualificationInformation.zipcode,
  state: state.preQualificationInformation.state,
  email: state.ftlInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeFtlInfoValue: (key, value) => dispatch(changeFtlInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BillingAddress);
