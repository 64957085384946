import React, { useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { searchContractorUsers } from '../../../redux/actions';
import ContractorUsersSearch from './ContractorUsersSearch';
import ContractorUsersTable from './ContractorUsersTable';

const ManageContractorUsers = props => {
  const pageLimit = 20;
  const {
    auth,
    history,
    contractorUsers,
    searchOptionUsers,
    dispatchSearchContractorUsers,
  } = props;
  const pageCount = contractorUsers && contractorUsers.total / pageLimit;

  useEffect(() => {
    if (!auth.user && (!auth.user.dealerUser || !auth.user.internalUser)) {
      history.push('/login');
    }
    // eslint-disable-next-line
  }, []);

  const handlePageClick = pageNumber => {
    dispatchSearchContractorUsers({
      ...searchOptionUsers,
      pageOffset: pageNumber.selected * pageLimit,
    });
  };

  return (
    <Col>
      <Row className="mt-5">
        <h1 id="panelTitle">Manage Contractor Users</h1>
        <ContractorUsersSearch
          searchOptionUsers={searchOptionUsers}
          searchContractorUsers={dispatchSearchContractorUsers}
        />
        <div className="panel">
          <div className="panel-body larger">
            <Row>
              <Col className="right-bordered">
                {contractorUsers && contractorUsers.total > 0 ? (
                  <>
                    <Row className="overflow-auto">
                      <ContractorUsersTable contractorUsers={contractorUsers} history={history} />
                    </Row>
                    {pageCount > 1 && (
                      <Row className="justify-content-between align-items-center">
                        <ReactPaginate
                          previousLabel={'<'}
                          nextLabel={'>'}
                          breakClassName={'break-me'}
                          breakLinkClassName={'page-link'}
                          breakLabel={'...'}
                          pageCount={pageCount}
                          pageLimitDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageClick}
                          containerClassName={'pagination'}
                          subContainerClassName={'pages pagination'}
                          pageClassName={'page-item'}
                          pageLinkClassName={'page-link'}
                          activeClassName={'active'}
                          nextClassName={'page-item'}
                          nextLinkClassName={'page-link'}
                          previousClassName={'page-item'}
                          previousLinkClassName={'page-link'}
                        />
                      </Row>
                    )}
                  </>
                ) : (
                  <>No users found.</>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Row>
    </Col>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  contractorUsers: state.softPull.contractorUsers,
  searchOptionUsers: state.softPull.searchOptionUsers,
});

const mapDispatchToProps = dispatch => ({
  dispatchSearchContractorUsers: searchOptions => dispatch(searchContractorUsers(searchOptions)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ManageContractorUsers),
);
