import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { getApplication } from '../../../redux/actions/index';
import queryString from 'query-string';
import FundingNotAllowed from '../../Microf/FundingNotAllowed';
import CompleteFundRheemApplication from './CompleteFundRheemApplication';

const isFundingAllowed = application => application.firstPayment && application.agreementSigned;
const COMPLETE_APPLICATION_TITLE = 'Complete / Fund Application';
const FUNDING_NOT_ALLOWED_TITLE = 'Funding not allowed';

const CompleteFundOpenRheemApplication = props => {
  const { auth, dispatchGetApplication, application, history } = props;
  const appId = +queryString.parse(window.location.search).appId;

  useEffect(() => {
    if (!auth.user || !auth.user.dealerUser) {
      history.push('/login');
    }
    if (appId) {
      if (!application || application.id !== appId) {
        dispatchGetApplication(appId);
      }
    } else {
      history.push('/portal/open-applications');
    }
  }, [appId, application, auth.user, dispatchGetApplication, history]);

  const ApplicationFunding = () => {
    if (isFundingAllowed(application)) {
      return <CompleteFundRheemApplication />;
    }
    return <FundingNotAllowed />;
  };

  const panelTitle = () => {
    if (!application) {
      return COMPLETE_APPLICATION_TITLE;
    }
    return isFundingAllowed(application) ? COMPLETE_APPLICATION_TITLE : FUNDING_NOT_ALLOWED_TITLE;
  };

  return (
    <Col>
      <Row className="mt-5">
        <div className="panel">
          <div className="panel-body larger">
            <h2 id="panelTitle">{panelTitle()}</h2>
            <Row>
              <Col className="right-bordered">
                {application ? <ApplicationFunding /> : <div>No application found.</div>}
              </Col>
            </Row>
          </div>
        </div>
      </Row>
    </Col>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  application: state.softPull.application,
});

const mapDispatchToProps = dispatch => ({
  dispatchGetApplication: applicationId => dispatch(getApplication(applicationId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompleteFundOpenRheemApplication);
