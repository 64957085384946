import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import {
  reactGAPageview,
  reactGAEvent,
  nextFinancingOption,
  isStateAllowed,
} from '../../../../helper';
import {
  changePage,
  mapApplicationToMicrof,
  resetState,
  mapAppToFoundation,
  mapAppToFtl,
} from '../../../../redux/actions/index';
import selectedFinancierEnum from '../../../../assets/js/SelectedFinancierEnum';
import { Link } from 'react-router-dom';
import CustomButton from '../../../../components/UI/Button/CustomButton';
import ApplicationFlowEnum from '../../../../assets/js/ApplicationFlowEnum';
import { MicrofFlowPageNumber } from '../../../Microf/MicrofFlow';
import { getIsOptimus } from '../../../../config/settings';
import AirConditioningSystemEnum from '../../../../assets/js/AirConditioningSystemEnum';

const Declined = props => {
  const {
    user,
    email,
    dealerUserId,
    did,
    dispatchSubmitMindfire,
    dispatchUpdateSelectedFinancier,
    consumerToken,
    history,
    dispatchSetFlow,
    dispatchChangePageTitle,
    application,
    dispatchMapApplicationToMicrof,
    dispatchMapApplicationToFoundation,
    dispatchMapApplicationToFTL,
    dispatchResetState,
    isHomeowner,
    dispatchChangePage,
    microfAssigned,
  } = props;

  useEffect(() => {
    dispatchChangePageTitle('Declined Application');
    reactGAPageview({
      path: '/ca_gsdeclined',
      trackerNames: [],
      title: 'ca_gsdeclined',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const isOptimus = getIsOptimus();

  let nextFO = nextFinancingOption(application, selectedFinancierEnum.GREENSKY);

  const score = application?.prequalificationInformation?.score;

  const handleClick = async () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: `Continue to ${nextFO.value}`,
      did,
      dealerUserId,
    });
    dispatchSubmitMindfire({
      email: email,
      lastPage: '9026',
      dealerUserId: dealerUserId,
      did: did,
    });

    await dispatchUpdateSelectedFinancier(nextFO.value, consumerToken, () => {
      if (nextFO === selectedFinancierEnum.FOUNDATION) {
        dispatchMapApplicationToFoundation(application);
        dispatchSetFlow(ApplicationFlowEnum.FOUNDATION_FLOW.value);
        history.push(ApplicationFlowEnum.FOUNDATION_FLOW.path);
      } else if (nextFO === selectedFinancierEnum.FTL) {
        dispatchMapApplicationToFTL(application);
        dispatchSetFlow(ApplicationFlowEnum.FTL_FLOW.value);
        history.push(ApplicationFlowEnum.FTL_FLOW.path);
      } else {
        dispatchMapApplicationToMicrof(application);
        dispatchSetFlow(ApplicationFlowEnum.MICROF_FLOW.value);
        dispatchChangePage(MicrofFlowPageNumber.AccountEmail);
        history.push(ApplicationFlowEnum.MICROF_FLOW.path);
      }
    });
  };

  const IsNotHomeownerContent = () => {
    return (
      <>
        <Row className="justify-content-center mt-2">
          <Col xs="auto" sm="8" className="text-center">
            <h5>
              <b>
                You may re-apply with the homeowner as a co-applicant to be considered for
                additional financing and lease purchase offers.
              </b>
            </h5>
          </Col>
        </Row>
        <Row className="justify-content-center mt-5">
          <Col xs="10" md="3">
            <Link
              to={user?.dealerUser ? '/portal/dashboard' : ''}
              onClick={() => !user?.dealerUser && dispatchResetState({})}
              style={{ textDecoration: 'none' }}
              className="block"
            >
              <CustomButton
                color="secondary"
                size="lg"
                block
                type="button"
                title={user?.dealerUser ? 'Return to dashboard' : 'Exit'}
              />
            </Link>
          </Col>
        </Row>
      </>
    );
  };

  const NextFOComponent = () => {
    return (
      <>
        <Row className="justify-content-center mt-2">
          <Col xs="auto" sm="8" className="text-center">
            <h5>
              {nextFO === selectedFinancierEnum.FTL ? (
                <b>
                  But wait! You may still qualify for an FTL financing option. If you would like to
                  apply with FTL, just press "Continue to FTL" and you will be directed to the FTL
                  application process.
                </b>
              ) : (
                <b>
                  But wait! You may still qualify for a lease purchase option. A lease purchase
                  option is different than financing.
                </b>
              )}
            </h5>
          </Col>
        </Row>
        {nextFO === selectedFinancierEnum.MICROF && (
          <Container>
            <Row className="justify-content-center mt-2">
              <Col xs="auto" sm="12" className="text-center">
                <p className="fs-25">
                  <b className="primary">
                    Congratulations! You've prequalified for a Microf Lease Offer
                  </b>
                </p>
              </Col>
            </Row>
            <Row className="justify-content-center ">
              <Col xs="auto" lg="8" sm="12" md="12">
                <p className="fs-22 fw-500 pl-40">
                  Microf’s lease purchase option is different than financing:
                </p>
                <ul className="powerpay-microf-lease-purchase-options-arrow">
                  <li>
                    Pay as you go!{' '}
                    <span className="fw-normal">
                      Monthly renewals up to 60 months to own in most states.
                    </span>
                  </li>
                  <li>
                    Own the item(s) after paying the total of payments to ownership.{' '}
                    <span className="fw-normal">
                      Substantial savings are available through Early Purchase Options!
                    </span>
                  </li>
                  <li>
                    Damaged credit?{' '}
                    <span className="fw-normal">
                      Microf regularly approves applicants with less than perfect credit.
                    </span>
                  </li>
                  <li>
                    Have questions?{' '}
                    <span className="fw-normal">
                      Call us at 855-642-7631 or email us at info@microf.com.
                    </span>
                  </li>
                </ul>
              </Col>
            </Row>
            <Row className="justify-content-center ">
              <Col xs="auto" lg="8" sm="12" md="12">
                <p className="fs-22 fw-500 pl-40">
                  Here is what you will need to complete the application:
                </p>
                <ul className="microf-lease-purchase-options">
                  <li>Social Security Number</li>
                  <li>Bank account information (routing and account numbers)</li>
                  <li>Income or benefits information (e.g., paystub, bank statement, etc.)</li>
                  <li>Co-applicant information (not required)</li>
                </ul>
              </Col>
            </Row>
            <Row className="justify-content-center ">
              <Col xs="auto" lg="8" sm="12" md="12">
                <p className="fs-22 fw-500 pl-40">Not eligible for lease purchase:</p>
                <ul className="powerpay-microf-lease-purchase-options-x">
                  <li>Homes on rented land (e.g., mobile homes in a park)</li>
                  <li>Reverse mortgages</li>
                  <li>Rent-to-own or lease-to-own properties</li>
                  <li>Homeowners with active or dismissed bankruptcies</li>
                </ul>
              </Col>
            </Row>
          </Container>
        )}
        <Row
          className={
            nextFO === selectedFinancierEnum.MICROF
              ? 'justify-content-center'
              : 'justify-content-center mt-5'
          }
        >
          <Col xs="auto">
            <CustomButton
              color="primary"
              clickHandler={handleClick}
              title={`Continue to ${nextFO.value}`}
            />
          </Col>
        </Row>
      </>
    );
  };

  const ExitBtnComponent = () => {
    return (
      <Row className="justify-content-center mt-2">
        <Col xs="12" md="2">
          <Link
            to={user && user.dealerUser ? '/portal/dashboard' : ''}
            onClick={() => !(user && user.dealerUser) && dispatchResetState({})}
            style={{ textDecoration: 'none' }}
            className="block"
          >
            <CustomButton
              color="secondary"
              size="lg"
              block
              type="button"
              title={user && user.dealerUser ? 'Back to dashboard' : 'Exit'}
            />
          </Link>
        </Col>
      </Row>
    );
  };

  const OutOfTradeArea = () => {
    return (
      <Container>
        <Row className="justify-content-center mt-2">
          <Col xs="auto" sm="8" className="text-center">
            <h5>
              <b>
                Optimus was unable to approve your credit application at this time. You will receive
                a notification via email within 30 days.
              </b>
            </h5>
          </Col>
        </Row>
        <Row className="justify-content-center mt-5">
          <Col xs="10" md="3">
            <Link
              to={user?.dealerUser ? '/portal/dashboard' : ''}
              onClick={() => !user?.dealerUser && dispatchResetState({})}
              style={{ textDecoration: 'none' }}
              className="block"
            >
              <CustomButton
                color="secondary"
                size="lg"
                block
                type="button"
                title={user?.dealerUser ? 'Close Application' : 'Exit'}
              />
            </Link>
          </Col>
        </Row>
      </Container>
    );
  };

  const getComponent = () => {
    if (!isHomeowner) {
      return IsNotHomeownerContent();
    }
    return nextFO ? NextFOComponent() : ExitBtnComponent();
  };

  if (
    (nextFO === selectedFinancierEnum.MICROF &&
      !isStateAllowed(application?.propertyAddress?.state)) ||
    (isOptimus && microfAssigned === false && nextFO === selectedFinancierEnum.MICROF) ||
    (nextFO === selectedFinancierEnum.MICROF &&
      application.airConditioningSystem !==
        AirConditioningSystemEnum.AIR_CONDITION_OR_HEATING_SYSTEM.id)
  ) {
    return OutOfTradeArea();
  } else {
    return (
      <Container>
        <Row className="justify-content-center mt-2">
          <Col xs="auto" sm="8" className="text-center">
            <h5>
              <b>
                GreenSky was unable to approve your credit application at this time. You will
                receive a notification via email within 30 days.
              </b>
            </h5>
          </Col>
        </Row>
        {getComponent()}
      </Container>
    );
  }
};

const mapStateToProps = state => ({
  consumerToken:
    state.softPull.applicationSubmitDTO && state.softPull.applicationSubmitDTO.consumerToken,
  isHomeowner:
    state.softPull.applicationSubmitDTO?.isHomeowner ||
    state.preQualificationInformation?.isHomeowner,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  user: state.auth.user,
  application: state.softPull.applicationSubmitDTO,
  microfAssigned: state.auth.user?.user?.dealerStatsSummaryDTO?.microfAssigned
    ? state.auth.user?.user?.dealerStatsSummaryDTO?.microfAssigned
    : state.softPull.applicationSubmitDTO?.microfSelected
    ? state.softPull.applicationSubmitDTO?.microfSelected
    : false,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchMapApplicationToMicrof: app => dispatch(mapApplicationToMicrof(app)),
  dispatchMapApplicationToFoundation: app => dispatch(mapAppToFoundation(app)),
  dispatchMapApplicationToFTL: app => dispatch(mapAppToFtl(app)),
  dispatchResetState: props => dispatch(resetState(props)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Declined);
