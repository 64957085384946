import React from 'react';
import { Col, Row, Label, Button } from 'reactstrap';
import { Field, Form, withFormik } from 'formik';
import CustomInput from '../../../components/UI/Form/Input/CustomInput';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { findApplicationByRtoOrId } from '../../../redux/actions';

const FindApplication = props => {
  const { dispatchFindApplicationByRtoOrId, setPageIndex, application, pageIndex } = props;

  const FindApplicationForm = () => (
    <Form>
      <Row>
        <Col sm="4">
          <Label for="idNumber" className="pt-1">
            RTO-Number Or Application Id
          </Label>
        </Col>
        <Col sm="4">
          <Field labelClassName="d-none" component={CustomInput} name="idNumber" id="idNumber" />
        </Col>
      </Row>
      <Row className="justify-content-center mt-2">
        <Button type="submit" color="primary" className="rounded-pill">
          Find Application
        </Button>
      </Row>
    </Form>
  );

  const FindApplicationFormFormik = withFormik({
    validationSchema: Yup.object({
      idNumber: Yup.string().required('Please enter a value.'),
    }),

    handleSubmit: values => {
      let idNumber = values.idNumber;
      if (
        application &&
        (application.rtoNumber === idNumber.trim() || application.applicationId === idNumber.trim())
      ) {
        setPageIndex(1);
      } else {
        dispatchFindApplicationByRtoOrId(idNumber, setPageIndex);
      }
    },
  })(FindApplicationForm);

  const style = {
    width: ((pageIndex + 1) * 33).toString() + '%',
  };

  return (
    <Col>
      <h2 id="panelTitle">Move Application to Contractor</h2>
      <Row noGutters>
        <Col xs="12" className="progress">
          <div className="active" style={style} />
        </Col>
      </Row>
      <div className="panel">
        <div className="panel-body larger">
          <h3>Find Application</h3>
          <Row>
            <Col className="right-bordered">
              <FindApplicationFormFormik />
            </Col>
          </Row>
        </div>
      </div>
    </Col>
  );
};

const mapStateToProps = state => ({
  dealerId: state.auth.dealerId,
  application: state.softPull.applicationMoveApp,
});

const mapDispatchToProps = dispatch => ({
  dispatchFindApplicationByRtoOrId: (idNumber, successCallback) =>
    dispatch(findApplicationByRtoOrId(idNumber, successCallback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FindApplication);
