import React from 'react';
import { Field } from 'formik';
import InputFeedback from '../InputFeedback/InputFeedback';
import { Label, FormGroup } from 'reactstrap';

const SelectList = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched, setFieldValue },
  id,
  label,
  className,
  labelClassName,
  selectFirstName,
  selectValues,
  disabled,
  ...props
}) => (
  <FormGroup className={className}>
    <Label for={id} className={labelClassName}>
      <b>{label}</b>
    </Label>
    <Field component="select" id={id} name={name} className="form-control" disabled={disabled}>
      <option value="">{selectFirstName}</option>
      {selectValues
        ? Object.keys(selectValues).map(key => (
            <option key={key} value={selectValues[key]}>
              {selectValues[key]}
            </option>
          ))
        : null}
    </Field>
    {touched[name] && <InputFeedback error={errors[name]} />}
  </FormGroup>
);

export default SelectList;
