import React from 'react';
import { Field } from 'formik';
import InputFeedback from '../InputFeedback/InputFeedback';

const PrimaryUsersRadioButtons = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched, setFieldValue },
  icon,
  max,
  min,
  maxLength,
  className,
  pattern,
  disabled,
  number,
  label,
  labelClassName,
  type,
  id,
  placeholder,
  formGroupClassName,
  readOnly,
  options,
  clickHandler,
  ...props
}) => {
  console.log(options);

  return (
    <>
      <Field name={name}>
        {({ field }) => {
          return options.map((option, index) => {
            return (
              <tr className={'cursor-pointer'} key={index}>
                <td className="align-middle ">
                  <label htmlFor={option.id}>{option.name}</label>
                </td>
                <td className="align-middle width-10 text-center">
                  <input
                    type="radio"
                    id={option.value}
                    {...field}
                    value={option.value}
                    checked={option.checked ? true : field.value === option.value}
                    onClick={() => {
                      clickHandler(option.value);
                      setFieldValue('primaryUsers', option.value);
                    }}
                  />
                </td>
              </tr>
            );
          });
        }}
      </Field>
      {touched[name] && <InputFeedback error={errors[name]} />}
    </>
  );
};

export default PrimaryUsersRadioButtons;
