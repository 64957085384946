import React from 'react';
import { connect } from 'react-redux';
import { Col, Row, Button, Label } from 'reactstrap';
import { Field, Form, withFormik } from 'formik';
import * as Yup from 'yup';
import InputField from '../../../components/UI/Form/Input/InputField';
import { findContractors, changeSoftPullValue } from '../../../redux/actions/index';

const SearchCriteria = props => {
  const { zipcodeFindAContractor, dispatchChangeSoftPullValue, dispatchFindContractors } = props;

  const SearchCriteriaForm = ({ values }) => (
    <Form>
      <Row>
        <Col>
          <div className="form-group">
            <Label for="zipcodeFindAContractor">Zip Code/Postal Code*:</Label>
            <Field
              component={InputField}
              name="zipcodeFindAContractor"
              id="zipcodeFindAContractor"
              type="text"
              maxLength={10}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <Button type="submit" color="primary" className="rounded-pill" block>
            Search
          </Button>
        </Col>
      </Row>
    </Form>
  );

  const SearchCriteriaFormFormik = withFormik({
    mapPropsToValues: () => ({
      zipcodeFindAContractor: zipcodeFindAContractor || '',
    }),

    validationSchema: Yup.object({
      zipcodeFindAContractor: Yup.string()
        .matches(/^\d{5}?$/, {
          message: 'Invalid ZIP Code.',
          excludeEmptyString: true,
        })
        .required('Please enter a ZIP Code.'),
    }),

    handleSubmit: values => {
      dispatchChangeSoftPullValue('zipcodeFindAContractor', values.zipcodeFindAContractor);
      dispatchFindContractors(values.zipcodeFindAContractor);
    },
  })(SearchCriteriaForm);

  return <SearchCriteriaFormFormik />;
};

const mapStateToProps = state => ({
  auth: state.auth,
  zipcodeFindAContractor: state.softPull.zipcodeFindAContractor,
});

const mapDispatchToProps = dispatch => ({
  dispatchFindContractors: zipcode => dispatch(findContractors(zipcode)),
  dispatchChangeSoftPullValue: (key, value) => dispatch(changeSoftPullValue(key, value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchCriteria);
