import React from 'react';
import NumberFormat from 'react-number-format';
import { Col, Row } from 'reactstrap/';

const ApprovedWithInfo = ({ isApprovedWithInfo, applicantName, borrowedAmount }) => (
  <>
    <Row className="justify-content-center mb-3">
      <Col xs="auto">
        <h5 className="text-center">
          <b>
            Application Approved
            {isApprovedWithInfo && <span> with Additional Documents Needed</span>}!
          </b>
        </h5>
      </Col>
    </Row>
    <Row className="justify-content-center mb-3">
      <Col xs="auto" sm="11">
        <h5 className="text-center">
          The loan application
          {isApprovedWithInfo && <span> for {applicantName}</span>} has been approved for{' '}
          <NumberFormat
            value={borrowedAmount}
            thousandSeparator={true}
            fixedDecimalScale={true}
            decimalScale={2}
            prefix={'$'}
            displayType="text"
          />
          . The homeowner has also received notification of their approval.
        </h5>
      </Col>
    </Row>
  </>
);

export default ApprovedWithInfo;
