import React from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import ApplicationCategoryEnum from '../../../assets/js/ApplicationCategoryEnum';
import { getApplicationStatus } from '../../../assets/js/ApplicationStatusEnum';
import {
  getAppByIdAndSelectedFinancierForUserAdminRevisit,
  searchContractorUsers,
} from '../../../redux/actions';
import { formatDate } from '../../../util/dateFormatter';

const Application = props => {
  const {
    history,
    setPageIndex,
    application,
    contractorUsers,
    pageIndex,
    dispatchGetAppByIdAndSelectedFinancierForUserAdminRevisit,
  } = props;
  const pageLimit = 20;
  let pageCount = contractorUsers && contractorUsers.total / pageLimit;
  let appStatus = getApplicationStatus(application?.status);

  const handleClick = () => {
    dispatchGetAppByIdAndSelectedFinancierForUserAdminRevisit(
      application.selectedFinancier,
      application.id,
      history,
    );
  };

  const style = {
    width: ((pageIndex + 1) * 50).toString() + '%',
  };

  return (
    <>
      <Col>
        <h2 id="panelTitle">Application {application?.id}</h2>
        <Row noGutters>
          <Col xs="12" className="progress">
            <div className="active" style={style} />
          </Col>
        </Row>
        <Row noGutters className="panel">
          <Col className="panel-body larger">
            <Row noGutters>
              <div className="app-panel">
                <Row>
                  <Col sm="6" xs="12">
                    <div>{application.nameFirst + ' ' + application.nameLast}</div>
                    {application.coapplicantNameFirst ? (
                      <div>
                        {application.coapplicantNameFirst + ' ' + application.coapplicantNameLast}
                      </div>
                    ) : null}
                    <div>{application.street}</div>
                    <div>
                      {application.city + ', ' + application.state + ' ' + application.zipcode}
                    </div>
                    {application.phone ? (
                      application.phone.length > 6 ? (
                        <div>
                          {'(' +
                            application.phone.substring(0, 3) +
                            ') ' +
                            application.phone.substring(3, 6) +
                            '-' +
                            application.phone.substring(6)}
                        </div>
                      ) : (
                        <div>{application.phone}</div>
                      )
                    ) : null}
                    <div className="text-break">{application.email}</div>
                  </Col>
                  <Col sm="6" xs="12">
                    <Row>
                      {application.rtoNumber ? (
                        <Col xs="12" className="no-padding">
                          {application.rtoNumber}
                        </Col>
                      ) : null}
                      {application.totalPrice && (
                        <>
                          <Col xs="6" className="bold no-padding">
                            Total Job Price:
                          </Col>
                          <Col xs="6">
                            <NumberFormat
                              value={application.totalPrice}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                              decimalScale={2}
                              prefix={'$'}
                              displayType="text"
                            />
                          </Col>
                        </>
                      )}

                      {application.totalFinancing && (
                        <>
                          <Col xs="6" className="bold no-padding">
                            Total Loan or Lease Amount:
                          </Col>
                          <Col xs="6">
                            <NumberFormat
                              value={application.totalFinancing}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                              decimalScale={2}
                              prefix={'$'}
                              displayType="text"
                            />
                          </Col>
                        </>
                      )}
                      {application.contractorDiscount ? (
                        <>
                          <Col xs="6" className="bold no-padding">
                            Contractor Discount:
                          </Col>
                          <Col xs="6">{application.contractorDiscount + '%'}</Col>
                        </>
                      ) : null}
                      {application.selectedFinancier && (
                        <>
                          <Col xs="6" className="bold no-padding">
                            Selected financier:
                          </Col>
                          <Col xs="6">{application.selectedFinancier}</Col>
                        </>
                      )}

                      {application.systemType && (
                        <>
                          <Col xs="6" className="bold no-padding">
                            Lease Project Type:
                          </Col>
                          <Col xs="6">{application.systemType}</Col>
                        </>
                      )}
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Row>
                      <Col xs="6" sm="3" className="bold">
                        Status:
                      </Col>
                      <Col xs="6" sm="9">
                        {appStatus ? appStatus.description || appStatus.name : null}
                      </Col>
                    </Row>
                    {application.applicationStatus && (
                      <Row>
                        <Col xs="6" sm="3" className="bold">
                          Application Status:
                        </Col>
                        <Col xs="6" sm="9">
                          {application.applicationStatus}
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col xs="6" sm="3" className="bold">
                        Submitted:
                      </Col>
                      <Col xs="6" sm="9" className="text-break">
                        {formatDate(application.submitted)}
                      </Col>
                    </Row>
                    {appStatus && ApplicationCategoryEnum.OPEN === appStatus.category ? (
                      <>
                        <Row>
                          <Col xs="6" sm="3" className="bold">
                            First Payment:
                          </Col>
                          <Col xs="6" sm="9">
                            {application.firstPayment ? formatDate(application.firstPayment) : '-'}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="6" sm="3" className="bold">
                            Is Agreement Signed:
                          </Col>
                          <Col xs="6" sm="9">
                            {application.agreementSigned ? 'Yes' : 'No'}
                          </Col>
                        </Row>
                      </>
                    ) : null}
                  </Col>
                </Row>
              </div>
            </Row>
            <Row noGutters>
              <Col xs="11" className="text-right">
                <Button
                  type="submit"
                  color="secondary"
                  className="rounded-pill"
                  onClick={() => {
                    setPageIndex(0);
                  }}
                >
                  Previous
                </Button>
              </Col>
              <Col xs="1" className="text-right">
                <Button
                  type="submit"
                  color="primary"
                  className="rounded-pill"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  Revisit
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </>
  );
};

const mapStateToProps = state => ({
  dealerId: state.auth.dealerId,
  application: state.softPull.applicationForGDS,
  contractorUsers: state.softPull.contractorUsers,
  searchOptionUsers: state.softPull.searchOptionUsers,
});

const mapDispatchToProps = dispatch => ({
  dispatchGetAppByIdAndSelectedFinancierForUserAdminRevisit: (
    appSelectedFinancier,
    appId,
    history,
  ) =>
    dispatch(
      getAppByIdAndSelectedFinancierForUserAdminRevisit(appSelectedFinancier, appId, history),
    ),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Application),
);
