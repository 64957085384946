import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
import { initialState } from '../initialState';

const changeValue = (state, action) => {
  const updatedValues = {
    [action.key]: action.value,
  };
  return updateObject(state, updatedValues);
};

const enerBankReducer = (state, action) => {
  if (!state) {
    state = initialState.enerBankInformation;
  }
  switch (action.type) {
    case actionTypes.CHANGE_ENER_BANK_INFO_VALUE:
      return changeValue(state, action);
    case actionTypes.RESET_STATE_ENER_BANK:
      state = initialState.enerBankInformation;
      return state;
    default:
      return state;
  }
};

export default enerBankReducer;
