const DecisionReasonEnum = {
  // Validation
  POI_INFORMATION_NEEDED: { value: 'PI', category: 'APPLICANT', name: 'POI Information Needed' },
  POI_NEEDED: { value: 'PN', category: 'APPLICANT', name: 'POI Needed' },
  TOTAL_FINANCING_AMOUNT_INVALID: {
    value: 'AI',
    category: 'JOB',
    name: 'Total contract amount is less than minimum required',
  },
  DEALER_INVALID: { value: 'DI', category: 'FINAL', name: 'Invalid dealer' },
  DISTRIBUTOR_SALES_REP_INVALID: {
    value: 'DR',
    category: 'FINAL',
    name: 'Distributor sales representative is invalid',
  },
  DISTRIBUTOR_INVALID: { value: 'DB', category: 'FINAL', name: 'Invalid distributor' },
  APPLICANTS_MISSING: {
    value: 'AM',
    category: 'APPLICANT',
    name: 'The application does not contain a valid set of applicants',
  },
  APPLICANT_NAME_INVALID: { value: 'AN', category: 'APPLICANT', name: 'Applicant name invalid' },
  MONTHLY_NET_INCOME_INVALID: {
    value: 'MN',
    category: 'FINANCE',
    name: 'Invalid monthly net income',
  },
  RELATIONSHIP_TO_PRIMARY_INVALID: {
    value: 'RP',
    category: 'FINANCE',
    name: 'Relationship to primary applicant is invalid',
  },
  HVACS_MISSING: { value: 'HM', category: 'JOB', name: 'Need further information on HVAC type' },
  HVAC_TYPE_INVALID: {
    value: 'HT',
    category: 'JOB',
    name: 'Need further information on HVAC type',
  },
  HVAC_TONNAGE_INVALID: { value: 'TI', category: 'JOB', name: 'Invalid HVAC tonnage' },
  ADDRESS_INVALID: { value: 'AD', category: 'APPLICANT', name: 'Invalid address' },
  STATE_INVALID: { value: 'SI', category: 'APPLICANT', name: 'Outside of market area' },
  ZIP_CODE_INVALID: { value: 'ZI', category: 'APPLICANT', name: 'Invalid zip code' },
  CUSTOMER_DOES_NOT_HAVE_HOME_OWNERS_INSURANCE: {
    value: 'HI',
    category: 'APPLICANT',
    name: 'Customer does not have homeowners insurance',
  },
  STATE_NOT_PERMITTED_FOR_LEASES: {
    value: 'SN',
    category: 'APPLICANT',
    name:
      'Microf does not currently offer rental/lease-purchase options in the customers billing or property state and/or zip code.',
  },

  // Qualification

  // General qualification
  NO_RECORDS_FOUND: { value: 'RF', category: 'FINAL', name: 'No ABA information found' },
  NO_NEXIS_RECORD_FOUND: { value: 'NR', category: 'FINAL', name: 'Lexis Nexis record not found' },
  NO_DATAX_RECORD_FOUND: { value: 'XR', category: 'FINAL', name: 'DataX record not found' },
  NEXIS_FIELDS_MISSING: {
    value: 'NM',
    category: 'FINAL',
    name: 'Lexis Nexis field values were missing',
  },
  DATAX_FIELDS_MISSING: { value: 'DM', category: 'FINAL', name: 'DataX field values were missing' },

  // LexisNexis ID Verification
  NEXIS_SUBJECT_CONF_FAILED: {
    value: 'NS',
    category: 'FINAL',
    name: 'Lexis Nexis ID confirmation failed',
  },
  NEXIS_SSN_CONF_FAILED: { value: 'NX', category: 'APPLICANT', name: 'SSN confirmation failed' },
  NEXIS_SSN_DECEASED: { value: 'ND', category: 'FINAL', name: 'SSN deceased' },
  NEXIS_SSN_PROBLEMS: { value: 'NP', category: 'FINAL', name: 'SSN confirmation failed' },

  // DataX ID Verification
  DATAX_ID_VERIFICATION_FAILED: {
    value: 'XI',
    category: 'APPLICANT',
    name: 'ID verification failed',
  },
  DATAX_ACCOUNT_VERIFICATION_FAILED: {
    value: 'XV',
    category: 'APPLICANT',
    name: 'Bank account criteria not met',
  },
  DATAX_NO_DATA_FOR_ACCOUNT_VERIFICATION: {
    value: 'XD',
    category: 'FINAL',
    name: 'Checking account could not be verified',
  },
  DATAX_SSN_POSSIBLY_MISKEYED: {
    value: 'XM',
    category: 'APPLICANT',
    name: 'Applicant SSN/TIN may have been miskeyed',
  },
  DATAX_SSN_MULTIPLE_LAST_NAMES: {
    value: 'XL',
    category: 'APPLICANT',
    name: 'Applicant SSN is associated with multiple last names',
  },

  // LexiswNexis Home Owner Verification
  PROPERTY_OWNERS_MISSING: {
    value: 'OM',
    category: 'APPLICANT',
    name: 'No property information found',
  },
  NOT_ALL_OWNERS_ARE_APPLICANTS: {
    value: 'OA',
    category: 'APPLICANT',
    name: 'Not all owners listed on the deed are Applicants',
  },
  NUM_OF_APPLICANTS_SMALLER_NUM_OF_OWNERS: {
    value: 'AS',
    category: 'APPLICANT',
    name: 'Not all owners listed on the deed are Applicants',
  },
  NEXIS_OWNER_INFO_MISSING: {
    value: 'NI',
    category: 'FINANCE',
    name: 'Property owner information is missing',
  },
  NEXIS_ADDRESS_NOT_CONFIRMED: {
    value: 'NA',
    category: 'FINAL',
    name: 'Address could not be confirmed',
  },
  NEXIS_OWNER_NOT_CONFIRMED: {
    value: 'NO',
    category: 'FINAL',
    name: 'Property owner is not confirmed',
  },

  // LexisNexis Credit Check
  NEXIS_CHECK_FOR_HIGHER_SCORE: {
    value: 'NC',
    category: 'FINAL',
    name: 'Lexis Nexis custom score not met',
  },
  NEXIS_LENDING_SCORE_TOO_LOW: {
    value: 'NL',
    category: 'FINAL',
    name: 'Lexis Nexis custom score not met',
  },
  NEXIS_BANKRUPTCY_COUNT_TOO_HIGH: {
    value: 'NT',
    category: 'FINAL',
    name: 'Bankruptcy requirements not met.',
  },
  NEXIS_BANKRUPTCY_STILL_OPEN_AND_OTHER_FAILURE_CONDITIONS: {
    value: 'BF',
    category: 'FINAL',
    name: 'Bankruptcy requirements not met.',
  },
  NEXIS_BANKRUPTCY_STILL_OPEN_AND_WOULD_BE_DISMISSED: {
    value: 'BD',
    category: 'FINAL',
    name: 'Bankruptcy requirements not met.',
  },
  NEXIS_BANKRUPTCIES_IN_THE_LAST_24_MONTHS: {
    value: 'NB',
    category: 'FINAL',
    name: 'Bankruptcy requirements not met.',
  },
  NEXIS_BANKRUPTCY_TOO_RECENT_AND_TOTAL_DISMISSAL: {
    value: 'TR',
    category: 'FINAL',
    name: 'Bankruptcy requirements not met.',
  },

  // DataX Credit Check
  DATAX_CHECK_FOR_LOWER_SCORE: {
    value: 'DC',
    category: 'FINAL',
    name: 'DataX custom score not met',
  },
  DATAX_ABA_TOO_HIGH: { value: 'XA', category: 'FINAL', name: 'DataX custom ABA score not met' },
  DATAX_CHARGEOFF_TOO_HIGH: {
    value: 'XC',
    category: 'FINAL',
    name: 'DataX custom charge off score not met',
  },
  DATAX_SCORE_TOO_HIGH: { value: 'XS', category: 'FINAL', name: 'DataX custom score not met' },

  // Combined Credit Check
  DATAX_SCORE_TOO_HIGH_AND_NEXIS_SCORE_TOO_LOW: {
    value: 'DN',
    category: 'FINAL',
    name: 'DataX and Lexis Nexis custom scores not met',
  },

  // Financing
  FINANCED_AMOUNT_BELOW_MINIMUM: {
    value: 'FL',
    category: 'JOB',
    name: 'Contract amount is less than minimum required',
  },
  FINANCED_AMOUNT_REDUCED_TO_MEET_INCOME_REQ: {
    value: 'FI',
    category: 'FINANCE',
    name: 'Contract amount reduced to meet income requirement',
  },
  FINANCED_AMOUNT_REDUCED_TO_MAX_SYSTEM_PRICE: {
    value: 'FM',
    category: 'FINAL',
    name: 'Contract amount reduced to meet affordability',
  },
  FINANCED_AMOUNT_REDUCED_TO_MAX_SYSTEM_PRICE_NO_CREDIT_CHECK: {
    value: 'FN',
    category: 'FINANCE',
    name: 'Contract amount reduced to customer affordability',
  },
  DEFAULT_STATE_SALES_TAX_RATE_USED: {
    value: 'ST',
    category: 'FINAL',
    name: 'Default state sales tax rate used due to unknown zip code',
  },
  INVALID_HVAC_SYSTEM_CONFIGURATION: {
    value: 'IC',
    category: 'FINAL',
    name: 'Need further information on HVAC type',
  },

  //DataX
  DATAX_ACTIVE_DUTY: { value: 'AT', category: 'FINAL', name: 'DataX ActiveDuty is true' },
  DATAX_FRAUD: { value: 'AF', category: 'FINAL', name: 'DataX Fraud is true' },
  INITIAL_STATUS_DECLINE: { value: 'ID', category: 'FINAL', name: 'DataX Initial status decline' },
  INITIAL_STATUS_APPROVE: { value: 'IA', category: 'FINAL', name: 'DataX Initial status approve' },
  INITIAL_STATUS_MANUAL_REVIEW: {
    value: 'IM',
    category: 'FINAL',
    name: 'DataX Initial status manual review',
  },

  FICO_SCORE_OUT_OF_RANGE: { value: 'FR', category: 'FINAL', name: 'FICO Score Out of Range' },
  FAILED_BANKRUPTCY_POLICY: { value: 'FB', category: 'FINAL', name: 'Failed BK Policy' },
  REPORT_ERROR: { value: 'RE', category: 'FINAL', name: 'Bureau Connector or Report Error' },
};

export default DecisionReasonEnum;
