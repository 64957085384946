import 'core-js/stable';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import { render } from 'react-dom';
import 'regenerator-runtime/runtime';
import App from './pages/App';
import * as serviceWorker from './serviceWorker';

render(<App />, document.querySelector('#main'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
