import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import usStates from '../../../../assets/js/USStateEnum';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import Input from '../../../../components/UI/Form/Input/CustomInput';
import Select from '../../../../components/UI/Form/Select/Select';
import {
  COAPPCITY_TOOLTIP_TEXT,
  COAPPSTATE_TOOLTIP_TEXT,
  COAPPSTREET_TOOLTIP_TEXT,
  COAPPUNITNUMBER_TOOLTIP_TEXT,
  COAPPZIPCODE_TOOLTIP_TEXT,
} from '../../../../constants/tooltipTexts';
import { changeApplicationValues, reactGAEvent, reactGAPageview } from '../../../../helper';
import {
  changePage,
  submitMindfire,
  changeGreenSkyApplicationInfoValue,
} from '../../../../redux/actions';
import Disclosure from '../Components/Disclosure';
import { AtWaterGreenSkyFlowPageNumber } from './GreenSkyFlow';

const CoApplicantAddress = props => {
  const {
    greenSkyApplicationInformation,
    dispatchChangeGreenSkyApplicationInfoValue,
    pqi,
    dispatchChangePage,
    children,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_gscoappinfo2',
      trackerNames: [],
      title: 'ca_gscoappinfo2',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const setIsSameAddress = bool => {
    dispatchChangeGreenSkyApplicationInfoValue('isSameAddress', bool);
  };

  const CoApplicantAddressForm = () => (
    <Form>
      <Label>
        <b>The Primary Address is the same as the applicant’s.</b>
      </Label>
      <Row>
        <Col sm="6">
          <Button
            className={greenSkyApplicationInformation.isSameAddress ? '' : 'inactive'}
            type="button"
            title="Yes"
            clickHandler={() => setIsSameAddress(true)}
          />
        </Col>
        <Col sm="6">
          <Button
            className={!greenSkyApplicationInformation.isSameAddress ? '' : 'inactive'}
            type="button"
            title="No"
            clickHandler={() => setIsSameAddress(false)}
          />
        </Col>
      </Row>
      <Field
        disabled={greenSkyApplicationInformation.isSameAddress}
        component={Input}
        label="Street"
        name="coAppStreet"
        id="coAppStreet"
        tooltipText={COAPPSTREET_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={Input}
        label="Unit/Apt. Number"
        name="coAppApartmentNumber"
        id="coAppApartmentNumber"
        tooltipText={COAPPUNITNUMBER_TOOLTIP_TEXT}
        type="text"
      />
      <Row>
        <Col sm="6">
          <Field
            disabled={greenSkyApplicationInformation.isSameAddress}
            component={Input}
            label="City"
            name="coAppCity"
            id="coAppCity"
            tooltipText={COAPPCITY_TOOLTIP_TEXT}
            type="text"
          />
        </Col>
        <Col sm="6">
          <Field
            disabled={greenSkyApplicationInformation.isSameAddress}
            component={Select}
            label="State"
            name="coAppState"
            id="coAppState"
            tooltipText={COAPPSTATE_TOOLTIP_TEXT}
            type="select"
            selectValues={usStates}
            selectFirstName="Select State"
          />
        </Col>
      </Row>
      <Field
        disabled={greenSkyApplicationInformation.isSameAddress}
        component={Input}
        label="ZIP Code"
        name="coAppZipCode"
        id="coAppZipCode"
        tooltipText={COAPPZIPCODE_TOOLTIP_TEXT}
        type="text"
        maxLength={10}
      />

      <Button type="submit" title="Next" />
      <Disclosure />
    </Form>
  );

  const CoApplicantAddressFormFormik = withFormik({
    mapPropsToValues: () => ({
      coAppApartmentNumber:
        greenSkyApplicationInformation.isSameAddress &&
        greenSkyApplicationInformation.primaryAddressApartmentNumber
          ? greenSkyApplicationInformation.primaryAddressApartmentNumber
          : greenSkyApplicationInformation.coAppApartmentNumber || '',
      coAppStreet:
        greenSkyApplicationInformation.isSameAddress &&
        greenSkyApplicationInformation.primaryAddressStreet
          ? greenSkyApplicationInformation.primaryAddressStreet
          : greenSkyApplicationInformation.coAppStreet || '',
      coAppCity:
        greenSkyApplicationInformation.isSameAddress &&
        greenSkyApplicationInformation.primaryAddressCity
          ? greenSkyApplicationInformation.primaryAddressCity
          : greenSkyApplicationInformation.coAppCity || '',
      coAppZipCode:
        greenSkyApplicationInformation.isSameAddress &&
        greenSkyApplicationInformation.primaryAddressZipCode
          ? greenSkyApplicationInformation.primaryAddressZipCode
          : greenSkyApplicationInformation.coAppZipCode || '',
      coAppState:
        greenSkyApplicationInformation.isSameAddress &&
        greenSkyApplicationInformation.primaryAddressState
          ? greenSkyApplicationInformation.primaryAddressState
          : greenSkyApplicationInformation.coAppState || '',
    }),

    validationSchema: Yup.object({
      coAppApartmentNumber: Yup.string(),
      coAppStreet: Yup.string()
        .matches(/.*([a-zA-Z].*[0-9]|[0-9].*[a-zA-Z]).*/, {
          message: 'The street field must contain the street name and number',
          excludeEmptyString: true,
        })
        .required('Please enter a street.'),
      coAppCity: Yup.string().required('Please enter a city.'),
      coAppZipCode: Yup.string()
        .matches(/^\d{5}?$/, {
          message: 'Invalid zip code.',
          excludeEmptyString: true,
        })
        .required('Please enter a zip code.'),
      coAppState: Yup.string().required('Please select a state.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      changeApplicationValues(dispatchChangeGreenSkyApplicationInfoValue, values);
      dispatchChangePage(AtWaterGreenSkyFlowPageNumber.AdditionalCoApplicantInformation);
      dispatchSubmitMindfire({
        email: pqi.email,
        lastPage: '9018',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(CoApplicantAddressForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={AtWaterGreenSkyFlowPageNumber.CoApplicantName}
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <CoApplicantAddressFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  pqi: state.preQualificationInformation,
  greenSkyApplicationInformation: state.greenSkyApplicationInformation,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeGreenSkyApplicationInfoValue: (key, value) =>
    dispatch(changeGreenSkyApplicationInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

CoApplicantAddress.propTypes = {
  dispatchChangePage: PropTypes.func,
  greenSkyApplicationInformation: PropTypes.object,
  dispatchChangeGreenSkyApplicationInfoValue: PropTypes.func,
  pqi: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CoApplicantAddress);
