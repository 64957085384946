import React from 'react';
import NumberFormat from 'react-number-format';
import { Col, Row } from 'reactstrap';
import './EnerBankLoanOption.scss';
import { connect } from 'react-redux';

const EnerBankLoanOption = ({ loanOption, isStatusUndecision }) => {
  return (
    <>
      <Row className="review">
        <Col>
          <h5 className="bold">{loanOption.numberOfMonths}-Mo Same-As-Cash Loan</h5>
        </Col>
      </Row>
      <Row className="review">
        <Col>
          <Row>
            <Col xs="6">
              <b>Loan Amount</b>
              <NumberFormat
                value={loanOption.loanAmount}
                thousandSeparator={true}
                fixedDecimalScale={true}
                decimalScale={2}
                displayType="text"
                prefix="$"
              />
            </Col>
            {isStatusUndecision ? (
              <Col xs="6">
                <b>Application Number</b>
                <span>{loanOption.referenceNumber}</span>
              </Col>
            ) : (
              <Col xs="6">
                <b>APR</b>
                <NumberFormat
                  value={loanOption.estimatedApr}
                  thousandSeparator={true}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  displayType="text"
                />
                %
              </Col>
            )}
          </Row>
          {!isStatusUndecision && (
            <>
              <Row className="values">
                <Col xs="6">
                  <b>Loan Term</b>
                  <span>{loanOption.numberOfMonths} months</span>
                </Col>
                <Col xs="6">
                  <b>Application Number</b>
                  <span>{loanOption.referenceNumber}</span>
                </Col>
              </Row>
              <Row className="values">
                <Col xs="">
                  <b>Estimated Monthly Payment</b>
                  <NumberFormat
                    value={loanOption.estimatedMonthlyPayment}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    displayType="text"
                    prefix="$"
                  />
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      {!isStatusUndecision && (
        <Row className="mt-3">
          <Col>
            <p>{loanOption.approvalText}</p>
            <p>{loanOption.congratulatoryText}</p>
          </Col>
        </Row>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  isConsumer: state.preQualificationInformation.isConsumer,
});

export default connect(mapStateToProps)(EnerBankLoanOption);
