import React, { useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { searchContractors } from '../../../redux/actions';
import ContractorsSearch from '../ManageContractorStates/ContractorsSearch';
import ContractorZipCodesTable from './ContractorZipCodesTable';

const ManageContractorZipCodes = props => {
  const pageLimit = 20;
  const {
    auth,
    history,
    dealerSearchCriteriaResult,
    searchOptionContractors,
    dispatchSearchContractors,
  } = props;
  const pageCount = dealerSearchCriteriaResult && dealerSearchCriteriaResult.total / pageLimit;

  useEffect(() => {
    if (!auth.user && (!auth.user.dealerUser || !auth.user.internalUser)) {
      history.push('/login');
    }
    // eslint-disable-next-line
  }, []);

  const handlePageClick = pageNumber => {
    const search = {
      ...searchOptionContractors,
      pageOffset: pageNumber.selected * pageLimit,
    };
    dispatchSearchContractors(search);
  };

  return (
    <Col>
      <Row className="mt-5">
        <h1 id="panelTitle">Manage Contractor Zipcodes</h1>
        <ContractorsSearch
          searchContractors={dispatchSearchContractors}
          searchOptionContractors={searchOptionContractors}
        />
        <div className="panel">
          <div className="panel-body larger">
            <Row>
              <Col className="right-bordered">
                {dealerSearchCriteriaResult && dealerSearchCriteriaResult.total > 0 ? (
                  <>
                    <Row className="overflow-auto">
                      <ContractorZipCodesTable
                        dealerSearchCriteriaResult={dealerSearchCriteriaResult}
                        history={history}
                      />
                    </Row>
                    {pageCount > 1 && (
                      <Row className="justify-content-between align-items-center">
                        <ReactPaginate
                          previousLabel={'<'}
                          nextLabel={'>'}
                          breakClassName={'break-me'}
                          breakLinkClassName={'page-link'}
                          breakLabel={'...'}
                          pageCount={pageCount}
                          pageLimitDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageClick}
                          containerClassName={'pagination'}
                          subContainerClassName={'pages pagination'}
                          pageClassName={'page-item'}
                          pageLinkClassName={'page-link'}
                          activeClassName={'active'}
                          nextClassName={'page-item'}
                          nextLinkClassName={'page-link'}
                          previousClassName={'page-item'}
                          previousLinkClassName={'page-link'}
                        />
                      </Row>
                    )}
                  </>
                ) : (
                  <>No users found.</>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Row>
    </Col>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  dealerSearchCriteriaResult: state.softPull.dealerSearchCriteriaResult,
  searchOptionContractors: state.softPull.searchOptionContractors,
});

const mapDispatchToProps = dispatch => ({
  dispatchSearchContractors: searchOptions => dispatch(searchContractors(searchOptions)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ManageContractorZipCodes),
);
