import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import AirConditioningSystemEnum from '../../assets/js/AirConditioningSystemEnum';
import BackBtn from '../../components/UI/BackButton/BackButton';
import Button from '../../components/UI/Button/CustomButton';
import CurrencyFormat from '../../components/UI/Form/NumberFormat/CurrencyFormat';
import { GROSS_ANNUAL_INCOME_TOOLTIP_TEXT } from '../../constants/tooltipTexts';
import { reactGAEvent, reactGAPageview } from '../../helper';
import { changePage, submitMindfire, changeRheemInfoValue } from '../../redux/actions';
import { RheemFlowPageNumber } from './RheemFlow';

const GrossAnnualIncome = props => {
  const {
    email,
    appGrossAnnualIncome,
    dispatchChangePage,
    dispatchChangeRheemInfoValue,
    children,
    dispatchSubmitMindfire,
    airConditioningSystem,
    did,
    dealerUserId,
    isReview,
  } = props;

  useEffect(() => {
    reactGAPageview({
      //todoRheem: change ga calls
      path: '/ca_prequalamount',
      trackerNames: [],
      title: 'ca_prequalamount',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const GrossAnnualIncomeForm = () => (
    <Form>
      <Field
        component={CurrencyFormat}
        label="Enter Amount"
        name="appGrossAnnualIncome"
        id="appGrossAnnualIncome"
        tooltipText={GROSS_ANNUAL_INCOME_TOOLTIP_TEXT}
      />
      <Button type="submit" title={'Next'} />
    </Form>
  );

  const GrossAnnualIncomeFormFormik = withFormik({
    mapPropsToValues: () => ({
      appGrossAnnualIncome: appGrossAnnualIncome || '',
    }),

    validationSchema: Yup.object({
      appGrossAnnualIncome: Yup.number()
        .moreThan(1499, `Amount should be at least $1500.`)
        .required('Please enter an amount.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      dispatchChangeRheemInfoValue('appGrossAnnualIncome', values.appGrossAnnualIncome);
      dispatchChangePage(
        isReview
          ? RheemFlowPageNumber.IdentificationInformation
          : RheemFlowPageNumber.HasCoApplicant,
      );
      dispatchSubmitMindfire({
        email: email,
        appGrossAnnualIncome: values.appGrossAnnualIncome,
        lastPage:
          airConditioningSystem === AirConditioningSystemEnum.AIR_CONDITION_OR_HEATING_SYSTEM.id
            ? '0013'
            : '0015',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(GrossAnnualIncomeForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={isReview ? RheemFlowPageNumber.Review : RheemFlowPageNumber.LoanAmount}
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <GrossAnnualIncomeFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  isReview: state.rheemInformation.isReview,
  appGrossAnnualIncome: state.rheemInformation.appGrossAnnualIncome,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeRheemInfoValue: (key, value) => dispatch(changeRheemInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

GrossAnnualIncome.propTypes = {
  appGrossAnnualIncome: PropTypes.number,
  dispatchChangePage: PropTypes.func,
  dispatchChangeRheemInfoValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GrossAnnualIncome);
