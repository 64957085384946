import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Field, Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import CustomInput from '../../components/UI/Form/Input/CustomInput';
import Button from '../../components/UI/Button/CustomButton';
import CustomPhoneNumber from '../../components/UI/Form/PhoneNumber/PhoneNumber';
import BackBtn from '../../components/UI/BackButton/BackButton';
import Select from '../../components/UI/Form/Select/Select';
import SelectList from '../../components/UI/Form/Select/SelectList';
import {
  changePage,
  changeContractorEnrollmentValue,
  submitMindfire,
  endLoading,
  applyToBeDealer,
} from '../../redux/actions/index';
import usStates from '../../assets/js/USStateEnum';
import { withRouter } from 'react-router-dom';
import { ContractorEnrollmentFlowPageNumber } from './_Flow';
import {
  MANAGER_CITY_TOOLTIP_TEXT,
  MANAGER_EMAIL_TOOLTIP_TEXT,
  MANAGER_FIRST_NAME_TOOLTIP_TEXT,
  MANAGER_LAST_NAME_TOOLTIP_TEXT,
  MANAGER_OWNERSHIP_TOOLTIP_TEXT,
  MANAGER_PHONE_TOOLTIP_TEXT,
  MANAGER_SSN_TOOLTIP_TEXT,
  MANAGER_STATE_TOOLTIP_TEXT,
  MANAGER_ZIP_CODE_TOOLTIP_TEXT,
} from '../../constants/tooltipTexts';
import { isNoreplyEmail } from '../../helper';

const BusinessManager = props => {
  const {
    ce,
    dispatchChangeValue,
    dispatchChangePage,
    children,
    dispatchApplyToBeDealer,
    history,
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  const BusinessManagerForm = ({ values, errors, touched }) => (
    <Form autoComplete="off">
      <h3>Business Manager Information</h3>
      <p>
        <b>The individual with the responsibility to control, manage or direct the company</b>
      </p>
      <Field
        component={CustomInput}
        label="Last Name *"
        name="businessManagerLastName"
        id="businessManagerLastName"
        tooltipText={MANAGER_LAST_NAME_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={CustomInput}
        label="First Name *"
        name="businessManagerFirstName"
        id="businessManagerFirstName"
        tooltipText={MANAGER_FIRST_NAME_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={CustomPhoneNumber}
        label="Phone *"
        name="businessManagerPhone"
        id="businessManagerPhone"
        tooltipText={MANAGER_PHONE_TOOLTIP_TEXT}
      />
      <Field
        component={CustomInput}
        label="Email *"
        name="businessManagerEmail"
        id="businessManagerEmail"
        type="email"
      />
      <Field
        component={CustomInput}
        label="Street Address (Home) *"
        name="businessManagerStreet"
        id="businessManagerStreet"
        tooltipText={MANAGER_EMAIL_TOOLTIP_TEXT}
        type="text"
      />
      <Row>
        <Col sm="6">
          <Field
            component={CustomInput}
            label="City (Home) *"
            name="businessManagerCity"
            id="businessManagerCity"
            tooltipText={MANAGER_CITY_TOOLTIP_TEXT}
            type="text"
          />
        </Col>
        <Col sm="6">
          <Field
            component={CustomInput}
            label="ZIP Code (Home) *"
            name="businessManagerZIPCode"
            id="businessManagerZIPCode"
            tooltipText={MANAGER_ZIP_CODE_TOOLTIP_TEXT}
            type="text"
            maxLength={10}
          />
        </Col>
      </Row>
      <Field
        component={Select}
        label="State (Home) *"
        name="businessManagerState"
        id="businessManagerState"
        tooltipText={MANAGER_STATE_TOOLTIP_TEXT}
        type="select"
        selectValues={usStates}
        selectFirstName="Select State"
      />
      <Field
        maxLength={9}
        component={CustomInput}
        label="SSN *"
        id="businessManagerSSN"
        name="businessManagerSSN"
        tooltipText={MANAGER_SSN_TOOLTIP_TEXT}
        type="password"
        number
        icon="lock"
      />
      {ce.applyForAquaProgram ||
      ce.applyForPowerPayProgram ||
      ce.applyForMosaicProgram ||
      ce.applyForEnerBankProgram ||
      ce.applyForGreenSkyProgram ||
      ce.applyForFoundationProgram ||
      ce.applyForFTLProgram ? (
        <>
          <h3 className="top-20">Beneficial Owners</h3>
          <Field
            component={SelectList}
            label="Are there owners that have 25% or greater ownership in your company?"
            name="businessManagerOwnership"
            id="businessManagerOwnership"
            tooltipText={MANAGER_OWNERSHIP_TOOLTIP_TEXT}
            type="select"
            selectValues={['Yes', 'No']}
            selectFirstName="Select One"
          />
        </>
      ) : null}
      <Button type="submit" title="Next" />
    </Form>
  );

  const BusinessManagerFormFormik = withFormik({
    mapPropsToValues: () => ({
      businessManagerLastName: ce.businessManagerLastName || '',
      businessManagerFirstName: ce.businessManagerFirstName || '',
      businessManagerPhone: ce.businessManagerPhone || '',
      businessManagerEmail: ce.businessManagerEmail || '',
      businessManagerStreet: ce.businessManagerStreet || '',
      businessManagerCity: ce.businessManagerCity || '',
      businessManagerZIPCode: ce.businessManagerZIPCode || '',
      businessManagerState: ce.businessManagerState || '',
      businessManagerSSN: ce.businessManagerSSN || '',
      businessManagerOwnership: ce.businessManagerOwnership || '',
    }),

    validationSchema: Yup.object({
      businessManagerLastName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .required('Please enter Last Name.'),
      businessManagerFirstName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .required('Please enter First Name.'),
      businessManagerPhone: Yup.string()
        .test('required', 'Please enter Phone.', val => val !== '+' && val)
        .test(
          'len',
          'Invalid Phone number.',
          val => val && val.match(/\d/g).join('').length === 10,
        ),
      businessManagerEmail: Yup.string()
        .email('Please enter a valid Email.')
        .required('Please enter an Email.')
        .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),

      businessManagerStreet: Yup.string().required('Please enter a Street.'),
      businessManagerCity: Yup.string().required('Please enter a City.'),
      businessManagerZIPCode: Yup.string()
        .matches(/^\d{5}?$/, {
          message: 'Invalid ZIP Code.',
          excludeEmptyString: true,
        })
        .required('Please enter a Property ZIP Code.'),
      businessManagerState: Yup.string().required('Please select a State.'),

      businessManagerSSN: Yup.string()
        .required('Please enter the full SSN.')
        .test('len', 'Must have 9 digits', val => val && val.toString().length === 9)
        .typeError('Only numbers allowed.'),
      businessManagerOwnership:
        ce.applyForAquaProgram ||
        ce.applyForPowerPayProgram ||
        ce.applyForMosaicProgram ||
        ce.applyForEnerBankProgram ||
        ce.applyForGreenSkyProgram ||
        ce.applyForFoundationProgram ||
        ce.applyForFTLProgram
          ? Yup.string().required('Please select ownership.')
          : null,
    }),

    handleSubmit: values => {
      dispatchChangeValue('businessManagerLastName', values.businessManagerLastName);
      dispatchChangeValue('businessManagerFirstName', values.businessManagerFirstName);
      dispatchChangeValue('businessManagerPhone', values.businessManagerPhone);
      dispatchChangeValue('businessManagerEmail', values.businessManagerEmail);
      dispatchChangeValue('businessManagerStreet', values.businessManagerStreet);
      dispatchChangeValue('businessManagerCity', values.businessManagerCity);
      dispatchChangeValue('businessManagerZIPCode', values.businessManagerZIPCode);
      dispatchChangeValue('businessManagerState', values.businessManagerState);
      dispatchChangeValue('businessManagerSSN', values.businessManagerSSN);
      dispatchChangeValue(
        'businessManagerOwnership',
        ce.applyForAquaProgram ||
          ce.applyForPowerPayProgram ||
          ce.applyForMosaicProgram ||
          ce.applyForEnerBankProgram ||
          ce.applyForGreenSkyProgram ||
          ce.applyForFoundationProgram ||
          ce.applyForFTLProgram
          ? values.businessManagerOwnership
          : null,
      );
      if (
        !ce.applyForAquaProgram &&
        !ce.applyForPowerPayProgram &&
        !ce.applyForMosaicProgram &&
        ce.applyForEnerBankProgram &&
        ce.applyForGreenSkyProgram &&
        ce.applyForFoundationProgram &&
        ce.applyForFTLProgram
      ) {
        dispatchApplyToBeDealer(ContractorEnrollmentFlowPageNumber.FinalMessage, history);
      } else {
        dispatchChangePage(ContractorEnrollmentFlowPageNumber.BeneficialOwnerInformation);
      }
    },
  })(BusinessManagerForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={ContractorEnrollmentFlowPageNumber.DealershipInformation}
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <BusinessManagerFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  ce: state.contractorEnrollment,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) => dispatch(changeContractorEnrollmentValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchEndLoading: () => dispatch(endLoading()),
  dispatchApplyToBeDealer: (page, history) => dispatch(applyToBeDealer(false, page, history)),
});

BusinessManager.propTypes = {
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
  dispatchSubmitSoftPullAquaApplication: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(BusinessManager),
);
