import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import { useVSPixelScript } from '../../../assets/hooks';
import relationshipEnum from '../../../assets/js/RelationshipEnum';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import CustomInput from '../../../components/UI/Form/Input/CustomInput';
import CustomPhoneNumber from '../../../components/UI/Form/PhoneNumber/PhoneNumber';
import Select from '../../../components/UI/Form/Select/Select';
import {
  COAPPEMAIL_TOOLTIP_TEXT,
  COAPPFIRSTNAME_TOOLTIP_TEXT,
  COAPPLASTNAME_TOOLTIP_TEXT,
  COAPPMIDDLEINITIAL_TOOLTIP_TEXT,
  COAPPPHONENUMBER_TOOLTIP_TEXT,
  COAPP_RELATIONSHIP_TOOLTIP_TEXT,
} from '../../../constants/tooltipTexts';
import { isNoreplyEmail, reactGAEvent, reactGAPageview } from '../../../helper';
import { changeAquaInfoValue, changePage, submitMindfire } from '../../../redux/actions/index';
import { AquaFlowPageNumber } from '../AquaFlow';

const CoAppDetails = props => {
  const {
    email,
    dispatchChangePage,
    dispatchChangeValue,
    aquaInformation,
    children,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
  } = props;

  useVSPixelScript();

  useEffect(() => {
    reactGAPageview({
      path: '/ca_aqcoappinfo1',
      trackerNames: [],
      title: 'ca_aqcoappinfo1',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const CoAppDetailsForm = () => (
    <Form>
      <Field
        component={Select}
        label="Your relationship with the Applicant?"
        name="appCoAppRelationship"
        id="appCoAppRelationship"
        tooltipText={COAPP_RELATIONSHIP_TOOLTIP_TEXT}
        type="select"
        selectValues={relationshipEnum}
        selectFirstName=""
      />
      <Field
        component={CustomInput}
        label="Co-Applicant's First Name"
        name="coAppFirstName"
        id="coAppFirstName"
        tooltipText={COAPPFIRSTNAME_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={CustomInput}
        label="Co-Applicant's Middle Initial"
        name="coAppMiddleInitial"
        id="coAppMiddleInitial"
        tooltipText={COAPPMIDDLEINITIAL_TOOLTIP_TEXT}
        type="text"
        maxLength={2}
      />
      <Field
        component={CustomInput}
        label="Co-Applicant's Last Name"
        name="coAppLastName"
        id="coAppLastName"
        tooltipText={COAPPLASTNAME_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={CustomInput}
        label="Co-Applicant's Email Address"
        name="coAppEmail"
        id="coAppEmail"
        tooltipText={COAPPEMAIL_TOOLTIP_TEXT}
        type="email"
      />
      <p className="small-text">
        Please enter the email address for the co-applicant (we recommend a different email address
        than the primary applicant's email address). If you need to create an email address,{' '}
        <a
          href="https://edu.gcfglobal.org/en/gmail/setting-up-a-gmail-account/1/"
          target="_blank"
          rel="noopener noreferrer"
        >
          click here
        </a>{' '}
        for instructions.
      </p>
      <Field
        component={CustomPhoneNumber}
        label="Phone Number (Mobile Preferred)"
        name="coAppPhoneNumber"
        id="coAppPhoneNumber"
        tooltipText={COAPPPHONENUMBER_TOOLTIP_TEXT}
      />
      <Button type="submit" title="Next" />
    </Form>
  );

  const CoAppDetailsFormFormik = withFormik({
    mapPropsToValues: () => ({
      appCoAppRelationship: aquaInformation.appCoAppRelationship || '',
      coAppFirstName: aquaInformation.coAppFirstName || '',
      coAppMiddleInitial: aquaInformation.coAppMiddleInitial || '',
      coAppLastName: aquaInformation.coAppLastName || '',
      coAppEmail: aquaInformation.coAppEmail || '',
      coAppPhoneNumber: aquaInformation.coAppPhoneNumber || '',
    }),

    validationSchema: Yup.object({
      appCoAppRelationship: Yup.string().required(
        'Please select the relationship with the Co-Applicant.',
      ),
      coAppFirstName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .required("Please enter the Co-Applicant's first name."),
      coAppLastName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .required("Please enter the Co-Applicant's last name."),
      coAppEmail: Yup.string()
        .email('Please enter a valid email.')
        .required('Please enter an email.')
        .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),
      coAppPhoneNumber: Yup.string()
        .test(
          'required',
          "Please enter the Co-Applicant's phone number.",
          val => val !== '+' && val,
        )
        .test('len', 'Invalid phone number', val => val && val.match(/\d/g).join('').length === 10),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      dispatchChangeValue('appCoAppRelationship', values.appCoAppRelationship);
      dispatchChangeValue('coAppFirstName', values.coAppFirstName.trim());
      dispatchChangeValue('coAppMiddleInitial', values.coAppMiddleInitial.trim());
      dispatchChangeValue('coAppLastName', values.coAppLastName.trim());
      dispatchChangeValue('coAppEmail', values.coAppEmail);
      dispatchChangeValue('coAppPhoneNumber', values.coAppPhoneNumber.match(/\d/g).join(''));
      dispatchChangePage(AquaFlowPageNumber.CoAppEmploymentDetails);
      dispatchSubmitMindfire({
        email: email,
        lastPage: '0046',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(CoAppDetailsForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={
            aquaInformation.isReview
              ? AquaFlowPageNumber.HasCoApplicant
              : AquaFlowPageNumber.AppOtherIncome
          }
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <CoAppDetailsFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  aquaInformation: state.aquaInformation,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) => dispatch(changeAquaInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

CoAppDetails.propTypes = {
  aquaInformation: PropTypes.object,
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CoAppDetails);
