import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Container, Row } from 'reactstrap';
import logo_sf_call from '../../../../assets/img/logo-sf-call.png';
import * as Yup from 'yup';
import Checkbox from '../../../../components/UI/Form/Checkbox/Checkbox';
import {
  changePage,
  changePreQualInfoValue,
  changeSoftPullValue,
  setFlow,
} from '../../../../redux/actions';
import MicrofWorkingHours from '../../../Microf/MicrofWorkingHours';
import { FoundationPreQualificationFlowPageNumber } from './PreQualificationFlow';
import { initialState, microfFlow } from '../../../../redux/initialState';
import { withRouter } from 'react-router-dom';
import { isStateAllowed } from '../../../../helper';
import AirConditioningSystemEnum from '../../../../assets/js/AirConditioningSystemEnum';

const InvalidResponse = ({
  dispatchChangePage,
  dispatchChangeSoftPullValue,
  dispatchChangeValue,
  verifyAddressChecked,
  continueChecked,
  history,
  dispatchSetFlow,
  softPullResponse,
  pqi,
}) => {
  useEffect(() => {
    dispatchChangeValue('verifyAddressChecked', false);
    dispatchChangeValue('continueChecked', false);
  }, []);

  const isRtoNotAvailable =
    !isStateAllowed(pqi?.state) ||
    softPullResponse?.airConditioningSystem !==
      AirConditioningSystemEnum.AIR_CONDITION_OR_HEATING_SYSTEM.id;

  const checkedVerifyAddress = values => {
    dispatchChangeValue('verifyAddressChecked', !values.verifyAddress);
    dispatchChangeValue('continueChecked', false);
  };

  const checkedContinue = values => {
    dispatchChangeValue('verifyAddressChecked', false);
    dispatchChangeValue('continueChecked', !values.continue);
  };

  const InvalidResponseForm = ({ values }) => (
    <Form>
      <Row className="justify-content-center">
        <Col xs="auto" lg="8" sm="12" md="12">
          <Field
            component={Checkbox}
            name="verifyAddress"
            id="verifyAddress"
            clickHandler={() => checkedVerifyAddress(values)}
            label={`I want to re-enter my Address (including zip code), Date of Birth, and last 4-digits of my Social Security Number after removing any freeze or alert on my Equifax consumer report and proceed with a pre-qualification for the best available offer.`}
          />
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col xs="auto" lg="8" sm="12" md="12">
          <Field
            component={Checkbox}
            name="continue"
            id="continue"
            clickHandler={() => checkedContinue(values)}
            label={`I want to proceed to Microf Lease Offer without a pre-qualification.`}
          />
        </Col>
      </Row>
      <Row className="mt-3 justify-content-center">
        <Button type="submit" title="Continue" className="w-300px margin-center" color="primary">
          Continue
        </Button>
      </Row>
    </Form>
  );

  const InvalidResponseFormFormik = withFormik({
    mapPropsToValues: () => ({
      verifyAddress: verifyAddressChecked,
      continue: continueChecked,
    }),

    validationSchema: Yup.object({
      verifyAddress:
        verifyAddressChecked || continueChecked
          ? Yup.boolean()
          : Yup.boolean().oneOf([true], 'Please check to continue.'),
      continue:
        verifyAddressChecked || continueChecked
          ? Yup.boolean()
          : Yup.boolean().oneOf([true], 'Please check to continue.'),
    }),

    handleSubmit: () => {
      if (verifyAddressChecked) {
        dispatchChangeSoftPullValue('softPullResponse', initialState.softPull.softPullResponse);
        dispatchChangePage(FoundationPreQualificationFlowPageNumber.UserInfo);
      }
      if (continueChecked) {
        dispatchSetFlow(microfFlow);
        history.push('/RTO');
      }
    },
  })(InvalidResponseForm);

  return (
    <Container>
      <Row className="justify-content-center mt-2">
        <Col className="text-center" xs="auto" lg="12" sm="12">
          <p className="fs-32">
            <b>An immediate pre-qualification decision is not available.</b>
            <br />
            {!isRtoNotAvailable ? (
              <b>Check the appropriate box below and then click Continue.</b>
            ) : null}
          </p>
        </Col>
      </Row>
      {isRtoNotAvailable ? (
        <>
          <Row className="justify-content-center mt-5">
            <Col xs="auto" lg="8" sm="12" md="12">
              <p className="fs-20 fw-500">
                Verify and re-enter your correct Address (including zip code), Date of Birth, and
                last 4-digits of your Social Security Number after removing any freeze or alert on
                your Equifax consumer report.
              </p>
            </Col>
          </Row>
          <Row className="mt-5 justify-content-center">
            <Button
              title="Continue"
              className="w-300px margin-center"
              color="primary"
              onClick={() => {
                dispatchChangeSoftPullValue(
                  'softPullResponse',
                  initialState.softPull.softPullResponse,
                );
                dispatchChangeValue('verifyAddressChecked', true);
                dispatchChangePage(FoundationPreQualificationFlowPageNumber.UserInfo);
              }}
            >
              Continue
            </Button>
          </Row>
        </>
      ) : (
        <InvalidResponseFormFormik />
      )}
      <Row className="justify-content-center mt-5">
        <Col className="text-center" xs="auto" lg="8" sm="12" md="12">
          <p className="fs-22 fw-500">Please contact Optimus if further assistance is needed.</p>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <a href="tel:833-678-1687">
            <img src={logo_sf_call} alt="Call" className="logo-sf-call-small" />
          </a>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4 className="text-center fs-16">
            Call here
            <br />
            or
            <br /> Dial{' '}
            <a href="tel:833-678-1687" className="sf-tel">
              <u>833-678-1687</u>
            </a>{' '}
            to speak to
            <br /> a representative.
          </h4>
        </Col>
      </Row>

      <MicrofWorkingHours />
    </Container>
  );
};

const mapStateToProps = state => ({
  verifyAddressChecked: state.preQualificationInformation.verifyAddressChecked,
  continueChecked: state.preQualificationInformation.continueChecked,
  softPullResponse: state.softPull.softPullResponse,
  pqi: state.preQualificationInformation,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangeSoftPullValue: (key, value) => dispatch(changeSoftPullValue(key, value)),
  dispatchChangeValue: (key, value) => dispatch(changePreQualInfoValue(key, value)),
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchSetFlow: flowName => dispatch(setFlow(flowName)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(InvalidResponse),
);
