import { Field, Form, withFormik } from 'formik';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Button, Col, Row } from 'reactstrap';
import { ftlSelectProgram } from '../../../../redux/actions';
import FtlRadioButtons from '../../Form/Radio/FtlRadioButtons';
import UploadSection from './FTLDropzone/UploadSection';
import FtlCheckbox from '../../Form/Checkbox/FtlCheckbox';

const ApprovedMoreWithInfo = props => {
  const {
    application,
    loanDoc,
    ftlUploadResponse,
    data,
    dispatchSelectProgram,
    ftlOptionsResponse,
    history,
  } = props;

  const isNewVersion = data.ftlInformation.newVersion === true;

  const [selected, setSelected] = useState(
    data && data.ftlPrograms && data.ftlPrograms.length > 0
      ? data.ftlPrograms.find(opt => opt.selected === true) !== undefined
      : false,
  );

  let isDocumentsApproved = true;
  if (data && data.documents && data.documents.length > 0) {
    data.documents.map(document => {
      if (document.status !== 'approved') {
        isDocumentsApproved = false;
      }
    });
  }

  let myOptions = [];

  myOptions = data.ftlPrograms.map(opt => ({
    key: opt.id,
    id: opt.id,
    value: opt.id,
    description: opt.description,
    name: opt.name,
    estimatedPayment: opt.estimatedPayment,
    contractorFee: opt.contractorFee,
    checked: opt.id,
    selected: opt.selected,
    feeAgreement: opt.feeAgreement,
  }));

  const clickHandler = value => {};

  const selectAotherOption = () => {
    setSelected(false);
  };

  const back = () => {
    history.push('/portal/applications-under-review');
  };

  const FtlOptionsForm = ({ values }) => {
    const consent = values.ftlOptions
      ? myOptions.find(option => option.id === values.ftlOptions).feeAgreement
      : '';

    return (
      <Form noValidate>
        <Field
          optionsComponent={true}
          component={FtlRadioButtons}
          name="ftlOptions"
          options={myOptions}
          clickHandler={clickHandler}
        />
        <input type="hidden" name="isConsent" />
        {consent && (
          <>
            <Field
              formFieldClassName="mt-10"
              component={FtlCheckbox}
              name="consent"
              id="consent"
              label={consent}
              labelClassName="bold mb-0 red"
            />
          </>
        )}
        <Row className="justify-content-center mt-4">
          <Col xs="12" md="auto">
            <Button type="submit">Next</Button>
          </Col>
        </Row>
      </Form>
    );
  };

  const FtlOptionsFormFormik = withFormik({
    mapPropsToValues: () => ({
      ftlOptions: '',
      isConsent: false,
      consent: false,
    }),

    validationSchema: Yup.object({
      ftlOptions: Yup.string().required('Please select an option.'),
      consent: Yup.boolean().when('isConsent', {
        is: true,
        then: Yup.boolean().oneOf([true], 'Please check to continue.'),
        otherwise: Yup.boolean(),
      }),
    }),

    handleSubmit: async values => {
      setSelected(true);
      dispatchSelectProgram(application.id, values.ftlOptions);
    },
  })(FtlOptionsForm);

  return (
    <div className="ftl-flow-section">
      {!data.ftlInformation.generatedPaperworkSuccess && (
        <>
          {ftlUploadResponse && ftlUploadResponse?.approvedWith?.length > 0 && (
            <Row className="justify-content-center mb-3 text-align-center">
              <Col xs="auto" sm="8">
                <p>
                  <b> Please select one of the options below to offer to your homeowner.</b>
                </p>
              </Col>
            </Row>
          )}
          <Row>
            <Col sm="3">
              <div className="pt-35">
                <UploadSection
                  application={application}
                  uploadResponse={ftlUploadResponse}
                  ftlData={data}
                />
              </div>
            </Col>
            {!isNewVersion && (
              <Col sm="6">
                <div className="inner-content">
                  {((ftlUploadResponse && ftlUploadResponse?.approvedWith?.length > 0) ||
                    (data && data.documents?.length > 0)) && (
                    <>
                      {selected && data && data.ftlPrograms && data.ftlPrograms.length > 0 ? (
                        <>
                          {ftlOptionsResponse && ftlOptionsResponse.selectedProgram
                            ? myOptions
                                .filter(
                                  program => program.id === ftlOptionsResponse.selectedProgram,
                                )
                                .map(x => {
                                  const hasFee = x.feeAgreement !== null;

                                  return (
                                    <Row key={x.id} className="justify-content-center">
                                      <div className="inner-content ftl-options-table">
                                        <table className="selected-program">
                                          <tbody>
                                            <tr>
                                              <td className={!hasFee ? 'green' : ''}>{x.name}</td>
                                              {x.description ? (
                                                <td className={!hasFee ? 'green' : ''}>
                                                  {x.description}
                                                </td>
                                              ) : (
                                                <td className={!hasFee ? 'green' : 'grey'}>
                                                  {`Standard Programs`}
                                                </td>
                                              )}
                                              {x.estimatedPayment && (
                                                <td className={!hasFee ? 'green' : ''}>
                                                  {x.estimatedPayment}
                                                </td>
                                              )}
                                              <td className={!hasFee ? 'green' : 'grey'}>
                                                {x.contractorFee ? x.contractorFee + `%` : 'FREE'}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </Row>
                                  );
                                })
                            : myOptions
                                .filter(program => program.selected === true)
                                .map(x => {
                                  const hasFee = x.feeAgreement !== null;
                                  return (
                                    <Row key={x.id} className="justify-content-center">
                                      <div className="inner-content ftl-options-table">
                                        <table className="selected-program">
                                          <tbody>
                                            <tr>
                                              <td className={!hasFee ? 'green' : ''}>{x.name}</td>
                                              {x.description ? (
                                                <td className={!hasFee ? 'green' : ''}>
                                                  {x.description}
                                                </td>
                                              ) : (
                                                <td className={!hasFee ? 'green' : 'grey'}>
                                                  {`Standard Programs`}
                                                </td>
                                              )}
                                              {x.estimatedPayment && (
                                                <td className={!hasFee ? 'green' : ''}>
                                                  {x.estimatedPayment}
                                                </td>
                                              )}
                                              <td className={!hasFee ? 'green' : 'grey'}>
                                                {x.contractorFee ? x.contractorFee + `%` : 'FREE'}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </Row>
                                  );
                                })}
                          {selected && (
                            <>
                              <Row className="justify-content-center">
                                <div className="inner-content">
                                  <span
                                    className="select-different-program"
                                    onClick={selectAotherOption}
                                  >
                                    SELECT A DIFFERENT PROGRAM
                                  </span>
                                </div>
                              </Row>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <Row className="justify-content-center">
                            <div className="inner-content ftl-options-table">
                              <FtlOptionsFormFormik />
                            </div>
                          </Row>
                        </>
                      )}
                    </>
                  )}
                </div>
              </Col>
            )}
            <Col sm="3" />
          </Row>
        </>
      )}
      <Col sm="2" />
      <Row className="justify-content-end">
        <Col sm="12" xs="12" md="auto">
          <Button
            type="submit"
            color="secondary"
            className="rounded-pill w-100 mt-1"
            outline
            onClick={() => {
              back();
            }}
          >
            Back
          </Button>
        </Col>
        {!isNewVersion &&
          !data.ftlInformation.generatedPaperworkSuccess &&
          selected &&
          ((ftlUploadResponse &&
            ftlUploadResponse?.approvedWith?.length > 0 &&
            ftlUploadResponse.approvedWith[0].decision === 'approved') ||
            (data && data.documents.length > 0 && isDocumentsApproved)) && (
            <Col sm="12" xs="12" md="auto">
              <Button
                type="submit"
                color="primary"
                className="rounded-pill w-100 mt-1"
                outline
                onClick={() => {
                  loanDoc(true);
                }}
              >
                Create Loan Documents
              </Button>
            </Col>
          )}
      </Row>
    </div>
  );
};

const mapStateToProps = state => ({
  ftlUploadResponse: state.softPull.ftlUploadResponse,
  ftlOptionsResponse: state.softPull.ftlOptionsResponse,
});

const mapDispatchToProps = dispatch => ({
  dispatchSelectProgram: (appId, optionId) => dispatch(ftlSelectProgram(appId, optionId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApprovedMoreWithInfo);
