import React from 'react';
import { Col, Row } from 'reactstrap';
import Logo from '../../components/UI/Logo/Logo';
import ErrorModal from '../../components/UI/Modal/Notification';
import { getTechnicalSupportNumber } from '../../config/settings';
import PasswordUpdateSuccessful from './PasswordUpdateSuccessful';
import './PasswordUpdateSuccessful.scss';

const Index = () => {
  return (
    <div className="container-fluid">
      <Row className="login-page">
        <Col xs="12" md="6" lg="8" className="px-0 left">
          <Row noGutters className="login-image">
            <Logo />
          </Row>
        </Col>
        <Col xs="12" md="6" lg="4">
          <div className="textContainer">
            <PasswordUpdateSuccessful />
            <p className="info text-center mt-5">
              For Technical Support {getTechnicalSupportNumber()}
            </p>
          </div>
        </Col>
      </Row>
      <ErrorModal />
    </div>
  );
};

export default Index;
