import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  isEnerBankApproved,
  isEnerBankDeclined,
  isEnerBankUndecisioned,
} from '../../../assets/js/ApplicationStatusEnum';
import { filterSelectedQuestions } from '../../../helper';
import {
  setFlow,
  submitMindfire,
  updateSelectedFinancier,
  resetState,
  changePageTitle,
  mapEnerBankToState,
  changeEnerBankInfoValue,
  postIdentityVerification,
  changePage,
} from '../../../redux/actions/index';
import {
  changeMicrofInfoValue,
  mapApplicationToMicrof,
} from '../../../redux/actions/microfActions';
import Approved from './Approved';
import Declined from './Declined';
import IdentityVerification from './IdentityVerification';
import Pending from './Pending';
import Undecisioned from './Undecisioned';

const ApplicationResult = props => {
  const { status, enerBankApplicantQuestion, enerBankCoApplicantQuestion } = props;

  const enerBankApplicantQuestionFiltered = filterSelectedQuestions(enerBankApplicantQuestion);
  const enerBankCoApplicantQuestionFiltered = filterSelectedQuestions(enerBankCoApplicantQuestion);

  if (isEnerBankUndecisioned(status)) {
    if (enerBankApplicantQuestionFiltered?.length || enerBankCoApplicantQuestionFiltered?.length) {
      return (
        <IdentityVerification
          {...props}
          enerBankApplicantQuestion={enerBankApplicantQuestionFiltered}
          enerBankCoApplicantQuestion={enerBankCoApplicantQuestionFiltered}
        />
      );
    } else {
      return <Undecisioned {...props} />;
    }
  } else if (isEnerBankApproved(status)) {
    return <Approved {...props} />;
  } else if (isEnerBankDeclined(status)) {
    return <Declined {...props} />;
  } else {
    return <Pending {...props} />;
  }
};

const mapStateToProps = state => ({
  application: state.softPull.applicationSubmitDTO,
  consumerToken:
    state.softPull.applicationSubmitDTO && state.softPull.applicationSubmitDTO.consumerToken,
  status: state.softPull.applicationSubmitDTO && state.softPull.applicationSubmitDTO.status,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  information: state.enerBankInformation,
  enerBankApplicantQuestion: state.softPull.applicationSubmitDTO?.enerBankApplicantQuestion,
  enerBankCoApplicantQuestion: state.softPull.applicationSubmitDTO?.enerBankCoApplicantQuestion,
  enerBankInformation: state.enerBankInformation,
  applicantQuestionSetId:
    state.softPull.applicationSubmitDTO.enerBankInformation.applicantQuestionSetId,
  applicantTransactionId:
    state.softPull.applicationSubmitDTO.enerBankInformation.applicantTransactionId,
  coApplicantQuestionSetId:
    state.softPull.applicationSubmitDTO.enerBankInformation.coApplicantQuestionSetId,
  coApplicantTransactionId:
    state.softPull.applicationSubmitDTO.enerBankInformation.coApplicantTransactionId,
});

const mapDispatchToProps = dispatch => ({
  dispatchMapApplicationToMicrof: app => dispatch(mapApplicationToMicrof(app)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchUpdateSelectedFinancier: (selectedFinancier, applicationId, successCallBack) =>
    dispatch(updateSelectedFinancier(selectedFinancier, applicationId, successCallBack)),
  dispatchSetFlow: flowName => dispatch(setFlow(flowName)),
  dispatchChangeMicrofInfoValue: (key, value) => dispatch(changeMicrofInfoValue(key, value)),
  dispatchResetState: props => dispatch(resetState(props)),
  dispatchChangePageTitle: value => dispatch(changePageTitle(value)),
  dispatchMapEnerBankToState: () => dispatch(mapEnerBankToState()),
  dispatchChangeEnerBankInfoValue: (key, value) => dispatch(changeEnerBankInfoValue(key, value)),
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchIdentityVerification: (appId, request) =>
    dispatch(postIdentityVerification(appId, request)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ApplicationResult),
);
