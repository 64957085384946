import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { useVSPixelScript } from '../../../assets/hooks';
import Button from '../../../components/UI/Button/CustomButton';
import { reactGAEvent, reactGAPageview } from '../../../helper';
import {
  changePage,
  changePowerPayInfoValue,
  setPowerPayApplicationPromotion,
  submitMindfire,
} from '../../../redux/actions';
import { PowerPayFlowPageNumber } from './PowerPayFlow';
import PowerPayLoanOption from './PowerPayLoanOption';

const Approved = props => {
  const {
    did,
    dealerUserId,
    powerPayLoanOptions,
    applicationId,
    email,
    dispatchSetPowerPayApplicationPromotion,
    dispatchChangePageTitle,
    dispatchChangePage,
    dispatchSubmitMindfire,
  } = props;

  useVSPixelScript();

  useEffect(() => {
    dispatchChangePageTitle('Application Results');
    reactGAPageview({
      path: '/ca_ppselectproduct',
      trackerNames: [],
      title: 'ca_ppselectproduct',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);
  const handleClick = () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Continue',
      did,
      dealerUserId,
    });
    dispatchSubmitMindfire({
      email: email,
      lastPage: '6018',
      dealerUserId: dealerUserId,
      did: did,
    });
    dispatchChangePage(PowerPayFlowPageNumber.ApprovedRedirect);
  };

  const mindFireRequest = { email: email, lastPage: '6018', dealerUserId: dealerUserId, did: did };
  const abc = 'ABCDE';

  return (
    <Container>
      <Row className="justify-content-center mt-2">
        <Col xs="auto">
          <h4>Congratulations! You have been approved for the following loan option(s):</h4>
        </Col>
      </Row>
      <Row className="justify-content-center mt-2">
        <Col xs="auto" md="8">
          {powerPayLoanOptions
            .sort((a, b) => {
              return a.loanTerm < b.loanTerm ? 1 : -1;
            })
            .reverse()
            .map((item, index) => (
              <PowerPayLoanOption
                loanOption={item}
                key={item.powerPayLoanOptionId}
                name={'Option ' + abc[index]}
                buttonText="I want this option"
                applicationId={applicationId}
                submitMindfire={() => dispatchSubmitMindfire(mindFireRequest)}
                setApplicationPromotion={dispatchSetPowerPayApplicationPromotion}
              />
            ))}
        </Col>
      </Row>
      <Row className="justify-content-center mt-2">
        <Col xs="auto">
          <h5>To select an option, please press continue to access the PowerPay portal.</h5>
        </Col>
      </Row>
      <Row className="justify-content-center mt-2">
        <Col xs="auto" md="6">
          <Button type="submit" title="Continue" clickHandler={handleClick} />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  powerPayLoanOptions: state.softPull.applicationSubmitDTO.powerPayLoanOptions,
  applicationId: state.softPull.applicationSubmitDTO.applicationId,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangePowerPayInfoValue: (key, value) => dispatch(changePowerPayInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchSetPowerPayApplicationPromotion: (applicationId, promotionId, page) =>
    dispatch(setPowerPayApplicationPromotion(applicationId, promotionId, page)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Approved);
