import { Field, Form, withFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import CustomInput from '../../../../components/UI/Form/Input/CustomInput';
import CurrencyFormat from '../../../../components/UI/Form/NumberFormat/CurrencyFormat';
import {
  BORROWEDAMOUNTT_TOOLTIP_TEXT,
  MERCHANT_ID_TOOLTIP_TEXT,
  PLAN_ID_TOOLTIP_TEXT,
} from '../../../../constants/tooltipTexts';
import { changeApplicationValues, reactGAEvent, reactGAPageview } from '../../../../helper';
import {
  changePage,
  submitMindfire,
  changeGreenSkyApplicationInfoValue,
  getGreenskyMerchantPlans,
} from '../../../../redux/actions/index';
import { AtWaterGreenSkyFlowPageNumber } from './GreenSkyFlow';
import SelectWithCustomOnChange from '../../../../components/UI/Form/Select/SelectWithCustomOnChange';
import Disclosure from '../Components/Disclosure';

const LoanInformation = props => {
  const {
    email,
    merchantId,
    allPlans,
    borrowedAmount,
    dispatchChangePage,
    dispatchChangeGreenSkyApplicationInfoValue,
    children,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    isReview,
    greenskyMerchantNumber,
    dispatchGetGreenskyMerchantPlans,
  } = props;

  const [planIds, setPlanIds] = useState();
  const [selectedPlan, setSelectedPlan] = useState(null);

  useEffect(() => {
    reactGAPageview({
      path: '/ca_gsloaninformation',
      trackerNames: [],
      title: 'ca_gsloaninformation',
      did,
      dealerUserId,
    });

    if (allPlans == null) {
      dispatchGetGreenskyMerchantPlans(merchantId || greenskyMerchantNumber || '');
    } else {
      let sorted = allPlans.sort((a, b) => a.planId - b.planId);
      let valueAndName = sorted.map(plan => ({
        value: +plan.planId,
        name: +plan.planId + ` - ${plan.category}`,
      }));
      setPlanIds(valueAndName);
    }
    // eslint-disable-next-line
  }, []);

  const handleSelectedPlan = value => {
    if (value) {
      const plan = allPlans.find(x => x.planId === value);
      if (plan) {
        setSelectedPlan(plan);
      }
    } else {
      setSelectedPlan(null);
    }
  };

  const LoanInformationForm = ({ values }) => (
    <Form>
      <Field
        component={CustomInput}
        label="Merchant ID"
        name="merchantId"
        id="merchantId"
        tooltipText={MERCHANT_ID_TOOLTIP_TEXT}
        type="text"
        number
        maxLength={8}
        disabled={true}
      />
      <Field
        component={SelectWithCustomOnChange}
        label="Plan ID"
        name="planId"
        id="planId"
        tooltipText={PLAN_ID_TOOLTIP_TEXT}
        type="select"
        selectValues={planIds ? planIds : ''}
        selectFirstName="Select One"
        handleChange={e => handleSelectedPlan(e.target.value)}
      />
      {selectedPlan && (
        <div className="plan-description mt-15">
          <p>{selectedPlan.description}</p>
        </div>
      )}
      <Field
        component={CurrencyFormat}
        label="Loan Amount"
        name="borrowedAmount"
        id="borrowedAmount"
        tooltipText={BORROWEDAMOUNTT_TOOLTIP_TEXT}
      />
      <Button type="submit" title="Next" />
      <Disclosure />
    </Form>
  );

  const LoanInformationFormFormik = withFormik({
    mapPropsToValues: () => ({
      merchantId: merchantId || greenskyMerchantNumber || '',
      planId: selectedPlan ? selectedPlan.planId : '',
      borrowedAmount: borrowedAmount || '',
    }),

    validationSchema: Yup.object({
      merchantId: Yup.string().required('Please enter a Merchant Id.'),
      planId: Yup.string().required('Please select a Plan Id.'),
      borrowedAmount: Yup.number()
        .moreThan(1499, `Amount should be at least $1500.`)
        .lessThan(150000.01, 'Amount too high.')
        .required('Please enter an amount.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      changeApplicationValues(dispatchChangeGreenSkyApplicationInfoValue, values);
      dispatchChangePage(AtWaterGreenSkyFlowPageNumber.AppPrimaryAddress);
      dispatchSubmitMindfire({
        email: email,
        borrowedAmount: values.borrowedAmount,
        lastPage: '9010',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(LoanInformationForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={
            isReview ? AtWaterGreenSkyFlowPageNumber.Review : AtWaterGreenSkyFlowPageNumber.Consent
          }
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <LoanInformationFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  isReview: state.greenSkyApplicationInformation.isReview,
  merchantId: state.greenSkyApplicationInformation.merchantId,
  allPlans: state.greenSkyApplicationInformation.allPlans,
  borrowedAmount: state.greenSkyApplicationInformation.borrowedAmount,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  greenskyPlanNumber:
    state.auth.user?.dealerUser?.dealer?.greenskyPlanNumber ||
    state.auth.user?.consumerUser?.dealer?.greenskyPlanNumber ||
    state.auth.greenskyPlanNumber,
  greenskyMerchantNumber:
    state.auth.user?.dealerUser?.dealer?.greenskyMerchantNumber ||
    state.auth.user?.consumerUser?.dealer?.greenskyMerchantNumber ||
    state.auth.greenskyMerchantNumber,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeGreenSkyApplicationInfoValue: (key, value) =>
    dispatch(changeGreenSkyApplicationInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchGetGreenskyMerchantPlans: merchantId => dispatch(getGreenskyMerchantPlans(merchantId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoanInformation);
