import React, { useState } from 'react';
import { withGoogleMap, withScriptjs, GoogleMap, Marker, InfoWindow } from 'react-google-maps';

const Map = props => {
  const { foundContractors } = props;
  const [infoOpen, setInfoOpen] = useState(null);

  const MapComponent = withScriptjs(
    withGoogleMap(() =>
      foundContractors ? (
        <GoogleMap
          zoom={8}
          center={{ lat: foundContractors[0].lattitude, lng: foundContractors[0].longitude }}
        >
          {foundContractors.map((contractor, index) => (
            <Marker
              key={index}
              position={{ lat: contractor.lattitude, lng: contractor.longitude }}
              onClick={() => {
                setInfoOpen(index);
              }}
            >
              {infoOpen === index ? (
                <InfoWindow
                  key={index}
                  position={{ lat: contractor.lattitude, lng: contractor.longitude }}
                  onCloseClick={() => {
                    setInfoOpen(null);
                  }}
                >
                  <>
                    <strong>{contractor.dealerName}</strong>
                    <br />
                    {contractor.street}
                    <br /> {contractor.city + ' ' + contractor.zipcode}
                    <br />
                    <br /> {contractor.phone}
                    <br />
                    <a
                      href={
                        'http://maps.google.com/maps?saddr=&daddr=' +
                        contractor.street +
                        ',' +
                        contractor.city +
                        ',' +
                        contractor.zipcode
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Get directions
                    </a>
                  </>
                </InfoWindow>
              ) : null}
            </Marker>
          ))}
        </GoogleMap>
      ) : null,
    ),
  );

  return (
    <MapComponent
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBpFNGL4OBGOYwR6N7v2kVQq5tmMfDc6kI"
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `400px` }} />}
      mapElement={<div style={{ height: `100%` }} />}
      isMarkerShowns
    />
  );
};

export default Map;
