import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

const redirectTimeoutMili = 5000;
const loginUrl = '/Login';

const PasswordUpdateSuccessful = ({ history }) => {
  const handleGoToLogin = () => history.push(loginUrl);

  useEffect(() => {
    const redirectTimeout = setTimeout(() => history.push(loginUrl), redirectTimeoutMili);
    return () => {
      clearTimeout(redirectTimeout);
    };
  });

  return (
    <div>
      <h1>
        <b>Password Successfully Updated</b>
      </h1>
      <p>
        Your password has successfully been updated. You will be automatically redirected back to
        the login page. Alternatively use the link below if this takes longer than 5 seconds.
      </p>
      <Link className="loginLink" to="/Login" onClick={handleGoToLogin}>
        Back to Login
      </Link>
    </div>
  );
};

export default withRouter(PasswordUpdateSuccessful);
