import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import BackBtn from '../../components/UI/BackButton/BackButton';
import Button from '../../components/UI/Button/CustomButton';
import Input from '../../components/UI/Form/Input/CustomInput';
import CustomPhoneNumber from '../../components/UI/Form/PhoneNumber/PhoneNumber';
import {
  FIRSTNAME_TOOLTIP_TEXT,
  LASTNAME_TOOLTIP_TEXT,
  MIDDLENAME_TOOLTIP_TEXT,
  PHONENUMBER_TOOLTIP_TEXT,
} from '../../constants/tooltipTexts';
import { reactGAEvent, reactGAPageview } from '../../helper';
import { changePage, submitMindfire, changeWellsFargoValue } from '../../redux/actions';
import { WellsFargoFlowPageNumber } from './WellsFargoFlow';

const UserInfo = props => {
  const {
    email,
    firstName,
    middleName,
    lastName,
    phoneNumber,
    dispatchChangePage,
    dispatchChangeWellsFargoValue,
    children,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/wf_consumerinfo1',
      trackerNames: [],
      title: 'wf_consumerinfo1',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const handleSubmit = values => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Continue',
      did,
      dealerUserId,
    });
    dispatchChangeWellsFargoValue('firstName', values.firstName.trim());
    dispatchChangeWellsFargoValue('middleName', values.middleName.trim());
    dispatchChangeWellsFargoValue('lastName', values.lastName.trim());
    dispatchChangeWellsFargoValue('phoneNumber', values.phoneNumber.match(/\d/g).join(''));
    dispatchChangePage(WellsFargoFlowPageNumber.UserAddress);
    dispatchSubmitMindfire({
      email: email,
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
      mobilePhone: values.phoneNumber,
      lastPage: '5006',
      dealerUserId: dealerUserId,
      did: did,
    });
  };

  const UserInfoForm = () => (
    <Form>
      <Field
        component={Input}
        label="First Name"
        name="firstName"
        id="firstName"
        tooltipText={FIRSTNAME_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={Input}
        label="Middle Initial (If Applicable – Optional Field)"
        name="middleName"
        id="middleName"
        tooltipText={MIDDLENAME_TOOLTIP_TEXT}
        type="text"
        maxLength={1}
      />
      <Field
        component={Input}
        label="Last Name"
        name="lastName"
        id="lastName"
        tooltipText={LASTNAME_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={CustomPhoneNumber}
        label="Home Phone Number"
        name="phoneNumber"
        id="phoneNumber"
        tooltipText={PHONENUMBER_TOOLTIP_TEXT}
      />
      <Button type="submit" title="Continue" />
    </Form>
  );

  const UserInfoFormFormik = withFormik({
    mapPropsToValues: () => ({
      firstName: firstName || '',
      middleName: middleName || '',
      lastName: lastName || '',
      phoneNumber: phoneNumber || '',
    }),

    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .required('Please enter your first name.'),
      middleName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .max(2, 'Middle initial is too long.'),
      lastName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .required('Please enter your last name.'),
      phoneNumber: Yup.string()
        .test('required', 'Please enter your phone number.', val => val !== '+' && val)
        .test('len', 'Invalid phone number', val => val && val.match(/\d/g).join('').length === 10),
    }),

    handleSubmit,
  })(UserInfoForm);

  return (
    <Container>
      <Row>
        <BackBtn changePage={dispatchChangePage} page={WellsFargoFlowPageNumber.LoanAmount} />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <UserInfoFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  firstName: state.wellsFargoInformation.firstName,
  middleName: state.wellsFargoInformation.middleName,
  lastName: state.wellsFargoInformation.lastName,
  phoneNumber: state.wellsFargoInformation.phoneNumber,
  email: state.wellsFargoInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeWellsFargoValue: (key, value) => dispatch(changeWellsFargoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

UserInfo.propTypes = {
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  phoneNumber: PropTypes.string,
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserInfo);
