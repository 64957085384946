import {
  CHANGE_PRE_QUAL_INFO_VALUE,
  IS_EMAIL_UNIQUE_SUCCESS,
  IS_EMAIL_UNIQUE_ERROR,
  GET_SCORE_SUCCESS,
  GET_SCORE_ERROR,
} from './actionTypes';
import { baseURL } from './api';
import { startLoading } from './notificationActions';
import axios from 'axios';
import { changePage } from './naviActions';
import { addNotification, endLoading } from './notificationActions';
import { handleApiError } from './api';
import { changeSoftPullValue, submitMindfire, getApplications } from './softPullActions';
import { updateConsumerUser } from './microfActions';
import { changeAuthValue, loginConsumerUserAfterCreation } from './authActions';

export const changePreQualInfoValue = (key, value) => ({
  type: CHANGE_PRE_QUAL_INFO_VALUE,
  key,
  value,
});

export const updatePrequalificationInformation = (params, app) => async dispatch => {
  dispatch(startLoading('Loading ...'));

  await axios
    .post(`${baseURL}/api/soft_pull/update_prequalification_information`, params)
    .then(async response => {
      dispatch({ type: GET_SCORE_SUCCESS, payload: response.data });
      dispatch(changeSoftPullValue('softPullResponse', response.data));
      if (response.data.consumerId) {
        await dispatch(
          updateConsumerUser(
            {
              email: params.email,
              firstName: params.firstName,
              lastName: params.lastName,
              phoneNumber: params.phoneNumber,
              zipcode: params.zipcode,
            },
            null,
          ),
        );
      }
      if (app != null) {
        await dispatch(updateApplicationPrequalification(response, app));
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch({ type: GET_SCORE_ERROR, payload: error });
    });

  dispatch(endLoading());
};

export const isEmailUnique = (email, page, submitMindfireParams) => async dispatch => {
  dispatch(startLoading('Loading ...'));

  await axios
    .get(`${baseURL}/api/soft_pull/is_email_unique?email=${encodeURIComponent(email)}`)
    .then(response => {
      dispatch({ type: IS_EMAIL_UNIQUE_SUCCESS, payload: response.data });
      if (response.data) {
        dispatch(submitMindfire(submitMindfireParams));
        dispatch(changePage(page));
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch({ type: IS_EMAIL_UNIQUE_ERROR, payload: error });
    });

  dispatch(endLoading());
};

export const getScore = (params, app, isConsumerUser, user) => async dispatch => {
  dispatch(startLoading('Loading ...'));
  dispatch(changePreQualInfoValue('preQualInfoExists', false));
  await axios
    .post(`${baseURL}/api/soft_pull/score`, params)
    .then(async response => {
      dispatch({ type: GET_SCORE_SUCCESS, payload: response.data });
      dispatch(changeSoftPullValue('softPullResponse', response.data));

      if (app != null) {
        await dispatch(updateApplicationPrequalification(response, app));
      }
      if (isConsumerUser) {
        dispatch(changeAuthValue('consumerId', response.data.consumerId));
        if (user.consumerUser === null) {
          await dispatch(
            loginConsumerUserAfterCreation({
              email: params.email,
              password: params.password,
              addedFrom: params.addedFrom,
              page: null,
            }),
          );
        }
      }
    })
    .catch(error => {
      dispatch({ type: GET_SCORE_ERROR, payload: error });
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.internalError &&
        error.response.data.internalError === 'USER_ALREADY_EXISTS'
      ) {
        dispatch(changePreQualInfoValue('preQualInfoExists', true));
      } else {
        dispatch(
          addNotification({
            message: handleApiError(error),
            messageType: 'Error',
          }),
        );
      }
    });

  dispatch(endLoading());
};

export const updateApplicationPrequalification = (response, app) => async dispatch => {
  await axios
    .post(
      `${baseURL}/api/soft_pull/updateApplicationPrequalification?appToken=${encodeURIComponent(
        app.consumerToken,
      )}&pqiId=${encodeURIComponent(response.data.prequalificationId)}`,
    )
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
};

export const movePrequalificationToHistory = (pqId, auth) => async dispatch => {
  dispatch(startLoading('Moving to history ...'));
  await axios
    .post(
      `${baseURL}/api/soft_pull/movePrequalificationToHistory?pqiId=${encodeURIComponent(
        pqId,
      )}&userName=${encodeURIComponent(
        auth.user.user.emulatorNameFirst + ' ' + auth.user.user.emulatorNameLast,
      )}`,
    )
    .then(async response => {
      console.log(response);
      if (response.status === 200) {
        if (auth.user && auth.user.dealerUser) {
          dispatch(getApplications(auth.user.dealerUser.userId, 'added_by_api'));
        }
      }
    })
    .catch(error => {
      dispatch(endLoading());
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
};
