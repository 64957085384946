const employmentStatusEnum = {
  FullTime: {
    value: 'FullTime',
    name: 'Full Time',
  },
  PartTime: {
    value: 'PartTime',
    name: 'Part Time',
  },
  Retired: {
    value: 'Retired',
    name: 'Retired',
  },
  SelfEmployed: {
    value: 'SelfEmployed',
    name: 'Self-Employed',
  },
  Student: {
    value: 'Student',
    name: 'Student',
  },
  Unemployed: {
    value: 'Unemployed',
    name: 'Unemployed',
  },
};

export const findEmploymentStatusEnumName = value => {
  for (let key in employmentStatusEnum) {
    let employmentStatus = employmentStatusEnum[key];
    if (employmentStatus.value === value) {
      return employmentStatus.name;
    }
  }
  return null;
};

export default employmentStatusEnum;
