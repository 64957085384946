import React from 'react';
import DealershipInformation from './DealershipInformation';
import BusinessManager from './BusinessManager';
import FinalMessage from './FinalMessage';
import { getAppName } from '../../../config/settings';

const appName = getAppName();

const Flow = [
  <DealershipInformation title="Contractor Enrollment - Dealership Information">
    <h2>Contractor Enrollment</h2>
  </DealershipInformation>,
  <BusinessManager title="Contractor Enrollment - Business Manager">
    <h2>Business Manager</h2>
    <h3>Individual who will sign the {appName} Contractor Agreement (Please use legal name)</h3>
  </BusinessManager>,
  <FinalMessage title="Contractor Enrollment" />,
];

export default Flow;

export const GreenskyContractorEnrollmentFlowPageNumber = {
  DealershipInformation: 0,
  BusinessManager: 1,
  FinalMessage: 2,
};
