import React, { useState, useEffect } from 'react';
import { Field, Form, withFormik } from 'formik';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Row, Label, Button } from 'reactstrap';
import queryString from 'query-string';
import {
  getDealerUser,
  emulateStart,
  deleteUser,
  updateDealerUser,
} from '../../../redux/actions/index';
import UserRoleEnum, {
  findBusinessRolesByUserType,
  findByValue,
} from '../../../assets/js/UserRoleEnum';
import InputField from '../../../components/UI/Form/Input/InputField';
import SelectField from '../../../components/UI/Form/Select/SelectField';
import ConfirmDelete from '../../../components/UI/Modal/ConfirmDelete';
import * as Yup from 'yup';
import UserTypeEnum from '../../../assets/js/UserTypeEnum';
import UserOperationEnum, { isOperationAuthorized } from '../../../assets/js/UserOperationEnum';
import { EMAIL_TOOLTIP_TEXT } from '../../../constants/tooltipTexts';
import AddedFromEnum from '../../../assets/js/AddedFromEnum';

const ViewContractorUser = props => {
  const {
    auth,
    history,
    dispatchGetDealerUser,
    contractorUser,
    dispatchEmulateStart,
    dispatchDeleteUser,
    dispatchUpdateDealerUser,
  } = props;
  const [isEdit, setEdit] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const userId = queryString.parse(window.location.search).id;

  useEffect(() => {
    if (!auth.user && (!auth.user.dealerUser || !auth.user.internalUser)) {
      history.push('/login');
    }
    if (userId) {
      dispatchGetDealerUser(userId);
    } else {
      history.push('/portal/manage-contractor-users');
    }
    // eslint-disable-next-line
  }, []);

  const ViewContractorUserForm = () => (
    <Form>
      <Row>
        <Col sm="4">
          <div className="inner-content has-input">Role</div>
        </Col>
        <Col sm="4">
          <Field
            component={SelectField}
            name="roleType"
            id="roleType"
            type="select"
            selectValues={findBusinessRolesByUserType(UserTypeEnum.USER_DEALER)}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm="4">
          <Label for="firstName">First Name</Label>
        </Col>
        <Col sm="4">
          <Field component={InputField} name="firstName" id="firstName" type="text" />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm="4">
          <Label for="lastName">Last Name</Label>
        </Col>
        <Col sm="4">
          <Field component={InputField} name="lastName" id="lastName" type="text" />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm="4">
          <div className="inner-content has-input">Email</div>
        </Col>
        <Col sm="4">
          <Field
            component={InputField}
            name="email"
            id="email"
            tooltipText={EMAIL_TOOLTIP_TEXT}
            type="text"
          />
        </Col>
      </Row>

      <Row className="mt-5">
        <Col className="text-right">
          <Button
            type="submit"
            color="secondary"
            className="rounded-pill"
            outline
            onClick={() => {
              setEdit(false);
            }}
          >
            Cancel
          </Button>

          <Button type="submit" color="primary" className="rounded-pill">
            Apply
          </Button>
        </Col>
      </Row>
    </Form>
  );

  const ViewContractorUserFormFormik = withFormik({
    mapPropsToValues: () => ({
      roleType: UserRoleEnum[contractorUser.role].value,
      firstName: contractorUser.firstName || '',
      lastName: contractorUser.lastName || '',
      email: contractorUser.email || '',
    }),

    validationSchema: Yup.object({}),

    handleSubmit: values => {
      let userDTO = {
        id: contractorUser.id,
        lastName: values.lastName,
        firstName: values.firstName,
        email: values.email,
        role: findByValue(values.roleType),
        lastLogin: null,
        dealer: null,
        state: null,
      };
      dispatchUpdateDealerUser(userDTO, history, '/portal/manage-contractor-users?search=true');
    },
  })(ViewContractorUserForm);

  const ViewContractorUserView = () => (
    <>
      <Row>
        <Col sm="4">
          <Label>Name</Label>
        </Col>
        <Col sm="4">{(contractorUser.firstName || '') + ' ' + (contractorUser.lastName || '')}</Col>
      </Row>
      <Row className="mt-4">
        <Col sm="4">
          <Label>Role</Label>
        </Col>
        <Col sm="4">{UserRoleEnum[contractorUser.role].name}</Col>
      </Row>
      <Row className="mt-4">
        <Col sm="4">
          <Label>Email</Label>
        </Col>
        <Col sm="4">{contractorUser.email || ''}</Col>
      </Row>
      <Row className="mt-4">
        <Col sm="4">
          <Label>Contractor</Label>
        </Col>
        <Col sm="4">{contractorUser.dealer || ''}</Col>
      </Row>
      <Row className="mt-4">
        <Col sm="4">
          <Label>Last Login</Label>
        </Col>
        <Col sm="4">{contractorUser.lastLogin || 'N/A'}</Col>
      </Row>
      <Row className="mt-4">
        <Col sm="4">
          <Label>Platform</Label>
        </Col>
        <Col sm="4">
          {AddedFromEnum[
            Object.keys(AddedFromEnum).find(
              key => AddedFromEnum[key].value === contractorUser.addedFrom,
            )
          ]?.name || 'N/A'}
        </Col>
      </Row>

      <Row className="mt-5">
        <Col className="text-right">
          {contractorUser.addedFrom === AddedFromEnum.MICROF.value ||
          contractorUser.addedFrom === AddedFromEnum.OPTIMUS.value ? (
            <Button
              type="submit"
              color="success"
              className="rounded-pill"
              onClick={() => {
                setConfirmDelete(true);
              }}
              disabled={
                !isOperationAuthorized(
                  UserOperationEnum.DELETE,
                  UserRoleEnum[
                    findByValue(
                      auth.user.dealerUser
                        ? auth.user.dealerUser.role
                        : auth.user.internalUser
                        ? auth.user.internalUser.role
                        : null,
                    )
                  ],
                  UserRoleEnum[contractorUser.role].userType,
                )
              }
            >
              Delete
            </Button>
          ) : null}
          <Button
            type="submit"
            color="primary"
            className={`rounded-pill ${
              isOperationAuthorized(
                UserOperationEnum.EMULATE,
                UserRoleEnum[
                  findByValue(
                    auth.user.dealerUser
                      ? auth.user.dealerUser.role
                      : auth.user.internalUser
                      ? auth.user.internalUser.role
                      : null,
                  )
                ],
                UserRoleEnum[contractorUser.role].userType,
              )
                ? ''
                : 'hidden'
            }`}
            onClick={() => {
              dispatchEmulateStart(contractorUser.id, auth.user.internalUser.userId, history);
            }}
            disabled={
              !isOperationAuthorized(
                UserOperationEnum.EMULATE,
                UserRoleEnum[
                  findByValue(
                    auth.user.dealerUser
                      ? auth.user.dealerUser.role
                      : auth.user.internalUser
                      ? auth.user.internalUser.role
                      : null,
                  )
                ],
                UserRoleEnum[contractorUser.role].userType,
              )
            }
          >
            Emulate
          </Button>
          {contractorUser.addedFrom === AddedFromEnum.MICROF.value ||
          contractorUser.addedFrom === AddedFromEnum.OPTIMUS.value ? (
            <Button
              type="submit"
              color="primary"
              className="rounded-pill"
              onClick={() => {
                setEdit(true);
              }}
              disabled={
                !isOperationAuthorized(
                  UserOperationEnum.UPDATE,
                  UserRoleEnum[
                    findByValue(
                      auth.user.dealerUser
                        ? auth.user.dealerUser.role
                        : auth.user.internalUser
                        ? auth.user.internalUser.role
                        : null,
                    )
                  ],
                  UserRoleEnum[contractorUser.role].userType,
                )
              }
            >
              Edit
            </Button>
          ) : null}
        </Col>
      </Row>
    </>
  );
  return (
    <>
      <Col>
        <Row className="mt-5">
          <h1 id="panelTitle">Contractor User View</h1>
          <div className="panel">
            <div className="panel-body larger">
              <div className="table-simple">
                {isEdit ? <ViewContractorUserFormFormik /> : <ViewContractorUserView />}
              </div>
            </div>
          </div>
        </Row>
      </Col>
      <ConfirmDelete
        headerText={'Delete user?'}
        bodyText={'Are you sure you want to delete this user?'}
        confirmDelete={confirmDelete}
        cancelButtonText={'No, Back to View User'}
        confirmButtonText={'Yes, Delete Contractor User'}
        cancelEventHandler={() => setConfirmDelete(false)}
        confirmEventHandler={() => {
          setConfirmDelete(false);
          dispatchDeleteUser(userId, history, '/portal/manage-contractor-users?search=true');
        }}
      />
    </>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  contractorUser: state.softPull.contractorUser,
});

const mapDispatchToProps = dispatch => ({
  dispatchGetDealerUser: userId => dispatch(getDealerUser(userId)),
  dispatchEmulateStart: (emulateeUserId, emulatorId, history) =>
    dispatch(emulateStart(emulateeUserId, emulatorId, history)),
  dispatchDeleteUser: (id, history, url) => dispatch(deleteUser(id, history, url)),
  dispatchUpdateDealerUser: (data, history, url) => dispatch(updateDealerUser(data, history, url)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ViewContractorUser),
);
