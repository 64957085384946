import React from 'react';
import InputFeedback from '../InputFeedback/InputFeedback';
import { Label, FormGroup } from 'reactstrap';

const CheckboxGroup = props => {
  const handleChange = event => {
    const target = event.currentTarget;
    let valueArray = [...props.value] || [];

    if (target.checked) {
      valueArray.push(target.id);
    } else {
      valueArray.splice(valueArray.indexOf(target.id), 1);
    }

    props.onChange(props.id, valueArray);
  };

  const handleBlur = () => {
    // take care of touched
    props.onBlur(props.id, true);
  };

  const { value, error, touched, children } = props;

  return (
    <FormGroup>
      <Label>
        <b>Select the Contractor Program</b>
      </Label>
      {React.Children.map(children, child => {
        return React.cloneElement(child, {
          field: {
            value: value.includes(child.props.id),
            onChange: handleChange,
            onBlur: handleBlur,
          },
        });
      })}
      {touched && <InputFeedback error={error} />}
    </FormGroup>
  );
};

export default CheckboxGroup;
