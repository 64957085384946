const EnerBankOwnershipEnum = {
  Owner: { value: 'owner', name: 'Owner' },
  CoBorrower: {
    value: 'occupant',
    name: 'Live in the home and have a co-borrower who is the owner',
  },
  None: { value: 'none', name: 'None of the above' },
};

export default EnerBankOwnershipEnum;
