import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import Button from '../../components/UI/Button/CustomButton';
import { goog_report_conversion, reactGAEvent } from '../../helper';
import {
  changeRheemInfoValue,
  finalAcceptMicrofApplication,
  rejectMicrofApplication,
  resetState,
  submitMindfire,
} from '../../redux/actions/index';

const Review = props => {
  const {
    pqi,
    app,
    rheemInformation,
    did,
    dealerUserId,
    user,
    history,
    dispatchFinalAcceptMicrofApplication,
    dispatchChangeRheemInfoValue,
    dispatchSubmitMindfire,
    consumerUser,
    consumerId,
  } = props;

  useEffect(() => {
    //   reactGAPageview({
    //     path: '/ca_review2',
    //     trackerNames: [],
    //     title: 'ca_review2',
    //     did,
    //     dealerUserId,
    //   });
    // eslint-disable-next-line
  }, []);

  const mindFireRequest = {
    email: pqi.email,
    lastPage: '',
    dealerUserId: dealerUserId,
    did: did,
  };

  return (
    <Container>
      <Row className="justify-content-center align-items-center h-100">
        <Col sm="7">
          <Row className="align-items-center h-100 top-100">
            <Col>
              <h4 className="text-center">
                Thanks for your business! Please click Submit below To complete the process and
                speak to a KwikComfort® representative!
              </h4>
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center h-100 top-100">
            <Col sm="9">
              <>
                <Button
                  disabled={rheemInformation.submittedToOracle}
                  type="button"
                  title="Submit"
                  clickHandler={() => {
                    if (consumerUser && consumerId) {
                      window.bingButtonClickHandler();
                    }
                    reactGAEvent({
                      category: 'Button',
                      action: 'Click',
                      label: 'Submit',
                      did,
                      dealerUserId,
                    });
                    goog_report_conversion();
                    dispatchFinalAcceptMicrofApplication(app.applicationId, props, user, history);
                    dispatchChangeRheemInfoValue('submittedToOracle', true);
                    dispatchSubmitMindfire(mindFireRequest);
                  }}
                />
              </>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  rheemInformation: state.rheemInformation,
  pqi: state.preQualificationInformation,
  app: state.softPull.applicationSubmitDTO,
  approvalAmount: !!state.softPull.applicationSubmitDTO.lastApplicationResponse
    ? state.softPull.applicationSubmitDTO.lastApplicationResponse.approvalAmount
    : 0,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  user: state.auth.user,
  consumerUser: state.auth.consumerUser,
  consumerId: state.auth.consumerId,
  appToken: state.auth.appToken,
  decisionLeaseOption:
    !!state.softPull.applicationSubmitDTO.lastApplicationResponse &&
    state.softPull.applicationSubmitDTO.lastApplicationResponse.decisionLeaseOptions &&
    state.softPull.applicationSubmitDTO.lastApplicationResponse.decisionLeaseOptions[0],
});

const mapDispatchToProps = dispatch => ({
  dispatchChangeRheemInfoValue: (name, value) => dispatch(changeRheemInfoValue(name, value)),
  dispatchFinalAcceptMicrofApplication: (applicationId, props, user, history) =>
    dispatch(finalAcceptMicrofApplication(applicationId, props, user, history)),
  dispatchRejectMicrofApplication: (applicationId, props) =>
    dispatch(rejectMicrofApplication(applicationId, props)),
  dispatchResetState: props => dispatch(resetState(props)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

Review.propTypes = {
  rheemInformation: PropTypes.object,
  pqi: PropTypes.object,
  app: PropTypes.object,
  status: PropTypes.string,
  decisionLeaseOption: PropTypes.object,
  leasingOffers: PropTypes.array,
  dispatchFinalAcceptMicrofApplication: PropTypes.func,
  dispatchRejectMicrofApplication: PropTypes.func,
  dispatchResetState: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Review),
);
