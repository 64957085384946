import React from 'react';
import { Col, Row } from 'reactstrap';
import './Detail.scss';

const OuterContent = ({ fieldName, children, path, history }) => {
  return (
    <div className="outer-content">
      <Row>
        <Col sm="6">
          <div className="inner-content">
            <span>{fieldName ? fieldName + ':' : null}</span>
          </div>
        </Col>
        <Col sm="6">
          <div className="inner-content">{children}</div>
        </Col>
      </Row>
    </div>
  );
};

export default OuterContent;
