import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Section from '../../../components/Section/Section';
import Header from '../../../components/UI/Header/Header';
import { setFlow } from '../../../redux/actions/index';
import {
  aquaFlow,
  microfFlow,
  preQualificationFlow,
  wellsFargoFlow,
  powerPayPreQualificationFlow,
  mosaicPreQualificationFlow,
  mosaicFlow,
  powerPayFlow,
  enerBankPreQualificationFlow,
} from '../../../redux/initialState';
import EnerBankFlow from './EnerBankFlow';

const EnerBank = props => {
  const { currentPage, history, flowName } = props;

  useEffect(() => {
    if (flowName === microfFlow) {
      history.push('/RTO');
    } else if (flowName === aquaFlow) {
      history.push('/Aqua');
    } else if (flowName === preQualificationFlow) {
      history.push('/');
    } else if (flowName === wellsFargoFlow) {
      history.push('/WellsFargo');
    } else if (flowName === powerPayFlow) {
      history.push('/powerpay');
    } else if (flowName === powerPayPreQualificationFlow) {
      history.push('/powerpay-prequalification');
    } else if (flowName === mosaicPreQualificationFlow) {
      history.push('/mosaic-prequalification');
    } else if (flowName === mosaicFlow) {
      history.push('/mosaic');
    } else if (flowName === enerBankPreQualificationFlow) {
      history.push('/enerbank-prequalification');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header
        title={EnerBankFlow[currentPage ? currentPage : 0].props.title}
        pages={EnerBankFlow.length - 1}
      />
      <Section Component={EnerBankFlow[currentPage ? currentPage : 0]} />
    </>
  );
};

const mapStateToProps = state => ({
  currentPage: state.navi.enerBankFlowCurrentPage,
  flowName: state.navi.flowName,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetFlow: flow => dispatch(setFlow(flow)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EnerBank);
