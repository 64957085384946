import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import BackBtn from '../../components/UI/BackButton/BackButton';
import Button from '../../components/UI/Button/CustomButton';
import Checkbox from '../../components/UI/Form/Checkbox/Checkbox';
import { reactGAEvent, reactGAPageview } from '../../helper';
import {
  changePage,
  submitMindfire,
  changeWellsFargoValue,
  submitWellsFargoApplication,
} from '../../redux/actions';
import NumberFormat from 'react-number-format';
import selectedFinancierEnum from '../../assets/js/SelectedFinancierEnum';
import { WellsFargoFlowPageNumber } from './WellsFargoFlow';

const Review = props => {
  const {
    dispatchSubmitWellsFargoApplication,
    children,
    dispatchChangePage,
    wellsFargoInformation,
    dealerUserId,
    did,
    app,
    emulatorId,
    track_id,
    applicantAddress,
    dispatchSubmitMindfire,
    dispatchChangeWellsFargoValue,
    consumerId,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/wf_confirminfo',
      trackerNames: [],
      title: 'wf_confirminfo',
      did,
      dealerUserId,
    });
    dispatchChangeWellsFargoValue('isReview', false);
    // eslint-disable-next-line
  }, []);

  const editPage = page => {
    dispatchChangeWellsFargoValue('isReview', true);
    dispatchChangePage(page);
  };

  const mindfireRequest = {
    email: wellsFargoInformation.email,
    lastPage: '5011',
    dealerUserId: dealerUserId,
    did: did,
  };

  const handleSubmit = () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Continue',
      did,
      dealerUserId,
    });

    const request = {
      consumerId: consumerId,
      email: wellsFargoInformation.email || null,
      borrowedAmount:
        wellsFargoInformation.borrowedAmount !== null
          ? Math.floor(wellsFargoInformation.borrowedAmount)
          : null,
      firstName: wellsFargoInformation.firstName || null,
      lastName: wellsFargoInformation.lastName || null,
      middleName: wellsFargoInformation.middleName || null,
      phoneNumber: wellsFargoInformation.phoneNumber || null,
      street: wellsFargoInformation.street || null,
      city: wellsFargoInformation.city || null,
      zipcode: wellsFargoInformation.zipcode || null,
      state: wellsFargoInformation.state || null,
      applicantStreet: applicantAddress ? applicantAddress.street : null,
      applicantCity: applicantAddress ? applicantAddress.city : null,
      applicantZip: applicantAddress ? applicantAddress.zip : null,
      applicantState: applicantAddress ? applicantAddress.state : null,
      airConditioningSystem: wellsFargoInformation.airConditioningSystem,
      isHomeowner: wellsFargoInformation.isHomeowner,
      dealerUserId: dealerUserId || null,
      coapplicantFirstName: wellsFargoInformation.hasCoApp
        ? wellsFargoInformation.coAppFirstName
        : null,
      coapplicantLastName: wellsFargoInformation.hasCoApp
        ? wellsFargoInformation.coAppLastName
        : null,
      coapplicantMiddleName: wellsFargoInformation.hasCoApp
        ? wellsFargoInformation.coAppMiddleInitial
        : null,
      coapplicantEmail: wellsFargoInformation.hasCoApp ? wellsFargoInformation.coAppEmail : null,
      coapplicantStreet: wellsFargoInformation.hasCoApp ? wellsFargoInformation.coAppStreet : null,
      coapplicantCity: wellsFargoInformation.hasCoApp ? wellsFargoInformation.coAppCity : null,
      coapplicantZipcode: wellsFargoInformation.hasCoApp
        ? wellsFargoInformation.coAppZipCode
        : null,
      coapplicantState: wellsFargoInformation.hasCoApp ? wellsFargoInformation.coAppState : null,
      selectedFinancier: selectedFinancierEnum.WELLS_FARGO.value,
      emulatorId: emulatorId,
      track_id: track_id,
      poBox: wellsFargoInformation.poBox || null,
      coAppPoBox: wellsFargoInformation.coAppPoBox ? wellsFargoInformation.coAppPoBox : null,
    };

    dispatchSubmitMindfire(mindfireRequest);

    dispatchSubmitWellsFargoApplication(request, app);

    dispatchChangeWellsFargoValue('submited', true);
  };

  const ApplicationReviewForm = () => (
    <Form>
      <Row className="review py-3">
        <Col>
          <h5>
            <b>Consent</b>
          </h5>
          <p>
            I acknowledge that, by checking the box below and pressing “continue”, the information
            on this page will be sent to Wells Fargo for the purpose of completing a credit
            application.
          </p>
          <Field
            component={Checkbox}
            name="applicantConsent"
            id="applicantConsent"
            label="Applicant"
            labelClassName="bold mb-0"
          />
          {wellsFargoInformation.hasCoApp && (
            <Field
              component={Checkbox}
              name="coApplicantConsent"
              id="coApplicantConsent"
              label="Co-Applicant"
              labelClassName="bold mb-0"
            />
          )}
        </Col>
      </Row>
      <Button type="submit" title="Continue" />
    </Form>
  );

  const ApplicationReviewFormFormik = withFormik({
    mapPropsToValues: () => ({
      applicantConsent: false,
      coApplicantConsent: false,
    }),

    validationSchema: Yup.object({
      applicantConsent: Yup.boolean().oneOf([true], 'Please check to continue.'),
      coApplicantConsent: wellsFargoInformation.hasCoApp
        ? Yup.boolean().oneOf([true], 'Please check to continue.')
        : Yup.boolean(),
    }),

    handleSubmit,
  })(ApplicationReviewForm);

  return (
    <Container>
      <Row>
        {!app ? (
          <BackBtn
            changePage={dispatchChangePage}
            page={
              wellsFargoInformation.hasCoApp
                ? WellsFargoFlowPageNumber.CoAppAddress
                : WellsFargoFlowPageNumber.HasCoApplicant
            }
          />
        ) : (
          <Col sm="1" />
        )}
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <Row className="review">
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col xs="8" lg="9">
                  <h5>Applicant’s Information</h5>
                </Col>
                {!app ? (
                  <Col xs="4" lg="3">
                    <Button
                      className="edit"
                      clickHandler={() => editPage(WellsFargoFlowPageNumber.AccountEmail)}
                      title="Edit"
                    />
                  </Col>
                ) : null}
              </Row>
              <Row>
                <Col xs="6">
                  <b>Name</b>
                  {(wellsFargoInformation.firstName ? wellsFargoInformation.firstName + ' ' : '') +
                    (wellsFargoInformation.middleName
                      ? wellsFargoInformation.middleName + ' '
                      : '') +
                    (wellsFargoInformation.lastName || '')}
                </Col>
                <Col xs="6">
                  <b>Home Phone Number</b>
                  {!!wellsFargoInformation.phoneNumber
                    ? wellsFargoInformation.phoneNumber.length > 6
                      ? '(' +
                        wellsFargoInformation.phoneNumber.substring(0, 3) +
                        ') ' +
                        wellsFargoInformation.phoneNumber.substring(3, 6) +
                        '-' +
                        wellsFargoInformation.phoneNumber.substring(6)
                      : wellsFargoInformation.phoneNumber
                    : 'N/A'}
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <b>Email Address</b>
                  <span className="text-break">{wellsFargoInformation.email || 'N/A'}</span>
                </Col>
                <Col xs="6">
                  {wellsFargoInformation.borrowedAmount && (
                    <>
                      <b>Estimated Purchase Amount</b>
                      <NumberFormat
                        value={wellsFargoInformation.borrowedAmount}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        prefix={'$'}
                        displayType="text"
                      />
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <b>Applicant’s Address</b>
                  {(wellsFargoInformation.street ? wellsFargoInformation.street + ', ' : '') +
                    (wellsFargoInformation.city ? wellsFargoInformation.city + ', ' : '') +
                    (wellsFargoInformation.state ? wellsFargoInformation.state + ', ' : '') +
                    (wellsFargoInformation.zipcode || '')}
                  {wellsFargoInformation.poBox ? <p>Po Box: {wellsFargoInformation.poBox}</p> : ''}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="review">
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col xs="8" lg="9">
                  <h5>Co-Applicant’s Information</h5>
                </Col>
                {!app ? (
                  <Col xs="4" lg="3">
                    <Button
                      className="edit"
                      clickHandler={() => editPage(WellsFargoFlowPageNumber.HasCoApplicant)}
                      title={wellsFargoInformation.hasCoApp ? 'Edit' : 'Add'}
                    />
                  </Col>
                ) : null}
              </Row>
              {wellsFargoInformation.hasCoApp ? (
                <>
                  <Row>
                    <Col xs="6">
                      <b>Name</b>
                      {(wellsFargoInformation.coAppFirstName
                        ? wellsFargoInformation.coAppFirstName + ' '
                        : '') +
                        (wellsFargoInformation.coAppMiddleInitial
                          ? wellsFargoInformation.coAppMiddleInitial + ' '
                          : '') +
                        (wellsFargoInformation.coAppLastName || '')}
                    </Col>
                    <Col xs="6" />
                  </Row>
                  <Row>
                    <Col xs="6">
                      <b>Email Address</b>
                      {wellsFargoInformation.coAppEmail || 'N/A'}
                    </Col>
                    <Col xs="6" />
                  </Row>
                  <Row>
                    <Col>
                      <b>Co-Applicant’s Address</b>
                      {(wellsFargoInformation.coAppStreet
                        ? wellsFargoInformation.coAppStreet + ', '
                        : '') +
                        (wellsFargoInformation.coAppCity
                          ? wellsFargoInformation.coAppCity + ', '
                          : '') +
                        (wellsFargoInformation.coAppState
                          ? wellsFargoInformation.coAppState + ', '
                          : '') +
                        (wellsFargoInformation.coAppZipCode || '')}
                      {wellsFargoInformation.coAppPoBox ? (
                        <p>Po Box: {wellsFargoInformation.coAppPoBox}</p>
                      ) : (
                        ''
                      )}
                    </Col>
                  </Row>
                </>
              ) : null}
            </Col>
          </Row>
          <ApplicationReviewFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  consumerId: state.auth.consumerId,
  applicantAddress: state.wellsFargoInformation.applicantAddress,
  wellsFargoInformation: state.wellsFargoInformation,
  softPull: state.softPull,
  app: state.softPull.applicationSubmitDTO,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  emulatorId: state.auth.emulatorId,
  track_id: state.auth.track_id,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeWellsFargoValue: (key, value) => dispatch(changeWellsFargoValue(key, value)),
  dispatchSubmitWellsFargoApplication: (params, app) =>
    dispatch(submitWellsFargoApplication(params, app)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

Review.propTypes = {
  dispatchChangePage: PropTypes.func,
  dispatchChangeWellsFargoValue: PropTypes.func,
  dispatchSubmitSoftPullAquaApplication: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Review);
