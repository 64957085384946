import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, Button } from 'reactstrap';
import { getAllTransactions, getAtWaterGreenskyAllTransactions } from '../../../redux/actions';
import TransactionHistoryTable from './TransactionHistoryTable';
import { CSVLink } from 'react-csv';
import ReactPaginate from 'react-paginate';
import { getIsPool } from '../../../config/settings';

const TransactionHistory = props => {
  const pageLimit = 20;
  const {
    auth,
    history,
    dispatchGetAllTransactions,
    dispatchGetAllAtWaterGreenskyTransactions,
    allTransactions,
  } = props;
  const pageCount = allTransactions && allTransactions.total / pageLimit;
  const searchOption = { pageLimit, pageOffset: 0 };

  const headers = [
    { label: 'Application Id', key: 'greesnkyApplicationId' },
    { label: 'Submission Date', key: 'submissionDate' },
    { label: 'Last Name', key: 'lastName' },
    { label: 'First Name', key: 'firstName' },
    { label: 'Txn Amount', key: 'txnAmount' },
    { label: 'Plan Fee Amount', key: 'planFeeAmount' },
    { label: 'Net Amount', key: 'netAmount' },
    { label: 'Plan Fee (%)', key: 'planFeePercent' },
    { label: 'Status', key: 'status' },
  ];

  const prettyLink = {
    color: '#fff',
  };

  useEffect(() => {
    if (!auth.user || !auth.user.dealerUser) {
      history.push('/login');
    }
    if (auth.user && auth.user.dealerUser) {
      if (getIsPool()) {
        dispatchGetAllAtWaterGreenskyTransactions(searchOption);
      } else {
        dispatchGetAllTransactions(searchOption);
      }
    }

    // eslint-disable-next-line
  }, []);

  const handlePageClick = pageNumber => {
    if (getIsPool()) {
      dispatchGetAllAtWaterGreenskyTransactions({
        ...searchOption,
        pageOffset: pageNumber.selected * pageLimit,
      });
    } else {
      dispatchGetAllTransactions({
        ...searchOption,
        pageOffset: pageNumber.selected * pageLimit,
      });
    }
  };

  return (
    <Col>
      <Row>
        <Col>
          <h1>Transactions</h1>
        </Col>
      </Row>
      <Row className="panel application-history mx-0">
        <Col>
          {allTransactions &&
          allTransactions.transactions &&
          allTransactions.transactions.length > 0 ? (
            <>
              <Row className="overflow-auto">
                <TransactionHistoryTable
                  transactionHistory={allTransactions.transactions}
                  history={history}
                />
              </Row>
              <Row className="justify-content-between align-items-center">
                <Col xs="12" md="auto">
                  {pageCount > 1 && (
                    <ReactPaginate
                      previousLabel={'<'}
                      nextLabel={'>'}
                      breakClassName={'break-me'}
                      breakLinkClassName={'page-link'}
                      breakLabel={'...'}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={'pagination'}
                      subContainerClassName={'pages pagination'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      activeClassName={'active'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                    />
                  )}
                </Col>
                <Col sm="12" xs="12" md="4">
                  <Row className="justify-content-end">
                    <Col md="auto">
                      <Button
                        type="submit"
                        color="secondary"
                        className="rounded-pill w-100 mt-1"
                        outline
                        onClick={() => history.push('/portal/dashboard')}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col md="auto">
                      <Form
                        target="_blank"
                        title="formRevisitMicrofApp"
                        method="get"
                        action="/"
                        className="inline"
                      >
                        {
                          <Button type="submit" color="primary" className="rounded-pill w-100 mt-1">
                            <CSVLink
                              data={allTransactions.transactions}
                              headers={headers}
                              filename="GreenskyTransactions.csv"
                              style={prettyLink}
                            >
                              Export CSV
                            </CSVLink>
                          </Button>
                        }
                      </Form>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          ) : (
            <div className="app-panel">No applications found.</div>
          )}
        </Col>
      </Row>
    </Col>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  applicationsHistory: state.softPull.applicationsHistory,
  allTransactions: state.softPull.allTransactions,
});

const mapDispatchToProps = dispatch => ({
  dispatchGetAllTransactions: searchOption => dispatch(getAllTransactions(searchOption)),
  dispatchGetAllAtWaterGreenskyTransactions: searchOption =>
    dispatch(getAtWaterGreenskyAllTransactions(searchOption)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TransactionHistory);
