import { Field, Form, withFormik } from 'formik';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { GoSearch } from 'react-icons/go';
import { connect } from 'react-redux';
import { Button, Col, Label, Row } from 'reactstrap';
import InputField from '../../../components/UI/Form/Input/InputField';
import { EMAIL_TOOLTIP_TEXT } from '../../../constants/tooltipTexts';
import { changeSoftPullValue } from '../../../redux/actions/index';
import { initialState } from '../../../redux/initialState';

const ContractorUsersSearch = props => {
  const { searchContractorUsers, searchOptionUsers, dispatchChangeSoftPullValue } = props;
  const [show, setShow] = useState(true);

  const search = queryString.parse(window.location.search).search;

  useEffect(() => {
    if (search) {
      searchContractorUsers(searchOptionUsers);
    }
    // eslint-disable-next-line
  }, []);

  const handleReset = () => {
    dispatchChangeSoftPullValue('searchOptionUsers', initialState.softPull.searchOptionUsers);
  };

  const CompleteFundMicrofApplicationForm = () => (
    <div className="search-panel">
      <Form>
        <Row>
          <Col sm="6">
            <div className="form-group">
              <Label for="lastName">Last Name</Label>
              <Field component={InputField} name="lastName" id="lastName" type="text" />
            </div>
          </Col>
          <Col sm="6">
            <div className="form-group">
              <Label for="firstName">First Name</Label>
              <Field component={InputField} name="firstName" id="firstName" type="text" />
            </div>
          </Col>
          <Col sm="6">
            <div className="form-group">
              <Label for="email">Email</Label>
              <Field
                component={InputField}
                name="email"
                id="email"
                tooltipText={EMAIL_TOOLTIP_TEXT}
                type="text"
              />
            </div>
          </Col>
          <Col sm="6">
            <div className="form-group">
              <Label for="dealer">Contractor</Label>
              <Field component={InputField} name="dealer" id="dealer" type="text" />
            </div>
          </Col>
          <Col sm="6">
            <div className="form-group">
              <Label for="dealerId">Dealer ID</Label>
              <Field component={InputField} type="number" name="dealerId" id="dealerId" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="text-right">
            <Button
              type="reset"
              color="secondary"
              className="rounded-pill"
              outline
              onClick={() => handleReset()}
            >
              Clear
            </Button>
            <Button type="submit" color="primary" className="rounded-pill">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );

  const CompleteFundMicrofApplicationFormFormik = withFormik({
    mapPropsToValues: () => ({
      lastName: searchOptionUsers.lastName,
      firstName: searchOptionUsers.firstName,
      email: searchOptionUsers.email,
      dealer: searchOptionUsers.dealer,
      dealerId: searchOptionUsers.dealerId,
    }),

    handleSubmit: values => {
      const searchOptions = {
        ...searchOptionUsers,
        firstName: values.firstName,
        lastName: values.lastName,
        dealer: values.dealer,
        email: values.email,
        dealerId: values.dealerId,
      };
      dispatchChangeSoftPullValue('searchOptionUsers', searchOptions);
      searchContractorUsers(searchOptions);
    },
  })(CompleteFundMicrofApplicationForm);

  return (
    <div className="search-form">
      <div className="search-btn">
        <span onClick={() => setShow(!show)}>
          <GoSearch size={18} />
          <span>Search Criteria</span>
        </span>
      </div>
      {show ? <CompleteFundMicrofApplicationFormFormik /> : null}
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  application: state.softPull.application,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangeSoftPullValue: (key, value) => dispatch(changeSoftPullValue(key, value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContractorUsersSearch);
