import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import AirConditioningSystemEnum from '../../../assets/js/AirConditioningSystemEnum';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import CurrencyFormat from '../../../components/UI/Form/NumberFormat/CurrencyFormat';
import Select from '../../../components/UI/Form/Select/Select';
import {
  ESTIMATED_PROJECT_COST_TOOLTIP_TEXT,
  PROJECT_TYPE_TOOLTIP_TEXT,
} from '../../../constants/tooltipTexts';
import { isHvacProjectType, reactGAEvent, reactGAPageview } from '../../../helper';
import { changePage, changeEnerBankInfoValue, submitMindfire } from '../../../redux/actions/index';
import { EnerBankFlowPageNumber } from './EnerBankFlow';

const ProjectDetail = props => {
  const {
    borrowedAmount,
    projectType,
    dispatchChangePage,
    dispatchChangeInfoValue,
    children,
    did,
    dealerUserId,
    dispatchSubmitMindfire,
    isReview,
    airConditioningSystem,
    programProduct: { minimumAmount, maximumAmount },
    programProjectTypes,
  } = props;

  let projectTypes = programProjectTypes.filter(obj => !obj.leasable);
  projectTypes =
    airConditioningSystem === AirConditioningSystemEnum.AIR_CONDITION_OR_HEATING_SYSTEM.id
      ? programProjectTypes.filter(isHvacProjectType)
      : programProjectTypes;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_ebprojectdetail',
      trackerNames: [],
      title: 'ca_ebprojectdetail',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const ProjectDetailForm = () => (
    <Form>
      <Field
        component={CurrencyFormat}
        label="Estimated Project Cost"
        name="borrowedAmount"
        id="borrowedAmount"
        tooltipText={ESTIMATED_PROJECT_COST_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={Select}
        label="Project Type"
        name="projectType"
        id="projectType"
        tooltipText={PROJECT_TYPE_TOOLTIP_TEXT}
        type="select"
        selectValues={projectTypes}
        selectFirstName="Choose One"
      />
      <Button type="submit" title="Next" />
    </Form>
  );

  const ProjectDetailFormFormik = withFormik({
    mapPropsToValues: () => ({
      borrowedAmount: borrowedAmount || '',
      projectType: projectType || '',
    }),

    validationSchema: Yup.object({
      borrowedAmount: Yup.number()
        .moreThan(minimumAmount, `Estimated Project Cost should be higher than $${minimumAmount}.`)
        .lessThan(maximumAmount, `Estimated Project Cost should be lower than $${maximumAmount}.`)
        .required('Please enter Estimated Project Cost.'),
      projectType: Yup.string().required('Please select Project Type.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      dispatchSubmitMindfire({
        lastPage: '11012',
        dealerUserId: dealerUserId,
        did: did,
      });
      dispatchChangeInfoValue('borrowedAmount', values.borrowedAmount);
      dispatchChangeInfoValue('projectType', values.projectType);
      dispatchChangePage(
        isReview
          ? EnerBankFlowPageNumber.ApplicationReview
          : EnerBankFlowPageNumber.AppIdentificationInfo,
      );
    },
  })(ProjectDetailForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={
            isReview
              ? EnerBankFlowPageNumber.ApplicationReview
              : EnerBankFlowPageNumber.LoanInformation
          }
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <ProjectDetailFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  borrowedAmount: state.enerBankInformation.borrowedAmount,
  isReview: state.enerBankInformation.isReview,
  projectType: state.enerBankInformation.projectType,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  programProduct: state.enerBankInformation.programProduct || {},
  programProjectTypes: state.enerBankInformation.programProjectTypes || [],
  airConditioningSystem: state.preQualificationInformation.airConditioningSystem,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeInfoValue: (key, value) => dispatch(changeEnerBankInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectDetail);
