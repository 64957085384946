import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select';

const MultiSelectIdentityVerification = ({
  id,
  className,
  label,
  labelClassName,
  selectClassName,
  disabled,
  options,
  selectedValues,
  onChange,
  isMulti,
  errorMessage,
}) => (
  <FormGroup className={className}>
    <Label for={id} className={labelClassName}>
      <b>{label}</b>
    </Label>
    <Select
      isMulti={isMulti}
      name="colors"
      options={options}
      className={`basic-multi-select ${selectClassName}`}
      value={selectedValues}
      onChange={onChange}
      classNamePrefix="select"
      isDisabled={disabled}
    />
    {errorMessage && <p className="text-danger mt-2">{errorMessage}</p>}
  </FormGroup>
);

export default MultiSelectIdentityVerification;
