import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import Button from '../../../components/UI/Button/CustomButton';
import Checkbox from '../../../components/UI/Form/Checkbox/Checkbox';
import { reactGAEvent, reactGAPageview } from '../../../helper';
import { changePage, submitMindfire, changeEnerBankInfoValue } from '../../../redux/actions/index';
import { EnerBankFlowPageNumber } from './EnerBankFlow';
import enerBank from '../../../assets/img/ener_bank_usa.png';
import Checkmark from '../../../components/UI/Row/Checkmark/Checkmark';
import { ENERBANK_APPLICANTCONSENT_TOOLTIP_TEXT } from '../../../constants/tooltipTexts';

const Consent = props => {
  const {
    dispatchChangePage,
    consent,
    dispatchChangeInfoValue,
    did,
    dealerUserId,
    dispatchSubmitMindfire,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_ebconsent',
      trackerNames: [],
      title: 'ca_ebconsent',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Continue',
      did,
      dealerUserId,
    });
    dispatchSubmitMindfire({
      lastPage: '11009',
      dealerUserId: dealerUserId,
      did: did,
    });
    dispatchChangeInfoValue('consent', true);
    dispatchChangePage(EnerBankFlowPageNumber.Eligibility);
  };

  const ConsentForm = () => (
    <Form noValidate autoComplete="off">
      <Field
        component={Checkbox}
        name="consent"
        id="consent"
        tooltipText={ENERBANK_APPLICANTCONSENT_TOOLTIP_TEXT}
        label="Applicant"
        labelClassName="bold mb-0"
      />
      <Button type="submit" title={'Continue'} />
      <Row className="justify-content-center mt-5">
        <Col xs="12" md="8">
          <div className="border p-3">
            <p className="bold">
              To complete this application, you’ll need the following information provided by your
              contractor:
            </p>
            <Checkmark>
              <p>Loan Code</p>
            </Checkmark>
            <Checkmark>
              <p>Contractor ID</p>
            </Checkmark>
            <Checkmark>
              <p>Loan Amount</p>
            </Checkmark>
          </div>
        </Col>
      </Row>
    </Form>
  );

  const ConsentFormFormik = withFormik({
    mapPropsToValues: () => ({
      consent: consent,
    }),

    validationSchema: Yup.object({
      consent: Yup.boolean().oneOf([true], 'Please check to continue.'),
    }),

    handleSubmit,
  })(ConsentForm);

  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs="auto" sm="11">
          <Row className="justify-content-center">
            <Col xs="auto">
              <h3>Nice! You have been prequalified for a lending option with:</h3>
            </Col>
          </Row>
          <Row className="justify-content-center mt-5">
            <Col xs="auto">
              <img alt="EnerBank USA" src={enerBank} width="300px" />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs="auto">
              <p className="mb-0">
                <b>Registered trademark of EnerBank USA and used with permission.</b>
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center mt-5">
            <Col md="8">
              <h4>Consent</h4>
              <p>
                I acknowledge that by checking the box below and pressing continue that the
                information on this application will be sent to EnerBank for the purpose of
                completing a credit application.
              </p>
              <ConsentFormFormik />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  consent: state.enerBankInformation.consent,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeInfoValue: (key, value) => dispatch(changeEnerBankInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Consent),
);
