import React, { useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { getApplicationsHistory } from '../../../redux/actions';
import ApplicationsHistoryTable from './ApplicationsHistoryTable';
import { getBaseURL } from '../../../config/settings';

const ApplicationHistory = props => {
  const pageLimit = 20;
  const {
    auth,
    history,
    dispatchGetApplicationsHistory,
    applicationsHistory,
    dealerInformationDTOs,
  } = props;
  const pageCount = applicationsHistory && applicationsHistory.total / pageLimit;
  const searchOption = { pageLimit, pageOffset: 0, searchApplication: true };
  const baseURL = getBaseURL();

  useEffect(() => {
    if (!auth.user || !auth.user.dealerUser) {
      history.push('/login');
    }
    if (auth.user && auth.user.dealerUser) {
      dispatchGetApplicationsHistory(searchOption);
    }
    // eslint-disable-next-line
  }, []);

  const handlePageClick = pageNumber => {
    dispatchGetApplicationsHistory({
      ...searchOption,
      pageOffset: pageNumber.selected * pageLimit,
    });
  };

  return (
    <Col className="pb-3">
      <Row>
        <Col>
          <h1>Application History Report</h1>
        </Col>
      </Row>
      <Row className="panel application-history mx-0">
        <Col>
          {dealerInformationDTOs && dealerInformationDTOs.length > 0 ? (
            <>
              <Row className="overflow-auto">
                <ApplicationsHistoryTable applicationsHistory={dealerInformationDTOs} />
              </Row>
              <Row className="justify-content-between align-items-center">
                {pageCount > 1 && (
                  <Col xs="12" md="auto">
                    <ReactPaginate
                      previousLabel={'<'}
                      nextLabel={'>'}
                      breakClassName={'break-me'}
                      breakLinkClassName={'page-link'}
                      breakLabel={'...'}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={'pagination'}
                      subContainerClassName={'pages pagination'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      activeClassName={'active'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                    />
                  </Col>
                )}

                <Col xs="12" md="auto">
                  <a
                    href={`${baseURL}/api/self/dealer/information-csv`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-primary rounded-pill w-100"
                  >
                    Export CSV
                  </a>
                </Col>
              </Row>
            </>
          ) : (
            <div className="app-panel">No applications found.</div>
          )}
        </Col>
      </Row>
    </Col>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  applicationsHistory: state.softPull.applicationsHistory,
  dealerInformationDTOs:
    state.softPull.applicationsHistory && state.softPull.applicationsHistory.dealerInformationDTOs,
});

const mapDispatchToProps = dispatch => ({
  dispatchGetApplicationsHistory: dealerUserId => dispatch(getApplicationsHistory(dealerUserId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApplicationHistory);
