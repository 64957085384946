import React from 'react';
import Consent from './Consent';
import Eligibility from './Eligibility';
import LoanInformation from './LoanInformation';
import AppIdentificationInfo from './AppIdentificationInfo';
import AppEmploymentDetails from './AppEmploymentDetails';
import HasCoApplicant from './HasCoApplicant';
import CoAppInfo from './CoAppInfo';
import CoAppAddress from './CoAppAddress';
import CoAppIdentificationInfo from './CoAppIdentificationInfo';
import CoAppEmploymentDetails from './CoAppEmploymentDetails';
import ApplicationReview from './ApplicationReview';
import ApplicationResult from './ApplicationResult';
import ProjectDetail from './ProjectDetail';
import MailingAddress from './MailingAddress';
import ApplicationSubmit from './ApplicationSubmit';
import DeliveryMethod from './DeliveryMethod';
import NotificationMethod from './NotificationMethod';

const applicantInformationTitle = `Applicant Information`;
const coApplicantInformationTitle = 'Co-Applicant Information';
const applicationStatusTitle = 'Application Status';

const EnerBankFlow = [
  //1
  <Consent title={applicationStatusTitle} />,
  //2
  <Eligibility title={applicantInformationTitle}>
    <h4>Answer some questions for Eligibility.</h4>
  </Eligibility>,
  //3
  <LoanInformation title={applicantInformationTitle}>
    <h4>Please enter the loan information provided by your contractor.</h4>
  </LoanInformation>,
  //4
  <ProjectDetail title={applicantInformationTitle}>
    <h4>Please enter the project information provided by your contractor.</h4>
  </ProjectDetail>,
  //5
  <AppIdentificationInfo title={applicantInformationTitle} />,
  //6
  <AppEmploymentDetails title={applicantInformationTitle}>
    <h4>Tell us about your income.</h4>
    <p>
      Please disclose your annual income from all sources. Please be advised that alimony, child
      support or separate maintenance income do not need to be disclosed if you don’t want EnerBank
      to consider it.
    </p>
  </AppEmploymentDetails>,
  //7
  <MailingAddress title={applicantInformationTitle}>
    <h4>Is your Mailing Address the same as the Installation Address?</h4>
  </MailingAddress>,
  //8
  <HasCoApplicant title={coApplicantInformationTitle}>
    <h4>Would you like to add a Co-Applicant?</h4>
  </HasCoApplicant>,
  //9
  <CoAppInfo title={coApplicantInformationTitle}>
    <h4>Co-Applicant, enter your name and contact information.</h4>
  </CoAppInfo>,
  //10
  <CoAppAddress title={coApplicantInformationTitle}>
    <h4>Co-Applicant, enter your address.</h4>
  </CoAppAddress>,
  //11
  <CoAppIdentificationInfo title={coApplicantInformationTitle}>
    <h4>Home stretch! Co-Applicant, enter your date of birth and Social Security Number.</h4>
    <p>We'll need this information to verify your identity.</p>
  </CoAppIdentificationInfo>,
  //12
  <CoAppEmploymentDetails title={coApplicantInformationTitle}>
    <h4>Co-Applicant, tell us about your income.</h4>
    <p>
      Please disclose your annual income from all sources. Please be advised that alimony, child
      support or separate maintenance income do not need to be disclosed if you don’t want EnerBank
      to consider it.
    </p>
  </CoAppEmploymentDetails>,
  //13
  <ApplicationReview title={applicantInformationTitle}>
    <h4>Let’s double check your information and submit your application.</h4>
  </ApplicationReview>,
  //14
  <DeliveryMethod>
    <h4>Please provide your preferred Loan Document Delivery Method.</h4>
  </DeliveryMethod>,
  //15
  <NotificationMethod>
    <h4>Please provide your preferred Payment Request Notification Method.</h4>
    <p>
      Upon approval of your loan application, your contractor will be authorized to request
      disbursement from your line of credit. You will select your mode of communication to receive
      and authorize these request(s). You will then receive a Payment Request Notification either by
      text or phone call at the number you provide based on your selection.
    </p>
  </NotificationMethod>,
  //16
  <ApplicationSubmit title={applicationStatusTitle}>
    <h4>Whoa! We’re at the finish line.</h4>
    <p>We've got a few disclosures and consent forms and you’ll be ready to submit.</p>
  </ApplicationSubmit>,
  //17
  <ApplicationResult title={applicationStatusTitle} />,
];

export default EnerBankFlow;

export const EnerBankFlowPageNumber = {
  Consent: 0,
  Eligibility: 1,
  LoanInformation: 2,
  ProjectDetail: 3,
  AppIdentificationInfo: 4,
  AppEmploymentDetails: 5,
  MailingAddress: 6,
  HasCoApplicant: 7,
  CoAppInfo: 8,
  CoAppAddress: 9,
  CoAppIdentificationInfo: 10,
  CoAppEmploymentDetails: 11,
  ApplicationReview: 12,
  DeliveryMethod: 13,
  NotificationMethod: 14,
  ApplicationSubmit: 15,
  ApplicationResult: 16,
};
