import { AgeFromDateString } from 'age-calculator';
import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import termsMicrof from '../../../assets/pdf/Upgrade Statement of Terms and Conditions.pdf';
import statementOfUseMicrof from '../../../assets/pdf/Upgrade Statement on Use of Electronic Records.pdf';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import Checkbox from '../../../components/UI/Form/Checkbox/Checkbox';
import CustomDatePicker from '../../../components/UI/Form/DatePicker/DatePicker';
import ErrorModal from '../../../components/UI/Modal/ErrorModal';
import {
  getAddedFrom,
  getAppName,
  getDealerPortalName,
  getDealerPortalURL,
  getIsMicrof,
  getIsRheem,
  getPrivacyStatementURL,
  getUpgradePrivacyStatementURL,
} from '../../../config/settings';
import { isStateAllowed, reactGAEvent, reactGAPageview, validDateFormat } from '../../../helper';
import {
  changePage,
  changePreQualInfoValue,
  getScore,
  setFlow,
  submitMindfire,
  updatePrequalificationInformation,
  setSecondSelectedFinancerFlow,
  upgradeSubmit,
  changeSoftPullValue,
} from '../../../redux/actions/index';
import { microfFlow, preQualificationFlow } from '../../../redux/initialState';
import productTypeEnum from '../../../assets/js/ProductTypeEnum';
import { UpgradePreQualificationFlowPageNumber } from './preQualificationFlow';
import { MicrofFlowPageNumber } from '../../Microf/MicrofFlow';
import selectedFinancierEnum from '../../../assets/js/SelectedFinancierEnum';
import AirConditioningSystemEnum from '../../../assets/js/AirConditioningSystemEnum';
import {
  APPFULLSSN_TOOLTIP_TEXT,
  APPLICANTCONSENT_TOOLTIP_TEXT,
  AUTHORIZE_TOOLTIP_TEXT,
  BIRTHDAY_TOOLTIP_TEXT,
} from '../../../constants/tooltipTexts';
import SsnFormat from '../../../components/UI/Form/NumberFormat/SsnFormat';

const appName = getAppName();
const isRheem = getIsRheem();
const isMicrof = getIsMicrof();
const terms = termsMicrof;
const statementOfUse = statementOfUseMicrof;

const UserLastInfo = props => {
  const {
    pqi,
    softPullResponse,
    dispatchChangePage,
    dispatchChangeValue,
    dispatchGetScore,
    history,
    dispatchSetFlow,
    dealerUserId,
    dealerId,
    did,
    aquaFinanceAssigned,
    lead_source,
    lp_url,
    app,
    track_id,
    dispatchSubmitMindfire,
    dispatchSetSecondSelectedFinancerFlow,
    dispatchChangeSoftPullValue,
    user,
    externalReferenceId,
    softPullRequest,
    dispatchUpgradeSubmit,
  } = props;

  useEffect(() => {
    if (softPullResponse && softPullResponse.score === null)
      reactGAPageview({
        path: '/ca_consumerinfo3',
        trackerNames: [],
        title: 'ca_consumerinfo3',
        did,
        dealerUserId,
      });

    // Logic to starts flows after PreQualification
    if (softPullResponse && softPullResponse.score !== null) {
      let isRtoNotAvailable =
        !isStateAllowed(pqi.state) ||
        softPullResponse?.airConditioningSystem ===
          AirConditioningSystemEnum.OTHER_HOME_IMPROVEMENT.id;

      if (softPullResponse.score === 0) {
        dispatchChangePage(UpgradePreQualificationFlowPageNumber.InvalidResponse);
      } else if (softPullResponse.score >= softPullResponse.scoreLimit) {
        const params = {
          ...softPullRequest,
          prequalificationId: pqi.prequalificationId,
          ssn: pqi.appFullSsn,
          grossAnnualIncome: pqi.grossAmount,
        };

        dispatchUpgradeSubmit(params, history);

        dispatchChangePage(UpgradePreQualificationFlowPageNumber.Offers);
      } else if (softPullResponse.score >= softPullResponse.scoreLimit2) {
        dispatchSetSecondSelectedFinancerFlow(
          softPullResponse.secondFinancingOption,
          softPullResponse.score,
          softPullResponse.scoreLimit2,
          softPullResponse.ssnLastDigits,
          softPullResponse.state,
          softPullResponse.airConditioningSystem,
          history,
        );
      } else {
        dispatchSetFlow(microfFlow);
        if (isRheem) dispatchChangePage(MicrofFlowPageNumber.RheemDecline);
        history.push('/RTO');
      }
    }
    // eslint-disable-next-line
  }, []);

  const mindfireRequest = {
    email: pqi.email,
    lastPage: '0021',
    dealerUserId: dealerUserId,
    did: did,
  };

  const UserLastInfoForm = () => (
    <Form noValidate autoComplete="off">
      <Field
        component={CustomDatePicker}
        name="birthday"
        id="birthday"
        tooltipText={BIRTHDAY_TOOLTIP_TEXT}
        hideTooltip={true}
        label="Your Date of Birth"
      />
      {aquaFinanceAssigned || isRheem || isMicrof ? (
        <React.Fragment>
          <Field
            component={SsnFormat}
            label="Your full Social Security Number"
            name="appFullSsn"
            id="appFullSsn"
            tooltipText={APPFULLSSN_TOOLTIP_TEXT}
            hideTooltip={true}
            type="password"
            icon="lock"
          />
          <small className="form-text text-muted">
            {appName} uses security measures that comply with federal law, including data encryption
            technology, to protect your personal information from unauthorized access and use.
          </small>
          <Field
            component={Checkbox}
            name="terms"
            id="terms"
            tooltipText={APPLICANTCONSENT_TOOLTIP_TEXT}
            label={`I agree to ${appName}’s Terms & Conditions`}
            labelClassName="bold mb-0"
          />
          {isRheem ? (
            <p>
              You authorize {appName} (the technology platform provider) to immediately share all
              application information with platform lessors and you understand and acknowledge that
              you are accepting {appName}’s{' '}
              <Link to={terms} target="_blank">
                Statement of Terms & Conditions
              </Link>
              ,{' '}
              <Link to={statementOfUse} target="_blank">
                Statement on Use of Electronic Records
              </Link>
              , and{' '}
              <a href={getPrivacyStatementURL()} target="_blank" rel="noopener noreferrer">
                Privacy Statement
              </a>
              .
            </p>
          ) : (
            <p>
              I agree to {appName}'s Terms & Conditions. You authorize {appName} (the technology
              platform provider) to immediately share all application information with lending
              partners and you understand and acknowledge that you are accepting {appName}'s{' '}
              <Link to={terms} target="_blank">
                Statement of Terms & Conditions
              </Link>
              ,{' '}
              <Link to={statementOfUse} target="_blank">
                Statement on Use of Electronic Records
              </Link>
              , and{' '}
              <a href={getUpgradePrivacyStatementURL()} target="_blank" rel="noopener noreferrer">
                Privacy Statement
              </a>
              .
            </p>
          )}

          <Field
            component={Checkbox}
            name="authorize"
            id="authorize"
            tooltipText={AUTHORIZE_TOOLTIP_TEXT}
            label={`I authorize ${appName} to access my info under the Fair Credit Reporting Act`}
            labelClassName="bold mb-0"
          />
          <p>
            You understand and agree that you are providing instructions to {appName} (the
            technology platform provider), lenders, or lending partners that partner with {appName}{' '}
            to access information under the Fair Credit Reporting Act ("FCRA") from your personal
            credit profile or other information from one or more consumer reporting agencies, such
            as Equifax, Experian, or TransUnion. You authorize your information to be obtained
            solely to conduct a pre-qualification for credit. If you subsequently apply, further
            credit checks might be performed pursuant to other authorizations. After checking the
            box and proceeding, a soft inquiry of your consumer report will be performed and this
            will not affect your credit score.
          </p>
        </React.Fragment>
      ) : null}
      <Button
        type="submit"
        title={aquaFinanceAssigned || isRheem ? 'Let’s Keep Going' : 'Continue'}
      />
    </Form>
  );

  const UserLastInfoFormFormik = withFormik({
    mapPropsToValues: () => ({
      birthday: pqi.birthday || '',
      appFullSsn: pqi.appFullSsn || '',
      terms: false,
      authorize: false,
    }),

    validationSchema: Yup.object({
      birthday: Yup.string()
        .required('Please enter your date of birth.')
        .test(
          'incomplete',
          'Incomplete date',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 8,
        )
        .test('valid', 'Invalid date format.', val => val && !validDateFormat(val))
        .test(
          'age',
          'You must be at least 18 years old.',
          val => val && new AgeFromDateString(val).age >= 18,
        ),
      appFullSsn: Yup.string()
        .required('Please enter your full SSN.')
        .test(
          'len',
          'Must have 9 digits',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 9,
        )
        .typeError('Numbers only.'),
      terms: Yup.boolean().oneOf([true], 'Please check to continue.'),
      authorize: Yup.boolean().oneOf([true], 'Please check to continue.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Navigation Button',
        action: 'Clicked continue to submit form',
        label: aquaFinanceAssigned || isRheem ? 'Find the best financing option' : 'Continue',
        did,
        dealerUserId,
      });
      dispatchChangeValue('birthday', values.birthday);
      dispatchChangeValue('ssnLastDigits', values.appFullSsn.substr(values.appFullSsn.length - 4));
      dispatchChangeValue('appFullSsn', values.appFullSsn);
      dispatchChangeValue('externalReferenceId', values.externalReferenceId);
      let request = {
        email: pqi.email,
        password: pqi.password,
        borrowedAmount: pqi.jobPrice,
        totalJobPrice: pqi.jobPrice,
        firstName: pqi.firstName,
        lastName: pqi.lastName,
        middleName: pqi.middleName,
        phoneNumber: pqi.phoneNumber,
        street: pqi.street,
        city: pqi.city,
        zipcode: pqi.zipcode,
        state: pqi.state,
        birthday: values.birthday,
        ssnLastDigits: values.appFullSsn.substr(values.appFullSsn.length - 4),
        airConditioningSystem:
          pqi.airConditioningSystem ||
          (pqi.productType === productTypeEnum.HVAC.value
            ? AirConditioningSystemEnum.AIR_CONDITION_OR_HEATING_SYSTEM.id
            : AirConditioningSystemEnum.OTHER_HOME_IMPROVEMENT.id),
        isHomeowner: pqi.isHomeowner,
        dealerUserId: dealerUserId,
        dealerId: dealerId,
        did: did,
        aquaFinanceAssigned: aquaFinanceAssigned,
        prequalificationId: pqi.prequalificationId,
        leadSource: lead_source,
        lpUrl: lp_url,
        lpReferrer: document.referrer,
        prequalificationToken: pqi.prequalificationToken,
        track_id: track_id,
        selectedFinancier: selectedFinancierEnum.UPGRADE.value,
        environment: appName,
        addedFrom: getAddedFrom(),
        productType: pqi.productType,
        externalReferenceId: externalReferenceId,
        ssn: values.appFullSsn,
        grossAnnualIncome: pqi.grossAmount,
        isUpgrade: pqi.isUpgrade,
      };

      dispatchChangeSoftPullValue('softPullRequest', { ...softPullRequest, ...request });

      if (!pqi.isUpgrade) {
        dispatchSubmitMindfire(mindfireRequest);
      }

      dispatchGetScore(request, app, pqi.isConsumer, user);

      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Continue',
        did,
        dealerUserId,
      });
    },
  })(UserLastInfoForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={UpgradePreQualificationFlowPageNumber.LoanAmount}
        />
        <Col sm="5">
          <h4>Provide your date of birth and the last 4 digits of your Social Security Number.</h4>
        </Col>
        <Col sm="6">
          <UserLastInfoFormFormik />
        </Col>
      </Row>
      <ErrorModal
        isOpen={pqi.preQualInfoExists}
        messageType="User Exists"
        message={
          <>
            Your email address already exists on our system. Please log in using{' '}
            <a href={getDealerPortalURL()}>{getDealerPortalName()}</a> and complete the application
            that is already in progress.
          </>
        }
        onClick={() => {
          dispatchChangeValue('preQualInfoExists', false);
          window.location.href = getDealerPortalURL();
        }}
      />
    </Container>
  );
};

const mapStateToProps = state => ({
  app: state.softPull.applicationSubmitDTO,
  pqi: state.preQualificationInformation,
  softPullResponse: state.softPull.softPullResponse,
  dealerUserId: state.auth.dealerUserId,
  dealerId: state.auth.dealerId,
  did: state.auth.did,
  lead_source: state.auth.lead_source,
  lp_url: state.auth.lp_url,
  aquaFinanceAssigned: state.auth.aquaFinanceAssigned,
  track_id: state.auth.track_id,
  user: state.auth.user,
  externalReferenceId: state.preQualificationInformation.externalReferenceId,
  softPullRequest: state.softPull.softPullRequest,
});

const mapDispatchToProps = dispatch => ({
  dispatchUpdatePrequalificationInformation: (params, app) =>
    dispatch(updatePrequalificationInformation(params, app)),
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) => dispatch(changePreQualInfoValue(key, value)),
  dispatchGetScore: (params, app, isConsumerUser, user) =>
    dispatch(getScore(params, app, isConsumerUser, user)),
  dispatchUpgradeSubmit: (data, history) => dispatch(upgradeSubmit(data, history)),
  dispatchSetFlow: flowName => dispatch(setFlow(flowName)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchChangeSoftPullValue: (key, value) => dispatch(changeSoftPullValue(key, value)),
  dispatchSetSecondSelectedFinancerFlow: (
    secondFinancingOption,
    score,
    scoreLimit2,
    ssnLastDigits,
    state,
    airConditioningSystem,
    history,
  ) =>
    dispatch(
      setSecondSelectedFinancerFlow(
        secondFinancingOption,
        score,
        scoreLimit2,
        ssnLastDigits,
        state,
        airConditioningSystem,
        history,
      ),
    ),
});

UserLastInfo.propTypes = {
  pqi: PropTypes.object,
  softPull: PropTypes.object,
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
  dispatchGetScore: PropTypes.func,
  dispatchSetFlow: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(UserLastInfo),
);
