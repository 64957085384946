import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { resetGreenSkyState } from '../../../redux/actions/index';
import { getAppName } from '../../../config/settings';

const appName = getAppName();

const FinalMessage = props => {
  const { children, dispatchResetGreenSkyState } = props;
  useEffect(() => {
    dispatchResetGreenSkyState();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Row>
        <Col sm="1" />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <Row>
            <Col>
              <p>
                Thank you! Your application for enrollment has been successfully submitted. A
                representative from {appName} will be reaching out to you shortly.
              </p>
              <p>
                {appName} will also be sending the individual noted as the Business Manager an email
                from <a href="mailto:vetting@microf.com">vetting@microf.com</a> with a DocuSign
                package to complete the enrollment process. It includes:
              </p>
              <ul>
                <li>Contractor Agreement</li>
                <li>W-9 Form</li>
                <li>ACH form for contractor funding</li>
              </ul>
              <p>
                In the meantime, you can always call us at 1-855-498 - 8200 or email us at{' '}
                <a href="mailto:greenskysales@microf.com">greenskysales@microf.com</a> with any
                questions.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  dispatchResetGreenSkyState: () => dispatch(resetGreenSkyState()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FinalMessage);
