import axios from 'axios';
import {
  CHANGE_RHEEM_INFO_VALUE,
  SUBMIT_APPLICATION_SOFT_PULL_ERROR,
  SUBMIT_APPLICATION_SOFT_PULL_SUCCESS,
} from './actionTypes';
import { baseURL, handleApiError } from './api';
import { changePage, setFlow } from './naviActions';
import { addNotification, endLoading, startLoading } from './notificationActions';
import { isRheemApproved, isRheemDeclined } from '../../assets/js/ApplicationStatusEnum';
import { microfFlow, preQualificationFlow } from '../initialState';
import { changePreQualInfoValue } from './preQualInfoActions';
import { isSameAddress } from '../../helper';
import { AquaPreQualificationFlowPageNumber } from '../../pages/PreQualification/preQualificationFlow';
import { MicrofFlowPageNumber } from '../../pages/Microf/MicrofFlow';
import { checkErrors, mapApplicationToPrequalification } from './softPullActions';
import AirConditioningSystemEnum from '../../assets/js/AirConditioningSystemEnum';

axios.defaults.withCredentials = true;

export const changeRheemInfoValue = (key, value) => ({
  type: CHANGE_RHEEM_INFO_VALUE,
  key,
  value,
});

export const mapAppDtoToRheem = app => dispatch => {
  if (app) {
    dispatch(mapApplicationToPrequalification(app));

    // Microf
    dispatch(changeRheemInfoValue('totalPrice', app.totalJobPrice));
    dispatch(changeRheemInfoValue('requestedLoanAmount', app.totalFinancingAmount));
    dispatch(changeRheemInfoValue('homeownerInsurance', app.homeownerInsurance));
    dispatch(changeRheemInfoValue('isHomeowner', true));

    if (app.applicantPrimary) {
      const applicant = app.applicantPrimary;
      dispatch(changePreQualInfoValue('firstName', applicant.nameFirst));
      dispatch(changePreQualInfoValue('middleName', applicant.nameMiddle));
      dispatch(changePreQualInfoValue('lastName', applicant.nameLast));
      dispatch(
        changePreQualInfoValue(
          'phoneNumber',
          applicant.mobilePhone ? applicant.mobilePhone.number : null,
        ),
      );
      dispatch(changePreQualInfoValue('birthday', applicant.birthDate || null));
      dispatch(
        changeRheemInfoValue(
          'appGrossAnnualIncome',
          applicant.monthlyNetIncome > 0
            ? Math.round((applicant.monthlyNetIncome / 0.83) * 12)
            : null,
        ),
      );

      dispatch(changeRheemInfoValue('appFullSSN', applicant.ssn));
    }
  }
  if (app.applicantNonPrimary) {
    const coapp = app.applicantNonPrimary;
    dispatch(changeRheemInfoValue('hasCoApp', true));
    dispatch(changeRheemInfoValue('relationshipWithCoapplicant', coapp.relationshipToPrimary));
    dispatch(changeRheemInfoValue('coAppFirstName', coapp.nameFirst));
    dispatch(changeRheemInfoValue('coAppMiddleInitial', coapp.nameMiddle));
    dispatch(changeRheemInfoValue('coAppLastName', coapp.nameLast));
    dispatch(
      changeRheemInfoValue('coAppPhoneNumber', coapp.mobilePhone ? coapp.mobilePhone.number : null),
    );
    dispatch(changeRheemInfoValue('coAppEmail', coapp.email ? coapp.email.email : null));

    dispatch(
      changeRheemInfoValue(
        'coApplicantGrossAnnualIncome',
        coapp.monthlyNetIncome > 0 ? Math.round((coapp.monthlyNetIncome / 0.83) * 12) : null,
      ),
    );

    dispatch(changeRheemInfoValue('coAppDoB', coapp.birthDate || '01/01/1900'));
    dispatch(changeRheemInfoValue('coAppFullSsn', coapp.ssn));

    if (coapp.address) {
      const address = coapp.address;
      dispatch(
        changeRheemInfoValue(
          'isSameAddress',
          isSameAddress(address, {
            street: app.propertyStreet,
            city: app.propertyCity,
            zipcode: app.propertyZip,
            state: app.propertyState,
          }),
        ),
      );
      dispatch(changeRheemInfoValue('coAppStreet', address.street));
      dispatch(changeRheemInfoValue('coAppCity', address.city));
      dispatch(changeRheemInfoValue('coAppZipCode', address.zipcode));
      dispatch(changeRheemInfoValue('coAppState', address.state));
    }
  }
};

export const submitRheemAppliction = (request, page, app, history) => async dispatch => {
  dispatch(startLoading('Give us a second while we find the best financing option for you.'));
  const url =
    app !== null
      ? `${baseURL}/api/hardpull/resubmit?appToken=${app.consumerToken}&appId=${app.applicationId}`
      : `${baseURL}/api/hardpull/submit`;

  await axios
    .post(url, request)
    .then(async response => {
      dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_SUCCESS, payload: response.data });
      if (dispatch(checkErrors(response))) {
        const { status } = response.data;
        if (isRheemApproved(status)) {
          dispatch(changePage(page));
        } else if (isRheemDeclined(status)) {
          if (
            request.airConditioningSystem !==
            AirConditioningSystemEnum.AIR_CONDITION_OR_HEATING_SYSTEM.id
          ) {
            dispatch(setFlow(preQualificationFlow));
            dispatch(changePage(AquaPreQualificationFlowPageNumber.NotAllowed));
          } else {
            dispatch(setFlow(microfFlow));
            dispatch(changePage(MicrofFlowPageNumber.RheemDecline));
            history.push('/RTO');
          }
        } else {
          dispatch(
            addNotification({
              message: 'Application status error',
              messageType: 'Error',
            }),
          );
        }
        dispatch(endLoading());
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_ERROR, payload: error });
      dispatch(endLoading());
    });
};
