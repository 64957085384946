import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import relationshipEnum from '../../../../assets/js/RelationshipEnum';
import selectedFinancierEnum from '../../../../assets/js/SelectedFinancierEnum';
import BackButton from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import Checkbox from '../../../../components/UI/Form/Checkbox/Checkbox';
import { reactGAEvent, reactGAPageview } from '../../../../helper';
import {
  getDisclosures,
  changeAtWaterApplicationInfoValue,
  changePage,
  submitAtWaterApplication,
  submitMindfire,
} from '../../../../redux/actions/index';
import { AtWaterFlowPageNumber } from './AtWaterFlow';

const ApplicationConsent = props => {
  const {
    dispatchChangePage,
    dispatchSubmitAtWaterApplication,
    applicantSubmitConsent,
    coApplicantSubmitConsent,
    dispatchChangeAtWaterApplicationInfoValue,
    did,
    dealerUserId,
    dispatchSubmitMindfire,
    softPull,
    atWaterApplicationInformation,
    emulatorId,
    track_id,
    consumerId,
    pqi,
    applicantAddress,
    app,
    dealer,
    dealerUser,
  } = props;

  const applicantName = `${softPull.softPullResponse.firstName || pqi.firstName} ${softPull
    .softPullResponse.lastName || pqi.lastName}`;
  const coApplicantName = `${atWaterApplicationInformation.coAppFirstName} ${
    atWaterApplicationInformation.coAppLastName
  }`;

  const handleSubmit = () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Submit',
      did,
      dealerUserId,
    });
    dispatchSubmitMindfire({
      lastPage: '9024',
      dealerUserId: dealerUserId,
      did: did,
    });

    dispatchChangeAtWaterApplicationInfoValue('applicantSubmitConsent', true);
    if (atWaterApplicationInformation.hasCoApp) {
      dispatchChangeAtWaterApplicationInfoValue('coApplicantSubmitConsent', true);
    }

    const softPullRequest = {
      Dealer: {
        DealerNumber: dealer.serviceFinanceDealerId,
        Name: dealer.name,
        Phone: dealer.phone,
        Amount: atWaterApplicationInformation.salesAmount,
        Down: atWaterApplicationInformation.downPayment,
      },
      SalesMan: {
        Name: dealerUser.nameFirst + ' ' + dealerUser.nameLast,
        Email: dealerUser.email,
        Phone: dealer.phone,
      },
      PrimaryApplicant: {
        FirstName: softPull.softPullResponse.firstName || pqi.firstName,
        MiddleName: softPull.softPullResponse.middleName || pqi.middleName,
        LastName: softPull.softPullResponse.lastName || pqi.lastName,
        DateOfBirth: softPull.softPullResponse.birthday || pqi.birthday,
        SSN: atWaterApplicationInformation.appSsn,
        EmailAddress: softPull.softPullResponse.email || pqi.email,
        HomePhone: softPull.softPullResponse.phoneNumber || pqi.phoneNumber,
        Employer: {
          Name: atWaterApplicationInformation.employerName,
          Income: atWaterApplicationInformation.grossMonthlyIncome,
        },
        IsUSCitizen: atWaterApplicationInformation.isUsCitizen ? 1 : 0,
        Married: 0,
        SpouseContact: '',
        SpouseName: '',
        Address: {
          StreetAddress: atWaterApplicationInformation.primaryAddressStreet,
          StreetAddress2: atWaterApplicationInformation.primaryAddressApartmentNumber,
          City: atWaterApplicationInformation.primaryAddressCity,
          State: atWaterApplicationInformation.primaryAddressState,
          Zip: atWaterApplicationInformation.primaryAddressZipCode,
        },
        Property: {
          Type: 'Single Family',
          Address: {
            StreetAddress: softPull.softPullResponse.street || pqi.street,
            StreetAddress2: '',
            City: softPull.softPullResponse.city || pqi.city,
            State: softPull.softPullResponse.state || pqi.state,
            Zip: softPull.softPullResponse.zipcode || pqi.zipcode,
          },
        },
        IDType: atWaterApplicationInformation.applicantIdType,
        DriversLicenseState: atWaterApplicationInformation.idIssuedState,
        DriversLicenseNo: atWaterApplicationInformation.idNumber,
        DriversLicenseIssueDate: atWaterApplicationInformation.appIdIssueDate,
        DriversLicenseExpDate: atWaterApplicationInformation.appIdExpirationDate,
        OtherDocument: atWaterApplicationInformation.applicantIdType,
        VisaIssueDate: null,
        OtherExpDate: null,
        MonthlyHousingPayment: 980,
        HousingType: 'Own',
      },

      CoApplicant: atWaterApplicationInformation.hasCoApp
        ? {
            FirstName: atWaterApplicationInformation.hasCoApp
              ? atWaterApplicationInformation.coAppFirstName
              : null,
            MiddleName: atWaterApplicationInformation.hasCoApp
              ? atWaterApplicationInformation.coAppMiddleInitial
              : null,
            LastName: atWaterApplicationInformation.hasCoApp
              ? atWaterApplicationInformation.coAppLastName
              : null,
            DateOfBirth: atWaterApplicationInformation.hasCoApp
              ? atWaterApplicationInformation.coAppDoB
              : null,
            SSN: atWaterApplicationInformation.hasCoApp
              ? atWaterApplicationInformation.coAppSsn
              : null,
            EmailAddress: atWaterApplicationInformation.hasCoApp
              ? atWaterApplicationInformation.coAppEmail
              : null,
            HomePhone: atWaterApplicationInformation.hasCoApp
              ? atWaterApplicationInformation.coAppPhoneNumber
              : null,
            Employer: {
              Name: atWaterApplicationInformation.coAppEmployerName,
              Income: atWaterApplicationInformation.coAppGrossMonthlyIncome,
            },
            IsUSCitizen: atWaterApplicationInformation.isCoAppUsCitizen ? 1 : 0,
            Married: 0,
            SpouseContact: '',
            SpouseName: '',
            Address: {
              StreetAddress: atWaterApplicationInformation.coAppStreet,
              StreetAddress2: atWaterApplicationInformation.coAppApartmentNumber,
              City: atWaterApplicationInformation.coAppCity,
              State: atWaterApplicationInformation.coAppState,
              Zip: atWaterApplicationInformation.coAppZipCode,
            },
            Property: {
              Type: 'Single Family',
              Address: {
                StreetAddress: atWaterApplicationInformation.coAppStreet,
                StreetAddress2: atWaterApplicationInformation.coAppApartmentNumber,
                City: atWaterApplicationInformation.coAppCity,
                State: atWaterApplicationInformation.coAppState,
                Zip: atWaterApplicationInformation.coAppZipCode,
              },
            },
            IDType: atWaterApplicationInformation.coAppIdType,
            DriversLicenseState: atWaterApplicationInformation.coAppIdIssuedState,
            DriversLicenseNo: atWaterApplicationInformation.coAppIdNumber,
            DriversLicenseIssueDate: atWaterApplicationInformation.coAppIdIssueDate,
            DriversLicenseExpDate: atWaterApplicationInformation.coAppIdExpirationDate,
            OtherDocument: atWaterApplicationInformation.coAppIdType,
            VisaIssueDate: null,
            OtherExpDate: null,
            MonthlyHousingPayment: 980,
            HousingType: 'Own',
          }
        : null,
      ContactMethod: 'WEB SERVICE',
      ProgramType: atWaterApplicationInformation.programId,
      Product: atWaterApplicationInformation.productId,
      RequestedAmount: atWaterApplicationInformation.amountFinanced,
    };

    dispatchSubmitAtWaterApplication(
      softPull.softPullResponse?.prequalificationId,
      AtWaterFlowPageNumber.ApplicationResult,
      softPullRequest,
    );
  };

  const ApplicationConsentForm = () => (
    <Form noValidate autoComplete="off">
      <Row>
        <Col>
          <Field
            component={Checkbox}
            name="applicantSubmitConsent"
            id="applicantSubmitConsent"
            label={`I, ${applicantName}, acknowledge that I have read the above and that the statements made therein are true and correct.`}
            labelClassName="bold mb-0 fs-14"
          />
        </Col>
      </Row>
      {atWaterApplicationInformation.hasCoApp && (
        <Row>
          <Col>
            <Field
              component={Checkbox}
              name="coApplicantSubmitConsent"
              id="coApplicantSubmitConsent"
              label={`I, ${coApplicantName}, acknowledge that I have read the above and that the statements made therein are true and correct.`}
              labelClassName="bold mb-0 fs-14"
            />
          </Col>
        </Row>
      )}

      <br />
      <Row>
        <Col sm="2" />
        <Col>
          <Button type="submit" title={'Submit'} />
        </Col>
        <Col sm="2" />
      </Row>
    </Form>
  );

  const ApplicationConsentFormFormik = withFormik({
    mapPropsToValues: () => ({
      applicantSubmitConsent: applicantSubmitConsent || false,
      coApplicantSubmitConsent: coApplicantSubmitConsent || false,
    }),

    validationSchema: Yup.object({
      applicantSubmitConsent: Yup.boolean().oneOf([true], 'Please check to continue.'),
      coApplicantSubmitConsent: atWaterApplicationInformation.hasCoApp
        ? Yup.boolean().oneOf([true], 'Please check to continue.')
        : Yup.boolean(),
    }),

    handleSubmit,
  })(ApplicationConsentForm);

  return (
    <Container>
      <Row className="justify-content-center">
        <BackButton
          changePage={dispatchChangePage}
          page={
            atWaterApplicationInformation.hasCoApp
              ? AtWaterFlowPageNumber.CoApplicantEmploymentInformation
              : AtWaterFlowPageNumber.HasCoApplicant
          }
        />
        <Col xs="auto" sm="11">
          <Row>
            <Col sm="11" className="disclaimer-banner">
              Disclaimer
            </Col>
          </Row>
          <br />
          <Row className="pools-disclosures">
            <Col xs="12" md="10">
              <div title="disclosures">
                The Undersigned hereby represents and agrees that (1) the applicant(s) have been
                made aware he or she was applying for credit, (2) the applicant(s) have given the
                undersigned their permission to share the information on the credit application with
                Service Finance Company, (3) the information submitted on the application is true
                and correct in all respects, (4) the applicant(s) have authorized Service Finance
                Company to share the information on this credit application with third parties as it
                deems appropriate, including but not limited to consumer reporting agencies, (5) the
                applicant(s) have acknowledged and agreed that the undersigned will collect
                information necessary to verify the applicant(s) identity and submit that
                information to Service Finance Company, and (6) the undersigned has delivered to the
                applicant(s) all disclosures required by applicable federal and state law.
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center mt-5">
            <Col xs="12" md="10">
              <ApplicationConsentFormFormik />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  atWaterApplicationInformation: state.atWaterApplicationInformation,
  softPull: state.softPull,
  applicantSubmitConsent: state.atWaterApplicationInformation.applicantSubmitConsent,
  coApplicantSubmitConsent: state.atWaterApplicationInformation.coApplicantSubmitConsent,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  emulatorId: state.auth.user.user ? state.auth.user.user.emulatorId : null,
  track_id: state.auth.track_id,
  consumerId: state.auth.consumerId,
  pqi: state.preQualificationInformation,
  applicantAddress: state.atWaterApplicationInformation.applicantAddress,
  app: state.softPull.applicationSubmitDTO,
  dealer: state.auth.user.dealerUser.dealer,
  dealerUser: state.auth.user.dealerUser,
});

const mapDispatchToProps = dispatch => ({
  dispatchGetDisclosures: () => dispatch(getDisclosures()),
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeAtWaterApplicationInfoValue: (key, value) =>
    dispatch(changeAtWaterApplicationInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchSubmitAtWaterApplication: (params, page, app) =>
    dispatch(submitAtWaterApplication(params, page, app)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ApplicationConsent),
);
