import React from 'react';
import { Row } from 'reactstrap';

const MicrofWorkingHours = () => (
  <Row noGutters className="sf-business-hours justify-content-center mt-3">
    <b>Hours:</b>
    <ul>
      <li>Mon - Fri</li>
      <li>Saturday</li>
      <li>Sunday</li>
    </ul>
    <ul>
      <li>8AM-9PM ET</li>
      <li>10AM-9PM ET</li>
      <li>Closed</li>
    </ul>
  </Row>
);

export default MicrofWorkingHours;
