import React, { useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { reactGAEvent, reactGAPageview } from '../../../../helper';
import {
  addNotification,
  changePage,
  getGreenSkyLoanAgreement,
  greenSkyCheckCta,
  submitMindfire,
  viewGreenSkyLoanAgreement,
} from '../../../../redux/actions';
import { handleApiError } from '../../../../redux/actions/api';
import Button from '../../../../components/UI/Button/CustomButton';
import { Field, Form, withFormik } from 'formik';
import Checkbox from '../../../../components/UI/Form/Checkbox/Checkbox';
import moment from 'moment';
import Disclosure from '../Components/Disclosure';

const LoanDocument = props => {
  const {
    applicationId,
    did,
    dealerUserId,
    greenSkyLoanAgreement,
    dispatchNotification,
    greenskyInformation,
    dispatchGetGreenSkyLoanAgreement,
    dispatchViewLoanAgreement,
    dispatchGreenSkyCheckCta,
    last4SSN,
  } = props;

  let data;
  const dateFormat = 'MM/DD/YYYY';

  useEffect(() => {
    if (greenSkyLoanAgreement === null) {
      dispatchGetGreenSkyLoanAgreement(applicationId);
    }

    reactGAPageview({
      path: '',
      trackerNames: [],
      title: '',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  if (greenSkyLoanAgreement) {
    if (greenSkyLoanAgreement.error) {
      dispatchNotification({
        message: handleApiError(greenSkyLoanAgreement.error.message),
        messageType: 'Error',
      });
      return null;
    } else {
      data = greenSkyLoanAgreement;
    }
  }

  const handleClick = () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Return to Dashboard',
      did,
      dealerUserId,
    });
    //dispatchSubmitMindfire(mindFireRequest);
    window.location.href = process.env.REACT_APP_DEALER_PORTAL_URL + '/portal/dashboard';
  };

  const handleLoanDocument = () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'View Loan Agreement',
      did,
      dealerUserId,
    });

    dispatchViewLoanAgreement(applicationId);
  };

  const activateAccountFormik = () => (
    <Form noValidate autoComplete="off">
      <Row>
        <Field
          component={Checkbox}
          name="identityVerificationInSpanish"
          id="identityVerificationInSpanish"
          label="Check this box if you wish to receive identity verifications in Spanish.
Marque esta casilla si desea recibir las preguntas de verificación de la identidad en español.
"
          labelClassName=""
        />
      </Row>
      <Row className="justify-content-center mt-4">
        <Col xs="12" md="auto">
          <Button type="submit" title={'Activate Account'} />
          <Disclosure />
        </Col>
      </Row>
    </Form>
  );

  const ActivateAccountFormik = withFormik({
    mapPropsToValues: () => ({
      identityVerificationInSpanish: false,
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Activate Account',
        did,
        dealerUserId,
      });

      dispatchGreenSkyCheckCta(
        greenskyInformation.greenskyApplicationId,
        last4SSN,
        values.identityVerificationInSpanish,
      );
    },
  })(activateAccountFormik);

  return data && Object.keys(data).length > 0 ? (
    <Container>
      <Row>
        <Col sm="6">
          <Row className="p-10">
            {' '}
            <h4>Activate Your Account:</h4>
            <span>
              To activate your account and make a purchase, review your loan agreement and select
              “Activate”. A link to your loan documents has also been sent to you via email.
            </span>
            <h4>
              <span>Account Status:</span>{' '}
              {greenskyInformation?.accountCreated === true &&
              greenskyInformation?.esignatureSuccess === true ? (
                <span className="status-auth-accepted">Active</span>
              ) : (
                <span className="status-cancelled">Not Active</span>
              )}
            </h4>
            <div className="loan-agreement-doc">
              <Button
                type="submit"
                outline
                title="View Loan Agreement"
                clickHandler={handleLoanDocument}
              />
              <span className="fs-12 loan-doc-description">
                View HTML screen-reader version of Loan Agreement for the visually impaired.
              </span>
            </div>
          </Row>
          <Row className="review">
            <Col>
              <Row className="row-outline-bottom">
                <Col>
                  <b className="b-no-border">Application ID:</b>
                </Col>
                <Col>{greenskyInformation.greenskyApplicationId}</Col>
              </Row>
              <Row className="row-outline-bottom">
                <Col>
                  <b className="b-no-border">Applicant Name:</b>
                </Col>
                <Col>{data.applicantFullName}</Col>
              </Row>
              <Row className="row-outline-bottom">
                <Col>
                  <b className="b-no-border">Lender:</b>
                </Col>
                <Col>{data.lender}</Col>
              </Row>
              <Row>
                <Col>
                  <b>Credit Limit</b>
                  <NumberFormat
                    value={data.amountFinanced}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={'$'}
                    displayType="text"
                  />
                </Col>
                <Col>
                  <b>Introductory Period</b>
                  {data.promotionPeriod + ' Months'}
                </Col>
              </Row>
              <Row>
                <Col>
                  <b>Amortization Term</b>
                  {data.term + ' Months'}
                </Col>
                <Col>
                  <b>Annual Percentage Rate (APR)</b>
                  {data.apr + '%'}
                </Col>
              </Row>
              <Row>
                <Col>
                  <b>Amortization Monthly Payment</b>
                  <NumberFormat
                    value={data.monthlyPayment}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={'$'}
                    displayType="text"
                  />
                </Col>
                <Col>
                  <b>Purchase Period End Date</b>
                  {moment(data.purchaseEndDate).format(dateFormat)}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="p-10">
            <Col>
              <p> {data.promotionDetails}</p>
            </Col>
          </Row>
        </Col>
        <Col sm="6">
          <Row className="p-10">
            <h5>
              By clicking “Activate Account”, you are electronically signing an acknowledgement
              that:
            </h5>
            <ul>
              <li>You are confirming that you applied for a loan with the GreenSky® Program</li>
              <li>You have received your loan agreement in a form you can retain.</li>
              <li>
                You agree to the{' '}
                <a
                  href="https://www.greensky.com/privacy-policy/"
                  without
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  GreenSky® Program Privacy Policy
                </a>
                . and{' '}
                <a
                  href="https://www.greensky.com/information.pdf"
                  without
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Consent to Electronic Records and Communications
                </a>{' '}
                and agree to receive electronic documents in connection with any loan obtained as a
                result of this application.
              </li>
              <li>
                You are activating your account so that you may use your loan to make payments to
                your merchant.
              </li>
              <li>
                You acknowledge that you have agreed to the marketing disclosure and consents
                provided to you at the point of application, which include (a) permission for
                GreenSky Marketing, LLC (“GS Marketing”) to use and share PII for it’s marketing
                purposes and with unaffiliated third-parties for their marketing purposes, and (b)
                your written instruction to your lender to provide GS Marketing with all information
                about you and your GreenSky® personal loan program loan, including but not limited
                to, details about your loan and loan performance so that GS Marketing and/or its
                affiliates and/or unaffiliated third-parties may provide you with various marketing
                offers
              </li>
              <li>
                You authorize all charges up to the Amount Financed for your project, Or, an Amount
                Financed subsequently approved by you and your co-applicant (if applicable).
              </li>
            </ul>
            <p>
              If you conducted any part of the discussion about your GreenSky Loan application or
              loan in Spanish or would otherwise like a copy of your loan agreement in Spanish,
              please call us at 833-215-6859. Si ha realizado alguna parte de la discusión sobre su
              solicitud o préstamo GreenSky Loan en español, o si desea una copia del contrato de su
              crédito en español. Ilámenos al 833-215-6859.
            </p>
          </Row>
          <ActivateAccountFormik />
        </Col>
      </Row>
    </Container>
  ) : (
    <Container>
      <Row className="justify-content-center mt-2">
        <Col xs="auto">
          <Button type="button" title="Return to Dashboard" clickHandler={handleClick} />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  email: state.preQualificationInformation.email,
  last4SSN: state.preQualificationInformation.ssnLastDigits,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  applicationId: state.softPull.applicationSubmitDTO.applicationId,
  greenSkyLoanAgreement: state.softPull.greenSkyLoanAgreement,
  greenskyInformation: state.softPull.applicationSubmitDTO.greenskyInformation,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchGetGreenSkyLoanAgreement: applicationId =>
    dispatch(getGreenSkyLoanAgreement(applicationId)),
  dispatchViewLoanAgreement: applicationId => dispatch(viewGreenSkyLoanAgreement(applicationId)),
  dispatchGreenSkyCheckCta: (applicationId, last4Ssn, isSpanish) =>
    dispatch(greenSkyCheckCta(applicationId, last4Ssn, isSpanish)),
  dispatchNotification: (messageType, message) => dispatch(addNotification(messageType, message)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoanDocument);
