import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import {
  isMicrofResubmitAppStatus,
  isMicrofReviewApprovedStatus,
  isMicrofReviewDeclinedStatus,
  isMicrofReviewNeedsReviewStatus,
} from '../../assets/js/ApplicationStatusEnum';
import decisionReason from '../../assets/js/DecisionReasonEnum';
import FinanceProductEnum from '../../assets/js/FinanceProductEnum';
import Button from '../../components/UI/Button/CustomButton';
import LeasingOffer from '../../components/UI/Microf/LeasingOffer/LeasingOffer';
import PixelSiteScouting from '../../components/UI/Tracking/PixelSiteScouting';
import { getAppName } from '../../config/settings';
import { reactGAEvent, reactGAPageview } from '../../helper';
import {
  acceptTermsMicrofApplication,
  acceptTermsMicrofPartnerApplication,
  changeMicrofInfoValue,
  changePage,
  finalAcceptMicrofApplication,
  manualAcceptMicrofApplication,
  manualAcceptMicrofApplications,
  resetNewAppState,
  resetState,
  setFlow,
  submitMindfire,
} from '../../redux/actions/index';
import { defaultFlow } from '../../redux/initialState';
import { MicrofFlowPageNumber } from './MicrofFlow';

const appName = getAppName();

const LeasingOffers = props => {
  const {
    decisionLeaseOptions,
    initialTotalFinancingAmount,
    initialTotalJobPrice,
    approvalAmount,
    decisionReasons,
    status,
    dispatchChangePage,
    dispatchChangeValue,
    isMoneySaver,
    dispatchSubmitMindfire,
    email,
    did,
    dealerUserId,
    app,
    app2,
    user,
    flowName,
    dispatchSetFlow,
    dispatchResetNewAppState,
    dispatchResetState,
    dispatchAcceptTermsMicrofApplication,
    dispatchAcceptTermsMicrofPartnerApplication,
    dispatchManualAcceptMicrofApplication,
    dispatchManualAcceptMicrofApplications,
  } = props;

  if (isMicrofResubmitAppStatus(status)) {
    dispatchChangePage(MicrofFlowPageNumber.RessubmissionPending);
  }

  let isPiOrPn = false;

  const leasingOffers = isMoneySaver
    ? decisionLeaseOptions.filter(x => x.financeProduct === FinanceProductEnum.MONEY_SAVER)
    : decisionLeaseOptions;
  const needsReviewStatusLastPage = '4116';
  const approvedStatusLastPage = '4098';
  const lastPage = isMicrofReviewDeclinedStatus(status)
    ? needsReviewStatusLastPage
    : approvedStatusLastPage;

  const [isPixelMounted, setIsPixelMounted] = useState(false);

  const goToHomeHandler = event => {
    if (user && user.dealerUser) {
      dispatchSetFlow(defaultFlow);
      dispatchResetNewAppState();
      dispatchChangePage(MicrofFlowPageNumber.Landing);
    } else {
      dispatchResetState(props.history);
      if (flowName === defaultFlow) {
        event.preventDefault();
      }
      dispatchSetFlow(defaultFlow);
      dispatchChangePage(MicrofFlowPageNumber.Landing);
    }
  };

  let decisionCodes = [];

  useEffect(() => {
    reactGAPageview({
      path: '/ca_suboptions',
      trackerNames: [],
      title: 'ca_suboptions',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const submitMindFire = () => {
    dispatchSubmitMindfire({
      email: email,
      lastPage: lastPage,
      dealerUserId: dealerUserId,
      did: did,
    });
  };

  if (isMicrofReviewDeclinedStatus(status)) {
    return (
      <Container>
        <Row>
          <Col sm="12">
            <Row className="h-100 justify-content-center align-items-center no-gutters text-align-center">
              <Col sm="10">
                <h4>
                  Microf was unable to approve your Lease Purchase application at this time. You
                  will receive a notification with reasons for our decision via email within 30
                  days.
                </h4>
                <p className="mt-30">
                  <b>
                    *Reasons may apply to the primary applicant or the co-applicant (if present).
                  </b>
                </p>
              </Col>
            </Row>

            <Row className="justify-content-center align-items-center no-gutters">
              <Col sm="6">
                <Link
                  to={user && user.dealerUser ? '/portal/dashboard' : ''}
                  onClick={goToHomeHandler}
                  style={{ textDecoration: 'none' }}
                  className="w-100"
                >
                  <Button
                    className="w-75 margin-center"
                    color="primary"
                    size="lg"
                    block
                    type="button"
                    title={user && user.dealerUser ? 'Close Application' : 'Exit'}
                  />
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  } else if (isMicrofReviewNeedsReviewStatus(status)) {
    return (
      <Container>
        <Row>
          <Col sm="1" />
          <Col sm="5">
            <h4>More Information is Required.</h4>
            <p>
              An immediate decision cannot be rendered. More information is required to complete the
              application process.
            </p>
          </Col>
          <Col sm="6">
            <Row className="notEligible">
              <Col>
                <h4>Reason(s) for no decision</h4>
                <ul>
                  {Object.keys(decisionReasons).map((key, index) => {
                    decisionCodes.push(decisionReasons[key].reasonCode);
                    return Object.keys(decisionReason).map(keyDR =>
                      decisionReason[keyDR].value === decisionReasons[key].reasonCode ? (
                        <li key={index}>{decisionReason[keyDR].name}</li>
                      ) : null,
                    );
                  })}
                </ul>
                <p className="error">
                  <b>
                    We still want to help. Please click Next to complete the process for manual
                    review.
                  </b>
                </p>
              </Col>
            </Row>
            <Button
              clickHandler={() => {
                setIsPixelMounted(true);
                reactGAEvent({
                  category: 'Button',
                  action: 'Click',
                  label: 'Next',
                  did,
                  dealerUserId,
                });

                isPiOrPn =
                  decisionCodes.includes(decisionReason.POI_INFORMATION_NEEDED.value) ||
                  decisionCodes.includes(decisionReason.POI_NEEDED.value)
                    ? true
                    : false;

                if (app2) {
                  setTimeout(
                    () =>
                      dispatchManualAcceptMicrofApplications(
                        app.applicationId,
                        app2.applicationId,
                        isPiOrPn,
                      ),
                    1,
                  );
                } else {
                  setTimeout(
                    () => dispatchManualAcceptMicrofApplication(app.applicationId, isPiOrPn),
                    1,
                  );
                }

                submitMindFire();
              }}
            >
              Next
            </Button>
          </Col>
        </Row>
        {isPixelMounted && <PixelSiteScouting type="submit" />}
      </Container>
    );
  } else if (isMicrofReviewApprovedStatus(status) && leasingOffers) {
    return (
      <Container>
        <Row>
          <Col sm="1" />
          <Col sm="5">
            <h4>Congratulations! We have the following leasing options for you.</h4>
            <p className="text-justify">
              Payment amounts are estimates and will be finalized once the quote and income have
              been verified.
            </p>
            <p className="text-justify">
              <b>Important Information</b>
            </p>
            <>
              <p className="text-justify">
                <b>Early Purchase Option:</b> Exercising the Early Purchase Option available in your
                Lease Purchase Agreement may result in savings of up to 40% or more off your
                remaining Total Cost to Own. Early Purchase Options vary by state. For details, call
                us at 855-642-7631 or email us at info@microf.com.
              </p>
              <p className="text-justify">
                <b>This is a Lease Purchase Transaction:</b> You are enrolling in a lease purchase
                transaction, rather than financing.
              </p>
              <p className="text-justify">
                <b>To exercise the Lease Purchase option, make the payment each month:</b> The term
                of the agreement is 1 month. After that, while you have the item, the Agreement
                renews for additional 1 month terms, until you own the item or surrender it to us.
                You may surrender the item at any time to terminate the Agreement, without paying a
                penalty.
              </p>
              <p className="text-justify">
                <b>Restrictions, Limitations, and Conditions:</b> The “Total Cost to Own” does not
                include other charges such as late fees and reinstatement fees.  You will own the
                item(s) once the total of payments to ownership is paid in full or is paid by
                prepayment.” 
              </p>
              <p className="text-justify">
                <b>Microf is not affiliated with your installing contractor.</b>
              </p>
            </>
          </Col>
          <Col sm="6">
            <h4>Leasing Options</h4>
            {leasingOffers.length > 0
              ? leasingOffers
                  .sort((a, b) => {
                    return a.termYears > b.termYears ? 1 : -1;
                  })
                  .reverse()
                  .map((item, index) => (
                    <LeasingOffer
                      key={index}
                      initialTotalFinancingAmount={initialTotalFinancingAmount}
                      initialTotalJobPrice={initialTotalJobPrice}
                      totalJobPrice={app.totalJobPrice}
                      optionNumber={index}
                      monthsToLeasing={item.termYears * 12}
                      payment={item.monthlyPayment}
                      totalOfPayments={item.totalFinancingCost}
                      capitalizedCostReduction={app.totalJobPrice - approvalAmount}
                      changePage={dispatchChangePage}
                      submitMindfire={submitMindFire}
                      dealerUserId={dealerUserId}
                      did={did}
                      email={email}
                      changeValue={dispatchChangeValue}
                      nextPage={MicrofFlowPageNumber.ApplicationReceived}
                      buttonText={`Accept and Submit`}
                      acceptTermsMicrofApplication={dispatchAcceptTermsMicrofApplication}
                      acceptTermsMicrofPartnerApplication={
                        dispatchAcceptTermsMicrofPartnerApplication
                      }
                      applicationId={app.applicationId}
                      termYears={item.termYears}
                      appToken={app.consumerToken}
                      addedBy={app.addedBy}
                      status={app.status}
                      pqi={app.prequalificationInformation}
                      setIsPixelMounted={setIsPixelMounted}
                      totalFinancingAmount={app.totalFinancingAmount}
                    />
                  ))
              : null}
          </Col>
        </Row>
        {isPixelMounted && <PixelSiteScouting type="submit" />}
      </Container>
    );
  } else {
    return (
      <Container>
        <Row>
          <Col sm="1" />
          <Col sm="5">
            <h4>More Information is Required.</h4>
            <p>
              An immediate decision cannot be rendered. More information is required to complete the
              application process.
            </p>
          </Col>
          <Col sm="6">
            <p>
              Thank you for applying for a {appName} Lease. We have received your application and it
              needs further review.
            </p>
          </Col>
        </Row>
      </Container>
    );
  }
};

const mapStateToProps = state => ({
  app: state.softPull.applicationSubmitDTO,
  app2: state.softPull.applicationSubmitDTO2,
  decisionLeaseOptions:
    state.softPull.applicationSubmitDTO?.lastApplicationResponse?.decisionLeaseOptions,
  decisionReasons:
    !!state.softPull.applicationSubmitDTO.lastApplicationResponse &&
    !!state.softPull.applicationSubmitDTO.lastApplicationResponse.decisionReasons
      ? state.softPull.applicationSubmitDTO.lastApplicationResponse.decisionReasons
      : null,
  initialTotalFinancingAmount: state.softPull.applicationSubmitDTO.initialTotalFinancingAmount,
  initialTotalJobPrice: state.softPull.applicationSubmitDTO?.initialTotalJobPrice,
  approvalAmount: !!state.softPull.applicationSubmitDTO.lastApplicationResponse
    ? state.softPull.applicationSubmitDTO.lastApplicationResponse.approvalAmount
    : 0,
  isMoneySaver: state.softPull?.applicationSubmitDTO?.lastApplicationResponse?.decisionLeaseOptions?.some(
    x => x.financeProduct === FinanceProductEnum.MONEY_SAVER,
  ),
  status: state.softPull.applicationSubmitDTO.status,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  user: state.auth.user,
  appToken: state.auth.appToken,
  systemType: state.microfInformation.systemType,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) => dispatch(changeMicrofInfoValue(key, value)),
  dispatchResetState: history => dispatch(resetState(history)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchSetFlow: flowName => dispatch(setFlow(flowName)),
  dispatchResetNewAppState: () => dispatch(resetNewAppState()),
  dispatchAcceptTermsMicrofApplication: (applicationId, termYears, nextPage) =>
    dispatch(acceptTermsMicrofApplication(applicationId, termYears, nextPage)),
  dispatchAcceptTermsMicrofPartnerApplication: (applicationId, termYears, appToken) =>
    dispatch(acceptTermsMicrofPartnerApplication(applicationId, termYears, appToken)),
  dispatchFinalAcceptMicrofApplication: applicationId =>
    dispatch(finalAcceptMicrofApplication(applicationId)),
  dispatchManualAcceptMicrofApplication: (applicationId, isPiOrPn) =>
    dispatch(manualAcceptMicrofApplication(applicationId, isPiOrPn)),
  dispatchManualAcceptMicrofApplications: (applicationId, application2Id, isPiOrPn) =>
    dispatch(manualAcceptMicrofApplications(applicationId, application2Id, isPiOrPn)),
});

LeasingOffers.propTypes = {
  decisionReasons: PropTypes.array,
  leasingOffers: PropTypes.array,
  status: PropTypes.string,
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
  dispatchResetState: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(LeasingOffers),
);
