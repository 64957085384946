import { AgeFromDateString } from 'age-calculator';
import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import BackBtn from '../../components/UI/BackButton/BackButton';
import Button from '../../components/UI/Button/CustomButton';
import DatePicker from '../../components/UI/Form/DatePicker/DatePicker';
import SsnFormat from '../../components/UI/Form/NumberFormat/SsnFormat';
import { getIsOptimus } from '../../config/settings';
import { APPFULLSSN_TOOLTIP_TEXT, BIRTHDAY_TOOLTIP_TEXT } from '../../constants/tooltipTexts';
import { reactGAEvent, reactGAPageview, validDateFormat } from '../../helper';
import {
  changeMicrofInfoValue,
  changePage,
  changePreQualInfoValue,
  submitMindfire,
} from '../../redux/actions/index';
import { MicrofFlowPageNumber } from './MicrofFlow';

const AppSSN = props => {
  const {
    isReview,
    hasCoApp,
    microfInformation,
    dispatchChangeValue,
    dispatchChangePage,
    children,
    aquaInformation,
    dispatchSubmitMindfire,
    email,
    did,
    dealerUserId,
    microfOnly,
    pqi,
    dispatchChangePqiValue,
    ssnLastDigits,
  } = props;

  const isOptimus = getIsOptimus();

  useEffect(() => {
    reactGAPageview({
      path: '/ca_consumerssn',
      trackerNames: [],
      title: 'ca_consumerssn',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const AppSSNForm = () => {
    return (
      <Form noValidate autoComplete="off">
        <Field
          component={DatePicker}
          name="birthday"
          id="birthday"
          tooltipText={BIRTHDAY_TOOLTIP_TEXT}
          label="Your Date of Birth"
        />
        <Field
          component={SsnFormat}
          label={'Your full Social Security Number'}
          name="appFullSSN"
          id="appFullSSN"
          tooltipText={APPFULLSSN_TOOLTIP_TEXT}
          type="password"
          icon="lock"
        />
        <p className="info">
          Microf uses security measures that comply with federal law, including data encryption
          technology, to protect your personal information from unauthorized access and use.
        </p>
        <Button type="submit" title="Continue" />
      </Form>
    );
  };

  const AppSSNFormFormik = withFormik({
    mapPropsToValues: () => ({
      birthday: pqi.birthday || '',
      appFullSSN:
        microfInformation.appFullSSN || aquaInformation.appFullSsn || pqi.appFullSsn || '',
      hasCoApp: hasCoApp || false,
    }),

    validationSchema: Yup.object({
      birthday: Yup.string()
        .required('Please select your date of birth.')
        .test(
          'incomplete',
          'Incomplete date',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 8,
        )
        .test('valid', 'Invalid date format.', val => val && !validDateFormat(val))
        .test(
          'age',
          'You must be at least 18 years old.',
          val => val && new AgeFromDateString(val).age >= 18,
        ),
      appFullSSN: Yup.string()
        .required('Please enter your SSN.')
        .test(
          'len',
          'Must have 9 digits',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 9,
        )
        .test(
          'match',
          'Last 4 digits must match.',
          val =>
            ssnLastDigits === '' ||
            (val &&
              val
                .toString()
                .match(/\d/g)
                .join('')
                .substr(
                  val
                    .toString()
                    .match(/\d/g)
                    .join('').length - 4,
                ) === ssnLastDigits),
        ),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Continue',
        did,
        dealerUserId,
      });
      dispatchChangePqiValue('birthday', values.birthday);
      dispatchChangeValue(
        'appFullSSN',
        values.appFullSSN
          .toString()
          .match(/\d/g)
          .join(''),
      );
      dispatchChangeValue('isReview', null);
      dispatchChangePage(MicrofFlowPageNumber.Homeowner);
      dispatchSubmitMindfire({
        email: email,
        lastPage: '4084',
        dealerUserId: dealerUserId,
        did: did,
        birthDay: values.birthday,
      });
    },
  })(AppSSNForm);

  return (
    <Container>
      <Row>
        <BackBtn changePage={dispatchChangePage} page={MicrofFlowPageNumber.UserInfo} />
        <Col sm="5">
          <h4>Please provide your date of birth and Social Security Number.</h4>

          {children}
        </Col>
        <Col sm="6">
          <AppSSNFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  pqi: state.preQualificationInformation,
  microfOnly: state.microfInformation.microfOnly,
  microfInformation: state.microfInformation,
  ssnLastDigits: state.preQualificationInformation.ssnLastDigits || '',
  hasCoApp: state.microfInformation.hasCoApp,
  isReview: state.microfInformation.isReview,
  aquaInformation: state.aquaInformation,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangePqiValue: (key, value) => dispatch(changePreQualInfoValue(key, value)),
  dispatchChangeValue: (key, value) => dispatch(changeMicrofInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

AppSSN.propTypes = {
  dispatchChangePage: PropTypes.func,
  ssnLastDigits: PropTypes.string,
  hasCoApp: PropTypes.bool,
  isReview: PropTypes.bool,
  microfInformation: PropTypes.object,
  dispatchChangeValue: PropTypes.func,
  aquaInformation: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppSSN);
