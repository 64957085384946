import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import * as Yup from 'yup';
import TextArea from '../Form/TextArea/TextArea';

const EmailModal = props => {
  const { isOpen, sendTo, toggleModal, className, onSendClick } = props;

  const EmailForm = () => (
    <Form>
      <ModalBody>
        <Label for="emailTextArea">To: {sendTo.name}</Label>
        <Field component={TextArea} name="emailText" id="emailTextArea" />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" outline onClick={toggleModal}>
          Close
        </Button>
        <Button color="primary" type="submit">
          Send
        </Button>
      </ModalFooter>
    </Form>
  );

  const EmailFormFormik = withFormik({
    validationSchema: Yup.object({
      emailText: Yup.string().required('Enter message.'),
    }),

    handleSubmit: values => {
      onSendClick(values.emailText);
      toggleModal();
    },
  })(EmailForm);

  return (
    <Modal centered isOpen={isOpen} className={className}>
      <ModalHeader toggle={toggleModal}>
        <b>{`Contact the ${sendTo.role}`}</b>
      </ModalHeader>
      <EmailFormFormik />
    </Modal>
  );
};

EmailModal.propTypes = {
  isOpen: PropTypes.bool,
  sendTo: PropTypes.object,
  toggleModal: PropTypes.func,
  className: PropTypes.string,
  onSendClick: PropTypes.func,
};

export default EmailModal;
