import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import AirConditioningSystemEnum from '../../../assets/js/AirConditioningSystemEnum';
import ApplicationFlowEnum from '../../../assets/js/ApplicationFlowEnum';
import Button from '../../../components/UI/Button/CustomButton';
import {
  changePage,
  changeSoftPullValue,
  resetNewAppState,
  resetState,
  setFlow,
  submitMindfire,
} from '../../../redux/actions/index';

const ScoreError = props => {
  const {
    dispatchSetFlow,
    children,
    history,
    airConditioningSystem,
    email,
    dealerUserId,
    did,
    dispatchSubmitMindfire,
  } = props;

  const mindFireRequest = {
    email: email,
    lastPage: '6008',
    dealerUserId: dealerUserId,
    did: did,
  };

  return (
    <Container>
      <Row>
        <Col />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <p>
            Unfortunately, we were unable to obtain a Soft Pull score for you with the date-of-birth
            and last 4 digits of the Social Security Number you provided.
            <br />
            <br />
            We could not prequalify you with one of our lenders, but you can continue your
            application for a lending or lease purchase option.
          </p>
          <Button
            title="Apply for Lending Option"
            clickHandler={() => {
              dispatchSubmitMindfire(mindFireRequest);
              dispatchSetFlow(ApplicationFlowEnum.POWERPAY_FLOW.value);
              history.push(ApplicationFlowEnum.POWERPAY_FLOW.path);
            }}
          />
          {airConditioningSystem ===
          AirConditioningSystemEnum.AIR_CONDITION_OR_HEATING_SYSTEM.id ? (
            <Button
              title="Apply for Lease Purchase Program"
              clickHandler={() => {
                dispatchSubmitMindfire(mindFireRequest);
                dispatchSetFlow(ApplicationFlowEnum.MICROF_FLOW.value);
                history.push(ApplicationFlowEnum.MICROF_FLOW.path);
              }}
            />
          ) : null}
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  airConditioningSystem:
    state.preQualificationInformation.airConditioningSystem ||
    state.softPull.applicationSubmitDTO?.airConditioningSystem,
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchResetState: props => dispatch(resetState(props)),
  dispatchResetNewAppState: () => dispatch(resetNewAppState()),
  dispatchChangeSoftPullValue: (key, value) => dispatch(changeSoftPullValue(key, value)),
  dispatchSetFlow: flow => dispatch(setFlow(flow)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

ScoreError.propTypes = {
  dispatchChangePage: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ScoreError),
);
