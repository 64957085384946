import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { changePage, submitMindfire, changeMicrofInfoValue } from '../../redux/actions/index';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import BackBtn from '../../components/UI/BackButton/BackButton';
import Button from '../../components/UI/Button/CustomButton';
import { reactGAPageview } from '../../helper';
import { MicrofFlowPageNumber } from './MicrofFlow';
import { Field, Form, withFormik } from 'formik';
import SelectWithCustomOnChange from '../../components/UI/Form/Select/SelectWithCustomOnChange';
import * as Yup from 'yup';
import hvacSystem, { isACUnit, isWHUnit } from '../../assets/js/HvacSystemEnum';
import hvacTonnage from '../../assets/js/HvacTonnageEnum';
import Checkbox from '../../components/UI/Form/Checkbox/Checkbox';
import { SECONDHVACSYSTEM_TOOLTIP_TEXT } from '../../constants/tooltipTexts';
import ErrorModal from '../../components/UI/Modal/ErrorModal';
import SystemTypeSelectWithCustomOnChange from '../../components/UI/Form/Select/SystemTypeSelectWithCustomOnChange';

const SystemType = props => {
  const {
    dispatchChangePage,
    dispatchChangeMicrofInfoValue,
    did,
    dealerUserId,
    systemType,
    hasSecondSystem,
    systemTonnage,
    secondSystemType,
    secondSystemTonnage,
    thirdSystemType,
    thirdSystemTonnage,
    hasThirdSystem,
    fourthSystemType,
    fourthSystemTonnage,
    hasFourthSystem,
  } = props;

  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    reactGAPageview({
      path: '/ca_homeowner',
      trackerNames: [],
      title: 'ca_homeowner',
      did,
      dealerUserId,
    });

    // eslint-disable-next-line
  }, []);

  const systems = {
    systemType: { value: systemType, systemTypeTonnage: systemTonnage },
    secondSystemType: { value: secondSystemType, systemTypeTonnage: secondSystemTonnage },
    thirdSystemType: { value: thirdSystemType, systemTypeTonnage: thirdSystemTonnage },
    fourthSystemType: { value: fourthSystemType, systemTypeTonnage: fourthSystemTonnage },
  };

  const handleSelectedSystem = e => {
    if (e) {
      dispatchChangeMicrofInfoValue(e.name, e.value);
      if (e.name === 'systemType') {
        if (!isACUnit(e.value)) {
          dispatchChangeMicrofInfoValue('systemTonnage', null);
        }
      }
      if (e.name === 'secondSystemType') {
        if (!isACUnit(e.value)) {
          dispatchChangeMicrofInfoValue('secondSystemTonnage', null);
        }
      }
      if (e.name === 'thirdSystemType') {
        if (!isACUnit(e.value)) {
          dispatchChangeMicrofInfoValue('thirdSystemTonnage', null);
        }
      }
      if (e.name === 'fourthSystemType') {
        if (!isACUnit(e.value)) {
          dispatchChangeMicrofInfoValue('fourthSystemTonnage', null);
        }
      }
    }
  };

  const mapSystems = (hvacSystems, whSystems) => {
    dispatchChangeMicrofInfoValue('hvacSystem', null);
    dispatchChangeMicrofInfoValue('hvacTonnage', null);
    dispatchChangeMicrofInfoValue('secondHvacSystem', null);
    dispatchChangeMicrofInfoValue('secondHvacTonnage', null);
    dispatchChangeMicrofInfoValue('waterHeater', null);
    dispatchChangeMicrofInfoValue('secondWaterHeater', null);

    if (hvacSystems.length > 0) {
      if (hvacSystems.length === 1) {
        dispatchChangeMicrofInfoValue('hvacSystem', hvacSystems[0].systemType);
        dispatchChangeMicrofInfoValue('hvacTonnage', hvacSystems[0].systemTonnage);
      }
      if (hvacSystems.length === 2) {
        dispatchChangeMicrofInfoValue('hvacSystem', hvacSystems[0].systemType);
        dispatchChangeMicrofInfoValue('hvacTonnage', hvacSystems[0].systemTonnage);
        dispatchChangeMicrofInfoValue('secondHvacSystem', hvacSystems[1].systemType);
        dispatchChangeMicrofInfoValue('secondHvacTonnage', hvacSystems[1].systemTonnage);
      }
    }
    if (whSystems.length > 0) {
      if (whSystems.length === 1) {
        dispatchChangeMicrofInfoValue('waterHeater', whSystems[0]);
      }
      if (whSystems.length === 2) {
        dispatchChangeMicrofInfoValue('waterHeater', whSystems[0]);
        dispatchChangeMicrofInfoValue('secondWaterHeater', whSystems[1]);
      }
    }
  };

  const checkSystems = systems => {
    let hvacSystems = [];
    let whSystems = [];
    Object.keys(systems).forEach((key, index) => {
      if (systems[key].value && systems[key].value !== '') {
        if (isACUnit(systems[key].value)) {
          hvacSystems.push({
            systemType: systems[key].value,
            systemTonnage: systems[key].systemTypeTonnage,
          });
        }
        if (isWHUnit(systems[key].value)) {
          whSystems.push(systems[key].value);
        }
      }
    });

    if (hvacSystems.length > 2 || whSystems.length > 2) {
      setErrorMessage('Cannot use more than two system types from the same category.');
    } else if (whSystems.length === 2) {
      if (whSystems[0] === whSystems[1]) {
        mapSystems(hvacSystems, whSystems);
        dispatchChangePage(MicrofFlowPageNumber.LeaseAmount);
      } else {
        setErrorMessage(
          'We only allow selection of one water heater type (tank, tankless, or heat pump hybrid).',
        );
      }
    } else {
      mapSystems(hvacSystems, whSystems);
      dispatchChangePage(MicrofFlowPageNumber.LeaseAmount);
    }
  };

  const SystemTypeForm = ({ values }) => (
    <Form>
      <Field
        component={SystemTypeSelectWithCustomOnChange}
        label="System Type"
        name="systemType"
        id="systemType"
        tooltipText={'Please select System Type'}
        type="select"
        selectValues={hvacSystem}
        selectFirstName="Select One"
        handleChange={e => handleSelectedSystem(e.target)}
      />
      {isACUnit(systemType) ? (
        <Field
          component={SelectWithCustomOnChange}
          label="System Tonnage"
          name="systemTonnage"
          id="systemTonnage"
          tooltipText={'Please select System Tonnage'}
          type="select"
          selectValues={hvacTonnage}
          selectFirstName="Select One"
          handleChange={e => handleSelectedSystem(e.target)}
        />
      ) : null}

      <Field
        component={Checkbox}
        name="hasSecondSystem"
        id="hasSecondSystem"
        tooltipText={SECONDHVACSYSTEM_TOOLTIP_TEXT}
        label={`Is there a second system being installed?`}
        labelClassName="bold mb-0"
        clickHandler={() => {
          dispatchChangeMicrofInfoValue('hasSecondSystem', !hasSecondSystem);
          dispatchChangeMicrofInfoValue('secondSystemType', null);
          dispatchChangeMicrofInfoValue('secondSystemTonnage', null);
          dispatchChangeMicrofInfoValue('hasThirdSystem', false);
          dispatchChangeMicrofInfoValue('thirdSystemType', null);
          dispatchChangeMicrofInfoValue('thirdSystemTonnage', null);
          dispatchChangeMicrofInfoValue('hasFourthSystem', false);
          dispatchChangeMicrofInfoValue('fourthSystemType', null);
          dispatchChangeMicrofInfoValue('fourthSystemTonnage', null);
        }}
      />
      {hasSecondSystem ? (
        <>
          <Field
            component={SystemTypeSelectWithCustomOnChange}
            label="System Type"
            name="secondSystemType"
            id="secondSystemType"
            tooltipText={'Please select System Type'}
            type="select"
            selectValues={hvacSystem}
            selectFirstName="Select One"
            handleChange={e => handleSelectedSystem(e.target)}
          />
          {isACUnit(secondSystemType) ? (
            <Field
              component={SelectWithCustomOnChange}
              label="System Tonnage"
              name="secondSystemTonnage"
              id="secondSystemTonnage"
              tooltipText={'Please select System Tonnage'}
              type="select"
              selectValues={hvacTonnage}
              selectFirstName="Select One"
              handleChange={e => handleSelectedSystem(e.target)}
            />
          ) : null}
          <Field
            component={Checkbox}
            name="hasThirdSystem"
            id="hasThirdSystem"
            tooltipText={SECONDHVACSYSTEM_TOOLTIP_TEXT}
            label={`Is there a third system being installed?`}
            labelClassName="bold mb-0"
            clickHandler={() => {
              dispatchChangeMicrofInfoValue('hasThirdSystem', !hasThirdSystem);
              dispatchChangeMicrofInfoValue('thirdSystemType', null);
              dispatchChangeMicrofInfoValue('thirdSystemTonnage', null);
              dispatchChangeMicrofInfoValue('hasFourthSystem', false);
              dispatchChangeMicrofInfoValue('fourthSystemType', null);
              dispatchChangeMicrofInfoValue('fourthSystemTonnage', null);
            }}
          />
        </>
      ) : null}

      {hasThirdSystem ? (
        <>
          <Field
            component={SystemTypeSelectWithCustomOnChange}
            label="System Type"
            name="thirdSystemType"
            id="thirdSystemType"
            tooltipText={'Please select System Type'}
            type="select"
            selectValues={hvacSystem}
            selectFirstName="Select One"
            handleChange={e => handleSelectedSystem(e.target)}
          />
          {isACUnit(thirdSystemType) ? (
            <Field
              component={SelectWithCustomOnChange}
              label="System Tonnage"
              name="thirdSystemTonnage"
              id="thirdSystemTonnage"
              tooltipText={'Please select System Tonnage'}
              type="select"
              selectValues={hvacTonnage}
              selectFirstName="Select One"
              handleChange={e => handleSelectedSystem(e.target)}
            />
          ) : null}
          <Field
            component={Checkbox}
            name="hasFourthSystem"
            id="hasFourthSystem"
            tooltipText={SECONDHVACSYSTEM_TOOLTIP_TEXT}
            label={`Is there a fourth system being installed?`}
            labelClassName="bold mb-0"
            clickHandler={() => {
              dispatchChangeMicrofInfoValue('hasFourthSystem', !hasFourthSystem);
              dispatchChangeMicrofInfoValue('fourthSystemType', null);
              dispatchChangeMicrofInfoValue('fourthSystemTonnage', null);
            }}
          />
        </>
      ) : null}

      {hasFourthSystem ? (
        <>
          <Field
            component={SystemTypeSelectWithCustomOnChange}
            label="System Type"
            name="fourthSystemType"
            id="fourthSystemType"
            tooltipText={'Please select System Type'}
            type="select"
            selectValues={hvacSystem}
            selectFirstName="Select One"
            handleChange={e => handleSelectedSystem(e.target)}
          />
          {isACUnit(fourthSystemType) ? (
            <Field
              component={SelectWithCustomOnChange}
              label="System Tonnage"
              name="fourthSystemTonnage"
              id="fourthSystemTonnage"
              tooltipText={'Please select System Tonnage'}
              type="select"
              selectValues={hvacTonnage}
              selectFirstName="Select One"
              handleChange={e => handleSelectedSystem(e.target)}
            />
          ) : null}
        </>
      ) : null}

      <Button type="submit" title="Next" />
    </Form>
  );

  const SystemTypeFormFormik = withFormik({
    mapPropsToValues: () => ({
      systemType: systemType ? systemType : '',
      systemTonnage: systemTonnage ? systemTonnage : '',
      hasSecondSystem: hasSecondSystem,
      secondSystemType: secondSystemType ? secondSystemType : '',
      secondSystemTonnage: secondSystemTonnage ? secondSystemTonnage : '',
      hasThirdSystem: hasThirdSystem,
      thirdSystemType: thirdSystemType ? thirdSystemType : '',
      thirdSystemTonnage: thirdSystemTonnage ? thirdSystemTonnage : '',
      hasFourthSystem: hasFourthSystem,
      fourthSystemType: fourthSystemType ? fourthSystemType : '',
      fourthSystemTonnage: fourthSystemTonnage ? fourthSystemTonnage : '',
    }),

    validationSchema: Yup.object({
      systemType: Yup.string().required('Please select a System Type.'),
      systemTonnage: isACUnit(systemType)
        ? Yup.string().required('Please select System Tonnage.')
        : Yup.string(),
      secondSystemType: hasSecondSystem
        ? Yup.string().required('Please select a System Type.')
        : Yup.string(),
      secondSystemTonnage: isACUnit(secondSystemType)
        ? Yup.string().required('Please select System Tonnage.')
        : Yup.string(),
      thirdSystemType: hasThirdSystem
        ? Yup.string().required('Please select a System Type.')
        : Yup.string(),
      thirdSystemTonnage: isACUnit(thirdSystemType)
        ? Yup.string().required('Please select System Tonnage.')
        : Yup.string(),
      fourthSystemType: hasFourthSystem
        ? Yup.string().required('Please select a System Type.')
        : Yup.string(),
      fourthSystemTonnage: isACUnit(fourthSystemType)
        ? Yup.string().required('Please select System Tonnage.')
        : Yup.string(),
    }),

    handleSubmit: values => {
      checkSystems(systems);
      // dispatchSubmitMindfire({
      //   email: email,
      //   borrowedAmount: values.borrowedAmount,
      //   lastPage: '9010',
      //   dealerUserId: dealerUserId,
      //   did: did,
      // });
    },
  })(SystemTypeForm);

  return (
    <Container>
      <Row>
        <Col>
          <Row className="mb-5">
            <BackBtn changePage={dispatchChangePage} page={MicrofFlowPageNumber.UserAddress} />
            <Col sm="5">
              <h4>What type of project would you like to lease today?</h4>
              <p>
                Please refer to your contractor’s quote and select the option that best describes
                the equipment being installed.
              </p>
            </Col>
            <Col sm="6">
              <SystemTypeFormFormik />
            </Col>
          </Row>
        </Col>
      </Row>
      <ErrorModal
        isOpen={errorMessage !== null}
        messageType=""
        message={<>{errorMessage}</>}
        onClick={() => setErrorMessage(null)}
      />
    </Container>
  );
};

const mapStateToProps = state => ({
  pqi: state.preQualificationInformation,
  isHomeowner: state.microfInformation.isHomeowner,
  systemType: state.microfInformation.systemType,
  systemTonnage: state.microfInformation.systemTonnage,
  hasSecondSystem: state.microfInformation.hasSecondSystem,
  secondSystemType: state.microfInformation.secondSystemType,
  secondSystemTonnage: state.microfInformation.secondSystemTonnage,
  hasThirdSystem: state.microfInformation.hasThirdSystem,
  thirdSystemType: state.microfInformation.thirdSystemType,
  thirdSystemTonnage: state.microfInformation.thirdSystemTonnage,
  hasFourthSystem: state.microfInformation.hasFourthSystem,
  fourthSystemType: state.microfInformation.fourthSystemType,
  fourthSystemTonnage: state.microfInformation.fourthSystemTonnage,
  homeownerInsurance: state.microfInformation.homeownerInsurance,
  email: state.preQualificationInformation.email,
  isReview: state.aquaInformation.isReview,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeMicrofInfoValue: (key, value) => dispatch(changeMicrofInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

SystemType.propTypes = {
  dispatchChangePage: PropTypes.func,
  dispatchChangeAquaInfoValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SystemType);
