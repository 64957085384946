import React, { useState } from 'react';
import { Button } from 'reactstrap';
import Confirm from '../../../components/UI/Modal/Confirm';

const SearchDealerTable = props => {
  const { dealerSearchCriteriaResult, application, assignApplication } = props;
  const [confirm, setConfirm] = useState(false);
  const [selectedDealer, setSlectedDealer] = useState(null);

  return (
    <>
      <table className="table table-hover table-striped">
        <thead>
          <tr>
            <th>Contractor Name</th>
            <th>Principal Email</th>
            <th>DRR Email</th>
            <th>State</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {dealerSearchCriteriaResult.dealers.map((dealer, index) => (
            <tr key={index}>
              <td>{dealer.name}</td>
              <td>{dealer.principalEmail}</td>
              <td>{dealer.drrEmail}</td>
              <td>{dealer.businessAddress.state}</td>
              <td>
                <Button
                  color="secondary"
                  className="rounded-pill"
                  outline
                  onClick={() => {
                    setSlectedDealer(dealer);
                    setConfirm(true);
                  }}
                >
                  Assign
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Confirm
        headerText={'Assign'}
        bodyText={
          'Are you sure you want to assign "' +
          application.applicants[0].nameFirst +
          ' ' +
          application.applicants[0].nameLast +
          '" to "' +
          (selectedDealer ? selectedDealer.name : '') +
          '"?'
        }
        confirm={confirm}
        cancelButtonText={'Cancel'}
        confirmButtonText={'OK'}
        cancelEventHandler={() => setConfirm(false)}
        confirmEventHandler={() => {
          assignApplication(application, selectedDealer);
          setConfirm(false);
        }}
      />
    </>
  );
};

export default SearchDealerTable;
