import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { changePage, submitMindfire, changeMicrofInfoValue } from '../../redux/actions/index';
import { Container, Row, Col } from 'reactstrap';
import BackBtn from '../../components/UI/BackButton/BackButton';
import Button from '../../components/UI/Button/CustomButton';
import { reactGAPageview } from '../../helper';
import { MicrofFlowPageNumber } from './MicrofFlow';
import HvacHeatTypeEnum from '../../assets/js/HvacHeatTypeEnum';
import hvacSystem from '../../assets/js/HvacSystemEnum';
import AirConditioningSystemEnum from '../../assets/js/AirConditioningSystemEnum';

const HvacHeatType = props => {
  const {
    email,
    dispatchChangePage,
    dispatchChangeMicrofInfoValue,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    hvacHeatType,
    hvacSystemType,
    children,
    systemType,
  } = props;
  const [isMessageHidden, setIsMessageHidden] = useState(
    hvacHeatType === null ? true : !!hvacHeatType,
  );

  const isWaterHeaterOrHvacPlusWaterHeater =
    systemType === AirConditioningSystemEnum.WATER_HEATER.id ||
    systemType === AirConditioningSystemEnum.AIR_CONDITION_OR_HEATING_SYSTEM_PLUS_WATER_HEATER.id;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_heattype',
      trackerNames: [],
      title: 'ca_heattype',
      did,
      dealerUserId,
    });

    // eslint-disable-next-line
  }, []);
  return (
    <Container>
      <Row>
        <BackBtn changePage={dispatchChangePage} page={MicrofFlowPageNumber.HvacSystemType} />
        <Col>{children}</Col>
        <Col>
          {Object.values(HvacHeatTypeEnum).map((obj, index) => (
            <Row className="mb-3" key={index}>
              <Col xs="12">
                <Button
                  className={hvacHeatType === obj.value ? '' : 'inactive'}
                  clickHandler={() => {
                    dispatchChangeMicrofInfoValue('hvacHeatType', obj.value);
                    setIsMessageHidden(true);
                  }}
                >
                  <h6 className="mb-0 font-weight-bold">{obj.name}</h6>
                </Button>
              </Col>
            </Row>
          ))}
          <Row>
            <Col>
              <p className="text-danger" hidden={isMessageHidden}>
                You must select an option.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                type="button"
                title="Continue"
                clickHandler={() => {
                  if (hvacHeatType) {
                    dispatchChangeMicrofInfoValue(
                      'hvacSystem',
                      Object.values(hvacSystem).find(
                        obj =>
                          obj.systemType?.includes(hvacSystemType) &&
                          obj.heatType?.includes(hvacHeatType),
                      ).value,
                    );
                    dispatchChangePage(
                      isWaterHeaterOrHvacPlusWaterHeater
                        ? MicrofFlowPageNumber.WaterHeater
                        : MicrofFlowPageNumber.LeaseAmount,
                    );
                    dispatchSubmitMindfire({
                      email: email,
                      lastPage: '4124',
                      dealerUserId: dealerUserId,
                      did: did,
                    });
                  } else {
                    setIsMessageHidden(false);
                  }
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  homeownerInsurance: state.microfInformation.homeownerInsurance,
  isHomeowner: state.microfInformation.isHomeowner,
  email: state.preQualificationInformation.email,
  isReview: state.aquaInformation.isReview,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  hvacHeatType: state.microfInformation.hvacHeatType,
  hvacSystemType: state.microfInformation.hvacSystemType,
  systemType: state.microfInformation.systemType,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeMicrofInfoValue: (key, value) => dispatch(changeMicrofInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HvacHeatType);
