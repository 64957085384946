import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import Product from '../../components/UI/AquaProductOffer/AquaProductOffer';
import { changePage, setFlow } from '../../redux/actions/index';
import Button from '../../components/UI/Button/CustomButton';
import { preQualificationFlow } from '../../redux/initialState';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { AquaFlowPageNumber } from './AquaFlow';
import { AquaPreQualificationFlowPageNumber } from '../PreQualification/preQualificationFlow';

const AquaAcceptOffer = ({
  dispatchChangePage,
  dispatchSetFlow,
  children,
  financingOption,
  history,
}) => (
  <Container>
    <Row>
      <Col sm="1" />
      <Col sm="5">{children}</Col>
      <Col sm="6">
        <Product years={financingOption} />
        <br />
        <Button
          title="Confirm and Accept"
          clickHandler={() => {
            dispatchChangePage(AquaFlowPageNumber.HvacDetails);
            dispatchSetFlow(preQualificationFlow);
            dispatchChangePage(AquaPreQualificationFlowPageNumber.UserType);
            history.push('/');
          }}
        />
        <br />
        <div className="text-center">
          <Link
            to=""
            className="go-back"
            onClick={() => dispatchChangePage(AquaFlowPageNumber.AquaApplicationResult)}
          >
            Go Back
          </Link>
        </div>
      </Col>
    </Row>
  </Container>
);

const mapStateToProps = state => ({
  financingOption: state.aquaInformation.financingOption,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetFlow: flowName => dispatch(setFlow(flowName)),
  dispatchChangePage: page => dispatch(changePage(page)),
});

AquaAcceptOffer.propTypes = {
  financingOption: PropTypes.object,
  dispatchChangePage: PropTypes.func,
  dispatchSetFlow: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AquaAcceptOffer),
);
