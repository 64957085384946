import { Form, withFormik, Field } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Row, Button } from 'reactstrap';
import * as Yup from 'yup';
import { setDefaultFinancingOption, isThemeOptimus } from '../../../redux/actions';
import SelectedFinancierEnum from '../../../assets/js/SelectedFinancierEnum';
import RadioButtons from '../../../components/UI/Form/Radio/RadioButtons';
import { isBankUser } from '../../../helper';
import mosaic from '../../../assets/img/MosaicSmall.png';
import enerBank from '../../../assets/img/EnerBankUSA.png';
import greensky from '../../../assets/img/GreenSky.png';
import foundation from '../../../assets/img/FoundationFinance.png';
import ftl from '../../../assets/img/FTLFinance_Option.png';

const FinancingOption = props => {
  const {
    auth,
    history,
    defaultBankId,
    secondBankId,
    dispatchSetDefaultFinancingOption,
    dealerBanks,
    theme,
  } = props;

  const isFoundationSecondary = () =>
    isBankUser(dealerBanks, SelectedFinancierEnum.GREENSKY.id) ||
    isBankUser(dealerBanks, SelectedFinancierEnum.MOSAIC.id) ||
    isBankUser(dealerBanks, SelectedFinancierEnum.ENERBANK.id);

  useEffect(() => {
    if (!auth.user || !auth.user.dealerUser) {
      history.push('/login');
    }
    // eslint-disable-next-line
  }, []);

  const cancel = event => {
    history.push('/portal/dashboard');
    event.preventDefault();
  };

  let radioOptions1 = [];
  let radioOptions2 = [];
  let radioOptionSelected = secondBankId;

  if (isBankUser(dealerBanks, SelectedFinancierEnum.FTL.id)) {
    if (!isBankUser(dealerBanks, SelectedFinancierEnum.GREENSKY.id)) {
      radioOptions1.push({
        key: SelectedFinancierEnum.FTL.value,
        id: 'defaultFTLBank',
        value: '' + SelectedFinancierEnum.FTL.id,
        image: isThemeOptimus(theme) ? ftl : null,
      });
    }
  }
  if (isBankUser(dealerBanks, SelectedFinancierEnum.ENERBANK.id)) {
    radioOptions1.push({
      key: 'EnerBank',
      id: 'defaultBankEnerBank',
      value: '' + SelectedFinancierEnum.ENERBANK.id,
      image: isThemeOptimus(theme) ? enerBank : null,
    });
  }
  if (isBankUser(dealerBanks, SelectedFinancierEnum.MOSAIC.id)) {
    radioOptions1.push({
      key: 'Mosaic',
      id: 'defaultBankMosaic',
      value: '' + SelectedFinancierEnum.MOSAIC.id,
      image: isThemeOptimus(theme) ? mosaic : null,
    });
  }
  if (isBankUser(dealerBanks, SelectedFinancierEnum.POWER_PAY.id)) {
    radioOptions1.push({
      key: 'PowerPay',
      id: 'defaultBankPowerPay',
      value: '' + SelectedFinancierEnum.POWER_PAY.id,
    });
  }
  if (isBankUser(dealerBanks, SelectedFinancierEnum.AQUA_FINANCE.id)) {
    radioOptions1.push({
      key: 'Aqua Finance',
      id: 'defaultBankAquaFinance',
      value: '' + SelectedFinancierEnum.AQUA_FINANCE.id,
    });
  }
  if (isBankUser(dealerBanks, SelectedFinancierEnum.GREENSKY.id)) {
    radioOptions1.push({
      key: 'Greensky',
      id: 'defaultBankGreensky',
      value: '' + SelectedFinancierEnum.GREENSKY.id,
      image: isThemeOptimus(theme) ? greensky : null,
    });
  }
  if (isBankUser(dealerBanks, SelectedFinancierEnum.FOUNDATION.id) && !isFoundationSecondary()) {
    radioOptions1.push({
      key: 'Foundation',
      id: 'defaultBankFoundation',
      value: '' + SelectedFinancierEnum.FOUNDATION.id,
      image: isThemeOptimus(theme) ? foundation : null,
    });
  }
  if (isBankUser(dealerBanks, SelectedFinancierEnum.UPGRADE.id)) {
    radioOptions1.push({
      key: 'Upgrade',
      id: 'defaultBankUpgrade',
      value: '' + SelectedFinancierEnum.UPGRADE.id,
    });
  }

  const clickHandler = value => {
    let radioOptions = [];
    let checkedFirst = radioOptionSelected === value;
    radioOptions1.forEach(option => {
      if (
        option.value !== '' + SelectedFinancierEnum.MOSAIC.id &&
        option.value !== '' + SelectedFinancierEnum.GREENSKY.id &&
        option.value !== '' + SelectedFinancierEnum.ENERBANK.id &&
        value !== option.value
      ) {
        if (checkedFirst || radioOptionSelected === option.value) {
          radioOptions.push({
            key: option.key,
            id: 'bank2nd' + option.key.replaceAll(' ', ''),
            value: option.value,
            image: option.image,
            checked: true,
          });
          radioOptionSelected = option.value;
          checkedFirst = false;
        } else {
          radioOptions.push({
            key: option.key,
            id: 'bank2nd' + option.key.replaceAll(' ', ''),
            value: option.value,
            image: option.image,
          });
        }
      }
    });

    radioOptions2 = radioOptions;

    const isFtlInPrimaryOpts = radioOptions1.find(
      x => x.value === '' + SelectedFinancierEnum.FTL.id,
    );

    if (isBankUser(dealerBanks, SelectedFinancierEnum.FTL.id) && !isFtlInPrimaryOpts) {
      radioOptions2.push({
        key: SelectedFinancierEnum.FTL.value,
        id: 'defaultFTLBank',
        value: '' + SelectedFinancierEnum.FTL.id,
        image: isThemeOptimus(theme) ? ftl : null,
      });
    }

    if (isBankUser(dealerBanks, SelectedFinancierEnum.FOUNDATION.id) && isFoundationSecondary()) {
      radioOptions2.push({
        key: 'Foundation',
        id: 'bank2ndFoundation',
        value: '' + SelectedFinancierEnum.FOUNDATION.id,
        image: isThemeOptimus(theme) ? foundation : null,
      });
    }

    radioOptions2.push({
      key: 'None',
      id: 'bank2ndNone',
      value: '0',
    });
  };

  clickHandler(defaultBankId);

  const clickHandler2 = value => {
    radioOptionSelected = value;
  };

  const FinancingOptionForm = () => (
    <Form>
      <Row>
        <Col>
          <h2>Primary Financing Option</h2>
          <p>Please choose your primary financing option (you can only choose one)</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={RadioButtons}
            name="radioOption"
            options={radioOptions1}
            clickHandler={clickHandler}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>2nd Look Financing Option</h2>
          <p>Please choose your 2nd Look financing option (you can only choose one)</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={RadioButtons}
            name="bank2nd"
            options={radioOptions2}
            clickHandler={clickHandler2}
          />
        </Col>
      </Row>

      <Row className="mt-5">
        <Col className="text-right">
          <Button
            type="reset"
            color="secondary"
            className="rounded-pill"
            outline
            onClick={envent => {
              cancel(envent);
            }}
          >
            Cancel
          </Button>
          <Button type="submit" color="primary" className="rounded-pill">
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );

  const FinancingOptionFormFormik = withFormik({
    mapPropsToValues: () => ({
      radioOption: '' + (defaultBankId || SelectedFinancierEnum.AQUA_FINANCE.id),
      bank2nd: '' + (secondBankId || 0),
    }),

    validationSchema: Yup.object({
      radioOption: Yup.string().required(
        'Please choose your primary financing option (you can only choose one)',
      ),
      bank2nd: Yup.string().required(
        'Please choose your 2nd Look financing option (you can only choose one)',
      ),
    }),

    handleSubmit: async values => {
      dispatchSetDefaultFinancingOption(values.radioOption, radioOptionSelected, history);
    },
  })(FinancingOptionForm);

  return (
    <Col>
      <Row>
        <Col>
          <h2>Financing Option</h2>
        </Col>
      </Row>
      <Row className="panel">
        <Col className="panel-body larger">
          <Row>
            <Col className="right-bordered">
              <FinancingOptionFormFormik />
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  defaultBankId:
    '' +
    (state.auth.user.user && state.auth.user.user.dealerStatsSummaryDTO
      ? state.auth.user.user.dealerStatsSummaryDTO.defaultBankId
      : SelectedFinancierEnum.AQUA_FINANCE.id),
  secondBankId:
    '' +
    (state.auth.user.user && state.auth.user.user.dealerStatsSummaryDTO
      ? state.auth.user.user.dealerStatsSummaryDTO.secondBankId
      : 0),
  dealerBanks: state.auth.user.dealerUser.dealer.dealerBanks,
  theme: state.theme.theme,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetDefaultFinancingOption: (bankId, bankId2nd, history) =>
    dispatch(setDefaultFinancingOption(bankId, bankId2nd, history)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(FinancingOption),
);
