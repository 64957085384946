const HvacHeatTypeEnum = {
  FURNACE: {
    value: 'FUR',
    name: 'Furnace (Gas, Oil, or Boiler)',
  },
  ELECTRIC_AIR_CONDITIONER: {
    value: 'EAC',
    name: 'Electric (Air Conditioner)',
  },
  ELECTRIC_HEAT_PUMP: {
    value: 'EHP',
    name: 'Electric (Heat Pump)',
  },
};

export default HvacHeatTypeEnum;
