import PropTypes from 'prop-types';
import React from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { Col } from 'reactstrap';
import './BackBtn.scss';
import { connect } from 'react-redux';
import { reactGAEvent } from '../../../helper';
import { Link } from 'react-router-dom';

const BackBtn = ({ page, changePage, did, dealerUserId, handleClick }) => {
  const handleChangePage = event => {
    event.preventDefault();
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Back',
      did,
      dealerUserId,
    });
    changePage(page);
  };

  return (
    <Col sm="1">
      <Link
        to=""
        className="btn-back"
        onClick={e => {
          if (handleClick) {
            handleClick();
          }
          handleChangePage(e);
        }}
      >
        <FiArrowLeft />
      </Link>
    </Col>
  );
};

BackBtn.propTypes = {
  page: PropTypes.number.isRequired,
  changePage: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

export default connect(mapStateToProps)(BackBtn);
