import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import homeType from '../../../assets/js/HomeTypeEnum';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import Input from '../../../components/UI/Form/Input/CustomInput';
import Select from '../../../components/UI/Form/Select/Select';
import {
  PROPERTY_TYPE_TOOLTIP_TEXT,
  TIME_AT_ADDRESS_TOOLTIP_TEXT,
} from '../../../constants/tooltipTexts';
import { reactGAEvent, reactGAPageview } from '../../../helper';
import { changePage, submitMindfire, changePowerPayInfoValue } from '../../../redux/actions';
import { PowerPayFlowPageNumber } from './PowerPayFlow';

const PropertyDetails = props => {
  const {
    email,
    dispatchChangePage,
    dispatchChangePowerPayInfoValue,
    children,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    propertyType,
    propertyYears,
    propertyMonths,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_pppropertydetails',
      trackerNames: [],
      title: 'ca_pppropertydetails',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const PropertyDetailsForm = () => (
    <Form>
      <Field
        component={Select}
        label="Property Type"
        name="propertyType"
        id="propertyType"
        type="select"
        selectValues={homeType}
        selectFirstName="Select One"
        tooltipText={PROPERTY_TYPE_TOOLTIP_TEXT}
      />
      <Row className="mt-2">
        <Col>
          <Label>
            <b>Time at address</b>
          </Label>
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          <Field
            component={Input}
            placeholder="Years"
            formGroupClass="mt-0 mb-2"
            labelClassName="d-none"
            name="propertyYears"
            id="propertyYears"
            type="number"
            min={0}
            tooltipText={TIME_AT_ADDRESS_TOOLTIP_TEXT}
          />
        </Col>
        <Col sm="6">
          <Field
            component={Input}
            placeholder="Months"
            formGroupClass="mt-0 mb-2"
            labelClassName="d-none"
            name="propertyMonths"
            id="propertyMonths"
            type="number"
            min={0}
            tooltipText={TIME_AT_ADDRESS_TOOLTIP_TEXT}
          />
        </Col>
      </Row>
      <Button type="submit" title="Next" />
    </Form>
  );

  const PropertyDetailsFormFormik = withFormik({
    mapPropsToValues: () => ({
      propertyType: propertyType || '',
      propertyYears: propertyYears || '',
      propertyMonths: propertyMonths || '',
    }),

    validationSchema: Yup.object({
      propertyType: Yup.string().required('Please select an option.'),
      propertyYears: Yup.number()
        .moreThan(-1, 'Please enter a positive number.')
        .integer('Please enter an integer number.')
        .max(70, 'Must be less than or equal to 70.')
        .required('Please enter years.'),
      propertyMonths: Yup.number()
        .moreThan(-1, 'Please enter a positive number.')
        .integer('Please enter an integer number.')
        .max(11, 'Must be less than or equal to 11.')
        .required('Please enter months.'),
    }),

    handleSubmit: async values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Continue',
        did,
        dealerUserId,
      });
      dispatchChangePowerPayInfoValue('propertyType', values.propertyType);
      dispatchChangePowerPayInfoValue('propertyYears', values.propertyYears);
      dispatchChangePowerPayInfoValue('propertyMonths', values.propertyMonths);
      dispatchSubmitMindfire({
        email: email,
        lastPage: '6012',
        dealerUserId: dealerUserId,
        did: did,
      });

      dispatchChangePage(PowerPayFlowPageNumber.AppEmploymentDetails);
    },
  })(PropertyDetailsForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={PowerPayFlowPageNumber.AppIdentificationInfo}
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <PropertyDetailsFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  propertyType: state.powerPayInformation.propertyType,
  propertyYears: state.powerPayInformation.propertyYears,
  propertyMonths: state.powerPayInformation.propertyMonths,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangePowerPayInfoValue: (key, value) => dispatch(changePowerPayInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PropertyDetails);
