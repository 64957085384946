import React from 'react';

const PrequalificationInformationTable = props => {
  const { prequalificationInfoDTOs, history } = props;

  return (
    <table className="table table-hover table-striped">
      <thead>
        <tr>
          <th>Id</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Email</th>
          <th>Address</th>
        </tr>
      </thead>
      <tbody>
        {prequalificationInfoDTOs.map((pqi, index) => (
          <tr
            className="cursor-pointer"
            key={index}
            onClick={() => {
              history.push(`/?pqiToken=${pqi.token}&pqiId=${pqi.prequalificationId}`);
            }}
          >
            <td>{pqi.prequalificationId}</td>
            <td>{pqi.firstName}</td>
            <td>{pqi.lastName}</td>
            <td>{pqi.email}</td>
            <td>{`${pqi.street}, ${pqi.city}, ${pqi.state}`}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PrequalificationInformationTable;
