import React from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import Button from '../../components/UI/Button/CustomButton';

const AlreadyFunded = props => (
  <Container>
    <Row className="justify-content-center">
      <Col xs="12" md="8">
        <h6 className="text-center">
          Make, model and serial number(s) has/have already been input. When Microf changes the
          status to "Producer Not Funded", you will able to complete the Service Finance
          certification process.
        </h6>
      </Col>
    </Row>
    <Row className="justify-content-center">
      <Col xs="12" md="auto">
        <Button
          type="submit"
          color="primary"
          title="Return"
          clickHandler={() => props.history.push('/login')}
        />
      </Col>
    </Row>
  </Container>
);

export default withRouter(AlreadyFunded);
