import React from 'react';
import { Redirect } from 'react-router-dom';
import SelectedFinancierEnum from '../../../assets/js/SelectedFinancierEnum';
import { getIsRheem } from '../../../config/settings';
import {
  defaultFlow,
  mosaicPreQualificationFlow,
  powerPayPreQualificationFlow,
  wellsFargoFlow,
  foundationPreQualificationFlow,
  enerBankPreQualificationFlow,
  microfFlow,
} from '../../../redux/initialState';
import { MosaicPreQualificationFlowPageNumber } from '../../Mosaic/PreQualification/PreQualificationFlow';
import { PowerPayPreQualificationFlowPageNumber } from '../../PowerPay/PreQualification/PreQualificationFlow';
import { AquaPreQualificationFlowPageNumber } from '../../PreQualification/preQualificationFlow';
import { WellsFargoFlowPageNumber } from '../../WellsFargo/WellsFargoFlow';
import { FoundationPreQualificationFlowPageNumber } from '../../Optimus/Foundation/PreQualification/PreQualificationFlow';
import ApplicationFlowEnum from '../../../assets/js/ApplicationFlowEnum';
import { GreenSkyPreQualificationFlowPageNumber } from '../../Optimus/GreenSky/PreQualification/PreQualificationFlow';
import { EnerBankPreQualificationFlowPageNumber } from '../../EnerBank/PreQualification/PreQualificationFlow';
import { FTLPreQualificationFlowPageNumber } from '../../FTL/PreQualification/PreQualificationFlow';
import { UpgradePreQualificationFlowPageNumber } from '../../Upgrade/PreQualification/preQualificationFlow';

const NewApplicationDefault = ({
  auth,
  dispatchChangePage,
  dispatchChangeMicrofInfoValue,
  dispatchChangeAuthValue,
  dispatchSetFlow,
  dispatchResetNewAppState,
}) => {
  const isRheem = getIsRheem();
  const aquaFinanceAssigned =
    auth.user.user && auth.user.user.dealerStatsSummaryDTO
      ? auth.user.user.dealerStatsSummaryDTO.aquaFinanceAssigned
      : null;
  const powerPayAssigned =
    auth.user.user && auth.user.user.dealerStatsSummaryDTO
      ? auth.user.user.dealerStatsSummaryDTO.powerPayAssigned
      : null;
  const mosaicAssigned =
    auth.user.user && auth.user.user.dealerStatsSummaryDTO
      ? auth.user.user.dealerStatsSummaryDTO.mosaicAssigned
      : null;
  const defaultBankId =
    auth.user.user && auth.user.user.dealerStatsSummaryDTO
      ? auth.user.user.dealerStatsSummaryDTO.defaultBankId
      : SelectedFinancierEnum.AQUA_FINANCE.id;
  const wellsFargoAssigned =
    auth.user.user && auth.user.user.dealerStatsSummaryDTO
      ? auth.user.user.dealerStatsSummaryDTO.wellsFargoAssigned
      : null;
  const foundationAssigned =
    auth.user.user && auth.user.user.dealerStatsSummaryDTO
      ? auth.user.user.dealerStatsSummaryDTO.foundationAssigned
      : null;
  const greenskyAssigned = auth.user?.user?.dealerStatsSummaryDTO?.greenskyAssigned;
  const enerBankAssigned =
    auth.user.user && auth.user.user.dealerStatsSummaryDTO
      ? auth.user.user.dealerStatsSummaryDTO.enerBankAssigned
      : null;
  const ftlAssigned = auth.user?.user?.dealerStatsSummaryDTO?.ftlAssigned;
  const upgradeAssigned =
    auth.user.user && auth.user.user.dealerStatsSummaryDTO
      ? auth.user.user.dealerStatsSummaryDTO.upgradeAssigned
      : null;

  if (isRheem) {
    dispatchChangePage(AquaPreQualificationFlowPageNumber.AccountEmail);
    return <Redirect to={{ pathname: '/' }} />;
  } else {
    if (aquaFinanceAssigned && defaultBankId === SelectedFinancierEnum.AQUA_FINANCE.id) {
      dispatchChangeAuthValue('aquaFinanceAssigned', true);
      dispatchSetFlow(defaultFlow);
      dispatchChangePage(AquaPreQualificationFlowPageNumber.AccountEmail);
      return <Redirect to={{ pathname: '/' }} />;
    } else if (powerPayAssigned && defaultBankId === SelectedFinancierEnum.POWER_PAY.id) {
      dispatchChangeAuthValue('powerPayAssigned', true);
      dispatchSetFlow(powerPayPreQualificationFlow);
      dispatchChangePage(PowerPayPreQualificationFlowPageNumber.Landing);
      return <Redirect to={{ pathname: '/powerpay-prequalification' }} />;
    } else if (mosaicAssigned && defaultBankId === SelectedFinancierEnum.MOSAIC.id) {
      dispatchResetNewAppState();
      dispatchChangeAuthValue('mosaicAssigned', true);
      dispatchSetFlow(mosaicPreQualificationFlow);
      dispatchChangePage(MosaicPreQualificationFlowPageNumber.Landing);
      return <Redirect to={{ pathname: '/mosaic-prequalification' }} />;
    } else if (enerBankAssigned && defaultBankId === SelectedFinancierEnum.ENERBANK.id) {
      dispatchResetNewAppState();
      dispatchChangeAuthValue('enerBankAssigned', true);
      dispatchSetFlow(enerBankPreQualificationFlow);
      dispatchChangePage(EnerBankPreQualificationFlowPageNumber.AccountEmail);
      return <Redirect to={{ pathname: '/enerbank-prequalification' }} />;
    } else if (wellsFargoAssigned && !aquaFinanceAssigned) {
      dispatchChangeAuthValue('wellsFargoAssigned', true);
      dispatchSetFlow(wellsFargoFlow);
      dispatchChangePage(WellsFargoFlowPageNumber.Landing);
      return <Redirect to={{ pathname: '/WellsFargo' }} />;
    } else if (foundationAssigned && defaultBankId === SelectedFinancierEnum.FOUNDATION.id) {
      dispatchChangeAuthValue('foundationAssigned', true);
      dispatchSetFlow(foundationPreQualificationFlow);
      dispatchChangePage(FoundationPreQualificationFlowPageNumber.AccountEmail);
      return <Redirect to={{ pathname: '/foundation-prequalification' }} />;
    } else if (greenskyAssigned && defaultBankId === SelectedFinancierEnum.GREENSKY.id) {
      dispatchChangeAuthValue('greenskyAssigned', true);
      dispatchSetFlow(ApplicationFlowEnum.GREENSKY_PREQUALIFICATION.value);
      dispatchChangePage(GreenSkyPreQualificationFlowPageNumber.AccountEmail);
      return <Redirect to={{ pathname: ApplicationFlowEnum.GREENSKY_PREQUALIFICATION.path }} />;
    } else if (ftlAssigned && defaultBankId === SelectedFinancierEnum.FTL.id) {
      dispatchChangeAuthValue('ftlAssigned', true);
      dispatchSetFlow(ApplicationFlowEnum.FTL_PREQUALIFICATION_FLOW.value);
      dispatchChangePage(FTLPreQualificationFlowPageNumber.AccountEmail);
      return <Redirect to={{ pathname: ApplicationFlowEnum.FTL_PREQUALIFICATION_FLOW.path }} />;
    } else if (upgradeAssigned && defaultBankId === SelectedFinancierEnum.UPGRADE.id) {
      dispatchChangeAuthValue('upgradeAssigned', true);
      dispatchSetFlow(ApplicationFlowEnum.UPGRADE_PREQUALIFICATION.value);
      dispatchChangePage(UpgradePreQualificationFlowPageNumber.AccountEmail);
      return <Redirect to={{ pathname: ApplicationFlowEnum.UPGRADE_PREQUALIFICATION.path }} />;
    } else {
      dispatchChangeMicrofInfoValue('microfOnly', true);
      dispatchSetFlow(microfFlow);
      return <Redirect to={{ pathname: '/RTO' }} />;
    }
  }
};

export default NewApplicationDefault;
