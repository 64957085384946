import React from 'react';
import { connect } from 'react-redux';
import { Button, Col, Container, Row } from 'reactstrap';
import { changePage, submitMindfire, changeTheme, setFlow } from '../../../../redux/actions';
import { reactGAEvent } from '../../../../helper';
import { withRouter } from 'react-router-dom';
import ApplicationFlowEnum from '../../../../assets/js/ApplicationFlowEnum';
import { MicrofFlowPageNumber } from '../../../Microf/MicrofFlow';
const GreenskyDeclinedToMicrof = props => {
  const {
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    email,
    history,
    dispatchSetFlow,
    dispatchChangePage,
    softPullResponse,
  } = props;

  return (
    <Container>
      <Row className="justify-content-center mt-2">
        <Col xs="12" md="auto" lg="8" className="text-center">
          <p className="fs-20">
            EGIA was unable to prequalify you for any financing offers from platform lenders or
            lessors. You will receive notification via email within 30 days.
          </p>
        </Col>
      </Row>
      <Row className="justify-content-center mt-2">
        <Col xs="12" md="auto" lg="6" className="text-center">
          <p className="fs-20">
            But wait! You may still qualify for an AtWater purchase option. An AtWater purchase
            option is not financing.
          </p>
        </Col>
      </Row>
      <Container>
        <Row className="justify-content-center mt-2">
          <Col xs="auto" sm="12" className="text-center">
            <p className="fs-25">
              <b className="primary">
                Congratulations! You've prequalified for a Microf Lease Offer
              </b>
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center ">
          <Col xs="auto" lg="8" sm="12" md="12">
            <p className="fs-22 fw-500 pl-40">
              Microf’s lease purchase option is different than financing:
            </p>
            <ul className="powerpay-microf-lease-purchase-options-arrow">
              <li>
                Pay as you go!{' '}
                <span className="fw-normal">
                  Monthly renewals up to 60 months to own in most states.
                </span>
              </li>
              <li>
                Own the item(s) after paying the total of payments to ownership.{' '}
                <span className="fw-normal">
                  Substantial savings are available through Early Purchase Options!
                </span>
              </li>
              <li>
                Damaged credit?{' '}
                <span className="fw-normal">
                  Microf regularly approves applicants with less than perfect credit.
                </span>
              </li>
              <li>
                Have questions?{' '}
                <span className="fw-normal">
                  Call us at 855-642-7631 or email us at info@microf.com.
                </span>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="justify-content-center ">
          <Col xs="auto" lg="8" sm="12" md="12">
            <p className="fs-22 fw-500 pl-40">
              Here is what you will need to complete the application:
            </p>
            <ul className="microf-lease-purchase-options">
              <li>Social Security Number</li>
              <li>Bank account information (routing and account numbers)</li>
              <li>Income or benefits information (e.g., paystub, bank statement, etc.)</li>
              <li>Co-applicant information (not required)</li>
            </ul>
          </Col>
        </Row>
        <Row className="justify-content-center ">
          <Col xs="auto" lg="8" sm="12" md="12">
            <p className="fs-22 fw-500 pl-40">Not eligible for lease purchase:</p>
            <ul className="powerpay-microf-lease-purchase-options-x">
              <li>Homes on rented land (e.g., mobile homes in a park)</li>
              <li>Reverse mortgages</li>
              <li>Rent-to-own or lease-to-own properties</li>
              <li>Homeowners with active or dismissed bankruptcies</li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Row className="mt-3 justify-content-center">
        <Col xs="12" md="auto">
          <Button
            color="primary"
            onClick={() => {
              reactGAEvent({
                category: 'Button',
                action: 'Click',
                label: 'Start Application',
                did,
                dealerUserId,
              });
              dispatchSetFlow(ApplicationFlowEnum.MICROF_FLOW.value);
              dispatchChangePage(MicrofFlowPageNumber.AccountEmail);
              dispatchSubmitMindfire({
                email: email,
                lastPage: '4066',
                dealerUserId: dealerUserId,
                did: did,
              });
            }}
          >
            Continue to Microf
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  softPullResponse: state.softPull.softPullResponse,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchChangeTheme: theme => dispatch(changeTheme(theme)),
  dispatchSetFlow: flow => dispatch(setFlow(flow)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(GreenskyDeclinedToMicrof),
);
