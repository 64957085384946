import React from 'react';
import { currencyFormat } from '../../../../helper';
import InputFeedback from '../InputFeedback/InputFeedback';

const FtlRadioButtons = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched, setFieldValue },
  icon,
  max,
  min,
  maxLength,
  className,
  pattern,
  disabled,
  number,
  label,
  labelClassName,
  type,
  id,
  placeholder,
  formGroupClassName,
  readOnly,
  optionsComponent,
  options,
  clickHandler,
  ...props
}) => {
  return (
    <>
      {optionsComponent && (
        <table className="table-title">
          <tbody>
            <tr>
              <td>Contractor Fee:</td>
            </tr>
          </tbody>
        </table>
      )}

      <table>
        <tbody>
          {!optionsComponent && (
            <tr className="productsTableHead">
              <td />
              <td />
              <td>Approve More Fee:</td>
              <td>Contractor Fee:</td>
            </tr>
          )}
          {options.map(option => {
            const hasFee = option.feeAgreement !== null;
            return (
              <tr key={option.key}>
                <td className={!hasFee ? 'green' : ''}>
                  <input
                    {...props}
                    type="radio"
                    id={option.id}
                    name={name}
                    value={option.value}
                    checked={option.value === value}
                    onChange={onChange}
                    onBlur={onBlur}
                    onClick={() => {
                      option.feeAgreement
                        ? setFieldValue('isConsent', true)
                        : setFieldValue('isConsent', false);
                      option.feeAgreement && setFieldValue('consent', false);
                    }}
                  />
                  <label htmlFor={option.id}>{option.name}</label>
                </td>
                {option.description ? (
                  <td className={!hasFee ? 'green' : ''}>
                    <label htmlFor={option.id}>{option.description}</label>
                  </td>
                ) : (
                  optionsComponent === true && (
                    <td className={!hasFee ? 'green' : 'grey'}>
                      <label htmlFor={option.id}>{`Standard Programs`}</label>
                    </td>
                  )
                )}
                {option.estimatedPayment ? (
                  <td className={!hasFee ? 'green' : ''}>
                    <label htmlFor={option.id}>{option.estimatedPayment}</label>
                  </td>
                ) : (
                  optionsComponent === true && (
                    <td className={!hasFee ? 'green' : 'grey'}>
                      <label htmlFor={option.id}>{` `}</label>
                    </td>
                  )
                )}
                {option.monthlyPayment && (
                  <td className={''}>
                    <label htmlFor={option.id}>{option.monthlyPayment}</label>
                  </td>
                )}
                {!optionsComponent && (
                  <td className={'text-align-center'}>
                    <label htmlFor={option.id}>
                      {option.approveMoreFee
                        ? currencyFormat(option.approveMoreFee)
                        : currencyFormat(0)}
                    </label>
                  </td>
                )}
                <td
                  className={`${!hasFee ? 'green' : 'grey'} ${
                    !optionsComponent ? 'text-align-center' : ''
                  }`}
                >
                  {!optionsComponent ? (
                    <label htmlFor={option.id}>
                      {option.contractorFee ? currencyFormat(option.contractorFee) : 'FREE'}
                    </label>
                  ) : (
                    <label htmlFor={option.id}>
                      {option.contractorFee ? option.contractorFee + `%` : 'FREE'}
                    </label>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {touched[name] && <InputFeedback error={errors[name]} />}
    </>
  );
};

export default FtlRadioButtons;
