import React from 'react';
import DealershipInformation from './DealershipInformation';
import BusinessManager from './BusinessManager';
import FinalMessage from './FinalMessage';
import BeneficialOwnerInformation from './BeneficialOwnerInformation';

const Flow = [
  <DealershipInformation title="Contractor Enrollment - Dealership Information">
    <h2>Contractor Enrollment</h2>
  </DealershipInformation>,
  <BusinessManager title="Contractor Enrollment - Business Manager">
    <h2>Business Manager</h2>
    <h3>Individual who will sign the Microf Contractor Agreement (Please use legal name)</h3>
  </BusinessManager>,
  <BeneficialOwnerInformation title="Contractor Enrollment - Beneficial Owner Information">
    <h2>Beneficial Owner Information</h2>
  </BeneficialOwnerInformation>,
  <FinalMessage title="Contractor Enrollment" />,
];

export default Flow;

export const ContractorEnrollmentFlowPageNumber = {
  DealershipInformation: 0,
  BusinessManager: 1,
  BeneficialOwnerInformation: 2,
  FinalMessage: 3,
};
