import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import FTLEmploymentTypeEnum from '../../../../assets/js/FTLEmploymentTypeEnum';
import USStateEnum from '../../../../assets/js/USStateEnum';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import CustomInput from '../../../../components/UI/Form/Input/CustomInput';
import CurrencyFormat from '../../../../components/UI/Form/NumberFormat/CurrencyFormat';
import PhoneNumber from '../../../../components/UI/Form/PhoneNumber/PhoneNumber';
import Select from '../../../../components/UI/Form/Select/Select';
import SelectWithHandleChange from '../../../../components/UI/Form/Select/SelectWithHandleChange';
import {
  COAPP_EMPLOYER_CITY_TOOLTIP_TEXT,
  COAPP_EMPLOYER_NAME_TOOLTIP_TEXT,
  COAPP_EMPLOYER_PHONE_NUMBER_TOOLTIP_TEXT,
  COAPP_EMPLOYER_STATE_TOOLTIP_TEXT,
  COAPP_EMPLOYER_STREET_TOOLTIP_TEXT,
  COAPP_EMPLOYER_ZIPCODE_TOOLTIP_TEXT,
  COAPP_EMPLOYMENT_STATUS_TOOLTIP_TEXT,
  COAPP_GROSS_ANNUAL_INCOME_TOOLTIP_TEXT,
  COAPP_OCCUPATION_STATUS_TOOLTIP_TEXT,
} from '../../../../constants/tooltipTexts';
import { isApplicantEmployedFTL, reactGAEvent, reactGAPageview } from '../../../../helper';
import { changeFtlInfoValue, changePage, submitMindfire } from '../../../../redux/actions/index';
import { FTLFlowPageNumber } from '../FTLFlow';

const EmploymentInformation = props => {
  const {
    isReview,
    email,
    dispatchChangePage,
    dispatchChangeFtlInfoValue,
    ftlInformation: {
      coAppEmploymentStatus,
      coAppEmployerName,
      coAppOccupation,
      coAppEmployerStreet,
      coAppEmployerCity,
      coAppEmployerZipCode,
      coAppEmployerState,
      coAppEmployerPhoneNumber,
      coAppAnnualIncome,
      coAppDoB,
      coAppFullSsn,
    },
    children,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
  } = props;
  let isEmployed = isApplicantEmployedFTL(coAppEmploymentStatus);

  useEffect(() => {
    reactGAPageview({
      path: '/ca_ftlcappincome',
      trackerNames: [],
      title: 'ca_ftlcappincome',
      dealerUserId,
      did,
    });
    // eslint-disable-next-line
  }, []);

  const changeValues = values => {
    isEmployed = isApplicantEmployedFTL(coAppEmploymentStatus);

    dispatchChangeFtlInfoValue('coAppEmployerName', isEmployed ? values.coAppEmployerName : null);
    dispatchChangeFtlInfoValue('coAppEmploymentStatus', values.coAppEmploymentStatus);
    dispatchChangeFtlInfoValue('coAppOccupation', isEmployed ? values.coAppOccupation : null);
    dispatchChangeFtlInfoValue(
      'coAppEmploymentYears',
      isEmployed ? parseInt(values.coAppEmploymentYears) : null,
    );
    dispatchChangeFtlInfoValue(
      'coAppEmploymentMonths',
      isEmployed ? parseInt(values.coAppEmploymentMonths) : null,
    );
    dispatchChangeFtlInfoValue('coAppAnnualIncome', values.coAppAnnualIncome);
    dispatchChangeFtlInfoValue('coAppAnnualIncome', values.coAppAnnualIncome);
    dispatchChangeFtlInfoValue('isCoAppEmployed', isEmployed);

    dispatchChangeFtlInfoValue(
      'coAppEmployerStreet',
      isEmployed ? values.coAppEmployerStreet : null,
    );
    dispatchChangeFtlInfoValue('coAppEmployerCity', isEmployed ? values.coAppEmployerCity : null);
    dispatchChangeFtlInfoValue('coAppEmployerState', isEmployed ? values.coAppEmployerState : null);
    dispatchChangeFtlInfoValue(
      'coAppEmployerZipCode',
      isEmployed ? values.coAppEmployerZipCode : null,
    );
    dispatchChangeFtlInfoValue(
      'coAppEmployerPhoneNumber',
      isEmployed ? values.coAppEmployerPhoneNumber : null,
    );
  };

  const EmploymentInformationForm = ({ values }) => (
    <Form>
      <Field
        component={SelectWithHandleChange}
        label="Income (or Benefits) Type"
        id="coAppEmploymentStatus"
        tooltipText={COAPP_EMPLOYMENT_STATUS_TOOLTIP_TEXT}
        name="coAppEmploymentStatus"
        type="select"
        selectValues={FTLEmploymentTypeEnum}
        selectFirstName="Select One"
        handleChange={e => changeValues({ ...values, coAppEmploymentStatus: e.target.value })}
      />
      {isEmployed && (
        <>
          <Field
            component={CustomInput}
            label="Employer"
            name="coAppEmployerName"
            id="coAppEmployerName"
            tooltipText={COAPP_EMPLOYER_NAME_TOOLTIP_TEXT}
            type="text"
          />
          <Field
            component={CustomInput}
            label="Title/Occupation"
            name="coAppOccupation"
            id="coAppOccupation"
            tooltipText={COAPP_OCCUPATION_STATUS_TOOLTIP_TEXT}
            type="text"
          />
          <Field
            component={CustomInput}
            label="Employer Street"
            name="coAppEmployerStreet"
            id="coAppEmployerStreet"
            tooltipText={COAPP_EMPLOYER_STREET_TOOLTIP_TEXT}
            type="text"
          />
          <Row>
            <Col sm="6">
              <Field
                component={CustomInput}
                label="Employer City"
                name="coAppEmployerCity"
                id="coAppEmployerCity"
                tooltipText={COAPP_EMPLOYER_CITY_TOOLTIP_TEXT}
                type="text"
              />
            </Col>
            <Col sm="6">
              <Field
                component={Select}
                label="Employer State"
                name="coAppEmployerState"
                id="coAppEmployerState"
                tooltipText={COAPP_EMPLOYER_STATE_TOOLTIP_TEXT}
                type="select"
                selectValues={USStateEnum}
                selectFirstName="Select State"
              />
            </Col>
          </Row>
          <Field
            component={CustomInput}
            label="Employer ZIP Code"
            name="coAppEmployerZipCode"
            id="coAppEmployerZipCode"
            tooltipText={COAPP_EMPLOYER_ZIPCODE_TOOLTIP_TEXT}
            type="text"
            maxLength={10}
          />
          <Field
            component={PhoneNumber}
            label="Employer Phone Number"
            name="coAppEmployerPhoneNumber"
            id="coAppEmployerPhoneNumber"
            tooltipText={COAPP_EMPLOYER_PHONE_NUMBER_TOOLTIP_TEXT}
          />
        </>
      )}

      <Field
        component={CurrencyFormat}
        label="Annual Income (or Benefits) Amount"
        name="coAppAnnualIncome"
        id="coAppAnnualIncome"
        tooltipText={COAPP_GROSS_ANNUAL_INCOME_TOOLTIP_TEXT}
        type="text"
      />
      <Button type="submit" title="Continue" />
    </Form>
  );

  const EmploymentInformationFormFormik = withFormik({
    mapPropsToValues: () => ({
      coAppEmploymentStatus: coAppEmploymentStatus || '',
      coAppEmployerName: coAppEmployerName || '',
      coAppOccupation: coAppOccupation || '',
      coAppEmployerStreet: coAppEmployerStreet || '',
      coAppEmployerCity: coAppEmployerCity || '',
      coAppEmployerZipCode: coAppEmployerZipCode || '',
      coAppEmployerState: coAppEmployerState || '',
      coAppEmployerPhoneNumber: coAppEmployerPhoneNumber || '',
      coAppAnnualIncome: coAppAnnualIncome || '',
    }),

    validationSchema: Yup.object({
      coAppEmploymentStatus: Yup.string().required('Please select the employment status.'),
      coAppEmployerName: isEmployed
        ? Yup.string().required("Please enter your employer's name.")
        : Yup.string(),
      coAppOccupation: isEmployed
        ? Yup.string().required('Please enter your occupation.')
        : Yup.string(),
      coAppEmployerStreet: isEmployed
        ? Yup.string().required('Please enter a street.')
        : Yup.string(),
      coAppEmployerCity: isEmployed ? Yup.string().required('Please enter a city.') : Yup.string(),
      coAppEmployerZipCode: isEmployed
        ? Yup.string()
            .matches(/^\d{5}?$/, {
              message: 'Invalid zip code.',
              excludeEmptyString: true,
            })
            .required('Please enter a zip code.')
        : Yup.string(),
      coAppEmployerState: isEmployed
        ? Yup.string().required('Please select a state.')
        : Yup.string(),
      coAppEmployerPhoneNumber: isEmployed
        ? Yup.string()
            .test(
              'required',
              "Please enter the Employer's phone number.",
              val => val !== '+' && val,
            )
            .test(
              'len',
              'Invalid phone number',
              val => val && val.match(/\d/g).join('').length === 10,
            )
        : Yup.string(),
      coAppAnnualIncome: Yup.number().required('Please enter your annual income.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Continue',
        did: did,
        dealerUserId: dealerUserId,
      });
      changeValues(values);
      dispatchChangePage(
        isReview && coAppDoB && coAppFullSsn
          ? FTLFlowPageNumber.Review
          : FTLFlowPageNumber.CoAppIdentificationInformation,
      );
      dispatchSubmitMindfire({
        email: email,
        company: values.coAppEmployerName,
        lastPage: '12016',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(EmploymentInformationForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={isReview ? FTLFlowPageNumber.Review : FTLFlowPageNumber.CoAppAddress}
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <EmploymentInformationFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  ftlInformation: state.ftlInformation,
  email: state.preQualificationInformation.email,
  isReview: state.ftlInformation.isReview,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeFtlInfoValue: (key, value) => dispatch(changeFtlInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmploymentInformation);
