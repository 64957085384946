import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import usStates from '../../../assets/js/USStateEnum';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import Input from '../../../components/UI/Form/Input/CustomInput';
import Select from '../../../components/UI/Form/Select/Select';
import {
  CITY_TOOLTIP_TEXT,
  STATE_TOOLTIP_TEXT,
  STREET_TOOLTIP_TEXT,
  UNIT_NUMBER_TOOLTIP_TEXT,
  ZIPCODE_TOOLTIP_TEXT,
} from '../../../constants/tooltipTexts';
import { reactGAEvent, reactGAPageview } from '../../../helper';
import { changePage, submitMindfire, changeEnerBankInfoValue } from '../../../redux/actions';
import { EnerBankFlowPageNumber } from './EnerBankFlow';

const MailingAddress = props => {
  const {
    email,
    dispatchChangePage,
    dispatchChangeInfoValue,
    children,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    sameMailingAddress,
    mailingAddress,
    address,
    isReview,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_enmailingaddress',
      trackerNames: [],
      title: 'ca_enmailingaddress',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const changeValues = values => {
    let newMailingAddress = {
      street: values.street,
      apartmentNumber: values.apartmentNumber,
      city: values.city,
      state: values.state,
      zipcode: values.zipcode,
    };
    dispatchChangeInfoValue('mailingAddress', newMailingAddress);
  };

  const MailingAddressForm = ({ values }) => (
    <Form>
      <Row>
        <Col sm="6">
          <Button
            className={sameMailingAddress ? '' : 'inactive'}
            type="button"
            title="Yes"
            clickHandler={() => {
              dispatchChangeInfoValue('sameMailingAddress', true);
              let newMailingAddress = {
                street: address.street,
                apartmentNumber: values.apartmentNumber,
                city: address.city,
                state: address.state,
                zipcode: address.zipcode,
              };
              dispatchChangeInfoValue('mailingAddress', newMailingAddress);
            }}
          />
        </Col>
        <Col sm="6">
          <Button
            className={!sameMailingAddress ? '' : 'inactive'}
            type="button"
            title="No"
            clickHandler={() => {
              dispatchChangeInfoValue('sameMailingAddress', false);
              changeValues(values);
            }}
          />
        </Col>
      </Row>
      <Field
        component={Input}
        label="Street"
        name="street"
        id="street"
        tooltipText={STREET_TOOLTIP_TEXT}
        type="text"
        disabled={sameMailingAddress}
      />
      <Field
        component={Input}
        label="Unit/Apt. Number"
        name="apartmentNumber"
        id="apartmentNumber"
        tooltipText={UNIT_NUMBER_TOOLTIP_TEXT}
        type="text"
      />
      <Row>
        <Col sm="6">
          <Field
            component={Input}
            label="City"
            name="city"
            id="city"
            tooltipText={CITY_TOOLTIP_TEXT}
            type="text"
            disabled={sameMailingAddress}
          />
        </Col>
        <Col sm="6">
          <Field
            component={Select}
            label="State"
            name="state"
            id="state"
            tooltipText={STATE_TOOLTIP_TEXT}
            type="select"
            selectValues={usStates}
            selectFirstName="Select State"
            disabled={sameMailingAddress}
          />
        </Col>
      </Row>
      <Field
        component={Input}
        label="ZIP Code"
        name="zipcode"
        id="zipcode"
        tooltipText={ZIPCODE_TOOLTIP_TEXT}
        type="text"
        maxLength={10}
        disabled={sameMailingAddress}
      />

      <Button type="submit" title={isReview ? 'Review' : 'Next'} />
    </Form>
  );

  const MailingAddressFormFormik = withFormik({
    mapPropsToValues: () => ({
      street: mailingAddress?.street || '',
      apartmentNumber: mailingAddress?.apartmentNumber || '',
      city: mailingAddress?.city || '',
      state: mailingAddress?.state || '',
      zipcode: mailingAddress?.zipcode || '',
    }),

    validationSchema: Yup.object({
      street: Yup.string()
        .matches(/.*([a-zA-Z].*[0-9]|[0-9].*[a-zA-Z]).*/, {
          message: 'The street field must contain the street name and number',
          excludeEmptyString: true,
        })
        .required('Please enter a street.'),
      city: Yup.string().required('Please enter a city.'),
      zipcode: Yup.string()
        .matches(/^\d{5}?$/, {
          message: 'Invalid zip code.',
          excludeEmptyString: true,
        })
        .required('Please enter a zip code.'),
      state: Yup.string().required('Please select a state.'),
    }),

    handleSubmit: async values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Continue',
        did,
        dealerUserId,
      });
      changeValues(values);
      dispatchSubmitMindfire({
        email: email,
        lastPage: '11015',
        dealerUserId: dealerUserId,
        did: did,
      });

      dispatchChangePage(
        isReview ? EnerBankFlowPageNumber.ApplicationReview : EnerBankFlowPageNumber.HasCoApplicant,
      );
    },
  })(MailingAddressForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={EnerBankFlowPageNumber.AppEmploymentDetails}
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <MailingAddressFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  sameMailingAddress: state.enerBankInformation.sameMailingAddress,
  address: state.preQualificationInformation,
  mailingAddress: state.enerBankInformation.mailingAddress,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  isReview: state.enerBankInformation.isReview,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeInfoValue: (key, value) => dispatch(changeEnerBankInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MailingAddress);
