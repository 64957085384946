import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import usStates from '../../../../assets/js/USStateEnum';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import Input from '../../../../components/UI/Form/Input/CustomInput';
import Select from '../../../../components/UI/Form/Select/Select';
import {
  COAPPCITY_TOOLTIP_TEXT,
  COAPPSTATE_TOOLTIP_TEXT,
  COAPPSTREET_TOOLTIP_TEXT,
  COAPPUNITNUMBER_TOOLTIP_TEXT,
  COAPPZIPCODE_TOOLTIP_TEXT,
} from '../../../../constants/tooltipTexts';
import { changeApplicationValues, reactGAEvent, reactGAPageview } from '../../../../helper';
import {
  changePage,
  submitMindfire,
  changeAtWaterApplicationInfoValue,
} from '../../../../redux/actions';
import { AtWaterFlowPageNumber } from './AtWaterFlow';

const CoApplicantAddress = props => {
  const {
    atWaterApplicationInformation,
    dispatchChangeAtWaterApplicationInfoValue,
    pqi,
    dispatchChangePage,
    children,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
  } = props;

  const setIsSameAddress = bool => {
    dispatchChangeAtWaterApplicationInfoValue('isSameAddress', bool);
  };

  const CoApplicantAddressForm = () => (
    <Form>
      <Label>
        <b>Is your address the same as the Property Address?</b>
      </Label>
      <Row>
        <Col sm="6">
          <Button
            className={atWaterApplicationInformation.isSameAddress ? '' : 'inactive'}
            type="button"
            title="Yes"
            clickHandler={() => setIsSameAddress(true)}
          />
        </Col>
        <Col sm="6">
          <Button
            className={!atWaterApplicationInformation.isSameAddress ? '' : 'inactive'}
            type="button"
            title="No"
            clickHandler={() => setIsSameAddress(false)}
          />
        </Col>
      </Row>
      <Field
        disabled={atWaterApplicationInformation.isSameAddress}
        component={Input}
        label="Street"
        name="coAppStreet"
        id="coAppStreet"
        tooltipText={COAPPSTREET_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={Input}
        label="Unit/Apt. Number"
        name="coAppApartmentNumber"
        id="coAppApartmentNumber"
        tooltipText={COAPPUNITNUMBER_TOOLTIP_TEXT}
        type="text"
      />
      <Row>
        <Col sm="6">
          <Field
            disabled={atWaterApplicationInformation.isSameAddress}
            component={Input}
            label="City"
            name="coAppCity"
            id="coAppCity"
            tooltipText={COAPPCITY_TOOLTIP_TEXT}
            type="text"
          />
        </Col>
        <Col sm="6">
          <Field
            disabled={atWaterApplicationInformation.isSameAddress}
            component={Select}
            label="State"
            name="coAppState"
            id="coAppState"
            tooltipText={COAPPSTATE_TOOLTIP_TEXT}
            type="select"
            selectValues={usStates}
            selectFirstName="Select State"
          />
        </Col>
      </Row>
      <Field
        disabled={atWaterApplicationInformation.isSameAddress}
        component={Input}
        label="ZIP Code"
        name="coAppZipCode"
        id="coAppZipCode"
        tooltipText={COAPPZIPCODE_TOOLTIP_TEXT}
        type="text"
        maxLength={10}
      />

      <Button type="submit" title="Next" />
    </Form>
  );

  const CoApplicantAddressFormFormik = withFormik({
    mapPropsToValues: () => ({
      coAppApartmentNumber:
        atWaterApplicationInformation.isSameAddress &&
        atWaterApplicationInformation.primaryAddressApartmentNumber
          ? atWaterApplicationInformation.primaryAddressApartmentNumber
          : atWaterApplicationInformation.coAppApartmentNumber || '',
      coAppStreet:
        atWaterApplicationInformation.isSameAddress &&
        atWaterApplicationInformation.primaryAddressStreet
          ? atWaterApplicationInformation.primaryAddressStreet
          : atWaterApplicationInformation.coAppStreet || '',
      coAppCity:
        atWaterApplicationInformation.isSameAddress &&
        atWaterApplicationInformation.primaryAddressCity
          ? atWaterApplicationInformation.primaryAddressCity
          : atWaterApplicationInformation.coAppCity || '',
      coAppZipCode:
        atWaterApplicationInformation.isSameAddress &&
        atWaterApplicationInformation.primaryAddressZipCode
          ? atWaterApplicationInformation.primaryAddressZipCode
          : atWaterApplicationInformation.coAppZipCode || '',
      coAppState:
        atWaterApplicationInformation.isSameAddress &&
        atWaterApplicationInformation.primaryAddressState
          ? atWaterApplicationInformation.primaryAddressState
          : atWaterApplicationInformation.coAppState || '',
    }),

    validationSchema: Yup.object({
      coAppApartmentNumber: Yup.string(),
      coAppStreet: Yup.string().required('Please enter a street.'),
      coAppCity: Yup.string().required('Please enter a city.'),
      coAppZipCode: Yup.string()
        .matches(/^\d{5}?$/, {
          message: 'Invalid zip code.',
          excludeEmptyString: true,
        })
        .required('Please enter a zip code.'),
      coAppState: Yup.string().required('Please select a state.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      changeApplicationValues(dispatchChangeAtWaterApplicationInfoValue, values);
      dispatchChangePage(AtWaterFlowPageNumber.CoApplicantEmploymentInformation);
      dispatchSubmitMindfire({
        email: pqi.email,
        lastPage: '9018',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(CoApplicantAddressForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={AtWaterFlowPageNumber.CoApplicantIdentificationInformation}
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <CoApplicantAddressFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  pqi: state.preQualificationInformation,
  atWaterApplicationInformation: state.atWaterApplicationInformation,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeAtWaterApplicationInfoValue: (key, value) =>
    dispatch(changeAtWaterApplicationInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

CoApplicantAddress.propTypes = {
  dispatchChangePage: PropTypes.func,
  atWaterApplicationInformation: PropTypes.object,
  dispatchChangeAtWaterApplicationInfoValue: PropTypes.func,
  pqi: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CoApplicantAddress);
