import * as actionTypes from './actionTypes';

export const setFlow = flowName => ({
  type: actionTypes.SET_FLOW,
  flowName,
});

export const changeNaviValue = (key, value) => ({
  type: actionTypes.CHANGE_NAVI_VALUE,
  key,
  value,
});

export const changePage = page => ({
  type: actionTypes.CHANGE_PAGE,
  page,
});

export const changePageTitle = value => ({
  type: actionTypes.CHANGE_PAGE_TITLE,
  value,
});
