import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import { withRouter } from 'react-router-dom';
import idTypes from '../../assets/js/IdTypeEnum';
import usStates from '../../assets/js/USStateEnum';
import BackBtn from '../../components/UI/BackButton/BackButton';
import Button from '../../components/UI/Button/CustomButton';
import CustomDatePicker from '../../components/UI/Form/DatePicker/DatePicker';
import CustomInput from '../../components/UI/Form/Input/CustomInput';
import SsnFormat from '../../components/UI/Form/NumberFormat/SsnFormat';
import Select from '../../components/UI/Form/Select/Select';
import SelectWithHandleChange from '../../components/UI/Form/Select/SelectWithHandleChange';
import {
  reactGAEvent,
  reactGAPageview,
  validDateFormat,
  isSSNanITIN,
  isStateAllowed,
} from '../../helper';
import {
  changeAquaInfoValue,
  changePage,
  submitMindfire,
  setFlow,
} from '../../redux/actions/index';
import { microfFlow, preQualificationFlow } from '../../redux/initialState';
import { AquaFlowPageNumber } from './AquaFlow';
import { AquaPreQualificationFlowPageNumber } from '../PreQualification/preQualificationFlow';
import { MicrofFlowPageNumber } from '../Microf/MicrofFlow';
import AirConditioningSystemEnum from '../../assets/js/AirConditioningSystemEnum';
import {
  APPFULLSSN_TOOLTIP_TEXT,
  APP_DRIVER_LICENSE_STATE_TOOLTIP_TEXT,
  ID_EXPIRATION_DATE_TOOLTIP_TEXT,
  ID_ISSUE_DATE_TOOLTIP_TEXT,
  ID_NUMBER_TOOLTIP_TEXT,
  ID_TYPE_TOOLTIP_TEXT,
} from '../../constants/tooltipTexts';

const AppIdentificationInfo = props => {
  const {
    email,
    dispatchChangePage,
    dispatchChangeValue,
    aquaInformation,
    children,
    ssnLastDigits,
    isReview,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    dispatchSetFlow,
    history,
    pqi,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_aqconsumerid',
      trackerNames: [],
      title: 'ca_aqconsumerid',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line no-extend-native
  Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  const handleIdTypeChange = (e, values) => {
    dispatchChangeValue('appIdType', e.target.value);
    dispatchChangeValue('appDriversLicenseState', values.appDriversLicenseState);
    dispatchChangeValue('appIdNumber', values.appIdNumber);
    dispatchChangeValue('appIdIssueDate', values.appIdIssueDate);
    dispatchChangeValue('appIdExpirationDate', values.appIdExpirationDate);
    dispatchChangeValue('appFullSsn', values.appFullSsn);
  };

  const AppIdentificationInfoForm = ({ values }) => (
    <Form noValidate autoComplete="off">
      <Field
        component={SelectWithHandleChange}
        label="ID Type"
        name="appIdType"
        id="appIdType"
        tooltipText={ID_TYPE_TOOLTIP_TEXT}
        type="select"
        selectValues={idTypes}
        selectFirstName="Select One"
        handleChange={e =>
          handleIdTypeChange(e, {
            appIdType: values.appIdType,
            appDriversLicenseState: values.appDriversLicenseState,
            appIdNumber: values.appIdNumber,
            appIdIssueDate: values.appIdIssueDate,
            appIdExpirationDate: values.appIdExpirationDate,
            appFullSsn: values.appFullSsn,
          })
        }
      />
      {aquaInformation.appIdType === 'D' ? (
        <Field
          component={Select}
          label="Issue State"
          name="appDriversLicenseState"
          id="appDriversLicenseState"
          tooltipText={APP_DRIVER_LICENSE_STATE_TOOLTIP_TEXT}
          type="select"
          selectValues={usStates}
          selectFirstName="Select One"
        />
      ) : null}
      <Field
        maxLength={30}
        component={CustomInput}
        label="ID Number"
        name="appIdNumber"
        id="appIdNumber"
        tooltipText={ID_NUMBER_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={CustomDatePicker}
        name="appIdIssueDate"
        id="appIdIssueDate"
        tooltipText={ID_ISSUE_DATE_TOOLTIP_TEXT}
        label="Issue Date"
      />
      <Field
        component={CustomDatePicker}
        name="appIdExpirationDate"
        id="appIdExpirationDate"
        tooltipText={ID_EXPIRATION_DATE_TOOLTIP_TEXT}
        label="Expiration Date"
      />
      <Field
        component={SsnFormat}
        label="Your full Social Security Number"
        name="appFullSsn"
        id="appFullSsn"
        tooltipText={APPFULLSSN_TOOLTIP_TEXT}
        type="password"
        icon="lock"
      />
      <p className="info">Click on the padlock to view the hidden text</p>
      <Button type="submit" title={aquaInformation.isReview ? 'Review' : 'Next'} />
    </Form>
  );

  const AppIdentificationInfoFormFormik = withFormik({
    mapPropsToValues: () => ({
      appIdType: aquaInformation.appIdType || '',
      appDriversLicenseState: aquaInformation.appDriversLicenseState || '',
      appIdNumber: aquaInformation.appIdNumber || '',
      appIdIssueDate: aquaInformation.appIdIssueDate || '',
      appIdExpirationDate: aquaInformation.appIdExpirationDate || '',
      appFullSsn: aquaInformation.appFullSsn || '',
    }),

    validationSchema: Yup.object({
      appIdType: Yup.string().required('Please select your ID type.'),
      appDriversLicenseState:
        aquaInformation.appIdType === 'D'
          ? Yup.string().required('Please select your issue state.')
          : Yup.string(),
      appIdNumber: Yup.string().required('Please enter your ID number.'),
      appIdIssueDate: Yup.string()
        .required('Please enter the issue date.')
        .test(
          'incomplete',
          'Incomplete date',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 8,
        )
        .test('valid', 'Invalid date format.', val => val && !validDateFormat(val))
        .test('maxDate', 'Invalid date.', val => val && new Date(val) < new Date()),
      appIdExpirationDate: Yup.string()
        .required('Please enter the expiration date.')
        .test(
          'incomplete',
          'Incomplete date',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 8,
        )
        .test('valid', 'Invalid date format.', val => val && !validDateFormat(val))
        .test(
          'minDate',
          'Invalid date.',
          val => val && new Date(val) >= new Date().setHours(0, 0, 0, 0),
        ),
      appFullSsn: Yup.string()
        .required('Please enter your full SSN.')
        .test(
          'len',
          'Must have 9 digits',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 9,
        )
        .test(
          'match',
          'Last 4 digits must match.',
          val =>
            ssnLastDigits === '' ||
            (val &&
              val
                .toString()
                .match(/\d/g)
                .join('')
                .substr(
                  val
                    .toString()
                    .match(/\d/g)
                    .join('').length - 4,
                ) === ssnLastDigits),
        )
        .typeError('Numbers only.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      let ssn = values.appFullSsn.match(/\d/g).join('');
      dispatchChangeValue('appIdType', values.appIdType);
      dispatchChangeValue('appDriversLicenseState', values.appDriversLicenseState);
      dispatchChangeValue('appIdNumber', values.appIdNumber);
      dispatchChangeValue('appIdIssueDate', values.appIdIssueDate);
      dispatchChangeValue('appIdExpirationDate', values.appIdExpirationDate);
      dispatchChangeValue('appFullSsn', ssn);

      if (isSSNanITIN(ssn)) {
        if (
          !isStateAllowed(pqi.state) ||
          pqi.airConditioningSystem !== AirConditioningSystemEnum.AIR_CONDITION_OR_HEATING_SYSTEM.id
        ) {
          dispatchSetFlow(preQualificationFlow);
          dispatchChangePage(AquaPreQualificationFlowPageNumber.NotAllowed);
          history.push('/');
        } else {
          dispatchSetFlow(microfFlow);
          dispatchChangePage(MicrofFlowPageNumber.Landing);
          history.push('/RTO');
        }
      } else {
        dispatchChangePage(
          aquaInformation.isReview
            ? AquaFlowPageNumber.ApplicationReview
            : AquaFlowPageNumber.AppEmploymentDetails,
        );
      }
      dispatchSubmitMindfire({
        email: email,
        lastPage: '0038',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(AppIdentificationInfoForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={isReview ? AquaFlowPageNumber.ApplicationReview : AquaFlowPageNumber.HasCoApplicant}
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <AppIdentificationInfoFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  aquaInformation: state.aquaInformation,
  pqi: state.preQualificationInformation,
  ssnLastDigits: state.preQualificationInformation.ssnLastDigits || '',
  email: state.preQualificationInformation.email,
  isReview: state.aquaInformation.isReview,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetFlow: flowName => dispatch(setFlow(flowName)),
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) => dispatch(changeAquaInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

AppIdentificationInfo.propTypes = {
  aquaInformation: PropTypes.object,
  ssnLastDigits: PropTypes.string,
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AppIdentificationInfo),
);
