import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import usStates from '../../../../assets/js/USStateEnum';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import CustomInput from '../../../../components/UI/Form/Input/CustomInput';
import SsnFormat from '../../../../components/UI/Form/NumberFormat/SsnFormat';
import Select from '../../../../components/UI/Form/Select/Select';
import {
  isSSNanITIN,
  isStateAllowed,
  reactGAEvent,
  reactGAPageview,
  validDateFormat,
} from '../../../../helper';
import {
  changePage,
  setFlow,
  submitMindfire,
  changeFoundationInfoValue,
} from '../../../../redux/actions/index';
import { microfFlow, preQualificationFlow } from '../../../../redux/initialState';
import CustomDatePicker from '../../../../components/UI/Form/DatePicker/DatePicker';
import { AgeFromDateString } from 'age-calculator';
import { FoundationFlowPageNumber } from './FoundationFlow';
import { MicrofFlowPageNumber } from '../../../Microf/MicrofFlow';
import { FoundationPreQualificationFlowPageNumber } from '../PreQualification/PreQualificationFlow';
import AirConditioningSystemEnum from '../../../../assets/js/AirConditioningSystemEnum';
import {
  COAPPDOB_TOOLTIP_TEXT,
  COAPPFULLSSN_TOOLTIP_TEXT,
  COAPP_DRIVER_LICENSE_NUMBER_TOOLTIP_TEXT,
  COAPP_DRIVER_LICENSE_STATE_TOOLTIP_TEXT,
} from '../../../../constants/tooltipTexts';

const CoAppIdentificationInfo = props => {
  const {
    email,
    dispatchChangePage,
    dispatchChangeFoundationInfoValue,
    foundationInformation,
    children,
    isReview,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    dispatchSetFlow,
    history,
    pqi,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_fdcoconsumerid',
      trackerNames: [],
      title: 'ca_fdcoconsumerid',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const CoAppIdentificationInfoForm = () => (
    <Form noValidate autoComplete="off">
      <Field
        maxLength={30}
        component={CustomInput}
        label="Co-Applicant's Driver License Number"
        name="coAppDriversLicenseNumber"
        id="coAppDriversLicenseNumber"
        tooltipText={COAPP_DRIVER_LICENSE_NUMBER_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={Select}
        label="Co-Applicant's Driver License State"
        name="coAppDriversLicenseState"
        id="coAppDriversLicenseState"
        tooltipText={COAPP_DRIVER_LICENSE_STATE_TOOLTIP_TEXT}
        type="select"
        selectValues={usStates}
        selectFirstName="Select One"
      />
      <Field
        component={CustomDatePicker}
        name="coAppBirthday"
        id="coAppBirthday"
        tooltipText={COAPPDOB_TOOLTIP_TEXT}
        label="Co-Applicant's Date of Birth"
      />
      <Field
        component={SsnFormat}
        label="Co-Applicant's full Social Security Number"
        name="coAppFullSsn"
        id="coAppFullSsn"
        tooltipText={COAPPFULLSSN_TOOLTIP_TEXT}
        type="password"
        icon="lock"
      />
      <p className="info">Click on the padlock to view the hidden text</p>
      <Button type="submit" title="Next" />
    </Form>
  );

  const CoAppIdentificationInfoFormFormik = withFormik({
    mapPropsToValues: () => ({
      coAppDriversLicenseState: foundationInformation.coAppDriversLicenseState || '',
      coAppDriversLicenseNumber: foundationInformation.coAppDriversLicenseNumber || '',
      coAppMilitaryActive: foundationInformation.isCoAppMilitaryActive || '',
      coAppUsCitizen: foundationInformation.isCoAppUsCitizen || '',
      coAppBirthday: foundationInformation.coAppBirthday || '',
      coAppFullSsn: foundationInformation.coAppFullSsn || '',
    }),

    validationSchema: Yup.object({
      coAppDriversLicenseState: Yup.string(),
      coAppDriversLicenseNumber: Yup.string(),
      coAppBirthday: Yup.string()
        .required("Please enter the Co-Applicant's date of birth.")
        .test(
          'incomplete',
          'Incomplete date',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 8,
        )
        .test('valid', 'Invalid date format.', val => val && !validDateFormat(val))
        .test(
          'age',
          'The Co-Applicant must be at least 18 years old.',
          val => val && new AgeFromDateString(val).age >= 18,
        ),
      coAppFullSsn: Yup.string()
        .required("Please enter Co-Applicant's full SSN.")
        .test(
          'len',
          'Must have 9 digits',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 9,
        )
        .test(
          'unique',
          "Same SSN as the applicant's is not allowed",
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('') !== foundationInformation.appFullSsn,
        )
        .typeError('Numbers only.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      let ssn = values.coAppFullSsn.match(/\d/g).join('');
      dispatchChangeFoundationInfoValue(
        'coAppDriversLicenseState',
        values.coAppDriversLicenseState,
      );
      dispatchChangeFoundationInfoValue(
        'coAppDriversLicenseNumber',
        values.coAppDriversLicenseNumber,
      );
      dispatchChangeFoundationInfoValue('coAppBirthday', values.coAppBirthday);
      dispatchChangeFoundationInfoValue('coAppFullSsn', ssn);

      if (isSSNanITIN(ssn)) {
        if (
          !isStateAllowed(pqi.state) ||
          pqi.airConditioningSystem !== AirConditioningSystemEnum.AIR_CONDITION_OR_HEATING_SYSTEM.id
        ) {
          dispatchSetFlow(preQualificationFlow);
          dispatchChangePage(FoundationPreQualificationFlowPageNumber.NotAllowed);
          history.push('/');
        } else {
          dispatchSetFlow(microfFlow);
          dispatchChangePage(MicrofFlowPageNumber.Landing);
          history.push('/RTO');
        }
      } else {
        dispatchChangePage(FoundationFlowPageNumber.CoAppEmploymentDetails);
      }
      dispatchSubmitMindfire({
        email: email,
        lastPage: '10018',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(CoAppIdentificationInfoForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={
            isReview
              ? FoundationFlowPageNumber.ApplicationReview
              : FoundationFlowPageNumber.CoAppAddress
          }
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <CoAppIdentificationInfoFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  foundationInformation: state.foundationInformation,
  pqi: state.preQualificationInformation,
  ssnLastDigits: state.preQualificationInformation.ssnLastDigits || '',
  email: state.preQualificationInformation.email,
  isReview: state.foundationInformation.isReview,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetFlow: flowName => dispatch(setFlow(flowName)),
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeFoundationInfoValue: (key, value) =>
    dispatch(changeFoundationInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

CoAppIdentificationInfo.propTypes = {
  foundationInformation: PropTypes.object,
  ssnLastDigits: PropTypes.string,
  dispatchChangePage: PropTypes.func,
  dispatchChangeFoundationInfoValue: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CoAppIdentificationInfo),
);
