import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import YesNoComponent from '../../components/UI/YesNoComponent/YesNoComponent';
import { changeAquaInfoValue, changePage, submitMindfire } from '../../redux/actions/index';
import PropTypes from 'prop-types';
import { reactGAPageview, reactGAEvent } from '../../helper';
import { AquaFlowPageNumber } from './AquaFlow';

const HasCoApplicant = props => {
  const {
    email,
    dispatchChangePage,
    dispatchChangeAquaInfoValue,
    aquaInformation,
    isReview,
    dispatchSubmitMindfire,
    children,
    did,
    dealerUserId,
    coApplicantAdded,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_aqcoappoption',
      trackerNames: [],
      title: 'ca_aqcoappoption',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const mindFireRequest = { email: email, lastPage: '0036', dealerUserId: dealerUserId, did: did };

  return (
    <YesNoComponent
      backpage={isReview ? AquaFlowPageNumber.ApplicationReview : AquaFlowPageNumber.HvacDetails}
      upperButtonText="Yes"
      lowerButtonText="No"
      upperBtnClickHandler={() => {
        reactGAEvent({
          category: 'Button',
          action: 'Click',
          label: 'Yes',
          did,
          dealerUserId,
        });
        dispatchChangePage(
          aquaInformation.isReview
            ? AquaFlowPageNumber.CoAppDetails
            : AquaFlowPageNumber.AppIdentificationInfo,
        );
        dispatchChangeAquaInfoValue('hasCoApp', true);
        dispatchSubmitMindfire(mindFireRequest);
      }}
      lowerBtnClickHandler={() => {
        reactGAEvent({
          category: 'Button',
          action: 'Click',
          label: 'No',
          did,
          dealerUserId,
        });
        dispatchChangePage(
          aquaInformation.isReview
            ? AquaFlowPageNumber.ApplicationReview
            : AquaFlowPageNumber.AppIdentificationInfo,
        );
        dispatchChangeAquaInfoValue('hasCoApp', false);
        dispatchSubmitMindfire(mindFireRequest);
      }}
      lowerButtonDisabled={coApplicantAdded ? true : false}
    >
      {children}
    </YesNoComponent>
  );
};

const mapStateToProps = state => ({
  aquaInformation: state.aquaInformation,
  email: state.preQualificationInformation.email,
  isReview: state.aquaInformation.isReview,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  // we only have applicationSubmitDTO when revisiting an app
  coApplicantAdded:
    state.softPull.applicationSubmitDTO !== null
      ? state.softPull.applicationSubmitDTO.applicantNonPrimary
      : null,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeAquaInfoValue: (key, value) => dispatch(changeAquaInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

HasCoApplicant.propTypes = {
  dispatchChangePage: PropTypes.func,
  dispatchChangeAquaInfoValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HasCoApplicant);
