import React, { useState } from 'react';
import { GoInfo } from 'react-icons/go';
import ReactPhoneInput from 'react-phone-input-2';
import { Col, Row, Tooltip } from 'reactstrap';
import InputFeedback from '../InputFeedback/InputFeedback';

const PhoneNumberField = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched, setFieldValue },
  id,
  labelClassName,
  label,
  className,
  disabled,
  tooltipText,
  ...props
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <Row className="align-items-center">
        <Col>
          <ReactPhoneInput
            disableDropdown={true}
            disableCountryCode={true}
            inputClass={className}
            id={id}
            value={value}
            disabled={disabled}
            onChange={e => {
              if (e && e !== '+') {
                setFieldValue(name, e);
              } else {
                setFieldValue(name, '');
              }
            }}
            onBlur={onBlur}
            inputExtraProps={{
              name: name,
            }}
            onlyCountries={['us']}
            defaultCountry="us"
            placeholder="(123) 456-7890"
          />
        </Col>
        <Col xs="auto" className="pl-0">
          <div id={`tooltip-${id}`} className="cursor-pointer">
            <GoInfo size={20} />
          </div>
        </Col>
      </Row>
      {touched[name] && <InputFeedback error={errors[name]} />}
      <Tooltip
        placement="right"
        target={`tooltip-${id}`}
        isOpen={tooltipOpen}
        toggle={toggleTooltip}
        autohide={false}
      >
        {tooltipText ? tooltipText : `Please insert '${label}'`}
      </Tooltip>
    </>
  );
};

export default PhoneNumberField;
