import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Section from '../../../components/Section/Section';
import Header from '../../../components/UI/Header/Header';
import PreQualificationFlow from './PreQualificationFlow';
import {
  wellsFargoFlow,
  powerPayFlow,
  preQualificationFlow,
  aquaFlow,
  microfFlow,
} from '../../../redux/initialState';

const PreQualification = props => {
  const { state, flowName, history } = props;
  const page = state.navi[`${state.navi.flowName}CurrentPage`];

  useEffect(() => {
    if (flowName === microfFlow) {
      history.push('/RTO');
    } else if (flowName === aquaFlow) {
      history.push('/Aqua');
    } else if (flowName === preQualificationFlow) {
      history.push('/');
    } else if (flowName === powerPayFlow) {
      history.push('/powerpay');
    } else if (flowName === wellsFargoFlow) {
      history.push('/WellsFargo');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header
        title={PreQualificationFlow[page].props.title}
        pages={PreQualificationFlow.length - 1}
      />
      <Section Component={PreQualificationFlow[page]} {...state} />
    </>
  );
};

const mapStateToProps = state => ({
  state,
  flowName: state.navi.flowName,
});

export default withRouter(
  connect(
    mapStateToProps,
    null,
  )(PreQualification),
);
