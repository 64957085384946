import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import AirConditioningSystemEnum from '../../assets/js/AirConditioningSystemEnum';
import BackBtn from '../../components/UI/BackButton/BackButton';
import Button from '../../components/UI/Button/CustomButton';
import { reactGAEvent, reactGAPageview } from '../../helper';
import { changePage, submitMindfire, changeWellsFargoValue } from '../../redux/actions';
import { WellsFargoFlowPageNumber } from './WellsFargoFlow';

const LoanFor = props => {
  const {
    dispatchChangePage,
    dealerUserId,
    did,
    children,
    app,
    dispatchChangeWellsFargoValue,
    email,
    dispatchSubmitMindfire,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/wf_option',
      trackerNames: [],
      title: 'wf_option',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const mindfireRequest = {
    email: email,
    lastPage: '5004',
    dealerUserId: dealerUserId,
    did: did,
  };

  return (
    <Container>
      <Row>
        {app == null ? (
          <BackBtn
            changePage={dispatchChangePage}
            page={
              !!dealerUserId && did === null
                ? WellsFargoFlowPageNumber.AccountEmail
                : WellsFargoFlowPageNumber.AccountPassword
            }
          />
        ) : (
          <Col sm="1" />
        )}
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <Button
            className="btn btn-primary"
            title="HVAC and/or Water Heater"
            clickHandler={() => {
              reactGAEvent({
                category: 'Button',
                action: 'Click',
                label: 'HVAC and/or Water Heater',
                did,
                dealerUserId,
              });
              dispatchChangeWellsFargoValue(
                'airConditioningSystem',
                AirConditioningSystemEnum.AIR_CONDITION_OR_HEATING_SYSTEM.id,
              );
              dispatchChangePage(WellsFargoFlowPageNumber.LoanAmount);
              dispatchSubmitMindfire(mindfireRequest);
            }}
          />
          <Button
            className="btn btn-primary"
            pClassName="top-20"
            title="Other Home Improvement"
            clickHandler={() => {
              reactGAEvent({
                category: 'Button',
                action: 'Click',
                label: 'Other Home Improvement',
                did,
                dealerUserId,
              });
              dispatchChangeWellsFargoValue(
                'airConditioningSystem',
                AirConditioningSystemEnum.OTHER_HOME_IMPROVEMENT.id,
              );
              dispatchChangePage(WellsFargoFlowPageNumber.LoanAmount);
              dispatchSubmitMindfire(mindfireRequest);
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  app: state.softPull.applicationSubmitDTO,
  email: state.wellsFargoInformation.email,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeWellsFargoValue: (key, value) => dispatch(changeWellsFargoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

LoanFor.propTypes = {
  dealerUserId: PropTypes.number,
  did: PropTypes.string,
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoanFor);
