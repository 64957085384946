import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row, FormGroup } from 'reactstrap';
import * as Yup from 'yup';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import Select from '../../../components/UI/Form/Select/SelectField';
import { changeEnerBankInfoValue, changePage, submitMindfire } from '../../../redux/actions/index';
import { reactGAPageview, reactGAEvent } from '../../../helper';
import { EnerBankFlowPageNumber } from './EnerBankFlow';
import coApplicantEnum from '../../../assets/js/CoApplicantEnum';
import { COAPP_TYPE_TOOLTIP_TEXT } from '../../../constants/tooltipTexts';

const HasCoApplicant = props => {
  const {
    dispatchChangePage,
    dispatchChangeValue,
    coApplicantType,
    children,
    hasCoApp,
    email,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    isReview,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_ebcoappoption',
      trackerNames: [],
      title: 'ca_ebcoappoption',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const HasCoApplicantForm = () => (
    <Form>
      <Row>
        <Col sm="6">
          <Button
            className={hasCoApp ? '' : 'inactive'}
            type="button"
            title="Yes"
            clickHandler={() => dispatchChangeValue('hasCoApp', true)}
          />
        </Col>
        <Col sm="6">
          <Button
            className={!hasCoApp ? '' : 'inactive'}
            type="button"
            title="No"
            clickHandler={() => dispatchChangeValue('hasCoApp', false)}
          />
        </Col>
      </Row>
      {hasCoApp ? (
        <Row>
          <Col>
            <FormGroup>
              <Field
                component={Select}
                label="CoApplicant Type"
                name="coApplicantType"
                id="coApplicantType"
                tooltipText={COAPP_TYPE_TOOLTIP_TEXT}
                type="select"
                selectValues={coApplicantEnum}
                selectFirstName="Select One"
              />
            </FormGroup>
          </Col>
        </Row>
      ) : null}
      <Button type="submit" title="Next" />
    </Form>
  );

  const HasCoApplicantFormFormik = withFormik({
    mapPropsToValues: () => ({
      coApplicantType: coApplicantType || '',
    }),

    validationSchema: Yup.object({
      coApplicantType: hasCoApp ? Yup.string().required('Please select one.') : Yup.string(),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      dispatchChangeValue('coApplicantType', hasCoApp ? values.coApplicantType : null);
      dispatchChangePage(
        hasCoApp ? EnerBankFlowPageNumber.CoAppInfo : EnerBankFlowPageNumber.ApplicationReview,
      );
      dispatchSubmitMindfire({
        email: email,
        lastPage: '11016',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(HasCoApplicantForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={
            isReview
              ? EnerBankFlowPageNumber.ApplicationReview
              : EnerBankFlowPageNumber.MailingAddress
          }
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <HasCoApplicantFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  hasCoApp: state.enerBankInformation.hasCoApp,
  coApplicantType: state.enerBankInformation.coApplicantType,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  isReview: state.enerBankInformation.isReview,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) => dispatch(changeEnerBankInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HasCoApplicant);
