import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
import { initialState } from '../initialState';

const changeValue = (state, action) => {
  const updatedValues = {
    [action.key]: action.value,
  };
  return updateObject(state, updatedValues);
};

const contractorEnrollmentReducer = (state, action) => {
  if (!state) {
    state = initialState.contractorEnrollment;
  }
  switch (action.type) {
    case actionTypes.CHANGE_CONTRACTOR_ENROLLMENT_VALUE:
      return changeValue(state, action);
    case actionTypes.RESET_STATE_CONTRACTOR_ENROLLMENT:
      state = initialState.contractorEnrollment;
      return state;
    default:
      return state;
  }
};

export default contractorEnrollmentReducer;
