import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { useVSPixelScript } from '../../assets/hooks';
import Btn from '../../components/UI/Button/CustomButton';
import {
  changeMicrofInfoValue,
  changePage,
  changePreQualInfoValue,
  resetState,
  setFlow,
} from '../../redux/actions/index';
import { defaultFlow } from '../../redux/initialState';
import { AquaPreQualificationFlowPageNumber } from './preQualificationFlow';

const UserType = props => {
  const {
    children,
    dispatchChangeMicrofInfoValue,
    dispatchSetFlow,
    history,
    dispatchChangePreQualInfoValue,
    user,
    dispatchResetState,
    dispatchCangePage,
  } = props;

  useVSPixelScript();

  return (
    <Container>
      <Row>
        <Col sm="1" />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <Btn
            className="btn btn-primary"
            title="I’m a Homeowner"
            clickHandler={() => {
              dispatchResetState({ history: null });
              dispatchChangeMicrofInfoValue('microfOnly', true);
              dispatchSetFlow(defaultFlow);
              dispatchCangePage(AquaPreQualificationFlowPageNumber.AccountEmail);
              history.push('/');
              dispatchChangePreQualInfoValue('isConsumer', true);
            }}
          />
          <Btn
            className="btn btn-primary"
            pClassName="top-20"
            title="I'm a Contractor or Returning Customer"
            clickHandler={() => {
              if (user && user.dealerUser) {
                history.push('/portal/dashboard');
              } else {
                history.push('/Login');
              }
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangeMicrofInfoValue: (field, value) => dispatch(changeMicrofInfoValue(field, value)),
  dispatchChangePreQualInfoValue: (field, value) => dispatch(changePreQualInfoValue(field, value)),
  dispatchSetFlow: flow => dispatch(setFlow(flow)),
  dispatchResetState: props => dispatch(resetState(props)),
  dispatchCangePage: page => dispatch(changePage(page)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(UserType),
);
