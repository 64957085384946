import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Field, Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import CustomInput from '../../components/UI/Form/Input/CustomInput';
import Button from '../../components/UI/Button/CustomButton';
import Select from '../../components/UI/Form/Select/Select';
import CustomDatePicker from '../../components/UI/Form/DatePicker/DatePicker';
import { changePage, submitMindfire, endLoading, applyToBeDealer } from '../../redux/actions/index';
import { withRouter } from 'react-router-dom';
import BackBtn from '../../components/UI/BackButton/BackButton';
import { ContractorEnrollmentAtWaterGSFlowPageNumber } from './_Flow';
import usStates from '../../assets/js/USStateEnum';
import { changeContractorEnrollmentAtWaterGSValue } from '../../redux/actions/contractorEnrollmentAtWaterGSActions';
import { BusinessStructureCategories } from '../../assets/js/ContractorEnrollmentAtWaterGSEnum';
import CurrencyFormat from '../../components/UI/Form/NumberFormat/CurrencyFormat';
import {
  CITY_TOOLTIP_TEXT,
  STATE_TOOLTIP_TEXT,
  STREET_TOOLTIP_TEXT,
  ZIPCODE_TOOLTIP_TEXT,
} from '../../constants/tooltipTexts';
import Checkbox from '../../components/UI/Form/Checkbox/Checkbox';
import { AgeFromDateString } from 'age-calculator';
import { validDateFormat } from '../../helper';

const BusinessInformation = props => {
  const { ce, dispatchChangeValue, dispatchChangePage, children, user } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  const setValuesToState = values => {
    dispatchChangeValue('federalTaxId', values.federalTaxId);
    dispatchChangeValue('programId', values.programId);
    dispatchChangeValue('allNames', values.allNames);
    dispatchChangeValue('inBusinessSince', values.inBusinessSince);
    dispatchChangeValue('referredBy', values.referredBy);
    dispatchChangeValue('businessStructure', values.businessStructure);
    dispatchChangeValue('typeOfService', values.typeOfService);
    dispatchChangeValue('annualConsumerSalesRevenue', values.annualConsumerSalesRevenue);
    dispatchChangeValue('annualConsumerFinanceVolume', values.annualConsumerFinanceVolume);
    dispatchChangeValue('averageTicket', values.averageTicket);

    dispatchChangeValue('street', values.street);
    dispatchChangeValue('city', values.city);
    dispatchChangeValue('zipcode', values.zipcode);
    dispatchChangeValue('state', values.state);

    if (!values.sameMailingAddr) {
      dispatchChangeValue('mailingStreet', values.street);
      dispatchChangeValue('mailingCity', values.city);
      dispatchChangeValue('mailingZipcode', values.zipcode);
      dispatchChangeValue('mailingState', values.state);
    } else {
      dispatchChangeValue('mailingStreet', values.mailingStreet);
      dispatchChangeValue('mailingCity', values.mailingCity);
      dispatchChangeValue('mailingZipcode', values.mailingZipcode);
      dispatchChangeValue('mailingState', values.mailingState);
    }
    dispatchChangeValue('sameMailingAddr', !ce.sameMailingAddr);
  };

  const BusinessInformationForm = ({ values, errors, touched }) => (
    <Form noValidate autoComplete="off">
      <Field
        maxLength={9}
        component={CustomInput}
        label="Federal Tax ID#"
        name="federalTaxId"
        id="federalTaxId"
        type="text"
        number
        disabled
      />
      <Field
        component={CustomInput}
        label="Program ID"
        name="programId"
        id="programId"
        type="text"
        maxLength="25"
      />
      <Field
        component={CustomInput}
        label="All Names You Are Doing Business As *"
        name="allNames"
        id="allNames"
        type="text"
      />
      <Field
        component={CustomDatePicker}
        name="inBusinessSince"
        id="inBusinessSince"
        label="In Business Since *"
      />
      <Field
        component={CustomInput}
        label="Referred By"
        name="referredBy"
        id="referredBy"
        type="text"
      />
      <Field
        component={Select}
        label="Business Structure *"
        name="businessStructure"
        id="businessStructure"
        type="select"
        selectValues={BusinessStructureCategories}
      />
      <Field
        component={CustomInput}
        label="Type of Service / Product Business Offers *"
        name="typeOfService"
        id="typeOfService"
        type="text"
        maxLength={45}
      />
      <Field
        component={CurrencyFormat}
        label="Annual Consumer Sales Revenue *"
        name="annualConsumerSalesRevenue"
        id="annualConsumerSalesRevenue"
      />
      <Field
        component={CurrencyFormat}
        label="Annual Consumer Finance Volume *"
        name="annualConsumerFinanceVolume"
        id="annualConsumerFinanceVolume"
      />
      <Field
        component={CurrencyFormat}
        label="Average Ticket"
        name="averageTicket"
        id="averageTicket"
      />
      <br />
      <h5>Physical Address</h5>
      <Field
        component={CustomInput}
        label="Street *"
        name="street"
        id="street"
        tooltipText={STREET_TOOLTIP_TEXT}
        type="text"
      />
      <Row>
        <Col sm="6">
          <Field
            component={CustomInput}
            label="City *"
            name="city"
            id="city"
            tooltipText={CITY_TOOLTIP_TEXT}
            type="text"
          />
        </Col>
        <Col sm="6">
          <Field
            component={Select}
            label="State *"
            name="state"
            id="state"
            tooltipText={STATE_TOOLTIP_TEXT}
            type="select"
            selectValues={usStates}
            selectFirstName="Select State"
          />
        </Col>
      </Row>
      <Field
        component={CustomInput}
        label="ZIP Code *"
        name="zipcode"
        id="zipcode"
        tooltipText={ZIPCODE_TOOLTIP_TEXT}
        type="text"
        maxLength={10}
      />
      <br />
      <Row>
        <Col sm="5">
          <h5 className="top-20">Mailing Address</h5>
        </Col>
        <Col>
          <Field
            component={Checkbox}
            name="sameMailingAddr"
            id="sameMailingAddr"
            label="Same as Physical Address"
            clickHandler={() => {
              setValuesToState(values);
            }}
          />
        </Col>
      </Row>

      <Field
        component={CustomInput}
        label="Street *"
        name="mailingStreet"
        id="mailingStreet"
        tooltipText={STREET_TOOLTIP_TEXT}
        type="text"
      />
      <Row>
        <Col sm="6">
          <Field
            component={CustomInput}
            label="City *"
            name="mailingCity"
            id="mailingCity"
            tooltipText={CITY_TOOLTIP_TEXT}
            type="text"
          />
        </Col>
        <Col sm="6">
          <Field
            component={Select}
            label="State *"
            name="mailingState"
            id="mailingState"
            tooltipText={STATE_TOOLTIP_TEXT}
            type="select"
            selectValues={usStates}
            selectFirstName="Select State"
          />
        </Col>
      </Row>
      <Field
        component={CustomInput}
        label="ZIP Code *"
        name="mailingZipcode"
        id="mailingZipcode"
        tooltipText={ZIPCODE_TOOLTIP_TEXT}
        type="text"
        maxLength={10}
      />
      <Button type="submit" title="Next" />
    </Form>
  );

  const BusinessInformationFormFormik = withFormik({
    mapPropsToValues: () => ({
      federalTaxId: ce.federalTaxId || user?.ein || '',
      programId: ce.programId || '',
      allNames: ce.allNames || '',
      inBusinessSince: ce.inBusinessSince || '',
      referredBy: ce.referredBy || '',
      businessStructure: ce.businessStructure || '',
      typeOfService: ce.typeOfService || '',
      annualConsumerSalesRevenue: ce.annualConsumerSalesRevenue || '',
      annualConsumerFinanceVolume: ce.annualConsumerFinanceVolume || '',
      averageTicket: ce.averageTicket || '',

      street: ce.street || '',
      city: ce.city || '',
      state: ce.state || '',
      zipcode: ce.zipcode || '',

      mailingStreet: ce.mailingStreet || '',
      mailingCity: ce.mailingCity || '',
      mailingState: ce.mailingState || '',
      mailingZipcode: ce.mailingZipcode || '',
      sameMailingAddr: ce.sameMailingAddr || '',
    }),

    validationSchema: Yup.object({
      federalTaxId: Yup.string()
        .min(9, 'Invalid EIN, use format 12-3456789.')
        .required('Please enter your Federal Tax ID (EIN).'),
      allNames: Yup.string().required('Please enter all names you are doing business as.'),
      inBusinessSince: Yup.string()
        .required('Please enter business starting date.')
        .test(
          'incomplete',
          'Incomplete date',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 8,
        )
        .test('valid', 'Invalid date format.', val => val && !validDateFormat(val))
        .test(
          'age',
          'Please provide a valid date.',
          val => val && new AgeFromDateString(val).age >= 0,
        ),
      businessStructure: Yup.string().required('Please select a Business Structure.'),
      typeOfService: Yup.string().required('Please enter a type of service.'),
      annualConsumerSalesRevenue: Yup.number()
        .required('Please enter the annual consumer sales revenue.')
        .moreThan(-1, 'Please enter a positive number.'),
      annualConsumerFinanceVolume: Yup.number()
        .required('Please enter the annual consumer finance volume.')
        .moreThan(-1, 'Please enter a positive number.'),

      street: Yup.string().required('Please enter a street.'),
      city: Yup.string().required('Please enter a city.'),
      zipcode: Yup.string()
        .matches(/^\d{5}?$/, {
          message: 'Invalid zip code.',
          excludeEmptyString: true,
        })
        .required('Please enter a zip code.'),
      state: Yup.string().required('Please select a state.'),

      mailingStreet: Yup.string().required('Please enter a street.'),
      mailingCity: Yup.string().required('Please enter a city.'),
      mailingZipcode: Yup.string()
        .matches(/^\d{5}?$/, {
          message: 'Invalid zip code.',
          excludeEmptyString: true,
        })
        .required('Please enter a zip code.'),
      mailingState: Yup.string().required('Please select a state.'),
    }),

    handleSubmit: values => {
      setValuesToState(values);
      dispatchChangePage(ContractorEnrollmentAtWaterGSFlowPageNumber.OwnerInformation);
    },
  })(BusinessInformationForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={ContractorEnrollmentAtWaterGSFlowPageNumber.PersonalInformation}
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <BusinessInformationFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  ce: state.contractorEnrollmentAtWaterGS,
  user: state.auth.user?.user,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) =>
    dispatch(changeContractorEnrollmentAtWaterGSValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchEndLoading: () => dispatch(endLoading()),
  dispatchApplyToBeDealer: (page, history) => dispatch(applyToBeDealer(false, page, history)),
});

BusinessInformation.propTypes = {
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
  dispatchSubmitSoftPullAquaApplication: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(BusinessInformation),
);
