import React, { useState } from 'react';
import { Container, Row, Col, Tooltip } from 'reactstrap';
import BackBtn from '../BackButton/BackButton';
import Button from '../Button/CustomButton';
import { connect } from 'react-redux';
import { changePage } from '../../../redux/actions/index';
import Disclosure from '../../../pages/Optimus/GreenSky/Components/Disclosure';

const YesNoComponent = ({
  isGreensky,
  noBackButton,
  backpage,
  children,
  upperButtonText,
  upperBtnClickHandler,
  lowerBtnHref,
  lowerButtonText,
  lowerBtnClickHandler,
  dispatchChangePage,
  lowerButtonDisabled,
  isWellsFargo,
  isConsumer,
  handleBackButtonClick,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(lowerButtonDisabled ? !tooltipOpen : false);
  return (
    <Container>
      <Row>
        {noBackButton ? (
          <Col sm="1" />
        ) : (
          <BackBtn
            changePage={dispatchChangePage}
            handleClick={handleBackButtonClick}
            page={backpage}
          />
        )}
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <Button title={upperButtonText} clickHandler={upperBtnClickHandler} />
          <span id="tooltipTarget" tabIndex="0" className="aqua-span-button-overlay">
            <Button
              href={lowerBtnHref}
              title={lowerButtonText}
              clickHandler={lowerBtnClickHandler}
              disabled={lowerButtonDisabled}
              style={lowerButtonDisabled ? { pointerEvents: 'none' } : { pointerEvents: 'all' }}
            />
          </span>
          {isWellsFargo && (
            <h5 className="mt-3">
              If this credit application is for joint credit, you acknowledge that you intend to
              apply for joint credit that you will both use.
            </h5>
          )}
          <Tooltip
            placement="bottom"
            target="tooltipTarget"
            isOpen={tooltipOpen}
            toggle={toggle}
            autohide={false}
          >
            Due to previously having a co-applicant added this selection is not available currently!
          </Tooltip>
          {isGreensky && <Disclosure />}
        </Col>
      </Row>
    </Container>
  );
};

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
});

export default connect(
  null,
  mapDispatchToProps,
)(YesNoComponent);
