import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

const ButtonField = ({ type, className, clickHandler, href, title, disabled, color }) => {
  const btn = (
    <Button
      type={type}
      color={color ? color : 'primary'}
      size="lg"
      block
      className={className}
      onClick={clickHandler}
      href={href}
      disabled={disabled}
    >
      {title}
    </Button>
  );

  return btn;
};

ButtonField.propTypes = {
  type: PropTypes.string,
  href: PropTypes.string,
};

export default ButtonField;
