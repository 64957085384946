import { Field, Form, withFormik } from 'formik';
import React from 'react';
import { Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import Button from '../../../components/UI/Button/CustomButton';
import CustomInput from '../../../components/UI/Form/Input/CustomInput';

const ContractorUserChangePassword = props => {
  const { selectedContractorUser, setChangePass, changePassword } = props;

  const ContractorUserChangePasswordForm = () => (
    <Form autoComplete="off">
      <Row>
        <Col>
          <h1>Contractor User - Change Password</h1>
        </Col>
      </Row>
      <Row className="panel">
        <Col>
          <p className="info mt-3 mb-0">All fields are mandatory</p>
          <Row className="contractor-user-view-row p-0 align-items-center border-bottom mt-3 password-row">
            <Col xs="6">
              <div>Password</div>
            </Col>
            <Col xs="6">
              <Field
                component={CustomInput}
                name="password"
                id="password"
                type="password"
                icon="eye"
              />
              <small className="form-text text-muted">
                Your password should be at least 7 characters with at least 3 letters (1 upper case,
                1 lower case) and at least 1 number.{' '}
              </small>
            </Col>
          </Row>
          <Row className="contractor-user-view-row p-0 align-items-center border-bottom password-row">
            <Col xs="6">
              <div>Confirm Password</div>
            </Col>
            <Col xs="6">
              <Field
                component={CustomInput}
                name="confirmPassword"
                id="confirmPassword"
                type="password"
                icon="eye"
              />
            </Col>
          </Row>
          <Row className="contractor-user-view-row align-items-center justify-content-end">
            <Button
              type="reset"
              className="inactive rounded-pill mb-3"
              title="Close"
              clickHandler={() => {
                setChangePass(false);
              }}
            />
            <Button type="submit" className="rounded-pill" title="Save" />
          </Row>
        </Col>
      </Row>
    </Form>
  );

  const ContractorUserChangePasswordFormFormik = withFormik({
    mapPropsToValues: () => ({
      password: '',
      confirmPassword: '',
    }),

    validationSchema: Yup.object({
      password: Yup.string()
        .min(7, 'Password has to be at least 7 characters.')
        .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,255}$/, 'Insert a valid password.')
        .test('3Letters', 'Insert a valid password.', val => {
          const alpha = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
          let charNo = 0;
          if (!!val) {
            for (var i = 0; i < val.length; i++) {
              if (alpha.indexOf(val.charAt(i)) > -1) {
                charNo++;
              }
            }
          }
          return charNo >= 3;
        })
        .required('Please enter a password.'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Password must match.')
        .required('Please enter a password.'),
    }),

    handleSubmit: async values => {
      const data = {
        id: selectedContractorUser.id,
        password: values.password,
      };
      changePassword(data, setChangePass);
    },
  })(ContractorUserChangePasswordForm);

  return (
    <Col>
      <ContractorUserChangePasswordFormFormik />
    </Col>
  );
};

export default ContractorUserChangePassword;
