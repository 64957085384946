import React, { useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { searchInternalUsers } from '../../../redux/actions';
import SearchCriteria from './SearchCriteria';
import SearchTable from './SearchTable';

const SearchContractorUsers = props => {
  const { history, internalUsers, auth, searchOptionUsers, dispatchSearchInternalUsers } = props;
  const pageLimit = 20;
  const pageCount = internalUsers && internalUsers.total / pageLimit;

  useEffect(() => {
    if (!auth.user && (!auth.user.dealerUser || !auth.user.internalUser)) {
      history.push('/login');
    }
    // eslint-disable-next-line
  }, []);

  const handlePageClick = pageNumber => {
    dispatchSearchInternalUsers({
      ...searchOptionUsers,
      pageOffset: pageNumber.selected * pageLimit,
    });
  };

  return (
    <Col>
      <Row className="mt-5">
        <h1 id="panelTitle">Manage Internal Users</h1>
        <SearchCriteria
          searchOptionUsers={searchOptionUsers}
          searchInternalUsers={dispatchSearchInternalUsers}
        />
        <div className="panel">
          <div className="panel-body larger">
            <Row>
              <Col className="right-bordered">
                {internalUsers && internalUsers.total > 0 ? (
                  <>
                    <Row className="overflow-auto">
                      <SearchTable internalUsers={internalUsers} history={history} />
                    </Row>
                    {pageCount > 1 && (
                      <Row className="justify-content-between align-items-center">
                        <ReactPaginate
                          previousLabel={'<'}
                          nextLabel={'>'}
                          breakClassName={'break-me'}
                          breakLinkClassName={'page-link'}
                          breakLabel={'...'}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageClick}
                          containerClassName={'pagination'}
                          subContainerClassName={'pages pagination'}
                          pageClassName={'page-item'}
                          pageLinkClassName={'page-link'}
                          activeClassName={'active'}
                          nextClassName={'page-item'}
                          nextLinkClassName={'page-link'}
                          previousClassName={'page-item'}
                          previousLinkClassName={'page-link'}
                        />
                      </Row>
                    )}
                  </>
                ) : (
                  <>No users found.</>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Row>
    </Col>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  internalUsers: state.softPull.internalUsers,
  searchOptionUsers: state.softPull.searchOptionUsers,
});

const mapDispatchToProps = dispatch => ({
  dispatchSearchInternalUsers: searchOptions => dispatch(searchInternalUsers(searchOptions)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SearchContractorUsers),
);
