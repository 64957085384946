import React, { useEffect } from 'react';
import DealerSection from '../../components/DealerSection/DealerSection';
import queryString from 'query-string';
import { connect } from 'react-redux';
import {
  setFlow,
  getReCapthcaKey,
  changeGSRegisterAsContractorInfoValue,
} from '../../redux/actions/index';
import PropTypes from 'prop-types';
import RegisterAsContractor from './RegisterAsContractor';
import { withRouter } from 'react-router-dom';
import { getIsPool } from '../../config/settings';
import PoolsHeader from '../../components/UI/Header/PoolsHeader';

const Index = ({
  app,
  score,
  history,
  aquaFlowCurrentPage,
  dispatchSetFlow,
  flowName,
  dispatchChangeValue,
  dispatchGetReCapthcaKey,
  ...props
}) => {
  const registrationParam = queryString.parse(window.location.search).param;
  useEffect(() => {
    dispatchChangeValue('registered', null);
    dispatchChangeValue('registeredMessage', null);
    dispatchChangeValue('recaptchaError', null);

    dispatchGetReCapthcaKey();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {getIsPool() && <PoolsHeader />}
      <section>
        <DealerSection
          Component={
            <RegisterAsContractor
              registrationParam={registrationParam}
              isGreenSky={props.isGreenSky}
            />
          }
        />
      </section>
    </>
  );
};

const mapStateToProps = state => ({
  aquaFlowCurrentPage: state.navi.aquaFlowCurrentPage,
  score: state.softPull.softPullResponse.score,
  flowName: state.navi.flowName,
  app: state.softPull.applicationSubmitDTO,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetFlow: flow => dispatch(setFlow(flow)),
  dispatchChangeValue: (key, value) => dispatch(changeGSRegisterAsContractorInfoValue(key, value)),
  dispatchGetReCapthcaKey: () => dispatch(getReCapthcaKey()),
});

Index.propTypes = {
  aquaFlowCurrentPage: PropTypes.number.isRequired,
  dispatchSetFlow: PropTypes.func,
  score: PropTypes.number,
  flowName: PropTypes.string,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Index),
);
