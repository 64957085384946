import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import Button from '../../../components/UI/Button/CustomButton';
import {
  setEnerBankApplicationPromotion,
  changePage,
  submitMindfire,
  resetState,
  resetNewAppState,
} from '../../../redux/actions';
import { reactGAPageview, reactGAEvent } from '../../../helper';
import EnerBankLoanOption from './EnerBankLoanOption';
import { getDealerPortalURL, getEnerBankTechSupport } from '../../../config/settings';

const Approved = props => {
  const {
    did,
    dealerUserId,
    enerBankLoanOptions,
    applicationId,
    email,
    dispatchSetApplicationPromotion,
    dispatchChangePageTitle,
    dispatchSubmitMindfire,
    user,
    history,
    dispatchResetState,
    dispatchResetNewAppState,
  } = props;

  const isUserLoggedIn = !did && user && user.dealerUser;
  const returnUrl = isUserLoggedIn ? '/portal/dashboard' : getDealerPortalURL();

  useEffect(() => {
    dispatchChangePageTitle('Application Results');
    reactGAPageview({
      path: '/ca_ebapproved',
      trackerNames: [],
      title: 'ca_ebapproved',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);
  const mindFireRequest = { email: email, lastPage: '11025', dealerUserId: dealerUserId, did: did };

  const handleClick = () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Close',
      did,
      dealerUserId,
    });
    dispatchSubmitMindfire(mindFireRequest);
    if (!isUserLoggedIn) {
      dispatchResetState(props);
      window.location.href = returnUrl;
    } else {
      dispatchResetNewAppState();
      history.push(returnUrl);
    }
  };

  return (
    <>
      <Container>
        <Row>
          <Col sm="1" />
          <Col sm="5">
            <h4 className="colorPrimay">Approved!</h4>
            <p>
              No Payments required during same-as-cash period. Interest waived if full loan amount
              is repaid during same-as-cash period.
            </p>
          </Col>
          <Col sm="6">
            <Row>
              <Col>
                <span className="colorPrimay bold">Congratulations!</span> Your loan application has
                been approved. Please review the details of your loan summarized below and contact
                our Lending Department with any questions at {getEnerBankTechSupport()}.
              </Col>
            </Row>
            <Row>
              <Col>
                {enerBankLoanOptions
                  ? enerBankLoanOptions
                      .sort((a, b) => {
                        return a.numberOfMonths < b.numberOfMonths ? 1 : -1;
                      })
                      .reverse()
                      .map((item, index) => (
                        <EnerBankLoanOption
                          loanOption={item}
                          key={item.loanTermId}
                          buttonText="I want this option"
                          applicationId={applicationId}
                          submitMindfire={() => dispatchSubmitMindfire(mindFireRequest)}
                          setApplicationPromotion={dispatchSetApplicationPromotion}
                        />
                      ))
                  : null}
              </Col>
            </Row>
            <Row>
              <Col>
                <Button type="submit" title="Close" clickHandler={handleClick} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = state => ({
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  enerBankLoanOptions: state.softPull.applicationSubmitDTO?.enerBankApplicationLoanTerm,
  applicationId: state.softPull.applicationSubmitDTO?.applicationId,
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchSetApplicationPromotion: (applicationId, promotionId, page) =>
    dispatch(setEnerBankApplicationPromotion(applicationId, promotionId, page)),
  dispatchResetState: props => dispatch(resetState(props)),
  dispatchResetNewAppState: () => dispatch(resetNewAppState()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Approved),
);
