import axios from 'axios';
import { v1 as uuidv1 } from 'uuid';
import {
  isMicrofAcceptedStatus,
  isMicrofAddedByAPI,
  isMicrofAddedByFTL,
  isMicrofAddedByServiceFinance,
  isMicrofAddedByPartnerApi,
  isMicrofFinalStatus,
  isMicrofRedirectStatus,
  isMicrofResubmitAppStatus,
  isMicrofReviewStatus,
  isMicrofStatusOpen,
  isPowerPayApproved,
  isPowerPayDeclined,
  isPowerPayIntermediates,
  isRheemApproved,
  isRheemDeclined,
  isUnderReview,
  isWellsFargoDeclined,
  isMosaicNew,
  isMosaicApproved,
  isMosaicDeclined,
  isMosaicIntermediates,
  isAquaDeclined,
  isGreenSkyDeclined,
  isGreenSkyApproved,
  isGreenSkyPending,
  isFoundationApproved,
  isFoundationDeclined,
  isFoundationIntermediates,
  isEnerBankDeclined,
  isEnerBankApproved,
  isEnerBankIntermediates,
  isFTLApproved,
  isFTLDeclined,
  isFTLIntermediate,
  isEnerBankUndecisioned,
  isStarted,
} from '../../assets/js/ApplicationStatusEnum';
import userRoleEnum from '../../assets/js/UserRoleEnum';
import selectedFinancierEnum from '../../assets/js/SelectedFinancierEnum';
import {
  getDealerPortalURL,
  getIsRheem,
  getIsPool,
  getPowerPayContractorURL,
} from '../../config/settings';
import {
  defaultFlow,
  aquaFlow,
  microfFlow,
  preQualificationFlow,
  rheemFlow,
  wellsFargoFlow,
  powerPayFlow,
  mosaicFlow,
  powerPayPreQualificationFlow,
  mosaicPreQualificationFlow,
  enerBankFlow,
  enerBankPreQualificationFlow,
  foundationFlow,
  foundationPreQualificationFlow,
  greenSkyFlow,
  greenSkyPreQualificationFlow,
  ftlPreQualificationFlow,
  ftlFlow,
  atWaterGreenSkyFlow,
  atWaterGreenSkyPreQualificationFlow,
  upgradePreQualificationFlow,
} from '../initialState';
import * as actionTypes from './actionTypes';
import {
  CHANGE_SOFTPULL_VALUE,
  SUBMIT_APPLICATION_SOFT_PULL_ERROR,
  SUBMIT_APPLICATION_SOFT_PULL_SUCCESS,
  SUBMIT_APPLICATION_SOFT_PULL_2_SUCCESS,
} from './actionTypes';
import { baseURL, getData, handleApiError, post2Data, postData } from './api';
import {
  changeAquaInfoValue,
  changeAuthValue,
  resetState,
  mapMosaicToState,
  changeMicrofInfoValue,
  changePage,
  setFlow,
  addNotification,
  endLoading,
  startLoading,
  changePreQualInfoValue,
  mapPowerPayToState,
  mapAppToWellsFargo,
  changeWellsFargoValue,
  resetNewAppState,
  mapAppDtoToRheem,
  mapApplicationToAqua,
  mapApplicationToMicrof,
  mapEnerBankToState,
  mapApplicationToUpgrade,
  mapFTLToState,
} from './index';
import { AquaPreQualificationFlowPageNumber } from '../../pages/PreQualification/preQualificationFlow';
import { AquaFlowPageNumber } from '../../pages/Aqua/AquaFlow';
import { MicrofFlowPageNumber } from '../../pages/Microf/MicrofFlow';
import { MosaicFlowPageNumber } from '../../pages/Mosaic/Flow/MosaicFlow';
import { PowerPayPreQualificationFlowPageNumber } from '../../pages/PowerPay/PreQualification/PreQualificationFlow';
import { PowerPayFlowPageNumber } from '../../pages/PowerPay/Flow/PowerPayFlow';
import { RheemFlowPageNumber } from '../../pages/Rheem/RheemFlow';
import { WellsFargoFlowPageNumber } from '../../pages/WellsFargo/WellsFargoFlow';
import { MosaicPreQualificationFlowPageNumber } from '../../pages/Mosaic/PreQualification/PreQualificationFlow';
import { mapGreenskyToState } from './greenskyActions';
import { mapAppToFoundation } from './foundationActions';
import { GreenSkyFlowPageNumber } from '../../pages/Optimus/GreenSky/Flow/GreenSkyFlow';
import { GreenSkyPreQualificationFlowPageNumber } from '../../pages/Optimus/GreenSky/PreQualification/PreQualificationFlow';
import ApplicationFlowEnum from '../../assets/js/ApplicationFlowEnum';
import { FoundationFlowPageNumber } from '../../pages/Optimus/Foundation/Flow/FoundationFlow';
import { FoundationPreQualificationFlowPageNumber } from '../../pages/Optimus/Foundation/PreQualification/PreQualificationFlow';
import { EnerBankFlowPageNumber } from '../../pages/EnerBank/Flow/EnerBankFlow';
import { EnerBankPreQualificationFlowPageNumber } from '../../pages/EnerBank/PreQualification/PreQualificationFlow';
import { mapWellsFargoToMicrof } from './wellsFargoActions';
import AirConditioningSystemEnum from '../../assets/js/AirConditioningSystemEnum';
import { FTLFlowPageNumber } from '../../pages/FTL/Flow/FTLFlow';
import { changeGreenSkyApplicationInfoValue } from './greenskyApplicationActions';
import { getFtlData } from './ftlActions';
import { changeApplicationValues, isStateAllowed } from '../../helper';
import { AtWaterGreenSkyFlowPageNumber } from '../../pages/Pools/Greensky/Flow/GreenSkyFlow';
import { AtWaterGreenSkyPreQualificationFlowPageNumber } from '../../pages/Pools/Greensky/PreQualification/PreQualificationFlow';
import { FTLPreQualificationFlowPageNumber } from '../../pages/FTL/PreQualification/PreQualificationFlow';
import { UpgradePreQualificationFlowPageNumber } from '../../pages/Upgrade/PreQualification/preQualificationFlow';

const isRheem = getIsRheem();
const isPools = getIsPool();
const ADDED_BY_SERVICE_FINANCE = 'ServiceFinance';
const ADDED_BY_FTL = 'FTL';
const ADDED_BY_PARTNER_API = 'OptimusPlus';
const DEFAULT_AC_BRAND = 'Enter Make';

export const changeSoftPullValue = (key, value) => ({
  type: CHANGE_SOFTPULL_VALUE,
  key,
  value,
});

axios.defaults.withCredentials = true;

export const submitSoftPullApplication = (params, page, app) => dispatch => {
  dispatch(startLoading('Give us a second while we find the best option for you'));

  if (app !== null) {
    const resubmitUrl = `${baseURL}/api/soft_pull/resubmit?appToken=${app.consumerToken}&appId=${
      app.applicationId
    }`;
    return postData(
      resubmitUrl,
      params,
      SUBMIT_APPLICATION_SOFT_PULL_SUCCESS,
      SUBMIT_APPLICATION_SOFT_PULL_ERROR,
      dispatch,
      null,
      null,
      page,
    );
  } else {
    const submitUrl = `${baseURL}/api/soft_pull/submit`;
    return postData(
      submitUrl,
      params,
      SUBMIT_APPLICATION_SOFT_PULL_SUCCESS,
      SUBMIT_APPLICATION_SOFT_PULL_ERROR,
      dispatch,
      null,
      null,
      page,
    );
  }
};

export const submitSoftPullApplication2 = (params, params2, page, app, app2) => dispatch => {
  dispatch(startLoading('Give us a second while we find the best option for you'));

  return post2Data(
    null,
    params,
    params2,
    SUBMIT_APPLICATION_SOFT_PULL_SUCCESS,
    SUBMIT_APPLICATION_SOFT_PULL_2_SUCCESS,
    SUBMIT_APPLICATION_SOFT_PULL_ERROR,
    dispatch,
    null,
    null,
    page,
    app,
    app2,
  );
};

export const submitMindfire = params => async (dispatch, getState) => {
  // if ((params.did !== null && params.did !== '') || getState().auth.dealerUserId === null) {
  //   await axios.post(`${baseURL}/api/soft_pull/mindfire_submit`, params);
  // }
  return '';
};

export const getAquaAppByIdAndSelectedFinancier = (
  appId,
  appSelectedFinancier,
  aquaAppNo,
  history,
) => async dispatch => {
  dispatch(startLoading('Loading application for revisit ...'));

  dispatch(changeAuthValue('aquaAppNo', aquaAppNo));

  await axios
    .get(
      `${baseURL}/api/soft_pull/getAquaAppByIdAndSelectedFinancier?appId=${encodeURIComponent(
        appId,
      )}` +
        `&appSelectedFinancier=${encodeURIComponent(appSelectedFinancier)}` +
        `&aquaAppNo=${encodeURIComponent(aquaAppNo)}`,
    )
    .then(response => {
      if (response.data === null || response.data === '') {
        dispatch(changePage(AquaPreQualificationFlowPageNumber.UserType));
        history.push('/');
      } else {
        dispatch(mapApplicationToMicrof(response.data));
        dispatch(mapApplicationToAqua(response.data));
        const dealerUserId = parseInt(response.data.dealerUser.dealerUser.userId);
        if (!!dealerUserId && dealerUserId > 0) {
          dispatch(changeAuthValue('dealerUserId', parseInt(dealerUserId)));
        }
        const dealerId = parseInt(response.data.dealer.dealerId);
        if (!!dealerId && dealerId > 0) {
          dispatch(changeAuthValue('dealerId', parseInt(dealerId)));
        }
        if (response.data.applicantPrimary != null) {
          const applicantPrimary = response.data.applicantPrimary;
          if (!!applicantPrimary && !!applicantPrimary.email && !!applicantPrimary.email.email) {
            dispatch(changePreQualInfoValue('email', applicantPrimary.email.email));
          }
        }
        dispatch(changePreQualInfoValue('microfOnly', false));
        dispatch(changeAuthValue('aquaFinanceAssigned', true));
        dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_SUCCESS, payload: response.data });

        const appStatus = response.data.status;
        const selectedFinancier = response.data.selectedFinancier;

        if (
          selectedFinancierEnum.POWER_PAY.value === selectedFinancier &&
          isAquaDeclined(appStatus)
        ) {
          dispatch(mapPowerPayToState(response.data));
          dispatch(setFlow(powerPayFlow));
          dispatch(changePage(PowerPayFlowPageNumber.Consent));
          history.push('/powerpay');
        } else {
          response.data.aquaPromotions.forEach(aquaPromotion => {
            if (aquaPromotion.selected) {
              dispatch(changeAquaInfoValue('selectedApplicationPromotion', aquaPromotion.id));
            }
          });
          getData(
            `${baseURL}/api/soft_pull/samlResponse?dealerUserId=` + dealerUserId,
            null,
            actionTypes.AQUA_FINANCE_LOGIN_SUCCESS,
            actionTypes.AQUA_FINANCE_LOGIN_ERROR,
            dispatch,
            null,
            null,
            null,
          );

          if (response.data.selectedFinancier === selectedFinancierEnum.MICROF.value) {
            dispatch(setFlow(microfFlow));
            dispatch(changePage(MicrofFlowPageNumber.Landing));
            history.push('/RTO');
          } else {
            dispatch(setFlow(aquaFlow));
            dispatch(changePage(AquaFlowPageNumber.AquaApplicationResult));
            history.push('/Aqua');
          }
        }
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(changePage(AquaPreQualificationFlowPageNumber.UserType));
      history.push('/');
    });

  dispatch(endLoading());
};

export const getAppByIdAndSelectedFinancier = (
  appSelectedFinancier,
  appId,
  history,
) => async dispatch => {
  //reset state with revisit flag "true" to keep the auth state
  dispatch(resetState({ history: null, revisit: true }));

  dispatch(startLoading('Loading application for revisit ...'));
  dispatch(changeAuthValue('appId', appId));
  await axios
    .get(
      `${baseURL}/api/soft_pull/getAppByIdAndSelectedFinancier?appSelectedFinancier=${encodeURIComponent(
        appSelectedFinancier,
      )}&appId=${encodeURIComponent(appId)}`,
    )
    .then(response => dispatch(handleApplicationRevisit(response, history)))
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(changePage(AquaPreQualificationFlowPageNumber.UserType));
      history.push('/');
    });

  dispatch(endLoading());
};

export const getAppByIdAndSelectedFinancierForUserAdminRevisit = (
  appSelectedFinancier,
  appId,
  history,
) => async dispatch => {
  //dispatch(resetState({ history: null }));
  dispatch(startLoading('Loading application for revisit ...'));
  dispatch(changeAuthValue('appId', appId));
  await axios
    .get(
      `${baseURL}/api/soft_pull/getAppByIdAndSelectedFinancierForUserAdminRevisit?appSelectedFinancier=${encodeURIComponent(
        appSelectedFinancier,
      )}&appId=${encodeURIComponent(appId)}`,
    )
    .then(response => dispatch(handleApplicationRevisit(response, history)))
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(changePage(AquaPreQualificationFlowPageNumber.UserType));
      history.push('/');
    });

  dispatch(endLoading());
};

export const getAppByIdAndToken = (appToken, appId, history) => async dispatch => {
  dispatch(resetState({ history: null }));
  dispatch(startLoading('Loading application for revisit ...'));

  dispatch(changeAuthValue('appToken', appToken));
  dispatch(changeAuthValue('appId', appId));
  await axios
    .get(
      `${baseURL}/api/soft_pull/getAppByIdAndToken?appToken=${encodeURIComponent(
        appToken,
      )}&appId=${encodeURIComponent(appId)}`,
    )
    .then(response => dispatch(handleApplicationRevisit(response, history)))
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(changePage(AquaPreQualificationFlowPageNumber.UserType));
      history.push('/');
    });

  dispatch(endLoading());
};

export const handleApplicationRevisit = (response, history) => async (dispatch, getState) => {
  const { auth } = getState();
  const isServiceFinance = addedBy =>
    addedBy && addedBy.toUpperCase() === ADDED_BY_SERVICE_FINANCE.toUpperCase();
  const isPartnerApi = addedBy =>
    addedBy && addedBy.toUpperCase() === ADDED_BY_PARTNER_API.toUpperCase();
  const isFTL = addedBy => addedBy && addedBy.toUpperCase() === ADDED_BY_FTL.toUpperCase();

  if (!response.data) {
    dispatch(changePage(AquaPreQualificationFlowPageNumber.UserType));
    history.push('/');
  } else {
    dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_SUCCESS, payload: response.data });
    const {
      dealer,
      dealerUser,
      applicantPrimary,
      selectedFinancier,
      status,
      rtoNumber,
      addedBy,
    } = response.data;

    dispatch(
      changeSoftPullValue('appProcessingStatus', response.data?.application?.processingStatus),
    );

    if (dealerUser && dealerUser.dealerUser && dealerUser.dealerUser.userId) {
      dispatch(changeAuthValue('dealerUserId', parseInt(dealerUser.dealerUser.userId)));
    }
    if (dealer && dealer.dealerId) {
      dispatch(changeAuthValue('dealerId', parseInt(dealer.dealerId)));
    }
    if (applicantPrimary && applicantPrimary.email && applicantPrimary.email.email) {
      dispatch(changePreQualInfoValue('email', applicantPrimary.email.email));
    }

    let path = '';
    if (selectedFinancierEnum.MICROF.value === selectedFinancier) {
      if (isWellsFargoDeclined(status)) {
        path = '/';
        dispatch(setFlow(defaultFlow));
      } else {
        path = '/RTO';
        dispatch(setFlow(microfFlow));
      }

      dispatch(mapApplicationToMicrof(response.data));
      dispatch(mapApplicationToAqua(response.data));

      if (isMicrofResubmitAppStatus(status)) {
        dispatch(changePage(MicrofFlowPageNumber.Review));
      } else if (rtoNumber) {
        if (
          isMicrofStatusOpen(status) &&
          (isServiceFinance(addedBy) || isFTL(addedBy) || isPartnerApi(addedBy))
        ) {
          dispatch(changeMicrofInfoValue('microfOnly', true));
          dispatch(setFlow(microfFlow));
          await axios
            .get(`${baseURL}/api/soft_pull/applications/token/${encodeURIComponent(auth.appToken)}`)
            .then(async appResponse => {
              const application = appResponse.data;
              if (application) {
                const selectedBrands = application.selectedBrands;
                if (
                  selectedBrands &&
                  selectedBrands.length > 0 &&
                  selectedBrands[0].serialNumber &&
                  selectedBrands[0].brandName !== DEFAULT_AC_BRAND
                ) {
                  dispatch(changePage(MicrofFlowPageNumber.AlreadyFunded));
                } else {
                  if (application.firstPayment && application.agreementSigned) {
                    dispatch(changeSoftPullValue('application', application));
                    await axios
                      .get(`${baseURL}/api/applications/hcBrands`)
                      .then(hcBrandsResponse => {
                        dispatch(changeSoftPullValue('hcBrands', hcBrandsResponse.data));
                        dispatch(changePage(MicrofFlowPageNumber.CompleteFundMicrofApplication));
                      })
                      .catch(error => {
                        dispatch(
                          addNotification({
                            message: handleApiError(error),
                            messageType: 'Error',
                          }),
                        );
                      });
                  } else {
                    dispatch(changePage(MicrofFlowPageNumber.FundingNotAllowed));
                  }
                }
              }
            })
            .catch(error => {
              dispatch(
                addNotification({
                  message: handleApiError(error),
                  messageType: 'Error',
                }),
              );
            });

          history.push(path);
        } else {
          if (
            auth?.user?.consumerUser?.role &&
            auth?.user?.consumerUser?.role === userRoleEnum.CONSUMER.value
          ) {
            history.push('/portal/consumer-dashboard');
          } else {
            window.location.href = getDealerPortalURL();
          }
          return;
        }
        dispatch(endLoading());
      } else if (isMicrofFinalStatus(status)) {
        history.push('/portal/consumer-dashboard');
      } else if (isMicrofRedirectStatus(status)) {
        dispatch(resetState({ history: null }));
        history.push(auth.user && auth.user.dealerUser ? '/portal/dashboard' : '/login');
      } else if (isMicrofReviewStatus(status)) {
        dispatch(changePage(MicrofFlowPageNumber.LeasingOffers));
      } else if (isMicrofAcceptedStatus(status)) {
        dispatch(changePage(MicrofFlowPageNumber.ApplicationReceived));
      } else if (isMicrofAddedByServiceFinance(status)) {
        dispatch(changeMicrofInfoValue('isServiceFinance', true));
        dispatch(changeMicrofInfoValue('microfOnly', true));
        dispatch(changePage(MicrofFlowPageNumber.ServiceFinance));
      } else if (isMicrofAddedByFTL(status)) {
        dispatch(changeMicrofInfoValue('isFTL', true));
        dispatch(changeMicrofInfoValue('microfOnly', true));
        dispatch(changePage(MicrofFlowPageNumber.LandingFTL));
      } else if (isMicrofAddedByPartnerApi(status)) {
        dispatch(changeMicrofInfoValue('isPartnerApi', true));
        dispatch(changeMicrofInfoValue('microfOnly', true));
        dispatch(changePage(MicrofFlowPageNumber.ServiceFinance));
      } else if (isMicrofAddedByAPI(status)) {
        const preQualificationInformation = response.data.prequalificationInformation;
        if (preQualificationInformation) {
          dispatch(mapPrequalificationToState(preQualificationInformation));
          if (preQualificationInformation.score) {
            dispatch(setFlow(microfFlow));
            dispatch(changePage(MicrofFlowPageNumber.Landing));
            path = '/RTO';
          } else {
            dispatch(setFlow(preQualificationFlow));
            dispatch(changePage(AquaPreQualificationFlowPageNumber.ScoreError));
            path = '/';
          }
        } else {
          let responseDealer = response.data.dealer;
          let aquaFinanceAssigned =
            responseDealer &&
            responseDealer.dealerBanks &&
            responseDealer.dealerBanks.some(
              bank =>
                bank.name.toUpperCase() === selectedFinancierEnum.AQUA_FINANCE.value.toUpperCase(),
            );
          dispatch(changeMicrofInfoValue('microfOnly', !aquaFinanceAssigned));
          dispatch(changeAuthValue('aquaFinanceAssigned', aquaFinanceAssigned));
          if (!aquaFinanceAssigned) {
            dispatch(setFlow(microfFlow));
            path = '/RTO';
          } else {
            dispatch(setFlow(preQualificationFlow));
            dispatch(changePage(AquaPreQualificationFlowPageNumber.LoanFor));
            path = '/';
          }
        }
      } else if (isAquaDeclined(status)) {
        dispatch(mapApplicationToAqua(response.data));
        dispatch(changePage(MicrofFlowPageNumber.Landing));
      } else if (isPowerPayDeclined(status)) {
        dispatch(mapPowerPayToState(response.data));
        dispatch(changePage(MicrofFlowPageNumber.Landing));
      } else if (isMosaicDeclined(status)) {
        dispatch(mapMosaicToState(response.data));
        dispatch(changePage(MicrofFlowPageNumber.Landing));
      } else if (isGreenSkyDeclined(status)) {
        dispatch(mapGreenskyToState(response.data));
        dispatch(changePage(MicrofFlowPageNumber.Landing));
      } else if (isWellsFargoDeclined(status)) {
        dispatch(mapWellsFargoToMicrof(response.data));
        dispatch(changePage(AquaPreQualificationFlowPageNumber.UserLastInfo));
      } else if (isRheemDeclined(status)) {
        dispatch(mapApplicationToMicrof(response.data));
        dispatch(changePage(MicrofFlowPageNumber.Landing));
      } else if (isEnerBankDeclined(status)) {
        dispatch(mapEnerBankToState(response.data));
        dispatch(changePage(MicrofFlowPageNumber.Landing));
      } else if (isFoundationDeclined(status)) {
        dispatch(mapAppToFoundation(response.data));
        dispatch(changePage(MicrofFlowPageNumber.Landing));
      } else if (isFTLDeclined(status)) {
        dispatch(mapFTLToState(response.data));
        dispatch(changePage(MicrofFlowPageNumber.Landing));
      } else {
        dispatch(changePage(MicrofFlowPageNumber.ApplicationReceived));
      }
      history.push(path);
    } else if (selectedFinancierEnum.AQUA_FINANCE.value === selectedFinancier) {
      if (isPowerPayDeclined(status)) {
        dispatch(mapPowerPayToState(response.data));
        dispatch(setFlow(aquaFlow));
        dispatch(changePage(AquaFlowPageNumber.AquaStartPage));
        history.push('/Aqua');
      } else if (isMosaicDeclined(status)) {
        dispatch(mapMosaicToState(response.data));
        dispatch(setFlow(aquaFlow));
        dispatch(changePage(AquaFlowPageNumber.AquaStartPage));
        history.push('/Aqua');
      } else {
        dispatch(setFlow(aquaFlow));
        const appStatus = response.data.status;
        if (appStatus === 'STARTED') {
          dispatch(mapApplicationToAqua(response.data));
          dispatch(changePage(AquaFlowPageNumber.ApplicationReview));
        } else if (isMicrofAddedByAPI(appStatus)) {
          dispatch(mapApplicationToAqua(response.data));
          dispatch(setFlow(aquaFlow));
          dispatch(changePage(AquaFlowPageNumber.AquaStartPage));
          history.push('/Aqua');
        } else if (isAquaDeclined(status)) {
          dispatch(mapApplicationToAqua(response.data));
          dispatch(setFlow(aquaFlow));
          dispatch(changePage(AquaFlowPageNumber.AquaApplicationResult));
          history.push('/Aqua');
        } else {
          dispatch(resetState({ history: null }));
          dispatch(setFlow(preQualificationFlow));
          dispatch(changePage(AquaPreQualificationFlowPageNumber.UserType));
          history.push('/');
        }
        history.push('/Aqua');
      }
    } else if (selectedFinancierEnum.RHEEM.value === selectedFinancier) {
      let flow = rheemFlow;
      path = '/rheem';
      let page;

      dispatch(mapAppDtoToRheem(response.data));
      if (isRheemApproved(status)) {
        page = RheemFlowPageNumber.LeasingOffers;
      } else if (isMicrofAcceptedStatus(status)) {
        page = RheemFlowPageNumber.FinalReview;
      } else if (isMicrofStatusOpen(status)) {
        page = RheemFlowPageNumber.ContactInformation;
      } else if (isRheemDeclined(status)) {
        dispatch(mapApplicationToMicrof(response.data));
        flow = microfFlow;
        path = '/RTO';
        page = MicrofFlowPageNumber.RheemDecline;
      } else if (isMicrofResubmitAppStatus(status)) {
        page = RheemFlowPageNumber.Review;
      } else {
        return history.push('/login');
      }
      dispatch(setFlow(flow));
      dispatch(changePage(page));
      history.push(path);
    } else if (selectedFinancierEnum.POWER_PAY.value === selectedFinancier) {
      if (isAquaDeclined(status)) {
        dispatch(mapPowerPayToState(response.data));
        dispatch(setFlow(powerPayFlow));
        dispatch(changePage(PowerPayFlowPageNumber.Consent));
        history.push('/powerpay');
      } else if (isMosaicDeclined(status)) {
        dispatch(mapMosaicToState(response.data));
        dispatch(setFlow(powerPayFlow));
        dispatch(changePage(PowerPayFlowPageNumber.Consent));
        history.push('/powerpay');
      } else if (isPowerPayApproved(status)) {
        dispatch(resetState({ history: null }));
        window.open(getPowerPayContractorURL(), '_self');
      } else if (isPowerPayDeclined(status) || isPowerPayIntermediates(status)) {
        dispatch(mapPowerPayToState(response.data));
        dispatch(setFlow(powerPayFlow));
        dispatch(changePage(PowerPayFlowPageNumber.ApplicationResult));
        history.push('/powerpay');
      } else if (status === 'STARTED') {
        dispatch(mapPowerPayToState(response.data));
        dispatch(setFlow(powerPayFlow));
        dispatch(changePage(PowerPayFlowPageNumber.ApplicationReview));
        history.push('/powerpay');
      } else {
        dispatch(resetState({ history: null }));
        dispatch(setFlow(preQualificationFlow));
        dispatch(changePage(AquaPreQualificationFlowPageNumber.UserType));
        history.push('/');
      }
    } else if (selectedFinancierEnum.WELLS_FARGO.value === selectedFinancier) {
      dispatch(mapAppToWellsFargo(response.data));
      let page = isUnderReview(status)
        ? WellsFargoFlowPageNumber.Review
        : WellsFargoFlowPageNumber.ApplicationResult;
      dispatch(setFlow(wellsFargoFlow));
      dispatch(changePage(page));
      history.push('/WellsFargo');
    } else if (selectedFinancierEnum.MOSAIC.value === selectedFinancier) {
      if (isMosaicApproved(status)) {
        dispatch(mapMosaicToState(response.data));
        dispatch(setFlow(mosaicFlow));
        dispatch(changePage(MosaicFlowPageNumber.Approved));
        history.push('/mosaic');
      } else if (isMosaicDeclined(status)) {
        dispatch(mapMosaicToState(response.data));
        dispatch(setFlow(mosaicFlow));
        dispatch(changePage(MosaicFlowPageNumber.Declined));
        history.push('/mosaic');
      } else if (isMosaicIntermediates(status)) {
        dispatch(mapMosaicToState(response.data));
        dispatch(setFlow(mosaicFlow));
        dispatch(changePage(MosaicFlowPageNumber.Pending));
        history.push('/mosaic');
      } else if (isMosaicNew(status)) {
        dispatch(mapMosaicToState(response.data));
        dispatch(setFlow(mosaicFlow));
        dispatch(changePage(MosaicFlowPageNumber.MosaicIFrame));
        history.push('/mosaic');
      } else {
        dispatch(resetState({ history: null }));
        dispatch(setFlow(preQualificationFlow));
        dispatch(changePage(AquaPreQualificationFlowPageNumber.UserType));
        history.push('/');
      }
    } else if (selectedFinancierEnum.GREENSKY.value === selectedFinancier) {
      dispatch(handleGreenskyRevisit(response.data, history));
    } else if (selectedFinancierEnum.FOUNDATION.value === selectedFinancier) {
      dispatch(handleFoundationRevisit(response.data, history));
    } else if (selectedFinancierEnum.ENERBANK.value === selectedFinancier) {
      dispatch(handleEnerBankRevisit(response.data, history));
    } else if (selectedFinancierEnum.FTL.value === selectedFinancier) {
      dispatch(handleFTLRevisit(response.data, history));
    } else if (selectedFinancierEnum.UPGRADE.value === selectedFinancier) {
      if (isStarted(status)) {
        dispatch(mapApplicationToUpgrade(response.data));
        dispatch(setFlow(upgradePreQualificationFlow));
        dispatch(changePage(UpgradePreQualificationFlowPageNumber.UserLastInfo));
        history.push('/upgrade-prequalification');
      } else {
        dispatch(mapApplicationToUpgrade(response.data));
        dispatch(setFlow(upgradePreQualificationFlow));
        dispatch(changePage(UpgradePreQualificationFlowPageNumber.Offers));
        history.push('/upgrade-prequalification');
      }
    } else {
      dispatch(resetState({ history: null }));
      dispatch(setFlow(preQualificationFlow));
      dispatch(changePage(AquaPreQualificationFlowPageNumber.UserType));
      history.push('/');
    }
  }
};

export const handleFTLRevisit = (app, history) => dispatch => {
  const { status } = app;
  let flow = ApplicationFlowEnum.FTL_FLOW.value;
  let urlPath = ApplicationFlowEnum.FTL_FLOW.path;
  let flowPageNumber = FTLFlowPageNumber.ApplicationResult;
  dispatch(mapFTLToState(app));

  if (status === 'STARTED') {
    flowPageNumber = FTLFlowPageNumber.Review;
  } else if (
    isMosaicDeclined(status) ||
    isPowerPayDeclined(status) ||
    isAquaDeclined(status) ||
    isGreenSkyDeclined(status) ||
    isEnerBankDeclined(status)
  ) {
    if (isMosaicDeclined(status)) {
      dispatch(mapMosaicToState(app));
    } else if (isPowerPayDeclined(status)) {
      dispatch(mapPowerPayToState(app));
    } else if (isGreenSkyDeclined(status)) {
      dispatch(mapGreenskyToState(app));
    } else if (isEnerBankDeclined(status)) {
      dispatch(mapEnerBankToState(app));
    } else {
      dispatch(mapApplicationToAqua(app));
    }
    flowPageNumber = FTLFlowPageNumber.Consent;
  } else if (!isFTLApproved(status) && !isFTLDeclined(status) && !isFTLIntermediate(status)) {
    dispatch(resetState({ history: null }));
    flow = ApplicationFlowEnum.AQUA_PREQUALIFICATION.value;
    flowPageNumber = AquaPreQualificationFlowPageNumber.UserType;
    urlPath = '/login';
  }

  dispatch(setFlow(flow));
  dispatch(changePage(flowPageNumber));
  history.push(urlPath);
};

export const handleEnerBankRevisit = (app, history) => dispatch => {
  const { status } = app;
  let flow = ApplicationFlowEnum.ENERBANK_FLOW.value;
  let urlPath = ApplicationFlowEnum.ENERBANK_FLOW.path;
  let flowPageNumber = EnerBankFlowPageNumber.ApplicationResult;
  dispatch(mapEnerBankToState(app));

  if (status === 'STARTED') {
    flowPageNumber = EnerBankFlowPageNumber.ApplicationReview;
  } else if (
    !isEnerBankApproved(status) &&
    !isEnerBankIntermediates(status) &&
    !isEnerBankUndecisioned(status) &&
    !isEnerBankDeclined(status)
  ) {
    dispatch(resetState({ history: null }));
    flow = ApplicationFlowEnum.AQUA_PREQUALIFICATION.value;
    flowPageNumber = AquaPreQualificationFlowPageNumber.UserType;
    urlPath = '/login';
  }

  dispatch(setFlow(flow));
  dispatch(changePage(flowPageNumber));
  history.push(urlPath);
};

export const handleGreenskyRevisit = (app, history) => dispatch => {
  const { status } = app;
  let flow = isPools
    ? ApplicationFlowEnum.ATWATER_GREENSKY_FLOW.value
    : ApplicationFlowEnum.GREENSKY_FLOW.value;
  let urlPath = isPools
    ? ApplicationFlowEnum.ATWATER_GREENSKY_FLOW.path
    : ApplicationFlowEnum.GREENSKY_FLOW.path;
  let flowPageNumber = isPools
    ? AtWaterGreenSkyFlowPageNumber.ApplicationResult
    : GreenSkyFlowPageNumber.ApplicationResult;
  dispatch(mapGreenskyToState(app));

  if (status === 'STARTED') {
    flowPageNumber = isPools ? AtWaterGreenSkyFlowPageNumber.Review : GreenSkyFlowPageNumber.Review;
  } else if (
    !isGreenSkyApproved(status) &&
    !isGreenSkyDeclined(status) &&
    !isGreenSkyPending(status)
  ) {
    dispatch(resetState({ history: null }));
    flow = ApplicationFlowEnum.AQUA_PREQUALIFICATION.value;
    flowPageNumber = AquaPreQualificationFlowPageNumber.UserType;
    urlPath = '/login';
  }

  dispatch(setFlow(flow));
  dispatch(changePage(flowPageNumber));
  history.push(urlPath);
};

export const handleFoundationRevisit = (app, history) => dispatch => {
  const { status } = app;
  let flow = ApplicationFlowEnum.FOUNDATION_FLOW.value;
  let urlPath = ApplicationFlowEnum.FOUNDATION_FLOW.path;
  let flowPageNumber = FoundationFlowPageNumber.ApplicationResult;
  dispatch(mapAppToFoundation(app));

  if (status === 'STARTED') {
    flowPageNumber = FoundationFlowPageNumber.ApplicationReview;
  } else if (
    isMosaicDeclined(status) ||
    isGreenSkyDeclined(status) ||
    isEnerBankDeclined(status) ||
    isEnerBankUndecisioned(status) ||
    isFTLDeclined(status)
  ) {
    if (isMosaicDeclined(status)) {
      dispatch(mapMosaicToState(app));
    } else if (isGreenSkyDeclined(status)) {
      dispatch(mapGreenskyToState(app));
    } else if (isFTLDeclined(status)) {
      dispatch(mapFTLToState(app));
    } else {
      dispatch(mapEnerBankToState(app));
    }
    flow = ApplicationFlowEnum.FOUNDATION_FLOW.value;
    flowPageNumber = FoundationFlowPageNumber.Consent;
    urlPath = '/foundation';
  } else if (
    !isFoundationApproved(status) &&
    !isFoundationDeclined(status) &&
    !isFoundationIntermediates(status)
  ) {
    dispatch(resetState({ history: null }));
    flow = ApplicationFlowEnum.AQUA_PREQUALIFICATION.value;
    flowPageNumber = AquaPreQualificationFlowPageNumber.UserType;
    urlPath = '/login';
  }

  dispatch(setFlow(flow));
  dispatch(changePage(flowPageNumber));
  history.push(urlPath);
};

export const getConsumerUser = (consumerId, consumerEmail, history) => async dispatch => {
  dispatch(startLoading('Loading ...'));

  await axios
    .get(
      `${baseURL}/api/soft_pull/get_consumer_user?consumerUserId=${encodeURIComponent(
        consumerId,
      )}&email=${encodeURIComponent(consumerEmail)}`,
    )
    .then(response => {
      if (response.data) {
        const consumerUser = response.data.consumerUser;
        const dealer = consumerUser && consumerUser.dealer;
        const wellsFargoMerchantNumber = dealer && dealer.wellsFargoMerchantNumber;
        const hasWellsFargoAssigned =
          dealer &&
          dealer.dealerBanks &&
          dealer.dealerBanks.some(bank => bank.bankId === selectedFinancierEnum.WELLS_FARGO.id);

        if (wellsFargoMerchantNumber && hasWellsFargoAssigned) {
          dispatch(mapConsumerUserToWellsFargo(consumerUser));
          dispatch(setFlow(wellsFargoFlow));
          dispatch(changePage(WellsFargoFlowPageNumber.LoanFor));
          history.push('/WellsFargo');
        } else {
          dispatch(mapConsumerUserToMicrof(consumerUser));
          dispatch(setFlow(microfFlow));
          dispatch(changePage(MicrofFlowPageNumber.UserInfo));
          history.push('/RTO');
        }
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });

  dispatch(endLoading());
};

const mapConsumerToAuth = data => dispatch => {
  dispatch(changeAuthValue('consumerUser', data));
  dispatch(changeAuthValue('consumerId', data.userId));
  dispatch(changeAuthValue('track_id', data.trackId));
};

const mapConsumerUserToWellsFargo = data => dispatch => {
  dispatch(mapConsumerToAuth(data));
  dispatch(changeWellsFargoValue('terms', true));
  dispatch(changeWellsFargoValue('email', data.email));
  dispatch(changeWellsFargoValue('isEmailUnique', true));
  dispatch(changeWellsFargoValue('zipcode', data.zip));
  dispatch(changeWellsFargoValue('firstName', data.nameFirst));
  dispatch(changeWellsFargoValue('lastName', data.nameLast));
  dispatch(changeWellsFargoValue('phoneNumber', data.mobilePhone ? data.mobilePhone.number : null));
};

const mapConsumerUserToMicrof = data => dispatch => {
  dispatch(mapConsumerToAuth(data));
  dispatch(changeMicrofInfoValue('homeownerInsurance', true));
  dispatch(changeMicrofInfoValue('microfOnly', true));
  dispatch(changeMicrofInfoValue('isHomeowner', true));
  dispatch(changePreQualInfoValue('zipcode', data.zip));
  dispatch(changePreQualInfoValue('isConsumer', true));
  dispatch(changePreQualInfoValue('isEmailUnique', true));
  dispatch(changePreQualInfoValue('email', data.email));
  dispatch(changePreQualInfoValue('firstName', data.nameFirst));
  dispatch(changePreQualInfoValue('lastName', data.nameLast));
  dispatch(
    changePreQualInfoValue('phoneNumber', data.mobilePhone ? data.mobilePhone.number : null),
  );
  if (data.address) {
    dispatch(changePreQualInfoValue('street', data.address.street));
    dispatch(changePreQualInfoValue('city', data.address.city));
    dispatch(changePreQualInfoValue('state', data.address.state));
  }
};

export const getConsumerApp = (
  consumerId,
  appSelectedFinancier,
  appId,
  history,
) => async dispatch => {
  dispatch(resetNewAppState());

  dispatch(startLoading('Loading application ...'));

  dispatch(changeAuthValue('consumerId', consumerId));
  dispatch(changeAuthValue('appId', appId));
  dispatch(changePreQualInfoValue('isConsumer', true));
  dispatch(changePreQualInfoValue('isEmailUnique', true));

  await axios
    .get(
      `${baseURL}/api/soft_pull/getAppByIdAndSelectedFinancier?appSelectedFinancier=${encodeURIComponent(
        appSelectedFinancier,
      )}&appId=${encodeURIComponent(appId)}`,
    )
    .then(response => dispatch(handleApplicationRevisit(response, history)))
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(changePage(AquaPreQualificationFlowPageNumber.UserType));
      history.push('/');
    });

  dispatch(endLoading());
};

const mapPrequalificationToState = data => dispatch => {
  dispatch({ type: actionTypes.GET_SCORE_SUCCESS, payload: data });
  dispatch({ type: actionTypes.GET_SCORE_SOFT_PULL_SUCCESS, payload: data });
  dispatch(changeAuthValue('consumerId', data.consumerId));
  dispatch(changeAuthValue('dealerId', data.dealerId));
  dispatch(changeAuthValue('dealerUserId', data.dealerUserId));
  dispatch(changeAuthValue('aquaFinanceAssigned', !!data.ssnLastDigits));
  dispatch(changePreQualInfoValue('email', data.email));
  dispatch(changePreQualInfoValue('isEmailUnique', true));
  dispatch(changePreQualInfoValue('consumerId', data.consumerId));
  dispatch(changePreQualInfoValue('borrowedAmount', data.borrowedAmount));
  dispatch(changePreQualInfoValue('firstName', data.firstName));
  dispatch(changePreQualInfoValue('middleName', data.middleName));
  dispatch(changePreQualInfoValue('lastName', data.lastName));
  dispatch(changePreQualInfoValue('phoneNumber', data.phoneNumber));
  dispatch(changePreQualInfoValue('street', data.street));
  dispatch(changePreQualInfoValue('city', data.city));
  dispatch(changePreQualInfoValue('zipcode', data.zipcode));
  dispatch(changePreQualInfoValue('state', data.state));
  dispatch(changePreQualInfoValue('ssnLastDigits', data.ssnLastDigits));
  dispatch(changePreQualInfoValue('birthday', data.birthday));
  dispatch(changePreQualInfoValue('airConditioningSystem', data.airConditioningSystem));
  dispatch(changePreQualInfoValue('isHomeowner', data.isHomeowner));
  dispatch(changePreQualInfoValue('prequalificationId', data.prequalificationId));
  dispatch(changePreQualInfoValue('score', data.score));
  dispatch(changePreQualInfoValue('scoreLimit', data.scoreLimit));
  dispatch(changePreQualInfoValue('scoreLimit2', data.scoreLimit2));
  dispatch(changePreQualInfoValue('isConsumer', !!data.consumerId));
  dispatch(changePreQualInfoValue('externalReferenceId', data.externalReferenceId));
};

export const getPrequalificationByIdAndToken = (pqiId, pqiToken, history) => async dispatch => {
  dispatch(resetNewAppState());
  dispatch(startLoading('Getting PreQualification Information ...'));

  dispatch(changeAuthValue('pqiId', pqiId));
  dispatch(changeAuthValue('pqiToken', pqiToken));

  await axios
    .get(
      `${baseURL}/api/soft_pull/getPrequalificationByIdAndToken?pqiId=${encodeURIComponent(
        pqiId,
      )}&pqiToken=${encodeURIComponent(pqiToken)}`,
    )
    .then(response => {
      if (!response.data) {
        dispatch(changePage(AquaPreQualificationFlowPageNumber.UserType));
        history.push('/');
      } else {
        const { defaultFinancingOption, secondFinancingOption } = response.data;
        dispatch(mapPrequalificationToState(response.data));

        let isRtoNotAvailable =
          !isStateAllowed(response.data.state) ||
          response.data.airConditioningSystem ===
            AirConditioningSystemEnum.OTHER_HOME_IMPROVEMENT.id;

        if (response.data.score !== null) {
          if (response.data.score === 0) {
            if (defaultFinancingOption === 'POWER_PAY') {
              if (secondFinancingOption === 'AQUA_FINANCE') {
                dispatch(changeAuthValue('aquaFinanceAssigned', true));
              }
              dispatch(changeAuthValue('powerPayAssigned', true));
              dispatch(setFlow(powerPayPreQualificationFlow));
              dispatch(changePage(PowerPayPreQualificationFlowPageNumber.InvalidResponse));
              history.push('/powerpay-prequalification');
            } else if (defaultFinancingOption === 'MOSAIC') {
              if (secondFinancingOption === 'AQUA_FINANCE') {
                dispatch(changeAuthValue('aquaFinanceAssigned', true));
              }
              if (secondFinancingOption === 'POWER_PAY') {
                dispatch(changeAuthValue('powerPayAssigned', true));
              }
              dispatch(changeAuthValue('mosaicAssigned', true));
              dispatch(setFlow(mosaicPreQualificationFlow));
              dispatch(changePage(MosaicPreQualificationFlowPageNumber.InvalidResponse));
              history.push('/mosaic-prequalification');
            } else if (defaultFinancingOption === 'ENERBANK') {
              if (secondFinancingOption === 'AQUA_FINANCE') {
                dispatch(changeAuthValue('aquaFinanceAssigned', true));
              }
              if (secondFinancingOption === 'POWER_PAY') {
                dispatch(changeAuthValue('powerPayAssigned', true));
              }
              dispatch(changeAuthValue('enerBankAssigned', true));
              dispatch(setFlow(enerBankPreQualificationFlow));
              dispatch(changePage(EnerBankPreQualificationFlowPageNumber.InvalidResponse));
              history.push('/enerbank-prequalification');
            } else if (defaultFinancingOption === 'FTL') {
              dispatch(changeAuthValue('ftlassigned', true));
              dispatch(setFlow(ftlPreQualificationFlow));
              dispatch(changePage(FTLPreQualificationFlowPageNumber.InvalidResponse));
              history.push('/ftl-prequalification');
            } else if (defaultFinancingOption === 'FOUNDATION') {
              if (secondFinancingOption === 'AQUA_FINANCE') {
                dispatch(changeAuthValue('aquaFinanceAssigned', true));
              }
              if (secondFinancingOption === 'POWER_PAY') {
                dispatch(changeAuthValue('powerPayAssigned', true));
              }
              dispatch(changeAuthValue('foundationAssigned', true));
              dispatch(setFlow(foundationPreQualificationFlow));
              dispatch(changePage(FoundationPreQualificationFlowPageNumber.InvalidResponse));
              history.push('/foundation-prequalification');
            } else if (defaultFinancingOption === 'GREENSKY') {
              if (secondFinancingOption === 'AQUA_FINANCE') {
                dispatch(changeAuthValue('aquaFinanceAssigned', true));
              }
              if (secondFinancingOption === 'POWER_PAY') {
                dispatch(changeAuthValue('powerPayAssigned', true));
              }
              dispatch(changeAuthValue('greenskyAssigned', true));
              dispatch(setFlow(greenSkyPreQualificationFlow));
              dispatch(changePage(GreenSkyPreQualificationFlowPageNumber.UserLastInfo));
              history.push('/greensky-prequalification');
            } else {
              if (secondFinancingOption === 'AQUA_FINANCE') {
                dispatch(changeAuthValue('aquaFinanceAssigned', true));
              }
              dispatch(changeAuthValue('aquaFinanceAssigned', true));
              dispatch(setFlow(preQualificationFlow));
              dispatch(changePage(AquaPreQualificationFlowPageNumber.InvalidResponse));
              history.push('/');
            }
          } else if (isRheem) {
            dispatch(changePage(AquaPreQualificationFlowPageNumber.UserLastInfo));
          } else if (
            defaultFinancingOption === 'POWER_PAY' &&
            response.data.score >= response.data.scoreLimit &&
            response.data.ssnLastDigits != null
          ) {
            dispatch(changeAuthValue('powerPayAssigned', true));
            dispatch(setFlow(powerPayFlow));
            dispatch(changePage(PowerPayFlowPageNumber.Consent));
            history.push('/powerpay');
          } else if (
            defaultFinancingOption === 'MOSAIC' &&
            response.data.score >= response.data.scoreLimit &&
            response.data.ssnLastDigits != null
          ) {
            dispatch(changeAuthValue('mosaicAssigned', true));
            dispatch(setFlow(mosaicFlow));
            dispatch(changePage(MosaicFlowPageNumber.Consent));
            history.push('/mosaic');
          } else if (
            defaultFinancingOption === 'ENERBANK' &&
            response.data.score >= response.data.scoreLimit &&
            response.data.ssnLastDigits != null
          ) {
            dispatch(changeAuthValue('enerBankAssigned', true));
            dispatch(setFlow(enerBankFlow));
            dispatch(changePage(EnerBankFlowPageNumber.Consent));
            history.push('/enerbank');
          } else if (
            defaultFinancingOption === 'FOUNDATION' &&
            response.data.score >= response.data.scoreLimit &&
            response.data.ssnLastDigits != null
          ) {
            dispatch(changeAuthValue('foundationAssigned', true));
            dispatch(setFlow(foundationFlow));
            dispatch(changePage(FoundationFlowPageNumber.Consent));
            history.push('/foundation');
          } else if (
            defaultFinancingOption === 'GREENSKY' &&
            response.data.score >= response.data.scoreLimit &&
            response.data.ssnLastDigits != null
          ) {
            if (isPools) {
              dispatch(changeAuthValue('greenskyAssigned', true));
              dispatch(setFlow(atWaterGreenSkyFlow));
              dispatch(changePage(AtWaterGreenSkyFlowPageNumber.Consent));
              history.push('/atWater-greensky');
            } else {
              dispatch(changeAuthValue('greenskyAssigned', true));
              dispatch(setFlow(greenSkyFlow));
              dispatch(changePage(GreenSkyFlowPageNumber.Consent));
              history.push('/greensky');
            }
          } else if (
            defaultFinancingOption === 'AQUA_FINANCE' &&
            response.data.score >= response.data.scoreLimit &&
            response.data.ssnLastDigits != null &&
            'PA,AR,CT,MI,'.indexOf(response.data.state + ',') === -1
          ) {
            dispatch(changeAuthValue('aquaFinanceAssigned', true));
            dispatch(setFlow(aquaFlow));
            history.push('/Aqua');
          } else if (
            defaultFinancingOption === 'FTL' &&
            response.data.score >= response.data.scoreLimit &&
            response.data.ssnLastDigits != null
          ) {
            dispatch(changeAuthValue('ftlassigned', true));
            dispatch(setFlow(ftlFlow));
            dispatch(changePage(FTLFlowPageNumber.Consent));
            history.push('/ftl');
          } else if (
            response.data.score < response.data.scoreLimit &&
            (response.data.score < response.data.scoreLimit2 || response.data.scoreLimit2 === 0) &&
            isRtoNotAvailable
          ) {
            if (isPools) {
              dispatch(setFlow(atWaterGreenSkyPreQualificationFlow));
              dispatch(changePage(AtWaterGreenSkyPreQualificationFlowPageNumber.NotAllowed));
              history.push('/atwater-prequalification');
            } else if (defaultFinancingOption === 'GREENSKY') {
              dispatch(setFlow(greenSkyPreQualificationFlow));
              dispatch(changePage(GreenSkyPreQualificationFlowPageNumber.NotAllowed));
              history.push('/greensky-prequalification');
            } else if (defaultFinancingOption === 'FOUNDATION') {
              dispatch(setFlow(foundationPreQualificationFlow));
              dispatch(changePage(FoundationPreQualificationFlowPageNumber.NotAllowed));
              history.push('/foundation-prequalification');
            } else if (defaultFinancingOption === 'POWER_PAY') {
              dispatch(setFlow(powerPayPreQualificationFlow));
              dispatch(changePage(PowerPayPreQualificationFlowPageNumber.NotAllowed));
              history.push('/powerpay-prequalification');
            } else if (defaultFinancingOption === 'MOSAIC') {
              dispatch(setFlow(mosaicPreQualificationFlow));
              dispatch(changePage(MosaicPreQualificationFlowPageNumber.NotAllowed));
              history.push('/mosaic-prequalification');
            } else if (defaultFinancingOption === 'ENERBANK') {
              dispatch(setFlow(enerBankPreQualificationFlow));
              dispatch(changePage(EnerBankPreQualificationFlowPageNumber.NotAllowed));
              history.push('/enerbank-prequalification');
            } else if (defaultFinancingOption === 'FTL') {
              dispatch(setFlow(ftlPreQualificationFlow));
              dispatch(changePage(FTLPreQualificationFlowPageNumber.NotAllowed));
              history.push('/ftl-prequalification');
            } else {
              // Home improvement with score for RTO flow
              dispatch(setFlow(preQualificationFlow));
              dispatch(changePage(AquaPreQualificationFlowPageNumber.NotAllowed));
              history.push('/');
            }
          } else if (isPools && response.data.score < response.data.scoreLimit) {
            dispatch(setFlow(atWaterGreenSkyPreQualificationFlow));
            dispatch(changePage(AtWaterGreenSkyPreQualificationFlowPageNumber.NotAllowed));
            history.push('/atwater-prequalification');
          } else {
            dispatch(
              setSecondSelectedFinancerFlow(
                secondFinancingOption,
                response.data.score,
                response.data.scoreLimit2,
                response.data.ssnLastDigits,
                response.data.state,
                response.data.airConditioningSystem,
                history,
              ),
            );
          }
        } else {
          dispatch(changePage(AquaPreQualificationFlowPageNumber.UserType));
          history.push('/');
        }
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(changePage(AquaPreQualificationFlowPageNumber.UserType));
      history.push('/');
    });

  dispatch(endLoading());
};

export const setSecondSelectedFinancerFlow = (
  secondFinancingOption,
  score,
  scoreLimit2,
  ssnLastDigits,
  state,
  airConditioningSystem,
  history,
) => dispatch => {
  if (secondFinancingOption === 'POWER_PAY' && score >= scoreLimit2 && ssnLastDigits != null) {
    dispatch(changeAuthValue('powerPayAssigned', true));
    dispatch(setFlow(powerPayFlow));
    dispatch(changePage(PowerPayFlowPageNumber.Consent));
    history.push('/powerpay');
  } else if (secondFinancingOption === 'MOSAIC' && score >= scoreLimit2 && ssnLastDigits != null) {
    dispatch(changeAuthValue('mosaicAssigned', true));
    dispatch(setFlow(mosaicFlow));
    dispatch(changePage(MosaicFlowPageNumber.Consent));
    history.push('/mosaic');
  } else if (
    secondFinancingOption === 'ENERBANK' &&
    score >= scoreLimit2 &&
    ssnLastDigits != null
  ) {
    dispatch(changeAuthValue('enerBankAssigned', true));
    dispatch(setFlow(enerBankFlow));
    dispatch(changePage(EnerBankFlowPageNumber.Consent));
    history.push('/enerbank');
  } else if (
    secondFinancingOption === 'FOUNDATION' &&
    score >= scoreLimit2 &&
    ssnLastDigits != null
  ) {
    dispatch(changeAuthValue('foundationAssigned', true));
    dispatch(setFlow(foundationFlow));
    dispatch(changePage(FoundationFlowPageNumber.Consent));
    history.push('/foundation');
  } else if (
    secondFinancingOption === 'GREENSKY' &&
    score >= scoreLimit2 &&
    ssnLastDigits != null
  ) {
    dispatch(changeAuthValue('greenskyAssigned', true));
    dispatch(setFlow(greenSkyFlow));
    dispatch(changePage(GreenSkyFlowPageNumber.Consent));
    history.push('/greensky');
  } else if (
    secondFinancingOption === 'AQUA_FINANCE' &&
    score >= scoreLimit2 &&
    ssnLastDigits != null &&
    'PA,AR,CT,MI,'.indexOf(state + ',') === -1
  ) {
    dispatch(changeAuthValue('aquaFinanceAssigned', true));
    dispatch(setFlow(aquaFlow));
    history.push('/Aqua');
  } else if (secondFinancingOption === 'FTL' && score >= scoreLimit2 && ssnLastDigits != null) {
    dispatch(changeAuthValue('ftlAssigned', true));
    dispatch(setFlow(ftlFlow));
    dispatch(changePage(FTLFlowPageNumber.Consent));
    history.push('/ftl');
  } else if (
    score < scoreLimit2 &&
    airConditioningSystem === AirConditioningSystemEnum.OTHER_HOME_IMPROVEMENT.id
  ) {
    // Home improvement with score for RTO flow
    dispatch(setFlow(preQualificationFlow));
    dispatch(changePage(AquaPreQualificationFlowPageNumber.NotAllowed));
    history.push('/');
  } else {
    dispatch(setFlow(microfFlow));
    history.push('/RTO');
  }
};

export const getApplicationsHistory = searchOptions => async dispatch => {
  dispatch(startLoading('Get Applications ...'));
  await axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(searchOptions),
    url: `${baseURL}/api/self/dealer/information`,
  })
    .then(response => {
      if (response.data) {
        dispatch(changeSoftPullValue('applicationsHistory', response.data));
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const getTransactionHistory = searchOptions => async dispatch => {
  dispatch(startLoading('Get GreenSky Transaction History ...'));
  // await axios({
  //   method: 'post',
  //   headers: {
  //     'Content-Type': 'application/json',
  //   },
  //   data: JSON.stringify(searchOptions),
  //   url: `${baseURL}/api/self/dealer/information`,
  // })
  //   .then(response => {
  //     if (response.data) {
  //       dispatch(changeSoftPullValue('transactionHistory', response.data));
  //     }
  //   })
  //   .catch(error => {
  //     dispatch(
  //       addNotification({
  //         message: handleApiError(error),
  //         messageType: 'Error',
  //       }),
  //       );
  //     });
  // dispatch(changeSoftPullValue('transactionHistory', 'Works!'));
  dispatch(endLoading());
};

export const getUnassignedPrequalificationInfoList = searchOptions => async dispatch => {
  dispatch(startLoading('Get unassigned prequalification ...'));
  await axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(searchOptions),
    url: `${baseURL}/api/soft_pull/get_unassigned_pqi`,
  })
    .then(response => {
      if (response.data) {
        dispatch(changeSoftPullValue('unassignedPrequalificationInfoList', response.data));
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const getDeclinedConsumerApplications = searchOptions => async dispatch => {
  dispatch(startLoading('Get declined consumer applications ...'));
  await axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(searchOptions),
    url: `${baseURL}/api/soft_pull/get_declined_consumer_applications`,
  })
    .then(response => {
      if (response.data) {
        dispatch(changeSoftPullValue('declinedConsumerApplications', response.data));
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const getApplications = (dealerUserId, applicationType) => async dispatch => {
  dispatch(changeSoftPullValue('applications', null));
  dispatch(startLoading('Get Applications ...'));
  await axios
    .get(
      `${baseURL}/api/soft_pull/get_applications?applicationType=${encodeURIComponent(
        applicationType,
      )}`,
    )
    .then(response => {
      if (response.data) {
        dispatch(changeSoftPullValue('applications', response.data));
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const updateExternalId = (preqId, externalId) => async dispatch => {
  dispatch(startLoading('Update External Id ...'));
  await axios
    .post(
      `${baseURL}/api/soft_pull/update_external_id?preqId=${encodeURIComponent(
        preqId,
      )}&externalId=${encodeURIComponent(externalId)}`,
    )
    .then(response => {
      if (response.data) {
        window.location.reload(false);
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const deleteUser = (id, history, url) => async dispatch => {
  dispatch(startLoading(`Deleting user with id: ${id}`));
  await axios
    .delete(`${baseURL}/api/soft_pull/delete_user?userId=${encodeURIComponent(id)}`)
    .then(response => {
      history.push(url ? url : '/portal/dashboard');
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const updateUser = (data, setIsEditMode) => async dispatch => {
  dispatch(startLoading(`Updating dealer user with email: ${data.email}`));
  await axios
    .put(`${baseURL}/api/soft_pull/update_user`, data)
    .then(response => {
      dispatch(changeSoftPullValue('selectedContractorUser', response.data));
      setIsEditMode(false);
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const changePassword = (data, setChangePass) => async dispatch => {
  dispatch(startLoading(`Change user password.`));
  await axios
    .put(`${baseURL}/api/soft_pull/change_password`, data)
    .then(response => {
      dispatch(changeSoftPullValue('selectedContractorUser', response.data));
      setChangePass(false);
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const searchDealerUsers = () => async dispatch => {
  dispatch(startLoading(`Searching dealer users.`));
  await axios
    .get(`${baseURL}/api/soft_pull/search_dealer_users`)
    .then(response => {
      dispatch(changeSoftPullValue('searchDealerUser', response.data));
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const sendEmailToSalesPerson = (applicationId, data) => dispatch => {
  axios
    .post(`${baseURL}/api/self/dealer/applications/${applicationId}/message/salesperson`, data)
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
};

export const sendEmailToRelationshipManager = (applicationId, data) => dispatch => {
  axios
    .post(`${baseURL}/api/self/dealer/applications/${applicationId}/message/drr`, data)
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
};

export const createRojosUser = data => async dispatch => {
  let isUserCreated = false;
  dispatch(startLoading(`Creating user with email: ${data.email}`));
  await axios
    .post(`${baseURL}/api/soft_pull/createRojosUser`, data)
    .then(() => {
      isUserCreated = true;
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
  return isUserCreated;
};

export const createDealerUser = (data, history) => async dispatch => {
  dispatch(startLoading(`Creating dealer user with email: ${data.email}`));
  await axios
    .post(`${baseURL}/api/soft_pull/create_dealer_user`, data)
    .then(response => {
      let selectedContractorUser = response.data;
      selectedContractorUser.isNew = true;
      dispatch(changeSoftPullValue('selectedContractorUser', selectedContractorUser));
      history.push('/portal/manage-contractor-user');
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const getApplication = applicationId => async dispatch => {
  dispatch(changeSoftPullValue('application', null));
  dispatch(startLoading('Get Application with Id ' + applicationId + ' ...'));
  await axios
    .get(`${baseURL}/api/self/dealer/applications/${encodeURIComponent(applicationId)}`)
    .then(response => {
      if (response.data) {
        dispatch(changeSoftPullValue('application', response.data));
        if (
          response.data.status === 'FTL_APPROVE_MORE' ||
          response.data.status === 'FTL_APPROVED_WITH_INFO' ||
          response.data.status === 'FTL_APPROVED_MORE_WITH_INFO' ||
          response.data.status === 'FTL_APPROVE_LOAN_MORE' ||
          response.data.status === 'FTL_APPROVED'
        ) {
          dispatch(endLoading());
          dispatch(getFtlData(applicationId));
        } else {
          dispatch(endLoading());
        }
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(endLoading());
    });
};

export const getSoftPullHcBrands = () => async dispatch => {
  dispatch(startLoading('Get hcbrands'));
  await axios
    .get(`${baseURL}/api/applications/hcBrands`)
    .then(response => {
      dispatch(changeSoftPullValue('hcBrands', response.data));
      dispatch(endLoading());
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(endLoading());
    });
};

export const initialContractorInformation = (application, history, url) => async dispatch => {
  dispatch(startLoading('Save Initial Contractor Information ...'));
  await axios
    .post(`${baseURL}/api/soft_pull/single_app/initialContractorInformation`, application)
    .then(response => {
      dispatch(endLoading());
      history.push(url);
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(endLoading());
    });
};

export const completeMicrofWork = (application, history, url) => async dispatch => {
  dispatch(startLoading('Save Complete Microf Work ...'));
  await axios
    .post(`${baseURL}/api/applications/completeMicrofWork`, application)
    .then(response => {
      dispatch(endLoading());
      if (getIsRheem()) {
        dispatch(changeSoftPullValue('isRheemSerialModelMapComplete', false));
        dispatch(changeSoftPullValue('rheemSerialModelMap', null));
        dispatch(changeSoftPullValue('hasModelRequestBeenSent', false));
        dispatch(changeSoftPullValue('infoValues', null));
      }
      history.push(url);
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(endLoading());
    });
};

export const greenskyApiCredentials = (application, history, url) => async dispatch => {
  dispatch(startLoading('Submit the Greensky credentials ...'));
  await axios
    .post(`${baseURL}/api/soft_pull/single_app/greenskyCredentials`, application)
    .then(response => {
      dispatch(endLoading());
      if (response.data) {
        history.push(url);
      } else {
        dispatch(
          addNotification({
            message: handleApiError(
              'Failed to submit the Greensky credentials. Please check if the information provided is correct and try again.',
            ),
            messageType: 'Greensky API Credentials Failed',
          }),
        );
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(endLoading());
    });
};

export const searchContractorUsers = searchOptions => async dispatch => {
  dispatch(startLoading('Search Contractor Users ...'));
  await axios
    .get(
      `${baseURL}/api/users/dealers?search_options=` +
        encodeURIComponent(JSON.stringify(searchOptions)),
    )
    .then(response => {
      dispatch(endLoading());
      if (response.data) {
        dispatch(changeSoftPullValue('contractorUsers', response.data));
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(endLoading());
    });
};

export const searchContractors = searchOptions => async dispatch => {
  dispatch(startLoading('Search Contractors ...'));
  await axios
    .get(`${baseURL}/api/dealers?options=` + encodeURIComponent(JSON.stringify(searchOptions)))
    .then(response => {
      dispatch(endLoading());
      if (response.data) {
        dispatch(changeSoftPullValue('dealerSearchCriteriaResult', response.data));
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(endLoading());
    });
};

export const searchUsers = (dealerId, searchOptions) => async dispatch => {
  dispatch(startLoading('Search Users ...'));
  dispatch(changeSoftPullValue('usersSearchCriteriaResult', null));
  await axios
    .get(`${baseURL}/api/soft_pull/dealer/${dealerId}/users`)
    .then(response => {
      dispatch(endLoading());
      if (response.data) {
        dispatch(changeSoftPullValue('usersSearchCriteriaResult', response.data));
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(endLoading());
    });
};

export const setPrimaryUser = (data, history, url) => async dispatch => {
  dispatch(startLoading('Updating Primary User...'));
  await axios
    .post(`${baseURL}/api/soft_pull/primary`, data)
    .then(response => {
      dispatch(endLoading());
      if (response.status === 200) {
        dispatch(changeSoftPullValue('usersSearchCriteriaResult', null));
        window.location.href = url;
      } else {
        dispatch(
          addNotification({
            message: handleApiError('Failed to update Primary User'),
            messageType: 'Updating Primary User Failed',
          }),
        );
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(endLoading());
    });
};

export const getDealers = () => async dispatch => {
  dispatch(startLoading('Get dealers ...'));
  await axios
    .get(`${baseURL}/api/pools/atwater_dealers`)
    .then(response => {
      dispatch(endLoading());
      if (response.data) {
        dispatch(changeSoftPullValue('dealers', response.data));
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(endLoading());
    });
};

export const getDealerUser = userId => async dispatch => {
  dispatch(startLoading('Get User ...'));
  await axios
    .get(`${baseURL}/api/users/${userId}`)
    .then(response => {
      dispatch(endLoading());
      if (response.data) {
        dispatch(changeSoftPullValue('contractorUser', response.data));
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(endLoading());
    });
};

export const updateDealerUser = (data, history, url) => async dispatch => {
  dispatch(startLoading(`Updating dealer user with email: ${data.email}`));
  await axios
    .put(`${baseURL}/api/soft_pull/${data.id}`, data)
    .then(() => {
      history.push(url);
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const findApplicationByRtoOrId = (idNumber, successCallback) => async dispatch => {
  dispatch(startLoading(`Finding application for AppId/RTO: ${idNumber}`));
  await axios
    .get(
      `${baseURL}/api/soft_pull/find_application?idNumber=${encodeURIComponent(idNumber.trim())}`,
    )
    .then(response => {
      if (response.data) {
        dispatch(changeSoftPullValue('applicationMoveApp', response.data));
        successCallback(1);
      } else {
        dispatch(
          addNotification({
            message: 'The application could not be found!',
            messageType: 'Error',
          }),
        );
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const findApplicationByIdForGDSResubmit = (idNumber, successCallback) => async dispatch => {
  dispatch(startLoading(`Finding application for AppId/RTO: ${idNumber}`));
  await axios
    .get(
      `${baseURL}/api/soft_pull/find_application_for_resubmit?idNumber=${encodeURIComponent(
        idNumber.trim(),
      )}`,
    )
    .then(response => {
      if (response.data) {
        dispatch(changeSoftPullValue('applicationForGDS', response.data));
        successCallback(1);
      } else {
        dispatch(
          addNotification({
            message: 'The application could not be found!',
            messageType: 'Error',
          }),
        );
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const searchApplications = searchOptions => async dispatch => {
  dispatch(startLoading('Search Applications ...'));
  await axios
    .get(`${baseURL}/api/applications?options=` + encodeURIComponent(JSON.stringify(searchOptions)))
    .then(response => {
      dispatch(endLoading());
      if (response.data) {
        dispatch(changeSoftPullValue('searchedApplications', response.data));
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
};

export const moveAppToContractor = (
  applicationId,
  targetContractorId,
  successCallback,
) => async dispatch => {
  dispatch(
    startLoading(
      `Moving application with id "${applicationId}" to contractor with id: ${targetContractorId}`,
    ),
  );
  await axios
    .put(
      `${baseURL}/api/soft_pull/move/${encodeURIComponent(
        applicationId,
      )}?dealerUserId=${targetContractorId}`,
    )
    .then(response => {
      successCallback(0);
      if (response && response.data) {
        dispatch(changeSoftPullValue('applicationMoveApp', response.data));
      }
      dispatch(endLoading());
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(endLoading());
    });
};

export const getApplicationViewDTO = applicationId => async dispatch => {
  dispatch(startLoading('Get Application ...'));
  await axios
    .get(`${baseURL}/api/applications/consumer/${encodeURIComponent(applicationId)}`)
    .then(async response => {
      if (response.data) {
        dispatch(changeSoftPullValue('applicationViewDTO', response.data));
      }
      dispatch(endLoading());
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(endLoading());
    });
};

export const updateDealerStates = (dealerDto, history) => async dispatch => {
  dispatch(startLoading('Update dealer states ...'));
  await axios
    .put(`${baseURL}/api/soft_pull/dealerStates`, dealerDto)
    .then(response => {
      history.push('/portal/manage-contractor-states?search=true');
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const updateDealerZipCodes = (dealerDto, history) => async dispatch => {
  dispatch(startLoading('Update dealer states ...'));
  await axios
    .put(`${baseURL}/api/soft_pull/dealerZipcodes`, dealerDto)
    .then(response => {
      history.push('/portal/manage-contractor-zipcodes?search=true');
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const assignApplication = (application, dealer) => async dispatch => {
  if (application && dealer) {
    dispatch(startLoading(`Assign application ...`));
    await axios
      .put(
        `${baseURL}/api/soft_pull/consumer/${encodeURIComponent(application.applicationId)}`,
        dealer,
      )
      .then(response => {
        dispatch(
          changeSoftPullValue(
            'assignApplicationMessage',
            'Successfully assign "Daniel Kuti" to "Lateral Inc Test Dealer"!',
          ),
        );
      })
      .catch(error => {
        dispatch(
          addNotification({
            message: handleApiError(error),
            messageType: 'Error',
          }),
        );
      });
    dispatch(endLoading());
  }
};

export const findContractors = zipcode => async dispatch => {
  dispatch(startLoading('Find Contractors ...'));
  await axios
    .get(`${baseURL}/api/dealers/findByZipInternal/${zipcode}`)
    .then(response => {
      dispatch(endLoading());
      if (response.data) {
        dispatch(changeSoftPullValue('foundContractors', response.data));
      }
    })
    .catch(error => {
      if (('' + error).includes('Request failed with status code 500')) {
        dispatch(changeSoftPullValue('foundContractors', null));
      } else {
        dispatch(
          addNotification({
            message: handleApiError(error),
            messageType: 'Error',
          }),
        );
      }
      dispatch(endLoading());
    });
};

export const getUploadedDocuments = () => async dispatch => {
  dispatch(changeSoftPullValue('uploadedDocuments', null));
  dispatch(startLoading('Get Uploaded Documents ...'));

  let searchOptions = {
    sortOption: '',
    sortAscending: false,
    pageLimit: 1000,
    pageOffset: 0,
  };
  await axios
    .get(
      `${baseURL}/api/rest/upload-document?search_options=` +
        encodeURIComponent(JSON.stringify(searchOptions)),
    )
    .then(response => {
      dispatch(endLoading());
      if (response.data) {
        dispatch(changeSoftPullValue('uploadedDocuments', response.data));
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(endLoading());
    });
};

export const createInternalUser = (data, createdBy, history) => async dispatch => {
  dispatch(startLoading(`Creating internal user with email: ${data.email}`));
  dispatch(changeSoftPullValue('newInternalUser', data));
  await axios
    .post(`${baseURL}/api/soft_pull/createInteralUser/${createdBy}`, data)
    .then(response => {
      let internalUser = response.data;
      dispatch(changeSoftPullValue('newInternalUser', internalUser));
      // history.push('/portal/manage-contractor-user');
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const searchInternalUsers = searchOptions => async dispatch => {
  dispatch(startLoading('Search Internal Users ...'));
  await axios
    .get(
      `${baseURL}/api/users/?search_options=` + encodeURIComponent(JSON.stringify(searchOptions)),
    )
    .then(response => {
      dispatch(endLoading());
      if (response.data) {
        dispatch(changeSoftPullValue('internalUsers', response.data));
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(endLoading());
    });
};

export const sendDataToOriginationExpert = (file, history) => async dispatch => {
  dispatch(startLoading(`Send Data to Origination Expert`));
  changeSoftPullValue('originationExpertLogFile', null);

  const formData = new FormData();
  const uuid = uuidv1();
  formData.append('inputFile', file);
  formData.append('uuid', uuid);

  await axios({
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
    url: `${baseURL}/api/soft_pull/origination_send_data`,
  })
    .then(response => {
      dispatch(
        changeSoftPullValue(
          'originationExpertLogFile',
          `${baseURL}/api/rest/download?folder=${response.data}&file=logger.log`,
        ),
      );
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const getDealerRestrictAquaPromotions = dealerId => async dispatch => {
  dispatch(startLoading('Get Dealer Restrict Aqua Promotions ...'));
  await axios
    .get(`${baseURL}/api/dealers/dealer-restrict-aqua-promotions/${dealerId}`)
    .then(response => {
      dispatch(endLoading());
      if (response.data) {
        dispatch(changeSoftPullValue('dealerRestrictAquaPromotions', response.data));
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(endLoading());
    });
};

export const updateDealerPromotions = (dealerDTO, history) => async dispatch => {
  dispatch(startLoading('Update Dealer Promotions ...'));
  await axios
    .put(`${baseURL}/api/soft_pull/dealer-restrict-aqua-promotions`, dealerDTO)
    .then(response => {
      dispatch(endLoading());
      history.push('/portal/restrict-aqua-promotions/');
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(endLoading());
    });
};

export const getApplicationByConsumerId = (
  consumerId,
  email,
  consumerUserWithDealerLink,
  history,
) => async (dispatch, getState) => {
  dispatch(startLoading('Loading application ...'));
  await axios
    .get(
      `${baseURL}/api/soft_pull/get_app_by_consumer_id?consumerUserId=${encodeURIComponent(
        consumerId,
      )}`,
    )
    .then(response => {
      if (response.data === null || response.data === '') {
        dispatch(getPrequalificationInformation(consumerId, history));
      } else {
        dispatch(endLoading());
        history.push(
          `/?consumerId=${encodeURIComponent(consumerId)}` +
            `&appId=${encodeURIComponent(response.data.applicationId)}` +
            `&appSelectedFinancier=${encodeURIComponent(response.data.selectedFinancier)}`,
        );
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
};

export const getPrequalificationInformation = (consumerId, history) => async dispatch => {
  dispatch(startLoading('Loading application ...'));
  await axios
    .get(
      `${baseURL}/api/soft_pull/get_prequalification_information?consumerUserId=${encodeURIComponent(
        consumerId,
      )}`,
    )
    .then(response => {
      if (
        response.data &&
        response.data.prequalificationId &&
        response.data.prequalificationToken
      ) {
        dispatch(endLoading());
        history.push(
          `/?pqiId=${encodeURIComponent(response.data.prequalificationId)}` +
            `&pqiToken=${encodeURIComponent(response.data.prequalificationToken)}`,
        );
      }
    })
    .catch(error => {
      dispatch(endLoading());
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
};

export const getDealer = (dealerId, history) => async dispatch => {
  dispatch(startLoading('Get Dealer info ...'));
  await axios
    .get(`${baseURL}/api/dealers/dealer/${encodeURIComponent(dealerId)}`)
    .then(response => {
      if (response.data) {
        dispatch(changeAuthValue('dealerDTO', response.data));
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const getModelNumbersFromRheem = serialNumbers => async dispatch => {
  dispatch(startLoading('Get model information for entered serial numbers ...'));
  let json = JSON.stringify({ serialNumbers: serialNumbers });
  await axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: json,
    url: `${baseURL}/api/soft_pull/model_numbers_from_serial_numbers`,
  })
    .then(response => {
      if (response.data) {
        dispatch(
          changeSoftPullValue(
            'isRheemSerialModelMapComplete',
            response.data.isSerialModelMapComplete,
          ),
        );
        dispatch(changeSoftPullValue('rheemSerialModelMap', response.data.serialNumberModelMap));
      }
    })
    .catch(error => {
      dispatch(endLoading());
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const setDefaultFinancingOption = (bankId, bankId2nd, history) => async dispatch => {
  dispatch(startLoading(`Set Default Financing Option`));
  await axios
    .put(
      `${baseURL}/api/self/dealer/default_financing_option?bankId=${encodeURIComponent(
        bankId,
      )}&secondBankId=${encodeURIComponent(bankId2nd)}`,
    )
    .then(response => {
      history.push('/portal/dashboard');
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const mapApplicationToPrequalification = app => dispatch => {
  dispatch(changePreQualInfoValue('email', app.email));
  dispatch(changePreQualInfoValue('borrowedAmount', app.totalFinancingAmount));
  dispatch(changePreQualInfoValue('ssnLastDigits', app.ssn && app.ssn.substr(app.ssn.length - 4)));
  dispatch(changePreQualInfoValue('street', app.propertyStreet));
  dispatch(changePreQualInfoValue('city', app.propertyCity));
  dispatch(changePreQualInfoValue('zipcode', app.propertyZip));
  dispatch(changePreQualInfoValue('state', app.propertyState));
  dispatch(changePreQualInfoValue('poBox', app.poBox));
  dispatch(
    changePreQualInfoValue(
      'airConditioningSystem',
      app.airConditioningSystem || app.prequalificationInformation?.airConditioningSystem,
    ),
  );
  dispatch(
    changePreQualInfoValue(
      'isHomeowner',
      app.isHomeowner || app.prequalificationInformation?.isHomeowner,
    ),
  );
};

export const checkErrors = response => dispatch => {
  if (response && response.data && response.data.error && response.data.error.message) {
    dispatch(
      addNotification({
        message: handleApiError(response.data.error.message),
        messageType: 'Error',
      }),
    );
    dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_ERROR, payload: response.data.error.message });
    dispatch(endLoading());
    return false;
  } else {
    return true;
  }
};

export const getSingleTransaction = (transactionId, reloadPage) => async dispatch => {
  const searchOption = { pageLimit: 20, pageOffset: 0 };
  dispatch(changeSoftPullValue('singleTransaction', null));
  dispatch(startLoading('Get Single Transaction with Id ' + transactionId + ' ...'));
  await axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: { transactionId: transactionId },
    url: `${baseURL}/api/soft_pull/greensky/transaction_search`,
  })
    .then(response => {
      if (response.data) {
        dispatch(changeSoftPullValue('singleTransaction', response.data));
        dispatch(endLoading());
        if (reloadPage) {
          dispatch(getAllTransactions(searchOption));
        }
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const getAllTransactions = searchOption => async dispatch => {
  dispatch(startLoading('Get All Transactions ...'));
  await axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: { pageLimit: searchOption.pageLimit, pageOffset: searchOption.pageOffset },
    url: `${baseURL}/api/soft_pull/greensky/transactions`,
  })
    .then(response => {
      if (response.data) {
        dispatch(changeSoftPullValue('allTransactions', response.data));
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const getGreenSkyApplication = applicationId => async dispatch => {
  dispatch(changeSoftPullValue('greenSkyApplication', null));
  dispatch(startLoading('Get GreenSky application ...'));
  await axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: { applicationId: parseInt(applicationId) },
    url: `${baseURL}/api/soft_pull/greensky/application_search`,
  })
    .then(response => {
      if (response.data) {
        if (response.data.message) {
          dispatch(
            addNotification({
              message: handleApiError(response.data.message),
              messageType: 'Error',
            }),
          );
        }
        dispatch(
          changeSoftPullValue('greenSkyApplication', response.data.applicationSearchResults[0]),
        );
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const getGreenSkyOffersData = applicationId => async dispatch => {
  dispatch(changeSoftPullValue('greenSkyOffersData', null));
  dispatch(startLoading('Get GreenSky offer data ...'));
  await axios
    .get(
      `${baseURL}/api/soft_pull/greensky/offers?applicationId=${encodeURIComponent(applicationId)}`,
    )
    .then(response => {
      if (response.data) {
        if (response.data.error) {
          dispatch(
            addNotification({
              message: handleApiError(response.data.error.message),
              messageType: 'Error',
            }),
            dispatch(changeSoftPullValue('greenSkyOffersData', response.data)),
          );
          dispatch(changePage(GreenSkyFlowPageNumber.ErrorPage));
          dispatch(endLoading());
        } else {
          dispatch(changeSoftPullValue('greenSkyOffersData', response.data));
          if (response.data.greenskyInformation.questionFailed === true) {
            dispatch(changePage(GreenSkyFlowPageNumber.ErrorPage));
            dispatch(endLoading());
          } else {
            if (
              response.data.greenskyInformation.ctaRequired === false &&
              response.data.greenskyInformation.esignatureComplete === 'yes'
            ) {
              dispatch(changePage(GreenSkyFlowPageNumber.ActivationPage));
            }
            dispatch(endLoading());
            if (response.data.greenskyInformation.ctaRequired === true) {
              dispatch(
                greenSkyCheckCta(
                  response.data.greenskyInformation.greenskyApplicationId,
                  response.data.prequalificationInformation.ssnLastDigits,
                  response.data.greenskyInformation.spanish,
                ),
              );
            }
          }
        }
      }
    })
    .catch(error => {
      //dispatch(changeSoftPullValue('greenSkyOffersData', {}));
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  // dispatch(endLoading());
};

export const recalculateGreenSkyOffer = (
  applicationId,
  loanAmount,
  planNumber,
  product,
) => async dispatch => {
  dispatch(changeSoftPullValue('greenSkyApplication', null));
  dispatch(startLoading('Recalculate GreenSky offer ...'));
  await axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      applicationId: applicationId,
      loanAmount: parseInt(loanAmount),
      planNumber: planNumber,
      product: product,
    },
    url: `${baseURL}/api/soft_pull/greensky/recalculate_offers`,
  })
    .then(response => {
      if (response.data) {
        if (response.data.error) {
          dispatch(
            addNotification({
              message: handleApiError(response.data.error.message),
              messageType: 'Error',
            }),
            dispatch(changeSoftPullValue('greenSkyOffersData', response.data)),
          );
        } else {
          dispatch(changeSoftPullValue('greenSkyOffersData', response.data));
        }
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });

  dispatch(endLoading());
};

export const greenSkyAcceptOffer = (
  offerId,
  applicationId,
  product,
  authorizedLoanAmount,
  last4Ssn,
) => async dispatch => {
  dispatch(startLoading('Accepting GreenSky offer ...'));
  await axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      offerId: offerId,
      applicationId: applicationId,
      product: product,
      authorizedLoanAmount: authorizedLoanAmount,
    },
    url: `${baseURL}/api/soft_pull/greensky/create_account`,
  })
    .then(response => {
      if (response.data) {
        dispatch(greenSkyCheckCta2(applicationId, last4Ssn, response.data));
      }
    })
    .catch(error => {
      dispatch(endLoading());
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });

  //dispatch(endLoading());
};

export const getGreenSkyLoanAgreement = applicationId => async dispatch => {
  dispatch(changeSoftPullValue('greenSkyLoanAgreement', null));
  dispatch(startLoading('Get GreenSky Loan Agreement ...'));
  await axios
    .get(
      `${baseURL}/api/soft_pull/greensky/loan_agreement?applicationId=${encodeURIComponent(
        applicationId,
      )}`,
    )
    .then(response => {
      if (response.data) {
        dispatch(changeSoftPullValue('greenSkyLoanAgreement', response.data));
      }
    })
    .catch(error => {
      dispatch(changeSoftPullValue('greenSkyLoanAgreement', {}));
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const viewGreenSkyLoanAgreement = applicationId => async dispatch => {
  //dispatch(changeSoftPullValue('greenSkyLoanAgreement', null));
  dispatch(startLoading('View GreenSky Loan Agreement ...'));
  await axios({
    method: 'GET',
    url: `${baseURL}/api/soft_pull/greensky/loan_document?applicationId=${encodeURIComponent(
      applicationId,
    )}`,
    responseType: 'blob',
  })
    .then(response => {
      if (response.data) {
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], {
          type: 'application/pdf',
        });
        // //Build a URL from the file
        const fileURL = URL.createObjectURL(file);

        var win = window.open(fileURL);

        if (win.document) {
          win.document.write(
            '<html><head><title>Loan Agreement Document</title></head><body height="100%" width="100%"><iframe src="' +
              fileURL +
              '" height="100%" width="100%"></iframe></body></html>',
          );
        }
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const greenSkyCheckCta = (applicationId, last4Ssn, isSpanish) => async dispatch => {
  if (isSpanish) {
    dispatch(changeSoftPullValue('isSpanish', true));
  }

  dispatch(startLoading('Activating Account ...'));
  await axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      applicationId: applicationId,
      instore: false,
      last4SSN: last4Ssn,
    },
    url: `${baseURL}/api/soft_pull/greensky/check_cta`,
  })
    .then(response => {
      if (response.data) {
        dispatch(changeSoftPullValue('ctaResponse', response.data));
        if (
          response.data.activationVersion === '2.0' ||
          response.data.activationVersion === '2.1'
        ) {
          dispatch(endLoading());
          dispatch(changePage(GreenSkyFlowPageNumber.ActivationPage));
        } else {
          if (response.data.ctarequired === true) {
            dispatch(endLoading());
            dispatch(getGreenSkyQuestions(applicationId, last4Ssn, isSpanish));
          }
          if (response.data.ctarequired === false && response.data.eSignatureComplete === 'no') {
            dispatch(endLoading());
            dispatch(eSign(applicationId));
          }
          if (response.data.ctarequired === false && response.data.eSignatureComplete === 'yes') {
            dispatch(endLoading());
            dispatch(changePage(GreenSkyFlowPageNumber.ActivationPage));
          }
        }
      }
    })
    .catch(error => {
      dispatch(endLoading());
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
};

export const greenSkyCheckCta2 = (applicationId, last4Ssn, accData) => async dispatch => {
  dispatch(changeSoftPullValue('ctaResponse', null));

  dispatch(startLoading('Activating Account ...'));
  await axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      applicationId: applicationId,
      instore: false,
      last4SSN: last4Ssn,
    },
    url: `${baseURL}/api/soft_pull/greensky/check_cta`,
  })
    .then(response => {
      if (response.data) {
        dispatch(changeSoftPullValue('ctaResponse', response.data));
        if (
          response.data.activationVersion === '2.0' ||
          response.data.activationVersion === '2.1'
        ) {
          dispatch(changePage(GreenSkyFlowPageNumber.ActivationPage));
        } else {
          dispatch(changeSoftPullValue('greenSkyOffersData', accData));
        }
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const eSign = applicationId => async dispatch => {
  dispatch(startLoading('eSign ...'));
  await axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    url: `${baseURL}/api/soft_pull/greensky/esign?applicationId=${encodeURIComponent(
      applicationId,
    )}`,
  })
    .then(response => {
      if (response.data) {
        dispatch(changeSoftPullValue('applicationSubmitDTO', response.data));
        if (response.data.greenskyInformation.esignatureSuccess === true)
          dispatch(changePage(GreenSkyFlowPageNumber.ActivationPage));
      } else {
        dispatch(
          addNotification({
            message: handleApiError(response.data.error),
            messageType: 'Error',
          }),
        );
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const getGreenSkyQuestions = (applicationId, last4Ssn, isSpanish) => async dispatch => {
  dispatch(startLoading('Activating Account ...'));

  let data = {
    applicationId: applicationId,
    last4SSN: last4Ssn,
  };
  if (isSpanish) {
    data = {
      applicationId: applicationId,
      language: 'es_ES',
      last4SSN: last4Ssn,
    };
  }

  await axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
    url: `${baseURL}/api/soft_pull/greensky/get_questions`,
  })
    .then(response => {
      if (response.data) {
        dispatch(changeSoftPullValue('questionsResponse', response.data));
        dispatch(changePage(GreenSkyFlowPageNumber.Questions));
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });

  dispatch(endLoading());
};

export const postGreenSkyIdentityVerification = (
  applicationId,
  request,
  last4Ssn,
  isSpanish,
  greenskyApplicationId,
) => async dispatch => {
  dispatch(startLoading());

  return axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: request,
    url: `${baseURL}/api/soft_pull/greensky/send_answers`,
  })
    .then(async response => {
      if (response.data) {
        dispatch(changeGreenSkyApplicationInfoValue('identityVerification', response.data));
        // dispatch(endLoading());
        if (response.data.status !== null) {
          if (response.data.status === 'failComplete' || response.data.status === 'FAIL') {
            dispatch(endLoading());
            dispatch(changePage(GreenSkyFlowPageNumber.ErrorPage));
          } else if (response.data.status === 'PASS') {
            dispatch(greenSkyCheckCta(greenskyApplicationId, last4Ssn, isSpanish));
          } else if (response.data.status === 'PENDING') {
            dispatch(changeSoftPullValue('questionsResponse', response.data));
            dispatch(endLoading());
          } else {
            dispatch(endLoading());
            dispatch(changePage(GreenSkyFlowPageNumber.ActivationPage));
          }
        }
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(endLoading());
    });
};

export const submitCharge = (applicationData, greenSkyApplication) => async dispatch => {
  dispatch(changeGreenSkyApplicationInfoValue('isAccountCreated', null));
  dispatch(startLoading('Submitting GreenSky application ...'));
  await axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      applicationId: greenSkyApplication.applicationId,
      amount: applicationData.currentChargeAmount,
      creditCardUID: greenSkyApplication.creditCardUID,
      totalJobAmount: applicationData.authorizedLoanAmount,
      chargeType: applicationData.chargeType,
      invoiceNumber: applicationData.invoiceNumber ? applicationData.invoiceNumber : '',
      txnCode: '101',
    },
    url: `${baseURL}/api/soft_pull/greensky/transaction_submit`,
  })
    .then(response => {
      if (response.data) {
        dispatch(changeSoftPullValue('greenSkyApplicationSubmit', response.data));
        if (response.data.status === 'Success') {
          dispatch(changeGreenSkyApplicationInfoValue('isAccountCreated', true));
        }
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(changeGreenSkyApplicationInfoValue('isAccountCreated', false));
    });
  dispatch(endLoading());
};

export const submitRefund = (
  appId,
  amount,
  parentTransactionId,
  creditCardUID,
) => async dispatch => {
  dispatch(changeSoftPullValue('submitRefundData', null));
  dispatch(startLoading('Submitting GreenSky application refund ...'));
  await axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      applicationId: appId,
      amount: amount,
      parentTransactionId: parentTransactionId,
      creditCardUID: creditCardUID,
      txnCode: '201',
    },
    url: `${baseURL}/api/soft_pull/greensky/transaction_submit`,
  })
    .then(response => {
      if (response.data) {
        dispatch(changeSoftPullValue('submitRefundData', response.data));
        dispatch(changeSoftPullValue('refundAmount', amount));
        dispatch(endLoading());
      }
    })
    .catch(error => {
      dispatch(endLoading());
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const cancelTransaction = (txnNumber, creditCardUID, applicationId) => async dispatch => {
  dispatch(startLoading('Canceling GreenSky application ...'));
  await axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      txnNumber: txnNumber,
      creditCardUID: creditCardUID,
      applicationId: applicationId,
    },
    url: `${baseURL}/api/soft_pull/greensky/transaction_cancel`,
  })
    .then(response => {
      if (response.data.errors) {
        dispatch(
          addNotification({
            message: handleApiError(response.data.errors),
            messageType: 'Error',
          }),
        );
      } else if (response.data.status === 'Success') {
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};
export const greenskyValidateEmail = (
  email,
  dealerId,
  isCoApplicant,
  coAppValues,
) => async dispatch => {
  dispatch(startLoading('GreenSky Validate Email ...'));
  await axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      email: email,
    },
    url: `${baseURL}/api/soft_pull/greensky/validate_email?dealerId=${encodeURIComponent(
      dealerId,
    )}`,
  })
    .then(response => {
      if (response.data) {
        if (response.data.isValid === true) {
          if (isCoApplicant) {
            //dispatch(changeApplicationValues(changeValues, coAppValues));
            Object.keys(coAppValues).forEach(key =>
              dispatch(changeGreenSkyApplicationInfoValue(key, coAppValues[key])),
            );
          } else {
            dispatch(changePreQualInfoValue('email', email));
          }
        } else {
          if (isCoApplicant) {
            dispatch(changePage(GreenSkyFlowPageNumber.AdditionalCoApplicantInformation));
          } else {
            dispatch(changePage(GreenSkyPreQualificationFlowPageNumber.AccountEmail));
          }

          dispatch(
            addNotification({
              message: handleApiError('Must provide a valid email address'),
              messageType: 'Bad Email format',
            }),
          );
        }
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const greenskyValidateUsersEmail = (
  email,
  dealerId,
  isCoApplicant,
  coAppValues,
) => async dispatch => {
  dispatch(startLoading('GreenSky Validate Email ...'));
  dispatch(changeGreenSkyApplicationInfoValue('userEmailAlreadyExist', false));
  await axios
    .get(
      `${baseURL}/api/soft_pull/greensky/is_email_valid?dealerId=${encodeURIComponent(
        dealerId,
      )}&email=${encodeURIComponent(email)}`,
    )
    .then(response => {
      if (!response.data) {
        dispatch(changePreQualInfoValue('userEmailAlreadyExist', true));
        dispatch(changePreQualInfoValue('email', null));
        dispatch(changePage(GreenSkyPreQualificationFlowPageNumber.AccountEmail));
        dispatch(endLoading());
      } else {
        dispatch(changePreQualInfoValue('userEmailAlreadyExist', false));
        dispatch(greenskyValidateEmail(email, dealerId, isCoApplicant, coAppValues));
      }
    })
    .catch(error => {
      dispatch(endLoading());
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
};

export const greenskyValidateMerchant = (
  dealerId,
  dealerUserId,
  history,
  greenskyMerchantNumber,
) => async dispatch => {
  dispatch(changeSoftPullValue('trainingData', null));
  dispatch(startLoading('Validating Merchant...'));
  await axios
    .get(
      `${baseURL}/api/soft_pull/greensky/validate_merchant?dealer_id=${encodeURIComponent(
        dealerId,
      )}&dealer_user_id=${encodeURIComponent(dealerUserId)}`,
    )
    .then(response => {
      if (response.data) {
        if (response.data.trainingRequired && response.data.trainingAvailable) {
          dispatch(changeSoftPullValue('trainingData', response.data));
          history.push('/greensky-invalid-merchant');
        }
        if (!response.data.trainingRequired && response.data.trainingAvailable) {
          if (dealerUserId !== 0) {
            dispatch(changeSoftPullValue('trainingData', response.data));
            history.push('/greensky-invalid-merchant');
          }
        }
      }
    })
    .catch(error => {
      if (error.response && error.response.data) {
        if (error.response.data.internalError === 'API_ERROR') {
          dispatch(
            addNotification({
              message: handleApiError(`MID ${greenskyMerchantNumber} requires an API Key.`),
              messageType: 'Error',
            }),
          );
        } else {
          dispatch(
            addNotification({
              message: handleApiError(error),
              messageType: 'Error',
            }),
          );
        }
      }

      if (dealerUserId === 0) {
        history.push('/login');
      } else {
        history.push('/portal/dashboard');
      }
    });
  dispatch(endLoading());
};
