import React from 'react';
import CoApplicantAddress from './CoApplicant/CoApplicantAddress';
import CoApplicantGrossAnnualIncome from './CoApplicant/CoApplicantGrossAnnualIncome';
import CoApplicantInformation from './CoApplicant/CoApplicantInformation';
import ContactInformation from './ContactInformation';
import GrossAnnualIncome from './GrossAnnualIncome';
import HasCoApplicant from './HasCoApplicant';
import IdentificationInformation from './IdentificationInformation';
import LeasingOffers from './LeasingOffers';
import LoanAmount from './LoanAmount';
import PreQualified from './PreQualified';
import Review from './Review';
import FinalReview from './FinalReview';

const leasePurchaseTitle = `KwikComfort Simplicity Application`;

const RheemFlow = [
  // 1
  <PreQualified title={leasePurchaseTitle} />,
  // 2
  <LoanAmount title={leasePurchaseTitle}>
    <h4>Enter the price quoted for this product or service.</h4>
  </LoanAmount>,
  // 3
  <GrossAnnualIncome title={leasePurchaseTitle}>
    <h4>What is your gross annual income (or benefits) amount?</h4>
  </GrossAnnualIncome>,
  // 4
  <HasCoApplicant title={leasePurchaseTitle}>
    <h4>Would you like to add a Co-Applicant?</h4>
    <p>
      A co-applicant is <u>not</u> required.
    </p>
  </HasCoApplicant>,
  // 5
  <CoApplicantInformation title={leasePurchaseTitle}>
    <h4>Co-Applicant, enter your name and contact information.</h4>
  </CoApplicantInformation>,
  // 6
  <CoApplicantAddress title={leasePurchaseTitle}>
    <h4>Co-Applicant, enter your address.</h4>
  </CoApplicantAddress>,
  // 7
  <CoApplicantGrossAnnualIncome title={leasePurchaseTitle}>
    <h4>Co-Applicant, enter your gross annual income (or benefits) amount.</h4>
  </CoApplicantGrossAnnualIncome>,
  // 8
  <IdentificationInformation title={leasePurchaseTitle}>
    <h4>Please provide your identification information.</h4>
    <p>
      We need additional identification information due to federal laws and protections. You may
      have provided the last 4 digits of your Social Security Number together with your date of
      birth earlier in this application, which was used solely for a “soft pull” of your credit
      profile to try to prequalify you for our lease purchase option. This should not have had any
      impact on your credit score. Providing your full Social Security Number here will help further
      verify your identity.
    </p>
  </IdentificationInformation>,
  // 9
  <Review title={leasePurchaseTitle}>
    <h4>Let’s double check the information you’ve provided.</h4>
  </Review>,
  // 10
  <LeasingOffers title={leasePurchaseTitle}>
    <p className="text-justify">
      Payment amounts are estimates and will be finalized once the quote has been verified.
    </p>
    <p className="text-justify">
      <b>Important Information</b>
    </p>
    <p className="text-justify">
      <b>This is a Lease Purchase Transaction:</b> You are enrolling in a lease purchase
      transaction, rather than financing.
    </p>
    <p className="text-justify">
      <b>To exercise the Lease Purchase option, make the payment each month:</b> The term of the
      agreement is 1 month. After that, while you have the item, the Agreement renews for additional
      1 month terms, until you own the item or surrender it to us. You may surrender the item at any
      time to terminate the Agreement, without paying a penalty.
    </p>
    <p className="text-justify">
      <b>Restrictions, Limitations, and Conditions:</b> The “Total Cost to Own” does not include
      other charges such as late fees and reinstatement fees.  You will own the item(s) once the
      total of payments to ownership is paid in full or is paid by prepayment.
    </p>
    <p className="text-justify">
      <b>Maintenance and Repairs:</b> We will, at no cost to you, maintain the leased equipment in
      good working condition while it is being leased. We will provide all necessary service, repair
      or replacement in home if you notify us by phone or in writing that service is needed.  Refer
      to the Agreement for details.
    </p>
    <p className="text-justify">
      <b>Early Purchase Option:</b> Exercising the Early Purchase Option available in your Lease
      Purchase Agreement may result in savings of up to 40% or more off your remaining Total Cost to
      Own. Early Purchase Options vary by state. For details, call us at 1-866-480-5945 or email
      kwikcomfort_info@microf.com.
    </p>
  </LeasingOffers>,
  // 11
  <FinalReview title={leasePurchaseTitle} />,
  // 12
  <ContactInformation title="Account Details" />,
];

export default RheemFlow;

export const RheemFlowPageNumber = {
  PreQualified: 0,
  LoanAmount: 1,
  GrossAnnualIncome: 2,
  HasCoApplicant: 3,
  CoApplicantInformation: 4,
  CoApplicantAddress: 5,
  CoApplicantGrossAnnualIncome: 6,
  IdentificationInformation: 7,
  Review: 8,
  LeasingOffers: 9,
  FinalReview: 10,
  ContactInformation: 11,
};
