import { Field } from 'formik';
import React, { useState } from 'react';
import { GoInfo } from 'react-icons/go';
import { Col, FormGroup, Label, Row, Tooltip } from 'reactstrap';
import InputFeedback from '../InputFeedback/InputFeedback';

const SystemTypeSelectWithCustomOnChange = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched, setFieldValue },
  id,
  label,
  className,
  fieldClassName,
  labelClassName,
  selectFirstName,
  selectValues,
  handleChange,
  disabled,
  tooltipText,
  ...props
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <FormGroup className={className}>
      <Label for={id} className={labelClassName}>
        <b>{label}</b>
      </Label>
      <Row className="align-items-center">
        <Col>
          <Field
            component="select"
            id={id}
            name={name}
            className={`form-control ${fieldClassName}`}
            onChange={e => {
              handleChange(e);
              setFieldValue(name, e.target.value);
            }}
            disabled={disabled}
          >
            <option value="">{selectFirstName}</option>
            {Object.keys(selectValues).map(key => (
              <option key={key} value={selectValues[key].value}>
                {selectValues[key].description}
              </option>
            ))}
          </Field>
        </Col>
        <Col xs="auto" className="pl-0">
          <div id={`tooltip-${id}`} className="cursor-pointer">
            <GoInfo size={20} />
          </div>
        </Col>
      </Row>
      {touched[name] && <InputFeedback error={errors[name]} />}
      <Tooltip
        placement="right"
        target={`tooltip-${id}`}
        isOpen={tooltipOpen}
        toggle={toggleTooltip}
        autohide={false}
      >
        {tooltipText ? tooltipText : `Please insert '${label}'`}
      </Tooltip>
    </FormGroup>
  );
};

export default SystemTypeSelectWithCustomOnChange;
