import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isPowerPayApproved, isPowerPayDeclined } from '../../../assets/js/ApplicationStatusEnum';
import {
  setFlow,
  submitMindfire,
  updateSelectedFinancier,
  resetState,
  changePageTitle,
  mapPowerPayToState,
} from '../../../redux/actions/index';
import { changeMicrofInfoValue } from '../../../redux/actions/microfActions';
import Approved from './Approved';
import Declined from './Declined';
import Pending from './Pending';

const ApplicationResult = props => {
  const { status } = props;

  if (isPowerPayApproved(status)) {
    return <Approved {...props} />;
  } else if (isPowerPayDeclined(status)) {
    return <Declined {...props} />;
  } else {
    return <Pending {...props} />;
  }
};

const mapStateToProps = state => ({
  consumerToken:
    state.softPull.applicationSubmitDTO && state.softPull.applicationSubmitDTO.consumerToken,
  status: state.softPull.applicationSubmitDTO && state.softPull.applicationSubmitDTO.status,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  powerPayInformation: state.powerPayInformation,
});

const mapDispatchToProps = dispatch => ({
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchUpdateSelectedFinancier: (selectedFinancier, applicationId, successCallBack) =>
    dispatch(updateSelectedFinancier(selectedFinancier, applicationId, successCallBack)),
  dispatchSetFlow: flowName => dispatch(setFlow(flowName)),
  dispatchChangeMicrofInfoValue: (key, value) => dispatch(changeMicrofInfoValue(key, value)),
  dispatchResetState: props => dispatch(resetState(props)),
  dispatchChangePageTitle: value => dispatch(changePageTitle(value)),
  dispatchMapPowerPayToState: () => dispatch(mapPowerPayToState()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ApplicationResult),
);
