import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import ownership from '../../../../assets/js/OwnershipEnum';
import USStateEnum from '../../../../assets/js/USStateEnum';
import CurrencyFormat from '../../../../components/UI/Form/NumberFormat/CurrencyFormat';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import Input from '../../../../components/UI/Form/Input/CustomInput';
import Select from '../../../../components/UI/Form/Select/Select';
import { reactGAEvent, reactGAPageview } from '../../../../helper';
import { changePage, submitMindfire, changeFoundationInfoValue } from '../../../../redux/actions';
import { FoundationFlowPageNumber } from './FoundationFlow';
import {
  CITY_TOOLTIP_TEXT,
  OWNERSHIP_TOOLTIP_TEXT,
  PROPERTY_MONTHLY_PAYMENT_TOOLTIP_TEXT,
  PROPERTY_MONTHS_TOOLTIP_TEXT,
  PROPERTY_YEARS_TOOLTIP_TEXT,
  STATE_TOOLTIP_TEXT,
  STREET_TOOLTIP_TEXT,
  UNIT_NUMBER_TOOLTIP_TEXT,
  ZIPCODE_TOOLTIP_TEXT,
} from '../../../../constants/tooltipTexts';

const PropertyDetails = props => {
  const {
    email,
    dispatchChangePage,
    dispatchChangeFoundationInfoValue,
    children,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    applicantOwnership,
    applicantPropertyYears,
    applicantPropertyMonths,
    applicantPropertyMonthlyPayment,
    applicantStreet,
    applicantApartmentNumber,
    applicantCity,
    applicantState,
    applicantZipcode,
    isInstallSameAddress,
    pqi,
    foundationInformation,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_fdinstalladdress',
      trackerNames: [],
      title: 'ca_fdinstalladdress',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const setIsSameAddress = bool => {
    dispatchChangeFoundationInfoValue('isInstallSameAddress', bool);
  };

  const PropertyDetailsForm = () => (
    <Form>
      <Row>
        <Col sm="6">
          <Button
            className={isInstallSameAddress ? '' : 'inactive'}
            type="button"
            title="Yes"
            clickHandler={() => setIsSameAddress(true)}
          />
        </Col>
        <Col sm="6">
          <Button
            className={!isInstallSameAddress ? '' : 'inactive'}
            type="button"
            title="No"
            clickHandler={() => setIsSameAddress(false)}
          />
        </Col>
      </Row>
      <Field
        disabled={isInstallSameAddress}
        component={Input}
        label="Street"
        name="applicantStreet"
        id="applicantStreet"
        tooltipText={STREET_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={Input}
        label="Unit/Apt. Number"
        name="applicantApartmentNumber"
        id="applicantApartmentNumber"
        tooltipText={UNIT_NUMBER_TOOLTIP_TEXT}
        type="text"
      />
      <Row>
        <Col sm="6">
          <Field
            disabled={isInstallSameAddress}
            component={Input}
            label="City"
            name="applicantCity"
            id="applicantCity"
            tooltipText={CITY_TOOLTIP_TEXT}
            type="text"
          />
        </Col>
        <Col sm="6">
          <Field
            disabled={isInstallSameAddress}
            component={Select}
            label="State"
            name="applicantState"
            id="applicantState"
            tooltipText={STATE_TOOLTIP_TEXT}
            type="select"
            selectValues={USStateEnum}
            selectFirstName="Select State"
          />
        </Col>
      </Row>
      <Field
        disabled={isInstallSameAddress}
        component={Input}
        label="ZIP Code"
        name="applicantZipcode"
        id="applicantZipcode"
        tooltipText={ZIPCODE_TOOLTIP_TEXT}
        type="text"
        maxLength={10}
      />
      <Field
        disabled={isInstallSameAddress}
        component={Select}
        label="Ownership"
        name="applicantOwnership"
        id="applicantOwnership"
        tooltipText={OWNERSHIP_TOOLTIP_TEXT}
        type="select"
        selectValues={ownership}
        selectFirstName="Select One"
      />
      <Field
        disabled={isInstallSameAddress}
        component={CurrencyFormat}
        placeholder="$0"
        label="Monthly Payment (if no payment enter $0)"
        name="applicantPropertyMonthlyPayment"
        id="applicantPropertyMonthlyPayment"
        tooltipText={PROPERTY_MONTHLY_PAYMENT_TOOLTIP_TEXT}
        type="text"
      />
      <Row className="mt-2">
        <Col>
          <Label>
            <b>Time at address</b>
          </Label>
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          <Field
            disabled={isInstallSameAddress}
            component={Input}
            placeholder="Years"
            formGroupClass="mt-0 mb-2"
            labelClassName="d-none"
            name="applicantPropertyYears"
            id="applicantPropertyYears"
            tooltipText={PROPERTY_YEARS_TOOLTIP_TEXT}
            type="number"
            min={0}
          />
        </Col>
        <Col sm="6">
          <Field
            disabled={isInstallSameAddress}
            component={Input}
            placeholder="Months"
            formGroupClass="mt-0 mb-2"
            labelClassName="d-none"
            name="applicantPropertyMonths"
            id="applicantPropertyMonths"
            tooltipText={PROPERTY_MONTHS_TOOLTIP_TEXT}
            type="number"
            min={0}
          />
        </Col>
      </Row>
      <Button type="submit" title="Next" />
    </Form>
  );

  const PropertyDetailsFormFormik = withFormik({
    mapPropsToValues: () => ({
      applicantStreet: isInstallSameAddress && pqi.street ? pqi.street : applicantStreet || '',
      applicantApartmentNumber: applicantApartmentNumber || '',
      applicantCity: isInstallSameAddress && pqi.city ? pqi.city : applicantCity || '',
      applicantState: isInstallSameAddress && pqi.state ? pqi.state : applicantState || '',
      applicantZipcode: isInstallSameAddress && pqi.zipcode ? pqi.zipcode : applicantZipcode || '',
      applicantOwnership:
        isInstallSameAddress && foundationInformation.ownership !== null
          ? foundationInformation.ownership
          : applicantOwnership || '',
      applicantPropertyMonthlyPayment:
        isInstallSameAddress && foundationInformation.propertyMonthlyPayment !== null
          ? foundationInformation.propertyMonthlyPayment
          : applicantPropertyMonthlyPayment || '',
      applicantPropertyYears:
        isInstallSameAddress && foundationInformation.propertyYears !== null
          ? foundationInformation.propertyYears
          : applicantPropertyYears || '',
      applicantPropertyMonths:
        isInstallSameAddress && foundationInformation.propertyMonths !== null
          ? foundationInformation.propertyMonths
          : applicantPropertyMonths || '',
    }),

    validationSchema: Yup.object({
      applicantStreet: Yup.string().required('Please enter a street.'),
      applicantCity: Yup.string().required('Please enter a city.'),
      applicantZipcode: Yup.string()
        .matches(/^\d{5}?$/, {
          message: 'Invalid zip code.',
          excludeEmptyString: true,
        })
        .required('Please enter a zip code.'),
      applicantState: Yup.string().required('Please select a state.'),
      applicantOwnership: Yup.string().required('Please select an option.'),
      applicantPropertyMonthlyPayment: Yup.number().required('Please enter your monthly payment.'),
      applicantPropertyYears: Yup.number()
        .moreThan(-1, 'Please enter a positive number.')
        .integer('Please enter an integer number.')
        .max(70, 'Must be less than or equal to 70.')
        .required('Please enter years.'),
      applicantPropertyMonths: Yup.number()
        .moreThan(-1, 'Please enter a positive number.')
        .integer('Please enter an integer number.')
        .max(11, 'Must be less than or equal to 11.')
        .required('Please enter months.'),
    }),

    handleSubmit: async values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Continue',
        did,
        dealerUserId,
      });

      dispatchChangeFoundationInfoValue('applicantStreet', values.applicantStreet);
      dispatchChangeFoundationInfoValue(
        'applicantApartmentNumber',
        values.applicantApartmentNumber,
      );
      dispatchChangeFoundationInfoValue('applicantCity', values.applicantCity);
      dispatchChangeFoundationInfoValue('applicantState', values.applicantState);
      dispatchChangeFoundationInfoValue('applicantZipcode', values.applicantZipcode);
      dispatchChangeFoundationInfoValue('applicantOwnership', values.applicantOwnership);
      dispatchChangeFoundationInfoValue(
        'applicantPropertyMonthlyPayment',
        values.applicantPropertyMonthlyPayment,
      );
      dispatchChangeFoundationInfoValue('applicantPropertyYears', values.applicantPropertyYears);
      dispatchChangeFoundationInfoValue('applicantPropertyMonths', values.applicantPropertyMonths);
      dispatchSubmitMindfire({
        email: email,
        lastPage: '10013',
        dealerUserId: dealerUserId,
        did: did,
      });

      dispatchChangePage(FoundationFlowPageNumber.AppEmploymentDetails);
    },
  })(PropertyDetailsForm);

  return (
    <Container>
      <Row>
        <BackBtn changePage={dispatchChangePage} page={FoundationFlowPageNumber.PropertyDetails} />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <PropertyDetailsFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  applicantStreet: state.foundationInformation.applicantStreet,
  applicantApartmentNumber: state.foundationInformation.applicantApartmentNumber,
  applicantCity: state.foundationInformation.applicantCity,
  applicantState: state.foundationInformation.applicantState,
  applicantZipcode: state.foundationInformation.applicantZipcode,
  applicantOwnership: state.foundationInformation.applicantOwnership,
  applicantPropertyMonthlyPayment: state.foundationInformation.applicantPropertyMonthlyPayment,
  applicantPropertyYears: state.foundationInformation.applicantPropertyYears,
  applicantPropertyMonths: state.foundationInformation.applicantPropertyMonths,
  isInstallSameAddress: state.foundationInformation.isInstallSameAddress,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  pqi: state.preQualificationInformation,
  foundationInformation: state.foundationInformation,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeFoundationInfoValue: (key, value) =>
    dispatch(changeFoundationInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PropertyDetails);
