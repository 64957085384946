import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  changePage,
  submitMindfire,
  changeGreenSkyApplicationInfoValue,
} from '../../../../redux/actions/index';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import { reactGAPageview } from '../../../../helper';
import { AtWaterGreenSkyFlowPageNumber } from './GreenSkyFlow';

const ApplicantMarried = props => {
  const {
    email,
    dispatchChangePage,
    dispatchChangeGreenSkyApplicationInfoValue,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    isAppMarried,
    isCoAppSpouse,
    isReview,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_gsapplicantmarried',
      trackerNames: [],
      title: 'ca_gsapplicantmarried',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);
  return (
    <Container>
      <Row>
        <Col>
          <Row>
            <BackBtn
              changePage={dispatchChangePage}
              page={
                isReview
                  ? AtWaterGreenSkyFlowPageNumber.Review
                  : AtWaterGreenSkyFlowPageNumber.AppGrossAnnualIncome
              }
            />
            <Col sm="5">
              <h4>Are you married?</h4>
            </Col>
            <Col sm="6">
              <Row>
                <Col sm="6">
                  <Button
                    title="Yes"
                    className={
                      'mt-3 ' +
                      (isAppMarried === null ? 'inactive' : isAppMarried ? '' : 'inactive')
                    }
                    clickHandler={() => {
                      dispatchChangeGreenSkyApplicationInfoValue('isAppMarried', true);
                    }}
                  />
                </Col>
                <Col sm="6">
                  <Button
                    className={
                      'mt-3 ' +
                      (isAppMarried === null ? 'inactive' : !isAppMarried ? '' : 'inactive')
                    }
                    title="No"
                    clickHandler={() => {
                      dispatchChangeGreenSkyApplicationInfoValue('isAppMarried', false);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col />
            <Col sm="5">
              <h4>Is your spouse the Co-Applicant?</h4>
            </Col>
            <Col sm="6">
              <Row>
                <Col sm="6">
                  <Button
                    className={
                      'mt-3 ' +
                      (isCoAppSpouse === null ? 'inactive' : isCoAppSpouse ? '' : 'inactive')
                    }
                    type="button"
                    title="Yes"
                    clickHandler={() => {
                      dispatchChangeGreenSkyApplicationInfoValue('isCoAppSpouse', true);
                      dispatchChangeGreenSkyApplicationInfoValue('isAppMarried', true);
                      dispatchChangeGreenSkyApplicationInfoValue('hasCoApp', true);
                    }}
                  />
                </Col>
                <Col sm="6">
                  <Button
                    className={
                      'mt-3 ' +
                      (isCoAppSpouse === null ? 'inactive' : !isCoAppSpouse ? '' : 'inactive')
                    }
                    type="button"
                    title="No"
                    clickHandler={() => {
                      dispatchChangeGreenSkyApplicationInfoValue('isCoAppSpouse', false);
                      dispatchChangeGreenSkyApplicationInfoValue('hasCoApp', false);
                    }}
                  />
                </Col>
              </Row>
              <p
                className="text-danger mt-2 position-absolute"
                hidden={!(isAppMarried && isCoAppSpouse === null)}
              >
                You must select an option.
              </p>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col sm="6" />
            <Col sm="6">
              <Button
                type="button"
                title="Next"
                clickHandler={() => {
                  if (isAppMarried && isCoAppSpouse === null) return;
                  dispatchChangePage(
                    isAppMarried && isCoAppSpouse
                      ? AtWaterGreenSkyFlowPageNumber.CoApplicantName
                      : isAppMarried && !isCoAppSpouse
                      ? AtWaterGreenSkyFlowPageNumber.ApplicantSpouse
                      : AtWaterGreenSkyFlowPageNumber.HasCoApplicant,
                  );
                  dispatchSubmitMindfire({
                    email: email,
                    lastPage: '9014',
                    dealerUserId: dealerUserId,
                    did: did,
                  });
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  isReview: state.greenSkyApplicationInformation.isReview,
  isAppMarried: state.greenSkyApplicationInformation.isAppMarried,
  isCoAppSpouse: state.greenSkyApplicationInformation.isCoAppSpouse,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeGreenSkyApplicationInfoValue: (key, value) =>
    dispatch(changeGreenSkyApplicationInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

ApplicantMarried.propTypes = {
  dispatchChangePage: PropTypes.func,
  dispatchChangeGreenSkyApplicationInfoValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApplicantMarried);
