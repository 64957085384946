import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import relationshipEnum from '../../../assets/js/RelationshipEnum';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import Input from '../../../components/UI/Form/Input/CustomInput';
import CustomPhoneNumber from '../../../components/UI/Form/PhoneNumber/PhoneNumber';
import Select from '../../../components/UI/Form/Select/Select';
import {
  COAPPEMAIL_TOOLTIP_TEXT,
  COAPPFIRSTNAME_TOOLTIP_TEXT,
  COAPPLASTNAME_TOOLTIP_TEXT,
  COAPPMIDDLEINITIAL_TOOLTIP_TEXT,
  COAPPPHONENUMBER_TOOLTIP_TEXT,
  COAPP_RELATIONSHIP_TOOLTIP_TEXT,
} from '../../../constants/tooltipTexts';
import { isNoreplyEmail, reactGAEvent, reactGAPageview } from '../../../helper';
import { changePage, submitMindfire, changeRheemInfoValue } from '../../../redux/actions';
import { RheemFlowPageNumber } from '../RheemFlow';

const CoApplicantInformation = props => {
  const {
    coAppFirstName,
    coAppMiddleInitial,
    coAppLastName,
    coAppPhoneNumber,
    coAppEmail,
    appEmail,
    dispatchChangeRheemInfoValue,
    relationshipWithCoapplicant,
    dispatchChangePage,
    children,
    aquaInformation,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_coappinfo1',
      trackerNames: [],
      title: 'ca_coappinfo1',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const CoApplicantInformationForm = () => {
    return (
      <Form>
        <Field
          component={Select}
          label="Your relationship with the Co-Applicant?"
          name="relationshipWithCoapplicant"
          id="relationshipWithCoapplicant"
          tooltipText={COAPP_RELATIONSHIP_TOOLTIP_TEXT}
          type="select"
          selectValues={relationshipEnum}
          selectFirstName=""
        />
        <Field
          component={Input}
          label="First Name"
          name="coAppFirstName"
          id="coAppFirstName"
          tooltipText={COAPPFIRSTNAME_TOOLTIP_TEXT}
          type="text"
        />
        <Field
          component={Input}
          label="Middle Initial"
          name="coAppMiddleInitial"
          id="coAppMiddleInitial"
          tooltipText={COAPPMIDDLEINITIAL_TOOLTIP_TEXT}
          type="text"
          maxLength={2}
        />
        <Field
          component={Input}
          label="Last Name"
          name="coAppLastName"
          id="coAppLastName"
          tooltipText={COAPPLASTNAME_TOOLTIP_TEXT}
          type="text"
        />
        <Field
          component={CustomPhoneNumber}
          label="Phone Number (Mobile Preferred)"
          name="coAppPhoneNumber"
          id="coAppPhoneNumber"
          tooltipText={COAPPPHONENUMBER_TOOLTIP_TEXT}
        />
        <Field
          component={Input}
          label="Email Address"
          name="coAppEmail"
          id="coAppEmail"
          tooltipText={COAPPEMAIL_TOOLTIP_TEXT}
          type="email"
        />
        <Button type="submit" title="Next" />
      </Form>
    );
  };

  const CoApplicantInformationFormFormik = withFormik({
    mapPropsToValues: () => ({
      relationshipWithCoapplicant:
        relationshipWithCoapplicant || aquaInformation.appCoAppRelationship || '', //make sure data pases from aqua to rheem
      coAppFirstName: coAppFirstName || aquaInformation.coAppFirstName || '',
      coAppMiddleInitial: coAppMiddleInitial || aquaInformation.coAppMiddleInitial || '',
      coAppLastName: coAppLastName || aquaInformation.coAppLastName || '',
      coAppPhoneNumber: coAppPhoneNumber || aquaInformation.coAppPhoneNumber || '',
      coAppEmail: coAppEmail || aquaInformation.coAppEmail || '',
    }),

    validationSchema: Yup.object({
      relationshipWithCoapplicant: Yup.string().required(
        'Please select the relationship with the Co-Applicant.',
      ),
      coAppFirstName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .required("Please enter the Co-Applicant's first name."),
      coAppMiddleInitial: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .max(2, 'Middle initial is too long.'),
      coAppLastName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .required("Please enter the Co-Applicant's last name."),
      coAppPhoneNumber: Yup.string()
        .test(
          'required',
          "Please enter the Co-Applicant's phone number.",
          val => val !== '+' && val,
        )
        .test('len', 'Invalid phone number', val => val && val.match(/\d/g).join('').length === 10),
      coAppEmail: Yup.string()
        .test(
          'sameEmail',
          "The co-applicant's email must be different than the primary applicant's email address.",
          val => val !== appEmail,
        )
        .email('Please enter a valid email.')
        .required('Please enter an email.')
        .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      dispatchChangeRheemInfoValue(
        'relationshipWithCoapplicant',
        values.relationshipWithCoapplicant,
      );
      dispatchChangeRheemInfoValue('coAppFirstName', values.coAppFirstName.trim());
      dispatchChangeRheemInfoValue('coAppMiddleInitial', values.coAppMiddleInitial.trim());
      dispatchChangeRheemInfoValue('coAppLastName', values.coAppLastName.trim());
      dispatchChangeRheemInfoValue(
        'coAppPhoneNumber',
        values.coAppPhoneNumber.match(/\d/g).join(''),
      );
      dispatchChangeRheemInfoValue('coAppEmail', values.coAppEmail);
      dispatchChangePage(RheemFlowPageNumber.CoApplicantAddress);
      dispatchSubmitMindfire({
        email: appEmail,
        // lastPage:  ? '4086' : '0086',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(CoApplicantInformationForm);

  return (
    <Container>
      <Row>
        <BackBtn changePage={dispatchChangePage} page={RheemFlowPageNumber.HasCoApplicant} />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <CoApplicantInformationFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  relationshipWithCoapplicant: state.rheemInformation.relationshipWithCoapplicant,
  coAppFirstName: state.rheemInformation.coAppFirstName,
  coAppMiddleInitial: state.rheemInformation.coAppMiddleInitial,
  coAppLastName: state.rheemInformation.coAppLastName,
  coAppPhoneNumber: state.rheemInformation.coAppPhoneNumber,
  coAppEmail: state.rheemInformation.coAppEmail,
  aquaInformation: state.aquaInformation,
  appEmail: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeRheemInfoValue: (key, value) => dispatch(changeRheemInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

CoApplicantInformation.propTypes = {
  relationshipWithCoapplicant: PropTypes.string,
  coAppFirstName: PropTypes.string,
  coAppMiddleInitial: PropTypes.string,
  coAppLastName: PropTypes.string,
  coAppPhoneNumber: PropTypes.string,
  coAppEmail: PropTypes.string,
  appEmail: PropTypes.string,
  aquaInformation: PropTypes.object,
  dispatchChangePage: PropTypes.func,
  dispatchChangeRheemInfoValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CoApplicantInformation);
