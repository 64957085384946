import { Field, Form, withFormik } from 'formik';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import SimpleCheckbox from '../../../components/UI/Form/Checkbox/SimpleCheckbox';
import { reactGAEvent, reactGAPageview } from '../../../helper';
import {
  changePage,
  changeEnerBankInfoValue,
  submitMindfire,
  submitEnerBankApplication,
  resetState,
  resetNewAppState,
} from '../../../redux/actions/index';
import selectedFinancierEnum from '../../../assets/js/SelectedFinancierEnum';
import { EnerBankFlowPageNumber } from './EnerBankFlow';
import { FaInfoCircle } from 'react-icons/fa';
import { getDealerPortalURL } from '../../../config/settings';
import Label from 'reactstrap/lib/Label';
import {
  COAPP_CONSENT_TOOLTIP_TEXT,
  PRIMARY_APPLICANT_CONSENT_TOOLTIP_TEXT,
} from '../../../constants/tooltipTexts';

const ApplicationReview = props => {
  const {
    dispatchSubmitApplication,
    children,
    dispatchChangePage,
    dispatchChangeInfoValue,
    pqi,
    information,
    softPull,
    dealerUserId,
    did,
    user,
    app,
    emulatorId,
    track_id,
    applicantAddress,
    dispatchSubmitMindfire,
    consumerId,
    dispatchResetState,
    dispatchResetNewAppState,
    history,
  } = props;

  const isUserLoggedIn = !did && user && user.dealerUser;
  const returnUrl = isUserLoggedIn ? '/portal/dashboard' : getDealerPortalURL();

  const mindfireRequest = {
    email: pqi.email,
    lastPage: '11024',
    dealerUserId: dealerUserId,
    did: did,
  };

  const handleCancelApplication = () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Cancel Application',
      did,
      dealerUserId,
    });
    dispatchSubmitMindfire(mindfireRequest);
    if (!isUserLoggedIn) {
      dispatchResetState(props);
      window.location.href = returnUrl;
    } else {
      dispatchResetNewAppState();
      history.push(returnUrl);
    }
  };

  useEffect(() => {
    reactGAPageview({
      path: '/ca_ebapplicationsubmit',
      trackerNames: [],
      title: 'ca_ebapplicationsubmit',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const ApplicationReviewForm = () => (
    <Form>
      <div className="info">
        <h5>Patriot Act Disclosure</h5>
        <p>
          To help the government fight the funding of terrorism and money laundering activities,
          Federal law requires all financial institutions to obtain, verify and record information
          that identifies each person who opens an account.
        </p>
        <p>
          What this means for you: when you open an account, we will ask for your name, address,
          date of birth, and other information that will allow us to identify you. We may also ask
          to see your driver's license or other identifying documents.
        </p>
        <h5>Ready to Apply?</h5>
        <p>
          I certify and agree that: (a) I am providing the information herein to EnerBank USA ("the
          Bank") in order to apply for a loan; (b) the information provided is true, correct, and
          complete to the best of my knowledge; (c) the Bank is authorized to verify the information
          provided in this application and to make any investigations regarding such information
          which may include, without limitation, obtaining a consumer credit report; (d) I am
          applying for the loan on my own behalf and not on behalf of any other person; (e) I give
          the Bank permission to share my application information and credit decision with my
          contractor and its loan program sponsor; and (f) that I am a U.S. resident.
        </p>
        <p>California Residents: If married, you may apply for a separate account.</p>
        <div className="mt-4">
          CONSENT&nbsp;&nbsp;&nbsp;&nbsp;
          <FaInfoCircle />
        </div>
        <Label className="bold mt-3">Primary Applicant</Label>
        <Field
          component={SimpleCheckbox}
          name="applicantConsent"
          id="applicantConsent"
          tooltipText={PRIMARY_APPLICANT_CONSENT_TOOLTIP_TEXT}
          label="I have read and agree to all consents and agreements. If applying with a co-applicant, I acknowledge my intent for applying for joint credit."
          labelClassName="ml-4"
          className="ml-0"
          clickHandler={() => {
            dispatchChangeInfoValue('applicantConsent', true);
          }}
        />
        {information.hasCoApp && (
          <>
            <Label className="bold mt-3">Co-Applicant</Label>
            <Field
              component={SimpleCheckbox}
              name="coApplicantConsent"
              id="coApplicantConsent"
              tooltipText={COAPP_CONSENT_TOOLTIP_TEXT}
              label="I have read and agree to all consents and agreements. If applying as a co-applicant, I acknowledge my intent for applying for joint credit."
              labelClassName="ml-4"
              className="ml-0"
              clickHandler={() => {
                dispatchChangeInfoValue('coApplicantConsent', true);
              }}
            />
          </>
        )}
        <div className="mt-5 text-center">
          <h5>
            By clicking on the Submit Loan Application button we take in your information and come
            back with a result and next steps for you.
          </h5>
        </div>
      </div>
      <Button type="submit" title="Submit Loan Application" />
      <Button
        type="reset"
        color="secondary"
        title="Cancel Application"
        className="mt-2"
        clickHandler={handleCancelApplication}
      />
    </Form>
  );

  const ApplicationReviewFormFormik = withFormik({
    mapPropsToValues: () => ({
      applicantConsent: information.applicantConsent || false,
      coApplicantConsent: information.coApplicantConsent || false,
    }),

    validationSchema: Yup.object({
      applicantConsent: Yup.boolean().oneOf([true], 'Please check to continue.'),
      coApplicantConsent: information.hasCoApp
        ? Yup.boolean().oneOf([true], 'Please check to continue.')
        : Yup.boolean(),
    }),

    handleSubmit: () => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Find the best financing option',
        did,
        dealerUserId,
      });
      dispatchChangeInfoValue('submited', true);
      const softPullRequest = {
        consumerId: consumerId,
        email: softPull.softPullResponse.email || null,
        borrowedAmount:
          information.borrowedAmount !== null ? Math.floor(information.borrowedAmount) : null,
        firstName: softPull.softPullResponse.firstName || null,
        lastName: softPull.softPullResponse.lastName || null,
        middleName: softPull.softPullResponse.middleName || null,
        phoneNumber: softPull.softPullResponse.phoneNumber || null,
        street: softPull.softPullResponse.street || null,
        city: softPull.softPullResponse.city || null,
        zipcode: softPull.softPullResponse.zipcode || null,
        state: softPull.softPullResponse.state || null,
        poBox: pqi.poBox || null,
        applicantStreet: applicantAddress ? applicantAddress.street : null,
        applicantCity: applicantAddress ? applicantAddress.city : null,
        applicantZip: applicantAddress ? applicantAddress.zip : null,
        applicantState: applicantAddress ? applicantAddress.state : null,
        birthday: softPull.softPullResponse.birthday || null,
        ssnLastDigits: softPull.softPullResponse.ssnLastDigits || null,
        airConditioningSystem: softPull.softPullResponse.airConditioningSystem,
        isHomeowner: softPull.softPullResponse.isHomeowner || pqi.isHomeowner,

        dealerUserId: dealerUserId || null,
        prequalificationId: softPull.softPullResponse.prequalificationId || null,
        ssn: information.appFullSsn,

        appGrossAnnualIncome: information.appGrossAnnualIncome,
        propertyType: information.propertyType,

        coAppGrossAnnualIncome: information.hasCoApp ? information.coAppGrossAnnualIncome : null,
        coapplicantSsn: information.hasCoApp ? information.coAppFullSsn : null,

        relationshipWithCoapplicant: information.hasCoApp
          ? information.relationshipWithCoapplicant || null
          : null,
        coapplicantFirstName: information.hasCoApp ? information.coAppFirstName || null : null,
        coapplicantLastName: information.hasCoApp ? information.coAppLastName || null : null,
        coapplicantMiddleName: information.hasCoApp ? information.coAppMiddleInitial || null : null,
        coapplicantPhoneNumber: information.hasCoApp ? information.coAppPhoneNumber || null : null,
        coapplicantEmail: information.hasCoApp ? information.coAppEmail || null : null,
        coapplicantStreet: information.hasCoApp ? information.coAppStreet || null : null,
        coapplicantCity: information.hasCoApp ? information.coAppCity || null : null,
        coapplicantZipcode: information.hasCoApp ? information.coAppZipCode || null : null,
        coapplicantState: information.hasCoApp ? information.coAppState || null : null,
        coapplicantBirthday: information.hasCoApp ? information.coAppBirthday || null : null,

        selectedFinancier: selectedFinancierEnum.ENERBANK.value,
        emulatorId: emulatorId,
        track_id: track_id,

        mailingAddressStreet: information.mailingAddress.street || null,
        mailingAddressCity: information.mailingAddress.city || null,
        mailingAddressZipcode: information.mailingAddress.zipcode || null,
        mailingAddressState: information.mailingAddress.state || null,
        mailingAddressApartmentNumber: information.mailingAddress.apartmentNumber || null,
        enerBankOwnership: information.ownership || null,
        mortgage: information.mortgage === null ? null : information.mortgage,
        programPhoneNumber: information.programPhoneNumber || null,
        loanCode: information.loanCode || null,
        contractorId: information.contractorId || null,
        projectType:
          (information.programProjectTypes &&
            Object.values(information.programProjectTypes)?.find(
              obj => obj.name === information.projectType,
            )?.projectTypeId) ||
          information.projectTypeId ||
          null,
        coApplicantType: information.coApplicantType || null,

        deliveryMethod: information.deliveryMethod || null,
        communicationMethod: information.communicationMethod || null,
        notificationPhoneNumber: information.notificationPhoneNumber || null,
      };

      dispatchSubmitApplication(softPullRequest, EnerBankFlowPageNumber.ApplicationResult, app);
      dispatchSubmitMindfire(mindfireRequest);
    },
  })(ApplicationReviewForm);

  return (
    <Container>
      <Row>
        <BackBtn changePage={dispatchChangePage} page={EnerBankFlowPageNumber.NotificationMethod} />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <ApplicationReviewFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  consumerId: state.auth.consumerId,
  aquaFinanceAssigned: state.auth.aquaFinanceAssigned,
  applicantAddress: state.enerBankInformation.applicantAddress,
  information: state.enerBankInformation,
  pqi: state.preQualificationInformation,
  softPull: state.softPull,
  app: state.softPull.applicationSubmitDTO,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  emulatorId: state.auth.emulatorId,
  track_id: state.auth.track_id,
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeInfoValue: (key, value) => dispatch(changeEnerBankInfoValue(key, value)),
  dispatchSubmitApplication: (params, page, app) =>
    dispatch(submitEnerBankApplication(params, page, app)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchResetState: props => dispatch(resetState(props)),
  dispatchResetNewAppState: () => dispatch(resetNewAppState()),
});

ApplicationReview.propTypes = {
  dispatchChangePage: PropTypes.func,
  dispatchChangeInfoValue: PropTypes.func,
  dispatchSubmitSoftPullAquaApplication: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ApplicationReview),
);
