import React from 'react';

const Logo = ({ isSpectrumLogo, className }) => (
  <img
    className={`${(isSpectrumLogo ? 'spectrum-logo' : 'logo') +
      (className ? ' ' + className : '')}`}
    alt="logo"
    title="logo"
  />
);

export default Logo;
