import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Input } from 'reactstrap';
import Button from '../../components/UI/Button/CustomButton';
import { consumerUrl, orbitUrl } from '../../redux/actions/api';
import { reactGAEvent } from '../../helper';
import { submitMindfire } from '../../redux/actions';

const AquaFinanceLogin = props => {
  const {
    samlResponse,
    applicationNumber,
    dispatchSubmitMindfire,
    dealerUserId,
    did,
    email,
  } = props;
  const [disabled, setDisabled] = useState(false);
  const handleSubmit = event => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Aqua Finance',
      did,
      dealerUserId,
    });
    dispatchSubmitMindfire({
      email: email,
      lastPage: '0062',
      dealerUserId: dealerUserId,
      did: did,
    });
    if (disabled) {
      return;
    }
    setDisabled(true);
  };

  return (
    <form
      id="submitLogin"
      method="post"
      action={consumerUrl}
      target="_blank"
      onSubmit={handleSubmit}
    >
      <Input type="hidden" name="RelayState" value={`${orbitUrl}/${applicationNumber}/detail`} />
      <Input type="hidden" name="SAMLResponse" value={samlResponse} />
      <Button type="submit" title="Aqua Finance" disabled={disabled} />
    </form>
  );
};

const mapStateToProps = state => ({
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  applicationNumber: state.softPull.applicationSubmitDTO.aquaApplicationNumber,
  samlResponse: state.aquaInformation.samlResponse,
});

const mapDispatchToProps = dispatch => ({
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AquaFinanceLogin),
);
