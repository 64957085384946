import { Field } from 'formik';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Col, Label, Row } from 'reactstrap';
import InputField from '../../../../components/UI/Form/Input/CustomInput';
import TextArea from '../../../../components/UI/Form/TextArea/TextArea';
import {
  SYSTEMDESCRIPTION_TOOLTIP_TEXT,
  SYSTEMMAKE_TOOLTIP_TEXT,
  SYSTEMMODEL_TOOLTIP_TEXT,
  SYSTEMSERIALNUMBER_TOOLTIP_TEXT,
} from '../../../../constants/tooltipTexts';

const EquipmentRow = ({ index }) => {
  return (
    <>
      <Row className="mt-4">
        <Col className="d-flex flex-column justify-content-center no-padding" sm="1">
          <Label className="mb-0 pt-1">
            <b>Unit {index + 1}</b>
          </Label>
        </Col>
        <Col sm="3" className={isMobile ? 'no-padding' : ''}>
          <Field
            fieldClassName="h-35p"
            formGroupClass="mt-0"
            labelClassName="hidden"
            component={InputField}
            name={`systemMake${index}`}
            id={`systemMake${index}`}
            tooltipText={SYSTEMMAKE_TOOLTIP_TEXT}
            type="text"
            placeholder="Brand..."
            maxLength={50}
          />
        </Col>
        <Col sm="3" className={isMobile ? 'no-padding' : ''}>
          <Field
            className="h-35p"
            formGroupClass="mt-0"
            labelClassName="hidden"
            component={InputField}
            name={`systemModel${index}`}
            id={`systemModel${index}`}
            tooltipText={SYSTEMMODEL_TOOLTIP_TEXT}
            type="text"
            placeholder="Model..."
            maxLength={50}
          />
        </Col>
        <Col sm="5" className={isMobile ? 'no-padding' : ''}>
          <Field
            className="h-35p"
            formGroupClass="mt-0"
            labelClassName="hidden"
            component={InputField}
            name={`systemSerialNumber${index}`}
            id={`systemSerialNumber${index}`}
            tooltipText={SYSTEMSERIALNUMBER_TOOLTIP_TEXT}
            type="text"
            placeholder="Serial number..."
            maxLength={50}
          />
        </Col>
      </Row>
    </>
  );
};

export default EquipmentRow;
