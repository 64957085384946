import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Container, Row } from 'reactstrap';
import selectedFinancierEnum from '../../assets/js/SelectedFinancierEnum';
import {
  changePage,
  mapApplicationToMicrof,
  updateSelectedFinancier,
  changeTheme,
  isThemeMicrof,
} from '../../redux/actions';
import MicrofCheckList from '../Microf/MicrofCheckList';
import { MicrofFlowPageNumber } from '../Microf/MicrofFlow';
import ThemeEnum from '../../assets/js/ThemeEnum';

const RheemDecline = ({
  dispatchChangePage,
  dispatchMapApplicationToMicrof,
  applicationSubmitDTO,
  dispatchUpdateSelectedFinancier,
  dispatchChangeTheme,
  theme,
}) => {
  useEffect(() => {
    if (isThemeMicrof(theme)) {
      dispatchChangeTheme(ThemeEnum.RHEEM.value);
    }
    // eslint-disable-next-line
  }, []);

  const handleClick = () => {
    if (applicationSubmitDTO) {
      const { consumerToken } = applicationSubmitDTO;
      dispatchUpdateSelectedFinancier(selectedFinancierEnum.MICROF.value, consumerToken, () => {
        dispatchMapApplicationToMicrof(applicationSubmitDTO);
      });
    }
    dispatchChangeTheme(ThemeEnum.MICROF.value);
    dispatchChangePage(MicrofFlowPageNumber.AccountEmail);
  };

  return (
    <Container>
      <Row className="justify-content-center mt-2">
        <Col xs="12" md="auto" className="text-center">
          <h6 className="font-weight-bold">
            Unfortunately, Rheem could not offer you a prime lease purchase option. You will receive
            a notification outlining the reason(s) for the decision via email within 30 days
          </h6>
          <h6 className="font-weight-bold">
            But wait! You may still qualify for a lease purchase option with Microf.
          </h6>
        </Col>
      </Row>
      <Row className="justify-content-center readyToFinish bg-transparent">
        <Col xs="12" md="10">
          <MicrofCheckList isRheem={true} />
        </Col>
      </Row>
      <Row className="mt-3 justify-content-center">
        <Col xs="12" md="auto">
          <Button color="primary" onClick={handleClick}>
            Continue to Microf
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  page: state.navi.microfFlowCurrentPage,
  theme: state.theme.theme,
  applicationSubmitDTO: state.softPull.applicationSubmitDTO,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchMapApplicationToMicrof: applicationSubmitDTO =>
    dispatch(mapApplicationToMicrof(applicationSubmitDTO)),
  dispatchUpdateSelectedFinancier: (selectedFinancier, consumerToken, callBack) =>
    dispatch(updateSelectedFinancier(selectedFinancier, consumerToken, callBack)),
  dispatchChangeTheme: theme => dispatch(changeTheme(theme)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RheemDecline);
