import React, { useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { getUnassignedPrequalificationInfoList } from '../../../redux/actions';
import PrequalificationInformationTable from './PrequalificationInformationTable';

const PrequalificationInformation = props => {
  const pageLimit = 20;
  const {
    auth,
    history,
    dispatchGetUnassignedPrequalificationInfoList,
    prequalificationInfoDTOs,
    total,
  } = props;
  const pageCount = total / pageLimit;
  const searchOption = { pageLimit, pageOffset: 0 };

  useEffect(() => {
    if (!auth.user || !auth.user.dealerUser) {
      history.push('/login');
    }
    if (auth.user && auth.user.dealerUser) {
      dispatchGetUnassignedPrequalificationInfoList(searchOption);
    }
    // eslint-disable-next-line
  }, []);

  const handlePageClick = pageNumber => {
    dispatchGetUnassignedPrequalificationInfoList({
      ...searchOption,
      pageOffset: pageNumber.selected * pageLimit,
    });
  };

  return (
    <Col className="pb-3">
      <Row>
        <Col>
          <h1>Prequalification Information Report</h1>
        </Col>
      </Row>
      <Row className="panel prequalification-information">
        <Col>
          {prequalificationInfoDTOs && prequalificationInfoDTOs.length > 0 ? (
            <>
              <Row className="overflow-auto">
                <PrequalificationInformationTable
                  prequalificationInfoDTOs={[...prequalificationInfoDTOs].reverse()}
                  history={history}
                />
              </Row>
              {pageCount > 1 && (
                <Row className="justify-content-between align-items-center mt-4">
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakClassName={'break-me'}
                    breakLinkClassName={'page-link'}
                    breakLabel={'...'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    activeClassName={'active'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                  />
                </Row>
              )}
            </>
          ) : (
            <div className="app-panel">No applications found.</div>
          )}
        </Col>
      </Row>
    </Col>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  prequalificationInfoDTOs:
    state.softPull.unassignedPrequalificationInfoList &&
    state.softPull.unassignedPrequalificationInfoList.prequalificationInfoDTOs,
  total:
    state.softPull.unassignedPrequalificationInfoList &&
    state.softPull.unassignedPrequalificationInfoList.total,
});

const mapDispatchToProps = dispatch => ({
  dispatchGetUnassignedPrequalificationInfoList: dealerUserId =>
    dispatch(getUnassignedPrequalificationInfoList(dealerUserId)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PrequalificationInformation),
);
