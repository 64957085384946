import React from 'react';
import PropTypes from 'prop-types';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { MdNotInterested } from 'react-icons/md';

const Checkmark = ({ isOK, text }) => (
  <p className="mt-3">
    {isOK ? (
      <IoIosCheckmarkCircle className="ok size-24" />
    ) : (
      <MdNotInterested className="error size-24" />
    )}
    {text}
  </p>
);

Checkmark.propTypes = {
  isOK: PropTypes.bool,
  text: PropTypes.string,
};

export default Checkmark;
