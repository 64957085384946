import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { changePage, submitMindfire, changeMicrofInfoValue } from '../../redux/actions/index';
import { Container, Row, Col } from 'reactstrap';
import BackBtn from '../../components/UI/BackButton/BackButton';
import Button from '../../components/UI/Button/CustomButton';
import { reactGAPageview } from '../../helper';
import { MicrofFlowPageNumber } from './MicrofFlow';
import hvacSystem from '../../assets/js/HvacSystemEnum';
import * as Yup from 'yup';
import WaterHeaterTypeEnum from '../../assets/js/WaterHeaterTypeEnum';
import AirConditioningSystemEnum from '../../assets/js/AirConditioningSystemEnum';
import { Field, Form, withFormik } from 'formik';
import Checkbox from '../../components/UI/Form/Checkbox/Checkbox';
import { SECONDHVAC_TOOLTIP_TEXT } from '../../constants/tooltipTexts';

const WaterHeater = props => {
  const {
    email,
    dispatchChangePage,
    dispatchChangeMicrofInfoValue,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    waterHeater,
    hvacSystemType,
    children,
    systemType,
    hasSecondWaterHeater,
  } = props;
  const [isMessageHidden, setIsMessageHidden] = useState(
    waterHeater === null ? true : !!waterHeater,
  );

  const isWaterHeater = systemType === AirConditioningSystemEnum.WATER_HEATER.id;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_heattype',
      trackerNames: [],
      title: 'ca_heattype',
      did,
      dealerUserId,
    });

    // eslint-disable-next-line
  }, []);

  const WaterHeaterTypeForm = () => (
    <Form>
      <Row className="review">
        <Col>
          <Field
            component={Checkbox}
            name="secondWaterHeater"
            id="secondWaterHeater"
            tooltipText={SECONDHVAC_TOOLTIP_TEXT}
            label={`Is there a second system being installed?`}
            labelClassName="bold mb-0"
            clickHandler={() =>
              dispatchChangeMicrofInfoValue('hasSecondWaterHeater', !hasSecondWaterHeater)
            }
          />
        </Col>
      </Row>
      <Button type="submit" title="Continue" />
    </Form>
  );

  const WaterHeaterTypeFormFormik = withFormik({
    mapPropsToValues: () => ({
      secondWaterHeater: hasSecondWaterHeater || false,
    }),

    validationSchema: Yup.object({
      secondWaterHeater: Yup.boolean(),
    }),

    handleSubmit: () => {
      if (waterHeater) {
        dispatchChangePage(MicrofFlowPageNumber.LeaseAmount);
        dispatchSubmitMindfire({
          email: email,
          lastPage: '4124',
          dealerUserId: dealerUserId,
          did: did,
        });
      } else {
        setIsMessageHidden(false);
      }
    },
  })(WaterHeaterTypeForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={
            isWaterHeater ? MicrofFlowPageNumber.UserAddress : MicrofFlowPageNumber.HvacHeatType
          }
        />
        <Col>{children}</Col>
        <Col>
          {Object.values(WaterHeaterTypeEnum).map((obj, index) => (
            <Row className="mb-3" key={index}>
              <Col xs="12">
                <Button
                  className={waterHeater === obj.value ? '' : 'inactive'}
                  clickHandler={() => {
                    dispatchChangeMicrofInfoValue('waterHeater', obj.value);
                    setIsMessageHidden(true);
                  }}
                >
                  <h6 className="mb-0 font-weight-bold">{obj.name}</h6>
                </Button>
              </Col>
            </Row>
          ))}
          <Row>
            <Col>
              <p className="text-danger" hidden={isMessageHidden}>
                You must select an option.
              </p>
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <Button
                type="button"
                title="Continue"
                clickHandler={() => {
                  if (waterHeater) {
                    // dispatchChangeMicrofInfoValue(
                    //   'hvacSystem',
                    //   Object.values(hvacSystem).find(
                    //     obj =>
                    //       obj.systemType?.includes(waterHeater) &&
                    //       obj.heatType?.includes(waterHeater),
                    //   ).value,
                    // );
                    dispatchChangePage(MicrofFlowPageNumber.LeaseAmount);
                    dispatchSubmitMindfire({
                      email: email,
                      lastPage: '4124',
                      dealerUserId: dealerUserId,
                      did: did,
                    });
                  } else {
                    setIsMessageHidden(false);
                  }
                }}
              />
            </Col>
          </Row> */}
          <WaterHeaterTypeFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  homeownerInsurance: state.microfInformation.homeownerInsurance,
  isHomeowner: state.microfInformation.isHomeowner,
  email: state.preQualificationInformation.email,
  isReview: state.aquaInformation.isReview,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  waterHeater: state.microfInformation.waterHeater,
  hvacSystemType: state.microfInformation.hvacSystemType,
  systemType: state.microfInformation.systemType,
  hasSecondWaterHeater: state.microfInformation.hasSecondWaterHeater,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeMicrofInfoValue: (key, value) => dispatch(changeMicrofInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WaterHeater);
