const businessType = {
  C_CORP: { value: 'CC', name: 'Corporation (C-Corp)' },
  S_CORP: { value: 'SC', name: 'Corporation (S-Corp)' },
  GENERAL_PARTNERSHIP: { value: 'GP', name: 'General Partnership' },
  LLC: { value: 'LC', name: 'Limited Liability Company (LLC)' },
  LLLP: { value: 'LL', name: 'Limited Liability Limited Partnership (LLLP)' },
  LLP: { value: 'LP', name: 'Limited Liability Partnership (LLP)' },
  LP: { value: 'LI', name: 'Limited Partnership (LP)' },
  SOLE_PROPRIETOR: { value: 'SR', name: 'Sole Proprietor' },
};

export default businessType;

export const dealerBusinessType = {
  C_CORP: { value: 'C_CORP', name: 'Corporation (C-Corp)' },
  S_CORP: { value: 'S_CORP', name: 'Corporation (S-Corp)' },
  GENERAL_PARTNERSHIP: { value: 'GENERAL_PARTNERSHIP', name: 'General Partnership' },
  LLC: { value: 'LLC', name: 'Limited Liability Company (LLC)' },
  LLLP: { value: 'LLLP', name: 'Limited Liability Limited Partnership (LLLP)' },
  LLP: { value: 'LLP', name: 'Limited Liability Partnership (LLP)' },
  LP: { value: 'LP', name: 'Limited Partnership (LP)' },
  SOLE_PROPRIETOR: { value: 'SOLE_PROPRIETOR', name: 'Sole Proprietor' },
};
