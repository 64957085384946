import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import {
  isAquaDeclined,
  isGreenSkyDeclined,
  isMosaicDeclined,
  isPowerPayDeclined,
  isStarted,
} from '../../../assets/js/ApplicationStatusEnum';
import FTLEmploymentTypeEnum from '../../../assets/js/FTLEmploymentTypeEnum';
import selectedFinancierEnum from '../../../assets/js/SelectedFinancierEnum';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import { getAddress, getInstallAddress, reactGAPageview } from '../../../helper';
import {
  changeFtlInfoValue,
  changePage,
  submitMindfire,
  submitFTLApplication,
} from '../../../redux/actions/index';
import { FTLFlowPageNumber } from './FTLFlow';

const Review = props => {
  const {
    ftlInformation,
    pqi,
    dispatchChangePage,
    dispatchChangeFtlInfoValue,
    children,
    did,
    dealerUserId,
    applicantAddress,
    softPull,
    emulatorId,
    consumerId,
    track_id,
    dispatchSubmitFTLApplication,
    app,
    dispatchSubmitMindfire,
  } = props;

  const isEditable =
    !app ||
    isMosaicDeclined(app.status) ||
    isAquaDeclined(app.status) ||
    isPowerPayDeclined(app.status) ||
    isGreenSkyDeclined(app.status) ||
    isStarted(app.status);

  const propertyAddress = pqi.street
    ? getAddress({
        street: pqi.street,
        city: pqi.city,
        state: pqi.state,
        zip: pqi.zipcode,
      })
    : 'No address';

  const primaryApplicantAddress = ftlInformation.applicantStreet
    ? getInstallAddress({
        street: ftlInformation.applicantStreet,
        unit: ftlInformation.applicantApartmentNumber,
        city: ftlInformation.applicantCity,
        state: ftlInformation.applicantState,
        zip: ftlInformation.applicantZipcode,
      })
    : propertyAddress;

  const coApplicantAddress = ftlInformation.hasCoApp
    ? getAddress({
        street: ftlInformation.coAppStreet,
        city: ftlInformation.coAppCity,
        state: ftlInformation.coAppState,
        zip: ftlInformation.coAppZipCode,
      })
    : 'No address';

  const applicantEmployerAddress = ftlInformation.isAppEmployed
    ? getAddress({
        street: ftlInformation.appEmployerStreet,
        city: ftlInformation.appEmployerCity,
        state: ftlInformation.appEmployerState,
        zip: ftlInformation.appEmployerZipCode,
      })
    : undefined;

  const coApplicantEmployerAddress = ftlInformation.isCoAppEmployed
    ? getAddress({
        street: ftlInformation.coAppEmployerStreet,
        city: ftlInformation.coAppEmployerCity,
        state: ftlInformation.coAppEmployerState,
        zip: ftlInformation.coAppEmployerZipCode,
      })
    : undefined;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_ftlconfirminfo',
      trackerNames: [],
      title: 'ca_ftlconfirminfo',
      did,
      dealerUserId,
    });
    dispatchChangeFtlInfoValue('isReview', false);
    // eslint-disable-next-line
  }, []);

  const editPage = page => {
    dispatchChangeFtlInfoValue('isReview', true);
    dispatchChangePage(page);
  };

  const submitApplication = () => {
    dispatchSubmitMindfire({
      email: pqi.email,
      lastPage: '12018',
      dealerUserId: dealerUserId,
      did: did,
    });

    const softPullRequest = {
      email: softPull.softPullResponse.email || pqi.email,
      borrowedAmount: ftlInformation.borrowedAmount || pqi.borrowedAmount,
      firstName: softPull.softPullResponse.firstName || pqi.firstName,
      lastName: softPull.softPullResponse.lastName || pqi.lastName,
      middleName: softPull.softPullResponse.middleName || pqi.middleName,
      phoneNumber: softPull.softPullResponse.phoneNumber || pqi.phoneNumber,
      street: softPull.softPullResponse.street || pqi.street,
      city: softPull.softPullResponse.city || pqi.city,
      poBox: softPull.softPullResponse.poBox || pqi.poBox,
      zipcode: softPull.softPullResponse.zipcode || pqi.zipcode,
      state: softPull.softPullResponse.state || pqi.state,

      applicantStreet: ftlInformation.applicantStreet || null,
      applicantApartmentNumber: ftlInformation.applicantApartmentNumber || null,
      applicantCity: ftlInformation.applicantCity || null,
      applicantZip: ftlInformation.applicantZipcode || null,
      applicantState: ftlInformation.applicantState || null,

      birthday: softPull.softPullResponse.birthday || pqi.birthday,
      ssnLastDigits: softPull.softPullResponse.ssnLastDigits || pqi.ssnLastDigits,
      airConditioningSystem:
        softPull.softPullResponse.airConditioningSystem || pqi.airConditioningSystem,
      isHomeowner: softPull.softPullResponse.isHomeowner || pqi.isHomeowner,
      dealerUserId: dealerUserId,
      consumerId: consumerId,
      prequalificationId: softPull.softPullResponse.prequalificationId || pqi.prequalificationId,
      ssn: ftlInformation.appFullSSN,
      selectedFinancier: selectedFinancierEnum.FTL.value,

      employerType: ftlInformation.appEmploymentStatus || undefined,
      occupation: ftlInformation.appOccupation || undefined,
      employerName: ftlInformation.appEmployerName || undefined,

      appEmployerStreet: ftlInformation.appEmployerStreet || undefined,
      appEmployerCity: ftlInformation.appEmployerCity || undefined,
      appEmployerZipCode: ftlInformation.appEmployerZipCode || undefined,
      appEmployerState: ftlInformation.appEmployerState || undefined,
      employerPhoneNumber: ftlInformation.appEmployerPhoneNumber || undefined,

      monthlyNetIncome: (ftlInformation.appAnnualIncome * 0.83) / 12,
      appGrossAnnualIncome: ftlInformation.appAnnualIncome,

      coapplicantFirstName: ftlInformation.hasCoApp ? ftlInformation.coAppFirstName : undefined,
      coapplicantLastName: ftlInformation.hasCoApp ? ftlInformation.coAppLastName : undefined,
      coapplicantMiddleName: ftlInformation.hasCoApp
        ? ftlInformation.coAppMiddleInitial
        : undefined,
      coapplicantEmail: ftlInformation.hasCoApp ? ftlInformation.coAppEmail : undefined,
      coapplicantPhoneNumber: ftlInformation.hasCoApp ? ftlInformation.coAppPhoneNumber : undefined,
      coapplicantStreet: ftlInformation.hasCoApp ? ftlInformation.coAppStreet : undefined,
      coapplicantCity: ftlInformation.hasCoApp ? ftlInformation.coAppCity : undefined,
      coapplicantApartmentNumber: ftlInformation.hasCoApp
        ? ftlInformation.coAppApartmentNumber
        : undefined,
      coapplicantZipcode: ftlInformation.hasCoApp ? ftlInformation.coAppZipCode : undefined,
      coapplicantState: ftlInformation.hasCoApp ? ftlInformation.coAppState : undefined,
      coapplicantBirthday: ftlInformation.hasCoApp ? ftlInformation.coAppDoB : undefined,
      coapplicantSsn: ftlInformation.hasCoApp ? ftlInformation.coAppFullSsn : undefined,

      coapplicantEmployerType: ftlInformation.hasCoApp
        ? ftlInformation.coAppEmploymentStatus
          ? ftlInformation.coAppEmploymentStatus
          : undefined
        : undefined,
      coAppOccupation: ftlInformation.hasCoApp ? ftlInformation.coAppOccupation : undefined,
      coapplicantEmployerName: ftlInformation.hasCoApp
        ? ftlInformation.coAppEmployerName
        : undefined,

      coAppEmployerStreet: ftlInformation.hasCoApp ? ftlInformation.coAppEmployerStreet : undefined,
      coAppEmployerCity: ftlInformation.hasCoApp ? ftlInformation.coAppEmployerCity : undefined,
      coAppEmployerZipCode: ftlInformation.hasCoApp
        ? ftlInformation.coAppEmployerZipCode
        : undefined,
      coAppEmployerState: ftlInformation.hasCoApp ? ftlInformation.coAppEmployerState : undefined,
      coapplicantEmployerPhoneNumber: ftlInformation.hasCoApp
        ? ftlInformation.coAppEmployerPhoneNumber
        : undefined,

      coapplicantMonthlyNetIncome: (ftlInformation.coAppAnnualIncome * 0.83) / 12,
      coAppGrossAnnualIncome: ftlInformation.hasCoApp
        ? ftlInformation.coAppAnnualIncome
        : undefined,

      emulatorId: emulatorId,
      track_id: track_id,
    };

    dispatchSubmitFTLApplication(softPullRequest, FTLFlowPageNumber.ApplicationResult, app);
  };

  return (
    <Container>
      <Row>
        {isEditable ? (
          <BackBtn
            changePage={dispatchChangePage}
            page={
              ftlInformation.hasCoApp
                ? FTLFlowPageNumber.CoAppIdentificationInformation
                : FTLFlowPageNumber.HasCoApplicant
            }
          />
        ) : (
          <Col />
        )}
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <Row className="review">
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col xs="8" lg="9">
                  <h5>Loan Information</h5>
                </Col>
                {isEditable && (
                  <Col xs="4" lg="3">
                    <Button
                      className="edit"
                      clickHandler={() => editPage(FTLFlowPageNumber.LoanAmount)}
                      title="Edit"
                    />
                  </Col>
                )}
              </Row>
              <Row>
                <Col xs="6">
                  <b>Request Amount</b>
                  <NumberFormat
                    value={ftlInformation.borrowedAmount}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={'$'}
                    displayType="text"
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="review">
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col xs="8" lg="9">
                  <h5>Applicant’s Information</h5>
                </Col>
                {isEditable && (
                  <Col xs="4" lg="3">
                    <Button
                      className="edit"
                      clickHandler={() => editPage(FTLFlowPageNumber.BillingAddress)}
                      title="Edit"
                    />
                  </Col>
                )}
              </Row>
              <Row>
                <Col xs="6">
                  <b>Name</b>
                  {(pqi.firstName ? pqi.firstName + ' ' : '') +
                    (pqi.middleName ? pqi.middleName + ' ' : '') +
                    (pqi.lastName || '')}
                </Col>
                <Col xs="6">
                  <b>Phone Number</b>
                  <NumberFormat
                    value={pqi.phoneNumber}
                    format="(###) ###-####"
                    displayType="text"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <b>Email Address</b>
                  <span className="text-break">{pqi.email || 'N/A'}</span>
                </Col>
                <Col xs="6">
                  <b>Date of Birth</b>
                  {pqi.birthday || 'N/A'}
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <b>Full SSN</b>
                  {ftlInformation.appFullSSN
                    ? 'xxxxx' + ftlInformation.appFullSSN.slice(5, 9)
                    : 'N/A'}
                </Col>
              </Row>
              <Row>
                <Col>
                  <b>Installation Address</b>
                  {primaryApplicantAddress}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="review">
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col xs="8" lg="9">
                  <h5>Applicant's Employment Information</h5>
                </Col>
                {isEditable && (
                  <Col xs="4" lg="3">
                    <Button
                      className="edit"
                      clickHandler={() => editPage(FTLFlowPageNumber.EmploymentInformation)}
                      title="Edit"
                    />
                  </Col>
                )}
              </Row>
              <Row>
                <Col xs="6">
                  <b>Annual Income (or Benefits) Amount</b>
                  <NumberFormat
                    value={ftlInformation.appAnnualIncome}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={'$'}
                    displayType="text"
                  />
                </Col>
                <Col xs="6">
                  <b>Income (or Benefits) Type</b>
                  <span className="text-break">
                    {Object.values(FTLEmploymentTypeEnum).find(
                      obj => obj.value === ftlInformation.appEmploymentStatus,
                    )?.name || 'N/A'}
                  </span>
                </Col>
              </Row>
              {ftlInformation.isAppEmployed && (
                <>
                  <Row>
                    <Col xs="6">
                      <b>Employer</b>
                      {ftlInformation.appEmployerName || 'N/A'}
                    </Col>
                    <Col xs="6">
                      <b>Title/Occupation</b>
                      {ftlInformation.appOccupation || 'N/A'}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">
                      <b>Employer Address</b>
                      {applicantEmployerAddress}
                    </Col>
                    <Col xs="6">
                      <b>Employer Phone Number</b>
                      <NumberFormat
                        value={ftlInformation.appEmployerPhoneNumber}
                        format="(###) ###-####"
                        displayType="text"
                      />
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
          <Row className="review">
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col xs="8" lg="9">
                  <h5>Co-Applicant’s Information</h5>
                </Col>
                {isEditable && (
                  <Col xs="4" lg="3">
                    <Button
                      className="edit"
                      clickHandler={() => editPage(FTLFlowPageNumber.HasCoApplicant)}
                      title={ftlInformation.hasCoApp ? 'Edit' : 'Add'}
                    />
                  </Col>
                )}
              </Row>
              {ftlInformation.hasCoApp && (
                <>
                  <Row>
                    <Col xs="6">
                      <b>Name</b>
                      {(ftlInformation.coAppFirstName ? ftlInformation.coAppFirstName + ' ' : '') +
                        (ftlInformation.coAppMiddleInitial
                          ? ftlInformation.coAppMiddleInitial + ' '
                          : '') +
                        (ftlInformation.coAppLastName || '')}
                    </Col>
                    <Col xs="6">
                      <b>Phone Number</b>
                      {!!ftlInformation.coAppPhoneNumber
                        ? ftlInformation.coAppPhoneNumber.length > 6
                          ? '(' +
                            ftlInformation.coAppPhoneNumber.substring(0, 3) +
                            ') ' +
                            ftlInformation.coAppPhoneNumber.substring(3, 6) +
                            '-' +
                            ftlInformation.coAppPhoneNumber.substring(6)
                          : ftlInformation.coAppPhoneNumber
                        : 'N/A'}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">
                      <b>Email Address</b>
                      <span className="text-break">{ftlInformation.coAppEmail || 'N/A'}</span>
                    </Col>
                    <Col xs="6">
                      <b>Date of Birth</b>
                      {ftlInformation.coAppDoB || 'N/A'}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">
                      <b>Full SSN</b>
                      {ftlInformation.coAppFullSsn
                        ? 'xxxxx' + ftlInformation.coAppFullSsn.slice(5, 9)
                        : 'N/A'}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <b>Co-Applicant’s Address</b>
                      {coApplicantAddress}
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
          {ftlInformation.hasCoApp && (
            <Row className="review">
              <Col>
                <Row className="align-items-center justify-content-between">
                  <Col xs="8" lg="9">
                    <h5>Co-Applicant's Employment Information</h5>
                  </Col>
                  {isEditable && (
                    <Col xs="4" lg="3">
                      <Button
                        className="edit"
                        clickHandler={() => editPage(FTLFlowPageNumber.CoAppEmploymentInformation)}
                        title="Edit"
                      />
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col xs="6">
                    <b>Annual Income (or Benefits) Amount</b>
                    <NumberFormat
                      value={ftlInformation.coAppAnnualIncome}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix={'$'}
                      displayType="text"
                    />
                  </Col>
                  <Col xs="6">
                    <b>Income (or Benefits) Type</b>
                    <span className="text-break">
                      {Object.values(FTLEmploymentTypeEnum).find(
                        obj => obj.value === ftlInformation.coAppEmploymentStatus,
                      )?.name || 'N/A'}
                    </span>
                  </Col>
                </Row>
                {ftlInformation.isCoAppEmployed && (
                  <>
                    <Row>
                      <Col xs="6">
                        <b>Employer</b>
                        {ftlInformation.coAppEmployerName || 'N/A'}
                      </Col>
                      <Col xs="6">
                        <b>Title/Occupation</b>
                        {ftlInformation.coAppOccupation || 'N/A'}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6">
                        <b>Employer Address</b>
                        {coApplicantEmployerAddress}
                      </Col>
                      <Col xs="6">
                        <b>Employer Phone Number</b>
                        <NumberFormat
                          value={ftlInformation.coAappEmployerPhoneNumber}
                          format="(###) ###-####"
                          displayType="text"
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
          )}

          <Button
            clickHandler={() => submitApplication()}
            title="Continue"
            disabled={ftlInformation.submited}
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  applicantAddress: state.ftlInformation.applicantAddress,
  ftlInformation: state.ftlInformation,
  pqi: state.preQualificationInformation,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  aquaFinanceAssigned: state.auth.aquaFinanceAssigned,
  softPull: state.softPull,
  emulatorId: state.auth.user.user ? state.auth.user.user.emulatorId : undefined,
  consumerId: state.auth.consumerId,
  track_id: state.auth.track_id,
  app: state.softPull.applicationSubmitDTO,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeFtlInfoValue: (key, value) => dispatch(changeFtlInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchSubmitFTLApplication: (params, page, app) =>
    dispatch(submitFTLApplication(params, page, app)),
});

Review.propTypes = {
  ftlInformation: PropTypes.object,
  pqi: PropTypes.object,
  dispatchChangePage: PropTypes.func,
  dispatchChangeFtlInfoValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Review);
