import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Field, Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Col, Container, FormGroup, Label, Row } from 'reactstrap';
import CustomInput from '../../components/UI/Form/Input/CustomInput';
import Button from '../../components/UI/Button/CustomButton';
import CustomPhoneNumber from '../../components/UI/Form/PhoneNumber/PhoneNumber';
import Select from '../../components/UI/Form/Select/Select';
import { changePage, submitMindfire, endLoading, applyToBeDealer } from '../../redux/actions/index';
import { withRouter } from 'react-router-dom';
import { ContractorEnrollmentAtWaterGSFlowPageNumber } from './_Flow';

import { isNoreplyEmail } from '../../helper';
import {
  HowDidYouHearAboutGreenSkyCategories,
  OtherBusinessCategories,
  PrimaryBusinessCategories,
} from '../../assets/js/ContractorEnrollmentAtWaterGSEnum';
import { changeContractorEnrollmentAtWaterGSValue } from '../../redux/actions/contractorEnrollmentAtWaterGSActions';

const PersonalInformation = props => {
  const { ce, dispatchChangeValue, dispatchChangePage, children, dealerUser, user } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  const [isRequired, setIsRequired] = useState(false);

  const setValuesToState = values => {
    dispatchChangeValue('firstName', values.firstName);
    dispatchChangeValue('lastName', values.lastName);
    dispatchChangeValue('title', values.title);
    dispatchChangeValue('email', values.email);
    dispatchChangeValue('workPhone', values.workPhone);
    dispatchChangeValue('mobilePhone', values.mobilePhone);
    dispatchChangeValue('primaryBusinessCategory', values.primaryBusinessCategory);
    dispatchChangeValue('otherBusinessCategory', values.otherBusinessCategory);
    dispatchChangeValue('businessLegalName', values.businessLegalName);
    dispatchChangeValue('websiteBusinessUrl', values.websiteBusinessUrl);
    dispatchChangeValue('howDidYouHearAboutGreenSky', values.howDidYouHearAboutGreenSky);
  };

  const PersonalInformationForm = ({ values, errors, touched }) => (
    <Form autoComplete="off">
      <h3>Person Completing This Application</h3>
      <Field
        component={CustomInput}
        label="First Name *"
        name="firstName"
        id="firstName"
        type="text"
      />
      <Field
        component={CustomInput}
        label="Last Name *"
        name="lastName"
        id="lastName"
        type="text"
      />
      <Field
        component={CustomInput}
        label="Title (Position with company) *"
        name="title"
        id="title"
        type="text"
      />
      <Field component={CustomInput} label="Email *" name="email" id="email" type="email" />
      <Field component={CustomPhoneNumber} label="Work Phone *" name="workPhone" id="workPhone" />
      <Field
        component={CustomPhoneNumber}
        label="Mobile Phone *"
        name="mobilePhone"
        id="mobilePhone"
      />
      <Field
        component={Select}
        label="Primary Business Category *"
        name="primaryBusinessCategory"
        id="primaryBusinessCategory"
        type="select"
        selectValues={PrimaryBusinessCategories}
      />
      <Field
        component={Select}
        label="Other Business Category "
        name="otherBusinessCategory"
        id="otherBusinessCategory"
        type="select"
        selectValues={OtherBusinessCategories}
      />
      <Field
        component={CustomInput}
        label="Business Legal Name (Merchant) *"
        name="businessLegalName"
        id="businessLegalName"
        type="text"
      />
      <Field
        component={CustomInput}
        label="Website / Business URL *"
        name="websiteBusinessUrl"
        id="websiteBusinessUrl"
        type="text"
        placeholder="http://"
      />
      <Field
        component={Select}
        label="How Did You Hear About AtWater *"
        name="howDidYouHearAboutGreenSky"
        id="howDidYouHearAboutGreenSky"
        type="select"
        selectValues={HowDidYouHearAboutGreenSkyCategories}
      />
      <FormGroup>
        <Row>
          <Col>
            <Label>
              <b>Are you an owner? *</b>
            </Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              className={ce.isOwner === null ? 'inactive' : ce.isOwner ? '' : 'inactive'}
              type="button"
              title="Yes"
              clickHandler={() => {
                dispatchChangeValue('isOwner', true);
                setValuesToState(values);
                setIsRequired(false);
              }}
            />
          </Col>
          <Col>
            <Button
              className={ce.isOwner === null ? 'inactive' : ce.isOwner ? 'inactive' : ''}
              type="button"
              title="No"
              clickHandler={() => {
                dispatchChangeValue('isOwner', false);
                setValuesToState(values);
                setIsRequired(false);
              }}
            />
          </Col>
        </Row>
        <p className="text-danger mt-2" hidden={!isRequired ? true : ce.isOwner}>
          You must select an option.
        </p>
      </FormGroup>

      <Button type="submit" title="Next" />
    </Form>
  );

  const PersonalInformationFormFormik = withFormik({
    mapPropsToValues: () => ({
      firstName: ce.firstName || dealerUser?.nameFirst || '',
      lastName: ce.lastName || dealerUser?.nameLast || '',
      title: ce.title || '',
      email: ce.email || dealerUser?.email || '',
      workPhone: ce.workPhone || '',
      mobilePhone: ce.mobilePhone || '',
      primaryBusinessCategory: ce.primaryBusinessCategory || '',
      otherBusinessCategory: ce.otherBusinessCategory || '',
      businessLegalName: ce.businessLegalName || '',
      websiteBusinessUrl: ce.websiteBusinessUrl || '',
      howDidYouHearAboutGreenSky: ce.howDidYouHearAboutGreenSky || '',
    }),

    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(/^(?=.*[a-zA-Z ])([a-zA-Z ]+)$/, 'Special characters are not allowed.')
        .required('Please enter First Name.'),
      lastName: Yup.string()
        .matches(/^(?=.*[a-zA-Z ])([a-zA-Z ]+)$/, 'Special characters are not allowed.')
        .required('Please enter Last Name.'),
      title: Yup.string()
        .matches(/^(?=.*[a-zA-Z ])([a-zA-Z ]+)$/, 'Special characters are not allowed.')
        .required('Please enter Last Name.'),
      email: Yup.string()
        .email('Please enter a valid email.')
        .required('Please enter an email.')
        .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),
      workPhone: Yup.string()
        .test('required', 'Please enter the work phone number.', val => val !== '+' && val)
        .test('len', 'Invalid phone number', val => val && val.match(/\d/g).join('').length === 10),
      mobilePhone: Yup.string()
        .test('required', 'Please enter the mobile phone number.', val => val !== '+' && val)
        .test('len', 'Invalid phone number', val => val && val.match(/\d/g).join('').length === 10),
      primaryBusinessCategory: Yup.string().required('Please select a Business Category.'),
      businessLegalName: Yup.string()
        .matches(/^(?=.*[a-zA-Z ])([a-zA-Z ]+)$/, 'Special characters are not allowed.')
        .required('Please enter Business Legal Name.'),
      websiteBusinessUrl: Yup.string().required('Please enter Business Website.'),
      howDidYouHearAboutGreenSky: Yup.string().required(
        'Please let us know how you did you hear about GreenSky.',
      ),
    }),

    handleSubmit: values => {
      setValuesToState(values);
      if (ce.isOwner !== null) {
        setIsRequired(false);
        dispatchChangePage(ContractorEnrollmentAtWaterGSFlowPageNumber.BusinessInformation);
      } else {
        setIsRequired(true);
      }
    },
  })(PersonalInformationForm);

  return (
    <Container>
      <Row>
        <Col sm="6">{children}</Col>
        <Col sm="6">
          <PersonalInformationFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  ce: state.contractorEnrollmentAtWaterGS,
  dealerUser: state.auth.user?.dealerUser,
  user: state.auth.user?.user,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) =>
    dispatch(changeContractorEnrollmentAtWaterGSValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchEndLoading: () => dispatch(endLoading()),
  dispatchApplyToBeDealer: (page, history) => dispatch(applyToBeDealer(false, page, history)),
});

PersonalInformation.propTypes = {
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
  dispatchSubmitSoftPullAquaApplication: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PersonalInformation),
);
