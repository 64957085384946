// Microf & PowerPay

export const EMAIL_TOOLTIP_TEXT = 'Please enter your email address.';
export const COAPPEMAIL_TOOLTIP_TEXT =
  "Please enter Co-Applicant's email address. Please enter the email address for the Co-Applicant (we recommend a different email address than the primary applicant's email address).";
export const EMAIL_CONFIRMATION_TOOLTIP_TEXT = 'Please enter the same email address again.';
export const APPMONTHLYNETINCOME_TOOLTIP_TEXT = 'Please enter your monthly net income.';
export const APPBANKACCOUNTROUTINGNUMBER_TOOLTIP_TEXT =
  'Please enter your bank account routing number.';
export const APPBANKACCOUNTNUMBER_TOOLTIP_TEXT = 'Please enter your bank account number.';
export const PASSWORD_TOOLTIP_TEXT =
  'Please create a password. Your password should be at least 7 characters with at least 3 letters (1 upper case, 1 lower case) and at least 1 number.';
export const PASSWORD_CONFIRM_TOOLTIP_TEXT = 'Please enter the same password again.';
export const BIRTHDAY_TOOLTIP_TEXT = 'Please enter your date of birth.';
export const LAST_4_SSN_TOOLTIP_TEXT =
  'Please enter your last 4 digist of your Social Security Number.';
export const APPFULLSSN_TOOLTIP_TEXT = 'Please enter your full Social Security Number number.';
export const SYSTEMMAKE_TOOLTIP_TEXT = 'Please enter system make.';
export const SYSTEMTYPE_TOOLTIP_TEXT = 'Please enter system type.';
export const SYSTEMMODEL_TOOLTIP_TEXT = 'Please enter system model.';
export const SYSTEMSERIALNUMBER_TOOLTIP_TEXT = 'Please enter system serial number.';
export const SYSTEMDESCRIPTION_TOOLTIP_TEXT = 'Please enter system description.';
export const SECONDHVAC_TOOLTIP_TEXT = 'Is there a second system being installed?';
export const TOTALPRICE_TOOLTIP_TEXT = 'Total job price.';
export const RETAILPRICE_TOOLTIP_TEXT = 'Retail (Cash) Price.';
export const REQUESTEDLOANAMOUNT_TOOLTIP_TEXT = 'Please enter the requested lease amount.';
export const BORROWEDAMOUNTT_TOOLTIP_TEXT = 'Please enter the desired amount.';
export const APPLICANTCONSENT_TOOLTIP_TEXT = 'Please agree to Microf’s Terms & Conditions.';
export const HVACSYSTEM_TOOLTIP_TEXT = 'HVAC system type.';
export const HVACTONNAGE_TOOLTIP_TEXT = 'Estimated HVAC system size.';
export const INSTALLMENTDATE_TOOLTIP_TEXT = 'Estimated installation date.';
export const SECONDHVACSYSTEM_TOOLTIP_TEXT = 'Second HVAC system type.';
export const SECONDHVACTONNAGE_TOOLTIP_TEXT = 'Second HVAC estimated system size.';
export const COAPPBANKACCOUNTROUTINGNUMBER_TOOLTIP_TEXT =
  "Please enter Co-Applicant's bank account routing number.";
export const COAPPBANKACCOUNTNUMBER_TOOLTIP_TEXT =
  "Please enter Co-Applicant's bank account number.";
export const STREET_TOOLTIP_TEXT = 'Please enter street address.';
export const UNIT_NUMBER_TOOLTIP_TEXT = 'Please enter unit/apt. number (if any).';
export const CITY_TOOLTIP_TEXT = 'Please enter city.';
export const STATE_TOOLTIP_TEXT = 'Please select state.';
export const ZIPCODE_TOOLTIP_TEXT = 'Please enter your zip code.';
export const FIRSTNAME_TOOLTIP_TEXT = 'Please enter your first name.';
export const MIDDLENAME_TOOLTIP_TEXT = 'Please enter your middle initial.';
export const LASTNAME_TOOLTIP_TEXT = 'Please enter your last name.';
export const PHONENUMBER_TOOLTIP_TEXT = 'Please enter your phone number (mobile preferred).';
export const COAPPSTREET_TOOLTIP_TEXT = "Please enter Co-Applicant's street address.";
export const COAPPCITY_TOOLTIP_TEXT = "Please enter Co-Applicant's city.";
export const COAPPSTATE_TOOLTIP_TEXT = "Please select Co-Applicant's state.";
export const COAPPZIPCODE_TOOLTIP_TEXT = "Please enter Co-Applicant's zip code.";
export const COAPPUNITNUMBER_TOOLTIP_TEXT = "Please enter Co-Applicant's unit/apt. number.";
export const COAPPDOB_TOOLTIP_TEXT = "Please enter Co-Applicant's date of birth.";
export const COAPPFULLSSN_TOOLTIP_TEXT = "Please enter Co-Applicant's full Social Security Number.";
export const COAPPMONTHLYNETINCOME_TOOLTIP_TEXT = "Please enter Co-Applicant's monthly net income.";
export const COAPPFIRSTNAME_TOOLTIP_TEXT = "Please enter Co-Applicant's first name.";
export const COAPPMIDDLEINITIAL_TOOLTIP_TEXT = "Please enter Co-Applicant's middle initial.";
export const COAPPLASTNAME_TOOLTIP_TEXT = "Please enter Co-Applicant's last name.";
export const COAPPPHONENUMBER_TOOLTIP_TEXT =
  "Please enter Co-Applicant's phone number (mobile preferred).";
export const COAPP_RELATIONSHIP_TOOLTIP_TEXT =
  'Please select the relationship with the Co-Applicant';

export const APP_DRIVER_LICENSE_NUMBER_TOOLTIP_TEXT =
  "Please enter the applicant's driver license number.";
export const APP_DRIVER_LICENSE_STATE_TOOLTIP_TEXT =
  "Please select the applicant's driver license state.";
export const MILITARY_ACTIVE_TOOLTIP_TEXT = 'Please select if you are military active.';
export const US_CITIZEN_TOOLTIP_TEXT = 'Please select if you are a US citizen.';
export const PROPERTY_TYPE_TOOLTIP_TEXT = 'Please select your property type.';
export const TIME_AT_ADDRESS_TOOLTIP_TEXT = 'Please enter time at address.';
export const GROSS_ANNUAL_INCOME_TOOLTIP_TEXT = 'Please enter the gross annual income.';
export const EMPLOYMENT_TYPE_TOOLTIP_TEXT = 'Please select the employment type.';
export const EMPLOYER_NAME_TOOLTIP_TEXT = "Please enter the employer's name.";
export const OCCUPATION_TOOLTIP_TEXT = 'Please enter the occupation.';
export const TIME_AT_EMPLOYER_TOOLTIP_TEXT = 'Please enter the time spent at employer.';
export const EMPLOYER_ZIPCODE_TOOLTIP_TEXT = "Please enter the employer's zip code.";

export const COAPP_DRIVER_LICENSE_NUMBER_TOOLTIP_TEXT =
  "Please enter the Co-Applicant's driver license number.";
export const COAPP_DRIVER_LICENSE_STATE_TOOLTIP_TEXT =
  "Please enter the Co-Applicant's driver license state.";
export const COAPP_MILITARY_ACTIVE_TOOLTIP_TEXT =
  'Please select if the Co-Applicant is military active.';
export const COAPP_US_CITIZEN_TOOLTIP_TEXT = 'Please select if the Co-Applicant is a US citizen.';

// Aqua
export const INCOME_SOURCE_TOOLTIP_TEXT = 'Please select your income source.';
export const EMPLOYMENT_POSITION_TOOLTIP_TEXT = 'Please enter your employment position.';
export const EMPLOYMENT_YEARS_TOOLTIP_TEXT = 'Please enter your employment years.';
export const EMPLOYMENT_MONTHS_TOOLTIP_TEXT = 'Please enter your employment months.';
export const EMPLOYER_PHONE_NUMBER_TOOLTIP_TEXT = "Please enter your employer's phone number.";
export const SALARY_INCOME_TOOLTIP_TEXT = 'Please enter your income.';
export const ID_TYPE_TOOLTIP_TEXT = 'Please select your ID type.';
export const ID_NUMBER_TOOLTIP_TEXT = 'Please enter your ID number.';
export const ID_ISSUE_DATE_TOOLTIP_TEXT = 'Please enter your ID issue date.';
export const ID_EXPIRATION_DATE_TOOLTIP_TEXT = 'Please enter your ID expiration date.';
export const AUTHORIZE_TOOLTIP_TEXT =
  'You understand and agree that you are providing instructions to Microf under the Fair Credit Reporting Act (“FCRA”) to access information from your personal credit profile or other information from one or more consumer reporting agencies such as Equifax, Experian, or TransUnion.';
export const OTHER_SOURCES_OF_INCOME_TOOLTIP_TEXT =
  'Please select source of other income. If you have multiple other sources, select the one that generates the highest income.';
export const ADDITIONAL_INCOME_TOOLTIP_TEXT =
  'Please enter your total income. Make sure the amount includes both your primary and other sources of income you earn before taxes.';
export const OWN_OR_RENT_TOOLTIP_TEXT =
  'Do you own or rent your home? Please select the apropriate answer.';
export const HVAC_MONTHLY_PAYMENT_TOOLTIP_TEXT =
  'Please enter monthly payment (if no payment enter $0)';
export const HOME_VALUE_TOOLTIP_TEXT =
  'Please enter your home value. Your home value can be an estimate.';
export const HVAC_INSTALLMENT_DATE_TOOLTIP_TEXT = 'Please select estimated installation date';
export const COAPP_ID_TYPE_TOOLTIP_TEXT = "Please select Co-Applicant's ID type.";
export const COAPP_ID_NUMBER_TOOLTIP_TEXT = "Please enter Co-Applicant's ID number.";
export const COAPP_ID_ISSUE_DATE_TOOLTIP_TEXT = "Please enter Co-Applicant's ID issue date.";
export const COAPP_ID_EXPIRATION_DATE_TOOLTIP_TEXT =
  "Please enter Co-Applicant's ID expiration date.";
export const COAPP_INCOME_SOURCE_TOOLTIP_TEXT = "Please select Co-Applicant's income source.";
export const COAPP_EMPLOYER_NAME_TOOLTIP_TEXT = "Please enter the Co-Applicant's employer's name.";
export const COAPP_EMPLOYMENT_POSITION_TOOLTIP_TEXT =
  "Please enter Co-Applicant's employment position.";
export const COAPP_EMPLOYMENT_YEARS_TOOLTIP_TEXT = "Please enter Co-Applicant's employment years.";
export const COAPP_EMPLOYMENT_MONTHS_TOOLTIP_TEXT =
  "Please enter Co-Applicant's employment months.";
export const COAPP_EMPLOYER_PHONE_NUMBER_TOOLTIP_TEXT =
  "Please enter Co-Applicant's employer's phone number.";
export const COAPP_SALARY_INCOME_TOOLTIP_TEXT = "Please enter Co-Applicant's income.";
export const COAPP_OTHER_SOURCES_OF_INCOME_TOOLTIP_TEXT =
  "Please select Co-Applicant's source of other income. If the Co-Applicant has multiple other sources, select the one that generates the highest income.";
export const COAPP_ADDITIONAL_INCOME_TOOLTIP_TEXT =
  "Please enter Co-Applicant's total income. Make sure the amount includes both the primary and other sources of income the Co-Applicant earns before taxes.";

// EnerBank
export const PRIMARY_APPLICANT_CONSENT_TOOLTIP_TEXT =
  'I have read and agree to all consents and agreements. If applying with a Co-Applicant, I acknowledge my intent for applying for joint credit.';
export const COAPP_CONSENT_TOOLTIP_TEXT =
  'I have read and agree to all consents and agreements. If applying as a Co-Applicant, I acknowledge my intent for applying for joint credit.';
export const COAPP_GROSS_ANNUAL_INCOME_TOOLTIP_TEXT =
  "Please enter the Co-Applicant's gross annual income.";
export const ENERBANK_APPLICANTCONSENT_TOOLTIP_TEXT =
  'I acknowledge that by checking the box below and pressing continue that the information on this application will be sent to EnerBank for the purpose of completing a credit application.';
export const DELIVERY_METHOD_TOOLTIP_TEXT = 'Please select the Delivery Method.';
export const OWNERSHIP_TOOLTIP_TEXT = 'Please select ownership/residency status.';
export const MORTGAGE_TOOLTIP_TEXT = 'Please enter a mortgage/rent payment.';
export const COAPP_TYPE_TOOLTIP_TEXT = 'Please select Co-Applicant type.';
export const PROGRAM_PHONE_NUMBER_TOOLTIP_TEXT = 'Please enter Program Phone Number.';
export const LOAN_CODE_TOOLTIP_TEXT = 'Please enter Loan Code.';
export const CONTRACTOR_ID_TOOLTIP_TEXT = 'Please enter Contractor ID.';
export const COMMUNICATION_METHOD_TOOLTIP_TEXT = 'Please select the communication method.';
export const ESTIMATED_PROJECT_COST_TOOLTIP_TEXT = 'Please enter estimated project cost.';
export const PROJECT_TYPE_TOOLTIP_TEXT = 'Please select project type.';
export const EGIA_TERMS_TOOLTIP_TEXT =
  'You authorize EGIA to immediately share all application information with participating lenders and you understand and acknowledge that you are accepting EGIA’s Statement of Terms & Conditions, Statement on Use of Electronic Records, Privacy Statement';
export const EGIA_AUTHORIZATION_TOOLTIP_TEXT =
  'You understand and agree that you are providing instructions to EGIA, Lenders, or Lending Partners that partner with EGIA to access information under the Fair Credit Reporting Act (”FCRA”) from your personal credit profile or other information from one or more consumer reporting agencies such as Equifax, Experian, or TransUnion. You authorize your information to be obtained solely to conduct a pre-qualification for credit. If you subsequently apply, further credit checks might be performed pursuant to other authorizations. After checking the box and proceeding, a ”soft pull” of your credit will be performed and this will not affect your credit score.';

// FTL
export const FTL_CONSENT_TOOLTIP_TEXT =
  'I acknowledge that by checking the box and pressing continue that the information on this page will be sent to FTL Finance for the purpose of completing a credit application.';
export const EMPLOYMENT_STATUS_TOOLTIP_TEXT = 'Please select your employment status.';
export const FTL_PROJECT_TYPE_TOOLTIP_TEXT = 'Please select your project type.';
export const EMPLOYER_STREET_TOOLTIP_TEXT = "Please select your employer's street.";
export const EMPLOYER_CITY_TOOLTIP_TEXT = "Please select your employer's city.";
export const EMPLOYER_STATE_TOOLTIP_TEXT = "Please select your employer's state.";
export const ANNUAL_INCOME_TOOLTIP_TEXT = 'Please select your annual income.';
export const COAPP_EMPLOYMENT_STATUS_TOOLTIP_TEXT =
  "Please select Co-Applicant's employment status.";
export const COAPP_OCCUPATION_STATUS_TOOLTIP_TEXT =
  "Please select Co-Applicant's title/occupation.";
export const COAPP_EMPLOYER_STREET_TOOLTIP_TEXT = "Please select Co-Applicant's employer's street.";
export const COAPP_EMPLOYER_CITY_TOOLTIP_TEXT = "Please select Co-Applicant's employer's city.";
export const COAPP_EMPLOYER_STATE_TOOLTIP_TEXT = "Please select Co-Applicant's employer's state.";
export const COAPP_EMPLOYER_ZIPCODE_TOOLTIP_TEXT =
  "Please enter Co-Applicant's employer's zip code.";

// Greensky
export const MANAGER_FIRST_NAME_TOOLTIP_TEXT = "Please enter the Business Manager's first name.";
export const MANAGER_LAST_NAME_TOOLTIP_TEXT = "Please enter the Business Manager's last name.";
export const MANAGER_PHONE_TOOLTIP_TEXT = "Please enter the Business Manager's phone.";
export const MANAGER_EMAIL_TOOLTIP_TEXT = "Please enter the Business Manager's email.";
export const CONTRACTOR_EIN_TOOLTIP_TEXT = "Please enter the Contractor's Federal Tax ID (EIN).";
export const CONTRACTOR_BUSINESS_NAME_TOOLTIP_TEXT =
  "Please enter the Contractor's Legal Business Name.";
export const CONTRACTOR_DBA_NAME_TOOLTIP_TEXT = "Please enter the Contractor's DBA Name.";
export const CONTRACTOR_BUSINESS_TYPE_TOOLTIP_TEXT =
  "Please enter the Contractor's Legal Business Type.";
export const CONTRACTOR_PHONE_TOOLTIP_TEXT = "Please enter the Contractor's phone number.";
export const CONTRACTOR_WEBSITE_TOOLTIP_TEXT = "Please enter the Contractor's website.";
export const CONTRACTOR_STREET_TOOLTIP_TEXT = "Please enter the Contractor's street.";
export const CONTRACTOR_CITY_TOOLTIP_TEXT = "Please enter the Contractor's city.";
export const CONTRACTOR_STATE_TOOLTIP_TEXT = "Please enter the Contractor's state.";
export const CONTRACTOR_ZIP_CODE_TOOLTIP_TEXT = "Please enter the Contractor's zip code.";
export const CONTRACTOR_ADDRESS_TOOLTIP_TEXT = "Please enter the Contractor's address.";
export const CURRENT_CHARGE_AMOUNT_TOOLTIP_TEXT = 'Please enter the current charge amount ($).';
export const AUTHORIZED_LOAN_AMOUNT_TOOLTIP_TEXT = 'Please enter the authorized loan amount ($).';
export const INVOICE_TOOLTIP_TEXT = 'Please enter the invoice number (optional).';
export const REFUND_AMOUNT_TOOLTIP_TEXT = 'Please enter the refund amount ($).';

// Mosaic
export const MICROF_TERMS_TOOLTIP_TEXT =
  'You authorize Microf to immediately share all application information with participating lenders and you understand and acknowledge that you are accepting EGIA’s Statement of Terms & Conditions, Statement on Use of Electronic Records, Privacy Statement.';
export const MICROF_AUTHORIZATION_TOOLTIP_TEXT =
  'You understand and agree that you are providing instructions to Microf, Lenders, or Lending Partners that partner with Microf to access information under the Fair Credit Reporting Act (”FCRA”) from your personal credit profile or other information from one or more consumer reporting agencies such as Equifax, Experian, or TransUnion. You authorize your information to be obtained solely to conduct a pre-qualification for credit. If you subsequently apply, further credit checks might be performed pursuant to other authorizations. After checking the box and proceeding, a ”soft pull” of your credit will be performed and this will not affect your credit score.';

// Optimus
export const GROSS_INCOME_TOOLTIP_TEXT = 'Please enter your gross income.';
export const INCOME_FREQUENCY_TOOLTIP_TEXT = 'Please select your income frequency.';
export const ESTIMATED_GROSS_ANNUAL_INCOME_TOOLTIP_TEXT =
  'Please enter the estimated gross annual income.';
export const OPTIMUS_COAPP_AUTHORIZE_TOOLTIP_TEXT =
  'You acknowledge that by checking the box below and pressing continue, that the info on this application will be sent to Foundation Finance Company for the purpose of completing a credit application and a hard credit pull will be completing upon application submission.';
export const COAPP_OWNERSHIP_TOOLTIP_TEXT =
  "Please select Co-Applicant's ownership/residency status.";
export const COAPP_PROPERTY_MONTHLY_PAYMENT_TOOLTIP_TEXT =
  "Please enter Co-Applicant's monthly payment (if no payment enter $0).";
export const COAPP_PROPERTY_YEARS_TOOLTIP_TEXT = "Please enter Co-Applicant's property years.";
export const COAPP_PROPERTY_MONTHS_TOOLTIP_TEXT = "Please enter Co-Applicant's property months.";
export const COAPP_GROSS_INCOME_TOOLTIP_TEXT = "Please enter Co-Applicant's gross income.";
export const COAPP_INCOME_FREQUENCY_TOOLTIP_TEXT = "Please select Co-Applicant's income frequency.";
export const COAPP_ESTIMATED_GROSS_ANNUAL_INCOME_TOOLTIP_TEXT =
  "Please enter the Co-Applicant's estimated gross annual income.";
export const COAPP_INCOME_TYPE_TOOLTIP_TEXT = "Please enter Co-Applicant's income type.";
export const PRODUCT_TYPE_TOOLTIP_TEXT = 'Please enter product type.';
export const PROPERTY_MONTHLY_PAYMENT_TOOLTIP_TEXT =
  'Please enter monthly payment (if no payment enter $0).';
export const PROPERTY_YEARS_TOOLTIP_TEXT = 'Please enter property years.';
export const PROPERTY_MONTHS_TOOLTIP_TEXT = 'Please enter property months.';
export const SALE_AMOUNT_TOOLTIP_TEXT = 'Please enter sale amount.';
export const DOWN_PAYMENT_TOOLTIP_TEXT = 'Please enter down payment.';
export const LOAN_OPTION_TOOLTIP_TEXT = 'Please select loan option.';
export const SPANISH_CONSENT_TOOLTIP_TEXT =
  'Marque la casilla si llevó a cabo alguna parte de la discusión sobre su solicitud o préstamo GreenSky® en español, o si desea una copia del contrato de su crédito en español.';
export const MOBILE_NUMBER_CONSENT_TOOLTIP_TEXT =
  'You understand that if you have provided your mobile telephone number in any application field you authorize any financial institution that makes a GreenSky loan to you and its agents, contractors, successors, assigns, or service providers to contact you at this mobile number and any future number that you provide for your cellular telephone or other wireless device by phone or text message using automated telephone dialing system or artificial or prerecorded voice messages, for any legal purpose, including, but not limited to, servicing a loan. You may revoke your consent at any time by contacting us at 1-866-936-0602 or P.O. Box 29429, Atlanta, GA 30359.';
export const SPOUSE_FIRST_NAME_TOOLTIP_TEXT = 'Please enter spouse’s first name.';
export const SPOUSE_MIDDLE_INITIAL_NAME_TOOLTIP_TEXT = 'Please enter spouse’s middle initial.';
export const SPOUSE_LAST_NAME_TOOLTIP_TEXT = 'Please enter spouse’s last name.';
export const SPOUSE_STREET_TOOLTIP_TEXT = 'Please enter spouse’s street.';
export const SPOUSE_UNIT_NUMBER_TOOLTIP_TEXT = 'Please enter spouse’s unit/apt. number.';
export const SPOUSE_CITY_TOOLTIP_TEXT = 'Please enter spouse’s city.';
export const SPOUSE_STATE_TOOLTIP_TEXT = 'Please enter spouse’s state.';
export const SPOUSE_ZIP_TOOLTIP_TEXT = 'Please enter spouse’s zip code.';
export const MERCHANT_ID_TOOLTIP_TEXT = 'Please enter the merchant id.';
export const PLAN_ID_TOOLTIP_TEXT = 'Please enter the plan id.';

// Wells Fargo
export const PO_BOX_TOOLTIP_TEXT = 'Please enter P.O. Box (if any - optional field).';
export const COAPP_PO_BOX_TOOLTIP_TEXT =
  "Please enter Co-Applicant's P.O. Box (if any - optional field).";

// Contractor enrollment

export const BENEFICIAL_OWNER_LASTNAME_TOOLTIP_TEXT =
  "Please enter the beneficial owner's last name.";
export const BENEFICIAL_OWNER_FIRSTNAME_TOOLTIP_TEXT =
  "Please enter the beneficial owner's first name.";
export const BENEFICIAL_OWNER_PHONENUMBER_TOOLTIP_TEXT =
  "Please enter the beneficial owner's phone number.";
export const BENEFICIAL_OWNER_EMAIL_TOOLTIP_TEXT =
  "Please enter the beneficial owner's email address.";
export const BENEFICIAL_OWNER_STREET_TOOLTIP_TEXT =
  "Please enter the beneficial owner's street address (home).";
export const BENEFICIAL_OWNER_CITY_TOOLTIP_TEXT =
  "Please enter the beneficial owner's city (home).";
export const BENEFICIAL_OWNER_ZIP_CODE_TOOLTIP_TEXT =
  "Please enter the beneficial owner's ZIP code. (home)";
export const BENEFICIAL_OWNER_STATE_TOOLTIP_TEXT =
  "Please select the beneficial owner's state (home).";
export const BENEFICIAL_OWNER_SSN_TOOLTIP_TEXT =
  "Please select the beneficial owner's Social Security Number.";
export const BENEFICIAL_OWNER_OWNERSHIP_TOOLTIP_TEXT =
  "Please select the beneficial owner's ownership in percentage.";
export const MANAGER_CITY_TOOLTIP_TEXT = "Please enter the Business Manager's city (home).";
export const MANAGER_ZIP_CODE_TOOLTIP_TEXT = "Please enter the Business Manager's ZIP code (home).";
export const MANAGER_STATE_TOOLTIP_TEXT = "Please select the Business Manager's state (home).";
export const MANAGER_SSN_TOOLTIP_TEXT =
  "Please select the Business Manager's Social Security Number.";
export const MANAGER_OWNERSHIP_TOOLTIP_TEXT =
  'Please select an option if there are owners that have 25% or greater ownership in your company?.';
export const CONTRACTOR_YEARS_TOOLTIP_TEXT = "Please enter the Contractor's years in business.";
export const CONTRACTOR_MONTHS_TOOLTIP_TEXT = "Please enter the Contractor's months in business.";
export const CONTRACTOR_DISTRIBUTOR_TOOLTIP_TEXT = 'Please select the distributor.';
export const BANK_ABA_TOOLTIP_TEXT = 'Please enter Bank ABA.';
export const BANK_ACCOUNT_NUMBER_TOOLTIP_TEXT = 'Please enter bank account number.';
export const BANK_ACCOUNT_TYPE_TOOLTIP_TEXT = 'Please enter bank account type.';
export const CONTRACTOR_FAX_TOOLTIP_TEXT = "Please enter the Contractor's fax number.";
