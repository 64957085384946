import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import Input from '../../../../components/UI/Form/Input/CustomInput';
import {
  changePage,
  changeGreenSkyApplicationInfoValue,
  submitMindfire,
} from '../../../../redux/actions/index';
import { reactGAPageview, reactGAEvent } from '../../../../helper';
import { getIsRheem } from '../../../../config/settings';
import { GreenSkyFlowPageNumber } from './GreenSkyFlow';
import USStateEnum from '../../../../assets/js/USStateEnum';
import {
  COAPPFIRSTNAME_TOOLTIP_TEXT,
  COAPPLASTNAME_TOOLTIP_TEXT,
  COAPPMIDDLEINITIAL_TOOLTIP_TEXT,
} from '../../../../constants/tooltipTexts';
import Disclosure from '../Components/Disclosure';

const isRheem = getIsRheem();

const UserInfo = props => {
  const {
    email,
    coAppFirstName,
    coAppMiddleInitial,
    coAppLastName,
    dispatchChangePage,
    dispatchChangeGreenSkyApplicationInfoValue,
    children,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    greenSkyApplicationInformation,
    state,
    isCoAppSpouse,
  } = props;

  const isStateOfWisconsin = USStateEnum.Wisconsin.value === state;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_gscoappinfo1',
      trackerNames: [],
      title: 'ca_gscoappinfo1',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const UserInfoForm = () => (
    <Form>
      <Field
        component={Input}
        label="First Name"
        name="coAppFirstName"
        id="coAppFirstName"
        tooltipText={COAPPFIRSTNAME_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={Input}
        label={`Middle Initial${isRheem ? ' (If Applicable – Optional Field)' : ''}`}
        name="coAppMiddleInitial"
        id="coAppMiddleInitial"
        tooltipText={COAPPMIDDLEINITIAL_TOOLTIP_TEXT}
        type="text"
        maxLength="2"
      />
      <Field
        component={Input}
        label="Last Name"
        name="coAppLastName"
        id="coAppLastName"
        tooltipText={COAPPLASTNAME_TOOLTIP_TEXT}
        type="text"
      />
      <Button type="submit" title="Continue" />
      <Disclosure />
    </Form>
  );

  const UserInfoFormFormik = withFormik({
    mapPropsToValues: () => ({
      coAppFirstName: coAppFirstName || greenSkyApplicationInformation.coAppFirstName || '',
      coAppMiddleInitial:
        coAppMiddleInitial || greenSkyApplicationInformation.coAppMiddleInitial || '',
      coAppLastName: coAppLastName || greenSkyApplicationInformation.coAppLastName || '',
    }),

    validationSchema: Yup.object({
      coAppFirstName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .required('Please enter your first name.'),
      coAppMiddleInitial: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .max(2, 'Middle initial is too long.'),
      coAppLastName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .required('Please enter your last name.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Continue',
        did,
        dealerUserId,
      });
      dispatchChangeGreenSkyApplicationInfoValue('coAppFirstName', values.coAppFirstName.trim());
      dispatchChangeGreenSkyApplicationInfoValue(
        'coAppMiddleInitial',
        values.coAppMiddleInitial.trim(),
      );
      dispatchChangeGreenSkyApplicationInfoValue('coAppLastName', values.coAppLastName.trim());
      dispatchChangePage(GreenSkyFlowPageNumber.CoApplicantAddress);
      dispatchSubmitMindfire({
        email: email,
        lastPage: '9017',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(UserInfoForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={
            isStateOfWisconsin && isCoAppSpouse
              ? GreenSkyFlowPageNumber.ApplicantMarried
              : GreenSkyFlowPageNumber.HasCoApplicant
          }
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <UserInfoFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  state: state.preQualificationInformation.state,
  coAppFirstName: state.greenSkyApplicationInformation.coAppFirstName,
  coAppMiddleInitial: state.greenSkyApplicationInformation.coAppMiddleInitial,
  coAppLastName: state.greenSkyApplicationInformation.coAppLastName,
  isCoAppSpouse: state.greenSkyApplicationInformation.isCoAppSpouse,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  greenSkyApplicationInformation: state.greenSkyApplicationInformation,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeGreenSkyApplicationInfoValue: (key, value) =>
    dispatch(changeGreenSkyApplicationInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

UserInfo.propTypes = {
  coAppFirstName: PropTypes.string,
  coAppMiddleInitial: PropTypes.string,
  coAppLastName: PropTypes.string,
  dispatchChangePage: PropTypes.func,
  dispatchChangeGreenSkyApplicationInfoValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserInfo);
