import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import CustomInput from '../../../../components/UI/Form/Input/CustomInput';
import CustomDatePicker from '../../../../components/UI/Form/DatePicker/DatePicker';
import {
  changeApplicationValues,
  reactGAEvent,
  reactGAPageview,
  validDateFormat,
} from '../../../../helper';
import usStates from '../../../../assets/js/USStateEnum';
import idTypes from '../../../../assets/js/IdTypeEnum';
import {
  changePage,
  submitMindfire,
  changeAtWaterApplicationInfoValue,
  getAtWaterProgramsAndProducts,
} from '../../../../redux/actions/index';
import { AtWaterFlowPageNumber } from './AtWaterFlow';
import Select from '../../../../components/UI/Form/Select/Select';
import SelectWithCustomOnChange from '../../../../components/UI/Form/Select/SelectWithCustomOnChange';

const IdentificationInformation = props => {
  const {
    dispatchChangePage,
    dispatchChangeAtWaterApplicationInfoValue,
    children,
    did,
    dealerUserId,
    appSsn,
    applicantIdType,
    idNumber,
    idIssuedState,
    appIdIssueDate,
    appIdExpirationDate,
    ssnLastDigits,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_gsloaninformation',
      trackerNames: [],
      title: 'ca_gsloaninformation',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const handleIdTypeChange = value => {};

  const IdentificationInformationForm = ({ values }) => (
    <Form noValidate>
      <Field
        maxLength={9}
        component={CustomInput}
        label="Full SSN"
        id="appSsn"
        name="appSsn"
        tooltipText={''}
        type="password"
        number
        icon="lock"
      />
      <Field
        component={SelectWithCustomOnChange}
        label="ID Type"
        name="applicantIdType"
        id="applicantIdType"
        tooltipText={''}
        type="select"
        selectValues={idTypes}
        selectFirstName="Select One"
        handleChange={e => handleIdTypeChange(values)}
      />
      <Field
        maxLength={30}
        component={CustomInput}
        label="ID #"
        name="idNumber"
        id="idNumber"
        tooltipText={''}
        type="text"
      />
      <Field
        component={Select}
        label="Issue State"
        name="idIssuedState"
        id="idIssuedState"
        tooltipText={''}
        type="select"
        selectValues={usStates}
        selectFirstName="Select One"
      />
      <Field
        component={CustomDatePicker}
        name="appIdIssueDate"
        id="appIdIssueDate"
        tooltipText={''}
        label="Issue Date"
      />
      <Field
        component={CustomDatePicker}
        name="appIdExpirationDate"
        id="appIdExpirationDate"
        tooltipText={''}
        label="Expiration Date"
      />
      <Button type="submit" title="Next" />
    </Form>
  );

  const IdentificationInformationFormFormik = withFormik({
    mapPropsToValues: () => ({
      appSsn: appSsn || '',
      applicantIdType: applicantIdType || '',
      idNumber: idNumber || '',
      idIssuedState: idIssuedState || '',
      appIdIssueDate: appIdIssueDate || '',
      appIdExpirationDate: appIdExpirationDate || '',
    }),

    validationSchema: Yup.object({
      appSsn: Yup.string()
        .required('Please enter your full SSN.')
        .test(
          'len',
          'Must have 9 digits',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 9,
        )
        .test(
          'match',
          'Last 4 digits must match.',
          val =>
            ssnLastDigits === '' ||
            (val &&
              val
                .toString()
                .match(/\d/g)
                .join('')
                .substr(
                  val
                    .toString()
                    .match(/\d/g)
                    .join('').length - 4,
                ) === ssnLastDigits),
        )
        .typeError('Numbers only.'),
      applicantIdType: Yup.string().required('Please select your ID type.'),
      idNumber: Yup.string().required('Please enter your ID number.'),
      idIssuedState: Yup.string().required('Please select a state.'),
      appIdIssueDate: Yup.string()
        .required('Please enter the issue date.')
        .test(
          'incomplete',
          'Incomplete date',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 8,
        )
        .test('valid', 'Invalid date format.', val => val && !validDateFormat(val))
        .test('maxDate', 'Invalid date.', val => val && new Date(val) < new Date()),
      appIdExpirationDate: Yup.string()
        .required('Please enter the expiration date.')
        .test(
          'incomplete',
          'Incomplete date',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 8,
        )
        .test('valid', 'Invalid date format.', val => val && !validDateFormat(val))
        .test(
          'minDate',
          'Invalid date.',
          val => val && new Date(val) >= new Date().setHours(0, 0, 0, 0),
        ),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });

      changeApplicationValues(dispatchChangeAtWaterApplicationInfoValue, values);
      dispatchChangePage(AtWaterFlowPageNumber.AppPrimaryAddress);
    },
  })(IdentificationInformationForm);

  return (
    <Container>
      <Row>
        <BackBtn changePage={dispatchChangePage} page={AtWaterFlowPageNumber.ProgramInformation} />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <IdentificationInformationFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  ssnLastDigits: state.preQualificationInformation.ssnLastDigits || '',
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  dealerId: state.auth.dealerId,
  programsAndProducts: state.atWaterApplicationInformation.programsAndProducts,
  appSsn: state.atWaterApplicationInformation.appSsn,
  applicantIdType: state.atWaterApplicationInformation.applicantIdType,
  idNumber: state.atWaterApplicationInformation.idNumber,
  idIssuedState: state.atWaterApplicationInformation.idIssuedState,
  appIdIssueDate: state.atWaterApplicationInformation.appIdIssueDate,
  appIdExpirationDate: state.atWaterApplicationInformation.appIdExpirationDate,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeAtWaterApplicationInfoValue: (key, value) =>
    dispatch(changeAtWaterApplicationInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IdentificationInformation);
