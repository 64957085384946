import axios from 'axios';
import { addNotification, endLoading, startLoading } from './notificationActions';
import { baseURL, handleApiError } from './api';
import { changeSoftPullValue, mapApplicationToPrequalification } from './softPullActions';
import {
  allApplicationStatusEnum,
  getApplicationStatus,
} from '../../assets/js/ApplicationStatusEnum';
import { setFlow } from './naviActions';
import { microfFlow } from '../initialState';
import { changePreQualInfoValue } from './preQualInfoActions';
const qs = require('qs');

export const mapApplicationToUpgrade = app => dispatch => {
  if (app) {
    dispatch(mapApplicationToPrequalification(app));
    if (app.applicantPrimary) {
      const applicant = app.applicantPrimary;
      dispatch(changePreQualInfoValue('firstName', applicant.nameFirst));
      dispatch(changePreQualInfoValue('middleName', applicant.nameMiddle));
      dispatch(changePreQualInfoValue('lastName', applicant.nameLast));
      dispatch(
        changePreQualInfoValue(
          'phoneNumber',
          applicant.mobilePhone ? applicant.mobilePhone.number : null,
        ),
      );
      dispatch(changePreQualInfoValue('birthday', applicant.birthDate));
    }

    dispatch(changePreQualInfoValue('grossAmount', app.upgradeInformation?.grossYearlyIncome));
    dispatch(changePreQualInfoValue('jobPrice', app.totalJobPrice));
    dispatch(changePreQualInfoValue('appFullSsn', app.ssn));
    dispatch(changeSoftPullValue('upgradeResponse', app));
  }
};

export const upgradeSubmit = (request, history) => async dispatch => {
  dispatch(startLoading('Loading Upgrade Offers ...'));
  await axios
    .post(
      `${baseURL}/api/soft_pull/upgrade/submit`,
      qs.stringify({ data: '' + JSON.stringify(request) }),
    )
    .then(async response => {
      if (response.data) {
        if (
          getApplicationStatus(response.data.status) === allApplicationStatusEnum.UPGRADE_DECLINED
        ) {
          dispatch(setFlow(microfFlow));
          history.push('/RTO');
        } else {
          dispatch(changeSoftPullValue('upgradeResponse', response.data));
        }
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });

  dispatch(endLoading());
};

export const upgradeSelectOffer = (request, history) => async dispatch => {
  //dispatch(resetNewAppState());
  dispatch(startLoading('Loading ...'));
  await axios
    .post(`${baseURL}/api/soft_pull/upgrade/select_offer`, request)
    .then(async response => {
      if (response.data) {
        dispatch(changePreQualInfoValue('upgradeOfferSelected', true));
        window.open(response.data.offerURL, '_self');
        dispatch(endLoading());
      }
    })
    .catch(error => {
      dispatch(endLoading());
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
};

export const upgradeEnroll = dealerId => async dispatch => {
  dispatch(startLoading('Loading ...'));
  await axios
    .post(
      `${baseURL}/api/soft_pull/upgrade/upgrade_enroll?dealerId=${encodeURIComponent(dealerId)}`,
    )
    .then(async response => {
      if (response.data) {
        window.open(
          `https://www.upgrade.com/home-improvement/apply/?unique_seller_id=0058V00000DceWq&sponsor_id=a018V00000msrL8&external_reference_id=${dealerId}`,
          '_blank',
        );
        dispatch(endLoading());
      }
    })
    .catch(error => {
      dispatch(endLoading());
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
};
