import React from 'react';
import { findSearchInternalUserRoleEnum } from '../../../assets/js/UserRoleEnum';

const SearchTable = props => {
  const { internalUsers, history } = props;

  return internalUsers && internalUsers.userDTOs && internalUsers.total > 0 ? (
    <>
      <table className="table table-hover table-striped mt-2">
        <thead>
          <tr>
            <th>Email</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Role</th>
            <th>Last Login Time</th>
          </tr>
        </thead>
        <tbody>
          {internalUsers.userDTOs.map((internalUser, index) => (
            <tr
              className={'cursor-pointer'}
              key={index}
              onClick={() => {
                history.push(
                  '/portal/manage-internal-users/view-internal-user?id=' + internalUser.id,
                );
              }}
            >
              <td>{internalUser.email}</td>
              <td>{internalUser.firstName}</td>
              <td>{internalUser.lastName}</td>
              <td>{findSearchInternalUserRoleEnum(internalUser.role)}</td>
              <td>{internalUser.lastLogin}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  ) : (
    <div className="mt-4 mb-4">No internal user available...</div>
  );
};

export default SearchTable;
