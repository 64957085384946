import axios from 'axios';
import {
  CHANGE_MOSAIC_INFO_VALUE,
  SUBMIT_APPLICATION_SOFT_PULL_ERROR,
  SUBMIT_APPLICATION_SOFT_PULL_SUCCESS,
} from './actionTypes';
import { baseURL, handleApiError } from './api';
import {
  addNotification,
  endLoading,
  startLoading,
  changePage,
  changeMicrofInfoValue,
  changePreQualInfoValue,
  checkErrors,
} from './index';
import { mapApplicationToPrequalification } from './softPullActions';

const qs = require('qs');

export const changeMosaicInfoValue = (key, value) => ({
  type: CHANGE_MOSAIC_INFO_VALUE,
  key,
  value,
});

export const mapMosaicToMicrof = () => (dispatch, getState) => {
  const { preQualificationInformation } = getState();
  dispatch(
    changeMicrofInfoValue(
      'airConditioningSystem',
      preQualificationInformation.airConditioningSystem,
    ),
  );
  dispatch(changeMicrofInfoValue('isHomeowner', preQualificationInformation.isHomeowner));
  dispatch(changeMicrofInfoValue('email', preQualificationInformation.email));
  dispatch(changeMicrofInfoValue('password', preQualificationInformation.password));

  dispatch(changeMicrofInfoValue('firstName', preQualificationInformation.firstName));
  dispatch(changeMicrofInfoValue('middleName', preQualificationInformation.middleName));
  dispatch(changeMicrofInfoValue('lastName', preQualificationInformation.lastName));
  dispatch(changeMicrofInfoValue('phoneNumber', preQualificationInformation.phoneNumber));
  dispatch(changeMicrofInfoValue('city', preQualificationInformation.city));
  dispatch(changeMicrofInfoValue('state', preQualificationInformation.state));
  dispatch(changeMicrofInfoValue('street', preQualificationInformation.street));
  dispatch(changeMicrofInfoValue('zipcode', preQualificationInformation.zipcode));
};

export const mapMosaicToState = app => dispatch => {
  dispatch(changePreQualInfoValue('applicationId', app.applicationId));
  dispatch(mapApplicationToPrequalification(app));

  if (app.applicantPrimary) {
    const applicant = app.applicantPrimary;
    dispatch(changePreQualInfoValue('firstName', applicant.nameFirst));
    dispatch(changePreQualInfoValue('middleName', applicant.nameMiddle));
    dispatch(changePreQualInfoValue('lastName', applicant.nameLast));
    dispatch(
      changePreQualInfoValue(
        'phoneNumber',
        applicant.mobilePhone ? applicant.mobilePhone.number : null,
      ),
    );
    dispatch(changePreQualInfoValue('birthday', app.dob));
  }
};

export const submitMosaicApplication = (params, page, app) => async dispatch => {
  dispatch(startLoading());
  if (app !== null) {
    await axios
      .post(
        `${baseURL}/api/soft_pull/mosaic/resubmit`,
        qs.stringify({
          appToken: `${encodeURIComponent(app.consumerToken)}`,
          appId: `${encodeURIComponent(app.applicationId)}`,
          data: '' + JSON.stringify(params),
        }),
      )
      .then(async response => {
        dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_SUCCESS, payload: response.data });
        if (dispatch(checkErrors(response))) {
          dispatch(changePage(page));
          dispatch(endLoading());
        }
      })
      .catch(error => {
        dispatch(
          addNotification({
            message: handleApiError(error),
            messageType: 'Error',
          }),
        );
        dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_ERROR, payload: error });
        dispatch(endLoading());
      });
  } else {
    await axios
      .post(
        `${baseURL}/api/soft_pull/mosaic/submit`,
        qs.stringify({ data: '' + JSON.stringify(params) }),
      )
      .then(async response => {
        dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_SUCCESS, payload: response.data });
        if (dispatch(checkErrors(response))) {
          dispatch(changePage(page));
          dispatch(endLoading());
        }
      })
      .catch(error => {
        dispatch(
          addNotification({
            message: handleApiError(error),
            messageType: 'Error',
          }),
        );
        dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_ERROR, payload: error });
        dispatch(endLoading());
      });
  }
};
