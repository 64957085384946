import React from 'react';
import ReactGA from 'react-ga';
import { FaUniversity, FaUserPlus } from 'react-icons/fa';
import {
  GoBrowser,
  GoClippy,
  GoDashboard,
  GoFileSubmodule,
  GoGistSecret,
  GoGlobe,
  GoInfo,
  GoPlus,
  GoRadioTower,
  GoSearch,
  GoThreeBars,
} from 'react-icons/go';
import { IoIosSend } from 'react-icons/io';
import AddedFromEnum from './assets/js/AddedFromEnum';
import AirConditioningSystemEnum from './assets/js/AirConditioningSystemEnum';
import {
  isAquaDeclined,
  isEnerBankDeclined,
  isFoundationDeclined,
  isGreenSkyDeclined,
  isMosaicDeclined,
  isPowerPayDeclined,
  isWellsFargoDeclined,
} from './assets/js/ApplicationStatusEnum';
import FTLEmploymentTypeEnum from './assets/js/FTLEmploymentTypeEnum';
import functionalEntitlementsEnum from './assets/js/FunctionalEntitlementsEnum';
import selectedFinancierEnum, { getSelectedFinancierById } from './assets/js/SelectedFinancierEnum';
import userRoleEnum from './assets/js/UserRoleEnum';
import userType from './assets/js/UserTypeEnum';
import {
  getAddedFrom,
  getIsMicrof,
  getIsOptimus,
  getIsPool,
  getIsRheem,
  getOptimusNewLenderUrl,
  getResourcesUrl,
} from './config/settings';
import {
  aquaFlow,
  enerBankFlow,
  foundationFlow,
  ftlFlow,
  ftlPreQualificationFlow,
  greenSkyFlow,
  preQualificationFlow,
  mosaicPreQualificationFlow,
  powerPayPreQualificationFlow,
  microfFlow,
  mosaicFlow,
  powerPayFlow,
  rheemFlow,
  wellsFargoFlow,
} from './redux/initialState';

/*  ------------------ FORMIK STATUS DEVELOP ONLY ------------------
- import this in page
import { DisplayFormikState } from "../helper";

- add this in the end of Form from Formik
<DisplayFormikState {...props} />

    ---------------- END FORMIK STATUS DEVELOP ONLY ---------------- */
/*
export const DisplayFormikState = props => (
  <div style={{ margin: "1rem 0" }}>
    <h3
      style={{
        fontFamily: "monospace",
        background: "#505050",
        color: "#ffffff",
        padding: "5px"
      }}
    >
      Formik State
    </h3>
    <pre
      style={{
        background: "#f6f8fa",
        fontSize: ".65rem",
        padding: ".5rem"
      }}
    >
      <strong>props</strong> = {JSON.stringify(props, null, 2)}
    </pre>
  </div>
);*/

const isMicrof = getIsMicrof();
const isOptimus = getIsOptimus();
const isPools = getIsPool();

export function currencyFormat(num) {
  return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export const isSameAddress = (address1, address2) =>
  address1 &&
  address2 &&
  address1.city === address2.city &&
  address1.state === address2.state &&
  address1.street === address2.street &&
  address1.zipcode === address2.zipcode &&
  address1.poBox === address2.poBox;

const goog_snippet_vars = function() {
  var w = window;
  w.google_conversion_id = 676828187;
  w.google_conversion_label = '5BiWCIajicABEJuo3sIC';
};

export const goog_report_conversion = function(url) {
  goog_snippet_vars();
  window.google_conversion_format = '3';
  // eslint-disable-next-line no-new-object
  var opt = new Object();
  opt.onload_callback = function() {
    if (typeof url != 'undefined') {
      window.location = url;
    }
  };
  var conv_handler = window['google_trackConversion'];
  if (typeof conv_handler == 'function') {
    conv_handler(opt);
  }
};

export function ssnFormatting(val) {
  val = val
    .toString()
    .match(/\d/g)
    .join('');
  let first = val.substring(0, 3);
  let second = val.substring(3, 5);
  let third = val.substring(5, 9);

  return first + (second.length ? '-' + second : '') + (third.length ? '-' + third : '');
}

export function validDateFormat(val) {
  let month = val.substring(0, 2) > '12';
  let day = val.substring(3, 5) > '31';

  return month || day;
}

export const reactGAPageview = params => {
  if ((params.did !== null && params.did !== '') || params.dealerUserId === null) {
    ReactGA.pageview(params.path, params.trackerNames, params.title);
  }
};

export const reactGAEvent = params => {
  if ((params.did !== null && params.did !== '') || params.dealerUserId === null) {
    ReactGA.event({
      category: params.category,
      action: params.action,
      label: params.label,
    });
  }
};

export function isValid4SSN(ssn) {
  var numbers = '0123456789';
  if ((ssn || '').toString().length !== 4) {
    return false;
  }
  for (var i = 0; i < ssn.toString().length; i++) {
    if (numbers.indexOf(ssn.toString().charAt(i)) === -1) {
      return false;
    }
  }
  return true;
}

export function isValidCode(code) {
  var numbers = '0123456789';
  if ((code || '').toString().length !== 4) {
    return false;
  }
  for (var i = 0; i < code.toString().length; i++) {
    if (numbers.indexOf(code.toString().charAt(i)) === -1) {
      return false;
    }
  }
  return true;
}

export function showItemEmulationMode(userState) {
  const workUser =
    userState?.user.dealerUser || userState?.user.internalUser || userState?.user.consumerUser;
  if (userState && userState.emulatorId && userState.user) {
    if (
      workUser &&
      (workUser?.addedFrom === AddedFromEnum.MICROF.value ||
        workUser?.addedFrom === AddedFromEnum.OPTIMUS.value)
    ) {
      return true;
    } else {
      return false;
    }
  }
  return true;
}

export const buildNavBar = (userState, dispatchUpgradeEnroll) => {
  const { functionalEntitlements, user, dealerStatsSummaryDTO } = userState;
  const {
    appliedForAquaFinance,
    appliedForPowerPay,
    appliedForMosaic,
    appliedForEnerBank,
    appliedForGreenSky,
    appliedForFoundation,
    appliedForFTL,
    dealerId,
  } = user?.dealerUser?.dealer || {};

  const isMaintenance =
    dealerStatsSummaryDTO?.siteMessages &&
    dealerStatsSummaryDTO?.siteMessages.length > 0 &&
    dealerStatsSummaryDTO?.siteMessages.find(x => x.message.includes('under maintenance'))
      ? true
      : false;

  let navBarComponents = [];
  if (
    user &&
    user.dealerUser &&
    user.dealerUser.userType === userType.USER_DEALER &&
    user.dealerUser.role === userRoleEnum.DEALER_CANDIDATE.value
  ) {
    navBarComponents.push({
      icon: <GoDashboard size={18} />,
      description: 'Dashboard',
      active: true,
      url: '/candidate-contractor-dashboard',
    });
  } else if (user && user.dealerUser && user.dealerUser.userType === userType.USER_DEALER) {
    navBarComponents.push({
      icon: <GoDashboard size={18} />,
      description: 'Dashboard',
      active: true,
      url: '/dashboard',
    });
  }
  if (user && user.consumerUser && user.consumerUser.userType === userType.USER_CONSUMER) {
    navBarComponents.push({
      icon: <GoDashboard size={18} />,
      description: 'Dashboard',
      active: true,
      url: '/consumer-dashboard',
    });
  }
  if (
    functionalEntitlements &&
    functionalEntitlements.includes(functionalEntitlementsEnum.SELFDEALER_APPLICATION_VIEW) &&
    user.dealerUser &&
    user.dealerUser.dealer &&
    user.dealerUser.dealer.allowedForApiBanks.length > 0 &&
    !user.dealerUser.dealer.greenskyCredentialsSubmitted
  ) {
    navBarComponents.push({
      icon: <GoRadioTower size={18} />,
      description: 'Greensky Api Credentials',
      url: '/greensky-api-credentials',
    });
  }
  if (
    functionalEntitlements &&
    functionalEntitlements.includes(functionalEntitlementsEnum.SELFDEALER_USER_SEARCH)
  ) {
    navBarComponents.push({
      icon: <GoSearch size={18} />,
      description: 'Search Users',
      url: '/search-users',
    });
  }
  if (
    functionalEntitlements &&
    functionalEntitlements.includes(functionalEntitlementsEnum.SELFDEALER_USER_CREATE) &&
    showItemEmulationMode(userState)
  ) {
    navBarComponents.push({
      icon: <GoPlus size={18} />,
      disabled: isMaintenance,
      description: 'New User',
      url: '/create-contractor-user',
    });
  }
  if (
    functionalEntitlements &&
    functionalEntitlements.includes(functionalEntitlementsEnum.INTERNAL_USER_CREATE)
  ) {
    navBarComponents.push({
      icon: <FaUserPlus size={18} />,
      description: 'New Internal User',
      url: '/create-internal-user',
    });
  }
  if (
    functionalEntitlements &&
    functionalEntitlements.includes(functionalEntitlementsEnum.INTERNAL_USER_SEARCH)
  ) {
    navBarComponents.push({
      icon: <GoSearch size={18} />,
      description: 'Manage Internal Users',
      url: '/manage-internal-users',
    });
  }
  if (
    functionalEntitlements &&
    functionalEntitlements.includes(functionalEntitlementsEnum.DEALER_USER_SEARCH)
  ) {
    navBarComponents.push({
      icon: <GoSearch size={18} />,
      description: 'Manage Contractor Users',
      url: '/manage-contractor-users',
    });
  }
  if (
    functionalEntitlements &&
    functionalEntitlements.includes(functionalEntitlementsEnum.APPLICATION_SEARCH)
  ) {
    navBarComponents.push({
      icon: <GoSearch size={18} />,
      description: 'Search Application',
      url: '/search-application',
    });
  }
  if (
    appliedForGreenSky &&
    functionalEntitlements &&
    functionalEntitlements.includes(functionalEntitlementsEnum.SELFDEALER_DEALER_INFORMATION)
  ) {
    navBarComponents.push({
      icon: <GoInfo size={18} />,
      description: 'GreenSky Transaction History',
      url: '/greensky-transaction-history',
    });
  }
  if (
    functionalEntitlements &&
    functionalEntitlements.includes(functionalEntitlementsEnum.SELFDEALER_DEALER_INFORMATION)
  ) {
    navBarComponents.push({
      icon: <GoInfo size={18} />,
      description: 'Application History',
      url: '/application-history',
    });
  }
  if (
    !getIsRheem() &&
    functionalEntitlements &&
    functionalEntitlements.includes(functionalEntitlementsEnum.SELFDEALER_APPLICATION_VIEW) &&
    functionalEntitlements.includes(functionalEntitlementsEnum.SELFDEALER_USER_CREATE) &&
    functionalEntitlements.includes(functionalEntitlementsEnum.SELFDEALER_USER_UPDATE) &&
    user &&
    user.dealerUser &&
    user.dealerUser.dealer &&
    user.dealerUser.dealer.dealerBanks &&
    !isBankUser(
      user.dealerUser.dealer.dealerBanks?.filter(
        bank => bank.bankId !== selectedFinancierEnum.MICROF.id,
      ),
      selectedFinancierEnum.MICROF.id,
    ) &&
    showItemEmulationMode(userState)
  ) {
    navBarComponents.push({
      icon: <FaUniversity size={18} />,
      description: 'Financing Option',
      url: '/financing-option',
    });
  }
  if (
    functionalEntitlements &&
    functionalEntitlements.includes(functionalEntitlementsEnum.SELFDEALER_APPLICATION_VIEW) &&
    functionalEntitlements.includes(functionalEntitlementsEnum.SELFDEALER_USER_CREATE) &&
    functionalEntitlements.includes(functionalEntitlementsEnum.SELFDEALER_USER_UPDATE) &&
    ((isMicrof &&
      (!appliedForAquaFinance || !appliedForPowerPay || !appliedForMosaic || !appliedForFTL)) ||
      (isOptimus &&
        (!appliedForMosaic ||
          !appliedForEnerBank ||
          !appliedForGreenSky ||
          !appliedForFoundation))) &&
    showItemEmulationMode(userState)
  ) {
    if (isOptimus) {
      navBarComponents.push({
        icon: <GoInfo size={18} />,
        disabled: isMaintenance,
        description: 'Add New Lender',
        clickHandler: () => {
          window.location.href = getOptimusNewLenderUrl();
        },
      });
    } else {
      navBarComponents.push({
        icon: <GoInfo size={18} />,
        description: 'Apply for Upgrade',
        clickHandler: () => {
          dispatchUpgradeEnroll(dealerId);
        },
      });
    }
  }
  if (
    functionalEntitlements &&
    functionalEntitlements.includes(functionalEntitlementsEnum.RESOURCE_LIBRARY) &&
    showItemEmulationMode(userState)
  ) {
    if (isOptimus) {
      navBarComponents.push({
        icon: <GoThreeBars size={18} />,
        description: 'Virtual Training Resources',
        clickHandler: () =>
          window.open('https://finportal.egia.org/finance-enrollment/index.php', '_blank'),
      });
    } else {
      if (!isPools) {
        navBarComponents.push({
          icon: <GoThreeBars size={18} />,
          description: 'Training and Marketing Resources',
          url: '/resources',
        });
      }
    }
  }
  if (
    functionalEntitlements &&
    functionalEntitlements.includes(functionalEntitlementsEnum.APPLICATION_MOVE)
  ) {
    navBarComponents.push({
      icon: <IoIosSend size={18} />,
      description: 'Move App to Contractor',
      url: '/move-app-to-contractor',
    });
  }
  if (
    functionalEntitlements &&
    functionalEntitlements.includes(functionalEntitlementsEnum.ROJOS_USER_CREATE)
  ) {
    navBarComponents.push({
      icon: <GoPlus size={18} />,
      description: 'Add Users to Rojos',
      url: '/add-users-to-rojos',
    });
  }
  if (
    functionalEntitlements &&
    functionalEntitlements.includes(functionalEntitlementsEnum.FIND_A_CONTRACTOR)
  ) {
    navBarComponents.push({
      icon: <IoIosSend size={18} />,
      description: 'Find a Contractor',
      url: '/find-a-contractor',
    });
  }
  if (
    functionalEntitlements &&
    functionalEntitlements.includes(functionalEntitlementsEnum.UPLOADED_DOCUMENTS_VIEW)
  ) {
    navBarComponents.push({
      icon: <GoSearch size={18} />,
      description: 'Uploaded Documents',
      url: '/uploaded-documents',
    });
  }
  if (
    functionalEntitlements &&
    functionalEntitlements.includes(functionalEntitlementsEnum.MANAGE_CONTRACTOR_STATES)
  ) {
    navBarComponents.push({
      icon: <GoSearch size={18} />,
      description: 'Manage Contractor States',
      url: '/manage-contractor-states',
    });
  }
  if (
    functionalEntitlements &&
    functionalEntitlements.includes(functionalEntitlementsEnum.ORIGINATION_EXPERT_SEND_DATA)
  ) {
    navBarComponents.push({
      icon: <IoIosSend size={18} />,
      description: 'Send Data to Origination Expert',
      url: '/origination-expert-send-data',
    });
  }
  if (
    functionalEntitlements &&
    functionalEntitlements.includes(functionalEntitlementsEnum.MANAGE_CONTRACTOR_ZIPCODES)
  ) {
    navBarComponents.push({
      icon: <GoSearch size={18} />,
      description: 'Manage Contractor Zipcodes',
      url: '/manage-contractor-zipcodes',
    });
  }
  if (
    functionalEntitlements &&
    functionalEntitlements.includes(functionalEntitlementsEnum.DEALER_USER_SEARCH)
  ) {
    navBarComponents.push({
      icon: <GoSearch size={18} />,
      description: 'Manage Primary Users',
      url: '/manage-primary-users',
    });
  }
  if (
    functionalEntitlements &&
    functionalEntitlements.includes(functionalEntitlementsEnum.RESTRICT_AQUA_PROMOTIONS)
  ) {
    navBarComponents.push({
      icon: <GoSearch size={18} />,
      description: 'Restrict Aqua Promotions',
      url: '/restrict-aqua-promotions',
    });
  }
  if (
    functionalEntitlements &&
    functionalEntitlements.includes(
      functionalEntitlementsEnum.RESEND_APPLICATION_TO_DECISION_ENGINE,
    )
  ) {
    navBarComponents.push({
      icon: <IoIosSend size={18} />,
      description: 'Resend application to decision engine',
      url: '/resend-application-to-decision-engine',
    });
  }
  if (
    isPools &&
    functionalEntitlements &&
    functionalEntitlements.includes(functionalEntitlementsEnum.UPDATE_MERCHANT_NUMBER)
  ) {
    navBarComponents.push({
      icon: <IoIosSend size={18} />,
      description: 'Update Merchant Number',
      url: '/update-merchant-number',
    });
  }

  return navBarComponents;
};

export function isValidPass(pass) {
  var num = '0123456789';
  var isNum = false;
  var upp = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  var isUpp = false;
  var low = 'abcdefghijklmnopqrstuvwxyz';
  var isLow = false;
  var chars = 0;
  for (var i = 0; i < pass.toString().length; i++) {
    if (!isNum && num.indexOf(pass.toString().charAt(i)) > -1) {
      isNum = true;
    } else if (!isUpp && upp.indexOf(pass.toString().charAt(i)) > -1) {
      isUpp = true;
      chars++;
    } else if (!isLow && low.indexOf(pass.toString().charAt(i)) > -1) {
      isLow = true;
      chars++;
    }
    if (isNum && isUpp && isLow && chars >= 3) {
      return true;
    }
  }
  return false;
}

export function renderFavicon() {
  let faviconPath = './favicon-microf.ico';
  switch (getAddedFrom()) {
    case AddedFromEnum.RHEEM.value:
      faviconPath = './favicon-rheem.ico';
      break;
    case AddedFromEnum.OPTIMUS.value:
      faviconPath = './favicon-optimus.ico';
      break;
    case AddedFromEnum.POOLS.value:
      faviconPath = './favicon-atwater.ico';
      break;

    default:
      break;
  }
  var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = faviconPath;
  document.getElementsByTagName('head')[0].appendChild(link);
}

export function getAppTier(tier) {
  switch (tier) {
    case 'TIER_01':
      return 1;
    case 'TIER_02':
      return 2;
    case 'TIER_03':
      return 3;
    case 'TIER_04':
      return 4;
    case 'TIER_05':
      return 5;
    case 'TIER_06':
      return 6;
    case 'TIER_07':
      return 7;
    case 'TIER_08':
      return 8;
    case 'TIER_09':
      return 9;
    case 'TIER_10':
      return 10;
    default:
      return 0;
  }
}

export function isValidEmail(emailAddress) {
  var pattern = new RegExp(/[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/gim);
  return pattern.test(emailAddress);
}

export function isNoreplyEmail(emailAddress) {
  var pattern = new RegExp(/[Nn]oreply/g);
  return !pattern.test(emailAddress);
}

export const getAddedByAPIValue = value => {
  return value !== 'added_by_api' ? value : null;
};

export const isStateAllowed = state => {
  const notAllowedStates = ['NJ', 'WI', 'MN', 'WY', 'AK', 'HI', 'PR', 'VT'];
  let result = false;

  if (state) {
    result = !notAllowedStates.includes(state);
  }
  return result;
};

export const isSSNanITIN = ssn => {
  if (ssn.length < 9) {
    return false;
  }
  let s1 = 1 * ssn[0];
  let s2 = 1 * (ssn[3] + ssn[4]);
  if (s1 === 9 && s2 >= 70 && s2 <= 88) {
    return true;
  }
  return false;
};

export const isWellsFargoFlow = flow => wellsFargoFlow === flow;

export const getAddress = address =>
  (address.street ? address.street + ', ' : '') +
  (address.city ? address.city + ', ' : '') +
  (address.state ? address.state + ', ' : '') +
  (address.zip || '');

export const getInstallAddress = address =>
  (address.street ? address.street + ', ' : '') +
  (address.unit ? address.unit + ', ' : '') +
  (address.city ? address.city + ', ' : '') +
  (address.state ? address.state + ', ' : '') +
  (address.zip || '');

export const getTimeAtPropery = time =>
  (time.years && time.years !== 0 ? time.years + (time.years !== 1 ? ' Years ' : ' Year ') : '') +
  (time.months && time.months !== 0
    ? time.months + (time.months !== 1 ? ' Months' : ' Month')
    : '');

export const isBankUser = (dealerBanks, bankId) => {
  if (!dealerBanks) {
    return false;
  }
  for (let i = 0; i < dealerBanks.length; i++) {
    if (bankId === dealerBanks[i].bankId) {
      return true;
    }
  }
  return false;
};

export const nextFinancingOption = ({ dealer, airConditioningSystem }, currentFO) => {
  if (dealer && isBankUser(dealer.dealerBanks, dealer.secondBankId)) {
    let nextFO = getSelectedFinancierById(dealer.secondBankId);
    if (nextFO && nextFO !== currentFO) {
      return nextFO;
    } else if (
      airConditioningSystem === AirConditioningSystemEnum.AIR_CONDITION_OR_HEATING_SYSTEM.id
    ) {
      return selectedFinancierEnum.MICROF;
    }
  } else if (
    airConditioningSystem === AirConditioningSystemEnum.AIR_CONDITION_OR_HEATING_SYSTEM.id
  ) {
    return selectedFinancierEnum.MICROF;
  }

  return null;
};

export const showFtlRepairOption = (application, pqi) => {
  if (application) {
    if (
      application.dealer &&
      isBankUser(application.dealer.dealerBanks, application.dealer.secondBankId)
    ) {
      if (
        application.selectedFinancier === selectedFinancierEnum.GREENSKY.value ||
        application.selectedFinancier === selectedFinancierEnum.ENERBANK.value ||
        application.selectedFinancier === selectedFinancierEnum.MOSAIC.value ||
        (application.selectedFinancier === selectedFinancierEnum.FTL.value &&
          isMosaicDeclined(application.status)) ||
        (application.selectedFinancier === selectedFinancierEnum.FTL.value &&
          isEnerBankDeclined(application.status)) ||
        (application.selectedFinancier === selectedFinancierEnum.FTL.value &&
          isGreenSkyDeclined(application.status))
      ) {
        return true;
      }
    }
  } else if (pqi) {
    if (pqi.score && pqi.scoreLimit && pqi.score < pqi.scoreLimit) {
      return true;
    }
  }

  return false;
};

export const nextFlowByNextFinancingOption = nextFO => {
  if (nextFO === selectedFinancierEnum.POWER_PAY) {
    return powerPayFlow;
  } else if (nextFO === selectedFinancierEnum.AQUA_FINANCE) {
    return aquaFlow;
  } else if (nextFO === selectedFinancierEnum.MOSAIC) {
    return mosaicFlow;
  } else if (nextFO === selectedFinancierEnum.WELLS_FARGO) {
    return wellsFargoFlow;
  } else if (nextFO === selectedFinancierEnum.RHEEM) {
    return rheemFlow;
  } else if (nextFO === selectedFinancierEnum.FOUNDATION) {
    return foundationFlow;
  } else if (nextFO === selectedFinancierEnum.GREENSKY) {
    return greenSkyFlow;
  } else if (nextFO === selectedFinancierEnum.ENERBANK) {
    return enerBankFlow;
  } else {
    return microfFlow;
  }
};

export const nextPageByNextFinancingOption = nextFO => {
  if (nextFO === selectedFinancierEnum.POWER_PAY) {
    return '/powerpay';
  } else if (nextFO === selectedFinancierEnum.AQUA_FINANCE) {
    return '/Aqua';
  } else if (nextFO === selectedFinancierEnum.MOSAIC) {
    return '/mosaic';
  } else if (nextFO === selectedFinancierEnum.WELLS_FARGO) {
    return '/WellsFargo';
  } else if (nextFO === selectedFinancierEnum.RHEEM) {
    return '/rheem';
  } else if (nextFO === selectedFinancierEnum.FOUNDATION) {
    return '/foundation';
  } else if (nextFO === selectedFinancierEnum.GREENSKY) {
    return '/greensky';
  } else if (nextFO === selectedFinancierEnum.ENERBANK) {
    return '/enerbank';
  } else {
    return '/RTO';
  }
};

const spectrumAcFlows = [
  preQualificationFlow,
  aquaFlow,
  mosaicPreQualificationFlow,
  mosaicFlow,
  powerPayPreQualificationFlow,
  powerPayFlow,
  ftlPreQualificationFlow,
  ftlFlow,
];

export const isSpectrumAcFlow = flowName => spectrumAcFlows.includes(flowName);

export const changeApplicationValues = (changeValue, values) =>
  Object.keys(values).forEach(key => changeValue(key, values[key]));

export const isLenderDeclined = appStatus =>
  isPowerPayDeclined(appStatus) ||
  isWellsFargoDeclined(appStatus) ||
  isAquaDeclined(appStatus) ||
  isMosaicDeclined(appStatus) ||
  isEnerBankDeclined(appStatus) ||
  isFoundationDeclined(appStatus) ||
  isGreenSkyDeclined(appStatus);

export const isHvacProjectType = obj => obj.name.includes('Heating') || obj.name.includes('HVAC');

export const isApplicantEmployedFTL = appEmploymentStatus =>
  appEmploymentStatus === FTLEmploymentTypeEnum.FULLTIME.value ||
  appEmploymentStatus === FTLEmploymentTypeEnum.PARTTIME.value ||
  appEmploymentStatus === FTLEmploymentTypeEnum.SELF_EMPLOYED.value ||
  appEmploymentStatus === FTLEmploymentTypeEnum.CONTRACT.value;

export const filterSelectedQuestions = questions =>
  questions?.filter(question => !question.questionChoices.some(choice => choice.selected));

export const calculateMonthlyNetIncome = amount => {
  return (amount / 12) * (0.08492 / 0.12);
};

export const showYears = (months, cap) => {
  if (months === '12') {
    return `${cap ? '1 Year' : '1 year'}`;
  } else if (months > 12) {
    return `${months / 12} ${cap ? 'Years' : 'years'}`;
  } else {
    return `${months} ${cap ? 'Months' : 'months'}`;
  }
};
