import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import ownershipEnum from '../../../../assets/js/OwnershipEnum';
import CurrencyFormat from '../../../../components/UI/Form/NumberFormat/CurrencyFormat';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import Input from '../../../../components/UI/Form/Input/CustomInput';
import Select from '../../../../components/UI/Form/Select/Select';
import { reactGAEvent, reactGAPageview } from '../../../../helper';
import { changePage, submitMindfire, changeFoundationInfoValue } from '../../../../redux/actions';
import { FoundationFlowPageNumber } from './FoundationFlow';
import {
  OWNERSHIP_TOOLTIP_TEXT,
  PROPERTY_MONTHLY_PAYMENT_TOOLTIP_TEXT,
  PROPERTY_MONTHS_TOOLTIP_TEXT,
  PROPERTY_YEARS_TOOLTIP_TEXT,
} from '../../../../constants/tooltipTexts';

const PropertyDetails = props => {
  const {
    email,
    dispatchChangePage,
    dispatchChangeFoundationInfoValue,
    children,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    propertyYears,
    propertyMonths,
    propertyMonthlyPayment,
    ownership,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_fdpropertydetails',
      trackerNames: [],
      title: 'ca_fdpropertydetails',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const PropertyDetailsForm = () => (
    <Form>
      <Field
        component={Select}
        label="Ownership"
        name="ownership"
        id="ownership"
        tooltipText={OWNERSHIP_TOOLTIP_TEXT}
        type="select"
        selectValues={ownershipEnum}
        selectFirstName="Select One"
      />
      <Field
        component={CurrencyFormat}
        placeholder="$0"
        label="Monthly Payment (if no payment enter $0)"
        name="propertyMonthlyPayment"
        id="propertyMonthlyPayment"
        tooltipText={PROPERTY_MONTHLY_PAYMENT_TOOLTIP_TEXT}
        type="text"
      />
      <Row className="mt-2">
        <Col>
          <Label>
            <b>Time at address</b>
          </Label>
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          <Field
            component={Input}
            placeholder="Years"
            formGroupClass="mt-0 mb-2"
            labelClassName="d-none"
            name="propertyYears"
            id="propertyYears"
            tooltipText={PROPERTY_YEARS_TOOLTIP_TEXT}
            type="number"
            min={0}
          />
        </Col>
        <Col sm="6">
          <Field
            component={Input}
            placeholder="Months"
            formGroupClass="mt-0 mb-2"
            labelClassName="d-none"
            name="propertyMonths"
            id="propertyMonths"
            tooltipText={PROPERTY_MONTHS_TOOLTIP_TEXT}
            type="number"
            min={0}
          />
        </Col>
      </Row>
      <Button type="submit" title="Next" />
    </Form>
  );

  const PropertyDetailsFormFormik = withFormik({
    mapPropsToValues: () => ({
      ownership: ownership || '',
      propertyMonthlyPayment: propertyMonthlyPayment || '',
      propertyYears: propertyYears || '',
      propertyMonths: propertyMonths || '',
    }),

    validationSchema: Yup.object({
      ownership: Yup.string().required('Please select an option.'),
      propertyMonthlyPayment: Yup.number().required('Please enter your monthly payment.'),
      propertyYears: Yup.number()
        .moreThan(-1, 'Please enter a positive number.')
        .integer('Please enter an integer number.')
        .max(70, 'Must be less than or equal to 70.')
        .required('Please enter years.'),
      propertyMonths: Yup.number()
        .moreThan(-1, 'Please enter a positive number.')
        .integer('Please enter an integer number.')
        .max(11, 'Must be less than or equal to 11.')
        .required('Please enter months.'),
    }),

    handleSubmit: async values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Continue',
        did,
        dealerUserId,
      });
      dispatchChangeFoundationInfoValue('ownership', values.ownership);
      dispatchChangeFoundationInfoValue('propertyMonthlyPayment', values.propertyMonthlyPayment);
      dispatchChangeFoundationInfoValue('propertyYears', values.propertyYears);
      dispatchChangeFoundationInfoValue('propertyMonths', values.propertyMonths);
      dispatchSubmitMindfire({
        email: email,
        lastPage: '10013',
        dealerUserId: dealerUserId,
        did: did,
      });

      dispatchChangePage(FoundationFlowPageNumber.InstallAddress);
    },
  })(PropertyDetailsForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={FoundationFlowPageNumber.AppIdentificationInfo}
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <PropertyDetailsFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  ownership: state.foundationInformation.ownership,
  propertyMonthlyPayment: state.foundationInformation.propertyMonthlyPayment,
  propertyYears: state.foundationInformation.propertyYears,
  propertyMonths: state.foundationInformation.propertyMonths,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeFoundationInfoValue: (key, value) =>
    dispatch(changeFoundationInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PropertyDetails);
