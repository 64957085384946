import React, { useState } from 'react';
import { FaSyncAlt } from 'react-icons/fa';
import { Tooltip } from 'reactstrap';
import { connect } from 'react-redux';
import {
  getAtWaterGreenskySingleTransaction,
  getSingleTransaction,
} from '../../../redux/actions/index';
import { getIsPool } from '../../../config/settings';

const RefreshTransactions = props => {
  const {
    index,
    transactionId,
    dispatchGetSingleTransaction,
    dispatchGetAtWaterSingleTransaction,
  } = props;

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  let reloadPage = false;

  const getTransactionDetails = transactionId => {
    if (transactionId) {
      reloadPage = true;
      if (getIsPool()) {
        dispatchGetAtWaterSingleTransaction(transactionId, reloadPage);
      } else {
        dispatchGetSingleTransaction(transactionId, reloadPage);
      }
    }
  };

  return (
    <td>
      <div id={`tooltip-${index}`} className="cursor-pointer">
        <FaSyncAlt color={'black'} size={20} onClick={() => getTransactionDetails(transactionId)} />
      </div>{' '}
      <Tooltip
        placement="auto"
        target={`tooltip-${index}`}
        isOpen={tooltipOpen}
        toggle={toggleTooltip}
        autohide={false}
      >
        {`Click to refresh transaction status`}
      </Tooltip>
    </td>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  dispatchGetAtWaterSingleTransaction: (transactionId, reloadPage) =>
    dispatch(getAtWaterGreenskySingleTransaction(transactionId, reloadPage)),
  dispatchGetSingleTransaction: (transactionId, reloadPage) =>
    dispatch(getSingleTransaction(transactionId, reloadPage)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RefreshTransactions);
