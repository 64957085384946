import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import usStates from '../../../assets/js/USStateEnum';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import CustomInput from '../../../components/UI/Form/Input/CustomInput';
import SsnFormat from '../../../components/UI/Form/NumberFormat/SsnFormat';
import Select from '../../../components/UI/Form/Select/Select';
import { isSSNanITIN, isStateAllowed, reactGAEvent, reactGAPageview } from '../../../helper';
import {
  changePage,
  setFlow,
  submitMindfire,
  changePowerPayInfoValue,
} from '../../../redux/actions/index';
import { microfFlow, preQualificationFlow } from '../../../redux/initialState';
import { PowerPayFlowPageNumber } from './PowerPayFlow';
import { MicrofFlowPageNumber } from '../../Microf/MicrofFlow';
import { AquaPreQualificationFlowPageNumber } from '../../PreQualification/preQualificationFlow';
import AirConditioningSystemEnum from '../../../assets/js/AirConditioningSystemEnum';
import {
  APPFULLSSN_TOOLTIP_TEXT,
  APP_DRIVER_LICENSE_NUMBER_TOOLTIP_TEXT,
  APP_DRIVER_LICENSE_STATE_TOOLTIP_TEXT,
  MILITARY_ACTIVE_TOOLTIP_TEXT,
  US_CITIZEN_TOOLTIP_TEXT,
} from '../../../constants/tooltipTexts';

const AppIdentificationInfo = props => {
  const {
    email,
    dispatchChangePage,
    dispatchChangePowerPayInfoValue,
    powerPayInformation,
    children,
    ssnLastDigits,
    isReview,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    dispatchSetFlow,
    history,
    pqi,
  } = props;

  const yesNo = {
    Yes: { value: true, name: 'Yes' },
    No: { value: false, name: 'No' },
  };

  useEffect(() => {
    reactGAPageview({
      path: '/ca_ppconsumerid',
      trackerNames: [],
      title: 'ca_ppconsumerid',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const AppIdentificationInfoForm = () => (
    <Form noValidate autoComplete="off">
      <Field
        maxLength={30}
        component={CustomInput}
        label="Driver License Number"
        name="appDriversLicenseNumber"
        id="appDriversLicenseNumber"
        type="text"
        tooltipText={APP_DRIVER_LICENSE_NUMBER_TOOLTIP_TEXT}
      />
      <Field
        component={Select}
        label="Driver License State"
        name="appDriversLicenseState"
        id="appDriversLicenseState"
        type="select"
        selectValues={usStates}
        selectFirstName="Select One"
        tooltipText={APP_DRIVER_LICENSE_STATE_TOOLTIP_TEXT}
      />
      <Field
        component={Select}
        label="Are you active military?"
        name="militaryActive"
        id="militaryActive"
        type="select"
        selectValues={yesNo}
        selectFirstName="Select One"
        tooltipText={MILITARY_ACTIVE_TOOLTIP_TEXT}
      />
      <Field
        component={Select}
        label="Are you a US citizen?"
        name="usCitizen"
        id="usCitizen"
        type="select"
        selectValues={yesNo}
        selectFirstName="Select One"
        tooltipText={US_CITIZEN_TOOLTIP_TEXT}
      />
      <Field
        component={SsnFormat}
        label="Your full Social Security Number"
        name="appFullSsn"
        type="password"
        icon="lock"
        tooltipText={APPFULLSSN_TOOLTIP_TEXT}
      />
      <p className="info">Click on the padlock to view the hidden text</p>
      <Button type="submit" title={isReview ? 'Review' : 'Next'} />
    </Form>
  );

  const AppIdentificationInfoFormFormik = withFormik({
    mapPropsToValues: () => ({
      appDriversLicenseState: powerPayInformation.appDriversLicenseState || '',
      appDriversLicenseNumber: powerPayInformation.appDriversLicenseNumber || '',
      militaryActive: powerPayInformation.isAppMilitaryActive || '',
      usCitizen: powerPayInformation.isAppUsCitizen || '',
      appFullSsn: powerPayInformation.appFullSsn || '',
    }),

    validationSchema: Yup.object({
      appDriversLicenseState: Yup.string().required('Please select your issue state.'),
      appDriversLicenseNumber: Yup.string().required('Please enter your drivers license number.'),
      militaryActive: Yup.bool().required('Please select an option.'),
      usCitizen: Yup.bool().required('Please select an option.'),
      appFullSsn: Yup.string()
        .required('Please enter your full SSN.')
        .test(
          'len',
          'Must have 9 digits',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 9,
        )
        .test(
          'match',
          'Last 4 digits must match.',
          val =>
            ssnLastDigits === '' ||
            (val &&
              val
                .toString()
                .match(/\d/g)
                .join('')
                .substr(
                  val
                    .toString()
                    .match(/\d/g)
                    .join('').length - 4,
                ) === ssnLastDigits),
        )
        .typeError('Numbers only.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      let ssn = values.appFullSsn.match(/\d/g).join('');
      dispatchChangePowerPayInfoValue('appDriversLicenseState', values.appDriversLicenseState);
      dispatchChangePowerPayInfoValue('appDriversLicenseNumber', values.appDriversLicenseNumber);
      dispatchChangePowerPayInfoValue('isAppMilitaryActive', values.militaryActive);
      dispatchChangePowerPayInfoValue('isAppUsCitizen', values.usCitizen);
      dispatchChangePowerPayInfoValue('appFullSsn', ssn);

      if (isSSNanITIN(ssn)) {
        if (
          !isStateAllowed(pqi.state) ||
          pqi.airConditioningSystem !== AirConditioningSystemEnum.AIR_CONDITION_OR_HEATING_SYSTEM.id
        ) {
          dispatchSetFlow(preQualificationFlow);
          dispatchChangePage(AquaPreQualificationFlowPageNumber.NotAllowed);
          history.push('/');
        } else {
          dispatchSetFlow(microfFlow);
          dispatchChangePage(MicrofFlowPageNumber.Landing);
          history.push('/RTO');
        }
      } else {
        dispatchChangePage(
          isReview
            ? PowerPayFlowPageNumber.ApplicationReview
            : PowerPayFlowPageNumber.PropertyDetails,
        );
      }
      dispatchSubmitMindfire({
        email: email,
        lastPage: '6011',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(AppIdentificationInfoForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={
            isReview ? PowerPayFlowPageNumber.ApplicationReview : PowerPayFlowPageNumber.LoanAmount
          }
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <AppIdentificationInfoFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  powerPayInformation: state.powerPayInformation,
  pqi: state.preQualificationInformation,
  ssnLastDigits: state.preQualificationInformation.ssnLastDigits || '',
  email: state.preQualificationInformation.email,
  isReview: state.powerPayInformation.isReview,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetFlow: flowName => dispatch(setFlow(flowName)),
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangePowerPayInfoValue: (key, value) => dispatch(changePowerPayInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

AppIdentificationInfo.propTypes = {
  powerPayInformation: PropTypes.object,
  ssnLastDigits: PropTypes.string,
  dispatchChangePage: PropTypes.func,
  dispatchChangePowerPayInfoValue: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AppIdentificationInfo),
);
