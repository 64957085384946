import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Section from '../../components/Section/Section';
import Header from '../../components/UI/Header/Header';
import { getIsMicrof, getIsRheem } from '../../config/settings';
import { CONSUMER_PORTAL_TITLE, DEALER_PORTAL_TITLE } from '../../constants/document';
import {
  addNotification,
  changeAuthValue,
  changeMicrofInfoValue,
  changePage,
  changePreQualInfoValue,
  endLoading,
  getAppByIdAndSelectedFinancier,
  getAppByIdAndToken,
  getAquaAppByIdAndSelectedFinancier,
  getConsumerApp,
  getConsumerUser,
  getPrequalificationByIdAndToken,
  removeNotification,
  resetState,
  setDefaultTheme,
  setFlow,
  setMicrofOnlyConsumerFlow,
  validateCustomLink,
  validateCustomLinkDealerId,
} from '../../redux/actions/index';
import {
  aquaFlow,
  microfFlow,
  powerPayFlow,
  powerPayPreQualificationFlow,
  preQualificationFlow,
  rheemFlow,
  wellsFargoFlow,
  mosaicPreQualificationFlow,
  enerBankFlow,
  enerBankPreQualificationFlow,
  upgradePreQualificationFlow,
} from '../../redux/initialState';
import { setDocumentTitle } from '../../util/documentUtil';
import PreQualificationFlow from './preQualificationFlow';

const isRheem = getIsRheem();
const isMicrof = getIsMicrof();

const PreQualification = props => {
  const {
    state,
    page,
    auth,
    dispatchRemoveNotification,
    dispatchValidateCustomLink,
    dispatchValidateCustomLinkDealerId,
    history,
    dispatchSetFlow,
    flowName,
    dispatchResetState,
    dispatchChangeValue,
    dispatchChangeAuthValue,
    dispatchEndLoading,
    dispatchGetAquaAppByIdAndSelectedFinancier,
    dispatchGetAppByIdAndSelectedFinancier,
    dispatchGetConsumerApp,
    dispatchGetPrequalificationByIdAndToken,
    dispatchGetConsumerUser,
    dispatchGetAppByIdAndToken,
    dispatchAddNotification,
    mosaicFlow,
    dispatchSetMicrofOnlyConsumerFlow,
    dispatchSetDefaultTheme,
    currentTheme,
    registerAsConsumer,
  } = props;
  const { user } = auth;

  const did = queryString.parse(window.location.search).did;

  useEffect(() => {
    const microfOnly = queryString.parse(window.location.search).microfOnly;
    const isRTOOnly = queryString.parse(window.location.search).isRTOOnly;
    const did = queryString.parse(window.location.search).did;
    const dealerId = queryString.parse(window.location.search).dealerId;
    const isConsumer = queryString.parse(window.location.search).isConsumer;
    const aquaAppNo = queryString.parse(window.location.search).aquaAppNo;
    const appId = queryString.parse(window.location.search).appId;
    const consumerId = queryString.parse(window.location.search).consumerId;
    const consumerEmail = queryString.parse(window.location.search).consumerEmail;
    const pqiId = queryString.parse(window.location.search).pqiId;
    const pqiToken = queryString.parse(window.location.search).pqiToken;
    const click_id = queryString.parse(window.location.search).click_id;
    const track_id =
      queryString.parse(window.location.search).track_id ||
      queryString.parse(window.location.search).tracking;
    const lead_source =
      queryString.parse(window.location.search)._gac ||
      queryString.parse(window.location.search).gac ||
      queryString.parse(window.location.search)._ga ||
      queryString.parse(window.location.search).ga;
    const lp_url = window.location.href;
    const appSelectedFinancier = queryString.parse(window.location.search).appSelectedFinancier;
    let appToken = queryString.parse(window.location.search).appToken;
    const isUpgrade = queryString.parse(window.location.search).isUpgrade;

    const utm_medium = queryString.parse(window.location.search).utm_medium
      ? 'utm_medium=' + queryString.parse(window.location.search).utm_medium
      : '';

    const utm_campaign = queryString.parse(window.location.search).utm_campaign
      ? 'utm_campaign=' + queryString.parse(window.location.search).utm_campaign
      : '';

    const utm_source = queryString.parse(window.location.search).utm_source
      ? 'utm_source=' + queryString.parse(window.location.search).utm_source
      : '';

    const utm_variables = utm_medium.concat(
      utm_medium === '' ? utm_campaign : ',' + utm_campaign,
      (utm_medium === '' && utm_campaign === '') || utm_campaign === ''
        ? utm_source
        : ',' + utm_source,
    );

    if (appToken && appToken.indexOf('?') >= 0) {
      appToken = appToken.substr(0, appToken.indexOf('?'));
    }

    dispatchSetDefaultTheme(currentTheme);
    history.push('/');

    const isDealerUserLoggedIn = user && !!user.dealerUser;
    setDocumentTitle(isDealerUserLoggedIn ? DEALER_PORTAL_TITLE : CONSUMER_PORTAL_TITLE);

    if (registerAsConsumer !== true) {
      dispatchChangeValue('microfOnly', null);
      dispatchChangeValue('isConsumer', null);
    }

    if ((click_id && !did) || (isConsumer && microfOnly)) {
      dispatchSetMicrofOnlyConsumerFlow(history);
    } else if (consumerId && consumerEmail) {
      dispatchResetState(props);
      dispatchGetConsumerUser(consumerId, consumerEmail, history);
    } else if (pqiId && pqiToken) {
      dispatchGetPrequalificationByIdAndToken(pqiId, pqiToken, history);
    } else if (!!consumerId && consumerId != null && consumerId !== '') {
      dispatchGetConsumerApp(consumerId, appSelectedFinancier, appId, history);
    } else if (!!did && did != null && did !== '') {
      dispatchValidateCustomLink(did, history, isRTOOnly);
    } else if (!!dealerId && dealerId != null && dealerId !== '') {
      if (/true/.test(isUpgrade)) {
        dispatchResetState(props);
        dispatchChangeValue('isUpgrade', /true/.test(isUpgrade));
        dispatchChangeAuthValue('dealerId', parseInt(dealerId));
        dispatchSetFlow(upgradePreQualificationFlow);
        history.push('/upgrade-prequalification');
      } else {
        dispatchValidateCustomLinkDealerId(dealerId, history);
      }
    } else if (!!aquaAppNo && aquaAppNo != null && aquaAppNo !== '') {
      dispatchGetAquaAppByIdAndSelectedFinancier(appId, appSelectedFinancier, aquaAppNo, history);
    } else if (appSelectedFinancier && appId) {
      dispatchGetAppByIdAndSelectedFinancier(appSelectedFinancier, appId, history);
    } else if (appToken && appId) {
      if (isRheem) {
        history.push('/');
        dispatchAddNotification({
          message: 'Revesiting partner applications is not permitted for rheem users',
          messageType: 'Error',
        });
      } else {
        dispatchGetAppByIdAndToken(appToken, appId, history);
      }
    } else {
      if (window.location.search.indexOf('?') > -1) {
        dispatchSetFlow(preQualificationFlow);
        history.push('/');
      } else {
        if (flowName === aquaFlow) {
          dispatchSetFlow(aquaFlow);
          history.push('/Aqua');
        } else if (flowName === microfFlow) {
          dispatchSetFlow(microfFlow);
          history.push('/RTO');
        } else if (flowName === rheemFlow) {
          dispatchSetFlow(rheemFlow);
          history.push('/rheem');
        } else if (flowName === powerPayFlow) {
          dispatchSetFlow(powerPayFlow);
          history.push('/powerpay');
        } else if (flowName === wellsFargoFlow) {
          history.push('/WellsFargo');
        } else if (flowName === powerPayPreQualificationFlow) {
          history.push('/powerpay-prequalification');
        } else if (!isMicrof && !page) {
          history.push('/login');
        } else if (flowName === mosaicPreQualificationFlow) {
          history.push('/mosaic-prequalification');
        } else if (flowName === mosaicFlow) {
          history.push('/mosaic');
        } else if (flowName === enerBankPreQualificationFlow) {
          history.push('/enerbank-prequalification');
        } else if (flowName === enerBankFlow) {
          history.push('/enerbank');
        } else if (flowName === upgradePreQualificationFlow) {
          history.push('/upgrade-prequalification');
        } else {
          dispatchSetFlow(preQualificationFlow);
          history.push('/');
        }
      }
      dispatchRemoveNotification();
      dispatchEndLoading();
    }

    dispatchChangeAuthValue('lead_source', lead_source ? lead_source : null);
    dispatchChangeAuthValue('utmVariables', utm_variables ? utm_variables : null);
    dispatchChangeAuthValue('click_id', click_id ? click_id : null);
    dispatchChangeAuthValue('track_id', track_id ? track_id : null);

    if (lead_source || click_id || track_id || utm_variables) {
      dispatchChangeAuthValue('lp_url', lp_url ? lp_url : null);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header
        title={PreQualificationFlow[page ? page : 0].props.title}
        pages={PreQualificationFlow.length - 1}
      />
      <Section
        Component={PreQualificationFlow[!!did && did != null && did !== '' ? 0 : page ? page : 0]}
        {...state}
      />
    </>
  );
};

const mapStateToProps = state => ({
  state,
  page: state.navi.preQualificationFlowCurrentPage,
  flowName: state.navi.flowName,
  auth: state.auth,
  microfOnly: state.microfInformation.microfOnly,
  registerAsConsumer: state.microfInformation.registerAsConsumer,
  currentTheme: state.theme.theme,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetFlow: flowName => dispatch(setFlow(flowName)),
  dispatchRemoveNotification: () => dispatch(removeNotification()),
  dispatchEndLoading: () => dispatch(endLoading()),
  dispatchValidateCustomLink: (did, history, isRTOOnly) =>
    dispatch(validateCustomLink(did, history, isRTOOnly)),
  dispatchValidateCustomLinkDealerId: (did, history) =>
    dispatch(validateCustomLinkDealerId(did, history)),
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchResetState: props => dispatch(resetState(props)),
  dispatchChangeValue: (key, value) => dispatch(changePreQualInfoValue(key, value)),
  dispatchChangeAuthValue: (key, value) => dispatch(changeAuthValue(key, value)),
  dispatchChangeMicrofInfoValue: (key, value) => dispatch(changeMicrofInfoValue(key, value)),
  dispatchGetAquaAppByIdAndSelectedFinancier: (appId, appSelectedFinancier, aquaAppNo, history) =>
    dispatch(getAquaAppByIdAndSelectedFinancier(appId, appSelectedFinancier, aquaAppNo, history)),
  dispatchGetAppByIdAndSelectedFinancier: (appSelectedFinancier, appId, history) =>
    dispatch(getAppByIdAndSelectedFinancier(appSelectedFinancier, appId, history)),
  dispatchGetConsumerApp: (consumerId, token, appId, history) =>
    dispatch(getConsumerApp(consumerId, token, appId, history)),
  dispatchGetPrequalificationByIdAndToken: (pqiId, pqiToken, history) =>
    dispatch(getPrequalificationByIdAndToken(pqiId, pqiToken, history)),
  dispatchGetConsumerUser: (consumerId, consumerEmail, history) =>
    dispatch(getConsumerUser(consumerId, consumerEmail, history)),
  dispatchGetAppByIdAndToken: (appToken, appId, history) =>
    dispatch(getAppByIdAndToken(appToken, appId, history)),
  dispatchAddNotification: params => dispatch(addNotification(params)),
  dispatchSetMicrofOnlyConsumerFlow: history => dispatch(setMicrofOnlyConsumerFlow(history)),
  dispatchSetDefaultTheme: currentTheme => dispatch(setDefaultTheme(currentTheme)),
});

PreQualification.propTypes = {
  state: PropTypes.object,
  dispatchSetFlow: PropTypes.func,
  dispatchRemoveNotification: PropTypes.func,
  dispatchEndLoading: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PreQualification),
);
