const AddedFromEnum = {
  MICROF: { value: 'M', name: 'Microf' },
  RHEEM: { value: 'R', name: 'Rheem' },
  OPTIMUS: { value: 'O', name: 'Optimus' },
  POOLS: { value: 'P', name: 'Pool' },
  OPTIMUS_PLUS: { value: '2', name: 'Optimus' },
  FINTURF: { value: 'N', name: 'Finturf' },
};

export default AddedFromEnum;
