import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import BackBtn from '../../components/UI/BackButton/BackButton';
import Button from '../../components/UI/Button/CustomButton';
import Input from '../../components/UI/Form/Input/CustomInput';
import { PASSWORD_CONFIRM_TOOLTIP_TEXT, PASSWORD_TOOLTIP_TEXT } from '../../constants/tooltipTexts';
import { reactGAEvent, reactGAPageview } from '../../helper';
import { changePage, submitMindfire, changeWellsFargoValue } from '../../redux/actions';
import { WellsFargoFlowPageNumber } from './WellsFargoFlow';

const AccountPassword = props => {
  const {
    email,
    password,
    dispatchChangePage,
    children,
    did,
    dealerUserId,
    consumerId,
    dispatchChangeWellsFargoValue,
    dispatchSubmitMindfire,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/wf_setpass',
      trackerNames: [],
      title: 'wf_setpass',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const handleSubmit = values => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Continue',
      did,
      dealerUserId,
    });
    dispatchChangeWellsFargoValue('password', values.password);
    dispatchChangePage(WellsFargoFlowPageNumber.LoanFor);
    dispatchSubmitMindfire({
      email: email,
      lastPage: '5003',
      dealerUserId: dealerUserId,
      did: did,
    });
  };

  const AccountPasswordForm = () => (
    <Form autoComplete="off">
      <Field
        component={Input}
        label="Password"
        name="password"
        id="password"
        tooltipText={PASSWORD_TOOLTIP_TEXT}
        type="password"
        icon="eye"
      />
      <small className="form-text text-muted">
        Your password should be at least 7 characters with at least 3 letters (1 upper case, 1 lower
        case) and at least 1 number.{' '}
      </small>
      <Field
        component={Input}
        label="Confirm Password"
        name="confirmPassword"
        id="confirmPassword"
        tooltipText={PASSWORD_CONFIRM_TOOLTIP_TEXT}
        type="password"
        icon="eye"
      />
      <Button type="submit" title="Continue" />
    </Form>
  );

  const AccountPasswordFormFormik = withFormik({
    mapPropsToValues: () => ({
      password: password || '',
      confirmPassword: password || '',
    }),

    validationSchema: Yup.object({
      password: Yup.string()
        .min(7, 'Password has to be at least 7 characters.')
        .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,255}$/, 'Insert a valid password.')
        .test('3Letters', 'Insert a valid password.', val => {
          const alpha = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
          let charNo = 0;
          if (!!val) {
            for (var i = 0; i < val.length; i++) {
              if (alpha.indexOf(val.charAt(i)) > -1) {
                charNo++;
              }
            }
          }
          return charNo >= 3;
        })
        .required('Please enter a password.'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Password must match.')
        .required('Please enter a password.'),
    }),

    handleSubmit,
  })(AccountPasswordForm);

  return (
    <Container>
      <Row>
        <BackBtn changePage={dispatchChangePage} page={WellsFargoFlowPageNumber.AccountEmail} />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <AccountPasswordFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  password: state.wellsFargoInformation.password,
  email: state.wellsFargoInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  consumerId: state.auth.consumerId,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeWellsFargoValue: (key, value) => dispatch(changeWellsFargoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

AccountPassword.propTypes = {
  password: PropTypes.string,
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountPassword);
