import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Section from '../../../components/Section/Section';
import Header from '../../../components/UI/Header/Header';

import {
  addNotification,
  changeAuthValue,
  changeMicrofInfoValue,
  changePage,
  changePreQualInfoValue,
  endLoading,
  getAppByIdAndSelectedFinancier,
  getAppByIdAndToken,
  getAquaAppByIdAndSelectedFinancier,
  getConsumerApp,
  getConsumerUser,
  getPrequalificationByIdAndToken,
  removeNotification,
  resetState,
  setDefaultTheme,
  setFlow,
  setMicrofOnlyConsumerFlow,
  validateCustomLink,
  validateCustomLinkDealerId,
} from '../../../redux/actions/index';

import PreQualificationFlow from './preQualificationFlow';

const PreQualification = props => {
  const { state, page, auth } = props;
  const { user } = auth;

  const did = queryString.parse(window.location.search).did;

  return (
    <>
      <Header
        title={PreQualificationFlow[page]?.props.title}
        pages={PreQualificationFlow.length - 1}
      />
      <Section Component={PreQualificationFlow[page]} {...state} />
    </>
  );
};

const mapStateToProps = state => ({
  state,
  page: state.navi.upgradePreQualificationFlowCurrentPage,
  flowName: state.navi.flowName,
  auth: state.auth,
  microfOnly: state.microfInformation.microfOnly,
  registerAsConsumer: state.microfInformation.registerAsConsumer,
  currentTheme: state.theme.theme,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetFlow: flowName => dispatch(setFlow(flowName)),
  dispatchRemoveNotification: () => dispatch(removeNotification()),
  dispatchEndLoading: () => dispatch(endLoading()),
  dispatchValidateCustomLink: (did, history, isRTOOnly) =>
    dispatch(validateCustomLink(did, history, isRTOOnly)),
  dispatchValidateCustomLinkDealerId: (did, history) =>
    dispatch(validateCustomLinkDealerId(did, history)),
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchResetState: props => dispatch(resetState(props)),
  dispatchChangeValue: (key, value) => dispatch(changePreQualInfoValue(key, value)),
  dispatchChangeAuthValue: (key, value) => dispatch(changeAuthValue(key, value)),
  dispatchChangeMicrofInfoValue: (key, value) => dispatch(changeMicrofInfoValue(key, value)),
  dispatchGetAquaAppByIdAndSelectedFinancier: (appId, appSelectedFinancier, aquaAppNo, history) =>
    dispatch(getAquaAppByIdAndSelectedFinancier(appId, appSelectedFinancier, aquaAppNo, history)),
  dispatchGetAppByIdAndSelectedFinancier: (appSelectedFinancier, appId, history) =>
    dispatch(getAppByIdAndSelectedFinancier(appSelectedFinancier, appId, history)),
  dispatchGetConsumerApp: (consumerId, token, appId, history) =>
    dispatch(getConsumerApp(consumerId, token, appId, history)),
  dispatchGetPrequalificationByIdAndToken: (pqiId, pqiToken, history) =>
    dispatch(getPrequalificationByIdAndToken(pqiId, pqiToken, history)),
  dispatchGetConsumerUser: (consumerId, consumerEmail, history) =>
    dispatch(getConsumerUser(consumerId, consumerEmail, history)),
  dispatchGetAppByIdAndToken: (appToken, appId, history) =>
    dispatch(getAppByIdAndToken(appToken, appId, history)),
  dispatchAddNotification: params => dispatch(addNotification(params)),
  dispatchSetMicrofOnlyConsumerFlow: history => dispatch(setMicrofOnlyConsumerFlow(history)),
  dispatchSetDefaultTheme: currentTheme => dispatch(setDefaultTheme(currentTheme)),
});

PreQualification.propTypes = {
  state: PropTypes.object,
  dispatchSetFlow: PropTypes.func,
  dispatchRemoveNotification: PropTypes.func,
  dispatchEndLoading: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PreQualification),
);
