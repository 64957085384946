import { Form, withFormik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Col, Container, Input, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import {
  getModelNumbersFromRheem,
  changeSoftPullValue,
  resetNewAppState,
} from '../../../redux/actions/index';
import HvacRow from './HvacRow';
import { isMobile } from 'react-device-detect';

const CompleteFundRheemApplication = props => {
  const {
    application,
    hcBrands,
    dispatchChangeSoftPullValue,
    isRheemSerialModelMapComplete,
    rheemSerialModelMap,
    dispatchGetModelNumbersFromRheem,
    infoValues,
    hasModelRequestBeenSent,
    dispatchResetNewAppState,
    history,
  } = props;

  const addMore = values => {
    let infoValuesTmp = [...infoValues];

    infoValuesTmp.forEach((infoValue, index) => {
      infoValue.systemModel = values[`systemModel${index}`];
      infoValue.systemSerialNumber = values[`systemSerialNumber${index}`];
    });

    infoValuesTmp.push({
      systemModel: '',
      systemSerialNumber: '',
    });

    dispatchChangeSoftPullValue('infoValues', infoValuesTmp);
  };

  const saveValuesToState = values => {
    let infoValuesTmp = [...infoValues];

    infoValuesTmp.forEach((infoValue, index) => {
      infoValue.systemModel = values[`systemModel${index}`];
      infoValue.systemSerialNumber = values[`systemSerialNumber${index}`];
    });

    dispatchChangeSoftPullValue('infoValues', infoValuesTmp);
  };

  const CompleteFundRheemApplicationForm = ({ values }) => (
    <Form>
      <h3 className="mb-4">Application</h3>
      <Row className="align-items-center">
        <Col sm="4" className="no-padding">
          <Label className="mb-0">Consumer Name</Label>
        </Col>
        <Col sm="4" className="no-padding">
          <Input
            type="text"
            value={application ? application.nameFirst + ' ' + application.nameLast : ''}
            disabled
            className="form-control h-35p"
          />
        </Col>
      </Row>
      {application.coapplicantNameFirst && (
        <Row className="mt-4">
          <Col sm="4" className="no-padding">
            <Label className="mb-0">Coapplicant Name</Label>
          </Col>
          <Col sm="4" className="no-padding">
            <Input
              type="text"
              value={application.coapplicantNameFirst + ' ' + application.coapplicantNameLast}
              disabled
              className="form-control h-35p"
            />
          </Col>
        </Row>
      )}
      <Row className="mt-4">
        <Col sm="4" className="no-padding">
          <Label className="mb-0">Consumer Address</Label>
        </Col>
        <Col sm="4" className="no-padding">
          <Input
            type="text"
            value={
              application
                ? application.street + ' ' + application.state + ' ' + application.zipcode
                : ''
            }
            disabled
            className="form-control h-35p"
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm="4" className="no-padding">
          <Label className="mb-0">Total Loan or Lease Amount</Label>
        </Col>
        <Col sm="4" className="no-padding">
          <Input
            type="text"
            value={application.totalFinancing || ''}
            disabled
            className="form-control h-35p"
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="no-padding">
          <h3>HVAC Units</h3>
        </Col>
      </Row>
      {infoValues.map((_info, index) => (
        <HvacRow hcBrands={hcBrands} index={index} key={index} />
      ))}
      {!isRheemSerialModelMapComplete && hasModelRequestBeenSent && (
        <Row className="mt-5 justify-content-end error">
          Please check serial numbers that don't have an associated model number and try again!
        </Row>
      )}
      <Row className={'mt-5 ' + (!isMobile ? 'justify-content-end' : 'justify-content-center')}>
        {infoValues.length < 10 && (
          <Button
            type="submit"
            className={'rounded-pill btn-secondary mr-2' + (isMobile ? ' mt-3 pl-5 pr-5' : '')}
            onClick={() => {
              addMore(values);
            }}
          >
            Add More
          </Button>
        )}
        <Button
          color="secondary"
          className={'rounded-pill mr-2' + (isMobile ? ' mt-3 pl-5 pr-5' : '')}
          outline
          onClick={() => {
            dispatchResetNewAppState();
            history.push('/portal/open-applications');
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          className={'rounded-pill w-auto' + (isMobile ? ' mt-3 pl-5 pr-5' : '')}
        >
          {isRheemSerialModelMapComplete ? 'Submit' : 'Get Model Numbers'}
        </Button>
      </Row>
    </Form>
  );

  const CompleteFundRheemApplicationFormFormik = withFormik({
    mapPropsToValues: () => ({
      ...infoValues.reduce(
        (previous, current, index) =>
          Object.assign(previous, {
            [`systemSerialNumber${index}`]: current.systemSerialNumber,
            [`systemModel${index}`]:
              (rheemSerialModelMap && rheemSerialModelMap[current.systemSerialNumber]) || '',
          }),
        {},
      ),
    }),

    validationSchema: Yup.object({
      ...infoValues.reduce(
        (previous, _current, index) =>
          Object.assign(previous, {
            [`systemModel${index}`]: Yup.string().test({
              name: 'required',
              exclusive: false,
              message: 'System model is required.',
              test: function(value) {
                const systemSerialNumber = this.resolve(Yup.ref(`systemSerialNumber${index}`));
                if (systemSerialNumber && isRheemSerialModelMapComplete) return !!value;
                return true;
              },
            }),
            [`systemSerialNumber${index}`]: Yup.string()
              .test({
                name: 'required',
                exclusive: false,
                message: 'System serial number is required.',
                test: function(value) {
                  if (!value) return false;
                  const systemModel = this.resolve(Yup.ref(`systemModel${index}`));
                  if (systemModel || !index) return !!value;
                  return true;
                },
              })
              .test({
                name: 'emptyString',
                exclusive: false,
                message: 'Invalid Serial Number.',
                test: function(value) {
                  return value ? value.trim() !== '' : true;
                },
              })
              .test({
                name: 'allCharEqual',
                exclusive: false,
                message: 'Invalid Serial Number.',
                test: function(value) {
                  return value ? !/^(.)\1*$/.test(value.trim()) : true;
                },
              }),
          }),
        {},
      ),
    }),

    handleSubmit: async values => {
      saveValuesToState(values);
      if (isRheemSerialModelMapComplete) {
        let selectedBrands = [];

        infoValues.forEach((_infoValue, index) => {
          if (values[`systemModel${index}`] && values[`systemSerialNumber${index}`]) {
            selectedBrands.push({
              applicationAcBrandId: 0,
              brandId: 80,
              systemMake: null,
              modelName: values[`systemModel${index}`],
              serialNumber: values[`systemSerialNumber${index}`],
              brandName: 'Rheem',
              systemName: `Unit ${index + 1}`,
              systemSize: null,
              opportunityId: null,
              salesforceId: null,
              isDeleted: false,
            });
          }
        });

        let applicationDTO = application;
        applicationDTO = {
          ...applicationDTO,
          selectedBrands: selectedBrands,
        };
        dispatchChangeSoftPullValue('application', applicationDTO);
        history.push(
          '/portal/open-applications/installation-certification?appId=' + applicationDTO.id,
        );
      } else {
        let serialNumbers = [];
        infoValues.forEach((_infoValue, index) => {
          serialNumbers.push(values[`systemSerialNumber${index}`]);
        });
        await dispatchGetModelNumbersFromRheem(serialNumbers);
        dispatchChangeSoftPullValue('hasModelRequestBeenSent', true);
      }
    },
  })(CompleteFundRheemApplicationForm);

  return (
    <Container>
      <Row>
        <Col>
          <CompleteFundRheemApplicationFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  application: state.softPull.application,
  hcBrands: state.softPull.hcBrands,
  isRheemSerialModelMapComplete: state.softPull.isRheemSerialModelMapComplete,
  rheemSerialModelMap: state.softPull.rheemSerialModelMap,
  infoValues: state.softPull.infoValues,
  hasModelRequestBeenSent: state.softPull.hasModelRequestBeenSent,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangeSoftPullValue: (key, value) => dispatch(changeSoftPullValue(key, value)),
  dispatchGetModelNumbersFromRheem: serialNumbers =>
    dispatch(getModelNumbersFromRheem(serialNumbers)),
  dispatchResetNewAppState: () => dispatch(resetNewAppState()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CompleteFundRheemApplication),
);
