import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { useVSPixelScript } from '../../../assets/hooks';
import Button from '../../../components/UI/Button/CustomButton';
import { getDealerPortalURL } from '../../../config/settings';
import { reactGAEvent, reactGAPageview } from '../../../helper';
import {
  changePageTitle,
  resetNewAppState,
  resetState,
  setFlow,
  submitMindfire,
  updateSelectedFinancier,
} from '../../../redux/actions/index';

const Pending = props => {
  const {
    dealerUserId,
    did,
    dispatchSubmitMindfire,
    email,
    dispatchResetState,
    dispatchChangePageTitle,
    user,
    history,
    dispatchResetNewAppState,
  } = props;

  const isUserLoggedIn = !did && user && user.dealerUser;
  const buttonTitle = isUserLoggedIn ? 'Return to Dashboard' : 'Return to Login Page';
  const returnUrl = isUserLoggedIn ? '/portal/dashboard' : getDealerPortalURL();

  useVSPixelScript();

  useEffect(() => {
    dispatchChangePageTitle('Pending Application');
    reactGAPageview({
      path: '/ca_pppending',
      trackerNames: [],
      title: 'ca_pppending',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const handleClick = () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Done',
      did,
      dealerUserId,
    });
    dispatchSubmitMindfire({
      email: email,
      lastPage: '6022',
      dealerUserId: dealerUserId,
      did: did,
    });
    if (!isUserLoggedIn) {
      dispatchResetState(props);
      window.location.href = returnUrl;
    } else {
      dispatchResetNewAppState();
      history.push(returnUrl);
    }
  };

  return (
    <Container>
      <Row className="justify-content-center mt-2">
        <Col xs="auto" md="8">
          <h5 className="text-center">
            <b>Applicant(s)</b>
          </h5>
        </Col>
      </Row>
      <Row className="justify-content-center mt-2">
        <Col xs="auto" md="8">
          <h5 className="text-center">Your application is currently pending</h5>
        </Col>
      </Row>
      <Row className="justify-content-center mt-2">
        <Col xs="auto" md="8">
          <h5 className="text-center">
            Please hand your device back to the contractor to continue
          </h5>
        </Col>
      </Row>
      <Row className="justify-content-center mt-5">
        <Col xs="auto" md="8">
          <h5 className="text-center">
            <b>Contractor</b>
          </h5>
        </Col>
      </Row>
      <Row className="justify-content-center mt-2">
        <Col xs="auto" md="8">
          <h5 className="text-center">
            If you have not received a credit decision in 15 minutes, please call Mosaic at
            855-746-7849
          </h5>
        </Col>
      </Row>
      <Row className="justify-content-center mt-2">
        <Col xs="auto">
          <Button type="button" title={buttonTitle} clickHandler={handleClick} />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  consumerToken:
    state.softPull.applicationSubmitDTO && state.softPull.applicationSubmitDTO.consumerToken,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchUpdateSelectedFinancier: (selectedFinancier, applicationId, successCallBack) =>
    dispatch(updateSelectedFinancier(selectedFinancier, applicationId, successCallBack)),
  dispatchSetFlow: flowName => dispatch(setFlow(flowName)),
  dispatchResetState: props => dispatch(resetState(props)),
  dispatchResetNewAppState: () => dispatch(resetNewAppState()),
  dispatchChangePageTitle: value => dispatch(changePageTitle(value)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Pending),
);
