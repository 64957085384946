import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import queryString from 'query-string';
import FundingNotAllowed from '../../Microf/FundingNotAllowed';
import CompleteFundMicrofApplication from '../../Microf/CompleteFundMicrofApplication';
import { getApplication, getSoftPullHcBrands, changeTheme } from '../../../redux/actions';
import { getIsRheem } from '../../../config/settings';

const isFundingAllowed = application => application.firstPayment && application.agreementSigned;
const COMPLETE_APPLICATION_TITLE = 'Complete / Fund Application';
const FUNDING_NOT_ALLOWED_TITLE = 'Funding not allowed';
const isRheem = getIsRheem();

const CompleteFundOpenMicrofApplication = props => {
  const {
    auth,
    dispatchGetApplication,
    application,
    history,
    dispatchGetBrands,
    hcBrands,
    dispatchChangeTheme,
  } = props;
  const appId = +queryString.parse(window.location.search).appId;

  useEffect(() => {
    if (!auth.user || !auth.user.dealerUser) {
      history.push('/login');
    }
    if (appId) {
      if (!application || application.id !== appId) {
        dispatchGetApplication(appId);
      }
      if (!hcBrands) {
        dispatchGetBrands();
      }
    } else {
      history.push('/portal/open-applications');
    }
    // eslint-disable-next-line
  }, []);

  const cancel = () => {
    //history.push('/portal/open-applications/details/?appId=' + appId);
    history.push('/portal/open-applications');
    if (isRheem) {
      dispatchChangeTheme('rheem');
    }
  };

  const ApplicationFunding = () => {
    if (isFundingAllowed(application)) {
      return <CompleteFundMicrofApplication onCancel={cancel} />;
    }
    return <FundingNotAllowed />;
  };

  const panelTitle = () => {
    if (!application) {
      return COMPLETE_APPLICATION_TITLE;
    }
    return isFundingAllowed(application) ? COMPLETE_APPLICATION_TITLE : FUNDING_NOT_ALLOWED_TITLE;
  };

  return (
    <Col>
      <Row className="mt-5">
        <div className="panel">
          <div className="panel-body larger">
            <h2 id="panelTitle">{panelTitle()}</h2>
            <Row>
              <Col className="right-bordered no-padding">
                {application && hcBrands ? (
                  <ApplicationFunding />
                ) : (
                  <div>No application found.</div>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Row>
    </Col>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  application: state.softPull.application,
  hcBrands: state.softPull.hcBrands,
});

const mapDispatchToProps = dispatch => ({
  dispatchGetApplication: applicationId => dispatch(getApplication(applicationId)),
  dispatchGetBrands: () => dispatch(getSoftPullHcBrands()),
  dispatchChangeTheme: theme => dispatch(changeTheme(theme)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompleteFundOpenMicrofApplication);
