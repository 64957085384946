import React from 'react';
import { connect } from 'react-redux';
import Section from '../../../../components/Section/Section';
import Header from '../../../../components/UI/Header/Header';
import { setFlow } from '../../../../redux/actions/index';
import FoundationFlow from './FoundationFlow';
import ApplicationFlowEnum from '../../../../assets/js/ApplicationFlowEnum';

const Foundation = props => {
  const { foundationFlowCurrentPage, history, flowName } = props;

  if (flowName !== ApplicationFlowEnum.FOUNDATION_FLOW.value) {
    history.push(Object.values(ApplicationFlowEnum).find(flow => flow.value === flowName).path);
    return <></>;
  }

  return (
    <>
      <Header
        title={
          FoundationFlow[foundationFlowCurrentPage ? foundationFlowCurrentPage : 0].props.title
        }
        pages={FoundationFlow.length - 1}
      />
      <Section
        Component={FoundationFlow[foundationFlowCurrentPage ? foundationFlowCurrentPage : 0]}
      />
    </>
  );
};

const mapStateToProps = state => ({
  foundationFlowCurrentPage: state.navi.foundationFlowCurrentPage,
  flowName: state.navi.flowName,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetFlow: flow => dispatch(setFlow(flow)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Foundation);
