import HvacHeatTypeEnum from './HvacHeatTypeEnum';
import HvacSystemTypeEnum from './HvacSystemTypeEnum';

const onlyHvacSystem = {
  AC_ONLY: {
    value: 'A/C only',
    name: 'A/C Only',
    description: 'A/C Only - A/C Outdoor unit Replacement',
  },
  AC_PACKAGE: {
    value: 'A/C Package',
    name: 'A/C Package Unit',
    description: 'A/C or Gas Small Package Product (Mobile Home unit)',
    systemType: [HvacSystemTypeEnum.PACKAGE_SYSTEM.value],
    heatType: [HvacHeatTypeEnum.FURNACE.value, HvacHeatTypeEnum.ELECTRIC_AIR_CONDITIONER.value],
  },
  AC_SYSTEM: {
    value: 'A/C System',
    name: 'A/C System',
    description: 'A/C System - A/C, Evap Coil or Fan Coil, Aux Heat and TSTAT',
    systemType: [HvacSystemTypeEnum.SPLIT_SYSTEM.value, HvacSystemTypeEnum.MINI_SPLIT_SYSTEM.value],
    heatType: [HvacHeatTypeEnum.ELECTRIC_AIR_CONDITIONER.value],
  },
  AIR_HANDLER_ONLY: { value: 'Air Handler', name: 'Air Handler', description: 'Air Handler' },
  BOILERS: { value: 'Boilers', name: 'Boilers', description: 'Gas or Oil Boiler' },
  GAS_FURNACE_ONLY: {
    value: 'Gas Furnace only',
    name: 'Gas Furnace Only',
    description: 'Gas Furnace Only',
  },
  GAS_FURNACE_SYSTEM: {
    value: 'Gas Furnace System',
    name: 'Gas Furnace System',
    description: 'Gas Furnace System - A/C, Evap Coil, Furnace',
    systemType: [HvacSystemTypeEnum.SPLIT_SYSTEM.value, HvacSystemTypeEnum.MINI_SPLIT_SYSTEM.value],
    heatType: [HvacHeatTypeEnum.FURNACE.value],
  },
  HEAT_PUMP_ONLY: {
    value: 'Heat Pump only',
    name: 'Heat Pump Only',
    description: 'Heat Pump Only - H/P Outdoor unit replacement',
  },
  HEAT_PUMP_SYSTEM: {
    value: 'Heat Pump System',
    name: 'Heat Pump System',
    description: 'Heat Pump System - H/P, Fan Coil, Aux Heat and TSTAT',
    systemType: [HvacSystemTypeEnum.SPLIT_SYSTEM.value, HvacSystemTypeEnum.MINI_SPLIT_SYSTEM.value],
    heatType: [HvacHeatTypeEnum.ELECTRIC_HEAT_PUMP.value],
  },
  HEAT_PUMP_PACKAGE: {
    value: 'H/P Package',
    name: 'Heat Pump Package',
    description: 'Heat Pump Small Package Product (Mobile Home unit)',
    systemType: [HvacSystemTypeEnum.PACKAGE_SYSTEM.value],
    heatType: [HvacHeatTypeEnum.ELECTRIC_HEAT_PUMP.value],
  },
};

export default onlyHvacSystem;
