import React from 'react';
import { Row, Col } from 'reactstrap';
import Logo from '../Logo/Logo';

const OptimusHeader = () => (
  <header className="optimus-header">
    <Row className="soft-pull-header">
      <Col xs="auto" className="position-absolute">
        <Logo isSpectrumLogo={false} />
      </Col>
    </Row>
    <Row noGutters>
      <Col xs="12" className="progress">
        <div className="active w-100" />
      </Col>
    </Row>
  </header>
);

export default OptimusHeader;
