import { Field, Form, withFormik } from 'formik';
import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import CustomInput from '../Form/Input/CustomInput';
import * as Yup from 'yup';

const ExternalReferenceIdModal = props => {
  const { isOpen, toggleModal, className, onSendClick, data } = props;

  const ExternalReferenceIdForm = ({ values }) => (
    <Form>
      <ModalBody>
        <Field
          component={CustomInput}
          label="External Reference ID"
          name="referenceId"
          id="referenceId"
          type="text"
        />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" outline onClick={toggleModal}>
          Close
        </Button>
        <Button color="primary" type="submit">
          Submit
        </Button>
      </ModalFooter>
    </Form>
  );

  const ExternalReferenceIdFormFormik = withFormik({
    mapPropsToValues: () => ({
      referenceId: data?.externalReferenceId || '',
    }),

    validationSchema: Yup.object({}),

    handleSubmit: values => {
      const requestData = {
        preqId: parseInt(data?.preqId),
        externalId: values.referenceId,
      };
      onSendClick(requestData);
    },
  })(ExternalReferenceIdForm);

  return (
    <Modal centered isOpen={isOpen} className={className}>
      <ModalHeader toggle={toggleModal}>
        <b>{`External Reference ID for AppId - ${data?.preqId}`}</b>
      </ModalHeader>
      <ExternalReferenceIdFormFormik />
    </Modal>
  );
};

export default ExternalReferenceIdModal;
