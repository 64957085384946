import React from 'react';
import { connect } from 'react-redux';
import { getIsPool } from '../../../config/settings';
import {
  atWaterGreenskyCancelTransaction,
  cancelTransaction,
  changeSoftPullValue,
} from '../../../redux/actions';
import RefreshTransactions from './RefreshTransactions';

const TransactionHistoryTable = props => {
  const {
    history,
    transactionHistory,
    dispatchCancelTransaction,
    dispatchSoftPullValueChange,
    dispatchAtWaterCancelTransaction,
  } = props;
  const linkPath = transactionId => {
    history.push(`/portal/single-transaction?transactionId=${transactionId}`);
  };
  const goToRefund = (e, transactionId) => {
    e.stopPropagation();
    dispatchSoftPullValueChange('submitRefundData', null);
    dispatchSoftPullValueChange('refundAmount', null);
    history.push(`/portal/submit-refund?transactionId=${transactionId}`);
  };
  const cancelAuth = (e, transactionId, creditCardUID, applicationId) => {
    e.stopPropagation();
    if (getIsPool()) {
      dispatchAtWaterCancelTransaction(transactionId, creditCardUID, applicationId);
    } else {
      dispatchCancelTransaction(transactionId, creditCardUID, applicationId);
    }
  };
  const goToDashboard = e => {
    e.stopPropagation();
    history.push(`/portal/dashboard`);
  };

  const ActionComponent = props => {
    const { transaction } = props;

    switch (transaction.status) {
      case 'POSTED':
        return (
          <React.Fragment>
            <td className={'status-posted'}>{transaction.status}</td>
            <td>
              <button
                type="button"
                className="border-0 bg-transparent status-posted"
                onClick={e => goToRefund(e, transaction.txnNumber)}
              >
                <u>Submit Refund</u>
              </button>
            </td>
          </React.Fragment>
        );
      case 'Posted':
        return (
          <React.Fragment>
            <td className={'status-posted'}>{transaction.status}</td>
            <td>
              <span className="status-refunded">Refunded</span>
            </td>
          </React.Fragment>
        );

      case 'PENDING':
        return (
          <React.Fragment>
            <td className={'status-pending-auth'}>{transaction.status}</td>
            <td>
              <button
                type="button"
                className="border-0 bg-transparent status-cancelled"
                onClick={e =>
                  cancelAuth(
                    e,
                    transaction.txnNumber,
                    transaction.creditCardUID,
                    transaction.greesnkyApplicationId,
                  )
                }
              >
                <u>Cancel Auth</u>
              </button>
            </td>
          </React.Fragment>
        );

      case 'ACCEPTED':
        return (
          <React.Fragment>
            <td className={'status-auth-accepted'}>{transaction.status}</td>
            <td>
              <button
                type="button"
                className="border-0 bg-transparent status-posted"
                onClick={e => goToDashboard(e)}
              >
                Go To Terminal
              </button>
            </td>
          </React.Fragment>
        );

      case 'Cancelled' && 'CANCELLED':
      default:
        return (
          <React.Fragment>
            <td className={'status-cancelled'}>{transaction.status}</td>
            <td />
          </React.Fragment>
        );
    }
  };

  return (
    <table className="table table-hover table-striped table-responsive mb-0">
      <thead>
        <tr>
          <th>Application Id</th>
          <th>Submission Date</th>
          <th>Last Name</th>
          <th>First Name</th>
          <th>Txn Amount</th>
          <th>Plan Fee Amount</th>
          <th>Net Amount</th>
          <th>Plan Fee (%)</th>
          <th>Status</th>
          <th>Action</th>
          <th>Refresh</th>
        </tr>
      </thead>
      <tbody>
        {transactionHistory &&
          transactionHistory.length > 0 &&
          transactionHistory.map((transaction, index) => (
            <tr key={index}>
              <td className="link" onClick={() => linkPath(transaction.txnNumber)}>
                {transaction.greesnkyApplicationId}
              </td>
              <td className="link" onClick={() => linkPath(transaction.txnNumber)}>
                {transaction.submissionDate}
              </td>
              <td className="link" onClick={() => linkPath(transaction.txnNumber)}>
                {transaction.lastName}
              </td>
              <td className="link" onClick={() => linkPath(transaction.txnNumber)}>
                {transaction.firstName}
              </td>
              <td className="link" onClick={() => linkPath(transaction.txnNumber)}>
                {transaction.txnAmount}
              </td>
              <td className="link" onClick={() => linkPath(transaction.txnNumber)}>
                {transaction.planFeeAmount}
              </td>
              <td className="link" onClick={() => linkPath(transaction.txnNumber)}>
                {transaction.planFeeAmount ? transaction.txnAmount - transaction.planFeeAmount : ''}
              </td>
              <td className="link" onClick={() => linkPath(transaction.txnNumber)}>
                {transaction.planFeePercent ? `${transaction.planFeePercent * 100}%` : ''}
              </td>
              <ActionComponent transaction={transaction} />

              {transaction.status === 'POSTED' || transaction.status === 'PENDING' ? (
                <RefreshTransactions
                  index={index}
                  transactionId={transaction.txnNumber}
                  history={history}
                />
              ) : (
                <td />
              )}
            </tr>
          ))}
      </tbody>
    </table>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  dispatchAtWaterCancelTransaction: (transactionId, creditCardUID, applicationId) =>
    dispatch(atWaterGreenskyCancelTransaction(transactionId, creditCardUID, applicationId)),
  dispatchCancelTransaction: (transactionId, creditCardUID, applicationId) =>
    dispatch(cancelTransaction(transactionId, creditCardUID, applicationId)),

  dispatchSoftPullValueChange: (key, value) => dispatch(changeSoftPullValue(key, value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TransactionHistoryTable);
