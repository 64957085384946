import React, { useEffect } from 'react';
import Section from '../../components/Section/Section';
import Header from '../../components/UI/Header/Header';
import { connect } from 'react-redux';
import { setFlow } from '../../redux/actions/index';
import PropTypes from 'prop-types';
import WellsFargoFlow from './WellsFargoFlow';
import {
  microfFlow,
  aquaFlow,
  preQualificationFlow,
  wellsFargoFlow,
  powerPayFlow,
  powerPayPreQualificationFlow,
  mosaicPreQualificationFlow,
  mosaicFlow,
  enerBankFlow,
  enerBankPreQualificationFlow,
} from '../../redux/initialState';

const WellsFargo = ({ history, wellsFargoFlowCurrentPage, flowName }) => {
  useEffect(() => {
    if (flowName === microfFlow) {
      history.push('/RTO');
    } else if (flowName === aquaFlow) {
      history.push('/Aqua');
    } else if (flowName === preQualificationFlow) {
      history.push('/');
    } else if (flowName === powerPayFlow) {
      history.push('/powerpay');
    } else if (flowName === powerPayPreQualificationFlow) {
      history.push('/powerpay-prequalification');
    } else if (flowName === mosaicPreQualificationFlow) {
      history.push('/mosaic-prequalification');
    } else if (flowName === mosaicFlow) {
      history.push('/mosaic');
    } else if (flowName === enerBankPreQualificationFlow) {
      history.push('/enerbank-prequalification');
    } else if (flowName === enerBankFlow) {
      history.push('/enerbank');
    }
    // eslint-disable-next-line
  }, []);

  if (flowName === wellsFargoFlow)
    return (
      <>
        <Header
          title={
            WellsFargoFlow[wellsFargoFlowCurrentPage ? wellsFargoFlowCurrentPage : 0].props.title
          }
          pages={WellsFargoFlow.length - 1}
        />
        <Section
          Component={WellsFargoFlow[wellsFargoFlowCurrentPage ? wellsFargoFlowCurrentPage : 0]}
        />
      </>
    );
  else return <></>;
};

const mapStateToProps = state => ({
  wellsFargoFlowCurrentPage: state.navi.wellsFargoFlowCurrentPage,
  score: state.softPull.softPullResponse.score,
  flowName: state.navi.flowName,
  app: state.softPull.applicationSubmitDTO,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetFlow: flow => dispatch(setFlow(flow)),
});

WellsFargo.propTypes = {
  wellsFargoFlowCurrentPage: PropTypes.number.isRequired,
  dispatchSetFlow: PropTypes.func,
  score: PropTypes.number,
  flowName: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WellsFargo);
