import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { reactGAPageview, reactGAEvent, nextFinancingOption } from '../../../../helper';
import { microfFlow } from '../../../../redux/initialState';
import { mapApplicationToMicrof, resetState, changePage } from '../../../../redux/actions/index';
import selectedFinancierEnum from '../../../../assets/js/SelectedFinancierEnum';
import { Link } from 'react-router-dom';
import CustomButton from '../../../../components/UI/Button/CustomButton';
import { MicrofFlowPageNumber } from '../../../Microf/MicrofFlow';

const Declined = props => {
  const {
    user,
    email,
    dealerUserId,
    did,
    dispatchSubmitMindfire,
    dispatchUpdateSelectedFinancier,
    consumerToken,
    history,
    dispatchSetFlow,
    dispatchChangePageTitle,
    application,
    dispatchMapApplicationToMicrof,
    dispatchResetState,
    dispatchChangePage,
  } = props;

  useEffect(() => {
    dispatchChangePageTitle('Declined Application');
    reactGAPageview({
      path: '/ca_fddeclined',
      trackerNames: [],
      title: 'ca_fddeclined',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  let nextFO = nextFinancingOption(application, selectedFinancierEnum.FOUNDATION);

  const handleClick = async () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: `Continue to ${nextFO.value}`,
      did,
      dealerUserId,
    });
    dispatchSubmitMindfire({
      email: email,
      lastPage: '10022',
      dealerUserId: dealerUserId,
      did: did,
    });

    await dispatchUpdateSelectedFinancier(nextFO.value, consumerToken, () => {
      dispatchMapApplicationToMicrof(application);
      dispatchSetFlow(microfFlow);
      dispatchChangePage(MicrofFlowPageNumber.AccountEmail);
      history.push('/RTO');
    });
  };

  return (
    <Container>
      <Row className="justify-content-center mt-2">
        <Col xs="auto" sm="8" className="text-center">
          <h5>
            <b>
              Foundation was unable to approve your credit application at this time. You will
              receive a notification via email within 30 days.
            </b>
          </h5>
        </Col>
      </Row>
      {nextFO ? (
        <>
          <Row className="justify-content-center mt-2">
            <Col xs="auto" sm="8" className="text-center">
              <h5>
                <b>
                  But Wait! You may still qualify for a lease purchase option. A lease purchase
                  option is different than financing.
                </b>
              </h5>
            </Col>
          </Row>
          {nextFO === selectedFinancierEnum.MICROF && (
            <>
              <Row className="justify-content-center mt-5">
                <Col xs="auto" className="text-center">
                  <h4>Microf Lease Option</h4>
                </Col>
              </Row>
              <Row className="justify-content-center ">
                <Col xs="auto" sm="8">
                  <ul className="microf-lease-purchase-options">
                    <li>
                      Monthly renewals up to 60 months to own.{' '}
                      <p>Not all term options are available in all states.</p>
                    </li>
                    <li>
                      You will own the item after the total of payments to ownership is paid in full
                      or is paid by prepayment.{' '}
                      <p>
                        Save up to 40% or more off your remaining payments to ownership. Early
                        Purchase Options vary by state, so call us at 855-642-7631 for details.
                      </p>
                    </li>
                    <li>
                      Damaged credit? Not a problem!{' '}
                      <p>Microf regularly approves applicants with less than perfect credit.</p>
                    </li>
                  </ul>
                </Col>
              </Row>
            </>
          )}
          <Row
            className={
              nextFO === selectedFinancierEnum.MICROF
                ? 'justify-content-center'
                : 'justify-content-center mt-5'
            }
          >
            <Col xs="auto">
              <CustomButton
                color="primary"
                clickHandler={handleClick}
                title={`Continue to ${nextFO.value}`}
              />
            </Col>
          </Row>
        </>
      ) : (
        <Row className="justify-content-center mt-2">
          <Col xs="12" md="2">
            <Link
              to={user && user.dealerUser ? '/portal/dashboard' : ''}
              onClick={() => !(user && user.dealerUser) && dispatchResetState({})}
              style={{ textDecoration: 'none' }}
              className="block"
            >
              <CustomButton
                color="secondary"
                size="lg"
                block
                type="button"
                title={user && user.dealerUser ? 'Back to dashboard' : 'Exit'}
              />
            </Link>
          </Col>
        </Row>
      )}
    </Container>
  );
};

const mapStateToProps = state => ({
  consumerToken:
    state.softPull.applicationSubmitDTO && state.softPull.applicationSubmitDTO.consumerToken,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  user: state.auth.user,
  application: state.softPull.applicationSubmitDTO,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchMapApplicationToMicrof: app => dispatch(mapApplicationToMicrof(app)),
  dispatchResetState: props => dispatch(resetState(props)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Declined);
