import { Field, Form, withFormik } from 'formik';
import React from 'react';
import { Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import Button from '../../../components/UI/Button/CustomButton';
import CustomInput from '../../../components/UI/Form/Input/CustomInput';
import Select from '../../../components/UI/Form/Select/Select';
import userRoleEnum, { contractorUserEnum, findByValue } from '../../../assets/js/UserRoleEnum';
import { EMAIL_TOOLTIP_TEXT } from '../../../constants/tooltipTexts';
import { isNoreplyEmail } from '../../../helper';

const ContractorUserEditView = props => {
  const { selectedContractorUser, setIsEditMode, updateUser } = props;

  const hasContractorUserDataChanged = data => {
    let hasChanged = false;
    if (
      data.email !== selectedContractorUser.email ||
      data.firstName !== selectedContractorUser.firstName ||
      data.lastName !== selectedContractorUser.lastName ||
      (contractorUserEnum[selectedContractorUser.role] &&
        data.role !== contractorUserEnum[selectedContractorUser.role].value)
    ) {
      hasChanged = true;
    }
    return hasChanged;
  };

  const ContractorUserEditViewForm = () => (
    <Form autoComplete="off">
      <Row>
        <Col>
          <h1>Contractor User View</h1>
        </Col>
      </Row>
      <Row className="panel">
        <Col>
          <p className="info mt-3 mb-0">All fields are mandatory</p>
          <Row className="contractor-user-view-row p-0 align-items-center border-bottom mt-3">
            <Col xs="6">
              <div>Role</div>
            </Col>
            <Col xs="6">
              <Field
                className="w-75"
                component={Select}
                name="contractorUserRole"
                id="contractorUserRole"
                type="select"
                selectValues={contractorUserEnum}
              />
            </Col>
          </Row>
          <Row className="contractor-user-view-row p-0 align-items-center border-bottom">
            <Col xs="6">
              <div>Last Name</div>
            </Col>
            <Col xs="6">
              <Field
                formGroupClassName="w-75"
                component={CustomInput}
                name="lastName"
                id="lastName"
                type="text"
              />
            </Col>
          </Row>
          <Row className="contractor-user-view-row p-0 align-items-center border-bottom">
            <Col xs="6">
              <div>First Name</div>
            </Col>
            <Col xs="6">
              <Field
                formGroupClassName="w-75"
                component={CustomInput}
                name="firstName"
                id="firstName"
                type="text"
              />
            </Col>
          </Row>
          <Row className="contractor-user-view-row p-0 align-items-center">
            <Col xs="6">
              <div>Email</div>
            </Col>
            <Col xs="6">
              <Field
                formGroupClassName="w-75"
                component={CustomInput}
                name="email"
                id="email"
                tooltipText={EMAIL_TOOLTIP_TEXT}
                type="email"
              />
            </Col>
          </Row>
          <Row className="contractor-user-view-row align-items-center justify-content-end">
            <Button
              className={'inactive rounded-pill mb-3'}
              title={'Close'}
              clickHandler={() => {
                setIsEditMode(false);
              }}
            />
            <Button type="submit" className={'rounded-pill'} title={'Save'} />
          </Row>
        </Col>
      </Row>
    </Form>
  );

  const ContractorUserEditViewFormFormik = withFormik({
    mapPropsToValues: () => ({
      contractorUserRole: userRoleEnum[selectedContractorUser.role].value || '',
      lastName: selectedContractorUser.lastName || '',
      firstName: selectedContractorUser.firstName || '',
      email: selectedContractorUser.email || '',
    }),

    validationSchema: Yup.object({
      contractorUserRole: Yup.string().required('Please select a role.'),
      lastName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .required('Please enter the users last name.'),
      firstName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .required('Please enter the users first name.'),
      email: Yup.string()
        .email('Please enter a valid email.')
        .required('Please enter an email.')
        .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),
    }),

    handleSubmit: async values => {
      const data = {
        id: selectedContractorUser.id,
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        role: findByValue(values.contractorUserRole),
      };

      if (hasContractorUserDataChanged(data)) {
        updateUser(data, setIsEditMode);
      } else {
        setIsEditMode(false);
      }
    },
  })(ContractorUserEditViewForm);

  return (
    <Col>
      <ContractorUserEditViewFormFormik />
    </Col>
  );
};

export default ContractorUserEditView;
