import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const ConfirmDelete = props => {
  const {
    headerText,
    bodyText,
    confirmDelete,
    cancelButtonText,
    confirmButtonText,
    className,
    cancelEventHandler,
    confirmEventHandler,
  } = props;

  return (
    <Modal isOpen={confirmDelete} centered className={className}>
      <ModalHeader toggle={cancelEventHandler}>{headerText}</ModalHeader>
      <ModalBody>{bodyText}</ModalBody>
      <ModalFooter>
        <Button className="rounded-pill" color="primary" onClick={cancelEventHandler}>
          {cancelButtonText}
        </Button>
        <Button className="rounded-pill" color="secondary" onClick={confirmEventHandler}>
          {confirmButtonText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ConfirmDelete.propTypes = {
  headerText: PropTypes.string,
  bodyText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  confirmDelete: PropTypes.bool,
  cancelEventHandler: PropTypes.func,
  confirmEventHandler: PropTypes.func,
};

export default ConfirmDelete;
