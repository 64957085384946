import React from 'react';
import { connect } from 'react-redux';
import { Button, Col, Container, Row } from 'reactstrap';
import { changePage } from '../../redux/actions';
import { MicrofFlowPageNumber } from './MicrofFlow';
import MicrofCheckList from './MicrofCheckList';
import TheMicrofStory from '../../assets/js/TheMicrofStory';

const LandingFTL = ({ dispatchChangePage }) => (
  <Container>
    <Row className="justify-content-center mt-2">
      <Col xs="12" md="auto" className="text-center">
        <h1 className="primary font-weight-bold">
          Congratulations!
          <br />
          You are prequalified for a lease-purchase option.
        </h1>
        <h2 className="orange font-weight-bold">We’re Microf, an FTL Finance Partner.</h2>
      </Col>
    </Row>
    <Row className="readyToFinish bg-transparent">
      <Col xs="12" lg="6" className="mt-3">
        <TheMicrofStory />
      </Col>
      <Col xs="12" lg="6" className="mt-3">
        <MicrofCheckList />
      </Col>
    </Row>
    <Row className="mt-3 justify-content-center">
      <Col xs="12" md="auto">
        <Button
          color="primary"
          onClick={() => dispatchChangePage(MicrofFlowPageNumber.ServiceFinance)}
        >
          NEXT STEP
        </Button>
      </Col>
    </Row>
    <Row className="mt-3">
      <Col xs="auto">
        <p>
          *Please note, your lease-purchase agreement will be with Microf and you will make payments
          in accordance with the terms of that lease-purchase agreement. A letter outlining the
          reason for the FTL Finance application decision will be mailed to you.
        </p>
      </Col>
    </Row>
  </Container>
);

const mapStateToProps = state => ({
  page: state.navi.microfFlowCurrentPage,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LandingFTL);
