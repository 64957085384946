import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import Offer from './Components/Offer';
import upgradeLogo from '../../../assets/img/upgrade.svg';
import { currencyFormat } from '../../../helper';
import {
  changePreQualInfoValue,
  resetNewAppState,
  resetState,
  upgradeSelectOffer,
} from '../../../redux/actions';

const Offers = props => {
  const {
    upgradeResponse,
    upgradeApplicationId,
    totalJobPrice,
    dispatchUpgradeSelectOffer,
    dispatchResetState,
    history,
    dispatchChangeValue,
    upgradeOfferSelected,
  } = props;

  // if (upgradeOfferSelected) {
  //   history.push('/portal/dashboard');
  // }

  const returnToDashboard = () => {
    window.location.href = process.env.REACT_APP_DEALER_PORTAL_URL + '/portal/dashboard';
  };

  if (upgradeResponse && upgradeResponse.upgradeOffers) {
    if (upgradeResponse.upgradeOffers.length > 0) {
      const checkSelectedOffer = upgradeResponse.upgradeOffers.find(
        offer => offer.selected === true,
      );
      if (checkSelectedOffer) {
        dispatchChangeValue('upgradeOfferSelected', true);
        window.open(checkSelectedOffer.offerUrl, '_self');
      }
    }
  }

  const selectOffer = ({ history, data }) => {
    dispatchUpgradeSelectOffer(data, history);
  };

  return upgradeResponse ? (
    <Container className="container upgrade-offers">
      <Row className="justify-content-center mt-2">
        {upgradeOfferSelected ? (
          <div className="text-center fs-32 fw-500">
            <p className="fs-32">
              <b className="primary">You already have an offer selected with:</b>
            </p>
            <div className="mb-15">
              <img src={upgradeLogo} alt="logo" />
            </div>
            <Row className="p-10 justify-content-center">
              <Button
                type="submit"
                className="rounded-pill"
                onClick={() => {
                  returnToDashboard();
                }}
              >
                Return to Dashboard
              </Button>
            </Row>
          </div>
        ) : (
          <Col sm="8">
            <div className="text-center fs-32 fw-500">
              <p className="fs-32">
                <b className="primary">
                  Nice! You have been prequalified for a lending option through:
                </b>
              </p>
              <div className="mb-15">
                <img src={upgradeLogo} alt="logo" />
              </div>
              <span>{`${upgradeResponse?.nameFirst}’s Qualifying Offers`}</span>
            </div>
            <Row className="offers justify-content-center mt-40">
              <Col xs="auto" sm="12" md="12">
                <div className="text-center mt-40">
                  <p className="fs-18 fw-500 grey mb-0">Project Amount</p>
                  <p className="fs-32 fw-500">
                    {totalJobPrice ? currencyFormat(totalJobPrice) : 'N/A'}
                  </p>
                </div>
                <div className="text-center mt-40">
                  <p className="fs-16 fw-500 grey">
                    When discussing the cost of payment plans, you may only refer to the Annual
                    Percentage Rate <br /> APRs displayed include an optional autopay discount
                  </p>
                </div>
                {upgradeResponse?.upgradeOffers.map((offer, i) => {
                  return (
                    <Offer
                      offer={offer}
                      upgradeApplicationId={upgradeApplicationId}
                      selectOffer={selectOffer}
                      key={offer.upgradeOfferId}
                    />
                  );
                })}
              </Col>
            </Row>
            <Row className="disclosures mt-40">
              <Col className="p-0" xs="auto" sm="12" md="12">
                <p>
                  These loan terms are not guaranteed and are subject to our verification and review
                  process. Applicants may be asked to provide additional documents to enable us to
                  verify their income and their identity.For loans that charge interest, this rate
                  includes an Autopay APR reduction of 0.5%.By enrolling in Autopay,
                  borrowers'payments will be automatically deducted from their bank
                  account.Selecting Autopay is optional.Subsequent charges and fees may increase the
                  cost of the loan. There is no fee or penalty for repaying a loan early.For more
                  information, applicants should refer to the applicable Borrower Agreement and TILA
                  Disclosure.
                </p>
                <p>
                  Loans may be disbursed in one or more advances. These loan terms are estimates
                  based on the assumption that the loan is disbursed in a single advance upon
                  execution of the Borrower Agreement. Actual loan terms may vary depending on,
                  among other things, the exact number of advances, the amount of each advance, and
                  the date on which each advance will occur.
                </p>
                <p>
                  *For example, under this promotional plan (i.e., no payments required and no
                  interest charges for a 12 months promotional period beginning after we disburse
                  the first advance), if a borrower receives a $10,000 loan with a 10-year term and
                  a 29.49% Annual Percentage Rate (APR) and the loan is disbursed in 2 advances (20%
                  on Day 1 and 80% on Day 90), the borrower will have a required monthly payment of
                  $259.86 after the promotional period ends.
                </p>
                <p>
                  †For example, under this promotional plan (i.e., no interest charges for a 12
                  months promotional period beginning after we disburse the first advance, but
                  payments are required monthly), if a borrower receives a $10,000 loan with a
                  10-year term and a 29.49% Annual Percentage Rate (APR) and the loan is disbursed
                  in 2 advances (20% on Day 1 and 80% on Day 90), the borrower will have a required
                  monthly payment of $218.01.
                </p>
                <p>
                  ‡For example, if a borrower receives a $10,000 loan with a 10-year term and a
                  6.99% Annual Percentage Rate (APR) and the loan is disbursed in 2 advances (20% on
                  Day 1 and 80% on Day 90), the borrower will have a required monthly payment of
                  $116.06.
                </p>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </Container>
  ) : null;
};

const mapStateToProps = state => ({
  upgradeOfferSelected: state.preQualificationInformation.upgradeOfferSelected,
  upgradeResponse: state.softPull.upgradeResponse,
  upgradeApplicationId: state.softPull.upgradeResponse?.upgradeInformation?.upgradeApplicationId,
  totalJobPrice: state.softPull.upgradeResponse?.totalJobPrice,
});

const mapDispatchToProps = dispatch => ({
  dispatchUpgradeSelectOffer: (data, history) => dispatch(upgradeSelectOffer(data, history)),
  dispatchResetState: props => dispatch(resetNewAppState()),
  dispatchChangeValue: (key, value) => dispatch(changePreQualInfoValue(key, value)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Offers),
);
