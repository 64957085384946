import React from 'react';
import PropTypes from 'prop-types';
import { GoAlert } from 'react-icons/go';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { removeNotification, resetState, changeAuthValue } from '../../../redux/actions/index';

const Notification = props => {
  const {
    message,
    messageType,
    dispatchRemoveNotification,
    className,
    history,
    dispatchResetState,
    dispatchChangeAuthValue,
  } = props;

  const currentPath = window.location.href;
  return ('' + message).includes('Authority Not Granted.') ? (
    <Modal isOpen={message !== null} className={className}>
      <ModalHeader
        className="error"
        toggle={() => {
          dispatchRemoveNotification();
          if (currentPath.indexOf('/login') < 0) {
            dispatchChangeAuthValue('currentPath', currentPath);
          }
          history.push('/login');
        }}
      >
        <GoAlert /> Error
      </ModalHeader>
      <ModalBody>Your session has expired, Please log in again.</ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            dispatchRemoveNotification();
            if (currentPath.indexOf('/login') < 0) {
              dispatchChangeAuthValue('currentPath', currentPath);
            }
            history.push('/login');
          }}
        >
          OK
        </Button>
      </ModalFooter>
    </Modal>
  ) : ('' + message).includes('Full authentication is required to access this resource') ? (
    <Modal isOpen={message !== null} className={className}>
      <ModalHeader
        className="error"
        toggle={() => {
          dispatchRemoveNotification();
          dispatchResetState({ history: null });
          history.push('/login');
        }}
      >
        <GoAlert /> Error
      </ModalHeader>
      <ModalBody>Your session has expired, Please log in again.</ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            dispatchRemoveNotification();
            dispatchResetState({ history: null });
            history.push('/login');
          }}
        >
          OK
        </Button>
      </ModalFooter>
    </Modal>
  ) : (
    <Modal isOpen={message !== null} className={className}>
      <ModalHeader
        className={messageType === 'Success' ? null : 'error'}
        toggle={dispatchRemoveNotification}
      >
        {messageType === 'Success' ? null : <GoAlert />} {messageType}
      </ModalHeader>
      <ModalBody>{message}</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={dispatchRemoveNotification}>
          OK
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = state => ({
  message: state.notification.message,
  messageType: state.notification.messageType,
});

const mapDispatchToProps = dispatch => ({
  dispatchRemoveNotification: () => dispatch(removeNotification()),
  dispatchResetState: props => dispatch(resetState(props)),
  dispatchChangeAuthValue: (key, value) => dispatch(changeAuthValue(key, value)),
});

Notification.propTypes = {
  message: PropTypes.string,
  messageType: PropTypes.string,
  dispatchRemoveNotification: PropTypes.func,
  className: PropTypes.string,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Notification),
);
