import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import SearchTable from './SearchTable';
import { getUploadedDocuments } from '../../../redux/actions/index';

const SearchContractorUsers = props => {
  const { history, uploadedDocuments, auth, dispatchGetUploadedDocuments } = props;

  useEffect(() => {
    if (!auth.user && (!auth.user.dealerUser || !auth.user.internalUser)) {
      history.push('/login');
    }
    dispatchGetUploadedDocuments();
    // eslint-disable-next-line
  }, []);

  return (
    <Col>
      <Row>
        <Col>
          <h1>Uploaded Documents</h1>
        </Col>
      </Row>
      <Row className="search-users panel p-3">
        <Col>
          <SearchTable uploadedDocuments={uploadedDocuments} />
        </Col>
      </Row>
    </Col>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  uploadedDocuments: state.softPull.uploadedDocuments,
});

const mapDispatchToProps = dispatch => ({
  dispatchGetUploadedDocuments: () => dispatch(getUploadedDocuments()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SearchContractorUsers),
);
