import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row, Label } from 'reactstrap';
import * as Yup from 'yup';
import usStates from '../../../assets/js/USStateEnum';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import Input from '../../../components/UI/Form/Input/CustomInput';
import Select from '../../../components/UI/Form/Select/Select';
import { changeAquaInfoValue, changePage, submitMindfire } from '../../../redux/actions/index';
import PropTypes from 'prop-types';
import { reactGAPageview, reactGAEvent } from '../../../helper';
import { AquaFlowPageNumber } from '../AquaFlow';
import {
  COAPPCITY_TOOLTIP_TEXT,
  COAPPSTATE_TOOLTIP_TEXT,
  COAPPSTREET_TOOLTIP_TEXT,
  COAPPZIPCODE_TOOLTIP_TEXT,
} from '../../../constants/tooltipTexts';

const CoAppAddress = props => {
  const {
    children,
    dispatchChangeAquaInfoValue,
    aquaInformation,
    pqi,
    dispatchChangePage,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_aqcoappinfo2',
      trackerNames: [],
      title: 'ca_aqcoappinfo2',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const setIsSameAddress = bool => {
    dispatchChangeAquaInfoValue('isSameAddress', bool);
  };

  const CoAppAddressForm = () => (
    <Form>
      <Label>
        <b>The address is the same as the applicant's.</b>
      </Label>
      <Row>
        <Col sm="6">
          <Button
            className={aquaInformation.isSameAddress ? '' : 'inactive'}
            type="button"
            title="Yes"
            clickHandler={() => setIsSameAddress(true)}
          />
        </Col>
        <Col sm="6">
          <Button
            className={!aquaInformation.isSameAddress ? '' : 'inactive'}
            type="button"
            title="No"
            clickHandler={() => setIsSameAddress(false)}
          />
        </Col>
      </Row>
      <div className={!aquaInformation.isSameAddress ? '' : 'opacity-05'}>
        <Field
          disabled={aquaInformation.isSameAddress}
          component={Input}
          label="Street"
          name="coAppStreet"
          id="coAppStreet"
          tooltipText={COAPPSTREET_TOOLTIP_TEXT}
          type="text"
        />
        <Row>
          <Col sm="6">
            <Field
              disabled={aquaInformation.isSameAddress}
              component={Input}
              label="City"
              name="coAppCity"
              id="coAppCity"
              tooltipText={COAPPCITY_TOOLTIP_TEXT}
              type="text"
            />
          </Col>
          <Col sm="6">
            <Field
              disabled={aquaInformation.isSameAddress}
              component={Select}
              label="State"
              name="coAppState"
              id="coAppState"
              tooltipText={COAPPSTATE_TOOLTIP_TEXT}
              type="select"
              selectValues={usStates}
              selectFirstName="Select State"
            />
          </Col>
        </Row>
        <Field
          disabled={aquaInformation.isSameAddress}
          component={Input}
          label="ZIP Code"
          name="coAppZipCode"
          id="coAppZipCode"
          tooltipText={COAPPZIPCODE_TOOLTIP_TEXT}
          type="text"
          maxLength={10}
        />
      </div>
      <Button type="submit" title="Next" />
    </Form>
  );

  const CoAppAddressFormFormik = withFormik({
    mapPropsToValues: () => ({
      coAppStreet:
        aquaInformation.isSameAddress && pqi.street
          ? pqi.street
          : aquaInformation.coAppStreet || '',
      coAppCity:
        aquaInformation.isSameAddress && pqi.city ? pqi.city : aquaInformation.coAppCity || '',
      coAppZipCode:
        aquaInformation.isSameAddress && pqi.zipcode
          ? pqi.zipcode
          : aquaInformation.coAppZipCode || '',
      coAppState:
        aquaInformation.isSameAddress && pqi.state ? pqi.state : aquaInformation.coAppState || '',
    }),

    validationSchema: Yup.object({
      coAppStreet: Yup.string().required('Please enter a street.'),
      coAppCity: Yup.string().required('Please enter a city.'),
      coAppZipCode: Yup.string()
        .matches(/^\d{5}?$/, {
          message: 'Invalid zip code.',
          excludeEmptyString: true,
        })
        .required('Please enter a zip code.'),
      coAppState: Yup.string().required('Please select a state.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      dispatchChangeAquaInfoValue('coAppStreet', values.coAppStreet);
      dispatchChangeAquaInfoValue('coAppCity', values.coAppCity);
      dispatchChangeAquaInfoValue('coAppZipCode', values.coAppZipCode);
      dispatchChangeAquaInfoValue('coAppState', values.coAppState);
      dispatchChangePage(AquaFlowPageNumber.CoAppAdditionalInfo);
      dispatchSubmitMindfire({
        email: pqi.email,
        lastPage: '0052',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(CoAppAddressForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={
            aquaInformation.isReview
              ? AquaFlowPageNumber.CoAppDetails
              : AquaFlowPageNumber.CoAppOtherIncome
          }
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <CoAppAddressFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  pqi: state.preQualificationInformation,
  aquaInformation: state.aquaInformation,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeAquaInfoValue: (key, value) => dispatch(changeAquaInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

CoAppAddress.propTypes = {
  dispatchChangePage: PropTypes.func,
  dispatchChangeAquaInfoValue: PropTypes.func,
  pqi: PropTypes.object,
  aquaInformation: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CoAppAddress);
