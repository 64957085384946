import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap/';
import { useVSPixelScript } from '../../../assets/hooks';
import ftlFinance from '../../../assets/img/FTLFinance.png';
import CustomButton from '../../../components/UI/Button/CustomButton';
import { getDealerPortalURL, getIsOptimus } from '../../../config/settings';
import { reactGAEvent } from '../../../helper';
import { resetNewAppState, resetState } from '../../../redux/actions';

const Pending = props => {
  const {
    dealerUserId,
    did,
    dispatchSubmitMindfire,
    email,
    history,
    user,
    dispatchResetNewAppState,
    dispatchResetState,
  } = props;

  useVSPixelScript();

  const isUserLoggedIn = !did && user && user.dealerUser;
  const returnUrl = isUserLoggedIn ? '/portal/dashboard' : getDealerPortalURL();

  const isOptimus = getIsOptimus();

  const handleClick = () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Done',
      did,
      dealerUserId,
    });
    dispatchSubmitMindfire({
      email: email,
      lastPage: '10023',
      dealerUserId: dealerUserId,
      did: did,
    });
    if (!isUserLoggedIn) {
      dispatchResetState(props);
      window.location.href = returnUrl;
    } else {
      dispatchResetNewAppState();
      history.push(returnUrl);
    }
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs="auto" sm="10">
          <Row className="justify-content-center">
            <Col xs="auto">
              <h5 className="text-center">
                <b>Your application was successfully submitted! Check your text message!</b>
              </h5>
            </Col>
          </Row>
          <Row className="justify-content-center mb-3">
            <Col xs="auto" sm="9">
              <h5 className="text-center">
                Thank you for completing your application. You will receive a text message from FTL
                Finance to verify your information. Once a decision is made on your application, FTL
                Finance will send the results to you via email.
              </h5>
            </Col>
          </Row>

          <Row className="justify-content-center mb-4">
            <Col xs="auto">
              <img alt="FTL Finance" src={ftlFinance} width="250px" />
            </Col>
          </Row>

          <Row className="justify-content-center mb-50">
            <Col xs="12" md="auto">
              <CustomButton
                type="submit"
                title={isOptimus ? 'Return to Optimus Dashboard' : 'Return to Dashboard'}
                clickHandler={handleClick}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  email: state.foundationInformation.email,
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
  dispatchResetState: props => dispatch(resetState(props)),
  dispatchResetNewAppState: () => dispatch(resetNewAppState()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Pending),
);
