import { Field, Form, withFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import CurrencyFormat from '../../../../components/UI/Form/NumberFormat/CurrencyFormat';
import {
  changeApplicationValues,
  currencyFormat,
  reactGAEvent,
  reactGAPageview,
} from '../../../../helper';
import {
  changePage,
  submitMindfire,
  changeAtWaterApplicationInfoValue,
  loanDecision,
} from '../../../../redux/actions/index';
import { AtWaterFlowPageNumber } from './AtWaterFlow';
import Input from '../../../../components/UI/Form/Input/CustomInput';

const ApplicationAproved = props => {
  const { did, dealerUserId, applicationSubmitDTO, dispatchLoanDecision } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_gsloaninformation',
      trackerNames: [],
      title: 'ca_gsloaninformation',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const data = applicationSubmitDTO?.serviceFinanceInformation;

  const ApplicationAprovedForm = ({ values }) => (
    <Form>
      <Field
        component={Input}
        label="Program Type"
        name="programType"
        id="programType"
        tooltipText={''}
        disabled={true}
      />
      <Field
        component={CurrencyFormat}
        name="amounts"
        id="amounts"
        tooltipText={''}
        label="Amounts"
      />
      <Field
        component={Input}
        label="Term"
        name="term"
        id="term"
        tooltipText={''}
        disabled={true}
      />
      <Field
        component={Input}
        label="Rate"
        name="rate"
        id="rate"
        tooltipText={''}
        disabled={true}
      />
      <Field
        component={CurrencyFormat}
        name="payment"
        id="payment"
        tooltipText={''}
        label="Payment"
        disabled={true}
      />

      <Button type="submit" title="Accept Offer" />
      <br />
      <p>
        Due to counteroffer’s done by the auto-decision engine. The values may not be the same that
        were applied for originally.
      </p>
    </Form>
  );

  const ApplicationAprovedFormFormik = withFormik({
    mapPropsToValues: () => ({
      programType: data.prgmType || '',
      amounts: applicationSubmitDTO.requestedAmount || '',
      term: data?.term || '',
      rate: data?.rate || '',
      payment: data?.payment || '',
    }),

    validationSchema: Yup.object({
      programType: Yup.string().required('Please enter a Product.'),
      amounts: Yup.number()
        .moreThan(499, `Amount should be at least $1500.`)
        .lessThan(applicationSubmitDTO.requestedAmount + 0.01 || 100000, 'Amount too high.')
        .required('Please enter an amount.'),
      term: Yup.string().required('Please enter a term.'),
      rate: Yup.string().required('Please enter a rate.'),
      payment: Yup.number().required('Please enter the payment.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });

      if (
        values.programType !== data.prgmType ||
        values.amounts !== applicationSubmitDTO.requestedAmount ||
        values.term !== data.term ||
        values.rate !== data.rate ||
        values.payment !== data.payment
      ) {
        const requestBody = {
          loanid: data.serviceFinanceAppId,
          acceptLoan: false,
          newAmount: values.amounts,
        };
        dispatchLoanDecision(
          applicationSubmitDTO.poolsApplicationId,
          requestBody,
          AtWaterFlowPageNumber.ApplicationReceived,
        );
      } else {
        const requestBody = {
          loanid: data.serviceFinanceAppId,
          acceptLoan: true,
          newAmount: values.amounts,
        };
        dispatchLoanDecision(
          applicationSubmitDTO.poolsApplicationId,
          requestBody,
          AtWaterFlowPageNumber.ApplicationReceived,
        );
      }
    },
  })(ApplicationAprovedForm);

  return (
    <Container>
      <Row>
        <Col sm="6">
          <h4>
            {`Congratulations, your application has been approved for a Credit Limit of ${currencyFormat(
              parseInt(applicationSubmitDTO.requestedAmount),
            )}`}
          </h4>
        </Col>
        <Col sm="6">
          <ApplicationAprovedFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  dealerId: state.auth.dealerId,
  state: state.atWaterApplicationInformation.state,
  applicationSubmitDTO: state.softPull.applicationSubmitDTO,
  loanDecisionResponse: state.atWaterApplicationInformation.loanDecisionResponse,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeAtWaterApplicationInfoValue: (key, value) =>
    dispatch(changeAtWaterApplicationInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchLoanDecision: (appId, data, page) => dispatch(loanDecision(appId, data, page)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApplicationAproved);
