import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { IoMdCheckmark } from 'react-icons/io';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import Button from '../../components/UI/Button/CustomButton';
import { reactGAEvent, reactGAPageview } from '../../helper';
import {
  changePage,
  resetState,
  submitMindfire,
  updateApplicationStatus,
} from '../../redux/actions/index';
import { RheemFlowPageNumber } from './RheemFlow';

const PreQualified = props => {
  const {
    email,
    dispatchChangePage,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    score,
    scoreLimit,
  } = props;
  useEffect(() => {
    reactGAPageview({
      path: '/ca_requirements',
      trackerNames: [],
      title: 'ca_requirements',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Row>
        <Col sm="1" />
        <Col sm="5">
          <h4>
            Nice! You have pre-qualified for the Residential KwikComfort® Simplicity<sup>TM</sup>{' '}
            Program, our Prime Lease Purchase Option!
          </h4>
          <p>
            We need some more information about your home, identity, and income (or benefits) so we
            can determine the best option for you
          </p>
        </Col>
        <Col sm="6">
          <Row className="readyToFinish">
            <Col>
              <h4>Here’s what you’ll need to finish the application:</h4>
              <p>
                <IoMdCheckmark className="ok" />
                Social Security Number
              </p>
              <p>
                <IoMdCheckmark className="ok" />
                Annual income (or benefits) information
              </p>
              <p>
                <IoMdCheckmark className="ok" />
                Co-Applicant’s information (if applicable)
              </p>
            </Col>
          </Row>
          <Button
            clickHandler={() => {
              reactGAEvent({
                category: 'Button',
                action: 'Click',
                label: 'Continue',
                did,
                dealerUserId,
              });
              dispatchChangePage(RheemFlowPageNumber.LoanAmount);
              dispatchSubmitMindfire({
                email: email,
                dealerUserId: dealerUserId,
                did: did,
              });
            }}
            title={score >= scoreLimit ? 'Continue' : 'Continue and Complete Application'}
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  microfOnly: state.microfInformation.microfOnly,
  email: state.preQualificationInformation.email,
  aquaFinanceAssigned: state.auth.aquaFinanceAssigned,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  appDTO: state.softPull.applicationSubmitDTO,
  aquaFinanceApplication: state.softPull.applicationSubmitDTO
    ? state.softPull.applicationSubmitDTO.aquaFinanceApplication
    : null,
  consumerToken: state.softPull.applicationSubmitDTO
    ? state.softPull.applicationSubmitDTO.consumerToken
    : null,
  score: state.softPull.softPullResponse.score,
  scoreLimit: state.softPull.softPullResponse.scoreLimit,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchResetState: props => dispatch(resetState(props)),
  dispatchUpdateApplicationStatus: (applicationStatus, consumerToken) =>
    dispatch(updateApplicationStatus(applicationStatus, consumerToken)),
});

PreQualified.propTypes = {
  dispatchChangePage: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PreQualified),
);
