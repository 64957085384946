import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Field, Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Col, Row, Alert } from 'reactstrap';
import Recaptcha from 'react-recaptcha';
import {
  changeGSRegisterAsContractorInfoValue,
  submitMindfire,
  registerContractor,
  endLoading,
} from '../../redux/actions/index';
import EINFormat from '../../components/UI/Form/NumberFormat/EINFormat';
import CustomInput from '../../components/UI/Form/Input/CustomInput';
import CustomPhoneNumber from '../../components/UI/Form/PhoneNumber/PhoneNumber';
import Button from '../../components/UI/Button/CustomButton';
import { getAddedFrom, getAppName } from '../../config/settings';
import Logo from '../../components/UI/Logo/Logo';
import PixelSiteScouting from '../../components/UI/Tracking/PixelSiteScouting';
import {
  CONTRACTOR_EIN_TOOLTIP_TEXT,
  EMAIL_TOOLTIP_TEXT,
  MANAGER_FIRST_NAME_TOOLTIP_TEXT,
  MANAGER_LAST_NAME_TOOLTIP_TEXT,
  PHONENUMBER_TOOLTIP_TEXT,
} from '../../constants/tooltipTexts';
import { isNoreplyEmail } from '../../helper';

const appName = getAppName();

const RegisterAsContractor = props => {
  const { gsi, dispatchChangeValue, dispatchRegisterContractor, registrationParam } = props;
  const [isPixelMounted, setIsPixelMounted] = useState(false);

  useEffect(() => {
    dispatchChangeValue('isGreenSky', props.isGreenSky);
    // eslint-disable-next-line
  }, []);

  let recaptchaVal = null;
  const handleChange = value => {
    recaptchaVal = value;
  };

  const RegisterAsContractorForm = ({ values, errors, touched }) => (
    <Form autoComplete="off">
      <h3>Dealership</h3>
      <Field
        maxLength={10}
        component={EINFormat}
        label="Federal Tax ID (EIN) *:"
        name="ein"
        id="ein"
        tooltipText={CONTRACTOR_EIN_TOOLTIP_TEXT}
        type="text"
      />
      <h3 className="margin-top">My Contact Information</h3>
      <Field
        component={CustomInput}
        label="First Name *:"
        name="firstName"
        id="firstName"
        tooltipText={MANAGER_FIRST_NAME_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={CustomInput}
        label="Last Name *:"
        name="lastName"
        id="lastName"
        tooltipText={MANAGER_LAST_NAME_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={CustomInput}
        label="Email *:"
        name="email"
        id="email"
        tooltipText={EMAIL_TOOLTIP_TEXT}
        type="email"
      />
      <Field
        component={CustomPhoneNumber}
        label="Phone *:"
        name="phone"
        id="phone"
        tooltipText={PHONENUMBER_TOOLTIP_TEXT}
      />
      <h3>Confirm You Are Not a Robot!</h3>
      {gsi.recaptchaKey ? (
        <div className="form-group">
          <Recaptcha sitekey={gsi.recaptchaKey} render="explicit" verifyCallback={handleChange} />
          {gsi.recaptchaError ? <p className="error">{gsi.recaptchaError}</p> : null}
        </div>
      ) : null}
      <Button
        type="submit"
        disabled={gsi.registered && gsi.registeredMessage === 'Success'}
        className="login-button btn btn-primary btn-lg btn-block"
      >
        Register
      </Button>
      {isPixelMounted && <PixelSiteScouting type="register" />}
    </Form>
  );

  const RegisterAsContractorFormFormik = withFormik({
    mapPropsToValues: () => ({
      ein: gsi.ein || '',
      firstName: gsi.firstName || '',
      lastName: gsi.lastName || '',
      email: gsi.email || '',
      phone: gsi.phone || '',
      recaptcha: null,
    }),

    validationSchema: Yup.object({
      ein: Yup.string()
        .min(9, 'Invalid EIN, use format 12-3456789.')
        .required('Please enter your Federal Tax ID (EIN).'),
      firstName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .required('Please enter your First Name.'),
      lastName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .required('Please enter your Last Name.'),
      email: Yup.string()
        .email('Please enter a valid email.')
        .required('Please enter your Email.')
        .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),
      phone: Yup.string()
        .test('required', 'Please enter the phone number.', val => val !== '+' && val)
        .test('len', 'Invalid phone number', val => val && val.match(/\d/g).join('').length === 10),
    }),

    handleSubmit: values => {
      setIsPixelMounted(true);
      dispatchChangeValue('registered', false);
      dispatchChangeValue('registeredMessage', null);
      dispatchChangeValue('recaptchaError', null);
      dispatchChangeValue('ein', values.ein);
      dispatchChangeValue('firstName', values.firstName.trim());
      dispatchChangeValue('lastName', values.lastName.trim());
      dispatchChangeValue('email', values.email);
      dispatchChangeValue('phone', values.phone);
      dispatchChangeValue('recaptcha', recaptchaVal);
      if (recaptchaVal) {
        const ein = '' + values.ein;
        const dealerUserRegistrationDTO = {
          lastName: values.lastName.trim(),
          firstName: values.firstName.trim(),
          email: values.email,
          phone: values.phone,
          captchaResponse: recaptchaVal,
          ein: ein.substring(0, 2) + '-' + ein.substring(2, ein.length),
          uniqueEINRequire: false,
          isGreenSky: gsi.isGreenSky,
          registrationParam: registrationParam,
          addedFrom: getAddedFrom(),
        };
        dispatchRegisterContractor(dealerUserRegistrationDTO);
      } else {
        dispatchChangeValue('recaptchaError', 'Please confirm that you are not a robot.');
      }
    },
  })(RegisterAsContractorForm);

  return (
    <Row className="login-page" noGutters>
      <Col xs="12" md="6" lg="8" className="px-0 left">
        <Row noGutters className={gsi.isGreenSky ? 'imageGreenSky' : 'login-image'}>
          <Logo />
        </Row>
      </Col>
      <Col sm="4" className="sign-in-panel p-3">
        <div className="login-form">
          <h1>
            <b>Register as Contractor</b>
          </h1>
          {gsi.registered ? (
            <>
              {gsi.registeredMessage === 'Success' ? (
                <Alert color="success">
                  A user account has been created. You will receive an email shortly with
                  instructions to activate your account. Once you activate your account, you will be
                  able to enroll your dealership with {appName}.
                </Alert>
              ) : gsi.registeredMessage === 'UserAlreadyExistsException' ? (
                <Alert color="danger">
                  You are already registered! If you cannot access your account, contact {appName}{' '}
                  for support.
                </Alert>
              ) : gsi.registeredMessage === 'DealershipAlreadyReservedException' ? (
                <Alert color="warning">
                  WARNING: A dealership has already registered with this tax ID. Depending on your
                  business type, the EIN may not be unique to your dealership. If that is the case,
                  then accept the consent and proceed with your registration. If someone from your
                  dealership has already registered, then you should not register. This admin user
                  for your dealership can provision you an account. If you have any questions, or
                  need assistance in identifying your admin user, then contact {appName} for
                  support.
                </Alert>
              ) : gsi.registeredMessage === 'OperationForbiddenException' ? (
                <Alert color="danger">Please check the reCaptcha and the EIN number.</Alert>
              ) : null}
            </>
          ) : (
            <RegisterAsContractorFormFormik />
          )}
        </div>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => ({
  gsi: state.greenskyInformation.registerAsContractor,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangeValue: (key, value) => dispatch(changeGSRegisterAsContractorInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchRegisterContractor: params => dispatch(registerContractor(params)),
  dispatchEndLoading: () => dispatch(endLoading()),
});

RegisterAsContractor.propTypes = {
  dispatchChangeValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegisterAsContractor);
