import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import Button from '../../../components/UI/Button/CustomButton';
import Checkbox from '../../../components/UI/Form/Checkbox/Checkbox';
import { reactGAEvent, reactGAPageview } from '../../../helper';
import {
  submitMindfire,
  submitMosaicApplication,
  changeMosaicInfoValue,
} from '../../../redux/actions/index';
import selectedFinancierEnum from '../../../assets/js/SelectedFinancierEnum';
import { MosaicFlowPageNumber } from './MosaicFlow';
import mosaicLogo from '../../../assets/img/mosaic.png';
import { getIsOptimus } from '../../../config/settings';
import AirConditioningSystemEnum from '../../../assets/js/AirConditioningSystemEnum';
const isOptimus = getIsOptimus();

const Consent = props => {
  const {
    consent,
    dispatchChangeMosaicInfoValue,
    did,
    dealerUserId,
    dispatchSubmitMindfire,
    dispatchSubmitMosaicApplication,
    app,
    consumerId,
    pqi,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_ppconsent',
      trackerNames: [],
      title: 'ca_ppconsent',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Continue',
      did,
      dealerUserId,
    });
    dispatchSubmitMindfire({
      lastPage: '6009',
      dealerUserId: dealerUserId,
      did: did,
    });
    dispatchChangeMosaicInfoValue('consent', true);

    const request = {
      consumerId: consumerId,
      email: pqi.email || null,
      borrowedAmount: pqi.borrowedAmount !== null ? Math.floor(pqi.borrowedAmount) : null,
      firstName: pqi.firstName || null,
      lastName: pqi.lastName || null,
      middleName: pqi.middleName || null,
      phoneNumber: pqi.phoneNumber || null,
      street: pqi.street || null,
      city: pqi.city || null,
      zipcode: pqi.zipcode || null,
      state: pqi.state || null,
      airConditioningSystem:
        pqi.airConditioningSystem || AirConditioningSystemEnum.OTHER_HOME_IMPROVEMENT.id,
      isHomeowner: pqi.isHomeowner,
      birthday: pqi.birthday || null,
      dealerUserId: dealerUserId || null,
      prequalificationId: pqi.prequalificationId || null,
      prequalificationToken: pqi.prequalificationToken || null,
      selectedFinancier: selectedFinancierEnum.MOSAIC.value,
    };

    dispatchSubmitMosaicApplication(request, MosaicFlowPageNumber.MosaicIFrame, app);
  };

  const ConsentForm = () => (
    <Form noValidate autoComplete="off">
      <Field
        component={Checkbox}
        name="consent"
        id="consent"
        label="Applicant"
        labelClassName="bold mb-0"
      />
      <Button type="submit" title={'Continue'} />
    </Form>
  );

  const ConsentFormFormik = withFormik({
    mapPropsToValues: () => ({
      consent: consent,
    }),

    validationSchema: Yup.object({
      consent: Yup.boolean().oneOf([true], 'Please check to continue.'),
    }),

    handleSubmit,
  })(ConsentForm);

  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs="auto" sm="11">
          <Row className="justify-content-center">
            <Col xs="auto">
              <h3>Nice! You are eligible for financing options!</h3>
            </Col>
          </Row>
          {isOptimus ? (
            <Row className="justify-content-center mt-5">
              <Col xs="auto">
                <img src={mosaicLogo} alt="Mosaic logo" height="75px" />
              </Col>
            </Row>
          ) : null}
          <Row className="justify-content-center mt-5">
            <Col md="8">
              <h4>Consent</h4>
              <p>
                I acknowledge that by checking the box below and pressing continue that the
                information on this application will be sent to Mosaic for the purpose of completing
                a credit application.
              </p>
              <ConsentFormFormik />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  consent: state.mosaicInformation.consent,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  app: state.softPull.applicationSubmitDTO,
  consumerId: state.auth.consumerId,
  pqi: state.preQualificationInformation,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangeMosaicInfoValue: (key, value) => dispatch(changeMosaicInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchSubmitMosaicApplication: (params, page, app) =>
    dispatch(submitMosaicApplication(params, page, app)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Consent),
);
