import React from 'react';
import Section from '../../../../components/Section/Section';
import Header from '../../../../components/UI/Header/Header';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import GreenSkyFlow from './GreenSkyFlow';
import ApplicationFlowEnum from '../../../../assets/js/ApplicationFlowEnum';

const GreenSky = ({ history, atWaterGreenSkyFlowCurrentPage, flowName }) => {
  if (flowName !== ApplicationFlowEnum.ATWATER_GREENSKY_FLOW.value) {
    history.push(Object.values(ApplicationFlowEnum).find(flow => flow.value === flowName).path);
    return <></>;
  }

  return (
    <>
      <Header
        title={
          GreenSkyFlow[atWaterGreenSkyFlowCurrentPage ? atWaterGreenSkyFlowCurrentPage : 0]?.props
            ?.title
        }
        pages={GreenSkyFlow.length - 1}
      />
      <Section
        Component={
          GreenSkyFlow[atWaterGreenSkyFlowCurrentPage ? atWaterGreenSkyFlowCurrentPage : 0]
        }
      />
    </>
  );
};

const mapStateToProps = state => ({
  atWaterGreenSkyFlowCurrentPage: state.navi.atWaterGreenSkyFlowCurrentPage,
  flowName: state.navi.flowName,
});

GreenSky.propTypes = {
  atWaterGreenSkyFlowCurrentPage: PropTypes.number.isRequired,
  flowName: PropTypes.string,
};

export default connect(mapStateToProps)(GreenSky);
