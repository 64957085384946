import React, { useEffect } from 'react';
import Section from '../../../components/Section/Section';
import Flow from './_Flow';
import { connect } from 'react-redux';
import {
  setFlow,
  getReCapthcaKey,
  changeGSRegisterAsContractorInfoValue,
  resetGreenSkyState,
} from '../../../redux/actions/index';
import Header from '../../../components/UI/Header/Header';
import PropTypes from 'prop-types';
import { greenskyContractorEnrollmentFlow } from '../../../redux/initialState';

const Aqua = ({
  state,
  history,
  greenskyContractorEnrollmentFlowCurrentPage,
  dispatchSetFlow,
  flowName,
  dispatchChangeValue,
  dispatchGetReCapthcaKey,
  dispatchResetGreenSkyState,
  gsi,
  ...props
}) => {
  useEffect(() => {
    dispatchSetFlow(greenskyContractorEnrollmentFlow);
    if (!gsi.ein) {
      dispatchResetGreenSkyState();
      history.push('/GDE');
    }

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header
        title={Flow[greenskyContractorEnrollmentFlowCurrentPage || 0].props.title}
        pages={Flow.length - 1}
      />
      <Section Component={Flow[greenskyContractorEnrollmentFlowCurrentPage || 0]} {...state} />
    </>
  );
};

const mapStateToProps = state => ({
  state,
  greenskyContractorEnrollmentFlowCurrentPage:
    state.navi.greenskyContractorEnrollmentFlowCurrentPage,
  flowName: state.navi.flowName,
  gsi: state.greenskyInformation.contractorEnrollment,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetFlow: flow => dispatch(setFlow(flow)),
  dispatchChangeValue: (key, value) => dispatch(changeGSRegisterAsContractorInfoValue(key, value)),
  dispatchGetReCapthcaKey: () => dispatch(getReCapthcaKey()),
  dispatchResetGreenSkyState: () => dispatch(resetGreenSkyState()),
});

Aqua.propTypes = {
  greenskyContractorEnrollmentFlowCurrentPage: PropTypes.number.isRequired,
  dispatchSetFlow: PropTypes.func,
  score: PropTypes.number,
  flowName: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Aqua);
