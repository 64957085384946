import React, { useEffect } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { Form, withFormik, Field } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { reactGAEvent } from '../../../helper';
import Input from '../../../components/UI/Form/Input/CustomInput';
import Checkbox from '../../../components/UI/Form/Checkbox/Checkbox';
import queryString from 'query-string';
import {
  atWaterGreenSkysubmitRefund,
  getAtWaterGreenskySingleTransaction,
  getSingleTransaction,
  submitRefund,
} from '../../../redux/actions/index';
import { INVOICE_TOOLTIP_TEXT, REFUND_AMOUNT_TOOLTIP_TEXT } from '../../../constants/tooltipTexts';
import NumberFormat from 'react-number-format';
import { getIsPool } from '../../../config/settings';

const SubmitRefund = props => {
  const {
    auth,
    did,
    dealerUserId,
    singleTransaction,
    dispatchGetSingleTransaction,
    dispatchSubmitRefund,
    dispatchAtWaterGSSubmitRefund,
    dispatchGetAtWaterGSSingleTransaction,
    submitRefundData,
    history,
    refundAmount,
  } = props;
  const { dealerUser } = auth.user;

  useEffect(() => {
    if (!auth.user || !dealerUser) {
      history.push('/login');
    }
    const transactionId = queryString.parse(window.location.search).transactionId;
    if (transactionId) {
      if (getIsPool()) {
        dispatchGetAtWaterGSSingleTransaction(transactionId, false);
      } else {
        dispatchGetSingleTransaction(transactionId, false);
      }
    } else {
      history.push('/portal/greensky-transaction-history');
    }
    // eslint-disable-next-line
  }, []);

  let transactionResult;
  if (singleTransaction && singleTransaction.transactionSearchResults.length > 0) {
    transactionResult = singleTransaction.transactionSearchResults[0];
  }

  const SubmitRefundForm = () => (
    <Form>
      <Row>
        <Col xs={12} md={5} lg={4}>
          <Field
            component={Input}
            label="Refund Amount ($):"
            name="refundAmount"
            id="refundAmount"
            type="text"
            tooltipText={REFUND_AMOUNT_TOOLTIP_TEXT}
          />
          <Field
            component={Input}
            label="Invoice (optional)"
            name="invoice"
            id="invoice"
            type="text"
            tooltipText={INVOICE_TOOLTIP_TEXT}
          />
          <Field
            component={Checkbox}
            name="refund"
            id="refund"
            label={`Confirm that you would like to REFUND ${transactionResult?.firstName} ${
              transactionResult?.lastName
            } for the amount listed above.`}
            labelClassName="bold mb-0"
          />
        </Col>
      </Row>
      <Row className="justify-content-end">
        <Col xs="auto">
          <Button
            type="button"
            color="secondary"
            className="rounded-pill"
            outline
            onClick={() => history.push('/portal/greensky-transaction-history')}
          >
            Cancel
          </Button>
        </Col>
        <Col xs="auto">
          <Button type="submit" color="primary" className="rounded-pill">
            Submit Refund
          </Button>
        </Col>
      </Row>
    </Form>
  );

  const SubmitRefundFormFormik = withFormik({
    mapPropsToValues: () => ({
      refund: false,
    }),

    validationSchema: Yup.object({
      refundAmount: Yup.string().required('Please enter Refund Amount ($)'),
      invoice: Yup.string(),
      refund: Yup.boolean().oneOf([true], 'Please check to continue.'),
    }),

    handleSubmit: async values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Submit refund.',
        did,
        dealerUserId,
      });
      if (getIsPool()) {
        dispatchAtWaterGSSubmitRefund(
          transactionResult.applicationId,
          values.refundAmount,
          transactionResult.linkedCharges[0].txnNumber,
          transactionResult.creditCardUID,
        );
      } else {
        dispatchSubmitRefund(
          transactionResult.applicationId,
          values.refundAmount,
          transactionResult.linkedCharges[0].txnNumber,
          transactionResult.creditCardUID,
        );
      }
    },
  })(SubmitRefundForm);

  return (
    <Col>
      <Row>
        <Col>
          <h1>Submit Refund</h1>
        </Col>
      </Row>
      <Row>
        {submitRefundData && transactionResult ? (
          <div className="app-panel">
            <div className="app-panel-body submit-refund-container">
              <div className="title mb-3">
                <span>{`Application - ${transactionResult.firstName} ${
                  transactionResult.lastName
                }`}</span>
              </div>
              <Row className="justify-content-center py-4 refund-status">
                <Col xs="auto" className="py-5">
                  <h2 className="text-center">Refund Submitted Successfully!</h2>
                  <p className="px-4">
                    <i />
                    Transaction
                    <b>{` #${submitRefundData.txnNumber}`}</b> a <b>REFUND </b>
                    in the amount of{' '}
                    <b>
                      <NumberFormat
                        value={refundAmount ? refundAmount : 0.0}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        prefix={'$'}
                        displayType="text"
                      />
                    </b>
                    , has been posted.
                  </p>
                </Col>
              </Row>
              <Row className="justify-content-end">
                <Col xs="auto">
                  <Button
                    type="button"
                    color="secondary"
                    className="rounded-pill"
                    outline
                    onClick={() => history.push('/portal/greensky-transaction-history')}
                  >
                    Back
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        ) : transactionResult ? (
          <div className="app-panel">
            <div className="app-panel-body submit-refund-container">
              <div className="title mb-3">
                <span>{`Application - ${transactionResult.firstName} ${
                  transactionResult.lastName
                }`}</span>
              </div>
              <SubmitRefundFormFormik />
            </div>
          </div>
        ) : (
          <div className="app-panel">Transaction was not found!</div>
        )}
      </Row>
    </Col>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  did: state.auth.did,
  dealerUserId: state.auth.dealerUserId,
  applications: state.softPull.applications,
  singleTransaction: state.softPull.singleTransaction,
  submitRefundData: state.softPull.submitRefundData,
  refundAmount: state.softPull.refundAmount,
});

const mapDispatchToProps = dispatch => ({
  dispatchGetSingleTransaction: (transactionId, reloadPage) =>
    dispatch(getSingleTransaction(transactionId, reloadPage)),
  dispatchGetAtWaterGSSingleTransaction: (transactionId, reloadPage) =>
    dispatch(getAtWaterGreenskySingleTransaction(transactionId, reloadPage)),

  dispatchSubmitRefund: (appId, amount, parentTransactionId, creditCardUID) =>
    dispatch(submitRefund(appId, amount, parentTransactionId, creditCardUID)),
  dispatchAtWaterGSSubmitRefund: (appId, amount, parentTransactionId, creditCardUID) =>
    dispatch(atWaterGreenSkysubmitRefund(appId, amount, parentTransactionId, creditCardUID)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubmitRefund);
