import React, { useState } from 'react';
import { Field, Form, withFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Label } from 'reactstrap';
import UserRoleEnum, { getByValue } from '../../../assets/js/UserRoleEnum';
import InputField from '../../../components/UI/Form/Input/InputField';
import { formatDate } from '../../../util/dateFormatter';
import { EMAIL_TOOLTIP_TEXT } from '../../../constants/tooltipTexts';
import { isNoreplyEmail } from '../../../helper';

const ViewProfile = props => {
  const { isOpen, user, uuser, className, changeAuthValue, setProfile } = props;
  const [isEdit, setIsEdit] = useState(false);

  const EditProfileForm = () => (
    <Form>
      <ModalBody>
        <Row className="mt-4">
          <Col sm="4">
            <Label for="firstName">First Name</Label>
          </Col>
          <Col sm="8">
            <Field component={InputField} name="firstName" id="firstName" type="text" />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col sm="4">
            <Label for="lastName">Last Name</Label>
          </Col>
          <Col sm="8">
            <Field component={InputField} name="lastName" id="lastName" type="text" />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col sm="4">
            <div className="inner-content has-input">Email</div>
          </Col>
          <Col sm="8">
            <Field
              component={InputField}
              name="email"
              id="email"
              tooltipText={EMAIL_TOOLTIP_TEXT}
              type="text"
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          onClick={() => {
            changeAuthValue('showProfile', false);
            setIsEdit(false);
          }}
        >
          Close
        </Button>
        <Button type="submit" color="primary">
          Apply
        </Button>
      </ModalFooter>
    </Form>
  );

  const EditProfileFormFormik = withFormik({
    mapPropsToValues: () => ({
      firstName: user.nameFirst || user.firstName || '',
      lastName: user.nameLast || user.lastName || '',
      email: user.email || '',
    }),

    validationSchema: Yup.object({
      lastName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .required('Please enter the users last name.'),
      firstName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .required('Please enter the users first name.'),
      email: Yup.string()
        .email('Please enter a valid email.')
        .required('Please enter an email.')
        .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),
    }),

    handleSubmit: values => {
      let profile = {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        mobile: '',
        phone: '',
      };
      changeAuthValue('showProfile', false);
      setIsEdit(false);
      setProfile(profile);
    },
  })(EditProfileForm);

  const getLastLoginTime = userInfo => {
    if (!userInfo.lastLoginTime) {
      return 'This is the first login';
    }
    return formatDate(userInfo.lastLoginTime);
  };

  const ViewProfileForm = () => (
    <>
      <ModalBody>
        <Row>
          <Col sm="4">Name</Col>
          <Col sm="8">
            {user
              ? user.nameFirst
                ? user.nameFirst + ' ' + user.nameLast
                : user.firstName + ' ' + user.lastName
              : null}
          </Col>
        </Row>
        <Row>
          <Col sm="4">Role</Col>
          <Col sm="8">
            {user
              ? getByValue(user.role) === null
                ? UserRoleEnum[user.role].name
                : getByValue(user.role).name
              : null}
          </Col>
        </Row>
        <Row>
          <Col sm="4">Last Login</Col>
          <Col sm="8">{uuser ? getLastLoginTime(uuser) : null}</Col>
        </Row>
        <Row>
          <Col sm="4">Email</Col>
          <Col sm="8">{user ? user.email : null}</Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          onClick={() => {
            changeAuthValue('showProfile', false);
          }}
        >
          Close
        </Button>
        <Button
          color="primary"
          onClick={() => {
            setIsEdit(true);
          }}
        >
          Edit
        </Button>
      </ModalFooter>
    </>
  );

  return (
    <Modal isOpen={isOpen} className={className}>
      <ModalHeader
        toggle={() => {
          changeAuthValue('showProfile', false);
        }}
      >
        View Profile
      </ModalHeader>
      {isEdit ? <EditProfileFormFormik /> : <ViewProfileForm />}
    </Modal>
  );
};

ViewProfile.propTypes = {
  message: PropTypes.object,
  messageType: PropTypes.string,
  removeNotification: PropTypes.func,
  className: PropTypes.string,
};

export default ViewProfile;
