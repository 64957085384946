import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field, Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Col, Row, Label, Alert } from 'reactstrap';
import CustomInput from '../../../../components/UI/Form/Input/InputFieldAll';
import Button from '../../../../components/UI/Button/CustomButton';
import Select from '../../../../components/UI/Form/Select/SelectField';
import SelectList from '../../../../components/UI/Form/Select/SelectListField';
import EINFormat from '../../../../components/UI/Form/NumberFormat/EINFormatField';
import CustomPhoneNumber from '../../../../components/UI/Form/PhoneNumber/PhoneNumberField';
import Checkbox from '../../../../components/UI/Form/Checkbox/Checkbox';
import SimpleCheckbox from '../../../../components/UI/Form/Checkbox/SimpleCheckbox';
import CheckboxGroup from '../../../../components/UI/Form/CheckboxGroup/CheckboxGroupField';
import { changePage, changeAuthValue } from '../../../../redux/actions/index';
import { dealerBusinessType } from '../../../../assets/js/BusinessTypeEnum';
import usStates from '../../../../assets/js/USStateEnum';
import { dealerBankAccountType } from '../../../../assets/js/BankAccountTypeEnum';
import { NewLanderContractorEnrollmentFlowPageNumber } from './_Flow';
import { getIsMicrof } from '../../../../config/settings';

const isMicrof = getIsMicrof();

const DealershipInformation = props => {
  const [alertMessage, setAlertMessage] = useState(null);

  const { dispatchChangeValue, dispatchChangePage, distributors, dealerDTO, dealer } = props;

  const getErrorMessage = () => 'Please select one of the unchecked contractor programs.';

  const setDealer = values => {
    let dealerToSet = { ...dealerDTO };

    dealerToSet.applyForPowerPay = false;
    dealerToSet.applyForMosaic = false;
    dealerToSet.applyForMicrof = false;
    dealerToSet.applyForEnerBank = false;
    dealerToSet.applyForGreenSky = false;
    dealerToSet.applyForFoundation = false;
    dealerToSet.applyForFTL = false;
    values.checkboxGroup.forEach(item => {
      if (item) {
        dealerToSet[item] = true;
      }
    });
    dealerToSet.dealerName = values.dealerName;
    dealerToSet.dbaName = values.dbaName;
    dealerToSet.yearsInBusiness = values.yearsInBusiness;
    dealerToSet.monthsInBusiness = values.monthsInBusiness;
    dealerToSet.businessType = values.businessType;
    dealerToSet.distributorName = values.distributorName;
    dealerToSet.hvacPartnerId = values.hvacPartnerId;
    dealerToSet.hvacLicenseNumber = values.hvacLicenseNumber;
    dealerToSet.stateOfIssue = values.stateOfIssue;
    dealerToSet.hvacLicenseHolderName = values.hvacLicenseHolderName;
    dealerToSet.stateControlNumber = values.stateControlNumber;
    dealerToSet.bankAba = values.bankAba;
    dealerToSet.bankAccountNumber = values.bankAccountNumber;
    dealerToSet.accountType = values.accountType;
    dealerToSet.phone = values.phone;
    dealerToSet.fax = values.fax;
    dealerToSet.website = values.website;
    dealerToSet.businessAddress.street = values.street;
    dealerToSet.businessAddress.city = values.city;
    dealerToSet.businessAddress.zipcode = values.zipcode;
    dealerToSet.businessAddress.state = values.state;
    if (values.sameMailingAddress) {
      dealerToSet.mailingAddress.street = values.street;
      dealerToSet.mailingAddress.city = values.city;
      dealerToSet.mailingAddress.zipcode = values.zipcode;
      dealerToSet.mailingAddress.state = values.state;
    } else {
      dealerToSet.mailingAddress.street = values.mailingStreet;
      dealerToSet.mailingAddress.city = values.mailingCity;
      dealerToSet.mailingAddress.zipcode = values.mailingZipcode;
      dealerToSet.mailingAddress.state = values.mailingState;
    }
    return dealerToSet;
  };

  const setIsSameAddress = values => {
    let dealerToSet = setDealer(values);
    dealerToSet.sameMailingAddress = !dealerDTO.sameMailingAddress;
    dispatchChangeValue('dealerDTO', dealerToSet);
  };

  const DealershipInformationForm = ({
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
  }) => (
    <Form autoComplete="off">
      {alertMessage ? <Alert color="danger">{alertMessage}</Alert> : null}
      <Row>
        <Col sm="4" className="pl-0">
          <Label>Select the Contractor Program</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <CheckboxGroup
            id="checkboxGroup"
            value={values.checkboxGroup}
            error={errors.checkboxGroup}
            touched={touched.checkboxGroup}
            onChange={setFieldValue}
            onBlur={setFieldTouched}
          >
            <Field
              component={SimpleCheckbox}
              name="applyForPowerPay"
              id="applyForPowerPay"
              label="Apply for PowerPay program"
              labelClassName="ml-4"
              className="ml-0"
              disabled={dealer.appliedForPowerPay}
            />
            <Field
              component={SimpleCheckbox}
              name="applyForMosaic"
              id="applyForMosaic"
              label="Apply for Mosaic program"
              labelClassName="ml-4"
              className="ml-0"
              disabled={dealer.appliedForMosaic}
            />
            <Field
              component={SimpleCheckbox}
              name="applyForEnerBank"
              id="applyForEnerBank"
              label="Apply for EnerBank program"
              labelClassName="ml-4"
              className="ml-0"
              disabled={dealer.appliedForEnerBank}
            />
            <Field
              component={SimpleCheckbox}
              name="applyForGreenSky"
              id="applyForGreenSky"
              label="Apply for GreenSky program"
              labelClassName="ml-4"
              className="ml-0"
              disabled={dealer.appliedForGreenSky}
            />
            <Field
              component={SimpleCheckbox}
              name="applyForFoundation"
              id="applyForFoundation"
              label="Apply for Foundation program"
              labelClassName="ml-4"
              className="ml-0"
              disabled={dealer.appliedForFoundation}
            />
            <Field
              component={SimpleCheckbox}
              name="applyForFTL"
              id="applyForFTL"
              label="Apply for FTL program"
              labelClassName="ml-4"
              className="ml-0"
              disabled={dealer.appliedForFTL}
            />
            <Field
              component={SimpleCheckbox}
              name="applyForMicrof"
              id="applyForMicrof"
              label={'Apply for Microf lease program'}
              labelClassName="ml-4"
              className="ml-0"
              disabled={dealer.appliedForMicrof}
            />
          </CheckboxGroup>
        </Col>
      </Row>
      <h2 className="top-20">Contractor Information</h2>
      <h3>General</h3>
      <Row>
        <Col sm="4" className="pl-0">
          <Label>Federal Tax ID (EIN)</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Field
            maxLength={10}
            component={EINFormat}
            name="ein"
            id="ein"
            type="text"
            disabled={true}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="pl-0">
          <Label>Legal Business Name *</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Field component={CustomInput} name="dealerName" id="dealerName" type="text" />
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="pl-0">
          <Label>DBA Name</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Field component={CustomInput} name="dbaName" id="dbaName" type="text" />
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="pl-0">
          <Label>Years / Months in Business *</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Row>
            <Col sm="5" className="pl-0  pr-0">
              <Field
                component={CustomInput}
                name="yearsInBusiness"
                id="yearsInBusiness"
                type="number"
              />
            </Col>
            <Col sm="2" className="pl-0 pr-0 text-center font-25">
              /
            </Col>
            <Col sm="5" className="pl-0 pr-0">
              <Field
                component={CustomInput}
                name="monthsInBusiness"
                id="monthsInBusiness"
                type="number"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="pl-0">
          <Label>Business Type *</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Field
            component={Select}
            name="businessType"
            id="businessType"
            type="select"
            selectValues={dealerBusinessType}
            selectFirstName="Select One"
          />
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="pl-0">
          <Label>Distributor *</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Field
            component={SelectList}
            name="distributorName"
            id="distributorName"
            type="select"
            selectValues={distributors}
            selectFirstName="Select One"
          />
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="pl-0">
          <Label>HVAC Partners ID</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Field component={CustomInput} name="hvacPartnerId" id="hvacPartnerId" type="text" />
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="pl-0">
          <Label>HVAC License Number /State of Issue *</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Row>
            <Col sm="5" className="pl-0 pr-0">
              <Field
                component={CustomInput}
                name="hvacLicenseNumber"
                id="hvacLicenseNumber"
                type="text"
              />
            </Col>
            <Col sm="2" className="pl-0 pr-0 text-center font-25">
              /
            </Col>
            <Col sm="5" className="pl-0 pr-0">
              <Field
                component={Select}
                name="stateOfIssue"
                id="stateOfIssue"
                type="select"
                selectValues={usStates}
                selectFirstName="Select State"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="pl-0">
          <Label>HVAC License Holder Name *</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Field
            component={CustomInput}
            name="hvacLicenseHolderName"
            id="hvacLicenseHolderName"
            type="text"
          />
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="pl-0">
          <Label>Secretary of State Control Number *</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Field
            component={CustomInput}
            name="stateControlNumber"
            id="stateControlNumber"
            type="text"
          />
        </Col>
      </Row>

      <h3 className="top-20">Bank Account</h3>
      <Row>
        <Col sm="4" className="pl-0">
          <Label>Bank ABA *</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Field
            component={CustomInput}
            name="bankAba"
            id="bankAba"
            type="password"
            icon="lock"
            number
            maxLength={9}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="pl-0">
          <Label>Bank Account Number *</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Field
            component={CustomInput}
            name="bankAccountNumber"
            id="bankAccountNumber"
            type="password"
            icon="lock"
            maxLength={25}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="pl-0">
          <Label>Account Type *</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Field
            component={Select}
            name="accountTypeEnum"
            id="accountTypeEnum"
            type="select"
            selectValues={dealerBankAccountType}
            selectFirstName="Select One"
          />
        </Col>
      </Row>

      <h3 className="top-20">Contact Information</h3>
      <Row>
        <Col sm="4" className="pl-0">
          <Label>Phone *</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Field component={CustomPhoneNumber} name="phone" id="phone" />
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="pl-0">
          <Label>Fax</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Field component={CustomPhoneNumber} name="fax" id="fax" type="text" />
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="pl-0">
          <Label>Website</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Field component={CustomInput} name="website" id="website" type="text" />
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="pl-0">
          <Label>Street Address *</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Field component={CustomInput} name="street" id="street" type="text" />
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="pl-0">
          <Label>City *</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Field component={CustomInput} name="city" id="city" type="text" />
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="pl-0">
          <Label>State, Zip *</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Row>
            <Col sm="6" className="pl-0">
              <Field
                component={Select}
                name="state"
                id="state"
                type="select"
                selectValues={usStates}
                selectFirstName="Select State"
              />
            </Col>
            <Col sm="6" className="pr-0">
              <Field
                component={CustomInput}
                name="zipcode"
                id="zipcode"
                type="text"
                maxLength={10}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <h3 className="top-20">Mailing Address</h3>
      <Field
        component={Checkbox}
        name="sameMailingAddress"
        id="sameMailingAddress"
        label="Same as property address"
        labelClassName="bold margin-left-25"
        clickHandler={() => {
          setIsSameAddress(values);
        }}
      />
      {values.sameMailingAddress ? null : (
        <>
          <Row>
            <Col sm="4" className="pl-0">
              <Label>Street Address *</Label>
            </Col>
            <Col sm="7" className="pr-0">
              <Field component={CustomInput} name="mailingStreet" id="mailingStreet" type="text" />
            </Col>
          </Row>
          <Row>
            <Col sm="4" className="pl-0">
              <Label>City *</Label>
            </Col>
            <Col sm="7" className="pr-0">
              <Field component={CustomInput} name="mailingCity" id="mailingCity" type="text" />
            </Col>
          </Row>
          <Row>
            <Col sm="4" className="pl-0">
              <Label>State, Zip *</Label>
            </Col>
            <Col sm="7" className="pr-0">
              <Row>
                <Col sm="6" className="pl-0">
                  <Field
                    component={Select}
                    name="mailingState"
                    id="mailingState"
                    type="select"
                    selectValues={usStates}
                    selectFirstName="Select State"
                  />
                </Col>
                <Col sm="6" className="pr-0">
                  <Field
                    component={CustomInput}
                    name="mailingZipcode"
                    id="mailingZipcode"
                    type="text"
                    maxLength={10}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
      <Row className="contractor-user-view-row p-0 m-0">
        <Col sm="11" className="text-right pl-0 pr-0">
          <Button type="submit" title="Next" className="rounded-pill" />
        </Col>
      </Row>
    </Form>
  );

  const getChechedList = (
    applyForPowerPay,
    applyForMosaic,
    applyForMicrof,
    applyForEnerBank,
    applyForGreenSky,
    applyForFoundation,
    applyForFTL,
  ) => {
    let checkedList = [];
    if (applyForPowerPay) {
      checkedList.push('applyForPowerPay');
    }
    if (applyForMosaic) {
      checkedList.push('applyForMosaic');
    }
    if (applyForMicrof) {
      checkedList.push('applyForMicrof');
    }
    if (applyForEnerBank) {
      checkedList.push('applyForEnerBank');
    }
    if (applyForGreenSky) {
      checkedList.push('applyForGreenSky');
    }
    if (applyForFoundation) {
      checkedList.push('applyForFoundation');
    }
    if (applyForFTL) {
      checkedList.push('applyForFTL');
    }

    return checkedList;
  };

  const DealershipInformationFormFormik = withFormik({
    mapPropsToValues: () => ({
      checkboxGroup: getChechedList(
        dealerDTO.applyForPowerPay,
        dealerDTO.applyForMosaic,
        dealerDTO.applyForMicrof,
        dealerDTO.applyForEnerBank,
        dealerDTO.applyForGreenSky,
        dealerDTO.applyForFoundation,
        dealerDTO.applyForFTL,
      ),
      applyForPowerPay: dealerDTO.applyForPowerPay || false,
      applyForMosaic: dealerDTO.applyForMosaic || false,
      applyForMicrof: dealerDTO.applyForMicrof || false,
      applyForEnerBank: dealerDTO.applyForEnerBank || false,
      applyForGreenSky: dealerDTO.applyForGreenSky || false,
      applyForFoundation: dealerDTO.applyForFoundation || false,
      applyForFTL: dealerDTO.applyForFTL || false,
      ein: dealerDTO.ein || '',
      dealerName: dealerDTO.dealerName || '',
      dbaName: dealerDTO.dbaName || '',
      yearsInBusiness: dealerDTO.yearsInBusiness || '',
      monthsInBusiness: dealerDTO.monthsInBusiness || '',
      businessType: dealerDTO.businessType || '',
      distributorName: dealerDTO.distributorName || '',
      hvacPartnerId: dealerDTO.hvacPartnerId || '',
      hvacLicenseNumber: dealerDTO.hvacLicenseNumber || '',
      stateOfIssue: dealerDTO.stateOfIssue || '',
      hvacLicenseHolderName: dealerDTO.hvacLicenseHolderName || '',
      stateControlNumber: dealerDTO.stateControlNumber || '',
      bankAba: dealerDTO.bankAba || '',
      bankAccountNumber: dealerDTO.bankAccountNumber || '',
      accountTypeEnum: dealerDTO.accountTypeEnum || '',
      phone: dealerDTO.phone || '',
      fax: dealerDTO.fax || '',
      website: dealerDTO.website || '',
      street: dealerDTO.businessAddress.street || '',
      city: dealerDTO.businessAddress.city || '',
      zipcode: dealerDTO.businessAddress.zipcode || '',
      state: dealerDTO.businessAddress.state || '',
      mailingStreet: dealerDTO.mailingAddress.street || '',
      mailingCity: dealerDTO.mailingAddress.city || '',
      mailingZipcode: dealerDTO.mailingAddress.zipcode || '',
      mailingState: dealerDTO.mailingAddress.state || '',
      sameMailingAddress: dealerDTO.sameMailingAddress || false,
    }),

    validationSchema: Yup.object({
      checkboxGroup: Yup.array().required('At least one checkbox is required'),
      dealerName: Yup.string().required('Please enter Legal Business Name.'),
      yearsInBusiness: Yup.number()
        .moreThan(-1, 'Please enter a positive number.')
        .integer('Please enter an integer number.')
        .max(70, 'Must be less than or equal to 70.')
        .required('Please enter Years in Business.'),
      monthsInBusiness: Yup.number()
        .moreThan(-1, 'Please enter a positive number.')
        .integer('Please enter an integer number.')
        .max(12, 'Must be less than or equal to 12.')
        .required('Please enter Months in Business.'),
      businessType: Yup.string().required('Please select one Business Type.'),
      distributorName: Yup.string().required('Please select one Distributor.'),
      hvacLicenseNumber: Yup.string().required('Please enter HVAC License Number.'),
      stateOfIssue: Yup.string().required('Please select one State of Issue.'),
      hvacLicenseHolderName: Yup.string().required('Please enter HVAC License Holder Name.'),
      stateControlNumber: Yup.string().required('Please enter Secretary of State Control Number.'),
      bankAba: Yup.string()
        .min(9, 'Please enter just 9 digits.')
        .max(9, 'Please enter just 9 digits.')
        .required('Please enter your Bank ABA.'),
      bankAccountNumber: Yup.string().required('Please enter your Bank Account Number.'),
      accountTypeEnum: Yup.string().required('Please select one Account Type.'),
      phone: Yup.string()
        .test('required', 'Please enter Phone number.', val => val !== '+' && val)
        .test(
          'len',
          'Invalid Phone number.',
          val => val && val.match(/\d/g).join('').length === 10,
        ),

      street: Yup.string().required('Please enter a Property Street.'),
      city: Yup.string().required('Please enter a Property City.'),
      zipcode: Yup.string()
        .matches(/^\d{5}?$/, {
          message: 'Invalid Property ZIP Code.',
          excludeEmptyString: true,
        })
        .required('Please enter a Property ZIP Code.'),
      state: Yup.string().required('Please select a Property State.'),

      mailingStreet: dealerDTO.sameMailingAddress
        ? Yup.string()
        : Yup.string().required('Please enter a Street.'),
      mailingCity: dealerDTO.sameMailingAddress
        ? Yup.string()
        : Yup.string().required('Please enter a City.'),
      mailingZipcode: dealerDTO.sameMailingAddress
        ? Yup.string()
        : Yup.string()
            .matches(/^\d{5}?$/, {
              message: 'Invalid ZIP Code.',
              excludeEmptyString: true,
            })
            .required('Please enter a ZIP Code.'),
      mailingState: dealerDTO.sameMailingAddress
        ? Yup.string()
        : Yup.string().required('Please select a State.'),
    }),

    handleSubmit: values => {
      let newDealerDTO = setDealer(values);
      dispatchChangeValue('dealerDTO', newDealerDTO);
      setAlertMessage(null);
      let isApplyForPowerPay = false;
      let isApplyForMosaic = false;
      let isApplyForEnerBank = false;
      let isApplyForGreenSky = false;
      let isApplyForFoundation = false;
      let isApplyForFTL = false;
      values.checkboxGroup.forEach(item => {
        if (item === 'applyForPowerPay') {
          isApplyForPowerPay = true;
        }
        if (item === 'applyForMosaic') {
          isApplyForMosaic = true;
        }
        if (item === 'applyForEnerBank') {
          isApplyForEnerBank = true;
        }
        if (item === 'applyForGreenSky') {
          isApplyForGreenSky = true;
        }
        if (item === 'applyForFoundation') {
          isApplyForFoundation = true;
        }
        if (item === 'applyForFTL') {
          isApplyForFTL = true;
        }
      });

      if (
        (isMicrof &&
          ((!dealer.appliedForPowerPay && isApplyForPowerPay) ||
            (!dealer.appliedForFTL && isApplyForFTL))) ||
        (!dealer.appliedForMosaic && isApplyForMosaic) ||
        (!dealer.appliedForEnerBank && isApplyForEnerBank) ||
        (!dealer.appliedForGreenSky && isApplyForGreenSky) ||
        (!dealer.appliedForFoundation && isApplyForFoundation)
      ) {
        dispatchChangePage(NewLanderContractorEnrollmentFlowPageNumber.BusinessManager);
      } else {
        setAlertMessage(getErrorMessage());
        window.scrollTo(0, 0);
      }
    },
  })(DealershipInformationForm);

  return (
    <Row>
      <Col sm="8" className="p-0">
        <DealershipInformationFormFormik />
      </Col>
    </Row>
  );
};

const mapStateToProps = state => ({
  distributors: state.utile.distributors,
  dealerDTO: state.auth.dealerDTO,
  dealer:
    state.auth.user && state.auth.user.dealerUser && state.auth.user.dealerUser.dealer
      ? state.auth.user.dealerUser.dealer
      : null,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) => dispatch(changeAuthValue(key, value)),
});

DealershipInformation.propTypes = {
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DealershipInformation);
