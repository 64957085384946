import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
import { initialState } from '../initialState';

const changeValue = (state, action) => {
  const updatedValues = {
    [action.key]: action.value,
  };
  return updateObject(state, updatedValues);
};

const isEmailUniqueSuccess = (state, action) => {
  const updatedValues = {
    isEmailUnique: action.payload,
    error: null,
  };
  return updateObject(state, updatedValues);
};

const isEmailUniqueError = (state, action) => {
  const updatedValues = {
    isEmailUnique: false,
    error: action.payload,
  };
  return updateObject(state, updatedValues);
};

const getScoreSuccess = (state, action) => {
  const updatedValues = {
    dealerUserId: action.payload.dealerUserId,
    consumerId: action.payload.consumerId,
    prequalificationId: action.payload.prequalificationId,
    prequalificationToken: action.payload.prequalificationToken,
    score: action.payload.score,
    scoreLimit: action.payload.scoreLimit,
    scoreLimit2: action.payload.scoreLimit2,
    error: null,
  };
  return updateObject(state, updatedValues);
};

const getScoreError = (state, action) => {
  const updatedValues = {
    error: action.payload,
  };
  return updateObject(state, updatedValues);
};

const preQualInfoReducer = (state, action) => {
  if (!state) {
    state = initialState.preQualificationInformation;
  }
  switch (action.type) {
    case actionTypes.CHANGE_PRE_QUAL_INFO_VALUE:
      return changeValue(state, action);
    case actionTypes.IS_EMAIL_UNIQUE_SUCCESS:
      return isEmailUniqueSuccess(state, action);
    case actionTypes.IS_EMAIL_UNIQUE_ERROR:
      return isEmailUniqueError(state, action);
    case actionTypes.GET_SCORE_SUCCESS:
      return getScoreSuccess(state, action);
    case actionTypes.GET_SCORE_ERROR:
      return getScoreError(state, action);
    case actionTypes.RESET_STATE_PQI:
      state = initialState.preQualificationInformation;
      return state;
    default:
      return state;
  }
};

export default preQualInfoReducer;
