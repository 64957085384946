import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import usStates from '../../../../assets/js/USStateEnum';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import Input from '../../../../components/UI/Form/Input/CustomInput';
import Select from '../../../../components/UI/Form/Select/Select';
import {
  SPOUSE_CITY_TOOLTIP_TEXT,
  SPOUSE_FIRST_NAME_TOOLTIP_TEXT,
  SPOUSE_LAST_NAME_TOOLTIP_TEXT,
  SPOUSE_MIDDLE_INITIAL_NAME_TOOLTIP_TEXT,
  SPOUSE_STATE_TOOLTIP_TEXT,
  SPOUSE_STREET_TOOLTIP_TEXT,
  SPOUSE_UNIT_NUMBER_TOOLTIP_TEXT,
  SPOUSE_ZIP_TOOLTIP_TEXT,
} from '../../../../constants/tooltipTexts';
import { changeApplicationValues, reactGAEvent, reactGAPageview } from '../../../../helper';
import {
  changePage,
  submitMindfire,
  changeGreenSkyApplicationInfoValue,
} from '../../../../redux/actions';
import { AtWaterGreenSkyFlowPageNumber } from './GreenSkyFlow';

const CoApplicantSpouse = props => {
  const {
    greenSkyApplicationInformation,
    dispatchChangeGreenSkyApplicationInfoValue,
    pqi,
    dispatchChangePage,
    children,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_gscoapplicantspouse',
      trackerNames: [],
      title: 'ca_gscoapplicantspouse',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const setIsSameAddress = bool => {
    dispatchChangeGreenSkyApplicationInfoValue('isSameAddress', bool);
  };

  const CoApplicantAddressForm = ({
    values: {
      coApplicantSpouseFirstName,
      coApplicantSpouseMiddleInitial,
      coApplicantSpouseLastName,
    },
  }) => (
    <Form>
      <Field
        component={Input}
        label="Spouse’s First Name"
        name="coApplicantSpouseFirstName"
        id="coApplicantSpouseFirstName"
        tooltipText={SPOUSE_FIRST_NAME_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={Input}
        label="Spouse’s Middle Initial"
        name="coApplicantSpouseMiddleInitial"
        id="coApplicantSpouseMiddleInitial"
        tooltipText={SPOUSE_MIDDLE_INITIAL_NAME_TOOLTIP_TEXT}
        type="text"
        maxLength="2"
      />
      <Field
        component={Input}
        label="Spouse’s Last Name"
        name="coApplicantSpouseLastName"
        id="coApplicantSpouseLastName"
        tooltipText={SPOUSE_LAST_NAME_TOOLTIP_TEXT}
        type="text"
      />
      <Label>
        <b>The Mailing Address is the same as the co-applicant’s.</b>
      </Label>
      <Row>
        <Col sm="6">
          <Button
            className={greenSkyApplicationInformation.isSameAddress ? '' : 'inactive'}
            type="button"
            title="Yes"
            clickHandler={() => {
              changeApplicationValues(dispatchChangeGreenSkyApplicationInfoValue, {
                coApplicantSpouseFirstName,
                coApplicantSpouseMiddleInitial,
                coApplicantSpouseLastName,
              });
              setIsSameAddress(true);
            }}
          />
        </Col>
        <Col sm="6">
          <Button
            className={!greenSkyApplicationInformation.isSameAddress ? '' : 'inactive'}
            type="button"
            title="No"
            clickHandler={() => {
              changeApplicationValues(dispatchChangeGreenSkyApplicationInfoValue, {
                coApplicantSpouseFirstName,
                coApplicantSpouseMiddleInitial,
                coApplicantSpouseLastName,
              });
              setIsSameAddress(false);
            }}
          />
        </Col>
      </Row>
      <Field
        disabled={greenSkyApplicationInformation.isSameAddress}
        component={Input}
        label="Street"
        name="coApplicantSpouseStreet"
        id="coApplicantSpouseStreet"
        tooltipText={SPOUSE_STREET_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={Input}
        label="Unit/Apt. Number"
        name="coApplicantSpouseApartmentNumber"
        id="coApplicantSpouseApartmentNumber"
        tooltipText={SPOUSE_UNIT_NUMBER_TOOLTIP_TEXT}
        type="text"
      />
      <Row>
        <Col sm="6">
          <Field
            disabled={greenSkyApplicationInformation.isSameAddress}
            component={Input}
            label="City"
            name="coApplicantSpouseCity"
            id="coApplicantSpouseCity"
            tooltipText={SPOUSE_CITY_TOOLTIP_TEXT}
            type="text"
          />
        </Col>
        <Col sm="6">
          <Field
            disabled={greenSkyApplicationInformation.isSameAddress}
            component={Select}
            label="State"
            name="coApplicantSpouseState"
            id="coApplicantSpouseState"
            tooltipText={SPOUSE_STATE_TOOLTIP_TEXT}
            type="select"
            selectValues={usStates}
            selectFirstName="Select State"
          />
        </Col>
      </Row>
      <Field
        disabled={greenSkyApplicationInformation.isSameAddress}
        component={Input}
        label="ZIP Code"
        name="coApplicantSpouseZipCode"
        id="coApplicantSpouseZipCode"
        tooltipText={SPOUSE_ZIP_TOOLTIP_TEXT}
        type="text"
        maxLength={10}
      />

      <Button type="submit" title="Next" />
    </Form>
  );

  const CoApplicantAddressFormFormik = withFormik({
    mapPropsToValues: () => ({
      coApplicantSpouseFirstName: greenSkyApplicationInformation.coApplicantSpouseFirstName || '',
      coApplicantSpouseMiddleInitial:
        greenSkyApplicationInformation.coApplicantSpouseMiddleInitial || '',
      coApplicantSpouseLastName: greenSkyApplicationInformation.coApplicantSpouseLastName || '',
      coApplicantSpouseApartmentNumber:
        greenSkyApplicationInformation.isSameAddress &&
        greenSkyApplicationInformation.coAppApartmentNumber
          ? greenSkyApplicationInformation.coAppApartmentNumber
          : greenSkyApplicationInformation.coApplicantSpouseApartmentNumber || '',
      coApplicantSpouseStreet:
        greenSkyApplicationInformation.isSameAddress && greenSkyApplicationInformation.coAppStreet
          ? greenSkyApplicationInformation.coAppStreet
          : greenSkyApplicationInformation.coApplicantSpouseStreet || '',
      coApplicantSpouseCity:
        greenSkyApplicationInformation.isSameAddress && greenSkyApplicationInformation.coAppCity
          ? greenSkyApplicationInformation.coAppCity
          : greenSkyApplicationInformation.coApplicantSpouseCity || '',
      coApplicantSpouseZipCode:
        greenSkyApplicationInformation.isSameAddress && greenSkyApplicationInformation.coAppZipCode
          ? greenSkyApplicationInformation.coAppZipCode
          : greenSkyApplicationInformation.coApplicantSpouseZipCode || '',
      coApplicantSpouseState:
        greenSkyApplicationInformation.isSameAddress && greenSkyApplicationInformation.coAppState
          ? greenSkyApplicationInformation.coAppState
          : greenSkyApplicationInformation.coApplicantSpouseState || '',
    }),

    validationSchema: Yup.object({
      coApplicantSpouseFirstName: Yup.string()
        .matches(/^(?=.*[a-zA-Z ])([a-zA-Z ]+)$/, 'Special characters are not allowed.')
        .required('Please enter your spouse’s first name.'),
      coApplicantSpouseMiddleInitial: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .max(2, 'Middle initial is too long.'),
      coApplicantSpouseLastName: Yup.string()
        .matches(/^(?=.*[a-zA-Z ])([a-zA-Z ]+)$/, 'Special characters are not allowed.')
        .required('Please enter your spouse’s last name.'),
      coApplicantSpouseApartmentNumber: Yup.string(),
      coApplicantSpouseStreet: Yup.string().required('Please enter a street.'),
      coApplicantSpouseCity: Yup.string().required('Please enter a city.'),
      coApplicantSpouseZipCode: Yup.string()
        .matches(/^\d{5}?$/, {
          message: 'Invalid zip code.',
          excludeEmptyString: true,
        })
        .required('Please enter a zip code.'),
      coApplicantSpouseState: Yup.string().required('Please select a state.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      changeApplicationValues(dispatchChangeGreenSkyApplicationInfoValue, values);
      dispatchChangePage(AtWaterGreenSkyFlowPageNumber.Review);
      dispatchSubmitMindfire({
        email: pqi.email,
        lastPage: '9022',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(CoApplicantAddressForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={AtWaterGreenSkyFlowPageNumber.CoApplicantMarried}
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <CoApplicantAddressFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  pqi: state.preQualificationInformation,
  greenSkyApplicationInformation: state.greenSkyApplicationInformation,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeGreenSkyApplicationInfoValue: (key, value) =>
    dispatch(changeGreenSkyApplicationInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

CoApplicantSpouse.propTypes = {
  dispatchChangePage: PropTypes.func,
  greenSkyApplicationInformation: PropTypes.object,
  dispatchChangeGreenSkyApplicationInfoValue: PropTypes.func,
  pqi: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CoApplicantSpouse);
