import React from 'react';

const ApplicationsHistoryTable = props => {
  const { applicationsHistory } = props;

  return (
    <table className="table table-hover table-striped table-responsive">
      <thead>
        <tr>
          <th>Created date</th>
          <th>RTO number</th>
          <th>Account Name</th>
          <th>Initial decision</th>
          <th>Stage</th>
          <th>Application Status</th>
          <th>Net invoice amount</th>
          <th>Fee</th>
          <th>Funding Date</th>
          <th>Submitting contractor email</th>
        </tr>
      </thead>
      <tbody>
        {applicationsHistory.map((application, index) => (
          <tr key={index}>
            <td>{new Date(application.createdDate).toLocaleDateString()}</td>
            <td>{application.rtoNumber}</td>
            <td>{application.accountName}</td>
            <td>{application.initialDecision}</td>
            <td>{application.stage}</td>
            <td>{application.applicationStatus}</td>
            <td>{application.netInvoiceAmount}</td>
            <td>{application.fee}</td>
            <td>
              {application.fundingDate
                ? new Date(application.fundingDate).toLocaleDateString()
                : null}
            </td>
            <td>{application.submittingDealerEmail}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ApplicationsHistoryTable;
