import React, { useEffect, useState } from 'react';
import { FiMenu } from 'react-icons/fi';
import Loader from 'react-loader-spinner';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import AboutModal from '../../components/UI/Modal/AboutModal';
import ErrorModal from '../../components/UI/Modal/Notification';
import { buildNavBar } from '../../helper';
import {
  changeAuthValue,
  changeYourPassword,
  setProfile,
  changeNaviValue,
  setDefaultTheme,
  upgradeEnroll,
} from '../../redux/actions';
import './Index.scss';
import NavBarComponent from './NavbarComponent';
import PortalDropdown from './PortalDropdown';
import PortalPages from './PortalPages';
import ChangeYourPassword from './Profile/ChangeYourPassword';
import ViewProfileModal from './Profile/ViewProfile';
import { IoMdClose } from 'react-icons/io';
import Logo from '../../components/UI/Logo/Logo';
import OptimusHeader from '../../components/UI/Header/OptimusHeader';

const Portal = props => {
  const {
    user,
    history,
    workUser,
    internalUser,
    loading,
    dispatchChangeAuthValue,
    dispatchChangeNaviValue,
    portalPageTitle,
    showAbout,
    showProfile,
    version,
    dispatchSetProfile,
    showChangeYourPassword,
    dispatchChangeYourPassword,
    theme,
    dispatchSetDefaultTheme,
    dispatchUpgradeEnroll,
  } = props;

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const toggleMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  const changePageTitle = title => {
    dispatchChangeNaviValue('portalPageTitle', title);
  };

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        if (window.innerWidth > 576) {
          setIsMobileMenuOpen(false);
        }
      },
      false,
    );

    if (!user) {
      history.push('/login');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Container fluid className="portal">
      {user && user.emulatorId ? (
        <Row className="emulationText">
          <Col>
            Emulation Mode: {user.emulatorNameFirst} {user.emulatorNameLast} emulating{' '}
            {workUser.nameFirst} {workUser.nameLast}
          </Col>
        </Row>
      ) : null}
      <OptimusHeader />
      <Row noGutters>
        <Col xs="auto" className={'side-nav-bar' + (isMobileMenuOpen ? ' mobile-open' : '')}>
          <div className="dashboard-logo">
            <Logo className={`${isMobileMenuOpen ? 'ml-1' : ''}`} />
            <PortalDropdown
              dropDownClassName="d-sm-none text-white"
              dropDownToggleClassName="bg-transparent text-white"
            />
          </div>
          {user
            ? buildNavBar(user, dispatchUpgradeEnroll).map((item, index) => {
                return (
                  <NavBarComponent
                    key={index}
                    icon={item.icon}
                    description={item.description}
                    active={item.active}
                    url={item.url}
                    history={history}
                    changePageTitle={changePageTitle}
                    clickHandler={item.clickHandler}
                    setDefaultTheme={dispatchSetDefaultTheme}
                    theme={theme}
                    disabled={item.disabled}
                  />
                );
              })
            : null}
        </Col>
        <Col className="content-panel">
          <Container fluid className="portal-pages bg-light">
            <Row className="dashboard-header">
              <div className={`fi-menu-icon${isMobileMenuOpen ? ' vw-90' : ''}`}>
                {!isMobileMenuOpen ? (
                  <FiMenu className="d-sm-none" onClick={toggleMenu} />
                ) : (
                  <IoMdClose className="d-sm-none" onClick={toggleMenu} />
                )}
              </div>
              <h4
                className={`text-white mb-0 d-sm-none text-center${
                  isMobileMenuOpen ? ' d-none' : ''
                }`}
              >
                {portalPageTitle && portalPageTitle !== 'Dashboard'
                  ? portalPageTitle
                  : internalUser
                  ? 'Manage Contractor Users'
                  : 'Dashboard'}
              </h4>
              <PortalDropdown
                dropDownClassName="float-right d-none d-sm-block"
                dropDownToggleClassName="bg-transparent text-dark"
              />
            </Row>
            <Row className="mobile-hidden">
              <Col>
                <div className="breadcrumbs">
                  {portalPageTitle && portalPageTitle !== 'Dashboard' ? (
                    <ul>
                      <li>
                        <Link
                          to={
                            internalUser ? '/portal/manage-contractor-users' : '/portal/dashboard'
                          }
                        >
                          Dashboard
                        </Link>
                      </li>
                      <li>{portalPageTitle}</li>
                    </ul>
                  ) : (
                    <ul>
                      <li>&nbsp;</li>
                    </ul>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <PortalPages />
            </Row>
          </Container>
        </Col>
      </Row>
      <Row className="loader justify-content-center align-items-center" hidden={!loading}>
        <Loader type="Oval" color="#119c4a" height="80" width="80" />
      </Row>
      <ErrorModal />
      <AboutModal
        isOpen={showAbout}
        messageType="About Microf Portal"
        message={
          <>
            <Row>
              <Col>
                <b>Version Information</b>
              </Col>
            </Row>
            <Row>
              <Col>Release</Col>
              <Col>{version.version}</Col>
            </Row>
            <Row>
              <Col>Build</Col>
              <Col>{version.buildNumber}</Col>
            </Row>
            <Row>
              <Col>SCM</Col>
              <Col>{version.scmCommitNumber}</Col>
            </Row>
          </>
        }
        onClick={() => {
          dispatchChangeAuthValue('showAbout', false);
        }}
      />
      <ViewProfileModal
        isOpen={showProfile}
        user={workUser}
        uuser={user}
        changeAuthValue={dispatchChangeAuthValue}
        setProfile={dispatchSetProfile}
      />
      <ChangeYourPassword
        isOpen={showChangeYourPassword}
        changeAuthValue={dispatchChangeAuthValue}
        changeYourPassword={dispatchChangeYourPassword}
        user={workUser}
      />
    </Container>
  );
};

const mapStateToProps = state => ({
  portalPageTitle: state.navi.portalPageTitle,
  user: state.auth.user.user,
  dealerUser: state.auth.user.dealerUser,
  internalUser: state.auth.user.internalUser,
  workUser:
    state.auth.user.dealerUser || state.auth.user.internalUser || state.auth.user.consumerUser,
  loading: state.notification.loading,
  showAbout: state.auth.showAbout,
  showProfile: state.auth.showProfile,
  showChangeYourPassword: state.auth.showChangeYourPassword,
  version: state.auth.version,
  theme: state.theme.theme,
});
const mapDispatchToProps = dispatch => ({
  dispatchChangeNaviValue: (key, value) => dispatch(changeNaviValue(key, value)),
  dispatchChangeAuthValue: (key, value) => dispatch(changeAuthValue(key, value)),
  dispatchSetProfile: profile => dispatch(setProfile(profile)),
  dispatchChangeYourPassword: changePass => dispatch(changeYourPassword(changePass)),
  dispatchSetDefaultTheme: theme => dispatch(setDefaultTheme(theme)),
  dispatchUpgradeEnroll: dealerId => dispatch(upgradeEnroll(dealerId)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Portal),
);
