import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import CustomInput from '../../../components/UI/Form/Input/CustomInput';
import { changeAquaInfoValue, changePage, submitMindfire } from '../../../redux/actions/index';
import CustomPhoneNumber from '../../../components/UI/Form/PhoneNumber/PhoneNumber';
import PropTypes from 'prop-types';
import incomeSource from '../../../assets/js/IncomeSourceEnum';
import SelectWithHandleChange from '../../../components/UI/Form/Select/SelectWithHandleChange';
import { reactGAPageview, reactGAEvent } from '../../../helper';
import CurrencyFormat from '../../../components/UI/Form/NumberFormat/CurrencyFormat';
import { AquaFlowPageNumber } from '../AquaFlow';
import {
  COAPP_EMPLOYER_NAME_TOOLTIP_TEXT,
  COAPP_EMPLOYER_PHONE_NUMBER_TOOLTIP_TEXT,
  COAPP_EMPLOYMENT_MONTHS_TOOLTIP_TEXT,
  COAPP_EMPLOYMENT_POSITION_TOOLTIP_TEXT,
  COAPP_EMPLOYMENT_YEARS_TOOLTIP_TEXT,
  COAPP_INCOME_SOURCE_TOOLTIP_TEXT,
  COAPP_SALARY_INCOME_TOOLTIP_TEXT,
} from '../../../constants/tooltipTexts';

const CoAppEmploymentDetails = props => {
  const {
    dispatchChangePage,
    dispatchChangeValue,
    aquaInformation,
    children,
    isCoAppEmployed,
    email,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_aqcoappincome1',
      trackerNames: [],
      title: 'ca_aqcoappincome1',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const handleSelectIncomeSource = (coAppIncomeSource, values) => {
    const showEmp = coAppIncomeSource === 'salary/wages';
    dispatchChangeValue('isCoAppEmployed', showEmp);
    dispatchChangeValue('coAppIncomeSource', coAppIncomeSource);
    dispatchChangeValue('coAppEmployerName', isCoAppEmployed ? values.coAppEmployerName : null);
    dispatchChangeValue(
      'coAppEmploymentPosition',
      isCoAppEmployed ? values.coAppEmploymentPosition : null,
    );
    dispatchChangeValue(
      'coAppEmploymentYears',
      isCoAppEmployed ? values.coAppEmploymentYears : null,
    );
    dispatchChangeValue(
      'coAppEmploymentMonths',
      isCoAppEmployed ? values.coAppEmploymentMonths : null,
    );
    dispatchChangeValue(
      'coAppEmployerPhoneNumber',
      isCoAppEmployed && !!values.coAppEmployerPhoneNumber
        ? values.coAppEmployerPhoneNumber.match(/\d/g).join('')
        : null,
    );
    dispatchChangeValue('coAppSalaryIncome', parseInt(values.coAppSalaryIncome));
  };

  const CoAppEmploymentDetailsForm = ({ values }) => {
    return (
      <Form>
        <Field
          component={SelectWithHandleChange}
          label="Co-Applicant's Income (or Benefits) Type"
          id="coAppIncomeSource"
          tooltipText={COAPP_INCOME_SOURCE_TOOLTIP_TEXT}
          name="coAppIncomeSource"
          type="select"
          selectValues={incomeSource}
          selectFirstName="Select One"
          handleChange={() =>
            handleSelectIncomeSource(
              document.getElementById('coAppIncomeSource').options[
                document.getElementById('coAppIncomeSource').selectedIndex
              ].value,
              {
                coAppIncomeSource: values.coAppIncomeSource,
                coAppEmployerName: values.coAppEmployerName,
                coAppEmploymentPosition: values.coAppEmploymentPosition,
                coAppEmploymentYears: parseInt(values.coAppEmploymentYears),
                coAppEmploymentMonths: parseInt(values.coAppEmploymentMonths),
                coAppEmployerPhoneNumber: values.coAppEmployerPhoneNumber,
                coAppSalaryIncome: values.coAppSalaryIncome,
              },
            )
          }
        />
        {isCoAppEmployed ? (
          <>
            <Field
              component={CustomInput}
              label="Employer Name"
              name="coAppEmployerName"
              id="coAppEmployerName"
              tooltipText={COAPP_EMPLOYER_NAME_TOOLTIP_TEXT}
              type="text"
            />
            <Field
              component={CustomInput}
              label="Co-Applicant's Position"
              name="coAppEmploymentPosition"
              id="coAppEmploymentPosition"
              tooltipText={COAPP_EMPLOYMENT_POSITION_TOOLTIP_TEXT}
              type="text"
            />
            <br />
            <label>
              <b>For how long has the Co-Applicant been receiving this income?</b>
            </label>
            <Row>
              <Col sm="6">
                <Field
                  component={CustomInput}
                  label="Years"
                  name="coAppEmploymentYears"
                  id="coAppEmploymentYears"
                  tooltipText={COAPP_EMPLOYMENT_YEARS_TOOLTIP_TEXT}
                  type="number"
                />
              </Col>
              <Col sm="6">
                <Field
                  component={CustomInput}
                  label="Months"
                  name="coAppEmploymentMonths"
                  id="coAppEmploymentMonths"
                  tooltipText={COAPP_EMPLOYMENT_MONTHS_TOOLTIP_TEXT}
                  type="number"
                />
              </Col>
            </Row>
            <Field
              component={CustomPhoneNumber}
              label="Employer's Phone Number"
              name="coAppEmployerPhoneNumber"
              id="coAppEmployerPhoneNumber"
              tooltipText={COAPP_EMPLOYER_PHONE_NUMBER_TOOLTIP_TEXT}
            />
          </>
        ) : null}
        <Field
          component={CurrencyFormat}
          label={
            aquaInformation.coAppIncomeSource &&
            incomeSource[
              Object.keys(incomeSource).find(
                key => incomeSource[key].value === aquaInformation.coAppIncomeSource,
              )
            ]
              ? incomeSource[
                  Object.keys(incomeSource).find(
                    key => incomeSource[key].value === aquaInformation.coAppIncomeSource,
                  )
                ].name + ' Income (or Benefits) Amount'
              : 'Income (or Benefits) Amount'
          }
          name="coAppSalaryIncome"
          id="coAppSalaryIncome"
          tooltipText={COAPP_SALARY_INCOME_TOOLTIP_TEXT}
          type="text"
        />
        <Button type="submit" title="Next" />
      </Form>
    );
  };

  const CoAppEmploymentDetailsFormFormik = withFormik({
    mapPropsToValues: () => ({
      coAppIncomeSource: aquaInformation.coAppIncomeSource || '',
      coAppEmployerName: aquaInformation.coAppEmployerName || '',
      coAppEmploymentPosition: aquaInformation.coAppEmploymentPosition || '',
      coAppEmploymentYears:
        aquaInformation.coAppEmploymentYears === 0 ? 0 : aquaInformation.coAppEmploymentYears || '',
      coAppEmploymentMonths:
        aquaInformation.coAppEmploymentMonths === 0
          ? 0
          : aquaInformation.coAppEmploymentMonths || '',
      coAppEmployerPhoneNumber: aquaInformation.coAppEmployerPhoneNumber || '',
      coAppSalaryIncome: aquaInformation.coAppSalaryIncome || '',
    }),

    validationSchema: Yup.object({
      coAppIncomeSource: Yup.string().required("Please select the Co-Applicant's income source."),
      coAppEmployerName: !isCoAppEmployed
        ? Yup.string()
        : Yup.string().required("Please enter the Co-Applicant's employer name."),
      coAppEmploymentPosition: !isCoAppEmployed
        ? Yup.string()
        : Yup.string().required("Please enter the Co-Applicant's position."),
      coAppEmploymentYears: isCoAppEmployed
        ? Yup.number()
            .moreThan(-1, 'Please enter a positive number.')
            .integer('Please enter an integer number.')
            .max(70, 'Must be less than or equal to 70.')
            .required('Please enter years.')
        : Yup.string(),
      coAppEmploymentMonths: isCoAppEmployed
        ? Yup.number()
            .moreThan(-1, 'Please enter a positive number.')
            .integer('Please enter an integer number.')
            .max(11, 'Must be less than or equal to 11.')
            .required('Please enter months.')
        : Yup.string(),
      coAppEmployerPhoneNumber: !isCoAppEmployed
        ? Yup.string()
        : Yup.string()
            .test(
              'required',
              "Please enter the Co-Applicant's employer's phone number.",
              val => val !== '+' && val,
            )
            .test(
              'len',
              'Invalid phone number',
              val => val && val.match(/\d/g).join('').length === 10,
            ),
      coAppSalaryIncome: Yup.number()
        .required("Please enter the Co-Applicant's salary income.")
        .moreThan(0, 'Please enter a positive number.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      dispatchChangeValue('coAppIncomeSource', values.coAppIncomeSource);
      dispatchChangeValue('coAppEmployerName', isCoAppEmployed ? values.coAppEmployerName : null);
      dispatchChangeValue(
        'coAppEmploymentPosition',
        isCoAppEmployed ? values.coAppEmploymentPosition : null,
      );
      dispatchChangeValue(
        'coAppEmploymentYears',
        isCoAppEmployed ? parseInt(values.coAppEmploymentYears) : null,
      );
      dispatchChangeValue(
        'coAppEmploymentMonths',
        isCoAppEmployed ? parseInt(values.coAppEmploymentMonths) : null,
      );
      dispatchChangeValue(
        'coAppEmployerPhoneNumber',
        isCoAppEmployed && !!values.coAppEmployerPhoneNumber
          ? values.coAppEmployerPhoneNumber.match(/\d/g).join('')
          : null,
      );
      dispatchChangeValue('coAppSalaryIncome', parseInt(values.coAppSalaryIncome));
      dispatchChangePage(AquaFlowPageNumber.CoAppOtherIncome);
      dispatchSubmitMindfire({
        email: email,
        lastPage: '0048',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(CoAppEmploymentDetailsForm);

  return (
    <Container>
      <Row>
        <BackBtn changePage={dispatchChangePage} page={AquaFlowPageNumber.CoAppDetails} />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <CoAppEmploymentDetailsFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  aquaInformation: state.aquaInformation,
  isCoAppEmployed: state.aquaInformation.isCoAppEmployed,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) => dispatch(changeAquaInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

CoAppEmploymentDetails.propTypes = {
  aquaInformation: PropTypes.object,
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CoAppEmploymentDetails);
