const AddedByAPIEnum = {
  GREENSKY: { value: 'G', name: 'Greensky' },
  ESS: { value: 'E', name: 'ESS' },
  CUSTOMFIN: { value: 'C', name: 'CustomFin' },
  SERVICEFINANCE: { value: 'S', name: 'ServiceFinance' },
  FTL: { value: 'F', name: 'FTL' },
  OPTIMUS_PLUS: { value: 'I', name: 'Optimus' },
};

export default AddedByAPIEnum;

export const isPartner = addedBy => {
  for (let key in AddedByAPIEnum) {
    if (AddedByAPIEnum[key].name === addedBy) {
      return true;
    }
  }
  return false;
};
