import React, { useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';
import { Form, withFormik, Field } from 'formik';
import * as Yup from 'yup';
import Input from '../../../components/UI/Form/Input/CustomInput';
import RadioButtons from '../../../components/UI/Form/Radio/RadioButtons';
import { formatDate } from '../../../util/dateFormatter';
import greenSkyLogo from '../../../assets/img/greenSkyLogo.png';
import {
  AUTHORIZED_LOAN_AMOUNT_TOOLTIP_TEXT,
  CURRENT_CHARGE_AMOUNT_TOOLTIP_TEXT,
  INVOICE_TOOLTIP_TEXT,
} from '../../../constants/tooltipTexts';
import { atWaterGreenSkySubmitCharge, submitCharge } from '../../../redux/actions';
import { Link } from 'react-router-dom';
import CurrencyFormat from '../../../components/UI/Form/NumberFormat/CurrencyFormat';
import { getIsPool } from '../../../config/settings';

const TransactionAuthorizationRequest = props => {
  const {
    auth,
    history,
    greenSkyApplication,
    isAccountCreated,
    dispatchSubmitCharge,
    dispatchAtWaterGreenSkySubmitCharge,
  } = props;

  useEffect(() => {
    if (!auth.user || !auth.user.dealerUser) {
      history.push('/login');
    }
    // eslint-disable-next-line
  }, []);

  const radioOptions = [
    { key: 'Progress Payment', id: 0, value: 'Progress Payment', image: null, checked: false },
    { key: 'Job Complete', id: 1, value: 'Job Complete', image: null, checked: false },
  ];

  const clickHandler = value => {
    // console.log('Radio clicked! ', value);
  };

  const ChargeTypeForm = () => (
    <Form>
      <Row className="mb-0">
        <Col>
          <Field
            component={CurrencyFormat}
            label="Current Charge Amount ($)"
            name="currentChargeAmount"
            id="currentChargeAmount"
            type="text"
            tooltipText={CURRENT_CHARGE_AMOUNT_TOOLTIP_TEXT}
          />
          <Field
            component={CurrencyFormat}
            label="Authorized Loan Amount ($)"
            name="authorizedLoanAmount"
            id="authorizedLoanAmount"
            type="text"
            tooltipText={AUTHORIZED_LOAN_AMOUNT_TOOLTIP_TEXT}
          />
          <Field
            component={Input}
            label="Invoice (optional)"
            name="invoice"
            id="invoice"
            type="text"
            tooltipText={INVOICE_TOOLTIP_TEXT}
          />
        </Col>
      </Row>

      <h5>Select a charge Type</h5>
      <Field
        component={RadioButtons}
        name="chargeType"
        options={radioOptions}
        clickHandler={clickHandler}
      />
      <Button type="submit" color="primary" className="btn btn-primary w-100 my-3">
        Submit
      </Button>
      <p>
        *Refunds must be done from the{' '}
        <Link className="link" to="/portal/greensky-transaction-history">
          Transactions
        </Link>{' '}
        page.
      </p>
    </Form>
  );

  const ChargeTypeFormFormik = withFormik({
    mapPropsToValues: () => ({
      chargeType: '',
      currentChargeAmount: '',
      authorizedLoanAmount: greenSkyApplication.creditLimit ? greenSkyApplication.creditLimit : '',
      invoice: '',
    }),

    validationSchema: Yup.object({
      chargeType: Yup.string().required(
        'Please select your charge type (you can only choose one).',
      ),
      currentChargeAmount: Yup.number().required('Please enter Current Charge Amount ($)'),
      authorizedLoanAmount: Yup.number().required('Please enter Authorized Loan Amount ($)'),
      invoice: Yup.string(),
    }),

    handleSubmit: async values => {
      if (getIsPool()) {
        dispatchAtWaterGreenSkySubmitCharge(values, greenSkyApplication);
      } else {
        dispatchSubmitCharge(values, greenSkyApplication);
      }
    },
  })(ChargeTypeForm);

  return (
    <Col xs={12} className="TAR-container py-3">
      <Row className="justify-content-between align-items-center pb-2">
        <Col xs={12} md="auto" className="order-md-12">
          <img src={greenSkyLogo} alt="GreenSky logo" height="65px" />
        </Col>
        <Col xs={12} md="auto" className="order-md-1 text-uppercase">
          <h5>{`${greenSkyApplication.lastName} ${greenSkyApplication.firstName}`}</h5>
        </Col>
      </Row>
      <Row>
        <Col xs={12} xl={9} className="py-3 px-0">
          <Row>
            <Col xs={12}>
              <h5>Customer Information</h5>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={3}>
              <h6>Applicant</h6>
              <p>
                <span className="text-uppercase">
                  {`${greenSkyApplication.lastName} ${greenSkyApplication.firstName}`}
                  <br />
                  {`${greenSkyApplication.city}, ${greenSkyApplication.zipCode}`}
                </span>
                <br />
                {greenSkyApplication.phoneNumber ? (
                  greenSkyApplication.phoneNumber.length > 6 ? (
                    <span>
                      {'(' +
                        greenSkyApplication.phoneNumber.substring(0, 3) +
                        ') ' +
                        greenSkyApplication.phoneNumber.substring(3, 6) +
                        '-' +
                        greenSkyApplication.phoneNumber.substring(6)}
                    </span>
                  ) : (
                    <span>{greenSkyApplication.phoneNumber}</span>
                  )
                ) : null}
                <br />
                {`Activation Status: ${greenSkyApplication.activationStatus}`}
              </p>
            </Col>
            {greenSkyApplication.coApplicantFirstName && (
              <Col xs={12} lg={3}>
                <h6>Co-Applicant</h6>
                <p>
                  <span className="text-uppercase">
                    {`${greenSkyApplication.coApplicantLastName} ${
                      greenSkyApplication.coApplicantFirstName
                    }`}
                  </span>
                  <br />
                  {greenSkyApplication.coApplicantPhoneNumber ? (
                    greenSkyApplication.coApplicantPhoneNumber.length > 6 ? (
                      <span>
                        {'(' +
                          greenSkyApplication.coApplicantPhoneNumber.substring(0, 3) +
                          ') ' +
                          greenSkyApplication.coApplicantPhoneNumber.substring(3, 6) +
                          '-' +
                          greenSkyApplication.coApplicantPhoneNumber.substring(6)}
                      </span>
                    ) : (
                      <span>{greenSkyApplication.coApplicantPhoneNumber}</span>
                    )
                  ) : null}
                  <br />
                  {`Activation Status: ${greenSkyApplication.activationStatus}`}
                </p>
              </Col>
            )}
          </Row>
          <Row>
            <Col xs={12}>
              <h5>Application Information</h5>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={3}>
              <h6>Merchant ID</h6>
              <p>{auth.user?.user?.user?.dealerUser?.dealer?.greenskyMerchantNumber}</p>
              <h6>Merchant Name</h6>
              <p>{auth.user?.user?.user?.dealerUser?.dealer?.name}</p>
              <h6>Application ID</h6>
              <p>{greenSkyApplication.applicationId}</p>
              <h6>Decision</h6>
              <p className={`${greenSkyApplication.decision === 'Approved' ? 'approved' : ''}`}>
                {greenSkyApplication.decision}
              </p>
              <h6>Open to Buy</h6>
              <p>
                <NumberFormat
                  value={greenSkyApplication.openToBuy}
                  thousandSeparator={true}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  prefix={'$'}
                  displayType="text"
                >
                  {greenSkyApplication.openToBuy}
                </NumberFormat>
              </p>
              <h6>Credit Limit</h6>
              <p>
                <NumberFormat
                  value={greenSkyApplication.creditLimit}
                  thousandSeparator={true}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  prefix={'$'}
                  displayType="text"
                >
                  {greenSkyApplication.creditLimit}
                </NumberFormat>
              </p>
              <h6>Credit Limit Offered</h6>
              <p>
                <NumberFormat
                  value={greenSkyApplication.creditLimitOffered}
                  thousandSeparator={true}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  prefix={'$'}
                  displayType="text"
                >
                  {greenSkyApplication.creditLimitOffered}
                </NumberFormat>
              </p>
            </Col>
            <Col xs={12} lg={3}>
              <h6>Application Date</h6>
              <p>
                {greenSkyApplication.applicationDate
                  ? formatDate(greenSkyApplication.applicationDate, 'MM/DD/YYYY')
                  : '-'}
              </p>
              <h6>Loan Status</h6>
              <p>{greenSkyApplication.activationStatus}</p>
              <h6>Reference</h6>
              <p>{greenSkyApplication.referenceNumber}</p>
              <h6>Purchase Window Exp. Date</h6>
              <p>
                {greenSkyApplication.purchaseWindowExpirationDate
                  ? formatDate(greenSkyApplication.purchaseWindowExpirationDate, 'MM/DD/YYYY')
                  : '-'}
              </p>
              <h6>Approved Auth Amount</h6>
              <p>{greenSkyApplication.authApprovedAmount}</p>
              <h6>Amount Transacted</h6>
              <p>{greenSkyApplication.openToBuy - greenSkyApplication.creditLimitOffered}</p>
            </Col>
            <Col xs={12} lg={6}>
              <h6>Card Status</h6>
              <p>{greenSkyApplication.cardStatus}</p>
              <h6>Plan #</h6>
              <p>{greenSkyApplication.planNumber}</p>
              <h6>Plan Description</h6>
              <p>{greenSkyApplication.planDescription}</p>
              <Link
                to={{ pathname: 'https://projects.greensky.com/loanagreement' }}
                className="btn btn-primary px-4 py-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                Shopping Pass
              </Link>
            </Col>
          </Row>
        </Col>
        <Col xs={12} xl={3} className="form-container p-3">
          <h5 className="mb-3">Submit Transaction Authorization Request</h5>
          {!isAccountCreated ? (
            <ChargeTypeFormFormik />
          ) : (
            <Row>
              <Col xs={12}>
                <div className="circle-and-checkmark" />
              </Col>
              <Col xs={12} className="text-center">
                <p>
                  <strong>Transaction has been submitted!</strong>
                </p>
                <p>
                  Transaction request has been submitted. A notification will be sent to customer to
                  confirm the job completion and accept the transaction authorization request.
                </p>
              </Col>
              <Link to="/portal/dashboard" className="btn btn-primary w-100">
                OK
              </Link>
            </Row>
          )}
        </Col>
      </Row>
    </Col>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  greenSkyApplication: state.softPull.greenSkyApplication,
  isAccountCreated: state.greenSkyApplicationInformation.isAccountCreated,
});

const mapDispatchToProps = dispatch => ({
  dispatchSubmitCharge: (values, greenSkyApplication) =>
    dispatch(submitCharge(values, greenSkyApplication)),
  dispatchAtWaterGreenSkySubmitCharge: (values, greenSkyApplication) =>
    dispatch(atWaterGreenSkySubmitCharge(values, greenSkyApplication)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TransactionAuthorizationRequest);
