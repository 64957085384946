import React, { useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { reactGAEvent, reactGAPageview } from '../../../../helper';
import {
  addNotification,
  changePage,
  getGreenSkyLoanAgreement,
  greenSkyCheckCta,
  submitMindfire,
  viewGreenSkyLoanAgreement,
} from '../../../../redux/actions';
import Button from '../../../../components/UI/Button/CustomButton';
import moment from 'moment';
import Disclosure from '../Components/Disclosure';

const ActivationPage = props => {
  const {
    applicationId,
    did,
    dealerUserId,
    dispatchViewLoanAgreement,
    greenskyOffersData,
    greenSkyLoanAgreement,
    ctaResponse,
    applicationSubmitDTO,
    greenskyInformation,
  } = props;

  let data;
  let dataDto;
  const dateFormat = 'MM/DD/YYYY';

  const loanAgreementUrl =
    process.env.REACT_APP_GREENSKY_LOANAGREEMENT_URL + greenskyInformation.greenskyApplicationId;

  if (greenskyOffersData) {
    dataDto = greenskyOffersData;
  } else {
    dataDto = applicationSubmitDTO;
  }

  if (greenskyOffersData?.greenskyOffers && greenskyOffersData?.greenskyOffers.length > 0) {
    data = greenskyOffersData.greenskyOffers[0];
  } else if (
    applicationSubmitDTO?.greenskyOffers &&
    applicationSubmitDTO?.greenskyOffers.length > 0
  ) {
    data = applicationSubmitDTO.greenskyOffers[0];
  }

  useEffect(() => {
    reactGAPageview({
      path: '',
      trackerNames: [],
      title: '',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const handleLoanDocument = () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'View Loan Agreement',
      did,
      dealerUserId,
    });

    dispatchViewLoanAgreement(applicationId);
  };

  const handleClick = () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Return to Dashboard',
      did,
      dealerUserId,
    });
    //dispatchSubmitMindfire(mindFireRequest);
    window.location.href = process.env.REACT_APP_DEALER_PORTAL_URL + '/portal/dashboard';
  };

  let eSignatureComplete = null;

  if (ctaResponse) {
    eSignatureComplete = ctaResponse.eSignatureComplete;
  } else {
    eSignatureComplete = dataDto.greenskyInformation?.esignatureComplete;
  }
  return (ctaResponse &&
    (ctaResponse.activationVersion === '2.0' || ctaResponse.activationVersion === '2.1')) ||
    (dataDto &&
      (dataDto.greenskyInformation?.activationVersion === '2.0' ||
        dataDto.greenskyInformation?.activationVersion === '2.1')) ? (
    <Container>
      <Row className="justify-content-center ">
        <Col sm="12" xs="12" md="auto">
          <Row className="p-10 justify-content-center">
            <Col>
              <b className="fs-32">Activate Account</b>
              <div className="merchant-message mt-3">
                <p>
                  To complete the last step, Applicant and Co-Applicant (if applicable) must
                  activate their loan by responding to the text or email that has been sent to them.
                  If an email or text has not been received, they must call GreenSky® at
                  1-866-936-0602 to activate their account.
                </p>
                {/* <a href={loanAgreementUrl} target="_blank" rel="noopener noreferrer">
                  {loanAgreementUrl}
                </a> */}
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm="12" xs="12" md="auto">
              <Row className="p-10 justify-content-center">
                <Button
                  type="submit"
                  clickHandler={() => {
                    handleClick();
                  }}
                >
                  Return to Dashboard
                </Button>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  ) : (
    <>
      {data ? (
        <Container>
          <Row>
            <Col sm="6">
              <Row className="p-10">
                <b className="primary fs-20">Congratulations, {data.applicantFullName}! </b>
                <p className="fs-20">
                  Your account has been activated and can be used to transact with the Merchant. For
                  any additional questions, please view our{' '}
                  <a
                    className="fs-20"
                    href="https://www.greenskyonline.com/greensky/faq"
                    without
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    FAQs
                  </a>
                  .
                </p>
                <h4>
                  <span>Account Status:</span>{' '}
                  {dataDto?.greenskyInformation?.accountCreated === true &&
                  eSignatureComplete === 'yes' ? (
                    <span className="status-auth-accepted">Activated</span>
                  ) : (
                    <span className="status-cancelled">Not Active</span>
                  )}
                </h4>
              </Row>
            </Col>
            <Col sm="6">
              <Row className="p-10">
                <div className="loan-agreement-doc">
                  <Button
                    type="submit"
                    outline
                    title="View Loan Agreement"
                    clickHandler={handleLoanDocument}
                  />
                  <span className="fs-12 loan-doc-description">
                    View HTML screen-reader version of Loan Agreement for the visually impaired.
                  </span>
                </div>
              </Row>
              <Row className="review">
                <Col>
                  <Row className="row-outline-bottom">
                    <Col>
                      <b className="b-no-border">Application ID:</b>
                    </Col>
                    <Col>{dataDto.greenskyInformation.greenskyApplicationId}</Col>
                  </Row>
                  <Row className="row-outline-bottom">
                    <Col>
                      <b className="b-no-border">Applicant Name:</b>
                    </Col>
                    <Col>{data.applicantFullName}</Col>
                  </Row>
                  <Row className="row-outline-bottom">
                    <Col>
                      <b className="b-no-border">Lender:</b>
                    </Col>
                    <Col>
                      {greenSkyLoanAgreement
                        ? greenSkyLoanAgreement.lender
                        : dataDto.greenskyInformation.lender}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <b>Credit Limit</b>
                      <NumberFormat
                        value={data.loanAmount}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        prefix={'$'}
                        displayType="text"
                      />
                    </Col>
                    <Col>
                      <b>Introductory Period</b>
                      {data.promotionPeriod + ' Months'}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <b>Amortization Term</b>
                      {data.term + ' Months'}
                    </Col>
                    <Col>
                      <b>Annual Percentage Rate (APR)</b>
                      {data.apr + '%'}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <b>Amortization Monthly Payment</b>
                      <NumberFormat
                        value={data.monthlyPayment}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        prefix={'$'}
                        displayType="text"
                      />
                    </Col>
                    <Col>
                      <b>Purchase Period End Date</b>
                      {moment(
                        greenSkyLoanAgreement
                          ? greenSkyLoanAgreement.purchaseEndDate
                          : dataDto.greenskyInformation.purchaseEndDate,
                      ).format(dateFormat)}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="p-10">
                <Col>
                  <p> {data.promotionDetails}</p>
                </Col>
              </Row>
              <Row className="justify-content-center mt-4">
                <Col xs="12" md="auto">
                  <Button type="submit" title="Return to Dashboard" clickHandler={handleClick} />
                  <Disclosure />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      ) : null}
    </>
  );
};

const mapStateToProps = state => ({
  email: state.preQualificationInformation.email,
  last4SSN: state.preQualificationInformation.ssnLastDigits,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  applicationId: state.softPull.applicationSubmitDTO.applicationId,
  greenSkyLoanInformation: state.softPull.greenSkyOffersData?.greenskyInformation,
  greenskyInformation: state.softPull.applicationSubmitDTO.greenskyInformation,
  greenSkyLoanAgreement: state.softPull.greenSkyLoanAgreement,
  greenskyOffersData: state.softPull.greenSkyOffersData,
  ctaResponse: state.softPull.ctaResponse,
  applicationSubmitDTO: state.softPull.applicationSubmitDTO,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchGetGreenSkyLoanAgreement: applicationId =>
    dispatch(getGreenSkyLoanAgreement(applicationId)),
  dispatchViewLoanAgreement: applicationId => dispatch(viewGreenSkyLoanAgreement(applicationId)),
  dispatchGreenSkyCheckCta: (applicationId, last4Ssn, isSpanish) =>
    dispatch(greenSkyCheckCta(applicationId, last4Ssn, isSpanish)),
  dispatchNotification: (messageType, message) => dispatch(addNotification(messageType, message)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActivationPage);
