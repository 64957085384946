import PropTypes from 'prop-types';
import React from 'react';
import { Field, Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import CustomInput from '../../components/UI/Form/Input/CustomInput';
import Button from '../../components/UI/Button/CustomButton';
import Select from '../../components/UI/Form/Select/Select';
import SelectList from '../../components/UI/Form/Select/SelectList';
import EINFormat from '../../components/UI/Form/NumberFormat/EINFormat';
import CustomPhoneNumber from '../../components/UI/Form/PhoneNumber/PhoneNumber';
import Checkbox from '../../components/UI/Form/Checkbox/Checkbox';
import SimpleCheckbox from '../../components/UI/Form/Checkbox/SimpleCheckbox';
import CheckboxGroup from '../../components/UI/Form/CheckboxGroup/CheckboxGroup';
import {
  changePage,
  changeContractorEnrollmentValue,
  submitMindfire,
  endLoading,
} from '../../redux/actions/index';
import businessType from '../../assets/js/BusinessTypeEnum';
import usStates from '../../assets/js/USStateEnum';
import bankAccountType from '../../assets/js/BankAccountTypeEnum';
import { getIsPool, getIsRheem } from '../../config/settings';
import { ContractorEnrollmentFlowPageNumber } from './_Flow';
import selectedFinancierEnum from '../../assets/js/SelectedFinancierEnum';
import {
  BANK_ABA_TOOLTIP_TEXT,
  BANK_ACCOUNT_NUMBER_TOOLTIP_TEXT,
  BANK_ACCOUNT_TYPE_TOOLTIP_TEXT,
  CONTRACTOR_BUSINESS_NAME_TOOLTIP_TEXT,
  CONTRACTOR_BUSINESS_TYPE_TOOLTIP_TEXT,
  CONTRACTOR_CITY_TOOLTIP_TEXT,
  CONTRACTOR_DBA_NAME_TOOLTIP_TEXT,
  CONTRACTOR_DISTRIBUTOR_TOOLTIP_TEXT,
  CONTRACTOR_EIN_TOOLTIP_TEXT,
  CONTRACTOR_FAX_TOOLTIP_TEXT,
  CONTRACTOR_MONTHS_TOOLTIP_TEXT,
  CONTRACTOR_PHONE_TOOLTIP_TEXT,
  CONTRACTOR_STATE_TOOLTIP_TEXT,
  CONTRACTOR_STREET_TOOLTIP_TEXT,
  CONTRACTOR_WEBSITE_TOOLTIP_TEXT,
  CONTRACTOR_YEARS_TOOLTIP_TEXT,
  CONTRACTOR_ZIP_CODE_TOOLTIP_TEXT,
} from '../../constants/tooltipTexts';

const DealershipInformation = props => {
  const {
    auth,
    ce,
    dispatchChangeValue,
    dispatchChangePage,
    children,
    distributors,
    limitedBanks,
  } = props;
  const { banks } = auth?.user?.user;
  const isProgramDefault = obj => obj.applyFor && obj.id !== selectedFinancierEnum.MICROF.id;
  const isProgramFiltered = obj => (banks ? banks.some(bank => bank.bankId === obj.id) : true);

  const programs = Object.values(selectedFinancierEnum).filter(obj =>
    limitedBanks ? limitedBanks.some(bank => bank === obj.id) : true,
  );

  const setIsSameAddress = values => {
    dispatchChangeValue('applyForMicrofProgram', false);
    dispatchChangeValue('applyForPowerPayProgram', false);
    dispatchChangeValue('applyForMosaicProgram', false);
    dispatchChangeValue('applyForEnerBankProgram', false);
    dispatchChangeValue('applyForGreenSkyProgram', false);
    dispatchChangeValue('applyForFoundationProgram', false);
    dispatchChangeValue('applyForFTLProgram', false);
    dispatchChangeValue('applyForServiceFinanceProgram', false);
    values.checkboxGroup.forEach(item => {
      if (item) dispatchChangeValue(item, true);
    });
    dispatchChangeValue('ein', values.ein);
    dispatchChangeValue('dealerName', values.dealerName);
    dispatchChangeValue('dbaName', values.dbaName);
    dispatchChangeValue('yearsInBusiness', values.yearsInBusiness);
    dispatchChangeValue('monthsInBusiness', values.monthsInBusiness);
    dispatchChangeValue('businessType', values.businessType);
    dispatchChangeValue('distributor', values.distributor);
    dispatchChangeValue('hvacPartnersId', values.hvacPartnersId);
    dispatchChangeValue('hvacLicenseNumber', values.hvacLicenseNumber);
    dispatchChangeValue('stateOfIssue', values.stateOfIssue);
    dispatchChangeValue('hvacLicenseHolderName', values.hvacLicenseHolderName);
    dispatchChangeValue('stateControlNumber', values.stateControlNumber);
    dispatchChangeValue('bankAba', values.bankAba);
    dispatchChangeValue('bankAccountNum', values.bankAccountNum);
    dispatchChangeValue('accountType', values.accountType);
    dispatchChangeValue('phone', values.phone);
    dispatchChangeValue('fax', values.fax);
    dispatchChangeValue('website', values.website);
    dispatchChangeValue('streetAddr', values.streetAddr);
    dispatchChangeValue('city', values.city);
    dispatchChangeValue('zipcode', values.zipcode);
    dispatchChangeValue('state', values.state);
    if (values.sameMailingAddr) {
      dispatchChangeValue('mailingStreetAddr', values.streetAddr);
      dispatchChangeValue('mailingCity', values.city);
      dispatchChangeValue('mailingZip', values.zipcode);
      dispatchChangeValue('mailingState', values.state);
    } else {
      dispatchChangeValue('mailingStreetAddr', values.mailingStreetAddr);
      dispatchChangeValue('mailingCity', values.mailingCity);
      dispatchChangeValue('mailingZip', values.mailingZip);
      dispatchChangeValue('mailingState', values.mailingState);
    }
    dispatchChangeValue('sameMailingAddr', !ce.sameMailingAddr);
  };

  const isRheem = getIsRheem();

  const DealershipInformationForm = ({
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
  }) => (
    <Form autoComplete="off">
      <CheckboxGroup
        id="checkboxGroup"
        label="Select the Contractor Program"
        value={values.checkboxGroup}
        error={errors.checkboxGroup}
        touched={touched.checkboxGroup}
        onChange={setFieldValue}
        onBlur={setFieldTouched}
      >
        {programs
          ?.filter(x => x.id !== 4) //removed AquaFinance from Contractor Program List
          .map((obj, index) => (
            <Field
              key={index}
              component={SimpleCheckbox}
              name={obj.applyFor}
              id={obj.applyFor}
              label={`Apply for ${obj.name} program`}
              labelClassName="margin-left-25"
            />
          ))}
        <Field
          component={SimpleCheckbox}
          disabled={true}
          name={selectedFinancierEnum.MICROF.applyFor}
          id={selectedFinancierEnum.MICROF.applyFor}
          label={`Apply for ${selectedFinancierEnum.MICROF.name} lease program`}
          labelClassName="margin-left-25"
        />
      </CheckboxGroup>
      <h2 className="top-20">Contractor Information</h2>
      <h3>General</h3>
      <Field
        maxLength={10}
        component={EINFormat}
        label="Federal Tax ID (EIN)"
        name="ein"
        id="ein"
        tooltipText={CONTRACTOR_EIN_TOOLTIP_TEXT}
        type="text"
        disabled={true}
      />
      <Field
        component={CustomInput}
        label="Legal Business Name *"
        name="dealerName"
        id="dealerName"
        tooltipText={CONTRACTOR_BUSINESS_NAME_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={CustomInput}
        label="DBA Name"
        name="dbaName"
        id="dbaName"
        tooltipText={CONTRACTOR_DBA_NAME_TOOLTIP_TEXT}
        type="text"
      />
      <Row>
        <Col sm={6}>
          <Field
            component={CustomInput}
            label="Years in Business *"
            name="yearsInBusiness"
            id="yearsInBusiness"
            tooltipText={CONTRACTOR_YEARS_TOOLTIP_TEXT}
            type="number"
          />
        </Col>
        <Col sm={6}>
          <Field
            component={CustomInput}
            label="Months in Business *"
            name="monthsInBusiness"
            id="monthsInBusiness"
            tooltipText={CONTRACTOR_MONTHS_TOOLTIP_TEXT}
            type="number"
          />
        </Col>
      </Row>
      <Field
        component={Select}
        label="Business Type *"
        name="businessType"
        id="businessType"
        tooltipText={CONTRACTOR_BUSINESS_TYPE_TOOLTIP_TEXT}
        type="select"
        selectValues={businessType}
        selectFirstName="Select One"
      />

      <Field
        component={SelectList}
        label="Distributor *"
        name="distributor"
        id="distributor"
        tooltipText={CONTRACTOR_DISTRIBUTOR_TOOLTIP_TEXT}
        type="select"
        selectValues={distributors}
        selectFirstName="Select One"
      />

      <h3 className="top-20">Bank Account</h3>
      <Field
        component={CustomInput}
        label="Bank ABA *"
        name="bankAba"
        id="bankAba"
        tooltipText={BANK_ABA_TOOLTIP_TEXT}
        type="password"
        icon="lock"
        number
        maxLength={9}
      />
      <Field
        component={CustomInput}
        label="Bank Account Number *"
        name="bankAccountNum"
        id="bankAccountNum"
        tooltipText={BANK_ACCOUNT_NUMBER_TOOLTIP_TEXT}
        type="password"
        icon="lock"
        maxLength={25}
      />
      <Field
        component={Select}
        label="Account Type *"
        name="accountType"
        id="accountType"
        tooltipText={BANK_ACCOUNT_TYPE_TOOLTIP_TEXT}
        type="select"
        selectValues={bankAccountType}
        selectFirstName="Select One"
      />

      <h3 className="top-20">Contact Information</h3>
      <Field
        component={CustomPhoneNumber}
        label="Phone *"
        name="phone"
        id="phone"
        tooltipText={CONTRACTOR_PHONE_TOOLTIP_TEXT}
      />
      <Field
        component={CustomPhoneNumber}
        label="Fax"
        name="fax"
        id="fax"
        tooltipText={CONTRACTOR_FAX_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={CustomInput}
        label="Website"
        name="website"
        id="website"
        tooltipText={CONTRACTOR_WEBSITE_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={CustomInput}
        label="Street Address *"
        name="streetAddr"
        id="streetAddr"
        tooltipText={CONTRACTOR_STREET_TOOLTIP_TEXT}
        type="text"
      />
      <Row>
        <Col sm="6">
          <Field
            component={CustomInput}
            label="City *"
            name="city"
            id="city"
            tooltipText={CONTRACTOR_CITY_TOOLTIP_TEXT}
            type="text"
          />
        </Col>
        <Col sm="6">
          <Field
            component={CustomInput}
            label="ZIP Code *"
            name="zipcode"
            id="zipcode"
            tooltipText={CONTRACTOR_ZIP_CODE_TOOLTIP_TEXT}
            type="text"
            maxLength={10}
          />
        </Col>
      </Row>
      <Field
        component={Select}
        label="State *"
        name="state"
        id="state"
        tooltipText={CONTRACTOR_STATE_TOOLTIP_TEXT}
        type="select"
        selectValues={usStates}
        selectFirstName="Select State"
      />
      <h3 className="top-20">Mailing Address</h3>
      <Field
        component={Checkbox}
        name="sameMailingAddr"
        id="sameMailingAddr"
        label="Same as property address"
        labelClassName="bold margin-left-25"
        clickHandler={() => {
          setIsSameAddress(values);
        }}
      />
      {ce.sameMailingAddr ? null : (
        <>
          <Field
            component={CustomInput}
            label="Street Address *"
            name="mailingStreetAddr"
            id="mailingStreetAddr"
            tooltipText={CONTRACTOR_STREET_TOOLTIP_TEXT}
            type="text"
          />
          <Row>
            <Col sm="6">
              <Field
                component={CustomInput}
                label="City *"
                name="mailingCity"
                id="mailingCity"
                tooltipText={CONTRACTOR_CITY_TOOLTIP_TEXT}
                type="text"
              />
            </Col>
            <Col sm="6">
              <Field
                component={CustomInput}
                label="ZIP Code *"
                name="mailingZip"
                id="mailingZip"
                tooltipText={CONTRACTOR_ZIP_CODE_TOOLTIP_TEXT}
                type="text"
                maxLength={10}
              />
            </Col>
          </Row>
          <Field
            component={Select}
            label="State *"
            name="mailingState"
            id="mailingState"
            tooltipText={CONTRACTOR_STATE_TOOLTIP_TEXT}
            type="select"
            selectValues={usStates}
            selectFirstName="Select State"
          />
        </>
      )}
      <Button type="submit" title="Next" />
    </Form>
  );

  const getChechedList = (
    applyForPowerPayProgram,
    applyForMicrofProgram,
    applyForMosaicProgram,
    applyForEnerBankProgram,
    applyForGreenSkyProgram,
    applyForFoundationProgram,
    applyForFTLProgram,
    applyForServiceFinanceProgram,
  ) => {
    let checkedList = [];
    if (applyForMicrofProgram) {
      checkedList.push('applyForMicrofProgram');
    }
    if (applyForPowerPayProgram) {
      checkedList.push('applyForPowerPayProgram');
    }
    if (applyForMosaicProgram) {
      checkedList.push('applyForMosaicProgram');
    }
    if (applyForEnerBankProgram) {
      checkedList.push('applyForEnerBankProgram');
    }
    if (applyForGreenSkyProgram) {
      checkedList.push('applyForGreenSkyProgram');
    }
    if (applyForFoundationProgram) {
      checkedList.push('applyForFoundationProgram');
    }
    if (applyForFTLProgram) {
      checkedList.push('applyForFTLProgram');
    }
    if (applyForServiceFinanceProgram) {
      checkedList.push('applyForServiceFinanceProgram');
    }

    return checkedList;
  };

  const DealershipInformationFormFormik = withFormik({
    mapPropsToValues: () => ({
      checkboxGroup: getChechedList(
        ce.applyForPowerPayProgram,
        ce.applyForMicrofProgram,
        ce.applyForMosaicProgram,
        ce.applyForEnerBankProgram,
        ce.applyForGreenSkyProgram,
        ce.applyForFoundationProgram,
        ce.applyForFTLProgram,
        ce.applyForServiceFinanceProgram,
      ),
      applyForPowerPayProgram: ce.applyForPowerPayProgram || '',
      applyForMicrofProgram: ce.applyForMicrofProgram || '',
      applyForMosaicProgram: ce.applyForMosaicProgram || '',
      applyForEnerBankProgram: ce.applyForEnerBankProgram || '',
      applyForGreenSkyProgram: ce.applyForGreenSkyProgram || '',
      applyForFoundationProgram: ce.applyForFoundationProgram || '',
      applyForFTLProgram: ce.applyForFTLProgram || '',
      applyForServiceFinanceProgram: ce.applyForServiceFinanceProgram || '',
      ein: ce.ein || auth.user.user.ein || '',
      dealerName: ce.dealerName || '',
      dbaName: ce.dbaName || '',
      yearsInBusiness: ce.yearsInBusiness || '',
      monthsInBusiness: ce.monthsInBusiness || '',
      businessType: ce.businessType || '',
      distributor: ce.distributor || '',
      bankAba: ce.bankAba || '',
      bankAccountNum: ce.bankAccountNum || '',
      accountType: ce.accountType || '',
      phone: ce.phone || '',
      fax: ce.fax || '',
      website: ce.website || '',
      streetAddr: ce.streetAddr || '',
      city: ce.city || '',
      zipcode: ce.zipcode || '',
      state: ce.state || '',
      mailingStreetAddr: ce.mailingStreetAddr || '',
      mailingCity: ce.mailingCity || '',
      mailingZip: ce.mailingZip || '',
      mailingState: ce.mailingState || '',
      sameMailingAddr: ce.sameMailingAddr || '',
    }),

    validationSchema: Yup.object({
      checkboxGroup: !isRheem
        ? Yup.array().required('At least one checkbox is required')
        : Yup.array(),
      dealerName: Yup.string()
        .matches(/^(?=.*[a-zA-Z ])([a-zA-Z ]+)$/, 'Special characters are not allowed.')
        .required('Please enter Legal Business Name.'),
      yearsInBusiness: Yup.number()
        .moreThan(-1, 'Please enter a positive number.')
        .integer('Please enter an integer number.')
        .max(70, 'Must be less than or equal to 70.')
        .required('Please enter Years in Business.'),
      monthsInBusiness: Yup.number()
        .moreThan(-1, 'Please enter a positive number.')
        .integer('Please enter an integer number.')
        .max(12, 'Must be less than or equal to 12.')
        .required('Please enter Months in Business.'),
      businessType: Yup.string().required('Please select one Business Type.'),
      distributor: Yup.string().required('Please select one Distributor.'),
      bankAba: Yup.string()
        .min(9, 'Please enter just 9 digits.')
        .max(9, 'Please enter just 9 digits.')
        .required('Please enter your Bank ABA.'),
      bankAccountNum: Yup.string().required('Please enter your Bank Account Number.'),
      accountType: Yup.string().required('Please select one Account Type.'),
      phone: Yup.string()
        .test('required', 'Please enter Phone number.', val => val !== '+' && val)
        .test(
          'len',
          'Invalid Phone number.',
          val => val && val.match(/\d/g).join('').length === 10,
        ),

      streetAddr: Yup.string().required('Please enter a Property Street.'),
      city: Yup.string().required('Please enter a Property City.'),
      zipcode: Yup.string()
        .matches(/^\d{5}?$/, {
          message: 'Invalid Property ZIP Code.',
          excludeEmptyString: true,
        })
        .required('Please enter a Property ZIP Code.'),
      state: Yup.string().required('Please select a Property State.'),

      mailingStreetAddr: ce.sameMailingAddr
        ? Yup.string()
        : Yup.string().required('Please enter a Street.'),
      mailingCity: ce.sameMailingAddr
        ? Yup.string()
        : Yup.string().required('Please enter a City.'),
      mailingZip: ce.sameMailingAddr
        ? Yup.string()
        : Yup.string()
            .matches(/^\d{5}?$/, {
              message: 'Invalid ZIP Code.',
              excludeEmptyString: true,
            })
            .required('Please enter a ZIP Code.'),
      mailingState: ce.sameMailingAddr
        ? Yup.string()
        : Yup.string().required('Please select a State.'),
    }),

    handleSubmit: values => {
      dispatchChangeValue('applyForMicrofProgram', false);
      dispatchChangeValue('applyForPowerPayProgram', false);
      dispatchChangeValue('applyForMosaicProgram', false);
      dispatchChangeValue('applyForEnerBankProgram', false);
      dispatchChangeValue('applyForGreenSkyProgram', false);
      dispatchChangeValue('applyForFoundationProgram', false);
      dispatchChangeValue('applyForFTLProgram', false);
      dispatchChangeValue('applyForServiceFinanceProgram', false);
      if (!isRheem) {
        values.checkboxGroup.forEach(item => dispatchChangeValue(item, true));
      }
      dispatchChangeValue('ein', values.ein);
      dispatchChangeValue('dealerName', values.dealerName);
      dispatchChangeValue('dbaName', values.dbaName);
      dispatchChangeValue('yearsInBusiness', values.yearsInBusiness);
      dispatchChangeValue('monthsInBusiness', values.monthsInBusiness);
      dispatchChangeValue('businessType', values.businessType);
      dispatchChangeValue('distributor', values.distributor);
      dispatchChangeValue('bankAba', values.bankAba);
      dispatchChangeValue('bankAccountNum', values.bankAccountNum);
      dispatchChangeValue('accountType', values.accountType);
      dispatchChangeValue('phone', values.phone);
      dispatchChangeValue('fax', values.fax);
      dispatchChangeValue('website', values.website);
      dispatchChangeValue('streetAddr', values.streetAddr);
      dispatchChangeValue('city', values.city);
      dispatchChangeValue('zipcode', values.zipcode);
      dispatchChangeValue('state', values.state);
      if (values.sameMailingAddr) {
        dispatchChangeValue('mailingStreetAddr', values.streetAddr);
        dispatchChangeValue('mailingCity', values.city);
        dispatchChangeValue('mailingZip', values.zipcode);
        dispatchChangeValue('mailingState', values.state);
      } else {
        dispatchChangeValue('mailingStreetAddr', values.mailingStreetAddr);
        dispatchChangeValue('mailingCity', values.mailingCity);
        dispatchChangeValue('mailingZip', values.mailingZip);
        dispatchChangeValue('mailingState', values.mailingState);
      }
      dispatchChangeValue('userId', auth.user.dealerUser.userId);
      dispatchChangePage(ContractorEnrollmentFlowPageNumber.BusinessManager);
    },
  })(DealershipInformationForm);

  return (
    <Container>
      <Row>
        <Col sm="1" />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <DealershipInformationFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  ce: state.contractorEnrollment,
  distributors: state.utile.distributors,
  limitedBanks: state.utile.banks,
  auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) => dispatch(changeContractorEnrollmentValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchEndLoading: () => dispatch(endLoading()),
});

DealershipInformation.propTypes = {
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
  dispatchSubmitSoftPullAquaApplication: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DealershipInformation);
