import { connect } from 'formik';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import ApplicationFlowEnum from '../../../assets/js/ApplicationFlowEnum';
import { reactGAEvent } from '../../../helper';
import { changePage, changeTheme, setFlow, submitMindfire } from '../../../redux/actions';

const Declined = props => {
  const { dispatchSubmitMindfire, did, dealerUserId, email, dispatchSetFlow } = props;

  return (
    <Container>
      <Row className="justify-content-center mt-2">
        <p className="fs-32">
          <b className="primary">Thank you for your interest in Microf’s Lease Purchase Program</b>
        </p>
      </Row>
      <Row className="justify-content-center ">
        <Col xs="auto" lg="8" sm="12" md="12">
          <p className="fs-22 fw-500 pl-40">
            Microf’s HVAC lease purchase option is different than financing:
          </p>
          <ul className="powerpay-microf-lease-purchase-options-arrow">
            <li>
              Pay as you go!{' '}
              <span className="fw-normal">
                Monthly renewals up to 60 months to own in most states.
              </span>
            </li>
            <li>
              Own the item(s) after paying the total of payments to ownership.{' '}
              <span className="fw-normal">
                Substantial savings are available through Early Purchase Options!
              </span>
            </li>
            <li>
              Damaged credit?{' '}
              <span className="fw-normal">
                Microf regularly approves applicants with less than perfect credit.
              </span>
            </li>
            <li>
              Have questions?{' '}
              <span className="fw-normal">
                Call us at 855-642-7631 or email us at info@microf.com.
              </span>
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="justify-content-center ">
        <Col xs="auto" lg="8" sm="12" md="12">
          <p className="fs-22 fw-500 pl-40">
            Here is what you will need to complete the application:
          </p>
          <ul className="microf-lease-purchase-options">
            <li>Social Security Number</li>
            <li>Bank account information (routing and account numbers)</li>
            <li>Income or benefits information (e.g., paystub, bank statement, etc.)</li>
            <li>Co-applicant information (not required)</li>
          </ul>
        </Col>
      </Row>
      <Row className="justify-content-center ">
        <Col xs="auto" lg="8" sm="12" md="12">
          <p className="fs-22 fw-500 pl-40">Not eligible for lease purchase:</p>
          <ul className="powerpay-microf-lease-purchase-options-x">
            <li>Homes on rented land (e.g., mobile homes in a park)</li>
            <li>Reverse mortgages</li>
            <li>Rent-to-own or lease-to-own properties</li>
            <li>Homeowners with active or dismissed bankruptcies</li>
          </ul>
        </Col>
      </Row>
      <Row className="mt-3 justify-content-center">
        <Col xs="12" md="auto">
          <Button
            color="primary"
            onClick={() => {
              reactGAEvent({
                category: 'Button',
                action: 'Click',
                label: 'Start Application',
                did,
                dealerUserId,
              });
              dispatchSetFlow(ApplicationFlowEnum.MICROF_FLOW.value);
              dispatchSubmitMindfire({
                email: email,
                lastPage: '4066',
                dealerUserId: dealerUserId,
                did: did,
              });
            }}
          >
            Continue to Microf
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchChangeTheme: theme => dispatch(changeTheme(theme)),
  dispatchSetFlow: flow => dispatch(setFlow(flow)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Declined),
);
