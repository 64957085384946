import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Label, Row, Button } from 'reactstrap';
import * as Yup from 'yup';
import UserRoleEnum, { internalUserEnum } from '../../../assets/js/UserRoleEnum';
import Input from '../../../components/UI/Form/Input/InputField';
import Select from '../../../components/UI/Form/Select/SelectField';
import { createInternalUser, changeSoftPullValue } from '../../../redux/actions';
import { getAddedFrom } from '../../../config/settings';
import { EMAIL_TOOLTIP_TEXT } from '../../../constants/tooltipTexts';
import { isNoreplyEmail } from '../../../helper';

const NewContractorUser = props => {
  const {
    auth,
    history,
    dispatchCreateInternalUser,
    newInternalUser,
    dispatchChangeSoftPullValue,
  } = props;
  const isEdit = !(newInternalUser && newInternalUser.id && newInternalUser.id > 0);
  const addedFrom = getAddedFrom();

  useEffect(() => {
    if (!auth.user || !auth.user.internalUser) {
      history.push('/login');
    }
    // eslint-disable-next-line
  }, []);

  const cancel = event => {
    dispatchChangeSoftPullValue('newInternalUser', {
      id: 0,
      lastName: null,
      firstName: null,
      email: null,
      role: null,
      lastLogin: null,
      dealer: null,
      state: null,
      addedFrom: addedFrom,
    });
    history.push('/portal/manage-internal-users?search=true');
    event.preventDefault();
  };

  const NewContractorUserForm = () => (
    <Form>
      <Row>
        <Col sm="6">
          <Label>Role</Label>
        </Col>
        <Col sm="6">
          <Field
            component={Select}
            name="role"
            id="role"
            type="select"
            selectValues={internalUserEnum}
            selectFirstName=" "
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm="6">
          <Label>Last Name</Label>
        </Col>
        <Col sm="6">
          <Field component={Input} name="lastName" id="lastName" type="text" />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm="6">
          <Label>First Name</Label>
        </Col>
        <Col sm="6">
          <Field component={Input} name="firstName" id="firstName" type="text" />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm="6">
          <Label>Email</Label>
        </Col>
        <Col sm="6">
          <Field
            component={Input}
            name="email"
            id="email"
            tooltipText={EMAIL_TOOLTIP_TEXT}
            type="email"
          />
        </Col>
      </Row>

      <Row className="mt-5">
        <Col className="text-right">
          <Button
            type="reset"
            color="secondary"
            className="rounded-pill"
            outline
            onClick={envent => {
              cancel(envent);
            }}
          >
            Cancel
          </Button>
          <Button type="submit" color="primary" className="rounded-pill">
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );

  const NewContractorUserFormFormik = withFormik({
    mapPropsToValues: () => ({
      role: newInternalUser.role || '',
      lastName: newInternalUser.lastName || '',
      firstName: newInternalUser.firstName || '',
      email: newInternalUser.email || '',
    }),

    validationSchema: Yup.object({
      role: Yup.string().required('Please select a role.'),
      lastName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .required('Please enter the users last name.'),
      firstName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .required('Please enter the users first name.'),
      email: Yup.string()
        .email('Please enter a valid email.')
        .required('Please enter an email.')
        .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),
    }),

    handleSubmit: async values => {
      const data = {
        id: 0,
        lastName: values.lastName,
        firstName: values.firstName,
        email: values.email,
        role: values.role,
        lastLogin: null,
        dealer: null,
        state: null,
        addedFrom: addedFrom,
      };

      dispatchCreateInternalUser(data, auth.user.internalUser.userId, history);
    },
  })(NewContractorUserForm);

  const ViewUser = () => (
    <>
      <Row>
        <Col sm="4">
          <Label>Name</Label>
        </Col>
        <Col sm="4">
          {(newInternalUser.firstName || '') + ' ' + (newInternalUser.lastName || '')}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm="4">
          <Label>Role</Label>
        </Col>
        <Col sm="4">
          {newInternalUser && newInternalUser.role ? UserRoleEnum[newInternalUser.role].name : ''}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm="4">
          <Label>Email</Label>
        </Col>
        <Col sm="4">{newInternalUser.email || ''}</Col>
      </Row>
      <Row className="mt-4">
        <Col sm="4">
          <Label>Last Login</Label>
        </Col>
        <Col sm="4">{newInternalUser.lastLogin || 'N/A'}</Col>
      </Row>

      <Row className="mt-5">
        <Col className="text-right">
          <Button
            type="reset"
            color="secondary"
            className="rounded-pill"
            onClick={envent => {
              cancel(envent);
            }}
            outline
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </>
  );

  return (
    <Col>
      <Row>
        <Col>
          <h1>New Internal User</h1>
        </Col>
      </Row>
      <Row className="panel">
        <Col className="panel-body larger">
          <Row>
            <Col className="right-bordered">
              {isEdit ? <NewContractorUserFormFormik /> : <ViewUser />}
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  newInternalUser: state.softPull.newInternalUser,
});

const mapDispatchToProps = dispatch => ({
  dispatchCreateInternalUser: (data, createdBy, history) =>
    dispatch(createInternalUser(data, createdBy, history)),
  dispatchChangeSoftPullValue: (key, value) => dispatch(changeSoftPullValue(key, value)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(NewContractorUser),
);
