import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import SsnFormat from '../../../components/UI/Form/NumberFormat/SsnFormat';
import { isSSNanITIN, isStateAllowed, reactGAEvent, reactGAPageview } from '../../../helper';
import {
  changePage,
  setFlow,
  submitMindfire,
  changeEnerBankInfoValue,
} from '../../../redux/actions/index';
import { microfFlow, preQualificationFlow } from '../../../redux/initialState';
import { EnerBankFlowPageNumber } from './EnerBankFlow';
import { MicrofFlowPageNumber } from '../../Microf/MicrofFlow';
import { AquaPreQualificationFlowPageNumber } from '../../PreQualification/preQualificationFlow';
import NumberFormat from 'react-number-format';
import AirConditioningSystemEnum from '../../../assets/js/AirConditioningSystemEnum';
import { APPFULLSSN_TOOLTIP_TEXT } from '../../../constants/tooltipTexts';

const AppIdentificationInfo = props => {
  const {
    email,
    dispatchChangePage,
    dispatchChangeInfoValue,
    information,
    ssnLastDigits,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    dispatchSetFlow,
    history,
    pqi,
    isReview,
    loanType,
    borrowedAmount,
    projectType,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_ebconsumerid',
      trackerNames: [],
      title: 'ca_ebconsumerid',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const AppIdentificationInfoForm = () => (
    <Form noValidate autoComplete="off">
      <Field
        component={SsnFormat}
        label="Your full Social Security Number"
        name="appFullSsn"
        id="appFullSsn"
        tooltipText={APPFULLSSN_TOOLTIP_TEXT}
        type="password"
        icon="lock"
      />
      <p className="info">Click on the padlock to view the hidden text</p>
      <Button type="submit" title="Next" />
    </Form>
  );

  const AppIdentificationInfoFormFormik = withFormik({
    mapPropsToValues: () => ({
      appFullSsn: information.appFullSsn || '',
    }),

    validationSchema: Yup.object({
      appFullSsn: Yup.string()
        .required('Please enter your full SSN.')
        .test(
          'len',
          'Must have 9 digits',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 9,
        )
        .test(
          'match',
          'Last 4 digits must match.',
          val =>
            ssnLastDigits === '' ||
            (val &&
              val
                .toString()
                .match(/\d/g)
                .join('')
                .substr(
                  val
                    .toString()
                    .match(/\d/g)
                    .join('').length - 4,
                ) === ssnLastDigits),
        )
        .typeError('Numbers only.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      let ssn = values.appFullSsn.match(/\d/g).join('');
      dispatchChangeInfoValue('appFullSsn', ssn);

      if (isSSNanITIN(ssn)) {
        if (
          !isStateAllowed(pqi.state) ||
          pqi.airConditioningSystem !== AirConditioningSystemEnum.AIR_CONDITION_OR_HEATING_SYSTEM.id
        ) {
          dispatchSetFlow(preQualificationFlow);
          dispatchChangePage(AquaPreQualificationFlowPageNumber.NotAllowed);
          history.push('/');
        } else {
          dispatchSetFlow(microfFlow);
          dispatchChangePage(MicrofFlowPageNumber.Landing);
          history.push('/RTO');
        }
      } else {
        dispatchChangePage(EnerBankFlowPageNumber.AppEmploymentDetails);
      }
      dispatchSubmitMindfire({
        email: email,
        lastPage: '11013',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(AppIdentificationInfoForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={
            isReview
              ? EnerBankFlowPageNumber.ApplicationReview
              : EnerBankFlowPageNumber.ProjectDetail
          }
        />
        <Col sm="5">
          <h4>
            You are applying for a <span className="primary">{loanType}</span> in the amount of{' '}
            <NumberFormat
              value={borrowedAmount}
              thousandSeparator={true}
              fixedDecimalScale={true}
              decimalScale={2}
              prefix={'$'}
              displayType="text"
              className="primary"
            />{' '}
            for <span className="primary">{projectType}</span>.
          </h4>
          <br />
          <h4>If this looks correct, please provide your identification information</h4>
        </Col>
        <Col sm="6">
          <AppIdentificationInfoFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  information: state.enerBankInformation,
  pqi: state.preQualificationInformation,
  ssnLastDigits: state.preQualificationInformation.ssnLastDigits || '',
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  isReview: state.enerBankInformation.isReview,
  loanType: state.enerBankInformation.programProduct?.name,
  borrowedAmount: state.enerBankInformation.borrowedAmount,
  projectType: state.enerBankInformation.projectType,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetFlow: flowName => dispatch(setFlow(flowName)),
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeInfoValue: (key, value) => dispatch(changeEnerBankInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

AppIdentificationInfo.propTypes = {
  information: PropTypes.object,
  ssnLastDigits: PropTypes.string,
  dispatchChangePage: PropTypes.func,
  dispatchChangeInfoValue: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AppIdentificationInfo),
);
