import punycode from 'punycode';
import React, { useState } from 'react';
import { GoInfo } from 'react-icons/go';
import { Col, FormGroup, Input, Label, Row, Tooltip } from 'reactstrap';
import InputFeedback from '../InputFeedback/InputFeedback';

const Password = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched, setFieldValue },
  max,
  maxLength,
  className,
  pattern,
  disabled,
  label,
  labelClassName,
  id,
  placeholder,
  formGroupClassName,
  readOnly,
  tooltipText,
  ...props
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <FormGroup className={formGroupClassName}>
      <Label for={id} className={labelClassName}>
        <b>{label}</b>
      </Label>
      <Row className="align-items-center">
        <Col>
          <Input
            placeholder={placeholder}
            name={name}
            id={id}
            type="password"
            value={value}
            disabled={disabled}
            readOnly={readOnly}
            checked={value}
            onChange={e => setFieldValue(name, punycode.toUnicode(e.currentTarget.value))}
            onBlur={onBlur}
            pattern={pattern}
            className={'form-control ' + (className || '') + 'password'}
            maxLength={maxLength}
            max={max}
          />
        </Col>
        <Col xs="auto" className="pl-0">
          <div id={`tooltip-${id}`} className="cursor-pointer">
            <GoInfo size={20} />
          </div>
        </Col>
        <Tooltip
          placement="right"
          target={`tooltip-${id}`}
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          autohide={false}
        >
          {tooltipText ? tooltipText : `Please insert '${label}'`}
        </Tooltip>
        {touched[name] && <InputFeedback error={errors[name]} />}
      </Row>
    </FormGroup>
  );
};

export default Password;
