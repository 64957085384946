import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import AirConditioningSystemEnum from '../../../assets/js/AirConditioningSystemEnum';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import CurrencyFormat from '../../../components/UI/Form/NumberFormat/CurrencyFormat';
import { COAPP_GROSS_ANNUAL_INCOME_TOOLTIP_TEXT } from '../../../constants/tooltipTexts';
import { reactGAEvent, reactGAPageview } from '../../../helper';
import { changePage, submitMindfire, changeRheemInfoValue } from '../../../redux/actions';
import { RheemFlowPageNumber } from '../RheemFlow';

const CoApplicantGrossAnnualIncome = props => {
  const {
    email,
    coApplicantGrossAnnualIncome,
    dispatchChangePage,
    dispatchChangeRheemInfoValue,
    children,
    dispatchSubmitMindfire,
    airConditioningSystem,
    did,
    dealerUserId,
  } = props;

  useEffect(() => {
    reactGAPageview({
      //todoRheem: change ga calls
      path: '/ca_prequalamount',
      trackerNames: [],
      title: 'ca_prequalamount',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const CoApplicantGrossAnnualIncomeForm = () => (
    <Form>
      <Field
        component={CurrencyFormat}
        label="Enter Amount"
        name="coApplicantGrossAnnualIncome"
        id="coApplicantGrossAnnualIncome"
        tooltipText={COAPP_GROSS_ANNUAL_INCOME_TOOLTIP_TEXT}
      />
      <Button type="submit" title="Next" />
    </Form>
  );

  const CoApplicantGrossAnnualIncomeFormFormik = withFormik({
    mapPropsToValues: () => ({
      coApplicantGrossAnnualIncome: coApplicantGrossAnnualIncome || '',
    }),

    validationSchema: Yup.object({
      coApplicantGrossAnnualIncome: Yup.number()
        .moreThan(1499, `Amount should be at least $1500.`)
        .lessThan(100000, 'Amount too high.')
        .required('Please enter an amount.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      dispatchChangeRheemInfoValue(
        'coApplicantGrossAnnualIncome',
        values.coApplicantGrossAnnualIncome,
      );
      dispatchChangePage(RheemFlowPageNumber.IdentificationInformation);
      dispatchSubmitMindfire({
        email: email,
        coApplicantGrossAnnualIncome: values.coApplicantGrossAnnualIncome,
        lastPage:
          airConditioningSystem === AirConditioningSystemEnum.AIR_CONDITION_OR_HEATING_SYSTEM.id
            ? '0013'
            : '0015',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(CoApplicantGrossAnnualIncomeForm);

  return (
    <Container>
      <Row>
        <BackBtn changePage={dispatchChangePage} page={RheemFlowPageNumber.CoApplicantAddress} />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <CoApplicantGrossAnnualIncomeFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  coApplicantGrossAnnualIncome: state.rheemInformation.coApplicantGrossAnnualIncome,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeRheemInfoValue: (key, value) => dispatch(changeRheemInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

CoApplicantGrossAnnualIncome.propTypes = {
  coApplicantGrossAnnualIncome: PropTypes.number,
  dispatchChangePage: PropTypes.func,
  dispatchChangeRheemInfoValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CoApplicantGrossAnnualIncome);
