import React, { useState } from 'react';
import FindApplication from './FindApplication';
import SearchContractor from './SearchContractor';
import Confirm from './Confirm';

const MoveAppToContractor = () => {
  const [pageIndex, setPageIndex] = useState(0);

  const pages = [
    <FindApplication pageIndex={pageIndex} setPageIndex={setPageIndex} />,
    <SearchContractor pageIndex={pageIndex} setPageIndex={setPageIndex} />,
    <Confirm pageIndex={pageIndex} setPageIndex={setPageIndex} />,
  ];

  return pages[pageIndex];
};

export default MoveAppToContractor;
