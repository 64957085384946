export const SET_FLOW = 'SET_FLOW';
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const CHANGE_PRE_QUAL_INFO_VALUE = 'CHANGE_PRE_QUAL_INFO_VALUE';
export const CHANGE_SUN_TRUST_INFO_VALUE = 'CHANGE_SUN_TRUST_INFO_VALUE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const PROFILE_UPDATE = 'PROFILE_UPDATE';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';
export const CHANGE_PAGE_TITLE = 'CHANGE_PAGE_TITLE';
export const CHANGE_AUTH_VALUE = 'CHANGE_AUTH_VALUE';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const START_LOADING = 'START_LOADING';
export const END_LOADING = 'END_LOADING';
export const CHANGE_AQUA_INFO_VALUE = 'CHANGE_AQUA_INFO_VALUE';
export const CHANGE_MICROF_INFO_VALUE = 'CHANGE_MICROF_INFO_VALUE';
export const IS_EMAIL_UNIQUE = 'IS_EMAIL_UNIQUE';
export const IS_EMAIL_UNIQUE_SUCCESS = 'IS_EMAIL_UNIQUE_SUCCESS';
export const IS_EMAIL_UNIQUE_ERROR = 'IS_EMAIL_UNIQUE_ERROR';
export const GET_SCORE_SUCCESS = 'GET_SCORE_SUCCESS';
export const GET_SCORE_ERROR = 'GET_SCORE_ERROR';
export const GET_SCORE_SOFT_PULL_SUCCESS = 'GET_SCORE_SOFT_PULL_SUCCESS';
export const GET_SCORE_SOFT_PULL_ERROR = 'GET_SCORE_SOFT_PULL_ERROR';
export const SUBMIT_APPLICATION_SOFT_PULL_SUCCESS = 'SUBMIT_APPLICATION_SOFT_PULL_SUCCESS';
export const SUBMIT_APPLICATION_SOFT_PULL_2_SUCCESS = 'SUBMIT_APPLICATION_SOFT_PULL_2_SUCCESS';
export const UPDATE_AQUA_STATUS_AND_PROMOTIONS = 'UPDATE_AQUA_STATUS_AND_PROMOTIONS';
export const SUBMIT_APPLICATION_SOFT_PULL_ERROR = 'SUBMIT_APPLICATION_SOFT_PULL_ERROR';
export const RESET_STATE_AQUA = 'RESET_STATE_AQUA';
export const RESET_STATE_AUTH = 'RESET_STATE_AUTH';
export const RESET_STATE_MICROF = 'RESET_STATE_MICROF';
export const RESET_STATE_NAVI = 'RESET_STATE_NAVI';
export const RESET_STATE_NOTIFICATION = 'RESET_STATE_NOTIFICATION';
export const RESET_STATE_PQI = 'RESET_STATE_PQI';
export const RESET_STATE_SOFT_PULL = 'RESET_STATE_SOFT_PULL';
export const RESET_STATE_CONTRACTOR_ENROLLMENT = 'RESET_STATE_CONTRACTOR_ENROLLMENT';
export const MICROF_FINISHED = 'MICROF_FINISHED';
export const AQUA_FINANCE_LOGIN_SUCCESS = 'AQUA_FINANCE_LOGIN_SUCCESS';
export const AQUA_FINANCE_LOGIN_ERROR = 'AQUA_FINANCE_LOGIN_ERROR';
export const CHANGE_GREENSKY_INFO_VALUE = 'CHANGE_GREENSKY_INFO_VALUE';
export const CHANGE_GREENSKY_REGISTER_AS_CONTRACTOR_INFO_VALUE =
  'CHANGE_GREENSKY_REGISTER_AS_CONTRACTOR_INFO_VALUE';
export const CHANGE_GREENSKY_CONTRACTOR_ENROLLMENT_INFO_VALUE =
  'CHANGE_GREENSKY_CONTRACTOR_ENROLLMENT_INFO_VALUE';
export const GREENSKY_FINANCE_LOGIN_ERROR = 'GREENSKY_FINANCE_LOGIN_ERROR';
export const RESET_STATE_GREENSKY = 'RESET_STATE_GREENSKY';
export const CHANGE_SOFTPULL_VALUE = 'CHANGE_SOFTPULL_VALUE';
export const CHANGE_UTILE_VALUE = 'CHANGE_UTILE_VALUE';
export const CHANGE_CONTRACTOR_ENROLLMENT_VALUE = 'CHANGE_CONTRACTOR_ENROLLMENT_VALUE';
export const UPDATE_DEALER_STATS_SUMMARY = 'UPDATE_DEALER_STATS_SUMMARY';
export const CHANGE_NAVI_VALUE = 'CHANGE_NAVI_VALUE';
export const UPDATE_DEALER_VALUE = 'UPDATE_DEALER_VALUE';
export const CHANGE_RHEEM_INFO_VALUE = 'CHANGE_RHEEM_INFO_VALUE';
export const RESET_STATE_RHEEM = 'RESET_STATE_RHEEM';
export const RHEEM_FINISHED = 'RHEEM_FINISHED';
export const CHANGE_POWER_PAY_INFO_VALUE = 'CHANGE_POWER_PAY_INFO_VALUE';
export const RESET_STATE_POWER_PAY = 'RESET_STATE_POWER_PAY';
export const CHANGE_WELLS_FARGO_INFO_VALUE = 'CHANGE_WELLS_FARGO_INFO_VALUE';
export const RESET_STATE_WELLS_FARGO = 'RESET_STATE_WELLS_FARGO';
export const CHANGE_MOSAIC_INFO_VALUE = 'CHANGE_MOSAIC_INFO_VALUE';
export const RESET_STATE_MOSAIC = 'RESET_STATE_MOSAIC';
export const CHANGE_THEME = 'CHANGE_THEME';
export const CHANGE_FOUNDATION_INFO_VALUE = 'CHANGE_FOUNDATION_INFO_VALUE';
export const RESET_STATE_FOUNDATION = 'RESET_STATE_FOUNDATION';
export const CHANGE_GREENSKY_APPLICATION_INFO_VALUE = 'CHANGE_GREENSKY_INFO_VALUE';
export const RESET_STATE_GREENSKY_APPLICATION = 'RESET_STATE_GREENSKY_APPLICATION';
export const CHANGE_ENER_BANK_INFO_VALUE = 'CHANGE_ENER_BANK_INFO_VALUE';
export const RESET_STATE_ENER_BANK = 'RESET_STATE_ENER_BANK';
export const CHANGE_FTL_INFO_VALUE = 'CHANGE_FTL_INFO_VALUE';
export const RESET_STATE_FTL = 'RESET_STATE_FTL';
export const CHANGE_ATWATER_INFO_VALUE = 'CHANGE_ATWATER_INFO_VALUE';
export const RESET_STATE_ATWATER = 'RESET_STATE_ATWATER';
export const CHANGE_ATWATER_APPLICATION_INFO_VALUE = 'CHANGE_ATWATER_APPLICATION_INFO_VALUE';
export const RESET_STATE_ATWATER_APPLICATION = 'RESET_STATE_ATWATER_APPLICATION';
export const CHANGE_CONTRACTOR_ENROLLMENT_ATWATER_GS_VALUE =
  'CHANGE_CONTRACTOR_ENROLLMENT_ATWATER_GS_VALUE';
export const RESET_STATE_CONTRACTOR_ATWATER_GS_ENROLLMENT =
  'RESET_STATE_CONTRACTOR_ATWATER_GS_ENROLLMENT';
