import React from 'react';
import Container from 'reactstrap/lib/Container';
import theMicrofStoryMp4 from '../video/the_microf_story.mp4';
import theMicrofStoryPlaceHolder from '../img/the_microf_story_placeholder.png';

const TheMicrofStory = () => (
  <Container className="container-aspect-ratio-169">
    <video controls poster={theMicrofStoryPlaceHolder}>
      <source src={theMicrofStoryMp4} type="video/mp4" />
    </video>
  </Container>
);

export default TheMicrofStory;
