import React from 'react';
import CustomButton from '../../../../components/UI/Button/CustomButton';
import { Col, Row } from 'reactstrap';
import { showYears } from '../../../../helper';

const Offer = props => {
  const { offer, upgradeApplicationId, selectOffer } = props;

  const isPromotion = offer.promotionApr && offer.promotionTerm;

  return (
    <Row className="offer">
      <Col>
        <Row className="header bg-grey p-10">
          <Col>
            {isPromotion ? (
              <span className="fs-22">{`${offer.creditPlanCategory}`}</span>
            ) : (
              <span className="fs-22">{`${
                offer.interestRate && parseInt(offer.interestRate) === 0
                  ? ''
                  : showYears(offer.term, true)
              } ${offer.creditPlanCategory}`}</span>
            )}
          </Col>
        </Row>
        <Row className="p-20">
          <Col className="offer-content">
            <Row>
              <Col sm="8">
                <Row>
                  <Col>
                    <p className="fs-16 fw-500 grey">{`#${offer.offerId}`}</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {isPromotion ? (
                      <p className="fs-25 fw-500">{`${offer.promotionApr}% interest for ${showYears(
                        offer.promotionTerm,
                      )}`}</p>
                    ) : (
                      <p className="fs-25 fw-500">{`${offer.interestRate}% interest for ${showYears(
                        offer.term,
                      )}`}</p>
                    )}
                  </Col>
                </Row>
              </Col>

              <Col sm="4" className="text-center">
                <CustomButton
                  type="submit"
                  title={'Select Plan'}
                  clickHandler={() =>
                    selectOffer({
                      data: {
                        offerId: offer.upgradeOfferId,
                        accountId: upgradeApplicationId,
                      },
                    })
                  }
                >
                  Select Plan
                </CustomButton>
              </Col>
            </Row>
            <Row className="justify-content-center text-center p-20">
              <Col className="bt-2g pt-20">
                <Row>
                  <Col>
                    <span className="fs-25 fw-500">{`${offer.apr}%`}</span>{' '}
                    {isPromotion ? 'APR' : ''}
                  </Col>
                </Row>
                <Row>
                  <Col>{isPromotion ? `after promo period` : 'APR'}</Col>
                </Row>
              </Col>
              <Col className="bt-2g pt-20">
                <Row>
                  <Col>
                    {' '}
                    <span className="fs-25 fw-500">{`${offer.term} Month`}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>Term</Col>
                </Row>
              </Col>
              <Col className="bt-2g pt-20">
                <Row>
                  <Col>
                    <span className="fs-25 fw-500">{`$${offer.monthlyPayment}`}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>Monthly payment</Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Offer;
