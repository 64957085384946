const EmploymentTypeEnum = {
  Employed: {
    value: 'Employed',
    powerPayValue: 'Employed',
    name: 'Employed',
    aquaValue: 'Employed',
  },
  Unemployed: {
    value: 'Unemployed',
    powerPayValue: 'Unemployed',
    name: 'Unemployed',
    aquaValue: null,
  },
  Retired: {
    value: 'Retired',
    powerPayValue: 'Retired Other',
    name: 'Retired',
    aquaValue: 'RetirementSSI',
  },
  SelfEmployed: {
    value: 'SelfEmployed',
    powerPayValue: 'SelfEmployed',
    name: 'Self Employed',
    aquaValue: null,
  },
};

export default EmploymentTypeEnum;
