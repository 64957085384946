import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import CurrencyFormat from '../../../components/UI/Form/NumberFormat/CurrencyFormat';
import Select from '../../../components/UI/Form/Select/Select';
import { reactGAEvent, reactGAPageview } from '../../../helper';
import { changePage, changeEnerBankInfoValue, submitMindfire } from '../../../redux/actions/index';
import { EnerBankFlowPageNumber } from './EnerBankFlow';
import ownershipEnum from '../../../assets/js/EnerBankOwnershipEnum';
import propertyTypeEnum from '../../../assets/js/PropertyTypeEnum';
import {
  MORTGAGE_TOOLTIP_TEXT,
  OWNERSHIP_TOOLTIP_TEXT,
  PROPERTY_TYPE_TOOLTIP_TEXT,
} from '../../../constants/tooltipTexts';

const Eligibility = props => {
  const {
    mortgage,
    ownership,
    propertyType,
    dispatchChangePage,
    dispatchChangeInfoValue,
    children,
    did,
    dealerUserId,
    dispatchSubmitMindfire,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_ebeligibility',
      trackerNames: [],
      title: 'ca_ebeligibility',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const EligibilityForm = () => (
    <Form>
      <Field
        component={Select}
        label="Ownership/Residency Status"
        name="ownership"
        id="ownership"
        tooltipText={OWNERSHIP_TOOLTIP_TEXT}
        type="select"
        selectValues={ownershipEnum}
        selectFirstName="Select One"
      />
      <Field
        component={Select}
        label="Property Type"
        name="propertyType"
        id="propertyType"
        tooltipText={PROPERTY_TYPE_TOOLTIP_TEXT}
        type="select"
        selectValues={propertyTypeEnum}
        selectFirstName="Select One"
      />
      <Field
        component={CurrencyFormat}
        label="Mortgage/Rent Payment"
        name="mortgage"
        id="mortgage"
        tooltipText={MORTGAGE_TOOLTIP_TEXT}
      />
      <Button type="submit" title="Next" />
    </Form>
  );

  const EligibilityFormFormik = withFormik({
    mapPropsToValues: () => ({
      ownership: ownership || '',
      propertyType: propertyType || '',
      mortgage: mortgage === null ? '' : mortgage,
    }),

    validationSchema: Yup.object({
      ownership: Yup.string().required('Please select Ownership/Residency Status.'),
      propertyType: Yup.string().required('Please select Property Type.'),
      mortgage: Yup.number()
        .moreThan(-1, 'Please enter a positive number.')
        .required('Please enter a Mortgage/Rent Payment.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      dispatchSubmitMindfire({
        lastPage: '11010',
        dealerUserId: dealerUserId,
        did: did,
      });
      dispatchChangeInfoValue('mortgage', values.mortgage);
      dispatchChangeInfoValue('ownership', values.ownership);
      dispatchChangeInfoValue('propertyType', values.propertyType);
      dispatchChangePage(EnerBankFlowPageNumber.LoanInformation);
    },
  })(EligibilityForm);

  return (
    <Container>
      <Row>
        <BackBtn changePage={dispatchChangePage} page={EnerBankFlowPageNumber.Consent} />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <EligibilityFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  mortgage: state.enerBankInformation.mortgage,
  ownership: state.enerBankInformation.ownership,
  propertyType: state.enerBankInformation.propertyType,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeInfoValue: (key, value) => dispatch(changeEnerBankInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Eligibility);
