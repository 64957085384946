import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import Input from '../../../../components/UI/Form/Input/CustomInput';
import CustomPhoneNumber from '../../../../components/UI/Form/PhoneNumber/PhoneNumber';
import {
  COAPPEMAIL_TOOLTIP_TEXT,
  COAPPFIRSTNAME_TOOLTIP_TEXT,
  COAPPLASTNAME_TOOLTIP_TEXT,
  COAPPMIDDLEINITIAL_TOOLTIP_TEXT,
  COAPPPHONENUMBER_TOOLTIP_TEXT,
} from '../../../../constants/tooltipTexts';
import { isNoreplyEmail, reactGAEvent, reactGAPageview } from '../../../../helper';
import { changeFtlInfoValue, changePage, submitMindfire } from '../../../../redux/actions/index';
import { FTLFlowPageNumber } from '../FTLFlow';

const ContactInformation = props => {
  const {
    coAppFirstName,
    coAppMiddleInitial,
    coAppLastName,
    coAppPhoneNumber,
    coAppEmail,
    appEmail,
    dispatchChangeFtlInfoValue,
    dispatchChangePage,
    children,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_ftlcoappinfo1',
      trackerNames: [],
      title: 'ca_ftlcoappinfo1',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const ContactInformationForm = () => {
    return (
      <Form>
        <Field
          component={Input}
          label="First Name"
          name="coAppFirstName"
          id="coAppFirstName"
          tooltipText={COAPPFIRSTNAME_TOOLTIP_TEXT}
          type="text"
        />
        <Field
          component={Input}
          label="Middle Initial"
          name="coAppMiddleInitial"
          id="coAppMiddleInitial"
          tooltipText={COAPPMIDDLEINITIAL_TOOLTIP_TEXT}
          type="text"
          maxLength={2}
        />
        <Field
          component={Input}
          label="Last Name"
          name="coAppLastName"
          id="coAppLastName"
          tooltipText={COAPPLASTNAME_TOOLTIP_TEXT}
          type="text"
        />
        <Field
          component={CustomPhoneNumber}
          label="Phone Number (Mobile Preferred)"
          name="coAppPhoneNumber"
          id="coAppPhoneNumber"
          tooltipText={COAPPPHONENUMBER_TOOLTIP_TEXT}
        />
        <Field
          component={Input}
          label="Email Address"
          name="coAppEmail"
          id="coAppEmail"
          tooltipText={COAPPEMAIL_TOOLTIP_TEXT}
          type="email"
        />
        <Button type="submit" title="Continue" />
      </Form>
    );
  };

  const ContactInformationFormFormik = withFormik({
    mapPropsToValues: () => ({
      coAppFirstName: coAppFirstName || '',
      coAppMiddleInitial: coAppMiddleInitial || '',
      coAppLastName: coAppLastName || '',
      coAppPhoneNumber: coAppPhoneNumber || '',
      coAppEmail: coAppEmail || '',
    }),

    validationSchema: Yup.object({
      coAppFirstName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .required("Please enter the Co-Applicant's first name."),
      coAppMiddleInitial: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .max(2, 'Middle initial is too long.'),
      coAppLastName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .required("Please enter the Co-Applicant's last name."),
      coAppPhoneNumber: Yup.string()
        .test(
          'required',
          "Please enter the Co-Applicant's phone number.",
          val => val !== '+' && val,
        )
        .test('len', 'Invalid phone number', val => val && val.match(/\d/g).join('').length === 10),
      coAppEmail: Yup.string()
        .test(
          'sameEmail',
          "The co-applicant's email must be different than the primary applicant's email address.",
          val => val !== appEmail,
        )
        .email('Please enter a valid email.')
        .required('Please enter an email.')
        .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Continue',
        did,
        dealerUserId,
      });
      dispatchChangeFtlInfoValue('coAppFirstName', values.coAppFirstName.trim());
      dispatchChangeFtlInfoValue('coAppMiddleInitial', values.coAppMiddleInitial.trim());
      dispatchChangeFtlInfoValue('coAppLastName', values.coAppLastName.trim());
      dispatchChangeFtlInfoValue('coAppPhoneNumber', values.coAppPhoneNumber.match(/\d/g).join(''));
      dispatchChangeFtlInfoValue('coAppEmail', values.coAppEmail);
      dispatchChangePage(FTLFlowPageNumber.CoAppAddress);
      dispatchSubmitMindfire({
        email: appEmail,
        lastPage: '12014',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(ContactInformationForm);

  return (
    <Container>
      <Row>
        <BackBtn changePage={dispatchChangePage} page={FTLFlowPageNumber.HasCoApplicant} />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <ContactInformationFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  coAppFirstName: state.ftlInformation.coAppFirstName,
  coAppMiddleInitial: state.ftlInformation.coAppMiddleInitial,
  coAppLastName: state.ftlInformation.coAppLastName,
  coAppPhoneNumber: state.ftlInformation.coAppPhoneNumber,
  coAppEmail: state.ftlInformation.coAppEmail,
  appEmail: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeFtlInfoValue: (key, value) => dispatch(changeFtlInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContactInformation);
