import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import DeliveryMethodEnum from '../../../assets/js/DeliveryMethodEnum';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import Select from '../../../components/UI/Form/Select/Select';
import { reactGAEvent, reactGAPageview } from '../../../helper';
import { changeEnerBankInfoValue, changePage, submitMindfire } from '../../../redux/actions/index';
import { EnerBankFlowPageNumber } from './EnerBankFlow';

const DeliveryMethod = props => {
  const {
    deliveryMethod,
    dispatchChangePage,
    dispatchChangeInfoValue,
    children,
    did,
    dealerUserId,
    dispatchSubmitMindfire,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_ebdeliverymethod',
      trackerNames: [],
      title: 'ca_ebdeliverymethod',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const DeliveryMethodForm = () => (
    <Form>
      <Field
        component={Select}
        label="Delivery Method"
        name="deliveryMethod"
        id="deliveryMethod"
        type="select"
        selectValues={DeliveryMethodEnum}
        selectFirstName="Choose One"
      />
      <Button type="submit" title="Next" />
    </Form>
  );

  const DeliveryMethodFormFormik = withFormik({
    mapPropsToValues: () => ({
      deliveryMethod: deliveryMethod || '',
    }),

    validationSchema: Yup.object({
      deliveryMethod: Yup.string().required('Please select the Delivery Method.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      dispatchSubmitMindfire({
        lastPage: '11022',
        dealerUserId: dealerUserId,
        did: did,
      });
      dispatchChangeInfoValue('deliveryMethod', values.deliveryMethod);
      dispatchChangePage(EnerBankFlowPageNumber.NotificationMethod);
    },
  })(DeliveryMethodForm);

  return (
    <Container>
      <Row>
        <BackBtn changePage={dispatchChangePage} page={EnerBankFlowPageNumber.ApplicationReview} />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <DeliveryMethodFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  deliveryMethod: state.enerBankInformation.deliveryMethod,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeInfoValue: (key, value) => dispatch(changeEnerBankInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeliveryMethod);
