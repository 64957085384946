import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import otherSourcesOfIncome from '../../../../assets/js/FoundationOtherSourceOfIncomeEnum';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import CurrencyFormat from '../../../../components/UI/Form/NumberFormat/CurrencyFormat';
import Select from '../../../../components/UI/Form/Select/Select';
import {
  changeFoundationInfoValue,
  changePage,
  submitMindfire,
} from '../../../../redux/actions/index';
import PropTypes from 'prop-types';
import { reactGAPageview, reactGAEvent } from '../../../../helper';
import { FoundationFlowPageNumber } from './FoundationFlow';
import IncomeFrequencyEnum from '../../../../assets/js/IncomeFrequencyEnum';
import {
  ADDITIONAL_INCOME_TOOLTIP_TEXT,
  INCOME_SOURCE_TOOLTIP_TEXT,
  OTHER_SOURCES_OF_INCOME_TOOLTIP_TEXT,
} from '../../../../constants/tooltipTexts';

const AppOtherIncome = props => {
  const {
    dispatchChangePage,
    dispatchChangeValue,
    foundationInformation,
    children,
    hasAppOtherIncome,
    email,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
  } = props;

  let otherSourcesOfIncomeChanged = { ...otherSourcesOfIncome };
  delete otherSourcesOfIncomeChanged[foundationInformation.appIncomeSource];

  useEffect(() => {
    reactGAPageview({
      path: '/ca_fdconsumerincome2',
      trackerNames: [],
      title: 'ca_fdconsumerincome2',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const AppOtherIncomeForm = () => (
    <Form>
      <label>
        <b>Do you have other sources of income (or benefits)?</b>
      </label>
      <Row>
        <Col sm="6">
          <Button
            className={hasAppOtherIncome ? '' : 'inactive'}
            type="button"
            title="Yes"
            clickHandler={() => dispatchChangeValue('hasAppOtherIncome', true)}
          />
        </Col>
        <Col sm="6">
          <Button
            className={!hasAppOtherIncome ? '' : 'inactive'}
            type="button"
            title="No"
            clickHandler={() => dispatchChangeValue('hasAppOtherIncome', false)}
          />
        </Col>
      </Row>
      <div className={hasAppOtherIncome ? '' : 'opacity-05'}>
        <Field
          disabled={!hasAppOtherIncome}
          component={Select}
          label="Source/Kind"
          name="appOtherSourcesOfIncome"
          id="appOtherSourcesOfIncome"
          tooltipText={INCOME_SOURCE_TOOLTIP_TEXT}
          type="select"
          selectValues={otherSourcesOfIncomeChanged}
          selectFirstName="Select One"
        />
        <Field
          component={Select}
          label="Income (or benefits) Type"
          name="appOtherIncomeType"
          id="appOtherIncomeType"
          tooltipText={OTHER_SOURCES_OF_INCOME_TOOLTIP_TEXT}
          type="select"
          selectValues={IncomeFrequencyEnum}
          selectFirstName="Select Income Type"
        />
      </div>
      <Field
        component={CurrencyFormat}
        label="Amount"
        name="appAdditionalIncome"
        id="appAdditionalIncome"
        tooltipText={ADDITIONAL_INCOME_TOOLTIP_TEXT}
        type="text"
      />
      <Button type="submit" title={foundationInformation.isReview ? 'Review' : 'Next'} />
    </Form>
  );

  const AppOtherIncomeFormFormik = withFormik({
    mapPropsToValues: () => ({
      appOtherSourcesOfIncome: foundationInformation.appOtherSourcesOfIncome || '',
      appOtherIncomeType: foundationInformation.appOtherIncomeType || '',
      appAdditionalIncome: foundationInformation.appAdditionalIncome || 0,
    }),

    validationSchema: Yup.object({
      appOtherSourcesOfIncome: hasAppOtherIncome
        ? Yup.string().required('Please select another source of income.')
        : Yup.string(),
      appOtherIncomeType: hasAppOtherIncome
        ? Yup.string().required('Please select the type of your income.')
        : Yup.string(),
      appAdditionalIncome: Yup.number()
        .required('Please enter your annual income.')
        .moreThan(-1, 'Please enter a positive number.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      dispatchChangeValue('appOtherSourcesOfIncome', values.appOtherSourcesOfIncome);
      dispatchChangeValue('appOtherIncomeType', values.appOtherIncomeType);
      dispatchChangeValue('appAdditionalIncome', values.appAdditionalIncome);
      dispatchChangePage(
        foundationInformation.isReview
          ? FoundationFlowPageNumber.ApplicationReview
          : FoundationFlowPageNumber.HasCoApplicant,
      );
      dispatchSubmitMindfire({
        email: email,
        lastPage: '10015',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(AppOtherIncomeForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={FoundationFlowPageNumber.AppEmploymentDetails}
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <AppOtherIncomeFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  foundationInformation: state.foundationInformation,
  hasAppOtherIncome: state.foundationInformation.hasAppOtherIncome,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) => dispatch(changeFoundationInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

AppOtherIncome.propTypes = {
  foundationInformation: PropTypes.object,
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppOtherIncome);
