import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import CommunicationMethodEnum from '../../../assets/js/CommunicationMethodEnum';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import CustomPhoneNumber from '../../../components/UI/Form/PhoneNumber/PhoneNumber';
import Select from '../../../components/UI/Form/Select/Select';
import {
  COMMUNICATION_METHOD_TOOLTIP_TEXT,
  PHONENUMBER_TOOLTIP_TEXT,
} from '../../../constants/tooltipTexts';
import { reactGAEvent, reactGAPageview } from '../../../helper';
import { changeEnerBankInfoValue, changePage, submitMindfire } from '../../../redux/actions/index';
import { EnerBankFlowPageNumber } from './EnerBankFlow';

const NotificationMethod = props => {
  const {
    notificationPhoneNumber,
    communicationMethod,
    dispatchChangePage,
    dispatchChangeInfoValue,
    children,
    did,
    dealerUserId,
    dispatchSubmitMindfire,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_ebnotificationmethod',
      trackerNames: [],
      title: 'ca_ebnotificationmethod',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const NotificationMethodForm = () => (
    <Form>
      <Field
        component={Select}
        label="Communication Method"
        name="communicationMethod"
        id="communicationMethod"
        tooltipText={COMMUNICATION_METHOD_TOOLTIP_TEXT}
        type="select"
        selectValues={CommunicationMethodEnum}
        selectFirstName="Choose One"
      />
      <Field
        component={CustomPhoneNumber}
        label="Preferred Phone Number"
        name="notificationPhoneNumber"
        id="notificationPhoneNumber"
        tooltipText={PHONENUMBER_TOOLTIP_TEXT}
      />
      <Button type="submit" title="Next" />
    </Form>
  );

  const NotificationMethodFormFormik = withFormik({
    mapPropsToValues: () => ({
      communicationMethod: communicationMethod || '',
      notificationPhoneNumber: notificationPhoneNumber || '',
    }),

    validationSchema: Yup.object({
      communicationMethod: Yup.string().required('Please select the Communication Method.'),
      notificationPhoneNumber: Yup.string()
        .test('required', 'Please enter the Preferred Phone Number.', val => val !== '+' && val)
        .test('len', 'Invalid phone number', val => val && val.match(/\d/g).join('').length === 10),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      dispatchSubmitMindfire({
        lastPage: '11023',
        dealerUserId: dealerUserId,
        did: did,
      });
      dispatchChangeInfoValue('communicationMethod', values.communicationMethod);
      dispatchChangeInfoValue(
        'notificationPhoneNumber',
        values.notificationPhoneNumber.match(/\d/g).join(''),
      );
      dispatchChangePage(EnerBankFlowPageNumber.ApplicationSubmit);
    },
  })(NotificationMethodForm);

  return (
    <Container>
      <Row>
        <BackBtn changePage={dispatchChangePage} page={EnerBankFlowPageNumber.DeliveryMethod} />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <NotificationMethodFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  communicationMethod: state.enerBankInformation.communicationMethod,
  notificationPhoneNumber: state.enerBankInformation.notificationPhoneNumber,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeInfoValue: (key, value) => dispatch(changeEnerBankInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationMethod);
