import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import Button from '../../components/UI/Button/CustomButton';
import {
  changeAuthValue,
  changeMicrofInfoValue,
  changePreQualInfoValue,
  getDealerStats,
  resetNewAppState,
  changePage,
  setFlow,
  setDefaultTheme,
  greenskyValidateMerchant,
} from '../../redux/actions';
import { defaultFlow, microfFlow } from '../../redux/initialState';
import { MicrofFlowPageNumber } from '../Microf/MicrofFlow';
import { AquaPreQualificationFlowPageNumber } from './preQualificationFlow';

const ApplicationType = props => {
  const {
    history,
    dispatchChangeMicrofInfoValue,
    dispatchChangePage,
    dispatchSetFlow,
    dispatchChangeAuthValue,
  } = props;

  return (
    <Container>
      <Row>
        <>
          <Col xs="12" md="6" className="mt-4">
            <Button
              className="h-100"
              clickHandler={() => {
                dispatchChangeMicrofInfoValue('microfOnly', true);
                dispatchChangeAuthValue('aquaFinanceAssigned', false);
                dispatchSetFlow(microfFlow);
                dispatchChangePage(MicrofFlowPageNumber.Landing);
                window.location.href = '/RTO';
              }}
              title="Begin New Application"
            />
          </Col>

          <Col xs="12" md="6" className="mt-4">
            <Button
              className="mb-1 h-100"
              clickHandler={() => {
                dispatchChangeMicrofInfoValue('microfOnly', true);
                dispatchChangeAuthValue('aquaFinanceAssigned', false);
                dispatchSetFlow(defaultFlow);
                dispatchChangePage(AquaPreQualificationFlowPageNumber.AccountEmail);
                window.location.href = '/';
              }}
              title="Begin New Application"
            />
          </Col>
        </>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  dealerStatsSummary:
    state.auth.user && state.auth.user.user ? state.auth.user.user.dealerStatsSummaryDTO : null,
  theme: state.theme.theme,
  dealerUserId: state.auth.dealerUserId,
  dealerId: state.auth.dealerId,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePreQualInfoValue: (key, value) => dispatch(changePreQualInfoValue(key, value)),
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeMicrofInfoValue: (key, value) => dispatch(changeMicrofInfoValue(key, value)),
  dispatchSetFlow: flow => dispatch(setFlow(flow)),
  dispatchChangeAuthValue: (key, value) => dispatch(changeAuthValue(key, value)),
  dispatchGetDealerStats: () => dispatch(getDealerStats()),
  dispatchResetNewAppState: () => dispatch(resetNewAppState()),
  dispatchSetDefaultTheme: theme => dispatch(setDefaultTheme(theme)),
  dispatchGreenskyValidateMerchant: (dealerId, dealerUserId, history) =>
    dispatch(greenskyValidateMerchant(dealerId, dealerUserId, history)),
});

ApplicationType.propTypes = {
  email: PropTypes.string,
  isEmailUniqueVal: PropTypes.bool,
  dealerUserId: PropTypes.number,
  dealerId: PropTypes.number,
  did: PropTypes.string,
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
  dispatchIsEmailUnique: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApplicationType);
