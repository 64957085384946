import React from 'react';
import Map from '../../../components/UI/Google/Map';

const SearchTable = props => {
  const { foundContractors } = props;

  return foundContractors && foundContractors.length > 0 ? (
    <>
      <div className="mt-5">
        <Map foundContractors={foundContractors} />
      </div>
      <table className="table table-hover table-striped mt-2">
        <thead>
          <tr>
            <th>Microf contractors near you</th>
            <th>Address</th>
            <th>Industry</th>
            <th>Phone number</th>
            <th>Distance</th>
          </tr>
        </thead>
        <tbody>
          {foundContractors.map((contractor, index) => (
            <tr key={index}>
              <td>{contractor.dealerName}</td>
              <td>{contractor.street + ', ' + contractor.city + ', ' + contractor.zipcode}</td>
              <td>{contractor.industry}</td>
              <td>{contractor.phone}</td>
              <td>{contractor.distance}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  ) : (
    <div className="no-result mt-4 mb-4">
      No dealers found near your zipcode. For more information call Microf call center: (855)
      642-7631
    </div>
  );
};

export default SearchTable;
