import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ApplicationFlowEnum from '../../assets/js/ApplicationFlowEnum';
import Section from '../../components/Section/Section';
import Header from '../../components/UI/Header/Header';
import { CONSUMER_PORTAL_TITLE, DEALER_PORTAL_TITLE } from '../../constants/document';
import { login, setFlow } from '../../redux/actions/index';
import { setDocumentTitle } from '../../util/documentUtil';
import MicrofFlow from './MicrofFlow';

const Microf = props => {
  const { microfFlowCurrentPage, history, flowName, auth } = props;
  const isUserLoggedIn = auth.user.dealerUser === null;

  useEffect(
    () => setDocumentTitle(isUserLoggedIn ? CONSUMER_PORTAL_TITLE : DEALER_PORTAL_TITLE),
    // eslint-disable-next-line
    [],
  );

  if (flowName !== ApplicationFlowEnum.MICROF_FLOW.value) {
    history.push(Object.values(ApplicationFlowEnum).find(flow => flow.value === flowName).path);
    return <></>;
  }

  return (
    <>
      <Header
        title={MicrofFlow[microfFlowCurrentPage ? microfFlowCurrentPage : 0].props.title}
        pages={MicrofFlow.length - 1}
      />
      <Section Component={MicrofFlow[microfFlowCurrentPage ? microfFlowCurrentPage : 0]} />
    </>
  );
};

const mapStateToProps = state => ({
  microfOnly: state.microfInformation.microfOnly,
  microfFlowCurrentPage: state.navi.microfFlowCurrentPage,
  score: state.softPull.softPullResponse.score,
  flowName: state.navi.flowName,
  app: state.softPull.applicationSubmitDTO,
  auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetFlow: flow => dispatch(setFlow(flow)),
  dispatchLogin: user => dispatch(login(user)),
});

Microf.propTypes = {
  microfFlowCurrentPage: PropTypes.number,
  score: PropTypes.number,
  flowName: PropTypes.string,
  dispatchSetFlow: PropTypes.func,
  dispatchLogin: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Microf);
