import React from 'react';
import { getIsOptimus } from '../../config/settings';
import NextSteps from '../Portal/NextSteps';
import RheemDecline from '../Rheem/RheemDecline';
import AccountEmail from './AccountEmail';
import AccountPassword from './AccountPassword';
import AlreadyFunded from './AlreadyFunded';
import AppIncomeInfo from './AppIncomeInfo';
import ApplicationFinished from './ApplicationFinished';
import ApplicationReceived from './ApplicationReceived';
import AppSSN from './AppSSN';
import CoApplicantAddress from './CoApplicant/CoAppAddress';
import CoApplicantDetails from './CoApplicant/CoAppDetails';
import CoAppIdentificationInfo from './CoApplicant/CoAppIdentificationInfo';
import CoApplicantInfo from './CoApplicant/CoAppInfo';
import CompleteFundMicrofApplication from './CompleteFundMicrofApplication';
import EditSystemType from './EditSystemType';
import FundingNotAllowed from './FundingNotAllowed';
import HasCoApplicant from './HasCoApplicant';
import Homeowner from './Homeowner';
import HvacHeatType from './HvacHeatType';
import HvacSystemType from './HvacSystemType';
import RessubmissionPending from './RessubmissionPending';
import Landing from './Landing';
import LandingFTL from './LandingFTL';
import LeaseAmount from './LeaseAmount';
import LeasingOffers from './LeasingOffers';
import ManualProofOfIncome from './ManualProofOfIncome';
import Review from './Review';
import ServiceFinance from './ServiceFinance';
import ServiceFinanceComplete from './ServiceFinanceComplete';
import SystemSize from './SystemSize';
import SystemType from './SystemType';
import UserAddress from './UserAddress';
import UserInfo from './UserInfo';
import WaterHeater from './WaterHeater';
import WaterHeaterLeasingOffers from './WaterHeaterLeasingOffers';

const leasePurchaseTitle = `Microf Lease Purchase Application`;
const isOptimus = getIsOptimus();

const MicrofFlow = [
  // 1
  <Landing title={leasePurchaseTitle} />,
  //<NewLanding title={leasePurchaseTitle} />,
  // 2
  <AccountEmail title={leasePurchaseTitle}>
    <h4>Let’s start with your email address.</h4>
  </AccountEmail>,
  // 3
  <AccountPassword title={leasePurchaseTitle}>
    <h4>Choose a password that you can remember.</h4>
  </AccountPassword>,
  // 4
  <UserInfo title={leasePurchaseTitle}>
    <h4>What’s your name and number?</h4>
  </UserInfo>,
  // 5
  <AppSSN title={leasePurchaseTitle}>
    <p>We’ll need this information to verify your identity.</p>
  </AppSSN>,
  // 6
  <Homeowner title={leasePurchaseTitle} />,
  //7
  <UserAddress title={leasePurchaseTitle}>
    <h4>What is the street address where the project will be completed?</h4>
  </UserAddress>,
  // 8
  <SystemType />,
  // 9
  <SystemSize title={leasePurchaseTitle}>
    <h4>Tell us more about your home.</h4>
    <p>What is the size of your home?</p>
  </SystemSize>,
  // 10
  <HvacSystemType title={leasePurchaseTitle}>
    <h4>Tell us about your existing system.</h4>
  </HvacSystemType>,
  // 11
  <HvacHeatType title={leasePurchaseTitle}>
    <h4>Tell us about your existing system.</h4>
    <p>How is your heat generated?</p>
  </HvacHeatType>,
  // 12 Water Heater
  <WaterHeater>
    <h4>Tell us about your new water heater.</h4>
  </WaterHeater>,
  // 13
  <LeaseAmount title={leasePurchaseTitle}>
    <h4>Please provide the estimated Retail (Cash) Price for your home improvement project.</h4>
    <p>
      This is the amount from your contractor's quote that includes the cost of equipment, delivery,
      and installation.
    </p>
  </LeaseAmount>,
  // 14
  <AppIncomeInfo title={leasePurchaseTitle}>
    <h4>
      In order to <span className="dotted-underline">make a decision</span>, let’s learn more about
      you.
    </h4>
    <p>
      Your banking information is only used to verify that you have an account that is in good
      standing, We do not use this to withdraw funds, unless you later provide your express informed
      consent.
    </p>

    <p>
      Revealing alimony, child support, or separate maintenance income is optional. You may include
      income from any source. If you are relying on income of a spouse, domestic partner, or another
      individual, please continue and add a Co-Applicant.
    </p>
  </AppIncomeInfo>,
  // 15
  <HasCoApplicant title={leasePurchaseTitle}>
    <h4>Would you like to add a Co-Applicant?</h4>
    <p>
      A co-applicant is <u>not</u> required.
    </p>
  </HasCoApplicant>,
  // 16
  <CoApplicantInfo title={leasePurchaseTitle}>
    <h4>Co-Applicant, enter your name and contact information.</h4>
  </CoApplicantInfo>,
  // 17
  <CoApplicantDetails title={leasePurchaseTitle}>
    <h4>Please provide your date of birth and Social Security Number.</h4>
  </CoApplicantDetails>,
  // 18
  <CoApplicantAddress title={leasePurchaseTitle}>
    <h4>Co-Applicant, enter your address.</h4>
  </CoApplicantAddress>,
  // 19
  <CoAppIdentificationInfo title={leasePurchaseTitle}>
    <h4>Co-Applicant, let’s learn more about you.</h4>
    <p>
      Your banking information is only used to verify that you have an account that is in good
      standing, We do not use this to withdraw funds, unless you later provide your express informed
      consent.
    </p>
    <p>
      Revealing alimony, child support or separate maintenance income is optional. You may include
      income from any source.
    </p>
  </CoAppIdentificationInfo>,
  // 20
  <Review title={leasePurchaseTitle}>
    <h4>Let’s double check the information you’ve provided.</h4>
  </Review>,
  // 21
  <LeasingOffers title={leasePurchaseTitle} />,
  // 22
  <WaterHeaterLeasingOffers title={leasePurchaseTitle} />,
  //23
  <ServiceFinance title={leasePurchaseTitle}>
    <h4>Please provide the additional information required.</h4>
  </ServiceFinance>,
  // 24
  <CompleteFundMicrofApplication onCancel={() => window.location.replace('/Login')} />,
  // 25
  <ServiceFinanceComplete />,
  // 26
  <FundingNotAllowed />,
  // 27
  <NextSteps />,
  // 28
  <ApplicationFinished />,
  // 29
  <LandingFTL />,
  // 30
  <RheemDecline />,
  // 31
  <ApplicationReceived title={leasePurchaseTitle} />,
  // 32
  <ManualProofOfIncome title={leasePurchaseTitle}>
    <h4>
      If you have a copy of your proof of income (or benefits) information, please upload it to the
      application.
    </h4>
    <br />
    <p>
      <b>If not, please send it to Microf via:</b>
      <br />
      Email: info@microf.com
      <br />
      Fax: 229-878-4865
      <br />
      Text: 229-255-2679
    </p>
  </ManualProofOfIncome>,
  //33
  <AlreadyFunded />,
  //34
  <EditSystemType>
    <h4>Tell us about the quote and system type of your new HVAC unit.</h4>
  </EditSystemType>,
  //35
  <RessubmissionPending />,
];

export default MicrofFlow;

export const MicrofFlowPageNumber = {
  Landing: 0,
  AccountEmail: 1,
  AccountPassword: 2,
  UserInfo: 3,
  AppSSN: 4,
  Homeowner: 5,
  UserAddress: 6,
  SystemType: 7,
  SystemSize: 8,
  HvacSystemType: 9,
  HvacHeatType: 10,
  WaterHeater: 11,
  LeaseAmount: 12,
  AppIncomeInfo: 13,
  HasCoApplicant: 14,
  CoApplicantInfo: 15,
  CoApplicantDetails: 16,
  CoApplicantAddress: 17,
  CoAppIdentificationInfo: 18,
  Review: 19,
  LeasingOffers: 20,
  WaterHeaterLeasingOffers: 21,
  ServiceFinance: 22,
  CompleteFundMicrofApplication: 23,
  ServiceFinanceComplete: 24,
  FundingNotAllowed: 25,
  NextSteps: 26,
  ApplicationFinished: 27,
  LandingFTL: 28,
  RheemDecline: 29,
  ApplicationReceived: 30,
  ManualProofOfIncome: 31,
  AlreadyFunded: 32,
  EditSystemType: 33,
  RessubmissionPending: 34,
};
