import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { submitMindfire } from '../../../../redux/actions';
import { reactGAEvent, reactGAPageview } from '../../../../helper';
import { getFoundationUrl } from '../../../../config/settings';
import Button from '../../../../components/UI/Button/CustomButton';

const Approved = props => {
  const { did, dealerUserId, dispatchChangePageTitle, dispatchSubmitMindfire, email } = props;
  const foundationUrl = getFoundationUrl();

  useEffect(() => {
    dispatchChangePageTitle('Application Results');
    reactGAPageview({
      path: '/ca_fdapproved',
      trackerNames: [],
      title: 'ca_fdapproved',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const mindFireRequest = { email: email, lastPage: '10024', dealerUserId: dealerUserId, did: did };

  const handleClick = () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Continue to Foundation',
      did,
      dealerUserId,
    });
    dispatchSubmitMindfire(mindFireRequest);
    window.open(foundationUrl, '_blank');
  };

  return (
    <Container>
      <Row className="justify-content-center mt-2">
        <Col xs="12" md="8">
          <h4 className="text-center">
            <span className="primary">Congratulations!</span> You’ve been approved. Please check
            with your sales reps for available financing terms.
          </h4>
        </Col>
      </Row>
      <Row className="justify-content-center mt-2">
        <Col xs="12" md="8">
          <h4 className="text-center">
            Your contractor will need to access their portal to select the loan product and create
            your loan documents at:
          </h4>
        </Col>
      </Row>
      <Row className="justify-content-center mt-2">
        <Col xs="12" md="auto">
          <Button type="submit" title="Continue to Foundation" clickHandler={handleClick} />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  applicationId: state.softPull.applicationSubmitDTO?.applicationId,
});

const mapDispatchToProps = dispatch => ({
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Approved);
