import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import Button from '../../../components/UI/Button/CustomButton';
import {
  setEnerBankApplicationPromotion,
  changePage,
  submitMindfire,
  resetState,
  resetNewAppState,
} from '../../../redux/actions';
import {
  reactGAPageview,
  reactGAEvent,
  nextFinancingOption,
  nextFlowByNextFinancingOption,
  nextPageByNextFinancingOption,
} from '../../../helper';
import EnerBankLoanOption from './EnerBankLoanOption';
import { getDealerPortalURL, getEnerBankTechSupport } from '../../../config/settings';
import selectedFinancierEnum from '../../../assets/js/SelectedFinancierEnum';

const Undecisioned = props => {
  const {
    did,
    dealerUserId,
    enerBankLoanOptions,
    applicationId,
    email,
    dispatchSetApplicationPromotion,
    dispatchChangePageTitle,
    dispatchSubmitMindfire,
    user,
    history,
    dispatchResetState,
    dispatchResetNewAppState,
    application,
    dispatchUpdateSelectedFinancier,
    consumerToken,
    dispatchMapApplicationToMicrof,
    dispatchSetFlow,
  } = props;

  const isUserLoggedIn = !did && user && user.dealerUser;
  const returnUrl = isUserLoggedIn ? '/portal/dashboard' : getDealerPortalURL();
  const mindFireRequest = { email: email, lastPage: '11028', dealerUserId: dealerUserId, did: did };

  let nextFO = nextFinancingOption(
    application ? application : { deler: null, airConditioningSystem: null },
    selectedFinancierEnum.POWER_PAY,
  );
  let nextFlow = nextFlowByNextFinancingOption(nextFO);
  let nextPage = nextPageByNextFinancingOption(nextFO);

  useEffect(() => {
    dispatchChangePageTitle('Application Results');
    reactGAPageview({
      path: '/ca_ebundecisioned',
      trackerNames: [],
      title: 'ca_ebundecisioned',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const handleSecondaryOption = async () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Continue to Secondary Lender',
      did,
      dealerUserId,
    });
    dispatchSubmitMindfire(mindFireRequest);

    await dispatchUpdateSelectedFinancier(nextFO.value, consumerToken, () => {
      dispatchMapApplicationToMicrof(application);
      dispatchSetFlow(nextFlow);
      history.push(nextPage);
    });
  };

  const handleClose = () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Close',
      did,
      dealerUserId,
    });
    dispatchSubmitMindfire(mindFireRequest);
    if (!isUserLoggedIn) {
      dispatchResetState(props);
      window.location.href = returnUrl;
    } else {
      dispatchResetNewAppState();
      history.push(returnUrl);
    }
  };

  return (
    <>
      <Container>
        <Row>
          <Col sm="1" />
          <Col sm="5">
            <h4>Application Results</h4>
            <br />
            <h3 className="colorPrimay">Application Submitted</h3>
            <p>
              Your loan application has been received. Please contact our Lending Department. For
              faster service, please provide the lending specialist with your application number
              (displayed to the right).
            </p>
            <h4 className="text-center">{getEnerBankTechSupport()}</h4>
          </Col>
          <Col sm="6">
            <Row>
              <Col>
                {enerBankLoanOptions
                  ? enerBankLoanOptions
                      .sort((a, b) => {
                        return a.numberOfMonths < b.numberOfMonths ? 1 : -1;
                      })
                      .reverse()
                      .map(item => (
                        <EnerBankLoanOption
                          isStatusUndecision={true}
                          loanOption={item}
                          key={item.loanTermId}
                          buttonText="I want this option"
                          applicationId={applicationId}
                          submitMindfire={() => dispatchSubmitMindfire(mindFireRequest)}
                          setApplicationPromotion={dispatchSetApplicationPromotion}
                        />
                      ))
                  : null}
              </Col>
            </Row>
            <Row>
              <Col>
                <Button type="submit" title="Close" clickHandler={handleClose} />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col>
                <p>
                  If you would rather continue to the next option available, please click on the
                  button below to proceed to the secondary lender.
                </p>
                <Button
                  type="submit"
                  title="Continue to Secondary Lender"
                  clickHandler={handleSecondaryOption}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = state => ({
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  enerBankLoanOptions: state.softPull.applicationSubmitDTO?.enerBankApplicationLoanTerm,
  applicationId: state.softPull.applicationSubmitDTO?.applicationId,
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchSetApplicationPromotion: (applicationId, promotionId, page) =>
    dispatch(setEnerBankApplicationPromotion(applicationId, promotionId, page)),
  dispatchResetState: props => dispatch(resetState(props)),
  dispatchResetNewAppState: () => dispatch(resetNewAppState()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Undecisioned),
);
