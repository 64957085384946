import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import BackBtn from '../../components/UI/BackButton/BackButton';
import Button from '../../components/UI/Button/CustomButton';
import { reactGAEvent, reactGAPageview } from '../../helper';
import {
  changePage,
  submitMindfire,
  changeRheemInfoValue,
  submitRheemAppliction,
} from '../../redux/actions';
import { withRouter, Link } from 'react-router-dom';
import { Field, Form, withFormik } from 'formik';
import * as Yup from 'yup';
import Checkbox from '../../components/UI/Form/Checkbox/Checkbox';
import { getAppName, getPrivacyStatementURL } from '../../config/settings';
import terms from '../../assets/pdf/KwikComfort Simplicity Terms and Conditions.pdf';
import statementOfUse from '../../assets/pdf/KwikComfort Simplicity Statement on Use of Electronic Records.pdf';
import { RheemFlowPageNumber } from './RheemFlow';
import AirConditioningSystemEnum from '../../assets/js/AirConditioningSystemEnum';

const appName = getAppName();

const Review = props => {
  const {
    rheemInformation,
    pqi,
    dispatchChangePage,
    dispatchChangeRheemInfoValue,
    children,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    applicantAddress,
    softPullResponse,
    emulatorId,
    track_id,
    dispatchSubmitRheemAppliction,
    app,
    history,
    consumerId,
  } = props;

  const getAddress = address =>
    (address.street ? address.street + ', ' : '') +
    (address.city ? address.city + ', ' : '') +
    (address.state ? address.state + ', ' : '') +
    (address.zip || '');

  const propertyAddress = pqi.street
    ? getAddress({
        street: pqi.street,
        city: pqi.city,
        state: pqi.state,
        zip: pqi.zipcode,
      })
    : 'No address';

  const primaryApplicantAddress = applicantAddress
    ? getAddress({
        street: applicantAddress.street,
        city: applicantAddress.city,
        state: applicantAddress.state,
        zip: applicantAddress.zip,
      })
    : propertyAddress;

  const coApplicantAddress = rheemInformation.hasCoApp
    ? getAddress({
        street: rheemInformation.coAppStreet,
        city: rheemInformation.coAppCity,
        state: rheemInformation.coAppState,
        zip: rheemInformation.coAppZipCode,
      })
    : 'No address';

  useEffect(() => {
    reactGAPageview({
      path: '/ca_confirminfo',
      trackerNames: [],
      title: 'ca_confirminfo',
      did,
      dealerUserId,
    });
    dispatchChangeRheemInfoValue('isReview', false);
    // eslint-disable-next-line
  }, []);

  const editPage = page => {
    dispatchChangeRheemInfoValue('isReview', true);
    dispatchChangePage(page);
  };

  const request = {
    email: softPullResponse.email || pqi.email,
    borrowedAmount: softPullResponse.borrowedAmount || pqi.borrowedAmount,
    firstName: softPullResponse.firstName || pqi.firstName,
    lastName: softPullResponse.lastName || pqi.lastName,
    middleName: softPullResponse.middleName || pqi.middleName,
    phoneNumber: softPullResponse.phoneNumber || pqi.phoneNumber,
    street: softPullResponse.street || pqi.street,
    city: softPullResponse.city || pqi.city,
    zipcode: softPullResponse.zipcode || pqi.zipcode,
    state: softPullResponse.state || pqi.state,
    applicantStreet: applicantAddress ? applicantAddress.street : null,
    applicantCity: applicantAddress ? applicantAddress.city : null,
    applicantZip: applicantAddress ? applicantAddress.zip : null,
    applicantState: applicantAddress ? applicantAddress.state : null,
    birthday: softPullResponse.birthday || pqi.birthday,
    ssnLastDigits: softPullResponse.ssnLastDigits || pqi.ssnLastDigits,
    airConditioningSystem:
      softPullResponse.airConditioningSystem ||
      pqi.airConditioningSystem ||
      AirConditioningSystemEnum.OTHER_HOME_IMPROVEMENT.id,
    isHomeowner: softPullResponse.isHomeowner || pqi.isHomeowner,
    dealerUserId: dealerUserId,
    consumerId: consumerId,
    prequalificationId: softPullResponse.prequalificationId || pqi.prequalificationId,
    ssn: rheemInformation.appFullSSN,
    selectedFinancier: 'Rheem',
    totalJobPrice: rheemInformation.requestedLoanAmount || null,
    monthlyNetIncome: (rheemInformation.appGrossAnnualIncome * 0.83) / 12,
    requestedLoanAmount: rheemInformation.requestedLoanAmount,
    relationshipWithCoapplicant: rheemInformation.hasCoApp
      ? rheemInformation.relationshipWithCoapplicant
      : null,
    coapplicantFirstName: rheemInformation.hasCoApp ? rheemInformation.coAppFirstName : null,
    coapplicantLastName: rheemInformation.hasCoApp ? rheemInformation.coAppLastName : null,
    coapplicantMiddleName: rheemInformation.hasCoApp ? rheemInformation.coAppMiddleInitial : null,
    coapplicantEmail: rheemInformation.hasCoApp ? rheemInformation.coAppEmail : null,
    coapplicantPhoneNumber: rheemInformation.hasCoApp ? rheemInformation.coAppPhoneNumber : null,
    coapplicantStreet: rheemInformation.hasCoApp ? rheemInformation.coAppStreet : null,
    coapplicantCity: rheemInformation.hasCoApp ? rheemInformation.coAppCity : null,
    coapplicantZipcode: rheemInformation.hasCoApp ? rheemInformation.coAppZipCode : null,
    coapplicantState: rheemInformation.hasCoApp ? rheemInformation.coAppState : null,
    coapplicantBirthday: rheemInformation.hasCoApp ? rheemInformation.coAppDoB : null,
    coapplicantSsn: rheemInformation.hasCoApp ? rheemInformation.coAppFullSsn : null,
    coapplicantMonthlyNetIncome: (rheemInformation.coApplicantGrossAnnualIncome * 0.83) / 12,

    emulatorId: emulatorId,
    track_id: track_id,
  };

  const handleSubmit = () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Submit Application',
      did,
      dealerUserId,
    });
    dispatchSubmitRheemAppliction(request, RheemFlowPageNumber.LeasingOffers, app, history);
    dispatchSubmitMindfire({
      email: pqi.email,
      lastPage: '0094',
      dealerUserId: dealerUserId,
      did: did,
    });
  };

  const ReviewForm = () => (
    <Form noValidate autoComplete="off">
      {rheemInformation.hasCoApp && (
        <Row className="review">
          <Col>
            <h5>I authorize Rheem to access my info under the Fair Credit Reporting Act.</h5>
            <p>
              You understand and agree that you are providing instructions to Rheem under the Fair
              Credit Reporting Act (“FCRA”) to access information from your personal credit profile
              or other information from one or more consumer reporting agencies such as Equifax,
              Experian, or TransUnion. This “hard pull” may impact your credit score.
            </p>
            <Field
              component={Checkbox}
              name="terms"
              id="terms"
              label={`Applicant`}
              labelClassName="bold mb-0"
            />
            <Field
              component={Checkbox}
              name="authorize"
              id="authorize"
              label={`Co-Applicant`}
              labelClassName="bold mb-0"
            />
          </Col>
        </Row>
      )}
      <Button type="submit" title="Submit Application" />
    </Form>
  );

  const ReviewFormFormik = withFormik({
    mapPropsToValues: () => ({
      terms: false,
      authorize: false,
    }),

    validationSchema: Yup.object({
      terms: rheemInformation.hasCoApp
        ? Yup.boolean().oneOf([true], 'Please check to continue.')
        : Yup.boolean(),
      authorize: rheemInformation.hasCoApp
        ? Yup.boolean().oneOf([true], 'Please check to continue.')
        : Yup.boolean(),
    }),

    handleSubmit,
  })(ReviewForm);

  return (
    <Container>
      <Row>
        {!app ? (
          <BackBtn
            changePage={dispatchChangePage}
            page={RheemFlowPageNumber.IdentificationInformation}
          />
        ) : (
          <Col sm="1" />
        )}
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <Row className="review">
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col xs="8" lg="9">
                  <h5>Applicant’s Information</h5>
                </Col>
                <Col xs="4" lg="3">
                  {!app && (
                    <Button
                      className="edit"
                      clickHandler={() => editPage(RheemFlowPageNumber.GrossAnnualIncome)}
                      title="Edit"
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <b>Lease Amount Request</b>
                  <NumberFormat
                    value={rheemInformation.requestedLoanAmount}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={'$'}
                    displayType="text"
                  />
                </Col>
                <Col />
              </Row>
              <Row>
                <Col xs="6">
                  <b>Name</b>
                  {(pqi.firstName ? pqi.firstName + ' ' : '') +
                    (pqi.middleName ? pqi.middleName + ' ' : '') +
                    (pqi.lastName || '')}
                </Col>
                <Col xs="6">
                  <b>Phone Number</b>
                  {!!pqi.phoneNumber
                    ? pqi.phoneNumber.length > 6
                      ? '(' +
                        pqi.phoneNumber.substring(0, 3) +
                        ') ' +
                        pqi.phoneNumber.substring(3, 6) +
                        '-' +
                        pqi.phoneNumber.substring(6)
                      : pqi.phoneNumber
                    : 'N/A'}
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <b>Email Address</b>
                  <span className="text-break">{pqi.email || 'N/A'}</span>
                </Col>
                <Col xs="6">
                  <b>Date of Birth</b>
                  {pqi.birthday || 'N/A'}
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <b>Full SSN</b>
                  {rheemInformation.appFullSSN
                    ? 'xxxxx' + rheemInformation.appFullSSN.slice(5, 9)
                    : 'N/A'}
                </Col>
                <Col xs="6">
                  <b>Annual Gross Income (or Benefits)</b>
                  <NumberFormat
                    value={rheemInformation.appGrossAnnualIncome}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={'$'}
                    displayType="text"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <b>Applicant’s Address</b>
                  {primaryApplicantAddress}
                </Col>
              </Row>
            </Col>
          </Row>
          {app && !rheemInformation.hasCoApp ? null : (
            <Row className="review">
              <Col>
                <Row className="align-items-center justify-content-between">
                  <Col xs="8" lg="9">
                    <h5>Co-Applicant’s Information</h5>
                  </Col>
                  <Col xs="4" lg="3">
                    <Button
                      className="edit"
                      clickHandler={() => editPage(RheemFlowPageNumber.HasCoApplicant)}
                      title={rheemInformation.hasCoApp ? 'Edit' : 'Add'}
                    />
                  </Col>
                </Row>
                {rheemInformation.hasCoApp ? (
                  <React.Fragment>
                    <Row>
                      <Col xs="6">
                        <b>Name</b>
                        {(rheemInformation.coAppFirstName
                          ? rheemInformation.coAppFirstName + ' '
                          : '') +
                          (rheemInformation.coAppMiddleInitial
                            ? rheemInformation.coAppMiddleInitial + ' '
                            : '') +
                          (rheemInformation.coAppLastName || '')}
                      </Col>
                      <Col xs="6">
                        <b>Phone Number</b>
                        {!!rheemInformation.coAppPhoneNumber
                          ? rheemInformation.coAppPhoneNumber.length > 6
                            ? '(' +
                              rheemInformation.coAppPhoneNumber.substring(0, 3) +
                              ') ' +
                              rheemInformation.coAppPhoneNumber.substring(3, 6) +
                              '-' +
                              rheemInformation.coAppPhoneNumber.substring(6)
                            : rheemInformation.coAppPhoneNumber
                          : 'N/A'}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6">
                        <b>Email Address</b>
                        <span className="text-break">{rheemInformation.coAppEmail || 'N/A'}</span>
                      </Col>
                      <Col xs="6">
                        <b>Date of Birth</b>
                        {rheemInformation.coAppDoB || 'N/A'}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6">
                        <b>Full SSN</b>
                        {rheemInformation.coAppFullSsn
                          ? 'xxxxx' + rheemInformation.coAppFullSsn.slice(5, 9)
                          : 'N/A'}
                      </Col>
                      <Col xs="6">
                        <b>Annual Gross Income (or Benefits)</b>
                        <NumberFormat
                          value={rheemInformation.coApplicantGrossAnnualIncome}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                          decimalScale={2}
                          prefix={'$'}
                          displayType="text"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <b>Co-Applicant’s Address</b>
                        {coApplicantAddress}
                      </Col>
                    </Row>
                  </React.Fragment>
                ) : null}
              </Col>
            </Row>
          )}
          <ReviewFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  app: state.softPull.applicationSubmitDTO,
  applicantAddress: state.rheemInformation.applicantAddress,
  softPullResponse: state.softPull.softPullResponse,
  rheemInformation: state.rheemInformation,
  pqi: state.preQualificationInformation,
  dealerUserId: state.auth.dealerUserId,
  consumerId: state.auth.consumerId,
  did: state.auth.did,
  emulatorId: state.auth.user.user ? state.auth.user.user.emulatorId : null,
  track_id: state.auth.track_id,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeRheemInfoValue: (key, value) => dispatch(changeRheemInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchSubmitRheemAppliction: (request, page, app, history) =>
    dispatch(submitRheemAppliction(request, page, app, history)),
});

Review.propTypes = {
  rheemInformation: PropTypes.object,
  pqi: PropTypes.object,
  dispatchChangePage: PropTypes.func,
  dispatchChangeRheemInfoValue: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Review),
);
