import * as actionTypes from './actionTypes';
import { changePage } from './naviActions';
import { changeAuthValue } from './authActions';
import { AquaPreQualificationFlowPageNumber } from '../../pages/PreQualification/preQualificationFlow';

export {
  addNotification,
  removeNotification,
  startLoading,
  endLoading,
} from './notificationActions';

export {
  login,
  logout,
  changeAuthValue,
  validateCustomLink,
  passwordRecovery,
  passwordReset,
  getVersion,
  setProfile,
  changeYourPassword,
  loginConsumerUserAfterCreation,
  createDealershipApplication,
  getDealerStats,
  emulateStart,
  emulateStop,
  getSession,
  updateDealerValue,
  validateCustomLinkDealerId,
} from './authActions';

export { setFlow, changePage, changePageTitle, changeNaviValue } from './naviActions';

export {
  changePreQualInfoValue,
  isEmailUnique,
  getScore,
  updatePrequalificationInformation,
  movePrequalificationToHistory,
} from './preQualInfoActions';

export {
  mapApplicationToUpgrade,
  upgradeSubmit,
  upgradeSelectOffer,
  upgradeEnroll,
} from './upgradeActions';

export {
  changeAquaInfoValue,
  submitAquaApplication,
  setApplicationPromotion,
  updateApplicationStatus,
  updateSelectedFinancier,
  mapApplicationToAqua,
} from './aquaActions';

export {
  changeMicrofInfoValue,
  finalAcceptMicrofApplication,
  finalAcceptMicrofPartnerApplication,
  acceptTermsMicrofApplication,
  acceptTermsMicrofPartnerApplication,
  uploadProofOfIncomeMicrofApplication,
  uploadProofOfIncomeMicrofPartnerApplication,
  manualAcceptMicrofApplication,
  manualAcceptMicrofApplications,
  rejectMicrofApplication,
  rejectMicrofPartnerApplication,
  incomeValidation,
  incomeValidationRequestCompleted,
  createConsumerUser,
  updateConsumerUser,
  setMicrofOnlyConsumerFlow,
  setMicrofOnlyConsumerSoftPullFlow,
  mapApplicationToMicrof,
  finishMicrofApplication,
} from './microfActions';

export {
  submitSoftPullApplication,
  submitSoftPullApplication2,
  getAquaAppByIdAndSelectedFinancier,
  getAppByIdAndSelectedFinancier,
  submitMindfire,
  getConsumerApp,
  getPrequalificationByIdAndToken,
  getConsumerUser,
  getApplications,
  getTransactionHistory,
  getApplicationsHistory,
  getUnassignedPrequalificationInfoList,
  getDeclinedConsumerApplications,
  getApplication,
  createRojosUser,
  createDealerUser,
  initialContractorInformation,
  deleteUser,
  updateUser,
  changeSoftPullValue,
  completeMicrofWork,
  searchDealerUsers,
  greenskyApiCredentials,
  searchContractorUsers,
  getDealerUser,
  updateDealerUser,
  findApplicationByRtoOrId,
  findApplicationByIdForGDSResubmit,
  moveAppToContractor,
  searchApplications,
  getApplicationViewDTO,
  assignApplication,
  findContractors,
  searchContractors,
  updateDealerStates,
  getUploadedDocuments,
  createInternalUser,
  updateDealerZipCodes,
  searchInternalUsers,
  sendDataToOriginationExpert,
  getDealerRestrictAquaPromotions,
  updateDealerPromotions,
  getApplicationByConsumerId,
  getPrequalificationInformation,
  getAppByIdAndToken,
  getAppByIdAndSelectedFinancierForUserAdminRevisit,
  getDealer,
  sendEmailToSalesPerson,
  sendEmailToRelationshipManager,
  getModelNumbersFromRheem,
  getSoftPullHcBrands,
  setDefaultFinancingOption,
  mapApplicationToPrequalification,
  changePassword,
  checkErrors,
  setSecondSelectedFinancerFlow,
  getAllTransactions,
  getSingleTransaction,
  getGreenSkyApplication,
  getGreenSkyOffersData,
  getGreenSkyLoanAgreement,
  recalculateGreenSkyOffer,
  viewGreenSkyLoanAgreement,
  greenSkyAcceptOffer,
  greenSkyCheckCta,
  getGreenSkyQuestions,
  postGreenSkyIdentityVerification,
  submitCharge,
  submitRefund,
  cancelTransaction,
  greenskyValidateEmail,
  greenskyValidateUsersEmail,
  greenskyValidateMerchant,
  getDealers,
  searchUsers,
  setPrimaryUser,
  updateExternalId,
} from './softPullActions';

export {
  changeGreenSkyInfoValue,
  changeGSRegisterAsContractorInfoValue,
  changeGSContractorEnrollmentInfoValue,
  getReCapthcaKey,
  registerContractor,
  applyToBeDealer,
  resetGreenSkyState,
  mapGreenskyToState,
} from './greenskyActions';

export { changeAtWaterInfoValue, resetAtWaterState } from './atWaterActions';

export {
  changeAtWaterApplicationInfoValue,
  getAtWaterProgramsAndProducts,
  submitAtWaterApplication,
  loanDecision,
  submitAtWaterGreenSkyApplication,
  getAtWaterGreenSkyApplication,
  getAtWaterGreenSkyOffersData,
  recalculateAtWaterGreenSkyOffer,
  atWaterGreenSkyAcceptOffer,
  getAtWaterGreenSkyLoanAgreement,
  viewAtWaterGreenSkyLoanAgreement,
  atWaterGreenSkyCheckCta,
  atWaterGreenSkyCheckCta2,
  atWaterESign,
  getAtWaterGreenSkyQuestions,
  postAtWaterGreenSkyIdentityVerification,
  atWaterGreenSkySubmitCharge,
  atWaterGreenSkysubmitRefund,
  atWaterGreenskyCancelTransaction,
  getAtWaterGreenskySingleTransaction,
  getAtWaterGreenskyAllTransactions,
  sendEmailConsumerUrl,
  applyToBeDealerGS,
  updateMerchantNumber,
} from './atWaterApplicationActions';

export {
  getDisclosures,
  getDisclosureAcceptApp,
  getDisclosureAcceptAppCoapp,
  getDisclosureSubmitApp,
  getDisclosureSubmitAppCoapp,
  getDisclosureFooter,
  changeGreenSkyApplicationInfoValue,
  submitGreenSkyApplication,
  getGreenskyMerchantPlans,
} from './greenskyApplicationActions';

export { getDistributors, getHCBrands, getBanks } from './utileActions';

export {
  changePowerPayInfoValue,
  submitPowerPayApplication,
  setPowerPayApplicationPromotion,
  mapPowerPayToState,
} from './powerPayActions';

export {
  changeFoundationInfoValue,
  mapAppToFoundation,
  submitFoundationApplication,
} from './foundationActions';

export {
  mapWellsFargoToMicrof,
  changeWellsFargoValue,
  submitWellsFargoApplication,
  mapAppToWellsFargo,
} from './wellsFargoActions';

export {
  mapMosaicToMicrof,
  changeMosaicInfoValue,
  submitMosaicApplication,
  mapMosaicToState,
} from './mosaicActions';

export {
  changeFtlInfoValue,
  submitFTLApplication,
  mapFTLToState,
  mapAppToFtl,
  uploadFTLDocumentsApplication,
  getFtlData,
  ftlSelectProgram,
  getFtlDistributors,
  ftlFetchProducts,
  ftlSetAmount,
  ftlGeneratePaperwork,
  ftlSelectLoanMoreProgram,
  ftlSendEquipmentInfo,
  provideFundingInfo,
  printFundingInfo,
} from './ftlActions';

export { getUser } from './api';

export { changeContractorEnrollmentValue } from './contractorEnrollmentActions';

export { changeRheemInfoValue, submitRheemAppliction, mapAppDtoToRheem } from './rheemActions';

export {
  changeTheme,
  isThemeRheem,
  isThemeMicrof,
  isThemeOptimus,
  setDefaultTheme,
} from './themeActions';

export {
  changeEnerBankInfoValue,
  submitEnerBankApplication,
  setEnerBankApplicationPromotion,
  mapEnerBankToState,
  getEnerBankProgram,
  postIdentityVerification,
} from './enerBankActions';

export const resetState = ({ history, revisit }) => dispatch => {
  dispatch({ type: actionTypes.RESET_STATE_AQUA });
  dispatch({ type: actionTypes.RESET_STATE_WELLS_FARGO });
  if (!revisit) {
    dispatch({ type: actionTypes.RESET_STATE_AUTH });
  }
  dispatch({ type: actionTypes.RESET_STATE_MICROF });
  dispatch({ type: actionTypes.RESET_STATE_NAVI });
  dispatch({ type: actionTypes.RESET_STATE_NOTIFICATION });
  dispatch({ type: actionTypes.RESET_STATE_PQI });
  dispatch({ type: actionTypes.RESET_STATE_SOFT_PULL });
  dispatch({ type: actionTypes.RESET_STATE_POWER_PAY });
  dispatch({ type: actionTypes.CHANGE_GREENSKY_REGISTER_AS_CONTRACTOR_INFO_VALUE });
  dispatch({ type: actionTypes.RESET_STATE_CONTRACTOR_ENROLLMENT });
  dispatch({ type: actionTypes.RESET_STATE_RHEEM });
  dispatch({ type: actionTypes.RESET_STATE_MOSAIC });
  dispatch({ type: actionTypes.RESET_STATE_FOUNDATION });
  dispatch({ type: actionTypes.RESET_STATE_GREENSKY_APPLICATION });
  dispatch({ type: actionTypes.RESET_STATE_ENER_BANK });
  dispatch({ type: actionTypes.RESET_STATE_FTL });
  dispatch({ type: actionTypes.RESET_STATE_ATWATER });
  dispatch({ type: actionTypes.RESET_STATE_ATWATER_APPLICATION });
  dispatch({ type: actionTypes.RESET_STATE_CONTRACTOR_ATWATER_GS_ENROLLMENT });
  if (history) {
    history.push('/');
  }
};

export const resetNewAppState = () => dispatch => {
  dispatch({ type: actionTypes.RESET_STATE_AQUA });
  dispatch({ type: actionTypes.RESET_STATE_MICROF });
  dispatch({ type: actionTypes.RESET_STATE_PQI });
  dispatch({ type: actionTypes.RESET_STATE_NAVI });
  dispatch({ type: actionTypes.RESET_STATE_SOFT_PULL });
  dispatch({ type: actionTypes.RESET_STATE_RHEEM });
  dispatch({ type: actionTypes.RESET_STATE_WELLS_FARGO });
  dispatch({ type: actionTypes.RESET_STATE_MOSAIC });
  dispatch({ type: actionTypes.RESET_STATE_POWER_PAY });
  dispatch({ type: actionTypes.RESET_STATE_FOUNDATION });
  dispatch({ type: actionTypes.RESET_STATE_GREENSKY_APPLICATION });
  dispatch({ type: actionTypes.RESET_STATE_ENER_BANK });
  dispatch({ type: actionTypes.RESET_STATE_FTL });
  dispatch({ type: actionTypes.RESET_STATE_ATWATER });
  dispatch({ type: actionTypes.RESET_STATE_ATWATER_APPLICATION });
  dispatch({ type: actionTypes.RESET_STATE_CONTRACTOR_ATWATER_GS_ENROLLMENT });
  dispatch(changeAuthValue('aquaFinanceAssigned', null));
  dispatch(changeAuthValue('powerPayAssigned', null));
  dispatch(changeAuthValue('mosaicAssigned', null));
  dispatch(changeAuthValue('enerBankAssigned', null));
  dispatch(changeAuthValue('foundationAssigned', null));
  dispatch(changeAuthValue('serviceFinanceAssigned', null));
  dispatch(changeAuthValue('upgradeAssigned', null));
  dispatch(changePage(AquaPreQualificationFlowPageNumber.UserType));
};
