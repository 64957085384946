import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { reactGAEvent } from '../../../../helper';
import { getDealerPortalURL, getGreenSkyTechnicalSupportNumber } from '../../../../config/settings';
import Button from '../../../../components/UI/Button/CustomButton';
import { submitMindfire } from '../../../../redux/actions/index';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { resetState, resetNewAppState } from '../../../../redux/actions/index';

const ErrorPage = props => {
  const {
    dealerUserId,
    did,
    dispatchSubmitMindfire,
    email,
    history,
    user,
    dispatchResetNewAppState,
    dispatchResetState,
  } = props;

  const isUserLoggedIn = !did && user && user.dealerUser;
  const buttonTitle = isUserLoggedIn ? 'Return to Dashboard' : 'Return to Login Page';
  const returnUrl = isUserLoggedIn ? '/portal/dashboard' : getDealerPortalURL();
  // useEffect(() => {
  //   reactGAPageview({
  //     path: '/ca_gserror',
  //     trackerNames: [],
  //     title: 'ca_gserror',
  //     did,
  //     dealerUserId,
  //   });
  //   // eslint-disable-next-line
  // }, []);

  const handleClick = () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: buttonTitle,
      did,
      dealerUserId,
    });
    dispatchSubmitMindfire({
      email: email,
      lastPage: '9027',
      dealerUserId: dealerUserId,
      did: did,
    });
    if (!isUserLoggedIn) {
      dispatchResetState(props);
      window.location.href = returnUrl;
    } else {
      dispatchResetNewAppState();
      history.push(returnUrl);
    }
  };

  return (
    <Container>
      <Row className="justify-content-center mt-2">
        <Col xs="auto" md="8">
          <h3 className="text-center">
            <b className="primary">Loan Activation</b>
          </h3>
        </Col>
      </Row>

      <Row className="justify-content-center mt-2">
        <Col xs="auto" md="8">
          <h5 className="text-center">
            For your protection, this account must be activated by phone.
          </h5>
        </Col>
      </Row>

      <Row className="justify-content-center mt-2">
        <Col xs="auto" md="8">
          <h5 className="text-center">
            We apologize for the inconvenience, but we are unable to verify your identity. At
            GreenSky, your financial protection is of utmost importance to us. Please call our
            customer support representative at{' '}
            <b className="primary">{getGreenSkyTechnicalSupportNumber()}</b> to verify your
            identity, review your loan documentation, and activate your account.
          </h5>
        </Col>
      </Row>
      <Row className="justify-content-center mt-2">
        <Col xs="auto">
          <Button type="button" title={buttonTitle} clickHandler={handleClick} />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  email: state.preQualificationInformation.email,
  user: state.auth.user,
  pendingDescription:
    state.softPull.applicationSubmitDTO && state.softPull.applicationSubmitDTO.pendingDescription,
});

const mapDispatchToProps = dispatch => ({
  dispatchResetState: props => dispatch(resetState(props)),
  dispatchResetNewAppState: () => dispatch(resetNewAppState()),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ErrorPage),
);
