import React from 'react';
import { Field, Form, withFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Label } from 'reactstrap';
import InputField from '../../../components/UI/Form/Input/InputField';

const ChangeYourPassword = props => {
  const { isOpen, user, className, changeAuthValue, changeYourPassword } = props;

  const ChangeYourPasswordForm = () => (
    <Form>
      <ModalBody>
        <Row className="mt-4">
          <Col>
            <Label for="password">Enter your current password.</Label>
            <Field
              component={InputField}
              name="password"
              id="password"
              type="password"
              placeholder="Password"
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Label for="newPassword">Enter your new password.</Label>
            <Field
              component={InputField}
              name="newPassword"
              id="newPassword"
              type="password"
              placeholder="New Password"
            />
            <Field
              component={InputField}
              name="confNewPassword"
              id="confNewPassword"
              type="password"
              placeholder="Confirm New Password"
              className="mt-1"
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <b>Password Policy:</b>
            <br />
            Minimum length is 8 characters.
            <br />
            Must have at least one numeric character.
            <br />
            Must have at least one upper letter.
            <br />
            Must have at least three letters.
            <br />
            Password must be sufficiently unique.
            <br />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          onClick={() => {
            changeAuthValue('showChangeYourPassword', false);
          }}
        >
          Cancel
        </Button>
        <Button type="submit" color="primary">
          Apply
        </Button>
      </ModalFooter>
    </Form>
  );

  const ChangeYourPasswordFormFormik = withFormik({
    mapPropsToValues: () => ({
      password: '',
      newPassword: '',
      confNewPassword: '',
    }),

    validationSchema: Yup.object({
      password: Yup.string().required('Please enter a password.'),
      newPassword: Yup.string()
        .min(7, 'Password has to be at least 7 characters.')
        .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,255}$/, 'Insert a valid password.')
        .test('3Letters', 'Insert a valid password.', val => {
          const alpha = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
          let charNo = 0;
          if (!!val) {
            for (var i = 0; i < val.length; i++) {
              if (alpha.indexOf(val.charAt(i)) > -1) {
                charNo++;
              }
            }
          }
          return charNo >= 3;
        })
        .required('Please enter a password.'),
      confNewPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Password must match.')
        .required('Please enter a password.'),
    }),

    handleSubmit: values => {
      let changePass = {
        email: user.email,
        existingPassword: values.password,
        candidatePassword: values.newPassword,
      };
      changeAuthValue('showChangeYourPassword', false);
      changeYourPassword(changePass);
    },
  })(ChangeYourPasswordForm);

  return (
    <Modal isOpen={isOpen} className={className}>
      <ModalHeader
        toggle={() => {
          changeAuthValue('showChangeYourPassword', false);
        }}
      >
        Change Your Password
      </ModalHeader>
      <ChangeYourPasswordFormFormik />
    </Modal>
  );
};

ChangeYourPassword.propTypes = {
  message: PropTypes.object,
  messageType: PropTypes.string,
  removeNotification: PropTypes.func,
  className: PropTypes.string,
};

export default ChangeYourPassword;
