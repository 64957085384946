import React from 'react';
import PropTypes from 'prop-types';
import { Button, FormGroup } from 'reactstrap';

const CustomButton = ({
  type,
  className,
  clickHandler,
  href,
  title,
  disabled,
  color,
  id,
  style,
  children,
  outline,
}) => {
  const btn = (
    <Button
      type={type}
      color={color ? color : 'primary'}
      size="lg"
      block
      className={className}
      onClick={clickHandler}
      href={href}
      disabled={disabled}
      id={id}
      style={style}
      outline={outline}
    >
      {children ? children : title}
    </Button>
  );

  return type === 'submit' ? <FormGroup>{btn}</FormGroup> : btn;
};

CustomButton.propTypes = {
  type: PropTypes.string,
  href: PropTypes.string,
};

export default CustomButton;
