const selectedFinancierEnum = {
  MICROF: {
    value: 'Microf',
    name: 'Microf',
    id: 1,
    option: 'lease purchase option',
    applyFor: 'applyForMicrofProgram',
  },
  AQUA_FINANCE: {
    value: 'Aqua Finance',
    name: 'Aqua Finance',
    id: 4,
    option: 'Aqua Finance financing option',
    applyFor: 'applyForAquaProgram',
  },
  WELLS_FARGO: {
    value: 'Wells Fargo',
    name: 'Wells Fargo',
    id: 5,
    option: 'Wells Fargo financing option',
  },
  POWER_PAY: {
    value: 'PowerPay',
    name: 'PowerPay',
    id: 6,
    option: 'PowerPay financing option',
    applyFor: 'applyForPowerPayProgram',
  },
  RHEEM: { value: 'Rheem', name: 'Rheem', id: 7, option: 'Rheem financing option' },
  MOSAIC: {
    value: 'Mosaic',
    name: 'Mosaic',
    id: 8,
    option: 'Mosaic financing option',
    applyFor: 'applyForMosaicProgram',
  },
  GREENSKY: {
    value: 'Greensky',
    name: 'GreenSky',
    id: 9,
    option: 'Greensky financing option',
    applyFor: 'applyForGreenSkyProgram',
  },
  FOUNDATION: {
    value: 'Foundation',
    name: 'Foundation',
    id: 10,
    option: 'Foundation financing option',
    applyFor: 'applyForFoundationProgram',
  },
  ENERBANK: {
    value: 'EnerBank',
    name: 'EnerBank',
    id: 11,
    option: 'EnerBank financing option',
    applyFor: 'applyForEnerBankProgram',
  },
  FTL: {
    value: 'FTL',
    name: 'FTL',
    id: 12,
    option: 'FTL financing option',
    applyFor: 'applyForFTLProgram',
  },
  UPGRADE: {
    value: 'Upgrade',
    name: 'Upgrade',
    id: 14,
    option: 'UPGRADE financing option',
    applyFor: 'applyForUpgradeProgram',
  },
};

export default selectedFinancierEnum;

export const getSelectedFinancierByValue = value => {
  for (let key in selectedFinancierEnum) {
    if (selectedFinancierEnum[key].value === value) {
      return selectedFinancierEnum[key];
    }
  }
  return null;
};

export const getSelectedFinancierById = id => {
  for (let key in selectedFinancierEnum) {
    if (selectedFinancierEnum[key].id === id) {
      return selectedFinancierEnum[key];
    }
  }
  return null;
};
