import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row, Alert } from 'reactstrap';
import * as Yup from 'yup';
import BackBtn from '../../components/UI/BackButton/BackButton';
import Button from '../../components/UI/Button/CustomButton';
import Input from '../../components/UI/Form/Input/CustomInput';
import { isNoreplyEmail, reactGAEvent, reactGAPageview } from '../../helper';
import {
  changePage,
  changePreQualInfoValue,
  isEmailUnique,
  changeTheme,
} from '../../redux/actions/index';
import { getDealerPortalURL, getDealerPortalName, getIsRheem } from '../../config/settings';
import { MicrofFlowPageNumber } from './MicrofFlow';
import ThemeEnum from '../../assets/js/ThemeEnum';
import { EMAIL_CONFIRMATION_TOOLTIP_TEXT, EMAIL_TOOLTIP_TEXT } from '../../constants/tooltipTexts';

const isRheem = getIsRheem();

const AccountEmail = props => {
  const {
    email,
    isEmailUniqueVal,
    dispatchChangePage,
    dispatchChangeValue,
    dispatchIsEmailUnique,
    dealerUserId,
    dealerId,
    children,
    did,
    isConsumer,
    dealerName,
    isReview,
    lead_source,
    lp_url,
    click_id,
    track_id,
    aquaFinanceAssigned,
    consumerId,
    utmVariables,
    dispatchChangeTheme,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_register',
      trackerNames: [],
      title: 'ca_register',
      did,
      dealerUserId,
    });

    if (isRheem) {
      dispatchChangeTheme(ThemeEnum.MICROF.value);
    }
    // eslint-disable-next-line
  }, []);

  const getPreviosPage = () =>
    isReview ? MicrofFlowPageNumber.Review : MicrofFlowPageNumber.Landing;

  const AccountEmailForm = () => (
    <Form>
      <Field
        readOnly={consumerId}
        component={Input}
        label={!!dealerUserId && did === null ? 'Customer Email Address' : 'Email Address'}
        name="email"
        id="email"
        tooltipText={EMAIL_TOOLTIP_TEXT}
        type="email"
      />
      <Field
        readOnly={consumerId}
        component={Input}
        label={
          !!dealerUserId && did === null
            ? 'Confirm Customer Email Address'
            : 'Confirm Email Address'
        }
        name="confirmEmail"
        id="confirmEmail"
        type="email"
        tooltipText={EMAIL_CONFIRMATION_TOOLTIP_TEXT}
      />
      <Button type="submit" title="Continue" />
    </Form>
  );

  const AccountEmailFormFormik = withFormik({
    mapPropsToValues: () => ({
      email: email || '',
      confirmEmail: email || '',
    }),

    validationSchema: Yup.object({
      email: Yup.string()
        .email('Please enter a valid email.')
        .required('Please enter an email.')
        .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),
      confirmEmail: Yup.string()
        .oneOf([Yup.ref('email'), null], 'Emails must match.')
        .required('Please enter an email.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Continue',
        did,
        dealerUserId,
      });
      dispatchChangeValue('email', values.email);
      if (isConsumer && isEmailUniqueVal && values.email === email) {
        dispatchChangePage(MicrofFlowPageNumber.AccountPassword);
      } else if (isConsumer || did) {
        dispatchIsEmailUnique(values.email, MicrofFlowPageNumber.AccountPassword, {
          email: values.email,
          lastPage: '4001',
          dealerUserId: dealerUserId,
          did: did,
          gacId: lead_source,
          lpUrl: lp_url,
          lpReferrer: document.referrer,
          clickId: click_id,
          track_id: track_id,
          utmVariables: utmVariables,
        });
      } else {
        dispatchChangePage(MicrofFlowPageNumber.UserInfo);
      }
    },
  })(AccountEmailForm);

  return (
    <Container>
      <Row>
        {!isRheem || (dealerId === null && !isConsumer) ? (
          <BackBtn changePage={dispatchChangePage} page={getPreviosPage()} />
        ) : (
          <Col sm="1" />
        )}
        <Col sm="5">
          {dealerName ? <h4 className="mb-3">{dealerName}</h4> : null}
          {children}
          {isConsumer ? (
            <p>
              You’ll use this email to log in after completing your application or if you have to
              finish the application later.
            </p>
          ) : null}
          {aquaFinanceAssigned || isRheem ? (
            <p>
              <b>IMPORTANT!</b> The applicant must be the homeowner.
            </p>
          ) : null}
        </Col>
        <Col sm="6">
          {isEmailUniqueVal != null && !isEmailUniqueVal ? (
            <Alert color="danger">
              Your email address already exists on our system. Please log in using{' '}
              <a href={getDealerPortalURL()}>{getDealerPortalName()}</a> and complete the
              application that is already in progress.
            </Alert>
          ) : null}
          <AccountEmailFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  aquaFinanceAssigned: state.auth.aquaFinanceAssigned,
  isReview: state.microfInformation.isReview,
  email: state.preQualificationInformation.email,
  isEmailUniqueVal: state.preQualificationInformation.isEmailUnique,
  dealerUserId: state.auth.dealerUserId,
  dealerId: state.auth.dealerId,
  did: state.auth.did,
  isConsumer: state.preQualificationInformation.isConsumer,
  dealerName: state.auth.dealerName,
  lead_source: state.auth.lead_source,
  lp_url: state.auth.lp_url,
  consumerId: state.auth.consumerId,
  click_id: state.auth.click_id,
  track_id: state.auth.track_id,
  utmVariables: state.auth.utmVariables,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) => dispatch(changePreQualInfoValue(key, value)),
  dispatchIsEmailUnique: (email, page, submitMindfireParams) =>
    dispatch(isEmailUnique(email, page, submitMindfireParams)),
  dispatchChangeTheme: theme => dispatch(changeTheme(theme)),
});

AccountEmail.propTypes = {
  email: PropTypes.string,
  isEmailUniqueVal: PropTypes.bool,
  dealerUserId: PropTypes.number,
  dealerId: PropTypes.number,
  did: PropTypes.string,
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
  dispatchIsEmailUnique: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountEmail);
