export const PrimaryBusinessCategories = {
  Appliances: { value: 'Appliances', name: 'Appliances' },
  BilliardsAndGameroom: { value: 'Billiards and Gameroom', name: 'Billiards and Gameroom' },
  ConsumerElectronics: { value: 'Consumer Electronics', name: 'Consumer Electronics' },
  FitnessCyclingSportingEquipment: {
    value: 'Fitness / Cycling / Sporting Equipment',
    name: 'Fitness / Cycling / Sporting Equipment',
  },
  GeneralMerchandiseRetailer: {
    value: 'General Merchandise Retailer',
    name: 'General Merchandise Retailer',
  },
  HomeFurnishingsAndHousewares: {
    value: 'Home Furnishings and Housewares',
    name: 'Home Furnishings and Housewares',
  },
  HomeImprovementAndTools: {
    value: 'Home Improvement and Tools',
    name: 'AHome Improvement and Toolsppliances',
  },
  PremiumFurnitureAndMattresses: {
    value: 'Premium Furniture and Mattresses',
    name: 'Premium Furniture and Mattresses',
  },
  SpasAndSaunas: { value: 'Spas and Saunas', name: 'Spas and Saunas' },
  CarportsPortableBuildings: {
    value: 'Carports/Portable Buildings',
    name: 'Carports/Portable Buildings',
  },
  Construction: { value: 'Construction', name: 'Construction' },
  HVAC: { value: 'HVAC', name: 'HVAC' },
  Plumbing: { value: 'Plumbing', name: 'Plumbing' },
  Pools: { value: 'Pools', name: 'Pools' },
  Remodeling: { value: 'Remodeling', name: 'Remodeling' },
  Roofing: { value: 'Roofing', name: 'Roofing' },
  Solar: { value: 'Solar', name: 'Solar' },
  WaterTreatment: { value: 'Water Treatment', name: 'Water Treatment' },
  WindowsAndDoors: { value: 'Windows and Doors', name: 'Windows and Doors' },
  Other: { value: 'Other', name: 'Other' },
};

export const OtherBusinessCategories = {
  Appliances: { value: 'Appliances', name: 'Appliances' },
  BilliardsAndGameroom: { value: 'Billiards and Gameroom', name: 'Billiards and Gameroom' },
  ConsumerElectronics: { value: 'Consumer Electronics', name: 'Consumer Electronics' },
  FitnessCyclingSportingEquipment: {
    value: 'Fitness / Cycling / Sporting Equipment',
    name: 'Fitness / Cycling / Sporting Equipment',
  },
  GeneralMerchandiseRetailer: {
    value: 'General Merchandise Retailer',
    name: 'General Merchandise Retailer',
  },
  HomeFurnishingsAndHousewares: {
    value: 'Home Furnishings and Housewares',
    name: 'Home Furnishings and Housewares',
  },
  HomeImprovementAndTools: {
    value: 'Home Improvement and Tools',
    name: 'AHome Improvement and Toolsppliances',
  },
  PremiumFurnitureAndMattresses: {
    value: 'Premium Furniture and Mattresses',
    name: 'Premium Furniture and Mattresses',
  },
  SpasAndSaunas: { value: 'Spas and Saunas', name: 'Spas and Saunas' },
  CarportsPortableBuildings: {
    value: 'Carports/Portable Buildings',
    name: 'Carports/Portable Buildings',
  },
  Construction: { value: 'Construction', name: 'Construction' },
  HVAC: { value: 'HVAC', name: 'HVAC' },
  Plumbing: { value: 'Plumbing', name: 'Plumbing' },
  Pools: { value: 'Pools', name: 'Pools' },
  Remodeling: { value: 'Remodeling', name: 'Remodeling' },
  Roofing: { value: 'Roofing', name: 'Roofing' },
  Solar: { value: 'Solar', name: 'Solar' },
  WaterTreatment: { value: 'Water Treatment', name: 'Water Treatment' },
  WindowsAndDoors: { value: 'Windows and Doors', name: 'Windows and Doors' },
  Other: { value: 'Other', name: 'Other' },
};

export const HowDidYouHearAboutGreenSkyCategories = {
  Mail: { value: 'Mail', name: 'Mail' },
  Email: { value: 'Email', name: 'Email' },
  Phone: { value: 'Phone', name: 'Phone' },
  Other: { value: 'Other', name: 'Other' },
};

export const BusinessStructureCategories = {
  SoleProprietorship: { value: 'Sole Proprietorship', name: 'Sole Proprietorship' },
  PrivatelyHeldCorp: { value: 'Privately Held Corp', name: 'Privately Held Corp' },
  PubliclyTradedCorp: { value: 'Publicly Traded Corp', name: 'Publicly Traded Corp' },
  GeneralPartnership: { value: 'General Partnership', name: 'General Partnership' },
  LimitedPartnership: { value: 'Limited Partnership', name: 'Limited Partnership' },
  LimitedLiabilityPartnership: {
    value: 'Limited liability Partnership',
    name: 'Limited liability Partnership',
  },
  LimitedLiabilityLimitedPartnership: {
    value: 'Limited liability limited Partnership',
    name: 'Limited liability limited Partnership',
  },
  LimitedLiabilityCompany: {
    value: 'Limited liability Company',
    name: 'Limited liability Company',
  },
  Other: { value: 'Other', name: 'Other' },
};
