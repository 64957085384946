import { Field, Form, withFormik } from 'formik';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Col, Container, FormGroup, Label, Row } from 'reactstrap';
import hvacSystemEnum from '../../assets/js/HvacSystemEnum';
import hvacTonnageEnum from '../../assets/js/HvacTonnageEnum';
import BackButton from '../../components/UI/BackButton/BackButton';
import Select from '../../components/UI/Form/Select/Select';
import Button from '../../components/UI/Button/CustomButton';
import * as Yup from 'yup';
import CustomDatePicker from '../../components/UI/Form/DatePicker/DatePicker';
import {
  APPMONTHLYNETINCOME_TOOLTIP_TEXT,
  HVACSYSTEM_TOOLTIP_TEXT,
  HVACTONNAGE_TOOLTIP_TEXT,
  INSTALLMENTDATE_TOOLTIP_TEXT,
  REQUESTEDLOANAMOUNT_TOOLTIP_TEXT,
  SECONDHVACSYSTEM_TOOLTIP_TEXT,
  SECONDHVACTONNAGE_TOOLTIP_TEXT,
  TOTALPRICE_TOOLTIP_TEXT,
} from '../../constants/tooltipTexts';
import { changeMicrofInfoValue, changePage, changePreQualInfoValue } from '../../redux/actions';
import { MicrofFlowPageNumber } from './MicrofFlow';
import CurrencyFormat from '../../components/UI/Form/NumberFormat/CurrencyFormat';

const EditSystemType = props => {
  const {
    children,
    dispatchChangePage,
    dispatchChangeInfoValue,
    secondHvacSystem,
    isFTL,
    totalPrice,
    requestedLoanAmount,
    hvacSystem,
    hvacTonnage,
    installmentDate,
    secondHvacTonnage,
  } = props;

  const [hasSecondHVAC, setHasSecondHVAC] = useState(!!secondHvacSystem);

  const setValuesToState = values => {
    dispatchChangeInfoValue('hvacSystem', values.hvacSystem);
    dispatchChangeInfoValue('hvacTonnage', values.hvacTonnage);
    dispatchChangeInfoValue('installmentDate', values.installmentDate);
    dispatchChangeInfoValue('secondHvacSystem', hasSecondHVAC ? values.secondHvacSystem : null);
    dispatchChangeInfoValue('secondHvacTonnage', hasSecondHVAC ? values.secondHvacTonnage : null);
    dispatchChangeInfoValue('totalPrice', values.totalPrice);
    dispatchChangeInfoValue('requestedLoanAmount', values.requestedLoanAmount);
  };

  const EditSystemTypeForm = ({ values }) => {
    return (
      <Form noValidate>
        {!isFTL && (
          <>
            <Field
              component={Select}
              label="HVAC System Type"
              name="hvacSystem"
              id="hvacSystem"
              tooltipText={HVACSYSTEM_TOOLTIP_TEXT}
              type="select"
              selectValues={hvacSystemEnum}
              selectFirstName="Select One"
            />
            <Field
              component={Select}
              label="Estimated HVAC System Size"
              name="hvacTonnage"
              id="hvacTonnage"
              tooltipText={HVACTONNAGE_TOOLTIP_TEXT}
              type="select"
              selectValues={hvacTonnageEnum}
              selectFirstName="Select One"
            />
          </>
        )}
        <Field
          component={CustomDatePicker}
          name="installmentDate"
          id="installmentDate"
          tooltipText={INSTALLMENTDATE_TOOLTIP_TEXT}
          label="Estimated installation Date"
        />
        <Field
          component={CurrencyFormat}
          label="Total Job Price"
          name="totalPrice"
          id="totalPrice"
          tooltipText={TOTALPRICE_TOOLTIP_TEXT}
          placeholder="$0.00"
          type="text"
        />
        <Field
          component={CurrencyFormat}
          label="Requested Lease Amount"
          name="requestedLoanAmount"
          id="requestedLoanAmount"
          tooltipText={REQUESTEDLOANAMOUNT_TOOLTIP_TEXT}
          placeholder="$0.00"
          type="text"
        />
        {!isFTL && (
          <>
            <FormGroup>
              <Row>
                <Col>
                  <Label>
                    <b>Is there a second system being installed?</b>
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    className={hasSecondHVAC ? '' : 'inactive'}
                    type="button"
                    title="Yes"
                    clickHandler={() => {
                      setHasSecondHVAC(true);
                      setValuesToState(values);
                    }}
                  />
                </Col>
                <Col>
                  <Button
                    className={!hasSecondHVAC ? '' : 'inactive'}
                    type="button"
                    title="No"
                    clickHandler={() => {
                      setHasSecondHVAC(false);
                      setValuesToState(values);
                    }}
                  />
                </Col>
              </Row>
            </FormGroup>
            <Field
              component={Select}
              label="Second HVAC System Type"
              name="secondHvacSystem"
              id="secondHvacSystem"
              tooltipText={SECONDHVACSYSTEM_TOOLTIP_TEXT}
              type="select"
              selectValues={hvacSystemEnum}
              selectFirstName="Select One"
              className={hasSecondHVAC ? '' : 'hidden'}
            />
            <Field
              component={Select}
              label="Second HVAC Estimated System Size"
              name="secondHvacTonnage"
              id="secondHvacTonnage"
              tooltipText={SECONDHVACTONNAGE_TOOLTIP_TEXT}
              type="select"
              selectValues={hvacTonnageEnum}
              selectFirstName="Select One"
              className={hasSecondHVAC ? '' : 'hidden'}
            />
          </>
        )}

        <Button type="submit" title={'Next'} />
      </Form>
    );
  };

  const EditSystemTypeFormFormik = withFormik({
    mapPropsToValues: () => ({
      hvacSystem: hvacSystem || '',
      hvacTonnage: hvacTonnage || '',
      installmentDate: installmentDate || '',
      secondHvacSystem: secondHvacSystem || '',
      secondHvacTonnage: secondHvacTonnage || '',
      totalPrice: totalPrice || '',
      requestedLoanAmount: requestedLoanAmount || '',
    }),
    validationSchema: Yup.object({
      hvacSystem: !isFTL
        ? Yup.string().required('Please select the HVAC System Type.')
        : Yup.string(),
      hvacTonnage: !isFTL
        ? Yup.string().required('Please select the Estimated HVAC System Size.')
        : Yup.string(),
      installmentDate: Yup.string()
        .required('Please enter the estimated installment date.')
        .test(
          'minDate',
          'Invalid date.',
          val => val && new Date(val) >= new Date().setHours(0, 0, 0, 0),
        ),
      secondHvacSystem:
        !isFTL && hasSecondHVAC
          ? Yup.string().required('Please select the Second HVAC System Type.')
          : Yup.string(),
      secondHvacTonnage:
        !isFTL && hasSecondHVAC
          ? Yup.string().required('Please select the Second HVAC Estimated System Size.')
          : Yup.string(),

      totalPrice: Yup.number()
        .required('Please enter the Total price.')
        .test('min3000', 'Total price should be greater than $3000.', val => {
          return val >= 3000;
        })
        .test('max15000', 'Amount should be lower than 15,000.', val => {
          return val <= 15000;
        }),

      requestedLoanAmount: Yup.number()
        .required('Please enter an amount.')
        .test({
          name: 'lowerThan',
          exclusive: false,
          message: 'Amount cannot be higher than Total price.',
          test: function(value) {
            return value <= this.resolve(Yup.ref('totalPrice'));
          },
        })
        .test({
          name: 'lowerThan',
          exclusive: false,
          message: 'Amount must be greater than 60% of the Total price.',
          test: function(value) {
            return value >= this.resolve(Yup.ref('totalPrice')) * 0.6;
          },
        }),
    }),
    handleSubmit: values => {
      setValuesToState(values);

      dispatchChangePage(MicrofFlowPageNumber.Review);
    },
  })(EditSystemTypeForm);

  return (
    <Container>
      <Row>
        <BackButton changePage={dispatchChangePage} page={MicrofFlowPageNumber.Review} />

        <Col sm="5">{children}</Col>
        <Col sm="6">
          <EditSystemTypeFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  hvacSystem: state.microfInformation.hvacSystem,
  hvacTonnage: state.microfInformation.hvacTonnage,
  installmentDate: state.microfInformation.installmentDate,
  secondHvacSystem: state.microfInformation.secondHvacSystem,
  secondHvacTonnage: state.microfInformation.secondHvacTonnage,
  isFTL: state.microfInformation.isFTL,
  totalPrice: state.microfInformation.microfOnly
    ? state.microfInformation.borrowedAmount ||
      state.microfInformation.totalPrice ||
      (!state.auth.dealerId && 6000) ||
      0
    : state.microfInformation.totalPrice || 0,
  requestedLoanAmount:
    state.microfInformation.requestedLoanAmount ||
    state.powerPayInformation.borrowedAmount ||
    state.wellsFargoInformation.borrowedAmount ||
    state.preQualificationInformation.borrowedAmount ||
    0,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangeInfoValue: (key, value) => dispatch(changeMicrofInfoValue(key, value)),
  dispatchChangePreQualInfoValue: (key, value) => dispatch(changePreQualInfoValue(key, value)),
  dispatchChangePage: page => dispatch(changePage(page)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditSystemType);
