import { Field, Form, withFormik } from 'formik';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { GoSearch } from 'react-icons/go';
import { connect } from 'react-redux';
import { Button, Col, Label, Row } from 'reactstrap';
import usStates from '../../../assets/js/USStateEnum';
import InputField from '../../../components/UI/Form/Input/InputField';
import SelectField from '../../../components/UI/Form/Select/SelectField';
import { changeSoftPullValue } from '../../../redux/actions/index';

const SearchApplicationCriteria = props => {
  const {
    searchContractors,
    searchOptionContractors,
    dispatchChangeSoftPullValue,
    application,
  } = props;
  const [show, setShow] = useState(true);
  const search = queryString.parse(window.location.search).search;

  useEffect(() => {
    if (search) {
      searchContractors(searchOptionContractors);
    }
    // eslint-disable-next-line
  }, []);

  const SearchApplicationCriteriaForm = ({ values }) => (
    <div className="search-panel">
      <Form>
        <Row>
          <Col sm="6">
            <div className="form-group">
              <Label for="dealerName">Contractor Name</Label>
              <Field component={InputField} name="dealerName" id="dealerName" type="text" />
            </div>
          </Col>
          <Col sm="6">
            <div className="form-group">
              <Label for="state">State</Label>
              <Field
                component={SelectField}
                name="state"
                id="state"
                type="select"
                selectValues={usStates}
                selectFirstName=" "
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="text-right">
            <Button type="reset" color="secondary" className="rounded-pill" outline>
              Clear
            </Button>
            <Button type="submit" color="primary" className="rounded-pill">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );

  const SearchApplicationCriteriaFormFormik = withFormik({
    mapPropsToValues: () => ({
      dealerName: searchOptionContractors.dealerName,
      state: searchOptionContractors.state,
    }),

    handleSubmit: values => {
      const searchOptions = {
        ...searchOptionContractors,
        dealerName: values.dealerName,
        state: values.state,
        multipleCreditors: application.selectedFinancier === 'Microf' ? 0 : 1,
        zipcode: undefined,
      };

      dispatchChangeSoftPullValue('searchOptionContractors', searchOptions);
      searchContractors(searchOptions);
    },
  })(SearchApplicationCriteriaForm);

  return (
    <div className="search-form">
      <div className="search-btn">
        <span onClick={() => setShow(!show)}>
          <GoSearch size={18} />
          <span>Search Criteria</span>
        </span>
      </div>
      {show ? <SearchApplicationCriteriaFormFormik /> : null}
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangeSoftPullValue: (key, value) => dispatch(changeSoftPullValue(key, value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchApplicationCriteria);
