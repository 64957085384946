import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import CustomButton from '../../../components/UI/Button/CustomButton';

const DeactivatedDealer = ({ history, dealerStatsSummary }) => {
  return (
    <Container>
      <Row className="justify-content-center mt-2">
        <Col className="text-center" xs="auto" lg="12" sm="12">
          <p className="fs-22 fw-500">
            Thank you for choosing OPTIMUS! We are unable to complete your request at this time.
          </p>
          <p className="fs-22 fw-500">
            Please contact the OPTIMUS Team at 833-678-1687 for assistance.
          </p>
        </Col>
      </Row>
      <Row className="mt-3 justify-content-center">
        <CustomButton
          type="submit"
          title="Back to Dashboard"
          className="w-300px margin-center"
          color="primary"
          clickHandler={() => {
            history.push('/portal/dashboard');
          }}
        >
          Back to Dashboard
        </CustomButton>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  dealerStatsSummary:
    state.auth.user && state.auth.user.user ? state.auth.user.user.dealerStatsSummaryDTO : null,
});

const mapDispatchToProps = dispatch => ({});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DeactivatedDealer),
);
