import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ConfirmDelete from '../../../components/UI/Modal/ConfirmDelete';
import { deleteUser, updateUser, changePassword } from '../../../redux/actions';
import ContractorUserEditView from './ContractorUserEditView';
import ContractorUserView from './ContractorUserView';
import ContractorUserChangePassword from './ContractorUserChangePassword';

const NewContractorUser = props => {
  const {
    auth,
    history,
    selectedContractorUser,
    dispatchDeleteUser,
    dispatchUpdateUser,
    dispatchChangePassword,
  } = props;

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [changePass, setChangePass] = useState(false);

  useEffect(() => {
    if (!auth.user || !auth.user.dealerUser) {
      history.push('/login');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {changePass ? (
        <ContractorUserChangePassword
          history={history}
          selectedContractorUser={selectedContractorUser}
          setChangePass={setChangePass}
          changePassword={dispatchChangePassword}
        />
      ) : !isEditMode ? (
        <ContractorUserView
          history={history}
          selectedContractorUser={selectedContractorUser}
          setIsEditMode={setIsEditMode}
          setConfirmDelete={setConfirmDelete}
          setChangePass={setChangePass}
          currentUser={auth.user.dealerUser}
        />
      ) : (
        <ContractorUserEditView
          history={history}
          selectedContractorUser={selectedContractorUser}
          setIsEditMode={setIsEditMode}
          updateUser={dispatchUpdateUser}
        />
      )}
      <ConfirmDelete
        headerText={'Delete contractor user?'}
        bodyText={'Are you sure you want to delete this contractor user?'}
        confirmDelete={confirmDelete}
        cancelButtonText={'No, Back to View User'}
        confirmButtonText={'Yes, Delete Contractor User'}
        cancelEventHandler={() => setConfirmDelete(false)}
        confirmEventHandler={() => {
          setConfirmDelete(false);
          dispatchDeleteUser(selectedContractorUser.id, history, '/portal/dashboard');
        }}
      />
    </>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  selectedContractorUser: state.softPull.selectedContractorUser,
});

const mapDispatchToProps = dispatch => ({
  dispatchDeleteUser: (id, history, url) => dispatch(deleteUser(id, history, url)),
  dispatchUpdateUser: (data, setIsEditMode) => dispatch(updateUser(data, setIsEditMode)),
  dispatchChangePassword: (data, setChangePass) => dispatch(changePassword(data, setChangePass)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(NewContractorUser),
);
