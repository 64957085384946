import React from 'react';
import DealershipInformation from './DealershipInformation';
import BusinessManager from './BusinessManager';
import BeneficialOwnerInformation from './BeneficialOwnerInformation';

const Flow = [<DealershipInformation />, <BusinessManager />, <BeneficialOwnerInformation />];

export default Flow;

export const NewLanderContractorEnrollmentFlowPageNumber = {
  DealershipInformation: 0,
  BusinessManager: 1,
  BeneficialOwnerInformation: 2,
};
