import axios from 'axios';
import { getBaseURL, getConsumerUrl, getOrbitUrl, getDealerPortalURL } from '../../config/settings';
import { addNotification, endLoading, startLoading } from './notificationActions';
import { changePage, setFlow } from './naviActions';
import { LOGIN_SUCCESS } from './actionTypes';
import { greenskyContractorEnrollmentFlow } from '../initialState';
import { changeGSContractorEnrollmentInfoValue, resetGreenSkyState } from './greenskyActions';
import { GreenskyContractorEnrollmentFlowPageNumber } from '../../pages/Greensky/ContractorEnrollment/_Flow';
import { checkErrors } from './index';

export const baseURL = getBaseURL();
export const dealerPortalURL = getDealerPortalURL();
export const consumerUrl = getConsumerUrl();
export const orbitUrl = getOrbitUrl();

export const getData = async (
  url,
  params,
  successAction,
  errorAction,
  dispatch,
  successCallback,
  errorCallback,
  page,
) => {
  try {
    const response = await axios.get(url, { params, ...getHeaders() });
    dispatch({ type: successAction, payload: response.data });
    if (successCallback && typeof successCallback === 'function') {
      successCallback(response.data);
    }
    if (!!page && page >= 0 && !!response.data) {
      dispatch(changePage(page));
    }
    dispatch(endLoading());
  } catch (error) {
    dispatch(
      addNotification({
        message: handleApiError(error),
        messageType: 'Error',
      }),
    );
    dispatch({ type: errorAction, payload: error });
    if (errorCallback && typeof errorCallback === 'function') {
      errorCallback(error);
    }
    dispatch(endLoading());
  }
};

export const postData = async (
  url,
  params,
  successAction,
  errorAction,
  dispatch,
  successCallback,
  errorCallback,
  page,
) => {
  try {
    const response = await axios.post(url, params);
    dispatch({ type: successAction, payload: response.data });
    if (dispatch(checkErrors(response))) {
      if (successCallback) {
        dispatch({ type: successCallback, payload: response.data });
      }
      if (!!page && page >= 0) {
        dispatch(changePage(page));
      }
      dispatch(endLoading());
    }
  } catch (error) {
    dispatch(
      addNotification({
        message: handleApiError(error),
        messageType: 'Error',
      }),
    );
    dispatch({ type: errorAction, payload: error });
    if (errorCallback) {
      dispatch({ type: errorCallback, payload: error });
    }
    dispatch(endLoading());
  }
};

export const post2Data = async (
  url,
  params,
  params2,
  successAction,
  success2Action,
  errorAction,
  dispatch,
  successCallback,
  errorCallback,
  page,
  app,
  app2,
) => {
  try {
    const submitUrl = `${baseURL}/api/soft_pull/submit`;
    const resubmitUrlApp =
      app !== null
        ? `${baseURL}/api/soft_pull/resubmit?appToken=${app.consumerToken}&appId=${
            app.applicationId
          }`
        : '';

    const resubmitUrlApp2 =
      app2 !== null
        ? `${baseURL}/api/soft_pull/resubmit?appToken=${app2.consumerToken}&appId=${
            app2.applicationId
          }`
        : '';

    const response = await axios.post(app !== null ? resubmitUrlApp : submitUrl, params);
    dispatch({ type: successAction, payload: response.data });
    if (dispatch(checkErrors(response))) {
      if (successCallback) {
        dispatch({ type: successCallback, payload: response.data });
      }

      const response2 = await axios.post(app2 !== null ? resubmitUrlApp2 : submitUrl, params2);
      dispatch({ type: success2Action, payload: response2.data });
      if (dispatch(checkErrors(response2))) {
        if (successCallback) {
          dispatch({ type: successCallback, payload: response2.data });
        }
        if (!!page && page >= 0) {
          dispatch(changePage(page));
        }
        dispatch(endLoading());
      }
      dispatch(endLoading());
    }
  } catch (error) {
    dispatch(
      addNotification({
        message: handleApiError(error),
        messageType: 'Error',
      }),
    );
    dispatch({ type: errorAction, payload: error });
    if (errorCallback) {
      dispatch({ type: errorCallback, payload: error });
    }
    dispatch(endLoading());
  }
};

export const putData = async (
  url,
  params,
  successAction,
  errorAction,
  dispatch,
  successCallback,
  errorCallback,
  page,
) => {
  try {
    const response = await axios.put(url, params);
    dispatch({ type: successAction, payload: response.data });
    if (dispatch(checkErrors(response))) {
      if (successCallback) {
        dispatch({ type: successCallback, payload: response.data });
      }
      if (!!page && page >= 0) {
        dispatch(changePage(page));
      }
      dispatch(endLoading());
    }
  } catch (error) {
    dispatch(
      addNotification({
        message: handleApiError(error),
        messageType: 'Error',
      }),
    );
    dispatch({ type: errorAction, payload: error });
    if (errorCallback) {
      dispatch({ type: errorCallback, payload: error });
    }
    dispatch(endLoading());
  }
};

export const getHeaders = () => {
  const token = localStorage.getItem('token');
  const headers = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (token) {
    headers.headers['Authorization'] = 'Bearer ' + token;
  }
  return headers;
};

export const handleApiError = error => {
  if (error && error.response) {
    const { response } = error;
    let errors = [];

    if (
      response &&
      response.data &&
      [400, 404, 422].includes(response.status) &&
      Object.values(response.data).length !== 0
    ) {
      if (response.data.message) {
        return response.data.message;
      } else {
        errors = Object.values(response.data).map(err => {
          if (Array.isArray(err)) {
            return err.join(' ');
          } else {
            return err;
          }
        });
      }
    } else if (response.status === 500) {
      return 'Internal Server Error';
    } else if (response.status === 401) {
      if (response.data.message) {
        return response.data.message;
      } else {
        return response.data;
      }
    } else if (response.status === 408) {
      return 'Request timeout';
    } else if (response.data.message) {
      return response.data.message;
    }
    if (!errors.length) {
      return `Error: ${JSON.stringify(response)}`;
    } else {
      return `Error: ${errors.toString()}`;
    }
  } else {
    return error.toString();
  }
};

export const getUser = (userId, guid, history) => async dispatch => {
  dispatch(startLoading('Processing'));
  resetGreenSkyState();
  dispatch(setFlow(greenskyContractorEnrollmentFlow));
  dispatch(changePage(GreenskyContractorEnrollmentFlowPageNumber.DealershipInformation));

  dispatch(changeGSContractorEnrollmentInfoValue('userId', null));
  dispatch(changeGSContractorEnrollmentInfoValue('guid', null));

  await axios
    .get(
      `${baseURL}/api/soft_pull/get_user?userId=${encodeURIComponent(
        userId,
      )}&guid=${encodeURIComponent(guid)}`,
    )
    .then(response => {
      dispatch({ type: LOGIN_SUCCESS, payload: response.data });
      dispatch(changeGSContractorEnrollmentInfoValue('ein', response.data.ein));
      dispatch(changeGSContractorEnrollmentInfoValue('userId', userId));
      dispatch(changeGSContractorEnrollmentInfoValue('guid', guid));

      dispatch(setFlow(greenskyContractorEnrollmentFlow));
      history.push('/CE');
      dispatch(endLoading());
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(endLoading());
    });
};
