import React from 'react';
import { connect } from 'react-redux';
import { Button, Col, Container, Row } from 'reactstrap';
import Section from '../../../../components/Section/Section';
import Header from '../../../../components/UI/Header/Header';
import { changeAuthValue, changePage, resetState, setFlow } from '../../../../redux/actions';
import { greenSkyPreQualificationFlow } from '../../../../redux/initialState';
import { GreenSkyPreQualificationFlowPageNumber } from './PreQualificationFlow';

const InvalidMerchant = props => {
  const {
    history,
    trainingData,
    dispatchChangePage,
    dispatchChangeAuthValue,
    dispatchSetFlow,
    did,
    dispatchResetState,
  } = props;

  const returnToDashboard = () => {
    window.location.href = process.env.REACT_APP_DEALER_PORTAL_URL + '/portal/dashboard';
  };

  const continueToApplication = () => {
    dispatchChangeAuthValue('greenskyAssigned', true);
    dispatchSetFlow(greenSkyPreQualificationFlow);
    dispatchChangePage(GreenSkyPreQualificationFlowPageNumber.AccountEmail);
    history.push('/greensky-prequalification');
  };

  const goToLogin = () => {
    dispatchResetState(props);
    history.push('/login');
  };

  //const text = `<p>If you do not complete your training by 01/01/2022, your ability to submit applications will be limited until you complete your training. <a href="https://www.greensky.com/TBD" target="_blank">Click here</a> to complete your annual refresher training.</p>`;
  const buildInnerHtml = () => ({
    __html: trainingData?.message,
  });
  const myComponent = (
    <Container>
      <Row className="justify-content-center mt-5">
        <Col sm="12" xs="12" md="auto">
          <Row className="p-10 justify-content-center">
            <Col className="text-center">
              {did ? (
                <div className="merchant-message">
                  <p>
                    An application cannot be submitted via weblink at this time. Contact your
                    contractor for an alternative way to apply. (ERROR: Annual TRN)
                  </p>
                </div>
              ) : (
                trainingData?.message && (
                  <div className="merchant-message" dangerouslySetInnerHTML={buildInnerHtml()} />
                )
              )}
            </Col>
          </Row>
          <Row className="p-10 justify-content-center">
            <Col sm="auto" xs="auto" md="auto" lg="auto">
              {trainingData?.trainingAvailable && trainingData?.trainingRequired ? (
                <Button
                  className="w-max-content"
                  type="submit"
                  onClick={() => {
                    did ? goToLogin() : returnToDashboard();
                  }}
                >
                  {did ? `Return to Login` : `Return to Dashboard`}
                </Button>
              ) : (
                <Button
                  className="w-max-content"
                  type="submit"
                  onClick={() => {
                    continueToApplication();
                  }}
                >
                  Continue to Application
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );

  return (
    <>
      <Header />
      <Section Component={myComponent} />
    </>
  );
};

const mapStateToProps = state => ({
  trainingData: state.softPull.trainingData,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchSetFlow: flow => dispatch(setFlow(flow)),
  dispatchChangeAuthValue: (key, value) => dispatch(changeAuthValue(key, value)),
  dispatchResetState: props => dispatch(resetState(props)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvalidMerchant);
