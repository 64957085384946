import React, { useState } from 'react';
import { GoInfo } from 'react-icons/go';
import NumberFormat from 'react-number-format';
import { Col, FormGroup, Label, Row, Tooltip } from 'reactstrap';
import InputFeedback from '../InputFeedback/InputFeedback';

const CurrencyFormat = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched, setFieldValue },
  id,
  labelClassName,
  label,
  propValue,
  disabled,
  placeholder,
  handleChange,
  tooltipText,
  ...props
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <FormGroup>
      <Label for={id} className={labelClassName}>
        <b>{label}</b>
      </Label>
      <Row className="align-items-center">
        <Col>
          <NumberFormat
            className="form-control"
            name={name}
            id={id}
            value={propValue ? propValue : value}
            disabled={disabled}
            thousandSeparator={true}
            decimalScale={2}
            allowNegative={true}
            allowEmptyFormatting={false}
            prefix={'$'}
            type="tel"
            placeholder={placeholder ? placeholder : '$'}
            onValueChange={e => {
              if (handleChange) {
                handleChange(e);
              }
              setFieldValue(name, e.floatValue);
            }}
            onBlur={onBlur}
            fixedDecimalScale
          />
        </Col>
        <Col xs="auto" className="pl-0">
          <div id={`tooltip-${id}`} className="cursor-pointer">
            <GoInfo size={20} />
          </div>
        </Col>
      </Row>
      {touched[name] && <InputFeedback error={errors[name]} />}
      <Tooltip
        placement="right"
        target={`tooltip-${id}`}
        isOpen={tooltipOpen}
        toggle={toggleTooltip}
        autohide={false}
      >
        {tooltipText ? tooltipText : `Please insert '${label}'`}
      </Tooltip>
    </FormGroup>
  );
};

export default CurrencyFormat;
