import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import BackBtn from '../../components/UI/BackButton/BackButton';
import Button from '../../components/UI/Button/CustomButton';
import { reactGAEvent } from '../../helper';
import productTypeEnum from '../../assets/js/ProductTypeEnum';
import { AquaPreQualificationFlowPageNumber } from './preQualificationFlow';

const LoanForRheem = props => {
  const {
    email,
    dispatchChangePage,
    dispatchChangeValue,
    dealerUserId,
    did,
    dispatchSubmitMindfire,
    productType,
    isHomeowner,
    children,
  } = props;
  const [isRequired, setIsRequired] = useState(false);

  const mindfireRequest = {
    email: email,
    lastPage: '6002',
    dealerUserId: dealerUserId,
    did: did,
  };

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={
            !!dealerUserId && did === null
              ? AquaPreQualificationFlowPageNumber.AccountEmail
              : AquaPreQualificationFlowPageNumber.AccountPassword
          }
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <Button
            className={
              'btn btn-primary mt-3 ' +
              (productType === null || productType !== productTypeEnum.HVAC.value ? 'inactive' : '')
            }
            title={productTypeEnum.HVAC.name}
            clickHandler={() => {
              reactGAEvent({
                category: 'Button',
                action: 'Click',
                label: productTypeEnum.HVAC.name,
                did,
                dealerUserId,
              });
              dispatchChangeValue('productType', productTypeEnum.HVAC.value);
            }}
          />
          <p
            className="text-danger mt-2 position-absolute"
            hidden={!(isRequired && productType === null)}
          >
            You must select an option.
          </p>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col />
        <Col sm="5">
          <h4>Are you the homeowner?</h4>
          <p>For mobile or manufactured homes, the applicant must own the land.</p>
        </Col>
        <Col sm="6">
          <Row>
            <Col sm="6">
              <Button
                className={
                  'btn btn-primary mt-3 ' +
                  (isHomeowner === null ? 'inactive' : isHomeowner ? '' : 'inactive')
                }
                title="Yes"
                clickHandler={() => {
                  reactGAEvent({
                    category: 'Button',
                    action: 'Click',
                    label: 'Yes',
                    did,
                    dealerUserId,
                  });
                  dispatchChangeValue('isHomeowner', true);
                }}
              />
            </Col>
            <Col sm="6">
              <Button
                className={
                  'btn btn-primary mt-3 ' +
                  (isHomeowner === null ? 'inactive' : isHomeowner ? 'inactive' : '')
                }
                pClassName="top-20"
                title="No"
                clickHandler={() => {
                  reactGAEvent({
                    category: 'Button',
                    action: 'Click',
                    label: 'No',
                    did,
                    dealerUserId,
                  });
                  dispatchChangeValue('isHomeowner', false);
                }}
              />
            </Col>
          </Row>
          <p className="text-danger mt-2" hidden={!isRequired ? true : isHomeowner}>
            You must be the homeowner to apply.
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm="6" />
        <Col sm="6">
          <Button
            className="mt-3"
            type="button"
            title="Next"
            clickHandler={() => {
              if (isHomeowner && productType !== null) {
                if (isRequired) setIsRequired(false);
                dispatchChangePage(AquaPreQualificationFlowPageNumber.UserInfo);
                dispatchSubmitMindfire(mindfireRequest);
              } else {
                if (!isRequired) setIsRequired(true);
              }
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default LoanForRheem;
