import React, { useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Button from '../../components/UI/Button/CustomButton';
import { reactGAPageview, reactGAEvent } from '../../helper';
import { getDealerPortalURL } from '../../config/settings';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  resetState,
  submitMindfire,
  changeWellsFargoValue,
  submitWellsFargoApplication,
} from '../../redux/actions/index';
import selectedFinancierEnum from '../../assets/js/SelectedFinancierEnum';

const Started = props => {
  const {
    dealerUserId,
    did,
    dispatchSubmitMindfire,
    dispatchResetState,
    app,
    wellsFargoInformation,
    consumerId,
    dispatchChangeWellsFargoValue,
    applicantAddress,
    emulatorId,
    track_id,
    dispatchSubmitWellsFargoApplication,
  } = props;

  const mindfireRequest = {
    email: wellsFargoInformation.email,
    lastPage: '5011',
    dealerUserId: dealerUserId,
    did: did,
  };

  useEffect(() => {
    reactGAPageview({
      path: '/wf_started',
      trackerNames: [],
      title: 'wf_started',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const handleClick = () => {
    dispatchResetState(props);
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Back to login',
      did,
      dealerUserId,
    });
    dispatchSubmitMindfire(mindfireRequest);
    window.location.href = getDealerPortalURL();
  };

  const handleResubmitClick = () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Continue',
      did,
      dealerUserId,
    });

    const request = {
      consumerId: consumerId,
      email: wellsFargoInformation.email || null,
      borrowedAmount:
        wellsFargoInformation.borrowedAmount !== null
          ? Math.floor(wellsFargoInformation.borrowedAmount)
          : null,
      firstName: wellsFargoInformation.firstName || null,
      lastName: wellsFargoInformation.lastName || null,
      middleName: wellsFargoInformation.middleName || null,
      phoneNumber: wellsFargoInformation.phoneNumber || null,
      street: wellsFargoInformation.street || null,
      city: wellsFargoInformation.city || null,
      zipcode: wellsFargoInformation.zipcode || null,
      state: wellsFargoInformation.state || null,
      applicantStreet: applicantAddress ? applicantAddress.street : null,
      applicantCity: applicantAddress ? applicantAddress.city : null,
      applicantZip: applicantAddress ? applicantAddress.zip : null,
      applicantState: applicantAddress ? applicantAddress.state : null,
      airConditioningSystem: wellsFargoInformation.airConditioningSystem,
      isHomeowner: wellsFargoInformation.isHomeowner,
      dealerUserId: dealerUserId || null,
      coapplicantFirstName: wellsFargoInformation.hasCoApp
        ? wellsFargoInformation.coAppFirstName
        : null,
      coapplicantLastName: wellsFargoInformation.hasCoApp
        ? wellsFargoInformation.coAppLastName
        : null,
      coapplicantMiddleName: wellsFargoInformation.hasCoApp
        ? wellsFargoInformation.coAppMiddleInitial
        : null,
      coapplicantEmail: wellsFargoInformation.hasCoApp ? wellsFargoInformation.coAppEmail : null,
      coapplicantStreet: wellsFargoInformation.hasCoApp ? wellsFargoInformation.coAppStreet : null,
      coapplicantCity: wellsFargoInformation.hasCoApp ? wellsFargoInformation.coAppCity : null,
      coapplicantZipcode: wellsFargoInformation.hasCoApp
        ? wellsFargoInformation.coAppZipCode
        : null,
      coapplicantState: wellsFargoInformation.hasCoApp ? wellsFargoInformation.coAppState : null,
      selectedFinancier: selectedFinancierEnum.WELLS_FARGO.value,
      emulatorId: emulatorId,
      track_id: track_id,
      poBox: wellsFargoInformation.poBox || null,
      coAppPoBox: wellsFargoInformation.coAppPoBox ? wellsFargoInformation.coAppPoBox : null,
    };

    dispatchSubmitMindfire(mindfireRequest);

    dispatchSubmitWellsFargoApplication(request, app);

    dispatchChangeWellsFargoValue('submited', true);
  };

  return (
    <Container>
      <Row className="justify-content-center mt-2">
        <Col xs="auto">
          <h5>Application Started</h5>
        </Col>
      </Row>
      <Row className="justify-content-center mt-2">
        <Col xs="auto">
          <h5>
            You can only complete this application during the initial submission from Microf the
            technology platform provider, to Wells Fargo. To complete this application now, you must
            manually log into Wells Fargo portal, find this application and complete it. For this to
            be possible please log out of the Microf application and open the Wells Fargo portal.
          </h5>
        </Col>
      </Row>
      <Row className="justify-content-center mt-4">
        <Col xs="12" sm="8" md="6" lg="4">
          <Button type="button" title="Logout" clickHandler={handleClick} />
        </Col>
      </Row>
      <Row className="justify-content-center mt-4">
        <Col xs="auto">
          <h5>
            If you wish to resubmit the application to Wells Fargo please click the below button
          </h5>
        </Col>
      </Row>
      <Row className="justify-content-center mt-4">
        <Col xs="12" sm="8" md="6" lg="4">
          <Button type="button" title="Resubmit application" clickHandler={handleResubmitClick} />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  app: state.softPull.applicationSubmitDTO,
  consumerId: state.auth.consumerId,
  wellsFargoInformation: state.wellsFargoInformation,
  applicantAddress: state.wellsFargoInformation.applicantAddress,
  emulatorId: state.auth.emulatorId,
  track_id: state.auth.track_id,
});

const mapDispatchToProps = dispatch => ({
  dispatchResetState: props => dispatch(resetState(props)),
  dispatchSubmitWellsFargoApplication: (params, app) =>
    dispatch(submitWellsFargoApplication(params, app)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchChangeWellsFargoValue: (key, value) => dispatch(changeWellsFargoValue(key, value)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Started),
);
