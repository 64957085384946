import React, { useEffect } from 'react';
import { Col, Row, Label, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { completeMicrofWork } from '../../../redux/actions/index';
import queryString from 'query-string';
import { Field, Form, withFormik } from 'formik';
import * as Yup from 'yup';
import InputField from '../../../components/UI/Form/Input/InputField';

const InstallationCertification = props => {
  const { auth, application, dispatchCompleteMicrofWork, history } = props;
  const appId = queryString.parse(window.location.search).appId;

  useEffect(() => {
    if (!auth.user || !auth.user.dealerUser) {
      history.push('/login');
    }
    if (!appId) {
      history.push('/portal/open-applications');
    }
    // eslint-disable-next-line
  }, []);

  const InstallationCertificationForm = ({ values }) => (
    <Form>
      <Row className="mt-5">
        <Col sm="4">
          <Label>Contractor initials</Label>
        </Col>
        <Col sm="4">
          <Field
            component={InputField}
            name="contractorInitials"
            id="contractorInitials"
            maxLength={4}
            type="text"
          />
        </Col>
      </Row>

      <Row className="mt-5">
        <Col className="text-right">
          <Button type="submit" color="primary" className="rounded-pill">
            I Certify
          </Button>
        </Col>
      </Row>
    </Form>
  );

  const InstallationCertificationFormFormik = withFormik({
    mapPropsToValues: () => ({
      contractorInitials: '',
    }),

    validationSchema: Yup.object({
      contractorInitials: Yup.string().required('Please insert contractor initials.'),
    }),

    handleSubmit: values => {
      application.contractorInitials = values.contractorInitials;
      dispatchCompleteMicrofWork(
        application,
        history,
        '/portal/open-applications/funding-has-been-submitted',
      );
    },
  })(InstallationCertificationForm);

  return (
    <Col>
      <Row className="mt-5">
        <div className="panel">
          <div className="panel-body larger">
            <h2 id="panelTitle">Installation Certification</h2>
            <Row>
              <Col>
                By inputting your initials and clicking "I Certify", I, the Contractor, hereby
                certify that I have completed the installation of the herein listed heating and air
                conditioning system (“System”), that the System is functioning properly, that the
                System was new and not previously in service at the time of installation and was
                manufactured within 24 months of the installation date, that the installation and
                all related tasks were performed in a manner that meets or exceeds industry
                standards, and that I am unaware of any issues relating to the System, installation,
                or related tasks other than those which I have already made Microf LLC aware of. The
                System is consistent with that listed in the customer’s Rental Purchase Agreement,
                and is as follows (equipment already entered)
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                By inputting your initials and clicking "I Certify",I further certify that, to the
                extent that I removed or disposed of any equipment that was located at the
                installation address on or prior to the installation date, said removal or disposal
                was performed at the direction of customer and customer was made aware that he/she
                was free to refuse to allow any such removal or disposal.
              </Col>
            </Row>
            <Row>
              <Col className="right-bordered">
                {application ? (
                  <InstallationCertificationFormFormik />
                ) : (
                  <div>No application found.</div>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Row>
    </Col>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  application: state.softPull.application,
});

const mapDispatchToProps = dispatch => ({
  dispatchCompleteMicrofWork: (application, history, url) =>
    dispatch(completeMicrofWork(application, history, url)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InstallationCertification);
