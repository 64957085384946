import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import Button from '../../../components/UI/Button/CustomButton';
import { resetState, resetNewAppState, submitMindfire } from '../../../redux/actions/index';
import { getDealerPortalURL, getIsOptimus } from '../../../config/settings';

const NotAllowed = props => {
  const {
    dispatchResetState,
    dispatchResetNewAppState,
    dispatchSubmitMindfire,
    children,
    user,
    did,
    history,
    pqi,
    dealerUserId,
  } = props;
  const isUserLoggedIn = !did && user && user.dealerUser;
  const buttonTitle = isUserLoggedIn ? 'Return to Dashboard' : 'Return to Login Page';
  const returnUrl = isUserLoggedIn ? '/portal/dashboard' : getDealerPortalURL();
  const isOptimus = getIsOptimus();

  return (
    <Container>
      <Row>
        <Col sm="1" />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          {isOptimus === true ? (
            <p>
              Optimus was unable to approve your credit application at this time. You will receive a
              notification via email within 30 days.
            </p>
          ) : (
            <p>
              We are unfortunately not able to prequalify you for credit with our lending partners.
            </p>
          )}
          <Button
            type="button"
            title={buttonTitle}
            clickHandler={() => {
              if (!isUserLoggedIn) {
                dispatchResetState(props);
                window.location.href = returnUrl;
              } else {
                dispatchResetNewAppState();
                history.push(returnUrl);
              }
              dispatchSubmitMindfire({
                email: pqi.email,
                lastPage: '6007',
                dealerUserId: dealerUserId,
                did: did,
              });
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
  pqi: state.preQualificationInformation,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchResetState: props => dispatch(resetState(props)),
  dispatchResetNewAppState: () => dispatch(resetNewAppState()),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

NotAllowed.propTypes = {
  dispatchResetState: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(NotAllowed),
);
