import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  setFlow,
  getReCapthcaKey,
  changeContractorEnrollmentValue,
  resetGreenSkyState,
  getDistributors,
  getBanks,
} from '../../redux/actions/index';
import Header from '../../components/UI/Header/Header';
import Section from '../../components/Section/Section';
import { contractorEnrollmentFlow } from '../../redux/initialState';
import Flow from './_Flow';

const Aqua = ({
  state,
  history,
  contractorEnrollmentFlowCurrentPage,
  dispatchSetFlow,
  flowName,
  dispatchChangeValue,
  dispatchGetReCapthcaKey,
  dispatchResetGreenSkyState,
  gsi,
  dispatchGetDistributors,
  dispatchGetBanks,
  ...props
}) => {
  useEffect(() => {
    dispatchSetFlow(contractorEnrollmentFlow);
    dispatchGetDistributors();
    dispatchGetBanks();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header
        title={Flow[contractorEnrollmentFlowCurrentPage || 0].props.title}
        pages={Flow.length - 1}
      />
      <Section Component={Flow[contractorEnrollmentFlowCurrentPage || 0]} {...state} />
    </>
  );
};

const mapStateToProps = state => ({
  state,
  contractorEnrollmentFlowCurrentPage: state.navi.contractorEnrollmentFlowCurrentPage,
  flowName: state.navi.flowName,
  gsi: state.greenskyInformation.contractorEnrollment,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetFlow: flow => dispatch(setFlow(flow)),
  dispatchChangeValue: (key, value) => dispatch(changeContractorEnrollmentValue(key, value)),
  dispatchGetReCapthcaKey: () => dispatch(getReCapthcaKey()),
  dispatchResetGreenSkyState: () => dispatch(resetGreenSkyState()),
  dispatchGetDistributors: () => dispatch(getDistributors()),
  dispatchGetBanks: () => dispatch(getBanks()),
});

Aqua.propTypes = {
  contractorEnrollmentFlowCurrentPage: PropTypes.number.isRequired,
  dispatchSetFlow: PropTypes.func,
  score: PropTypes.number,
  flowName: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Aqua);
