import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Section from '../../../components/Section/Section';
import Header from '../../../components/UI/Header/Header';
import { setFlow } from '../../../redux/actions/index';
import {
  aquaFlow,
  microfFlow,
  preQualificationFlow,
  wellsFargoFlow,
  powerPayPreQualificationFlow,
  mosaicPreQualificationFlow,
  mosaicFlow,
} from '../../../redux/initialState';
import MosaicFlow from './MosaicFlow';

const Mosaic = props => {
  const { mosaicFlowCurrentPage, history, flowName } = props;

  useEffect(() => {
    if (flowName === microfFlow) {
      history.push('/RTO');
    } else if (flowName === aquaFlow) {
      history.push('/Aqua');
    } else if (flowName === preQualificationFlow) {
      history.push('/');
    } else if (flowName === wellsFargoFlow) {
      history.push('/WellsFargo');
    } else if (flowName === powerPayPreQualificationFlow) {
      history.push('/powerpay-prequalification');
    } else if (flowName === mosaicPreQualificationFlow) {
      history.push('/mosaic-prequalification');
    } else if (flowName === mosaicFlow) {
      history.push('/mosaic');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header
        title={MosaicFlow[mosaicFlowCurrentPage ? mosaicFlowCurrentPage : 0].props.title}
        pages={MosaicFlow.length - 1}
      />
      <Section Component={MosaicFlow[mosaicFlowCurrentPage ? mosaicFlowCurrentPage : 0]} />
    </>
  );
};

const mapStateToProps = state => ({
  mosaicFlowCurrentPage: state.navi.mosaicFlowCurrentPage,
  flowName: state.navi.flowName,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetFlow: flow => dispatch(setFlow(flow)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Mosaic);
