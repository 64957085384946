import { Field, Form, withFormik } from 'formik';
import queryString from 'query-string';
import * as Yup from 'yup';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import Select from '../../../components/UI/Form/Select/Select';
import {
  getDealerRestrictAquaPromotions,
  updateDealerPromotions,
  changeSoftPullValue,
} from '../../../redux/actions';

const EditRestrictAquaPromotions = props => {
  const {
    dealerRestrictAquaPromotions,
    history,
    dispatchUpdateDealerPromotions,
    dispatchGetDealerRestrictAquaPromotions,
    dispatchChangeSoftPullValue,
  } = props;

  const dealerId = queryString.parse(window.location.search).id;

  useEffect(() => {
    if (dealerId) {
      dispatchGetDealerRestrictAquaPromotions(dealerId);
    } else {
      history.push('/portal/restrict-aqua-promotions');
    }
    // eslint-disable-next-line
  }, []);

  let promotion = null;

  let dealerPromotions = dealerRestrictAquaPromotions.dealerRestrictAquaPromotion;

  const addPromotion = dealerPromotion => {
    let dealerPromotionsTmp = dealerPromotions;
    if (
      !dealerPromotionsTmp.find(
        dealerPromotionTmp => dealerPromotionTmp.restrictAquaPromotionId === 1 * dealerPromotion,
      )
    ) {
      const restrictAquaPromotion =
        dealerRestrictAquaPromotions.aquaPromotions[
          Object.keys(dealerRestrictAquaPromotions.aquaPromotions).find(
            key =>
              dealerRestrictAquaPromotions.aquaPromotions[key].afPromoId === 1 * dealerPromotion,
          )
        ].name;
      dealerPromotionsTmp.push({
        dealerRestrictAquaPromotionId: 0,
        restrictAquaPromotionId: 1 * dealerPromotion,
        restrictAquaPromotion: restrictAquaPromotion,
      });

      dealerPromotions = dealerPromotionsTmp;
      let dealerDto = dealerRestrictAquaPromotions;
      dealerDto = {
        ...dealerDto,
        dealerRestrictAquaPromotion: dealerPromotions,
      };
      dispatchChangeSoftPullValue('dealerRestrictAquaPromotions', dealerDto);
    }
  };

  const removePromotion = index => {
    let dealerPromotionsTmp = dealerPromotions;
    dealerPromotionsTmp.splice(index, 1);
    dealerPromotions = dealerPromotionsTmp;
    let dealerDto = dealerRestrictAquaPromotions;
    dealerDto = {
      ...dealerDto,
      dealerRestrictAquaPromotion: dealerPromotions,
    };
    dispatchChangeSoftPullValue('dealerRestrictAquaPromotions', dealerDto);
  };

  let aquaPromotions = Object.keys(dealerRestrictAquaPromotions.aquaPromotions).map(key => ({
    value: dealerRestrictAquaPromotions.aquaPromotions[key].afPromoId,
    name: dealerRestrictAquaPromotions.aquaPromotions[key].name,
  }));

  const AddPromotionForm = () => (
    <Form>
      <Row className="mt-5">
        <Col sm="4" className="text-right pt-1">
          Promotion:
        </Col>
        <Col sm="4">
          <Field
            className="mb-0"
            labelClassName="d-none"
            component={Select}
            label="Promotion"
            name="promotion"
            id="promotion"
            type="select"
            selectValues={aquaPromotions}
            selectFirstName="Select Promotion"
          />
        </Col>
        <Col sm="4" className="text-left">
          <Button type="submit" color="primary" className="rounded-pill">
            Add Promotion
          </Button>
        </Col>
      </Row>
    </Form>
  );

  const AddPromotionFormFormik = withFormik({
    mapPropsToValues: () => ({
      promotion: promotion || '',
    }),

    validationSchema: Yup.object({
      promotion: Yup.string().required('Please select an promotion.'),
    }),

    handleSubmit: values => {
      addPromotion(values.promotion);
    },
  })(AddPromotionForm);

  return (
    <Col>
      <h2 id="panelTitle">{dealerRestrictAquaPromotions.dealerName}</h2>
      <div className="panel">
        <div className="panel-body larger">
          <Row>
            <Col className="right-bordered">
              {dealerPromotions ? (
                dealerPromotions.map((dealerPromotion, index) => (
                  <Row key={index} className="mt-1">
                    <Col sm="4" className="text-right pt-1">
                      Promotion:
                    </Col>
                    <Col sm="4" className="pt-1">
                      {dealerPromotion.restrictAquaPromotion}
                    </Col>
                    <Col sm="4" className="text-left">
                      <Button
                        type="reset"
                        className="btn-secondary rounded-pill"
                        onClick={() => {
                          removePromotion(index);
                        }}
                      >
                        Remove
                      </Button>
                    </Col>
                  </Row>
                ))
              ) : (
                <>xxx</>
              )}
            </Col>
          </Row>
          <AddPromotionFormFormik />
          <Row className="mt-5">
            <Col className="text-right">
              <Button
                type="reset"
                color="secondary"
                className="rounded-pill"
                outline
                onClick={() => {
                  history.push('/portal/restrict-aqua-promotions');
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                className="rounded-pill"
                onClick={() => {
                  let dealerDto = dealerRestrictAquaPromotions;

                  dealerDto = {
                    ...dealerDto,
                    dealerRestrictAquaPromotion: dealerPromotions,
                  };
                  dispatchUpdateDealerPromotions(dealerDto, history);
                }}
              >
                Submit Promotions
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </Col>
  );
};

const mapStateToProps = state => ({
  dealerRestrictAquaPromotions: state.softPull.dealerRestrictAquaPromotions || {
    dealerName: null,
    dealerRestrictAquaPromotion: [],
    aquaPromotions: [],
  },
});

const mapDispatchToProps = dispatch => ({
  dispatchGetDealerRestrictAquaPromotions: dealerId =>
    dispatch(getDealerRestrictAquaPromotions(dealerId)),
  dispatchUpdateDealerPromotions: (dealerDto, history) =>
    dispatch(updateDealerPromotions(dealerDto, history)),
  dispatchChangeSoftPullValue: (key, value) => dispatch(changeSoftPullValue(key, value)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(EditRestrictAquaPromotions),
);
