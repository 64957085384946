import React from 'react';
import InputFeedback from '../InputFeedback/InputFeedback';
import { Label, FormGroup } from 'reactstrap';

const Checkbox = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched },
  id,
  label,
  className,
  labelClassName,
  disabled,
  clickHandler,
  ...props
}) => (
  <FormGroup>
    <input
      name={name}
      disabled={disabled}
      id={id}
      type="checkbox"
      checked={value}
      onChange={onChange}
      onBlur={onBlur}
      className={'styled-checkbox ' + className}
      onClick={clickHandler}
    />
    <Label for={id} className={labelClassName}>
      <b>{label}</b>
    </Label>
    {touched[name] && <InputFeedback className="no-margin" error={errors[name]} />}
  </FormGroup>
);

export default Checkbox;
