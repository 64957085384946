import React, { useState } from 'react';
import Application from './Application';
import FindApplication from './FindApplication';

const ResendApplicationToDecisionEngine = () => {
  const [pageIndex, setPageIndex] = useState(0);

  const pages = [
    <FindApplication pageIndex={pageIndex} setPageIndex={setPageIndex} />,
    <Application pageIndex={pageIndex} setPageIndex={setPageIndex} />,
  ];

  return pages[pageIndex];
};

export default ResendApplicationToDecisionEngine;
