import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { useVSPixelScript } from '../../../assets/hooks';
import { getApplicationStatus } from '../../../assets/js/ApplicationStatusEnum';
import selectedFinancierEnum from '../../../assets/js/SelectedFinancierEnum';
import CustomButton from '../../../components/UI/Button/CustomButton';
import {
  isStateAllowed,
  nextFinancingOption,
  reactGAEvent,
  reactGAPageview,
} from '../../../helper';
import {
  changePage,
  changePageTitle,
  mapMosaicToMicrof,
  resetState,
  setFlow,
  submitMindfire,
  updateApplicationStatus,
  updateSelectedFinancier,
} from '../../../redux/actions/index';
import { aquaFlow, foundationFlow, microfFlow, powerPayFlow } from '../../../redux/initialState';
import { AquaFlowPageNumber } from '../../Aqua/AquaFlow';
import { MicrofFlowPageNumber } from '../../Microf/MicrofFlow';
import { FoundationFlowPageNumber } from '../../Optimus/Foundation/Flow/FoundationFlow';
import { PowerPayFlowPageNumber } from '../../PowerPay/Flow/PowerPayFlow';

const Declined = props => {
  const {
    user,
    email,
    dealerUserId,
    did,
    dispatchSubmitMindfire,
    dispatchUpdateSelectedFinancier,
    consumerToken,
    history,
    dispatchSetFlow,
    dispatchMapMosaicToMicrof,
    application,
    dispatchChangePage,
    dispatchResetState,
    dispatchUpdateApplicationStatus,
    applicationStatus,
  } = props;

  useVSPixelScript();

  useEffect(() => {
    reactGAPageview({
      path: '/ca_ppdeclined',
      trackerNames: [],
      title: 'ca_ppdeclined',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  let nextFO = nextFinancingOption(application, selectedFinancierEnum.MOSAIC);

  const score = application?.prequalificationInformation?.score;

  const handleClick = async () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: `Continue to ${nextFO.value}`,
      did,
      dealerUserId,
    });
    dispatchSubmitMindfire({
      email: email,
      lastPage: '6023',
      dealerUserId: dealerUserId,
      did: did,
    });

    await dispatchUpdateSelectedFinancier(nextFO.value, consumerToken, () => {
      let flow = microfFlow;
      let page = MicrofFlowPageNumber.AccountEmail;
      let path = '/RTO';
      dispatchMapMosaicToMicrof();

      if (nextFO === selectedFinancierEnum.AQUA_FINANCE) {
        flow = aquaFlow;
        page = AquaFlowPageNumber.AquaStartPage;
        path = '/Aqua';
      } else if (nextFO === selectedFinancierEnum.POWER_PAY) {
        flow = powerPayFlow;
        page = PowerPayFlowPageNumber.Consent;
        path = '/powerpay';
        dispatchChangePage();
      } else if (nextFO === selectedFinancierEnum.FOUNDATION) {
        flow = foundationFlow;
        page = FoundationFlowPageNumber.Consent;
        path = '/foundation';
      }

      dispatchSetFlow(flow);
      dispatchChangePage(page);
      history.push(path);
    });
    await dispatchUpdateApplicationStatus(
      getApplicationStatus(applicationStatus).value,
      consumerToken,
    );
  };

  const handleBackToDashboardExitClick = async () => {
    if (!user?.dealerUser) {
      dispatchResetState({});
    }
    await dispatchUpdateApplicationStatus(
      getApplicationStatus(applicationStatus).value,
      consumerToken,
    );
  };

  const OutOfTradeArea = () => {
    return (
      <Container>
        <Row className="justify-content-center mt-2">
          <Col xs="auto" sm="8" className="text-center">
            <h5>
              <b>
                Mosaic was unable to approve your credit application at this time. You will receive
                a notification via email within 30 days.
              </b>
            </h5>
          </Col>
        </Row>
        <Row className="justify-content-center mt-5">
          <Col xs="10" md="3">
            <Link
              to={user?.dealerUser ? '/portal/dashboard' : ''}
              onClick={() => !user?.dealerUser && dispatchResetState({})}
              style={{ textDecoration: 'none' }}
              className="block"
            >
              <CustomButton
                color="secondary"
                size="lg"
                block
                type="button"
                title={user?.dealerUser ? 'Return to dashboard' : 'Exit'}
              />
            </Link>
          </Col>
        </Row>
      </Container>
    );
  };

  if (
    nextFO === selectedFinancierEnum.MICROF &&
    !isStateAllowed(application?.propertyAddress?.state)
  ) {
    return OutOfTradeArea();
  } else {
    return (
      <Container>
        <Row className="justify-content-center mt-2">
          <Col xs="auto" sm="8" className="text-center">
            <h5>
              <b>Your credit application was not approved at this time.</b>
            </h5>
          </Col>
        </Row>
        {nextFO ? (
          <>
            <Row className="justify-content-center mt-2">
              <Col xs="auto" sm="8" className="text-center">
                <h5>
                  <b>
                    But Wait! You may still qualify for a lease purchase option. A lease purchase
                    option is different than financing.
                  </b>
                </h5>
              </Col>
            </Row>
            {nextFO === selectedFinancierEnum.MICROF && (
              <Container>
                <Row className="justify-content-center mt-2">
                  <Col xs="auto" sm="12" className="text-center">
                    <p className="fs-25">
                      <b className="primary">
                        Congratulations! You've prequalified for a Microf Lease Offer
                      </b>
                    </p>
                  </Col>
                </Row>
                <Row className="justify-content-center ">
                  <Col xs="auto" lg="8" sm="12" md="12">
                    <p className="fs-22 fw-500 pl-40">
                      Microf’s lease purchase option is different than financing:
                    </p>
                    <ul className="powerpay-microf-lease-purchase-options-arrow">
                      <li>
                        Pay as you go!{' '}
                        <span className="fw-normal">
                          Monthly renewals up to 60 months to own in most states.
                        </span>
                      </li>
                      <li>
                        Own the item(s) after paying the total of payments to ownership.{' '}
                        <span className="fw-normal">
                          Substantial savings are available through Early Purchase Options!
                        </span>
                      </li>
                      <li>
                        Damaged credit?{' '}
                        <span className="fw-normal">
                          Microf regularly approves applicants with less than perfect credit.
                        </span>
                      </li>
                      <li>
                        Have questions?{' '}
                        <span className="fw-normal">
                          Call us at 855-642-7631 or email us at info@microf.com.
                        </span>
                      </li>
                    </ul>
                  </Col>
                </Row>
                <Row className="justify-content-center ">
                  <Col xs="auto" lg="8" sm="12" md="12">
                    <p className="fs-22 fw-500 pl-40">
                      Here is what you will need to complete the application:
                    </p>
                    <ul className="microf-lease-purchase-options">
                      <li>Social Security Number</li>
                      <li>Bank account information (routing and account numbers)</li>
                      <li>Income or benefits information (e.g., paystub, bank statement, etc.)</li>
                      <li>Co-applicant information (not required)</li>
                    </ul>
                  </Col>
                </Row>
                <Row className="justify-content-center ">
                  <Col xs="auto" lg="8" sm="12" md="12">
                    <p className="fs-22 fw-500 pl-40">Not eligible for lease purchase:</p>
                    <ul className="powerpay-microf-lease-purchase-options-x">
                      <li>Homes on rented land (e.g., mobile homes in a park)</li>
                      <li>Reverse mortgages</li>
                      <li>Rent-to-own or lease-to-own properties</li>
                      <li>Homeowners with active or dismissed bankruptcies</li>
                    </ul>
                  </Col>
                </Row>
              </Container>
            )}
            <Row
              className={
                nextFO === selectedFinancierEnum.MICROF
                  ? 'justify-content-center'
                  : 'justify-content-center mt-5'
              }
            >
              <Col xs="auto">
                <CustomButton
                  color="primary"
                  clickHandler={handleClick}
                  title={` Continue to ${nextFO.value}`}
                />
              </Col>
            </Row>
          </>
        ) : (
          <Row className="justify-content-center mt-2">
            <Col xs="12" md="2">
              <Link
                to={user && user.dealerUser ? '/portal/dashboard' : ''}
                onClick={handleBackToDashboardExitClick}
                style={{ textDecoration: 'none' }}
                className="block"
              >
                <CustomButton
                  color="secondary"
                  size="lg"
                  block
                  type="button"
                  title={user && user.dealerUser ? 'Back to dashboard' : 'Exit'}
                />
              </Link>
            </Col>
          </Row>
        )}
      </Container>
    );
  }
};

const mapStateToProps = state => ({
  consumerToken:
    state.softPull.applicationSubmitDTO && state.softPull.applicationSubmitDTO.consumerToken,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  user: state.auth.user,
  application: state.softPull.applicationSubmitDTO,
  applicationStatus: state.mosaicInformation.mosaicDecision,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchUpdateSelectedFinancier: (selectedFinancier, applicationId, successCallBack) =>
    dispatch(updateSelectedFinancier(selectedFinancier, applicationId, successCallBack)),
  dispatchSetFlow: flowName => dispatch(setFlow(flowName)),
  dispatchResetState: props => dispatch(resetState(props)),
  dispatchChangePageTitle: value => dispatch(changePageTitle(value)),
  dispatchMapMosaicToMicrof: props => dispatch(mapMosaicToMicrof()),
  dispatchUpdateApplicationStatus: (applicationStatus, consumerToken) =>
    dispatch(updateApplicationStatus(applicationStatus, consumerToken)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Declined),
);
