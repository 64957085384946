const ApplicationCategoryEnum = {
  NEW_OPPORTUNITY: { value: 'NO', name: 'New Oportunity' },
  UNSUBMITTED: { value: 'US', name: 'Unsubmited' },
  UNDER_REVIEW: { value: 'UR', name: 'Under Review' },
  OPEN: { value: 'OP', name: 'Open' },
  MISSED_OPPORTUNITY: { value: 'MO', name: 'Missed Oportunity' },
  COMPLETE: { value: 'CT', name: 'Complete' },
  ADDED_BY_API: { value: 'AA', name: 'Added by API' },
};

export default ApplicationCategoryEnum;
