const FTLProjectTypeEnum = {
  EQUIPMENT_INSTALL: {
    value: 'equipment-install',
    name: 'Equipment Install',
  },
  SERVICE_REPAIR_MATERIALS: {
    value: 'service-repair-materials',
    name: 'Service, Repair, or Materials',
  },
};

export default FTLProjectTypeEnum;
