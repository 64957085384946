const foundationOtherSourceOfIncomeEnum = {
  ALAMONY_TAX_EXEMPT: { value: 'AlimonyTaxExempt', name: 'Alimony-Tax Exempt' },
  CHILD_SUPPORT_TAX_EXEMPT: { value: 'ChildSupportTaxExempt', name: 'Child Support-Tax Exempt' },
  COURT_ORDERED_PAYMENT: { value: 'CourtOrderedPayment', name: 'Court Ordered Payment' },
  DISABILITY: { value: 'Disability', name: 'Disability' },
  INVESTMENT: { value: 'Investment', name: 'Investment' },
  MILITARY_PENSION: { value: 'MilitaryPension', name: 'Military Pension' },
  OTHER: { value: 'Other', name: 'Other' },
  PENSION: { value: 'Pension', name: 'Pension' },
  RENTAL: { value: 'Rental', name: 'Rental' },
  RETIREMENT_INCOME: { value: 'RetirementIncome', name: 'Retirement Income' },
  SECOND_JOB: { value: 'SecondJob', name: 'Second Job' },
  SOCIAL_SECURITY: { value: 'SocialSecurity', name: 'Social Security' },
  UNEMPLOYMENT: { value: 'Unemployment', name: 'Unemployment' },
};

export const findOtherSourceOfIncomeEnumName = value => {
  for (let key in foundationOtherSourceOfIncomeEnum) {
    let source = foundationOtherSourceOfIncomeEnum[key];
    if (source.value === value) {
      return source.name;
    }
  }
  return null;
};

export default foundationOtherSourceOfIncomeEnum;
