import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { createAutoCorrectedDatePipe } from 'text-mask-addons';
import MaskedInput from 'react-text-mask';
import InputFeedback from '../InputFeedback/InputFeedback';
import { isMobile } from 'react-device-detect';
import moment from 'moment';

const dateFormat = 'MM/DD/YYYY';

const generateMaskFromFormat = format => {
  const letterPattern = /[a-zA-Z]/;

  return format.split('').map(key => (letterPattern.test(key) ? /\d/ : key));
};

const DatePickerField = ({
  field: { name, value },
  form: { errors, touched, setFieldValue },
  id,
  label,
  className,
  labelClassName,
  disabled,
  maxDate,
  minDate,
  ...props
}) => {
  const [startDate, setStartDate] = useState();
  const autoCorrectedDatePipe = createAutoCorrectedDatePipe(dateFormat.toLowerCase());

  const handleChange = dateValue => {
    setStartDate(dateValue);

    const formattedDate = moment(dateValue).format(dateFormat);
    setFieldValue(name, formattedDate);
  };

  return (
    <>
      <DatePicker
        customInput={
          <MaskedInput
            mask={generateMaskFromFormat(dateFormat)}
            pipe={autoCorrectedDatePipe}
            guide={false}
          />
        }
        name={name}
        id={id}
        disabled={disabled}
        className={className ? className : 'form-control full'}
        dateFormat="MM/dd/yyyy"
        placeholderText={dateFormat}
        maxDate={new Date()}
        minDate={minDate}
        onChange={handleChange}
        autoComplete="off"
        selected={startDate}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        withPortal={isMobile}
        value={value}
      />
      {touched[name] && <InputFeedback error={errors[name]} />}
    </>
  );
};

export default DatePickerField;
