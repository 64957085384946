import { Field } from 'formik';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Col, Label, Row } from 'reactstrap';
import InputField from '../../../components/UI/Form/Input/CustomInput';
import TextArea from '../../../components/UI/Form/TextArea/TextArea';
import CustomPhoneNumber from '../../../components/UI/Form/PhoneNumber/PhoneNumber';
import CustomDatePicker from '../../../components/UI/Form/DatePicker/DatePicker';
import usStates from '../../../assets/js/USStateEnum';
import {
  CITY_TOOLTIP_TEXT,
  STATE_TOOLTIP_TEXT,
  STREET_TOOLTIP_TEXT,
  SYSTEMDESCRIPTION_TOOLTIP_TEXT,
  SYSTEMMAKE_TOOLTIP_TEXT,
  SYSTEMMODEL_TOOLTIP_TEXT,
  SYSTEMSERIALNUMBER_TOOLTIP_TEXT,
  ZIPCODE_TOOLTIP_TEXT,
} from '../../../constants/tooltipTexts';
import SsnFormat from '../../../components/UI/Form/NumberFormat/SsnFormat';
import Select from '../../../components/UI/Form/Select/Select';

const OwnerInfoRow = ({ index }) => {
  return (
    <>
      <Row>
        <Col sm="6" className="justify-content-center">
          <Label>
            <b>OWNER #{index + 1}</b>
          </Label>
        </Col>
        <Col />
      </Row>
      <Row>
        <Col sm="6" className={isMobile ? 'no-padding' : ''}>
          <Field
            label="First Name *"
            component={InputField}
            name={`firstName${index}`}
            id={`firstName${index}`}
            type="text"
            maxLength={50}
          />
        </Col>
        <Col sm="6" className={isMobile ? 'no-padding' : ''}>
          <Field
            label="Last Name *"
            component={InputField}
            name={`lastName${index}`}
            id={`lastName${index}`}
            type="text"
            maxLength={50}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="6" className={isMobile ? 'no-padding' : ''}>
          <Field
            component={InputField}
            label="Email *"
            name={`email${index}`}
            id={`email${index}`}
            type="email"
          />
        </Col>
        <Col sm="6" className={isMobile ? 'no-padding' : ''}>
          <Field
            component={CustomPhoneNumber}
            label="Mobile Phone *"
            name={`mobilePhone${index}`}
            id={`mobilePhone${index}`}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="6" className={isMobile ? 'no-padding' : ''}>
          <Field
            component={CustomDatePicker}
            name={`birthDate${index}`}
            id={`birthDate${index}`}
            label="Birth Date *"
          />
        </Col>
        <Col sm="6" className={isMobile ? 'no-padding' : ''}>
          <Field
            component={CustomDatePicker}
            name={`ownerSince${index}`}
            id={`ownerSince${index}`}
            label="Owner Since *"
          />
        </Col>
      </Row>
      <Row>
        <Col sm="6" className={isMobile ? 'no-padding' : ''}>
          <Field
            component={SsnFormat}
            label="Social Security Number *"
            name={`ssN${index}`}
            id={`ssN${index}`}
            type="password"
            icon="lock"
          />
        </Col>
        <Col sm="6" className={isMobile ? 'no-padding' : ''}>
          <Field
            component={InputField}
            label="% Ownership *"
            name={`ownership${index}`}
            id={`ownership${index}`}
            type="text"
            min={1}
            max={100}
            number
            placeholder="%"
          />
        </Col>
      </Row>
      <br />
      <h5>Residential Address</h5>
      <Row>
        <Col sm="6" className={isMobile ? 'no-padding' : ''}>
          <Field
            component={InputField}
            label="ZIP Code *"
            name={`zipcode${index}`}
            id={`zipcode${index}`}
            tooltipText={ZIPCODE_TOOLTIP_TEXT}
            type="text"
            maxLength={10}
          />
        </Col>
        <Col sm="6" className={isMobile ? 'no-padding' : ''}>
          <Field
            component={InputField}
            label="City *"
            name={`city${index}`}
            id={`city${index}`}
            tooltipText={CITY_TOOLTIP_TEXT}
            type="text"
          />
        </Col>
      </Row>
      <Row>
        <Col sm="6" className={isMobile ? 'no-padding' : ''}>
          <Field
            component={Select}
            label="State *"
            name={`state${index}`}
            id={`state${index}`}
            tooltipText={STATE_TOOLTIP_TEXT}
            type="select"
            selectValues={usStates}
            selectFirstName="Select State"
          />
        </Col>
      </Row>
      <Row>
        <Col sm="12" className={isMobile ? 'no-padding' : ''}>
          <Field
            component={InputField}
            label="Street *"
            name={`street${index}`}
            id={`street${index}`}
            tooltipText={STREET_TOOLTIP_TEXT}
            type="text"
          />
        </Col>
      </Row>
      <br />
    </>
  );
};

export default OwnerInfoRow;
