import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import CurrencyFormat from '../../../components/UI/Form/NumberFormat/CurrencyFormat';
import { changePage, changePreQualInfoValue, submitMindfire } from '../../../redux/actions/index';
import { reactGAPageview, reactGAEvent } from '../../../helper';
import { UpgradePreQualificationFlowPageNumber } from './preQualificationFlow';

const LoanAmount = props => {
  const {
    dispatchChangePage,
    dispatchChangeValue,
    children,
    did,
    dealerUserId,
    grossAmount,
    jobPrice,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_prequalamount',
      trackerNames: [],
      title: 'ca_prequalamount',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const LoanAmountForm = () => (
    <Form>
      <Field
        component={CurrencyFormat}
        label="Gross Annual Income (or Benefits) Amount"
        name="grossAmount"
        id="grossAmount"
      />
      <Field component={CurrencyFormat} label="Job Price" name="jobPrice" id="jobPrice" />
      <Button type="submit" title="Next" />
    </Form>
  );

  const LoanAmountFormFormik = withFormik({
    mapPropsToValues: () => ({
      grossAmount: grossAmount || '',
      jobPrice: jobPrice || '',
    }),

    validationSchema: Yup.object({
      grossAmount: Yup.number()
        .moreThan(1499, `Amount should be at least $1500.`)
        .required('Please enter an amount.'),
      jobPrice: Yup.number()
        .moreThan(1499, `Amount should be at least $1500.`)
        .lessThan(100000, 'Amount too high.')
        .required('Please enter an amount.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      dispatchChangeValue('grossAmount', values.grossAmount);
      dispatchChangeValue('jobPrice', values.jobPrice);
      dispatchChangePage(UpgradePreQualificationFlowPageNumber.UserLastInfo);
    },
  })(LoanAmountForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={UpgradePreQualificationFlowPageNumber.UserAddress}
        />
        <Col sm="5">
          {children}{' '}
          <p>
            Revealing alimony, child support, or separate maintenance income is optional. You may
            include income from any source. If you are relying on income of a spouse, domestic
            partner, or another individual, please continue and add a Co-Applicant.
          </p>
        </Col>
        <Col sm="6">
          <LoanAmountFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  grossAmount: state.preQualificationInformation.grossAmount,
  jobPrice: state.preQualificationInformation.jobPrice,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) => dispatch(changePreQualInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

LoanAmount.propTypes = {
  grossAmount: PropTypes.number,
  jobPrice: PropTypes.number,
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoanAmount);
