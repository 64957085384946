import React from 'react';
import InputFeedback from '../InputFeedback/InputFeedback';
import { Input } from 'reactstrap';
import punycode from 'punycode';

const InputField = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched, setFieldValue },
  icon,
  max,
  maxLength,
  className,
  pattern,
  disabled,
  number,
  label,
  labelClassName,
  type,
  id,
  placeholder,
  ...props
}) => (
  <>
    <Input
      name={name}
      id={id}
      type={type}
      value={value}
      disabled={disabled}
      checked={value}
      onChange={e =>
        number
          ? /^\d*$/.test(e.currentTarget.value)
            ? setFieldValue(name, e.currentTarget.value)
            : null
          : !/[?]/.test(e.currentTarget.value) &&
            !/[À-ÖØ-öø-ÿ]/.test(punycode.toUnicode(e.currentTarget.value))
          ? setFieldValue(name, punycode.toUnicode(e.currentTarget.value))
          : null
      }
      onBlur={onBlur}
      pattern={pattern}
      className={'form-control ' + (className || '')}
      autoComplete="off"
      maxLength={maxLength}
      max={max}
      placeholder={placeholder}
    />
    {touched[name] && <InputFeedback error={errors[name]} />}
  </>
);

export default InputField;
