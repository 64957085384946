import React from 'react';
import NumberFormat from 'react-number-format';
import { Col, Row } from 'reactstrap';
import Button from '../../Button/CustomButton';
import '../../Microf/LeasingOffer/LeasingOffer.scss';
import { reactGAEvent } from '../../../../helper';

const LeasingOffer = props => {
  const {
    nextPage,
    acceptTermsMicrofApplication,
    applicationId,
    submitMindfire,
    did,
    capitalizedCostReduction,
    dealerUserId,
    leasingOffer,
    totalLeaseAmount,
    application,
  } = props;

  let costOfLeaseServices = 0;

  if (leasingOffer.totalFinancingCost && application.initialTotalJobPrice) {
    costOfLeaseServices =
      parseInt(leasingOffer.totalFinancingCost) - parseInt(application.initialTotalJobPrice);
  }

  return (
    <Row className="leasingOffer p-2">
      <Col>
        <Row className="mt-25" noGutters>
          <Col className="d-flex justify-content-between" xs="12">
            <b>Monthly Payment</b>
            <NumberFormat
              value={leasingOffer.monthlyPayment}
              thousandSeparator={true}
              fixedDecimalScale={true}
              decimalScale={2}
              prefix={'$'}
              displayType="text"
            />
          </Col>
        </Row>
        <Row noGutters>
          <Col className="d-flex justify-content-between" xs="12">
            <b>Months to Own</b>
            <span>{leasingOffer.termYears * 12}</span>
          </Col>
        </Row>
        <Row noGutters>
          <Col className="d-flex justify-content-between" xs="12">
            <b>Retail (Cash) Price</b>
            <NumberFormat
              value={application.initialTotalJobPrice}
              thousandSeparator={true}
              fixedDecimalScale={true}
              decimalScale={2}
              prefix={'$'}
              displayType="text"
            />
          </Col>
        </Row>
        <Row noGutters>
          <Col className="d-flex justify-content-between" xs="12">
            <b>Capitalized Cost Reduction</b>
            <NumberFormat
              value={capitalizedCostReduction}
              thousandSeparator={true}
              fixedDecimalScale={true}
              decimalScale={2}
              prefix={'$'}
              displayType="text"
            />
          </Col>
        </Row>
        <Row noGutters>
          <Col className="d-flex justify-content-between" xs="12">
            <b>Cost of Lease Services</b>
            <NumberFormat
              value={costOfLeaseServices}
              thousandSeparator={true}
              fixedDecimalScale={true}
              decimalScale={2}
              prefix={'$'}
              displayType="text"
            />
          </Col>
        </Row>
        <Row noGutters>
          <Col className="d-flex justify-content-between" xs="12">
            <b>Total Cost To Own</b>
            <NumberFormat
              value={leasingOffer.totalFinancingCost}
              thousandSeparator={true}
              fixedDecimalScale={true}
              decimalScale={2}
              prefix={'$'}
              displayType="text"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              type="submit"
              title={'Accept Lease Terms'}
              clickHandler={() => {
                reactGAEvent({
                  category: 'Button',
                  action: 'Click',
                  label: 'Accept Lease Terms',
                  dealerUserId: dealerUserId,
                  did: did,
                });
                submitMindfire();
                acceptTermsMicrofApplication(applicationId, leasingOffer.termYears, nextPage);
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default LeasingOffer;
