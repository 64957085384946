import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import {
  changePage,
  changePreQualInfoValue,
  submitMindfire,
} from '../../../../redux/actions/index';
import { reactGAPageview, reactGAEvent } from '../../../../helper';
import { AtWaterGreenSkyPreQualificationFlowPageNumber } from './PreQualificationFlow';
import AirConditioningSystemEnum from '../../../../assets/js/AirConditioningSystemEnum';

const LoanFor = props => {
  const {
    email,
    dispatchChangePage,
    dispatchChangePreQualInfoValue,
    dealerUserId,
    did,
    dispatchSubmitMindfire,
    airConditioningSystem,
    isHomeowner,
  } = props;

  const [isRequired, setIsRequired] = useState(false);

  useEffect(() => {
    reactGAPageview({
      path: '/ca_gsprequaloption',
      trackerNames: [],
      title: 'ca_gsprequaloption',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const mindfireRequest = {
    email: email,
    lastPage: '9003',
    dealerUserId: dealerUserId,
    did: did,
  };

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={
            !!dealerUserId && did === null
              ? AtWaterGreenSkyPreQualificationFlowPageNumber.AccountEmail
              : AtWaterGreenSkyPreQualificationFlowPageNumber.AccountPassword
          }
        />
        <Col sm="5">
          <h4>What type of project would you like to finance today?</h4>
        </Col>
        <Col sm="6">
          <Button
            className={
              'btn btn-primary mt-3 ' +
              (airConditioningSystem === null
                ? 'inactive'
                : airConditioningSystem ===
                  AirConditioningSystemEnum.AIR_CONDITION_OR_HEATING_SYSTEM.id
                ? ''
                : 'inactive')
            }
            title="Air Conditioner or Heating System"
            clickHandler={() => {
              reactGAEvent({
                category: 'Button',
                action: 'Click',
                label: 'Air Conditioner or Heating System',
                did,
                dealerUserId,
              });
              dispatchChangePreQualInfoValue(
                'airConditioningSystem',
                AirConditioningSystemEnum.AIR_CONDITION_OR_HEATING_SYSTEM.id,
              );
            }}
          />
          <Button
            className={
              'btn btn-primary mt-3 ' +
              (airConditioningSystem === null
                ? 'inactive'
                : airConditioningSystem === AirConditioningSystemEnum.OTHER_HOME_IMPROVEMENT.id
                ? ''
                : 'inactive')
            }
            pClassName="top-20"
            title="Other Home Improvement"
            clickHandler={() => {
              reactGAEvent({
                category: 'Button',
                action: 'Click',
                label: 'Other Home Improvement',
                did,
                dealerUserId,
              });
              dispatchChangePreQualInfoValue(
                'airConditioningSystem',
                AirConditioningSystemEnum.OTHER_HOME_IMPROVEMENT.id,
              );
            }}
          />
          <p className="text-danger mt-2 position-absolute" hidden={airConditioningSystem !== null}>
            You must select an option.
          </p>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col />
        <Col sm="5">
          <h4>Are you the homeowner?</h4>
        </Col>
        <Col sm="6">
          <Row>
            <Col sm="6">
              <Button
                className={
                  'btn btn-primary mt-3 ' +
                  (isHomeowner === null ? 'inactive' : isHomeowner ? '' : 'inactive')
                }
                title="Yes"
                clickHandler={() => {
                  reactGAEvent({
                    category: 'Button',
                    action: 'Click',
                    label: 'Yes',
                    did,
                    dealerUserId,
                  });
                  dispatchChangePreQualInfoValue('isHomeowner', true);
                  setIsRequired(false);
                }}
              />
            </Col>
            <Col sm="6">
              <Button
                className={
                  'btn btn-primary mt-3 ' +
                  (isHomeowner === null ? 'inactive' : isHomeowner ? 'inactive' : '')
                }
                pClassName="top-20"
                title="No"
                clickHandler={() => {
                  reactGAEvent({
                    category: 'Button',
                    action: 'Click',
                    label: 'No',
                    did,
                    dealerUserId,
                  });
                  dispatchChangePreQualInfoValue('isHomeowner', false);
                  setIsRequired(false);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="text-danger mt-2 position-absolute" hidden={!isRequired}>
                You must select an option.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col sm="6" />
        <Col sm="6">
          <Button
            className="mt-3"
            type="button"
            title="Next"
            clickHandler={() => {
              if (airConditioningSystem !== null) {
                if (isHomeowner !== null) {
                  dispatchChangePage(AtWaterGreenSkyPreQualificationFlowPageNumber.UserInfo);
                  dispatchSubmitMindfire(mindfireRequest);
                } else {
                  setIsRequired(true);
                }
              }
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  airConditioningSystem: state.preQualificationInformation.airConditioningSystem,
  isHomeowner: state.preQualificationInformation.isHomeowner,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangePreQualInfoValue: (key, value) => dispatch(changePreQualInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

LoanFor.propTypes = {
  dealerUserId: PropTypes.number,
  did: PropTypes.string,
  dispatchChangePage: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoanFor);
