import { Field } from 'formik';
import React from 'react';
import { Col, Row } from 'reactstrap';
import InputField from '../../components/UI/Form/Input/CustomInput';
import Select from '../../components/UI/Form/Select/Select';
import { isMobile } from 'react-device-detect';
import {
  SYSTEMMAKE_TOOLTIP_TEXT,
  SYSTEMMODEL_TOOLTIP_TEXT,
  SYSTEMSERIALNUMBER_TOOLTIP_TEXT,
  SYSTEMTYPE_TOOLTIP_TEXT,
} from '../../constants/tooltipTexts';
import SystemTypesCompleteFundList from '../../assets/js/SystemTypesCompleteFundList';
import SystemTypeCompleteFundEnum from '../../assets/js/SystemTypeCompleteFundEnum';

const HvacRowHvacWh = ({ index, hcBrands, systemType }) => {
  return (
    <Row className="mt-4">
      <Col sm="3" className={isMobile ? 'no-padding' : ''}>
        <Field
          fieldClassName="h-35p"
          labelClassName="hidden"
          component={Select}
          name={`systemType${index}`}
          id={`systemType${index}`}
          tooltipText={SYSTEMTYPE_TOOLTIP_TEXT}
          selectValues={
            systemType === SystemTypeCompleteFundEnum.HVAC.value ||
            systemType === SystemTypeCompleteFundEnum.WH.value
              ? SystemTypesCompleteFundList.filter(x => x.value === systemType)
              : SystemTypesCompleteFundList
          }
          disabled={
            systemType === SystemTypeCompleteFundEnum.HVAC.value ||
            systemType === SystemTypeCompleteFundEnum.WH.value
              ? true
              : false
          }
        />
      </Col>
      <Col sm="3" className={isMobile ? 'no-padding' : ''}>
        <Field
          fieldClassName="h-35p"
          labelClassName="hidden"
          component={Select}
          name={`systemMake${index}`}
          id={`systemMake${index}`}
          tooltipText={SYSTEMMAKE_TOOLTIP_TEXT}
          selectValues={hcBrands}
          selectFirstName="System Brand..."
        />
      </Col>
      <Col sm="3" className={isMobile ? 'no-padding' : ''}>
        <Field
          className="h-35p"
          formGroupClass="mt-0"
          labelClassName="hidden"
          component={InputField}
          name={`systemModel${index}`}
          id={`systemModel${index}`}
          tooltipText={SYSTEMMODEL_TOOLTIP_TEXT}
          type="text"
          placeholder="Model..."
          maxLength={50}
        />
      </Col>
      <Col sm="3" className={isMobile ? 'no-padding' : ''}>
        <Field
          className="h-35p"
          formGroupClass="mt-0"
          labelClassName="hidden"
          component={InputField}
          name={`systemSerialNumber${index}`}
          id={`systemSerialNumber${index}`}
          tooltipText={SYSTEMSERIALNUMBER_TOOLTIP_TEXT}
          type="text"
          placeholder="Serial number..."
          maxLength={30}
        />
      </Col>
    </Row>
  );
};

export default HvacRowHvacWh;
