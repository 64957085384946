import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import employmentStatusEnum, {
  findEmploymentStatusEnumName,
} from '../../../../assets/js/EmploymentStatusEnum';
import { findIncomeFrequencyEnumName } from '../../../../assets/js/IncomeFrequencyEnum';
import idTypes from '../../../../assets/js/IdTypeEnum';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import Checkbox from '../../../../components/UI/Form/Checkbox/Checkbox';
import {
  getAddress,
  reactGAEvent,
  reactGAPageview,
  getInstallAddress,
  getTimeAtPropery,
} from '../../../../helper';
import {
  changePage,
  changeFoundationInfoValue,
  submitMindfire,
  submitFoundationApplication,
} from '../../../../redux/actions/index';
import selectedFinancierEnum from '../../../../assets/js/SelectedFinancierEnum';
import { FoundationFlowPageNumber } from './FoundationFlow';
import {
  isEnerBankDeclined,
  isGreenSkyDeclined,
  isMosaicDeclined,
} from '../../../../assets/js/ApplicationStatusEnum';
import { findOtherSourceOfIncomeEnumName } from '../../../../assets/js/FoundationOtherSourceOfIncomeEnum';
import { findLoanOptionEnumName } from '../../../../assets/js/LoanOptionEnum';
import FoundationProductTypesEnum, {
  findFoundationProductTypeEnumName,
} from '../../../../assets/js/FoundationProductTypesEnum';
import { findOwnershipEnumName } from '../../../../assets/js/OwnershipEnum';
import { getFoundationDisclosuresUrl } from '../../../../config/settings';
import { OPTIMUS_COAPP_AUTHORIZE_TOOLTIP_TEXT } from '../../../../constants/tooltipTexts';

const foundationDisclosuresUrl = getFoundationDisclosuresUrl();

const ApplicationReview = props => {
  const {
    dispatchSubmitFoundationApplication,
    children,
    dispatchChangePage,
    dispatchChangeFoundationInfoValue,
    pqi,
    foundationInformation,
    softPull,
    dealerUserId,
    did,
    app,
    emulatorId,
    track_id,
    dispatchSubmitMindfire,
    consumerId,
    dealerName,
  } = props;
  const isEditable =
    !app ||
    isMosaicDeclined(app.status) ||
    isEnerBankDeclined(app.status) ||
    isGreenSkyDeclined(app.status);

  useEffect(() => {
    reactGAPageview({
      path: '/ca_fdconfirminfo',
      trackerNames: [],
      title: 'ca_fdconfirminfo',
      did,
      dealerUserId,
    });
    dispatchChangeFoundationInfoValue('isReview', false);
    // eslint-disable-next-line
  }, []);

  const editPage = page => {
    dispatchChangeFoundationInfoValue('isReview', true);
    dispatchChangePage(page);
  };

  const mindfireRequest = {
    email: pqi.email,
    lastPage: '10021',
    dealerUserId: dealerUserId,
    did: did,
  };

  const propertyAddress = getAddress({
    street: pqi.street,
    city: pqi.city,
    state: pqi.state,
    zip: pqi.zipcode,
  });

  const applicantAddress = getInstallAddress({
    street: foundationInformation.applicantStreet,
    unit: foundationInformation.applicantApartmentNumber,
    city: foundationInformation.applicantCity,
    state: foundationInformation.applicantState,
    zip: foundationInformation.applicantZipcode,
  });

  const coApplicantAddress = foundationInformation.hasCoApp
    ? getAddress({
        street: foundationInformation.coAppStreet,
        city: foundationInformation.coAppCity,
        state: foundationInformation.coAppState,
        zip: foundationInformation.coAppZipCode,
      })
    : 'No address';

  const timeAtApplicantAddress = getTimeAtPropery({
    years: foundationInformation.applicantPropertyYears,
    months: foundationInformation.applicantPropertyMonths,
  });

  const timeAtAddress = getTimeAtPropery({
    years: foundationInformation.propertyYears,
    months: foundationInformation.propertyMonths,
  });

  const ApplicationReviewForm = () => (
    <Form>
      {foundationInformation.hasCoApp ? (
        <Row className="review">
          <Col>
            <Row className="align-items-center mb-0">
              <Col>
                <h5>Co-Applicant Disclosures</h5>
              </Col>
            </Row>
            <Row className="align-items-center mb-0">
              <Col>
                <p>
                  I acknowledge that by checking the box below and pressing continue, that the info
                  on this application will be sent to Foundation Finance Company for the purpose of
                  completing a credit application and a hard credit pull will be completing upon
                  application submission.
                </p>
              </Col>
            </Row>
            <Row className="justify-content-center mb-0">
              <Col>
                <h5>
                  Consumer Credit Disclosures:{' '}
                  <a
                    className="font-16 text-break"
                    href={foundationDisclosuresUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {foundationDisclosuresUrl}
                  </a>
                </h5>
              </Col>
            </Row>
            <Field
              component={Checkbox}
              name="authorize"
              id="authorize"
              tooltipText={OPTIMUS_COAPP_AUTHORIZE_TOOLTIP_TEXT}
              label="Co-Applicant"
              labelClassName="bold mb-0"
            />
          </Col>
        </Row>
      ) : null}
      <Button
        type="submit"
        title="Find the best financing option"
        disabled={foundationInformation.submited}
      />
    </Form>
  );

  const ApplicationReviewFormFormik = withFormik({
    mapPropsToValues: () => ({
      authorize: false,
    }),

    validationSchema: Yup.object({
      authorize: foundationInformation.hasCoApp
        ? Yup.boolean().oneOf([true], 'Please check to continue.')
        : Yup.boolean(),
    }),

    handleSubmit: () => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Find the best financing option',
        did,
        dealerUserId,
      });
      dispatchChangeFoundationInfoValue('submited', true);
      const softPullRequest = {
        applicationId:
          softPull.applicationSubmitDTO && softPull.applicationSubmitDTO.applicationId
            ? softPull.applicationSubmitDTO.applicationId
            : 0,
        consumerId: consumerId,
        email: softPull.softPullResponse.email
          ? softPull.softPullResponse.email
          : pqi.email || null,
        totalJobPrice:
          foundationInformation.saleAmount !== null
            ? Math.floor(foundationInformation.saleAmount)
            : null,
        loanOption: foundationInformation.loanOption,
        borrowedAmount:
          foundationInformation.loanAmountRequested !== null
            ? Math.floor(foundationInformation.loanAmountRequested)
            : null,
        firstName: softPull.softPullResponse.firstName
          ? softPull.softPullResponse.firstName
          : pqi.firstName || null,
        lastName: softPull.softPullResponse.lastName
          ? softPull.softPullResponse.lastName
          : pqi.lastName || null,
        middleName: softPull.softPullResponse.middleName
          ? softPull.softPullResponse.middleName
          : pqi.middleName || null,
        phoneNumber: softPull.softPullResponse.phoneNumber
          ? softPull.softPullResponse.phoneNumber
          : pqi.phoneNumber || null,
        street: softPull.softPullResponse.street
          ? softPull.softPullResponse.street
          : pqi.street || null,
        city: softPull.softPullResponse.city ? softPull.softPullResponse.city : pqi.city || null,
        zipcode: softPull.softPullResponse.zipcode
          ? softPull.softPullResponse.zipcode
          : pqi.zipcode || null,
        state: softPull.softPullResponse.state
          ? softPull.softPullResponse.state
          : pqi.state || null,

        applicantStreet: foundationInformation.applicantStreet || null,
        applicantApartmentNumber: foundationInformation.applicantApartmentNumber || null,
        applicantCity: foundationInformation.applicantCity || null,
        applicantZip: foundationInformation.applicantZipcode || null,
        applicantState: foundationInformation.applicantState || null,

        ownership: foundationInformation.ownership,
        propertyMonths: foundationInformation.propertyMonths,
        propertyYears: foundationInformation.propertyYears,
        propertyMonthlyPayment: foundationInformation.propertyMonthlyPayment,

        applicantOwnership: foundationInformation.applicantOwnership,
        applicantPropertyMonths: foundationInformation.applicantPropertyMonths,
        applicantPropertyYears: foundationInformation.applicantPropertyYears,
        applicantPropertyMonthlyPayment: foundationInformation.applicantPropertyMonthlyPayment,

        birthday: softPull.softPullResponse.birthday
          ? softPull.softPullResponse.birthday
          : pqi.birthday || null,
        ssnLastDigits: softPull.softPullResponse.ssnLastDigits
          ? softPull.softPullResponse.ssnLastDigits
          : pqi.ssnLastDigits || null,
        airConditioningSystem:
          softPull.softPullResponse.airConditioningSystem || pqi.airConditioningSystem,
        isHomeowner: softPull.softPullResponse.isHomeowner || pqi.isHomeowner,

        homeImprovementProductType:
          softPull.softPullResponse.airConditioningSystem || pqi.airConditioningSystem
            ? FoundationProductTypesEnum.HVAC.value
            : foundationInformation.appProductType,

        dealerUserId: dealerUserId || null,
        prequalificationId: softPull.softPullResponse.prequalificationId
          ? softPull.softPullResponse.prequalificationId
          : pqi.prequalificationId || null,
        idType: idTypes.DrivesLicense.value,
        driversLicenseState: foundationInformation.appDriversLicenseState || null,
        idNumber: foundationInformation.appDriversLicenseNumber || null,
        ssn: foundationInformation.appFullSsn,
        employerType: foundationInformation.appEmploymentStatus
          ? foundationInformation.appEmploymentStatus
          : null,
        occupation:
          foundationInformation.appEmploymentStatus === employmentStatusEnum.Retired.value
            ? employmentStatusEnum.Retired.value
            : foundationInformation.appEmploymentStatus === employmentStatusEnum.Student.value
            ? employmentStatusEnum.Student.value
            : foundationInformation.appOccupation || null,
        employerName: foundationInformation.appEmployerName || null,
        employmentYears: foundationInformation.appEmploymentYears || 0,
        employmentMonths: foundationInformation.appEmploymentMonths || 1,
        appGrossIncome: foundationInformation.appGrossIncome || null,
        appIncomeFrequency: foundationInformation.appIncomeFrequency || null,
        appGrossAnnualIncome: foundationInformation.appEstimatedsAnnualGrosIncome,

        appOtherSourcesOfIncome: foundationInformation.appOtherSourcesOfIncome || null,
        appOtherIncomeType: foundationInformation.appOtherIncomeType || null,
        appAdditionalIncome: foundationInformation.appAdditionalIncome || null,

        coapplicantEmployerType: foundationInformation.hasCoApp
          ? foundationInformation.coAppEmploymentStatus
            ? foundationInformation.coAppEmploymentStatus
            : null
          : null,

        coAppOccupation: foundationInformation.hasCoApp
          ? foundationInformation.coAppEmploymentStatus === employmentStatusEnum.Retired.value
            ? employmentStatusEnum.Retired.value
            : foundationInformation.coAppEmploymentStatus === employmentStatusEnum.Student.value
            ? employmentStatusEnum.Student.value
            : foundationInformation.coAppOccupation || null
          : null,
        coapplicantEmployerName: foundationInformation.hasCoApp
          ? foundationInformation.coAppEmployerName
          : null,
        coapplicantEmploymentYears: foundationInformation.hasCoApp
          ? foundationInformation.coAppEmploymentYears || 0
          : null,
        coapplicantEmploymentMonths: foundationInformation.hasCoApp
          ? foundationInformation.coAppEmploymentMonths || 1
          : null,
        coAppGrossIncome: foundationInformation.hasCoApp
          ? foundationInformation.coAppGrossIncome || 0
          : null,
        coAppIncomeFrequency: foundationInformation.hasCoApp
          ? foundationInformation.coAppIncomeFrequency || null
          : null,
        coAppGrossAnnualIncome: foundationInformation.hasCoApp
          ? foundationInformation.coAppEstimatedsAnnualGrosIncome
          : null,

        coapplicantSsn: foundationInformation.hasCoApp ? foundationInformation.coAppFullSsn : null,
        coapplicantIdType: idTypes.DrivesLicense.value,
        coapplicantIdNumber: foundationInformation.hasCoApp
          ? foundationInformation.coAppDriversLicenseNumber
          : null,
        coapplicantDriversLicenseState: foundationInformation.hasCoApp
          ? foundationInformation.coAppDriversLicenseState || null
          : null,

        coapplicantFirstName: foundationInformation.hasCoApp
          ? foundationInformation.coAppFirstName || null
          : null,
        coapplicantLastName: foundationInformation.hasCoApp
          ? foundationInformation.coAppLastName || null
          : null,
        coapplicantMiddleName: foundationInformation.hasCoApp
          ? foundationInformation.coAppMiddleInitial || null
          : null,
        coapplicantPhoneNumber: foundationInformation.hasCoApp
          ? foundationInformation.coAppPhoneNumber || null
          : null,
        coapplicantEmail: foundationInformation.hasCoApp
          ? foundationInformation.coAppEmail || null
          : null,
        coapplicantStreet: foundationInformation.hasCoApp
          ? foundationInformation.coAppStreet || null
          : null,
        coapplicantCity: foundationInformation.hasCoApp
          ? foundationInformation.coAppCity || null
          : null,
        coapplicantZipcode: foundationInformation.hasCoApp
          ? foundationInformation.coAppZipCode || null
          : null,
        coapplicantState: foundationInformation.hasCoApp
          ? foundationInformation.coAppState || null
          : null,
        coapplicantBirthday: foundationInformation.hasCoApp
          ? foundationInformation.coAppBirthday || null
          : null,
        coAppOtherSourcesOfIncome: foundationInformation.hasCoApp
          ? foundationInformation.coAppOtherSourcesOfIncome || null
          : null,
        coAppOtherIncomeType: foundationInformation.hasCoApp
          ? foundationInformation.coAppOtherIncomeType || null
          : null,
        coAppAdditionalIncome: foundationInformation.hasCoApp
          ? foundationInformation.coAppAdditionalIncome || null
          : null,

        coApplicantOwnership: foundationInformation.coApplicantOwnership,
        coApplicantPropertyMonths: foundationInformation.coApplicantPropertyMonths,
        coApplicantPropertyYears: foundationInformation.coApplicantPropertyYears,
        coApplicantPropertyMonthlyPayment: foundationInformation.coApplicantPropertyMonthlyPayment,

        selectedFinancier: selectedFinancierEnum.FOUNDATION.value,
        emulatorId: emulatorId,
        track_id: track_id,
      };

      dispatchSubmitMindfire(mindfireRequest);
      dispatchSubmitFoundationApplication(
        softPullRequest,
        FoundationFlowPageNumber.ApplicationResult,
        app,
      );
    },
  })(ApplicationReviewForm);

  return (
    <Container>
      <Row>
        {isEditable ? (
          <BackBtn
            changePage={dispatchChangePage}
            page={
              foundationInformation.hasCoApp
                ? FoundationFlowPageNumber.CoAppOtherSourceOfIncome
                : FoundationFlowPageNumber.HasCoApplicant
            }
          />
        ) : (
          <Col sm="1" />
        )}
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <Row className="review">
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col xs="8" lg="9">
                  <h5>Job Information</h5>
                </Col>
                {isEditable ? (
                  <Col xs="4" lg="3">
                    <Button
                      className="edit"
                      clickHandler={() =>
                        editPage(
                          foundationInformation.appProductType
                            ? FoundationFlowPageNumber.HomeImprovementProductType
                            : FoundationFlowPageNumber.LoanAmount,
                        )
                      }
                      title="Edit"
                    />
                  </Col>
                ) : null}
              </Row>
              <Row>
                <Col xs="6">
                  <b>Dealer</b>
                  <span className="text-break">{dealerName || 'N/A'}</span>
                </Col>
                <Col xs="6">
                  <b>Product Type</b>
                  {findFoundationProductTypeEnumName(foundationInformation.appProductType) || 'N/A'}
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <b>Sale Amount</b>
                  <NumberFormat
                    value={foundationInformation.saleAmount}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={'$'}
                    displayType="text"
                  />
                </Col>
                <Col xs="6">
                  <b>Down Payment</b>
                  <NumberFormat
                    value={foundationInformation.downPayment}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={'$'}
                    displayType="text"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <b>Loan Amount Requested</b>
                  <NumberFormat
                    value={foundationInformation.loanAmountRequested}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={'$'}
                    displayType="text"
                  />
                </Col>
                <Col xs="6">
                  <b>Loan Option</b>
                  <span className="text-break">
                    {findLoanOptionEnumName(foundationInformation.loanOption) || 'N/A'}
                  </span>
                </Col>
              </Row>
              <Row className="align-items-center justify-content-between">
                <Col xs="8" lg="9">
                  <h5>Applicant’s Information</h5>
                </Col>
                {isEditable ? (
                  <Col xs="4" lg="3">
                    <Button
                      className="edit"
                      clickHandler={() => editPage(FoundationFlowPageNumber.AppIdentificationInfo)}
                      title="Edit"
                    />
                  </Col>
                ) : null}
              </Row>
              <Row>
                <Col xs="6">
                  <b>Name</b>
                  {(pqi.firstName ? pqi.firstName + ' ' : '') +
                    (pqi.middleName ? pqi.middleName + ' ' : '') +
                    (pqi.lastName || '')}
                </Col>
                <Col xs="6">
                  <b>Phone Number</b>
                  {!!pqi.phoneNumber
                    ? pqi.phoneNumber.length > 6
                      ? '(' +
                        pqi.phoneNumber.substring(0, 3) +
                        ') ' +
                        pqi.phoneNumber.substring(3, 6) +
                        '-' +
                        pqi.phoneNumber.substring(6)
                      : pqi.phoneNumber
                    : 'N/A'}
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <b>Email Address</b>
                  <span className="text-break">{pqi.email || 'N/A'}</span>
                </Col>
                <Col xs="6">
                  <b>Date of Birth</b>
                  {pqi.birthday || 'N/A'}
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <b>Full SSN</b>
                  {foundationInformation.appFullSsn
                    ? 'xxxxx' + foundationInformation.appFullSsn.slice(5, 9)
                    : 'N/A'}
                </Col>
                <Col xs="6">
                  <b>Photo ID Information (Drivers License)</b>
                  <span className="text-break">
                    {foundationInformation.appDriversLicenseNumber || 'N/A'}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <b>Installation Address</b>
                  {propertyAddress}
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <b>Ownership</b>
                  <span className="text-break">
                    {findOwnershipEnumName(foundationInformation.applicantOwnership) || 'N/A'}
                  </span>
                </Col>
                <Col xs="6">
                  <b>Monthly Payment</b>
                  <NumberFormat
                    value={foundationInformation.applicantPropertyMonthlyPayment}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={'$'}
                    displayType="text"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <b>Time at Address</b>
                  <span className="text-break">{timeAtApplicantAddress || 'N/A'}</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <b>Billing Address</b>
                  {applicantAddress}
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <b>Ownership</b>
                  <span className="text-break">
                    {findOwnershipEnumName(foundationInformation.ownership) || 'N/A'}
                  </span>
                </Col>
                <Col xs="6">
                  <b>Monthly Payment</b>
                  <NumberFormat
                    value={foundationInformation.propertyMonthlyPayment}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={'$'}
                    displayType="text"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <b>Time at Address</b>
                  <span className="text-break">{timeAtAddress || 'N/A'}</span>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="review">
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col xs="8" lg="9">
                  <h5>Applicant Employment</h5>
                </Col>
                {isEditable ? (
                  <Col xs="4" lg="3">
                    <Button
                      className="edit"
                      clickHandler={() => editPage(FoundationFlowPageNumber.AppEmploymentDetails)}
                      title="Edit"
                    />
                  </Col>
                ) : null}
              </Row>
              <Row>
                <Col xs="6">
                  <b>Employment Status</b>
                  <span className="text-break">
                    {findEmploymentStatusEnumName(foundationInformation.appEmploymentStatus) ||
                      'N/A'}
                  </span>
                </Col>
                <Col xs="6">
                  <b>Employer</b>
                  {foundationInformation.appEmployerName || 'N/A'}
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <b>Title/Occupation</b>
                  {foundationInformation.appOccupation || 'N/A'}
                </Col>
                <Col xs="6">
                  <b>Time at Employer</b>
                  {!foundationInformation.appEmploymentYears &&
                  !foundationInformation.appEmploymentMonths
                    ? 'N/A'
                    : foundationInformation.appEmploymentYears +
                      ' Year(s)' +
                      (foundationInformation.appEmploymentMonths &&
                      foundationInformation.appEmploymentMonths !== '0'
                        ? ' and ' + foundationInformation.appEmploymentMonths + ' Month(s)'
                        : '')}
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <b>Gross Income</b>
                  <NumberFormat
                    value={foundationInformation.appGrossIncome}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={'$'}
                    displayType="text"
                  />
                </Col>
                <Col xs="6">
                  <b>Frequency</b>
                  {findIncomeFrequencyEnumName(foundationInformation.appIncomeFrequency) || 'N/A'}
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <b>Gross Annual Income (or benefits)</b>
                  <NumberFormat
                    value={foundationInformation.appEstimatedsAnnualGrosIncome}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={'$'}
                    displayType="text"
                  />
                </Col>
              </Row>
              <Row className="align-items-center justify-content-between">
                <Col xs="8" lg="9">
                  <h5>Additional Income (or benefits)</h5>
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <b>Source</b>
                  <span className="text-break">
                    {findOtherSourceOfIncomeEnumName(
                      foundationInformation.appOtherSourcesOfIncome,
                    ) || 'N/A'}
                  </span>
                </Col>
                <Col xs="6">
                  <b>Income (or benefits) Type</b>
                  {findIncomeFrequencyEnumName(foundationInformation.appOtherIncomeType) || 'N/A'}
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <b>Gross Amount</b>
                  <NumberFormat
                    value={foundationInformation.appAdditionalIncome}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={'$'}
                    displayType="text"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="review">
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col xs="8" lg="9">
                  <h5>Co-Applicant’s Information</h5>
                </Col>
                {isEditable ? (
                  <Col xs="4" lg="3">
                    <Button
                      className="edit"
                      clickHandler={() => editPage(FoundationFlowPageNumber.HasCoApplicant)}
                      title={foundationInformation.hasCoApp ? 'Edit' : 'Add'}
                    />
                  </Col>
                ) : null}
              </Row>
              {foundationInformation.hasCoApp && (
                <>
                  <Row>
                    <Col xs="6">
                      <b>Name</b>
                      {(foundationInformation.coAppFirstName
                        ? foundationInformation.coAppFirstName + ' '
                        : '') +
                        (foundationInformation.coAppMiddleName
                          ? foundationInformation.coAppMiddleName + ' '
                          : '') +
                        (foundationInformation.coAppLastName || '')}
                    </Col>
                    <Col xs="6">
                      <b>Phone Number</b>
                      {!!foundationInformation.coAppPhoneNumber
                        ? foundationInformation.coAppPhoneNumber.length > 6
                          ? '(' +
                            foundationInformation.coAppPhoneNumber.substring(0, 3) +
                            ') ' +
                            foundationInformation.coAppPhoneNumber.substring(3, 6) +
                            '-' +
                            foundationInformation.coAppPhoneNumber.substring(6)
                          : foundationInformation.coAppPhoneNumber
                        : 'N/A'}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">
                      <b>Email Address</b>
                      <span className="text-break">
                        {foundationInformation.coAppEmail || 'N/A'}
                      </span>
                    </Col>
                    <Col xs="6">
                      <b>Date of Birth</b>
                      {foundationInformation.coAppBirthday || 'N/A'}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">
                      <b>Full SSN</b>
                      {foundationInformation.coAppFullSsn
                        ? 'xxxxx' + foundationInformation.coAppFullSsn.slice(5, 9)
                        : 'N/A'}
                    </Col>
                    <Col xs="6">
                      <b>Photo ID Information (Drivers License)</b>
                      <span className="text-break">
                        {foundationInformation.coAppDriversLicenseNumber || 'N/A'}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <b>Co-Applicant’s Address</b>
                      {coApplicantAddress}
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
          {foundationInformation.hasCoApp && (
            <Row className="review">
              <Col>
                <Row className="align-items-center justify-content-between">
                  <Col xs="8" lg="9">
                    <h5>Co-Applicant’s Employment</h5>
                  </Col>
                  {isEditable ? (
                    <Col xs="4" lg="3">
                      <Button
                        className="edit"
                        clickHandler={() =>
                          editPage(FoundationFlowPageNumber.CoAppEmploymentDetails)
                        }
                        title="Edit"
                      />
                    </Col>
                  ) : null}
                </Row>
                <Row>
                  <Col xs="6">
                    <b>Employment Status</b>
                    <span className="text-break">
                      {findEmploymentStatusEnumName(foundationInformation.coAppEmploymentStatus) ||
                        'N/A'}
                    </span>
                  </Col>
                  <Col xs="6">
                    <b>Employer</b>
                    {foundationInformation.coAppEmployerName || 'N/A'}
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <b>Title/Occupation</b>
                    {foundationInformation.coAppOccupation || 'N/A'}
                  </Col>
                  <Col xs="6">
                    <b>Time at Employer</b>
                    {!foundationInformation.coAppEmploymentYears &&
                    !foundationInformation.coAppEmploymentMonths
                      ? 'N/A'
                      : foundationInformation.coAppEmploymentYears +
                        ' Year(s)' +
                        (foundationInformation.coAppEmploymentMonths &&
                        foundationInformation.coAppEmploymentMonths !== '0'
                          ? ' and ' + foundationInformation.coAppEmploymentMonths + ' Month(s)'
                          : '')}
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <b>Gross Income</b>
                    <NumberFormat
                      value={foundationInformation.coAppGrossIncome}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix={'$'}
                      displayType="text"
                    />
                  </Col>
                  <Col xs="6">
                    <b>Frequency</b>
                    {findIncomeFrequencyEnumName(foundationInformation.coAppIncomeFrequency) ||
                      'N/A'}
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <b>Gross Annual Income (or benefits)</b>
                    <NumberFormat
                      value={foundationInformation.coAppEstimatedsAnnualGrosIncome}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix={'$'}
                      displayType="text"
                    />
                  </Col>
                </Row>
                <Row className="align-items-center justify-content-between">
                  <Col xs="8" lg="9">
                    <h5>Co-Applicant’s Additional Income (or benefits)</h5>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <b>Source</b>
                    <span className="text-break">
                      {findOtherSourceOfIncomeEnumName(
                        foundationInformation.coAppOtherSourcesOfIncome,
                      ) || 'N/A'}
                    </span>
                  </Col>
                  <Col xs="6">
                    <b>Income (or benefits) Type</b>
                    {findIncomeFrequencyEnumName(foundationInformation.coAppOtherIncomeType) ||
                      'N/A'}
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <b>Gross Amount</b>
                    <NumberFormat
                      value={foundationInformation.coAppAdditionalIncome}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix={'$'}
                      displayType="text"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          <ApplicationReviewFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  consumerId: state.auth.consumerId,
  aquaFinanceAssigned: state.auth.aquaFinanceAssigned,
  foundationInformation: state.foundationInformation,
  pqi: state.preQualificationInformation,
  softPull: state.softPull,
  app: state.softPull.applicationSubmitDTO,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  emulatorId: state.auth.emulatorId,
  track_id: state.auth.track_id,
  dealerName: state.auth.user?.user?.dealerStatsSummaryDTO?.dealerName || state.auth.dealerName,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeFoundationInfoValue: (key, value) =>
    dispatch(changeFoundationInfoValue(key, value)),
  dispatchSubmitFoundationApplication: (params, page, app) =>
    dispatch(submitFoundationApplication(params, page, app)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

ApplicationReview.propTypes = {
  dispatchChangePage: PropTypes.func,
  dispatchChangeFoundationInfoValue: PropTypes.func,
  dispatchSubmitSoftPullAquaApplication: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApplicationReview);
