const relationshipEnum = {
  SPOUSE: { value: 'SP', name: 'Spouse', oracleValue: 'SPOUSE' },
  BROTHER: { value: 'BR', name: 'Brother', oracleValue: 'OTHER' },
  SISTER: { value: 'SS', name: 'Sister', oracleValue: 'OTHER' },
  MOTHER: { value: 'MM', name: 'Mother', oracleValue: 'MOTHER' },
  FATHER: { value: 'DD', name: 'Father', oracleValue: 'FATHER' },
  OTHER: { value: 'OT', name: 'Other', oracleValue: 'OTHER' },
};

export default relationshipEnum;
