import { Field, Form, withFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { formatDate } from '../../../../util/dateFormatter';
import MultiSelectIdentityVerification from '../../Form/Select/MultiSelectIdentityVerification';
import CustomDatePicker from '../../../../components/UI/Form/DatePicker/DatePicker';
import CurrencyFormat from '../../../../components/UI/Form/NumberFormat/CurrencyFormat';
import EquipmentRow from './EquipmentRow';
import { validDateFormat } from '../../../../helper';
import { changeSoftPullValue, ftlFetchProducts, ftlSetAmount } from '../../../../redux/actions';
import Products from './Products';
import PreviewSection from './PreviewSection';
import Checkbox from '../../Form/Checkbox/Checkbox';
import InputField from '../../../../components/UI/Form/Input/CustomInput';

const CreateLoanDocuments = props => {
  const {
    application,
    history,
    ftlData,
    ftlDistributors,
    dispatchChangeSoftPullValue,
    dispatchFtlFetchProducts,
    ftlOptionsResponse,
    ftlFetchProductsResponse,
    loanDoc,
    ftlUploadResponse,
    dispatchFtlSetAmount,
  } = props;

  const [isRequired, setIsRequired] = useState(false);
  const [selectedDistributor, setSelectedDistributor] = useState(null);
  const [selectedDistributorBrand, setSelectedDistributorBrand] = useState(null);
  const [brands, setBrands] = useState([]);
  const [infoValues, setInfoValues] = useState([]);
  const [seeProducts, setSeeProducts] = useState(false);
  const [jobDate, setJobDate] = useState(null);
  const [amount, setAmount] = useState(null);
  const [noEquipmentSelected, setNoEquipmentSelected] = useState(false);
  const [seePreview, setSeePreview] = useState(false);

  const isLoanMoreSelectedOpt = ftlData.ftlInformation.selectedLoanMoreOptionId;

  let showNoEquipmentCheck = true;
  if (application.status === 'FTL_APPROVE_LOAN_MORE') {
    showNoEquipmentCheck = false;
  }
  if (isLoanMoreSelectedOpt) {
    showNoEquipmentCheck = false;
  }

  useEffect(() => {
    dispatchChangeSoftPullValue('ftlGeneratePaperworkResponse', null);
    dispatchChangeSoftPullValue('ftlFetchProductsResponse', null);
  }, []);

  const addMore = values => {
    if (values.completedDate !== '') {
      setJobDate(values.completedDate);
    }
    if (values.amount !== '') {
      setAmount(values.amount);
    }

    let infoValuesTmp = [...infoValues];

    infoValuesTmp.forEach((infoValue, index) => {
      infoValue.systemMake = values[`systemMake${index}`];
      infoValue.systemModel = values[`systemModel${index}`];
      infoValue.systemSerialNumber = values[`systemSerialNumber${index}`];
      infoValue.systemDescription = values[`systemDescription${index}`];
    });

    infoValuesTmp.push({
      systemMake: selectedDistributorBrand,
      systemModel: '',
      systemSerialNumber: '',
      systemDescription: '',
    });

    setInfoValues(infoValuesTmp);
  };

  const removeUnit = () => {
    if (infoValues.length > 1) {
      setInfoValues(infoValues.slice(0, -1));
    }
  };

  const backButtonForPrograms = () => {
    setSeeProducts(false);
  };

  const getBrands = distributor => {
    setSelectedDistributor(distributor.value);
    const distributorBrands = ftlDistributors.find(x => x.id === distributor.value).brands;
    setBrands(distributorBrands);
    setInfoValues([]);
  };

  const setSelectedBrand = brand => {
    setSelectedDistributorBrand(brand.value);
    setInfoValues([
      {
        systemMake: brand.value,
        systemModel: '',
        systemSerialNumber: '',
        systemDescription: '',
      },
    ]);
  };

  const getPriorDateLimit = () => {
    let d = new Date();
    d.setMonth(d.getMonth() - 3);
    return d;
  };

  const NoEquipmentFormik = ({ values }) => {
    return (
      <Form noValidate>
        <Row className="p-15">
          <Col>
            <Field
              component={Checkbox}
              name="noEquipment"
              id="noEquipment"
              label="No equipment is being purchased"
              labelClassName="mb-0"
              clickHandler={() => setNoEquipmentSelected(!noEquipmentSelected)}
            />
          </Col>
        </Row>
      </Form>
    );
  };

  const NoEquipmentFormFormik = withFormik({
    mapPropsToValues: () => ({
      noEquipment: noEquipmentSelected || false,
    }),

    handleSubmit: values => {},
  })(NoEquipmentFormik);

  const CreateLoanDocumentsFormik = ({ values }) => {
    return (
      <Form noValidate>
        <Row className="p-15">
          {noEquipmentSelected ? (
            <Col>
              <Row>
                <Field
                  formGroupClassName="mt-0 loan-doc-servicing-description"
                  component={InputField}
                  label="Servicing description:"
                  name="servicingDescription"
                  id="servicingDescription"
                  type="text"
                  placeholder="Description..."
                  maxLength={50}
                />
              </Row>
            </Col>
          ) : (
            <Col sm="7">
              <Row>
                <MultiSelectIdentityVerification
                  errorMessage={isRequired && 'Please select an option.'}
                  label={'Where was the equipment or parts purchased?'}
                  className={'create-loan-doc-dropdown'}
                  key={'distributors-dd'}
                  isMulti={false}
                  options={ftlDistributors.map((distributor, index) => ({
                    value: distributor.id,
                    label: distributor.name,
                  }))}
                  selectedValues={ftlDistributors
                    .filter(x => x.id === selectedDistributor)
                    .map((distributor, index) => ({
                      value: distributor.id,
                      label: distributor.name,
                    }))}
                  onChange={e => getBrands(e)}
                />
              </Row>
              {brands && brands.length > 0 && (
                <Row>
                  <MultiSelectIdentityVerification
                    errorMessage={isRequired && 'Please select an option.'}
                    label={'What is the brand of the equipment being installed?'}
                    className={'create-loan-doc-dropdown'}
                    key={'brands-dd'}
                    isMulti={false}
                    options={brands.map((brand, index) => ({
                      value: brand,
                      label: brand,
                    }))}
                    selectedValues={brands
                      .filter(x => x === selectedDistributorBrand)
                      .map((brand, index) => ({
                        value: brand,
                        label: brand,
                      }))}
                    onChange={e => setSelectedBrand(e)}
                  />
                </Row>
              )}
              {infoValues.length > 0 && (
                <Row className="mt-4">
                  <label className="mb-m20">
                    <b>What equipment is being installed? (limit 6)</b>
                  </label>
                </Row>
              )}
              {infoValues.length > 0 &&
                infoValues.map((_info, index) => <EquipmentRow index={index} key={index} />)}
              <Row
                className={'mt-5 ' + (!isMobile ? 'justify-content-end' : 'justify-content-center')}
              >
                {infoValues.length > 1 && (
                  <Button
                    type="button"
                    className={
                      'rounded-pill btn-secondary mr-2' + (isMobile ? ' mt-3 pl-5 pr-5' : '')
                    }
                    onClick={() => {
                      removeUnit();
                    }}
                  >
                    Remove
                  </Button>
                )}
                {infoValues.length > 0 && infoValues.length < 6 && (
                  <Button
                    type="button"
                    className={
                      'rounded-pill btn-primary mr-2' + (isMobile ? ' mt-3 pl-5 pr-5' : '')
                    }
                    onClick={() => {
                      addMore(values);
                    }}
                  >
                    Add More
                  </Button>
                )}
              </Row>
            </Col>
          )}

          <Col sm="5">
            <Row>
              <Col sm="8">
                <Field
                  component={CustomDatePicker}
                  name="completedDate"
                  id="completedDate"
                  tooltipText={
                    'Date must be before ' +
                    formatDate(ftlData.ftlInformation.expiresOn, 'MM/DD/YYYY') +
                    ', the expiration date of the approval.'
                  }
                  label="What date will this project be completed?"
                />
              </Col>
              <Col />
            </Row>
            <Row>
              <Col sm="8">
                <Field
                  component={CurrencyFormat}
                  name="amount"
                  id="amount"
                  tooltipText={
                    'Up to $' +
                    application.totalFinancing +
                    '. If you need additional funding, contact FTL finance.'
                  }
                  type="text"
                  label="Enter the exact amount needed."
                />
              </Col>
              <Col />
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-end">
          <Col sm="12" xs="12" md="auto">
            <Button
              type="button"
              color="secondary"
              className="rounded-pill w-100 mt-1"
              outline
              onClick={() => {
                loanDoc(false);
              }}
            >
              Back
            </Button>
          </Col>
          {selectedDistributorBrand && (
            <Col sm="12" xs="12" md="auto">
              <Button type="submit" color="primary" className="rounded-pill w-100 mt-1" outline>
                Next
              </Button>
            </Col>
          )}
        </Row>
      </Form>
    );
  };

  const CreateLoanDocumentsFormFormik = withFormik({
    mapPropsToValues: () => ({
      ...infoValues.reduce(
        (previous, current, index) =>
          Object.assign(previous, {
            [`systemMake${index}`]: current.systemMake,
            [`systemModel${index}`]: current.systemModel,
            [`systemSerialNumber${index}`]: current.systemSerialNumber,
            [`systemDescription${index}`]: current.systemDescription,
          }),
        {},
      ),
      completedDate: jobDate ? jobDate : '',
      amount: amount ? amount : '',
      servicingDescription: '',
    }),

    validationSchema: Yup.object({
      completedDate: Yup.date()
        .required('Please enter the date.')
        .max(
          ftlData.ftlInformation.expiresOn,
          'Date must be earlier than ' + formatDate(ftlData.ftlInformation.expiresOn, 'MM/DD/YYYY'),
        )
        .min(
          formatDate(getPriorDateLimit(), 'MM/DD/YYYY'),
          'Date must be after ' + formatDate(getPriorDateLimit(), 'MM/DD/YYYY'),
        ),
      amount: Yup.number()
        .moreThan(299.99, `Amount should be at least $300.`)
        .lessThan(application ? application.totalFinancing + 1 : 5001, 'Amount too high.')
        .required('Please enter an amount.'),
      ...infoValues.reduce(
        (previous, _current, index) =>
          Object.assign(previous, {
            [`systemMake${index}`]: Yup.string().test({
              name: 'required',
              exclusive: false,
              message: 'System make is required.',
              test: function(value) {
                const systemModel = this.resolve(Yup.ref(`systemModel${index}`));
                const systemSerialNumber = this.resolve(Yup.ref(`systemSerialNumber${index}`));
                const systemDescription = this.resolve(Yup.ref(`systemDescription${index}`));
                if (systemModel || systemSerialNumber || systemDescription || !index)
                  return !!value;
                return true;
              },
            }),
            [`systemModel${index}`]: Yup.string().test({
              name: 'required',
              exclusive: false,
              message: 'System model is required.',
              test: function(value) {
                const systemMake = this.resolve(Yup.ref(`systemMake${index}`));
                const systemSerialNumber = this.resolve(Yup.ref(`systemSerialNumber${index}`));
                const systemDescription = this.resolve(Yup.ref(`systemDescription${index}`));
                if (systemMake || systemSerialNumber || systemDescription || !index) return !!value;
                return true;
              },
            }),
            [`systemSerialNumber${index}`]: Yup.string()
              .test({
                name: 'required',
                exclusive: false,
                message: 'System serial number is required.',
                test: function(value) {
                  const systemMake = this.resolve(Yup.ref(`systemMake${index}`));
                  const systemModel = this.resolve(Yup.ref(`systemModel${index}`));
                  const systemDescription = this.resolve(Yup.ref(`systemDescription${index}`));
                  if (systemMake || systemModel || systemDescription || !index) return !!value;
                  return true;
                },
              })
              .test({
                name: 'emptyString',
                exclusive: false,
                message: 'Invalid Serial Number.',
                test: function(value) {
                  return value ? value.trim() !== '' : true;
                },
              })
              .test({
                name: 'allCharEqual',
                exclusive: false,
                message: 'Invalid Serial Number.',
                test: function(value) {
                  return value ? !/^(.)\1*$/.test(value.trim()) : true;
                },
              }),
            [`systemDescription${index}`]: Yup.string().test({
              name: 'required',
              exclusive: false,
              message: 'System description is required.',
              test: function(value) {
                const systemMake = this.resolve(Yup.ref(`systemMake${index}`));
                const systemSerialNumber = this.resolve(Yup.ref(`systemSerialNumber${index}`));
                const systemModel = this.resolve(Yup.ref(`systemModel${index}`));
                if (systemMake || systemSerialNumber || systemModel || !index) return !!value;
                return true;
              },
            }),
          }),
        {},
      ),
    }),

    handleSubmit: values => {
      let selectedBrands = [];

      infoValues.forEach((_infoValue, index) => {
        if (
          values[`systemMake${index}`] &&
          values[`systemModel${index}`] &&
          values[`systemSerialNumber${index}`] &&
          values[`systemDescription${index}`]
        )
          selectedBrands.push({
            brand: values[`systemMake${index}`],
            description: values[`systemDescription${index}`],
            modelNumber: values[`systemModel${index}`],
            serialNumber: values[`systemSerialNumber${index}`],
          });
      });

      const ftlBodyRequest = {
        equipment: noEquipmentSelected ? [] : selectedBrands,
        requestAmount: values.amount,
        installationDate: values.completedDate,
        selectedDistributor: noEquipmentSelected ? null : selectedDistributor,
        serviceDescription: null,
      };

      dispatchChangeSoftPullValue('ftlBodyRequest', ftlBodyRequest);

      if (ftlOptionsResponse) {
        if (ftlOptionsResponse.selectProduct === true) {
          dispatchFtlFetchProducts(application.id, values.amount);
          setSeeProducts(true);
        } else {
          dispatchFtlSetAmount(application.id, values.amount);
          setSeePreview(true);
        }
      } else if (ftlUploadResponse) {
        if (ftlUploadResponse.selectProduct === true) {
          dispatchFtlFetchProducts(application.id, values.amount);
          setSeeProducts(true);
        } else {
          dispatchFtlSetAmount(application.id, values.amount);
          setSeePreview(true);
        }
      } else {
        if (ftlData && ftlData.ftlInformation && ftlData.ftlInformation.selectProduct === true) {
          dispatchFtlFetchProducts(application.id, values.amount);
          setSeeProducts(true);
        } else {
          dispatchFtlSetAmount(application.id, values.amount);
          setSeePreview(true);
        }
      }
    },
  })(CreateLoanDocumentsFormik);

  const CreateLoanDocumentsNoEquipmentFormik = ({ values }) => {
    return (
      <Form noValidate>
        <Row className="p-15">
          <Col>
            <Row>
              <Field
                formGroupClassName="mt-0 loan-doc-servicing-description"
                component={InputField}
                label="Servicing description:"
                name="servicingDescription"
                id="servicingDescription"
                type="text"
                placeholder="Description..."
                maxLength={50}
              />
            </Row>
          </Col>

          <Col sm="5">
            <Row>
              <Col sm="8">
                <Field
                  component={CustomDatePicker}
                  name="completedDate"
                  id="completedDate"
                  tooltipText={
                    'Date must be before ' +
                    formatDate(ftlData.ftlInformation.expiresOn, 'MM/DD/YYYY') +
                    ', the expiration date of the approval.'
                  }
                  label="What date will this project be completed?"
                />
              </Col>
              <Col />
            </Row>
            <Row>
              <Col sm="8">
                <Field
                  component={CurrencyFormat}
                  name="amount"
                  id="amount"
                  tooltipText={
                    'Up to $' +
                    application.totalFinancing +
                    '. If you need additional funding, contact FTL finance.'
                  }
                  type="text"
                  label="Enter the exact amount needed."
                />
              </Col>
              <Col />
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-end">
          <Col sm="12" xs="12" md="auto">
            <Button
              type="button"
              color="secondary"
              className="rounded-pill w-100 mt-1"
              outline
              onClick={() => {
                loanDoc(false);
              }}
            >
              Back
            </Button>
          </Col>

          <Col sm="12" xs="12" md="auto">
            <Button type="submit" color="primary" className="rounded-pill w-100 mt-1" outline>
              Next
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  const CreateLoanDocumentsNoEquipmentFormFormik = withFormik({
    mapPropsToValues: () => ({
      completedDate: jobDate ? jobDate : '',
      amount: amount ? amount : '',
      servicingDescription: '',
    }),

    validationSchema: Yup.object({
      servicingDescription: Yup.string().required('Please enter servicing description.'),

      completedDate: Yup.date()
        .required('Please enter the date.')
        .max(
          ftlData.ftlInformation.expiresOn,
          'Date must be earlier than ' + formatDate(ftlData.ftlInformation.expiresOn, 'MM/DD/YYYY'),
        )
        .min(
          formatDate(getPriorDateLimit(), 'MM/DD/YYYY'),
          'Date must be after ' + formatDate(getPriorDateLimit(), 'MM/DD/YYYY'),
        ),
      amount: Yup.number()
        .moreThan(299.99, `Amount should be at least $300.`)
        .lessThan(application ? application.totalFinancing + 1 : 5001, 'Amount too high.')
        .required('Please enter an amount.'),
    }),

    handleSubmit: values => {
      const ftlBodyRequest = {
        equipment: [],
        requestAmount: values.amount,
        installationDate: values.completedDate,
        selectedDistributor: null,
        serviceDescription: values.servicingDescription,
        repairsOnly: true,
      };

      dispatchChangeSoftPullValue('ftlBodyRequest', ftlBodyRequest);

      if (ftlOptionsResponse) {
        if (ftlOptionsResponse.selectProduct === true) {
          dispatchFtlFetchProducts(application.id, values.amount);
          setSeeProducts(true);
        } else {
          dispatchFtlSetAmount(application.id, values.amount);
          setSeePreview(true);
        }
      } else if (ftlUploadResponse) {
        if (ftlUploadResponse.selectProduct === true) {
          dispatchFtlFetchProducts(application.id, values.amount);
          setSeeProducts(true);
        } else {
          dispatchFtlSetAmount(application.id, values.amount);
          setSeePreview(true);
        }
      } else {
        if (ftlData && ftlData.ftlInformation && ftlData.ftlInformation.selectProduct === true) {
          dispatchFtlFetchProducts(application.id, values.amount);
          setSeeProducts(true);
        } else {
          dispatchFtlSetAmount(application.id, values.amount);
          setSeePreview(true);
        }
      }
    },
  })(CreateLoanDocumentsNoEquipmentFormik);

  return (
    ftlData &&
    ftlDistributors &&
    ftlDistributors.length > 0 && (
      <div className="app-panel">
        <div className="app-panel-body">
          <div className="title">
            <span>Application - {application.nameFirst + ' ' + application.nameLast}</span>
          </div>
          <div>
            {seePreview ? (
              <PreviewSection
                application={application}
                history={history}
                setSeePreview={setSeePreview}
                setSeeProducts={setSeeProducts}
              />
            ) : seeProducts && ftlFetchProductsResponse && ftlFetchProductsResponse.length > 0 ? (
              <Products
                application={application}
                products={ftlFetchProductsResponse}
                history={history}
                backButtonForPrograms={backButtonForPrograms}
                setSeePreview={setSeePreview}
                setSeeProducts={setSeeProducts}
              />
            ) : (
              <>
                <>
                  {noEquipmentSelected ? (
                    <CreateLoanDocumentsNoEquipmentFormFormik />
                  ) : (
                    <CreateLoanDocumentsFormFormik />
                  )}
                  {showNoEquipmentCheck && <NoEquipmentFormFormik />}
                </>
              </>
            )}
          </div>
        </div>
      </div>
    )
  );
};

const mapStateToProps = state => ({
  ftlData: state.softPull.ftlData,
  ftlDistributors: state.softPull.ftlDistributors,
  auth: state.auth,
  ftlBodyRequest: state.softPull.ftlBodyRequest,
  ftlOptionsResponse: state.softPull.ftlOptionsResponse,
  ftlFetchProductsResponse: state.softPull.ftlFetchProductsResponse,
  ftlUploadResponse: state.softPull.ftlUploadResponse,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangeSoftPullValue: (key, value) => dispatch(changeSoftPullValue(key, value)),
  dispatchFtlFetchProducts: (appId, reqAmount) => dispatch(ftlFetchProducts(appId, reqAmount)),
  dispatchFtlSetAmount: (appId, reqAmount) => dispatch(ftlSetAmount(appId, reqAmount)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateLoanDocuments);
