import { Form, withFormik } from 'formik';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import { currencyFormat } from '../../../../helper';
import {
  changeSoftPullValue,
  ftlGeneratePaperwork,
  printFundingInfo,
} from '../../../../redux/actions';
import MultiSelectIdentityVerification from '../../Form/Select/MultiSelectIdentityVerification';

const PreviewSection = props => {
  const {
    application,
    history,
    ftlData,
    ftlBodyRequest,
    dispatchFtlGeneratePaperwork,
    ftlGeneratePaperworkResponse,
    dispatchChangeSoftPullValue,
    ftlOptionsResponse,
    ftlFetchProductsResponse,
    dispatchPrintFundingInfo,
    setSeePreview,
    setSeeProducts,
    ftlUploadResponse,
    updateAmountResponse,
  } = props;

  const [selectedLanguage, setSelectedLanguage] = useState(0);

  let selected =
    ftlData && ftlData.ftlPrograms && ftlData.ftlPrograms.length > 0
      ? ftlData.ftlPrograms.find(opt => opt.selected === true)
      : null;

  if (selected === null || selected === undefined) {
    if (ftlOptionsResponse && ftlOptionsResponse.pfoOptionSelected) {
      selected =
        ftlData && ftlData.ftlPrograms && ftlData.ftlPrograms.length > 0
          ? ftlData.ftlPrograms.find(opt => opt.id === ftlOptionsResponse.pfoOptionSelected)
          : null;
    } else if (ftlFetchProductsResponse && ftlFetchProductsResponse.length > 0) {
      selected = ftlFetchProductsResponse.find(opt => opt.id === ftlBodyRequest.productID);
    } else {
      selected =
        ftlData && ftlData.ftlPrograms && ftlData.ftlPrograms.length > 0
          ? ftlData.ftlPrograms.find(opt => opt.id === ftlOptionsResponse.selectedProgram)
          : null;
    }
  }

  if (
    updateAmountResponse &&
    updateAmountResponse.pfoOptionSelected &&
    updateAmountResponse.loanMoreOptions &&
    updateAmountResponse.loanMoreOptions.length > 0
  ) {
    selected = updateAmountResponse.loanMoreOptions.find(
      opt => opt.id === updateAmountResponse.pfoOptionSelected,
    );
  }

  if (
    updateAmountResponse &&
    updateAmountResponse.selectedProgram &&
    updateAmountResponse.approveMoreOptions &&
    updateAmountResponse.approveMoreOptions.length > 0
  ) {
    selected = updateAmountResponse.approveMoreOptions.find(
      opt => opt.id === updateAmountResponse.selectedProgram,
    );
  }

  let selectedProgram = null;

  if (ftlFetchProductsResponse && ftlFetchProductsResponse.length > 0) {
    selectedProgram = ftlFetchProductsResponse.find(opt => opt.id === ftlBodyRequest.productID);
  }

  let estimatedPayment = updateAmountResponse?.monthlyPayment
    ? updateAmountResponse.monthlyPayment
    : '';

  const amount = ftlBodyRequest ? ftlBodyRequest.requestAmount : application.totalFinancing;

  let approveMoreFee = 0;
  let deducted = 0;
  let depositedAmount = 0;

  let approveFeeText = 'Approve More Fee';

  let sacFee = updateAmountResponse?.SACFee ? `$` + updateAmountResponse?.SACFee : null;
  let buyDownFee = updateAmountResponse?.buyDownFee ? `$` + updateAmountResponse?.buyDownFee : null;

  approveFeeText =
    updateAmountResponse && updateAmountResponse.loanMoreFee ? 'Loan More Fee' : approveFeeText;
  approveMoreFee =
    updateAmountResponse && updateAmountResponse.approveMoreFee
      ? updateAmountResponse.approveMoreFee
      : updateAmountResponse && updateAmountResponse.loanMoreFee
      ? updateAmountResponse.loanMoreFee
      : 0;
  deducted =
    updateAmountResponse && updateAmountResponse.deducted ? updateAmountResponse.deducted : 0;
  depositedAmount =
    updateAmountResponse && updateAmountResponse.depositedAmount
      ? updateAmountResponse.depositedAmount
      : 0;

  if (selectedProgram) {
    estimatedPayment = selectedProgram.monthlyPayment
      ? selectedProgram.monthlyPayment
      : estimatedPayment;
    sacFee = selectedProgram.SACFee && selectedProgram.SACFee > 0 ? selectedProgram.SacFee : sacFee;
    buyDownFee =
      selectedProgram.buyDownFee && selectedProgram.buyDownFee > 0
        ? selectedProgram.buyDownFee
        : buyDownFee;
    approveMoreFee = selectedProgram.approveMoreFee
      ? selectedProgram.approveMoreFee
      : selectedProgram.loanMoreFee
      ? selectedProgram.loanMoreFee
      : approveMoreFee;
    deducted = selectedProgram.deducted ? selectedProgram.deducted : deducted;
    depositedAmount = selectedProgram.depositedAmount
      ? selectedProgram.depositedAmount
      : depositedAmount;
  }

  const languages = [
    {
      value: 0,
      label: 'English',
    },
    {
      value: 1,
      label: 'Spanish',
    },
  ];

  const back = () => {
    if (ftlOptionsResponse) {
      if (ftlOptionsResponse.selectProduct === true) {
        setSeeProducts(true);
        setSeePreview(false);
      } else {
        setSeeProducts(false);
        setSeePreview(false);
      }
    } else if (ftlUploadResponse) {
      if (ftlUploadResponse.selectProduct === true) {
        setSeeProducts(true);
        setSeePreview(false);
      } else {
        setSeeProducts(false);
        setSeePreview(false);
      }
    } else {
      if (ftlData && ftlData.ftlInformation && ftlData.ftlInformation.selectProduct === true) {
        setSeeProducts(true);
        setSeePreview(false);
      } else {
        setSeeProducts(false);
        setSeePreview(false);
      }
    }
  };

  const returnToDashboard = () => {
    window.location.href = process.env.REACT_APP_DEALER_PORTAL_URL + '/portal/dashboard';
  };

  const print = () => {
    const productId = ftlBodyRequest.productID ? ftlBodyRequest.productID : null;
    dispatchPrintFundingInfo(application.id, amount, productId);
  };

  const generatePaperwork = () => {
    dispatchFtlGeneratePaperwork(application.id, ftlBodyRequest, history);
  };

  const SelectLanguageFormmik = ({ values }) => {
    return (
      <Form noValidate>
        <MultiSelectIdentityVerification
          labelClassName="grey"
          label={'Loan documents language set to:'}
          name="language"
          options={languages}
          selectedValues={languages
            .filter(x => x.value === selectedLanguage)
            .map((language, index) => ({
              value: language.value,
              label: language.label,
            }))}
          onChange={e => {
            dispatchChangeSoftPullValue('ftlBodyRequest', {
              ...ftlBodyRequest,
              language: e.label.toLowerCase(),
            });
            setSelectedLanguage(e.value);
          }}
        />
      </Form>
    );
  };

  const SelectLanguageFormFormmik = withFormik({})(SelectLanguageFormmik);

  return (
    updateAmountResponse && (
      <>
        {ftlGeneratePaperworkResponse && ftlGeneratePaperworkResponse.status === true ? (
          <Row className="justify-content-center mt-5">
            <Col sm="12" xs="12" md="auto">
              <Row className="p-10 justify-content-center">
                <p className="fs-20">The Loan Documents have been sent.</p>
              </Row>
              <Row className="p-10 justify-content-center">
                <Button
                  type="submit"
                  onClick={() => {
                    returnToDashboard();
                  }}
                >
                  Return to Dashboard
                </Button>
              </Row>
            </Col>
          </Row>
        ) : (
          <div className="table-simple">
            <Row className="p-10">
              <Col>
                <h4>Please review the following before creating the loan documents.</h4>
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                {selected && (
                  <>
                    <div className="outer-content">
                      <Row className="p-10">
                        <Col>Product</Col>
                        <Col className="text-align-right">
                          {selected.name ? selected.name : selected.label}
                        </Col>
                      </Row>
                    </div>
                    <div className="outer-content">
                      <Row className="p-10">
                        <Col>Amount Financed</Col>
                        <Col className="text-align-right">{currencyFormat(parseInt(amount))}</Col>
                      </Row>
                    </div>
                    <div className="outer-content">
                      <Row className="p-10">
                        <Col>Monthly Payment</Col>
                        <Col className="text-align-right">{estimatedPayment}</Col>
                      </Row>
                    </div>
                  </>
                )}

                <div className="outer-content">
                  <Row className="p-10">
                    <Col>{approveFeeText}</Col>
                    <Col className="text-align-right">{currencyFormat(approveMoreFee)}</Col>
                  </Row>
                </div>
                {sacFee && (
                  <div className="outer-content">
                    <Row className="p-10">
                      <Col>SAC Fee</Col>
                      <Col className="text-align-right">{currencyFormat(sacFee)}</Col>
                    </Row>
                  </div>
                )}
                {buyDownFee && (
                  <div className="outer-content">
                    <Row className="p-10">
                      <Col>Buy Down Fee</Col>
                      <Col className="text-align-right">{currencyFormat(buyDownFee)}</Col>
                    </Row>
                  </div>
                )}
                <div className="outer-content">
                  <Row className="p-10">
                    <Col>Total Deducted from Funding</Col>
                    <Col className="text-align-right">{currencyFormat(deducted)}</Col>
                  </Row>
                </div>
                <div className="outer-content">
                  <Row className="p-10">
                    <Col>Deposit Amount</Col>
                    <Col className="text-align-right">{currencyFormat(depositedAmount)}</Col>
                  </Row>
                </div>

                <Row className="pt-10 pl-25">
                  <SelectLanguageFormFormmik />
                </Row>
                <Row className="pl-25">
                  {`Documents will be sent to ${application.nameFirst} ${application.nameLast} - ${
                    application.email
                  }`}
                </Row>
              </Col>
              <Col sm="6" />
            </Row>
            <Row>
              <Col />
              <Col>
                <Row className="justify-content-center">
                  <Col sm="12" xs="12" md="auto">
                    <Button
                      type="button"
                      color="primary"
                      className="rounded-pill w-100 mt-1"
                      onClick={() => {
                        print();
                      }}
                    >
                      Print
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row className="justify-content-end">
                  <Col sm="12" xs="12" md="auto">
                    <Button
                      type="button"
                      color="secondary"
                      className="rounded-pill w-100 mt-1"
                      outline
                      onClick={() => {
                        back();
                      }}
                    >
                      Back
                    </Button>
                  </Col>

                  <Col sm="12" xs="12" md="auto">
                    <Button
                      type="submit"
                      color="primary"
                      className=" w-100 mt-1"
                      onClick={() => {
                        generatePaperwork();
                      }}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}
      </>
    )
  );
};

const mapStateToProps = state => ({
  ftlData: state.softPull.ftlData,
  ftlDistributors: state.softPull.ftlDistributors,
  auth: state.auth,
  ftlBodyRequest: state.softPull.ftlBodyRequest,
  ftlOptionsResponse: state.softPull.ftlOptionsResponse,
  ftlFetchProductsResponse: state.softPull.ftlFetchProductsResponse,
  ftlGeneratePaperworkResponse: state.softPull.ftlGeneratePaperworkResponse,
  ftlUploadResponse: state.softPull.ftlUploadResponse,
  updateAmountResponse: state.softPull.updateAmountResponse,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangeSoftPullValue: (key, value) => dispatch(changeSoftPullValue(key, value)),
  dispatchPrintFundingInfo: (appId, amount, productId) =>
    dispatch(printFundingInfo(appId, amount, productId)),
  dispatchFtlGeneratePaperwork: (appId, data, history) =>
    dispatch(ftlGeneratePaperwork(appId, data, history)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PreviewSection);
