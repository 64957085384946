import AccountEmail from './AccountEmail';
import React from 'react';
import AccountPassword from './AccountPassword';
import LoanFor from './LoanFor';
import NotAllowed from './NotAllowed';
import ScoreError from './ScoreError';
import UserAddress from './UserAddress';
import UserInfo from './UserInfo';
import UserLastInfo from './UserLastInfo';
import GreenskyDeclinedToMicrof from './GreenskyDeclinedToMicrof';
import InvalidResponse from './InvalidResponse';

const PreQualificationFlow = [
  <AccountEmail>
    <h4>Let’s start with your email address.</h4>
  </AccountEmail>,
  <AccountPassword>
    <h4>Choose a password that you can remember.</h4>
  </AccountPassword>,
  <LoanFor />,
  <UserInfo>
    <h4>What’s your name and number?</h4>
  </UserInfo>,
  <UserAddress>
    <h4>What is the address where the service will be completed?</h4>
  </UserAddress>,
  <UserLastInfo />,
  <NotAllowed />,
  <ScoreError>
    <h4>We could not return a Soft Pull score.</h4>
  </ScoreError>,
  <GreenskyDeclinedToMicrof />,
  <InvalidResponse />,
];

export default PreQualificationFlow;

export const GreenSkyPreQualificationFlowPageNumber = {
  AccountEmail: 0,
  AccountPassword: 1,
  LoanFor: 2,
  UserInfo: 3,
  UserAddress: 4,
  UserLastInfo: 5,
  NotAllowed: 6,
  ScoreError: 7,
  GreenskyDeclinedToMicrof: 8,
  InvalidResponse: 9,
};
