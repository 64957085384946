import { Field, Form, withFormik } from 'formik';
import React from 'react';
import CustomInput from '../../../components/UI/Form/Input/CustomInput';
import Button from '../../../components/UI/Button/CustomButton';
import { isNoreplyEmail } from '../../../helper';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { sendEmailConsumerUrl } from '../../../redux/actions';

const EmailConsumerForm = props => {
  const { dealerId, dispatchSendEmailConsumerUrl, emailCustomerUrlResponse } = props;

  const EmailConsumerFrom = () => {
    return (
      <Form noValidate>
        <Row>
          <Col md="6">
            <Field
              component={CustomInput}
              label="Consumer Email Address"
              name="email"
              id="email"
              type="email"
            />
          </Col>
          <Col className="pt-25" md="3">
            <Button type="submit" title="Send" />
          </Col>
        </Row>
      </Form>
    );
  };

  const EmailConsumerFormFormik = withFormik({
    mapPropsToValues: () => ({
      email: '',
    }),

    validationSchema: Yup.object({
      email: Yup.string()
        .email('Please enter a valid email.')
        .required('Please enter an email.')
        .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),
    }),

    handleSubmit: values => {
      dispatchSendEmailConsumerUrl(values.email, dealerId);
    },
  })(EmailConsumerFrom);

  return (
    <>
      <Col xs="12" md="4">
        <p className="mt-30">
          <b>Email Consumer URL to the customer:</b>
        </p>
      </Col>
      <Col>
        <EmailConsumerFormFormik />
      </Col>
    </>
  );
};

const mapStateToProps = state => ({
  emailCustomerUrlResponse: state.softPull.emailCustomerUrlResponse,
});

const mapDispatchToProps = dispatch => ({
  dispatchSendEmailConsumerUrl: (email, dealerId) =>
    dispatch(sendEmailConsumerUrl(email, dealerId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmailConsumerForm);
