import React from 'react';
import { Col, Row } from 'reactstrap';
import Button from '../../../components/UI/Button/CustomButton';

export const SummaryCard = ({ buttonClickHandler, dealerStat, children, className, arrow }) => (
  <Col className={'summary-card ' + className}>
    <Row className="dealer-stat">
      <Col>
        <Row noGutters className="h-25">
          <Col xs="8">
            <p className="mb-0">{children}</p>
          </Col>
          <Col xs="4" className="d-flex justify-content-end">
            {arrow}
          </Col>
        </Row>
        <Row className="justify-content-center align-items-center h-75">
          <h1>
            <b>{dealerStat === 0 ? 0 : dealerStat || 'N/A'}</b>
          </h1>
        </Row>
      </Col>
    </Row>
    <Row noGutters className="justify-content-center mt-3">
      <Button
        className="rounded-pill dashboard-button border bg-white w-75"
        title="Revisit"
        clickHandler={buttonClickHandler}
      />
    </Row>
  </Col>
);
