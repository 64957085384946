import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { reactGAPageview, reactGAEvent } from '../../../helper';
import { getDealerPortalURL } from '../../../config/settings';
import { getEnerBankStatus } from '../../../assets/js/ApplicationStatusEnum';
import Button from '../../../components/UI/Button/CustomButton';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { resetState, resetNewAppState } from '../../../redux/actions/index';

const Pending = props => {
  const {
    dealerUserId,
    did,
    dispatchSubmitMindfire,
    email,
    dispatchChangePageTitle,
    status,
    history,
    user,
    dispatchResetNewAppState,
    dispatchResetState,
  } = props;

  const isUserLoggedIn = !did && user && user.dealerUser;
  const buttonTitle = isUserLoggedIn ? 'Return to Dashboard' : 'Return to Login Page';
  const returnUrl = isUserLoggedIn ? '/portal/dashboard' : getDealerPortalURL();

  useEffect(() => {
    dispatchChangePageTitle('Pending Application');
    reactGAPageview({
      path: '/ca_ebpending',
      trackerNames: [],
      title: 'ca_ebpending',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const handleClick = () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Done',
      did,
      dealerUserId,
    });
    dispatchSubmitMindfire({
      email: email,
      lastPage: '11026',
      dealerUserId: dealerUserId,
      did: did,
    });
    if (!isUserLoggedIn) {
      dispatchResetState(props);
      window.location.href = returnUrl;
    } else {
      dispatchResetNewAppState();
      history.push(returnUrl);
    }
  };

  const st = getEnerBankStatus(status);
  return (
    <Container>
      <Row className="justify-content-center mt-2">
        <Col xs="auto" md="8">
          <h3 className="text-center">
            <b>{st ? st.description : 'UNKNOWN STATUS'}</b>
          </h3>
        </Col>
      </Row>
      <Row className="justify-content-center mt-2">
        <Col xs="auto" md="8">
          <h5 className="text-center">
            <b>Applicant(s)</b>
          </h5>
        </Col>
      </Row>
      <Row className="justify-content-center mt-2">
        <Col xs="auto" md="8">
          <h5 className="text-center">
            Your application is currently under review. Please hand your device back to the
            contractor to continue.
          </h5>
        </Col>
      </Row>
      <Row className="justify-content-center mt-5">
        <Col xs="auto" md="8">
          <h5 className="text-center">
            <b>Contractor</b>
          </h5>
        </Col>
      </Row>
      <Row className="justify-content-center mt-2">
        <Col xs="auto" md="8">
          <h5 className="text-center">
            If you have not received a credit decision in 15 minutes, please call EnerBank USA at
            1-877-477-5900
          </h5>
        </Col>
      </Row>
      <Row className="justify-content-center mt-2">
        <Col xs="auto">
          <Button type="button" title={buttonTitle} clickHandler={handleClick} />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  email: state.preQualificationInformation.email,
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
  dispatchResetState: props => dispatch(resetState(props)),
  dispatchResetNewAppState: () => dispatch(resetNewAppState()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Pending),
);
