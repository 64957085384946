import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import ownershipEnum from '../../../../assets/js/OwnershipEnum';
import usStates from '../../../../assets/js/USStateEnum';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import Input from '../../../../components/UI/Form/Input/CustomInput';
import CurrencyFormat from '../../../../components/UI/Form/NumberFormat/CurrencyFormat';
import Select from '../../../../components/UI/Form/Select/Select';
import {
  COAPPCITY_TOOLTIP_TEXT,
  COAPPSTREET_TOOLTIP_TEXT,
  COAPP_OWNERSHIP_TOOLTIP_TEXT,
  COAPP_PROPERTY_MONTHLY_PAYMENT_TOOLTIP_TEXT,
  COAPP_PROPERTY_MONTHS_TOOLTIP_TEXT,
  COAPP_PROPERTY_YEARS_TOOLTIP_TEXT,
  STATE_TOOLTIP_TEXT,
  ZIPCODE_TOOLTIP_TEXT,
} from '../../../../constants/tooltipTexts';
import { reactGAEvent, reactGAPageview } from '../../../../helper';
import {
  changeFoundationInfoValue,
  changePage,
  submitMindfire,
} from '../../../../redux/actions/index';
import { FoundationFlowPageNumber } from './FoundationFlow';

const CoAppAddress = props => {
  const {
    email,
    dispatchChangeInfoValue,
    pqi,
    dispatchChangePage,
    children,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    foundationInformation: {
      coAppStreet,
      coAppCity,
      coAppZipCode,
      coAppState,
      isSameAddress,
      coApplicantOwnership,
      coApplicantPropertyMonthlyPayment,
      coApplicantPropertyYears,
      coApplicantPropertyMonths,
      ownership,
      propertyMonthlyPayment,
      propertyYears,
      propertyMonths,
    },
    isReview,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_fdcoappinfo2',
      trackerNames: [],
      title: 'ca_fdcoappinfo2',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const setIsSameAddress = bool => {
    dispatchChangeInfoValue('isSameAddress', bool);
  };

  const CoAppAddressForm = () => (
    <Form>
      <Label>
        <b>The address is the same as the applicant's.</b>
      </Label>
      <Row>
        <Col sm="6">
          <Button
            className={isSameAddress ? '' : 'inactive'}
            type="button"
            title="Yes"
            clickHandler={() => setIsSameAddress(true)}
          />
        </Col>
        <Col sm="6">
          <Button
            className={!isSameAddress ? '' : 'inactive'}
            type="button"
            title="No"
            clickHandler={() => setIsSameAddress(false)}
          />
        </Col>
      </Row>
      <Field
        disabled={isSameAddress}
        component={Input}
        label="Street"
        name="coAppStreet"
        id="coAppStreet"
        tooltipText={COAPPSTREET_TOOLTIP_TEXT}
        type="text"
      />
      <Row>
        <Col sm="6">
          <Field
            disabled={isSameAddress}
            component={Input}
            label="City"
            name="coAppCity"
            id="coAppCity"
            tooltipText={COAPPCITY_TOOLTIP_TEXT}
            type="text"
          />
        </Col>
        <Col sm="6">
          <Field
            disabled={isSameAddress}
            component={Select}
            label="State"
            name="coAppState"
            id="coAppState"
            tooltipText={STATE_TOOLTIP_TEXT}
            type="select"
            selectValues={usStates}
            selectFirstName="Select State"
          />
        </Col>
      </Row>
      <Field
        disabled={isSameAddress}
        component={Input}
        label="ZIP Code"
        name="coAppZipCode"
        id="coAppZipCode"
        tooltipText={ZIPCODE_TOOLTIP_TEXT}
        type="text"
        maxLength={10}
      />
      <Field
        component={Select}
        label="Ownership"
        name="coApplicantOwnership"
        id="coApplicantOwnership"
        tooltipText={COAPP_OWNERSHIP_TOOLTIP_TEXT}
        type="select"
        selectValues={ownershipEnum}
        selectFirstName="Select One"
      />
      <Field
        component={CurrencyFormat}
        placeholder="$0"
        label="Monthly Payment (if no payment enter $0)"
        name="coApplicantPropertyMonthlyPayment"
        id="coApplicantPropertyMonthlyPayment"
        tooltipText={COAPP_PROPERTY_MONTHLY_PAYMENT_TOOLTIP_TEXT}
        type="text"
      />
      <Row className="mt-2">
        <Col>
          <Label>
            <b>Time at address</b>
          </Label>
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          <Field
            component={Input}
            placeholder="Years"
            formGroupClass="mt-0 mb-2"
            labelClassName="d-none"
            name="coApplicantPropertyYears"
            id="coApplicantPropertyYears"
            tooltipText={COAPP_PROPERTY_YEARS_TOOLTIP_TEXT}
            type="number"
            min={0}
          />
        </Col>
        <Col sm="6">
          <Field
            component={Input}
            placeholder="Months"
            formGroupClass="mt-0 mb-2"
            labelClassName="d-none"
            name="coApplicantPropertyMonths"
            id="coApplicantPropertyMonths"
            tooltipText={COAPP_PROPERTY_MONTHS_TOOLTIP_TEXT}
            type="number"
            min={0}
          />
        </Col>
      </Row>
      <Button type="submit" title="Next" />
    </Form>
  );

  const CoAppAddressFormFormik = withFormik({
    mapPropsToValues: () => ({
      coAppStreet: isSameAddress && pqi.street ? pqi.street : coAppStreet || '',
      coAppCity: isSameAddress && pqi.city ? pqi.city : coAppCity || '',
      coAppZipCode: isSameAddress && pqi.zipcode ? pqi.zipcode : coAppZipCode || '',
      coAppState: isSameAddress && pqi.state ? pqi.state : coAppState || '',
      coApplicantOwnership:
        isSameAddress && ownership !== null ? ownership : coApplicantOwnership || '',
      coApplicantPropertyMonthlyPayment:
        isSameAddress && propertyMonthlyPayment !== null
          ? propertyMonthlyPayment
          : coApplicantPropertyMonthlyPayment || '',
      coApplicantPropertyYears:
        isSameAddress && propertyYears !== null ? propertyMonths : coApplicantPropertyYears || '',
      coApplicantPropertyMonths:
        isSameAddress && propertyMonths !== null ? propertyMonths : coApplicantPropertyMonths || '',
    }),

    validationSchema: Yup.object({
      coAppStreet: Yup.string().required('Please enter a street.'),
      coAppCity: Yup.string().required('Please enter a city.'),
      coAppZipCode: Yup.string()
        .matches(/^\d{5}?$/, {
          message: 'Invalid zip code.',
          excludeEmptyString: true,
        })
        .required('Please enter a zip code.'),
      coAppState: Yup.string().required('Please select a state.'),
      coApplicantOwnership: Yup.string().required('Please select an option.'),
      coApplicantPropertyMonthlyPayment: Yup.number().required(
        'Please enter your monthly payment.',
      ),
      coApplicantPropertyYears: Yup.number()
        .moreThan(-1, 'Please enter a positive number.')
        .integer('Please enter an integer number.')
        .max(70, 'Must be less than or equal to 70.')
        .required('Please enter years.'),
      coApplicantPropertyMonths: Yup.number()
        .moreThan(-1, 'Please enter a positive number.')
        .integer('Please enter an integer number.')
        .max(11, 'Must be less than or equal to 11.')
        .required('Please enter months.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      dispatchChangeInfoValue('coAppStreet', values.coAppStreet);
      dispatchChangeInfoValue('coAppCity', values.coAppCity);
      dispatchChangeInfoValue('coAppZipCode', values.coAppZipCode);
      dispatchChangeInfoValue('coAppState', values.coAppState);
      dispatchChangeInfoValue('coApplicantOwnership', values.coApplicantOwnership);
      dispatchChangeInfoValue(
        'coApplicantPropertyMonthlyPayment',
        values.coApplicantPropertyMonthlyPayment,
      );
      dispatchChangeInfoValue('coApplicantPropertyYears', values.coApplicantPropertyYears);
      dispatchChangeInfoValue('coApplicantPropertyMonths', values.coApplicantPropertyMonths);
      dispatchChangePage(FoundationFlowPageNumber.CoAppIdentificationInfo);
      dispatchSubmitMindfire({
        email: email,
        lastPage: '10017',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(CoAppAddressForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={
            isReview
              ? FoundationFlowPageNumber.ApplicationReview
              : FoundationFlowPageNumber.CoAppInfo
          }
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <CoAppAddressFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  microfOnly: state.microfInformation.microfOnly,
  pqi: state.preQualificationInformation,
  foundationInformation: state.foundationInformation,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  email: state.preQualificationInformation.email,
  isReview: state.isReview,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeInfoValue: (key, value) => dispatch(changeFoundationInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

CoAppAddress.propTypes = {
  dispatchChangePage: PropTypes.func,
  foundationInformation: PropTypes.object,
  dispatchChangeInfoValue: PropTypes.func,
  pqi: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CoAppAddress);
