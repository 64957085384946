import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { useVSPixelScript } from '../../../assets/hooks';
import {
  getApplicationStatus,
  isMosaicApproved,
  isMosaicDeclined,
  isMosaicIntermediates,
} from '../../../assets/js/ApplicationStatusEnum';
import Button from '../../../components/UI/Button/CustomButton';
import { getDealerPortalURL, getMosaicLoginUrl, getMosaicUrl } from '../../../config/settings';
import { reactGAPageview } from '../../../helper';
import {
  changeMosaicInfoValue,
  changePage,
  getAppByIdAndToken,
  resetNewAppState,
  resetState,
  submitMindfire,
  updateApplicationStatus,
} from '../../../redux/actions/index';
import { MosaicFlowPageNumber } from './MosaicFlow';

const mosaicUrl = getMosaicUrl();
const mosaicLoginUrl = getMosaicLoginUrl();
const mosaicMessageName = 'application_status';

const MosaicIFrame = props => {
  const {
    did,
    user,
    dispatchChangePage,
    dealerUserId,
    mosaicApplicationUrl,
    dispatchResetState,
    dispatchResetNewAppState,
    dispatchChangeMosaicInfoValue,
    iFrameHeight,
    history,
    dispatchUpdateApplicationStatus,
    appToken,
    applicationStatus,
  } = props;
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [isPortalUrlVisible, setIsPortalUrlVisible] = useState(false);
  const isUserLoggedIn = !did && user && user.dealerUser;
  const returnUrl = isUserLoggedIn ? '/portal/dashboard' : getDealerPortalURL();

  const receiveMessage = ({ origin, data }) => {
    if (origin === mosaicUrl && data?.name === mosaicMessageName) {
      const {
        data: { decision },
        frameInfo: {
          recommendedMinimumSize,
          recommendedScrollTo,
          recommendedContinueButtonVisibility,
        },
      } = data;

      if (recommendedScrollTo) {
        window.scrollTo(recommendedScrollTo.x, recommendedScrollTo.y);
      }
      if (decision) {
        const mosaicDecision = `MOSAIC_${decision}`;
        if (
          recommendedContinueButtonVisibility?.visible &&
          (isMosaicApproved(mosaicDecision) || isMosaicIntermediates(mosaicDecision))
        ) {
          if (recommendedMinimumSize) {
            dispatchChangeMosaicInfoValue('iFrameHeight', recommendedMinimumSize.height);
          }
          setIsButtonVisible(recommendedContinueButtonVisibility?.visible || false);
          setIsPortalUrlVisible(true);
          dispatchChangeMosaicInfoValue('mosaicDecision', mosaicDecision);
        } else if (isMosaicDeclined(mosaicDecision)) {
          dispatchChangeMosaicInfoValue('mosaicDecision', mosaicDecision);
          dispatchChangePage(MosaicFlowPageNumber.Declined);
        }
      }
    }
  };

  useVSPixelScript();

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    reactGAPageview({
      path: '/ca_pploanamount',
      trackerNames: [],
      title: 'ca_pploanamount',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const mosaicClickHandler = async () => {
    await dispatchUpdateApplicationStatus(getApplicationStatus(applicationStatus).value, appToken);
    if (!isUserLoggedIn) {
      dispatchResetState(props);
      window.location.href = returnUrl;
    } else {
      dispatchResetNewAppState();
      history.push(returnUrl);
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          <iframe
            title="MosaicIFrameHolder"
            id="mosaicIFrame"
            frameBorder="0"
            height={iFrameHeight}
            width="100%"
            src={mosaicApplicationUrl}
          />
        </Col>
      </Row>
      {isPortalUrlVisible && (
        <Row className="justify-content-center mb-3 mt-3">
          <Col xs="auto">
            <b>
              <a href="https://tap.joinmosaic.com/login" target="_blank" rel="noopener noreferrer">
                <h5>{mosaicLoginUrl}</h5>
              </a>
            </b>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Button
            className={`${!isButtonVisible ? 'hidden' : ''}`}
            title="Continue"
            clickHandler={mosaicClickHandler}
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  mosaicApplicationUrl: state.softPull.applicationSubmitDTO.mosaicApplicationUrl,
  appId: state.softPull.applicationSubmitDTO.applicationId,
  appToken: state.softPull.applicationSubmitDTO.consumerToken,
  iFrameHeight: state.mosaicInformation.iFrameHeight,
  user: state.auth.user,
  applicationStatus: state.mosaicInformation.mosaicDecision,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchResetState: props => dispatch(resetState(props)),
  dispatchResetNewAppState: () => dispatch(resetNewAppState()),
  dispatchChangeMosaicInfoValue: (key, value) => dispatch(changeMosaicInfoValue(key, value)),
  dispatchGetAppByIdAndToken: (appToken, appId, history) =>
    dispatch(getAppByIdAndToken(appToken, appId, history)),
  dispatchUpdateApplicationStatus: (applicationStatus, consumerToken) =>
    dispatch(updateApplicationStatus(applicationStatus, consumerToken)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(MosaicIFrame),
);
