import React from 'react';
import { Field, Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Col, Row, FormGroup, Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import { changeAuthValue, passwordReset } from '../../redux/actions/index';
import Input from '../../components/UI/Form/Input/CustomInput';
import Button from '../../components/UI/Button/CustomButton';
import { withRouter } from 'react-router-dom';
import { getAddedFrom } from '../../config/settings';
import { EMAIL_TOOLTIP_TEXT, LASTNAME_TOOLTIP_TEXT } from '../../constants/tooltipTexts';
import { isNoreplyEmail } from '../../helper';

const ForgotPassword = props => {
  const { auth, dispatchPasswordReset, dispatchChangeAuthValue, history } = props;

  const handleBackClick = () => {
    dispatchChangeAuthValue('forgotPasswordMessage', null);
    dispatchChangeAuthValue('passwordRetrievalDTO', {
      email: null,
      lastName: null,
    });
  };

  const ForgotPasswordForm = ({ values, errors, touched }) => (
    <Form autoComplete="off">
      <Field
        component={Input}
        label="Email address"
        name="email"
        id="email"
        tooltipText={EMAIL_TOOLTIP_TEXT}
        type="email"
      />
      <Field
        component={Input}
        label="Last name"
        name="lastName"
        id="lastName"
        tooltipText={LASTNAME_TOOLTIP_TEXT}
        type="text"
      />
      {auth.forgotPasswordMessage && auth.forgotPasswordMessage === 'Unauthorized' ? (
        <Alert color="danger">No account found with that email address and last name</Alert>
      ) : null}
      <Row className="align-items-center">
        <Col sm={6}>
          <Button
            className="login-button btn btn-primary btn-lg btn-block"
            type="submit"
            title="Set Password"
            disabled={auth.passSet && auth.passSetMessage === 'Success'}
          />
        </Col>
        <Col sm={6}>
          <FormGroup className="d-flex justify-content-center">
            <Link to="/Login" onClick={handleBackClick}>
              Back to Login
            </Link>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );

  const ForgotPasswordFormFormik = withFormik({
    mapPropsToValues: () => ({
      email: auth.passwordRetrievalDTO.email || '',
      lastName: auth.passwordRetrievalDTO.lastName || '',
    }),

    validationSchema: Yup.object({
      email: Yup.string()
        .email('This email address is not valid')
        .required('This field is mandatory')
        .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),
      lastName: Yup.string().required('This field is mandatory'),
    }),

    handleSubmit: values => {
      const passwordRetrievalDTO = {
        email: values.email,
        lastName: values.lastName,
        addedFrom: getAddedFrom(),
      };
      dispatchPasswordReset(passwordRetrievalDTO, history);
    },
  })(ForgotPasswordForm);

  return (
    <div className="login-form">
      {auth.forgotPasswordMessage && auth.forgotPasswordMessage === 'success' ? (
        <>
          <h1>
            <b>Password Recovery Email Sent</b>
          </h1>
          <p>
            An email has been sent with password recovery instructions. Please check your inbox. The
            password token expires after 24 hours. If not completed within that timeframe, you will
            need to start over by clicking the "Forgot Password" button on the login page and follow
            the instructions.
          </p>
          <Form autoComplete="off">
            <Row className="align-items-center">
              <Col sm={6}>&nbsp;</Col>
              <Col sm={6}>
                <FormGroup className="d-flex justify-content-center">
                  <Link to="/Login" onClick={handleBackClick}>
                    Back to Login
                  </Link>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </>
      ) : (
        <>
          <h1>
            <b>Forgot Password?</b>
          </h1>
          <p>
            Please provide your <b>email address</b> and <b>last name</b>. An email will be sent
            with instructions for resetting your password.
          </p>
          <ForgotPasswordFormFormik />
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangeAuthValue: (key, value) => dispatch(changeAuthValue(key, value)),
  dispatchPasswordReset: (data, history) => dispatch(passwordReset(data, history)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ForgotPassword),
);
