import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import Application from '../../../components/UI/Application/Detail';
import { getApplication } from '../../../redux/actions/index';
import queryString from 'query-string';

const OpenApplications = props => {
  const { auth, dispatchGetApplication, application, history } = props;

  useEffect(() => {
    if (!auth.user || !auth.user.dealerUser) {
      history.push('/login');
    }
    const appId = queryString.parse(window.location.search).appId;
    if (appId) {
      dispatchGetApplication(appId);
    } else {
      history.push('/portal/applications-under-review');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Col>
      <Row>
        <Col>
          <h1>Loan Applications</h1>
        </Col>
      </Row>
      <Row>
        {application ? (
          <Application
            application={application}
            history={history}
            parentPath="/portal/applications-under-review"
          />
        ) : (
          <div className="app-panel">No application found.</div>
        )}
      </Row>
    </Col>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  application: state.softPull.application,
});

const mapDispatchToProps = dispatch => ({
  dispatchGetApplication: applicationId => dispatch(getApplication(applicationId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OpenApplications);
