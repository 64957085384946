import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import otherSourcesOfIncome from '../../../../assets/js/FoundationOtherSourceOfIncomeEnum';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import CurrencyFormat from '../../../../components/UI/Form/NumberFormat/CurrencyFormat';
import Select from '../../../../components/UI/Form/Select/Select';
import {
  changeFoundationInfoValue,
  changePage,
  submitMindfire,
} from '../../../../redux/actions/index';
import PropTypes from 'prop-types';
import { reactGAPageview, reactGAEvent } from '../../../../helper';
import { FoundationFlowPageNumber } from './FoundationFlow';
import IncomeFrequencyEnum from '../../../../assets/js/IncomeFrequencyEnum';
import {
  COAPP_ADDITIONAL_INCOME_TOOLTIP_TEXT,
  COAPP_INCOME_TYPE_TOOLTIP_TEXT,
  COAPP_OTHER_SOURCES_OF_INCOME_TOOLTIP_TEXT,
} from '../../../../constants/tooltipTexts';

const CoAppOtherIncome = props => {
  const {
    dispatchChangePage,
    dispatchChangeValue,
    foundationInformation,
    children,
    hasCoAppOtherIncome,
    email,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
  } = props;

  let otherSourcesOfIncomeChanged = { ...otherSourcesOfIncome };
  delete otherSourcesOfIncomeChanged[foundationInformation.coAppIncomeSource];

  useEffect(() => {
    reactGAPageview({
      path: '/ca_fdcoconsumerincome2',
      trackerNames: [],
      title: 'ca_fdcoconsumerincome2',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const AppOtherIncomeForm = () => (
    <Form>
      <label>
        <b>Do you have other sources of income (or benefits)?</b>
      </label>
      <Row>
        <Col sm="6">
          <Button
            className={hasCoAppOtherIncome ? '' : 'inactive'}
            type="button"
            title="Yes"
            clickHandler={() => dispatchChangeValue('hasCoAppOtherIncome', true)}
          />
        </Col>
        <Col sm="6">
          <Button
            className={!hasCoAppOtherIncome ? '' : 'inactive'}
            type="button"
            title="No"
            clickHandler={() => dispatchChangeValue('hasCoAppOtherIncome', false)}
          />
        </Col>
      </Row>
      <div className={hasCoAppOtherIncome ? '' : 'opacity-05'}>
        <Field
          disabled={!hasCoAppOtherIncome}
          component={Select}
          label="Source/Kind"
          name="coAppOtherSourcesOfIncome"
          id="coAppOtherSourcesOfIncome"
          tooltipText={COAPP_OTHER_SOURCES_OF_INCOME_TOOLTIP_TEXT}
          type="select"
          selectValues={otherSourcesOfIncomeChanged}
          selectFirstName="Select One"
        />
        <Field
          component={Select}
          label="Income (or benefits) Type"
          name="coAppOtherIncomeType"
          id="coAppOtherIncomeType"
          tooltipText={COAPP_INCOME_TYPE_TOOLTIP_TEXT}
          type="select"
          selectValues={IncomeFrequencyEnum}
          selectFirstName="Select Income Type"
        />
      </div>
      <Field
        component={CurrencyFormat}
        label="Amount"
        name="coAppAdditionalIncome"
        id="coAppAdditionalIncome"
        tooltipText={COAPP_ADDITIONAL_INCOME_TOOLTIP_TEXT}
        type="text"
      />
      <Button type="submit" title={foundationInformation.isReview ? 'Review' : 'Next'} />
    </Form>
  );

  const AppOtherIncomeFormFormik = withFormik({
    mapPropsToValues: () => ({
      coAppOtherSourcesOfIncome: foundationInformation.coAppOtherSourcesOfIncome || '',
      coAppOtherIncomeType: foundationInformation.coAppOtherIncomeType || '',
      coAppAdditionalIncome: foundationInformation.coAppAdditionalIncome || 0,
    }),

    validationSchema: Yup.object({
      coAppOtherSourcesOfIncome: hasCoAppOtherIncome
        ? Yup.string().required('Please select another source of income.')
        : Yup.string(),
      coAppOtherIncomeType: hasCoAppOtherIncome
        ? Yup.string().required('Please select the type of your income.')
        : Yup.string(),
      coAppAdditionalIncome: Yup.number()
        .required('Please enter your annual income.')
        .moreThan(-1, 'Please enter a positive number.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      dispatchChangeValue('coAppOtherSourcesOfIncome', values.coAppOtherSourcesOfIncome);
      dispatchChangeValue('coAppOtherIncomeType', values.coAppOtherIncomeType);
      dispatchChangeValue('coAppAdditionalIncome', values.coAppAdditionalIncome);
      dispatchChangePage(FoundationFlowPageNumber.ApplicationReview);
      dispatchSubmitMindfire({
        email: email,
        lastPage: '10020',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(AppOtherIncomeForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={FoundationFlowPageNumber.CoAppEmploymentDetails}
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <AppOtherIncomeFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  foundationInformation: state.foundationInformation,
  hasCoAppOtherIncome: state.foundationInformation.hasCoAppOtherIncome,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) => dispatch(changeFoundationInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

CoAppOtherIncome.propTypes = {
  foundationInformation: PropTypes.object,
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CoAppOtherIncome);
