import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Field, Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { batch, connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import CustomInput from '../../components/UI/Form/Input/CustomInput';
import Button from '../../components/UI/Button/CustomButton';
import CustomPhoneNumber from '../../components/UI/Form/PhoneNumber/PhoneNumber';
import {
  changePage,
  submitMindfire,
  endLoading,
  applyToBeDealer,
  applyToBeDealerGS,
} from '../../redux/actions/index';
import { withRouter } from 'react-router-dom';
import { ContractorEnrollmentAtWaterGSFlowPageNumber } from './_Flow';
import BackBtn from '../../components/UI/BackButton/BackButton';

import { isNoreplyEmail } from '../../helper';
import { changeContractorEnrollmentAtWaterGSValue } from '../../redux/actions/contractorEnrollmentAtWaterGSActions';
import Checkbox from '../../components/UI/Form/Checkbox/Checkbox';

const ContactAndBankInformation = props => {
  const {
    ce,
    dispatchChangeValue,
    dispatchChangePage,
    children,
    userId,
    dispatchApplyToBeDealer,
    history,
  } = props;

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const setValuesToState = values => {
    batch(() => {
      dispatchChangeValue('sameAddress', values.sameAddress);

      dispatchChangeValue('primaryCustomerFirstName', values.primaryCustomerFirstName);
      dispatchChangeValue('primaryCustomerLastName', values.primaryCustomerLastName);
      dispatchChangeValue('primaryCustomerEmail', values.primaryCustomerEmail);
      dispatchChangeValue('primaryCustomerWorkPhone', values.primaryCustomerWorkPhone);
      dispatchChangeValue('primaryCustomerMobilePhone', values.primaryCustomerMobilePhone);

      dispatchChangeValue('primaryAccountFirstName', values.primaryAccountFirstName);
      dispatchChangeValue('primaryAccountLastName', values.primaryAccountLastName);
      dispatchChangeValue('primaryAccountEmail', values.primaryAccountEmail);
      dispatchChangeValue('primaryAccountWorkPhone', values.primaryAccountWorkPhone);
      dispatchChangeValue('primaryAccountMobilePhone', values.primaryAccountMobilePhone);

      dispatchChangeValue('primarySalesFirstName', values.primarySalesFirstName);
      dispatchChangeValue('primarySalesLastName', values.primarySalesLastName);
      dispatchChangeValue('primarySalesEmail', values.primarySalesEmail);
      dispatchChangeValue('primarySalesWorkPhone', values.primarySalesWorkPhone);
      dispatchChangeValue('primarySalesMobilePhone', values.primarySalesMobilePhone);

      dispatchChangeValue('nameOnCheckingAccount', values.nameOnCheckingAccount);
      dispatchChangeValue('bankRoutingNoumber', values.bankRoutingNoumber);
      dispatchChangeValue('bankAccountNumber', values.bankAccountNumber);

      dispatchChangeValue('authorize', !values.authorize);
    });
  };

  const setSameAddress = values => {
    batch(() => {
      dispatchChangeValue('sameAddress', !values.sameAddress);

      dispatchChangeValue('primaryCustomerFirstName', ce.owners[0].firstName);
      dispatchChangeValue('primaryCustomerLastName', ce.owners[0].lastName);
      dispatchChangeValue('primaryCustomerEmail', ce.owners[0].email);
      dispatchChangeValue('primaryCustomerWorkPhone', ce.owners[0].mobilePhone);
      dispatchChangeValue('primaryCustomerMobilePhone', ce.owners[0].mobilePhone);

      dispatchChangeValue('primaryAccountFirstName', ce.owners[0].firstName);
      dispatchChangeValue('primaryAccountLastName', ce.owners[0].lastName);
      dispatchChangeValue('primaryAccountEmail', ce.owners[0].email);
      dispatchChangeValue('primaryAccountWorkPhone', ce.owners[0].mobilePhone);
      dispatchChangeValue('primaryAccountMobilePhone', ce.owners[0].mobilePhone);

      dispatchChangeValue('primarySalesFirstName', ce.owners[0].firstName);
      dispatchChangeValue('primarySalesLastName', ce.owners[0].lastName);
      dispatchChangeValue('primarySalesEmail', ce.owners[0].email);
      dispatchChangeValue('primarySalesWorkPhone', ce.owners[0].mobilePhone);
      dispatchChangeValue('primarySalesMobilePhone', ce.owners[0].mobilePhone);

      dispatchChangeValue('nameOnCheckingAccount', values.nameOnCheckingAccount);
      dispatchChangeValue('bankRoutingNoumber', values.bankRoutingNoumber);
      dispatchChangeValue('bankAccountNumber', values.bankAccountNumber);

      dispatchChangeValue('authorize', values.authorize);
    });
  };

  const ContactAndBankInformationForm = ({ values, errors, touched }) => (
    <Form autoComplete="off">
      <h5>Primary Customer Credit Contact</h5>
      <br />
      <Row>
        <Col>
          <Field
            component={Checkbox}
            name="sameAddress"
            id="sameAddress"
            label="Use Business Owner Contact Information"
            clickHandler={() => {
              setSameAddress(values);
            }}
          />
        </Col>
      </Row>
      <Field
        component={CustomInput}
        label="First Name *"
        name="primaryCustomerFirstName"
        id="primaryCustomerFirstName"
        type="text"
      />
      <Field
        component={CustomInput}
        label="Last Name *"
        name="primaryCustomerLastName"
        id="primaryCustomerLastName"
        type="text"
      />
      <Field
        component={CustomInput}
        label="Email *"
        name="primaryCustomerEmail"
        id="primaryCustomerEmail"
        type="email"
      />
      <Field
        component={CustomPhoneNumber}
        label="Work Phone *"
        name="primaryCustomerWorkPhone"
        id="primaryCustomerWorkPhone"
      />
      <Field
        component={CustomPhoneNumber}
        label="Mobile Phone *"
        name="primaryCustomerMobilePhone"
        id="primaryCustomerMobilePhone"
      />
      <br />
      <h5>Primary Account and ACH Contact</h5>
      <Field
        component={CustomInput}
        label="First Name *"
        name="primaryAccountFirstName"
        id="primaryAccountFirstName"
        type="text"
      />
      <Field
        component={CustomInput}
        label="Last Name *"
        name="primaryAccountLastName"
        id="primaryAccountLastName"
        type="text"
      />
      <Field
        component={CustomInput}
        label="Email *"
        name="primaryAccountEmail"
        id="primaryAccountEmail"
        type="email"
      />
      <Field
        component={CustomPhoneNumber}
        label="Work Phone *"
        name="primaryAccountWorkPhone"
        id="primaryAccountWorkPhone"
      />
      <Field
        component={CustomPhoneNumber}
        label="Mobile Phone *"
        name="primaryAccountMobilePhone"
        id="primaryAccountMobilePhone"
      />
      <br />
      <h5>Primary Sales, Marketing and Sales Consultant Training Contact</h5>
      <Field
        component={CustomInput}
        label="First Name *"
        name="primarySalesFirstName"
        id="primarySalesFirstName"
        type="text"
      />
      <Field
        component={CustomInput}
        label="Last Name *"
        name="primarySalesLastName"
        id="primarySalesLastName"
        type="text"
      />
      <Field
        component={CustomInput}
        label="Email *"
        name="primarySalesEmail"
        id="primarySalesEmail"
        type="email"
      />
      <Field
        component={CustomPhoneNumber}
        label="Work Phone *"
        name="primarySalesWorkPhone"
        id="primarySalesWorkPhone"
      />
      <Field
        component={CustomPhoneNumber}
        label="Mobile Phone *"
        name="primarySalesMobilePhone"
        id="primarySalesMobilePhone"
      />
      <br />
      <h5>Bank Information</h5>
      <Field
        component={CustomInput}
        label="Name on Checking Account *"
        name="nameOnCheckingAccount"
        id="nameOnCheckingAccount"
        type="text"
      />
      <Field
        component={CustomInput}
        label="Bank Routing Number *"
        name="bankRoutingNoumber"
        id="bankRoutingNoumber"
        type="password"
        number
        pattern="\d*"
        maxLength={9}
      />
      <Field
        component={CustomInput}
        label="Bank Account Number *"
        name="bankAccountNumber"
        id="bankAccountNumber"
        type="password"
        pattern="\d*"
        maxLength={17}
      />
      <br />
      <p>
        By clicking the check box below and submitting this application, you are certifying that you
        have read and agree to the{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://greenskycredit.com/merchant-disclosures/"
        >
          Disclosures
        </a>{' '}
        and{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href=" https://greenskycredit.com/merchantagreement/ "
        >
          Program Agreement
        </a>
        .{' '}
        <b>
          PLEASE NOTE: It is important that you and your employees are aware of the various rules
          governing your participation in the Program. Failure to follow Program rules, including,
          but not limited to, submitting credit applications and/or transactions without your
          customer's knowledge and authorization, may result in suspension and/or termination from
          the Program.
        </b>
        <br />
        The GreenSky® Program may check and verify credit history and secure credit reports of the
        Applicant and any named principals, owners, or partners. The named principals, owners, and
        partners instruct any third party including but not limited to any consumer reporting
        agency, partner, and vendor to provide such report.
      </p>
      <Field
        component={Checkbox}
        name="authorize"
        id="authorize"
        label="I certify that the information submitted is true, accurate, and complete.
        By submitting this Application, I certify that I have read and agree to
        the Disclosures and Program Agreement."
        labelClassName="bold mb-0"
        clickHandler={e => setValuesToState(values)}
      />

      <Button type="submit" title="Submit Application" />
    </Form>
  );

  const ContactAndBankInformationFormFormik = withFormik({
    mapPropsToValues: () => ({
      sameAddress: ce.sameAddress || false,
      primaryCustomerFirstName: ce.primaryCustomerFirstName || '',
      primaryCustomerLastName: ce.primaryCustomerLastName || '',
      primaryCustomerEmail: ce.primaryCustomerEmail || '',
      primaryCustomerWorkPhone: ce.primaryCustomerWorkPhone || '',
      primaryCustomerMobilePhone: ce.primaryCustomerMobilePhone || '',

      primaryAccountFirstName: ce.primaryAccountFirstName || '',
      primaryAccountLastName: ce.primaryAccountLastName || '',
      primaryAccountEmail: ce.primaryAccountEmail || '',
      primaryAccountWorkPhone: ce.primaryAccountWorkPhone || '',
      primaryAccountMobilePhone: ce.primaryAccountMobilePhone || '',

      primarySalesFirstName: ce.primarySalesFirstName || '',
      primarySalesLastName: ce.primarySalesLastName || '',
      primarySalesEmail: ce.primarySalesEmail || '',
      primarySalesWorkPhone: ce.primarySalesWorkPhone || '',
      primarySalesMobilePhone: ce.primarySalesMobilePhone || '',

      nameOnCheckingAccount: ce.nameOnCheckingAccount || '',
      bankRoutingNoumber: ce.bankRoutingNoumber || '',
      bankAccountNumber: ce.bankAccountNumber || '',

      authorize: ce.authorize || false,
    }),

    validationSchema: Yup.object({
      primaryCustomerFirstName: Yup.string()
        .matches(/^(?=.*[a-zA-Z ])([a-zA-Z ]+)$/, 'Special characters are not allowed.')
        .required('Please enter First Name.'),
      primaryCustomerLastName: Yup.string()
        .matches(/^(?=.*[a-zA-Z ])([a-zA-Z ]+)$/, 'Special characters are not allowed.')
        .required('Please enter Last Name.'),
      primaryCustomerEmail: Yup.string()
        .email('Please enter a valid email.')
        .required('Please enter an email.')
        .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),
      primaryCustomerWorkPhone: Yup.string()
        .test('required', 'Please enter the work phone number.', val => val !== '+' && val)
        .test('len', 'Invalid phone number', val => val && val.match(/\d/g).join('').length === 10),
      primaryCustomerMobilePhone: Yup.string()
        .test('required', 'Please enter the mobile phone number.', val => val !== '+' && val)
        .test('len', 'Invalid phone number', val => val && val.match(/\d/g).join('').length === 10),

      primaryAccountFirstName: Yup.string()
        .matches(/^(?=.*[a-zA-Z ])([a-zA-Z ]+)$/, 'Special characters are not allowed.')
        .required('Please enter First Name.'),
      primaryAccountLastName: Yup.string()
        .matches(/^(?=.*[a-zA-Z ])([a-zA-Z ]+)$/, 'Special characters are not allowed.')
        .required('Please enter Last Name.'),
      primaryAccountEmail: Yup.string()
        .email('Please enter a valid email.')
        .required('Please enter an email.')
        .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),
      primaryAccountWorkPhone: Yup.string()
        .test('required', 'Please enter the work phone number.', val => val !== '+' && val)
        .test('len', 'Invalid phone number', val => val && val.match(/\d/g).join('').length === 10),
      primaryAccountMobilePhone: Yup.string()
        .test('required', 'Please enter the mobile phone number.', val => val !== '+' && val)
        .test('len', 'Invalid phone number', val => val && val.match(/\d/g).join('').length === 10),

      primarySalesFirstName: Yup.string()
        .matches(/^(?=.*[a-zA-Z ])([a-zA-Z ]+)$/, 'Special characters are not allowed.')
        .required('Please enter First Name.'),
      primarySalesLastName: Yup.string()
        .matches(/^(?=.*[a-zA-Z ])([a-zA-Z ]+)$/, 'Special characters are not allowed.')
        .required('Please enter Last Name.'),
      primarySalesEmail: Yup.string()
        .email('Please enter a valid email.')
        .required('Please enter an email.')
        .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),
      primarySalesWorkPhone: Yup.string()
        .test('required', 'Please enter the work phone number.', val => val !== '+' && val)
        .test('len', 'Invalid phone number', val => val && val.match(/\d/g).join('').length === 10),
      primarySalesMobilePhone: Yup.string()
        .test('required', 'Please enter the mobile phone number.', val => val !== '+' && val)
        .test('len', 'Invalid phone number', val => val && val.match(/\d/g).join('').length === 10),

      nameOnCheckingAccount: Yup.string().required('Please enter Name on Checking Account.'),
      bankRoutingNoumber: Yup.string()
        .min(9, 'Please enter just 9 digits.')
        .max(9, 'Please enter just 9 digits.')
        .required('Please enter your Bank Account Routing Number.'),
      bankAccountNumber: Yup.string()
        .max(17, 'Please enter up to 17 digits.')
        .required('Please enter your Bank Account Number.'),

      authorize: Yup.boolean().oneOf([true], 'Please check to continue.'),
    }),

    handleSubmit: values => {
      setValuesToState(values);

      const bodyRequest = {
        userId: userId,
        website: ce.websiteBusinessUrl,
        sameMailingAddr: ce.sameMailingAddr,
        street: ce.street,
        city: ce.city,
        zipcode: ce.zipcode,
        state: ce.state,
        isOwner: ce.isOwner,
        firstName: ce.firstName,
        lastName: ce.lastName,
        title: ce.title,
        email: ce.email,
        workPhone: ce.workPhone,
        mobilePhone: ce.mobilePhone,
        primaryBusinessCategory: ce.primaryBusinessCategory,
        otherBusinessCategory: ce.otherBusinessCategory,
        businessLegalName: ce.businessLegalName,
        websiteBusinessUrl: ce.websiteBusinessUrl,
        howDidYouHearAboutGreenSky: ce.howDidYouHearAboutGreenSky,
        federalTaxId: ce.federalTaxId,
        programId: ce.programId,
        allNames: ce.allNames,
        inBusinessSince: ce.inBusinessSince,
        referredBy: ce.referredBy,
        businessStructure: ce.businessStructure,
        typeOfService: ce.typeOfService,
        annualConsumerSalesRevenue: ce.annualConsumerSalesRevenue,
        annualConsumerFinanceVolume: ce.annualConsumerFinanceVolume,
        averageTicket: ce.averageTicket,
        mailingStreet: ce.mailingStreet,
        mailingCity: ce.mailingCity,
        mailingZipcode: ce.mailingZipcode,
        mailingState: ce.mailingState,
        owners: ce.owners,
        primaryCustomerFirstName: ce.primaryCustomerFirstName,
        primaryCustomerLastName: ce.primaryCustomerLastName,
        primaryCustomerEmail: ce.primaryCustomerEmail,
        primaryCustomerWorkPhone: ce.primaryCustomerWorkPhone,
        primaryCustomerMobilePhone: ce.primaryCustomerMobilePhone,
        primaryAccountFirstName: ce.primaryAccountFirstName,
        primaryAccountLastName: ce.primaryAccountLastName,
        primaryAccountEmail: ce.primaryAccountEmail,
        primaryAccountWorkPhone: ce.primaryAccountWorkPhone,
        primaryAccountMobilePhone: ce.primaryAccountMobilePhone,
        primarySalesFirstName: ce.primarySalesFirstName,
        primarySalesLastName: ce.primarySalesLastName,
        primarySalesEmail: ce.primarySalesEmail,
        primarySalesWorkPhone: ce.primarySalesWorkPhone,
        primarySalesMobilePhone: ce.primarySalesMobilePhone,
        nameOnCheckingAccount: ce.nameOnCheckingAccount,
        bankRoutingNoumber: values.bankRoutingNoumber,
        bankAccountNumber: values.bankAccountNumber,
        blackBox: `${document.getElementById('fpBlackBox')?.value || ''};${document.getElementById(
          'ioBlackBox',
        )?.value || ''}`,
      };

      dispatchApplyToBeDealer(
        ContractorEnrollmentAtWaterGSFlowPageNumber.FinalMessage,
        history,
        bodyRequest,
      );
    },
  })(ContactAndBankInformationForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={ContractorEnrollmentAtWaterGSFlowPageNumber.OwnerInformation}
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <ContactAndBankInformationFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  ce: state.contractorEnrollmentAtWaterGS,
  userId: state.auth.user?.dealerUser?.userId,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) =>
    dispatch(changeContractorEnrollmentAtWaterGSValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchEndLoading: () => dispatch(endLoading()),
  dispatchApplyToBeDealer: (page, history, data) =>
    dispatch(applyToBeDealerGS(true, page, history, data)),
});

ContactAndBankInformation.propTypes = {
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
  dispatchSubmitSoftPullAquaApplication: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ContactAndBankInformation),
);
