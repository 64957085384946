const FTLEmploymentTypeEnum = {
  FULLTIME: {
    value: 'full-time',
    name: 'Full Time',
  },
  PARTTIME: {
    value: 'part-time',
    name: 'Part Time',
  },
  RETIRED: {
    value: 'retired',
    name: 'Retired',
  },
  SELF_EMPLOYED: {
    value: 'self-employed',
    name: 'Self Employed',
  },
  CONTRACT: {
    value: 'contract',
    name: 'Contract',
  },
  DISABILITY: {
    value: 'disability',
    name: 'Disability',
  },
};

export default FTLEmploymentTypeEnum;
