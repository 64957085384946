import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row, Alert } from 'reactstrap';
import * as Yup from 'yup';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import Input from '../../../components/UI/Form/Input/CustomInput';
import { isNoreplyEmail, reactGAEvent, reactGAPageview } from '../../../helper';
import { changePage, changePreQualInfoValue, isEmailUnique } from '../../../redux/actions/index';
import { getDealerPortalURL, getDealerPortalName } from '../../../config/settings';
import { PowerPayPreQualificationFlowPageNumber } from './PreQualificationFlow';
import {
  EMAIL_CONFIRMATION_TOOLTIP_TEXT,
  EMAIL_TOOLTIP_TEXT,
} from '../../../constants/tooltipTexts';

const AccountEmail = props => {
  const {
    email,
    isEmailUniqueVal,
    dispatchChangePage,
    dispatchChangeValue,
    dispatchIsEmailUnique,
    dealerUserId,
    children,
    did,
    isConsumer,
    dealerName,
    lead_source,
    lp_url,
    click_id,
    track_id,
    utmVariables,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_ppregister',
      trackerNames: [],
      title: 'ca_ppregister',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const AccountEmailForm = () => (
    <Form>
      <Field
        component={Input}
        label={!!dealerUserId && did === null ? 'Customer Email Address' : 'Email Address'}
        name="email"
        id="email"
        tooltipText={EMAIL_TOOLTIP_TEXT}
        type="email"
      />
      <Field
        component={Input}
        label={
          !!dealerUserId && did === null
            ? 'Confirm Customer Email Address'
            : 'Confirm Email Address'
        }
        name="confirmEmail"
        id="confirmEmail"
        type="email"
        tooltipText={EMAIL_CONFIRMATION_TOOLTIP_TEXT}
      />
      <Button type="submit" title="Continue" />
    </Form>
  );

  const AccountEmailFormFormik = withFormik({
    mapPropsToValues: () => ({
      email: email || '',
      confirmEmail: email || '',
    }),

    validationSchema: Yup.object({
      email: Yup.string()
        .required('Please enter an email.')
        .email('Please enter a valid email.')
        .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),

      confirmEmail: Yup.string()
        .required('Please enter an email.')
        .oneOf([Yup.ref('email'), null], 'Emails must match.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Continue',
        did,
        dealerUserId,
      });
      dispatchChangeValue('email', values.email);
      if (isEmailUniqueVal && values.email === email) {
        dispatchChangePage(PowerPayPreQualificationFlowPageNumber.AccountPassword);
      } else if (isConsumer) {
        dispatchIsEmailUnique(
          values.email,
          PowerPayPreQualificationFlowPageNumber.AccountPassword,
          {
            email: values.email,
            lastPage: '6001',
            dealerUserId: dealerUserId,
            did: did,
            gacId: lead_source,
            lpUrl: lp_url,
            lpReferrer: document.referrer,
            clickId: click_id,
            track_id: track_id,
            utmVariables: utmVariables,
          },
        );
      } else {
        dispatchChangePage(PowerPayPreQualificationFlowPageNumber.LoanFor);
      }
    },
  })(AccountEmailForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={PowerPayPreQualificationFlowPageNumber.Landing}
        />
        <Col sm="5">
          {dealerName ? <h4 className="mb-3">{dealerName}</h4> : null}
          {children}
          {isConsumer && (
            <p>
              You’ll use this email to log in after completing your application or if you have to
              finish the application later.
            </p>
          )}
          <p>
            <b>IMPORTANT!</b> The applicant must be the homeowner.
          </p>
        </Col>
        <Col sm="6">
          {isEmailUniqueVal != null && !isEmailUniqueVal ? (
            <Alert color="danger">
              Your email address already exists on our system. Please log in using{' '}
              <a href={getDealerPortalURL()}>{getDealerPortalName()}</a> and complete the
              application that is already in progress.
            </Alert>
          ) : null}
          <AccountEmailFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  email: state.preQualificationInformation.email,
  isEmailUniqueVal: state.preQualificationInformation.isEmailUnique,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  isConsumer: state.preQualificationInformation.isConsumer,
  dealerName: state.auth.dealerName,
  lead_source: state.auth.lead_source,
  lp_url: state.auth.lp_url,
  consumerId: state.auth.consumerId,
  click_id: state.auth.click_id,
  track_id: state.auth.track_id,
  utmVariables: state.auth.utmVariables,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) => dispatch(changePreQualInfoValue(key, value)),
  dispatchIsEmailUnique: (email, page, submitMindfireParams) =>
    dispatch(isEmailUnique(email, page, submitMindfireParams)),
});

AccountEmail.propTypes = {
  email: PropTypes.string,
  isEmailUniqueVal: PropTypes.bool,
  dealerUserId: PropTypes.number,
  did: PropTypes.string,
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
  dispatchIsEmailUnique: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountEmail);
