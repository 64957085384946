import { Field, Form, withFormik } from 'formik';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import { contractorUserEnum, findByValue } from '../../../assets/js/UserRoleEnum';
import Button from '../../../components/UI/Button/CustomButton';
import Input from '../../../components/UI/Form/Input/CustomInput';
import Select from '../../../components/UI/Form/Select/Select';
import { getAddedFrom } from '../../../config/settings';
import { EMAIL_TOOLTIP_TEXT } from '../../../constants/tooltipTexts';
import { isNoreplyEmail } from '../../../helper';
import { changeSoftPullValue, createRojosUser } from '../../../redux/actions';

const AddUsersToRojos = props => {
  const { history, dispatchCreateRojosUser, newRojosUser, dispatchChangeSoftPullValue } = props;
  let addedFrom = getAddedFrom();

  const [isUserCreated, setIsUserCreated] = useState(false);

  const AddUsersToRojosForm = () => (
    <Form autoComplete="off" className="py-5">
      <Row className="justify-content-center align-items-center">
        <Col xs="12" md="10" lg="2">
          <Label className="mb-0">
            <b>First Name *</b>
          </Label>
        </Col>
        <Col xs="12" md="10" lg="6" xl="4">
          <Field component={Input} name="firstName" id="firstName" type="text" />
        </Col>
      </Row>
      <Row className="justify-content-center align-items-center">
        <Col xs="12" md="10" lg="2">
          <Label className="mb-0">
            <b>Last Name *</b>
          </Label>
        </Col>
        <Col xs="12" md="10" lg="6" xl="4">
          <Field component={Input} name="lastName" id="lastName" type="text" />
        </Col>
      </Row>
      <Row className="justify-content-center align-items-center">
        <Col xs="12" md="10" lg="2">
          <Label className="mb-0">
            <b>Email *</b>
          </Label>
        </Col>
        <Col xs="12" md="10" lg="6" xl="4">
          <Field
            component={Input}
            name="email"
            id="email"
            tooltipText={EMAIL_TOOLTIP_TEXT}
            type="email"
          />
        </Col>
      </Row>
      <Row className="justify-content-center align-items-center">
        <Col xs="12" md="10" lg="2">
          <Label className="mb-0">
            <b>Salesforce Case Safe ID *</b>
          </Label>
        </Col>
        <Col xs="12" md="10" lg="6" xl="4">
          <Field component={Input} name="salesforceId" id="salesforceId" type="text" />
        </Col>
      </Row>
      <Row className="justify-content-center align-items-center">
        <Col xs="12" md="10" lg="2">
          <Label className="mb-0">
            <b>Contact Type *</b>
          </Label>
        </Col>
        <Col xs="12" md="10" lg="6" xl="4">
          <Field
            component={Select}
            name="contactType"
            id="contactType"
            type="select"
            selectValues={contractorUserEnum}
          />
        </Col>
      </Row>
      {isUserCreated ? (
        <Row>
          <Col>
            <div className="alert-success rounded mt-0 p-3">The user was successfully created.</div>
          </Col>
        </Row>
      ) : null}
      <Row className="align-items-center justify-content-end mt-3">
        <Col xs="12" md="auto">
          <FormGroup>
            <Button
              className={'inactive rounded-pill'}
              title={'Cancel'}
              clickHandler={() => history.push('/portal/manage-contractor-users')}
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="auto">
          <Button className={'rounded-pill'} type="submit" title={'Create'} />
        </Col>
      </Row>
    </Form>
  );

  const AddUsersToRojosFormFormik = withFormik({
    mapPropsToValues: () => ({
      contactType:
        (contractorUserEnum[`${newRojosUser.role}`] &&
          contractorUserEnum[`${newRojosUser.role}`].value) ||
        '',
      lastName: newRojosUser.lastName || '',
      firstName: newRojosUser.firstName || '',
      email: newRojosUser.email || '',
      salesforceId: newRojosUser.salesforceId || '',
      addedFrom: addedFrom || '',
    }),

    validationSchema: Yup.object({
      contactType: Yup.string().required('Please select contact type.'),
      lastName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .required('Please enter the users last name.'),
      firstName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .required('Please enter the users first name.'),
      salesforceId: Yup.string().required('Please enter the salesforce case safe id.'),
      email: Yup.string()
        .email('Please enter a valid email.')
        .required('Please enter an email.')
        .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),
    }),

    handleSubmit: async values => {
      const rojosUser = {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        role: findByValue(values.contactType),
        addedFrom: values.addedFrom,
        salesforceId: values.salesforceId,
      };

      dispatchChangeSoftPullValue('newRojosUser', rojosUser);
      setIsUserCreated(await dispatchCreateRojosUser(rojosUser));
    },
  })(AddUsersToRojosForm);

  return (
    <Col>
      <Row>
        <Col>
          <h1>Add Users to Rojos</h1>
        </Col>
      </Row>
      <Row className="panel">
        <Col>
          <AddUsersToRojosFormFormik />
        </Col>
      </Row>
    </Col>
  );
};

const mapStateToProps = state => ({
  newRojosUser: state.softPull.newRojosUser,
});

const mapDispatchToProps = dispatch => ({
  dispatchCreateRojosUser: data => dispatch(createRojosUser(data)),
  dispatchChangeSoftPullValue: (key, value) => dispatch(changeSoftPullValue(key, value)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AddUsersToRojos),
);
