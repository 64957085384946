import axios from 'axios';
import {
  CHANGE_GREENSKY_INFO_VALUE,
  CHANGE_GREENSKY_REGISTER_AS_CONTRACTOR_INFO_VALUE,
  CHANGE_GREENSKY_CONTRACTOR_ENROLLMENT_INFO_VALUE,
  RESET_STATE_GREENSKY,
} from './actionTypes';
import { baseURL, handleApiError } from './api';
import { addNotification, endLoading, startLoading } from './notificationActions';
import { changePage } from './naviActions';
import { updateDealerValue } from './authActions';
import { mapApplicationToPrequalification } from './softPullActions';
import { changePreQualInfoValue } from './preQualInfoActions';
import { getAddedByAPIValue, isSameAddress } from '../../helper';
import { changeGreenSkyApplicationInfoValue } from './greenskyApplicationActions';
import relationshipEnum from '../../assets/js/RelationshipEnum';

export const changeGreenSkyInfoValue = (key, value) => ({
  type: CHANGE_GREENSKY_INFO_VALUE,
  key,
  value,
});

export const changeGSRegisterAsContractorInfoValue = (key, value) => ({
  type: CHANGE_GREENSKY_REGISTER_AS_CONTRACTOR_INFO_VALUE,
  key,
  value,
});

export const changeGSContractorEnrollmentInfoValue = (key, value) => ({
  type: CHANGE_GREENSKY_CONTRACTOR_ENROLLMENT_INFO_VALUE,
  key,
  value,
});

export const resetGreenSkyState = () => ({ type: RESET_STATE_GREENSKY });

export const getReCapthcaKey = () => async dispatch => {
  await axios
    .get(`${baseURL}/api/soft_pull/get_recaptcha_key`)
    .then(response => {
      dispatch(changeGSRegisterAsContractorInfoValue('recaptchaKey', response.data));
      dispatch(endLoading());
    })
    .catch(error => {
      dispatch(endLoading());
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
};

export const registerContractor = params => async dispatch => {
  dispatch(startLoading('Register as Contractor...'));
  await axios
    .post(`${baseURL}/api/soft_pull/register_contractor`, params)
    .then(async response => {
      dispatch(changeGSRegisterAsContractorInfoValue('registered', true));
      dispatch(changeGSRegisterAsContractorInfoValue('registeredMessage', response.data));
      dispatch(endLoading());
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(endLoading());
    });
};

export const applyToBeDealer = (isGreensky, page, history) => async (dispatch, getState) => {
  dispatch(startLoading('Apply to be Dealer ...'));
  let dealerDTO;
  if (isGreensky) {
    dealerDTO = getState().greenskyInformation.contractorEnrollment;
  } else {
    dealerDTO = getState().contractorEnrollment;
  }

  await axios
    .post(`${baseURL}/api/soft_pull/create_dealer?isGreensky=${isGreensky}`, dealerDTO)
    .then(async response => {
      if (isGreensky) {
        dispatch(changePage(page));
      } else {
        dispatch(updateDealerValue(response.data));
        history.push('/portal/candidate-contractor-dashboard');
      }
      dispatch(endLoading());
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(endLoading());
    });
};

export const mapGreenskyToState = app => dispatch => {
  if (app) {
    const { greenskyInformation } = app;
    dispatch(mapApplicationToPrequalification(app));
    dispatch(changeGreenSkyApplicationInfoValue('consent', true));
    dispatch(changeGreenSkyApplicationInfoValue('applicantSubmitConsent', true));
    dispatch(changeGreenSkyApplicationInfoValue('coApplicantSubmitConsent', true));

    if (greenskyInformation) {
      dispatch(changeGreenSkyApplicationInfoValue('merchantId', greenskyInformation.merchantId));
      dispatch(changeGreenSkyApplicationInfoValue('planId', greenskyInformation.planId));
    }

    dispatch(changeGreenSkyApplicationInfoValue('loanAmmount', app.totalFinancingAmount));
    if (app.applicantPrimary) {
      const applicant = app.applicantPrimary;
      dispatch(changePreQualInfoValue('firstName', applicant.nameFirst));
      dispatch(changePreQualInfoValue('middleName', applicant.nameMiddle));
      dispatch(changePreQualInfoValue('lastName', applicant.nameLast));
      dispatch(changePreQualInfoValue('phoneNumber', applicant.mobilePhone?.number));
      dispatch(changePreQualInfoValue('birthday', getAddedByAPIValue(applicant.birthDate) || null));

      dispatch(changeGreenSkyApplicationInfoValue('appEmail', app.email));
      dispatch(changeGreenSkyApplicationInfoValue('appSpanishConsent', true));

      dispatch(
        changeGreenSkyApplicationInfoValue('appMobileNumber', applicant.mobilePhone?.number),
      );
      dispatch(changeGreenSkyApplicationInfoValue('appMobileNumberConsent', true));
      dispatch(changeGreenSkyApplicationInfoValue('isAppMarried', applicant.isAppMarried));
      dispatch(changeGreenSkyApplicationInfoValue('appSsn', getAddedByAPIValue(applicant.ssn)));
      dispatch(
        changeGreenSkyApplicationInfoValue('appGrossAnnualIncome', applicant.grossYearlyIncome),
      );

      dispatch(
        changeGreenSkyApplicationInfoValue(
          'isSameAsInstalationAddress',
          applicant.isSameAsInstalationAddress,
        ),
      );

      dispatch(
        changeGreenSkyApplicationInfoValue(
          'primaryAddressPoBox',
          applicant.primaryAddressApartmentNumber,
        ),
      );
      dispatch(
        changeGreenSkyApplicationInfoValue('primaryAddressStreet', applicant.primaryAddressStreet),
      );
      dispatch(
        changeGreenSkyApplicationInfoValue('primaryAddressCity', applicant.primaryAddressCity),
      );
      dispatch(
        changeGreenSkyApplicationInfoValue(
          'primaryAddressZipCode',
          applicant.primaryAddressZipCode,
        ),
      );
      dispatch(
        changeGreenSkyApplicationInfoValue('primaryAddressState', applicant.primaryAddressState),
      );
    }

    if (app.applicantNonPrimary) {
      const coapp = app.applicantNonPrimary;
      dispatch(changeGreenSkyApplicationInfoValue('hasCoApp', true));

      dispatch(
        changeGreenSkyApplicationInfoValue(
          'isCoAppSpouse',
          coapp.relationshipToPrimary === relationshipEnum.SPOUSE.value,
        ),
      );
      dispatch(changeGreenSkyApplicationInfoValue('coAppFirstName', coapp.nameFirst));
      dispatch(changeGreenSkyApplicationInfoValue('coAppMiddleInitial', coapp.nameMiddle));
      dispatch(changeGreenSkyApplicationInfoValue('coAppLastName', coapp.nameLast));
      dispatch(changeGreenSkyApplicationInfoValue('coAppPhoneNumber', coapp.mobilePhone?.number));
      dispatch(changeGreenSkyApplicationInfoValue('coAppEmail', coapp.email?.email));
      dispatch(changeGreenSkyApplicationInfoValue('coAppSpanishConsent', true));
      dispatch(changeGreenSkyApplicationInfoValue('coAppPhoneNumberConsent', true));
      dispatch(changeGreenSkyApplicationInfoValue('isAppMarried', coapp.isAppMarried));

      dispatch(
        changeGreenSkyApplicationInfoValue(
          'coAppDoB',
          getAddedByAPIValue(coapp.birthDate) || '01/01/1900',
        ),
      );
      dispatch(changeGreenSkyApplicationInfoValue('coAppSsn', getAddedByAPIValue(coapp.ssn)));

      dispatch(
        changeGreenSkyApplicationInfoValue('coAppGrossAnnualIncome', coapp.grossYearlyIncome),
      );

      if (coapp.address) {
        const address = coapp.address;
        dispatch(
          changeGreenSkyApplicationInfoValue(
            'isSameAddress',
            isSameAddress(app.applicantPrimary.address, coapp.address),
          ),
        );
        dispatch(changeGreenSkyApplicationInfoValue('coAppStreet', address.street));
        dispatch(changeGreenSkyApplicationInfoValue('coAppPoBox', address.poBox));
        dispatch(changeGreenSkyApplicationInfoValue('coAppCity', address.city));
        dispatch(changeGreenSkyApplicationInfoValue('coAppZipCode', address.zipcode));
        dispatch(changeGreenSkyApplicationInfoValue('coAppState', address.state));
      }
    }
  }
};
