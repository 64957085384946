import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import * as Yup from 'yup';
import CustomInput from '../Form/Input/CustomInput';
import { connect } from 'react-redux';
import { provideFundingInfo, changeSoftPullValue } from '../../../redux/actions';

const ProvideFundingInfo = props => {
  const {
    isOpen,
    toggleModal,
    className,
    onSendClick,
    dispatchChangeValue,
    ftlOptionsResponse,
  } = props;

  const fileNameStyle = {
    marginLeft: '10px',
  };
  const fileNameErrorStyle = {
    marginLeft: '10px',
    color: 'red',
  };

  const [uploadedFile, setUploadedFile] = useState(null);
  const [formikValues, setFormikValues] = useState({
    bankAccountHolderName: ftlOptionsResponse ? ftlOptionsResponse.accountHolderName : '',
    bankName: ftlOptionsResponse ? ftlOptionsResponse.bankName : '',
    bankCityAndState: ftlOptionsResponse ? ftlOptionsResponse.bankCityAndState : '',
    routingNumber: ftlOptionsResponse ? ftlOptionsResponse.routingNumber : '',
    accountNumber: ftlOptionsResponse ? ftlOptionsResponse.accountNumber : '',
    printName: ftlOptionsResponse ? ftlOptionsResponse.printName : '',
  });

  const saveFormikValues = values => {
    setFormikValues({
      bankAccountHolderName: values.bankAccountHolderName,
      bankName: values.bankName,
      bankCityAndState: values.bankCityAndState,
      routingNumber: values.routingNumber,
      accountNumber: values.accountNumber,
      printName: values.printName,
    });
  };
  const fileInput = React.createRef();

  const ProvideFundingInfoForm = ({ values }) => (
    <Form>
      <ModalBody>
        <Field
          component={CustomInput}
          label="Bank account holder name"
          name="bankAccountHolderName"
          id="bankAccountHolderName"
          type="text"
        />
        <Field
          component={CustomInput}
          label="Bank name"
          name="bankName"
          id="bankName"
          type="text"
        />
        <Field
          component={CustomInput}
          label="Bank city and state"
          name="bankCityAndState"
          id="bankCityAndState"
          type="text"
        />
        <Field
          component={CustomInput}
          label="Routing number"
          name="routingNumber"
          id="routingNumber"
          type="text"
        />
        <Field
          component={CustomInput}
          label="Account number"
          name="accountNumber"
          id="accountNumber"
          type="text"
        />
        <Field
          component={CustomInput}
          label="Print Name"
          name="printName"
          id="printName"
          type="text"
        />
        <input
          type="file"
          name="file"
          id="file"
          style={{ display: 'none' }}
          onChange={event => {
            saveFormikValues(values);
            dispatchChangeValue('fundingInfoDocuments', event.currentTarget.files);
            setUploadedFile(event.currentTarget.files[0]);
          }}
          ref={fileInput}
        />

        <Button type="button" onClick={() => fileInput.current.click()}>
          Choose file
        </Button>

        {values.file ? (
          <span style={fileNameStyle}>{values.file.name}</span>
        ) : (
          <span style={fileNameErrorStyle}>No file chosen.</span>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" outline onClick={toggleModal}>
          Close
        </Button>
        <Button color="primary" type="submit">
          Submit
        </Button>
      </ModalFooter>
    </Form>
  );

  const ProvideFundingInfoFormFormik = withFormik({
    mapPropsToValues: () => ({
      bankAccountHolderName: formikValues.bankAccountHolderName,
      bankName: formikValues.bankName,
      bankCityAndState: formikValues.bankCityAndState,
      routingNumber: formikValues.routingNumber,
      accountNumber: formikValues.accountNumber,
      printName: formikValues.printName,
      file: uploadedFile,
    }),

    validationSchema: Yup.object({
      bankAccountHolderName: Yup.string().required('Please enter the bank account holder name.'),
      bankName: Yup.string().required('Please enter the bank name.'),
      bankCityAndState: Yup.string().required('Please enter the bank city and state.'),
      routingNumber: Yup.string()
        .required('Please enter the routing number.')
        .min(9)
        .max(9),
      accountNumber: Yup.string().required('Please enter the account number.'),
      printName: Yup.string().required('Please enter the print name.'),
      file: Yup.mixed().required('A file is required.'),
    }),

    handleSubmit: values => {
      const data = {
        accountHolderName: values.bankAccountHolderName,
        bankName: values.bankName,
        bankCityAndState: values.bankCityAndState,
        routingNumber: values.routingNumber,
        accountNumber: values.accountNumber,
        printName: values.printName,
      };

      onSendClick(data);
    },
  })(ProvideFundingInfoForm);

  return (
    <Modal centered isOpen={isOpen} className={className}>
      <ModalHeader toggle={toggleModal}>
        <b>{'Provide Funding Info'}</b>
      </ModalHeader>
      <ProvideFundingInfoFormFormik />
    </Modal>
  );
};

ProvideFundingInfo.propTypes = {
  isOpen: PropTypes.bool,
  sendTo: PropTypes.object,
  toggleModal: PropTypes.func,
  className: PropTypes.string,
  onSendClick: PropTypes.func,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  dispatchProvideFundingInfo: (appId, optionId, data) =>
    dispatch(provideFundingInfo(appId, optionId, data)),
  dispatchChangeValue: (key, value) => dispatch(changeSoftPullValue(key, value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProvideFundingInfo);
