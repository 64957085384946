import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import { reactGAEvent, reactGAPageview } from '../../../../helper';
import {
  changePage,
  changeFoundationInfoValue,
  submitMindfire,
} from '../../../../redux/actions/index';
import { FoundationFlowPageNumber } from './FoundationFlow';
import foundationProductTypes from '../../../../assets/js/FoundationProductTypesEnum';
import Select from '../../../../components/UI/Form/Select/Select';
import { PRODUCT_TYPE_TOOLTIP_TEXT } from '../../../../constants/tooltipTexts';

const HomeImprovementProductType = props => {
  const {
    email,
    dispatchChangePage,
    dispatchChangeFoundationInfoValue,
    children,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    appProductType,
    isReview,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_fdhomeimpprodtype',
      trackerNames: [],
      title: 'ca_fdhomeimpprodtype',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const HomeImprovementProductTypeForm = () => (
    <Form>
      <Field
        component={Select}
        label="Product Type"
        name="appProductType"
        id="appProductType"
        tooltipText={PRODUCT_TYPE_TOOLTIP_TEXT}
        type="select"
        selectValues={foundationProductTypes}
        selectFirstName="Select Product Type"
      />
      <Button type="submit" title="Next" />
    </Form>
  );

  const HomeImprovementProductTypeFormFormik = withFormik({
    mapPropsToValues: () => ({
      appProductType: appProductType || '',
    }),

    validationSchema: Yup.object({
      appProductType: Yup.string().required('Please select a Product Type.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      dispatchChangeFoundationInfoValue('appProductType', values.appProductType);
      dispatchChangePage(FoundationFlowPageNumber.LoanAmount);
      dispatchSubmitMindfire({
        email: email,
        appProductType: values.appProductType,
        lastPage: '10011',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(HomeImprovementProductTypeForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={
            isReview ? FoundationFlowPageNumber.ApplicationReview : FoundationFlowPageNumber.Consent
          }
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <HomeImprovementProductTypeFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  appProductType: state.foundationInformation.appProductType,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  isReview: state.foundationInformation.isReview,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeFoundationInfoValue: (key, value) =>
    dispatch(changeFoundationInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HomeImprovementProductType);
