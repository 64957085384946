import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import incomeSource from '../../assets/js/IncomeSourceEnum';
import BackBtn from '../../components/UI/BackButton/BackButton';
import Button from '../../components/UI/Button/CustomButton';
import CustomInput from '../../components/UI/Form/Input/CustomInput';
import CustomPhoneNumber from '../../components/UI/Form/PhoneNumber/PhoneNumber';
import SelectWithHandleChange from '../../components/UI/Form/Select/SelectWithHandleChange';
import { changeAquaInfoValue, changePage, submitMindfire } from '../../redux/actions/index';
import PropTypes from 'prop-types';
import { reactGAPageview, reactGAEvent } from '../../helper';
import CurrencyFormat from '../../components/UI/Form/NumberFormat/CurrencyFormat';
import { AquaFlowPageNumber } from './AquaFlow';
import {
  EMPLOYER_NAME_TOOLTIP_TEXT,
  EMPLOYER_PHONE_NUMBER_TOOLTIP_TEXT,
  EMPLOYMENT_MONTHS_TOOLTIP_TEXT,
  EMPLOYMENT_POSITION_TOOLTIP_TEXT,
  EMPLOYMENT_YEARS_TOOLTIP_TEXT,
  INCOME_SOURCE_TOOLTIP_TEXT,
  SALARY_INCOME_TOOLTIP_TEXT,
} from '../../constants/tooltipTexts';

const AppEmploymentDetails = props => {
  const {
    email,
    dispatchChangePage,
    dispatchChangeValue,
    aquaInformation,
    isEmployed,
    children,
    isReview,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_aqconsumerincome1',
      trackerNames: [],
      title: 'ca_aqconsumerincome1',
      dealerUserId,
      did,
    });
    // eslint-disable-next-line
  }, []);

  const handleSelectIncomeSource = (appIncomeSource, values) => {
    const showEmp = appIncomeSource === 'salary/wages';
    dispatchChangeValue('isEmployed', showEmp);
    dispatchChangeValue('appIncomeSource', appIncomeSource);
    dispatchChangeValue('appEmployerName', isEmployed ? values.appEmployerName : null);
    dispatchChangeValue('appEmploymentPosition', isEmployed ? values.appEmploymentPosition : null);
    dispatchChangeValue('appEmploymentYears', isEmployed ? values.appEmploymentYears : null);
    dispatchChangeValue('appEmploymentMonths', isEmployed ? values.appEmploymentMonths : null);
    dispatchChangeValue(
      'appEmployerPhoneNumber',
      isEmployed && !!values.appEmployerPhoneNumber
        ? values.appEmployerPhoneNumber.match(/\d/g).join('')
        : '',
    );
    dispatchChangeValue('appSalaryIncome', parseInt(values.appSalaryIncome));
  };
  const AppEmploymentDetailsForm = ({ values }) => (
    <Form>
      <Field
        component={SelectWithHandleChange}
        label="Income (or Benefits) Type"
        id="appIncomeSource"
        tooltipText={INCOME_SOURCE_TOOLTIP_TEXT}
        name="appIncomeSource"
        type="select"
        selectValues={incomeSource}
        selectFirstName="Select One"
        handleChange={() =>
          handleSelectIncomeSource(
            document.getElementById('appIncomeSource').options[
              document.getElementById('appIncomeSource').selectedIndex
            ].value,
            {
              appEmployerName: values.appEmployerName,
              appEmploymentPosition: values.appEmploymentPosition,
              appEmploymentYears: parseInt(values.appEmploymentYears),
              appEmploymentMonths: parseInt(values.appEmploymentMonths),
              appEmployerPhoneNumber: values.appEmployerPhoneNumber,
              appSalaryIncome: parseInt(values.appSalaryIncome),
            },
          )
        }
      />
      {isEmployed ? (
        <React.Fragment>
          <Field
            component={CustomInput}
            label="Employer Name"
            name="appEmployerName"
            id="appEmployerName"
            tooltipText={EMPLOYER_NAME_TOOLTIP_TEXT}
            type="text"
          />
          <Field
            component={CustomInput}
            label="Your Position"
            name="appEmploymentPosition"
            id="appEmploymentPosition"
            tooltipText={EMPLOYMENT_POSITION_TOOLTIP_TEXT}
            type="text"
          />
          <br />
          <label>
            <b>For how long have you been receiving this income?</b>
          </label>
          <Row>
            <Col sm="6">
              <Field
                component={CustomInput}
                label="Years"
                name="appEmploymentYears"
                id="appEmploymentYears"
                tooltipText={EMPLOYMENT_YEARS_TOOLTIP_TEXT}
                type="number"
              />
            </Col>
            <Col sm="6">
              <Field
                component={CustomInput}
                label="Months"
                name="appEmploymentMonths"
                id="appEmploymentMonths"
                tooltipText={EMPLOYMENT_MONTHS_TOOLTIP_TEXT}
                type="number"
              />
            </Col>
          </Row>
          <Field
            component={CustomPhoneNumber}
            label="Employer's Phone Number"
            name="appEmployerPhoneNumber"
            id="appEmployerPhoneNumber"
            tooltipText={EMPLOYER_PHONE_NUMBER_TOOLTIP_TEXT}
          />
        </React.Fragment>
      ) : null}
      <Field
        component={CurrencyFormat}
        label={
          aquaInformation.appIncomeSource &&
          incomeSource[
            Object.keys(incomeSource).find(
              key => incomeSource[key].value === aquaInformation.appIncomeSource,
            )
          ]
            ? incomeSource[
                Object.keys(incomeSource).find(
                  key => incomeSource[key].value === aquaInformation.appIncomeSource,
                )
              ].name + ' Income (or Benefits) Amount'
            : 'Income (or Benefits) Amount'
        }
        name="appSalaryIncome"
        id="appSalaryIncome"
        tooltipText={SALARY_INCOME_TOOLTIP_TEXT}
        type="text"
      />
      <Button type="submit" title="Next" />
    </Form>
  );

  const AppEmploymentDetailsFormFormik = withFormik({
    mapPropsToValues: () => ({
      appIncomeSource: aquaInformation.appIncomeSource || '',
      appEmployerName: aquaInformation.appEmployerName || '',
      appEmploymentPosition: aquaInformation.appEmploymentPosition || '',
      appEmploymentYears:
        aquaInformation.appEmploymentYears === 0 ? 0 : aquaInformation.appEmploymentYears || '',
      appEmploymentMonths:
        aquaInformation.appEmploymentMonths === 0 ? 0 : aquaInformation.appEmploymentMonths || '',
      appEmployerPhoneNumber: aquaInformation.appEmployerPhoneNumber || '',
      appSalaryIncome: aquaInformation.appSalaryIncome || '',
    }),

    validationSchema: Yup.object({
      appIncomeSource: Yup.string().required('Please select your income source.'),
      appEmployerName: isEmployed
        ? Yup.string().required("Please enter your employer's name.")
        : Yup.string(),
      appEmploymentPosition: isEmployed
        ? Yup.string().required('Please enter your position.')
        : Yup.string(),
      appEmploymentYears: isEmployed
        ? Yup.number()
            .moreThan(-1, 'Please enter a positive number.')
            .integer('Please enter an integer number.')
            .max(70, 'Must be less than or equal to 70.')
            .required('Please enter years.')
        : Yup.string(),
      appEmploymentMonths: isEmployed
        ? Yup.number()
            .moreThan(-1, 'Please enter a positive number.')
            .integer('Please enter an integer number.')
            .max(11, 'Must be less than or equal to 11.')
            .required('Please enter months.')
        : /*.test({
              name: 'total',
              exclusive: false,
              message: 'Years or Months should be 1.',
              test: function(value) {
                return 0 < value + (this.resolve(Yup.ref('appEmploymentYears')) || 0);
              },
            })*/
          Yup.string(),
      appEmployerPhoneNumber: isEmployed
        ? Yup.string()
            .test(
              'required',
              "Please enter your employer's phone number.",
              val => val !== '+' && val,
            )
            .test(
              'len',
              'Invalid phone number',
              val => val && val.match(/\d/g).join('').length === 10,
            )
        : Yup.string(),
      appSalaryIncome: Yup.number()
        .required('Please enter your ' + (aquaInformation.appIncomeSource || '') + ' income.')
        .moreThan(0, 'Please enter a positive number.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did: did,
        dealerUserId: dealerUserId,
      });
      dispatchChangeValue('appIncomeSource', values.appIncomeSource);
      dispatchChangeValue('appEmployerName', isEmployed ? values.appEmployerName : null);
      dispatchChangeValue(
        'appEmploymentPosition',
        isEmployed ? values.appEmploymentPosition : null,
      );
      dispatchChangeValue(
        'appEmploymentYears',
        isEmployed ? parseInt(values.appEmploymentYears) : null,
      );
      dispatchChangeValue(
        'appEmploymentMonths',
        isEmployed ? parseInt(values.appEmploymentMonths) : null,
      );
      dispatchChangeValue(
        'appEmployerPhoneNumber',
        isEmployed && !!values.appEmployerPhoneNumber
          ? values.appEmployerPhoneNumber.match(/\d/g).join('')
          : null,
      );
      dispatchChangeValue('appSalaryIncome', parseInt(values.appSalaryIncome));

      dispatchChangePage(AquaFlowPageNumber.AppOtherIncome);
      dispatchSubmitMindfire({
        email: email,
        company: values.appEmployerName,
        lastPage: '0040',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(AppEmploymentDetailsForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={
            isReview
              ? AquaFlowPageNumber.ApplicationReview
              : AquaFlowPageNumber.AppIdentificationInfo
          }
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <AppEmploymentDetailsFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  aquaInformation: state.aquaInformation,
  isEmployed: state.aquaInformation.isEmployed,
  email: state.preQualificationInformation.email,
  isReview: state.aquaInformation.isReview,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) => dispatch(changeAquaInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

AppEmploymentDetails.propTypes = {
  aquaInformation: PropTypes.object,
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppEmploymentDetails);
