const PropertyTypeEnum = {
  Single: { value: 'singleFamily', name: 'Single Family' },
  Condo: { value: 'condominiumOrTownhouse', name: 'Condo/Town Home' },
  Multi: { value: 'multiFamily', name: 'Multi-Family (6 or Fewer Units)' },
  Mobile: {
    value: 'mobile',
    name: 'Mobile Home (Own the Property; Permanently Affixed)',
  },
  None: { value: 'none', name: 'None of the Above' },
};

export default PropertyTypeEnum;
