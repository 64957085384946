import React from 'react';
import PropTypes from 'prop-types';

import './Section.scss';

const EmptySection = props => {
  const { Component } = props;

  return <>{Component}</>;
};

EmptySection.propTypes = {
  Component: PropTypes.object,
};

export default EmptySection;
