import React from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';

const ProgressBar = ({ progressList, currentPage, style }) => {
  if (currentPage >= progressList?.[0]?.activeIndex) {
    return (
      <Row noGutters className="progress-bar-with-text">
        {progressList.map((chapter, index) => (
          <Col
            key={index}
            xs="5"
            sm="4"
            md="3"
            lg={Math.floor(12 / progressList.length)}
            className={currentPage >= chapter.activeIndex ? 'active' : ''}
          >
            <p>{chapter.value}</p>
          </Col>
        ))}
      </Row>
    );
  } else {
    return (
      <Row noGutters className="progress">
        <Col xs="12" className="progress">
          <div className="active" style={style} />
        </Col>
      </Row>
    );
  }
};

const mapStateToProps = state => ({
  currentPage: state.navi[`${state.navi.flowName}CurrentPage`],
});

export default connect(mapStateToProps)(ProgressBar);
