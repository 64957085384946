import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ApplicationFlowEnum from '../../../../assets/js/ApplicationFlowEnum';
import Section from '../../../../components/Section/Section';
import Header from '../../../../components/UI/Header/Header';
import PreQualificationFlow from './PreQualificationFlow';

const PreQualification = props => {
  const { state, flowName, history } = props;
  const page = state.navi[`${state.navi.flowName}CurrentPage`];

  if (flowName !== ApplicationFlowEnum.ATWATER_GREENSKY_PREQUALIFICATION.value) {
    history.push(Object.values(ApplicationFlowEnum).find(flow => flow.value === flowName).path);
    return <></>;
  }

  return (
    <>
      <Header
        title={PreQualificationFlow[page].props.title}
        pages={PreQualificationFlow.length - 1}
      />
      <Section Component={PreQualificationFlow[page]} {...state} />
    </>
  );
};

const mapStateToProps = state => ({
  state,
  flowName: state.navi.flowName,
});

export default withRouter(
  connect(
    mapStateToProps,
    null,
  )(PreQualification),
);
