const incomeFrequencyEnum = {
  ANUALLY: {
    value: 'Annually',
    name: 'Annually',
    numericValue: 1,
  },
  BIWEEKLY: {
    value: 'Biweekly',
    name: 'Biweekly',
    numericValue: 26,
  },
  MONTHLY: {
    value: 'Monthly',
    name: 'Monthly',
    numericValue: 12,
  },
  WEEKLY: {
    value: 'Weekly',
    name: 'Weekly',
    numericValue: 52,
  },
};

export const findIncomeFrequencyEnumName = value => {
  for (let key in incomeFrequencyEnum) {
    let frequency = incomeFrequencyEnum[key];
    if (frequency.value === value) {
      return frequency.name;
    }
  }
  return null;
};

export default incomeFrequencyEnum;
