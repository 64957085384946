import React from 'react';
import PropTypes from 'prop-types';
import { GoAlert } from 'react-icons/go';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ErrorModal = props => {
  const { isOpen, message, messageType, onClick, className } = props;

  return (
    <Modal isOpen={isOpen} className={className}>
      <ModalHeader className="error" toggle={onClick}>
        <GoAlert /> {messageType}
      </ModalHeader>
      <ModalBody>{message}</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onClick}>
          OK
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ErrorModal.propTypes = {
  message: PropTypes.object,
  messageType: PropTypes.string,
  removeNotification: PropTypes.func,
  className: PropTypes.string,
};

export default ErrorModal;
