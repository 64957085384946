import React from 'react';
import Section from '../../../../components/Section/Section';
import Header from '../../../../components/UI/Header/Header';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AtWaterFlow from './AtWaterFlow';
import ApplicationFlowEnum from '../../../../assets/js/ApplicationFlowEnum';

const AtWater = ({ history, atWaterFlowCurrentPage, flowName }) => {
  if (flowName !== ApplicationFlowEnum.ATWATER_FLOW.value) {
    history.push(Object.values(ApplicationFlowEnum).find(flow => flow.value === flowName).path);
    return <></>;
  }

  return (
    <>
      <Header
        title={AtWaterFlow[atWaterFlowCurrentPage ? atWaterFlowCurrentPage : 0]?.props?.title}
        pages={AtWaterFlow.length - 1}
      />
      <Section Component={AtWaterFlow[atWaterFlowCurrentPage ? atWaterFlowCurrentPage : 0]} />
    </>
  );
};

const mapStateToProps = state => ({
  atWaterFlowCurrentPage: state.navi.atWaterFlowCurrentPage,
  flowName: state.navi.flowName,
});

AtWater.propTypes = {
  atWaterFlowCurrentPage: PropTypes.number.isRequired,
  flowName: PropTypes.string,
};

export default connect(mapStateToProps)(AtWater);
