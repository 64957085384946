import { Field, Form, withFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import CurrencyFormat from '../../../components/UI/Form/NumberFormat/CurrencyFormat';
import { BORROWEDAMOUNTT_TOOLTIP_TEXT } from '../../../constants/tooltipTexts';
import { reactGAEvent, reactGAPageview, showFtlRepairOption } from '../../../helper';
import { changeFtlInfoValue, changePage, submitMindfire } from '../../../redux/actions/index';
import { FTLFlowPageNumber } from './FTLFlow';
import { FTL_PROJECT_TYPE_TOOLTIP_TEXT } from '../../../constants/tooltipTexts';
import FTLProjectTypeEnum from '../../../assets/js/FTLProjectTypeEnum';
import SelectWithHandleChange from '../../../components/UI/Form/Select/SelectWithHandleChange';
import { getIsOptimus } from '../../../config/settings';

const LoanAmount = props => {
  const {
    email,
    borrowedAmount,
    projectType,
    dispatchChangePage,
    dispatchChangeFtlInfoValue,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    isReview,
    applicationType,
    application,
    pqi,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_ftlloanamount',
      trackerNames: [],
      title: 'ca_ftlloanamount',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const isOptimus = getIsOptimus();

  const changeValues = values => {
    dispatchChangeFtlInfoValue('projectType', values.projectType);
    dispatchChangeFtlInfoValue('borrowedAmount', values.borrowedAmount);
  };

  const [lessThan, setLessThan] = useState(null);
  const [moreThan, setMoreThan] = useState(null);

  if (
    (applicationType === 'A' || applicationType === 'O') &&
    lessThan === null &&
    moreThan === null
  ) {
    setLessThan(299.99);
    setMoreThan(35001);
  } else if (applicationType === 'R' && lessThan === null && moreThan === null) {
    setLessThan(299.99);
    setMoreThan(12501);
  }

  const ProjectTypeWithLoanAmountForm = ({ values }) => (
    <Form>
      <Row>
        <Col sm="6">
          <h4>What home improvement project is being financed?</h4>
        </Col>
        <Col sm="6">
          <Field
            component={SelectWithHandleChange}
            label="Project Type"
            id="projectType"
            FTL_PROJECT_TYPE_TOOLTIP_TEXT
            tooltipText={FTL_PROJECT_TYPE_TOOLTIP_TEXT}
            name="projectType"
            type="select"
            selectValues={FTLProjectTypeEnum}
            selectFirstName="Select One"
            handleChange={e => changeValues({ ...values, projectType: e.target.value })}
          />
        </Col>
      </Row>

      <Row>
        <Col sm="6">
          <h4>How much do you want to finance?</h4>
        </Col>
        <Col sm="6">
          <Field
            component={CurrencyFormat}
            label="Enter Amount"
            name="borrowedAmount"
            id="borrowedAmount"
            tooltipText={BORROWEDAMOUNTT_TOOLTIP_TEXT}
          />
          <Button type="submit" title="Continue" />
        </Col>
      </Row>
    </Form>
  );

  const ProjectTypeWithLoanAmountFormFormik = withFormik({
    mapPropsToValues: () => ({
      projectType: projectType || '',
      borrowedAmount: borrowedAmount || '',
    }),

    validationSchema: Yup.object({
      projectType: Yup.string().required('Please select the project type.'),
      borrowedAmount: Yup.number().when('projectType', {
        is: 'service-repair-materials',
        then: Yup.number()
          .moreThan(299.99, `Amount should be at least $300.`)
          .lessThan(12501.0, 'Amount too high.')
          .required('Please enter an amount.'),
        otherwise: Yup.number()
          .moreThan(299.99, `Amount should be at least $300.`)
          .lessThan(35001.0, 'Amount too high.')
          .required('Please enter an amount.'),
      }),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Continue',
        did,
        dealerUserId,
      });
      changeValues(values);
      dispatchChangePage(isReview ? FTLFlowPageNumber.Review : FTLFlowPageNumber.BillingAddress);
      dispatchSubmitMindfire({
        email: email,
        borrowedAmount: values.borrowedAmount,
        lastPage: '12009',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(ProjectTypeWithLoanAmountForm);

  const LoanAmountForm = ({ values }) => (
    <Form>
      <Row>
        <Col sm="6">
          <h4>How much do you want to finance?</h4>
        </Col>
        <Col sm="6">
          <Field
            component={CurrencyFormat}
            label="Enter Amount"
            name="borrowedAmount"
            id="borrowedAmount"
            tooltipText={BORROWEDAMOUNTT_TOOLTIP_TEXT}
          />
          <Button type="submit" title="Continue" />
        </Col>
      </Row>
    </Form>
  );

  const LoanAmountFormFormik = withFormik({
    mapPropsToValues: () => ({
      borrowedAmount: borrowedAmount || '',
    }),

    validationSchema: Yup.object({
      borrowedAmount: Yup.number()
        .moreThan(lessThan, `Amount should be at least $300.`)
        .lessThan(moreThan, 'Amount too high.')
        .required('Please enter an amount.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Continue',
        did,
        dealerUserId,
      });
      changeValues(values);
      dispatchChangePage(isReview ? FTLFlowPageNumber.Review : FTLFlowPageNumber.BillingAddress);
      dispatchSubmitMindfire({
        email: email,
        borrowedAmount: values.borrowedAmount,
        lastPage: '12009',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(LoanAmountForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={
            isReview
              ? FTLFlowPageNumber.Review
              : showFtlRepairOption(application, pqi)
              ? FTLFlowPageNumber.LoanFor
              : FTLFlowPageNumber.Consent
          }
        />
        <Col sm="11">
          {isOptimus && applicationType && applicationType === 'O' ? (
            <ProjectTypeWithLoanAmountFormFormik />
          ) : (
            <LoanAmountFormFormik />
          )}
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  isReview: state.ftlInformation.isReview,
  borrowedAmount: state.ftlInformation.borrowedAmount,
  projectType: state.ftlInformation.projectType,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  applicationType: state.preQualificationInformation.airConditioningSystem,
  application: state.softPull.applicationSubmitDTO,
  pqi: state.preQualificationInformation,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeFtlInfoValue: (key, value) => dispatch(changeFtlInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoanAmount);
