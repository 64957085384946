import React from 'react';
import Loader from 'react-loader-spinner';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import SetYourPassword from './SetYourPassword';
import ErrorModal from '../../components/UI/Modal/Notification';
import Logo from '../../components/UI/Logo/Logo';
import { getTechnicalSupportNumber } from '../../config/settings';

const Index = ({ loading, ...props }) => {
  return (
    <div className="container-fluid">
      <Row className="login-page">
        <Col xs="12" md="6" lg="8" className="px-0 left">
          <Row noGutters className="login-image">
            <Logo />
          </Row>
        </Col>
        <Col xs="12" md="6" lg="4" className={`sign-in-panel ${false ? 'overflow-hidden' : ''}`}>
          <Row className="loader justify-content-center align-items-center" hidden={!loading}>
            <Loader type="Oval" color="#119c4a" height="80" width="80" />
          </Row>
          <>
            <SetYourPassword />
            <p className="info text-center mt-5">
              For Technical Support {getTechnicalSupportNumber()}
            </p>
          </>
        </Col>
      </Row>
      <ErrorModal />
    </div>
  );
};

const mapStateToProps = state => ({
  loading: state.notification.loading,
});

export default connect(
  mapStateToProps,
  null,
)(Index);
