import ApplicationCategoryEnum from './ApplicationCategoryEnum';

export const allApplicationStatusEnum = {
  AQUA_FINANCE_DENIED: { value: 'AD', name: 'Aqua Finance Denied' },
  AQUA_FINANCE_CANCELLED: { value: 'AC', name: 'Aqua Finance Cancelled' },
  AQUA_FINANCE_PROCESSING: { value: 'PR', name: 'Aqua Finance Processing' },
  AQUA_FINANCE_SUBMITTED: { value: 'AS', name: 'Aqua Finance Submitted' },
  AQUA_FINANCE_PENDING: { value: 'PG', name: 'Aqua Finance Pending' },
  AQUA_FINANCE_PROBLEM: { value: 'PB', name: 'Aqua Finance Problem' },
  AQUA_FINANCE_APPROVED: { value: 'PP', name: 'Aqua Finance Approved' },
  AQUA_FINANCE_READY_FOR_FUNDING: { value: 'AR', name: 'Aqua Finance Ready For Funding' },
  AQUA_FINANCE_CONDITIONALLY_APPROVED: {
    value: 'CP',
    name: 'Aqua Finance Conditionally Approved',
  },
  AQUA_FINANCE_RECEIVED: { value: 'RC', name: 'Aqua Finance Received' },
  AQUA_FINANCE_PENDING_LLC: {
    value: 'PC',
    name: 'Aqua Finance PendingLCC',
    description: 'Aqua Finance PendingLCC',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  AQUA_FINANCE_FUNDED: {
    value: 'FD',
    name: 'Aqua Finance Funded',
    description: 'Aqua Finance Funded',
    category: ApplicationCategoryEnum.COMPLETE,
  },
  AQUA_FINANCE_IN_VERIFICATION: {
    value: 'VE',
    name: 'Aqua Finance In Verification',
    description: 'Aqua Finance In Verification',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  AQUA_FINANCE_SEND_BACK: {
    value: 'SB',
    name: 'Aqua Finance Send Back',
    description: 'Aqua Finance Send Back',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  AQUA_FINANCE_ON_HOLD: {
    value: 'HO',
    name: 'Aqua Finance On Hold',
    description: 'Aqua Finance On Hold',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  AQUA_FINANCE_IN_PRE_VERIFICATION: {
    value: 'AV',
    name: 'Aqua Finance In PreVerification',
    description: 'Aqua Finance In PreVerification',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  AQUA_HISTORY: {
    value: 'AH',
    name: 'Aqua Finance History',
    description: 'Aqua Finance History',
    category: ApplicationCategoryEnum.MISSED_OPPORTUNITY,
  },
  ABANDONED: {
    value: 'AB',
    name: 'Abandoned',
    description: 'Abandoned',
    category: ApplicationCategoryEnum.MISSED_OPPORTUNITY,
  },
  DECLINED: {
    value: 'DE',
    name: 'Declined',
    description: 'Declined',
    category: ApplicationCategoryEnum.MISSED_OPPORTUNITY,
  },
  INVALID: {
    value: 'IN',
    name: 'Invalid',
    description: 'Invalid',
    category: ApplicationCategoryEnum.MISSED_OPPORTUNITY,
  },
  OFFER_REJECTED: {
    value: 'RJ',
    name: 'Offer Rejected',
    description: 'Offer Rejected',
    category: ApplicationCategoryEnum.MISSED_OPPORTUNITY,
  },
  ADDED_BY_ESS: {
    value: 'AA',
    name: 'Added by ESS API',
    description: 'Added by ESS API',
    category: ApplicationCategoryEnum.ADDED_BY_API,
  },
  ADDED_BY_GREENSKY: {
    value: 'AZ',
    name: 'Added by Greensky API',
    description: 'Added by Greensky API',
    category: ApplicationCategoryEnum.ADDED_BY_API,
  },
  ADDED_BY_SERVICE_FINANCE: {
    value: 'SF',
    name: 'Added by Service Finance API',
    description: 'Added by Service Finance API',
    category: ApplicationCategoryEnum.ADDED_BY_API,
  },
  ADDED_BY_FTL: {
    value: 'FL',
    name: 'Added by FTL API',
    description: 'Added by FTL API',
    category: ApplicationCategoryEnum.ADDED_BY_API,
  },
  ADDED_BY_PARTNER_API: {
    value: 'AO',
    name: 'Added by Partner API',
    description: 'Added by Partner API',
    category: ApplicationCategoryEnum.ADDED_BY_API,
  },
  APPROVED_FULL: {
    value: 'AF',
    name: 'Fully Approved',
    description: 'Fully Approved',
    category: ApplicationCategoryEnum.UNSUBMITTED,
  },
  APPROVED_PARTIAL: {
    value: 'AP',
    name: 'Partially Approved',
    description: 'Partially Approved',
    category: ApplicationCategoryEnum.UNSUBMITTED,
  },
  DOCS_SUBMITTED: {
    value: 'DS',
    name: 'Documentation Submitted',
    description: 'Documentation Submitted',
    category: ApplicationCategoryEnum.UNSUBMITTED,
  },
  NEEDS_REVIEW: {
    value: 'NR',
    name: 'Needs Review',
    description: 'Needs Review',
    category: ApplicationCategoryEnum.UNSUBMITTED,
  },
  RESUBMISSION_PENDING: {
    value: 'RS',
    name: 'Resubmission Pending',
    description: 'Resubmission Pending',
    category: ApplicationCategoryEnum.UNSUBMITTED,
  },
  TERMS_ACCEPTED: {
    value: 'TA',
    name: 'Terms Accepted',
    description: 'Terms Accepted',
    category: ApplicationCategoryEnum.UNSUBMITTED,
  },
  TOO_MANY_SUBJECTS_EXCEPTION: {
    value: 'SE',
    name: 'Too Many Subjects Exception',
    description: 'Too Many Subjects Exception',
    category: ApplicationCategoryEnum.UNSUBMITTED,
  },
  APPLICATION_ORIGINATION_EXCEPTION: {
    value: 'OE',
    name: 'Application Origination Exception',
    description: 'Application Origination Exception',
    category: ApplicationCategoryEnum.UNSUBMITTED,
  },
  AWAIT_DEALER: {
    value: 'AW',
    name: 'Await Dealer',
    description: 'Await Dealer',
    category: ApplicationCategoryEnum.NEW_OPPORTUNITY,
  },
  COMPLETED: {
    value: 'CO',
    name: 'Completed',
    description: 'Completed',
    category: ApplicationCategoryEnum.COMPLETE,
  },
  DECISION_PENDING: {
    value: 'DP',
    name: 'Decision Pending',
    description: 'Decision Pending',
    category: ApplicationCategoryEnum.OPEN,
  },
  MISSING_INFORMATION: {
    value: 'MI',
    name: 'Pending Missing Information',
    description: 'Pending Missing Information',
    category: ApplicationCategoryEnum.OPEN,
  },
  PRE_APPROVED: {
    value: 'PA',
    name: 'Preapproved',
    description: 'Preapproved',
    category: ApplicationCategoryEnum.OPEN,
  },
  CLOSED_WON: {
    value: 'CW',
    name: 'Closed Won',
    description: 'Closed Won',
    category: ApplicationCategoryEnum.OPEN,
  },
  STARTED: {
    value: 'ST',
    name: 'Unsubmitted',
    description: 'Started',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  RHEEM_APPROVED: {
    value: 'RA',
    name: 'Rheem Approved',
    description: 'Rheem Approved',
    category: ApplicationCategoryEnum.UNSUBMITTED,
  },
  RHEEM_DECLINED: {
    value: 'RD',
    name: 'Rheem Declined',
    description: 'Rheem Declined',
    category: ApplicationCategoryEnum.UNSUBMITTED,
  },
  WELLSFARGO_DENIED: {
    value: 'WD',
    name: 'Wells Fargo DENIED',
    description: 'Wells Fargo Denied',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  WELLSFARGO_APPROVE: {
    value: 'WA',
    name: 'Wells Fargo APPROVED',
    description: 'Wells Fargo Approved',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  WELLSFARGO_PENDING: {
    value: 'WP',
    name: 'Wells Fargo PENDING',
    description: 'Wells Fargo Pending',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  WELLSFARGO_STARTED: {
    value: 'WS',
    name: 'Wells Fargo STARTED',
    description: 'Wells Fargo Started',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  WELLSFARGO_FUNDED: {
    value: 'WF',
    name: 'Wells Fargo Funded',
    description: 'Wells Fargo Funded',
    category: ApplicationCategoryEnum.COMPLETE,
  },
  POWER_PAY_ERROR: {
    value: 'CB',
    name: 'PowerPay CB ERR',
    description: 'Error with app (infrequent)',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  POWER_PAY_AUTO_DECLINED: {
    value: 'PL',
    name: 'PowerPay Auto Declined',
    description: 'Auto Declined',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  POWER_PAY_DECLINED: {
    value: 'PN',
    name: 'PowerPay Declined',
    description: 'Declined',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  POWER_PAY_APPROVED: {
    value: 'PZ',
    name: 'PowerPay Approved',
    description: 'Approved (infrequent)',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  POWER_PAY_AUTO_APPROVED: {
    value: 'PX',
    name: 'PowerPay Auto Approved',
    description: 'Approved',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  POWER_PAY_SYSTEM_ERROR: {
    value: 'PY',
    name: 'PowerPay System Error',
    description: 'System Error',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  POWER_PAY_RECOMMEND_REVIEW: {
    value: 'PW',
    name: 'PowerPay Recommend Review',
    description: 'Recommend Review',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  POWER_PAY_CONTRACT_RECEIVED: {
    value: 'PT',
    name: 'PowerPay Contract Received',
    description: 'Contract Received',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  POWER_PAY_NOT_BOOKED: {
    value: 'PO',
    name: 'PowerPay Not Booked',
    description: 'PowerPay Not Booked',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  POWER_PAY_RETURN_TO_UW: {
    value: 'PU',
    name: 'PowerPay Return to UW',
    description: 'PowerPay Return to UW',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  POWER_PAY_CANCELLED: {
    value: 'PV',
    name: 'PowerPay Cancelled',
    description: 'PowerPay Cancelled',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  POWER_PAY_FUNDED_AND_COMPLETE: {
    value: 'PF',
    name: 'PowerPay Funded and Complete',
    description: 'PowerPay Funded and Complete',
    category: ApplicationCategoryEnum.COMPLETE,
  },
  POWER_PAY_READY_TO_BOOK: {
    value: 'PB',
    name: 'PowerPay Ready to Book',
    description: 'PowerPay Ready to Book',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  MOSAIC_NEW: {
    value: 'MN',
    name: 'Mosaic New',
    description: 'Mosaic New',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  MOSAIC_APPROVED: {
    value: 'MA',
    name: 'Mosaic APPROVED',
    description: 'Mosaic Approved',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  MOSAIC_DECLINED: {
    value: 'MD',
    name: 'Mosaic DECLINED',
    description: 'Mosaic Declined',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  MOSAIC_NOT_QUALIFIED: {
    value: 'MQ',
    name: 'Mosaic NOT_QUALIFIED',
    description: 'Mosaic Not Qualified',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  MOSAIC_BLOCKED: {
    value: 'MB',
    name: 'Mosaic BLOCKED',
    description: 'Mosaic Blocked',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  MOSAIC_NEEDS_REVIEW: {
    value: 'MR',
    name: 'Mosaic NEEDS_REVIEW',
    description: 'Mosaic Needs Review',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  MOSAIC_PENDING: {
    value: 'MP',
    name: 'Mosaic PENDING',
    description: 'Mosaic Pending',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  MOSAIC_CREDIT_APP_SENT: {
    value: 'MC',
    name: 'Mosaic Credit app sent',
    description: 'Mosaic Credit app sent',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  MOSAIC_CREDIT_APP_COMPLETED: {
    value: 'MZ',
    name: 'Mosaic Credit App Completed',
    description: 'Mosaic Credit App Completed',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  MOSAIC_CREDIT_APP_APPROVED: {
    value: 'MX',
    name: 'Mosaic Credit App Approved',
    description: 'Mosaic Credit App Approved',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  MOSAIC_CREDIT_APP_DENIED: {
    value: 'ME',
    name: 'Mosaic Credit App Denied',
    description: 'Mosaic Credit App Denied',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  MOSAIC_LOAN_APP_SENT: {
    value: 'MW',
    name: 'Mosaic Loan App Sent',
    description: 'Mosaic Review',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  MOSAIC_LOAN_APP_SUBMITTED: {
    value: 'MS',
    name: 'Mosaic Loan App Submitted',
    description: 'Mosaic Loan App Submitted',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  MOSAIC_LOAN_APP_APPROVED: {
    value: 'MO',
    name: 'Mosaic Loan App Approved',
    description: 'Mosaic Loan App Approved',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  MOSAIC_LOAN_APP_SINGNED: {
    value: 'MG',
    name: 'Mosaic Loan App Signed',
    description: 'Mosaic Loan App Signed',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  MOSAIC_LOAN_APP_COUNTERSINGNED: {
    value: 'MU',
    name: 'Mosaic Loan App Countersigned',
    description: 'Mosaic Loan App Countersigned',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  MOSAIC_BILL_OF_LANDING_SUBMITTED: {
    value: 'ML',
    name: 'Mosaic Bill Of Landing Submitted',
    description: 'Mosaic Bill Of Landing Submitted',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  MOSAIC_BILL_OF_LANDING_APPROVED: {
    value: 'MV',
    name: 'Mosaic Bill Of Landing Approved',
    description: 'Mosaic Bill Of Landing Approved',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  MOSAIC_INSTALLATION_COMPLETE: {
    value: 'MT',
    name: 'Mosaic Installation Complete',
    description: 'Mosaic Installation Complete',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  MOSAIC_INSTALLATION_CONFIRMED: {
    value: 'MM',
    name: 'Mosaic Installation Confirmed',
    description: 'Mosaic Installation Confirmed',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  MOSAIC_INSPECTION_COMPLETE: {
    value: 'MY',
    name: 'Mosaic Inspection Complete',
    description: 'Mosaic Inspection Complete',
    category: ApplicationCategoryEnum.COMPLETE,
  },
  MOSAIC_FINAL_CONFIRMATION: {
    value: 'MH',
    name: 'Mosaic Final Confirmation',
    description: 'Mosaic Final Confirmation',
    category: ApplicationCategoryEnum.COMPLETE,
  },
  MOSAIC_FINAL_PAYMENT_MADE: {
    value: 'MF',
    name: 'Mosaic Final payment made',
    description: 'Mosaic Final payment made',
    category: ApplicationCategoryEnum.COMPLETE,
  },
  MOSAIC_CLOSED: {
    value: 'MK',
    name: 'Mosaic Closed',
    description: 'Mosaic Closed',
    category: ApplicationCategoryEnum.COMPLETE,
  },
  GREENSKY_APPROVE: {
    value: 'GA',
    name: 'Greenky Approved',
    description: 'GreenSky Approved',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  GREENSKY_ASSIGNED: {
    value: 'GS',
    name: 'Greensky Assigned',
    description: 'Greensky Assigned',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  GREENSKY_DECLINED: {
    value: 'GD',
    name: 'Greensky Declined',
    description: 'GreenSky Declined',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  GREENSKY_PENDING: {
    value: 'GP',
    name: 'Greensky Pending',
    description: 'GreenSky Pending',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },

  GREENSKY_CANCELLED: {
    value: 'GC',
    name: 'Greensky Cancelled - please submit a new application',
    description: 'Greensky Cancelled - please submit a new application',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },

  // EnerBank
  ENERBANK_UNDECISIONED: {
    value: 'EU',
    name: 'EnerBank undecisioned',
    description: 'EnerBank undecisioned',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  ENERBANK_PREQUALIFIED: {
    value: 'EP',
    name: 'EnerBank prequalified',
    description: 'EnerBank prequalified',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  ENERBANK_APPROVED: {
    value: 'EA',
    name: 'EnerBank approved',
    description: 'EnerBank approved',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  ENERBANK_APPROVED_COMPLETED: {
    value: 'EB',
    name: 'EnerBank approved completed',
    description: 'EnerBank Approved',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  ENERBANK_DENIED: {
    value: 'ED',
    name: 'EnerBank denied',
    description: 'EnerBank denied',
    category: ApplicationCategoryEnum.MISSED_OPPORTUNITY,
  },
  //Foundation
  FOUNDATION_APPROVED: {
    value: 'FA',
    name: 'Foundation Approved',
    description: 'Foundation Approved',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  FOUNDATION_AUTO_APPROVED: {
    value: 'FX',
    name: 'Foundation Auto Approved',
    description: 'Foundation Auto Approved',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  FOUNDATION_PENDING: {
    value: 'FP',
    name: 'Foundation Pending',
    description: 'Foundation Pending',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  FOUNDATION_COUNTER_OFFERED: {
    value: 'FC',
    name: 'Foundation Counter Offered',
    description: 'Foundation Counter Offered',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  FOUNDATION_WITHDRAWN: {
    value: 'FW',
    name: 'Foundation Withdrawn',
    description: 'Foundation Withdrawn',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  FOUNDATION_REFER: {
    value: 'FR',
    name: 'Foundation Refer',
    description: 'Foundation Refer',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  FOUNDATION_NO_DECISION_RUN: {
    value: 'FN',
    name: 'Foundation No Decision Run',
    description: 'Foundation No Decision Run',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  FOUNDATION_DECLINE: {
    value: 'FE',
    name: 'Foundation Declined',
    description: 'Foundation Auto Declined',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  FOUNDATION_AUTO_DECLINED: {
    value: 'FZ',
    name: 'Foundation Declined',
    description: 'Foundation Auto Declined',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },

  //FTL
  FTL_NEW: {
    value: 'NW',
    name: 'FTL new',
    description: 'FTL new',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  FTL_IN_REVIEW: {
    value: 'IR',
    name: 'FTL in-review',
    description: 'FTL in-review',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  FTL_FLAGGED: {
    value: 'FF',
    name: 'FTL flagged',
    description: 'FTL flagged',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  FTL_APPROVED: {
    value: 'VD',
    name: 'FTL approved',
    description: 'FTL approved',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  FTL_APPROVED_WITH_INFO: {
    value: 'WI',
    name: 'FTL approved-with-info',
    description: 'FTL approved-with-info',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  FTL_APPROVE_MORE: {
    value: 'VF',
    name: 'FTL approve-more',
    description: 'FTL approve-more',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  FTL_APPROVED_MORE_WITH_INFO: {
    value: 'WX',
    name: 'FTL approve-more-with-info',
    description: 'FTL approve-more-with-info',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  FTL_DECLINED: {
    value: 'DC',
    name: 'FTL declined',
    description: 'FTL declined',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  FTL_EXPIRED: {
    value: 'EX',
    name: 'FTL expired',
    description: 'FTL expired',
    category: ApplicationCategoryEnum.COMPLETE,
  },
  FTL_COMPLETE: {
    value: 'FM',
    name: 'FTL complete',
    description: 'FTL complete',
    category: ApplicationCategoryEnum.COMPLETE,
  },
  FTL_APPROVE_LOAN_MORE: {
    value: 'VL',
    name: 'FTL approved-with-loanmore',
    description: 'FTL approved-with-loanmore',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  FTL_FUNDED: {
    value: 'FU',
    name: 'FTL funded',
    description: 'FTL funded',
    category: ApplicationCategoryEnum.COMPLETE,
  },
  FTL_PENDING_KBA: {
    value: 'FK',
    name: 'FTL pending-kba',
    description: 'FTL pending-kba',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  FTL_REVIEWING: {
    value: 'FG',
    name: 'FTL reviewing',
    description: 'FTL reviewing',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  FTL_ON_HOLD: {
    value: 'FH',
    name: 'FTL on-hold',
    description: 'FTL on-hold',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },

  //Upgrade

  UPGRADE_NEW: {
    value: 'UN',
    name: 'Upgrade NEW',
    description: 'Upgrade NEW',
    category: ApplicationCategoryEnum.NEW_OPPORTUNITY,
  },
  UPGRADE_OFFERED: {
    value: 'UO',
    name: 'Upgrade OFFERED',
    description: 'Upgrade OFFERED',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  UPGRADE_SUBMITTED: {
    value: 'US',
    name: 'Upgrade SUBMITTED',
    description: 'Upgrade SUBMITTED',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  UPGRADE_APPROVED: {
    value: 'UA',
    name: 'Upgrade APPROVED',
    description: 'Upgrade APPROVED',
    category: ApplicationCategoryEnum.COMPLETE,
  },
  UPGRADE_DECLINED: {
    value: 'UD',
    name: 'Upgrade DECLINED',
    description: 'Upgrade DECLINED',
    category: ApplicationCategoryEnum.COMPLETE,
  },
  UPGRADE_WITHDRAWN: {
    value: 'UW',
    name: 'Upgrade WITHDRAWN',
    description: 'Upgrade WITHDRAWN',
    category: ApplicationCategoryEnum.COMPLETE,
  },
  UPGRADE_OPENED: {
    value: 'UP',
    name: 'Upgrade OPENED',
    description: 'Upgrade OPENED',
    category: ApplicationCategoryEnum.UNDER_REVIEW,
  },
  UPGRADE_EXPIRED: {
    value: 'UE',
    name: 'Upgrade EXPIRED',
    description: 'Upgrade EXPIRED',
    category: ApplicationCategoryEnum.COMPLETE,
  },
  UPGRADE_CLOSED: {
    value: 'UC',
    name: 'Upgrade CLOSED',
    description: 'Upgrade CLOSED',
    category: ApplicationCategoryEnum.COMPLETE,
  },
};

export const getApplicationStatus = status => {
  for (let key in allApplicationStatusEnum) {
    if (key === status) {
      return allApplicationStatusEnum[key];
    }
  }
  return null;
};

const applicationStatusEnum = {
  aquaFinanceDeclines: {
    AQUA_FINANCE_DENIED: { value: 'AD', name: 'Aqua Finance Denied' },
    AQUA_FINANCE_CANCELLED: { value: 'AC', name: 'Aqua Finance Cancelled' },
    AQUA_HISTORY: { value: 'AH', name: 'Aqua Finance History' },
  },
  aquaFinanceIntermediates: {
    AQUA_FINANCE_PROCESSING: { value: 'PR', name: 'Aqua Finance Processing' },
    AQUA_FINANCE_SUBMITTED: { value: 'AS', name: 'Aqua Finance Submitted' },
    AQUA_FINANCE_PENDING: { value: 'PG', name: 'Aqua Finance Pending' },
    AQUA_FINANCE_PROBLEM: { value: 'PB', name: 'Aqua Finance Problem' },
    AQUA_FINANCE_PENDING_LLC: { value: 'PC', name: 'Aqua Finance PendingLCC' },
    AQUA_FINANCE_IN_VERIFICATION: { value: 'VE', name: 'Aqua Finance In Verification' },
    AQUA_FINANCE_SEND_BACK: { value: 'SB', name: 'Aqua Finance Send Back' },
    AQUA_FINANCE_ON_HOLD: { value: 'HO', name: 'Aqua Finance On Hold' },
    AQUA_FINANCE_IN_PRE_VERIFICATION: { value: 'AV', name: 'Aqua Finance In PreVerification' },
  },
  aquaFinanceApprove: {
    AQUA_FINANCE_APPROVED: { value: 'PP', name: 'Aqua Finance Approved' },
    AQUA_FINANCE_READY_FOR_FUNDING: { value: 'AR', name: 'Aqua Finance Ready For Funding' },
    AQUA_FINANCE_CONDITIONALLY_APPROVED: {
      value: 'CP',
      name: 'Aqua Finance Conditionally Approved',
    },
    AQUA_FINANCE_RECEIVED: { value: 'RC', name: 'Aqua Finance Received' },
  },

  microfMissedOpportunity: {
    ABANDONED: {
      value: 'AB',
      name: 'Abandoned',
      description: 'Abandoned',
      category: ApplicationCategoryEnum.MISSED_OPPORTUNITY,
    },
    DECLINED: {
      value: 'DE',
      name: 'Declined',
      description: 'Declined',
      category: ApplicationCategoryEnum.MISSED_OPPORTUNITY,
    },
    INVALID: {
      value: 'IN',
      name: 'Invalid',
      description: 'Invalid',
      category: ApplicationCategoryEnum.MISSED_OPPORTUNITY,
    },
    OFFER_REJECTED: {
      value: 'RJ',
      name: 'Offer Rejected',
      description: 'Offer Rejected',
      category: ApplicationCategoryEnum.MISSED_OPPORTUNITY,
    },
  },

  microfAddedByAPI: {
    ADDED_BY_ESS: {
      value: 'AA',
      name: 'Added by ESS API',
      description: 'Added by ESS API',
      category: ApplicationCategoryEnum.ADDED_BY_API,
    },
    ADDED_BY_GREENSKY: {
      value: 'AZ',
      name: 'Added by Greensky API',
      description: 'Added by Greensky API',
      category: ApplicationCategoryEnum.ADDED_BY_API,
    },
  },

  microfAddedByServiceFinance: {
    ADDED_BY_SERVICE_FINANCE: {
      value: 'SF',
      name: 'Added by Service Finance API',
      description: 'Added by Service Finance API',
      category: ApplicationCategoryEnum.ADDED_BY_API,
    },
  },

  microfAddedByFTL: {
    ADDED_BY_FTL: {
      value: 'FL',
      name: 'Added by FTL API',
      description: 'Added by FTL API',
      category: ApplicationCategoryEnum.ADDED_BY_API,
    },
  },

  microfAddedByPartnerApi: {
    ADDED_BY_PARTNER_API: {
      value: 'AO',
      name: 'Added by Partner API',
      description: 'Added by Partner API',
      category: ApplicationCategoryEnum.ADDED_BY_API,
    },
  },

  microfUnsubmitted: {
    APPROVED_FULL: {
      value: 'AF',
      name: 'Fully Approved',
      description: 'Fully Approved',
      category: ApplicationCategoryEnum.UNSUBMITTED,
    },
    APPROVED_PARTIAL: {
      value: 'AP',
      name: 'Partially Approved',
      description: 'Partially Approved',
      category: ApplicationCategoryEnum.UNSUBMITTED,
    },
    DOCS_SUBMITTED: {
      value: 'DS',
      name: 'Documentation Submitted',
      description: 'Documentation Submitted',
      category: ApplicationCategoryEnum.UNSUBMITTED,
    },
    NEEDS_REVIEW: {
      value: 'NR',
      name: 'Needs Review',
      description: 'Needs Review',
      category: ApplicationCategoryEnum.UNSUBMITTED,
    },
    RESUBMISSION_PENDING: {
      value: 'RS',
      name: 'Resubmission Pending',
      description: 'Resubmission Pending',
      category: ApplicationCategoryEnum.UNSUBMITTED,
    },
    TERMS_ACCEPTED: {
      value: 'TA',
      name: 'Terms Accepted',
      description: 'Terms Accepted',
      category: ApplicationCategoryEnum.UNSUBMITTED,
    },
    TOO_MANY_SUBJECTS_EXCEPTION: {
      value: 'SE',
      name: 'Too Many Subjects Exception',
      description: 'Too Many Subjects Exception',
      category: ApplicationCategoryEnum.UNSUBMITTED,
    },
    APPLICATION_ORIGINATION_EXCEPTION: {
      value: 'OE',
      name: 'Application Origination Exception',
      description: 'Application Origination Exception',
      category: ApplicationCategoryEnum.UNSUBMITTED,
    },
  },

  microfNewOpportunity: {
    AWAIT_DEALER: {
      value: 'AW',
      name: 'Await Dealer',
      description: 'Await Dealer',
      category: ApplicationCategoryEnum.NEW_OPPORTUNITY,
    },
  },

  micrfoCOmplete: {
    COMPLETED: {
      value: 'CO',
      name: 'Completed',
      description: 'Completed',
      category: ApplicationCategoryEnum.COMPLETE,
    },
  },

  microfOpen: {
    DECISION_PENDING: {
      value: 'DP',
      name: 'Decision Pending',
      description: 'Decision Pending',
      category: ApplicationCategoryEnum.OPEN,
    },
    MISSING_INFORMATION: {
      value: 'MI',
      name: 'Pending Missing Information',
      description: 'Pending Missing Information',
      category: ApplicationCategoryEnum.OPEN,
    },
    PRE_APPROVED: {
      value: 'PA',
      name: 'Preapproved',
      description: 'Preapproved',
      category: ApplicationCategoryEnum.OPEN,
    },
    CLOSED_WON: {
      value: 'CW',
      name: 'Closed Won',
      description: 'Closed Won',
      category: ApplicationCategoryEnum.OPEN,
    },
  },

  microfUnderReview: {
    STARTED: {
      value: 'ST',
      name: 'Unsubmitted',
      description: 'Started',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
  },

  // MICROF Pages Statuses
  microfFinalStatus: {
    PRE_APPROVED: {
      value: 'PA',
      name: 'Preapproved',
      description: 'Preapproved',
      category: ApplicationCategoryEnum.OPEN,
    },
    DECISION_PENDING: {
      value: 'DP',
      name: 'Decision Pending',
      description: 'Decision Pending',
      category: ApplicationCategoryEnum.OPEN,
    },
    OFFER_REJECTED: {
      value: 'RJ',
      name: 'Offer Rejected',
      description: 'Offer Rejected',
      category: ApplicationCategoryEnum.MISSED_OPPORTUNITY,
    },
  },

  microfReviewStatus: {
    DECLINED: {
      value: 'DE',
      name: 'Declined',
      description: 'Declined',
      category: ApplicationCategoryEnum.MISSED_OPPORTUNITY,
    },
    NEEDS_REVIEW: {
      value: 'NR',
      name: 'Needs Review',
      description: 'Needs Review',
      category: ApplicationCategoryEnum.UNSUBMITTED,
    },
    APPROVED_FULL: {
      value: 'AF',
      name: 'Fully Approved',
      description: 'Fully Approved',
      category: ApplicationCategoryEnum.UNSUBMITTED,
    },
    APPROVED_PARTIAL: {
      value: 'AP',
      name: 'Partially Approved',
      description: 'Partially Approved',
      category: ApplicationCategoryEnum.UNSUBMITTED,
    },
  },

  microfReviewDeclinedStatus: {
    DECLINED: {
      value: 'DE',
      name: 'Declined',
      description: 'Declined',
      category: ApplicationCategoryEnum.MISSED_OPPORTUNITY,
    },
  },

  microfReviewNeedsReviewStatus: {
    NEEDS_REVIEW: {
      value: 'NR',
      name: 'Needs Review',
      description: 'Needs Review',
      category: ApplicationCategoryEnum.UNSUBMITTED,
    },
  },

  microfReviewApprovedStatus: {
    APPROVED_FULL: {
      value: 'AF',
      name: 'Fully Approved',
      description: 'Fully Approved',
      category: ApplicationCategoryEnum.UNSUBMITTED,
    },
    APPROVED_PARTIAL: {
      value: 'AP',
      name: 'Partially Approved',
      description: 'Partially Approved',
      category: ApplicationCategoryEnum.UNSUBMITTED,
    },
  },

  microfAcceptedStatus: {
    TERMS_ACCEPTED: {
      value: 'TA',
      name: 'Terms Accepted',
      description: 'Terms Accepted',
      category: ApplicationCategoryEnum.UNSUBMITTED,
    },
  },

  microfNeedsReviewStatus: {
    NEEDS_REVIEW: {
      value: 'NR',
      name: 'Needs Review',
      description: 'Needs Review',
      category: ApplicationCategoryEnum.UNSUBMITTED,
    },
  },

  microfFromNeedsReviewStatus: {
    DOCS_SUBMITTED: {
      value: 'DS',
      name: 'Documentation Submitted',
      description: 'Documentation Submitted',
      category: ApplicationCategoryEnum.UNSUBMITTED,
    },
  },

  microfRedirectStatus: {
    ABANDONED: {
      value: 'AB',
      name: 'Abandoned',
      description: 'Abandoned',
      category: ApplicationCategoryEnum.MISSED_OPPORTUNITY,
    },
    INVALID: {
      value: 'IN',
      name: 'Invalid',
      description: 'Invalid',
      category: ApplicationCategoryEnum.MISSED_OPPORTUNITY,
    },
    AWAIT_DEALER: {
      value: 'AW',
      name: 'Await Dealer',
      description: 'Await Dealer',
      category: ApplicationCategoryEnum.NEW_OPPORTUNITY,
    },
    COMPLETED: {
      value: 'CO',
      name: 'Completed',
      description: 'Completed',
      category: ApplicationCategoryEnum.COMPLETE,
    },
    MISSING_INFORMATION: {
      value: 'MI',
      name: 'Pending Missing Information',
      description: 'Pending Missing Information',
      category: ApplicationCategoryEnum.OPEN,
    },
    CLOSED_WON: {
      value: 'CW',
      name: 'Closed Won',
      description: 'Closed Won',
      category: ApplicationCategoryEnum.OPEN,
    },
    OFFER_REJECTED: {
      value: 'RJ',
      name: 'Offer Rejected',
      description: 'Offer Rejected',
      category: ApplicationCategoryEnum.MISSED_OPPORTUNITY,
    },
  },

  microfResubmitAppStatus: {
    RESUBMISSION_PENDING: {
      value: 'RS',
      name: 'Resubmission Pending',
      description: 'Resubmission Pending',
      category: ApplicationCategoryEnum.UNSUBMITTED,
    },
    APPLICATION_ORIGINATION_EXCEPTION: {
      value: 'OE',
      name: 'Application Origination Exception',
      description: 'Application Origination Exception',
      category: ApplicationCategoryEnum.UNSUBMITTED,
    },
    TOO_MANY_SUBJECTS_EXCEPTION: {
      value: 'SE',
      name: 'Too Many Subjects Exception',
      description: 'Too Many Subjects Exception',
      category: ApplicationCategoryEnum.UNSUBMITTED,
    },
  },

  rheemApprove: {
    RHEEM_APPROVED: {
      value: 'RA',
      name: 'Rheem Approved',
      description: 'Rheem Approved',
      category: ApplicationCategoryEnum.UNSUBMITTED,
    },
  },

  rheemDecline: {
    RHEEM_DECLINED: {
      value: 'RD',
      name: 'Rheem Declined',
      description: 'Rheem Declined',
      category: ApplicationCategoryEnum.UNSUBMITTED,
    },
  },

  wellsFargoDecline: {
    WELLSFARGO_DENIED: {
      value: 'WD',
      name: 'Wells Fargo DENIED',
      description: 'Wells Fargo Denied',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
  },
  wellsFargoApprove: {
    WELLSFARGO_APPROVE: {
      value: 'WA',
      name: 'Wells Fargo APPROVED',
      description: 'Wells Fargo Approved',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
  },
  wellsFargoIntermediates: {
    WELLSFARGO_PENDING: {
      value: 'WP',
      name: 'Wells Fargo PENDING',
      description: 'Wells Fargo Pending',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
  },

  powerPayDecline: {
    POWER_PAY_ERROR: {
      value: 'CB',
      name: 'PowerPay CB ERR',
      description: 'Error with app (infrequent)',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    POWER_PAY_AUTO_DECLINED: {
      value: 'PL',
      name: 'PowerPay Auto Declined',
      description: 'Auto Declined',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    POWER_PAY_DECLINED: {
      value: 'PN',
      name: 'PowerPay Declined',
      description: 'Declined',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    POWER_PAY_CANCELLED: {
      value: 'PV',
      name: 'PowerPay Cancelled',
      description: 'Cancelled',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
  },
  powerPayApprove: {
    POWER_PAY_APPROVED: {
      value: 'PZ',
      name: 'PowerPay Approved',
      description: 'Approved (infrequent)',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    POWER_PAY_AUTO_APPROVED: {
      value: 'PX',
      name: 'PowerPay Auto Approved',
      description: 'Approved',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
  },
  powerPayIntermediates: {
    POWER_PAY_SYSTEM_ERROR: {
      value: 'PY',
      name: 'PowerPay System Error',
      description: 'System Error',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    POWER_PAY_RECOMMEND_REVIEW: {
      value: 'PW',
      name: 'PowerPay Recommend Review',
      description: 'Recommend Review',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    POWER_PAY_CONTRACT_RECEIVED: {
      value: 'PT',
      name: 'PowerPay Contract Received',
      description: 'Contract Received',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
  },

  mosaicNew: {
    MOSAIC_NEW: {
      value: 'MN',
      name: 'Mosaic New',
      description: 'Mosaic New',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    MOSAIC_CREDIT_APP_SENT: {
      value: 'MC',
      name: 'Mosaic Credit app sent',
      description: 'Mosaic Credit App Sent',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
  },
  mosaicApproved: {
    MOSAIC_APPROVED: {
      value: 'MA',
      name: 'Mosaic APPROVED',
      description: 'Mosaic Approved',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    MOSAIC_LOAN_APP_APPROVED: {
      value: 'MO',
      name: 'Mosaic Loan app approved',
      description: 'Mosaic Loan App Approved',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    MOSAIC_CREDIT_APP_APPROVED: {
      value: 'MX',
      name: 'Mosaic Credit app approved',
      description: 'Mosaic Credit App Approved',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
  },
  mosaicDeclined: {
    MOSAIC_DECLINED: {
      value: 'MD',
      name: 'Mosaic DECLINED',
      description: 'Mosaic Declined',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    MOSAIC_NOT_QUALIFIED: {
      value: 'MQ',
      name: 'Mosaic NOT_QUALIFIED',
      description: 'Mosaic Not Qualified',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    MOSAIC_BLOCKED: {
      value: 'MB',
      name: 'Mosaic BLOCKED',
      description: 'Mosaic Blocked',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    MOSAIC_CREDIT_APP_DENIED: {
      value: 'ME',
      name: 'Mosaic Credit app denied',
      description: 'Mosaic Credit App Denied',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
  },
  mosaicIntermediates: {
    MOSAIC_NEEDS_REVIEW: {
      value: 'MR',
      name: 'Mosaic NEEDS_REVIEW',
      description: 'Mosaic Needs Review',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    MOSAIC_PENDING: {
      value: 'MP',
      name: 'Mosaic PENDING',
      description: 'Mosaic Pending',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },

    MOSAIC_CREDIT_APP_COMPLETED: {
      value: 'MZ',
      name: 'Mosaic Credit app completed',
      description: 'Mosaic Credit App Completed',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    MOSAIC_LOAN_APP_SENT: {
      value: 'MW',
      name: 'Mosaic Loan app sent',
      description: 'Mosaic Loan App Sent',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    MOSAIC_LOAN_APP_SUBMITTED: {
      value: 'MS',
      name: 'Mosaic Loan app submitted',
      description: 'Mosaic Loan App Submitted',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
  },
  greenskyApproved: {
    GREENSKY_APPROVED: {
      value: 'GA',
      name: 'Greensky Approved',
      description: 'Greensky Approved',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    GREENSKY_ASSIGNED: {
      value: 'GS',
      name: 'Greensky Assigned',
      description: 'Greensky Assigned',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
  },
  greenskyIntermediates: {
    GREENSKY_PEDING: {
      value: 'GP',
      name: 'Greensky Pending',
      description: 'Greensky Pending',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
  },
  greenskyDeclined: {
    GREENSKY_DECLINED: {
      value: 'GD',
      name: 'Greensky Declined',
      description: 'Greensky Declined',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    GREENSKY_CANCELLED: {
      value: 'GC',
      name: 'Greensky Cancelled - please submit a new application',
      description: 'Greensky Cancelled - please submit a new application',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
  },
  foundationApproved: {
    FOUNDATION_APPROVED: {
      value: 'FA',
      name: 'Foundation Approved',
      description: 'Foundation Approved',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    FOUNDATION_AUTO_APPROVED: {
      value: 'FX',
      name: 'Foundation Auto Approved',
      description: 'Foundation Auto Approved',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
  },
  foundationIntermediates: {
    FOUNDATION_PENDING: {
      value: 'FP',
      name: 'Foundation Pending',
      description: 'Pending',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    FOUNDATION_COUNTER_OFFERED: {
      value: 'FC',
      name: 'Foundation Counter Offered',
      description: 'Pending',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    FOUNDATION_WITHDRAWN: {
      value: 'FW',
      name: 'Foundation Withdrawn',
      description: 'Pending',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    FOUNDATION_REFER: {
      value: 'FR',
      name: 'Foundation Refer',
      description: 'Pending',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    FOUNDATION_NO_DECISION_RUN: {
      value: 'FN',
      name: 'Foundation No Decision Run',
      description: 'Pending',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
  },
  foundationDeclined: {
    FOUNDATION_DECLINE: {
      value: 'FE',
      name: 'Foundation Declined',
      description: 'Foundation Declined',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    FOUNDATION_AUTO_DECLINED: {
      value: 'FZ',
      name: 'Foundation Declined',
      description: 'Foundation Auto Declined',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
  },

  greenSkyApproved: {
    GREENSKY_APPROVE: {
      value: 'GA',
      name: 'Greensky Approved',
      description: 'Greensky Approved',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    GREENSKY_ASSIGNED: {
      value: 'GS',
      name: 'Greensky Assigned',
      description: 'Greensky Assigned',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
  },

  greenSkyAssigned: {
    GREENSKY_ASSIGNED: {
      value: 'GS',
      name: 'Greensky Assigned',
      description: 'Greensky Assigned',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
  },

  greenSkyDeclined: {
    GREENSKY_DECLINED: {
      value: 'GD',
      name: 'Greensky Declined',
      description: 'Greensky Declined',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
  },

  greenSkyPending: {
    GREENSKY_PENDING: {
      value: 'GP',
      name: 'Greensky Pending',
      description: 'Greensky Pending',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
  },

  // EnerBank USA
  enerBankDecline: {
    ENERBANK_DENIED: {
      value: 'ED',
      name: 'EnerBank denied',
      description: 'EnerBank denied',
      category: ApplicationCategoryEnum.MISSED_OPPORTUNITY,
    },
  },
  enerBankApprove: {
    ENERBANK_APPROVED: {
      value: 'EA',
      name: 'EnerBank approved',
      description: 'EnerBank approved',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    ENERBANK_APPROVED_COMPLETED: {
      value: 'EB',
      name: 'EnerBank approved completed',
      description: 'EnerBank Approved',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
  },

  enerBankIntermediate: {
    ENERBANK_PREQUALIFIED: {
      value: 'EP',
      name: 'EnerBank prequalified',
      description: 'EnerBank prequalified',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
  },

  enerBankUndecisioned: {
    ENERBANK_UNDECISIONED: {
      value: 'EU',
      name: 'EnerBank undecisioned',
      description: 'EnerBank undecisioned',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
  },

  //FTL
  ftlIntermediate: {
    FTL_NEW: {
      value: 'NW',
      name: 'FTL new',
      description: 'FTL new',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    FTL_IN_REVIEW: {
      value: 'IR',
      name: 'FTL in-review',
      description: 'FTL in-review',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    FTL_FLAGGED: {
      value: 'FF',
      name: 'FTL flagged',
      description: 'FTL flagged',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    FTL_PENDING_KBA: {
      value: 'FK',
      name: 'FTL pending-kba',
      description: 'FTL pending-kba',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    FTL_REVIEWING: {
      value: 'FG',
      name: 'FTL reviewing',
      description: 'FTL reviewing',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    FTL_ON_HOLD: {
      value: 'FH',
      name: 'FTL on-hold',
      description: 'FTL on-hold',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
  },

  ftlApproved: {
    FTL_APPROVED: {
      value: 'VD',
      name: 'FTL approved',
      description: 'FTL approved',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    FTL_APPROVE_MORE: {
      value: 'VF',
      name: 'FTL approve-more',
      description: 'FTL approve-more',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    FTL_APPROVED_WITH_INFO: {
      value: 'WI',
      name: 'FTL approved-with-info',
      description: 'FTL approved-with-info',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    FTL_APPROVED_MORE_WITH_INFO: {
      value: 'WX',
      name: 'FTL approve-more-with-info',
      description: 'FTL approve-more-with-info',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    FTL_APPROVE_LOAN_MORE: {
      value: 'VL',
      name: 'FTL approved-with-loanmore',
      description: 'FTL approved-with-loanmore',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
  },

  ftlApprovedWithInfo: {
    FTL_APPROVED_WITH_INFO: {
      value: 'WI',
      name: 'FTL approved-with-info',
      description: 'FTL approved-with-info',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    FTL_APPROVED_MORE_WITH_INFO: {
      value: 'WX',
      name: 'FTL approve-more-with-info',
      description: 'FTL approve-more-with-info',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
  },

  ftlApprovedMore: {
    FTL_APPROVE_MORE: {
      value: 'VF',
      name: 'FTL approve-more',
      description: 'FTL approve-more',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    FTL_APPROVED_MORE_WITH_INFO: {
      value: 'WX',
      name: 'FTL approve-more-with-info',
      description: 'FTL approve-more-with-info',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
  },

  ftlApprovedWithLoanMore: {
    FTL_APPROVE_LOAN_MORE: {
      value: 'VL',
      name: 'FTL approved-with-loanmore',
      description: 'FTL approved-with-loanmore',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
  },

  ftlDeclined: {
    FTL_DECLINED: {
      value: 'DC',
      name: 'FTL declined',
      description: 'FTL declined',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
    FTL_TRASHED: {
      value: 'TS',
      name: 'FTL trashed',
      description: 'FTL trashed',
      category: ApplicationCategoryEnum.UNDER_REVIEW,
    },
  },

  ftlFinal: {
    FTL_EXPIRED: {
      value: 'EX',
      name: 'FTL expired',
      description: 'FTL expired',
      category: ApplicationCategoryEnum.COMPLETE,
    },
    FTL_COMPLETE: {
      value: 'FM',
      name: 'FTL complete',
      description: 'FTL complete',
      category: ApplicationCategoryEnum.COMPLETE,
    },
    FTL_FUNDED: {
      value: 'FU',
      name: 'FTL funded',
      description: 'FTL funded',
      category: ApplicationCategoryEnum.COMPLETE,
    },
  },
};

export default applicationStatusEnum;

export const isStatusAquaFinance = status => {
  for (let x in applicationStatusEnum.aquaFinanceDeclines) {
    if (applicationStatusEnum.aquaFinanceDeclines[x].value === status) {
      return true;
    }
  }
  for (let x in applicationStatusEnum.aquaFinanceApprove) {
    if (applicationStatusEnum.aquaFinanceApprove[x].value === status) {
      return true;
    }
  }
  for (let x in applicationStatusEnum.aquaFinanceIntermediates) {
    if (applicationStatusEnum.aquaFinanceIntermediates[x].value === status) {
      return true;
    }
  }
  return false;
};
export const isAquaDeclined = status =>
  Object.keys(applicationStatusEnum.aquaFinanceDeclines).includes(status);

export const isStatusAquaDecline = status => {
  for (let x in applicationStatusEnum.aquaFinanceDeclines) {
    if (applicationStatusEnum.aquaFinanceDeclines[x].value === status) {
      return true;
    }
  }
  return false;
};

export const isStatusAquaApprove = status => {
  for (let x in applicationStatusEnum.aquaFinanceApprove) {
    if (applicationStatusEnum.aquaFinanceApprove[x].value === status) {
      return true;
    }
  }
  return false;
};

export const isStatusAquaIntermediate = status => {
  for (let x in applicationStatusEnum.aquaFinanceIntermediates) {
    if (applicationStatusEnum.aquaFinanceIntermediates[x].value === status) {
      return true;
    }
  }
  return false;
};

export const isMicrofFinalStatus = status => {
  for (let key in applicationStatusEnum.microfFinalStatus) {
    if (key === status) {
      return true;
    }
  }
  return false;
};

export const isMicrofReviewStatus = status => {
  for (let key in applicationStatusEnum.microfReviewStatus) {
    if (key === status) {
      return true;
    }
  }
  return false;
};

export const isMicrofAcceptedStatus = status => {
  for (let key in applicationStatusEnum.microfAcceptedStatus) {
    if (key === status) {
      return true;
    }
  }
  return false;
};

export const isMicrofNeedsReviewStatus = (status, hasSelectedOffer) => {
  for (let key in applicationStatusEnum.microfNeedsReviewStatus) {
    if (key === status) {
      return true;
    }
  }
  if (!hasSelectedOffer) {
    for (let key in applicationStatusEnum.microfFromNeedsReviewStatus) {
      if (key === status) {
        return true;
      }
    }
  }
  return false;
};

export const isMicrofRedirectStatus = status => {
  for (let key in applicationStatusEnum.microfRedirectStatus) {
    if (key === status) {
      return true;
    }
  }
  return false;
};

export const isMicrofReviewDeclinedStatus = status => {
  for (let key in applicationStatusEnum.microfReviewDeclinedStatus) {
    if (key === status) {
      return true;
    }
  }
  return false;
};

export const isMicrofReviewNeedsReviewStatus = status => {
  for (let key in applicationStatusEnum.microfReviewNeedsReviewStatus) {
    if (key === status) {
      return true;
    }
  }
  return false;
};

export const isMicrofReviewApprovedStatus = status => {
  for (let key in applicationStatusEnum.microfReviewApprovedStatus) {
    if (key === status) {
      return true;
    }
  }
  return false;
};

export const isMicrofResubmitAppStatus = status => {
  for (let key in applicationStatusEnum.microfResubmitAppStatus) {
    if (key === status) {
      return true;
    }
  }
  return false;
};

export const isMicrofAddedByAPI = status => {
  for (let key in applicationStatusEnum.microfAddedByAPI) {
    if (key === status) {
      return true;
    }
  }
  return false;
};

export const isMicrofStatusOpen = status =>
  Object.keys(applicationStatusEnum.microfOpen).find(key => key === status);

export const isMicrofAddedByServiceFinance = status =>
  Object.keys(applicationStatusEnum.microfAddedByServiceFinance).find(key => key === status);

export const isMicrofStatusApproved = status => {
  if (applicationStatusEnum.microfOpen.PRE_APPROVED.value === status) {
    return true;
  }

  for (let key in applicationStatusEnum.microfReviewApprovedStatus) {
    if (applicationStatusEnum.microfReviewApprovedStatus[key].value === status) {
      return true;
    }
  }

  return false;
};
export const isMicrofAddedByFTL = status =>
  Object.keys(applicationStatusEnum.microfAddedByFTL).find(key => key === status);

export const isMicrofAddedByPartnerApi = status =>
  Object.keys(applicationStatusEnum.microfAddedByPartnerApi).find(key => key === status);

export const isMicrofMissedOpportunity = status =>
  Object.keys(applicationStatusEnum.microfMissedOpportunity).find(key => key === status);

export const isMissedOpportunityProcessingStatus = status => {
  for (let key in applicationStatusEnum.microfMissedOpportunity) {
    if (applicationStatusEnum.microfMissedOpportunity[key].value === status) {
      return true;
    }
  }
};

export const isRheemApproved = status =>
  Object.keys(applicationStatusEnum.rheemApprove).find(key => key === status);

export const isRheemDeclined = status =>
  Object.keys(applicationStatusEnum.rheemDecline).find(key => key === status);

export const isPowerPayApproved = status =>
  Object.keys(applicationStatusEnum.powerPayApprove).includes(status);

export const isPowerPayDeclined = status =>
  Object.keys(applicationStatusEnum.powerPayDecline).includes(status) ||
  Object.keys(applicationStatusEnum.powerPayDecline).some(
    key => applicationStatusEnum.powerPayDecline[key].value === status,
  );

export const isPowerPayIntermediates = status =>
  Object.keys(applicationStatusEnum.powerPayIntermediates).includes(status);

export const getPowerPayStatus = status => {
  let st = applicationStatusEnum.powerPayApprove[status];
  if (st) {
    return st;
  }
  st = applicationStatusEnum.powerPayDecline[status];
  if (st) {
    return st;
  }
  st = applicationStatusEnum.powerPayIntermediates[status];
  if (st) {
    return st;
  }
  st = applicationStatusEnum.microfUnderReview[status];
  return st;
};

export const isWellsFargoApproved = status =>
  Object.keys(applicationStatusEnum.wellsFargoApprove).includes(status);

export const isWellsFargoDeclined = status =>
  Object.keys(applicationStatusEnum.wellsFargoDecline).includes(status);

export const isWellsFargoPending = status =>
  Object.keys(applicationStatusEnum.wellsFargoIntermediates).includes(status);

export const isUnderReview = status =>
  Object.keys(applicationStatusEnum.microfUnderReview).includes(status);

export const isMosaicNew = status => Object.keys(applicationStatusEnum.mosaicNew).includes(status);

export const isMosaicApproved = status =>
  Object.keys(applicationStatusEnum.mosaicApproved).includes(status);

export const isMosaicDeclined = status =>
  Object.keys(applicationStatusEnum.mosaicDeclined).includes(status) ||
  Object.keys(applicationStatusEnum.mosaicDeclined).some(
    key => applicationStatusEnum.mosaicDeclined[key].value === status,
  );

export const isMosaicIntermediates = status =>
  Object.keys(applicationStatusEnum.mosaicIntermediates).includes(status);

export const getMosaicStatus = status => {
  let st = applicationStatusEnum.mosaicNew[status];
  if (st) {
    return st;
  }
  st = applicationStatusEnum.mosaicApproved[status];
  if (st) {
    return st;
  }
  st = applicationStatusEnum.mosaicDeclined[status];
  if (st) {
    return st;
  }
  st = applicationStatusEnum.mosaicIntermediates[status];
  if (st) {
    return st;
  }
};

export const isFoundationApproved = status =>
  Object.keys(applicationStatusEnum.foundationApproved).find(key => key === status);

export const isFoundationDeclined = status =>
  Object.keys(applicationStatusEnum.foundationDeclined).find(key => key === status);

export const isFoundationIntermediates = status =>
  Object.keys(applicationStatusEnum.foundationIntermediates).find(key => key === status);

export const getFoundationStatus = status => {
  let st = applicationStatusEnum.foundationApproved[status];
  if (st) {
    return st;
  }
  st = applicationStatusEnum.foundationDeclined[status];
  if (st) {
    return st;
  }
  st = applicationStatusEnum.foundationIntermediates[status];
  if (st) {
    return st;
  }
  st = applicationStatusEnum.microfUnderReview[status];
  return st;
};
export const isGreenSkyApproved = status =>
  Object.keys(applicationStatusEnum.greenSkyApproved).includes(status);

export const isGreenSkyAssigned = status =>
  Object.keys(applicationStatusEnum.greenSkyAssigned).includes(status);

export const isGreenSkyDeclined = status =>
  Object.keys(applicationStatusEnum.greenSkyDeclined).includes(status);

export const isGreenSkyPending = status =>
  Object.keys(applicationStatusEnum.greenSkyPending).includes(status);

export const isEnerBankApproved = status =>
  Object.keys(applicationStatusEnum.enerBankApprove).includes(status);

export const isEnerBankDeclined = status =>
  Object.keys(applicationStatusEnum.enerBankDecline).includes(status) ||
  Object.keys(applicationStatusEnum.enerBankDecline).some(
    key => applicationStatusEnum.enerBankDecline[key].value === status,
  );

export const isEnerBankUndecisioned = status =>
  Object.keys(applicationStatusEnum.enerBankUndecisioned).includes(status);

export const isEnerBankIntermediates = status =>
  Object.keys(applicationStatusEnum.enerBankIntermediate).includes(status);

export const getEnerBankStatus = status => {
  let st = applicationStatusEnum.enerBankApprove[status];
  if (st) {
    return st;
  }
  st = applicationStatusEnum.enerBankDecline[status];
  if (st) {
    return st;
  }
  st = applicationStatusEnum.enerBankIntermediate[status];
  if (st) {
    return st;
  }
  st = applicationStatusEnum.microfUnderReview[status];
  return st;
};

export const isFTLApproved = status =>
  Object.keys(applicationStatusEnum.ftlApproved).includes(status);

export const isFTLDeclined = status =>
  Object.keys(applicationStatusEnum.ftlDeclined).includes(status);

export const isFTLIntermediate = status =>
  Object.keys(applicationStatusEnum.ftlIntermediate).includes(status);

export const isFTLApprovedMore = status =>
  Object.keys(applicationStatusEnum.ftlApprovedMore).includes(status);

export const isFTLApprovedWithInfo = status =>
  Object.keys(applicationStatusEnum.ftlApprovedWithInfo).includes(status);

export const isFTLApprovedWithLoanMore = status =>
  Object.keys(applicationStatusEnum.ftlApprovedWithLoanMore).includes(status);

export const isStarted = status => status === 'STARTED';
