import React, { useEffect } from 'react';
import { Field, Form, withFormik } from 'formik';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Row, Label, Button } from 'reactstrap';
import queryString from 'query-string';
import { updateMerchantNumber } from '../../../redux/actions/index';
import * as Yup from 'yup';
import InputField from '../../../components/UI/Form/Input/InputField';

const UpdateDealer = props => {
  const { auth, history, dispatchUpdateMerchantNumber } = props;

  const dealerId = queryString.parse(window.location.search).dealerId;

  useEffect(() => {
    if (!auth.user && (!auth.user.dealerUser || !auth.user.internalUser)) {
      history.push('/login');
    }
    // eslint-disable-next-line
  }, []);

  const UpdateDealerForm = () => (
    <Form>
      <Row className="mt-4">
        <Col sm="4">
          <Label for="firstName">Merchant Number</Label>
        </Col>
        <Col sm="4">
          <Field
            component={InputField}
            name="merchantNumber"
            id="merchantNumber"
            type="text"
            number
            maxLength={8}
          />
        </Col>
      </Row>

      <Row className="mt-5">
        <Col className="text-right">
          <Button
            type="submit"
            color="secondary"
            className="rounded-pill"
            outline
            onClick={() => {
              history.push('/portal/update-merchant-number');
            }}
          >
            Cancel
          </Button>

          <Button type="submit" color="primary" className="rounded-pill">
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );

  const UpdateDealerFormFormik = withFormik({
    mapPropsToValues: () => ({
      merchantNumber: '',
    }),

    validationSchema: Yup.object({
      merchantNumber: Yup.string().required('Please enter Merchant Number.'),
    }),

    handleSubmit: values => {
      dispatchUpdateMerchantNumber(values.merchantNumber, dealerId, history);
    },
  })(UpdateDealerForm);

  return (
    <>
      <Col>
        <Row className="mt-5">
          <h1 id="panelTitle">Update Merchant Number</h1>
          <div className="panel">
            <div className="panel-body larger">
              <div className="table-simple">
                <UpdateDealerFormFormik />
              </div>
            </div>
          </div>
        </Row>
      </Col>
    </>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  contractorUser: state.softPull.contractorUser,
});

const mapDispatchToProps = dispatch => ({
  dispatchUpdateMerchantNumber: (merchantNumber, dealerId, history) =>
    dispatch(updateMerchantNumber(merchantNumber, dealerId, history)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(UpdateDealer),
);
