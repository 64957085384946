import UserTypeEnum from './UserTypeEnum';

const userRoleEnum = {
  CONSUMER: {
    value: 'CO',
    name: 'Consumer',
    businessRole: true,
    userType: UserTypeEnum.USER_CONSUMER,
  },
  RELATIONSHIP_MANAGER: {
    value: 'RM',
    name: 'Relationship Manager',
    businessRole: true,
    userType: UserTypeEnum.USER_INTERNAL,
  },
  DEALER_CANDIDATE: {
    value: 'DC',
    name: 'Dealer Candidate',
    businessRole: false,
    userType: UserTypeEnum.USER_DEALER,
  },
  DEALER_EMPLOYEE: {
    value: 'DE',
    name: 'Dealer Employee',
    businessRole: true,
    userType: UserTypeEnum.USER_DEALER,
  },
  DEALER_ADMIN: {
    value: 'DA',
    name: 'Dealer Admin',
    businessRole: true,
    userType: UserTypeEnum.USER_DEALER,
  },
  DEALER_RELATIONSHIP_REP: {
    value: 'DR',
    name: 'Dealer Relationship Rep',
    businessRole: true,
    userType: UserTypeEnum.USER_INTERNAL,
  },
  RECOVERY_SPECIALIST: {
    value: 'RS',
    name: 'Recovery Specialist',
    businessRole: true,
    userType: UserTypeEnum.USER_INTERNAL,
  },
  RECOVERY_CONTROLLER: {
    value: 'RC',
    name: 'Recovery Controller',
    businessRole: true,
    userType: UserTypeEnum.USER_INTERNAL,
  },
  COLLECTIONS_MANAGER: {
    value: 'CM',
    name: 'Collections Manager',
    businessRole: true,
    userType: UserTypeEnum.USER_INTERNAL,
  },
  MARKETING: {
    value: 'MK',
    name: 'Marketing',
    businessRole: true,
    userType: UserTypeEnum.USER_INTERNAL,
  },
  USER_ADMINISTRATOR: {
    value: 'UA',
    name: 'User Administrator',
    businessRole: true,
    userType: UserTypeEnum.USER_INTERNAL,
  },
  ADMINISTRATOR: {
    value: 'AD',
    name: 'Administrator',
    businessRole: true,
    userType: UserTypeEnum.USER_INTERNAL,
  },
  SYSTEM_ADMINISTRATOR: {
    value: 'SA',
    name: 'System Administrator',
    businessRole: true,
    userType: UserTypeEnum.USER_INTERNAL,
  },
  VENDOR: {
    value: 'VD',
    name: 'Vendor',
    businessRole: false,
    userType: UserTypeEnum.USER_INTERNAL,
  },
};

export default userRoleEnum;

export const contractorUserEnum = {
  DEALER_EMPLOYEE: userRoleEnum.DEALER_EMPLOYEE,
  DEALER_ADMIN: userRoleEnum.DEALER_ADMIN,
};

export const internalUserEnum = {
  DEALER_RELATIONSHIP_REP: {
    value: 'DEALER_RELATIONSHIP_REP',
    name: 'Dealer Relationship Rep',
  },
  USER_ADMINISTRATOR: {
    value: 'USER_ADMINISTRATOR',
    name: 'User Administrator',
  },
};

export const searchInternalUserRoleEnum = {
  ADMINISTRATOR: {
    value: 'ADMINISTRATOR',
    name: 'Administrator',
  },
  COLLECTIONS_MANAGER: {
    value: 'COLLECTIONS_MANAGER',
    name: 'Collections Manager',
  },
  DEALER_RELATIONSHIP_REP: {
    value: 'DEALER_RELATIONSHIP_REP',
    name: 'Dealer Relationship Rep',
  },
  MARKETING: {
    value: 'MARKETING',
    name: 'Marketing',
  },
  RECOVERY_CONTROLLER: {
    value: 'RECOVERY_CONTROLLER',
    name: 'Recovery Controller',
  },
  RECOVERY_SPECIALIST: {
    value: 'RECOVERY_SPECIALIST',
    name: 'Recovery Specialist',
  },
  RELATIONSHIP_MANAGER: {
    value: 'RELATIONSHIP_MANAGER',
    name: 'Relationship Manager',
  },
  SYSTEM_ADMINISTRATOR: {
    value: 'SYSTEM_ADMINISTRATOR',
    name: 'System Administrator',
  },
  USER_ADMINISTRATOR: {
    value: 'USER_ADMINISTRATOR',
    name: 'User Administrator',
  },
  VENDOR: {
    value: 'VENDOR',
    name: 'Vendor',
  },
};

export const findBusinessRolesByUserType = userType => {
  let roles = [];
  for (let key in userRoleEnum) {
    let role = userRoleEnum[key];
    if (role.userType === userType && role.businessRole) {
      roles.push(role);
    }
  }
  return roles;
};

export const findByValue = value => {
  for (let key in userRoleEnum) {
    if (userRoleEnum[key].value === value) {
      return key;
    }
  }
  return null;
};

export const getByValue = value => {
  for (let key in userRoleEnum) {
    if (userRoleEnum[key].value === value) {
      return userRoleEnum[key];
    }
  }
  return null;
};

export const findSearchInternalUserRoleEnum = value => {
  for (let key in searchInternalUserRoleEnum) {
    let role = searchInternalUserRoleEnum[key];
    if (role.value === value) {
      return role.name;
    }
  }
  return null;
};
