import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { setFlow, changePage, resetState, resetNewAppState } from '../../../redux/actions/index';
import { defaultFlow } from '../../../redux/initialState';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './Header.scss';
import { AquaPreQualificationFlowPageNumber } from '../../../pages/PreQualification/preQualificationFlow';
import Logo from '../Logo/Logo';
import { getIsOptimus, getIsRheem } from '../../../config/settings';
import { isSpectrumAcFlow } from '../../../helper';
import ProgressBar from '../../../assets/js/ProgressBar';
import ApplicationFlowEnum from '../../../assets/js/ApplicationFlowEnum';
import userRoleEnum from '../../../assets/js/UserRoleEnum';

const isRheem = getIsRheem();
const isOptimus = getIsOptimus();

const Header = props => {
  const {
    pages,
    currentPage,
    pageTitle,
    dispatchSetFlow,
    dispatchChangePage,
    flowName,
    dispatchResetState,
    did,
    title,
    user,
    dispatchResetNewAppState,
    workUser,
    history,
  } = props;

  const isSpectrumLogo =
    !isRheem &&
    !isOptimus &&
    !(
      flowName === ApplicationFlowEnum.AQUA_PREQUALIFICATION.value &&
      currentPage === AquaPreQualificationFlowPageNumber.UserType
    ) &&
    isSpectrumAcFlow(flowName);
  const progressList = Object.values(ApplicationFlowEnum).find(flow => flowName === flow.value)
    ?.progressList;

  const goToHomeHandler = event => {
    if (user && user.dealerUser) {
      if (user.dealerUser.role === userRoleEnum.DEALER_CANDIDATE.value) {
        if (user.dealerUser.dealer) {
          history.push('/portal/candidate-contractor-dashboard');
        } else {
          dispatchResetState({ history: null });
        }
      } else {
        dispatchSetFlow(defaultFlow);
        if (did) dispatchResetNewAppState();
      }
    } else if (
      user.internalUser &&
      (user.internalUser.role === userRoleEnum.DEALER_RELATIONSHIP_REP.value ||
        user.internalUser.role === userRoleEnum.USER_ADMINISTRATOR.value)
    ) {
      dispatchResetNewAppState(props);
    } else {
      dispatchResetState(props);
      if (flowName === defaultFlow) {
        event.preventDefault();
      }
      dispatchSetFlow(defaultFlow);
      dispatchChangePage(AquaPreQualificationFlowPageNumber.UserType);
    }
  };

  const style = {
    width:
      pages === 0 || (100 / pages) * currentPage > 100
        ? '100%'
        : ((100 / pages) * currentPage).toString() + '%' || '0%',
  };

  return (
    <>
      {user && user.user && user.user.emulatorId ? (
        <div className="emulationText">
          Emulation Mode: {user.user.emulatorNameFirst} {user.user.emulatorNameLast} emulating{' '}
          {workUser.nameFirst} {workUser.nameLast}
        </div>
      ) : workUser ? (
        <div className="loggedText">
          Logged User: {workUser.nameFirst} {workUser.nameLast}
        </div>
      ) : null}
      <header>
        <Container>
          <Row className="soft-pull-header">
            <Col xs="auto" className="position-absolute">
              <Link
                to={
                  !did && user && user.dealerUser
                    ? '/portal/dashboard'
                    : user.internalUser &&
                      (user.internalUser.role === userRoleEnum.DEALER_RELATIONSHIP_REP.value ||
                        user.internalUser.role === userRoleEnum.USER_ADMINISTRATOR.value)
                    ? '/portal/manage-contractor-users'
                    : ''
                }
                onClick={goToHomeHandler}
              >
                <Logo isSpectrumLogo={false} />
              </Link>
            </Col>
            <Col xs="8" md="12" className="d-flex align-items-center">
              <h4>{title || pageTitle}</h4>
            </Col>
          </Row>
          <ProgressBar progressList={progressList} style={style} />
        </Container>
      </header>
    </>
  );
};

const mapStateToProps = state => ({
  did: state.auth.did,
  currentPage: state.navi[`${state.navi.flowName}CurrentPage`] || 0,
  isAuthenticated: state.auth.isAuthenticated,
  flowName: state.navi.flowName,
  user: state.auth.user,
  workUser:
    state.auth.user.consumerUser || state.auth.user.dealerUser || state.auth.user.internalUser,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchSetFlow: flowName => dispatch(setFlow(flowName)),
  dispatchResetState: props => dispatch(resetState(props)),
  dispatchResetNewAppState: () => dispatch(resetNewAppState()),
});

Header.propTypes = {
  currentPage: PropTypes.number,
  isAuthenticated: PropTypes.bool,
  flowName: PropTypes.string.isRequired,
  dispatchChangePage: PropTypes.func,
  dispatchSetFlow: PropTypes.func,
  hideLogin: PropTypes.bool,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Header),
);
