import React from 'react';
import { connect } from 'react-redux';
import ApproveMore from './ApproveMore';
import ApprovedWithInfo from './ApprovedWithInfo';
import ApprovedMoreWithInfo from './ApprovedMoreWithInfo';
import ApprovedLoanMore from './ApprovedLoanMore';
import Approved from './Approved';

const ApplicationFtl = props => {
  const { application, data } = props;

  return (
    <>
      {(application.status === 'FTL_APPROVE_MORE' ||
        (application.status === 'FTL_APPROVED' &&
          !data.ftlInformation.generatedPaperworkSuccess &&
          data.ftlInformation.selectedApproveMoreOptionId !== undefined)) && (
        <ApproveMore {...props} />
      )}
      {application.status === 'FTL_APPROVED_WITH_INFO' && <ApprovedWithInfo {...props} />}
      {application.status === 'FTL_APPROVED_MORE_WITH_INFO' && <ApprovedMoreWithInfo {...props} />}
      {(application.status === 'FTL_APPROVE_LOAN_MORE' ||
        (application.status === 'FTL_APPROVED' &&
          !data.ftlInformation.generatedPaperworkSuccess &&
          data.ftlInformation?.selectedLoanMoreOptionId !== undefined)) && (
        <ApprovedLoanMore {...props} />
      )}
      {application.status === 'FTL_APPROVED' &&
        !data.ftlInformation.generatedPaperworkSuccess &&
        data.ftlInformation.selectedApproveMoreOptionId === undefined &&
        data.ftlInformation.selectedLoanMoreOptionId === undefined && <Approved {...props} />}
      {application.status === 'FTL_APPROVED' && data.ftlInformation.generatedPaperworkSuccess && (
        <Approved {...props} />
      )}
    </>
  );
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(ApplicationFtl);
