import { Field } from 'formik';
import React from 'react';
import { Col, Label, Row } from 'reactstrap';
import InputField from '../../../components/UI/Form/Input/CustomInput';
import { isMobile } from 'react-device-detect';

const HvacRow = ({ index, hcBrands }) => {
  return (
    <Row className="mt-4">
      <Col sm="4" className="no-padding">
        <Label className="mb-0 pt-4">
          <b>Unit {index + 1}</b>
        </Label>
      </Col>
      <Col sm="4" className={isMobile ? 'no-padding' : ''}>
        <Field
          className="h-35p"
          formGroupClass="mt-0"
          labelClassName="hidden"
          component={InputField}
          name={`systemSerialNumber${index}`}
          id={`systemSerialNumber${index}`}
          type="text"
          placeholder="Serial number..."
          maxLength={50}
        />
      </Col>
      <Col sm="4" className={isMobile ? 'no-padding' : ''}>
        <Field
          className="h-35p"
          formGroupClass="mt-0"
          labelClassName="hidden"
          component={InputField}
          name={`systemModel${index}`}
          id={`systemModel${index}`}
          type="text"
          placeholder="Model..."
          maxLength={50}
          disabled={true}
        />
      </Col>
    </Row>
  );
};

export default HvacRow;
