const FunctionalEntitlementsEnum = {
  SESSION_CREATE: 'SESSION_CREATE',
  EMULATE_CONSUMER: 'EMULATE_CONSUMER',
  EMULATE_DEALER: 'EMULATE_DEALER',
  USER_CREATE: 'USER_CREATE',
  USER_VIEW: 'USER_VIEW',
  USER_UPDATE: 'USER_UPDATE',
  USER_DELETE: 'USER_DELETE',
  INTERNAL_USER_SEARCH: 'INTERNAL_USER_SEARCH',
  DEALER_USER_SEARCH: 'DEALER_USER_SEARCH',
  DEALER_SEARCH: 'DEALER_SEARCH',
  DEALER_VIEW: 'DEALER_VIEW',
  DEALER_UPDATE: 'DEALER_UPDATE',
  DEALER_APPLICATION_CREATE: 'DEALER_APPLICATION_CREATE',
  INTERNAL_USER_CREATE: 'INTERNAL_USER_CREATE',
  ROJOS_USER_CREATE: 'ROJOS_USER_CREATE',
  SELFDEALER_USER_SEARCH: 'SELFDEALER_USER_SEARCH',
  SELFDEALER_USER_CREATE: 'SELFDEALER_USER_CREATE',
  SELFDEALER_USER_VIEW: 'SELFDEALER_USER_VIEW',
  SELFDEALER_USER_UPDATE: 'SELFDEALER_USER_UPDATE',
  SELFDEALER_APPLICATION_VIEW: 'SELFDEALER_APPLICATION_VIEW',
  SELFDEALER_DEALER_INFORMATION: 'SELFDEALER_DEALER_INFORMATION',
  SELFDEALER_DEALER_PQI_REPORT: 'SELFDEALER_DEALER_PQI_REPORT',
  SELFCONSUMER_APPLICATION_VIEW: 'SELFCONSUMER_APPLICATION_VIEW',
  APPLICATION_CREATE: 'APPLICATION_CREATE',
  APPLICATION_VIEW: 'APPLICATION_VIEW',
  APPLICATION_SEARCH: 'APPLICATION_SEARCH',
  APPLICATION_ASSIGN: 'APPLICATION_ASSIGN',
  APPLICATION_MESSAGE: 'APPLICATION_MESSAGE',
  APPLICATION_MOVE: 'APPLICATION_MOVE',
  RECUPERATION_CREATE: 'RECUPERATION_CREATE',
  RECUPERATION_SEARCH: 'RECUPERATION_SEARCH',
  RECUPERATION_ASSIGN: 'RECUPERATION_ASSIGN',
  PAYMENT_CALCULATOR: 'PAYMENT_CALCULATOR',
  INVENTORY_VIEW: 'INVENTORY_VIEW',
  HELPDESK_TICKET_CREATE: 'HELPDESK_TICKET_CREATE',
  RESOURCE_CREATE: 'RESOURCE_CREATE',
  RESOURCE_SEARCH: 'RESOURCE_SEARCH',
  DISTRIBUTOR_ALL: 'DISTRIBUTOR_ALL',
  SITE_MESSAGE_ALL: 'SITE_MESSAGE_ALL',
  SYSTEM_PROPERTY_UPDATE: 'SYSTEM_PROPERTY_UPDATE',
  SYSTEM_ADMIN_CLUSTER_MEMBERS: 'SYSTEM_ADMIN_CLUSTER_MEMBERS',
  SYSTEM_ADMIN_CLEAR_CACHE_DB: 'SYSTEM_ADMIN_CLEAR_CACHE_DB',
  SYSTEM_ADMIN_LOG_TEST: 'SYSTEM_ADMIN_LOG_TEST',
  AUDIT_ACTIVITY_VIEW: 'AUDIT_ACTIVITY_VIEW',
  RESOURCE_LIBRARY: 'RESOURCE_LIBRARY',
  FIND_A_CONTRACTOR: 'FIND_A_CONTRACTOR',
  ORACLE_APPLICATION_SEARCH: 'ORACLE_APPLICATION_SEARCH',
  SEND_APPROVAL_CODE: 'SEND_APPROVAL_CODE',
  UPLOADED_DOCUMENTS_VIEW: 'UPLOADED_DOCUMENTS_VIEW',
  MANAGE_CONTRACTOR_STATES: 'MANAGE_CONTRACTOR_STATES',
  ORIGINATION_EXPERT_SEND_DATA: 'ORIGINATION_EXPERT_SEND_DATA',
  MANAGE_CONTRACTOR_ZIPCODES: 'MANAGE_CONTRACTOR_ZIPCODES',
  RESTRICT_AQUA_PROMOTIONS: 'RESTRICT_AQUA_PROMOTIONS',
  UPDATE_MERCHANT_NUMBER: 'UPDATE_MERCHANT_NUMBER',
  RESEND_APPLICATION_TO_DECISION_ENGINE: 'RESEND_APPLICATION_TO_DECISION_ENGINE',
};

export default FunctionalEntitlementsEnum;
