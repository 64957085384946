import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import {
  reactGAPageview,
  reactGAEvent,
  nextFinancingOption,
  nextFlowByNextFinancingOption,
  nextPageByNextFinancingOption,
  isStateAllowed,
} from '../../../helper';
import { resetState, mapAppToFtl, changePage } from '../../../redux/actions/index';
import selectedFinancierEnum from '../../../assets/js/SelectedFinancierEnum';
import { Link } from 'react-router-dom';
import CustomButton from '../../../components/UI/Button/CustomButton';
import ApplicationFlowEnum from '../../../assets/js/ApplicationFlowEnum';
import { MicrofFlowPageNumber } from '../../Microf/MicrofFlow';
import AirConditioningSystemEnum from '../../../assets/js/AirConditioningSystemEnum';
import { getIsOptimus } from '../../../config/settings';

const Declined = props => {
  const {
    user,
    email,
    dealerUserId,
    did,
    dispatchSubmitMindfire,
    dispatchUpdateSelectedFinancier,
    consumerToken,
    history,
    dispatchSetFlow,
    dispatchChangePageTitle,
    application,
    dispatchMapApplicationToMicrof,
    dispatchMapApplicationToFTL,
    dispatchResetState,
    dispatchChangePage,
    microfAssigned,
  } = props;

  useEffect(() => {
    dispatchChangePageTitle('Declined Application');
    reactGAPageview({
      path: '/ca_ebdeclined',
      trackerNames: [],
      title: 'ca_ebdeclined',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const isOptimus = getIsOptimus();

  let nextFO = nextFinancingOption(
    application ? application : { deler: null, airConditioningSystem: null },
    selectedFinancierEnum.POWER_PAY,
  );
  let nextFlow = nextFlowByNextFinancingOption(nextFO);
  let nextPage = nextPageByNextFinancingOption(nextFO);

  const handleClick = async () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: `Continue to ${nextFO.value}`,
      did,
      dealerUserId,
    });
    dispatchSubmitMindfire({
      email: email,
      lastPage: '11027',
      dealerUserId: dealerUserId,
      did: did,
    });

    await dispatchUpdateSelectedFinancier(nextFO.value, consumerToken, () => {
      if (nextFO === selectedFinancierEnum.FTL) {
        dispatchMapApplicationToFTL(application);
        dispatchSetFlow(ApplicationFlowEnum.FTL_FLOW.value);
        history.push(ApplicationFlowEnum.FTL_FLOW.path);
      } else {
        dispatchMapApplicationToMicrof(application);
        dispatchSetFlow(nextFlow);
        dispatchChangePage(MicrofFlowPageNumber.AccountEmail);
        history.push(nextPage);
      }
    });
  };

  const OutOfTradeArea = () => {
    return (
      <Container>
        <Row className="justify-content-center mt-2">
          <Col xs="auto" sm="8" className="text-center">
            <h5>
              <b>
                EnerBank was unable to approve your credit application at this time. You will
                receive a notification via email within 30 days.
              </b>
            </h5>
          </Col>
        </Row>
        <Row className="justify-content-center mt-5">
          <Col xs="10" md="3">
            <Link
              to={user?.dealerUser ? '/portal/dashboard' : ''}
              onClick={() => !user?.dealerUser && dispatchResetState({})}
              style={{ textDecoration: 'none' }}
              className="block"
            >
              <CustomButton
                color="secondary"
                size="lg"
                block
                type="button"
                title={user?.dealerUser ? 'Return to dashboard' : 'Exit'}
              />
            </Link>
          </Col>
        </Row>
      </Container>
    );
  };

  if (
    (nextFO === selectedFinancierEnum.MICROF &&
      !isStateAllowed(application?.propertyAddress?.state)(
        isOptimus && !microfAssigned && nextFO === selectedFinancierEnum.MICROF,
      )) ||
    application.airConditioningSystem !==
      AirConditioningSystemEnum.AIR_CONDITION_OR_HEATING_SYSTEM.id
  ) {
    return OutOfTradeArea();
  } else {
    return (
      <Container>
        <Row className="justify-content-center mt-2">
          <Col xs="auto" sm="8" className="text-center">
            <h6>
              EnerBank is not able to approve your request for credit. Their decision was based on
              the application and/or credit information received. You will also receive a written
              notice regarding the decision.
            </h6>
            <h6 className="mt-4">
              If you have any questions regarding the review of your application, please contact
              EnerBank’s Lending Department at 1-877-477-5900. For faster service, please provide
              the lending specialist with your application number.
            </h6>
          </Col>
        </Row>
        {application?.enerBankInformation?.refrenceNumber ? (
          <Row className="justify-content-center mt-5">
            <Col xs="auto" sm="8" className="text-center">
              <h6 id="appNo">
                Application Number: <span>{application?.enerBankInformation?.refrenceNumber}</span>
              </h6>
            </Col>
          </Row>
        ) : null}
        {nextFO ? (
          <>
            <Row className="justify-content-center mt-5">
              <Col xs="auto" sm="8" className="text-center">
                <h5>
                  <b>
                    But wait! You may still qualify for
                    {nextFO === selectedFinancierEnum.AQUA_FINANCE ? ' an' : ' a'} {nextFO.option}.
                    If you would like to apply with {nextFO.value}, just press Continue to{' '}
                    {nextFO.value} and you will be directed to the {nextFO.value} application
                    process.
                  </b>
                </h5>
              </Col>
            </Row>
            {nextFO === selectedFinancierEnum.MICROF && (
              <>
                <Row className="justify-content-center mt-5">
                  <Col xs="auto" className="text-center">
                    <h4>Microf Lease Option</h4>
                    <p>
                      <i>We have several lease purchase options for you</i>
                    </p>
                  </Col>
                </Row>
                <Row className="justify-content-center ">
                  <Col xs="auto">
                    <ul>
                      <li>A month-to-month lease with a guaranteed purchase option</li>
                      <li>36, 48, 60, and 72 months to ownership</li>
                      <li>Terminate without penalty at any time</li>
                    </ul>
                  </Col>
                </Row>
              </>
            )}
            <Row
              className={
                nextFO === selectedFinancierEnum.MICROF
                  ? 'justify-content-center'
                  : 'justify-content-center mt-5'
              }
            >
              <Col xs="auto">
                <CustomButton
                  color="primary"
                  clickHandler={handleClick}
                  title={`Continue to ${nextFO.value}`}
                />
              </Col>
            </Row>
          </>
        ) : (
          <Row className="justify-content-center mt-2">
            <Col xs="12" md="2">
              <Link
                to={user && user.dealerUser ? '/portal/dashboard' : ''}
                onClick={() => !(user && user.dealerUser) && dispatchResetState({})}
                style={{ textDecoration: 'none' }}
                className="block"
              >
                <CustomButton
                  color="secondary"
                  size="lg"
                  block
                  type="button"
                  title={user && user.dealerUser ? 'Back to dashboard' : 'Exit'}
                />
              </Link>
            </Col>
          </Row>
        )}
      </Container>
    );
  }
};

const mapStateToProps = state => ({
  consumerToken:
    state.softPull.applicationSubmitDTO && state.softPull.applicationSubmitDTO.consumerToken,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  user: state.auth.user,
  microfAssigned: state.auth.user?.user?.dealerStatsSummaryDTO?.microfAssigned,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchResetState: props => dispatch(resetState(props)),
  dispatchMapApplicationToFTL: app => dispatch(mapAppToFtl(app)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Declined);
