import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import YesNoComponent from '../../../components/UI/YesNoComponent/YesNoComponent';
import { reactGAEvent, reactGAPageview } from '../../../helper';
import { changeFtlInfoValue, changePage, submitMindfire } from '../../../redux/actions/index';
import { FTLFlowPageNumber } from './FTLFlow';

const HasCoApplicant = props => {
  const {
    isReview,
    dispatchChangePage,
    dispatchChangeFtlInfoValue,
    children,
    dispatchSubmitMindfire,
    email,
    did,
    dealerUserId,
    coApplicantAdded,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_ftlcoappoption',
      trackerNames: [],
      title: 'ca_ftlcoappoption',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const mindFireRequest = {
    email: email,
    lastPage: '12013',
    dealerUserId: dealerUserId,
    did: did,
  };

  return (
    <YesNoComponent
      backpage={isReview ? FTLFlowPageNumber.Review : FTLFlowPageNumber.IdentificationInformation}
      upperButtonText="Yes"
      lowerButtonText="No"
      upperBtnClickHandler={() => {
        reactGAEvent({
          category: 'Button',
          action: 'Click',
          label: 'Yes',
          did,
          dealerUserId,
        });
        dispatchChangePage(FTLFlowPageNumber.CoAppContactInformation);
        dispatchChangeFtlInfoValue('hasCoApp', true);
        dispatchSubmitMindfire(mindFireRequest);
      }}
      lowerBtnClickHandler={() => {
        reactGAEvent({
          category: 'Button',
          action: 'Click',
          label: 'No',
          did,
          dealerUserId,
        });
        dispatchChangePage(FTLFlowPageNumber.Review);
        dispatchChangeFtlInfoValue('isReview', null);
        dispatchChangeFtlInfoValue('hasCoApp', false);
        dispatchSubmitMindfire(mindFireRequest);
      }}
      lowerButtonDisabled={!!coApplicantAdded}
    >
      {children}
    </YesNoComponent>
  );
};

const mapStateToProps = state => ({
  isReview: state.ftlInformation.isReview,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  coApplicantAdded:
    state.softPull.applicationSubmitDTO !== null
      ? state.softPull.applicationSubmitDTO.applicantNonPrimary
      : null,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeFtlInfoValue: (key, value) => dispatch(changeFtlInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HasCoApplicant);
