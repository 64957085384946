const WaterHeaterTypeEnum = {
  TANKLESS_WATER_HEATER: {
    value: 'Tankless Water Heaters',
    name: 'Tankless Water Heaters',
    description: 'Tankless',
  },
  TANK_WATER_HEATER: {
    value: 'Tank Water Heaters',
    name: 'Tank Water Heaters',
    description: 'Tank',
  },
};

export const getWaterHeaterSystem = systemType => {
  for (let key in WaterHeaterTypeEnum) {
    if (key === systemType) {
      return WaterHeaterTypeEnum[key].value;
    }
  }
  return null;
};

export default WaterHeaterTypeEnum;
