import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import BackBtn from '../../components/UI/BackButton/BackButton';
import { reactGAPageview } from '../../helper';
import {
  changePage,
  submitMindfire,
  resetState,
  resetNewAppState,
} from '../../redux/actions/index';
import { getWFCreditConnectUrl } from '../../config/settings';
import Button from '../../components/UI/Button/CustomButton';
import { getDealerPortalURL } from '../../config/settings';
import { withRouter } from 'react-router-dom';
import { WellsFargoFlowPageNumber } from './WellsFargoFlow';

const ApplicationResult = props => {
  const {
    email,
    dispatchChangePage,
    dealerUserId,
    did,
    dispatchSubmitMindfire,
    user,
    history,
    dispatchResetNewAppState,
    dispatchResetState,
  } = props;

  const isUserLoggedIn = !did && user && user.dealerUser;
  const buttonTitle = isUserLoggedIn ? 'Return to Dashboard' : 'Return to Login Page';
  const returnUrl = isUserLoggedIn ? '/portal/dashboard' : getDealerPortalURL();

  useEffect(() => {
    reactGAPageview({
      path: '/wf_creditconnect',
      trackerNames: [],
      title: 'wf_creditconnect',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const handleClick = () => {
    dispatchSubmitMindfire({
      email: email,
      lastPage: '5014',
      dealerUserId: dealerUserId,
      did: did,
    });
    window.open(getWFCreditConnectUrl(), '_blank');
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <BackBtn
          changePage={dispatchChangePage}
          page={WellsFargoFlowPageNumber.ApplicationResult}
        />
        <Col xs="auto" sm="11">
          <Row className="justify-content-center mt-2">
            <Col xs="auto">
              <h5>
                <b>Contractor</b>
              </h5>
            </Col>
          </Row>
          <Row className="justify-content-center mt-2">
            <Col xs="auto">
              <h5 className="text-center">
                <b>Please complete the Wells Fargo paperless invoice at</b>
              </h5>
              <h5 className="text-center">
                <a href="##" onClick={handleClick}>
                  <b>www.wellsfargo.com/creditconnect</b>
                </a>
              </h5>
            </Col>
          </Row>
          <Row className="justify-content-center mt-2">
            <Col xs="auto">
              <Button
                type="button"
                title={buttonTitle}
                clickHandler={() => {
                  if (!isUserLoggedIn) {
                    dispatchResetState(props);
                    window.location.href = returnUrl;
                  } else {
                    dispatchResetNewAppState();
                    history.push(returnUrl);
                  }
                  dispatchSubmitMindfire({
                    email: email,
                    lastPage: '5014',
                    dealerUserId: dealerUserId,
                    did: did,
                  });
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  email: state.wellsFargoInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchResetState: props => dispatch(resetState(props)),
  dispatchResetNewAppState: () => dispatch(resetNewAppState()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ApplicationResult),
);
