import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import Button from '../../../../components/UI/Button/CustomButton';
import {
  addNotification,
  changeGreenSkyInfoValue,
  changePage,
  getGreenSkyOffersData,
  recalculateGreenSkyOffer,
  submitMindfire,
  greenSkyAcceptOffer,
} from '../../../../redux/actions';
import * as Yup from 'yup';
import { reactGAPageview, reactGAEvent, currencyFormat } from '../../../../helper';
import NumberFormat from 'react-number-format';
import { handleApiError } from '../../../../redux/actions/api';
import { getGreenSkyTechnicalSupportNumber } from '../../../../config/settings';
import { Field, Form, withFormik } from 'formik';
import CurrencyFormat from '../../../../components/UI/Form/NumberFormat/CurrencyFormat';
import { GreenSkyFlowPageNumber } from './GreenSkyFlow';
import Disclosure from '../Components/Disclosure';

const Approved = props => {
  const {
    applicationId,
    did,
    dealerUserId,
    email,
    dispatchSubmitMindfire,
    dispatchGetGreenSkyOffersData,
    dispatchRecalculateGreenSkyOffer,
    greenSkyOffersData,
    dispatchGreenSkyAcceptOffer,
    dispatchNotification,
    dispatchChangePage,
    nextState,
    last4SSN,
    ctaResponse,
    activation,
  } = props;

  let data;

  const showReturnToDashboard = () => {
    return (
      <Row className="justify-content-center mt-4">
        <Col xs="12" md="auto">
          <Button type="submit" title="Return to Dashboard" clickHandler={handleClick} />
        </Col>
      </Row>
    );
  };

  useEffect(() => {
    if (greenSkyOffersData === null) {
      // if (nextState !== 'Loan Agreement') {
      //   dispatchGetGreenSkyOffersData(applicationId);
      // }
      dispatchGetGreenSkyOffersData(applicationId);
    }

    reactGAPageview({
      path: '/ca_gsapproved',
      trackerNames: [],
      title: 'ca_gsapproved',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const handleAcceptOffer = () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Accept Offer',
      did,
      dealerUserId,
    });
    dispatchGreenSkyAcceptOffer(
      data.id,
      greenSkyOffersData.greenskyInformation.greenskyApplicationId,
      data.creditProduct,
      data.loanAmount,
      last4SSN,
    );
  };

  const handleClick = () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Return to Dashboard',
      did,
      dealerUserId,
    });
    dispatchSubmitMindfire(mindFireRequest);
    window.location.href = process.env.REACT_APP_DEALER_PORTAL_URL + '/portal/dashboard';
  };

  const mindFireRequest = { email: email, lastPage: '9025', dealerUserId: dealerUserId, did: did };

  if (nextState === 'Loan Agreement') {
    if (activation === '1.0') {
      dispatchChangePage(GreenSkyFlowPageNumber.LoanDocument);
    }
  }

  if (greenSkyOffersData) {
    if (greenSkyOffersData.error) {
      dispatchNotification({
        message: handleApiError(greenSkyOffersData.error.message),
        messageType: 'Error',
      });
      return null;
    } else {
      // if (
      //   !(
      //     greenSkyOffersData.greenskyInformation.ctaRequired === false &&
      //     greenSkyOffersData.greenskyInformation.esignatureComplete === 'yes'
      //   ) ||
      //   !greenSkyOffersData.greenskyInformation.ctaRequired === true
      // ) {
      // }

      if (
        greenSkyOffersData.greenskyInformation.accountCreated &&
        greenSkyOffersData.greenskyInformation.accountCreated === true &&
        !(
          greenSkyOffersData.greenskyInformation.ctaRequired &&
          greenSkyOffersData.greenskyInformation.ctaRequired === true
        ) &&
        !(
          greenSkyOffersData.greenskyInformation.ctaRequired === false &&
          greenSkyOffersData.greenskyInformation.esignatureComplete === 'yes'
        )
      ) {
        if (
          greenSkyOffersData.greenskyInformation.activationVersion &&
          (greenSkyOffersData.greenskyInformation.activationVersion === '2.0' ||
            greenSkyOffersData.greenskyInformation.activationVersion === '2.1')
        ) {
          dispatchChangePage(GreenSkyFlowPageNumber.ActivationPage);
        } else if (
          ctaResponse?.activationVersion !== '2.0' ||
          ctaResponse?.activationVersion !== '2.1'
        ) {
          dispatchChangePage(GreenSkyFlowPageNumber.LoanDocument);
        }
      }

      if (greenSkyOffersData.greenskyOffers) {
        data = greenSkyOffersData.greenskyOffers[greenSkyOffersData.greenskyOffers.length - 1];
      } else {
        return (
          <Container>
            <Row className="justify-content-center mt-2">
              <div className="app-panel">No offers found.</div>
            </Row>
          </Container>
        );
      }
    }
  }

  const recalculateOfferForm = () => (
    <Form className="col-10 offset-1 recalculate-form">
      <Row className="align-items-end">
        <Col className="">
          <Field
            component={CurrencyFormat}
            label="Credit Limit"
            name="creditLimit"
            id="creditLimit"
          />
        </Col>
        <Col>
          <Button type="submit" outline title="Recalculate Offer" />
        </Col>
      </Row>
    </Form>
  );

  const RecalculateOfferFormFormik = withFormik({
    validationSchema: Yup.object({
      creditLimit: Yup.number()
        .moreThan(1499, `Amount should be at least $1500.`)
        .lessThan(data ? data.originalCreditLimit + 1 : 10001, 'Amount too high.')
        .required('Please enter an amount.'),
    }),
    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });

      const applicationId = greenSkyOffersData.greenskyInformation.greenskyApplicationId;
      const loanAmount = values.creditLimit;
      const planNumber = data.planNumber;
      const product = data.creditProduct;

      dispatchRecalculateGreenSkyOffer(applicationId, loanAmount, planNumber, product);
    },
  })(recalculateOfferForm);

  return data ? (
    <Container>
      <Row>
        <Col sm="6">
          <Row className="p-10">
            <p className="fs-20">
              <b className="primary">Congratulations</b>, your application has been approved for a{' '}
              <b>Credit Limit of {currencyFormat(data.loanAmount)}.</b> If you wish to increase your
              Credit Limit, please contact your Merchant directly or contact GreenSky at{' '}
              {getGreenSkyTechnicalSupportNumber()}.
            </p>
            <p className="fs-16">
              The following information is provided for your convenience. Please see your loan
              agreement, including the Truth in Lending Disclosures, for details about your loan
              terms.
            </p>
          </Row>
          <Row className="p-10 recalculate-box">
            <Col>
              <p className="mt-15">Would you like to recalculate your offer?</p>
              <p>
                Please update the credit limit below with a new amount and select “Recalculate
                Offer” to generate a modified offer.
              </p>
            </Col>
            <RecalculateOfferFormFormik />
          </Row>
        </Col>
        <Col sm="6">
          <Row className="review">
            <Col>
              <Row className="row-outline-bottom">
                <Col>
                  <b className="b-no-border">Application ID:</b>
                </Col>
                <Col>{data.greenskyOfferId}</Col>
              </Row>
              <Row className="row-outline-bottom">
                <Col>
                  <b className="b-no-border">Applicant Name:</b>
                </Col>
                <Col>{data.applicantFullName}</Col>
              </Row>
              <Row>
                <Col>
                  <b>Credit Limit</b>
                  <NumberFormat
                    value={data.loanAmount}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={'$'}
                    displayType="text"
                  />
                </Col>
                <Col>
                  <b>Introductory Limit</b>
                  {data.promotionPeriod + ' Months'}
                </Col>
              </Row>
              <Row>
                <Col>
                  <b>Amortization Term</b>
                  {data.term + ' Months'}
                </Col>
                <Col>
                  <b>Annual Percentage Rate (APR)</b>
                  {data.apr + '%'}
                </Col>
              </Row>
              <Row>
                <Col>
                  <b>Amortization Monthly Payment</b>
                  <NumberFormat
                    value={data.monthlyPayment}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={'$'}
                    displayType="text"
                  />
                </Col>
                <Col />
              </Row>
              <Row>
                <Col />
                <Col />
              </Row>
            </Col>
          </Row>
          <Row className="p-10">
            <Col>
              <p> {data.paymentTermOfferMessage}</p>
              <p> {data.promotionDetails}</p>
            </Col>
          </Row>
          <Row className="justify-content-center mt-4">
            <Col xs="12" md="auto">
              <Button type="submit" title="Accept Offer" clickHandler={handleAcceptOffer} />
              <Disclosure />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  ) : null;
};

const mapStateToProps = state => ({
  email: state.preQualificationInformation.email,
  last4SSN: state.preQualificationInformation.ssnLastDigits,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  applicationId: state.softPull.applicationSubmitDTO.applicationId,
  ctaResponse: state.softPull.ctaResponse,
  greenSkyOffersData: state.softPull.greenSkyOffersData,
  nextState: state.softPull.applicationSubmitDTO.greenskyInformation.nextState,
  activation: state.softPull.applicationSubmitDTO?.greenskyInformation?.activationVersion,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeGreenSkyInfoValue: (key, value) => dispatch(changeGreenSkyInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchGetGreenSkyOffersData: applicationId => dispatch(getGreenSkyOffersData(applicationId)),
  dispatchRecalculateGreenSkyOffer: (applicationId, loanAmount, planNumber, product) =>
    dispatch(recalculateGreenSkyOffer(applicationId, loanAmount, planNumber, product)),
  dispatchGreenSkyAcceptOffer: (offerId, applicationId, product, authorizedLoanAmount, last4Ssn) =>
    dispatch(greenSkyAcceptOffer(offerId, applicationId, product, authorizedLoanAmount, last4Ssn)),
  dispatchNotification: (messageType, message) => dispatch(addNotification(messageType, message)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Approved);
