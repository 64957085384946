import React from 'react';
import { Field } from 'formik';
import InputFeedback from '../InputFeedback/InputFeedback';

const SelectListField = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched, setFieldValue },
  id,
  label,
  className,
  labelClassName,
  selectFirstName,
  selectValues,
  disabled,
  ...props
}) => (
  <>
    <Field component="select" id={id} name={name} className="form-control" disabled={disabled}>
      <option value="">{selectFirstName}</option>
      {selectValues
        ? Object.keys(selectValues).map(key => (
            <option key={key} value={selectValues[key]}>
              {selectValues[key]}
            </option>
          ))
        : null}
    </Field>
    {touched[name] && <InputFeedback error={errors[name]} />}
  </>
);

export default SelectListField;
