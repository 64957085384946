import React from 'react';

const SearchApplicationTable = props => {
  const { searchedApplications, history } = props;

  return (
    <table className="table table-hover table-striped">
      <thead>
        <tr>
          <th>Name</th>
          <th>Apply Date</th>
          <th>Assignment Status</th>
          <th>Contractor</th>
        </tr>
      </thead>
      <tbody>
        {searchedApplications.applications.map((application, index) => (
          <tr
            className="cursor-pointer"
            key={index}
            onClick={() => {
              history.push('/portal/search-application/assign-application?id=' + application.id);
            }}
          >
            <td>{application.consumerName}</td>
            <td>{application.applyDate}</td>
            <td>{application.assignmentStatus}</td>
            <td>{application.dealer}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SearchApplicationTable;
