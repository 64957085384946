const incomeSource = {
  salaryWages: {
    value: 'salary/wages',
    name: 'Salary/Wages',
    aquaValue: 'Employed',
    powerPayValue: 'Employed',
  },
  retirement: {
    value: 'RetirementSSI',
    name: 'Retirement',
    aquaValue: 'RetirementSSI',
    powerPayValue: 'Retired Other',
  },
  disability: { value: 'disability', name: 'Disability', aquaValue: 'Other', powerPayValue: null },
  investments: {
    value: 'Investments',
    name: 'Investments',
    aquaValue: 'Investments',
    powerPayValue: null,
  },
  rental: { value: 'Rental', name: 'Rental', aquaValue: 'Rental' },
  other: { value: 'Other', name: 'Other', aquaValue: 'Other', powerPayValue: null },
};

export const incomeSourceOrig = {
  salaryWages: { value: 'salary/wages', name: 'Salary/Wages', aquaValue: 'Employed' },
  retirement: { value: 'retirement', name: 'Retirement', aquaValue: 'Retired' },
  disability: { value: 'disability', name: 'Disability', aquaValue: 'Unemployed' },
  investments: { value: 'investments', name: 'Investments', aquaValue: 'Unemployed' },
  rental: { value: 'rental', name: 'Rental', aquaValue: 'Unemployed' },
  other: { value: 'other', name: 'Other', aquaValue: 'Unemployed' },
};

export default incomeSource;
