import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import UploadSection from './FTLDropzone/UploadSection';

export const ApprovedWithInfo = props => {
  const { application, history, loanDoc, ftlUploadResponse, data } = props;

  const isNewVersion = data.ftlInformation.newVersion === true;
  let isDocumentsApproved = true;
  if (data && data.documents && data.documents.length > 0) {
    data.documents.map(document => {
      if (document.status !== 'approved') {
        isDocumentsApproved = false;
      }
    });
  }

  const back = () => {
    history.push('/portal/applications-under-review');
  };

  return (
    <div className="ftl-flow-section">
      {!data.ftlInformation.generatedPaperworkSuccess && (
        <Row>
          <Col sm="4">
            <div className="inner-content">
              <UploadSection
                application={application}
                uploadResponse={ftlUploadResponse}
                ftlData={data}
              />
            </div>
          </Col>
          <Col sm="8">
            <div className="inner-content" />
          </Col>
        </Row>
      )}
      <Row className="justify-content-end">
        <Col sm="12" xs="12" md="auto">
          <Button
            type="submit"
            color="secondary"
            className="rounded-pill w-100 mt-1"
            outline
            onClick={() => {
              back();
            }}
          >
            Back
          </Button>
        </Col>
        {!isNewVersion &&
          !data.ftlInformation.generatedPaperworkSuccess &&
          ((ftlUploadResponse &&
            ftlUploadResponse?.approvedWith?.length > 0 &&
            ftlUploadResponse.approvedWith[0].decision === 'approved') ||
            (data && data.documents.length > 0 && isDocumentsApproved)) && (
            <Col sm="12" xs="12" md="auto">
              <Button
                type="submit"
                color="primary"
                className="rounded-pill w-100 mt-1"
                outline
                onClick={() => {
                  loanDoc(true);
                }}
              >
                Create Loan Documents
              </Button>
            </Col>
          )}
      </Row>
    </div>
  );
};

const mapStateToProps = state => ({
  ftlUploadResponse: state.softPull.ftlUploadResponse,
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApprovedWithInfo);
