import React from 'react';
import { connect } from 'react-redux';
import { changeSoftPullValue } from '../../../../redux/actions';
import { Button, Col, Row } from 'reactstrap';
import { Field, Form, withFormik } from 'formik';
import * as Yup from 'yup';
import FtlRadioButtons from '../../Form/Radio/FtlRadioButtons';
import FtlCheckbox from '../../Form/Checkbox/FtlCheckbox';

const Products = props => {
  const {
    products,
    ftlBodyRequest,
    dispatchChangeSoftPullValue,
    backButtonForPrograms,
    setSeePreview,
    setSeeProducts,
  } = props;

  let myOptions = [];

  myOptions = products.map(opt => ({
    key: opt.id,
    id: opt.id,
    value: opt.id,
    description: null,
    name: opt.label,
    checked: opt.id,
    feeAgreement: opt.feeAgreement,
    contractorFee: opt.contractorFee,
    approveMoreFee: opt.approveMoreFee,
    monthlyPayment: opt.monthlyPayment,
  }));

  const clickHandler = () => {};

  const back = () => {
    backButtonForPrograms();
  };

  const FtlProductsForm = ({ values }) => {
    const consent = values.ftlProducts
      ? myOptions.find(option => option.id === values.ftlProducts).feeAgreement
      : '';

    return (
      <Form noValidate>
        <Field
          optionsComponent={false}
          component={FtlRadioButtons}
          name="ftlProducts"
          options={myOptions}
          clickHandler={clickHandler}
        />
        <input type="hidden" name="isConsent" />
        {consent && (
          <>
            <Field
              formFieldClassName="mt-10"
              component={FtlCheckbox}
              name="consent"
              id="consent"
              label={consent}
              labelClassName="bold mb-0 red"
            />
          </>
        )}
        <Row className="justify-content-center mt-4">
          <Col xs="12" md="auto">
            <Button type="submit">Next</Button>
          </Col>
        </Row>
      </Form>
    );
  };

  const FtlProductsFormFormik = withFormik({
    mapPropsToValues: () => ({
      ftlProducts: '',
      isConsent: false,
      consent: false,
    }),

    validationSchema: Yup.object({
      ftlProducts: Yup.string().required('Please select a product.'),
      consent: Yup.boolean().when('isConsent', {
        is: true,
        then: Yup.boolean().oneOf([true], 'Please check to continue.'),
        otherwise: Yup.boolean(),
      }),
    }),

    handleSubmit: async values => {
      let data = ftlBodyRequest;
      data = {
        ...data,
        productID: values.ftlProducts,
      };
      dispatchChangeSoftPullValue('ftlBodyRequest', data);
      setSeePreview(true);
      setSeeProducts(false);
    },
  })(FtlProductsForm);

  return (
    <>
      <div className="ftl-flow-section">
        <Row className="justify-content-center mb-3 text-align-center">
          <Col xs="auto" sm="8">
            <p>
              <b>Select a financing program for your homeowner.</b>
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <div className="inner-content ftl-options-table">
            <FtlProductsFormFormik />
          </div>
        </Row>
      </div>

      <Row className="justify-content-end">
        <Col sm="12" xs="12" md="auto">
          <Button
            type="button"
            color="secondary"
            className="rounded-pill w-100 mt-1"
            outline
            onClick={() => {
              back();
            }}
          >
            Back
          </Button>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = state => ({
  ftlData: state.softPull.ftlData,
  ftlDistributors: state.softPull.ftlDistributors,
  auth: state.auth,
  ftlBodyRequest: state.softPull.ftlBodyRequest,
  ftlGeneratePaperworkResponse: state.softPull.ftlGeneratePaperworkResponse,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangeSoftPullValue: (key, value) => dispatch(changeSoftPullValue(key, value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Products);
