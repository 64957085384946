const messageRecipientEnum = {
  SALESPERSON: {
    value: 'SP',
    name: 'Salesperson',
  },
  DRR: {
    value: 'DR',
    name: 'Relationship Manager',
  },
};

export default messageRecipientEnum;
