import PropTypes from 'prop-types';
import React from 'react';
import { Field, Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import CustomInput from '../../../components/UI/Form/Input/CustomInput';
import Button from '../../../components/UI/Button/CustomButton';
import CustomPhoneNumber from '../../../components/UI/Form/PhoneNumber/PhoneNumber';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import {
  changePage,
  changeGSContractorEnrollmentInfoValue,
  submitMindfire,
  endLoading,
  applyToBeDealer,
} from '../../../redux/actions/index';
import { withRouter } from 'react-router-dom';
import { GreenskyContractorEnrollmentFlowPageNumber } from './_Flow';
import {
  MANAGER_EMAIL_TOOLTIP_TEXT,
  MANAGER_FIRST_NAME_TOOLTIP_TEXT,
  MANAGER_LAST_NAME_TOOLTIP_TEXT,
  MANAGER_PHONE_TOOLTIP_TEXT,
} from '../../../constants/tooltipTexts';
import { isNoreplyEmail } from '../../../helper';

const BusinessManager = props => {
  const {
    gsi,
    dispatchChangeValue,
    dispatchChangePage,
    children,
    dispatchApplyToBeDealer,
    history,
  } = props;

  const BusinessManagerForm = ({ values, errors, touched }) => (
    <Form autoComplete="off">
      <h3>General</h3>
      <Field
        component={CustomInput}
        label="Last Name *"
        name="lastName"
        id="lastName"
        tooltipText={MANAGER_LAST_NAME_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={CustomInput}
        label="First Name *"
        name="firstName"
        id="firstName"
        tooltipText={MANAGER_FIRST_NAME_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={CustomPhoneNumber}
        label="Phone *"
        name="phone"
        id="phone"
        tooltipText={MANAGER_PHONE_TOOLTIP_TEXT}
      />
      <Field
        component={CustomInput}
        label="Email *"
        name="email"
        id="email"
        tooltipText={MANAGER_EMAIL_TOOLTIP_TEXT}
        type="email"
      />
      <Button type="submit" title="Next" />
    </Form>
  );

  const BusinessManagerFormFormik = withFormik({
    mapPropsToValues: () => ({
      lastName: gsi.lastName || '',
      firstName: gsi.firstName || '',
      phone: gsi.phone || '',
      email: gsi.email || '',
    }),

    validationSchema: Yup.object({
      lastName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .required('Please enter Last Name.'),
      firstName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .required('Please enter First Name.'),
      phone: Yup.string()
        .test('required', 'Please enter Phone.', val => val !== '+' && val)
        .test(
          'len',
          'Invalid Phone number.',
          val => val && val.match(/\d/g).join('').length === 10,
        ),
      email: Yup.string()
        .email('Please enter a valid Email.')
        .required('Please enter an Email.')
        .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),
    }),

    handleSubmit: values => {
      dispatchChangeValue('lastName', values.lastName.trim());
      dispatchChangeValue('firstName', values.firstName.trim());
      dispatchChangeValue('phone', values.phone);
      dispatchChangeValue('email', values.email);
      dispatchApplyToBeDealer(2, history);
    },
  })(BusinessManagerForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={GreenskyContractorEnrollmentFlowPageNumber.DealershipInformation}
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <BusinessManagerFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  gsi: state.greenskyInformation.contractorEnrollment,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) => dispatch(changeGSContractorEnrollmentInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchEndLoading: () => dispatch(endLoading()),
  dispatchApplyToBeDealer: (page, history) => dispatch(applyToBeDealer(true, page, history)),
});

BusinessManager.propTypes = {
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
  dispatchSubmitSoftPullAquaApplication: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(BusinessManager),
);
