import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ApplicationAproved from './ApplicationAproved';

const ApplicationResult = () => {
  return <ApplicationAproved />;
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ApplicationResult),
);
