import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import AddedByAPIEnum from '../../assets/js/AddedByAPIEnum';
import Button from '../../components/UI/Button/CustomButton';
import { getSFCPortalUrl } from '../../config/settings';

const FundingNotAllowed = props => {
  const isUserLoggedIn = props.auth.user.user;
  return !isUserLoggedIn ? (
    <Container>
      <Row className="justify-content-center mt-2">
        <Col xs="12" md="auto">
          <h5>
            The applicant(s) still need to complete one or more of the following:
            <ul>
              <li>Submit Proof of Income</li>
              <li>Sign the Rental Purchase Agreement</li>
              <li>Make the first rental payment</li>
            </ul>
          </h5>
        </Col>
      </Row>
      {props.addedBy === AddedByAPIEnum.SERVICEFINANCE.name && (
        <Row className="justify-content-center mt-4">
          <Col xs="12" md="auto">
            <Button
              type="button"
              title="Return to SFC Portal"
              clickHandler={() => window.open(getSFCPortalUrl(), '_blank')}
            />
          </Col>
        </Row>
      )}
    </Container>
  ) : (
    <Container className="no-padding no-margin">
      <Row className="no-padding">
        <Col className="no-padding">
          The customer must sign their rental purchase agreement and make their first payment before
          Make, Model and Serial number information can be entered.
        </Col>
      </Row>
      <Row className="mt-4 justify-content-end">
        <Col xs="12" md="auto">
          <Button
            type="submit"
            color="primary"
            className="rounded-pill w-100 mt-1"
            title="Return"
            clickHandler={() => props.history.push('/portal/open-applications')}
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  addedBy: state.softPull.applicationSubmitDTO?.addedBy,
});

export default withRouter(connect(mapStateToProps)(FundingNotAllowed));
