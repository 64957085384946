import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import { greenskyApiCredentials } from '../../../redux/actions';
import InputField from '../../../components/UI/Form/Input/InputField';

const GreenskyApiCredentials = props => {
  const { auth, history, dispatchGreenskyApiCredentials } = props;

  useEffect(() => {
    if (!auth.user || !auth.user.dealerUser) {
      history.push('/login');
    }
    // eslint-disable-next-line
  }, []);

  const cancel = event => {
    history.push('/portal/dashboard');
    event.preventDefault();
  };

  const GreenskyApiCredentialsForm = () => (
    <Form>
      <Row className="mt-4">
        <Col sm="4">
          <Label for="source">Source *</Label>
        </Col>
        <Col sm="4">
          <Field component={InputField} name="source" id="source" type="text" disabled={true} />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm="4">
          <Label for="merchantID">Merchant ID *</Label>
        </Col>
        <Col sm="4">
          <Field component={InputField} name="merchantID" id="merchantID" type="text" />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm="4">
          <Label for="username">Username / Email *</Label>
        </Col>
        <Col sm="4">
          <Field component={InputField} name="username" id="username" type="text" />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm="4">
          <Label for="password">Password *</Label>
        </Col>
        <Col sm="4">
          <Field component={InputField} name="password" id="password" type="password" />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col className="text-right">
          <Button
            type="submit"
            color="secondary"
            className="rounded-pill"
            outline
            onClick={envent => {
              cancel(envent);
            }}
          >
            Cancel
          </Button>

          <Button type="submit" color="primary" className="rounded-pill">
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );

  const GreenskyApiCredentialsFormFormik = withFormik({
    mapPropsToValues: () => ({
      source: 'Microf',
      merchantID: '',
      username: '',
      password: '',
    }),

    validationSchema: Yup.object({
      merchantID: Yup.string().required('Please enter the Merchant ID.'),
      username: Yup.string().required('Please enter the Username / Email.'),
      password: Yup.string().required('Please enter the Password.'),
    }),

    handleSubmit: values => {
      const applicationViewDTO = {
        id: 0,
        rtoNumber: null,
        applicantId: 0,
        coapplicantId: 0,
        nameFirst: null,
        nameLast: null,
        street: null,
        city: null,
        state: null,
        zipcode: null,
        email: null,
        totalPrice: null,
        totalFinancing: null,
        status: null,
        statusItems: null,
        salesperson: null,
        salespersonEmail: null,
        relationshipManager: null,
        relationshipManagerEmail: null,
        dealer: null,
        submitted: null,
        phone: null,
        consumerApplication: false,
        noCreditCheckApp: false,
        applicationStatus: null,
        downPaymentAmount: null,
        equipmentCost: null,
        singleApp: false,
        coapplicantNameFirst: null,
        coapplicantNameLast: null,
        consumerInstallationCode: null,
        selectedBrands: null,
        statusMessage: null,
        selectedFinancier: null,
        fundedLoanAmount: null,
        showCompleteBtnAfter: null,
        addedBy: null,
        applicantTilaSigned: null,
        coapplicantTilaSigned: null,
        installDate: null,
        contractorInitials: null,
        isAssetAdded: null,
        contractorDiscount: null,
        fundedApplication: null,
        fundingDate: null,
        firstPayment: null,
        source: values.source,
        mid: values.merchantID,
        username: values.username,
        password: values.password,
        dealerEntity: null,
        samlResponse: null,
        aquaApplicationNumber: null,
        consumerUrl: null,
        orbitUrl: null,
        aquaPromotions: [],
        shouldSelectAquaProduct: false,
        token: null,
        agreementSigned: false,
      };
      dispatchGreenskyApiCredentials(applicationViewDTO, history, '/portal/dashboard');
      return false;
    },
  })(GreenskyApiCredentialsForm);

  return (
    <Col>
      <Row className="mt-5">
        <div className="panel">
          <div className="panel-body larger">
            <h2 id="panelTitle">Greensky Api Credentials</h2>
            <Row>
              <Col className="right-bordered">
                <GreenskyApiCredentialsFormFormik />
              </Col>
            </Row>
          </div>
        </div>
      </Row>
    </Col>
  );
};

const mapStateToProps = state => ({ auth: state.auth });

const mapDispatchToProps = dispatch => ({
  dispatchGreenskyApiCredentials: (application, history, url) =>
    dispatch(greenskyApiCredentials(application, history, url)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(GreenskyApiCredentials),
);
