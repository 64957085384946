import React from 'react';
import { Col } from 'reactstrap';
import NumberFormat from 'react-number-format';
import { getAppName, getIsMicrof, getIsOptimus } from '../../../config/settings';

const appName = getAppName();
const isMicrof = getIsMicrof();
const isOptimus = getIsOptimus();

export const ContractorStatusTable = ({ dealerStatsSummary }) => (
  <Col>
    <div className="overflow-auto">
      <table className="table mb-0">
        <thead className="thead-light">
          <tr>
            <th>Contractor Status</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {isMicrof ? (
            <>
              <tr>
                <td>Contractor Contact</td>
                <td>
                  {`P: ${
                    dealerStatsSummary ? dealerStatsSummary.contractorContactPhone : 'N/A'
                  }  E: ${dealerStatsSummary ? dealerStatsSummary.contractorContactEmail : 'N/A'}`}
                </td>
              </tr>
              <tr>
                <td>Consumer Contact</td>
                <td>
                  {`P: ${
                    dealerStatsSummary ? dealerStatsSummary.consumerContactPhone : 'N/A'
                  }  E: ${dealerStatsSummary ? dealerStatsSummary.consumerContactEmail : 'N/A'}`}
                </td>
              </tr>
            </>
          ) : (
            <>
              <tr>
                <td>Total Funding</td>
                <td>
                  $
                  <NumberFormat
                    value={
                      dealerStatsSummary && dealerStatsSummary.dealerStats
                        ? dealerStatsSummary.dealerStats.totalFunding
                        : 0
                    }
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    displayType="text"
                  />
                </td>
              </tr>
              <tr>
                <td>{appName} Distributor Representative</td>
                <td>{dealerStatsSummary ? dealerStatsSummary.drrName : 'N/A'}</td>
              </tr>
              {!isOptimus && (
                <>
                  <tr>
                    <td>Recuperation / Re-Entry Technician</td>
                    <td>
                      {dealerStatsSummary
                        ? dealerStatsSummary.interestedInRecup
                          ? 'Interested'
                          : 'Not Interested'
                        : 'N/A'}
                    </td>
                  </tr>
                  <tr>
                    <td>Total Contract Price (TCP) Average</td>
                    <td>
                      $
                      <NumberFormat
                        value={
                          dealerStatsSummary && dealerStatsSummary.dealerStats
                            ? dealerStatsSummary.dealerStats.tcpAverage
                            : 0
                        }
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        displayType="text"
                      />
                    </td>
                  </tr>
                </>
              )}

              <tr>
                <td>Sales Contact</td>
                <td>
                  {dealerStatsSummary ? dealerStatsSummary.drrName : 'N/A'},{' '}
                  {dealerStatsSummary ? dealerStatsSummary.drrEmail : 'N/A'},{' '}
                  {dealerStatsSummary ? dealerStatsSummary.drrPhone : 'N/A'}
                </td>
              </tr>
              <tr>
                <td>{appName} Dealer ID</td>
                <td>{dealerStatsSummary ? dealerStatsSummary.dealerId : 'N/A'}</td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </div>
  </Col>
);
