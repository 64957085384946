import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import usStates from '../../../assets/js/USStateEnum';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import CustomInput from '../../../components/UI/Form/Input/CustomInput';
import SsnFormat from '../../../components/UI/Form/NumberFormat/SsnFormat';
import Select from '../../../components/UI/Form/Select/Select';
import {
  isSSNanITIN,
  isStateAllowed,
  reactGAEvent,
  reactGAPageview,
  validDateFormat,
} from '../../../helper';
import {
  changePage,
  setFlow,
  submitMindfire,
  changePowerPayInfoValue,
} from '../../../redux/actions/index';
import { microfFlow, preQualificationFlow } from '../../../redux/initialState';
import CustomDatePicker from '../../../components/UI/Form/DatePicker/DatePicker';
import { AgeFromDateString } from 'age-calculator';
import { PowerPayFlowPageNumber } from './PowerPayFlow';
import { MicrofFlowPageNumber } from '../../Microf/MicrofFlow';
import { AquaPreQualificationFlowPageNumber } from '../../PreQualification/preQualificationFlow';
import AirConditioningSystemEnum from '../../../assets/js/AirConditioningSystemEnum';
import {
  COAPPDOB_TOOLTIP_TEXT,
  COAPPFULLSSN_TOOLTIP_TEXT,
  COAPP_DRIVER_LICENSE_NUMBER_TOOLTIP_TEXT,
  COAPP_DRIVER_LICENSE_STATE_TOOLTIP_TEXT,
  COAPP_MILITARY_ACTIVE_TOOLTIP_TEXT,
  COAPP_US_CITIZEN_TOOLTIP_TEXT,
} from '../../../constants/tooltipTexts';

const CoAppIdentificationInfo = props => {
  const {
    email,
    dispatchChangePage,
    dispatchChangePowerPayInfoValue,
    powerPayInformation,
    children,
    isReview,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    dispatchSetFlow,
    history,
    pqi,
  } = props;

  const yesNo = {
    Yes: { value: true, name: 'Yes' },
    No: { value: false, name: 'No' },
  };

  useEffect(() => {
    reactGAPageview({
      path: '/ca_ppconsumerid',
      trackerNames: [],
      title: 'ca_ppconsumerid',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const CoAppIdentificationInfoForm = () => (
    <Form noValidate autoComplete="off">
      <Field
        maxLength={30}
        component={CustomInput}
        label="Co-Applicant's Driver License Number"
        name="coAppDriversLicenseNumber"
        id="coAppDriversLicenseNumber"
        type="text"
        tooltipText={COAPP_DRIVER_LICENSE_NUMBER_TOOLTIP_TEXT}
      />
      <Field
        component={Select}
        label="Co-Applicant's Driver License State"
        name="coAppDriversLicenseState"
        id="coAppDriversLicenseState"
        type="select"
        selectValues={usStates}
        selectFirstName="Select One"
        tooltipText={COAPP_DRIVER_LICENSE_STATE_TOOLTIP_TEXT}
      />
      <Field
        component={Select}
        label="Is Co-Applicant active military?"
        name="coAppMilitaryActive"
        id="coAppMilitaryActive"
        type="select"
        selectValues={yesNo}
        selectFirstName="Select One"
        tooltipText={COAPP_MILITARY_ACTIVE_TOOLTIP_TEXT}
      />
      <Field
        component={Select}
        label="Is Co-Applicant a US citizen?"
        name="coAppUsCitizen"
        id="coAppUsCitizen"
        type="select"
        selectValues={yesNo}
        selectFirstName="Select One"
        tooltipText={COAPP_US_CITIZEN_TOOLTIP_TEXT}
      />
      <Field
        component={CustomDatePicker}
        name="coAppBirthday"
        id="coAppBirthday"
        label="Co-Applicant's Date of Birth"
        tooltipText={COAPPDOB_TOOLTIP_TEXT}
      />
      <Field
        component={SsnFormat}
        label="Co-Applicant's full Social Security Number"
        name="coAppFullSsn"
        type="password"
        icon="lock"
        tooltipText={COAPPFULLSSN_TOOLTIP_TEXT}
      />
      <p className="info">Click on the padlock to view the hidden text</p>
      <Button type="submit" title="Next" />
    </Form>
  );

  const CoAppIdentificationInfoFormFormik = withFormik({
    mapPropsToValues: () => ({
      coAppDriversLicenseState: powerPayInformation.coAppDriversLicenseState || '',
      coAppDriversLicenseNumber: powerPayInformation.coAppDriversLicenseNumber || '',
      coAppMilitaryActive: powerPayInformation.isCoAppMilitaryActive || '',
      coAppUsCitizen: powerPayInformation.isCoAppUsCitizen || '',
      coAppBirthday: powerPayInformation.coAppBirthday || '',
      coAppFullSsn: powerPayInformation.coAppFullSsn || '',
    }),

    validationSchema: Yup.object({
      coAppDriversLicenseState: Yup.string().required("Please select Co-Applicant's issue state."),
      coAppDriversLicenseNumber: Yup.string().required(
        "Please enter Co-Applicant's drivers license number.",
      ),
      coAppMilitaryActive: Yup.bool().required('Please select an option.'),
      coAppUsCitizen: Yup.bool().required('Please select an option.'),
      coAppBirthday: Yup.string()
        .required("Please enter the Co-Applicant's date of birth.")
        .test(
          'incomplete',
          'Incomplete date',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 8,
        )
        .test('valid', 'Invalid date format.', val => val && !validDateFormat(val))
        .test(
          'age',
          'The Co-Applicant must be at least 18 years old.',
          val => val && new AgeFromDateString(val).age >= 18,
        ),
      coAppFullSsn: Yup.string()
        .required("Please enter Co-Applicant's full SSN.")
        .test(
          'len',
          'Must have 9 digits',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 9,
        )
        .test(
          'unique',
          "Same SSN as the applicant's is not allowed",
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('') !== powerPayInformation.appFullSsn,
        )
        .typeError('Numbers only.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      let ssn = values.coAppFullSsn.match(/\d/g).join('');
      dispatchChangePowerPayInfoValue('coAppDriversLicenseState', values.coAppDriversLicenseState);
      dispatchChangePowerPayInfoValue(
        'coAppDriversLicenseNumber',
        values.coAppDriversLicenseNumber,
      );
      dispatchChangePowerPayInfoValue('isCoAppMilitaryActive', values.coAppMilitaryActive);
      dispatchChangePowerPayInfoValue('isCoAppUsCitizen', values.coAppUsCitizen);
      dispatchChangePowerPayInfoValue('coAppBirthday', values.coAppBirthday);
      dispatchChangePowerPayInfoValue('coAppFullSsn', ssn);

      if (isSSNanITIN(ssn)) {
        if (
          !isStateAllowed(pqi.state) ||
          pqi.airConditioningSystem !== AirConditioningSystemEnum.AIR_CONDITION_OR_HEATING_SYSTEM.id
        ) {
          dispatchSetFlow(preQualificationFlow);
          dispatchChangePage(AquaPreQualificationFlowPageNumber.NotAllowed);
          history.push('/');
        } else {
          dispatchSetFlow(microfFlow);
          dispatchChangePage(MicrofFlowPageNumber.Landing);
          history.push('/RTO');
        }
      } else {
        dispatchChangePage(PowerPayFlowPageNumber.CoAppEmploymentDetails);
      }
      dispatchSubmitMindfire({
        email: email,
        lastPage: '6015',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(CoAppIdentificationInfoForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={
            isReview
              ? PowerPayFlowPageNumber.ApplicationReview
              : PowerPayFlowPageNumber.CoAppAddress
          }
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <CoAppIdentificationInfoFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  powerPayInformation: state.powerPayInformation,
  pqi: state.preQualificationInformation,
  ssnLastDigits: state.preQualificationInformation.ssnLastDigits || '',
  email: state.preQualificationInformation.email,
  isReview: state.powerPayInformation.isReview,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetFlow: flowName => dispatch(setFlow(flowName)),
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangePowerPayInfoValue: (key, value) => dispatch(changePowerPayInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

CoAppIdentificationInfo.propTypes = {
  powerPayInformation: PropTypes.object,
  ssnLastDigits: PropTypes.string,
  dispatchChangePage: PropTypes.func,
  dispatchChangePowerPayInfoValue: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CoAppIdentificationInfo),
);
