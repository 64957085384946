import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import HvacSystemType, { isACUnit } from '../../assets/js/HvacSystemEnum';
import hvacTonnageEnum from '../../assets/js/HvacTonnageEnum';
import selectedFinancierEnum from '../../assets/js/SelectedFinancierEnum';
import terms from '../../assets/pdf/Microf Statement of Terms and Conditions.pdf';
import statementOfUse from '../../assets/pdf/Microf Statement on Use of Electronic Records.pdf';
import BackBtn from '../../components/UI/BackButton/BackButton';
import Button from '../../components/UI/Button/CustomButton';
import Checkbox from '../../components/UI/Form/Checkbox/Checkbox';
import { getPrivacyStatementURL } from '../../config/settings';
import { APPLICANTCONSENT_TOOLTIP_TEXT } from '../../constants/tooltipTexts';
import { getAddress, reactGAEvent, reactGAPageview } from '../../helper';
import {
  changeMicrofInfoValue,
  changePage,
  submitMindfire,
  submitSoftPullApplication,
} from '../../redux/actions/index';
import { MicrofFlowPageNumber } from './MicrofFlow';
import { isPartner } from '../../assets/js/AddedByAPIEnum';

const Review = props => {
  const {
    microfInformation,
    pqi,
    dispatchChangePage,
    dispatchChangeValue,
    children,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    applicantAddress,
    isFTL,
    aquaFinanceAssigned,
    softPull,
    emulatorId,
    consumerId,
    track_id,
    dispatchSubmitSoftPullApplication,
    app,
    airConditioningSystem,
    installmentDate,
    addedBy,
    isServiceFinance,
  } = props;

  const isPartnerApp = isPartner(addedBy);

  const propertyAddress = pqi.street
    ? getAddress({
        street: pqi.street,
        city: pqi.city,
        state: pqi.state,
        zip: pqi.zipcode,
      })
    : 'No address';

  const primaryApplicantAddress = applicantAddress
    ? getAddress({
        street: applicantAddress.street,
        city: applicantAddress.city,
        state: applicantAddress.state,
        zip: applicantAddress.zip,
      })
    : propertyAddress;

  const coApplicantAddress = microfInformation.hasCoApp
    ? getAddress({
        street: microfInformation.coAppStreet,
        city: microfInformation.coAppCity,
        state: microfInformation.coAppState,
        zip: microfInformation.coAppZipCode,
      })
    : 'No address';

  useEffect(() => {
    reactGAPageview({
      path: '/ca_confirminfo',
      trackerNames: [],
      title: 'ca_confirminfo',
      did,
      dealerUserId,
    });
    dispatchChangeValue('isReview', false);
    // eslint-disable-next-line
  }, []);

  const editPage = page => {
    dispatchChangeValue('isReview', true);
    dispatchChangePage(page);
  };

  const ReviewForm = () => (
    <Form>
      <Row className="review">
        <Col>
          {!microfInformation.decisionEngineResubmit ? (
            <Row className="align-items-center justify-content-between">
              <Col xs="8" lg="9">
                <h5>Applicant Disclosures</h5>
              </Col>
            </Row>
          ) : null}

          <Field
            component={Checkbox}
            name="applicantConsent"
            id="applicantConsent"
            tooltipText={APPLICANTCONSENT_TOOLTIP_TEXT}
            label={
              microfInformation.decisionEngineResubmit
                ? `Bypass any cache values`
                : `I authorize Microf to access my info under the Fair Credit Reporting Act`
            }
            labelClassName="bold mb-0"
            clickHandler={() =>
              dispatchChangeValue('applicantConsent', !microfInformation.applicantConsent)
            }
          />
          {!microfInformation.decisionEngineResubmit ? (
            <p>
              You authorize Microf under the Fair Credit Reporting Act (“FCRA”) to access
              information from your personal credit profile or other information from one or more
              consumer reporting agencies. After checking the box and proceeding, Microf will
              perform a hard inquiry of your consumer report that may affect your credit score.
            </p>
          ) : null}
        </Col>
      </Row>
      {microfInformation.hasCoApp && !microfInformation.decisionEngineResubmit && (
        <Row className="review">
          <Col>
            <Row className="align-items-center justify-content-between">
              <Col xs="8" lg="9">
                <h5>Co-Applicant Disclosures</h5>
              </Col>
            </Row>
            <Field
              component={Checkbox}
              name="coApplicantConsent"
              id="coApplicantConsent"
              tooltipText={APPLICANTCONSENT_TOOLTIP_TEXT}
              label={`I agree to Microf’s Terms & Conditions`}
              labelClassName="bold mb-0"
              clickHandler={() =>
                dispatchChangeValue('coApplicantConsent', !microfInformation.coApplicantConsent)
              }
            />
            <p>
              {`You understand and agree to Microf’s `}
              <Link to={terms} target="_blank">
                Statement of Terms and Conditions
              </Link>
              ,{' '}
              <Link to={statementOfUse} target="_blank">
                Statement on Use of Electronic Records
              </Link>
              , and{' '}
              <a href={getPrivacyStatementURL()} target="_blank" rel="noopener noreferrer">
                Privacy Statement
              </a>
              .
            </p>
            <Field
              component={Checkbox}
              name="coApplicantSecondConsent"
              id="coApplicantSecondConsent"
              tooltipText={APPLICANTCONSENT_TOOLTIP_TEXT}
              label={`I authorize Microf to access my info under the Fair Credit Reporting Act`}
              labelClassName="bold mb-0"
              clickHandler={() =>
                dispatchChangeValue(
                  'coApplicantSecondConsent',
                  !microfInformation.coApplicantSecondConsent,
                )
              }
            />
            <p>
              You authorize Microf under the Fair Credit Reporting Act (“FCRA”) to access
              information from your personal credit profile or other information from one or more
              consumer reporting agencies. After checking the box and proceeding, Microf will
              perform a hard inquiry of your consumer report that may affect your credit score.
            </p>
          </Col>
        </Row>
      )}
      <Button type="submit" title="Yes this is correct" disabled={microfInformation.submited} />
    </Form>
  );

  const ReviewFormFormik = withFormik({
    mapPropsToValues: () => ({
      applicantConsent: microfInformation.applicantConsent || false,
      coApplicantConsent: microfInformation.coApplicantConsent || false,
      coApplicantSecondConsent: microfInformation.coApplicantSecondConsent || false,
    }),

    validationSchema: Yup.object({
      applicantConsent: Yup.boolean().oneOf([true], 'Please check to continue.'),
      coApplicantConsent: microfInformation.hasCoApp
        ? Yup.boolean().oneOf([true], 'Please check to continue.')
        : Yup.boolean(),
      coApplicantSecondConsent: microfInformation.hasCoApp
        ? Yup.boolean().oneOf([true], 'Please check to continue.')
        : Yup.boolean(),
    }),

    handleSubmit: () => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Yes this is correct',
        did,
        dealerUserId,
      });
      dispatchSubmitMindfire({
        email: pqi.email,
        lastPage: '4094',
        dealerUserId: dealerUserId,
        did: did,
      });

      submitApplication();
    },
  })(ReviewForm);

  const submitApplication = () => {
    const softPullRequest = {
      email: pqi.email || softPull.softPullResponse.email,
      borrowedAmount: pqi.borrowedAmount || softPull.softPullResponse.borrowedAmount,
      firstName: pqi.firstName || softPull.softPullResponse.firstName,
      lastName: pqi.lastName || softPull.softPullResponse.lastName,
      middleName: pqi.middleName || softPull.softPullResponse.middleName,
      phoneNumber: pqi.phoneNumber || softPull.softPullResponse.phoneNumber,
      street: pqi.street || softPull.softPullResponse.street,
      city: pqi.city || softPull.softPullResponse.city,
      zipcode: pqi.zipcode || softPull.softPullResponse.zipcode,
      state: pqi.state || softPull.softPullResponse.state,
      applicantStreet: applicantAddress ? applicantAddress.street : null,
      applicantCity: applicantAddress ? applicantAddress.city : null,
      applicantZip: applicantAddress ? applicantAddress.zip : null,
      applicantState: applicantAddress ? applicantAddress.state : null,
      birthday: pqi.birthday || softPull.softPullResponse.birthday,
      ssnLastDigits: pqi.ssnLastDigits || softPull.softPullResponse.ssnLastDigits,
      airConditioningSystem: airConditioningSystem
        ? airConditioningSystem
        : softPull.softPullResponse?.airConditioningSystem
        ? softPull.softPullResponse?.airConditioningSystem
        : pqi.airConditioningSystem,
      dealerUserId: dealerUserId,
      consumerId: consumerId,
      prequalificationId: pqi.prequalificationId || softPull.softPullResponse.prequalificationId,
      ssn: microfInformation.appFullSSN,
      selectedFinancier: selectedFinancierEnum.MICROF.value,
      totalJobPrice: microfInformation.totalPrice,
      requestedLoanAmount: microfInformation.totalPrice,
      //1
      hvacSystemType: microfInformation.hvacSystem,
      hvacSystemSize: microfInformation.hvacTonnage,
      systemType: null,
      hvacHeatType: null,
      secondSystemType: null,
      secondHvacHeatType: null,
      //2
      secondHvacSystemType: microfInformation.secondHvacSystem,
      secondHvacSystemSize: microfInformation.secondHvacTonnage,
      //3
      waterHeaterType: microfInformation.waterHeater
        ? microfInformation.waterHeater.toUpperCase()
        : null,
      secondWaterHeaterType: microfInformation.secondWaterHeater
        ? microfInformation.secondWaterHeater.toUpperCase()
        : null,
      homeownerInsurance: microfInformation.homeownerInsurance,
      monthlyNetIncome: microfInformation.appMonthlyNetIncome,
      bankAba: microfInformation.appBankAccountRoutingNumber,
      bankAccountNumber: microfInformation.appBankAccountNumber,
      coapplicantFirstName: microfInformation.hasCoApp ? microfInformation.coAppFirstName : null,
      coapplicantLastName: microfInformation.hasCoApp ? microfInformation.coAppLastName : null,
      coapplicantMiddleName: microfInformation.hasCoApp
        ? microfInformation.coAppMiddleInitial
        : null,
      coapplicantEmail: microfInformation.hasCoApp ? microfInformation.coAppEmail : null,
      coapplicantPhoneNumber: microfInformation.hasCoApp
        ? microfInformation.coAppPhoneNumber
        : null,
      coapplicantStreet: microfInformation.hasCoApp ? microfInformation.coAppStreet : null,
      coapplicantCity: microfInformation.hasCoApp ? microfInformation.coAppCity : null,
      coapplicantZipcode: microfInformation.hasCoApp ? microfInformation.coAppZipCode : null,
      coapplicantState: microfInformation.hasCoApp ? microfInformation.coAppState : null,
      coapplicantBirthday: microfInformation.hasCoApp ? microfInformation.coAppDoB : null,
      coapplicantSsn: microfInformation.hasCoApp ? microfInformation.coAppFullSsn : null,
      coapplicantMonthlyNetIncome: microfInformation.hasCoApp
        ? microfInformation.coAppMonthlyNetIncome
        : null,
      coapplicantBankAba: microfInformation.hasCoApp
        ? microfInformation.coAppBankAccountRoutingNumber
        : null,
      coapplicantBankAccountNumber: microfInformation.hasCoApp
        ? microfInformation.coAppBankAccountNumber
        : null,
      emulatorId: emulatorId,
      track_id: track_id,
      aquaFinanceAssigned: !!aquaFinanceAssigned,
      isHvacPlusWaterHeater: null,
      installmentDate: installmentDate,
      decisionEngineResubmit: microfInformation.decisionEngineResubmit,
    };

    dispatchSubmitSoftPullApplication(softPullRequest, MicrofFlowPageNumber.LeasingOffers, app);
  };

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={
            isPartnerApp
              ? MicrofFlowPageNumber.ServiceFinance
              : microfInformation.hasCoApp
              ? MicrofFlowPageNumber.CoAppIdentificationInfo
              : MicrofFlowPageNumber.HasCoApplicant
          }
        />
        <Col sm="5">
          {children}
          {isFTL ? (
            <div>
              <p>
                <b>
                  If you need to adjust the Total Job Price or Lease amount request, please click
                  the Edit button to the right.
                </b>
              </p>
              <p>
                <b>Microf Max Approval:</b> $10,000.00 (based on customer eligibility)
                <br />
                <b>Microf Min Approval:</b> $2,500.00 (may vary by state)
              </p>
            </div>
          ) : null}
        </Col>
        <Col sm="6">
          <Row className="review">
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col xs="8" lg="9">
                  <h5>Equipment Information</h5>
                </Col>
                <Col xs="4" lg="3">
                  {!isFTL ? (
                    <Button
                      className="edit"
                      clickHandler={() =>
                        editPage(
                          isServiceFinance
                            ? MicrofFlowPageNumber.ServiceFinance
                            : isPartnerApp
                            ? MicrofFlowPageNumber.EditSystemType
                            : MicrofFlowPageNumber.SystemType,
                        )
                      }
                      title="Edit"
                    />
                  ) : null}
                </Col>
              </Row>

              <Row>
                <Col xs="6">
                  <b>Retail (Cash) Price</b>
                  <NumberFormat
                    value={microfInformation.totalPrice}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={'$'}
                    displayType="text"
                  />
                </Col>
              </Row>
              {microfInformation.hvacSystem ? (
                <Row>
                  <Col xs="6">
                    <b>System Type</b>
                    {Object.values(HvacSystemType).find(
                      obj => obj.value === microfInformation.hvacSystem,
                    )?.description || 'N/A'}
                  </Col>
                  {isACUnit(microfInformation.hvacSystem) ? (
                    <Col xs="6">
                      <b>Tonnage</b>
                      {Object.values(hvacTonnageEnum).find(
                        obj => obj.value === microfInformation.hvacTonnage,
                      )?.name || 'N/A'}
                    </Col>
                  ) : null}
                </Row>
              ) : null}

              {microfInformation.secondHvacSystem ? (
                <Row>
                  <Col xs="6">
                    <b>System Type</b>
                    {Object.values(HvacSystemType).find(
                      obj => obj.value === microfInformation.secondHvacSystem,
                    )?.description || 'N/A'}
                  </Col>
                  {isACUnit(microfInformation.secondHvacSystem) ? (
                    <Col xs="6">
                      <b>Tonnage</b>
                      {Object.values(hvacTonnageEnum).find(
                        obj => obj.value === microfInformation.secondHvacTonnage,
                      )?.name || 'N/A'}
                    </Col>
                  ) : null}
                </Row>
              ) : null}
              {microfInformation.waterHeater ? (
                <Row>
                  <Col xs="6">
                    <b>System Type</b>
                    {Object.values(HvacSystemType).find(
                      obj => obj.value === microfInformation.waterHeater,
                    )?.description || 'N/A'}
                  </Col>
                </Row>
              ) : null}
              {microfInformation.secondWaterHeater ? (
                <Row>
                  <Col xs="6">
                    <b>System Type</b>
                    {Object.values(HvacSystemType).find(
                      obj => obj.value === microfInformation.secondWaterHeater,
                    )?.description || 'N/A'}
                  </Col>
                </Row>
              ) : null}
            </Col>
          </Row>

          <Row className="review">
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col xs="8" lg="9">
                  <h5>Household Information</h5>
                </Col>
                <Col xs="4" lg="3">
                  <Button
                    className="edit"
                    clickHandler={() => editPage(MicrofFlowPageNumber.Homeowner)}
                    title="Edit"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <b>Household Address</b>
                  {propertyAddress}
                </Col>
                <Col xs="6">
                  <b>Homeowner's Insurance</b>
                  {microfInformation.homeownerInsurance ? 'Yes' : 'No'}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="review">
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col xs="8" lg="9">
                  <h5>Applicant’s Information</h5>
                </Col>
                <Col xs="4" lg="3">
                  <Button
                    className="edit"
                    clickHandler={() => editPage(MicrofFlowPageNumber.AccountEmail)}
                    title="Edit"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <b>Name</b>
                  {(pqi.firstName ? pqi.firstName + ' ' : '') +
                    (pqi.middleName ? pqi.middleName + ' ' : '') +
                    (pqi.lastName || '')}
                </Col>
                <Col xs="6">
                  <b>Phone Number</b>
                  {!!pqi.phoneNumber
                    ? pqi.phoneNumber.length > 6
                      ? '(' +
                        pqi.phoneNumber.substring(0, 3) +
                        ') ' +
                        pqi.phoneNumber.substring(3, 6) +
                        '-' +
                        pqi.phoneNumber.substring(6)
                      : pqi.phoneNumber
                    : 'N/A'}
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <b>Email Address</b>
                  <span className="text-break">{pqi.email || 'N/A'}</span>
                </Col>
                <Col xs="6">
                  <b>Date of Birth</b>
                  {pqi.birthday || 'N/A'}
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <b>Monthly Net Income</b>
                  <NumberFormat
                    value={microfInformation.appMonthlyNetIncome}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={'$'}
                    displayType="text"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <b>Applicant’s Address</b>
                  {primaryApplicantAddress}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="review">
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col xs="8" lg="9">
                  <h5>Co-Applicant’s Information</h5>
                </Col>
                <Col xs="4" lg="3">
                  <Button
                    className="edit"
                    clickHandler={() => editPage(MicrofFlowPageNumber.HasCoApplicant)}
                    title={microfInformation.hasCoApp ? 'Edit' : 'Add'}
                  />
                </Col>
              </Row>
              {microfInformation.hasCoApp ? (
                <React.Fragment>
                  <Row>
                    <Col xs="6">
                      <b>Name</b>
                      {(microfInformation.coAppFirstName
                        ? microfInformation.coAppFirstName + ' '
                        : '') +
                        (microfInformation.coAppMiddleInitial
                          ? microfInformation.coAppMiddleInitial + ' '
                          : '') +
                        (microfInformation.coAppLastName || '')}
                    </Col>
                    <Col xs="6">
                      <b>Phone Number</b>
                      {!!microfInformation.coAppPhoneNumber
                        ? microfInformation.coAppPhoneNumber.length > 6
                          ? '(' +
                            microfInformation.coAppPhoneNumber.substring(0, 3) +
                            ') ' +
                            microfInformation.coAppPhoneNumber.substring(3, 6) +
                            '-' +
                            microfInformation.coAppPhoneNumber.substring(6)
                          : microfInformation.coAppPhoneNumber
                        : 'N/A'}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">
                      <b>Email Address</b>
                      <span className="text-break">{microfInformation.coAppEmail || 'N/A'}</span>
                    </Col>
                    <Col xs="6">
                      <b>Date of Birth</b>
                      {microfInformation.coAppDoB || 'N/A'}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">
                      <b>Monthly Net Income</b>
                      <NumberFormat
                        value={microfInformation.coAppMonthlyNetIncome}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        prefix={'$'}
                        displayType="text"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <b>Co-Applicant’s Address</b>
                      {coApplicantAddress}
                    </Col>
                  </Row>
                </React.Fragment>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col>
              <ReviewFormFormik />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  applicantAddress: state.microfInformation.applicantAddress
    ? state.microfInformation.applicantAddress
    : state.softPull.applicationSubmitDTO?.applicantPrimary?.address,
  microfInformation: state.microfInformation,
  hasSecondWaterHeater: state.microfInformation.hasSecondWaterHeater,
  pqi: state.preQualificationInformation,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  isServiceFinance: state.microfInformation.isServiceFinance,
  isFTL: state.microfInformation.isFTL,
  aquaFinanceAssigned: state.auth.aquaFinanceAssigned,
  softPull: state.softPull,
  emulatorId: state.auth.user.user ? state.auth.user.user.emulatorId : null,
  consumerId: state.auth.consumerId,
  track_id: state.auth.track_id,
  app: state.softPull.applicationSubmitDTO,
  app2: state.softPull.applicationSubmitDTO2,
  airConditioningSystem: state.microfInformation.airConditioningSystem,
  installmentDate: state.microfInformation.installmentDate,
  addedBy: state.softPull.applicationSubmitDTO?.addedBy,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) => dispatch(changeMicrofInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchSubmitSoftPullApplication: (params, page, app) =>
    dispatch(submitSoftPullApplication(params, page, app)),
});

Review.propTypes = {
  microfInformation: PropTypes.object,
  pqi: PropTypes.object,
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Review);
