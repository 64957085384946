import PropTypes from 'prop-types';
import React from 'react';
import { Field, Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Col, Row, Label } from 'reactstrap';
import CustomInput from '../../../../components/UI/Form/Input/InputFieldAll';
import Button from '../../../../components/UI/Button/ButtonField';
import CustomPhoneNumber from '../../../../components/UI/Form/PhoneNumber/PhoneNumberField';
import Select from '../../../../components/UI/Form/Select/SelectField';
import SelectList from '../../../../components/UI/Form/Select/SelectListField';
import { changePage, changeAuthValue, endLoading } from '../../../../redux/actions/index';
import usStates from '../../../../assets/js/USStateEnum';
import { withRouter } from 'react-router-dom';
import { NewLanderContractorEnrollmentFlowPageNumber } from './_Flow';
import { isNoreplyEmail } from '../../../../helper';

const BusinessManager = props => {
  const { dealerDTO, dispatchChangeValue, dispatchChangePage } = props;

  const BusinessManagerForm = ({ values, errors, touched }) => (
    <Form autoComplete="off">
      <Row>
        <Col sm="4" className="pl-0">
          <Label>Last Name *</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Field
            component={CustomInput}
            name="businessManagerLastName"
            id="businessManagerLastName"
            type="text"
          />
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="pl-0">
          <Label>First Name *</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Field
            component={CustomInput}
            name="businessManagerFirstName"
            id="businessManagerFirstName"
            type="text"
          />
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="pl-0">
          <Label>Phone *</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Field
            component={CustomPhoneNumber}
            name="businessManagerPhone"
            id="businessManagerPhone"
          />
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="pl-0">
          <Label>Email *</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Field
            component={CustomInput}
            name="businessManagerEmail"
            id="businessManagerEmail"
            type="email"
          />
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="pl-0">
          <Label>Street Address (Home) *</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Field
            component={CustomInput}
            name="businessManagerStreet"
            id="businessManagerStreet"
            type="text"
          />
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="pl-0">
          <Label>City (Home) *</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Field
            component={CustomInput}
            name="businessManagerCity"
            id="businessManagerCity"
            type="text"
          />
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="pl-0">
          <Label>State, ZIP Code (Home) *</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Row>
            <Col sm="6" className="pl-0">
              <Field
                component={Select}
                name="businessManagerState"
                id="businessManagerState"
                type="select"
                selectValues={usStates}
                selectFirstName="Select State"
              />
            </Col>
            <Col sm="6" className="pr-0">
              <Field
                component={CustomInput}
                name="businessManagerZIPCode"
                id="businessManagerZIPCode"
                type="text"
                maxLength={10}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="pl-0">
          <Label>SSN *</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Field
            maxLength={9}
            component={CustomInput}
            id="businessManagerSSN"
            name="businessManagerSSN"
            type="password"
            number
            icon="lock"
          />
        </Col>
      </Row>
      {dealerDTO.applyForAquaFinance || dealerDTO.applyForPowerPay || dealerDTO.applyForMosaic ? (
        <>
          <h3 className="top-20">Beneficial Owners</h3>
          <Row>
            <Col sm="4" className="pl-0">
              <Label>Are there owners that have 25% or greater ownership in your company?</Label>
            </Col>
            <Col sm="7" className="pr-0">
              <Field
                component={SelectList}
                name="businessManagerOwnership"
                id="businessManagerOwnership"
                type="select"
                selectValues={['Yes', 'No']}
                selectFirstName="Select One"
              />
            </Col>
          </Row>
        </>
      ) : null}
      <Row className="contractor-user-view-row p-0 m-0">
        <Col sm="11" className="text-right pl-0 pr-0">
          <Button
            type="submit"
            title="Previous"
            className="rounded-pill"
            clickHandler={() => {
              dispatchChangePage(NewLanderContractorEnrollmentFlowPageNumber.DealershipInformation);
            }}
          />
          <Button type="submit" title="Next" className="rounded-pill" />
        </Col>
      </Row>
    </Form>
  );

  const BusinessManagerFormFormik = withFormik({
    mapPropsToValues: () => ({
      businessManagerLastName: dealerDTO.principalNameLast || '',
      businessManagerFirstName: dealerDTO.principalNameFirst || '',
      businessManagerPhone: dealerDTO.principalPhone || '',
      businessManagerEmail: dealerDTO.principalEmail || '',
      businessManagerStreet: dealerDTO.principalAddressStreet || '',
      businessManagerCity: dealerDTO.principalAddressCity || '',
      businessManagerZIPCode: dealerDTO.principalAddressZipcode || '',
      businessManagerState: dealerDTO.principalAddressState || '',
      businessManagerSSN: dealerDTO.principalSSN ? dealerDTO.principalSSN.split('-').join('') : '',
      businessManagerOwnership: dealerDTO.ownersWith25 ? 'No' : 'Yes',
    }),

    validationSchema: Yup.object({
      businessManagerLastName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .required('Please enter Last Name.'),
      businessManagerFirstName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .required('Please enter First Name.'),
      businessManagerPhone: Yup.string()
        .test('required', 'Please enter Phone.', val => val !== '+' && val)
        .test(
          'len',
          'Invalid Phone number.',
          val => val && val.match(/\d/g).join('').length === 10,
        ),
      businessManagerEmail: Yup.string()
        .email('Please enter a valid Email.')
        .required('Please enter an Email.')
        .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),

      businessManagerStreet: Yup.string().required('Please enter a Street.'),
      businessManagerCity: Yup.string().required('Please enter a City.'),
      businessManagerZIPCode: Yup.string()
        .matches(/^\d{5}?$/, {
          message: 'Invalid ZIP Code.',
          excludeEmptyString: true,
        })
        .required('Please enter a Property ZIP Code.'),
      businessManagerState: Yup.string().required('Please select a State.'),

      businessManagerSSN: Yup.string()
        .required('Please enter the full SSN.')
        .test('len', 'Must have 9 digits', val => val && val.toString().length === 9)
        .typeError('Only numbers allowed.'),
      businessManagerOwnership:
        dealerDTO.applyForAquaFinance || dealerDTO.applyForPowerPay || dealerDTO.applyForMosaic
          ? Yup.string().required('Please select ownership.')
          : null,
    }),

    handleSubmit: values => {
      let dealer = { ...dealerDTO };
      dealer.principalNameLast = values.businessManagerLastName;
      dealer.principalNameFirst = values.businessManagerFirstName;
      dealer.principalPhone = values.businessManagerPhone;
      dealer.principalEmail = values.businessManagerEmail;
      dealer.principalAddressStreet = values.businessManagerStreet;
      dealer.principalAddressCity = values.businessManagerCity;
      dealer.principalAddressZipcode = values.businessManagerZIPCode;
      dealer.principalAddressState = values.businessManagerState;
      dealer.principalSSN = values.businessManagerSSN;
      dealer.ownersWith25 = values.businessManagerOwnership === 'No';

      dispatchChangeValue('dealerDTO', dealer);
      dispatchChangePage(NewLanderContractorEnrollmentFlowPageNumber.BeneficialOwnerInformation);
    },
  })(BusinessManagerForm);

  return (
    <>
      <h2>Business Manager</h2>
      <h3>Business Manager Information</h3>
      <p>
        <b>The individual with the responsibility to control, manage or direct the company</b>
      </p>
      <Row>
        <Col sm="8" className="p-0">
          <BusinessManagerFormFormik />
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = state => ({
  dealerDTO: state.auth.dealerDTO,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) => dispatch(changeAuthValue(key, value)),
  dispatchEndLoading: () => dispatch(endLoading()),
});

BusinessManager.propTypes = {
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(BusinessManager),
);
