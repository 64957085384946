import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import Button from '../../../../components/UI/Button/CustomButton';
import { resetNewAppState } from '../../../../redux/actions';

const ApplicationReceived = props => {
  const { dispatchResetNewAppState, history } = props;

  return (
    <Container className="app-received">
      <Row className="justify-content-center text-center ">
        <Col>
          <Row className="">
            <Col>
              <div>
                <b>Applicant</b>{' '}
                <p>
                  You will receive an email with a URL for the Service Finance Company e-sign portal
                  to review and e-sign you loan documents once they are ready.
                </p>
              </div>
            </Col>
          </Row>
          <br />
          <Row className="">
            <Col>
              <div>
                <b>Contractor</b>{' '}
                <p>
                  Please log into your Service Finance Company dealer portal to complete any
                  remaining steps and to request funding once the job is complete, loan documents
                  are signed, and any stipulations have been cleared.
                </p>
                <span>
                  (
                  <a
                    href="https://apps.svcfin.com/dealerportal"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    https://apps.svcfin.com/dealerportal
                  </a>
                  )
                </span>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center mt-5">
            <Col sm="2" />
            <Col>
              <Button
                type="button"
                title={'Close Application'}
                clickHandler={() => {
                  dispatchResetNewAppState();
                  history.push('/portal/dashboard');
                }}
              />
            </Col>
            <Col sm="2" />
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  dispatchResetNewAppState: () => dispatch(resetNewAppState()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ApplicationReceived),
);
