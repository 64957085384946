import React from 'react';
import { Col, Row } from 'reactstrap';

const NavbarComponent = props => {
  const {
    url,
    icon,
    description,
    history,
    changePageTitle,
    clickHandler,
    setDefaultTheme,
    theme,
    disabled,
  } = props;
  return (
    <Row
      noGutters
      onClick={
        disabled
          ? () => {}
          : clickHandler
          ? clickHandler
          : () => {
              changePageTitle(description);
              history.push('/portal' + url);
              setDefaultTheme(theme);
            }
      }
      className={`navbar-component ${window.location.pathname.includes(url) ? 'active' : ''} ${
        disabled ? 'disabled' : ''
      }`}
    >
      <Col className={`${disabled ? 'disabled' : ''}`} xs="2 text-center">
        {icon}
      </Col>
      <Col className={`${disabled ? 'disabled' : ''}`} xs="10">
        {description}
      </Col>
    </Row>
  );
};

export default NavbarComponent;
