import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row } from 'reactstrap';
import Loader from 'react-loader-spinner';
import ErrorModal from '../UI/Modal/Notification';

import './Section.scss';

const Section = props => {
  const { isFinished, Component, loading, loadingMessage } = props;

  return loading ? (
    <section id="content" className="finished">
      <div className="finish">
        <Row className="h-100 justify-content-center align-items-center no-gutters">
          <Loader type="Oval" color="#119c4a" height="226" width="226" />
          <h2 className="w-50 position-absolute">{loadingMessage || 'Loading ...'}</h2>
        </Row>
      </div>
    </section>
  ) : (
    <section id="content" className={isFinished ? 'finished' : ''}>
      {Component}
      <ErrorModal />
    </section>
  );
};

const mapStateToProps = state => ({
  loading: state.notification.loading,
  loadingMessage: state.notification.loadingMessage,
});

Section.propTypes = {
  loading: PropTypes.bool,
  loadingMessage: PropTypes.string,
  Component: PropTypes.object,
};

export default connect(mapStateToProps)(Section);
