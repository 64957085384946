import React from 'react';
import { Field } from 'formik';
import InputFeedback from '../InputFeedback/InputFeedback';

const RadioButtons = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched, setFieldValue },
  icon,
  max,
  min,
  maxLength,
  className,
  pattern,
  disabled,
  number,
  label,
  labelClassName,
  type,
  id,
  placeholder,
  formGroupClassName,
  readOnly,
  options,
  clickHandler,
  ...props
}) => {
  return (
    <>
      <Field name={name} {...props}>
        {({ field }) => {
          return options.map(option => {
            return (
              <div key={option.key}>
                <input
                  type="radio"
                  id={option.id}
                  {...field}
                  value={option.value}
                  checked={option.checked ? true : field.value === option.value}
                  onClick={() => {
                    clickHandler(option.value);
                  }}
                />{' '}
                <label htmlFor={option.id}>
                  {option.image ? <img src={option.image} alt={option.key} /> : option.key}
                </label>
              </div>
            );
          });
        }}
      </Field>
      {touched[name] && <InputFeedback error={errors[name]} />}
    </>
  );
};

export default RadioButtons;
