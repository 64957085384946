import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import LeasingOffer from '../../components/UI/Rheem/LeasingOffer/LeasingOffer';
import { reactGAPageview } from '../../helper';
import {
  changePage,
  submitMindfire,
  resetNewAppState,
  acceptTermsMicrofApplication,
  changeRheemInfoValue,
} from '../../redux/actions';
import kwikLeasingOffers from '../../assets/img/kwik-leasing-offers.png';
import { RheemFlowPageNumber } from './RheemFlow';

const LeasingOffers = props => {
  const {
    did,
    dealerUserId,
    email,
    children,
    dispatchChangeRheemInfoValue,
    dispatchChangePage,
    leasingOffer,
    app,
    dispatchAcceptTermsMicrofApplication,
    dispatchSubmitMindfire,
    totalLeaseAmount,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_suboptions',
      trackerNames: [],
      title: 'ca_suboptions',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const handleSubmitMindFire = () => {
    dispatchSubmitMindfire({
      email: email,
      lastPage: '100000000',
      dealerUserId: dealerUserId,
      did: did,
    });
  };

  return (
    <Container>
      <Row>
        <Col sm="1" />
        <Col sm="5">
          <h4>Congratulations! We found an option for you.</h4>
          {children}
        </Col>
        <Col sm="6">
          <LeasingOffer
            applicationId={app.applicationId}
            leasingOffer={leasingOffer}
            changePage={dispatchChangePage}
            submitMindfire={handleSubmitMindFire}
            capitalizedCostReduction={app.totalJobPrice - totalLeaseAmount}
            dealerUserId={dealerUserId}
            did={did}
            email={email}
            changeRheemInfoValue={dispatchChangeRheemInfoValue}
            acceptTermsMicrofApplication={dispatchAcceptTermsMicrofApplication}
            nextPage={RheemFlowPageNumber.FinalReview}
            totalLeaseAmount={totalLeaseAmount}
            application={app}
          />
          <Row noGutters className="leasing-offers-parent">
            <Col xs="12" md="5" xl="6" className="mt-5 d-flex align-items-center">
              <img
                width="100%"
                height="auto"
                src={kwikLeasingOffers}
                alt="kwik comfort leasing offers"
              />
            </Col>
            <Col xs="12" md="7" xl="6" className="mt-5 rheem-ul leasing-offers">
              <Row className="ml--10px">
                <Col>
                  <h6>New Equipment and Labor</h6>
                </Col>
              </Row>
              <Row className="ml-10px">
                <Col>
                  <h6>Annual Maintenance Included</h6>
                </Col>
              </Row>
              <Row className="ml-10px">
                <Col>
                  <h6>Supply of Filters Included</h6>
                </Col>
              </Row>
              <Row className="ml--10px">
                <Col>
                  <h6>Full 10 Year Warranty</h6>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  app: state.softPull.applicationSubmitDTO,
  leasingOffer:
    state.softPull.applicationSubmitDTO.lastApplicationResponse &&
    state.softPull.applicationSubmitDTO.lastApplicationResponse.decisionLeaseOptions &&
    state.softPull.applicationSubmitDTO.lastApplicationResponse.decisionLeaseOptions[0],
  totalLeaseAmount: !!state.softPull.applicationSubmitDTO.lastApplicationResponse
    ? state.softPull.applicationSubmitDTO.lastApplicationResponse.approvalAmount
    : 0,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangeRheemInfoValue: (key, value) => dispatch(changeRheemInfoValue(key, value)),
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchResetNewAppState: () => dispatch(resetNewAppState()),
  dispatchAcceptTermsMicrofApplication: (applicationId, termYears, page) =>
    dispatch(acceptTermsMicrofApplication(applicationId, termYears, page)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(LeasingOffers),
);
