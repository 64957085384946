import { Field, Form, withFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import HvacSystemTypeEnum from '../../assets/js/HvacSystemTypeEnum';
import BackBtn from '../../components/UI/BackButton/BackButton';
import Button from '../../components/UI/Button/CustomButton';
import Checkbox from '../../components/UI/Form/Checkbox/Checkbox';
import { SECONDHVAC_TOOLTIP_TEXT } from '../../constants/tooltipTexts';
import { reactGAPageview } from '../../helper';
import { changeMicrofInfoValue, changePage, submitMindfire } from '../../redux/actions/index';
import { MicrofFlowPageNumber } from './MicrofFlow';

const SystemType = props => {
  const {
    email,
    dispatchChangePage,
    dispatchChangeMicrofInfoValue,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    hvacSystemType,
    children,
    hasSecondHvac,
  } = props;
  const [isMessageHidden, setIsMessageHidden] = useState(
    hvacSystemType === null ? true : !!hvacSystemType,
  );

  useEffect(() => {
    reactGAPageview({
      path: '/ca_systemtype',
      trackerNames: [],
      title: 'ca_systemtype',
      did,
      dealerUserId,
    });

    // eslint-disable-next-line
  }, []);

  const SystemTypeForm = () => (
    <Form>
      <Row className="review">
        <Col>
          <Field
            component={Checkbox}
            name="secondHvac"
            id="secondHvac"
            tooltipText={SECONDHVAC_TOOLTIP_TEXT}
            label={`Is there a second system being installed?`}
            labelClassName="bold mb-0"
            clickHandler={() => dispatchChangeMicrofInfoValue('hasSecondHvac', !hasSecondHvac)}
          />
        </Col>
      </Row>
      <Button type="submit" title="Continue" />
    </Form>
  );

  const SystemTypeFormFormik = withFormik({
    mapPropsToValues: () => ({
      secondHvac: hasSecondHvac || false,
    }),

    validationSchema: Yup.object({
      secondHvac: Yup.boolean(),
    }),

    handleSubmit: () => {
      if (hvacSystemType) {
        dispatchChangePage(MicrofFlowPageNumber.HvacHeatType);
        dispatchSubmitMindfire({
          email: email,
          lastPage: '4124',
          dealerUserId: dealerUserId,
          did: did,
        });
      } else {
        setIsMessageHidden(false);
      }
    },
  })(SystemTypeForm);

  return (
    <Container>
      <Row>
        <BackBtn changePage={dispatchChangePage} page={MicrofFlowPageNumber.SystemSize} />
        <Col>{children}</Col>
        <Col>
          {Object.values(HvacSystemTypeEnum).map((obj, index) => (
            <Row className="mb-3" key={index}>
              <Col xs="12">
                <Button
                  className={hvacSystemType === obj.value ? '' : 'inactive'}
                  clickHandler={() => {
                    dispatchChangeMicrofInfoValue('hvacSystemType', obj.value);
                    setIsMessageHidden(true);
                  }}
                >
                  <h6 className="mb-0">
                    <span className="fs-20 font-weight-bold">{obj.name}</span> <br />{' '}
                    {obj.description}
                  </h6>
                </Button>
              </Col>
            </Row>
          ))}
          <Row>
            <Col>
              <p className="text-danger" hidden={isMessageHidden}>
                You must select an option.
              </p>
            </Col>
          </Row>
          <SystemTypeFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  homeownerInsurance: state.microfInformation.homeownerInsurance,
  isHomeowner: state.microfInformation.isHomeowner,
  email: state.preQualificationInformation.email,
  isReview: state.aquaInformation.isReview,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  hvacSystemType: state.microfInformation.hvacSystemType,
  hasSecondHvac: state.microfInformation.hasSecondHvac,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeMicrofInfoValue: (key, value) => dispatch(changeMicrofInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SystemType);
