import { Field, Form, withFormik } from 'formik';
import queryString from 'query-string';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import CustomInput from '../../../components/UI/Form/Input/CustomInput';
import { updateDealerZipCodes } from '../../../redux/actions';

const EditContractorZipCodes = props => {
  let zipcode = null;
  const { dealerSearchCriteriaResult, history, dispatchUpdateDealerZipCodes } = props;
  const dealerId = queryString.parse(window.location.search).id;

  let dealer = dealerSearchCriteriaResult?.dealers?.find(
    dealerToFind => dealerToFind.dealerId === parseInt(dealerId),
  );

  const [dealerZipCodes, setDealerZipCodes] = useState(dealer ? [...dealer.dealerZipcodes] : []);

  const addZipCode = dealerZipcode => {
    let dealerZipCodesTmp = dealerZipCodes;
    if (!dealerZipCodesTmp.find(zipCode => zipCode.zipcode === dealerZipcode)) {
      dealerZipCodesTmp.push({ dealerZipcodeId: 0, zipcode: dealerZipcode });
      setDealerZipCodes([...dealerZipCodesTmp]);
    }
  };

  const removeZipCode = index => {
    let dealerZipCodesTmp = dealerZipCodes;
    dealerZipCodesTmp.splice(index, 1);
    setDealerZipCodes([...dealerZipCodesTmp]);
  };

  const AddZipCodeForm = () => (
    <Form>
      <Row className="mt-5">
        <Col sm="4" className="text-right pt-1">
          Zipcode:
        </Col>
        <Col sm="4" className="text-center">
          <Field
            formGroupClassName="mb-0"
            labelClassName="d-none"
            component={CustomInput}
            label="ZIP Code"
            name="zipcode"
            id="zipcode"
            type="text"
            maxLength={10}
          />
        </Col>
        <Col sm="4" className="text-left">
          <Button type="submit" color="primary" className="rounded-pill">
            Add Zipcode
          </Button>
        </Col>
      </Row>
    </Form>
  );

  const AddZipCodeFormFormik = withFormik({
    mapPropsToValues: () => ({
      zipcode: zipcode || '',
    }),
    validationSchema: Yup.object({
      zipcode: Yup.string()
        .matches(/^\d{5}?$/, {
          message: 'Invalid zip code.',
          excludeEmptyString: true,
        })
        .required('Please enter a zip code.'),
    }),

    handleSubmit: values => {
      addZipCode(values.zipcode);
    },
  })(AddZipCodeForm);

  return (
    <Col>
      <h2 id="panelTitle">{dealer?.name}</h2>
      <div className="panel">
        <div className="panel-body larger">
          <Row>
            <Col className="right-bordered">
              <Row className="mt-1">
                <Col sm="4" className="text-right pt-1">
                  Zipcode:
                </Col>
                <Col sm="4" className="text-center pt-1">
                  {dealer?.businessAddress?.zipcode}
                </Col>
                <Col sm="4" className="text-left" />
              </Row>
              {dealerZipCodes
                ? dealerZipCodes.map((dealerZipCode, index) => (
                    <Row key={index} className="mt-1">
                      <Col sm="4" className="text-right pt-1">
                        Zipcode:
                      </Col>
                      <Col sm="4" className="text-center pt-1">
                        {dealerZipCode.zipcode}
                      </Col>
                      <Col sm="4" className="text-left">
                        <Button
                          type="reset"
                          className="btn-secondary rounded-pill"
                          onClick={() => {
                            removeZipCode(index);
                          }}
                        >
                          Remove
                        </Button>
                      </Col>
                    </Row>
                  ))
                : null}
            </Col>
          </Row>
          <AddZipCodeFormFormik />
          <Row>
            <Col className="text-right">
              <Button
                type="reset"
                color="secondary"
                className="rounded-pill"
                outline
                onClick={() => {
                  history.push('/portal/manage-contractor-zipcodes');
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                className="rounded-pill"
                onClick={() => {
                  let dealerDto = {
                    dealerId: dealer?.dealerId,
                    dealerZipcodes: dealerZipCodes,
                  };
                  if (
                    JSON.stringify(dealer?.dealerZipcodes.sort()) !==
                    JSON.stringify(dealerZipCodes.sort())
                  ) {
                    dispatchUpdateDealerZipCodes(dealerDto, history);
                  }
                }}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </Col>
  );
};

const mapStateToProps = state => ({
  dealerSearchCriteriaResult: state.softPull.dealerSearchCriteriaResult,
});

const mapDispatchToProps = dispatch => ({
  dispatchUpdateDealerZipCodes: (dealerDto, history) =>
    dispatch(updateDealerZipCodes(dealerDto, history)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(EditContractorZipCodes),
);
