import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import { reactGAPageview } from '../../../helper';
import {
  changePage,
  submitMindfire,
  resetState,
  changePageTitle,
  resetNewAppState,
} from '../../../redux/actions/index';
import Button from '../../../components/UI/Button/CustomButton';
import { getDealerPortalURL, getMosaicLoginUrl } from '../../../config/settings';
import { MosaicFlowPageNumber } from './MosaicFlow';

const ApprovedRedirect = props => {
  const {
    dispatchChangePage,
    dealerUserId,
    did,
    dispatchChangePageTitle,
    user,
    history,
    dispatchResetNewAppState,
    dispatchResetState,
  } = props;

  const isUserLoggedIn = !did && user && user.dealerUser;
  const buttonTitle = isUserLoggedIn ? 'Return to Dashboard' : 'Return to Login Page';
  const returnUrl = isUserLoggedIn ? '/portal/dashboard' : getDealerPortalURL();
  const mosaicLoginUrl = getMosaicLoginUrl();

  useEffect(() => {
    dispatchChangePageTitle('Approved Application');
    reactGAPageview({
      path: '/ca_ppapprovedredirect',
      trackerNames: [],
      title: 'ca_ppapprovedredirect',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Row className="justify-content-center">
        <BackBtn changePage={dispatchChangePage} page={MosaicFlowPageNumber.Approved} />
        <Col xs="auto" sm="11">
          <Row className="justify-content-center mt-2">
            <Col xs="auto">
              <h5>
                <b>Applicant</b>
              </h5>
            </Col>
          </Row>
          <Row className="justify-content-center mt-2">
            <b xs="auto">
              <h5 className="text-center">
                <b>
                  Applicant Please complete your Mosaic loan documents at{' '}
                  <a href={mosaicLoginUrl}>
                    <b>{mosaicLoginUrl}</b>
                  </a>
                </b>
              </h5>
            </b>
          </Row>
          <Row className="justify-content-center mt-2">
            <Col xs="auto">&nbsp;</Col>
          </Row>
          <Row className="justify-content-center mt-2">
            <Col xs="auto">
              <h5>
                <b>Contractor</b>
              </h5>
            </Col>
          </Row>
          <Row className="justify-content-center mt-2">
            <Col xs="auto">
              <h5 className="text-center">
                <b>
                  Please complete the Mosaic documents to create the opportunity for the applicant
                  at{' '}
                  <a href={mosaicLoginUrl}>
                    <b>{mosaicLoginUrl}</b>
                  </a>
                </b>
              </h5>
            </Col>
          </Row>
          <Row className="justify-content-center mt-3">
            <Col xs="auto">
              <Button
                type="button"
                title={buttonTitle}
                clickHandler={() => {
                  if (!isUserLoggedIn) {
                    dispatchResetState(props);
                    window.location.href = returnUrl;
                  } else {
                    dispatchResetNewAppState();
                    history.push(returnUrl);
                  }
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  status: state.softPull.applicationSubmitDTO && state.softPull.applicationSubmitDTO.status,
  consumerId: state.auth.consumerId,
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchResetState: props => dispatch(resetState(props)),
  dispatchResetNewAppState: () => dispatch(resetNewAppState()),
  dispatchChangePageTitle: value => dispatch(changePageTitle(value)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ApprovedRedirect),
);
