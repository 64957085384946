import * as actionTypes from './actionTypes';
import ThemeEnum from '../../assets/js/ThemeEnum';
import { getTheme } from '../../config/settings';

const defaultTheme = getTheme();

export const changeValue = (key, value) => ({
  type: actionTypes.CHANGE_THEME,
  key,
  value,
});

export const changeTheme = theme => dispatch => {
  dispatch(changeValue('theme', theme));
};

export const isThemeRheem = theme => {
  return theme === ThemeEnum.RHEEM.value;
};

export const isThemeMicrof = theme => {
  return theme === ThemeEnum.MICROF.value;
};

export const isThemeOptimus = theme => {
  return theme === ThemeEnum.OPTIMUS.value;
};

export const setDefaultTheme = currentTheme => dispatch => {
  if (currentTheme !== defaultTheme) {
    dispatch(changeTheme(defaultTheme));
  }
};
