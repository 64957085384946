import React from 'react';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import DashboardPage from './Dashboard/index';
import OpenApplicationsPage from './OpenApplications/Index';
import ApplicationsUnderReviewPage from './ApplicationsUnderReview/Index';
import UnsubmittedApplicationsPage from './UnsubmittedApplications/Index';
import ConsumerApplicationsPage from './ConsumerApplications/Index';
import WebServiceApplicationsPage from './WebServiceApplications/Index';
import TransactionHistory from './TransactionHistory/Index';
import SingleTransaction from './SingleTransaction/Index';
import SubmitRefund from './SubmitRefund/Index';
import ApplicationHistory from './ApplicationHistory/Index';
import OpenApplicationDetail from './OpenApplications/ApplicationDetail';
import ConsumerApplicationDetail from './ConsumerApplications/ApplicationDetail';
import UnsubmittedApplicationDetail from './UnsubmittedApplications/ApplicationDetail';
import UnderReviewApplicationDetail from './ApplicationsUnderReview/ApplicationDetail';
import TransactionAuthorizationRequest from './TransactionAuthorizationRequest/Index';
import WebServiceApplicationDetail from './WebServiceApplications/ApplicationDetail';
import PrequalificationInformation from './PrequalificationInformation';
import DeclinedConsumerApplication from './DeclinedConsumerApplication';
import NewContractorUser from './NewContractorUser';
import ManageContractorUser from './NewContractorUser/ManageContractorUser';
import InstallationCertification from './OpenApplications/InstallationCertification';
import SearchContractorUsers from './SearchContractorUsers';
import GreenskyApiCredentials from './GreenskyApiCredentials';
import ManageContractorUsers from './ManageContractorUsers';
import ViewContractorUser from './ManageContractorUsers/ViewContractorUser';
import MoveAppToContractor from './MoveAppToContractor';
import SearchApplication from './SearchApplication';
import AssignApplication from './SearchApplication/AssignApplication';
import FindAContractor from './FindAContractor';
import ManageContractorStates from './ManageContractorStates';
import EditContractorStates from './ManageContractorStates/EditContractorStates';
import UploadedDocuments from './UploadedDocuments';
import NewInternalUser from './NewInternalUser';
import ManageInternalUser from './ManageInternalUser';
import ViewEditUserInternalUser from './ManageInternalUser/ViewEditUser';
import ManageContractorZipCodes from './ManageContractorZipCodes';
import EditContractorZipCodes from './ManageContractorZipCodes/EditContractorZipCodes';
import AddUsersToRojos from './AddUsersToRojos';
import OriginationExpertSendData from './OriginationExpertSendData';
import RestrictAquaPromotions from './RestrictAquaPromotions';
import EditRestrictAquaPromotions from './RestrictAquaPromotions/EditRestrictAquaPromotions';
import NewApplicationPage from './NewApplication';
import NextSteps from './NextSteps';
import NewLender from './NewLender';
import DealerCandidate from './DealerCandidate';
import CompleteFundOpenMicrofApplication from './OpenApplications/CompleteFundOpenMicrofApplication';
import CompleteFundOpenRheemApplication from './OpenApplications/CompleteFundOpenRheemApplication';
import FundingSubmitted from './OpenApplications/FundingSubmitted';
import FinancingOption from './FinancingOption';
import Resources from './Resources';
import UpdateMerchantNumber from './UpdateMerchantNumber';
import UpdateDealer from './UpdateMerchantNumber/UpdateDealer';
import ManagePrimaryUsers from './ManagePrimaryUsers';
import EditPrimaryUsers from './ManagePrimaryUsers/EditPrimaryUsers';
import ResendApplicationToDecisionEngine from './ResendApplicationToDecisionEngine';
import DeactivatedDealer from './DeactivatedDealer';

const PortalPages = () => (
  <>
    <Route path="/portal/dashboard" component={DashboardPage} />
    <Route path="/portal/new-application" component={NewApplicationPage} />
    <Route path="/portal/open-applications" component={OpenApplicationsPage} exact />
    <Route path="/portal/open-applications/details" component={OpenApplicationDetail} exact />
    <Route
      path="/portal/open-applications/complete-fund-microf-application"
      component={CompleteFundOpenMicrofApplication}
      exact
    />
    <Route
      path="/portal/open-applications/complete-fund-rheem-application"
      component={CompleteFundOpenRheemApplication}
      exact
    />
    <Route
      path="/portal/open-applications/installation-certification"
      component={InstallationCertification}
      exact
    />
    <Route
      path="/portal/open-applications/funding-has-been-submitted"
      component={FundingSubmitted}
      exact
    />
    <Route path="/portal/applications-under-review" component={ApplicationsUnderReviewPage} exact />
    <Route
      path="/portal/applications-under-review/details"
      component={UnderReviewApplicationDetail}
      exact
    />
    <Route
      path="/portal/transaction-authorization-request"
      component={TransactionAuthorizationRequest}
      exact
    />
    <Route path="/portal/unsubmitted-leases" component={UnsubmittedApplicationsPage} exact />
    <Route
      path="/portal/unsubmitted-leases/details"
      component={UnsubmittedApplicationDetail}
      exact
    />
    <Route path="/portal/consumer-applications" component={ConsumerApplicationsPage} exact />
    <Route
      path="/portal/consumer-applications/details"
      component={ConsumerApplicationDetail}
      exact
    />
    <Route path="/portal/web-service-applications" component={WebServiceApplicationsPage} exact />
    <Route
      path="/portal/web-service-applications/details"
      component={WebServiceApplicationDetail}
      exact
    />
    <Route path="/portal/greensky-transaction-history" component={TransactionHistory} />
    <Route path="/portal/single-transaction" component={SingleTransaction} />
    <Route path="/portal/submit-refund" component={SubmitRefund} />
    <Route path="/portal/application-history" component={ApplicationHistory} />
    <Route
      path="/portal/prequalification-information"
      component={PrequalificationInformation}
      exact
    />
    <Route
      path="/portal/declined-consumer-applications"
      component={DeclinedConsumerApplication}
      exact
    />
    <Route path="/portal/create-contractor-user" component={NewContractorUser} exact />
    <Route path="/portal/manage-contractor-user" component={ManageContractorUser} exact />
    <Route path="/portal/move-app-to-contractor" component={MoveAppToContractor} exact />
    <Route path="/portal/greensky-api-credentials" component={GreenskyApiCredentials} exact />
    <Route path="/portal/search-users" component={SearchContractorUsers} exact />
    <Route path="/portal/manage-contractor-users" component={ManageContractorUsers} exact />
    <Route
      path="/portal/manage-contractor-users/view-contractor-user"
      component={ViewContractorUser}
      exact
    />
    <Route path="/portal/search-application" component={SearchApplication} exact />
    <Route
      path="/portal/search-application/assign-application"
      component={AssignApplication}
      exact
    />
    <Route path="/portal/find-a-contractor" component={FindAContractor} exact />
    <Route path="/portal/manage-contractor-states" component={ManageContractorStates} exact />
    <Route path="/portal/manage-contractor-zipcodes" component={ManageContractorZipCodes} exact />
    <Route path="/portal/manage-primary-users" component={ManagePrimaryUsers} exact />
    <Route path="/portal/edit-contractor-states" component={EditContractorStates} exact />
    <Route path="/portal/edit-contractor-zipcodes" component={EditContractorZipCodes} exact />
    <Route path="/portal/edit-primary-users" component={EditPrimaryUsers} exact />
    <Route path="/portal/uploaded-documents" component={UploadedDocuments} exact />
    <Route path="/portal/create-internal-user" component={NewInternalUser} exact />
    <Route path="/portal/manage-internal-users" component={ManageInternalUser} exact />
    <Route path="/portal/add-users-to-rojos" component={AddUsersToRojos} exact />
    <Route
      path="/portal/manage-internal-users/view-internal-user"
      component={ViewEditUserInternalUser}
      exact
    />
    <Route
      path="/portal/origination-expert-send-data"
      component={OriginationExpertSendData}
      exact
    />
    <Route path="/portal/restrict-aqua-promotions" component={RestrictAquaPromotions} exact />
    <Route
      path="/portal/resend-application-to-decision-engine"
      component={ResendApplicationToDecisionEngine}
      exact
    />

    <Route
      path="/portal/restrict-aqua-promotions/edit-restrict-aqua-promotions"
      component={EditRestrictAquaPromotions}
      exact
    />
    <Route path="/portal/consumer-dashboard" component={NextSteps} exact />
    <Route path="/portal/financing-option" component={FinancingOption} exact />
    <Route path="/portal/add-new-lender" component={NewLender} exact />
    <Route path="/portal/candidate-contractor-dashboard" component={DealerCandidate} exact />
    <Route path="/portal/resources" component={Resources} exact />
    <Route path="/portal/update-merchant-number" component={UpdateMerchantNumber} exact />
    <Route path="/portal/update-merchant-number/update-dealer" component={UpdateDealer} exact />
    <Route path="/portal/deactivated-dealer" component={DeactivatedDealer} exact />
  </>
);

export default withRouter(PortalPages);
