import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import configureStore from '../redux/store';
import Router from './Router';

import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

const App = () => (
  <Provider store={configureStore()}>
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  </Provider>
);

export default App;
