import { useEffect } from 'react';
import { getIsMicrof, getVitalStormTagId } from '../../config/settings';

export const useVSPixelScript = () => {
  const isMicrof = getIsMicrof();
  useEffect(() => {
    if (isMicrof) {
      const script = document.createElement('script');

      script.async = true;
      script.innerHTML = `cntrUpTag.track('cntrData', '${getVitalStormTagId()}');`;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [isMicrof]);
};
