import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Field, Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Col, Container, Row, Alert } from 'reactstrap';
import CustomInput from '../../components/UI/Form/Input/CustomInput';
import Button from '../../components/UI/Button/CustomButton';
import CustomPhoneNumber from '../../components/UI/Form/PhoneNumber/PhoneNumber';
import BackBtn from '../../components/UI/BackButton/BackButton';
import Select from '../../components/UI/Form/Select/Select';
import {
  changePage,
  changeContractorEnrollmentValue,
  submitMindfire,
  endLoading,
  applyToBeDealer,
} from '../../redux/actions/index';
import usStates from '../../assets/js/USStateEnum';
import { withRouter } from 'react-router-dom';
import { ContractorEnrollmentFlowPageNumber } from './_Flow';
import {
  BENEFICIAL_OWNER_CITY_TOOLTIP_TEXT,
  BENEFICIAL_OWNER_EMAIL_TOOLTIP_TEXT,
  BENEFICIAL_OWNER_FIRSTNAME_TOOLTIP_TEXT,
  BENEFICIAL_OWNER_LASTNAME_TOOLTIP_TEXT,
  BENEFICIAL_OWNER_OWNERSHIP_TOOLTIP_TEXT,
  BENEFICIAL_OWNER_PHONENUMBER_TOOLTIP_TEXT,
  BENEFICIAL_OWNER_SSN_TOOLTIP_TEXT,
  BENEFICIAL_OWNER_STATE_TOOLTIP_TEXT,
  BENEFICIAL_OWNER_STREET_TOOLTIP_TEXT,
  BENEFICIAL_OWNER_ZIP_CODE_TOOLTIP_TEXT,
} from '../../constants/tooltipTexts';
import { isNoreplyEmail } from '../../helper';

const BeneficialOwnerInformation = props => {
  const [alertMessage, setAlertMessage] = useState(null);

  const {
    ce,
    dispatchChangeValue,
    dispatchChangePage,
    children,
    dispatchApplyToBeDealer,
    history,
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  const BeneficialOwnerInformationForm = ({ values, errors, touched }) => (
    <Form autoComplete="off">
      {alertMessage ? <Alert color="danger">{alertMessage}</Alert> : null}
      <h3>Beneficial Owner {ce.businessManagerOwnership === 'No' ? '' : '1 '}Information</h3>
      <Field
        component={CustomInput}
        label="Last Name *"
        name="beneficialOwner1LastName"
        id="beneficialOwner1LastName"
        tooltipText={BENEFICIAL_OWNER_LASTNAME_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={CustomInput}
        label="First Name *"
        name="beneficialOwner1FirstName"
        id="beneficialOwner1FirstName"
        tooltipText={BENEFICIAL_OWNER_FIRSTNAME_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={CustomPhoneNumber}
        label="Phone *"
        name="beneficialOwner1Phone"
        id="beneficialOwner1Phone"
        tooltipText={BENEFICIAL_OWNER_PHONENUMBER_TOOLTIP_TEXT}
      />
      <Field
        component={CustomInput}
        label="Email *"
        name="beneficialOwner1Email"
        id="beneficialOwner1Email"
        tooltipText={BENEFICIAL_OWNER_EMAIL_TOOLTIP_TEXT}
        type="email"
      />
      <Field
        component={CustomInput}
        label="Street Address (Home) *"
        name="beneficialOwner1Street"
        id="beneficialOwner1Street"
        tooltipText={BENEFICIAL_OWNER_STREET_TOOLTIP_TEXT}
        type="text"
      />
      <Row>
        <Col sm="6">
          <Field
            component={CustomInput}
            label="City (Home) *"
            name="beneficialOwner1City"
            id="beneficialOwner1City"
            tooltipText={BENEFICIAL_OWNER_CITY_TOOLTIP_TEXT}
            type="text"
          />
        </Col>
        <Col sm="6">
          <Field
            component={CustomInput}
            label="ZIP Code (Home) *"
            name="beneficialOwner1ZIPCode"
            id="beneficialOwner1ZIPCode"
            tooltipText={BENEFICIAL_OWNER_ZIP_CODE_TOOLTIP_TEXT}
            type="text"
            maxLength={10}
          />
        </Col>
      </Row>
      <Field
        component={Select}
        label="State (Home) *"
        name="beneficialOwner1State"
        id="beneficialOwner1State"
        tooltipText={BENEFICIAL_OWNER_STATE_TOOLTIP_TEXT}
        type="select"
        selectValues={usStates}
        selectFirstName="Select State"
      />
      <Field
        maxLength={9}
        component={CustomInput}
        label="SSN *"
        id="beneficialOwner1SSN"
        name="beneficialOwner1SSN"
        tooltipText={BENEFICIAL_OWNER_SSN_TOOLTIP_TEXT}
        type="password"
        number
        icon="lock"
      />
      <Field
        component={CustomInput}
        label="% Ownership *"
        name="beneficialOwner1Ownership"
        id="beneficialOwner1Ownership"
        tooltipText={BENEFICIAL_OWNER_OWNERSHIP_TOOLTIP_TEXT}
        type="number"
      />

      {ce.businessManagerOwnership === 'No' ? null : (
        <>
          <h3 className="top-20">Beneficial Owner 2 Information</h3>
          <Field
            component={CustomInput}
            label="Last Name *"
            name="beneficialOwner2LastName"
            id="beneficialOwner2LastName"
            tooltipText={BENEFICIAL_OWNER_LASTNAME_TOOLTIP_TEXT}
            type="text"
          />
          <Field
            component={CustomInput}
            label="First Name *"
            name="beneficialOwner2FirstName"
            id="beneficialOwner2FirstName"
            tooltipText={BENEFICIAL_OWNER_FIRSTNAME_TOOLTIP_TEXT}
            type="text"
          />
          <Field
            component={CustomPhoneNumber}
            label="Phone *"
            name="beneficialOwner2Phone"
            id="beneficialOwner2Phone"
            tooltipText={BENEFICIAL_OWNER_PHONENUMBER_TOOLTIP_TEXT}
          />
          <Field
            component={CustomInput}
            label="Email *"
            name="beneficialOwner2Email"
            id="beneficialOwner2Email"
            tooltipText={BENEFICIAL_OWNER_EMAIL_TOOLTIP_TEXT}
            type="email"
          />
          <Field
            component={CustomInput}
            label="Street Address (Home) *"
            name="beneficialOwner2Street"
            id="beneficialOwner2Street"
            tooltipText={BENEFICIAL_OWNER_STREET_TOOLTIP_TEXT}
            type="text"
          />
          <Row>
            <Col sm="6">
              <Field
                component={CustomInput}
                label="City (Home) *"
                name="beneficialOwner2City"
                id="beneficialOwner2City"
                tooltipText={BENEFICIAL_OWNER_CITY_TOOLTIP_TEXT}
                type="text"
              />
            </Col>
            <Col sm="6">
              <Field
                component={CustomInput}
                label="ZIP Code (Home) *"
                name="beneficialOwner2ZIPCode"
                id="beneficialOwner2ZIPCode"
                tooltipText={BENEFICIAL_OWNER_ZIP_CODE_TOOLTIP_TEXT}
                type="text"
                maxLength={10}
              />
            </Col>
          </Row>
          <Field
            component={Select}
            label="State (Home) *"
            name="beneficialOwner2State"
            id="beneficialOwner2State"
            tooltipText={BENEFICIAL_OWNER_STATE_TOOLTIP_TEXT}
            type="select"
            selectValues={usStates}
            selectFirstName="Select State"
          />
          <Field
            maxLength={9}
            component={CustomInput}
            label="SSN *"
            id="beneficialOwner2SSN"
            name="beneficialOwner2SSN"
            tooltipText={BENEFICIAL_OWNER_SSN_TOOLTIP_TEXT}
            type="password"
            number
            icon="lock"
          />
          <Field
            component={CustomInput}
            label="% Ownership *"
            name="beneficialOwner2Ownership"
            id="beneficialOwner2Ownership"
            tooltipText={BENEFICIAL_OWNER_OWNERSHIP_TOOLTIP_TEXT}
            type="number"
          />

          <h3 className="top-20">Beneficial Owner 3 Information</h3>
          <Field
            component={CustomInput}
            label="Last Name *"
            name="beneficialOwner3LastName"
            id="beneficialOwner3LastName"
            tooltipText={BENEFICIAL_OWNER_LASTNAME_TOOLTIP_TEXT}
            type="text"
          />
          <Field
            component={CustomInput}
            label="First Name *"
            name="beneficialOwner3FirstName"
            id="beneficialOwner3FirstName"
            tooltipText={BENEFICIAL_OWNER_FIRSTNAME_TOOLTIP_TEXT}
            type="text"
          />
          <Field
            component={CustomPhoneNumber}
            label="Phone *"
            name="beneficialOwner3Phone"
            id="beneficialOwner3Phone"
            tooltipText={BENEFICIAL_OWNER_PHONENUMBER_TOOLTIP_TEXT}
          />
          <Field
            component={CustomInput}
            label="Email *"
            name="beneficialOwner3Email"
            id="beneficialOwner3Email"
            tooltipText={BENEFICIAL_OWNER_EMAIL_TOOLTIP_TEXT}
            type="email"
          />
          <Field
            component={CustomInput}
            label="Street Address (Home) *"
            name="beneficialOwner3Street"
            id="beneficialOwner3Street"
            tooltipText={BENEFICIAL_OWNER_STREET_TOOLTIP_TEXT}
            type="text"
          />
          <Row>
            <Col sm="6">
              <Field
                component={CustomInput}
                label="City (Home) *"
                name="beneficialOwner3City"
                id="beneficialOwner3City"
                tooltipText={BENEFICIAL_OWNER_CITY_TOOLTIP_TEXT}
                type="text"
              />
            </Col>
            <Col sm="6">
              <Field
                component={CustomInput}
                label="ZIP Code (Home) *"
                name="beneficialOwner3ZIPCode"
                id="beneficialOwner3ZIPCode"
                tooltipText={BENEFICIAL_OWNER_ZIP_CODE_TOOLTIP_TEXT}
                type="text"
                maxLength={10}
              />
            </Col>
          </Row>
          <Field
            component={Select}
            label="State (Home) *"
            name="beneficialOwner3State"
            id="beneficialOwner3State"
            tooltipText={BENEFICIAL_OWNER_STATE_TOOLTIP_TEXT}
            type="select"
            selectValues={usStates}
            selectFirstName="Select State"
          />
          <Field
            maxLength={9}
            component={CustomInput}
            label="SSN *"
            id="beneficialOwner3SSN"
            name="beneficialOwner3SSN"
            tooltipText={BENEFICIAL_OWNER_SSN_TOOLTIP_TEXT}
            type="password"
            number
            icon="lock"
          />
          <Field
            component={CustomInput}
            label="% Ownership *"
            name="beneficialOwner3Ownership"
            id="beneficialOwner3Ownership"
            tooltipText={BENEFICIAL_OWNER_OWNERSHIP_TOOLTIP_TEXT}
            type="number"
          />

          <h3 className="top-20">Beneficial Owner 4 Information</h3>
          <Field
            component={CustomInput}
            label="Last Name *"
            name="beneficialOwner4LastName"
            id="beneficialOwner4LastName"
            tooltipText={BENEFICIAL_OWNER_LASTNAME_TOOLTIP_TEXT}
            type="text"
          />
          <Field
            component={CustomInput}
            label="First Name *"
            name="beneficialOwner4FirstName"
            id="beneficialOwner4FirstName"
            tooltipText={BENEFICIAL_OWNER_FIRSTNAME_TOOLTIP_TEXT}
            type="text"
          />
          <Field
            component={CustomPhoneNumber}
            label="Phone *"
            name="beneficialOwner4Phone"
            id="beneficialOwner4Phone"
            tooltipText={BENEFICIAL_OWNER_PHONENUMBER_TOOLTIP_TEXT}
          />
          <Field
            component={CustomInput}
            label="Email *"
            name="beneficialOwner4Email"
            id="beneficialOwner4Email"
            tooltipText={BENEFICIAL_OWNER_EMAIL_TOOLTIP_TEXT}
            type="email"
          />
          <Field
            component={CustomInput}
            label="Street Address (Home) *"
            name="beneficialOwner4Street"
            id="beneficialOwner4Street"
            tooltipText={BENEFICIAL_OWNER_STREET_TOOLTIP_TEXT}
            type="text"
          />
          <Row>
            <Col sm="6">
              <Field
                component={CustomInput}
                label="City (Home) *"
                name="beneficialOwner4City"
                id="beneficialOwner4City"
                tooltipText={BENEFICIAL_OWNER_CITY_TOOLTIP_TEXT}
                type="text"
              />
            </Col>
            <Col sm="6">
              <Field
                component={CustomInput}
                label="ZIP Code (Home) *"
                name="beneficialOwner4ZIPCode"
                id="beneficialOwner4ZIPCode"
                tooltipText={BENEFICIAL_OWNER_ZIP_CODE_TOOLTIP_TEXT}
                type="text"
                maxLength={10}
              />
            </Col>
          </Row>
          <Field
            component={Select}
            label="State (Home) *"
            name="beneficialOwner4State"
            id="beneficialOwner4State"
            tooltipText={BENEFICIAL_OWNER_STATE_TOOLTIP_TEXT}
            type="select"
            selectValues={usStates}
            selectFirstName="Select State"
          />
          <Field
            maxLength={9}
            component={CustomInput}
            label="SSN *"
            id="beneficialOwner4SSN"
            name="beneficialOwner4SSN"
            tooltipText={BENEFICIAL_OWNER_SSN_TOOLTIP_TEXT}
            type="password"
            number
            icon="lock"
          />
          <Field
            component={CustomInput}
            label="% Ownership *"
            name="beneficialOwner4Ownership"
            id="beneficialOwner4Ownership"
            tooltipText={BENEFICIAL_OWNER_OWNERSHIP_TOOLTIP_TEXT}
            type="number"
          />
        </>
      )}
      <Button type="submit" title="Next" />
    </Form>
  );

  const BeneficialOwnerInformationFormFormik = withFormik({
    mapPropsToValues: () => ({
      beneficialOwner1LastName: ce.beneficialOwner1LastName || '',
      beneficialOwner1FirstName: ce.beneficialOwner1FirstName || '',
      beneficialOwner1Phone: ce.beneficialOwner1Phone || '',
      beneficialOwner1Email: ce.beneficialOwner1Email || '',
      beneficialOwner1Street: ce.beneficialOwner1Street || '',
      beneficialOwner1City: ce.beneficialOwner1City || '',
      beneficialOwner1ZIPCode: ce.beneficialOwner1ZIPCode || '',
      beneficialOwner1State: ce.beneficialOwner1State || '',
      beneficialOwner1SSN: ce.beneficialOwner1SSN || '',
      beneficialOwner1Ownership: ce.beneficialOwner1Ownership || '',

      beneficialOwner2LastName: ce.beneficialOwner2LastName || '',
      beneficialOwner2FirstName: ce.beneficialOwner2FirstName || '',
      beneficialOwner2Phone: ce.beneficialOwner2Phone || '',
      beneficialOwner2Email: ce.beneficialOwner2Email || '',
      beneficialOwner2Street: ce.beneficialOwner2Street || '',
      beneficialOwner2City: ce.beneficialOwner2City || '',
      beneficialOwner2ZIPCode: ce.beneficialOwner2ZIPCode || '',
      beneficialOwner2State: ce.beneficialOwner2State || '',
      beneficialOwner2SSN: ce.beneficialOwner2SSN || '',
      beneficialOwner2Ownership: ce.beneficialOwner2Ownership || '',

      beneficialOwner3LastName: ce.beneficialOwner3LastName || '',
      beneficialOwner3FirstName: ce.beneficialOwner3FirstName || '',
      beneficialOwner3Phone: ce.beneficialOwner3Phone || '',
      beneficialOwner3Email: ce.beneficialOwner3Email || '',
      beneficialOwner3Street: ce.beneficialOwner3Street || '',
      beneficialOwner3City: ce.beneficialOwner3City || '',
      beneficialOwner3ZIPCode: ce.beneficialOwner3ZIPCode || '',
      beneficialOwner3State: ce.beneficialOwner3State || '',
      beneficialOwner3SSN: ce.beneficialOwner3SSN || '',
      beneficialOwner3Ownership: ce.beneficialOwner3Ownership || '',

      beneficialOwner4LastName: ce.beneficialOwner4LastName || '',
      beneficialOwner4FirstName: ce.beneficialOwner4FirstName || '',
      beneficialOwner4Phone: ce.beneficialOwner4Phone || '',
      beneficialOwner4Email: ce.beneficialOwner4Email || '',
      beneficialOwner4Street: ce.beneficialOwner4Street || '',
      beneficialOwner4City: ce.beneficialOwner4City || '',
      beneficialOwner4ZIPCode: ce.beneficialOwner4ZIPCode || '',
      beneficialOwner4State: ce.beneficialOwner4State || '',
      beneficialOwner4SSN: ce.beneficialOwner4SSN || '',
      beneficialOwner4Ownership: ce.beneficialOwner4Ownership || '',
    }),

    validationSchema:
      ce.businessManagerOwnership === 'No'
        ? Yup.object({
            beneficialOwner1LastName: Yup.string()
              .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
              .required('Please enter Last Name.'),
            beneficialOwner1FirstName: Yup.string()
              .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
              .required('Please enter First Name.'),
            beneficialOwner1Phone: Yup.string()
              .test('required', 'Please enter Phone.', val => val !== '+' && val)
              .test(
                'len',
                'Invalid Phone number.',
                val => val && val.match(/\d/g).join('').length === 10,
              ),
            beneficialOwner1Email: Yup.string()
              .email('Please enter a valid Email.')
              .required('Please enter an Email.')
              .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),
            beneficialOwner1Street: Yup.string().required('Please enter a Street.'),
            beneficialOwner1City: Yup.string().required('Please enter a City.'),
            beneficialOwner1ZIPCode: Yup.string()
              .matches(/^\d{5}?$/, {
                message: 'Invalid ZIP Code.',
                excludeEmptyString: true,
              })
              .required('Please enter a Property ZIP Code.'),
            beneficialOwner1State: Yup.string().required('Please select a State.'),
            beneficialOwner1SSN: Yup.string()
              .required('Please enter the full SSN.')
              .test('len', 'Must have 9 digits', val => val && val.toString().length === 9)
              .typeError('Only numbers allowed.'),
            beneficialOwner1Ownership: Yup.number()
              .moreThan(-1, 'Please enter a positive number.')
              .integer('Please enter an integer number.')
              .max(100, 'Must be less than or equal to 100.')
              .required('Please enter Ownership %.'),
          })
        : Yup.object({
            beneficialOwner1LastName: Yup.string()
              .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
              .required('Please enter Last Name.'),
            beneficialOwner1FirstName: Yup.string()
              .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
              .required('Please enter First Name.'),
            beneficialOwner1Phone: Yup.string()
              .test('required', 'Please enter Phone.', val => val !== '+' && val)
              .test(
                'len',
                'Invalid Phone number.',
                val => val && val.match(/\d/g).join('').length === 10,
              ),
            beneficialOwner1Email: Yup.string()
              .email('Please enter a valid Email.')
              .required('Please enter an Email.')
              .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),
            beneficialOwner1Street: Yup.string().required('Please enter a Street.'),
            beneficialOwner1City: Yup.string().required('Please enter a City.'),
            beneficialOwner1ZIPCode: Yup.string()
              .matches(/^\d{5}?$/, {
                message: 'Invalid ZIP Code.',
                excludeEmptyString: true,
              })
              .required('Please enter a Property ZIP Code.'),
            beneficialOwner1State: Yup.string().required('Please select a State.'),
            beneficialOwner1SSN: Yup.string()
              .required('Please enter the full SSN.')
              .test('len', 'Must have 9 digits', val => val && val.toString().length === 9)
              .typeError('Only numbers allowed.'),
            beneficialOwner1Ownership: Yup.number()
              .moreThan(-1, 'Please enter a positive number.')
              .integer('Please enter an integer number.')
              .max(100, 'Must be less than or equal to 100.')
              .min(25, 'Must be more than or equal to 25.')
              .required('Please enter Ownership %.'),

            beneficialOwner2LastName: Yup.string()
              .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
              .when('beneficialOwner2Ownership', {
                is: value => value,
                then: Yup.string().required('Please enter Last Name.'),
                otherwise: Yup.string().notRequired(),
              }),
            beneficialOwner2FirstName: Yup.string()
              .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
              .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
              .when('beneficialOwner2Ownership', {
                is: value => value,
                then: Yup.string().required('Please enter First Name.'),
                otherwise: Yup.string().notRequired(),
              }),
            beneficialOwner2Phone: Yup.string().when('beneficialOwner2Ownership', {
              is: value => value,
              then: Yup.string()
                .test('required', 'Please enter Phone.', val => val !== '+' && val)
                .test(
                  'len',
                  'Invalid Phone number.',
                  val => val && val.match(/\d/g).join('').length === 10,
                ),
              otherwise: Yup.string().notRequired(),
            }),
            beneficialOwner2Email: Yup.string().when('beneficialOwner2Ownership', {
              is: value => value,
              then: Yup.string()
                .email('Please enter a valid Email.')
                .required('Please enter an Email.')
                .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),
              otherwise: Yup.string().notRequired(),
            }),
            beneficialOwner2Street: Yup.string().when('beneficialOwner2Ownership', {
              is: value => value,
              then: Yup.string().required('Please enter a Street.'),
              otherwise: Yup.string().notRequired(),
            }),
            beneficialOwner2City: Yup.string().when('beneficialOwner2Ownership', {
              is: value => value,
              then: Yup.string().required('Please enter a City.'),
              otherwise: Yup.string().notRequired(),
            }),
            beneficialOwner2ZIPCode: Yup.string().when('beneficialOwner2Ownership', {
              is: value => value,
              then: Yup.string()
                .matches(/^\d{5}?$/, {
                  message: 'Invalid ZIP Code.',
                  excludeEmptyString: true,
                })
                .required('Please enter a Property ZIP Code.'),
              otherwise: Yup.string().notRequired(),
            }),
            beneficialOwner2State: Yup.string().when('beneficialOwner2Ownership', {
              is: value => value,
              then: Yup.string().required('Please select a State.'),
              otherwise: Yup.string().notRequired(),
            }),
            beneficialOwner2SSN: Yup.string().when('beneficialOwner2Ownership', {
              is: value => value,
              then: Yup.string()
                .required('Please enter the full SSN.')
                .test('len', 'Must have 9 digits', val => val && val.toString().length === 9)
                .typeError('Only numbers allowed.'),
              otherwise: Yup.string().notRequired(),
            }),
            beneficialOwner2Ownership: Yup.number()
              .moreThan(-1, 'Please enter a positive number.')
              .integer('Please enter an integer number.')
              .min(25, 'Must be more than or equal to 25.')
              .max(100, 'Must be less than or equal to 100.'),

            beneficialOwner3LastName: Yup.string()
              .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
              .when('beneficialOwner3Ownership', {
                is: value => value,
                then: Yup.string().required('Please enter Last Name.'),
                otherwise: Yup.string().notRequired(),
              }),
            beneficialOwner3FirstName: Yup.string()
              .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
              .when('beneficialOwner3Ownership', {
                is: value => value,
                then: Yup.string().required('Please enter First Name.'),
                otherwise: Yup.string().notRequired(),
              }),
            beneficialOwner3Phone: Yup.string().when('beneficialOwner3Ownership', {
              is: value => value,
              then: Yup.string()
                .test('required', 'Please enter Phone.', val => val !== '+' && val)
                .test(
                  'len',
                  'Invalid Phone number.',
                  val => val && val.match(/\d/g).join('').length === 10,
                ),
              otherwise: Yup.string().notRequired(),
            }),
            beneficialOwner3Email: Yup.string().when('beneficialOwner3Ownership', {
              is: value => value,
              then: Yup.string()
                .email('Please enter a valid Email.')
                .required('Please enter an Email.')
                .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),
              otherwise: Yup.string().notRequired(),
            }),
            beneficialOwner3Street: Yup.string().when('beneficialOwner3Ownership', {
              is: value => value,
              then: Yup.string().required('Please enter a Street.'),
              otherwise: Yup.string().notRequired(),
            }),
            beneficialOwner3City: Yup.string().when('beneficialOwner3Ownership', {
              is: value => value,
              then: Yup.string().required('Please enter a City.'),
              otherwise: Yup.string().notRequired(),
            }),
            beneficialOwner3ZIPCode: Yup.string().when('beneficialOwner3Ownership', {
              is: value => value,
              then: Yup.string()
                .matches(/^\d{5}?$/, {
                  message: 'Invalid ZIP Code.',
                  excludeEmptyString: true,
                })
                .required('Please enter a Property ZIP Code.'),
              otherwise: Yup.string().notRequired(),
            }),
            beneficialOwner3State: Yup.string().when('beneficialOwner3Ownership', {
              is: value => value,
              then: Yup.string().required('Please select a State.'),
              otherwise: Yup.string().notRequired(),
            }),
            beneficialOwner3SSN: Yup.string().when('beneficialOwner3Ownership', {
              is: value => value,
              then: Yup.string()
                .required('Please enter the full SSN.')
                .test('len', 'Must have 9 digits', val => val && val.toString().length === 9)
                .typeError('Only numbers allowed.'),
              otherwise: Yup.string().notRequired(),
            }),
            beneficialOwner3Ownership: Yup.number()
              .moreThan(-1, 'Please enter a positive number.')
              .integer('Please enter an integer number.')
              .min(25, 'Must be more than or equal to 25.')
              .max(100, 'Must be less than or equal to 100.'),

            beneficialOwner4LastName: Yup.string()
              .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
              .when('beneficialOwner4Ownership', {
                is: value => value,
                then: Yup.string().required('Please enter Last Name.'),
                otherwise: Yup.string().notRequired(),
              }),
            beneficialOwner4FirstName: Yup.string()
              .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
              .when('beneficialOwner4Ownership', {
                is: value => value,
                then: Yup.string().required('Please enter First Name.'),
                otherwise: Yup.string().notRequired(),
              }),
            beneficialOwner4Phone: Yup.string().when('beneficialOwner4Ownership', {
              is: value => value,
              then: Yup.string()
                .test('required', 'Please enter Phone.', val => val !== '+' && val)
                .test(
                  'len',
                  'Invalid Phone number.',
                  val => val && val.match(/\d/g).join('').length === 10,
                ),
              otherwise: Yup.string().notRequired(),
            }),
            beneficialOwner4Email: Yup.string().when('beneficialOwner4Ownership', {
              is: value => value,
              then: Yup.string()
                .email('Please enter a valid Email.')
                .required('Please enter an Email.')
                .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),
              otherwise: Yup.string().notRequired(),
            }),
            beneficialOwner4Street: Yup.string().when('beneficialOwner4Ownership', {
              is: value => value,
              then: Yup.string().required('Please enter a Street.'),
              otherwise: Yup.string().notRequired(),
            }),
            beneficialOwner4City: Yup.string().when('beneficialOwner4Ownership', {
              is: value => value,
              then: Yup.string().required('Please enter a City.'),
              otherwise: Yup.string().notRequired(),
            }),
            beneficialOwner4ZIPCode: Yup.string().when('beneficialOwner4Ownership', {
              is: value => value,
              then: Yup.string()
                .matches(/^\d{5}?$/, {
                  message: 'Invalid ZIP Code.',
                  excludeEmptyString: true,
                })
                .required('Please enter a Property ZIP Code.'),
              otherwise: Yup.string().notRequired(),
            }),
            beneficialOwner4State: Yup.string().when('beneficialOwner4Ownership', {
              is: value => value,
              then: Yup.string().required('Please select a State.'),
              otherwise: Yup.string().notRequired(),
            }),
            beneficialOwner4SSN: Yup.string().when('beneficialOwner4Ownership', {
              is: value => value,
              then: Yup.string()
                .required('Please enter the full SSN.')
                .test('len', 'Must have 9 digits', val => val && val.toString().length === 9)
                .typeError('Only numbers allowed.'),
              otherwise: Yup.string().notRequired(),
            }),
            beneficialOwner4Ownership: Yup.number()
              .moreThan(-1, 'Please enter a positive number.')
              .integer('Please enter an integer number.')
              .min(25, 'Must be more than or equal to 25.')
              .max(100, 'Must be less than or equal to 100.'),
          }),

    handleSubmit: values => {
      dispatchChangeValue('beneficialOwner1LastName', values.beneficialOwner1LastName);
      dispatchChangeValue('beneficialOwner1FirstName', values.beneficialOwner1FirstName);
      dispatchChangeValue('beneficialOwner1Phone', values.beneficialOwner1Phone);
      dispatchChangeValue('beneficialOwner1Email', values.beneficialOwner1Email);
      dispatchChangeValue('beneficialOwner1Street', values.beneficialOwner1Street);
      dispatchChangeValue('beneficialOwner1City', values.beneficialOwner1City);
      dispatchChangeValue('beneficialOwner1ZIPCode', values.beneficialOwner1ZIPCode);
      dispatchChangeValue('beneficialOwner1State', values.beneficialOwner1State);
      dispatchChangeValue('beneficialOwner1SSN', values.beneficialOwner1SSN);
      dispatchChangeValue('beneficialOwner1Ownership', values.beneficialOwner1Ownership);

      if (ce.businessManagerOwnership === 'No') {
        dispatchChangeValue('beneficialOwner2LastName', null);
        dispatchChangeValue('beneficialOwner2FirstName', null);
        dispatchChangeValue('beneficialOwner2Phone', null);
        dispatchChangeValue('beneficialOwner2Email', null);
        dispatchChangeValue('beneficialOwner2Street', null);
        dispatchChangeValue('beneficialOwner2City', null);
        dispatchChangeValue('beneficialOwner2ZIPCode', null);
        dispatchChangeValue('beneficialOwner2State', null);
        dispatchChangeValue('beneficialOwner2SSN', null);
        dispatchChangeValue('beneficialOwner2Ownership', null);

        dispatchChangeValue('beneficialOwner3LastName', null);
        dispatchChangeValue('beneficialOwner3FirstName', null);
        dispatchChangeValue('beneficialOwner3Phone', null);
        dispatchChangeValue('beneficialOwner3Email', null);
        dispatchChangeValue('beneficialOwner3Street', null);
        dispatchChangeValue('beneficialOwner3City', null);
        dispatchChangeValue('beneficialOwner3ZIPCode', null);
        dispatchChangeValue('beneficialOwner3State', null);
        dispatchChangeValue('beneficialOwner3SSN', null);
        dispatchChangeValue('beneficialOwner3Ownership', null);

        dispatchChangeValue('beneficialOwner4LastName', null);
        dispatchChangeValue('beneficialOwner4FirstName', null);
        dispatchChangeValue('beneficialOwner4Phone', null);
        dispatchChangeValue('beneficialOwner4Email', null);
        dispatchChangeValue('beneficialOwner4Street', null);
        dispatchChangeValue('beneficialOwner4City', null);
        dispatchChangeValue('beneficialOwner4ZIPCode', null);
        dispatchChangeValue('beneficialOwner4State', null);
        dispatchChangeValue('beneficialOwner4SSN', null);
        dispatchChangeValue('beneficialOwner4Ownership', null);
      } else {
        if (values.beneficialOwner2Ownership) {
          dispatchChangeValue('beneficialOwner2LastName', values.beneficialOwner2LastName);
          dispatchChangeValue('beneficialOwner2FirstName', values.beneficialOwner2FirstName);
          dispatchChangeValue('beneficialOwner2Phone', values.beneficialOwner2Phone);
          dispatchChangeValue('beneficialOwner2Email', values.beneficialOwner2Email);
          dispatchChangeValue('beneficialOwner2Street', values.beneficialOwner2Street);
          dispatchChangeValue('beneficialOwner2City', values.beneficialOwner2City);
          dispatchChangeValue('beneficialOwner2ZIPCode', values.beneficialOwner2ZIPCode);
          dispatchChangeValue('beneficialOwner2State', values.beneficialOwner2State);
          dispatchChangeValue('beneficialOwner2SSN', values.beneficialOwner2SSN);
          dispatchChangeValue('beneficialOwner2Ownership', values.beneficialOwner2Ownership);
        } else {
          dispatchChangeValue('beneficialOwner2LastName', null);
          dispatchChangeValue('beneficialOwner2FirstName', null);
          dispatchChangeValue('beneficialOwner2Phone', null);
          dispatchChangeValue('beneficialOwner2Email', null);
          dispatchChangeValue('beneficialOwner2Street', null);
          dispatchChangeValue('beneficialOwner2City', null);
          dispatchChangeValue('beneficialOwner2ZIPCode', null);
          dispatchChangeValue('beneficialOwner2State', null);
          dispatchChangeValue('beneficialOwner2SSN', null);
          dispatchChangeValue('beneficialOwner2Ownership', null);
        }

        if (values.beneficialOwner3Ownership) {
          dispatchChangeValue('beneficialOwner3LastName', values.beneficialOwner3LastName);
          dispatchChangeValue('beneficialOwner3FirstName', values.beneficialOwner3FirstName);
          dispatchChangeValue('beneficialOwner3Phone', values.beneficialOwner3Phone);
          dispatchChangeValue('beneficialOwner3Email', values.beneficialOwner3Email);
          dispatchChangeValue('beneficialOwner3Street', values.beneficialOwner3Street);
          dispatchChangeValue('beneficialOwner3City', values.beneficialOwner3City);
          dispatchChangeValue('beneficialOwner3ZIPCode', values.beneficialOwner3ZIPCode);
          dispatchChangeValue('beneficialOwner3State', values.beneficialOwner3State);
          dispatchChangeValue('beneficialOwner3SSN', values.beneficialOwner3SSN);
          dispatchChangeValue('beneficialOwner3Ownership', values.beneficialOwner3Ownership);
        } else {
          dispatchChangeValue('beneficialOwner3LastName', null);
          dispatchChangeValue('beneficialOwner3FirstName', null);
          dispatchChangeValue('beneficialOwner3Phone', null);
          dispatchChangeValue('beneficialOwner3Email', null);
          dispatchChangeValue('beneficialOwner3Street', null);
          dispatchChangeValue('beneficialOwner3City', null);
          dispatchChangeValue('beneficialOwner3ZIPCode', null);
          dispatchChangeValue('beneficialOwner3State', null);
          dispatchChangeValue('beneficialOwner3SSN', null);
          dispatchChangeValue('beneficialOwner3Ownership', null);
        }

        if (values.beneficialOwner4Ownership) {
          dispatchChangeValue('beneficialOwner4LastName', values.beneficialOwner4LastName);
          dispatchChangeValue('beneficialOwner4FirstName', values.beneficialOwner4FirstName);
          dispatchChangeValue('beneficialOwner4Phone', values.beneficialOwner4Phone);
          dispatchChangeValue('beneficialOwner4Email', values.beneficialOwner4Email);
          dispatchChangeValue('beneficialOwner4Street', values.beneficialOwner4Street);
          dispatchChangeValue('beneficialOwner4City', values.beneficialOwner4City);
          dispatchChangeValue('beneficialOwner4ZIPCode', values.beneficialOwner4ZIPCode);
          dispatchChangeValue('beneficialOwner4State', values.beneficialOwner4State);
          dispatchChangeValue('beneficialOwner4SSN', values.beneficialOwner4SSN);
          dispatchChangeValue('beneficialOwner4Ownership', values.beneficialOwner4Ownership);
        } else {
          dispatchChangeValue('beneficialOwner4LastName', null);
          dispatchChangeValue('beneficialOwner4FirstName', null);
          dispatchChangeValue('beneficialOwner4Phone', null);
          dispatchChangeValue('beneficialOwner4Email', null);
          dispatchChangeValue('beneficialOwner4Street', null);
          dispatchChangeValue('beneficialOwner4City', null);
          dispatchChangeValue('beneficialOwner4ZIPCode', null);
          dispatchChangeValue('beneficialOwner4State', null);
          dispatchChangeValue('beneficialOwner4SSN', null);
          dispatchChangeValue('beneficialOwner4Ownership', null);
        }
      }

      let totalOwnership = 0;
      setAlertMessage(null);
      if (values.beneficialOwner1Ownership) {
        totalOwnership += 1 * values.beneficialOwner1Ownership;
      }
      if (values.beneficialOwner2Ownership) {
        totalOwnership += 1 * values.beneficialOwner2Ownership;
      }
      if (values.beneficialOwner3Ownership) {
        totalOwnership += 1 * values.beneficialOwner3Ownership;
      }
      if (values.beneficialOwner4Ownership) {
        totalOwnership += 1 * values.beneficialOwner4Ownership;
      }
      if (totalOwnership > 100) {
        setAlertMessage('The sum of ownerships % should be lower or equals to 100%.');
        window.scrollTo(0, 0);
      } else {
        dispatchApplyToBeDealer(3, history);
      }
    },
  })(BeneficialOwnerInformationForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={ContractorEnrollmentFlowPageNumber.BusinessManager}
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <BeneficialOwnerInformationFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  ce: state.contractorEnrollment,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) => dispatch(changeContractorEnrollmentValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchEndLoading: () => dispatch(endLoading()),
  dispatchApplyToBeDealer: (page, history) => dispatch(applyToBeDealer(false, page, history)),
});

BeneficialOwnerInformation.propTypes = {
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(BeneficialOwnerInformation),
);
