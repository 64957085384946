import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { GoInfo } from 'react-icons/go';
import Application from '../../../components/UI/Application/Application';
import { getApplications } from '../../../redux/actions/index';

const OpenApplications = props => {
  const { auth, dispatchGetApplications, applications, history } = props;

  useEffect(() => {
    if (!auth.user || !auth.user.dealerUser) {
      history.push('/login');
    }
    if (auth.user && auth.user.dealerUser) {
      dispatchGetApplications(auth.user.dealerUser.userId, 'unsubmitted');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Col>
      <Row>
        <Col>
          <h1>Unsubmitted Leases</h1>
          <p>
            <GoInfo size={20} /> Click on an application for more information and the option to
            complete or cancel the application.
          </p>
        </Col>
      </Row>
      <Row>
        {applications && applications.length > 0 ? (
          Object.keys(applications).map(key => (
            <Application
              key={applications[key].id}
              value={applications[key]}
              path="/portal/unsubmitted-leases/details/"
              history={history}
              isEmulated={!!auth.user.user.emulatorId}
            />
          ))
        ) : (
          <div className="app-panel">No applications found.</div>
        )}
      </Row>
    </Col>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  applications: state.softPull.applications,
});

const mapDispatchToProps = dispatch => ({
  dispatchGetApplications: (dealerUserId, applicationType) =>
    dispatch(getApplications(dealerUserId, applicationType)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OpenApplications);
