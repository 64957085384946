import React, { useState, useEffect } from 'react';
import { Field, Form, withFormik } from 'formik';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Row, Label, Button } from 'reactstrap';
import queryString from 'query-string';
import { getDealerUser, deleteUser, updateDealerUser } from '../../../redux/actions/index';
import UserRoleEnum, { findByValue, internalUserEnum } from '../../../assets/js/UserRoleEnum';
import InputField from '../../../components/UI/Form/Input/InputField';
import SelectField from '../../../components/UI/Form/Select/SelectField';
import ConfirmDelete from '../../../components/UI/Modal/ConfirmDelete';
import * as Yup from 'yup';
import UserOperationEnum, { isOperationAuthorized } from '../../../assets/js/UserOperationEnum';
import { EMAIL_TOOLTIP_TEXT } from '../../../constants/tooltipTexts';
import { isNoreplyEmail } from '../../../helper';

const ViewEditUser = props => {
  const {
    auth,
    history,
    dispatchGetDealerUser,
    user,
    dispatchDeleteUser,
    dispatchUpdateDealerUser,
  } = props;
  const [isEdit, setEdit] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const userId = queryString.parse(window.location.search).id;

  useEffect(() => {
    if (!auth.user && (!auth.user.dealerUser || !auth.user.internalUser)) {
      history.push('/login');
    }
    if (userId) {
      dispatchGetDealerUser(userId);
    } else {
      history.push('/portal/manage-internal-users');
    }
    // eslint-disable-next-line
  }, []);

  const EditUserForm = () => (
    <Form>
      <Row>
        <Col sm="4">
          <div className="inner-content has-input">Role</div>
        </Col>
        <Col sm="4">
          <Field
            component={SelectField}
            name="role"
            id="role"
            type="select"
            selectValues={internalUserEnum}
            selectFirstName=" "
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm="4">
          <Label for="firstName">First Name</Label>
        </Col>
        <Col sm="4">
          <Field component={InputField} name="firstName" id="firstName" type="text" />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm="4">
          <Label for="lastName">Last Name</Label>
        </Col>
        <Col sm="4">
          <Field component={InputField} name="lastName" id="lastName" type="text" />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm="4">
          <div className="inner-content has-input">Email</div>
        </Col>
        <Col sm="4">
          <Field
            component={InputField}
            name="email"
            id="email"
            tooltipText={EMAIL_TOOLTIP_TEXT}
            type="text"
          />
        </Col>
      </Row>

      <Row className="mt-5">
        <Col className="text-right">
          <Button
            type="submit"
            color="secondary"
            className="rounded-pill"
            outline
            onClick={() => {
              setEdit(false);
            }}
          >
            Cancel
          </Button>

          <Button type="submit" color="primary" className="rounded-pill">
            Apply
          </Button>
        </Col>
      </Row>
    </Form>
  );

  const EditUserFormFormik = withFormik({
    mapPropsToValues: () => ({
      role: user.role,
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      email: user.email || '',
    }),

    validationSchema: Yup.object({
      role: Yup.string().required('Please select a role.'),
      lastName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .required('Please enter the users last name.'),
      firstName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .required('Please enter the users first name.'),
      email: Yup.string()
        .email('Please enter a valid email.')
        .required('Please enter an email.')
        .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),
    }),

    handleSubmit: values => {
      let userDTO = {
        id: user.id,
        lastName: values.lastName,
        firstName: values.firstName,
        email: values.email,
        role: values.role,
        lastLogin: null,
        dealer: null,
        state: null,
      };
      dispatchUpdateDealerUser(userDTO, history, '/portal/manage-internal-users?search=true');
    },
  })(EditUserForm);

  const ViewUser = () => (
    <>
      <Row>
        <Col sm="4">
          <Label>Name</Label>
        </Col>
        <Col sm="4">{(user.firstName || '') + ' ' + (user.lastName || '')}</Col>
      </Row>
      <Row className="mt-4">
        <Col sm="4">
          <Label>Role</Label>
        </Col>
        <Col sm="4">{UserRoleEnum[user.role].name}</Col>
      </Row>
      <Row className="mt-4">
        <Col sm="4">
          <Label>Email</Label>
        </Col>
        <Col sm="4">{user.email || ''}</Col>
      </Row>
      <Row className="mt-4">
        <Col sm="4">
          <Label>Last Login</Label>
        </Col>
        <Col sm="4">{user.lastLogin || 'N/A'}</Col>
      </Row>

      <Row className="mt-5">
        <Col className="text-right">
          <Button
            type="reset"
            color="secondary"
            className="rounded-pill"
            onClick={() => {
              history.push('/portal/manage-internal-users');
            }}
            outline
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="success"
            className="rounded-pill"
            onClick={() => {
              setConfirmDelete(true);
            }}
            disabled={
              !isOperationAuthorized(
                UserOperationEnum.DELETE,
                UserRoleEnum[
                  findByValue(
                    auth.user.dealerUser
                      ? auth.user.dealerUser.role
                      : auth.user.internalUser
                      ? auth.user.internalUser.role
                      : null,
                  )
                ],
                UserRoleEnum[user.role].userType,
              )
            }
          >
            Delete
          </Button>
          <Button
            type="submit"
            color="primary"
            className="rounded-pill"
            onClick={() => {
              setEdit(true);
            }}
            disabled={
              !isOperationAuthorized(
                UserOperationEnum.UPDATE,
                UserRoleEnum[
                  findByValue(
                    auth.user.dealerUser
                      ? auth.user.dealerUser.role
                      : auth.user.internalUser
                      ? auth.user.internalUser.role
                      : null,
                  )
                ],
                UserRoleEnum[user.role].userType,
              )
            }
          >
            Edit
          </Button>
        </Col>
      </Row>
    </>
  );
  return (
    <>
      <Col>
        <Row className="mt-5">
          <h1 id="panelTitle">View Internal User</h1>
          <div className="panel">
            <div className="panel-body larger">
              <div className="table-simple">{isEdit ? <EditUserFormFormik /> : <ViewUser />}</div>
            </div>
          </div>
        </Row>
      </Col>
      <ConfirmDelete
        headerText={'Delete user?'}
        bodyText={'Are you sure you want to delete this user?'}
        confirmDelete={confirmDelete}
        cancelButtonText={'No, Back to View User'}
        confirmButtonText={'Yes, Delete Internal User'}
        cancelEventHandler={() => setConfirmDelete(false)}
        confirmEventHandler={() => {
          setConfirmDelete(false);
          dispatchDeleteUser(userId, history, '/portal/manage-internal-users?search=true');
        }}
      />
    </>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  user: state.softPull.contractorUser,
});

const mapDispatchToProps = dispatch => ({
  dispatchGetDealerUser: userId => dispatch(getDealerUser(userId)),
  dispatchDeleteUser: (id, history, url) => dispatch(deleteUser(id, history, url)),
  dispatchUpdateDealerUser: (data, history, url) => dispatch(updateDealerUser(data, history, url)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ViewEditUser),
);
