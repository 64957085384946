import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import { getAddress, reactGAPageview } from '../../../helper';
import { changePage, changeMosaicInfoValue, submitMindfire } from '../../../redux/actions/index';
import { MosaicPreQualificationFlowPageNumber } from './PreQualificationFlow';

const Review = props => {
  const {
    children,
    dispatchChangePage,
    dispatchChangeMosaicInfoValue,
    pqi,
    dealerUserId,
    did,
    app,
    dispatchSubmitMindfire,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_ppconfirminfo',
      trackerNames: [],
      title: 'ca_ppconfirminfo',
      did,
      dealerUserId,
    });
    dispatchChangeMosaicInfoValue('isReview', false);
    // eslint-disable-next-line
  }, []);

  const editPage = page => {
    dispatchChangeMosaicInfoValue('isReview', true);
    dispatchChangePage(page);
  };

  const mindfireRequest = {
    email: pqi.email,
    lastPage: '6017',
    dealerUserId: dealerUserId,
    did: did,
  };

  const appAddress = getAddress({
    street: pqi.street,
    city: pqi.city,
    state: pqi.state,
    zip: pqi.zipcode,
  });

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={MosaicPreQualificationFlowPageNumber.UserAddress}
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <Row className="review">
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col xs="8" lg="9">
                  <h5>Applicant’s Information</h5>
                </Col>
                {!app ? (
                  <Col xs="4" lg="3">
                    <Button
                      className="edit"
                      clickHandler={() =>
                        editPage(MosaicPreQualificationFlowPageNumber.AccountEmail)
                      }
                      title="Edit"
                    />
                  </Col>
                ) : null}
              </Row>
              <Row>
                <Col xs="6">
                  <b>Name</b>
                  {(pqi.firstName ? pqi.firstName + ' ' : '') +
                    (pqi.middleName ? pqi.middleName + ' ' : '') +
                    (pqi.lastName || '')}
                </Col>
                <Col xs="6">
                  <b>Phone Number</b>
                  {!!pqi.phoneNumber
                    ? pqi.phoneNumber.length > 6
                      ? '(' +
                        pqi.phoneNumber.substring(0, 3) +
                        ') ' +
                        pqi.phoneNumber.substring(3, 6) +
                        '-' +
                        pqi.phoneNumber.substring(6)
                      : pqi.phoneNumber
                    : 'N/A'}
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <b>Email Address</b>
                  <span className="text-break">{pqi.email || 'N/A'}</span>
                </Col>
                <Col xs="6">
                  <b>PO Box</b>
                  {pqi.poBox || 'N/A'}
                </Col>
              </Row>
              <Row>
                <Col>
                  <b>Applicant’s Address</b>
                  {appAddress}
                </Col>
              </Row>
            </Col>
          </Row>
          <Button
            type="submit"
            title="Next"
            clickHandler={() => {
              dispatchChangePage(MosaicPreQualificationFlowPageNumber.UserLastInfo);
              dispatchSubmitMindfire(mindfireRequest);
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  pqi: state.preQualificationInformation,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeMosaicInfoValue: (key, value) => dispatch(changeMosaicInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

Review.propTypes = {
  dispatchChangePage: PropTypes.func,
  dispatchChangeMosaicInfoValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Review);
