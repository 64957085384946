import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Label, Row, Button, Form } from 'reactstrap';
import { sendDataToOriginationExpert } from '../../../redux/actions';
import InputFeedback from '../../../components/UI/Form/InputFeedback/InputFeedback';

const OriginationExpertSendData = props => {
  const { auth, history, dispatchSendDataToOriginationExpert, originationExpertLogFile } = props;

  useEffect(() => {
    if (!auth.user || !auth.user.internalUser) {
      history.push('/login');
    }
    // eslint-disable-next-line
  }, []);

  const fileField = React.createRef();
  let file = null;
  const handleChange = e => {
    file = e.currentTarget.files[0];
  };
  const handleSubmit = e => {
    e.preventDefault();
    if (!fileField.current.value) {
      setError(true);
    } else {
      setError(false);
      dispatchSendDataToOriginationExpert(file, history);
    }
    return false;
  };

  const [error, setError] = useState(null);

  const NewContractorUserForm = () => (
    <Form onSubmit={e => handleSubmit(e)}>
      <Row>
        <Col>
          <Label for="inputFile">Input File</Label>
        </Col>
      </Row>
      <Row>
        <Col>
          <input
            ref={fileField}
            type={'file'}
            onChange={e => handleChange(e)}
            className={'form-control'}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
          {error && <InputFeedback error="Please enter the Input File." />}
        </Col>
      </Row>

      {originationExpertLogFile ? (
        <Row className="mt-2">
          <Col>
            <a href={originationExpertLogFile} target="_blank" rel="noopener noreferrer">
              Download Log File
            </a>
          </Col>
        </Row>
      ) : null}
      <Row className="mt-5">
        <Col className="text-right">
          <Button
            type="submit"
            color="primary"
            className="rounded-pill"
            title="Send Data to Origination Expert"
          >
            Send Data
          </Button>
        </Col>
      </Row>
    </Form>
  );

  return (
    <Col>
      <Row>
        <Col>
          <h1>Send Data to Origination Expert</h1>
        </Col>
      </Row>
      <Row className="panel">
        <Col className="panel-body larger">
          <Row>
            <Col className="right-bordered">
              <NewContractorUserForm />
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  originationExpertLogFile: state.softPull.originationExpertLogFile,
});

const mapDispatchToProps = dispatch => ({
  dispatchSendDataToOriginationExpert: (file, history) =>
    dispatch(sendDataToOriginationExpert(file, history)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(OriginationExpertSendData),
);
