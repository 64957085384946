import React from 'react';
import NumberFormat from 'react-number-format';
import { Col, Row } from 'reactstrap';
import './PowerPayLoanOption.scss';
import { connect } from 'react-redux';

const PowerPayLoanOption = ({ name, loanOption }) => {
  return (
    <Row className="powerPayProduct">
      <Col>
        <Row className="name">
          <Col>
            <h6>{name}</h6>
          </Col>
        </Row>
        <Row className="values">
          <Col xs="4">
            <b>Loan Term</b>
            <span>{loanOption.loanTerm}</span>
          </Col>
          <Col xs="4">
            <b>Loan Rate</b>
            <NumberFormat
              value={loanOption.loanRate}
              thousandSeparator={true}
              fixedDecimalScale={true}
              decimalScale={2}
              displayType="text"
            />
          </Col>
          <Col xs="4">
            <b>Max Loan Amt</b>
            <NumberFormat
              value={loanOption.maxLoanAmt}
              thousandSeparator={true}
              fixedDecimalScale={true}
              decimalScale={2}
              displayType="text"
              prefix="$"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => ({
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  isConsumer: state.preQualificationInformation.isConsumer,
});

export default connect(mapStateToProps)(PowerPayLoanOption);
