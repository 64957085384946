import React from 'react';
import Landing from './Landing';
import Terms from './Terms';
import AccountEmail from './AccountEmail';
import LoanFor from './LoanFor';
import AccountPassword from './AccountPassword';
import LoanAmount from './LoanAmount';
import UserInfo from './UserInfo';
import UserAddress from './UserAddress';
import HasCoApplicant from './HasCoApplicant';
import CoAppAddress from './CoApplicant/CoAppAddress';
import Review from './Review';
import CoAppDetails from './CoApplicant/CoAppDetails';
import ApplicationResult from './ApplicationResult';
import CreditConnect from './CreditConnect';

const applicantInformationTitle = `Applicant Information`;
const financingOptionInformationTitle = 'Financing Option Information';
const coApplicantInformationTitle = 'Co-Applicant Information';
const applicationStatusTitle = 'Application Status';

const WellsFargoFlow = [
  //1
  <Landing title={applicantInformationTitle} />,
  //2
  <Terms title={applicantInformationTitle} />,
  //3
  <AccountEmail title={applicantInformationTitle}>
    <h4>Let’s start with your email address.</h4>
  </AccountEmail>,
  //4
  <AccountPassword title={applicantInformationTitle}>
    <h4>Choose a password that you can remember.</h4>
  </AccountPassword>,
  //5
  <LoanFor title={applicantInformationTitle}>
    <h4>What type of project would you like to finance today?</h4>
  </LoanFor>,
  //6
  <LoanAmount title={applicantInformationTitle}>
    <h4>What is your desired financing amount?</h4>
  </LoanAmount>,
  //7
  <UserInfo title={applicantInformationTitle}>
    <h4>What’s your name and number?</h4>
  </UserInfo>,
  //8
  <UserAddress title={applicantInformationTitle}>
    <h4>What is your address?</h4>
  </UserAddress>,
  //9
  <HasCoApplicant title={financingOptionInformationTitle}>
    <h4>Do you have a Co-Applicant?</h4>
    <p>We’ll first complete your profile then ask questions about your Co-Applicant.</p>
  </HasCoApplicant>,
  //10
  <CoAppDetails title={coApplicantInformationTitle}>
    <h4>Co-Applicant, please provide your name and contact information.</h4>
  </CoAppDetails>,
  //11
  <CoAppAddress title={coApplicantInformationTitle}>
    <h4>Co-Applicant, please provide your address.</h4>
  </CoAppAddress>,
  //12
  <Review title={applicantInformationTitle}>
    <h4>Let’s double check the information you’ve provided.</h4>
  </Review>,
  //13
  <ApplicationResult title={applicationStatusTitle} />,
  //14
  <CreditConnect title={applicationStatusTitle} />,
];

export default WellsFargoFlow;

export const WellsFargoFlowPageNumber = {
  Landing: 0,
  Terms: 1,
  AccountEmail: 2,
  AccountPassword: 3,
  LoanFor: 4,
  LoanAmount: 5,
  UserInfo: 6,
  UserAddress: 7,
  HasCoApplicant: 8,
  CoAppDetails: 9,
  CoAppAddress: 10,
  Review: 11,
  ApplicationResult: 12,
  CreditConnect: 13,
};
