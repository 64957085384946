import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const requireAuth = (Component, allowedRights) => {
  const AuthenticatedComponent = ({ isAuthenticated, userRights, location, ...props }) =>
    isAuthenticated ? (
      !allowedRights || (allowedRights && userRights.some(v => allowedRights.indexOf(v) !== -1)) ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/' }} />
      )
    ) : (
      <Redirect
        to={{
          pathname: '/login',
          state: {
            from: location.pathname,
            params: location.search,
          },
        }}
      />
    );

  const mapStoreToProps = store => ({
    userRights: store.auth.userRights,
    isAuthenticated: store.auth.isAuthenticated,
  });

  return connect(mapStoreToProps)(AuthenticatedComponent);
};

export default requireAuth;
