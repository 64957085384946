import HvacHeatTypeEnum from './HvacHeatTypeEnum';
import HvacSystemTypeEnum from './HvacSystemTypeEnum';

const hvacSystem = {
  AC_ONLY: {
    value: 'A/C only',
    name: 'A/C Only',
    description: 'AC Condenser',
  },
  AC_PACKAGE: {
    value: 'A/C Package',
    name: 'A/C Package Unit',
    description: 'AC Package Unit',
    systemType: [HvacSystemTypeEnum.PACKAGE_SYSTEM.value],
    heatType: [HvacHeatTypeEnum.FURNACE.value, HvacHeatTypeEnum.ELECTRIC_AIR_CONDITIONER.value],
  },
  AC_SYSTEM: {
    value: 'A/C System',
    name: 'A/C System',
    description: 'AC Condenser + Coil',
    systemType: [HvacSystemTypeEnum.SPLIT_SYSTEM.value, HvacSystemTypeEnum.MINI_SPLIT_SYSTEM.value],
    heatType: [HvacHeatTypeEnum.ELECTRIC_AIR_CONDITIONER.value],
  },
  DUCTLESS_MINI_SPLIT_SYSTEM: {
    value: 'Ductless Mini Split System',
    name: 'Ductless Mini Split System',
    description: 'Mini Split Indoor + Condenser',
  },
  AIR_HANDLER: { value: 'Air Handler', name: 'Air Handler', description: 'Air Handler' },
  BOILERS: { value: 'Boilers', name: 'Boilers', description: 'Boiler Only' },
  GAS_FURNACE_ONLY: {
    value: 'Gas Furnace only',
    name: 'Gas Furnace Only',
    description: 'Gas Furnace Only',
  },
  GAS_FURNACE_SYSTEM: {
    value: 'Gas Furnace System',
    name: 'Gas Furnace System',
    description: 'AC Condenser + Coil + Furnace',
    systemType: [HvacSystemTypeEnum.SPLIT_SYSTEM.value, HvacSystemTypeEnum.MINI_SPLIT_SYSTEM.value],
    heatType: [HvacHeatTypeEnum.FURNACE.value],
  },
  HEAT_PUMP_ONLY: {
    value: 'Heat Pump only',
    name: 'Heat Pump Only',
    description: 'Heat Pump Condenser',
  },
  HEAT_PUMP_SYSTEM: {
    value: 'Heat Pump System',
    name: 'Heat Pump System',
    description: 'Heat Pump Condenser + Air Handler',
    systemType: [HvacSystemTypeEnum.SPLIT_SYSTEM.value, HvacSystemTypeEnum.MINI_SPLIT_SYSTEM.value],
    heatType: [HvacHeatTypeEnum.ELECTRIC_HEAT_PUMP.value],
  },
  HEAT_PUMP_PACKAGE: {
    value: 'H/P Package',
    name: 'Heat Pump Package',
    description: 'Heat Pump Package Unit',
    systemType: [HvacSystemTypeEnum.PACKAGE_SYSTEM.value],
    heatType: [HvacHeatTypeEnum.ELECTRIC_HEAT_PUMP.value],
  },
  TANKLESS_WATER_HEATER: {
    value: 'Tankless Water Heaters',
    name: 'Tankless Water Heaters',
    description: 'Tankless Water Heater',
  },
  TANK_WATER_HEATER: {
    value: 'Tank Water Heaters',
    name: 'Tank Water Heaters',
    description: 'Tank Water Heater',
  },
  HEAT_PUMP_HYBRID_WATER_HEATERS: {
    value: 'Heat Pump Hybrid Water Heater',
    name: 'Heat Pump Hybrid Water Heater',
    description: 'Heat Pump Hybrid Water Heater',
  },
};

export const getHVACSystem = systemType => {
  for (let key in hvacSystem) {
    if (key === systemType) {
      return hvacSystem[key].value;
    }
  }
  return null;
};

export const getUpdatedHVACSystem = systemType => {
  const list = Object.entries(hvacSystem);
  // .filter(system => system[1].value !== systemType)
  // .reduce((dict, [key, value]) => Object.assign(dict, { [key]: value }), {});

  if (systemType === hvacSystem.TANK_WATER_HEATER.value) {
    for (let i = 0; i < list.length; i++) {
      if (list[i][1].value === hvacSystem.TANKLESS_WATER_HEATER.value) {
        list.splice(i, 1);
      }
      if (list[i][1].value === hvacSystem.HEAT_PUMP_HYBRID_WATER_HEATERS.value) {
        list.splice(i, 1);
      }
    }
  }

  if (systemType === hvacSystem.TANKLESS_WATER_HEATER.value) {
    for (let i = 0; i < list.length; i++) {
      if (list[i][1].value === hvacSystem.TANK_WATER_HEATER.value) {
        list.splice(i, 1);
      }
      if (list[i][1].value === hvacSystem.HEAT_PUMP_HYBRID_WATER_HEATERS.value) {
        list.splice(i, 1);
      }
    }
  }

  if (systemType === hvacSystem.HEAT_PUMP_HYBRID_WATER_HEATERS.value) {
    for (let i = 0; i < list.length; i++) {
      if (list[i][1].value === hvacSystem.TANK_WATER_HEATER.value) {
        list.splice(i, 1);
      }
      if (list[i][1].value === hvacSystem.TANKLESS_WATER_HEATER.value) {
        list.splice(i, 1);
      }
    }
  }

  return list.reduce((dict, [key, value]) => Object.assign(dict, { [key]: value }), {});
};

export const isACUnit = systemType => {
  if (
    systemType === hvacSystem.TANK_WATER_HEATER.value ||
    systemType === hvacSystem.TANKLESS_WATER_HEATER.value ||
    systemType === hvacSystem.HEAT_PUMP_HYBRID_WATER_HEATERS.value ||
    systemType === '' ||
    systemType === null
  ) {
    return false;
  }
  return true;
};

export const isWHUnit = systemType => {
  if (
    systemType === hvacSystem.AC_ONLY.value ||
    systemType === hvacSystem.AC_PACKAGE.value ||
    systemType === hvacSystem.AC_SYSTEM.value ||
    systemType === hvacSystem.DUCTLESS_MINI_SPLIT_SYSTEM.value ||
    systemType === hvacSystem.AIR_HANDLER.value ||
    systemType === hvacSystem.BOILERS.value ||
    systemType === hvacSystem.GAS_FURNACE_ONLY.value ||
    systemType === hvacSystem.GAS_FURNACE_SYSTEM.value ||
    systemType === hvacSystem.HEAT_PUMP_ONLY.value ||
    systemType === hvacSystem.HEAT_PUMP_SYSTEM.value ||
    systemType === hvacSystem.HEAT_PUMP_PACKAGE.value ||
    systemType === '' ||
    systemType === null
  ) {
    return false;
  }
  return true;
};
export default hvacSystem;
