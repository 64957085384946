import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { GoInfo } from 'react-icons/go';
import Application from '../../../components/UI/Application/Application';
import { getApplications } from '../../../redux/actions/index';

const OpenApplications = props => {
  const { auth, dispatchGetApplications, applications, history } = props;
  const { user, dealerUser } = auth.user;
  useEffect(() => {
    if (!auth.user || !dealerUser) {
      history.push('/login');
    }
    if (
      !(
        user?.dealerStatsSummaryDTO &&
        (user.dealerStatsSummaryDTO.aquaFinanceAssigned ||
          user.dealerStatsSummaryDTO.wellsFargoAssigned ||
          user.dealerStatsSummaryDTO.powerPayAssigned ||
          user.dealerStatsSummaryDTO.mosaicAssigned ||
          user.dealerStatsSummaryDTO.enerBankAssigned ||
          user.dealerStatsSummaryDTO.greenskyAssigned ||
          user.dealerStatsSummaryDTO.foundationAssigned ||
          user.dealerStatsSummaryDTO.ftlAssigned ||
          user.dealerStatsSummaryDTO.upgradeAssigned)
      )
    ) {
      history.push('/portal/dashboard');
    } else if (dealerUser.userId) {
      dispatchGetApplications(dealerUser.userId, 'under_review');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Col>
      <Row>
        <Col>
          <h1>Loan Applications</h1>
          <p>
            <GoInfo size={20} /> Click on an application for more information and the option to
            complete or cancel the application.
          </p>
        </Col>
      </Row>
      <Row>
        {applications && applications.length > 0 ? (
          Object.keys(applications).map(key => (
            <Application
              key={applications[key].id}
              value={applications[key]}
              path="/portal/applications-under-review/details/"
              history={history}
              isEmulated={!!user.emulatorId}
            />
          ))
        ) : (
          <div className="app-panel">No applications found.</div>
        )}
      </Row>
    </Col>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  applications: state.softPull.applications,
});

const mapDispatchToProps = dispatch => ({
  dispatchGetApplications: (dealerUserId, applicationType) =>
    dispatch(getApplications(dealerUserId, applicationType)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OpenApplications);
