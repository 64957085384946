import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import employmentTypes from '../../../assets/js/EmploymentTypeEnum';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import CustomInput from '../../../components/UI/Form/Input/CustomInput';
import CurrencyFormat from '../../../components/UI/Form/NumberFormat/CurrencyFormat';
import SelectWithHandleChange from '../../../components/UI/Form/Select/SelectWithHandleChange';
import {
  EMPLOYER_NAME_TOOLTIP_TEXT,
  EMPLOYER_ZIPCODE_TOOLTIP_TEXT,
  EMPLOYMENT_TYPE_TOOLTIP_TEXT,
  GROSS_ANNUAL_INCOME_TOOLTIP_TEXT,
  OCCUPATION_TOOLTIP_TEXT,
  TIME_AT_EMPLOYER_TOOLTIP_TEXT,
} from '../../../constants/tooltipTexts';
import { reactGAEvent, reactGAPageview } from '../../../helper';
import { changePage, changePowerPayInfoValue, submitMindfire } from '../../../redux/actions/index';
import { PowerPayFlowPageNumber } from './PowerPayFlow';

const CoAppIdentificationInfo = props => {
  const {
    email,
    dispatchChangePage,
    dispatchChangePowerPayInfoValue,
    powerPayInformation,
    children,
    isReview,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
  } = props;
  let isEmployed = powerPayInformation.coAppEmploymentType === employmentTypes.Employed.value;
  let isSelfEmployed =
    powerPayInformation.coAppEmploymentType === employmentTypes.SelfEmployed.value;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_ppconsumerincome1',
      trackerNames: [],
      title: 'ca_ppconsumerincome1',
      dealerUserId,
      did,
    });
    // eslint-disable-next-line
  }, []);

  const changeValues = values => {
    isEmployed = values.coAppEmploymentType === employmentTypes.Employed.value;
    isSelfEmployed = values.coAppEmploymentType === employmentTypes.SelfEmployed.value;
    dispatchChangePowerPayInfoValue('coAppGrossAnnualIncome', values.coAppGrossAnnualIncome);
    dispatchChangePowerPayInfoValue(
      'coAppEmployerName',
      isEmployed || isSelfEmployed ? values.coAppEmployerName : null,
    );
    dispatchChangePowerPayInfoValue('coAppEmploymentType', values.coAppEmploymentType);
    dispatchChangePowerPayInfoValue(
      'coAppOccupation',
      isEmployed || isSelfEmployed ? values.coAppOccupation : null,
    );
    dispatchChangePowerPayInfoValue(
      'coAppEmploymentYears',
      isEmployed ? parseInt(values.coAppEmploymentYears) : null,
    );
    dispatchChangePowerPayInfoValue(
      'coAppEmploymentMonths',
      isEmployed ? parseInt(values.coAppEmploymentMonths) : null,
    );
    dispatchChangePowerPayInfoValue(
      'coAppEmployerZipCode',
      isEmployed || isSelfEmployed ? values.coAppEmployerZipCode : null,
    );
  };

  const CoAppIdentificationInfoForm = ({ values }) => (
    <Form>
      <Field
        component={CurrencyFormat}
        label="Gross Annual Income (or Benefits) Amount"
        name="coAppGrossAnnualIncome"
        id="coAppGrossAnnualIncome"
        type="text"
        tooltipText={GROSS_ANNUAL_INCOME_TOOLTIP_TEXT}
      />
      <Field
        component={SelectWithHandleChange}
        label="Income (or Benefits) Type"
        id="coAppEmploymentType"
        name="coAppEmploymentType"
        type="select"
        selectValues={employmentTypes}
        selectFirstName="Select One"
        handleChange={e => changeValues({ ...values, coAppEmploymentType: e.target.value })}
        tooltipText={EMPLOYMENT_TYPE_TOOLTIP_TEXT}
      />
      {isEmployed ? (
        <>
          <Field
            component={CustomInput}
            label="Employer Name"
            name="coAppEmployerName"
            id="coAppEmployerName"
            type="text"
            tooltipText={EMPLOYER_NAME_TOOLTIP_TEXT}
          />
          <Field
            component={CustomInput}
            label="Occupation"
            name="coAppOccupation"
            id="coAppOccupation"
            type="text"
            tooltipText={OCCUPATION_TOOLTIP_TEXT}
          />
          <Row className="mt-4">
            <Col>
              <Label>
                <b>Years at employer</b>
              </Label>
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <Field
                component={CustomInput}
                placeholder="Years"
                formGroupClass="mt-0 mb-2"
                labelClassName="d-none"
                label="Years"
                name="coAppEmploymentYears"
                id="coAppEmploymentYears"
                type="number"
                min={0}
                tooltipText={TIME_AT_EMPLOYER_TOOLTIP_TEXT}
              />
            </Col>
            <Col sm="6">
              <Field
                component={CustomInput}
                placeholder="Months"
                formGroupClass="mt-0 mb-2"
                labelClassName="d-none"
                label="Months"
                name="coAppEmploymentMonths"
                id="coAppEmploymentMonths"
                type="number"
                min={0}
                tooltipText={TIME_AT_EMPLOYER_TOOLTIP_TEXT}
              />
            </Col>
          </Row>
          <Field
            component={CustomInput}
            label="Employer's Zip Code"
            name="coAppEmployerZipCode"
            id="coAppEmployerZipCode"
            type="text"
            maxLength={10}
            tooltipText={EMPLOYER_ZIPCODE_TOOLTIP_TEXT}
          />
        </>
      ) : null}

      {isSelfEmployed ? (
        <>
          <Field
            component={CustomInput}
            label="Company Name"
            name="coAppEmployerName"
            id="coAppEmployerName"
            type="text"
            tooltipText={EMPLOYER_NAME_TOOLTIP_TEXT}
          />
          <Field
            component={CustomInput}
            label="Title"
            name="coAppOccupation"
            id="coAppOccupation"
            type="text"
            tooltipText={OCCUPATION_TOOLTIP_TEXT}
          />
          <Field
            component={CustomInput}
            label="Company Zip Code"
            name="coAppEmployerZipCode"
            id="coAppEmployerZipCode"
            type="text"
            maxLength={10}
            tooltipText={EMPLOYER_ZIPCODE_TOOLTIP_TEXT}
          />
        </>
      ) : null}
      <Button type="submit" title="Next" />
    </Form>
  );

  const CoAppIdentificationInfoFormFormik = withFormik({
    mapPropsToValues: () => ({
      coAppGrossAnnualIncome: powerPayInformation.coAppGrossAnnualIncome || '',
      coAppEmployerName: powerPayInformation.coAppEmployerName || '',
      coAppEmploymentType: powerPayInformation.coAppEmploymentType || '',
      coAppOccupation: powerPayInformation.coAppOccupation || '',
      coAppEmploymentYears:
        powerPayInformation.coAppEmploymentYears === 0
          ? 0
          : powerPayInformation.coAppEmploymentYears || '',
      coAppEmploymentMonths:
        powerPayInformation.coAppEmploymentMonths === 0
          ? 0
          : powerPayInformation.coAppEmploymentMonths || '',
      coAppEmployerZipCode: powerPayInformation.coAppEmployerZipCode || '',
    }),

    validationSchema: Yup.object({
      coAppGrossAnnualIncome: Yup.number().required(
        "Please enter Co-Applicant's gross annual income.",
      ),
      coAppEmployerName:
        isEmployed || isSelfEmployed
          ? Yup.string().required("Please enter Co-Applicant's employer's name.")
          : Yup.string(),
      coAppEmploymentType: Yup.string().required('Please select the employment type.'),
      coAppOccupation:
        isEmployed || isSelfEmployed
          ? Yup.string().required("Please enter Co-Applicant's occupation.")
          : Yup.string(),
      coAppEmploymentYears: isEmployed
        ? Yup.number()
            .moreThan(-1, 'Please enter a positive number.')
            .integer('Please enter an integer number.')
            .max(70, 'Must be less than or equal to 70.')
            .required('Please enter years.')
        : Yup.number(),
      coAppEmploymentMonths: isEmployed
        ? Yup.number()
            .moreThan(-1, 'Please enter a positive number.')
            .integer('Please enter an integer number.')
            .max(11, 'Must be less than or equal to 11.')
            .required('Please enter months.')
        : Yup.number(),
      coAppEmployerZipCode:
        isEmployed || isSelfEmployed
          ? Yup.string()
              .matches(/^\d{5}?$/, {
                message: 'Invalid zip code.',
                excludeEmptyString: true,
              })
              .required('Please enter a zip code.')
          : Yup.string(),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did: did,
        dealerUserId: dealerUserId,
      });
      changeValues(values);
      dispatchChangePage(PowerPayFlowPageNumber.ApplicationReview);
      dispatchSubmitMindfire({
        email: email,
        company: values.coAppEmployerName,
        lastPage: '6015',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(CoAppIdentificationInfoForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={
            isReview
              ? PowerPayFlowPageNumber.ApplicationReview
              : PowerPayFlowPageNumber.CoAppIdentificationInfo
          }
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <CoAppIdentificationInfoFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  powerPayInformation: state.powerPayInformation,
  email: state.preQualificationInformation.email,
  isReview: state.powerPayInformation.isReview,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangePowerPayInfoValue: (key, value) => dispatch(changePowerPayInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

CoAppIdentificationInfo.propTypes = {
  powerPayInformation: PropTypes.object,
  dispatchChangePage: PropTypes.func,
  dispatchChangePowerPayInfoValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CoAppIdentificationInfo);
