import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container } from 'reactstrap';
import Row from 'reactstrap/lib/Row';
import CustomButton from '../../components/UI/Button/CustomButton';

const RessubmissionPending = props => {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs="12" md="10" className="text-center">
          <span className="fs-22 fw-500 pl-40">
            We're having an internal error sending your application to the decision engine. Please
            revisit and resubmit your application. You can find your application under the
            Unsubmitted leases tab.
          </span>
        </Col>
      </Row>
      <br />
      <Row className="p-10 justify-content-center">
        <Link to={'/portal/dashboard'}>
          <CustomButton type="submit">Return to Dashboard</CustomButton>
        </Link>
      </Row>
    </Container>
  );
};

export default RessubmissionPending;
