import React from 'react';
import { Label, Input } from 'reactstrap';
import InputFeedback from '../InputFeedback/InputFeedback';

const SimpleCheckbox = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched, setFieldValue },
  id,
  label,
  className,
  labelClassName,
  disabled,
  clickHandler,
  ...props
}) => (
  <>
    <div className="clear-fix">
      <Input
        name={name}
        disabled={disabled}
        id={id}
        type="checkbox"
        value={value}
        checked={value}
        onChange={onChange}
        onBlur={onBlur}
        className={className}
        onClick={clickHandler}
      />
      <Label for={id} className={labelClassName}>
        {label}
      </Label>
    </div>
    {touched && <InputFeedback error={errors[id]} />}
  </>
);

export default SimpleCheckbox;
