import {
  CHANGE_FOUNDATION_INFO_VALUE,
  SUBMIT_APPLICATION_SOFT_PULL_ERROR,
  SUBMIT_APPLICATION_SOFT_PULL_SUCCESS,
} from './actionTypes';
import axios from 'axios';
import { baseURL, handleApiError } from './api';
import { changePage } from './naviActions';
import { addNotification, endLoading, startLoading } from './notificationActions';
import { changePreQualInfoValue } from './preQualInfoActions';
import { mapApplicationToPrequalification } from './softPullActions';
import { isSameAddress } from '../../helper';
import IncomeFrequencyEnum from '../../assets/js/IncomeFrequencyEnum';
import LoanOptionEnum from '../../assets/js/LoanOptionEnum';
import EmploymentStatusEnum from '../../assets/js/EmploymentStatusEnum';
import OwnershipEnum from '../../assets/js/OwnershipEnum';
import OtherSourceOfIncomeEnum from '../../assets/js/FoundationOtherSourceOfIncomeEnum';
import ProductTypeEnum from '../../assets/js/FoundationProductTypesEnum';
import { checkErrors } from './index';

const qs = require('qs');
export const changeFoundationInfoValue = (key, value) => ({
  type: CHANGE_FOUNDATION_INFO_VALUE,
  key,
  value,
});

export const mapAppToFoundation = app => dispatch => {
  dispatch(changePreQualInfoValue('applicationId', app.applicationId));
  dispatch(mapApplicationToPrequalification(app));
  // Foundation
  //Loan amount page
  dispatch(changeFoundationInfoValue('loanAmountRequested', app.totalFinancingAmount));
  dispatch(changeFoundationInfoValue('saleAmount', app.saleAmount));
  dispatch(changeFoundationInfoValue('downPayment', app.downPayment));
  dispatch(changeFoundationInfoValue('loanOption', LoanOptionEnum[app.loanOption]?.value));

  //Home Improvement Product Type
  dispatch(changeFoundationInfoValue('appProductType', ProductTypeEnum[app.appProductType]?.value));

  //Install address
  dispatch(changePreQualInfoValue('city', app.propertyCity));
  dispatch(changePreQualInfoValue('street', app.propertyStreet));
  dispatch(changePreQualInfoValue('zipcode', app.propertyZip));
  dispatch(changePreQualInfoValue('state', app.propertyState));
  dispatch(changeFoundationInfoValue('ownership', OwnershipEnum[app.ownership]?.value));
  dispatch(changeFoundationInfoValue('propertyMonthlyPayment', app.propertyMonthlyPayment));
  dispatch(changeFoundationInfoValue('propertyYears', app.numberOfMonthsAtAddress));
  dispatch(changeFoundationInfoValue('propertyMonths', app.numberOfYearsAtAddress));

  dispatch(changeFoundationInfoValue('homeownerInsurance', app.homeownerInsurance));
  dispatch(changeFoundationInfoValue('isHomeowner', true));
  const { foundationInformation } = app;

  if (app.applicantPrimary) {
    const applicant = app.applicantPrimary;
    dispatch(
      changePreQualInfoValue(
        'firstName',
        applicant.nameFirst ? applicant.nameFirst : app.nameFirst,
      ),
    );
    dispatch(
      changePreQualInfoValue(
        'middleName',
        applicant.nameMiddle ? applicant.nameMiddle : app.nameMiddle,
      ),
    );
    dispatch(
      changePreQualInfoValue('lastName', applicant.nameLast ? applicant.nameLast : app.nameLast),
    );
    dispatch(changePreQualInfoValue('phoneNumber', applicant.mobilePhone?.number));
    dispatch(changePreQualInfoValue('email', applicant.email?.email));
    dispatch(changePreQualInfoValue('birthday', app.dob));
    dispatch(changeFoundationInfoValue('appDriversLicenseNumber', applicant.idNumber));
    dispatch(changeFoundationInfoValue('appDriversLicenseState', applicant.driversLicenseSOI));
    dispatch(changeFoundationInfoValue('appFullSsn', applicant.ssn));

    //App Employment details
    dispatch(changeFoundationInfoValue('appEmployerName', app.employer));
    dispatch(
      changeFoundationInfoValue(
        'appEmploymentStatus',
        EmploymentStatusEnum[app.employerType]?.value,
      ),
    );
    dispatch(
      changeFoundationInfoValue(
        'appOccupation',
        app.occupation || foundationInformation?.employmentPosition,
      ),
    );
    dispatch(changeFoundationInfoValue('appEmploymentYears', app.employmentYears));
    dispatch(changeFoundationInfoValue('appEmploymentMonths', app.employmentMonths));
    dispatch(
      changeFoundationInfoValue('appEstimatedsAnnualGrosIncome', applicant.grossYearlyIncome),
    );
    dispatch(changeFoundationInfoValue('appGrossIncome', app.appGrossIncome));
    dispatch(
      changeFoundationInfoValue(
        'appIncomeFrequency',
        IncomeFrequencyEnum[app.appIncomeFrequency]?.value,
      ),
    );

    //App Other Income Source
    dispatch(
      changeFoundationInfoValue(
        'appOtherSourcesOfIncome',
        OtherSourceOfIncomeEnum[app.appOtherSourcesOfIncome]?.value,
      ),
    );
    dispatch(
      changeFoundationInfoValue(
        'appOtherIncomeType',
        IncomeFrequencyEnum[app.appOtherIncomeType]?.value,
      ),
    );
    dispatch(changeFoundationInfoValue('appAdditionalIncome', app.appAdditionalIncome));

    //Applicant address
    dispatch(changeFoundationInfoValue('applicantStreet', app.applicantStreet));
    dispatch(changeFoundationInfoValue('applicantApartmentNumber', app.applicantApartmentNumber));
    dispatch(changeFoundationInfoValue('applicantCity', app.applicantCity));
    dispatch(changeFoundationInfoValue('applicantState', app.applicantState));
    dispatch(changeFoundationInfoValue('applicantZipcode', app.applicantZip));
    dispatch(
      changeFoundationInfoValue('applicantOwnership', OwnershipEnum[app.applicantOwnership]?.value),
    );
    dispatch(
      changeFoundationInfoValue(
        'applicantPropertyMonthlyPayment',
        app.applicantPropertyMonthlyPayment,
      ),
    );
    dispatch(changeFoundationInfoValue('applicantPropertyYears', app.applicantPropertyYears));
    dispatch(changeFoundationInfoValue('applicantPropertyMonths', app.applicantPropertyMonths));
  }
  if (app.applicantNonPrimary) {
    const coapp = app.applicantNonPrimary;
    dispatch(changeFoundationInfoValue('hasCoApp', true));

    //CoAppInfo
    dispatch(changeFoundationInfoValue('coAppFirstName', coapp.nameFirst));
    dispatch(changeFoundationInfoValue('coAppLastName', coapp.nameLast));
    dispatch(changeFoundationInfoValue('coAppMiddleInitial', coapp.nameMiddle));
    dispatch(changeFoundationInfoValue('coAppPhoneNumber', coapp.mobilePhone?.number));
    dispatch(changeFoundationInfoValue('coAppEmail', coapp.email?.email));

    //CoAppAddress
    const { address } = coapp;
    if (address) {
      dispatch(
        changeFoundationInfoValue(
          'isSameAddress',
          isSameAddress(app.applicantPrimary.address, address),
        ),
      );
      dispatch(changeFoundationInfoValue('coAppStreet', address.street));
      dispatch(changeFoundationInfoValue('coAppPOBox', address.poBox));
      dispatch(changeFoundationInfoValue('coAppCity', address.city));
      dispatch(changeFoundationInfoValue('coAppZipCode', address.zipcode));
      dispatch(changeFoundationInfoValue('coAppState', address.state));
    }

    //CoAppIdentification
    dispatch(changeFoundationInfoValue('coAppDriversLicenseNumber', coapp.idNumber));
    dispatch(changeFoundationInfoValue('coAppDriversLicenseState', coapp.driversLicenseSOI));
    dispatch(changeFoundationInfoValue('coAppFullSsn', coapp.ssn));
    dispatch(changeFoundationInfoValue('coAppBirthday', coapp.birthDate));

    //CoAppEmployment
    dispatch(changeFoundationInfoValue('coAppEmployerName', app.coappEmployer));
    dispatch(
      changeFoundationInfoValue(
        'coAppEmploymentStatus',
        EmploymentStatusEnum[app.coappEmployerType]?.value,
      ),
    );
    dispatch(changeFoundationInfoValue('coAppOccupation', app.coappOccupation));
    dispatch(changeFoundationInfoValue('coAppEmploymentYears', app.coappEmploymentYears));
    dispatch(changeFoundationInfoValue('coAppEmploymentMonths', app.coappEmploymentMonths));
    dispatch(
      changeFoundationInfoValue('coAppEstimatedsAnnualGrosIncome', app.coappGrossYearlyIncome),
    );
    dispatch(changeFoundationInfoValue('coAppGrossIncome', app.coAppGrossIncome));
    dispatch(
      changeFoundationInfoValue(
        'coAppIncomeFrequency',
        IncomeFrequencyEnum[app.coAppIncomeFrequency]?.value,
      ),
    );

    //CoAppOtherSourceOfIncome
    dispatch(
      changeFoundationInfoValue(
        'coAppOtherSourcesOfIncome',
        OtherSourceOfIncomeEnum[app.coAppOtherSourcesOfIncome]?.value,
      ),
    );
    dispatch(
      changeFoundationInfoValue(
        'coAppOtherIncomeType',
        IncomeFrequencyEnum[app.coAppOtherIncomeType]?.value,
      ),
    );
    dispatch(changeFoundationInfoValue('coAppAdditionalIncome', app.coAppAdditionalIncome));
    dispatch(changeFoundationInfoValue('relationshipWithCoapplicant', coapp.relationshipToPrimary));

    dispatch(changeFoundationInfoValue('coApplicantOwnership', app.coappOwnership));
    dispatch(changeFoundationInfoValue('coApplicantPropertyMonths', app.coappPropertyMonths));
    dispatch(changeFoundationInfoValue('coApplicantPropertyYears', app.coappPropertyYears));
    dispatch(
      changeFoundationInfoValue(
        'coApplicantPropertyMonthlyPayment',
        app.coappPropertyMonthlyPayment,
      ),
    );
  }
};

export const submitFoundationApplication = (params, page, app) => async dispatch => {
  dispatch(
    startLoading(
      'Give us a second while we find the best ' +
        params.selectedFinancier +
        ' financing option for you.',
    ),
  );
  if (app) {
    await axios
      .post(
        `${baseURL}/api/soft_pull/foundation/resubmit`,
        qs.stringify({
          selectedFinancier: `${encodeURIComponent(params.selectedFinancier)}`,
          appId: `${encodeURIComponent(app.applicationId)}`,
          data: '' + JSON.stringify(params),
        }),
      )
      .then(async response => {
        dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_SUCCESS, payload: response.data });
        if (dispatch(checkErrors(response))) {
          dispatch(changePage(page));
          dispatch(endLoading());
        }
      })
      .catch(error => {
        dispatch(
          addNotification({
            message: handleApiError(error),
            messageType: 'Error',
          }),
        );
        dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_ERROR, payload: error });
        dispatch(endLoading());
      });
  } else {
    await axios
      .post(
        `${baseURL}/api/soft_pull/foundation/submit`,
        qs.stringify({ data: '' + JSON.stringify(params) }),
      )
      .then(async response => {
        dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_SUCCESS, payload: response.data });
        if (dispatch(checkErrors(response))) {
          dispatch(changePage(page));
          dispatch(endLoading());
        }
      })
      .catch(error => {
        dispatch(
          addNotification({
            message: handleApiError(error),
            messageType: 'Error',
          }),
        );
        dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_ERROR, payload: error });
        dispatch(endLoading());
      });
  }
};
