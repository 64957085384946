import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import { getAddress, reactGAEvent, reactGAPageview } from '../../../helper';
import {
  changePage,
  changeEnerBankInfoValue,
  submitMindfire,
  submitEnerBankApplication,
} from '../../../redux/actions/index';
import { EnerBankFlowPageNumber } from './EnerBankFlow';

const ApplicationReview = props => {
  const {
    children,
    dispatchChangePage,
    dispatchChangeInfoValue,
    pqi,
    information,
    dealerUserId,
    did,
    dispatchSubmitMindfire,
    app,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_ebconfirminfo',
      trackerNames: [],
      title: 'ca_ebconfirminfo',
      did,
      dealerUserId,
    });
    dispatchChangeInfoValue('isReview', false);
    // eslint-disable-next-line
  }, []);

  const editPage = page => {
    dispatchChangeInfoValue('isReview', true);
    dispatchChangePage(page);
  };

  const mindfireRequest = {
    email: pqi.email,
    lastPage: '11021',
    dealerUserId: dealerUserId,
    did: did,
  };

  const appAddress = getAddress({
    street: pqi.street,
    city: pqi.city,
    state: pqi.state,
    zip: pqi.zipcode,
  });

  const coApplicantAddress = information.hasCoApp
    ? getAddress({
        street: information.coAppStreet,
        city: information.coAppCity,
        state: information.coAppState,
        zip: information.coAppZipCode,
      })
    : 'No address';

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={
            information.hasCoApp
              ? EnerBankFlowPageNumber.CoAppEmploymentDetails
              : EnerBankFlowPageNumber.HasCoApplicant
          }
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <Row className="review">
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col xs="8" lg="9">
                  <h5>Applicant’s Information</h5>
                </Col>
                {!app && (
                  <Col xs="4" lg="3">
                    <Button
                      className="edit"
                      clickHandler={() => editPage(EnerBankFlowPageNumber.AppIdentificationInfo)}
                      title="Edit"
                    />
                  </Col>
                )}
              </Row>
              <Row>
                <Col xs="6">
                  <b>Name</b>
                  {(pqi.firstName ? pqi.firstName + ' ' : '') +
                    (pqi.middleName ? pqi.middleName + ' ' : '') +
                    (pqi.lastName || '')}
                </Col>
                <Col xs="6">
                  <b>Phone Number</b>
                  {!!pqi.phoneNumber
                    ? pqi.phoneNumber.length > 6
                      ? '(' +
                        pqi.phoneNumber.substring(0, 3) +
                        ') ' +
                        pqi.phoneNumber.substring(3, 6) +
                        '-' +
                        pqi.phoneNumber.substring(6)
                      : pqi.phoneNumber
                    : 'N/A'}
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <b>Email Address</b>
                  <span className="text-break">{pqi.email || 'N/A'}</span>
                </Col>
                <Col xs="6">
                  <b>Date of Birth</b>
                  {pqi.birthday || 'N/A'}
                </Col>
              </Row>
              <Row>
                <Col>
                  <b>Full SSN</b>
                  {information.appFullSsn ? 'xxxxx' + information.appFullSsn.slice(5, 9) : 'N/A'}
                </Col>
              </Row>
              <Row>
                <Col>
                  <b>Applicant’s Address</b>
                  {appAddress}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="review">
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col xs="8" lg="9">
                  <h5>Loan Information</h5>
                </Col>
                {!app && (
                  <Col xs="4" lg="3">
                    <Button
                      className="edit"
                      clickHandler={() => editPage(EnerBankFlowPageNumber.ProjectDetail)}
                      title="Edit"
                    />
                  </Col>
                )}
              </Row>
              <Row>
                <Col xs="6">
                  <b>Loan Name</b>
                  {information.programProduct?.name || 'N/A'}
                </Col>
                <Col xs="6">
                  <b>Estimated Loan Amount</b>
                  <NumberFormat
                    value={information.borrowedAmount}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={'$'}
                    displayType="text"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="review">
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col xs="8" lg="9">
                  <h5>Applicant’s Income Information</h5>
                </Col>
                {!app && (
                  <Col xs="4" lg="3">
                    <Button
                      className="edit"
                      clickHandler={() => editPage(EnerBankFlowPageNumber.AppEmploymentDetails)}
                      title="Edit"
                    />
                  </Col>
                )}
              </Row>
              <Row>
                <Col>
                  <b>Your Annual Income</b>
                  <NumberFormat
                    value={information.appGrossAnnualIncome}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={'$'}
                    displayType="text"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="review">
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col xs="8" lg="9">
                  <h5>Co-Applicant’s Information</h5>
                </Col>
                {!app && (
                  <Col xs="4" lg="3">
                    <Button
                      className="edit"
                      clickHandler={() => editPage(EnerBankFlowPageNumber.HasCoApplicant)}
                      title={information.hasCoApp ? 'Edit' : 'Add'}
                    />
                  </Col>
                )}
              </Row>
              {information.hasCoApp && (
                <>
                  <Row>
                    <Col xs="6">
                      <b>Name</b>
                      {(information.coAppFirstName ? information.coAppFirstName + ' ' : '') +
                        (information.coAppMiddleName ? information.coAppMiddleName + ' ' : '') +
                        (information.coAppLastName || '')}
                    </Col>
                    <Col xs="6">
                      <b>Phone Number</b>
                      {!!information.coAppPhoneNumber
                        ? information.coAppPhoneNumber.length > 6
                          ? '(' +
                            information.coAppPhoneNumber.substring(0, 3) +
                            ') ' +
                            information.coAppPhoneNumber.substring(3, 6) +
                            '-' +
                            information.coAppPhoneNumber.substring(6)
                          : information.coAppPhoneNumber
                        : 'N/A'}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">
                      <b>Email Address</b>
                      <span className="text-break">{information.coAppEmail || 'N/A'}</span>
                    </Col>
                    <Col xs="6">
                      <b>Date of Birth</b>
                      {information.coAppBirthday || 'N/A'}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <b>Full SSN</b>
                      {information.coAppFullSsn
                        ? 'xxxxx' + information.coAppFullSsn.slice(5, 9)
                        : 'N/A'}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <b>Co-Applicant’s Address</b>
                      {coApplicantAddress}
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
          {information.hasCoApp && (
            <Row className="review">
              <Col>
                <Row className="align-items-center justify-content-between">
                  <Col xs="8" lg="9">
                    <h5>Co-Applicant’s Income Information</h5>
                  </Col>
                  {!app && (
                    <Col xs="4" lg="3">
                      <Button
                        className="edit"
                        clickHandler={() => editPage(EnerBankFlowPageNumber.CoAppEmploymentDetails)}
                        title="Edit"
                      />
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col>
                    <b>Your Annual Income</b>
                    <NumberFormat
                      value={information.coAppGrossAnnualIncome}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix={'$'}
                      displayType="text"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          <Button
            type="submit"
            title="Next"
            clickHandler={() => {
              reactGAEvent({
                category: 'Button',
                action: 'Click',
                label: 'Find the best financing option',
                did,
                dealerUserId,
              });
              dispatchSubmitMindfire(mindfireRequest);
              dispatchChangePage(EnerBankFlowPageNumber.DeliveryMethod);
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  app: state.softPull.applicationSubmitDTO,
  consumerId: state.auth.consumerId,
  aquaFinanceAssigned: state.auth.aquaFinanceAssigned,
  applicantAddress: state.enerBankInformation.applicantAddress,
  information: state.enerBankInformation,
  pqi: state.preQualificationInformation,
  softPull: state.softPull,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  emulatorId: state.auth.emulatorId,
  track_id: state.auth.track_id,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeInfoValue: (key, value) => dispatch(changeEnerBankInfoValue(key, value)),
  dispatchSubmitApplication: (params, page, app) =>
    dispatch(submitEnerBankApplication(params, page, app)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

ApplicationReview.propTypes = {
  dispatchChangePage: PropTypes.func,
  dispatchChangeInfoValue: PropTypes.func,
  dispatchSubmitSoftPullAquaApplication: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApplicationReview);
