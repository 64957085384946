import { AgeFromDateString } from 'age-calculator';
import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import selectedFinancierEnum from '../../../../assets/js/SelectedFinancierEnum';
import USStateEnum from '../../../../assets/js/USStateEnum';
import terms from '../../../../assets/pdf/Optimus Terms and Conditions.pdf';
import statementOfUse from '../../../../assets/pdf/Optimus Use of Electronic Records.pdf';
import privacyPolicy from '../../../../assets/pdf/Optimus Privacy Policy.pdf';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import Checkbox from '../../../../components/UI/Form/Checkbox/Checkbox';
import CustomDatePicker from '../../../../components/UI/Form/DatePicker/DatePicker';
import Input from '../../../../components/UI/Form/Input/CustomInput';
import ErrorModal from '../../../../components/UI/Modal/ErrorModal';
import {
  getDealerPortalName,
  getDealerPortalURL,
  getAddedFrom,
  getAppName,
} from '../../../../config/settings';
import {
  changeApplicationValues,
  isStateAllowed,
  reactGAEvent,
  reactGAPageview,
  validDateFormat,
} from '../../../../helper';
import {
  changePage,
  changePreQualInfoValue,
  getScore,
  setFlow,
  submitMindfire,
  updatePrequalificationInformation,
  setSecondSelectedFinancerFlow,
} from '../../../../redux/actions/index';
import { greenSkyFlow, microfFlow } from '../../../../redux/initialState';
import { GreenSkyPreQualificationFlowPageNumber } from './PreQualificationFlow';
import AirConditioningSystemEnum from '../../../../assets/js/AirConditioningSystemEnum';
import {
  BIRTHDAY_TOOLTIP_TEXT,
  EGIA_AUTHORIZATION_TOOLTIP_TEXT,
  EGIA_TERMS_TOOLTIP_TEXT,
  LAST_4_SSN_TOOLTIP_TEXT,
} from '../../../../constants/tooltipTexts';

const applicationName = getAppName();

const UserLastInfo = props => {
  const {
    pqi,
    softPullResponse,
    dispatchChangePage,
    dispatchChangeValue,
    dispatchGetScore,
    history,
    dispatchSetFlow,
    dealerUserId,
    dealerId,
    did,
    lead_source,
    lp_url,
    app,
    dispatchUpdatePrequalificationInformation,
    track_id,
    dispatchSubmitMindfire,
    state,
    dispatchSetSecondSelectedFinancerFlow,
    user,
  } = props;

  const isStateOfAlabama = USStateEnum.Alabama.value === state;
  const minimumAgeRequired = isStateOfAlabama ? 19 : 18;

  useEffect(() => {
    if (softPullResponse && softPullResponse.score === null)
      reactGAPageview({
        path: '/ca_gsconsumerinfo3',
        trackerNames: [],
        title: 'ca_gsconsumerinfo3',
        did,
        dealerUserId,
      });
    // eslint-disable-next-line
  }, []);

  const mindfireRequest = {
    email: pqi.email,
    lastPage: '9006',
    dealerUserId: dealerUserId,
    did: did,
  };

  if (softPullResponse && softPullResponse.score !== null) {
    let isRtoNotAvailable =
      !isStateAllowed(pqi.state) ||
      softPullResponse.airConditioningSystem ===
        AirConditioningSystemEnum.OTHER_HOME_IMPROVEMENT.id;

    if (softPullResponse.score === 0) {
      // dispatchChangePage(GreenSkyPreQualificationFlowPageNumber.InvalidResponse);
      // MIC-1855
      dispatchSetFlow(greenSkyFlow);
      history.push('/greensky');
    } else if (
      softPullResponse.score < softPullResponse.scoreLimit &&
      (softPullResponse.score < softPullResponse.scoreLimit2 ||
        softPullResponse.scoreLimit2 === 0) &&
      isRtoNotAvailable
    ) {
      dispatchChangePage(GreenSkyPreQualificationFlowPageNumber.NotAllowed);
    } else if (softPullResponse.score >= softPullResponse.scoreLimit) {
      dispatchSetFlow(greenSkyFlow);
      history.push('/greensky');
    } else if (softPullResponse.score >= softPullResponse.scoreLimit2) {
      dispatchSetSecondSelectedFinancerFlow(
        softPullResponse.secondFinancingOption,
        softPullResponse.score,
        softPullResponse.scoreLimit2,
        softPullResponse.ssnLastDigits,
        softPullResponse.state,
        softPullResponse.airConditioningSystem,
        history,
      );
    } else {
      dispatchChangePage(GreenSkyPreQualificationFlowPageNumber.GreenskyDeclinedToMicrof);
      history.push('/RTO');
    }
  }

  const UserLastInfoForm = () => (
    <Form noValidate autoComplete="off">
      <Field
        component={CustomDatePicker}
        name="birthday"
        id="birthday"
        tooltipText={BIRTHDAY_TOOLTIP_TEXT}
        label="Your Date of Birth"
      />
      <React.Fragment>
        <Field
          component={Input}
          label="Last 4 digits of your SSN"
          name="ssnLastDigits"
          id="ssnLastDigits"
          tooltipText={LAST_4_SSN_TOOLTIP_TEXT}
          type="password"
          icon="lock"
          maxLength="4"
          number
        />
        <small className="form-text text-muted">
          {applicationName} uses security measures that comply with federal law, including data
          encryption technology, to protect your personal information from unauthorized access and
          use.
        </small>
        <Field
          component={Checkbox}
          name="terms"
          id="terms"
          tooltipText={EGIA_TERMS_TOOLTIP_TEXT}
          label={`I agree to the EGIA Terms & Conditions`}
          labelClassName="bold mb-0"
        />
        <p>
          You understand that by checking the box immediately above this notice you are providing
          EGIA (the technology platform provider) written authorization to share all application
          information with platform lenders and lessors and you understand and acknowledge that you
          are accepting EGIA’s{' '}
          <Link to={terms} target="_blank">
            Statement of Terms & Conditions
          </Link>
          ,{' '}
          <Link to={statementOfUse} target="_blank">
            Statement on Use of Electronic Records
          </Link>
          , and{' '}
          <Link to={privacyPolicy} target="_blank">
            Privacy Statement
          </Link>
          .
        </p>
        <Field
          component={Checkbox}
          name="authorize"
          id="authorize"
          tooltipText={EGIA_AUTHORIZATION_TOOLTIP_TEXT}
          label={`I authorize EGIA to access my info under the Fair Credit Reporting Act`}
          labelClassName="bold mb-0"
        />
        <p>
          You understand that by checking the box immediately above this notice you are providing
          ‘written instructions’ to EGIA (the technology platform provider) under the Fair Credit
          Reporting Act authorizing EGIA to obtain information from your personal credit profile or
          other information from Experian. You authorize EGIA to obtain such information solely to
          prequalify you for financing options from platform lenders and lessors.
        </p>
      </React.Fragment>
      <Button type="submit" title={'Continue with Prequalification'} />
    </Form>
  );

  const UserLastInfoFormFormik = withFormik({
    mapPropsToValues: () => ({
      birthday: pqi.birthday || '',
      ssnLastDigits: pqi.ssnLastDigits || '',
      terms: false,
      authorize: false,
    }),

    validationSchema: Yup.object({
      birthday: Yup.string()
        .required('Please enter your date of birth.')
        .test(
          'incomplete',
          'Incomplete date',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 8,
        )
        .test('valid', 'Invalid date format.', val => val && !validDateFormat(val))
        .test(
          'age',
          `You must be at least ${minimumAgeRequired} years old.`,
          val => val && new AgeFromDateString(val).age >= minimumAgeRequired,
        ),
      ssnLastDigits: Yup.string()
        .required('Please enter the last 4 digits of your SSN.')
        .min(4, 'Please enter just 4 digits.')
        .max(4, 'Please enter just 4 digits.'),
      terms: Yup.boolean().oneOf([true], 'Please check to continue.'),
      authorize: Yup.boolean().oneOf([true], 'Please check to continue.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Navigation Button',
        action: 'Clicked continue to submit form',
        label: 'Find the best financing option',
        did,
        dealerUserId,
      });
      changeApplicationValues(dispatchChangeValue, values);
      let request = {
        email: pqi.email,
        password: pqi.password,
        firstName: pqi.firstName,
        lastName: pqi.lastName,
        middleName: pqi.middleName,
        phoneNumber: pqi.phoneNumber,
        street: pqi.street,
        city: pqi.city,
        zipcode: pqi.zipcode,
        state: pqi.state,
        birthday: values.birthday,
        ssnLastDigits: values.ssnLastDigits,
        airConditioningSystem: pqi.airConditioningSystem,
        isHomeowner: pqi.isHomeowner,
        dealerUserId: dealerUserId,
        dealerId: dealerId,
        did: did,
        prequalificationId: pqi.prequalificationId,
        leadSource: lead_source,
        lpUrl: lp_url,
        lpReferrer: document.referrer,
        prequalificationToken: pqi.prequalificationToken,
        track_id: track_id,
        selectedFinancier: selectedFinancierEnum.GREENSKY.value,
        environment: applicationName,
        addedFrom: getAddedFrom(),
      };

      dispatchSubmitMindfire(mindfireRequest);

      dispatchGetScore(request, app, pqi.isConsumer, user);
    },
  })(UserLastInfoForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={GreenSkyPreQualificationFlowPageNumber.UserAddress}
        />
        <Col sm="5">
          <>
            <h4>
              Please enter your date of birth and the last 4 digits of your Social Security Number
              to see the types of financing options that you prequalify for.
            </h4>
            <p>
              {pqi.firstName} {pqi.lastName}, we'll do a soft inquiry of your consumer report, which
              allows us to get a sense of how well you are managing your credit. It does not affect
              your credit score because it is not yet associated with a specific financing
              application, but it will be listed on your credit report.
            </p>
          </>
        </Col>
        <Col sm="6">
          <UserLastInfoFormFormik />
        </Col>
      </Row>
      <ErrorModal
        isOpen={pqi.preQualInfoExists}
        messageType="User Exists"
        message={
          <>
            Your email address already exists on our system. Please log in using{' '}
            <a href={getDealerPortalURL()}>{getDealerPortalName()}</a> and complete the application
            that is already in progress.
          </>
        }
        onClick={() => {
          dispatchChangeValue('preQualInfoExists', false);
          window.location.href = getDealerPortalURL();
        }}
      />
    </Container>
  );
};

const mapStateToProps = state => ({
  state: state.preQualificationInformation.state,
  app: state.softPull.applicationSubmitDTO,
  pqi: state.preQualificationInformation,
  softPullResponse: state.softPull.softPullResponse,
  dealerUserId: state.auth.dealerUserId,
  dealerId: state.auth.dealerId,
  did: state.auth.did,
  lead_source: state.auth.lead_source,
  lp_url: state.auth.lp_url,
  track_id: state.auth.track_id,
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
  dispatchUpdatePrequalificationInformation: (params, app) =>
    dispatch(updatePrequalificationInformation(params, app)),
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) => dispatch(changePreQualInfoValue(key, value)),
  dispatchGetScore: (params, app, isConsumerUser, user) =>
    dispatch(getScore(params, app, isConsumerUser, user)),
  dispatchSetFlow: flowName => dispatch(setFlow(flowName)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchSetSecondSelectedFinancerFlow: (
    secondFinancingOption,
    score,
    scoreLimit2,
    ssnLastDigits,
    state,
    airConditioningSystem,
    history,
  ) =>
    dispatch(
      setSecondSelectedFinancerFlow(
        secondFinancingOption,
        score,
        scoreLimit2,
        ssnLastDigits,
        state,
        airConditioningSystem,
        history,
      ),
    ),
});

UserLastInfo.propTypes = {
  pqi: PropTypes.object,
  softPull: PropTypes.object,
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
  dispatchGetScore: PropTypes.func,
  dispatchSetFlow: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(UserLastInfo),
);
