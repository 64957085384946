import React from 'react';
import Consent from './Consent';
import MosaicIFrame from './MosaicIFrame';
import Declined from './Declined';
import Pending from './Pending';
import Approved from './Approved';
import ApprovedRedirect from './ApprovedRedirect';

const MosaicFlow = [
  //1
  <Consent />,
  //2
  <MosaicIFrame />,
  //3
  <Pending />,
  //4
  <Declined />,
  //5
  <Approved />,
  //6
  <ApprovedRedirect />,
];

export default MosaicFlow;

export const MosaicFlowPageNumber = {
  Consent: 0,
  MosaicIFrame: 1,
  Pending: 2,
  Declined: 3,
  Approved: 4,
  ApprovedRedirect: 5,
};
