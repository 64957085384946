import React from 'react';
import { getPixelSiteScoutUrl } from '../../../config/settings';

const PixelSiteScouting = props => {
  const { type } = props;
  const style = { width: 1, height: 1, position: 'absolute', left: '-150px' };

  return <img alt="pixel" style={style} src={`${getPixelSiteScoutUrl()}?button=${type}`} />;
};

export default PixelSiteScouting;
