import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const Alert = props => {
  const { show, bodyText, headerText, clickEventHandler, className } = props;

  return (
    <Modal isOpen={show} className={className}>
      <ModalHeader toggle={clickEventHandler}>{headerText}</ModalHeader>
      <ModalBody>{bodyText}</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={clickEventHandler}>
          OK
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default Alert;
