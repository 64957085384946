import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import { useVSPixelScript } from '../../../../assets/hooks';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import SsnFormat from '../../../../components/UI/Form/NumberFormat/SsnFormat';
import { APPFULLSSN_TOOLTIP_TEXT } from '../../../../constants/tooltipTexts';
import { reactGAEvent, reactGAPageview } from '../../../../helper';
import { changeFtlInfoValue, changePage, submitMindfire } from '../../../../redux/actions/index';
import { FTLFlowPageNumber } from '../FTLFlow';

const IdentificationInformation = props => {
  const {
    dispatchChangeFtlInfoValue,
    dispatchChangePage,
    children,
    dispatchSubmitMindfire,
    email,
    did,
    dealerUserId,
    isReview,
    appFullSSN,
    ssnLastDigits,
  } = props;

  useVSPixelScript();

  useEffect(() => {
    reactGAPageview({
      path: '/ca_ftlconsumerssn',
      trackerNames: [],
      title: 'ca_ftlconsumerssn',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const IdentificationInformationForm = () => {
    return (
      <Form noValidate autoComplete="off">
        <Field
          component={SsnFormat}
          label="Your full Social Security Number"
          name="appFullSSN"
          id="appFullSSN"
          tooltipText={APPFULLSSN_TOOLTIP_TEXT}
          type="password"
          icon="lock"
        />
        <Button type="submit" title="Continue" />
      </Form>
    );
  };

  const IdentificationInformationFormFormik = withFormik({
    mapPropsToValues: () => ({
      appFullSSN: appFullSSN || '',
    }),

    validationSchema: Yup.object({
      appFullSSN: Yup.string()
        .required('Please enter your SSN.')
        .test(
          'len',
          'Must have 9 digits',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 9,
        )
        .test(
          'match',
          'Last 4 digits must match.',
          val =>
            ssnLastDigits === '' ||
            (val &&
              val
                .toString()
                .match(/\d/g)
                .join('')
                .substr(
                  val
                    .toString()
                    .match(/\d/g)
                    .join('').length - 4,
                ) === ssnLastDigits),
        )
        .typeError('Numbers only.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Continue',
        did,
        dealerUserId,
      });
      dispatchChangeFtlInfoValue(
        'appFullSSN',
        values.appFullSSN
          .toString()
          .match(/\d/g)
          .join(''),
      );
      dispatchChangePage(isReview ? FTLFlowPageNumber.Review : FTLFlowPageNumber.HasCoApplicant);
      dispatchSubmitMindfire({
        email: email,
        lastPage: '12012',
        dealerUserId: dealerUserId,
        did: did,
        birthDay: values.birthday,
      });
    },
  })(IdentificationInformationForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={
            isReview ? FTLFlowPageNumber.BillingAddress : FTLFlowPageNumber.EmploymentInformation
          }
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <IdentificationInformationFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  isReview: state.ftlInformation.isReview,
  pqi: state.preQualificationInformation,
  appFullSSN: state.ftlInformation.appFullSSN,
  ssnLastDigits: state.preQualificationInformation.ssnLastDigits || '',
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeFtlInfoValue: (key, value) => dispatch(changeFtlInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IdentificationInformation);
