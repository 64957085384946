import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import YesNoComponent from '../../components/UI/YesNoComponent/YesNoComponent';
import { changeMicrofInfoValue, changePage, submitMindfire } from '../../redux/actions/index';
import { reactGAPageview, reactGAEvent } from '../../helper';
import { MicrofFlowPageNumber } from './MicrofFlow';

const HasCoApplicant = props => {
  const {
    isReview,
    dispatchChangePage,
    dispatchChangeInfoValue,
    children,
    dispatchSubmitMindfire,
    email,
    did,
    dealerUserId,
    coApplicantAdded,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_coappoption',
      trackerNames: [],
      title: 'ca_coappoption',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const mindFireRequest = {
    email: email,
    lastPage: '4080',
    dealerUserId: dealerUserId,
    did: did,
  };

  return (
    <YesNoComponent
      backpage={isReview ? MicrofFlowPageNumber.Review : MicrofFlowPageNumber.AppIncomeInfo}
      upperButtonText="Yes"
      lowerButtonText="No"
      upperBtnClickHandler={() => {
        reactGAEvent({
          category: 'Button',
          action: 'Click',
          label: 'Yes',
          did,
          dealerUserId,
        });
        dispatchChangePage(MicrofFlowPageNumber.CoApplicantInfo);
        dispatchChangeInfoValue('hasCoApp', true);
        dispatchSubmitMindfire(mindFireRequest);
      }}
      lowerBtnClickHandler={() => {
        reactGAEvent({
          category: 'Button',
          action: 'Click',
          label: 'No',
          did,
          dealerUserId,
        });
        dispatchChangePage(MicrofFlowPageNumber.Review);
        dispatchChangeInfoValue('isReview', null);
        dispatchChangeInfoValue('hasCoApp', false);
        dispatchSubmitMindfire(mindFireRequest);
      }}
      lowerButtonDisabled={!!coApplicantAdded}
    >
      {children}
    </YesNoComponent>
  );
};

const mapStateToProps = state => ({
  isReview: state.microfInformation.isReview,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  coApplicantAdded:
    state.softPull.applicationSubmitDTO !== null
      ? state.softPull.applicationSubmitDTO.applicantNonPrimary
      : null,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeInfoValue: (key, value) => dispatch(changeMicrofInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

HasCoApplicant.propTypes = {
  dispatchChangePage: PropTypes.func,
  dispatchChangeInfoValue: PropTypes.func,
  isReview: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HasCoApplicant);
