import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';
import { Button, Col } from 'reactstrap';
import { changeSoftPullValue, uploadFTLDocumentsApplication } from '../../../../../redux/actions';

const Dropzone = props => {
  const {
    application,
    filesListName,
    label,
    dispatchChangeValue,
    dispatchUploadFTLDocumentsApplication,
    homeownerDocuments,
    ftlData,
    uploadResponse,
  } = props;
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  useEffect(() => {
    dispatchChangeValue(filesListName, acceptedFiles);

    // eslint-disable-next-line
  }, [acceptedFiles]);

  const upload = () => {
    if (homeownerDocuments && homeownerDocuments.length > 0) {
      dispatchUploadFTLDocumentsApplication(acceptedFiles, application.id, application.email);
    }
  };

  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  let uploadedFiles = [];

  if (ftlData && ftlData.documents.length > 0) {
    uploadedFiles = ftlData.documents;
  }

  if (
    uploadResponse &&
    uploadResponse.approvedWith &&
    uploadResponse.approvedWith[0]?.documents?.length > 0
  ) {
    uploadedFiles = uploadResponse.approvedWith[0].documents;
  }

  const filesPreview =
    uploadedFiles.length > 0
      ? uploadedFiles.map(file => (
          <li key={file.id}>
            <a alt={file.name} href={file.url} rel="noopener noreferrer" target="_blank">
              {file.name}
            </a>
          </li>
        ))
      : null;

  return (
    <section>
      <label className="mb-4">
        <b>{label}</b>
      </label>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
      </div>
      <aside>{files}</aside>
      {filesPreview && <aside>{filesPreview}</aside>}
      <Col sm="12" xs="12" md="auto">
        <Button
          type="submit"
          color="primary"
          className="rounded-pill w-100 mt-1"
          outline
          onClick={() => {
            upload();
          }}
        >
          Upload
        </Button>
      </Col>
    </section>
  );
};

const mapStateToProps = state => ({
  homeownerDocuments: state.softPull.homeownerDocuments,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangeValue: (key, value) => dispatch(changeSoftPullValue(key, value)),
  dispatchUploadFTLDocumentsApplication: (applicantFiles, applicationId, email) =>
    dispatch(uploadFTLDocumentsApplication(applicantFiles, applicationId, email)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dropzone);
