import React from 'react';
import UserRoleEnum from '../../../assets/js/UserRoleEnum';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { changeSoftPullValue } from '../../../redux/actions';

const DealersTable = props => {
  const { dealersData, history, hasAction, setPageIndex, dispatchChangeSoftPullValue } = props;

  return (
    <table className="table table-hover table-striped">
      <thead>
        <tr>
          <th>DealerId</th>
          <th>Dealer Name</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {dealersData.map((dealer, index) => (
          <tr
            className={`${!hasAction ? 'cursor-pointer' : ''}`}
            key={index}
            onClick={() => {
              if (!hasAction) {
                history.push(
                  '/portal/update-merchant-number/update-dealer?dealerId=' + dealer.dealerId,
                );
              }
            }}
          >
            <td className="align-middle">{dealer.dealerId}</td>
            <td className="align-middle">{dealer.name}</td>

            <td className="align-middle" />
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const mapDispatchToProps = dispatch => ({
  dispatchChangeSoftPullValue: (key, value) => dispatch(changeSoftPullValue(key, value)),
});

export default connect(
  null,
  mapDispatchToProps,
)(DealersTable);
