const ownershipEnum = {
  MOBILE_WITH_LAND: { value: 'MWL', name: 'Mobile Home w/Land' },
  MOBILE_NO_LAND: { value: 'MNL', name: 'Mobile Home - No Land' },
  OTHER: { value: 'OTH', name: 'Other' },
  OWN_MORTGAGE: { value: 'OMT', name: 'Own - Mortgage' },
  OWN_PAID_FULL: { value: 'OPF', name: 'Own - Paid In Full' },
  RENT: { value: 'RNT', name: 'Rent' },
};

export const findOwnershipEnumName = value => {
  for (let key in ownershipEnum) {
    let ownership = ownershipEnum[key];
    if (ownership.value === value) {
      return ownership.name;
    }
  }
  return null;
};

export default ownershipEnum;
