import React from 'react';
import Checkmark from '../../components/UI/Row/Checkmark/Checkmark';

const MicrofCheckList = ({ isWellsFargo, microfOnly, isRheem }) => {
  return (
    <>
      <h5 className="microf-primary font-weight-bold">Microf’s HVAC Lease-Purchase Option:</h5>
      <Checkmark>
        <p className="mb-1 font-weight-bold">Monthly renewals up to 60 months to own.</p>
        <p>Not all term options are available in all states.</p>
      </Checkmark>
      <Checkmark>
        <p className="mb-1 font-weight-bold">
          You will own the item after the total of payments to ownership is paid in full or is paid
          by prepayment.
        </p>
        <p>
          Save up to 40% or more off your remaining payments to ownership. Early Purchase Options
          vary by state, so call us at 855-642-7631 for details.
        </p>
      </Checkmark>
      <Checkmark>
        <p className="mb-1 font-weight-bold">Damaged credit? Not a problem!</p>
        <p>Microf regularly approves applicants with less than perfect credit.</p>
      </Checkmark>
    </>
  );
};

export default MicrofCheckList;
