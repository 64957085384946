import React from 'react';

const Contractors = props => {
  const { dealerSearchCriteriaResult, history } = props;
  return (
    <table className="table table-hover table-striped">
      <thead>
        <tr>
          <th>Contractor</th>
        </tr>
      </thead>
      <tbody>
        {dealerSearchCriteriaResult.dealers.map((dealer, index) => (
          <tr
            className={'cursor-pointer'}
            key={index}
            onClick={() => {
              history.push('/portal/edit-primary-users?id=' + dealer.dealerId);
            }}
          >
            <td className="align-middle w-50">{dealer.name}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Contractors;
