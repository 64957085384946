import React from 'react';
import NumberFormat from 'react-number-format';
import { Col, Row } from 'reactstrap';
import Button from '../Button/CustomButton';
import './AquaPromotion.scss';
import { connect } from 'react-redux';
import { reactGAEvent } from '../../../helper';

const AquaPromotion = ({
  name,
  apr,
  paymentFactor,
  monthlyPayment,
  buttonText,
  applicationId,
  promotionId,
  nextPage,
  setApplicationPromotion,
  did,
  dealerUserId,
  isConsumer,
  submitMindfire,
}) => {
  return (
    <Row className="aquaPromotion">
      <Col>
        <Row>
          <Col>
            <h6>{name}</h6>
          </Col>
        </Row>
        <Row>
          <Col xs="6">
            <b>APR</b>
            <NumberFormat
              value={apr * 100}
              thousandSeparator={true}
              fixedDecimalScale={true}
              decimalScale={2}
              displayType="text"
            />
            %
          </Col>
          <Col xs="6">
            <b>Payment Factor</b>
            <NumberFormat
              value={paymentFactor * 100}
              thousandSeparator={true}
              fixedDecimalScale={true}
              decimalScale={2}
              displayType="text"
            />
            %
          </Col>
        </Row>
        <Row>
          <Col xs="6">
            <b>Monthly Payment</b>
            <NumberFormat
              value={monthlyPayment}
              thousandSeparator={true}
              fixedDecimalScale={true}
              decimalScale={2}
              displayType="text"
              prefix="$"
            />
          </Col>
          <Col xs="6" />
        </Row>
        <Row>
          <Col>
            <Button
              type="submit"
              title={buttonText ? buttonText : 'I want this option'}
              clickHandler={() => {
                if (isConsumer) {
                  window.bingButtonClickHandler();
                }
                reactGAEvent({
                  category: 'Button',
                  action: 'Click',
                  label: buttonText ? buttonText : 'I want this option',
                  did,
                  dealerUserId,
                });
                submitMindfire();
                setApplicationPromotion(applicationId, promotionId, nextPage);
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => ({
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  isConsumer: state.preQualificationInformation.isConsumer,
});

export default connect(mapStateToProps)(AquaPromotion);
