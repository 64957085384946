import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import Button from '../../../../components/UI/Button/CustomButton';
import Checkbox from '../../../../components/UI/Form/Checkbox/Checkbox';
import { reactGAEvent, reactGAPageview } from '../../../../helper';
import {
  changePage,
  submitMindfire,
  changeGreenSkyApplicationInfoValue,
  getDisclosureFooter,
} from '../../../../redux/actions/index';
import { AtWaterGreenSkyFlowPageNumber } from './GreenSkyFlow';
import greenSkyLogo from '../../../../assets/img/greenSkyLogo.png';
import Disclosure from '../Components/Disclosure';

const Consent = props => {
  const {
    dispatchChangePage,
    consent,
    dispatchChangeGreenSkyApplicationInfoValue,
    did,
    dealerUserId,
    dispatchSubmitMindfire,
    dispatchGetDisclosureFooter,
  } = props;

  useEffect(() => {
    dispatchGetDisclosureFooter();
    reactGAPageview({
      path: '/ca_gsconsent',
      trackerNames: [],
      title: 'ca_gsconsent',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Continue',
      did,
      dealerUserId,
    });
    dispatchSubmitMindfire({
      lastPage: '9009',
      dealerUserId: dealerUserId,
      did: did,
    });
    dispatchChangeGreenSkyApplicationInfoValue('consent', true);
    dispatchChangePage(AtWaterGreenSkyFlowPageNumber.LoanInformation);
  };

  const ConsentForm = () => (
    <Form noValidate autoComplete="off">
      <Field
        component={Checkbox}
        name="consent"
        id="consent"
        label="Applicant"
        labelClassName="bold mb-0"
      />
      <Button type="submit" title={'Continue'} />
      <Disclosure />
    </Form>
  );

  const ConsentFormFormik = withFormik({
    mapPropsToValues: () => ({
      consent: consent || false,
    }),

    validationSchema: Yup.object({
      consent: Yup.boolean().oneOf([true], 'Please check to continue.'),
    }),

    handleSubmit,
  })(ConsentForm);

  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs="auto" sm="11">
          <Row className="justify-content-center">
            <Col xs="auto">
              <h3>Nice! You have been prequalified for a lending option with:</h3>
            </Col>
          </Row>

          <Row className="justify-content-center mt-5">
            <Col xs="auto">
              <img src={greenSkyLogo} alt="Greensky logo" height="75px" />
            </Col>
          </Row>

          <Row className="justify-content-center mt-5">
            <Col md="8">
              <h4>Consent</h4>
              <p>
                I acknowledge that by checking the box below and pressing continue that, upon
                completion, the information on the application will be sent to the lenders
                participating in the GreenSky® Programs.
              </p>
              <ConsentFormFormik />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  consent: state.greenSkyApplicationInformation.consent,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeGreenSkyApplicationInfoValue: (key, value) =>
    dispatch(changeGreenSkyApplicationInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchGetDisclosureFooter: () => dispatch(getDisclosureFooter()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Consent),
);
