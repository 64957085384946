import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import employmentStatus from '../../../../assets/js/EmploymentStatusEnum';
import incomeFrequencyEnum from '../../../../assets/js/IncomeFrequencyEnum';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import CustomInput from '../../../../components/UI/Form/Input/CustomInput';
import CurrencyFormat from '../../../../components/UI/Form/NumberFormat/CurrencyFormat';
import SelectWithHandleChange from '../../../../components/UI/Form/Select/SelectWithHandleChange';
import { changeApplicationValues, reactGAEvent, reactGAPageview } from '../../../../helper';
import {
  changeFoundationInfoValue,
  changePage,
  submitMindfire,
} from '../../../../redux/actions/index';
import { FoundationFlowPageNumber } from './FoundationFlow';
import {
  EMPLOYER_NAME_TOOLTIP_TEXT,
  EMPLOYMENT_MONTHS_TOOLTIP_TEXT,
  EMPLOYMENT_STATUS_TOOLTIP_TEXT,
  EMPLOYMENT_YEARS_TOOLTIP_TEXT,
  ESTIMATED_GROSS_ANNUAL_INCOME_TOOLTIP_TEXT,
  GROSS_INCOME_TOOLTIP_TEXT,
  INCOME_FREQUENCY_TOOLTIP_TEXT,
  OCCUPATION_TOOLTIP_TEXT,
} from '../../../../constants/tooltipTexts';

const AppEmploymentDetails = props => {
  const {
    email,
    dispatchChangePage,
    dispatchChangeFoundationInfoValue,
    foundationInformation,
    children,
    isReview,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
  } = props;
  let isEmployed =
    foundationInformation.appEmploymentStatus === employmentStatus.FullTime.value ||
    foundationInformation.appEmploymentStatus === employmentStatus.PartTime.value ||
    foundationInformation.appEmploymentStatus === employmentStatus.SelfEmployed.value;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_fdconsumerincome1',
      trackerNames: [],
      title: 'ca_fdconsumerincome1',
      dealerUserId,
      did,
    });
    // eslint-disable-next-line
  }, []);

  const changeValues = values => {
    isEmployed =
      foundationInformation.appEmploymentStatus === employmentStatus.FullTime.value ||
      foundationInformation.appEmploymentStatus === employmentStatus.PartTime.value ||
      foundationInformation.appEmploymentStatus === employmentStatus.SelfEmployed.value;

    dispatchChangeFoundationInfoValue(
      'appEmployerName',
      isEmployed ? values.appEmployerName : null,
    );
    dispatchChangeFoundationInfoValue('appEmploymentStatus', values.appEmploymentStatus);
    dispatchChangeFoundationInfoValue('appOccupation', isEmployed ? values.appOccupation : null);
    dispatchChangeFoundationInfoValue(
      'appEmploymentYears',
      isEmployed ? parseInt(values.appEmploymentYears) : null,
    );
    dispatchChangeFoundationInfoValue(
      'appEmploymentMonths',
      isEmployed ? parseInt(values.appEmploymentMonths) : null,
    );
    dispatchChangeFoundationInfoValue('appGrossIncome', values.appGrossIncome);
    dispatchChangeFoundationInfoValue('appIncomeFrequency', values.appIncomeFrequency);
    dispatchChangeFoundationInfoValue(
      'appEstimatedsAnnualGrosIncome',
      values.appEstimatedsAnnualGrosIncome,
    );
  };

  const calculateEstimatedAnualIncomeBasedOnFrequency = (incomeFrequency, grossIncome) => {
    dispatchChangeFoundationInfoValue('appIncomeFrequency', incomeFrequency);
    if (grossIncome) {
      const frequency = Object.values(incomeFrequencyEnum).find(
        freq => freq.value === incomeFrequency,
      )?.numericValue;
      dispatchChangeFoundationInfoValue('appEstimatedsAnnualGrosIncome', frequency * grossIncome);
    }
  };

  const calculateEstimatedAnualIncomeBasedOnIncome = (income, setFieldValue) => {
    const incomeFrequency = Object.values(incomeFrequencyEnum).find(
      freq => freq.value === foundationInformation.appIncomeFrequency,
    )?.numericValue;
    if (incomeFrequency) {
      setFieldValue('appEstimatedsAnnualGrosIncome', incomeFrequency * income);
    }
  };

  const AppEmploymentDetailsForm = ({ values, setFieldValue }) => (
    <Form>
      <Field
        component={SelectWithHandleChange}
        label="Income (or benefits) Type"
        id="appEmploymentStatus"
        tooltipText={EMPLOYMENT_STATUS_TOOLTIP_TEXT}
        name="appEmploymentStatus"
        type="select"
        selectValues={employmentStatus}
        selectFirstName="Select One"
        handleChange={e => changeValues({ ...values, appEmploymentStatus: e.target.value })}
      />
      {isEmployed ? (
        <>
          <Field
            component={CustomInput}
            label="Employer"
            name="appEmployerName"
            id="appEmployerName"
            tooltipText={EMPLOYER_NAME_TOOLTIP_TEXT}
            type="text"
          />
          <Field
            component={CustomInput}
            label="Title/Occupation"
            name="appOccupation"
            id="appOccupation"
            tooltipText={OCCUPATION_TOOLTIP_TEXT}
            type="text"
          />
          <Row className="mt-4">
            <Col>
              <Label>
                <b>Years at employer</b>
              </Label>
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <Field
                component={CustomInput}
                placeholder="Years"
                formGroupClass="mt-0 mb-2"
                labelClassName="d-none"
                label="Years"
                name="appEmploymentYears"
                id="appEmploymentYears"
                tooltipText={EMPLOYMENT_YEARS_TOOLTIP_TEXT}
                type="number"
                min={0}
              />
            </Col>
            <Col sm="6">
              <Field
                component={CustomInput}
                placeholder="Months"
                formGroupClass="mt-0 mb-2"
                labelClassName="d-none"
                label="Months"
                name="appEmploymentMonths"
                id="appEmploymentMonths"
                tooltipText={EMPLOYMENT_MONTHS_TOOLTIP_TEXT}
                type="number"
                min={0}
              />
            </Col>
          </Row>
        </>
      ) : null}
      <Field
        component={CurrencyFormat}
        label="Gross Income (or benefits)"
        name="appGrossIncome"
        id="appGrossIncome"
        tooltipText={GROSS_INCOME_TOOLTIP_TEXT}
        type="text"
        handleChange={v => {
          calculateEstimatedAnualIncomeBasedOnIncome(v.floatValue, setFieldValue);
        }}
      />

      <Field
        component={SelectWithHandleChange}
        label="Frequency"
        name="appIncomeFrequency"
        id="appIncomeFrequency"
        tooltipText={INCOME_FREQUENCY_TOOLTIP_TEXT}
        type="select"
        selectValues={incomeFrequencyEnum}
        selectFirstName="Select Frequency"
        handleChange={f => {
          changeApplicationValues(dispatchChangeFoundationInfoValue, values);
          calculateEstimatedAnualIncomeBasedOnFrequency(f.target.value, values.appGrossIncome);
        }}
      />

      <Field
        disabled={true}
        component={CurrencyFormat}
        label="Estimated Annual Gross Income (or benefits)"
        name="appEstimatedsAnnualGrosIncome"
        id="appEstimatedsAnnualGrosIncome"
        tooltipText={ESTIMATED_GROSS_ANNUAL_INCOME_TOOLTIP_TEXT}
        type="text"
      />
      <Button type="submit" title="Next" />
    </Form>
  );

  const AppEmploymentDetailsFormFormik = withFormik({
    mapPropsToValues: () => ({
      appEmploymentStatus: foundationInformation.appEmploymentStatus || '',
      appEmployerName: foundationInformation.appEmployerName || '',
      appOccupation: foundationInformation.appOccupation || '',
      appEmploymentYears:
        foundationInformation.appEmploymentYears === 0
          ? 0
          : foundationInformation.appEmploymentYears || '',
      appEmploymentMonths:
        foundationInformation.appEmploymentMonths === 0
          ? 0
          : foundationInformation.appEmploymentMonths || '',
      appGrossIncome: foundationInformation.appGrossIncome || '',
      appIncomeFrequency: foundationInformation.appIncomeFrequency || '',
      appEstimatedsAnnualGrosIncome: foundationInformation.appEstimatedsAnnualGrosIncome || '',
    }),

    validationSchema: Yup.object({
      appEmploymentStatus: Yup.string().required('Please select the employment status.'),
      appGrossIncome: Yup.number().required('Please enter your gross income.'),
      appEstimatedsAnnualGrosIncome: Yup.number().required(
        'Please enter your gross income and income frequency.',
      ),
      appIncomeFrequency: Yup.string().required('Please select a Frequency.'),
      appEmployerName: isEmployed
        ? Yup.string().required("Please enter your employer's name.")
        : Yup.string(),
      appOccupation: isEmployed
        ? Yup.string().required('Please enter your occupation.')
        : Yup.string(),
      appEmploymentYears: isEmployed
        ? Yup.number()
            .moreThan(-1, 'Please enter a positive number.')
            .integer('Please enter an integer number.')
            .max(70, 'Must be less than or equal to 70.')
            .required('Please enter years.')
        : Yup.number(),
      appEmploymentMonths: isEmployed
        ? Yup.number()
            .moreThan(-1, 'Please enter a positive number.')
            .integer('Please enter an integer number.')
            .max(11, 'Must be less than or equal to 11.')
            .required('Please enter months.')
        : Yup.number(),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did: did,
        dealerUserId: dealerUserId,
      });
      changeValues(values);
      dispatchChangePage(FoundationFlowPageNumber.AppOtherSourceOfIncome);
      dispatchSubmitMindfire({
        email: email,
        company: values.appEmployerName,
        lastPage: '10014',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(AppEmploymentDetailsForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={
            isReview
              ? FoundationFlowPageNumber.ApplicationReview
              : FoundationFlowPageNumber.InstallAddress
          }
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <AppEmploymentDetailsFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  foundationInformation: state.foundationInformation,
  email: state.preQualificationInformation.email,
  isReview: state.foundationInformation.isReview,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeFoundationInfoValue: (key, value) =>
    dispatch(changeFoundationInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

AppEmploymentDetails.propTypes = {
  foundationInformation: PropTypes.object,
  dispatchChangePage: PropTypes.func,
  dispatchChangeFoundationInfoValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppEmploymentDetails);
