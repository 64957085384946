import React, { useEffect } from 'react';
import { Col, Container, Row, Button } from 'reactstrap';
import { reactGAPageview, reactGAEvent, isStateAllowed } from '../../helper';
import { microfFlow } from '../../redux/initialState';
import selectedFinancierEnum from '../../assets/js/SelectedFinancierEnum';
import { connect } from 'react-redux';
import { changePage, resetState } from '../../redux/actions';
import { AquaPreQualificationFlowPageNumber } from '../PreQualification/preQualificationFlow';
import { MicrofFlowPageNumber } from '../Microf/MicrofFlow';
import { Link } from 'react-router-dom';
import CustomButton from '../../components/UI/Button/CustomButton';
import AirConditioningSystemEnum from '../../assets/js/AirConditioningSystemEnum';

const Declined = props => {
  const {
    user,
    email,
    dealerUserId,
    did,
    consumerToken,
    history,
    wellsFargoInformation,
    dispatchSetFlow,
    dispatchSubmitMindfire,
    dispatchUpdateSelectedFinancier,
    dispatchMapWellsFargoToMicrof,
    isConsumer,
    dispatchChangePage,
    dispatchResetState,
    microfAssigned,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/wf_declined',
      trackerNames: [],
      title: 'wf_declined',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const handleClick = async () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Continue to Microf',
      did,
      dealerUserId,
    });
    dispatchSubmitMindfire({
      email: email,
      lastPage: '5013',
      dealerUserId: dealerUserId,
      did: did,
    });
    await dispatchUpdateSelectedFinancier(selectedFinancierEnum.MICROF.value, consumerToken, () => {
      dispatchMapWellsFargoToMicrof(wellsFargoInformation);
      dispatchSetFlow(microfFlow);
      dispatchChangePage(MicrofFlowPageNumber.AccountEmail);
      history.push('/RTO');
    });
  };

  const OutOfTradeArea = () => {
    return (
      <Container>
        <Row className="justify-content-center mt-2">
          <Col xs="auto" sm="8" className="text-center">
            <h5>
              <b>
                Wells Fargo was unable to approve your credit application at this time. You will
                receive a notification via email within 30 days.
              </b>
            </h5>
          </Col>
        </Row>
        <Row className="justify-content-center mt-5">
          <Col xs="10" md="3">
            <Link
              to={user?.dealerUser ? '/portal/dashboard' : ''}
              onClick={() => !user?.dealerUser && dispatchResetState({})}
              style={{ textDecoration: 'none' }}
              className="block"
            >
              <CustomButton
                color="secondary"
                size="lg"
                block
                type="button"
                title={user?.dealerUser ? 'Return to dashboard' : 'Exit'}
              />
            </Link>
          </Col>
        </Row>
      </Container>
    );
  };

  const ExitBtnComponent = () => {
    return (
      <Row className="justify-content-center mt-2">
        <Col xs="12" md="2">
          <Link
            to={user && user.dealerUser ? '/portal/dashboard' : ''}
            onClick={() => !(user && user.dealerUser) && dispatchResetState({})}
            style={{ textDecoration: 'none' }}
            className="block"
          >
            <CustomButton
              color="secondary"
              size="lg"
              block
              type="button"
              title={user && user.dealerUser ? 'Back to dashboard' : 'Exit'}
            />
          </Link>
        </Col>
      </Row>
    );
  };

  if (
    !isStateAllowed(wellsFargoInformation?.state) ||
    !microfAssigned ||
    wellsFargoInformation.airConditioningSystem !==
      AirConditioningSystemEnum.AIR_CONDITION_OR_HEATING_SYSTEM.id
  ) {
    return OutOfTradeArea();
  } else if (
    wellsFargoInformation.airConditioningSystem ===
      AirConditioningSystemEnum.AIR_CONDITION_OR_HEATING_SYSTEM.id &&
    microfAssigned
  ) {
    return (
      <Container>
        <Row className="justify-content-center mt-2">
          <Col xs="auto" sm="8" className="text-center">
            <h5>
              <b>
                Wells Fargo was unable to approve your credit application at this time. You will
                receive a notification via email within 30 days.
              </b>
            </h5>
          </Col>
        </Row>
        <Row className="justify-content-center mt-2">
          <Col xs="auto" sm="8" className="text-center">
            <h5>
              <b>
                But Wait! You may still qualify for a lease purchase option. A lease purchase option
                is different than financing.
              </b>
            </h5>
          </Col>
        </Row>

        <Container>
          <Row className="justify-content-center mt-2">
            <Col xs="auto" sm="12" className="text-center">
              <p className="fs-25">
                <b className="primary">
                  Congratulations! You've prequalified for a Microf Lease Offer
                </b>
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center ">
            <Col xs="auto" lg="8" sm="12" md="12">
              <p className="fs-22 fw-500 pl-40">
                Microf’s lease purchase option is different than financing:
              </p>
              <ul className="powerpay-microf-lease-purchase-options-arrow">
                <li>
                  Pay as you go!{' '}
                  <span className="fw-normal">
                    Monthly renewals up to 60 months to own in most states.
                  </span>
                </li>
                <li>
                  Own the item(s) after paying the total of payments to ownership.{' '}
                  <span className="fw-normal">
                    Substantial savings are available through Early Purchase Options!
                  </span>
                </li>
                <li>
                  Damaged credit?{' '}
                  <span className="fw-normal">
                    Microf regularly approves applicants with less than perfect credit.
                  </span>
                </li>
                <li>
                  Have questions?{' '}
                  <span className="fw-normal">
                    Call us at 855-642-7631 or email us at info@microf.com.
                  </span>
                </li>
              </ul>
            </Col>
          </Row>
          <Row className="justify-content-center ">
            <Col xs="auto" lg="8" sm="12" md="12">
              <p className="fs-22 fw-500 pl-40">
                Here is what you will need to complete the application:
              </p>
              <ul className="microf-lease-purchase-options">
                <li>Social Security Number</li>
                <li>Bank account information (routing and account numbers)</li>
                <li>Income or benefits information (e.g., paystub, bank statement, etc.)</li>
                <li>Co-applicant information (not required)</li>
              </ul>
            </Col>
          </Row>
          <Row className="justify-content-center ">
            <Col xs="auto" lg="8" sm="12" md="12">
              <p className="fs-22 fw-500 pl-40">Not eligible for lease purchase:</p>
              <ul className="powerpay-microf-lease-purchase-options-x">
                <li>Homes on rented land (e.g., mobile homes in a park)</li>
                <li>Reverse mortgages</li>
                <li>Rent-to-own or lease-to-own properties</li>
                <li>Homeowners with active or dismissed bankruptcies</li>
              </ul>
            </Col>
          </Row>
        </Container>

        <Row className="mt-3 justify-content-center">
          <Col xs="12" md="auto">
            <Button color="primary" onClick={handleClick}>
              {isConsumer ? 'Apply Now' : 'Continue to Microf'}
            </Button>
          </Col>
        </Row>
      </Container>
    );
  } else {
    return (
      <Container>
        <Row className="justify-content-center mt-2">
          <Col xs="auto" sm="8" className="text-center">
            <h5>
              <b>
                Wells Fargo was unable to approve your credit application at this time. You will
                receive a notification via email within 30 days.
              </b>
            </h5>
          </Col>
        </Row>
        <ExitBtnComponent />
      </Container>
    );
  }
};

const mapStateToProps = state => ({
  user: state.auth.user,
  microfAssigned: state.auth.user?.user?.dealerStatsSummaryDTO?.microfAssigned,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchResetState: props => dispatch(resetState(props)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Declined);
