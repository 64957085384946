import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const ApplicationFinished = () => (
  <Container>
    <Row className="justify-content-center mt-2">
      <Col xs="12" md="auto">
        <h4>The application is finished, please close the tab.</h4>
      </Col>
    </Row>
  </Container>
);

export default ApplicationFinished;
