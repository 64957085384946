import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import Button from '../../../../components/UI/Button/CustomButton';
import Checkbox from '../../../../components/UI/Form/Checkbox/Checkbox';
import { reactGAEvent, reactGAPageview } from '../../../../helper';
import {
  changePage,
  submitMindfire,
  changeFoundationInfoValue,
} from '../../../../redux/actions/index';
import { FoundationFlowPageNumber } from './FoundationFlow';
import FoundationLogo from '../../../../components/UI/Optimus/FoundationLogo';
import '../Foundation.scss';
import { getFoundationDisclosuresUrl } from '../../../../config/settings';
import AirConditioningSystemEnum from '../../../../assets/js/AirConditioningSystemEnum';
import { OPTIMUS_COAPP_AUTHORIZE_TOOLTIP_TEXT } from '../../../../constants/tooltipTexts';

const foundationDisclosuresUrl = getFoundationDisclosuresUrl();

const Consent = props => {
  const {
    dispatchChangePage,
    consent,
    dispatchChangeFoundationInfoValue,
    did,
    dealerUserId,
    dispatchSubmitMindfire,
    airConditioningSystem,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_fdconsent',
      trackerNames: [],
      title: 'ca_fdconsent',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Continue',
      did,
      dealerUserId,
    });
    dispatchSubmitMindfire({
      lastPage: '10009',
      dealerUserId: dealerUserId,
      did: did,
    });
    dispatchChangeFoundationInfoValue('consent', true);
    if (airConditioningSystem === AirConditioningSystemEnum.AIR_CONDITION_OR_HEATING_SYSTEM.id) {
      dispatchChangePage(FoundationFlowPageNumber.LoanAmount);
    } else {
      dispatchChangePage(FoundationFlowPageNumber.HomeImprovementProductType);
    }
  };

  const ConsentForm = () => (
    <Form noValidate autoComplete="off">
      <Field
        component={Checkbox}
        name="consent"
        id="consent"
        tooltipText={OPTIMUS_COAPP_AUTHORIZE_TOOLTIP_TEXT}
        label="Applicant"
        labelClassName="bold mb-0"
      />
      <Button type="submit" title={'Continue'} />
    </Form>
  );

  const ConsentFormFormik = withFormik({
    mapPropsToValues: () => ({
      consent: consent,
    }),

    validationSchema: Yup.object({
      consent: Yup.boolean().oneOf([true], 'Please check to continue.'),
    }),

    handleSubmit,
  })(ConsentForm);

  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs="auto" sm="11">
          <Row className="justify-content-center">
            <Col xs="auto">
              <h3>Nice! You may qualify for a financing option with:</h3>
            </Col>
          </Row>
          <Row className="justify-content-center mt-2">
            <Col xs="auto">
              <FoundationLogo />
            </Col>
          </Row>
          <Row className="justify-content-center mt-5">
            <Col md="8">
              <h5>
                Consumer Credit Disclosures:{' '}
                <a
                  className="font-16"
                  href={foundationDisclosuresUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {foundationDisclosuresUrl}
                </a>
              </h5>
            </Col>
          </Row>
          <Row className="justify-content-center mt-2">
            <Col md="8">
              <h4>Consent</h4>
              <p>
                I acknowledge that by checking the box below and pressing continue that the info on
                this application will be sent to Foundation Finance Company for the purpose of
                completing a credit application and a hard credit pull will be completed upon
                application submission.
              </p>
              <ConsentFormFormik />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  consent: state.foundationInformation.consent,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  airConditioningSystem: state.preQualificationInformation.airConditioningSystem,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeFoundationInfoValue: (key, value) =>
    dispatch(changeFoundationInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Consent),
);
