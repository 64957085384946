import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import { useVSPixelScript } from '../../../assets/hooks';
import { reactGAEvent, reactGAPageview } from '../../../helper';
import { changePage, submitMindfire } from '../../../redux/actions/index';
import { MosaicFlowPageNumber } from './MosaicFlow';

const ApprovedRedirect = props => {
  const { email, dispatchChangePage, dealerUserId, did, dispatchSubmitMindfire } = props;

  useVSPixelScript();

  useEffect(() => {
    reactGAPageview({
      path: '/ca_ppapprovedredirect',
      trackerNames: [],
      title: 'ca_ppapprovedredirect',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const mindFireRequest = {
    email: email,
    lastPage: '6021',
    dealerUserId: dealerUserId,
    did: did,
  };

  const handleButtonClick = () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Done',
      did,
      dealerUserId,
    });
    dispatchSubmitMindfire(mindFireRequest);
    dispatchChangePage(MosaicFlowPageNumber.ApprovedRedirect);
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col sm="1" />
        <Col xs="auto" sm="11">
          <Row className="justify-content-center mt-2">
            <Col xs="auto">
              <h5>
                <b>Application Approved</b>
              </h5>
            </Col>
          </Row>
          <Row className="justify-content-center mt-2">
            <Col xs="auto">
              <h5 className="text-center">
                <b>Please hand device back to the contractor to continue Consumer – do this</b>
              </h5>
            </Col>
          </Row>
          <Row className="justify-content-center mt-2">
            <Col xs="auto">&nbsp;</Col>
          </Row>
          <Row className="justify-content-center mt-2">
            <Col xs="auto">
              <h5>
                <b>Contractor</b>
              </h5>
            </Col>
          </Row>
          <Row className="justify-content-center mt-2">
            <Col xs="auto">
              <h5 className="text-center">
                <b>Please press Next to continue</b>
              </h5>
            </Col>
          </Row>
          <Row className="justify-content-center mt-2">
            <Col xs="12" md="4">
              <Button color="primary" onClick={handleButtonClick}>
                Next
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  status: state.softPull.applicationSubmitDTO && state.softPull.applicationSubmitDTO.status,
  consumerId: state.auth.consumerId,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ApprovedRedirect),
);
