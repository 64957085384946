import axios from 'axios';
import { changeSoftPullValue } from '.';
import { getAddedByAPIValue, isApplicantEmployedFTL, isSameAddress } from '../../helper';
import {
  CHANGE_FTL_INFO_VALUE,
  SUBMIT_APPLICATION_SOFT_PULL_ERROR,
  SUBMIT_APPLICATION_SOFT_PULL_SUCCESS,
} from './actionTypes';
import { baseURL, handleApiError } from './api';
import { changePage } from './naviActions';
import { addNotification, endLoading, startLoading } from './notificationActions';
import { changePreQualInfoValue } from './preQualInfoActions';
import { checkErrors, mapApplicationToPrequalification } from './softPullActions';
const qs = require('qs');

export const changeFtlInfoValue = (key, value) => ({
  type: CHANGE_FTL_INFO_VALUE,
  key,
  value,
});

export const submitFTLApplication = (params, page, app) => async dispatch => {
  dispatch(
    startLoading(
      'Give us a second while we find the best ' +
        params.selectedFinancier +
        ' financing option for you.',
    ),
  );

  const postUrl = `${baseURL}/api/soft_pull/ftl/${app ? 're' : ''}submit`;
  const postData = app
    ? {
        selectedFinancier: `${encodeURIComponent(params.selectedFinancier)}`,
        appId: `${encodeURIComponent(app.applicationId)}`,
        data: '' + JSON.stringify(params),
      }
    : { data: '' + JSON.stringify(params) };

  return await axios
    .post(postUrl, qs.stringify(postData))
    .then(async response => {
      dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_SUCCESS, payload: response.data });
      if (dispatch(checkErrors(response))) {
        dispatch(changePage(page));
      }
      dispatch(endLoading());
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_ERROR, payload: error });
      dispatch(endLoading());
    });
};

export const mapFTLToState = app => dispatch => {
  if (app) {
    const { ftlinformation } = app;
    dispatch(mapApplicationToPrequalification(app));
    dispatch(changeFtlInfoValue('consent', true));

    if (ftlinformation) {
      if (ftlinformation.appEmployerAddress) {
        const address = ftlinformation.appEmployerAddress;
        dispatch(changeFtlInfoValue('appEmployerCity', address.city));
        dispatch(changeFtlInfoValue('appEmployerState', address.state));
        dispatch(changeFtlInfoValue('appEmployerStreet', address.street));
        dispatch(changeFtlInfoValue('appEmployerZipCode', address.zipcode));
      }

      if (ftlinformation.coAppEmployerAddress) {
        const address = ftlinformation.coAppEmployerAddress;
        dispatch(changeFtlInfoValue('coAppEmployerCity', address.city));
        dispatch(changeFtlInfoValue('coAppEmployerState', address.state));
        dispatch(changeFtlInfoValue('coAppEmployerStreet', address.street));
        dispatch(changeFtlInfoValue('coAppEmployerZipCode', address.zipcode));
      }

      dispatch(changeFtlInfoValue('appEmployerPhoneNumber', ftlinformation.employerPhoneNumber));
      dispatch(
        changeFtlInfoValue('coAppEmployerPhoneNumber', ftlinformation.coAppEmployerPhoneNumber),
      );
    }

    dispatch(changeFtlInfoValue('borrowedAmount', app.totalFinancingAmount));
    if (app.applicantPrimary) {
      const applicant = app.applicantPrimary;
      dispatch(
        changeFtlInfoValue('isAppEmployed', isApplicantEmployedFTL(applicant.employmentType)),
      );
      dispatch(changeFtlInfoValue('appAnnualIncome', applicant.grossYearlyIncome));
      dispatch(changeFtlInfoValue('appEmployerName', applicant.employer));
      dispatch(changeFtlInfoValue('appEmploymentStatus', applicant.employmentType));
      dispatch(changeFtlInfoValue('appOccupation', applicant.occupation));

      dispatch(changePreQualInfoValue('firstName', applicant.nameFirst));
      dispatch(changePreQualInfoValue('middleName', applicant.nameMiddle));
      dispatch(changePreQualInfoValue('lastName', applicant.nameLast));
      dispatch(changePreQualInfoValue('phoneNumber', applicant.mobilePhone?.number));
      dispatch(changePreQualInfoValue('birthday', getAddedByAPIValue(applicant.birthDate) || null));

      if (applicant.address) {
        const address = applicant.address;
        dispatch(
          changePreQualInfoValue('isSameAddress', isSameAddress(address, app.propertyAddress)),
        );
        dispatch(changePreQualInfoValue('applicantApartmentNumber', address.apartmentNumber));
        dispatch(changePreQualInfoValue('applicantCity', address.city));
        dispatch(changePreQualInfoValue('applicantState', address.state));
        dispatch(changePreQualInfoValue('applicantStreet', address.street));
        dispatch(changePreQualInfoValue('applicantZipcode', address.zipcode));
      }

      dispatch(changeFtlInfoValue('appFullSSN', getAddedByAPIValue(applicant.ssn)));
    }

    if (app.applicantNonPrimary) {
      const coapp = app.applicantNonPrimary;
      dispatch(changeFtlInfoValue('hasCoApp', true));

      dispatch(changeFtlInfoValue('isCoAppEmployed', isApplicantEmployedFTL(coapp.employmentType)));
      dispatch(changeFtlInfoValue('coAppAnnualIncome', coapp.grossYearlyIncome));
      dispatch(changeFtlInfoValue('coAppEmployerName', coapp.employer));
      dispatch(changeFtlInfoValue('coAppEmploymentStatus', coapp.employmentType));
      dispatch(changeFtlInfoValue('coAppOccupation', coapp.occupation));

      dispatch(changeFtlInfoValue('coAppFirstName', coapp.nameFirst));
      dispatch(changeFtlInfoValue('coAppMiddleInitial', coapp.nameMiddle));
      dispatch(changeFtlInfoValue('coAppLastName', coapp.nameLast));
      dispatch(changeFtlInfoValue('coAppPhoneNumber', coapp.mobilePhone?.number));
      dispatch(changeFtlInfoValue('coAppEmail', coapp.email?.email));
      dispatch(changeFtlInfoValue('coAppDoB', coapp.birthDate));
      dispatch(changeFtlInfoValue('coAppFullSsn', coapp.ssn));

      if (coapp.address) {
        const address = coapp.address;

        dispatch(
          changePreQualInfoValue(
            'isCoAppSameAddress',
            isSameAddress(address, app.applicantPrimary.address),
          ),
        );
        dispatch(changePreQualInfoValue('coApplicantApartmentNumber', address.apartmentNumber));
        dispatch(changePreQualInfoValue('coApplicantCity', address.city));
        dispatch(changePreQualInfoValue('coApplicantState', address.state));
        dispatch(changePreQualInfoValue('coApplicantStreet', address.street));
        dispatch(changePreQualInfoValue('coApplicantZipcode', address.zipcode));
      }
    }
  }
};

export const mapAppToFtl = app => dispatch => {
  if (app) {
    const { ftlinformation } = app;
    dispatch(mapApplicationToPrequalification(app));
    dispatch(changeFtlInfoValue('consent', true));

    if (ftlinformation) {
      if (ftlinformation.appEmployerAddress) {
        const address = ftlinformation.appEmployerAddress;
        dispatch(changeFtlInfoValue('appEmployerCity', address.city));
        dispatch(changeFtlInfoValue('appEmployerState', address.state));
        dispatch(changeFtlInfoValue('appEmployerStreet', address.street));
        dispatch(changeFtlInfoValue('appEmployerZipCode', address.zipcode));
      }

      if (ftlinformation.coAppEmployerAddress) {
        const address = ftlinformation.coAppEmployerAddress;
        dispatch(changeFtlInfoValue('coAppEmployerCity', address.city));
        dispatch(changeFtlInfoValue('coAppEmployerState', address.state));
        dispatch(changeFtlInfoValue('coAppEmployerStreet', address.street));
        dispatch(changeFtlInfoValue('coAppEmployerZipCode', address.zipcode));
      }

      dispatch(changeFtlInfoValue('appEmployerPhoneNumber', ftlinformation.employerPhoneNumber));
      dispatch(
        changeFtlInfoValue('coAppEmployerPhoneNumber', ftlinformation.coAppEmployerPhoneNumber),
      );
    }

    dispatch(changeFtlInfoValue('borrowedAmount', app.totalFinancingAmount));
    if (app.applicantPrimary) {
      const applicant = app.applicantPrimary;
      dispatch(
        changeFtlInfoValue('isAppEmployed', isApplicantEmployedFTL(applicant.employmentType)),
      );
      dispatch(changeFtlInfoValue('appAnnualIncome', applicant.grossYearlyIncome));
      dispatch(changeFtlInfoValue('appEmployerName', applicant.employer));
      dispatch(changeFtlInfoValue('appEmploymentStatus', applicant.employmentType));
      dispatch(changeFtlInfoValue('appOccupation', applicant.occupation));

      dispatch(changePreQualInfoValue('firstName', applicant.nameFirst));
      dispatch(changePreQualInfoValue('middleName', applicant.nameMiddle));
      dispatch(changePreQualInfoValue('lastName', applicant.nameLast));
      dispatch(changePreQualInfoValue('phoneNumber', applicant.mobilePhone?.number));
      dispatch(changePreQualInfoValue('birthday', getAddedByAPIValue(applicant.birthDate) || null));

      if (applicant.address) {
        const address = applicant.address;
        dispatch(
          changePreQualInfoValue('isSameAddress', isSameAddress(address, app.propertyAddress)),
        );
        dispatch(changePreQualInfoValue('applicantApartmentNumber', address.apartmentNumber));
        dispatch(changePreQualInfoValue('applicantCity', address.city));
        dispatch(changePreQualInfoValue('applicantState', address.state));
        dispatch(changePreQualInfoValue('applicantStreet', address.street));
        dispatch(changePreQualInfoValue('applicantZipcode', address.zipcode));
      }

      dispatch(changeFtlInfoValue('appFullSSN', getAddedByAPIValue(applicant.ssn)));
    }

    if (app.applicantNonPrimary) {
      const coapp = app.applicantNonPrimary;
      dispatch(changeFtlInfoValue('hasCoApp', true));

      dispatch(changeFtlInfoValue('isCoAppEmployed', isApplicantEmployedFTL(coapp.employmentType)));
      dispatch(changeFtlInfoValue('coAppAnnualIncome', coapp.grossYearlyIncome));
      dispatch(changeFtlInfoValue('coAppEmployerName', coapp.employer));
      dispatch(changeFtlInfoValue('coAppEmploymentStatus', coapp.employmentType));
      dispatch(changeFtlInfoValue('coAppOccupation', coapp.occupation));

      dispatch(changeFtlInfoValue('coAppFirstName', coapp.nameFirst));
      dispatch(changeFtlInfoValue('coAppMiddleInitial', coapp.nameMiddle));
      dispatch(changeFtlInfoValue('coAppLastName', coapp.nameLast));
      dispatch(changeFtlInfoValue('coAppPhoneNumber', coapp.mobilePhone?.number));
      dispatch(changeFtlInfoValue('coAppEmail', coapp.email?.email));
      dispatch(changeFtlInfoValue('coAppDoB', coapp.birthDate));
      dispatch(changeFtlInfoValue('coAppFullSsn', coapp.ssn));

      if (coapp.address) {
        const address = coapp.address;

        dispatch(
          changePreQualInfoValue(
            'isCoAppSameAddress',
            isSameAddress(address, app.applicantPrimary.address),
          ),
        );
        dispatch(changePreQualInfoValue('coApplicantApartmentNumber', address.apartmentNumber));
        dispatch(changePreQualInfoValue('coApplicantCity', address.city));
        dispatch(changePreQualInfoValue('coApplicantState', address.state));
        dispatch(changePreQualInfoValue('coApplicantStreet', address.street));
        dispatch(changePreQualInfoValue('coApplicantZipcode', address.zipcode));
      }
    }
  }
};

const createUploadRequestPayload = (applicantFiles, applicationId) => {
  let fd = new FormData();
  fd.append('applicationId', applicationId);

  if (!!applicantFiles[0]) {
    fd.append('proposal', applicantFiles[0]);
  }

  if (!!applicantFiles[1]) fd.append('proposal2', applicantFiles[1]);
  if (!!applicantFiles[2]) fd.append('proposal3', applicantFiles[2]);
  if (!!applicantFiles[3]) fd.append('proposal4', applicantFiles[3]);
  if (!!applicantFiles[4]) fd.append('proposal5', applicantFiles[4]);

  return fd;
};

export const uploadFTLDocumentsApplication = (applicantFiles, applicationId) => async dispatch => {
  dispatch(startLoading());
  dispatch(changeSoftPullValue('ftlUploadResponse', null));
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  let fd = createUploadRequestPayload(applicantFiles, applicationId);

  await axios
    .post(
      `${baseURL}/api/soft_pull/ftl/send_ftl_files?applicationId=${encodeURIComponent(
        applicationId,
      )}`,
      fd,
      config,
    )
    .then(response => {
      if (response.data) {
        dispatch(changeSoftPullValue('ftlUploadResponse', response.data));
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });

  dispatch(endLoading());
};

export const provideFundingInfo = (fundingInfoDocuments, applicationId, data) => async dispatch => {
  dispatch(startLoading());
  dispatch(changeSoftPullValue('ftlFundingInfoResponse', null));
  let fd = new FormData();
  if (!!fundingInfoDocuments[0]) {
    fd.append('proposal', fundingInfoDocuments[0]);
  }

  if (!!fundingInfoDocuments[1]) fd.append('proposal', fundingInfoDocuments[1]);
  if (!!fundingInfoDocuments[2]) fd.append('proposal', fundingInfoDocuments[2]);
  if (!!fundingInfoDocuments[3]) fd.append('proposal', fundingInfoDocuments[3]);
  if (!!fundingInfoDocuments[4]) fd.append('proposal', fundingInfoDocuments[4]);
  fd.append('accountHolderName', data.accountHolderName);
  fd.append('bankName', data.bankName);
  fd.append('bankCityAndState', data.bankCityAndState);
  fd.append('routingNumber', data.routingNumber);
  fd.append('accountNumber', data.accountNumber);
  fd.append('printName', data.printName);
  await axios
    .post(
      `${baseURL}/api/soft_pull/ftl/banking_info?applicationId=${encodeURIComponent(
        applicationId,
      )}`,
      fd,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
    .then(response => {
      if (response.data) {
        dispatch(changeSoftPullValue('ftlFundingInfoResponse', response.data));
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });

  dispatch(endLoading());
};

export const getFtlData = appId => async dispatch => {
  dispatch(changeSoftPullValue('ftlData', null));
  dispatch(changeSoftPullValue('ftlOptionsResponse', null));
  dispatch(changeSoftPullValue('ftlFetchProductsResponse', null));
  dispatch(startLoading('Get FTL Data with Id ' + appId + ' ...'));
  await axios
    .get(`${baseURL}/api/soft_pull/ftl/ftl_data?applicationId=${encodeURIComponent(appId)}`)
    .then(response => {
      if (response.data) {
        dispatch(changeSoftPullValue('ftlData', response.data));
        dispatch(getFtlDistributors(appId));
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(endLoading());
    });
};

export const getFtlDistributors = appId => async dispatch => {
  dispatch(changeSoftPullValue('ftlDistributors', null));
  dispatch(startLoading('Get FTL Distributors Id ' + appId + ' ...'));
  await axios
    .get(`${baseURL}/api/soft_pull/ftl/get_distributors?applicationId=${encodeURIComponent(appId)}`)
    .then(response => {
      if (response.data) {
        dispatch(changeSoftPullValue('ftlDistributors', response.data));
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const ftlSelectProgram = (appId, optionId) => async dispatch => {
  dispatch(changeSoftPullValue('ftlOptionsResponse', null));
  dispatch(startLoading('Selecting Option with ID ' + optionId + ' ...'));
  await axios
    .post(
      `${baseURL}/api/soft_pull/ftl/select_program?applicationId=${encodeURIComponent(
        appId,
      )}&selectedOption=${encodeURIComponent(optionId)}`,
    )
    .then(response => {
      if (response.data) {
        dispatch(changeSoftPullValue('ftlOptionsResponse', response.data));
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const ftlSelectLoanMoreProgram = (appId, optionId) => async dispatch => {
  dispatch(changeSoftPullValue('ftlOptionsResponse', null));
  dispatch(startLoading('Selecting Option with ID ' + optionId + ' ...'));
  await axios
    .post(
      `${baseURL}/api/soft_pull/ftl/select_loan_more?applicationId=${encodeURIComponent(
        appId,
      )}&selectedOption=${encodeURIComponent(optionId)}`,
    )
    .then(response => {
      if (response.data) {
        dispatch(changeSoftPullValue('ftlOptionsResponse', response.data));
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const ftlFetchProducts = (appId, reqAmount) => async dispatch => {
  dispatch(changeSoftPullValue('ftlFetchProductsResponse', null));
  dispatch(startLoading('FTL Fetching Products for Application with ID ' + appId + ' ...'));
  await axios({
    method: 'post',
    data: {
      requestAmount: reqAmount,
    },
    url: `${baseURL}/api/soft_pull/ftl/get_products?applicationId=${encodeURIComponent(appId)}`,
  })
    .then(response => {
      if (response.data) {
        dispatch(changeSoftPullValue('ftlFetchProductsResponse', response.data));
        dispatch(endLoading());
        dispatch(ftlSetAmount(appId, reqAmount));
      }
    })
    .catch(error => {
      dispatch(endLoading());
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
};

export const ftlSetAmount = (appId, reqAmount) => async dispatch => {
  dispatch(changeSoftPullValue('updateAmountResponse', null));
  dispatch(startLoading('FTL Update Amount for Application with ID ' + appId + ' ...'));
  await axios({
    method: 'post',
    url: `${baseURL}/api/soft_pull/ftl/set_amount?applicationId=${encodeURIComponent(
      appId,
    )}&requestAmount=${encodeURIComponent(reqAmount)}`,
  })
    .then(response => {
      if (response.data) {
        dispatch(changeSoftPullValue('updateAmountResponse', response.data));
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const printFundingInfo = (appId, amount, productId) => async dispatch => {
  dispatch(startLoading('Print Funding Info'));
  const data = {
    amount: amount,
    product: productId,
  };
  await axios({
    method: 'post',
    data: data,
    url: `${baseURL}/api/soft_pull/ftl/funding_info?applicationId=${encodeURIComponent(appId)}`,
    responseType: 'blob',
  })
    .then(response => {
      if (response.data) {
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], {
          type: 'application/pdf',
        });
        // //Build a URL from the file
        const fileURL = URL.createObjectURL(file);

        var win = window.open(fileURL);

        if (win.document) {
          win.document.write(
            '<html><head><title>Funding Info Document</title></head><body height="100%" width="100%"><iframe src="' +
              fileURL +
              '" height="100%" width="100%"></iframe></body></html>',
          );
        }
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const ftlGeneratePaperwork = (appId, data, history) => async dispatch => {
  dispatch(changeSoftPullValue('ftlGeneratePaperworkResponse', null));
  dispatch(startLoading('FTL Generate Paperwork for Application with ID ' + appId + ' ...'));
  await axios({
    method: 'post',
    data: data,
    url: `${baseURL}/api/soft_pull/ftl/generate_paperwork?applicationId=${encodeURIComponent(
      appId,
    )}`,
  })
    .then(response => {
      if (response.data && response.data.error) {
        dispatch(
          addNotification({
            message: handleApiError(response.data.error),
            messageType: 'Error',
          }),
          dispatch(changeSoftPullValue('ftlGeneratePaperworkResponse', response.data)),
        );
      }
      if (response.data) {
        dispatch(changeSoftPullValue('ftlGeneratePaperworkResponse', response.data));
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const ftlSendEquipmentInfo = (appId, data) => async dispatch => {
  dispatch(changeSoftPullValue('ftlSendEquipmentInfoResponse', null));
  dispatch(startLoading('FTL Send Equipment Info for Application with ID ' + appId + ' ...'));
  await axios({
    method: 'post',
    data: data,
    url: `${baseURL}/api/soft_pull/ftl/sending_equipment_service_info?applicationId=${encodeURIComponent(
      appId,
    )}`,
  })
    .then(response => {
      if (response.data && response.data.error) {
        dispatch(
          addNotification({
            message: handleApiError(response.data.error),
            messageType: 'Error',
          }),
        );
      } else {
        dispatch(changeSoftPullValue('ftlSendEquipmentInfoResponse', response.data));
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};
