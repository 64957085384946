import React from 'react';
import Consent from './Consent';
import LoanAmount from './LoanAmount';
import AppIdentificationInfo from './AppIdentificationInfo';
import PropertyDetails from './PropertyDetails';
import AppEmploymentDetails from './AppEmploymentDetails';
import HasCoApplicant from './HasCoApplicant';
import CoAppInfo from './CoAppInfo';
import CoAppAddress from './CoAppAddress';
import CoAppIdentificationInfo from './CoAppIdentificationInfo';
import CoAppEmploymentDetails from './CoAppEmploymentDetails';
import ApplicationReview from './ApplicationReview';
import ApplicationResult from './ApplicationResult';
import HomeImprovementProductType from './HomeImprovementProductType';
import InstallAddress from './InstallAddress';
import AppOtherSourceOfIncome from './AppOtherSourceOfIncome';
import CoAppOtherSourceOfIncome from './CoAppOtherSourceOfIncome';

const applicantInformationTitle = `Applicant Information`;
const coApplicantInformationTitle = 'Co-Applicant Information';
const applicationStatusTitle = 'Application Status';

const FoundationFlow = [
  //0
  <Consent />,
  //1
  <LoanAmount title={applicantInformationTitle}>
    <h4>What is your desired loan amount?</h4>
  </LoanAmount>,
  //2
  <HomeImprovementProductType>
    <h4>Please provide more information about the job.</h4>
  </HomeImprovementProductType>,
  //3
  <AppIdentificationInfo title={applicantInformationTitle}>
    <h4>Please provide your identification information.</h4>
    <p>
      Although a driver’s license number is not required to process the application, the contractor
      must review a photo ID and provide this information to FFC prior to receiving any funding
    </p>
  </AppIdentificationInfo>,
  //4
  <PropertyDetails title={applicantInformationTitle}>
    <h4>Tell us a little more about the installation address.</h4>
  </PropertyDetails>,
  //5
  <InstallAddress title={applicantInformationTitle}>
    <h4> Is your Primary Address the same as the Install Address?</h4>
  </InstallAddress>,
  //6
  <AppEmploymentDetails title={applicantInformationTitle}>
    <h4>Describe your income (or benefits).</h4>
  </AppEmploymentDetails>,
  //7
  <AppOtherSourceOfIncome title={applicantInformationTitle}>
    <h4> Tell us about any additional income (or benefits) you receive</h4>
  </AppOtherSourceOfIncome>,
  //8
  <HasCoApplicant title={coApplicantInformationTitle}>
    <h4>Would you like to add a Co-Applicant?</h4>
    <p>
      A co-applicant is <u>not</u> required.
    </p>
  </HasCoApplicant>,
  //9
  <CoAppInfo title={coApplicantInformationTitle}>
    <h4>Co-Applicant, enter your name and contact information.</h4>
  </CoAppInfo>,
  //10
  <CoAppAddress title={coApplicantInformationTitle}>
    <h4>Co-Applicant, enter your address.</h4>
  </CoAppAddress>,
  //11
  <CoAppIdentificationInfo title={coApplicantInformationTitle}>
    <h4>Co-Applicant, provide your identification information.</h4>
    <p>
      Although a driver’s license number is not required to process the application, the contractor
      must review a photo ID and provide this information to FFC prior to receiving any funding
    </p>
  </CoAppIdentificationInfo>,
  //12
  <CoAppEmploymentDetails title={coApplicantInformationTitle}>
    <h4>Co-Applicant, describe your income (or benefits).</h4>
  </CoAppEmploymentDetails>,
  <CoAppOtherSourceOfIncome>
    <h4>Co-Applicant, describe any additional income (or benefits)</h4>
  </CoAppOtherSourceOfIncome>,
  //13
  <ApplicationReview title={applicantInformationTitle}>
    <h4>Let’s double check your information and submit your application.</h4>
  </ApplicationReview>,
  //14
  <ApplicationResult title={applicationStatusTitle} />,
  //15
  // <ApprovedRedirect title={applicationStatusTitle} />,
];

export default FoundationFlow;

export const FoundationFlowPageNumber = {
  Consent: 0,
  LoanAmount: 1,
  HomeImprovementProductType: 2,
  AppIdentificationInfo: 3,
  PropertyDetails: 4,
  InstallAddress: 5,
  AppEmploymentDetails: 6,
  AppOtherSourceOfIncome: 7,
  HasCoApplicant: 8,
  CoAppInfo: 9,
  CoAppAddress: 10,
  CoAppIdentificationInfo: 11,
  CoAppEmploymentDetails: 12,
  CoAppOtherSourceOfIncome: 13,
  ApplicationReview: 14,
  ApplicationResult: 15,
  // ApprovedRedirect: 12,
};
