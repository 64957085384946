import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import CustomInput from '../../../components/UI/Form/Input/CustomInput';
import PhoneNumber from '../../../components/UI/Form/PhoneNumber/PhoneNumber';
import {
  CONTRACTOR_ID_TOOLTIP_TEXT,
  LOAN_CODE_TOOLTIP_TEXT,
  PROGRAM_PHONE_NUMBER_TOOLTIP_TEXT,
} from '../../../constants/tooltipTexts';
import { reactGAEvent, reactGAPageview } from '../../../helper';
import {
  changePage,
  changeEnerBankInfoValue,
  submitMindfire,
  getEnerBankProgram,
} from '../../../redux/actions/index';
import { EnerBankFlowPageNumber } from './EnerBankFlow';

const LoanInformation = props => {
  const {
    programPhoneNumber,
    loanCode,
    contractorId,
    dispatchChangePage,
    dispatchChangeInfoValue,
    children,
    did,
    dealerUserId,
    dispatchSubmitMindfire,
    enerBankContractorId,
    enerBankProgramNumber,
    dispatchGetEnerBankProgram,
    programProduct,
    programProjectTypes,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_ebloanamount',
      trackerNames: [],
      title: 'ca_ebloanamount',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const isValueChanged = values =>
    values.programPhoneNumber !== programPhoneNumber ||
    values.loanCode !== loanCode ||
    values.contractorId !== contractorId;

  const LoanInformationForm = () => (
    <Form>
      <Field
        component={PhoneNumber}
        label="Program Phone Number"
        name="programPhoneNumber"
        id="programPhoneNumber"
        tooltipText={PROGRAM_PHONE_NUMBER_TOOLTIP_TEXT}
      />
      <Field
        component={CustomInput}
        label="Loan Code"
        name="loanCode"
        id="loanCode"
        tooltipText={LOAN_CODE_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={CustomInput}
        label="Contractor ID"
        name="contractorId"
        id="contractorId"
        tooltipText={CONTRACTOR_ID_TOOLTIP_TEXT}
        type="text"
      />
      <Button type="submit" title="Next" />
    </Form>
  );

  const LoanInformationFormFormik = withFormik({
    mapPropsToValues: () => ({
      programPhoneNumber: programPhoneNumber || enerBankProgramNumber || '',
      loanCode: loanCode || '',
      contractorId: contractorId || enerBankContractorId || '',
    }),

    validationSchema: Yup.object({
      programPhoneNumber: Yup.string()
        .test('required', 'Please enter Program Phone Number.', val => val !== '+' && val)
        .test('len', 'Invalid phone number', val => val && val.match(/\d/g).join('').length === 10),
      loanCode: Yup.string().required('Please enter Loan Code.'),
      contractorId: Yup.string().required('Please enter Contractor ID.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      dispatchSubmitMindfire({
        lastPage: '11011',
        dealerUserId: dealerUserId,
        did: did,
      });
      dispatchChangeInfoValue(
        'programPhoneNumber',
        values.programPhoneNumber ? values.programPhoneNumber.match(/\d/g).join('') : null,
      );
      dispatchChangeInfoValue('loanCode', values.loanCode);
      dispatchChangeInfoValue('contractorId', values.contractorId);

      if (!(programProduct && programProjectTypes) || isValueChanged(values)) {
        dispatchGetEnerBankProgram(EnerBankFlowPageNumber.ProjectDetail);
      } else {
        dispatchChangePage(EnerBankFlowPageNumber.ProjectDetail);
      }
    },
  })(LoanInformationForm);

  return (
    <Container>
      <Row>
        <BackBtn changePage={dispatchChangePage} page={EnerBankFlowPageNumber.Eligibility} />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <LoanInformationFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  programPhoneNumber: state.enerBankInformation.programPhoneNumber,
  loanCode: state.enerBankInformation.loanCode,
  contractorId: state.enerBankInformation.contractorId,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  enerBankProgramNumber:
    state.auth.user?.dealerUser?.dealer?.enerBankProgramNumber || state.auth.enerBankProgramNumber,
  enerBankContractorId:
    state.auth.user?.dealerUser?.dealer?.enerBankContractorId || state.auth.enerBankContractorId,
  programProduct: state.enerBankInformation.programProduct,
  programProjectTypes: state.enerBankInformation.programProjectTypes,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeInfoValue: (key, value) => dispatch(changeEnerBankInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchGetEnerBankProgram: page => dispatch(getEnerBankProgram(page)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoanInformation);
