import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { initialState } from './initialState';
import aquaReducer from './reducers/aquaReducer';
import authReducer from './reducers/authReducer';
import contractorEnrollmentReducer from './reducers/contractorEnrollmentReducer';
import greenskyReducer from './reducers/greenskyReducer';
import microfReducer from './reducers/microfReducer';
import naviReducer from './reducers/naviReducer';
import notificationReducer from './reducers/notificationReducer';
import powerPayReducer from './reducers/powerPayReducer';
import preQualInfoReducer from './reducers/preQualInfoReducer';
import rheemInformation from './reducers/rheemReducer';
import softPullReducer from './reducers/softPullReducer';
import utileReducer from './reducers/utileReducer';
import wellsFargoReducer from './reducers/wellsFargoReducer';
import mosaicReducer from './reducers/mosaicReducer';
import themeReducer from './reducers/themeReducer';
import foundationReducer from './reducers/foundationReducer';
import greenSkyApplicationReducer from './reducers/greenSkyApplicationReducer';
import enerBankReducer from './reducers/enerBankReducer';
import ftlInformation from './reducers/ftlReducer';
import atWaterReducer from './reducers/atWaterReducer';
import contractorEnrollmentAtWaterGSReducer from './reducers/contractorEnrollmentAtWaterGSReducer';

export default () => {
  const rootReducer = combineReducers({
    notification: notificationReducer,
    auth: authReducer,
    navi: naviReducer,
    preQualificationInformation: preQualInfoReducer,
    aquaInformation: aquaReducer,
    microfInformation: microfReducer,
    softPull: softPullReducer,
    greenskyInformation: greenskyReducer,
    utile: utileReducer,
    contractorEnrollment: contractorEnrollmentReducer,
    contractorEnrollmentAtWaterGS: contractorEnrollmentAtWaterGSReducer,
    rheemInformation: rheemInformation,
    powerPayInformation: powerPayReducer,
    wellsFargoInformation: wellsFargoReducer,
    mosaicInformation: mosaicReducer,
    greenSkyApplicationInformation: greenSkyApplicationReducer,
    atWaterApplicationInformation: atWaterReducer,
    theme: themeReducer,
    foundationInformation: foundationReducer,
    enerBankInformation: enerBankReducer,
    ftlInformation: ftlInformation,
  });

  let persistedState = localStorage.getItem('reduxState')
    ? JSON.parse(localStorage.getItem('reduxState'))
    : rootReducer.initialState;
  persistedState =
    persistedState &&
    persistedState.navi &&
    initialState.navi.version !== persistedState.navi.version
      ? rootReducer.initialState
      : persistedState;
  let store = null;
  if (process.env.NODE_ENV !== 'development') {
    store = createStore(rootReducer, persistedState, applyMiddleware(thunkMiddleware));
  } else {
    const logger = loggerStore => next => action => {
      console.log('[Middleware] Dispatching', action);
      const result = next(action);
      console.log('[Middleware] next state', loggerStore.getState());
      return result;
    };
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    store = createStore(
      rootReducer,
      persistedState,
      composeEnhancers(applyMiddleware(thunkMiddleware, logger)),
    );
  }
  store.subscribe(() => {
    localStorage.setItem('reduxState', JSON.stringify(store.getState()));
  });
  return store;
};
