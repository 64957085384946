import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  setFlow,
  getReCapthcaKey,
  changeContractorEnrollmentValue,
} from '../../redux/actions/index';
import Header from '../../components/UI/Header/Header';
import Section from '../../components/Section/Section';
import Flow from './_Flow';

const UpdateMerchantNumber = ({
  state,
  history,
  dispatchSetFlow,
  flowName,
  dispatchChangeValue,
  dispatchGetReCapthcaKey,
  dispatchResetGreenSkyState,
  dispatchGetDistributors,
  ...props
}) => {
  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header title={'Update Merchant Number'} pages={0} />
      <Section Component={Flow[0]} {...state} />
    </>
  );
};

const mapStateToProps = state => ({
  state,
  flowName: state.navi.flowName,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetFlow: flow => dispatch(setFlow(flow)),
  dispatchChangeValue: (key, value) => dispatch(changeContractorEnrollmentValue(key, value)),
  dispatchGetReCapthcaKey: () => dispatch(getReCapthcaKey()),
});

UpdateMerchantNumber.propTypes = {
  dispatchSetFlow: PropTypes.func,
  score: PropTypes.number,
  flowName: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateMerchantNumber);
