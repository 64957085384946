import axios from 'axios';
import {
  getGreenSkyApiKey,
  getGreenSkyDisclosuresProgram,
  getGreenSkyDisclosuresType,
  getGreenSkyDisclosuresUrl,
} from '../../config/settings';
import {
  CHANGE_GREENSKY_APPLICATION_INFO_VALUE,
  SUBMIT_APPLICATION_SOFT_PULL_ERROR,
  SUBMIT_APPLICATION_SOFT_PULL_SUCCESS,
} from './actionTypes';
import { baseURL, handleApiError } from './api';
import { changePage } from './naviActions';
import { addNotification, endLoading, startLoading } from './notificationActions';
import { checkErrors } from './index';

const qs = require('qs');

export const changeGreenSkyApplicationInfoValue = (key, value) => ({
  type: CHANGE_GREENSKY_APPLICATION_INFO_VALUE,
  key,
  value,
});

export const getDisclosures = () => async dispatch => {
  return axios({
    method: 'get',
    url: getGreenSkyDisclosuresUrl(),
    params: {
      type: getGreenSkyDisclosuresType(),
      program: getGreenSkyDisclosuresProgram(),
    },
    headers: { Authorization: getGreenSkyApiKey() },
  }).then(response => {
    dispatch(changeGreenSkyApplicationInfoValue('disclosures', response.data?.content?.[0]?.body));
  });
};

export const getDisclosureAcceptApp = () => async dispatch => {
  return axios({
    method: 'get',
    url: getGreenSkyDisclosuresUrl(),
    params: {
      type: 'disclosureAcceptApp',
      program: getGreenSkyDisclosuresProgram(),
    },
    headers: { Authorization: getGreenSkyApiKey() },
  }).then(response => {
    dispatch(
      changeGreenSkyApplicationInfoValue('disclosureAccept', response.data?.content?.[0]?.body),
    );
  });
};

export const getDisclosureAcceptAppCoapp = () => async dispatch => {
  return axios({
    method: 'get',
    url: getGreenSkyDisclosuresUrl(),
    params: {
      type: 'disclosureAcceptAppCoapp',
      program: getGreenSkyDisclosuresProgram(),
    },
    headers: { Authorization: getGreenSkyApiKey() },
  }).then(response => {
    dispatch(
      changeGreenSkyApplicationInfoValue('disclosureAccept', response.data?.content?.[0]?.body),
    );
  });
};

export const getDisclosureSubmitApp = () => async dispatch => {
  return axios({
    method: 'get',
    url: getGreenSkyDisclosuresUrl(),
    params: {
      type: 'submitApp',
      program: getGreenSkyDisclosuresProgram(),
    },
    headers: { Authorization: getGreenSkyApiKey() },
  }).then(response => {
    dispatch(
      changeGreenSkyApplicationInfoValue('disclosureSubmit', response.data?.content?.[0]?.body),
    );
  });
};

export const getDisclosureSubmitAppCoapp = () => async dispatch => {
  return axios({
    method: 'get',
    url: getGreenSkyDisclosuresUrl(),
    params: {
      type: 'submitAppCoapp',
      program: getGreenSkyDisclosuresProgram(),
    },
    headers: { Authorization: getGreenSkyApiKey() },
  }).then(response => {
    dispatch(
      changeGreenSkyApplicationInfoValue('disclosureSubmit', response.data?.content?.[0]?.body),
    );
  });
};

export const getDisclosureFooter = () => async dispatch => {
  return axios({
    method: 'get',
    url: getGreenSkyDisclosuresUrl(),
    params: {
      type: 'mainFooter',
      program: getGreenSkyDisclosuresProgram(),
    },
    headers: { Authorization: getGreenSkyApiKey() },
  }).then(response => {
    dispatch(
      changeGreenSkyApplicationInfoValue('disclosureFooter', response.data?.content?.[0]?.body),
    );
  });
};

export const submitGreenSkyApplication = (params, page, app) => async dispatch => {
  dispatch(
    startLoading('Give us a second while we find the best GreenSky financing option for you.'),
  );
  const url = app
    ? `${baseURL}/api/soft_pull/greensky/resubmit`
    : `${baseURL}/api/soft_pull/greensky/submit`;
  const data = qs.stringify(
    app
      ? {
          selectedFinancier: `${encodeURIComponent(params.selectedFinancier)}`,
          appId: `${encodeURIComponent(app.applicationId)}`,
          data: '' + JSON.stringify(params),
        }
      : { data: '' + JSON.stringify(params) },
  );

  await axios
    .post(url, data)
    .then(async response => {
      dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_SUCCESS, payload: response.data });
      if (
        response &&
        response.data &&
        response.data.error &&
        response.data.error.httpErrorCode === 1
      ) {
        dispatch(endLoading());
      } else {
        if (dispatch(checkErrors(response))) {
          dispatch(changePage(page));
          dispatch(endLoading());
        }
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_ERROR, payload: error });
      dispatch(endLoading());
    });
};

export const getGreenskyMerchantPlans = merchantId => async dispatch => {
  dispatch(startLoading('Give us a second while we fetch plans for you.'));

  const url = `${baseURL}/api/soft_pull/greensky/plan_filtering`;

  await axios({ method: 'get', url: url, params: { merchantId } })
    .then(async response => {
      if (response.data) {
        if (response.data.userMessage) {
          dispatch(changeGreenSkyApplicationInfoValue('allPlans', []));
          dispatch(endLoading());
          dispatch(
            addNotification({
              message: handleApiError(response.data.userMessage),
              messageType: 'Error',
            }),
          );
        } else {
          dispatch(changeGreenSkyApplicationInfoValue('allPlans', response.data.plans));
          dispatch(endLoading());
        }
      }
    })
    .catch(error => {
      dispatch(changeGreenSkyApplicationInfoValue('allPlans', []));
      dispatch(endLoading());
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
};
