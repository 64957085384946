import { Field, Form, withFormik } from 'formik';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { GoSearch } from 'react-icons/go';
import { connect } from 'react-redux';
import { Button, Col, Label, Row } from 'reactstrap';
import InputField from '../../../components/UI/Form/Input/InputField';
import { changeSoftPullValue } from '../../../redux/actions/index';
import { initialState } from '../../../redux/initialState';

const ContractorsSearch = props => {
  const {
    searchOptionContractors,
    dispatchChangeSoftPullValue,
    searchContractors,
    resetForm,
  } = props;
  const [show, setShow] = useState(true);

  const search = queryString.parse(window.location.search).search;

  useEffect(() => {
    if (search) {
      searchContractors(searchOptionContractors);
    }
    // eslint-disable-next-line
  }, []);

  const handleReset = () => {
    dispatchChangeSoftPullValue(
      'searchOptionContractors',
      initialState.softPull.searchOptionContractors,
    );
  };

  const ContractorsSearchForm = () => (
    <div className="search-panel">
      <Form>
        <Row>
          <Col sm="6">
            <div className="form-group">
              <Label for="dealerName">Contractor</Label>
              <Field component={InputField} name="dealerName" id="dealerName" type="text" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="text-right">
            <Button
              type="reset"
              color="secondary"
              className="rounded-pill"
              outline
              onClick={() => handleReset()}
            >
              Clear
            </Button>
            <Button type="submit" color="primary" className="rounded-pill">
              Search
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );

  const ContractorsSearchFormFormik = withFormik({
    mapPropsToValues: () => ({
      dealerName: searchOptionContractors.dealerName,
    }),

    handleSubmit: values => {
      const searchOption = {
        ...searchOptionContractors,
        dealerName: values.dealerName,
        state: undefined,
        multipleCreditors: undefined,
        zipcode: undefined,
      };

      dispatchChangeSoftPullValue('searchOptionContractors', searchOption);
      searchContractors(searchOption);
    },
  })(ContractorsSearchForm);

  return (
    <div className="search-form">
      <div className="search-btn">
        <span onClick={() => setShow(!show)}>
          <GoSearch size={18} />
          <span>Search Criteria</span>
        </span>
      </div>
      {show ? <ContractorsSearchFormFormik /> : null}
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  dispatchChangeSoftPullValue: (key, value) => dispatch(changeSoftPullValue(key, value)),
});

export default connect(null, mapDispatchToProps)(ContractorsSearch);
