import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import IdentityVerificationQuestionTypeEnum from '../../../assets/js/IdentityVerificationQuestionTypeEnum';
import Button from '../../../components/UI/Button/CustomButton';
import MultiSelectIdentityVerification from '../../../components/UI/Form/Select/MultiSelectIdentityVerification';
import { reactGAPageview } from '../../../helper';

const IdentityVerification = props => {
  const {
    application,
    did,
    dealerUserId,
    email,
    enerBankInformation: { identityVerification },
    enerBankApplicantQuestion,
    enerBankCoApplicantQuestion,
    dispatchSubmitMindfire,
    dispatchChangeEnerBankInfoValue,
    dispatchChangePageTitle,
    dispatchIdentityVerification,
    applicantQuestionSetId,
    applicantTransactionId,
    coApplicantQuestionSetId,
    coApplicantTransactionId,
  } = props;
  const [isRequired, setIsRequired] = useState(false);
  const mindFireRequest = { email: email, lastPage: '11029', dealerUserId: dealerUserId, did: did };

  const getUpdatedApplicantChoices = (e, question, applicantQuestions) => {
    applicantQuestions.find(
      obj => obj.questionId === question.questionId,
    ).selectedChoices = Array.isArray(e) ? e : [e];

    return applicantQuestions;
  };

  const formValidation = () => {
    let isValid = true;
    identityVerification.applicant.forEach(obj => {
      if (obj.selectedChoices.length === 0) isValid = false;
    });

    identityVerification.coApplicant.forEach(obj => {
      if (obj.selectedChoices.length === 0) isValid = false;
    });

    return isValid;
  };

  const getIdentityVerificationRequest = () => ({
    applicant:
      applicantQuestionSetId && applicantTransactionId
        ? {
            questionSetId: applicantQuestionSetId,
            transactionId: applicantTransactionId,
            answers: identityVerification.applicant.map(question => ({
              questionId: question.questionId,
              choiceIds: question.selectedChoices.map(choice => choice.value),
            })),
          }
        : null,
    coApplicant:
      coApplicantQuestionSetId && coApplicantTransactionId
        ? {
            questionSetId: coApplicantQuestionSetId,
            transactionId: coApplicantTransactionId,
            answers: identityVerification.coApplicant.map(question => ({
              questionId: question.questionId,
              choiceIds: question.selectedChoices.map(choice => choice.value),
            })),
          }
        : null,
  });

  useEffect(() => {
    dispatchChangePageTitle('Application Results');
    reactGAPageview({
      path: '/ca_ebidentityverification',
      trackerNames: [],
      title: 'ca_ebidentityverification',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col sm="1" />
          <Col sm="5">
            <h4>Identity verification</h4>
          </Col>
          <Col sm="6">
            {enerBankApplicantQuestion && (
              <>
                <h5>Applicant</h5>
                {enerBankApplicantQuestion.map(question => (
                  <MultiSelectIdentityVerification
                    errorMessage={
                      isRequired &&
                      identityVerification.applicant.find(
                        obj => obj.questionId === question.questionId,
                      ).selectedChoices.length === 0 &&
                      'Please select an option.'
                    }
                    isMulti={question.questionType !== IdentityVerificationQuestionTypeEnum.SINGLE}
                    label={question.text}
                    key={question.questionId}
                    options={question.questionChoices?.map(obj => ({
                      value: obj.choiceId,
                      label: obj.text,
                    }))}
                    selectedValues={
                      identityVerification?.applicant.find(
                        obj => obj.questionId === question.questionId,
                      )?.selectedChoices || []
                    }
                    onChange={e =>
                      dispatchChangeEnerBankInfoValue('identityVerification', {
                        applicant: getUpdatedApplicantChoices(e, question, [
                          ...identityVerification.applicant,
                        ]),
                        coApplicant: [...identityVerification.coApplicant],
                      })
                    }
                  />
                ))}
              </>
            )}
            {enerBankCoApplicantQuestion && (
              <>
                <h5>Co-Applicant</h5>
                {enerBankCoApplicantQuestion.map(question => (
                  <MultiSelectIdentityVerification
                    errorMessage={
                      isRequired &&
                      identityVerification.coApplicant.find(
                        obj => obj.questionId === question.questionId,
                      ).selectedChoices.length === 0 &&
                      'Please select an option.'
                    }
                    isMulti={question.questionType !== IdentityVerificationQuestionTypeEnum.SINGLE}
                    label={question.text}
                    key={question.questionId}
                    options={question.questionChoices?.map(obj => ({
                      value: obj.choiceId,
                      label: obj.text,
                    }))}
                    selectedValues={
                      identityVerification?.coApplicant.find(
                        obj => obj.questionId === question.questionId,
                      )?.selectedChoices || []
                    }
                    onChange={e =>
                      dispatchChangeEnerBankInfoValue('identityVerification', {
                        applicant: [...identityVerification.applicant],
                        coApplicant: getUpdatedApplicantChoices(e, question, [
                          ...identityVerification.coApplicant,
                        ]),
                      })
                    }
                  />
                ))}
              </>
            )}
            <Button
              title="Next"
              className="mt-4"
              clickHandler={() => {
                if (formValidation()) {
                  if (isRequired) setIsRequired(false);
                  dispatchSubmitMindfire(mindFireRequest);
                  dispatchIdentityVerification(
                    application.applicationId,
                    getIdentityVerificationRequest(),
                  );
                } else {
                  setIsRequired(true);
                }
              }}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default withRouter(IdentityVerification);
