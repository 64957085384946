import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { currencyFormat } from '../../../../helper';

const CompleteConfirmation = props => {
  const { application, data } = props;

  const returnToDashboard = () => {
    window.location.href = process.env.REACT_APP_DEALER_PORTAL_URL + '/portal/dashboard';
  };

  return (
    <>
      <Row className="p-15">
        <Col sm="12">
          <p className="fs-18">
            <b className="text-success">The completion certificate has been sent! </b>{' '}
            <span>
              A notification has been sent to the homeowner to confirm the completion of the project
              and authorize the release of funds.
            </span>
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm="4">
          <Row className="p-15 fs-14 fw-500">
            <Col>
              <Row>
                <Col>
                  <span>Application ID: {application.id}</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span>Applicant Name: {`${application.nameFirst} ${application.nameLast}`}</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span>Requested Amount: {currencyFormat(parseInt(data.requestedAmount))}</span>
                </Col>
              </Row>

              <Row>
                <Col>
                  <span>Term: {data.term}</span>
                </Col>
              </Row>

              <Row>
                <Col>
                  <span>APR: {data.APR}</span>
                </Col>
              </Row>

              <Row>
                <Col>
                  <span>Monthly Payment: {data.monthlyPayment}</span>
                </Col>
              </Row>
              <Row className="pt-25">
                <Col>
                  <span>Contractor Fee: {currencyFormat(parseInt(data.contractorFee))}</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span>Deposit Amount: {currencyFormat(data.depositedAmount)}</span>
                </Col>
              </Row>
              {data.contractorMessage && data.contractorMessage !== '' && (
                <Row className="pt-25">
                  <Col>
                    <span>{data.contractorMessage}</span>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="p-10 justify-content-end">
        <Button
          type="submit"
          className="rounded-pill"
          onClick={() => {
            returnToDashboard();
          }}
        >
          Return to Dashboard
        </Button>
      </Row>
    </>
  );
};

export default CompleteConfirmation;
