import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Alert } from 'reactstrap';
import { IoIosCheckmarkCircle, IoIosInformationCircleOutline } from 'react-icons/io';
import { MdNotInterested } from 'react-icons/md';
import { reactGAPageview } from '../../helper';
import applicationStatusEnum, {
  isMicrofStatusApproved,
  isMissedOpportunityProcessingStatus,
} from '../../assets/js/ApplicationStatusEnum';
import { setDocumentTitle } from '../../util/documentUtil';
import { CONSUMER_PORTAL_TITLE } from '../../constants/document';

const NextSteps = props => {
  const { did, dealerUserId, dealerId, appProcessingStatus } = props;
  const engagedWithDealer = () => {
    if (appProcessingStatus === applicationStatusEnum.microfRedirectStatus.AWAIT_DEALER.value) {
      return false;
    }
    return (did && did > 0) || (dealerId && dealerId > 0);
  };
  const receiveLoanApproval =
    isMicrofStatusApproved(appProcessingStatus) ||
    appProcessingStatus === applicationStatusEnum.microfRedirectStatus.COMPLETED.value ||
    appProcessingStatus === applicationStatusEnum.microfRedirectStatus.CLOSED_WON.value;

  const isMissedOpportunity = isMissedOpportunityProcessingStatus(appProcessingStatus);
  setDocumentTitle(CONSUMER_PORTAL_TITLE);

  useEffect(() => {
    reactGAPageview({
      path: '/ca_appcomplete',
      trackerNames: [],
      title: 'ca_appcomplete',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);
  return (
    <Col>
      <Row>
        <Col>
          <h1>Consumer Dashboard</h1>
        </Col>
      </Row>
      <Row>
        <div className="app-panel">
          <div className="app-panel-body">
            {renderApplicationPhase(isMissedOpportunity)}

            {renderEngagedWithDealer(engagedWithDealer(), isMissedOpportunity)}

            {renderLoanApproval(receiveLoanApproval, isMissedOpportunity)}
          </div>
        </div>
      </Row>
    </Col>
  );
};

const renderApplicationPhase = isMissedOpportunity => {
  if (isMissedOpportunity) {
    return (
      <p className="mt-3">
        <MdNotInterested className="error size-24" />
        Missed Opportunity!
      </p>
    );
  }
  return (
    <p className="mt-3">
      <IoIosCheckmarkCircle className="ok size-24" />
      Complete Application
    </p>
  );
};

const renderEngagedWithDealer = (engagedWithDealer, isMissedOpportunity) => {
  if (isMissedOpportunity) return null;

  if (engagedWithDealer) {
    return (
      <p className="mt-3">
        <IoIosCheckmarkCircle className="ok size-24" />
        Engage with a dealer
      </p>
    );
  }
  return (
    <p>
      <Alert color="success">
        <IoIosInformationCircleOutline className="ok size-24" />
        Your application is submitted. A Microf relationship manager will help set you up with one
        of our qualified dealers. Thank you for your patience.
      </Alert>
      <p className="mt-3">
        <MdNotInterested className="error size-24" />
        Engage with a dealer
      </p>
    </p>
  );
};

const renderLoanApproval = (receivedLoanApproval, isMissedOpportunity) => {
  if (isMissedOpportunity) return null;
  if (receivedLoanApproval) {
    return (
      <p className="mt-3">
        <IoIosCheckmarkCircle className="ok size-24" />
        Receive Lease Approval
      </p>
    );
  }
  return (
    <p className="mt-3">
      <MdNotInterested className="error size-24" />
      Receive Lease Approval
    </p>
  );
};

const mapStateToProps = state => ({
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  dealerId: state.auth.dealerId,
  appProcessingStatus: state.softPull.appProcessingStatus,
});

export default connect(mapStateToProps)(NextSteps);
