import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
import { initialState } from '../initialState';

const startLoading = (state, action) => {
  const updatedValues = {
    loading: true,
    loadingMessage: action.loadingMessage,
  };
  return updateObject(state, updatedValues);
};

const endLoading = state => {
  const updatedValues = {
    loading: false,
    loadingMessage: null,
  };
  return updateObject(state, updatedValues);
};

const addNotification = (state, action) => {
  const updatedValues = {
    message: action.params.message,
    messageType: action.params.messageType,
  };
  return updateObject(state, updatedValues);
};

const removeNotification = state => {
  const updatedValues = {
    message: null,
    messageType: null,
  };
  return updateObject(state, updatedValues);
};

const notificationReducer = (state, action) => {
  if (!state) {
    state = initialState.notification;
  }
  switch (action.type) {
    case actionTypes.ADD_NOTIFICATION:
      return addNotification(state, action);
    case actionTypes.REMOVE_NOTIFICATION:
      return removeNotification(state);
    case actionTypes.START_LOADING:
      return startLoading(state, action);
    case actionTypes.END_LOADING:
      return endLoading(state);
    case actionTypes.RESET_STATE_NOTIFICATION:
      state = initialState.notification;
      return state;
    default:
      return state;
  }
};

export default notificationReducer;
