import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import { useVSPixelScript } from '../../assets/hooks';
import hvacSystem from '../../assets/js/HvacSystemEnum';
import BackBtn from '../../components/UI/BackButton/BackButton';
import Button from '../../components/UI/Button/CustomButton';
import CurrencyFormat from '../../components/UI/Form/NumberFormat/CurrencyFormat';
import { RETAILPRICE_TOOLTIP_TEXT } from '../../constants/tooltipTexts';
import { reactGAEvent, reactGAPageview } from '../../helper';
import { changeMicrofInfoValue, changePage, submitMindfire } from '../../redux/actions/index';
import { MicrofFlowPageNumber } from './MicrofFlow';

const LeaseAmount = props => {
  const {
    email,
    isReview,
    totalPrice,
    dispatchChangeInfoValue,
    dispatchChangePage,
    children,
    dispatchSubmitMindfire,
    dealerUserId,
    did,
    secondHvacSystem,
    waterHeater,
    secondWaterHeater,
  } = props;

  useVSPixelScript();

  useEffect(() => {
    reactGAPageview({
      path: '/ca_hvacinfo',
      trackerNames: [],
      title: 'ca_hvacinfo',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  let reqAmountMin = 1000;
  let reqAmountMax = 25000;

  if (secondHvacSystem) {
    reqAmountMax += 25000;
  }

  if (waterHeater) {
    reqAmountMax +=
      waterHeater === hvacSystem.TANK_WATER_HEATER.value
        ? 6666.67
        : waterHeater === hvacSystem.TANKLESS_WATER_HEATER.value
        ? 14166.67
        : 0;
  }

  if (secondWaterHeater) {
    reqAmountMax +=
      secondWaterHeater === hvacSystem.TANK_WATER_HEATER.value
        ? 6666.67
        : secondWaterHeater === hvacSystem.TANKLESS_WATER_HEATER.value
        ? 14166.67
        : 0;
  }

  const LeaseAmountForm = () => {
    return (
      <Form noValidate>
        <Field
          component={CurrencyFormat}
          label="Retail (Cash) Price"
          name="totalPrice"
          id="totalPrice"
          tooltipText={RETAILPRICE_TOOLTIP_TEXT}
          placeholder="$0.00"
          type="text"
        />

        <Button type="submit" title={isReview ? 'Review' : 'Continue'} />
      </Form>
    );
  };

  const LeaseAmountFormFormik = withFormik({
    mapPropsToValues: () => ({
      totalPrice: totalPrice || '',
    }),

    validationSchema: Yup.object({
      totalPrice: Yup.number()
        .required('Please enter the Total price.')
        .test('min1000', `Total price should be greater than $${reqAmountMin}.`, val => {
          return val >= reqAmountMin;
        })
        .test('max15000', `Amount should be lower than $${reqAmountMax}.`, val => {
          return val <= reqAmountMax;
        }),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Continue',
        did,
        dealerUserId,
      });
      dispatchChangeInfoValue('totalPrice', values.totalPrice);
      // dispatchChangeInfoValue(
      //   'requestedLoanAmount',
      //   dealerId && (!consumerId || did) ? values.requestedLoanAmount : values.totalPrice,
      // );
      // dispatchChangeInfoValue('whTotalPrice', values.whTotalPrice);
      // dispatchChangeInfoValue(
      //   'whRequestedLoanAmount',
      //   dealerId && (!consumerId || did) ? values.whRequestedLoanAmount : values.whTotalPrice,
      // );
      dispatchChangePage(
        isReview ? MicrofFlowPageNumber.Review : MicrofFlowPageNumber.AppIncomeInfo,
      );
      dispatchChangeInfoValue('isReview', null);
      dispatchSubmitMindfire({
        email: email,
        dealerUserId: dealerUserId,
        lastPage: '4076',
        did: did,
      });
    },
  })(LeaseAmountForm);
  return (
    <Container>
      <Row>
        <BackBtn changePage={dispatchChangePage} page={MicrofFlowPageNumber.SystemType} />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <LeaseAmountFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  consumerId: state.auth.consumerId,
  dealerId: state.auth.dealerId,
  totalPrice: state.microfInformation.microfOnly
    ? state.microfInformation.borrowedAmount ||
      state.microfInformation.totalPrice ||
      (!state.auth.dealerId && 6000) ||
      0
    : state.microfInformation.totalPrice || state.preQualificationInformation.jobPrice || 0,
  requestedLoanAmount:
    state.microfInformation.requestedLoanAmount ||
    state.powerPayInformation.borrowedAmount ||
    state.wellsFargoInformation.borrowedAmount ||
    state.preQualificationInformation.borrowedAmount ||
    0,
  whTotalPrice: state.microfInformation.whTotalPrice,
  whRequestedLoanAmount: state.microfInformation.whRequestedLoanAmount,
  isReview: state.microfInformation.isReview,
  email: state.preQualificationInformation.email,
  state: state.preQualificationInformation.state,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  systemType: state.microfInformation.systemType,
  secondHvacSystem: state.microfInformation.secondHvacSystem,
  waterHeater: state.microfInformation.waterHeater,
  secondWaterHeater: state.microfInformation.secondWaterHeater,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeInfoValue: (key, value) => dispatch(changeMicrofInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

LeaseAmount.propTypes = {
  totalPrice: PropTypes.number,
  requestedLoanAmount: PropTypes.number,
  hvacSystem: PropTypes.string,
  hvacTonnage: PropTypes.string,
  secondHvacSystem: PropTypes.string,
  secondHvacTonnage: PropTypes.string,
  isReview: PropTypes.bool,
  dispatchChangePage: PropTypes.func,
  dispatchChangeInfoValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LeaseAmount);
