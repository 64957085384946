import axios from 'axios';
import { baseURL, handleApiError } from './api';
import { addNotification } from './notificationActions';
import { CHANGE_UTILE_VALUE } from './actionTypes';

export const changeUtileValue = (key, value) => ({
  type: CHANGE_UTILE_VALUE,
  key,
  value,
});

export const getDistributors = () => async dispatch => {
  await axios
    .get(`${baseURL}/api/distributors`)
    .then(response => {
      dispatch(changeUtileValue('distributors', response.data));
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
};

export const getBanks = () => async dispatch => {
  await axios
    .get(`${baseURL}/api/bank/showOnDealerEnrollment`)
    .then(response => {
      dispatch(changeUtileValue('banks', response.data));
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
};

export const getHCBrands = applicationId => async dispatch => {
  await axios
    .get(`${baseURL}/api/applications/hcBrands`)
    .then(response => {
      dispatch(changeUtileValue('hcBrands', response.data));
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
};
