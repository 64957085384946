import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import SearchCriteria from './SearchCriteria';
import SearchTable from './SearchTable';

const SearchContractorUsers = props => {
  const { history, foundContractors, zipcodeFindAContractor, auth } = props;

  useEffect(() => {
    if (!auth.user && (!auth.user.dealerUser || !auth.user.internalUser)) {
      history.push('/login');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Col>
      <Row>
        <Col>
          <h1>Find a contractor</h1>
        </Col>
      </Row>
      <Row className="search-users panel p-3">
        <Col>
          <SearchCriteria />
          {zipcodeFindAContractor ? (
            <SearchTable foundContractors={foundContractors} history={history} />
          ) : null}
        </Col>
      </Row>
    </Col>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  foundContractors: state.softPull.foundContractors,
  zipcodeFindAContractor: state.softPull.zipcodeFindAContractor,
});

export default withRouter(
  connect(
    mapStateToProps,
    null,
  )(SearchContractorUsers),
);
