import axios from 'axios';
import {
  AQUA_FINANCE_LOGIN_ERROR,
  AQUA_FINANCE_LOGIN_SUCCESS,
  CHANGE_AQUA_INFO_VALUE,
  SUBMIT_APPLICATION_SOFT_PULL_ERROR,
  SUBMIT_APPLICATION_SOFT_PULL_SUCCESS,
  UPDATE_AQUA_STATUS_AND_PROMOTIONS,
} from './actionTypes';
import { baseURL, getData, handleApiError } from './api';
import { addNotification, endLoading, startLoading } from './notificationActions';
import { changePage } from './naviActions';
import {
  isMicrofAddedByAPI,
  isStatusAquaIntermediate,
} from '../../assets/js/ApplicationStatusEnum';
import { changePreQualInfoValue } from './preQualInfoActions';
import incomeSource from '../../assets/js/IncomeSourceEnum';
import { getAddedByAPIValue, isSameAddress } from '../../helper';
import { mapApplicationToPrequalification } from './softPullActions';
import { checkErrors } from './index';

export const changeAquaInfoValue = (key, value) => ({
  type: CHANGE_AQUA_INFO_VALUE,
  key,
  value,
});

function sleep(seconds) {
  return new Promise(resolve => setTimeout(resolve, seconds * 1000));
}

export const mapApplicationToAqua = app => dispatch => {
  if (app) {
    const { aquaFinanceInformation } = app;
    dispatch(mapApplicationToPrequalification(app));

    // Aqua
    if (aquaFinanceInformation) {
      dispatch(changeAquaInfoValue('ownOrRent', aquaFinanceInformation.ownOrRent));
      dispatch(changeAquaInfoValue('hvacMonthlyPayment', aquaFinanceInformation.monthlyPayment));
      dispatch(changeAquaInfoValue('homeValue', aquaFinanceInformation.homeValue));
      dispatch(changeAquaInfoValue('hvacInstallmentDate', aquaFinanceInformation.installmentDate));
    }

    // Applicant
    if (app.applicantPrimary) {
      const applicant = app.applicantPrimary;
      dispatch(changePreQualInfoValue('firstName', applicant.nameFirst));
      dispatch(changePreQualInfoValue('middleName', applicant.nameMiddle));
      dispatch(changePreQualInfoValue('lastName', applicant.nameLast));
      dispatch(changePreQualInfoValue('phoneNumber', applicant.mobilePhone?.number));
      dispatch(changePreQualInfoValue('birthday', getAddedByAPIValue(applicant.birthDate) || null));
      if (applicant.address && isMicrofAddedByAPI(app.status)) {
        const address = applicant.address;
        dispatch(
          changeAquaInfoValue('applicantAddress', {
            city: address.city,
            state: address.state,
            street: address.street,
            zip: address.zipcode,
          }),
        );
      }
      dispatch(changeAquaInfoValue('appFullSsn', getAddedByAPIValue(applicant.ssn)));
      dispatch(changeAquaInfoValue('appIdType', applicant.idType));
      dispatch(changeAquaInfoValue('appIdNumber', getAddedByAPIValue(applicant.idNumber)));
      dispatch(
        changeAquaInfoValue(
          'appDriversLicenseState',
          getAddedByAPIValue(applicant.driversLicenseSOI),
        ),
      );
      dispatch(
        changeAquaInfoValue(
          'appIdIssueDate',
          getAddedByAPIValue(applicant.driversLicenseDateOfIssue),
        ),
      );
      dispatch(
        changeAquaInfoValue(
          'idExpirationDate',
          getAddedByAPIValue(applicant.driversLicenseExpirationDate),
        ),
      );

      const appIncomeSource = Object.keys(incomeSource).find(
        key =>
          incomeSource[key].aquaValue === aquaFinanceInformation?.employerType ||
          incomeSource[key].powerPayValue === applicant.employmentType,
      );
      dispatch(changeAquaInfoValue('appIncomeSource', incomeSource[appIncomeSource]?.value));
      dispatch(
        changeAquaInfoValue(
          'appEmploymentPosition',
          aquaFinanceInformation?.employmentPosition || applicant.occupation,
        ),
      );

      dispatch(changeAquaInfoValue('appEmploymentYears', applicant.employmentYears));
      dispatch(changeAquaInfoValue('appEmploymentMonths', applicant.employmentMonths));
      dispatch(changeAquaInfoValue('isEmployed', applicant.employer ? true : null));
      dispatch(changeAquaInfoValue('appEmployerName', applicant.employer));
      dispatch(
        changeAquaInfoValue(
          'appEmployerPhoneNumber',
          aquaFinanceInformation?.employerPhoneNumber || app.employerPhoneNumber,
        ),
      );
      dispatch(changeAquaInfoValue('appSalaryIncome', applicant.grossYearlyIncome));
      dispatch(changeAquaInfoValue('appAdditionalIncome', applicant.otherYearlyIncome));
      dispatch(changeAquaInfoValue('appOtherSourcesOfIncome', applicant.sourceOtherYearlyIncome));
    }

    // CoApplicant
    if (app.applicantNonPrimary) {
      const coapp = app.applicantNonPrimary;
      dispatch(changeAquaInfoValue('hasCoApp', true));
      dispatch(changeAquaInfoValue('appCoAppRelationship', coapp.relationshipToPrimary));
      dispatch(changeAquaInfoValue('coAppFirstName', coapp.nameFirst));
      dispatch(changeAquaInfoValue('coAppMiddleInitial', coapp.nameMiddle));
      dispatch(changeAquaInfoValue('coAppLastName', coapp.nameLast));
      dispatch(changeAquaInfoValue('coAppPhoneNumber', coapp.mobilePhone?.number));
      dispatch(changeAquaInfoValue('coAppEmail', coapp.email?.email));

      dispatch(changeAquaInfoValue('coAppIdType', coapp.idType));
      dispatch(changeAquaInfoValue('coAppIdNumber', getAddedByAPIValue(coapp.idNumber)));
      dispatch(
        changeAquaInfoValue(
          'coAppDriversLicenseState',
          getAddedByAPIValue(coapp.driversLicenseSOI),
        ),
      );
      dispatch(
        changeAquaInfoValue(
          'coAppIdIssueDate',
          getAddedByAPIValue(coapp.driversLicenseDateOfIssue),
        ),
      );
      dispatch(
        changeAquaInfoValue(
          'coAppIdExpirationDate',
          getAddedByAPIValue(coapp.driversLicenseExpirationDate),
        ),
      );

      dispatch(
        changeAquaInfoValue(
          'coAppMonthlyNetIncome',
          coapp.monthlyNetIncome > 0 && coapp.monthlyNetIncome,
        ),
      );

      const coAppIncomeSource = Object.keys(incomeSource).find(
        key =>
          incomeSource[key].aquaValue === aquaFinanceInformation?.coapplicantEmployerType ||
          incomeSource[key].powerPayValue === coapp.employmentType,
      );
      dispatch(changeAquaInfoValue('coAppIncomeSource', incomeSource[coAppIncomeSource]?.value));
      dispatch(
        changeAquaInfoValue(
          'coAppEmploymentPosition',
          aquaFinanceInformation?.coapplicantEmploymentPosition || coapp.occupation,
        ),
      );

      dispatch(changeAquaInfoValue('coAppEmploymentYears', coapp.employmentYears));
      dispatch(changeAquaInfoValue('coAppEmploymentMonths', coapp.employmentMonths));
      dispatch(changeAquaInfoValue('isCoAppEmployed', coapp.employer ? true : null));
      dispatch(changeAquaInfoValue('coAppEmployerName', coapp.employer));
      dispatch(
        changeAquaInfoValue(
          'coAppEmployerPhoneNumber',
          aquaFinanceInformation?.coapplicantEmployerPhoneNumber || app.coappEmployerPhoneNumber,
        ),
      );

      dispatch(
        changeAquaInfoValue('coAppDoB', getAddedByAPIValue(coapp.birthDate) || '01/01/1900'),
      );
      dispatch(changeAquaInfoValue('coAppFullSsn', getAddedByAPIValue(coapp.ssn)));

      dispatch(changeAquaInfoValue('coAppSalaryIncome', coapp.grossYearlyIncome));
      dispatch(changeAquaInfoValue('coAppAdditionalIncome', coapp.otherYearlyIncome));
      dispatch(changeAquaInfoValue('coAppOtherSourcesOfIncome', coapp.sourceOtherYearlyIncome));

      if (coapp.address) {
        const address = coapp.address;
        dispatch(
          changeAquaInfoValue(
            'isSameAddress',
            isSameAddress(app.applicantPrimary.address, coapp.address),
          ),
        );
        dispatch(changeAquaInfoValue('coAppStreet', address.street));
        dispatch(changeAquaInfoValue('coAppCity', address.city));
        dispatch(changeAquaInfoValue('coAppZipCode', address.zipcode));
        dispatch(changeAquaInfoValue('coAppState', address.state));
      }
    }
  }
};

const recursiveGetAquaStatus = (i, app) => async dispatch => {
  if (i <= 5 && app != null && isStatusAquaIntermediate(app.application.processingStatus)) {
    if (0 === i) {
      // Timeout after Submit call
      await sleep(15);
    } else {
      await sleep(10);
    }
    await axios
      .get(
        `${baseURL}/api/soft_pull/get_aqua_status?selectedFinancier=${encodeURIComponent(
          app.selectedFinancier,
        )}&appId=${encodeURIComponent(app.applicationId)}`,
      )
      .then(response => {
        dispatch({ type: UPDATE_AQUA_STATUS_AND_PROMOTIONS, payload: response.data });
        return dispatch(recursiveGetAquaStatus(i + 1, response.data));
      })
      .catch(error => {
        let errorMessage = handleApiError(error);
        if (!('' + errorMessage).includes('Network')) {
          dispatch(
            addNotification({
              message: errorMessage,
              messageType: 'Error',
            }),
          );
        }
        dispatch({
          type: SUBMIT_APPLICATION_SOFT_PULL_ERROR,
          payload: error,
          errorMessage: errorMessage,
        });
        return false;
      });
  } else {
    return true;
  }
};

export const submitAquaApplication = (params, page, app) => async (dispatch, getState) => {
  dispatch(
    startLoading(
      'Give us a second while we find the best ' +
        params.selectedFinancier +
        ' financing option for you.',
    ),
  );
  if (app !== null) {
    await axios
      .post(
        `${baseURL}/api/soft_pull/resubmit?appToken=${app.consumerToken}&appId=${
          app.applicationId
        }`,
        params,
      )
      .then(async response => {
        dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_SUCCESS, payload: response.data });
        if (dispatch(checkErrors(response))) {
          await dispatch(recursiveGetAquaStatus(0, response.data));
          const errorMessage = getState().softPull.errorMessage;
          const dealerUserId = parseInt(response.data.dealerUser.dealerUser.userId);
          if (!('' + errorMessage).includes('Network')) {
            getData(
              `${baseURL}/api/soft_pull/samlResponse?dealerUserId=` + dealerUserId,
              null,
              AQUA_FINANCE_LOGIN_SUCCESS,
              AQUA_FINANCE_LOGIN_ERROR,
              dispatch,
              null,
              null,
              page,
            );
          } else {
            dispatch(changePage(page));
            dispatch(endLoading());
          }
        }
      })
      .catch(error => {
        dispatch(
          addNotification({
            message: handleApiError(error),
            messageType: 'Error',
          }),
        );
        dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_ERROR, payload: error });
        dispatch(endLoading());
      });
  } else {
    await axios
      .post(`${baseURL}/api/soft_pull/submit`, params)
      .then(async response => {
        dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_SUCCESS, payload: response.data });
        if (dispatch(checkErrors(response))) {
          await dispatch(recursiveGetAquaStatus(0, response.data));
          const errorMessage = getState().softPull.errorMessage;
          const dealerUserId = parseInt(response.data.dealerUser.dealerUser.userId);
          if (!('' + errorMessage).includes('Network')) {
            getData(
              `${baseURL}/api/soft_pull/samlResponse?dealerUserId=` + dealerUserId,
              null,
              AQUA_FINANCE_LOGIN_SUCCESS,
              AQUA_FINANCE_LOGIN_ERROR,
              dispatch,
              null,
              null,
              page,
            );
          } else {
            dispatch(changePage(page));
            dispatch(endLoading());
          }
        }
      })
      .catch(error => {
        dispatch(
          addNotification({
            message: handleApiError(error),
            messageType: 'Error',
          }),
        );
        dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_ERROR, payload: error });
        dispatch(endLoading());
      });
  }
};

export const updateApplicationStatus = (applicationStatus, consumerToken) => async dispatch => {
  dispatch(startLoading('Processing'));
  await axios
    .post(
      `${baseURL}/api/soft_pull/updateAppStatus?applicationStatus=${applicationStatus}&consumerToken=${consumerToken}`,
    )
    .then(function() {
      dispatch(endLoading());
    })
    .catch(error => {
      dispatch(endLoading());
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
};

export const updateSelectedFinancier = (
  selectedFinancier,
  consumerToken,
  successCallBack,
) => async dispatch => {
  dispatch(startLoading('Processing'));
  await axios
    .post(
      `${baseURL}/api/soft_pull/updateSelectedFinancier?selectedFinancier=${encodeURIComponent(
        selectedFinancier,
      )}&consumerToken=${consumerToken}`,
    )
    .then(function() {
      successCallBack();
      dispatch(endLoading());
    })
    .catch(error => {
      dispatch(endLoading());
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
};

export const setApplicationPromotion = (applicationId, promotionId, page) => async dispatch => {
  dispatch(startLoading('Processing'));
  await axios
    .post(`${baseURL}/api/soft_pull/set_promotion`, {
      applicationId: applicationId,
      promotionId: promotionId,
    })
    .then(response => {
      dispatch(changeAquaInfoValue('selectedApplicationPromotion', promotionId));
      dispatch(changePage(page));
      dispatch(endLoading());
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(endLoading());
    });
};
