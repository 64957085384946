import axios from 'axios';
import { isMicrofAddedByAPI } from '../../assets/js/ApplicationStatusEnum';
import {
  CHANGE_POWER_PAY_INFO_VALUE,
  SUBMIT_APPLICATION_SOFT_PULL_ERROR,
  SUBMIT_APPLICATION_SOFT_PULL_SUCCESS,
} from './actionTypes';
import { baseURL, handleApiError } from './api';
import { changePage } from './naviActions';
import { mapApplicationToPrequalification } from './softPullActions';
import { addNotification, endLoading, startLoading } from './notificationActions';
import { changePreQualInfoValue } from './preQualInfoActions';
import employmentTypes from '../../assets/js/EmploymentTypeEnum';
import { isSameAddress } from '../../helper';
import { checkErrors } from './index';

const qs = require('qs');
export const changePowerPayInfoValue = (key, value) => ({
  type: CHANGE_POWER_PAY_INFO_VALUE,
  key,
  value,
});

export const mapPowerPayToState = app => dispatch => {
  dispatch(changePreQualInfoValue('applicationId', app.applicationId));
  dispatch(mapApplicationToPrequalification(app));

  // PowerPay
  dispatch(changePowerPayInfoValue('borrowedAmount', app.totalFinancingAmount));
  dispatch(changePowerPayInfoValue('propertyType', app.propertyType));
  dispatch(changePowerPayInfoValue('propertyMonths', app.numberOfMonthsAtAddress));
  dispatch(changePowerPayInfoValue('propertyYears', app.numberOfYearsAtAddress));

  dispatch(changePowerPayInfoValue('homeownerInsurance', app.homeownerInsurance));
  dispatch(changePowerPayInfoValue('isHomeowner', true));
  const { aquaFinanceInformation } = app;

  if (app.applicantPrimary) {
    const applicant = app.applicantPrimary;
    dispatch(changePreQualInfoValue('firstName', applicant.nameFirst));
    dispatch(changePreQualInfoValue('middleName', applicant.nameMiddle));
    dispatch(changePreQualInfoValue('lastName', applicant.nameLast));
    dispatch(changePreQualInfoValue('phoneNumber', applicant.mobilePhone?.number));
    dispatch(changePreQualInfoValue('birthday', app.dob));
    if (applicant.address && isMicrofAddedByAPI(app.status)) {
      const address = applicant.address;
      dispatch(
        changePowerPayInfoValue('applicantAddress', {
          city: address.city,
          state: address.state,
          street: address.street,
          zip: address.zipcode,
        }),
      );
    }

    const employmentType = Object.keys(employmentTypes).find(
      key =>
        employmentTypes[key].powerPayValue === applicant.employmentType ||
        employmentTypes[key].aquaValue === applicant.employmentType,
    );

    dispatch(changePowerPayInfoValue('appEmploymentType', employmentTypes[employmentType]?.value));
    dispatch(
      changePowerPayInfoValue(
        'appOccupation',
        app.occupation || aquaFinanceInformation?.employmentPosition,
      ),
    );
    dispatch(changePowerPayInfoValue('appEmployerName', app.employer));
    dispatch(changePowerPayInfoValue('appEmploymentYears', app.employmentYears));
    dispatch(changePowerPayInfoValue('appEmploymentMonths', app.employmentMonths));
    dispatch(changePowerPayInfoValue('appEmployerZipCode', app.appEmployerZipCode));
    dispatch(changePowerPayInfoValue('isAppMilitaryActive', applicant.militaryActive));
    dispatch(changePowerPayInfoValue('isAppUsCitizen', applicant.uscitizen));
    dispatch(changePowerPayInfoValue('appDriversLicenseNumber', applicant.idNumber));
    dispatch(changePowerPayInfoValue('appDriversLicenseState', applicant.driversLicenseSOI));
    dispatch(changePowerPayInfoValue('appGrossAnnualIncome', applicant.grossYearlyIncome));
    dispatch(changePowerPayInfoValue('appFullSsn', applicant.ssn));
  }
  if (app.applicantNonPrimary) {
    const coapp = app.applicantNonPrimary;
    dispatch(changePowerPayInfoValue('hasCoApp', true));
    dispatch(changePowerPayInfoValue('coAppIdNumber', coapp.idNumber));
    dispatch(changePowerPayInfoValue('coAppDriversLicenseState', coapp.driversLicenseSOI));
    dispatch(changePowerPayInfoValue('grossYearlyIncome', coapp.grossYearlyIncome));
    dispatch(changePowerPayInfoValue('coAppFullSsn', coapp.ssn));
    dispatch(changePowerPayInfoValue('coapplicantEmployerType', app.coapplicantEmployerType));
    dispatch(
      changePowerPayInfoValue(
        'coAppOccupation',
        coapp.occupation || aquaFinanceInformation?.coapplicantEmploymentPosition,
      ),
    );
    dispatch(changePowerPayInfoValue('coapplicantEmployerName', app.coapplicantEmployerName));
    dispatch(changePowerPayInfoValue('coapplicantEmploymentYears', app.coapplicantEmploymentYears));
    dispatch(
      changePowerPayInfoValue('coapplicantEmploymentMonths', app.coapplicantEmploymentMonths),
    );
    dispatch(changePowerPayInfoValue('coAppEmployerZipCode', app.coAppEmployerZipCode));
    dispatch(changePowerPayInfoValue('isCoAppMilitaryActive', coapp.militaryActive));
    dispatch(changePowerPayInfoValue('isCoAppUsCitizen', coapp.uscitizen));

    dispatch(changePowerPayInfoValue('relationshipWithCoapplicant', coapp.relationshipToPrimary));
    dispatch(changePowerPayInfoValue('coAppFirstName', coapp.nameFirst));
    dispatch(changePowerPayInfoValue('coAppLastName', coapp.nameLast));
    dispatch(changePowerPayInfoValue('coAppMiddleInitial', coapp.nameMiddle));
    dispatch(changePowerPayInfoValue('coAppPhoneNumber', coapp.mobilePhone?.number));
    dispatch(changePowerPayInfoValue('coAppEmail', coapp.email?.email));

    const { address } = coapp;
    if (address) {
      dispatch(
        changePowerPayInfoValue(
          'isSameAddress',
          isSameAddress(app.applicantPrimary.address, address),
        ),
      );
      dispatch(changePowerPayInfoValue('coAppStreet', address.street));
      dispatch(changePowerPayInfoValue('coAppPOBox', address.poBox));
      dispatch(changePowerPayInfoValue('coAppCity', address.city));
      dispatch(changePowerPayInfoValue('coAppZipCode', address.zipcode));
      dispatch(changePowerPayInfoValue('coAppState', address.state));
    }

    dispatch(changePowerPayInfoValue('coAppBirthday', coapp.birthDate));
    dispatch(changePowerPayInfoValue('coAppDriversLicenseNumber', coapp.idNumber));
    dispatch(changePowerPayInfoValue('coAppGrossAnnualIncome', coapp.grossYearlyIncome));

    const employmentType = Object.keys(employmentTypes).find(
      key =>
        employmentTypes[key].powerPayValue === coapp.employmentTypee ||
        employmentTypes[key].aquaValue === coapp.employmentType,
    );
    dispatch(
      changePowerPayInfoValue('coAppEmploymentType', employmentTypes[employmentType]?.value),
    );
    dispatch(changePowerPayInfoValue('coAppEmployerName', coapp.employer));
    dispatch(changePowerPayInfoValue('coAppEmploymentMonths', coapp.employmentMonths));
    dispatch(changePowerPayInfoValue('coAppEmploymentYears', coapp.employmentYears));
    dispatch(changePowerPayInfoValue('coAppEmployerZipCode', coapp.employerZipcode));
  }
};

export const setPowerPayApplicationPromotion = () => {};

export const submitPowerPayApplication = (params, page, app) => async dispatch => {
  dispatch(
    startLoading(
      'Give us a second while we find the best ' +
        params.selectedFinancier +
        ' financing option for you.',
    ),
  );
  if (app !== null) {
    await axios
      .post(
        `${baseURL}/api/soft_pull/power_pay_app_resubmit`,
        qs.stringify({
          appToken: `${encodeURIComponent(app.consumerToken)}`,
          appId: `${encodeURIComponent(app.applicationId)}`,
          data: '' + JSON.stringify(params),
        }),
      )
      .then(async response => {
        dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_SUCCESS, payload: response.data });
        if (dispatch(checkErrors(response))) {
          dispatch(changePage(page));
          dispatch(endLoading());
        }
      })
      .catch(error => {
        dispatch(
          addNotification({
            message: handleApiError(error),
            messageType: 'Error',
          }),
        );
        dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_ERROR, payload: error });
        dispatch(endLoading());
      });
  } else {
    await axios
      .post(
        `${baseURL}/api/soft_pull/power_pay_app`,
        qs.stringify({ data: '' + JSON.stringify(params) }),
      )
      .then(async response => {
        dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_SUCCESS, payload: response.data });
        if (dispatch(checkErrors(response))) {
          dispatch(changePage(page));
          dispatch(endLoading());
        }
      })
      .catch(error => {
        dispatch(
          addNotification({
            message: handleApiError(error),
            messageType: 'Error',
          }),
        );
        dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_ERROR, payload: error });
        dispatch(endLoading());
      });
  }
};
