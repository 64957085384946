import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
import { initialState } from '../initialState';

const changeValue = (state, action) => {
  const updatedValues = {
    [action.key]: action.value,
  };
  return updateObject(state, updatedValues);
};

const themeReducer = (state, action) => {
  if (!state) {
    state = initialState.theme;
  }
  if (action.type === actionTypes.CHANGE_THEME) {
    return changeValue(state, action);
  } else {
    return state;
  }
};

export default themeReducer;
