import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import BackBtn from '../../components/UI/BackButton/BackButton';
import Button from '../../components/UI/Button/CustomButton';
import CustomDatePicker from '../../components/UI/Form/DatePicker/DatePicker';
import CurrencyFormat from '../../components/UI/Form/NumberFormat/CurrencyFormat';
import Select from '../../components/UI/Form/Select/Select';
import { changeAquaInfoValue, changePage, submitMindfire } from '../../redux/actions/index';
import PropTypes from 'prop-types';
import { reactGAPageview, reactGAEvent } from '../../helper';
import { AquaFlowPageNumber } from './AquaFlow';
import {
  BORROWEDAMOUNTT_TOOLTIP_TEXT,
  HOME_VALUE_TOOLTIP_TEXT,
  HVAC_INSTALLMENT_DATE_TOOLTIP_TEXT,
  HVAC_MONTHLY_PAYMENT_TOOLTIP_TEXT,
  OWN_OR_RENT_TOOLTIP_TEXT,
} from '../../constants/tooltipTexts';

const HvacDetails = props => {
  const {
    dispatchChangePage,
    dispatchChangeValue,
    aquaInformation,
    isReview,
    children,
    email,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    borrowedAmountPqi,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_aqhomeinfo',
      trackerNames: [],
      title: 'ca_aqhomeinfo',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const HvacDetailsForm = () => (
    <Form noValidate>
      {!borrowedAmountPqi && (
        <Field
          component={CurrencyFormat}
          label="Financing Amount"
          name="borrowedAmount"
          id="borrowedAmount"
          tooltipText={BORROWEDAMOUNTT_TOOLTIP_TEXT}
        />
      )}
      <Field
        component={Select}
        label="Do you own or rent your home?"
        name="ownOrRent"
        id="ownOrRent"
        tooltipText={OWN_OR_RENT_TOOLTIP_TEXT}
        type="select"
        selectValues={{
          Own: { value: 'Own', name: 'Own' },
          Rent: { value: 'Rent', name: 'Rent' },
        }}
        selectFirstName="Select One"
      />
      <Field
        component={CurrencyFormat}
        placeholder="$0"
        label="Monthly Payment (if no payment enter $0)"
        name="hvacMonthlyPayment"
        id="hvacMonthlyPayment"
        tooltipText={HVAC_MONTHLY_PAYMENT_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={CurrencyFormat}
        label="Home Value"
        name="homeValue"
        id="homeValue"
        tooltipText={HOME_VALUE_TOOLTIP_TEXT}
        type="text"
      />
      <p className="info">Your home value can be an estimate.</p>
      <Field
        component={CustomDatePicker}
        name="hvacInstallmentDate"
        id="hvacInstallmentDate"
        tooltipText={HVAC_INSTALLMENT_DATE_TOOLTIP_TEXT}
        label="Estimated installation Date"
      />
      <Button type="submit" title={isReview ? 'Review' : 'Next'} />
    </Form>
  );

  const HvacDetailsFormFormik = withFormik({
    mapPropsToValues: () => ({
      borrowedAmount: aquaInformation.borrowedAmount || '',
      ownOrRent: aquaInformation.ownOrRent || '',
      hvacMonthlyPayment: aquaInformation.hvacMonthlyPayment || '',
      homeValue: aquaInformation.homeValue || '',
      hvacInstallmentDate: aquaInformation.hvacInstallmentDate || '',
    }),

    validationSchema: Yup.object({
      borrowedAmount: Yup.number()
        .moreThan(1499, `Amount should be at least $1500.`)
        .lessThan(100000, 'Amount too high.')
        .test('required', 'Please enter an amount.', val => (!borrowedAmountPqi ? val : !val)),
      ownOrRent: Yup.string().required('Please select an option.'),
      hvacMonthlyPayment: Yup.number().required('Please enter your monthly payment.'),
      homeValue: Yup.number()
        .min(3000, 'Amount should be at least $3000.')
        .required('Please enter your home value.'),
      hvacInstallmentDate: Yup.string()
        .required('Please enter the estimated installment date.')
        .test(
          'minDate',
          'Invalid date.',
          val => val && new Date(val) >= new Date().setHours(0, 0, 0, 0),
        ),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      dispatchChangeValue('ownOrRent', values.ownOrRent);
      dispatchChangeValue('borrowedAmount', values.borrowedAmount);
      dispatchChangeValue('hvacMonthlyPayment', values.hvacMonthlyPayment);
      dispatchChangeValue('homeValue', values.homeValue);
      dispatchChangeValue('hvacInstallmentDate', values.hvacInstallmentDate);
      dispatchChangeValue('isReview', false);
      dispatchChangePage(
        aquaInformation.isReview
          ? AquaFlowPageNumber.ApplicationReview
          : AquaFlowPageNumber.HasCoApplicant,
      );
      dispatchSubmitMindfire({
        email: email,
        homeowner: values.ownOrRent,
        lastPage: '0034',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(HvacDetailsForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={isReview ? AquaFlowPageNumber.ApplicationReview : AquaFlowPageNumber.AquaStartPage}
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <HvacDetailsFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  borrowedAmountPqi:
    state.preQualificationInformation?.borrowedAmount ||
    state.softPull.softPullResponse?.borrowedAmount ||
    state.softPull.applicationSubmitDTO?.prequalificationInformation?.borrowedAmount,
  airConditioningSystem: state.preQualificationInformation.airConditioningSystem,
  aquaInformation: state.aquaInformation,
  borrowedAmount: state.aquaInformation.borrowedAmount,
  email: state.preQualificationInformation.email,
  ownOrRent: state.aquaInformation.ownOrRent,
  isReview: state.aquaInformation.isReview,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) => dispatch(changeAquaInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

HvacDetails.propTypes = {
  aquaInformation: PropTypes.object,
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HvacDetails);
