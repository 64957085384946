import React, { useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Button from '../../../components/UI/Button/CustomButton';
import { connect } from 'react-redux';
import { changePage, submitMindfire } from '../../../redux/actions/index';
import { reactGAPageview, reactGAEvent } from '../../../helper';
import { MosaicPreQualificationFlowPageNumber } from './PreQualificationFlow';

const Landing = props => {
  const { dispatchChangePage, dealerUserId, did, dispatchSubmitMindfire } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_pplanding',
      trackerNames: [],
      title: 'ca_pplanding',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const handleClick = () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Continue',
      did,
      dealerUserId,
    });
    dispatchSubmitMindfire({
      lastPage: '6000',
      dealerUserId: dealerUserId,
      did: did,
    });
    dispatchChangePage(MosaicPreQualificationFlowPageNumber.AccountEmail);
  };

  return (
    <Container>
      <Row className="justify-content-center mt-2">
        <Col xs="auto">
          <h5>Contractor</h5>
        </Col>
      </Row>
      <Row className="justify-content-center mt-2">
        <Col xs="auto">
          <h5>Please hand device to the applicant</h5>
        </Col>
      </Row>
      <Row className="justify-content-center mt-5">
        <Col xs="auto">
          <h5>Applicant</h5>
        </Col>
      </Row>
      <Row className="justify-content-center mt-2">
        <Col xs="auto">
          <h5>Please press the Continue Button</h5>
        </Col>
      </Row>
      <Row className="justify-content-center mt-4">
        <Col xs="12" sm="8" md="6" lg="4">
          <Button type="button" title="Continue" clickHandler={handleClick} />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Landing);
