import React from 'react';
import UserRoleEnum from '../../../assets/js/UserRoleEnum';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { changeSoftPullValue } from '../../../redux/actions';
import AddedFromEnum from '../../../assets/js/AddedFromEnum';

const ContractorUsersTable = props => {
  const { contractorUsers, history, hasAction, setPageIndex, dispatchChangeSoftPullValue } = props;

  return (
    <table className="table table-hover table-striped">
      <thead>
        <tr>
          <th>Email</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Role</th>
          <th>Contractor</th>
          <th>Platform</th>
          <th>Dealer ID</th>
          <th>{`${hasAction ? 'Action' : 'State'}`}</th>
        </tr>
      </thead>
      <tbody>
        {contractorUsers.userDTOs.map((userDTO, index) => (
          <tr
            className={`${!hasAction ? 'cursor-pointer' : ''}`}
            key={index}
            onClick={() => {
              if (!hasAction) {
                history.push(
                  '/portal/manage-contractor-users/view-contractor-user?id=' + userDTO.id,
                );
              }
            }}
          >
            <td className="align-middle">{userDTO.email}</td>
            <td className="align-middle">{userDTO.firstName}</td>
            <td className="align-middle">{userDTO.lastName}</td>
            <td className="align-middle">{UserRoleEnum[userDTO.role].name}</td>
            <td className="align-middle">{userDTO.dealer}</td>
            <td className="align-middle">
              {
                AddedFromEnum[
                  Object.keys(AddedFromEnum).find(
                    key => AddedFromEnum[key].value === userDTO.addedFrom,
                  )
                ]?.name
              }
            </td>
            <td className="align-middle">{userDTO.dealerId}</td>
            <td className="align-middle">
              {hasAction ? (
                <Button
                  type="reset"
                  color="secondary"
                  className="rounded-pill"
                  outline
                  onClick={() => {
                    dispatchChangeSoftPullValue('moveAppTargetContractor', userDTO);
                    setPageIndex(2);
                  }}
                >
                  Move
                </Button>
              ) : (
                userDTO.state
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const mapDispatchToProps = dispatch => ({
  dispatchChangeSoftPullValue: (key, value) => dispatch(changeSoftPullValue(key, value)),
});

export default connect(
  null,
  mapDispatchToProps,
)(ContractorUsersTable);
