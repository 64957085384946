import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

const Disclosure = props => {
  const { disclosureFooter } = props;

  const buildFooterInnerHtml = () => ({
    __html: disclosureFooter,
  });

  return (
    <Row className="mt-10 disclosure">
      <Col>
        {disclosureFooter && (
          <p title="disclosureFooter" dangerouslySetInnerHTML={buildFooterInnerHtml()} />
        )}
      </Col>
    </Row>
  );
};

const mapStateToProps = state => ({
  disclosureFooter: state.greenSkyApplicationInformation.disclosureFooter,
});

const mapDispatchToProps = dispatch => ({});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Disclosure),
);
