import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import employmentStatus from '../../../../assets/js/EmploymentStatusEnum';
import incomeFrequencyEnum from '../../../../assets/js/IncomeFrequencyEnum';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import CustomInput from '../../../../components/UI/Form/Input/CustomInput';
import CurrencyFormat from '../../../../components/UI/Form/NumberFormat/CurrencyFormat';
import SelectWithHandleChange from '../../../../components/UI/Form/Select/SelectWithHandleChange';
import {
  COAPP_EMPLOYER_NAME_TOOLTIP_TEXT,
  COAPP_EMPLOYMENT_MONTHS_TOOLTIP_TEXT,
  COAPP_EMPLOYMENT_STATUS_TOOLTIP_TEXT,
  COAPP_EMPLOYMENT_YEARS_TOOLTIP_TEXT,
  COAPP_ESTIMATED_GROSS_ANNUAL_INCOME_TOOLTIP_TEXT,
  COAPP_GROSS_INCOME_TOOLTIP_TEXT,
  COAPP_INCOME_FREQUENCY_TOOLTIP_TEXT,
  COAPP_OCCUPATION_STATUS_TOOLTIP_TEXT,
} from '../../../../constants/tooltipTexts';
import { changeApplicationValues, reactGAEvent, reactGAPageview } from '../../../../helper';
import {
  changeFoundationInfoValue,
  changePage,
  submitMindfire,
} from '../../../../redux/actions/index';
import { FoundationFlowPageNumber } from './FoundationFlow';

const CoAppEmploymentDetails = props => {
  const {
    email,
    dispatchChangePage,
    dispatchChangeFoundationInfoValue,
    foundationInformation,
    children,
    isReview,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
  } = props;
  let isEmployed =
    foundationInformation.coAppEmploymentStatus === employmentStatus.FullTime.value ||
    foundationInformation.coAppEmploymentStatus === employmentStatus.PartTime.value ||
    foundationInformation.coAppEmploymentStatus === employmentStatus.SelfEmployed.value;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_fdcoconsumerincome1',
      trackerNames: [],
      title: 'ca_fdcoconsumerincome1',
      dealerUserId,
      did,
    });
    // eslint-disable-next-line
  }, []);

  const changeValues = values => {
    isEmployed =
      foundationInformation.coAppEmploymentStatus === employmentStatus.FullTime.value ||
      foundationInformation.coAppEmploymentStatus === employmentStatus.PartTime.value ||
      foundationInformation.coAppEmploymentStatus === employmentStatus.SelfEmployed.value;

    dispatchChangeFoundationInfoValue(
      'coAppEmployerName',
      isEmployed ? values.coAppEmployerName : null,
    );
    dispatchChangeFoundationInfoValue('coAppEmploymentStatus', values.coAppEmploymentStatus);
    dispatchChangeFoundationInfoValue(
      'coAppOccupation',
      isEmployed ? values.coAppOccupation : null,
    );
    dispatchChangeFoundationInfoValue(
      'coAppEmploymentYears',
      isEmployed ? parseInt(values.coAppEmploymentYears) : null,
    );
    dispatchChangeFoundationInfoValue(
      'coAppEmploymentMonths',
      isEmployed ? parseInt(values.coAppEmploymentMonths) : null,
    );
    dispatchChangeFoundationInfoValue('coAppGrossIncome', values.coAppGrossIncome);
    dispatchChangeFoundationInfoValue('coAppIncomeFrequency', values.coAppIncomeFrequency);
    dispatchChangeFoundationInfoValue(
      'coAppEstimatedsAnnualGrosIncome',
      values.coAppEstimatedsAnnualGrosIncome,
    );
  };

  const calculateEstimatedAnualIncomeBasedOnFrequency = (incomeFrequency, grossIncome) => {
    dispatchChangeFoundationInfoValue('coAppIncomeFrequency', incomeFrequency);
    if (grossIncome) {
      const frequency = Object.values(incomeFrequencyEnum).find(
        freq => freq.value === incomeFrequency,
      )?.numericValue;
      dispatchChangeFoundationInfoValue('coAppEstimatedsAnnualGrosIncome', frequency * grossIncome);
    }
  };

  const calculateEstimatedAnualIncomeBasedOnIncome = (income, setFieldValue) => {
    const incomeFrequency = Object.values(incomeFrequencyEnum).find(
      freq => freq.value === foundationInformation.coAppIncomeFrequency,
    )?.numericValue;
    if (incomeFrequency) {
      setFieldValue('coAppEstimatedsAnnualGrosIncome', incomeFrequency * income);
    }
  };

  const CoAppEmploymentDetailsForm = ({ values, setFieldValue }) => (
    <Form>
      <Field
        component={SelectWithHandleChange}
        label="Income (or benefits) Type"
        id="coAppEmploymentStatus"
        tooltipText={COAPP_EMPLOYMENT_STATUS_TOOLTIP_TEXT}
        name="coAppEmploymentStatus"
        type="select"
        selectValues={employmentStatus}
        selectFirstName="Select One"
        handleChange={e => changeValues({ ...values, coAppEmploymentStatus: e.target.value })}
      />
      {isEmployed ? (
        <>
          <Field
            component={CustomInput}
            label="Employer"
            name="coAppEmployerName"
            id="coAppEmployerName"
            tooltipText={COAPP_EMPLOYER_NAME_TOOLTIP_TEXT}
            type="text"
          />
          <Field
            component={CustomInput}
            label="Title/Occupation"
            name="coAppOccupation"
            id="coAppOccupation"
            tooltipText={COAPP_OCCUPATION_STATUS_TOOLTIP_TEXT}
            type="text"
          />
          <Row className="mt-4">
            <Col>
              <Label>
                <b>Years at employer</b>
              </Label>
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <Field
                component={CustomInput}
                placeholder="Years"
                formGroupClass="mt-0 mb-2"
                labelClassName="d-none"
                label="Years"
                name="coAppEmploymentYears"
                id="coAppEmploymentYears"
                tooltipText={COAPP_EMPLOYMENT_YEARS_TOOLTIP_TEXT}
                type="number"
                min={0}
              />
            </Col>
            <Col sm="6">
              <Field
                component={CustomInput}
                placeholder="Months"
                formGroupClass="mt-0 mb-2"
                labelClassName="d-none"
                label="Months"
                name="coAppEmploymentMonths"
                id="coAppEmploymentMonths"
                tooltipText={COAPP_EMPLOYMENT_MONTHS_TOOLTIP_TEXT}
                type="number"
                min={0}
              />
            </Col>
          </Row>
        </>
      ) : null}
      <Field
        component={CurrencyFormat}
        label="Gross Income (or benefits)"
        name="coAppGrossIncome"
        id="coAppGrossIncome"
        tooltipText={COAPP_GROSS_INCOME_TOOLTIP_TEXT}
        type="text"
        handleChange={v => {
          calculateEstimatedAnualIncomeBasedOnIncome(v.floatValue, setFieldValue);
        }}
      />

      <Field
        component={SelectWithHandleChange}
        label="Frequency"
        name="coAppIncomeFrequency"
        id="coAppIncomeFrequency"
        tooltipText={COAPP_INCOME_FREQUENCY_TOOLTIP_TEXT}
        type="select"
        selectValues={incomeFrequencyEnum}
        selectFirstName="Select Frequency"
        handleChange={f => {
          changeApplicationValues(dispatchChangeFoundationInfoValue, values);
          calculateEstimatedAnualIncomeBasedOnFrequency(f.target.value, values.coAppGrossIncome);
        }}
      />

      <Field
        disabled={true}
        component={CurrencyFormat}
        label="Estimated Annual Gross Income (or benefits)"
        name="coAppEstimatedsAnnualGrosIncome"
        id="coAppEstimatedsAnnualGrosIncome"
        tooltipText={COAPP_ESTIMATED_GROSS_ANNUAL_INCOME_TOOLTIP_TEXT}
        type="text"
      />
      <Button type="submit" title="Next" />
    </Form>
  );

  const CoAppEmploymentDetailsFormFormik = withFormik({
    mapPropsToValues: () => ({
      coAppEmploymentStatus: foundationInformation.coAppEmploymentStatus || '',
      coAppEmployerName: foundationInformation.coAppEmployerName || '',
      coAppOccupation: foundationInformation.coAppOccupation || '',
      coAppEmploymentYears:
        foundationInformation.coAppEmploymentYears === 0
          ? 0
          : foundationInformation.coAppEmploymentYears || '',
      coAppEmploymentMonths:
        foundationInformation.coAppEmploymentMonths === 0
          ? 0
          : foundationInformation.coAppEmploymentMonths || '',
      coAppGrossIncome: foundationInformation.coAppGrossIncome || '',
      coAppIncomeFrequency: foundationInformation.coAppIncomeFrequency || '',
      coAppEstimatedsAnnualGrosIncome: foundationInformation.coAppEstimatedsAnnualGrosIncome || '',
    }),

    validationSchema: Yup.object({
      coAppEmploymentStatus: Yup.string().required('Please select the employment status.'),
      coAppGrossIncome: Yup.number().required('Please enter your gross income.'),
      coAppEstimatedsAnnualGrosIncome: Yup.number().required(
        'Please enter your gross income and income frequency.',
      ),
      coAppIncomeFrequency: Yup.string().required('Please select a Frequency.'),
      coAppEmployerName: isEmployed
        ? Yup.string().required("Please enter your employer's name.")
        : Yup.string(),
      coAppOccupation: isEmployed
        ? Yup.string().required('Please enter your occupation.')
        : Yup.string(),
      coAppEmploymentYears: isEmployed
        ? Yup.number()
            .moreThan(-1, 'Please enter a positive number.')
            .integer('Please enter an integer number.')
            .max(70, 'Must be less than or equal to 70.')
            .required('Please enter years.')
        : Yup.number(),
      coAppEmploymentMonths: isEmployed
        ? Yup.number()
            .moreThan(-1, 'Please enter a positive number.')
            .integer('Please enter an integer number.')
            .max(11, 'Must be less than or equal to 11.')
            .required('Please enter months.')
        : Yup.number(),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did: did,
        dealerUserId: dealerUserId,
      });
      changeValues(values);
      dispatchChangePage(FoundationFlowPageNumber.CoAppOtherSourceOfIncome);
      dispatchSubmitMindfire({
        email: email,
        company: values.coAppEmployerName,
        lastPage: '10019',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(CoAppEmploymentDetailsForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={
            isReview
              ? FoundationFlowPageNumber.ApplicationReview
              : FoundationFlowPageNumber.CoAppIdentificationInfo
          }
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <CoAppEmploymentDetailsFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  foundationInformation: state.foundationInformation,
  email: state.preQualificationInformation.email,
  isReview: state.foundationInformation.isReview,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeFoundationInfoValue: (key, value) =>
    dispatch(changeFoundationInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

CoAppEmploymentDetails.propTypes = {
  foundationInformation: PropTypes.object,
  dispatchChangePage: PropTypes.func,
  dispatchChangeFoundationInfoValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CoAppEmploymentDetails);
