import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Section from '../../components/Section/Section';
import Header from '../../components/UI/Header/Header';
import { CONSUMER_PORTAL_TITLE, DEALER_PORTAL_TITLE } from '../../constants/document';
import { login, setFlow } from '../../redux/actions/index';
import { aquaFlow, microfFlow, preQualificationFlow } from '../../redux/initialState';
import { setDocumentTitle } from '../../util/documentUtil';
import RheemFlow from './RheemFlow';

const Rheem = props => {
  const { rheemFlowCurrentPage, history, flowName, auth } = props;

  useEffect(() => {
    const isUserLoggedIn = auth.user.dealerUser === null;
    setDocumentTitle(isUserLoggedIn ? CONSUMER_PORTAL_TITLE : DEALER_PORTAL_TITLE);

    if (flowName === microfFlow) {
      history.push('/RTO');
    } else if (flowName === aquaFlow) {
      history.push('/Aqua');
    } else if (flowName === preQualificationFlow) {
      history.push('/');
    }

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header
        title={RheemFlow[rheemFlowCurrentPage ? rheemFlowCurrentPage : 0].props.title}
        pages={RheemFlow.length - 1}
      />
      <Section Component={RheemFlow[rheemFlowCurrentPage ? rheemFlowCurrentPage : 0]} />
    </>
  );
};

const mapStateToProps = state => ({
  rheemFlowCurrentPage: state.navi.rheemFlowCurrentPage,
  flowName: state.navi.flowName,
  auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetFlow: flow => dispatch(setFlow(flow)),
  dispatchLogin: user => dispatch(login(user)),
});

Rheem.propTypes = {
  rheemFlowCurrentPage: PropTypes.number,
  score: PropTypes.number,
  flowName: PropTypes.string,
  dispatchSetFlow: PropTypes.func,
  dispatchLogin: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Rheem);
