import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field, Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Col, Label, Row, Alert } from 'reactstrap';
import CustomInput from '../../../../components/UI/Form/Input/InputFieldAll';
import Button from '../../../../components/UI/Button/ButtonField';
import CustomPhoneNumber from '../../../../components/UI/Form/PhoneNumber/PhoneNumberField';
import Select from '../../../../components/UI/Form/Select/SelectField';
import SimpleCheckbox from '../../../../components/UI/Form/Checkbox/SimpleCheckbox';
import {
  changePage,
  changeAuthValue,
  endLoading,
  createDealershipApplication,
} from '../../../../redux/actions/index';
import usStates from '../../../../assets/js/USStateEnum';
import { withRouter } from 'react-router-dom';
import { NewLanderContractorEnrollmentFlowPageNumber } from './_Flow';
import { isNoreplyEmail } from '../../../../helper';

const BeneficialOwnerInformation = props => {
  const [alertMessage, setAlertMessage] = useState(null);
  const [isReviewed, setReviewed] = useState(false);

  const {
    dealerDTO,
    dispatchChangeValue,
    dispatchChangePage,
    dispatchCreateDealershipApplication,
    history,
    dealerDTOCreateDealershipApplication,
    user,
  } = props;

  const changeDealerInfo = values => {
    let dealerOwners = [];
    let dealerOwner = {
      email: values.beneficialOwner1Email,
      nameFirst: values.beneficialOwner1FirstName,
      nameLast: values.beneficialOwner1LastName,
      percentOwnership: 1 * values.beneficialOwner1Ownership,
      phone: values.beneficialOwner1Phone,
      ssn: values.beneficialOwner1SSN,
      address: {
        city: values.beneficialOwner1City,
        county: null,
        numberOfMonthsAtAddress: null,
        numberOfYearsAtAddress: null,
        state: values.beneficialOwner1State,
        street: values.beneficialOwner1Street,
        zipcode: values.beneficialOwner1ZIPCode,
      },
    };
    dealerOwners.push(dealerOwner);

    if (!dealerDTO.ownersWith25) {
      if (values.beneficialOwner2Ownership) {
        let dealerOwner2 = {
          email: values.beneficialOwner2Email,
          nameFirst: values.beneficialOwner2FirstName,
          nameLast: values.beneficialOwner2LastName,
          percentOwnership: 1 * values.beneficialOwner2Ownership,
          phone: values.beneficialOwner2Phone,
          ssn: values.beneficialOwner2SSN,
          address: {
            city: values.beneficialOwner2City,
            county: null,
            numberOfMonthsAtAddress: null,
            numberOfYearsAtAddress: null,
            state: values.beneficialOwner2State,
            street: values.beneficialOwner2Street,
            zipcode: values.beneficialOwner2ZIPCode,
          },
        };
        dealerOwners.push(dealerOwner2);
      }

      if (values.beneficialOwner3Ownership) {
        let dealerOwner3 = {
          email: values.beneficialOwner3Email,
          nameFirst: values.beneficialOwner3FirstName,
          nameLast: values.beneficialOwner3LastName,
          percentOwnership: 1 * values.beneficialOwner3Ownership,
          phone: values.beneficialOwner3Phone,
          ssn: values.beneficialOwner3SSN,
          address: {
            city: values.beneficialOwner3City,
            county: null,
            numberOfMonthsAtAddress: null,
            numberOfYearsAtAddress: null,
            state: values.beneficialOwner3State,
            street: values.beneficialOwner3Street,
            zipcode: values.beneficialOwner3ZIPCode,
          },
        };
        dealerOwners.push(dealerOwner3);
      }

      if (values.beneficialOwner4Ownership) {
        let dealerOwner4 = {
          email: values.beneficialOwner4Email,
          nameFirst: values.beneficialOwner4FirstName,
          nameLast: values.beneficialOwner4LastName,
          percentOwnership: 1 * values.beneficialOwner4Ownership,
          phone: values.beneficialOwner4Phone,
          ssn: values.beneficialOwner4SSN,
          address: {
            city: values.beneficialOwner4City,
            county: null,
            numberOfMonthsAtAddress: null,
            numberOfYearsAtAddress: null,
            state: values.beneficialOwner4State,
            street: values.beneficialOwner4Street,
            zipcode: values.beneficialOwner4ZIPCode,
          },
        };
        dealerOwners.push(dealerOwner4);
      }
    }
    let dealer = { ...dealerDTO };
    dealer.dealerOwners = dealerOwners;

    dispatchChangeValue('dealerDTO', dealer);
    return dealer;
  };

  const BeneficialOwnerInformationForm = ({ values, errors, touched }) => (
    <Form autoComplete="off">
      {alertMessage ? <Alert color="danger">{alertMessage}</Alert> : null}
      <h3>Beneficial Owner {dealerDTO.ownersWith25 ? '' : '1 '}Information</h3>
      <Row>
        <Col sm="4" className="pl-0">
          <Label>Last Name *</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Field
            component={CustomInput}
            name="beneficialOwner1LastName"
            id="beneficialOwner1LastName"
            type="text"
          />
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="pl-0">
          <Label>First Name *</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Field
            component={CustomInput}
            name="beneficialOwner1FirstName"
            id="beneficialOwner1FirstName"
            type="text"
          />
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="pl-0">
          <Label>Phone *</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Field
            component={CustomPhoneNumber}
            name="beneficialOwner1Phone"
            id="beneficialOwner1Phone"
          />
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="pl-0">
          <Label>Email *</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Field
            component={CustomInput}
            name="beneficialOwner1Email"
            id="beneficialOwner1Email"
            type="email"
          />
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="pl-0">
          <Label>Street Address (Home) *</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Field
            component={CustomInput}
            name="beneficialOwner1Street"
            id="beneficialOwner1Street"
            type="text"
          />
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="pl-0">
          <Label>City (Home) *</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Field
            component={CustomInput}
            name="beneficialOwner1City"
            id="beneficialOwner1City"
            type="text"
          />
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="pl-0">
          <Label>State, Zip (Home) *</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Row>
            <Col sm="6" className="pl-0">
              <Field
                component={Select}
                name="beneficialOwner1State"
                id="beneficialOwner1State"
                type="select"
                selectValues={usStates}
                selectFirstName="Select State"
              />
            </Col>
            <Col sm="6" className="pr-0">
              <Field
                component={CustomInput}
                name="beneficialOwner1ZIPCode"
                id="beneficialOwner1ZIPCode"
                type="text"
                maxLength={10}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="pl-0">
          <Label>SSN *</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Field
            maxLength={9}
            component={CustomInput}
            id="beneficialOwner1SSN"
            name="beneficialOwner1SSN"
            type="password"
            number
            icon="lock"
          />
        </Col>
      </Row>
      <Row>
        <Col sm="4" className="pl-0">
          <Label>% Ownership *</Label>
        </Col>
        <Col sm="7" className="pr-0">
          <Field
            component={CustomInput}
            name="beneficialOwner1Ownership"
            id="beneficialOwner1Ownership"
            type="number"
          />
        </Col>
      </Row>

      {dealerDTO.ownersWith25 ? null : (
        <>
          <h3 className="top-20">Beneficial Owner 2 Information</h3>
          <Row>
            <Col sm="4" className="pl-0">
              <Label>Last Name *</Label>
            </Col>
            <Col sm="7" className="pr-0">
              <Field
                component={CustomInput}
                name="beneficialOwner2LastName"
                id="beneficialOwner2LastName"
                type="text"
              />
            </Col>
          </Row>
          <Row>
            <Col sm="4" className="pl-0">
              <Label>First Name *</Label>
            </Col>
            <Col sm="7" className="pr-0">
              <Field
                component={CustomInput}
                name="beneficialOwner2FirstName"
                id="beneficialOwner2FirstName"
                type="text"
              />
            </Col>
          </Row>
          <Row>
            <Col sm="4" className="pl-0">
              <Label>Phone *</Label>
            </Col>
            <Col sm="7" className="pr-0">
              <Field
                component={CustomPhoneNumber}
                name="beneficialOwner2Phone"
                id="beneficialOwner2Phone"
              />
            </Col>
          </Row>
          <Row>
            <Col sm="4" className="pl-0">
              <Label>Email *</Label>
            </Col>
            <Col sm="7" className="pr-0">
              <Field
                component={CustomInput}
                name="beneficialOwner2Email"
                id="beneficialOwner2Email"
                type="email"
              />
            </Col>
          </Row>
          <Row>
            <Col sm="4" className="pl-0">
              <Label>Street Address (Home) *</Label>
            </Col>
            <Col sm="7" className="pr-0">
              <Field
                component={CustomInput}
                name="beneficialOwner2Street"
                id="beneficialOwner2Street"
                type="text"
              />
            </Col>
          </Row>
          <Row>
            <Col sm="4" className="pl-0">
              <Label>City (Home) *</Label>
            </Col>
            <Col sm="7" className="pr-0">
              <Field
                component={CustomInput}
                name="beneficialOwner2City"
                id="beneficialOwner2City"
                type="text"
              />
            </Col>
          </Row>
          <Row>
            <Col sm="4" className="pl-0">
              <Label>State, Zip (Home) *</Label>
            </Col>
            <Col sm="7" className="pr-0">
              <Row>
                <Col sm="6" className="pl-0">
                  <Field
                    component={Select}
                    name="beneficialOwner2State"
                    id="beneficialOwner2State"
                    type="select"
                    selectValues={usStates}
                    selectFirstName="Select State"
                  />
                </Col>
                <Col sm="6" className="pr-0">
                  <Field
                    component={CustomInput}
                    name="beneficialOwner2ZIPCode"
                    id="beneficialOwner2ZIPCode"
                    type="text"
                    maxLength={10}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col sm="4" className="pl-0">
              <Label>SSN *</Label>
            </Col>
            <Col sm="7" className="pr-0">
              <Field
                maxLength={9}
                component={CustomInput}
                id="beneficialOwner2SSN"
                name="beneficialOwner2SSN"
                type="password"
                number
                icon="lock"
              />
            </Col>
          </Row>
          <Row>
            <Col sm="4" className="pl-0">
              <Label>% Ownership *</Label>
            </Col>
            <Col sm="7" className="pr-0">
              <Field
                component={CustomInput}
                name="beneficialOwner2Ownership"
                id="beneficialOwner2Ownership"
                type="number"
              />
            </Col>
          </Row>

          <h3 className="top-20">Beneficial Owner 3 Information</h3>
          <Row>
            <Col sm="4" className="pl-0">
              <Label>Last Name *</Label>
            </Col>
            <Col sm="7" className="pr-0">
              <Field
                component={CustomInput}
                name="beneficialOwner3LastName"
                id="beneficialOwner3LastName"
                type="text"
              />
            </Col>
          </Row>
          <Row>
            <Col sm="4" className="pl-0">
              <Label>First Name *</Label>
            </Col>
            <Col sm="7" className="pr-0">
              <Field
                component={CustomInput}
                name="beneficialOwner3FirstName"
                id="beneficialOwner3FirstName"
                type="text"
              />
            </Col>
          </Row>
          <Row>
            <Col sm="4" className="pl-0">
              <Label>Phone *</Label>
            </Col>
            <Col sm="7" className="pr-0">
              <Field
                component={CustomPhoneNumber}
                name="beneficialOwner3Phone"
                id="beneficialOwner3Phone"
              />
            </Col>
          </Row>
          <Row>
            <Col sm="4" className="pl-0">
              <Label>Email *</Label>
            </Col>
            <Col sm="7" className="pr-0">
              <Field
                component={CustomInput}
                name="beneficialOwner3Email"
                id="beneficialOwner3Email"
                type="email"
              />
            </Col>
          </Row>
          <Row>
            <Col sm="4" className="pl-0">
              <Label>Street Address (Home) *</Label>
            </Col>
            <Col sm="7" className="pr-0">
              <Field
                component={CustomInput}
                name="beneficialOwner3Street"
                id="beneficialOwner3Street"
                type="text"
              />
            </Col>
          </Row>
          <Row>
            <Col sm="4" className="pl-0">
              <Label>City (Home) *</Label>
            </Col>
            <Col sm="7" className="pr-0">
              <Field
                component={CustomInput}
                name="beneficialOwner3City"
                id="beneficialOwner3City"
                type="text"
              />
            </Col>
          </Row>
          <Row>
            <Col sm="4" className="pl-0">
              <Label>State, Zip (Home) *</Label>
            </Col>
            <Col sm="7" className="pr-0">
              <Row>
                <Col sm="6" className="pl-0">
                  <Field
                    component={Select}
                    name="beneficialOwner3State"
                    id="beneficialOwner3State"
                    type="select"
                    selectValues={usStates}
                    selectFirstName="Select State"
                  />
                </Col>
                <Col sm="6" className="pr-0">
                  <Field
                    component={CustomInput}
                    name="beneficialOwner3ZIPCode"
                    id="beneficialOwner3ZIPCode"
                    type="text"
                    maxLength={10}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col sm="4" className="pl-0">
              <Label>SSN *</Label>
            </Col>
            <Col sm="7" className="pr-0">
              <Field
                maxLength={9}
                component={CustomInput}
                id="beneficialOwner3SSN"
                name="beneficialOwner3SSN"
                type="password"
                number
                icon="lock"
              />
            </Col>
          </Row>
          <Row>
            <Col sm="4" className="pl-0">
              <Label>% Ownership *</Label>
            </Col>
            <Col sm="7" className="pr-0">
              <Field
                component={CustomInput}
                name="beneficialOwner3Ownership"
                id="beneficialOwner3Ownership"
                type="number"
              />
            </Col>
          </Row>

          <h3 className="top-20">Beneficial Owner 4 Information</h3>
          <Row>
            <Col sm="4" className="pl-0">
              <Label>Last Name *</Label>
            </Col>
            <Col sm="7" className="pr-0">
              <Field
                component={CustomInput}
                name="beneficialOwner4LastName"
                id="beneficialOwner4LastName"
                type="text"
              />
            </Col>
          </Row>
          <Row>
            <Col sm="4" className="pl-0">
              <Label>First Name *</Label>
            </Col>
            <Col sm="7" className="pr-0">
              <Field
                component={CustomInput}
                name="beneficialOwner4FirstName"
                id="beneficialOwner4FirstName"
                type="text"
              />
            </Col>
          </Row>
          <Row>
            <Col sm="4" className="pl-0">
              <Label>Phone *</Label>
            </Col>
            <Col sm="7" className="pr-0">
              <Field
                component={CustomPhoneNumber}
                name="beneficialOwner4Phone"
                id="beneficialOwner4Phone"
              />
            </Col>
          </Row>
          <Row>
            <Col sm="4" className="pl-0">
              <Label>Email *</Label>
            </Col>
            <Col sm="7" className="pr-0">
              <Field
                component={CustomInput}
                name="beneficialOwner4Email"
                id="beneficialOwner4Email"
                type="email"
              />
            </Col>
          </Row>
          <Row>
            <Col sm="4" className="pl-0">
              <Label>Street Address (Home) *</Label>
            </Col>
            <Col sm="7" className="pr-0">
              <Field
                component={CustomInput}
                name="beneficialOwner4Street"
                id="beneficialOwner4Street"
                type="text"
              />
            </Col>
          </Row>
          <Row>
            <Col sm="4" className="pl-0">
              <Label>City (Home) *</Label>
            </Col>
            <Col sm="7" className="pr-0">
              <Field
                component={CustomInput}
                name="beneficialOwner4City"
                id="beneficialOwner4City"
                type="text"
              />
            </Col>
          </Row>
          <Row>
            <Col sm="4" className="pl-0">
              <Label>State, Zip (Home) *</Label>
            </Col>
            <Col sm="7" className="pr-0">
              <Row>
                <Col sm="6" className="pl-0">
                  <Field
                    component={Select}
                    name="beneficialOwner4State"
                    id="beneficialOwner4State"
                    type="select"
                    selectValues={usStates}
                    selectFirstName="Select State"
                  />
                </Col>
                <Col sm="6" className="pr-0">
                  <Field
                    component={CustomInput}
                    name="beneficialOwner4ZIPCode"
                    id="beneficialOwner4ZIPCode"
                    type="text"
                    maxLength={10}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col sm="4" className="pl-0">
              <Label>SSN *</Label>
            </Col>
            <Col sm="7" className="pr-0">
              <Field
                maxLength={9}
                component={CustomInput}
                id="beneficialOwner4SSN"
                name="beneficialOwner4SSN"
                type="password"
                number
                icon="lock"
              />
            </Col>
          </Row>
          <Row>
            <Col sm="4" className="pl-0">
              <Label>% Ownership *</Label>
            </Col>
            <Col sm="7" className="pr-0">
              <Field
                component={CustomInput}
                name="beneficialOwner4Ownership"
                id="beneficialOwner4Ownership"
                type="number"
              />
            </Col>
          </Row>
        </>
      )}

      <Row>
        <Col sm="11" className="pl-0 pr-0">
          <Field
            component={SimpleCheckbox}
            name="reviewed"
            id="reviewed"
            label="I have reviewed all of the information on this enrollment Application and certify that it is correct."
            labelClassName="ml-4"
            className="ml-0"
            clickHandler={() => {
              changeDealerInfo(values);
              setReviewed(!isReviewed);
            }}
          />
        </Col>
      </Row>

      {dealerDTOCreateDealershipApplication ? (
        <Row>
          <Col sm="11" className="pl-0 pr-0">
            <Alert color="success">Your application was successfully submitted.</Alert>
          </Col>
        </Row>
      ) : null}

      {dealerDTOCreateDealershipApplication ? (
        <Row className="contractor-user-view-row p-0 m-0">
          <Col sm="11" className="text-right pl-0 pr-0">
            <Button
              type="submit"
              color="secondary"
              title="Back to Dashboard"
              className="rounded-pill"
              clickHandler={() => {
                const dealer = user?.user?.user?.dealerUser?.dealer;
                dispatchChangePage(
                  NewLanderContractorEnrollmentFlowPageNumber.DealershipInformation,
                );

                if (dealer) {
                  dealer.appliedForAquaFinance = dealerDTO.applyForAquaFinance;
                  dealer.appliedForPowerPay = dealerDTO.applyForPowerPay;
                  dealer.appliedForMosaic = dealerDTO.applyForMosaic;
                  dealer.appliedForEnerBank = dealerDTO.appliedForEnerBank;
                  dealer.appliedForGreenSky = dealerDTO.appliedForGreenSky;
                  dealer.appliedForFoundation = dealerDTO.appliedForFoundation;
                  dealer.appliedForFTL = dealerDTO.appliedForFTL;
                  dispatchChangeValue('user', user);
                }
                history.push('/portal/dashboard');
              }}
            />
          </Col>
        </Row>
      ) : (
        <Row className="contractor-user-view-row p-0 m-0">
          <Col sm="11" className="text-right pl-0 pr-0">
            <Button
              type="submit"
              title="Previous"
              className="rounded-pill"
              clickHandler={() => {
                dispatchChangePage(NewLanderContractorEnrollmentFlowPageNumber.BusinessManager);
              }}
            />
            <Button type="submit" title="Submit" className="rounded-pill" disabled={!isReviewed} />
          </Col>
        </Row>
      )}
    </Form>
  );

  const BeneficialOwnerInformationFormFormik = withFormik({
    mapPropsToValues: () => ({
      reviewed: isReviewed,

      beneficialOwner1LastName:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[0]
          ? dealerDTO.dealerOwners[0].nameLast || ''
          : '',
      beneficialOwner1FirstName:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[0]
          ? dealerDTO.dealerOwners[0].nameFirst || ''
          : '',
      beneficialOwner1Phone:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[0]
          ? dealerDTO.dealerOwners[0].phone || ''
          : '',
      beneficialOwner1Email:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[0]
          ? dealerDTO.dealerOwners[0].email || ''
          : '',
      beneficialOwner1Street:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[0]
          ? dealerDTO.dealerOwners[0].address.street || ''
          : '',
      beneficialOwner1City:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[0]
          ? dealerDTO.dealerOwners[0].address.city || ''
          : '',
      beneficialOwner1ZIPCode:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[0]
          ? dealerDTO.dealerOwners[0].address.zipcode || ''
          : '',
      beneficialOwner1State:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[0]
          ? dealerDTO.dealerOwners[0].address.state || ''
          : '',
      beneficialOwner1SSN:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[0] && dealerDTO.dealerOwners[0].ssn
          ? dealerDTO.dealerOwners[0].ssn.split('-').join('')
          : '',
      beneficialOwner1Ownership:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[0]
          ? dealerDTO.dealerOwners[0].percentOwnership || ''
          : '',

      beneficialOwner2LastName:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[1]
          ? dealerDTO.dealerOwners[1].nameLast || ''
          : '',
      beneficialOwner2FirstName:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[1]
          ? dealerDTO.dealerOwners[1].nameFirst || ''
          : '',
      beneficialOwner2Phone:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[1]
          ? dealerDTO.dealerOwners[1].phone || ''
          : '',
      beneficialOwner2Email:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[1]
          ? dealerDTO.dealerOwners[1].email || ''
          : '',
      beneficialOwner2Street:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[1]
          ? dealerDTO.dealerOwners[1].address.street || ''
          : '',
      beneficialOwner2City:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[1]
          ? dealerDTO.dealerOwners[1].address.city || ''
          : '',
      beneficialOwner2ZIPCode:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[1]
          ? dealerDTO.dealerOwners[1].address.zipcode || ''
          : '',
      beneficialOwner2State:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[1]
          ? dealerDTO.dealerOwners[1].address.state || ''
          : '',
      beneficialOwner2SSN:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[1] && dealerDTO.dealerOwners[1].ssn
          ? dealerDTO.dealerOwners[1].ssn.split('-').join('')
          : '',
      beneficialOwner2Ownership:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[1]
          ? dealerDTO.dealerOwners[1].percentOwnership || ''
          : '',

      beneficialOwner3LastName:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[2]
          ? dealerDTO.dealerOwners[2].nameLast || ''
          : '',
      beneficialOwner3FirstName:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[2]
          ? dealerDTO.dealerOwners[2].nameFirst || ''
          : '',
      beneficialOwner3Phone:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[2]
          ? dealerDTO.dealerOwners[2].phone || ''
          : '',
      beneficialOwner3Email:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[2]
          ? dealerDTO.dealerOwners[2].email || ''
          : '',
      beneficialOwner3Street:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[2]
          ? dealerDTO.dealerOwners[2].address.street || ''
          : '',
      beneficialOwner3City:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[2]
          ? dealerDTO.dealerOwners[2].address.city || ''
          : '',
      beneficialOwner3ZIPCode:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[2]
          ? dealerDTO.dealerOwners[2].address.zipcode || ''
          : '',
      beneficialOwner3State:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[2]
          ? dealerDTO.dealerOwners[2].address.state || ''
          : '',
      beneficialOwner3SSN:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[2] && dealerDTO.dealerOwners[2].ssn
          ? dealerDTO.dealerOwners[2].ssn.split('-').join('')
          : '',
      beneficialOwner3Ownership:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[2]
          ? dealerDTO.dealerOwners[2].percentOwnership || ''
          : '',

      beneficialOwner4LastName:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[3]
          ? dealerDTO.dealerOwners[3].nameLast || ''
          : '',
      beneficialOwner4FirstName:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[3]
          ? dealerDTO.dealerOwners[3].nameFirst || ''
          : '',
      beneficialOwner4Phone:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[3]
          ? dealerDTO.dealerOwners[3].phone || ''
          : '',
      beneficialOwner4Email:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[3]
          ? dealerDTO.dealerOwners[3].email || ''
          : '',
      beneficialOwner4Street:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[3]
          ? dealerDTO.dealerOwners[3].address.street || ''
          : '',
      beneficialOwner4City:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[3]
          ? dealerDTO.dealerOwners[3].address.city || ''
          : '',
      beneficialOwner4ZIPCode:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[3]
          ? dealerDTO.dealerOwners[3].address.zipcode || ''
          : '',
      beneficialOwner4State:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[3]
          ? dealerDTO.dealerOwners[3].address.state || ''
          : '',
      beneficialOwner4SSN:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[3] && dealerDTO.dealerOwners[3].ssn
          ? dealerDTO.dealerOwners[3].ssn.split('-').join('')
          : '',
      beneficialOwner4Ownership:
        dealerDTO.dealerOwners && dealerDTO.dealerOwners[3]
          ? dealerDTO.dealerOwners[3].percentOwnership || ''
          : '',
    }),

    validationSchema: dealerDTO.ownersWith25
      ? Yup.object({
          beneficialOwner1LastName: Yup.string()
            .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
            .required('Please enter Last Name.'),
          beneficialOwner1FirstName: Yup.string()
            .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
            .required('Please enter First Name.'),
          beneficialOwner1Phone: Yup.string()
            .test('required', 'Please enter Phone.', val => val !== '+' && val)
            .test(
              'len',
              'Invalid Phone number.',
              val => val && val.match(/\d/g).join('').length === 10,
            ),
          beneficialOwner1Email: Yup.string()
            .email('Please enter a valid Email.')
            .required('Please enter an Email.')
            .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),
          beneficialOwner1Street: Yup.string().required('Please enter a Street.'),
          beneficialOwner1City: Yup.string().required('Please enter a City.'),
          beneficialOwner1ZIPCode: Yup.string()
            .matches(/^\d{5}?$/, {
              message: 'Invalid ZIP Code.',
              excludeEmptyString: true,
            })
            .required('Please enter a Property ZIP Code.'),
          beneficialOwner1State: Yup.string().required('Please select a State.'),
          beneficialOwner1SSN: Yup.string()
            .required('Please enter the full SSN.')
            .test('len', 'Must have 9 digits', val => val && val.toString().length === 9)
            .typeError('Only numbers allowed.'),
          beneficialOwner1Ownership: Yup.number()
            .moreThan(-1, 'Please enter a positive number.')
            .integer('Please enter an integer number.')
            .max(100, 'Must be less than or equal to 100.')
            .required('Please enter Ownership %.'),
        })
      : Yup.object({
          beneficialOwner1LastName: Yup.string()
            .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
            .required('Please enter Last Name.'),
          beneficialOwner1FirstName: Yup.string()
            .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
            .required('Please enter First Name.'),
          beneficialOwner1Phone: Yup.string()
            .test('required', 'Please enter Phone.', val => val !== '+' && val)
            .test(
              'len',
              'Invalid Phone number.',
              val => val && val.match(/\d/g).join('').length === 10,
            ),
          beneficialOwner1Email: Yup.string()
            .email('Please enter a valid Email.')
            .required('Please enter an Email.')
            .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),
          beneficialOwner1Street: Yup.string().required('Please enter a Street.'),
          beneficialOwner1City: Yup.string().required('Please enter a City.'),
          beneficialOwner1ZIPCode: Yup.string()
            .matches(/^\d{5}?$/, {
              message: 'Invalid ZIP Code.',
              excludeEmptyString: true,
            })
            .required('Please enter a Property ZIP Code.'),
          beneficialOwner1State: Yup.string().required('Please select a State.'),
          beneficialOwner1SSN: Yup.string()
            .required('Please enter the full SSN.')
            .test('len', 'Must have 9 digits', val => val && val.toString().length === 9)
            .typeError('Only numbers allowed.'),
          beneficialOwner1Ownership: Yup.number()
            .moreThan(-1, 'Please enter a positive number.')
            .integer('Please enter an integer number.')
            .max(100, 'Must be less than or equal to 100.')
            .min(25, 'Must be more than or equal to 25.')
            .required('Please enter Ownership %.'),

          beneficialOwner2LastName: Yup.string()
            .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
            .when('beneficialOwner2Ownership', {
              is: value => value,
              then: Yup.string().required('Please enter Last Name.'),
              otherwise: Yup.string().notRequired(),
            }),
          beneficialOwner2FirstName: Yup.string()
            .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
            .when('beneficialOwner2Ownership', {
              is: value => value,
              then: Yup.string().required('Please enter First Name.'),
              otherwise: Yup.string().notRequired(),
            }),
          beneficialOwner2Phone: Yup.string().when('beneficialOwner2Ownership', {
            is: value => value,
            then: Yup.string()
              .test('required', 'Please enter Phone.', val => val !== '+' && val)
              .test(
                'len',
                'Invalid Phone number.',
                val => val && val.match(/\d/g).join('').length === 10,
              ),
            otherwise: Yup.string().notRequired(),
          }),
          beneficialOwner2Email: Yup.string().when('beneficialOwner2Ownership', {
            is: value => value,
            then: Yup.string()
              .email('Please enter a valid Email.')
              .required('Please enter an Email.')
              .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),
            otherwise: Yup.string().notRequired(),
          }),
          beneficialOwner2Street: Yup.string().when('beneficialOwner2Ownership', {
            is: value => value,
            then: Yup.string().required('Please enter a Street.'),
            otherwise: Yup.string().notRequired(),
          }),
          beneficialOwner2City: Yup.string().when('beneficialOwner2Ownership', {
            is: value => value,
            then: Yup.string().required('Please enter a City.'),
            otherwise: Yup.string().notRequired(),
          }),
          beneficialOwner2ZIPCode: Yup.string().when('beneficialOwner2Ownership', {
            is: value => value,
            then: Yup.string()
              .matches(/^\d{5}?$/, {
                message: 'Invalid ZIP Code.',
                excludeEmptyString: true,
              })
              .required('Please enter a Property ZIP Code.'),
            otherwise: Yup.string().notRequired(),
          }),
          beneficialOwner2State: Yup.string().when('beneficialOwner2Ownership', {
            is: value => value,
            then: Yup.string().required('Please select a State.'),
            otherwise: Yup.string().notRequired(),
          }),
          beneficialOwner2SSN: Yup.string().when('beneficialOwner2Ownership', {
            is: value => value,
            then: Yup.string()
              .required('Please enter the full SSN.')
              .test('len', 'Must have 9 digits', val => val && val.toString().length === 9)
              .typeError('Only numbers allowed.'),
            otherwise: Yup.string().notRequired(),
          }),
          beneficialOwner2Ownership: Yup.number()
            .moreThan(-1, 'Please enter a positive number.')
            .integer('Please enter an integer number.')
            .min(25, 'Must be more than or equal to 25.')
            .max(100, 'Must be less than or equal to 100.'),

          beneficialOwner3LastName: Yup.string()
            .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
            .when('beneficialOwner3Ownership', {
              is: value => value,
              then: Yup.string().required('Please enter Last Name.'),
              otherwise: Yup.string().notRequired(),
            }),
          beneficialOwner3FirstName: Yup.string()
            .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
            .when('beneficialOwner3Ownership', {
              is: value => value,
              then: Yup.string().required('Please enter First Name.'),
              otherwise: Yup.string().notRequired(),
            }),
          beneficialOwner3Phone: Yup.string().when('beneficialOwner3Ownership', {
            is: value => value,
            then: Yup.string()
              .test('required', 'Please enter Phone.', val => val !== '+' && val)
              .test(
                'len',
                'Invalid Phone number.',
                val => val && val.match(/\d/g).join('').length === 10,
              ),
            otherwise: Yup.string().notRequired(),
          }),
          beneficialOwner3Email: Yup.string().when('beneficialOwner3Ownership', {
            is: value => value,
            then: Yup.string()
              .email('Please enter a valid Email.')
              .required('Please enter an Email.')
              .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),
            otherwise: Yup.string().notRequired(),
          }),
          beneficialOwner3Street: Yup.string().when('beneficialOwner3Ownership', {
            is: value => value,
            then: Yup.string().required('Please enter a Street.'),
            otherwise: Yup.string().notRequired(),
          }),
          beneficialOwner3City: Yup.string().when('beneficialOwner3Ownership', {
            is: value => value,
            then: Yup.string().required('Please enter a City.'),
            otherwise: Yup.string().notRequired(),
          }),
          beneficialOwner3ZIPCode: Yup.string().when('beneficialOwner3Ownership', {
            is: value => value,
            then: Yup.string()
              .matches(/^\d{5}?$/, {
                message: 'Invalid ZIP Code.',
                excludeEmptyString: true,
              })
              .required('Please enter a Property ZIP Code.'),
            otherwise: Yup.string().notRequired(),
          }),
          beneficialOwner3State: Yup.string().when('beneficialOwner3Ownership', {
            is: value => value,
            then: Yup.string().required('Please select a State.'),
            otherwise: Yup.string().notRequired(),
          }),
          beneficialOwner3SSN: Yup.string().when('beneficialOwner3Ownership', {
            is: value => value,
            then: Yup.string()
              .required('Please enter the full SSN.')
              .test('len', 'Must have 9 digits', val => val && val.toString().length === 9)
              .typeError('Only numbers allowed.'),
            otherwise: Yup.string().notRequired(),
          }),
          beneficialOwner3Ownership: Yup.number()
            .moreThan(-1, 'Please enter a positive number.')
            .integer('Please enter an integer number.')
            .min(25, 'Must be more than or equal to 25.')
            .max(100, 'Must be less than or equal to 100.'),

          beneficialOwner4LastName: Yup.string()
            .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
            .when('beneficialOwner4Ownership', {
              is: value => value,
              then: Yup.string().required('Please enter Last Name.'),
              otherwise: Yup.string().notRequired(),
            }),
          beneficialOwner4FirstName: Yup.string()
            .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
            .when('beneficialOwner4Ownership', {
              is: value => value,
              then: Yup.string().required('Please enter First Name.'),
              otherwise: Yup.string().notRequired(),
            }),
          beneficialOwner4Phone: Yup.string().when('beneficialOwner4Ownership', {
            is: value => value,
            then: Yup.string()
              .test('required', 'Please enter Phone.', val => val !== '+' && val)
              .test(
                'len',
                'Invalid Phone number.',
                val => val && val.match(/\d/g).join('').length === 10,
              ),
            otherwise: Yup.string().notRequired(),
          }),
          beneficialOwner4Email: Yup.string().when('beneficialOwner4Ownership', {
            is: value => value,
            then: Yup.string()
              .email('Please enter a valid Email.')
              .required('Please enter an Email.')
              .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),
            otherwise: Yup.string().notRequired(),
          }),
          beneficialOwner4Street: Yup.string().when('beneficialOwner4Ownership', {
            is: value => value,
            then: Yup.string().required('Please enter a Street.'),
            otherwise: Yup.string().notRequired(),
          }),
          beneficialOwner4City: Yup.string().when('beneficialOwner4Ownership', {
            is: value => value,
            then: Yup.string().required('Please enter a City.'),
            otherwise: Yup.string().notRequired(),
          }),
          beneficialOwner4ZIPCode: Yup.string().when('beneficialOwner4Ownership', {
            is: value => value,
            then: Yup.string()
              .matches(/^\d{5}?$/, {
                message: 'Invalid ZIP Code.',
                excludeEmptyString: true,
              })
              .required('Please enter a Property ZIP Code.'),
            otherwise: Yup.string().notRequired(),
          }),
          beneficialOwner4State: Yup.string().when('beneficialOwner4Ownership', {
            is: value => value,
            then: Yup.string().required('Please select a State.'),
            otherwise: Yup.string().notRequired(),
          }),
          beneficialOwner4SSN: Yup.string().when('beneficialOwner4Ownership', {
            is: value => value,
            then: Yup.string()
              .required('Please enter the full SSN.')
              .test('len', 'Must have 9 digits', val => val && val.toString().length === 9)
              .typeError('Only numbers allowed.'),
            otherwise: Yup.string().notRequired(),
          }),
          beneficialOwner4Ownership: Yup.number()
            .moreThan(-1, 'Please enter a positive number.')
            .integer('Please enter an integer number.')
            .min(25, 'Must be more than or equal to 25.')
            .max(100, 'Must be less than or equal to 100.'),
        }),

    handleSubmit: values => {
      const dealer = changeDealerInfo(values);

      let totalOwnership = 0;
      setAlertMessage(null);
      if (values.beneficialOwner1Ownership) {
        totalOwnership += 1 * values.beneficialOwner1Ownership;
      }
      if (values.beneficialOwner2Ownership) {
        totalOwnership += 1 * values.beneficialOwner2Ownership;
      }
      if (values.beneficialOwner3Ownership) {
        totalOwnership += 1 * values.beneficialOwner3Ownership;
      }
      if (values.beneficialOwner4Ownership) {
        totalOwnership += 1 * values.beneficialOwner4Ownership;
      }
      if (totalOwnership > 100) {
        setAlertMessage('The sum of ownerships % should be lower or equals to 100%.');
        window.scrollTo(0, 0);
      } else {
        dispatchCreateDealershipApplication(dealer, 3);
      }
    },
  })(BeneficialOwnerInformationForm);

  return (
    <>
      <h2>Beneficial Owner Information</h2>
      <Row>
        <Col sm="8" className="p-0">
          <BeneficialOwnerInformationFormFormik />
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = state => ({
  dealerDTO: state.auth.dealerDTO,
  user: state.auth.user,
  dealerDTOCreateDealershipApplication: state.auth.dealerDTOCreateDealershipApplication,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) => dispatch(changeAuthValue(key, value)),
  dispatchEndLoading: () => dispatch(endLoading()),
  dispatchCreateDealershipApplication: (dealer, page) =>
    dispatch(createDealershipApplication(dealer, page)),
});

BeneficialOwnerInformation.propTypes = {
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(BeneficialOwnerInformation),
);
