import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { changePage, submitMindfire, changeMicrofInfoValue } from '../../redux/actions/index';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import BackBtn from '../../components/UI/BackButton/BackButton';
import Button from '../../components/UI/Button/CustomButton';
import { reactGAPageview } from '../../helper';
import { MicrofFlowPageNumber } from './MicrofFlow';

const Homeowner = props => {
  const {
    email,
    dispatchChangePage,
    dispatchChangeMicrofInfoValue,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    isHomeowner,
    homeownerInsurance,
  } = props;

  const [isRequired, setIsRequired] = useState(false);

  useEffect(() => {
    reactGAPageview({
      path: '/ca_homeowner',
      trackerNames: [],
      title: 'ca_homeowner',
      did,
      dealerUserId,
    });

    // eslint-disable-next-line
  }, []);
  return (
    <Container>
      <Row>
        <Col>
          <Row className="mb-5">
            <BackBtn changePage={dispatchChangePage} page={MicrofFlowPageNumber.AppSSN} />
            <Col sm="5">
              <h4>Are you the homeowner?</h4>
              <p>For Manufactured and Mobile Homes, the applicant must own the land.</p>
            </Col>
            <Col sm="6">
              <Row>
                <Col sm="6">
                  <Button
                    title="Yes"
                    className={
                      'mt-3 ' + (isHomeowner === null ? 'inactive' : isHomeowner ? '' : 'inactive')
                    }
                    clickHandler={() => {
                      dispatchChangeMicrofInfoValue('isHomeowner', true);
                    }}
                  />
                </Col>
                <Col sm="6">
                  <Button
                    className={
                      'mt-3 ' + (isHomeowner === null ? 'inactive' : !isHomeowner ? '' : 'inactive')
                    }
                    title="No"
                    clickHandler={() => {
                      dispatchChangeMicrofInfoValue('isHomeowner', false);
                    }}
                  />
                </Col>
              </Row>
              <p
                className="text-danger mt-2 position-absolute"
                hidden={!isRequired ? true : isHomeowner}
              >
                You must be the homeowner to apply for a lease.
              </p>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col />
            <Col sm="5">
              <h4>Do you have homeowner’s insurance?</h4>
            </Col>
            <Col sm="6">
              <Row>
                <Col sm="6">
                  <Button
                    className={
                      'mt-3 ' +
                      (homeownerInsurance === null
                        ? 'inactive'
                        : homeownerInsurance
                        ? ''
                        : 'inactive')
                    }
                    type="button"
                    title="Yes"
                    clickHandler={() => {
                      dispatchChangeMicrofInfoValue('homeownerInsurance', true);
                    }}
                  />
                </Col>
                <Col sm="6">
                  <Button
                    className={
                      'mt-3 ' +
                      (homeownerInsurance === null
                        ? 'inactive'
                        : !homeownerInsurance
                        ? ''
                        : 'inactive')
                    }
                    type="button"
                    title="No"
                    clickHandler={() => {
                      dispatchChangeMicrofInfoValue('homeownerInsurance', false);
                    }}
                  />
                </Col>
              </Row>
              <p
                className="text-danger mt-2 position-absolute"
                hidden={!isRequired ? true : homeownerInsurance !== null}
              >
                Please select an option.
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm="6" />
            <Col sm="6">
              <Button
                type="button"
                title="Continue"
                clickHandler={() => {
                  if (isHomeowner && homeownerInsurance !== null) {
                    if (isRequired) setIsRequired(false);
                    dispatchChangePage(MicrofFlowPageNumber.UserAddress);
                    dispatchSubmitMindfire({
                      email: email,
                      lastPage: '4070',
                      dealerUserId: dealerUserId,
                      did: did,
                    });
                  } else {
                    if (!isRequired) setIsRequired(true);
                  }
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  isHomeowner: state.microfInformation.isHomeowner,
  homeownerInsurance: state.microfInformation.homeownerInsurance,
  email: state.preQualificationInformation.email,
  isReview: state.aquaInformation.isReview,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeMicrofInfoValue: (key, value) => dispatch(changeMicrofInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

Homeowner.propTypes = {
  dispatchChangePage: PropTypes.func,
  dispatchChangeAquaInfoValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Homeowner);
