import axios from 'axios';
import { changeSoftPullValue, resetState } from '.';
import { AtWaterGreenSkyFlowPageNumber } from '../../pages/Pools/Greensky/Flow/GreenSkyFlow';
import {
  CHANGE_ATWATER_APPLICATION_INFO_VALUE,
  SUBMIT_APPLICATION_SOFT_PULL_ERROR,
  SUBMIT_APPLICATION_SOFT_PULL_SUCCESS,
} from './actionTypes';
import { baseURL, handleApiError } from './api';
import { changeGreenSkyApplicationInfoValue } from './greenskyApplicationActions';
import { changePage } from './naviActions';
import { addNotification, endLoading, startLoading } from './notificationActions';
import { checkErrors } from './softPullActions';
import { changeAuthValue, updateDealerValue } from './authActions';

const qs = require('qs');

export const changeAtWaterApplicationInfoValue = (key, value) => ({
  type: CHANGE_ATWATER_APPLICATION_INFO_VALUE,
  key,
  value,
});

axios.defaults.withCredentials = true;

export const getAtWaterProgramsAndProducts = dealerId => async dispatch => {
  dispatch(startLoading('Give us a second while we fetch products and programs for you.'));

  const url = `${baseURL}/api/pools/programs_and_products`;

  await axios({ method: 'get', url: url, params: { dealerId } })
    .then(async response => {
      if (response.data) {
        if (response.data.message) {
          dispatch(changeAtWaterApplicationInfoValue('programsAndProducts', []));
          dispatch(endLoading());
          dispatch(
            addNotification({
              message: handleApiError(response.data.message),
              messageType: 'Error',
            }),
          );
        } else {
          dispatch(changeAtWaterApplicationInfoValue('programsAndProducts', response.data));
          dispatch(endLoading());
        }
      }
    })
    .catch(error => {
      dispatch(changeAtWaterApplicationInfoValue('programsAndProducts', []));
      dispatch(endLoading());
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
};

export const submitAtWaterApplication = (preqId, page, data) => async dispatch => {
  dispatch(changeAtWaterApplicationInfoValue('loanDecisionResponse', null));
  dispatch(
    startLoading('Give us a second while we find the best AtWater financing option for you.'),
  );

  const url = `${baseURL}/api/pools/submit_application?preqId=${encodeURIComponent(preqId)}`;

  await axios
    .post(url, data)
    .then(async response => {
      dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_SUCCESS, payload: response.data });
      if (dispatch(checkErrors(response))) {
        dispatch(changePage(page));
        dispatch(endLoading());
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_ERROR, payload: error });
      dispatch(endLoading());
    });
};

export const loanDecision = (appId, data, page) => async dispatch => {
  dispatch(changeAtWaterApplicationInfoValue('loanDecisionResponse', null));
  dispatch(startLoading('Give us a second while we are taking a decision.'));

  const url = `${baseURL}/api/pools/loan_decision?applicationId=${encodeURIComponent(appId)}`;

  await axios
    .post(url, data)
    .then(async response => {
      if (response.data) {
        dispatch(changeAtWaterApplicationInfoValue('loanDecisionResponse', response.data));
        if (data.acceptLoan) {
          dispatch(changePage(page));
        }
        dispatch(endLoading());
      }
    })
    .catch(error => {
      dispatch(changeAtWaterApplicationInfoValue('programsAndProducts', []));
      dispatch(endLoading());
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
};

export const submitAtWaterGreenSkyApplication = (params, page, app) => async dispatch => {
  dispatch(
    startLoading('Give us a second while we find the best GreenSky financing option for you.'),
  );

  const url = app
    ? `${baseURL}/api/pools/greensky/resubmit`
    : `${baseURL}/api/pools/greensky/submit_application`;
  const data = qs.stringify(
    app
      ? {
          selectedFinancier: `${encodeURIComponent(params.selectedFinancier)}`,
          appId: `${encodeURIComponent(app.applicationId)}`,
          data: '' + JSON.stringify(params),
        }
      : { data: '' + JSON.stringify(params) },
  );

  await axios
    .post(url, data)
    .then(async response => {
      dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_SUCCESS, payload: response.data });
      if (dispatch(checkErrors(response))) {
        dispatch(changePage(page));
        dispatch(endLoading());
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_ERROR, payload: error });
      dispatch(endLoading());
    });
};

export const getAtWaterGreenSkyApplication = applicationId => async dispatch => {
  dispatch(changeSoftPullValue('greenSkyApplication', null));
  dispatch(startLoading('Get GreenSky application ...'));
  await axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: { applicationId: parseInt(applicationId) },
    url: `${baseURL}/api/pools/greensky/application_search`,
  })
    .then(response => {
      if (response.data) {
        if (response.data.message) {
          dispatch(
            addNotification({
              message: handleApiError(response.data.message),
              messageType: 'Error',
            }),
          );
        }
        dispatch(
          changeSoftPullValue('greenSkyApplication', response.data.applicationSearchResults[0]),
        );
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const getAtWaterGreenSkyOffersData = applicationId => async dispatch => {
  dispatch(changeSoftPullValue('greenSkyOffersData', null));
  dispatch(startLoading('Get GreenSky offer data ...'));
  await axios
    .get(`${baseURL}/api/pools/greensky/offers?applicationId=${encodeURIComponent(applicationId)}`)
    .then(response => {
      if (response.data) {
        if (response.data.error) {
          dispatch(
            addNotification({
              message: handleApiError(response.data.error.message),
              messageType: 'Error',
            }),
            dispatch(changeSoftPullValue('greenSkyOffersData', response.data)),
          );
          dispatch(changePage(AtWaterGreenSkyFlowPageNumber.ErrorPage));
          dispatch(endLoading());
        } else {
          dispatch(changeSoftPullValue('greenSkyOffersData', response.data));
          if (response.data.poolsGreenskyInformation.questionFailed === true) {
            dispatch(changePage(AtWaterGreenSkyFlowPageNumber.ErrorPage));
            dispatch(endLoading());
          } else {
            if (
              response.data.poolsGreenskyInformation.ctaRequired === false &&
              response.data.poolsGreenskyInformation.esignatureComplete === 'yes'
            ) {
              dispatch(changePage(AtWaterGreenSkyFlowPageNumber.ActivationPage));
            }
            dispatch(endLoading());
            if (response.data.poolsGreenskyInformation.ctaRequired === true) {
              dispatch(
                atWaterGreenSkyCheckCta(
                  response.data.poolsGreenskyInformation.greenskyApplicationId,
                  response.data.poolsGreenskyInformation.ssnLastDigits,
                  response.data.poolsGreenskyInformation.spanish,
                ),
              );
            }
          }
        }
      }
    })
    .catch(error => {
      //dispatch(changeSoftPullValue('greenSkyOffersData', {}));
      dispatch(endLoading());
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  // dispatch(endLoading());
};

export const recalculateAtWaterGreenSkyOffer = (
  applicationId,
  loanAmount,
  planNumber,
  product,
) => async dispatch => {
  dispatch(changeSoftPullValue('greenSkyApplication', null));
  dispatch(startLoading('Recalculate GreenSky offer ...'));
  await axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      applicationId: applicationId,
      loanAmount: parseInt(loanAmount),
      planNumber: planNumber,
      product: product,
    },
    url: `${baseURL}/api/pools/greensky/recalculate_offers`,
  })
    .then(response => {
      if (response.data) {
        if (response.data.error) {
          dispatch(
            addNotification({
              message: handleApiError(response.data.error.message),
              messageType: 'Error',
            }),
            dispatch(changeSoftPullValue('greenSkyOffersData', response.data)),
          );
        } else {
          dispatch(changeSoftPullValue('greenSkyOffersData', response.data));
        }
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });

  dispatch(endLoading());
};

export const atWaterGreenSkyAcceptOffer = (
  offerId,
  applicationId,
  product,
  authorizedLoanAmount,
  last4Ssn,
) => async dispatch => {
  dispatch(startLoading('Accepting GreenSky offer ...'));
  await axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      offerId: offerId,
      applicationId: applicationId,
      product: product,
      authorizedLoanAmount: authorizedLoanAmount,
    },
    url: `${baseURL}/api/pools/greensky/create_account`,
  })
    .then(response => {
      if (response.data) {
        dispatch(atWaterGreenSkyCheckCta2(applicationId, last4Ssn, response.data));
      }
    })
    .catch(error => {
      dispatch(endLoading());
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });

  //dispatch(endLoading());
};

export const getAtWaterGreenSkyLoanAgreement = applicationId => async dispatch => {
  dispatch(changeSoftPullValue('greenSkyLoanAgreement', null));
  dispatch(startLoading('Get GreenSky Loan Agreement ...'));
  await axios
    .get(
      `${baseURL}/api/pools/greensky/loan_agreement?applicationId=${encodeURIComponent(
        applicationId,
      )}`,
    )
    .then(response => {
      if (response.data) {
        dispatch(changeSoftPullValue('greenSkyLoanAgreement', response.data));
      }
    })
    .catch(error => {
      dispatch(changeSoftPullValue('greenSkyLoanAgreement', {}));
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const viewAtWaterGreenSkyLoanAgreement = applicationId => async dispatch => {
  //dispatch(changeSoftPullValue('greenSkyLoanAgreement', null));
  dispatch(startLoading('View GreenSky Loan Agreement ...'));
  await axios({
    method: 'GET',
    url: `${baseURL}/api/pools/greensky/loan_document?applicationId=${encodeURIComponent(
      applicationId,
    )}`,
    responseType: 'blob',
  })
    .then(response => {
      if (response.data) {
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], {
          type: 'application/pdf',
        });
        // //Build a URL from the file
        const fileURL = URL.createObjectURL(file);

        var win = window.open(fileURL);

        if (win.document) {
          win.document.write(
            '<html><head><title>Loan Agreement Document</title></head><body height="100%" width="100%"><iframe src="' +
              fileURL +
              '" height="100%" width="100%"></iframe></body></html>',
          );
        }
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const atWaterGreenSkyCheckCta = (applicationId, last4Ssn, isSpanish) => async dispatch => {
  if (isSpanish) {
    dispatch(changeSoftPullValue('isSpanish', true));
  }

  dispatch(startLoading('Activating Account ...'));
  await axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      applicationId: applicationId,
      instore: false,
      last4SSN: last4Ssn,
    },
    url: `${baseURL}/api/pools/greensky/check_cta`,
  })
    .then(response => {
      if (response.data) {
        dispatch(changeSoftPullValue('ctaResponse', response.data));
        if (response.data.activationVersion === '2.0') {
          dispatch(endLoading());
          dispatch(changePage(AtWaterGreenSkyFlowPageNumber.ActivationPage));
        } else {
          if (response.data.ctarequired === true) {
            dispatch(endLoading());
            dispatch(getAtWaterGreenSkyQuestions(applicationId, last4Ssn, isSpanish));
          }
          if (response.data.ctarequired === false && response.data.eSignatureComplete === 'no') {
            dispatch(endLoading());
            dispatch(atWaterESign(applicationId));
          }
          if (response.data.ctarequired === false && response.data.eSignatureComplete === 'yes') {
            dispatch(endLoading());
            dispatch(changePage(AtWaterGreenSkyFlowPageNumber.ActivationPage));
          }
        }
      }
    })
    .catch(error => {
      dispatch(endLoading());
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
};

export const atWaterGreenSkyCheckCta2 = (applicationId, last4Ssn, accData) => async dispatch => {
  dispatch(changeSoftPullValue('ctaResponse', null));

  dispatch(startLoading('Activating Account ...'));
  await axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      applicationId: applicationId,
      instore: false,
      last4SSN: last4Ssn,
    },
    url: `${baseURL}/api/pools/greensky/check_cta`,
  })
    .then(response => {
      if (response.data) {
        dispatch(changeSoftPullValue('ctaResponse', response.data));
        if (response.data.activationVersion === '2.0') {
          dispatch(changePage(AtWaterGreenSkyFlowPageNumber.ActivationPage));
        } else {
          dispatch(changeSoftPullValue('greenSkyOffersData', accData));
        }
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const atWaterESign = applicationId => async dispatch => {
  dispatch(startLoading('eSign ...'));
  await axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    url: `${baseURL}/api/pools/greensky/esign?applicationId=${encodeURIComponent(applicationId)}`,
  })
    .then(response => {
      if (response.data) {
        dispatch(changeSoftPullValue('applicationSubmitDTO', response.data));
        if (response.data.greenskyInformation.esignatureSuccess === true)
          dispatch(changePage(AtWaterGreenSkyFlowPageNumber.ActivationPage));
      } else {
        dispatch(
          addNotification({
            message: handleApiError(response.data.error),
            messageType: 'Error',
          }),
        );
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const getAtWaterGreenSkyQuestions = (
  applicationId,
  last4Ssn,
  isSpanish,
) => async dispatch => {
  dispatch(startLoading('Activating Account ...'));

  let data = {
    applicationId: applicationId,
    last4SSN: last4Ssn,
  };
  if (isSpanish) {
    data = {
      applicationId: applicationId,
      language: 'es_ES',
      last4SSN: last4Ssn,
    };
  }

  await axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
    url: `${baseURL}/api/pools/greensky/get_questions`,
  })
    .then(response => {
      if (response.data) {
        dispatch(changeSoftPullValue('questionsResponse', response.data));
        dispatch(changePage(AtWaterGreenSkyFlowPageNumber.Questions));
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });

  dispatch(endLoading());
};

export const postAtWaterGreenSkyIdentityVerification = (
  applicationId,
  request,
  last4Ssn,
  isSpanish,
  greenskyApplicationId,
) => async dispatch => {
  dispatch(startLoading());

  return axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: request,
    url: `${baseURL}/api/pools/greensky/send_answers`,
  })
    .then(async response => {
      if (response.data) {
        dispatch(changeGreenSkyApplicationInfoValue('identityVerification', response.data));
        // dispatch(endLoading());
        if (response.data.status !== null) {
          if (response.data.status === 'failComplete' || response.data.status === 'FAIL') {
            dispatch(endLoading());
            dispatch(changePage(AtWaterGreenSkyFlowPageNumber.ErrorPage));
          } else if (response.data.status === 'PASS') {
            dispatch(atWaterGreenSkyCheckCta(greenskyApplicationId, last4Ssn, isSpanish));
          } else if (response.data.status === 'PENDING') {
            dispatch(changeSoftPullValue('questionsResponse', response.data));
            dispatch(endLoading());
          } else {
            dispatch(endLoading());
            dispatch(changePage(AtWaterGreenSkyFlowPageNumber.ActivationPage));
          }
        }
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(endLoading());
    });
};

export const atWaterGreenSkySubmitCharge = (
  applicationData,
  greenSkyApplication,
) => async dispatch => {
  dispatch(changeGreenSkyApplicationInfoValue('isAccountCreated', null));
  dispatch(startLoading('Submitting GreenSky application ...'));
  await axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      applicationId: greenSkyApplication.applicationId,
      amount: applicationData.currentChargeAmount,
      creditCardUID: greenSkyApplication.creditCardUID,
      totalJobAmount: applicationData.authorizedLoanAmount,
      chargeType: applicationData.chargeType,
      invoiceNumber: applicationData.invoiceNumber ? applicationData.invoiceNumber : '',
      txnCode: '101',
    },
    url: `${baseURL}/api/pools/greensky/transaction_submit`,
  })
    .then(response => {
      if (response.data) {
        dispatch(changeSoftPullValue('greenSkyApplicationSubmit', response.data));
        if (response.data.status === 'Success') {
          dispatch(changeGreenSkyApplicationInfoValue('isAccountCreated', true));
        }
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(changeGreenSkyApplicationInfoValue('isAccountCreated', false));
    });
  dispatch(endLoading());
};

export const atWaterGreenSkysubmitRefund = (
  appId,
  amount,
  parentTransactionId,
  creditCardUID,
) => async dispatch => {
  dispatch(changeSoftPullValue('submitRefundData', null));
  dispatch(startLoading('Submitting GreenSky application refund ...'));
  await axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      applicationId: appId,
      amount: amount,
      parentTransactionId: parentTransactionId,
      creditCardUID: creditCardUID,
      txnCode: '201',
    },
    url: `${baseURL}/api/pools/greensky/transaction_submit`,
  })
    .then(response => {
      if (response.data) {
        dispatch(changeSoftPullValue('submitRefundData', response.data));
        dispatch(changeSoftPullValue('refundAmount', amount));
        dispatch(endLoading());
      }
    })
    .catch(error => {
      dispatch(endLoading());
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const atWaterGreenskyCancelTransaction = (
  txnNumber,
  creditCardUID,
  applicationId,
) => async dispatch => {
  dispatch(startLoading('Canceling GreenSky application ...'));
  await axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      txnNumber: txnNumber,
      creditCardUID: creditCardUID,
      applicationId: applicationId,
    },
    url: `${baseURL}/api/pools/greensky/transaction_cancel`,
  })
    .then(response => {
      if (response.data.errors) {
        dispatch(
          addNotification({
            message: handleApiError(response.data.errors),
            messageType: 'Error',
          }),
        );
      } else if (response.data.status === 'Success') {
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const getAtWaterGreenskySingleTransaction = (
  transactionId,
  reloadPage,
) => async dispatch => {
  const searchOption = { pageLimit: 20, pageOffset: 0 };
  dispatch(changeSoftPullValue('singleTransaction', null));
  dispatch(startLoading('Get Single Transaction with Id ' + transactionId + ' ...'));
  await axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: { transactionId: transactionId },
    url: `${baseURL}/api/pools/greensky/transaction_search`,
  })
    .then(response => {
      if (response.data) {
        dispatch(changeSoftPullValue('singleTransaction', response.data));
        dispatch(endLoading());
        if (reloadPage) {
          dispatch(getAtWaterGreenskyAllTransactions(searchOption));
        }
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const getAtWaterGreenskyAllTransactions = searchOption => async dispatch => {
  dispatch(startLoading('Get All Transactions ...'));
  await axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: { pageLimit: searchOption.pageLimit, pageOffset: searchOption.pageOffset },
    url: `${baseURL}/api/pools/greensky/transactions`,
  })
    .then(response => {
      if (response.data) {
        dispatch(changeSoftPullValue('allTransactions', response.data));
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const sendEmailConsumerUrl = (email, dealerId) => async dispatch => {
  dispatch(startLoading('SendinG Consumer URL ...'));
  await axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    url: `${baseURL}/api/pools/send_customlink?email=${encodeURIComponent(
      email,
    )}&dealerId=${encodeURIComponent(dealerId)}`,
  })
    .then(response => {
      dispatch(changeSoftPullValue('emailCustomerUrlResponse', response.data));
      dispatch(
        addNotification({
          message: response.data,
          messageType: 'Success',
        }),
      );
    })
    .catch(error => {
      dispatch(changeSoftPullValue('emailCustomerUrlResponse', null));
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const applyToBeDealerGS = (isGreensky, page, history, data) => async (
  dispatch,
  getState,
) => {
  dispatch(startLoading('Apply to be Dealer ...'));

  await axios
    .post(`${baseURL}/api/pools/create_dealer`, data)
    .then(async response => {
      if (isGreensky) {
        dispatch(changePage(page));
      } else {
        dispatch(updateDealerValue(response.data));
        history.push('/portal/candidate-contractor-dashboard');
      }
      dispatch(endLoading());
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(endLoading());
    });
};

export const updateMerchantNumber = (merchantNumber, dealerId, history) => async (
  dispatch,
  getState,
) => {
  dispatch(startLoading('Updating Merchant Number ...'));

  await axios
    .post(
      `${baseURL}/api/pools/update_merchant_number?merchantNumber=${encodeURIComponent(
        merchantNumber,
      )}&dealerId=${encodeURIComponent(dealerId)}`,
    )
    .then(async response => {
      if (response.data && response.data.success) {
        dispatch(changeAuthValue('isDealerIdCustomLink', false));
        dispatch(changeAuthValue('customDealerId', null));
        if (
          history &&
          history.location &&
          history.location.pathname === '/portal/update-merchant-number/update-dealer'
        ) {
          dispatch(changeSoftPullValue('dealers', null));
          history.push('/portal/update-merchant-number');
        } else {
          history.push('/portal/dashboard');
        }
      }
      dispatch(endLoading());
    })
    .catch(error => {
      dispatch(resetState({ history: null }));
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      history.push('/login');
      dispatch(endLoading());
    });
};
