import React from 'react';
import AquaStartPage from './AquaStartPage';
import HvacDetails from './HvacDetails';
import HasCoApplicant from './HasCoApplicant';
import AppIdentificationInfo from './AppIdentificationInfo';
import AppEmploymentDetails from './AppEmploymentDetails';
import AppOtherIncome from './AppOtherIncome';
import CoAppDetails from './CoApplicant/CoAppDetails';
import CoAppEmploymentDetails from './CoApplicant/CoAppEmploymentDetails';
import CoAppOtherIncome from './CoApplicant/CoAppOtherIncome';
import CoAppAddress from './CoApplicant/CoAppAddress';
import CoAppAdditionalInfo from './CoApplicant/CoAppAdditionalInfo';
import ApplicationReview from './ApplicationReview';
import AquaApplicationResult from './AquaApplicationResult';
import AquaAcceptOffer from './AquaAcceptOffer';

const AquaFlow = [
  <AquaStartPage title="Financing Option Application">
    <p>
      <b>Aqua Finance</b> needs additional information about your home, identity, and income (or
      benefits) to determine the best financing option for you.
    </p>
  </AquaStartPage>,
  <HvacDetails title="Financing Option Information">
    <h4>
      Please provide information about the home where the service will be provided and the estimated
      installation date.
    </h4>
  </HvacDetails>,
  <HasCoApplicant title="Financing Option Information">
    <h4>Do you have a Co-Applicant?</h4>
    <p>
      Complete your profile and, if applicable, have the Co-Applicant complete his or her profile.
    </p>
    <div>
      <b>IMPORTANT!</b>
    </div>
    <ul>
      <li>The co-applicant must be the spouse</li>
      <li>If no spouse, the co-applicant must also be the homeowner OR</li>
      <li>The co-applicant must be living in the home</li>
    </ul>
  </HasCoApplicant>,
  <AppIdentificationInfo title="Applicant Information">
    <h4>Please provide your identification information.</h4>
    <p>
      <b>Aqua Finance</b> needs additional information. You may have provided the last 4 digits of
      your Social Security Number together with your date of birth earlier in this application,
      which was used solely for a “soft pull” of your credit report to try to prequalify you for
      financing. This should not have had any impact on your credit score. Providing your full
      Social Security Number here will help further verify your identity.
    </p>
  </AppIdentificationInfo>,
  <AppEmploymentDetails title="Applicant Information">
    <h4>Please provide your income (or benefits) information.</h4>
    <p>
      Alimony, child support, or separate maintenance income need not be revealed if you do not wish
      to have it considered as a basis for repayment. You may include income from any source. If you
      are relying on the income or assets of a spouse, domestic partner, or another individual,
      please complete a joint applicant.
    </p>
  </AppEmploymentDetails>,
  <AppOtherIncome title="Applicant Information">
    <h4>Please provide any additional income (or benefits) you receive.</h4>
    <p>
      Alimony, child support, or separate maintenance income need not be revealed if you do not wish
      to have it considered as a basis for repayment. You may include income from any source. If you
      are relying on the income or assets of a spouse, domestic partner, or another individual,
      please complete a joint applicant.
    </p>
  </AppOtherIncome>,
  <CoAppDetails title="Co-Applicant Information">
    <h4>Co-Applicant, enter your name and contact information.</h4>
  </CoAppDetails>,
  <CoAppEmploymentDetails title="Co-Applicant Information">
    <h4>Co-Applicant, enter your income (or benefits) information.</h4>
    <p>
      Alimony, child support, or separate maintenance income need not be revealed if you do not wish
      to have it considered as a basis for repayment. You may include income from any source. If you
      are relying on the income or assets of a spouse, domestic partner, or another individual,
      please complete a joint applicant.
    </p>
  </CoAppEmploymentDetails>,
  <CoAppOtherIncome title="Co-Applicant Information">
    <h4>Co-Applicant, enter any additional income (or benefits) you receive.</h4>
    <p>
      Alimony, child support, or separate maintenance income need not be revealed if you do not wish
      to have it considered as a basis for repayment. You may include income from any source. If you
      are relying on the income or assets of a spouse, domestic partner, or another individual,
      please complete a joint applicant.
    </p>
  </CoAppOtherIncome>,
  <CoAppAddress title="Co-Applicant Information">
    <h4>Co-Applicant, enter your address.</h4>
  </CoAppAddress>,
  <CoAppAdditionalInfo title="Co-Applicant Information">
    <h4>Co-Applicant, enter your date of birth and identification information.</h4>
  </CoAppAdditionalInfo>,
  <ApplicationReview title="Applicant Information">
    <h4>Let’s double check your information and submit your application.</h4>
  </ApplicationReview>,
  <AquaApplicationResult title="Financing Options">
    <h4>Application Results</h4>
  </AquaApplicationResult>,
  <AquaAcceptOffer title="Financing Options">
    <h4>Confirm your selection and accept the offer!</h4>
  </AquaAcceptOffer>,
];

export default AquaFlow;

export const AquaFlowPageNumber = {
  AquaStartPage: 0,
  HvacDetails: 1,
  HasCoApplicant: 2,
  AppIdentificationInfo: 3,
  AppEmploymentDetails: 4,
  AppOtherIncome: 5,
  CoAppDetails: 6,
  CoAppEmploymentDetails: 7,
  CoAppOtherIncome: 8,
  CoAppAddress: 9,
  CoAppAdditionalInfo: 10,
  ApplicationReview: 11,
  AquaApplicationResult: 12,
  AquaAcceptOffer: 13,
};
