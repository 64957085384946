import React from 'react';
import { Container, Row, Col } from 'reactstrap/';
import ftlFinance from '../../../assets/img/FTLFinance.png';
import CustomButton from '../../../components/UI/Button/CustomButton';
import ApproveMore from './ApproveMore';
import {
  isFTLApprovedMore,
  isFTLApprovedWithInfo,
  isFTLApprovedWithLoanMore,
} from '../../../assets/js/ApplicationStatusEnum';
import { getFTLContinueApplicationUrl } from '../../../config/settings';
import { useVSPixelScript } from '../../../assets/hooks';
import ApprovedWithInfo from './ApprovedWithInfo';
import ApprovedWithLoanMore from './ApprovedWithLoanMore';

const Approved = ({ pqi, ftlInformation: { borrowedAmount }, status }) => {
  const isApprovedWithInfo = isFTLApprovedWithInfo(status);
  const applicantName =
    (pqi?.firstName ? pqi.firstName + ' ' : '') +
    (pqi?.middleName ? pqi.middleName + ' ' : '') +
    (pqi?.lastName || '');

  useVSPixelScript();

  const getApproveComponent = () => {
    let component = (
      <ApprovedWithInfo
        isApprovedWithInfo={isApprovedWithInfo}
        applicantName={applicantName}
        borrowedAmount={borrowedAmount}
      />
    );

    if (isFTLApprovedMore(status)) {
      component = (
        <ApproveMore
          applicantName={applicantName}
          borrowedAmount={borrowedAmount}
          isApprovedWithInfo={isApprovedWithInfo}
        />
      );
    } else if (isFTLApprovedWithLoanMore(status)) {
      component = (
        <ApprovedWithLoanMore applicantName={applicantName} borrowedAmount={borrowedAmount} />
      );
    }
    return component;
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs="auto" sm="11">
          {getApproveComponent()}
          <Row className="justify-content-center mb-3">
            <Col xs="auto" sm="11">
              {isFTLApprovedMore(status) ? (
                <h5 className="text-center">
                  In order to complete the loan approval, we need additional information. The
                  homeowner has received an email request to add the required documents to their
                  online application. If you need to submit these on behalf of the homeowner, please
                  use the link below.
                </h5>
              ) : (
                <h5 className="text-center">
                  Please login to AppTracker to create the loan documents. Once the work is
                  complete, the loan documents will be electronically signed through DocuSign and we
                  will notify you when the project has been funded.
                </h5>
              )}
            </Col>
          </Row>
          <Row className="justify-content-center mb-5">
            <Col xs="auto">
              <img alt="FTL Finance" src={ftlFinance} width="250px" />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs="12" sm="auto" lg="6">
              <CustomButton
                color="primary"
                clickHandler={() => (window.location.href = getFTLContinueApplicationUrl())}
                title={`Continue to FTL Finance`}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Approved;
