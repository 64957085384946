import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getIsRheem } from '../../config/settings';
import { reactGAPageview } from '../../helper';
import { changePage, changePreQualInfoValue, submitMindfire } from '../../redux/actions/index';
import LoanForMicrof from './LoanForMicrof';
import LoanForRheem from './LoanForRheem';

const isRheem = getIsRheem();

const LoanFor = props => {
  const { dealerUserId, did } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_prequaloption',
      trackerNames: [],
      title: 'ca_prequaloption',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  return isRheem ? (
    <LoanForRheem {...props}>
      <h4>Select desired product or service.</h4>
    </LoanForRheem>
  ) : (
    <LoanForMicrof {...props}>
      <h4>Why would you like financing?</h4>
    </LoanForMicrof>
  );
};

const mapStateToProps = state => ({
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  aquaFinanceAssigned: state.auth.aquaFinanceAssigned,
  app: state.softPull.applicationSubmitDTO,
  isHomeowner: state.preQualificationInformation.isHomeowner,
  productType: state.preQualificationInformation.productType,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) => dispatch(changePreQualInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

LoanFor.propTypes = {
  dealerUserId: PropTypes.number,
  did: PropTypes.string,
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoanFor);
