import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import { changeGreenSkyApplicationInfoValue } from '../../../../redux/actions';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import CustomInput from '../../../../components/UI/Form/Input/CustomInput';
import SsnFormat from '../../../../components/UI/Form/NumberFormat/SsnFormat';
import {
  changeApplicationValues,
  isNoreplyEmail,
  reactGAEvent,
  reactGAPageview,
} from '../../../../helper';
import { changePage, setFlow, submitMindfire } from '../../../../redux/actions/index';
import { AtWaterGreenSkyFlowPageNumber } from './GreenSkyFlow';
import CustomPhoneNumber from '../../../../components/UI/Form/PhoneNumber/PhoneNumber';
import Checkbox from '../../../../components/UI/Form/Checkbox/Checkbox';
import {
  APPFULLSSN_TOOLTIP_TEXT,
  EMAIL_TOOLTIP_TEXT,
  MOBILE_NUMBER_CONSENT_TOOLTIP_TEXT,
  PHONENUMBER_TOOLTIP_TEXT,
  SPANISH_CONSENT_TOOLTIP_TEXT,
} from '../../../../constants/tooltipTexts';
import Disclosure from '../Components/Disclosure';

const AdditionalApplicantInformation = props => {
  const {
    preQualificationInformation: { email, phoneNumber },
    dispatchChangePage,
    dispatchChangeGreenSkyApplicationInfoValue,
    greenSkyApplicationInformation: {
      appEmail,
      appSpanishConsent,
      appMobileNumber,
      appMobileNumberConsent,
      appSsn,
      isReview,
    },
    ssnLastDigits,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_gsadditionalapplicantinformation',
      trackerNames: [],
      title: 'ca_gsadditionalapplicantinformation',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const AdditionalApplicantInformationForm = () => (
    <Form noValidate autoComplete="off">
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={AtWaterGreenSkyFlowPageNumber.AppPrimaryAddress}
        />
        <Col sm="5">
          <h4>Additional Applicant Information.</h4>
          <Field
            component={Checkbox}
            name="appSpanishConsent"
            id="appSpanishConsent"
            tooltipText={SPANISH_CONSENT_TOOLTIP_TEXT}
            label={`Marque la casilla si llevó a cabo alguna parte de la discusión sobre su solicitud o préstamo GreenSky® en español, o si desea una copia del contrato de su crédito en español.`}
            labelClassName="font-weight-light mb-0"
          />
          <br />
          <p className="fs-16">
            Check the box if you conducted any part of the discussion about your GreenSky
            application or loan in Spanish, or would otherwise like a copy of your loan agreement in
            Spanish.
          </p>
          <Field
            component={Checkbox}
            name="appMobileNumberConsent"
            id="appMobileNumberConsent"
            tooltipText={MOBILE_NUMBER_CONSENT_TOOLTIP_TEXT}
            label={`You understand that if you have provided your mobile telephone number in any application field you authorize any financial institution that makes a GreenSky loan to you and its agents, contractors, successors, assigns, or service providers to contact you at this mobile number and any future number that you provide for your cellular telephone or other wireless device by phone or text message using automated telephone dialing system or artificial or prerecorded voice messages, for any legal purpose, including, but not limited to, servicing a loan. You may revoke your consent at any time by contacting us at 1-866-936-0602 or P.O. Box 29429, Atlanta, GA 30359.`}
            labelClassName="font-weight-light mb-0"
          />
        </Col>
        <Col sm="6">
          <Field
            component={CustomInput}
            label={'Email Address'}
            name="appEmail"
            id="appEmail"
            tooltipText={EMAIL_TOOLTIP_TEXT}
            type="email"
          />
          <br />
          <p>
            We may use this email address to send you notifications and messages related to your
            account, respond to email requests we receive from you, and to contact you about our
            products, services, special offers, and other promotions. You understand that email is
            not a secure means of communication and we are not responsible for any misdirection,
            interception, or other disclosure of the contents of any email properly addressed to
            you. If you would like to opt out, contact us at 866-936-0602.
          </p>
          <Field
            component={CustomPhoneNumber}
            label="Mobile Number"
            name="appMobileNumber"
            id="appMobileNumber"
            tooltipText={PHONENUMBER_TOOLTIP_TEXT}
          />
          <br />
          <p>
            You are not required to have a Mobile Number as a condition of this loan application. If
            you do not have a Mobile Number, please enter another Phone Number in the Mobile Number
            field.
          </p>
          <Field
            component={SsnFormat}
            label="Your full Social Security Number"
            name="appSsn"
            id="appSsn"
            tooltipText={APPFULLSSN_TOOLTIP_TEXT}
            type="password"
            icon="lock"
          />
          <p className="info">Click on the padlock to view the hidden text</p>
          <Button type="submit" title={isReview ? 'Review' : 'Next'} />
          <Disclosure />
        </Col>
      </Row>
    </Form>
  );

  const AdditionalApplicantInformationFormFormik = withFormik({
    mapPropsToValues: () => ({
      appEmail: appEmail || email || '',
      appSpanishConsent: appSpanishConsent || false,
      appMobileNumber: appMobileNumber || phoneNumber || '',
      appMobileNumberConsent: appMobileNumberConsent || false,
      appSsn: appSsn || '',
    }),

    validationSchema: Yup.object({
      appEmail: Yup.string()
        .email('Please enter a valid email.')
        .required('Please enter an email.')
        .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),
      appSpanishConsent: Yup.boolean(),
      appMobileNumber: Yup.string()
        .test('required', 'Please enter your phone number.', val => val !== '+' && val)
        .test('len', 'Invalid phone number', val => val && val.match(/\d/g).join('').length === 10),
      appMobileNumberConsent: Yup.boolean().oneOf([true], 'Please check to continue.'),
      appSsn: Yup.string()
        .required('Please enter your full SSN.')
        .test(
          'len',
          'Must have 9 digits',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 9,
        )
        .test(
          'match',
          'Last 4 digits must match.',
          val =>
            ssnLastDigits === '' ||
            (val &&
              val
                .toString()
                .match(/\d/g)
                .join('')
                .substr(
                  val
                    .toString()
                    .match(/\d/g)
                    .join('').length - 4,
                ) === ssnLastDigits),
        )
        .typeError('Numbers only.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: isReview ? 'Review' : 'Next',
        did,
        dealerUserId,
      });

      values.appSsn = values.appSsn.match(/\d/g).join('');
      values.appMobileNumber = values.appMobileNumber.match(/\d/g).join('');
      changeApplicationValues(dispatchChangeGreenSkyApplicationInfoValue, values);
      dispatchChangePage(
        isReview
          ? AtWaterGreenSkyFlowPageNumber.Review
          : AtWaterGreenSkyFlowPageNumber.AppGrossAnnualIncome,
      );

      dispatchSubmitMindfire({
        email: email,
        lastPage: '9012',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(AdditionalApplicantInformationForm);

  return (
    <Container>
      <AdditionalApplicantInformationFormFormik />
    </Container>
  );
};

const mapStateToProps = state => ({
  greenSkyApplicationInformation: state.greenSkyApplicationInformation,
  ssnLastDigits: state.preQualificationInformation.ssnLastDigits || '',
  preQualificationInformation: state.preQualificationInformation,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetFlow: flowName => dispatch(setFlow(flowName)),
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeGreenSkyApplicationInfoValue: (key, value) =>
    dispatch(changeGreenSkyApplicationInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

AdditionalApplicantInformation.propTypes = {
  greenSkyApplicationInformation: PropTypes.object,
  ssnLastDigits: PropTypes.string,
  dispatchChangePage: PropTypes.func,
  dispatchChangeGreenSkyApplicationInfoValue: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AdditionalApplicantInformation),
);
