import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { isMicrofReviewNeedsReviewStatus } from '../../assets/js/ApplicationStatusEnum';
import BackBtn from '../../components/UI/BackButton/BackButton';
import Button from '../../components/UI/Button/CustomButton';
import { reactGAEvent, reactGAPageview } from '../../helper';
import {
  changeMicrofInfoValue,
  changePage,
  submitMindfire,
  uploadProofOfIncomeMicrofApplication,
  uploadProofOfIncomeMicrofPartnerApplication,
} from '../../redux/actions/index';
import { MicrofFlowPageNumber } from './MicrofFlow';
import proofOfIncomeImg from '../../assets/img/ProofOfIncome.png';
import { withRouter } from 'react-router-dom';
import { getIsOptimus } from '../../config/settings';

const Dropzone = props => {
  const { filesListName, label, dispatchChangeValue } = props;
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  useEffect(() => {
    dispatchChangeValue(filesListName, acceptedFiles);
    // eslint-disable-next-line
  }, [acceptedFiles]);

  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <section>
      <label className="mb-4">
        <b>{label}</b>
      </label>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
      </div>
      <aside>
        <ul>{files}</ul>
      </aside>
    </section>
  );
};

const ManualProofOfIncome = props => {
  const {
    children,
    microfInformation,
    dispatchChangePage,
    dispatchUploadProofOfIncomeMicrofApplication,
    dispatchUploadProofOfIncomeMicrofPartnerApplication,
    email,
    did,
    dealerUserId,
    dispatchSubmitMindfire,
    status,
    appToken,
    addedBy,
    history,
  } = props;

  const isOptimus = getIsOptimus();

  const needsReviewStatusLastPage = '4120';
  const approvedStatusLastPage = '4110';
  const lastPage = isMicrofReviewNeedsReviewStatus(status)
    ? needsReviewStatusLastPage
    : approvedStatusLastPage;

  const mindFireRequest = {
    email: email,
    lastPage: lastPage,
    dealerUserId: dealerUserId,
    did: did,
  };

  useEffect(() => {
    reactGAPageview({
      path: '/ca_uploaddocs',
      trackerNames: [],
      title: 'ca_uploaddocs',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Row>
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <Row>
            <Col>
              <img alt="Proof of income" src={proofOfIncomeImg} className="w-100" />
            </Col>
          </Row>
          <div className="dropzone-container">
            <Dropzone
              label={'Applicant Proof of Income (or benefits) Information'}
              filesListName="applicantFiles"
              {...props}
            />
          </div>
          <br />
          {microfInformation.hasCoApp && (
            <>
              <div className="dropzone-container">
                <Dropzone
                  label={'Co-Applicant Proof of Income (or benefits) Information'}
                  filesListName="coApplicantFiles"
                  {...props}
                />
              </div>
              <br />
            </>
          )}
          <Button
            type="button"
            title="Submit"
            clickHandler={() => {
              reactGAEvent({
                category: 'Button',
                action: 'Click',
                label: 'Submit',
                did,
                dealerUserId,
              });
              dispatchSubmitMindfire(mindFireRequest);
              if (addedBy && appToken) {
                dispatchUploadProofOfIncomeMicrofPartnerApplication(
                  microfInformation.applicantFiles,
                  microfInformation.coApplicantFiles,
                  history,
                );
              } else {
                dispatchUploadProofOfIncomeMicrofApplication(
                  microfInformation.applicantFiles,
                  microfInformation.coApplicantFiles,
                  history,
                );
              }
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  status: state.softPull.applicationSubmitDTO.status,
  microfInformation: state.microfInformation,
  applicationId: state.softPull.applicationSubmitDTO.applicationId,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  appToken: state.auth.appToken,
  addedBy: state.softPull.applicationSubmitDTO.addedBy,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) => dispatch(changeMicrofInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchUploadProofOfIncomeMicrofApplication: (applicantFiles, coApplicantFiles, history) =>
    dispatch(uploadProofOfIncomeMicrofApplication(applicantFiles, coApplicantFiles, history)),
  dispatchUploadProofOfIncomeMicrofPartnerApplication: (
    applicantFiles,
    coApplicantFiles,
    history,
  ) =>
    dispatch(
      uploadProofOfIncomeMicrofPartnerApplication(applicantFiles, coApplicantFiles, history),
    ),
});

ManualProofOfIncome.propTypes = {
  microfInformation: PropTypes.object,
  applicationId: PropTypes.number,
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
  dispatchUploadProofOfIncomeMicrofApplication: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ManualProofOfIncome),
);
