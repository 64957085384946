import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import userRoleEnum from '../../assets/js/UserRoleEnum';
import UserRoleEnum, { getByValue } from '../../assets/js/UserRoleEnum';
import { emulateStop, changeAuthValue, getVersion, logout } from '../../redux/actions/index';

const PortalDropdown = props => {
  const {
    dispatchChangeAuthValue,
    dispatchGetVersion,
    workUser,
    history,
    dropDownClassName,
    dropDownToggleClassName,
    user,
    dispatchEmulateStop,
    dispatchLogout,
  } = props;
  const [dropDownToggle, setDropDownToggle] = useState(false);
  const toggleDropDown = () => setDropDownToggle(!dropDownToggle);

  const consumerUser =
    user?.user?.consumerUser && user?.user?.consumerUser?.role === userRoleEnum.CONSUMER.value;

  return (
    <Dropdown className={dropDownClassName} isOpen={dropDownToggle} toggle={toggleDropDown}>
      <DropdownToggle caret className={dropDownToggleClassName} id="dropdown-button">
        My Account
      </DropdownToggle>
      <DropdownMenu right className={consumerUser ? 'dropdown-open-co' : ''}>
        <DropdownItem header>
          {workUser
            ? workUser.nameFirst
              ? workUser.nameFirst + ' ' + workUser.nameLast
              : workUser.firstName + ' ' + workUser.lastName
            : null}
        </DropdownItem>
        <DropdownItem disabled>
          {workUser
            ? getByValue(workUser.role) === null
              ? UserRoleEnum[workUser.role].name
              : getByValue(workUser.role).name
            : null}
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem
          onClick={() => {
            dispatchChangeAuthValue('showProfile', true);
          }}
        >
          View Profile
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            dispatchChangeAuthValue('showChangeYourPassword', true);
          }}
        >
          Change Password
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            dispatchGetVersion();
          }}
        >
          About
        </DropdownItem>
        {user && user.emulatorId ? (
          <DropdownItem
            onClick={() => {
              dispatchEmulateStop(history);
            }}
          >
            Stop Emulation
          </DropdownItem>
        ) : (
          <DropdownItem
            onClick={() => {
              dispatchLogout(history);
            }}
          >
            Sign Out
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

const mapStateToProps = state => ({
  workUser: state.auth.user.dealerUser || state.auth.user.internalUser,
  user: state.auth.user.user,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangeAuthValue: (key, value) => dispatch(changeAuthValue(key, value)),
  dispatchGetVersion: () => dispatch(getVersion()),
  dispatchEmulateStop: history => dispatch(emulateStop(history)),
  dispatchLogout: history => dispatch(logout(history)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PortalDropdown),
);
