import { AgeFromDateString } from 'age-calculator';
import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import BackBtn from '../../components/UI/BackButton/BackButton';
import Button from '../../components/UI/Button/CustomButton';
import DatePicker from '../../components/UI/Form/DatePicker/DatePicker';
import SsnFormat from '../../components/UI/Form/NumberFormat/SsnFormat';
import { getAppName } from '../../config/settings';
import {
  APPFULLSSN_TOOLTIP_TEXT,
  COAPPDOB_TOOLTIP_TEXT,
  COAPPFULLSSN_TOOLTIP_TEXT,
} from '../../constants/tooltipTexts';
import { reactGAEvent, reactGAPageview, validDateFormat } from '../../helper';
import { changePage, submitMindfire, changeRheemInfoValue } from '../../redux/actions';
import { RheemFlowPageNumber } from './RheemFlow';

const appName = getAppName();

const IdentificationInformation = props => {
  const {
    rheemInformation,
    dispatchChangeRheemInfoValue,
    children,
    dispatchChangePage,
    dispatchSubmitMindfire,
    email,
    did,
    dealerUserId,
    ssnLastDigits,
    isReview,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_coappssn',
      trackerNames: [],
      title: 'ca_coappssn',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const IdentificationInformationForm = () => {
    return (
      <Form noValidate autoComplete="off">
        <Field
          component={SsnFormat}
          label="Your full Social Security Number"
          name="appFullSSN"
          id="appFullSSN"
          tooltipText={APPFULLSSN_TOOLTIP_TEXT}
          type="password"
          icon="lock"
        />
        {rheemInformation.hasCoApp && (
          <>
            <Field
              component={SsnFormat}
              label="Co-Applicant’s full Social Security Number"
              name="coAppFullSsn"
              id="coAppFullSsn"
              tooltipText={COAPPFULLSSN_TOOLTIP_TEXT}
              type="password"
              icon="lock"
            />
            <Field
              component={DatePicker}
              name="coAppDoB"
              id="coAppDoB"
              tooltipText={COAPPDOB_TOOLTIP_TEXT}
              label="Co-Applicant's Date of Birth"
            />
          </>
        )}

        <p className="info">
          {appName} uses security measures that comply with federal law, including data encryption
          technology, to protect your personal information from unauthorized access and use.
        </p>
        <Button type="submit" title="Review Application" />
      </Form>
    );
  };

  const IdentificationInformationFormFormik = withFormik({
    mapPropsToValues: () => ({
      appFullSSN: rheemInformation.appFullSSN || '',
      coAppFullSsn: rheemInformation.coAppFullSsn || '',
      coAppDoB: rheemInformation.coAppDoB || '',
    }),

    validationSchema: Yup.object({
      coAppDoB: rheemInformation.hasCoApp
        ? Yup.string()
            .required("Please enter the Co-Applicant's date of birth.")
            .test(
              'incomplete',
              'Incomplete date',
              val =>
                val &&
                val
                  .toString()
                  .match(/\d/g)
                  .join('').length === 8,
            )
            .test('valid', 'Invalid date format.', val => val && !validDateFormat(val))
            .test(
              'age',
              'The Co-Applicant must be at least 18 years old.',
              val => val && new AgeFromDateString(val).age >= 18,
            )
        : Yup.string(),
      appFullSSN: Yup.string()
        .required("Please enter the aplicant's full SSN.")
        .typeError('Only numbers allowed.')
        .test(
          'len',
          'Must have 9 digits',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 9,
        )
        .test(
          'match',
          'Last 4 digits must match.',
          val =>
            ssnLastDigits === '' ||
            (val &&
              val
                .toString()
                .match(/\d/g)
                .join('')
                .substr(
                  val
                    .toString()
                    .match(/\d/g)
                    .join('').length - 4,
                ) === ssnLastDigits),
        ),
      coAppFullSsn: rheemInformation.hasCoApp
        ? Yup.string()
            .required("Please enter the Co-Applicant's full SSN.")
            .typeError('Only numbers allowed.')
            .test(
              'len',
              'Must have 9 digits',
              val =>
                val &&
                val
                  .toString()
                  .match(/\d/g)
                  .join('').length === 9,
            )
            .test({
              name: 'sameSsn',
              exclusive: false,
              message: `Same SSN as the applicant's is not allowed.`,
              test: function(value) {
                return this.resolve(Yup.ref('appFullSSN')) !== value;
              },
            })
        : Yup.string(),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Review Application',
        did,
        dealerUserId,
      });
      dispatchChangeRheemInfoValue(
        'appFullSSN',
        values.appFullSSN
          .toString()
          .match(/\d/g)
          .join(''),
      );
      if (rheemInformation.hasCoApp) {
        dispatchChangeRheemInfoValue('coAppDoB', values.coAppDoB);
        dispatchChangeRheemInfoValue(
          'coAppFullSsn',
          values.coAppFullSsn
            .toString()
            .match(/\d/g)
            .join(''),
        );
      }
      dispatchChangePage(RheemFlowPageNumber.Review);
      dispatchSubmitMindfire({
        email: email,
        lastPage: '0092',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(IdentificationInformationForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={
            !rheemInformation.hasCoApp
              ? RheemFlowPageNumber.HasCoApplicant
              : isReview
              ? RheemFlowPageNumber.CoApplicantGrossAnnualIncome
              : RheemFlowPageNumber.HasCoApplicant
          }
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <IdentificationInformationFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  isReview: state.rheemInformation.isReview,
  rheemInformation: state.rheemInformation,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  ssnLastDigits: state.preQualificationInformation.ssnLastDigits || '',
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeRheemInfoValue: (key, value) => dispatch(changeRheemInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

IdentificationInformation.propTypes = {
  dispatchChangePage: PropTypes.func,
  rheemInformation: PropTypes.object,
  dispatchChangeRheemInfoValue: PropTypes.func,
  aquaInformation: PropTypes.object,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(IdentificationInformation),
);
