import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import Button from '../../components/UI/Button/CustomButton';
import { changePage, submitMindfire, endLoading, applyToBeDealer } from '../../redux/actions/index';
import { withRouter } from 'react-router-dom';
import BackBtn from '../../components/UI/BackButton/BackButton';
import { ContractorEnrollmentAtWaterGSFlowPageNumber } from './_Flow';
import { changeContractorEnrollmentAtWaterGSValue } from '../../redux/actions/contractorEnrollmentAtWaterGSActions';
import OwnerInfoRow from './Components/OwnerInfoRow';
import { isMobile } from 'react-device-detect';
import { isNoreplyEmail, validDateFormat } from '../../helper';
import { AgeFromDateString } from 'age-calculator';

const OwnerInformation = props => {
  const { ce, dispatchChangeValue, dispatchChangePage, children } = props;

  const [infoValues, setInfoValues] = useState(
    ce.owners && ce.owners.length > 0
      ? ce.owners
      : [
          {
            firstName: '',
            lastName: '',
            email: '',
            mobilePhone: '',
            birthDate: '',
            ownerSince: '',
            ssN: '',
            ownership: '',
            zipcode: '',
            city: '',
            state: '',
            street: '',
          },
        ],
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  const manageOwners = values => {
    let infoValuesTmp = [...infoValues];

    infoValuesTmp.forEach((infoValue, index) => {
      infoValue.firstName = values[`firstName${index}`];
      infoValue.lastName = values[`lastName${index}`];
      infoValue.email = values[`email${index}`];
      infoValue.mobilePhone = values[`mobilePhone${index}`];
      infoValue.birthDate = values[`birthDate${index}`];
      infoValue.ownerSince = values[`ownerSince${index}`];
      infoValue.ssN = values[`ssN${index}`];
      infoValue.ownership = values[`ownership${index}`];
      infoValue.zipcode = values[`zipcode${index}`];
      infoValue.city = values[`city${index}`];
      infoValue.state = values[`state${index}`];
      infoValue.street = values[`street${index}`];
    });

    infoValuesTmp.push({
      firstName: '',
      lastName: '',
      email: '',
      mobilePhone: '',
      birthDate: '',
      ownerSince: '',
      ssN: '',
      ownership: '',
      zipcode: '',
      city: '',
      state: '',
      street: '',
    });

    setInfoValues(infoValuesTmp);
  };

  const addMore = values => {
    manageOwners(values);
  };

  const removeUnit = () => {
    if (infoValues.length > 1) {
      setInfoValues(infoValues.slice(0, -1));
    }
  };

  const OwnerInformationForm = ({ values, errors, touched }) => (
    <Form noValidate>
      {infoValues.length > 0 &&
        infoValues.map((_info, index) => <OwnerInfoRow index={index} key={index} />)}
      <Row className={'justify-content-center mt-15'}>
        <Col sm="6">
          {infoValues.length > 1 && (
            <Button
              type="button"
              color="danger"
              className={'rounded-pill mr-2' + (isMobile ? ' mt-3' : '')}
              clickHandler={() => {
                removeUnit();
              }}
            >
              Remove
            </Button>
          )}
        </Col>
        <Col sm="6">
          {infoValues.length > 0 && infoValues.length < 4 && (
            <Button
              type="button"
              className={'rounded-pill btn-primary mr-2' + (isMobile ? ' mt-3 pl-5 pr-5' : '')}
              clickHandler={() => {
                addMore(values);
              }}
            >
              Add Another Owner
            </Button>
          )}
        </Col>
      </Row>

      <Button type="submit" title="Next" />
    </Form>
  );

  const OwnerInformationFormFormik = withFormik({
    mapPropsToValues: () => ({
      ...infoValues.reduce(
        (previous, current, index) =>
          Object.assign(previous, {
            [`firstName${index}`]: current.firstName,
            [`lastName${index}`]: current.lastName,
            [`email${index}`]: current.email,
            [`mobilePhone${index}`]: current.mobilePhone,
            [`birthDate${index}`]: current.birthDate,
            [`ownerSince${index}`]: current.ownerSince,
            [`ssN${index}`]: current.ssN,
            [`ownership${index}`]: current.ownership,
            [`zipcode${index}`]: current.zipcode,
            [`city${index}`]: current.city,
            [`state${index}`]: current.state,
            [`street${index}`]: current.street,
          }),
        {},
      ),
    }),

    validationSchema: Yup.object({
      ...infoValues.reduce(
        (previous, _current, index) =>
          Object.assign(previous, {
            [`firstName${index}`]: Yup.string()
              .test({
                name: 'required',
                exclusive: false,
                message: 'First name is required.',
                test: function(value) {
                  const lastName = this.resolve(Yup.ref(`lastName${index}`));
                  const email = this.resolve(Yup.ref(`email${index}`));
                  const mobilePhone = this.resolve(Yup.ref(`mobilePhone${index}`));
                  const birthDate = this.resolve(Yup.ref(`birthDate${index}`));
                  const ownerSince = this.resolve(Yup.ref(`ownerSince${index}`));
                  const ssN = this.resolve(Yup.ref(`ssN${index}`));
                  const ownership = this.resolve(Yup.ref(`ownership${index}`));
                  const zipcode = this.resolve(Yup.ref(`zipcode${index}`));
                  const city = this.resolve(Yup.ref(`city${index}`));
                  const state = this.resolve(Yup.ref(`state${index}`));
                  const street = this.resolve(Yup.ref(`street${index}`));
                  if (
                    lastName ||
                    email ||
                    mobilePhone ||
                    birthDate ||
                    ownerSince ||
                    ssN ||
                    ownership ||
                    zipcode ||
                    city ||
                    state ||
                    street ||
                    !index
                  )
                    return !!value;
                  return true;
                },
              })
              .matches(/^(?=.*[a-zA-Z ])([a-zA-Z ]+)$/, 'Special characters are not allowed.'),
            [`lastName${index}`]: Yup.string()
              .test({
                name: 'required',
                exclusive: false,
                message: 'Last name is required.',
                test: function(value) {
                  const firstName = this.resolve(Yup.ref(`firstName${index}`));
                  const email = this.resolve(Yup.ref(`email${index}`));
                  const mobilePhone = this.resolve(Yup.ref(`mobilePhone${index}`));
                  const birthDate = this.resolve(Yup.ref(`birthDate${index}`));
                  const ownerSince = this.resolve(Yup.ref(`ownerSince${index}`));
                  const ssN = this.resolve(Yup.ref(`ssN${index}`));
                  const ownership = this.resolve(Yup.ref(`ownership${index}`));
                  const zipcode = this.resolve(Yup.ref(`zipcode${index}`));
                  const city = this.resolve(Yup.ref(`city${index}`));
                  const state = this.resolve(Yup.ref(`state${index}`));
                  const street = this.resolve(Yup.ref(`street${index}`));
                  if (
                    firstName ||
                    email ||
                    mobilePhone ||
                    birthDate ||
                    ownerSince ||
                    ssN ||
                    ownership ||
                    zipcode ||
                    city ||
                    state ||
                    street ||
                    !index
                  )
                    return !!value;
                  return true;
                },
              })
              .matches(/^(?=.*[a-zA-Z ])([a-zA-Z ]+)$/, 'Special characters are not allowed.'),
            [`email${index}`]: Yup.string()
              .test({
                name: 'required',
                exclusive: false,
                message: 'Email is required.',
                test: function(value) {
                  const firstName = this.resolve(Yup.ref(`firstName${index}`));
                  const lastName = this.resolve(Yup.ref(`lastName${index}`));
                  const mobilePhone = this.resolve(Yup.ref(`mobilePhone${index}`));
                  const birthDate = this.resolve(Yup.ref(`birthDate${index}`));
                  const ownerSince = this.resolve(Yup.ref(`ownerSince${index}`));
                  const ssN = this.resolve(Yup.ref(`ssN${index}`));
                  const ownership = this.resolve(Yup.ref(`ownership${index}`));
                  const zipcode = this.resolve(Yup.ref(`zipcode${index}`));
                  const city = this.resolve(Yup.ref(`city${index}`));
                  const state = this.resolve(Yup.ref(`state${index}`));
                  const street = this.resolve(Yup.ref(`street${index}`));
                  if (
                    firstName ||
                    lastName ||
                    mobilePhone ||
                    birthDate ||
                    ownerSince ||
                    ssN ||
                    ownership ||
                    zipcode ||
                    city ||
                    state ||
                    street ||
                    !index
                  )
                    return !!value;
                  return true;
                },
              })
              .email('Please enter a valid email.')
              .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),
            [`mobilePhone${index}`]: Yup.string()
              .test({
                name: 'required',
                exclusive: false,
                message: 'Mobile phone is required.',
                test: function(value) {
                  const firstName = this.resolve(Yup.ref(`firstName${index}`));
                  const lastName = this.resolve(Yup.ref(`lastName${index}`));
                  const email = this.resolve(Yup.ref(`email${index}`));
                  const birthDate = this.resolve(Yup.ref(`birthDate${index}`));
                  const ownerSince = this.resolve(Yup.ref(`ownerSince${index}`));
                  const ssN = this.resolve(Yup.ref(`ssN${index}`));
                  const ownership = this.resolve(Yup.ref(`ownership${index}`));
                  const zipcode = this.resolve(Yup.ref(`zipcode${index}`));
                  const city = this.resolve(Yup.ref(`city${index}`));
                  const state = this.resolve(Yup.ref(`state${index}`));
                  const street = this.resolve(Yup.ref(`street${index}`));
                  if (
                    firstName ||
                    lastName ||
                    email ||
                    birthDate ||
                    ownerSince ||
                    ssN ||
                    ownership ||
                    zipcode ||
                    city ||
                    state ||
                    street ||
                    !index
                  )
                    return !!value;
                  return true;
                },
              })
              .test('required', 'Please enter the mobile phone number.', val => val !== '+' && val)
              .test(
                'len',
                'Invalid phone number',
                val => val && val.match(/\d/g).join('').length === 10,
              ),
            [`birthDate${index}`]: Yup.string()
              .test({
                name: 'required',
                exclusive: false,
                message: 'Birth date is required.',
                test: function(value) {
                  const firstName = this.resolve(Yup.ref(`firstName${index}`));
                  const lastName = this.resolve(Yup.ref(`lastName${index}`));
                  const email = this.resolve(Yup.ref(`email${index}`));
                  const mobilePhone = this.resolve(Yup.ref(`mobilePhone${index}`));
                  const ownerSince = this.resolve(Yup.ref(`ownerSince${index}`));
                  const ssN = this.resolve(Yup.ref(`ssN${index}`));
                  const ownership = this.resolve(Yup.ref(`ownership${index}`));
                  const zipcode = this.resolve(Yup.ref(`zipcode${index}`));
                  const city = this.resolve(Yup.ref(`city${index}`));
                  const state = this.resolve(Yup.ref(`state${index}`));
                  const street = this.resolve(Yup.ref(`street${index}`));
                  if (
                    firstName ||
                    lastName ||
                    email ||
                    mobilePhone ||
                    ownerSince ||
                    ssN ||
                    ownership ||
                    zipcode ||
                    city ||
                    state ||
                    street ||
                    !index
                  )
                    return !!value;
                  return true;
                },
              })
              .test(
                'incomplete',
                'Incomplete date',
                val =>
                  val &&
                  val
                    .toString()
                    .match(/\d/g)
                    .join('').length === 8,
              )
              .test('valid', 'Invalid date format.', val => val && !validDateFormat(val))
              .test(
                'age',
                'The person must be at least 18 years old.',
                val => val && new AgeFromDateString(val).age >= 18,
              ),
            [`ownerSince${index}`]: Yup.string()
              .test({
                name: 'required',
                exclusive: false,
                message: 'This field is required.',
                test: function(value) {
                  const firstName = this.resolve(Yup.ref(`firstName${index}`));
                  const lastName = this.resolve(Yup.ref(`lastName${index}`));
                  const email = this.resolve(Yup.ref(`email${index}`));
                  const mobilePhone = this.resolve(Yup.ref(`mobilePhone${index}`));
                  const birthDate = this.resolve(Yup.ref(`birthDate${index}`));
                  const ssN = this.resolve(Yup.ref(`ssN${index}`));
                  const ownership = this.resolve(Yup.ref(`ownership${index}`));
                  const zipcode = this.resolve(Yup.ref(`zipcode${index}`));
                  const city = this.resolve(Yup.ref(`city${index}`));
                  const state = this.resolve(Yup.ref(`state${index}`));
                  const street = this.resolve(Yup.ref(`street${index}`));
                  if (
                    firstName ||
                    lastName ||
                    email ||
                    mobilePhone ||
                    birthDate ||
                    ssN ||
                    ownership ||
                    zipcode ||
                    city ||
                    state ||
                    street ||
                    !index
                  )
                    return !!value;
                  return true;
                },
              })
              .test(
                'incomplete',
                'Incomplete date',
                val =>
                  val &&
                  val
                    .toString()
                    .match(/\d/g)
                    .join('').length === 8,
              )
              .test('valid', 'Invalid date format.', val => val && !validDateFormat(val))
              .test(
                'age',
                'Please provide a valid date.',
                val => val && new AgeFromDateString(val).age >= 0,
              ),
            [`ssN${index}`]: Yup.string()
              .test({
                name: 'required',
                exclusive: false,
                message: 'Please enter full SSN.',
                test: function(value) {
                  const firstName = this.resolve(Yup.ref(`firstName${index}`));
                  const lastName = this.resolve(Yup.ref(`lastName${index}`));
                  const email = this.resolve(Yup.ref(`email${index}`));
                  const mobilePhone = this.resolve(Yup.ref(`mobilePhone${index}`));
                  const birthDate = this.resolve(Yup.ref(`birthDate${index}`));
                  const ownerSince = this.resolve(Yup.ref(`ownerSince${index}`));
                  const ownership = this.resolve(Yup.ref(`ownership${index}`));
                  const zipcode = this.resolve(Yup.ref(`zipcode${index}`));
                  const city = this.resolve(Yup.ref(`city${index}`));
                  const state = this.resolve(Yup.ref(`state${index}`));
                  const street = this.resolve(Yup.ref(`street${index}`));
                  if (
                    firstName ||
                    lastName ||
                    email ||
                    mobilePhone ||
                    birthDate ||
                    ownerSince ||
                    ownership ||
                    zipcode ||
                    city ||
                    state ||
                    street ||
                    !index
                  )
                    return !!value;
                  return true;
                },
              })
              .test(
                'len',
                'Must have 9 digits',
                val =>
                  val &&
                  val
                    .toString()
                    .match(/\d/g)
                    .join('').length === 9,
              )
              .typeError('Numbers only.'),
            [`ownership${index}`]: Yup.number()
              .test({
                name: 'required',
                exclusive: false,
                message: 'This field is required.',
                test: function(value) {
                  const firstName = this.resolve(Yup.ref(`firstName${index}`));
                  const lastName = this.resolve(Yup.ref(`lastName${index}`));
                  const email = this.resolve(Yup.ref(`email${index}`));
                  const mobilePhone = this.resolve(Yup.ref(`mobilePhone${index}`));
                  const birthDate = this.resolve(Yup.ref(`birthDate${index}`));
                  const ownerSince = this.resolve(Yup.ref(`ownerSince${index}`));
                  const ssN = this.resolve(Yup.ref(`ssN${index}`));
                  const zipcode = this.resolve(Yup.ref(`zipcode${index}`));
                  const city = this.resolve(Yup.ref(`city${index}`));
                  const state = this.resolve(Yup.ref(`state${index}`));
                  const street = this.resolve(Yup.ref(`street${index}`));
                  if (
                    firstName ||
                    lastName ||
                    email ||
                    mobilePhone ||
                    birthDate ||
                    ownerSince ||
                    ssN ||
                    zipcode ||
                    city ||
                    state ||
                    street ||
                    !index
                  )
                    return !!value;
                  return true;
                },
              })
              .moreThan(0, 'Please enter a value between 1 and 100.')
              .lessThan(101, 'Please enter a value between 1 and 100.')
              .typeError('Numbers only.'),
            [`zipcode${index}`]: Yup.string()
              .test({
                name: 'required',
                exclusive: false,
                message: 'This field is required.',
                test: function(value) {
                  const firstName = this.resolve(Yup.ref(`firstName${index}`));
                  const lastName = this.resolve(Yup.ref(`lastName${index}`));
                  const email = this.resolve(Yup.ref(`email${index}`));
                  const mobilePhone = this.resolve(Yup.ref(`mobilePhone${index}`));
                  const birthDate = this.resolve(Yup.ref(`birthDate${index}`));
                  const ownerSince = this.resolve(Yup.ref(`ownerSince${index}`));
                  const ssN = this.resolve(Yup.ref(`ssN${index}`));
                  const ownership = this.resolve(Yup.ref(`ownership${index}`));
                  const city = this.resolve(Yup.ref(`city${index}`));
                  const state = this.resolve(Yup.ref(`state${index}`));
                  const street = this.resolve(Yup.ref(`street${index}`));
                  if (
                    firstName ||
                    lastName ||
                    email ||
                    mobilePhone ||
                    birthDate ||
                    ownerSince ||
                    ssN ||
                    ownership ||
                    city ||
                    state ||
                    street ||
                    !index
                  )
                    return !!value;
                  return true;
                },
              })
              .matches(/^\d{5}?$/, {
                message: 'Invalid zip code.',
                excludeEmptyString: true,
              }),
            [`city${index}`]: Yup.string().test({
              name: 'required',
              exclusive: false,
              message: 'This field is required.',
              test: function(value) {
                const firstName = this.resolve(Yup.ref(`firstName${index}`));
                const lastName = this.resolve(Yup.ref(`lastName${index}`));
                const email = this.resolve(Yup.ref(`email${index}`));
                const mobilePhone = this.resolve(Yup.ref(`mobilePhone${index}`));
                const birthDate = this.resolve(Yup.ref(`birthDate${index}`));
                const ownerSince = this.resolve(Yup.ref(`ownerSince${index}`));
                const ssN = this.resolve(Yup.ref(`ssN${index}`));
                const ownership = this.resolve(Yup.ref(`ownership${index}`));
                const zipcode = this.resolve(Yup.ref(`zipcode${index}`));
                const state = this.resolve(Yup.ref(`state${index}`));
                const street = this.resolve(Yup.ref(`street${index}`));
                if (
                  firstName ||
                  lastName ||
                  email ||
                  mobilePhone ||
                  birthDate ||
                  ownerSince ||
                  ssN ||
                  ownership ||
                  zipcode ||
                  state ||
                  street ||
                  !index
                )
                  return !!value;
                return true;
              },
            }),
            [`state${index}`]: Yup.string().test({
              name: 'required',
              exclusive: false,
              message: 'This field is required.',
              test: function(value) {
                const firstName = this.resolve(Yup.ref(`firstName${index}`));
                const lastName = this.resolve(Yup.ref(`lastName${index}`));
                const email = this.resolve(Yup.ref(`email${index}`));
                const mobilePhone = this.resolve(Yup.ref(`mobilePhone${index}`));
                const birthDate = this.resolve(Yup.ref(`birthDate${index}`));
                const ownerSince = this.resolve(Yup.ref(`ownerSince${index}`));
                const ssN = this.resolve(Yup.ref(`ssN${index}`));
                const ownership = this.resolve(Yup.ref(`ownership${index}`));
                const zipcode = this.resolve(Yup.ref(`zipcode${index}`));
                const city = this.resolve(Yup.ref(`city${index}`));
                const street = this.resolve(Yup.ref(`street${index}`));
                if (
                  firstName ||
                  lastName ||
                  email ||
                  mobilePhone ||
                  birthDate ||
                  ownerSince ||
                  ssN ||
                  ownership ||
                  zipcode ||
                  city ||
                  street ||
                  !index
                )
                  return !!value;
                return true;
              },
            }),
            [`street${index}`]: Yup.string()
              .test({
                name: 'required',
                exclusive: false,
                message: 'This field is required.',
                test: function(value) {
                  const firstName = this.resolve(Yup.ref(`firstName${index}`));
                  const lastName = this.resolve(Yup.ref(`lastName${index}`));
                  const email = this.resolve(Yup.ref(`email${index}`));
                  const mobilePhone = this.resolve(Yup.ref(`mobilePhone${index}`));
                  const birthDate = this.resolve(Yup.ref(`birthDate${index}`));
                  const ownerSince = this.resolve(Yup.ref(`ownerSince${index}`));
                  const ssN = this.resolve(Yup.ref(`ssN${index}`));
                  const ownership = this.resolve(Yup.ref(`ownership${index}`));
                  const zipcode = this.resolve(Yup.ref(`zipcode${index}`));
                  const city = this.resolve(Yup.ref(`city${index}`));
                  const state = this.resolve(Yup.ref(`state${index}`));
                  if (
                    firstName ||
                    lastName ||
                    email ||
                    mobilePhone ||
                    birthDate ||
                    ownerSince ||
                    ssN ||
                    ownership ||
                    zipcode ||
                    city ||
                    state ||
                    !index
                  )
                    return !!value;
                  return true;
                },
              })
              .matches(/.*([a-zA-Z].*[0-9]|[0-9].*[a-zA-Z]).*/, {
                message: 'The street field must contain the street name and number',
                excludeEmptyString: true,
              }),
          }),
        {},
      ),
    }),

    handleSubmit: values => {
      let ownersList = [];

      infoValues.forEach((_infovalue, index) => {
        if (
          values[`firstName${index}`] &&
          values[`lastName${index}`] &&
          values[`email${index}`] &&
          values[`mobilePhone${index}`] &&
          values[`birthDate${index}`] &&
          values[`ownerSince${index}`] &&
          values[`ssN${index}`] &&
          values[`ownership${index}`] &&
          values[`zipcode${index}`] &&
          values[`city${index}`] &&
          values[`state${index}`] &&
          values[`street${index}`]
        ) {
          ownersList.push({
            firstName: values[`firstName${index}`],
            lastName: values[`lastName${index}`],
            email: values[`email${index}`],
            mobilePhone: values[`mobilePhone${index}`],
            birthDate: values[`birthDate${index}`],
            ownerSince: values[`ownerSince${index}`],
            ssN: values[`ssN${index}`],
            ownership: values[`ownership${index}`],
            zipcode: values[`zipcode${index}`],
            city: values[`city${index}`],
            state: values[`state${index}`],
            street: values[`street${index}`],
          });
        }

        dispatchChangeValue('owners', ownersList);
        dispatchChangePage(ContractorEnrollmentAtWaterGSFlowPageNumber.ContactAndBankInformation);
      });
    },
  })(OwnerInformationForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={ContractorEnrollmentAtWaterGSFlowPageNumber.BusinessInformation}
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <h5>
            {`(Please provide all owners so that BOTH: a) all owners with 10% or greater ownership are
            listed, AND b) the combined ownership is at least 51%. It is currently 0%)`}
          </h5>
          <OwnerInformationFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  ce: state.contractorEnrollmentAtWaterGS,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) =>
    dispatch(changeContractorEnrollmentAtWaterGSValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchEndLoading: () => dispatch(endLoading()),
  dispatchApplyToBeDealer: (page, history) => dispatch(applyToBeDealer(false, page, history)),
});

OwnerInformation.propTypes = {
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
  dispatchSubmitSoftPullAquaApplication: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(OwnerInformation),
);
