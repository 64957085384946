import AirConditioningSystemEnum from '../assets/js/AirConditioningSystemEnum';
import { getIsPool, getTheme } from '../config/settings';
export const preQualificationFlow = 'preQualificationFlow';
export const loginFlow = 'loginFlow';
export const aquaFlow = 'aquaFlow';
export const wellsFargoFlow = 'wellsFargoFlow';
export const microfFlow = 'microfFlow';
export const greenskyContractorEnrollmentFlow = 'greenskyContractorEnrollmentFlow';
export const contractorEnrollmentFlow = 'contractorEnrollmentFlow';
export const contractorEnrollmentAtWaterGSFlow = 'contractorEnrollmentAtWaterGSFlow';
export const rheemFlow = 'rheemFlow';
export const powerPayPreQualificationFlow = 'powerPayPreQualificationFlow';
export const powerPayFlow = 'powerPayFlow';
export const mosaicPreQualificationFlow = 'mosaicPreQualificationFlow';
export const mosaicFlow = 'mosaicFlow';
export const foundationPreQualificationFlow = 'foundationPreQualificationFlow';
export const foundationFlow = 'foundationFlow';
export const greenSkyPreQualificationFlow = 'greenSkyPreQualificationFlow';
export const greenSkyFlow = 'greenSkyFlow';
export const enerBankPreQualificationFlow = 'enerBankPreQualificationFlow';
export const enerBankFlow = 'enerBankFlow';
export const ftlPreQualificationFlow = 'ftlPreQualificationFlow';
export const ftlFlow = 'ftlFlow';
export const atWaterPreQualificationFlow = 'atWaterPreQualificationFlow';
export const atWaterFlow = 'atWaterFlow';
export const atWaterGreenSkyPreQualificationFlow = 'atWaterGreenSkyPreQualificationFlow';
export const atWaterGreenSkyFlow = 'atWaterGreenSkyFlow';
export const upgradePreQualificationFlow = 'upgradePreQualificationFlow';
export const defaultFlow = preQualificationFlow;

export const initialState = {
  navi: {
    version: '5.3.9', // !IMPORTANT: the version value should be changed before the deploy

    flowName: defaultFlow,

    /***** FLOWS *****/
    preQualificationFlowCurrentPage: 0,
    loginFlowCurrentPage: 0,
    aquaFlowCurrentPage: 0,
    microfFlowCurrentPage: 0,
    greenskyContractorEnrollmentFlowCurrentPage: 0,
    contractorEnrollmentFlowCurrentPage: 0,
    contractorEnrollmentAtWaterGSFlowCurrentPage: 0,
    rheemFlowCurrentPage: 0,
    wellsFargoFlowCurrentPage: 0,
    powerPayFlowCurrentPage: 0,
    mosaicPreQualificationFlowCurrentPage: 0,
    mosaicFlowCurrentPage: 0,
    foundationFlowCurrentPage: 0,
    foundationPreQualificationFlowCurrentPage: 0,
    greenSkyFlowCurrentPage: 0,
    enerBankPreQualificationFlowCurrentPage: 0,
    enerBankFlowCurrentPage: 0,
    ftlPreQualificationFlowCurrentPage: 0,
    ftlFlowCurrentPage: 0,
    appFtlFlowCurrentPage: 0,
    atWaterPreQualificationFlowCurrentPage: 0,
    atWaterFlowCurrentPage: 0,
    atWaterGreenSkyPreQualificationFlow: 0,
    atWaterGreenSkyFlowCurrentPage: 0,
    upgradePreQualificationFlow: 0,
    upgradePreQualificationFlowCurrentPage: 0,
    /***** END FLOWS *****/
  },

  theme: {
    /* ----------------- THEME -----------------
      Microf: microf;
      Rheem: rheem;
      Optimus: optimus;
    */
    theme: getTheme(),
    /* ----------------- END THEME ----------------- */
  },

  preQualificationInformation: {
    isEmailUnique: null,
    isConsumer: null,
    score: null,

    email: null,
    password: null,
    borrowedAmount: null,
    grossAmount: null,
    jobPrice: null,
    firstName: null,
    middleName: null,
    lastName: null,
    phoneNumber: null,
    street: null,
    city: null,
    zipcode: null,
    state: null,
    ssnLastDigits: null,
    birthday: null,
    isHomeowner: null,
    airConditioningSystem: AirConditioningSystemEnum.AIR_CONDITION_OR_HEATING_SYSTEM.id,
    dealerUserId: null,
    prequalificationId: null,
    productType: null,
    userEmailAlreadyExist: false,

    verifyAddressChecked: false,
    continueChecked: false,

    externalReferenceId: null,

    upgradeOfferSelected: false,

    isUpgrade: false,
  },

  powerPayInformation: {
    appDriversLicenseNumber: null,
    appDriversLicenseState: null,
    appEmployerName: null,
    appEmployerZipCode: null,
    appEmploymentMonths: null,
    appEmploymentType: null,
    appEmploymentYears: null,
    appFullSSN: null,
    appFullSsn: null,
    appGrossAnnualIncome: null,
    borrowedAmount: null,
    coAppDriversLicenseNumber: null,
    coAppDriversLicenseState: null,
    coAppEmployerName: null,
    coAppEmployerZipCode: null,
    coAppEmploymentMonths: null,
    coAppEmploymentType: null,
    coAppEmploymentYears: null,
    coAppFullSsn: null,
    coAppGrossAnnualIncome: null,
    coAppIdNumber: null,
    consent: false,
    grossYearlyIncome: null,
    hasCoApp: false,
    isAppMilitaryActive: null,
    isAppUsCitizen: null,
    isCoAppMilitaryActive: null,
    isCoAppUsCitizen: null,
    isHomeowner: null,
    isReview: false,
    propertyType: null,
    submited: null,
  },

  wellsFargoInformation: {
    airConditioningSystem: AirConditioningSystemEnum.AIR_CONDITION_OR_HEATING_SYSTEM.id,
    borrowedAmount: null,
    city: null,
    coAppCity: null,
    coAppEmail: null,
    coAppFirstName: null,
    coAppLastName: null,
    coAppMiddleInitial: null,
    coAppPoBox: null,
    coAppState: null,
    coAppStreet: null,
    coAppZipCode: null,
    email: null,
    firstName: null,
    hasCoApp: null,
    isReview: null,
    isSameAddress: null,
    lastName: null,
    middleName: null,
    password: null,
    phoneNumber: null,
    poBox: null,
    state: null,
    street: null,
    terms: false,
    zipcode: null,
    redirectUrl: null,
  },

  aquaInformation: {
    submited: null,
    ownOrRent: null,
    hvacMonthlyPayment: null,
    homeValue: null,
    hvacInstallmentDate: null,
    hasCoApp: null,
    appIdType: null,
    appDriversLicenseState: null,
    appIdNumber: null,
    appIdIssueDate: null,
    appIdExpirationDate: null,
    appFullSsn: null,
    appIncomeSource: null,
    appEmployerName: null,
    appEmploymentPosition: null,
    appEmploymentYears: null,
    appEmploymentMonths: null,
    appEmployerPhoneNumber: null,
    appSalaryIncome: null,
    appOtherSourcesOfIncome: null,
    appAdditionalIncome: null,
    appCoAppRelationship: null,
    coAppFirstName: null,
    coAppMiddleInitial: null,
    coAppLastName: null,
    coAppPhoneNumber: null,
    coAppEmail: null,
    coAppStreet: null,
    coAppCity: null,
    coAppZipCode: null,
    coAppState: null,
    coAppDoB: null,
    coAppIdType: null,
    coAppDriversLicenseState: null,
    coAppIdNumber: null,
    coAppIdIssueDate: null,
    coAppIdExpirationDate: null,
    coAppFullSsn: null,
    coAppIncomeSource: null,
    coAppEmployerName: null,
    coAppEmploymentPosition: null,
    coAppEmploymentYears: null,
    coAppEmploymentMonths: null,
    coAppEmployerPhoneNumber: null,
    coAppSalaryIncome: null,
    coAppOtherSourcesOfIncome: null,
    coAppAdditionalIncome: null,
    isSameAddress: null,
    isReview: null,
    isCoAppEmployed: null,
    isEmployed: null,
    hasAppOtherIncome: null,
    hasCoAppOtherIncome: null,
    selectedApplicationPromotion: null,
    applicantAddress: null,
    borrowedAmount: null,
  },

  rheemInformation: {
    submited: null,
    totalPrice: null,
    requestedLoanAmount: null,
    appGrossAnnualIncome: null,
    homeownerInsurance: null,
    hasCoApp: null,
    appFullSSN: null,
    coAppFirstName: null,
    coAppMiddleInitial: null,
    coAppLastName: null,
    coAppPhoneNumber: null,
    isSameAddress: null,
    coAppStreet: null,
    coAppCity: null,
    coAppZipCode: null,
    coAppState: null,
    coAppDoB: null,
    coAppFullSsn: null,
    coAppEmail: null,
    coApplicantGrossAnnualIncome: null,
    isReview: null,
    appConsent: null,
    coAppConsent: null,
    selectedLeasingOffer: null,
    applicantAddress: null,
    submittedToOracle: null,
    done: null,
    isHomeowner: null,
  },

  microfInformation: {
    airConditioningSystem: null,
    totalPrice: null,
    requestedLoanAmount: null,
    whTotalPrice: null,
    whRequestedLoanAmount: null,
    hvacSystem: null,
    hvacTonnage: null,
    hvacSystemType: null,
    hvacHeatType: null,
    waterHeater: null,
    secondWaterHeater: null,
    installmentDate: null,
    secondHvacSystem: null,
    secondHvacTonnage: null,
    ownOrRent: null,
    homeownerInsurance: null,
    hasCoApp: null,
    appIdType: null,
    appIdNumber: null,
    appMonthlyNetIncome: null,
    appBankAccountNumber: null,
    appBankAccountRoutingNumber: null,
    appFullSSN: null,
    coAppFirstName: null,
    coAppMiddleInitial: null,
    coAppLastName: null,
    coAppPhoneNumber: null,
    isSameAddress: null,
    coAppStreet: null,
    coAppCity: null,
    coAppZipCode: null,
    coAppState: null,
    coAppIdType: null,
    coAppIdNumber: null,
    coAppDoB: null,
    coAppFullSsn: null,
    coAppMonthlyNetIncome: null,
    coAppBankAccountNumber: null,
    coAppBankAccountRoutingNumber: null,
    coAppEmail: null,
    isReview: null,
    appConsent: null,
    coAppConsent: null,
    selectedLeasingOffer: null,
    verifyBankInformation: null,
    verifyBankFirstName: null,
    verifyBankLastName: null,
    verifyBankAccountNumber: null,
    verifyBankAccountRoutingNumber: null,
    verifyEmail: null,
    verifyCoAppBankInformation: null,
    verifyCoAppBankFirstName: null,
    verifyCoAppBankLastName: null,
    verifyCoAppBankAccountNumber: null,
    verifyCoAppBankAccountRoutingNumber: null,
    verifyCoAppEmail: null,
    applicantAddress: null,
    systemType: '',
    systemTonnage: '',
    secondSystemType: '',
    secondSystemTonnage: '',
    hasSecondSystem: false,
    thirdSystemType: '',
    thirdSystemTonnage: '',
    hasThirdSystem: false,
    fourthSystemType: '',
    fourthSystemTonnage: '',
    hasFourthSystem: false,
    submittedToOracle: null,
    done: null,
    microfOnly: null,
    isHomeowner: null,
    isServiceFinance: null,
    isFTL: null,
    isPartnerApi: null,
    hasSecondHvac: null,
    hasSecondWaterHeater: null,
    applicantConsent: null,
    coApplicantConsent: null,
    registerAsConsumer: null,
    decisionEngineResubmit: null,
  },

  foundationInformation: {
    appDriversLicenseNumber: null,
    appDriversLicenseState: null,
    appEmployerName: null,
    appEmploymentMonths: null,
    appEmploymentStatus: null,
    appEmploymentYears: null,
    appOccupation: null,
    appFullSSN: null,
    appFullSsn: null,
    appGrossIncome: null,
    appIncomeFrequency: null,
    appOtherSourcesOfIncome: null,
    appOtherIncomeType: null,
    appAdditionalIncome: null,
    appEstimatedsAnnualGrosIncome: null,
    borrowedAmount: null,
    coAppDriversLicenseNumber: null,
    coAppDriversLicenseState: null,
    coAppEmployerName: null,
    coAppEmploymentMonths: null,
    coAppEmploymentStatus: null,
    coAppEmploymentYears: null,
    coAppFullSsn: null,
    coAppGrossIncome: null,
    coAppIncomeFrequency: null,
    coAppEstimatedsAnnualGrosIncome: null,
    hasCoAppOtherIncome: null,
    coAppOtherSourcesOfIncome: null,
    coAppOtherIncomeType: null,
    coAppAdditionalIncome: null,
    coAppIdNumber: null,
    consent: false,
    grossYearlyIncome: null,
    hasCoApp: false,
    isHomeowner: null,
    isReview: false,
    submited: null,
    appProductType: null,
    saleAmount: null,
    downPayment: null,
    loanAmountRequested: null,
    loanOption: null,
    ownership: null,
    propertyMonthlyPayment: null,
    propertyYears: null,
    propertyMonths: null,
    applicantOwnership: null,
    applicantPropertyYears: null,
    applicantPropertyMonths: null,
    applicantPropertyMonthlyPayment: null,
    applicantStreet: null,
    applicantApartmentNumber: null,
    applicantCity: null,
    applicantState: null,
    applicantZipcode: null,
    isInstallSameAddress: null,
    isSameAddress: null,
    coApplicantOwnership: null,
    coApplicantPropertyMonths: null,
    coApplicantPropertyYears: null,
    coApplicantPropertyMonthlyPayment: null,
  },

  greenSkyApplicationInformation: {
    consent: null,
    merchantId: null,
    allPlans: null,
    planId: null,
    borrowedAmount: null,
    appEmail: null,
    appSpanishConsent: null,
    appMobileNumber: null,
    appMobileNumberConsent: null,
    appSsn: null,
    hasCoApp: null,
    appGrossAnnualIncome: null,
    isAppMarried: null,
    isCoAppSpouse: null,
    coAppFirstName: null,
    coAppMiddleInitial: null,
    coAppLastName: null,
    coAppEmail: null,
    coAppSpanishConsent: null,
    coAppPhoneNumber: null,
    coAppPhoneNumberConsent: null,
    coAppDoB: null,
    coAppSsn: null,
    isSameAddress: null,
    coAppStreet: null,
    coAppApartmentNumber: null,
    coAppCity: null,
    coAppState: null,
    coAppZipCode: null,
    coAppGrossAnnualIncome: null,
    isCoAppMarried: null,
    applicantSubmitConsent: null,
    coApplicantSubmitConsent: null,
    isReview: null,
    applicantSpouseFirstName: null,
    applicantSpouseMiddleInitial: null,
    applicantSpouseLastName: null,
    applicantSpouseApartmentNumber: null,
    applicantSpouseStreet: null,
    applicantSpouseCity: null,
    applicantSpouseZipCode: null,
    applicantSpouseState: null,
    coApplicantSpouseFirstName: null,
    coApplicantSpouseMiddleInitial: null,
    coApplicantSpouseLastName: null,
    coApplicantSpouseApartmentNumber: null,
    coApplicantSpouseStreet: null,
    coApplicantSpouseCity: null,
    coApplicantSpouseZipCode: null,
    coApplicantSpouseState: null,
    isSameAsInstalationAddress: null,
    primaryAddressApartmentNumber: null,
    primaryAddressStreet: null,
    primaryAddressCity: null,
    primaryAddressZipCode: null,
    primaryAddressState: null,
    isAccountCreated: null,
    disclosureSubmit: null,
    disclosureAccept: null,
    disclosureFooter: null,
  },

  ftlInformation: {
    appAnnualIncome: null,
    appEmployerName: null,
    appEmploymentMonths: null,
    appEmploymentStatus: null,
    appEmploymentYears: null,
    appFullSSN: null,
    appOccupation: null,
    applicantApartmentNumber: null,
    applicantCity: null,
    applicantState: null,
    applicantStreet: null,
    applicantZipcode: null,
    borrowedAmount: null,
    coAppAnnualIncome: null,
    coAppApartmentNumber: null,
    coAppCity: null,
    coAppDoB: null,
    coAppEmail: null,
    coAppEmployerName: null,
    coAppEmploymentMonths: null,
    coAppEmploymentStatus: null,
    coAppEmploymentYears: null,
    coAppFirstName: null,
    coAppFullSsn: null,
    coAppLastName: null,
    coAppMiddleInitial: null,
    coAppOccupation: null,
    coAppPhoneNumber: null,
    coAppState: null,
    coAppStreet: null,
    coAppZipCode: null,
    consent: null,
    hasCoApp: null,
    isAppEmployed: null,
    isCoAppEmployed: null,
    isCoAppSameAddress: null,
    isReview: null,
    isSameAddress: null,
    projectType: null,
  },

  atWaterApplicationInformation: {
    consent: null,
    merchantId: null,
    allPlans: null,
    planId: null,
    borrowedAmount: null,
    appEmail: null,
    appSpanishConsent: null,
    appMobileNumber: null,
    appMobileNumberConsent: null,
    appSsn: null,
    hasCoApp: null,
    appGrossAnnualIncome: null,
    isAppMarried: null,
    isCoAppSpouse: null,
    coAppFirstName: null,
    coAppMiddleInitial: null,
    coAppLastName: null,
    coAppEmail: null,
    coAppSpanishConsent: null,
    coAppPhoneNumber: null,
    coAppPhoneNumberConsent: null,
    coAppDoB: null,
    coAppSsn: null,
    isSameAddress: null,
    coAppStreet: null,
    coAppApartmentNumber: null,
    coAppCity: null,
    coAppState: null,
    coAppZipCode: null,
    coAppGrossAnnualIncome: null,
    isCoAppMarried: null,
    applicantSubmitConsent: null,
    coApplicantSubmitConsent: null,
    isReview: null,
    applicantSpouseFirstName: null,
    applicantSpouseMiddleInitial: null,
    applicantSpouseLastName: null,
    applicantSpouseApartmentNumber: null,
    applicantSpouseStreet: null,
    applicantSpouseCity: null,
    applicantSpouseZipCode: null,
    applicantSpouseState: null,
    coApplicantSpouseFirstName: null,
    coApplicantSpouseMiddleInitial: null,
    coApplicantSpouseLastName: null,
    coApplicantSpouseApartmentNumber: null,
    coApplicantSpouseStreet: null,
    coApplicantSpouseCity: null,
    coApplicantSpouseZipCode: null,
    coApplicantSpouseState: null,
    isSameAsInstalationAddress: null,
    primaryAddressApartmentNumber: null,
    primaryAddressStreet: null,
    primaryAddressCity: null,
    primaryAddressZipCode: null,
    primaryAddressState: null,
    isAccountCreated: null,
    programsAndProducts: null,
    productId: null,
    salesAmount: null,
    downPayment: null,
    amountFinanced: null,
    state: null,
    programId: null,
    applicantIdType: null,
    idNumber: null,
    idIssuedState: null,
    appIdIssueDate: null,
    appIdExpirationDate: null,
    isUsCitizen: null,
    coAppIdType: null,
    coAppIdNumber: null,
    coAppIdIssuedState: null,
    coAppIdIssueDate: null,
    coAppIdExpirationDate: null,
    coAppEmployerName: null,
    coAppGrossMonthlyIncome: null,
    loanDecisionResponse: null,
  },

  softPull: {
    error: null,
    applicationSubmitDTO: null,
    applicationSubmitDTO2: null,
    validationCode: null,
    samlResponse: null,
    transactionHistory: null,
    applicationsHistory: {
      dealerInformationDTOs: [],
      prequalificationInfoDTOs: null,
      softPullUrl: null,
      total: null,
    },
    unassignedPrequalificationInfoList: null,
    declinedConsumerApplications: null,
    selectedContractorUser: null,
    searchDealerUser: null,
    contractorUsers: null,
    searchOptionUsers: {
      sortAscending: true,
      pageLimit: 20,
      pageOffset: 0,
    },
    searchOptionContractors: {
      sortAscending: true,
      pageLimit: 20,
      pageOffset: 0,
    },
    contractorUser: {
      firstName: null,
      lastName: null,
      role: 'DEALER_CANDIDATE',
      dealer: null,
      lastLogin: null,
    },

    showAllSearchOptions: false,
    searchedApplications: null,
    searchApplicationsCriteria: {
      assignmentStatus: 'Unassigned',
      sortAscending: true,
      pageOffset: 0,
      pageLimit: 20,
    },
    searchedDealers: null,
    searchDealerCriteria: {
      dealerName: '',
      state: '',
    },
    assignApplicationMessage: null,

    zipcodeFindAContractor: null,
    foundContractors: null,
    dealerSearchCriteriaResult: null,
    usersSearchCriteriaResult: null,

    internalUsers: null,
    newInternalUser: {
      id: 0,
      lastName: null,
      firstName: null,
      email: null,
      role: null,
      lastLogin: null,
      dealer: null,
      state: null,
      addedFrom: null,
    },

    newContractorUser: {
      role: null,
      lastName: null,
      firstName: null,
      email: null,
    },

    newRojosUser: {
      role: null,
      lastName: null,
      firstName: null,
      email: null,
      salesForceId: null,
    },

    softPullResponse: {
      score: null,
      prequalificationId: null,
      dealerUserId: null,
      consumerId: null,
      email: null,
      password: null,
      borrowedAmount: null,
      firstName: null,
      lastName: null,
      middleName: null,
      phoneNumber: null,
      street: null,
      city: null,
      zipcode: null,
      state: null,
      birthday: null,
      ssnLastDigits: null,
      airConditioningSystem: null,
    },

    softPullRequest: {
      email: null,
      password: null,
      ipAddress: null,
      borrowedAmount: null,
      firstName: null,
      lastName: null,
      middleName: null,
      phoneNumber: null,
      street: null,
      city: null,
      zipcode: null,
      state: null,
      birthday: null,
      ssnLastDigits: null,
      airConditioningSystem: null,
      dealerUserId: null,
      prequalificationId: null,

      ownOrRent: null,
      monthlyPayment: null,
      homeValue: null,
      installmentDate: null,
      idType: null,
      idNumber: null,
      idIssueDate: null,
      idExpirationDate: null,
      ssn: null,
      employerType: null,
      employerName: null,
      employmentPosition: null,
      employmentYears: null,
      employmentMonths: null,
      employerPhoneNumber: null,
      otherIncomeSource: null,
      otherIncomeSourceValue: null,
      relationshipWithCoapplicant: null,
      coapplicantFirstName: null,
      coapplicantLastName: null,
      coapplicantMiddleName: null,
      coapplicantPhoneNumber: null,
      coapplicantEmployerType: null,
      coapplicantEmployerName: null,
      coapplicantEmploymentPosition: null,
      coapplicantEmploymentYears: null,
      coapplicantEmploymentMonths: null,
      coapplicantEmployerPhoneNumber: null,
      coapplicantOtherIncomeSource: null,
      coapplicantOtherIncomeSourceValue: null,
      coapplicantStreet: null,
      coapplicantCity: null,
      coapplicantZipcode: null,
      coapplicantState: null,
      coapplicantBirthday: null,
      coapplicantSsn: null,
      selectedFinancier: null,
      bankAba: null,
      bankAccountNumber: null,
      coapplicantBankAba: null,
    },
    appProcessingStatus: null,
    isRheemSerialModelMapComplete: false,
    rheemSerialModelMap: null,
    infoValues: [
      {
        systemModel: '',
        systemSerialNumber: '',
      },
    ],
    hasModelRequestBeenSent: false,
    allTransactions: null,
    singleTransaction: null,
    transactionId: null,
    greenSkyApplication: null,
    submitCharge: {
      amount: null,
      creditCardUID: null,
      totalJobAmount: null,
      chargeType: null,
      invoiceNumber: null,
      txnCode: null,
    },
    submitRefundData: {
      appId: null,
      amount: null,
      parentTransactionId: null,
      creditCardUID: null,
      txnCode: null,
    },
    greenSkyOffersData: null,
    greenSkyLoanAgreement: null,
    isSpanish: false,
    ctaResponse: null,
    questionsResponse: null,
    refundAmount: null,
    ftlData: null,
    ftlOptionsResponse: null,
    homeownerDocuments: null,
    fundingInfoDocuments: null,
    ftlDistributors: null,
    ftlBodyRequest: null,
    ftlFetchProductsResponse: null,
    ftlGeneratePaperworkResponse: null,
    ftlUploadResponse: null,
    ftlFundingInfoResponse: null,
    updateAmountResponse: null,
    ftlSendEquipmentInfoResponse: null,
    trainingData: null,
    validateMerchantMessage: null,
    emailCustomerUrlResponse: null,
    dealers: null,
    upgradeResponse: null,
  },

  auth: {
    consumerId: null,
    userRights: null,
    error: null,
    user: {
      user: null,
      consumerUser: null,
      dealerUser: null,
      internalUser: null,
    },
    dealerId: null,
    dealerDTO: null,
    dealerUserId: null,
    did: null,
    aquaFinanceAssigned: null,
    isAuthenticated: false,
    appToken: null,
    aquaAppNo: null,
    appId: null,
    dealerName: null,
    lead_source: null,
    lp_url: null,
    isGreenSky: false,
    // SetYourPassword
    passwordToken: null,
    candidatePassword: null,
    passSet: false,
    passSetMessage: null,

    forgotPasswordMessage: null,
    passwordRetrievalDTO: {
      email: null,
      lastName: null,
    },
    track_id: null,
    click_id: null,

    version: { buildNumber: null, scmCommitNumber: null, version: null },
    showAbout: false,
    showProfile: false,
    showChangeYourPassword: false,
    utmVariables: null,
    isDealerIdCustomLink: false,
    customDealerId: null,
  },

  notification: {
    messageType: null,
    message: null,
    loading: false,
    loadingMessage: null,
  },

  greenskyInformation: {
    registerAsContractor: {
      ein: null,
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      recaptcha: null,
      recaptchaError: null,
      registered: null,
      registeredMessage: null,
      recaptchaKey: null,
    },
    contractorEnrollment: {
      userId: null,
      guid: null,
      ein: null,
      dealerName: null,
      dbaName: null,
      businessType: null,
      phoneNumber: null,
      website: null,
      propertyStreet: null,
      propertyCity: null,
      propertyZipcode: null,
      propertyState: null,
      sameMailingAddr: false,
      street: null,
      city: null,
      zipcode: null,
      state: null,
      lastName: null,
      firstName: null,
      phone: null,
      email: null,
    },
  },

  contractorEnrollment: {
    userId: null,

    applyForAquaProgram: false,
    applyForMicrofProgram: getIsPool() ? false : true,
    applyForPowerPayProgram: false,
    applyForMosaicProgram: false,
    applyForEnerBankProgram: false,
    applyForGreenSkyProgram: false,
    applyForFoundationProgram: false,
    applyForFTLProgram: false,
    applyForServiceFinanceProgram: getIsPool() ? true : false,
    ein: null,
    dealerName: null,
    yearsInBusiness: null,
    monthsInBusiness: null,
    businessType: null,
    distributor: null,
    hvacPartnersId: null,
    hvacLicenseNumber: null,
    stateOfIssue: null,
    hvacLicenseHolderName: null,
    stateControlNumber: null,
    bankAba: null,
    bankAccountNum: null,
    accountType: null,
    phone: null,
    fax: null,
    website: null,
    streetAddr: null,
    city: null,
    zipcode: null,
    state: null,
    mailingStreetAddr: null,
    mailingCity: null,
    mailingZip: null,
    mailingState: null,
    sameMailingAddr: false,

    businessManagerLastName: null,
    businessManagerFirstName: null,
    businessManagerPhone: null,
    businessManagerEmail: null,
    businessManagerStreet: null,
    businessManagerCity: null,
    businessManagerZIPCode: null,
    businessManagerState: null,
    businessManagerSSN: null,
    businessManagerOwnership: null,

    beneficialOwner1LastName: null,
    beneficialOwner1FirstName: null,
    beneficialOwner1Phone: null,
    beneficialOwner1Email: null,
    beneficialOwner1Street: null,
    beneficialOwner1City: null,
    beneficialOwner1ZIPCode: null,
    beneficialOwner1State: null,
    beneficialOwner1SSN: null,
    beneficialOwner1Ownership: null,
    beneficialOwner2LastName: null,
    beneficialOwner2FirstName: null,
    beneficialOwner2Phone: null,
    beneficialOwner2Email: null,
    beneficialOwner2Street: null,
    beneficialOwner2City: null,
    beneficialOwner2ZIPCode: null,
    beneficialOwner2State: null,
    beneficialOwner2SSN: null,
    beneficialOwner2Ownership: null,
    beneficialOwner3LastName: null,
    beneficialOwner3FirstName: null,
    beneficialOwner3Phone: null,
    beneficialOwner3Email: null,
    beneficialOwner3Street: null,
    beneficialOwner3City: null,
    beneficialOwner3ZIPCode: null,
    beneficialOwner3State: null,
    beneficialOwner3SSN: null,
    beneficialOwner3Ownership: null,
    beneficialOwner4LastName: null,
    beneficialOwner4FirstName: null,
    beneficialOwner4Phone: null,
    beneficialOwner4Email: null,
    beneficialOwner4Street: null,
    beneficialOwner4City: null,
    beneficialOwner4ZIPCode: null,
    beneficialOwner4State: null,
    beneficialOwner4SSN: null,
    beneficialOwner4Ownership: null,
  },

  contractorEnrollmentAtWaterGS: {
    userId: null,
    guid: null,
    ein: null,
    dealerName: null,
    dbaName: null,
    businessType: null,
    phoneNumber: null,
    website: null,
    propertyStreet: null,
    propertyCity: null,
    propertyZipcode: null,
    propertyState: null,
    sameMailingAddr: false,
    street: null,
    city: null,
    zipcode: null,
    state: null,
    lastName: null,
    firstName: null,
    title: null,
    workPhone: null,
    mobilePhone: null,
    email: null,

    primaryBusinessCategory: null,
    otherBusinessCategory: null,
    businessLegalName: null,
    websiteBusinessUrl: null,
    howDidYouHearAboutGreenSky: null,
    isOwner: null,

    federalTaxId: null,
    programId: null,
    allNames: null,
    inBusinessSince: null,
    referredBy: null,
    businessStructure: null,
    typeOfService: null,
    annualConsumerSalesRevenue: null,
    annualConsumerFinanceVolume: null,
    averageTicket: null,

    mailingStreet: null,
    mailingCity: null,
    mailingState: null,
    mailingZipcode: null,

    owners: null,

    primaryCustomerFirstName: null,
    primaryCustomerLastName: null,
    primaryCustomerEmail: null,
    primaryCustomerWorkPhone: null,
    primaryCustomerMobilePhone: null,

    primaryAccountFirstName: null,
    primaryAccountLastName: null,
    primaryAccountEmail: null,
    primaryAccountWorkPhone: null,
    primaryAccountMobilePhone: null,

    primarySalesFirstName: null,
    primarySalesLastName: null,
    primarySalesEmail: null,
    primarySalesWorkPhone: null,
    primarySalesMobilePhone: null,

    nameOnCheckingAccount: null,
    bankRoutingNoumber: null,
    bankAccountNumber: null,
  },

  utile: {
    distributors: null,
    hcBrands: null,
    banks: null,
  },
  mosaicInformation: {
    isReview: null,
    consent: false,
    url: null,
    iFrameHeight: 1724,
    mosaicDecision: null,
  },

  enerBankInformation: {
    appDriversLicenseNumber: null,
    appDriversLicenseState: null,
    appEmployerName: null,
    appEmployerZipCode: null,
    appEmploymentMonths: null,
    appEmploymentType: null,
    appEmploymentYears: null,
    appFullSSN: null,
    appFullSsn: null,
    appGrossAnnualIncome: null,
    borrowedAmount: null,
    coAppDriversLicenseNumber: null,
    coAppDriversLicenseState: null,
    coAppEmployerName: null,
    coAppEmployerZipCode: null,
    coAppEmploymentMonths: null,
    coAppEmploymentType: null,
    coAppEmploymentYears: null,
    coAppFullSsn: null,
    coAppGrossAnnualIncome: null,
    coAppIdNumber: null,
    consent: false,
    grossYearlyIncome: null,
    hasCoApp: false,
    isAppMilitaryActive: null,
    isAppUsCitizen: null,
    isCoAppMilitaryActive: null,
    isCoAppUsCitizen: null,
    isHomeowner: null,
    isReview: false,
    propertyType: null,
    submited: null,
    deliveryMethod: null,
    applicantConsent: null,
    coApplicantConsent: null,
    programProduct: null,
    programProjectTypes: null,
    identityVerification: { applicant: [], coApplicant: [] },
  },
};
