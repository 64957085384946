import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { changePage, submitMindfire, changeMicrofInfoValue } from '../../redux/actions/index';
import { Container, Row, Col } from 'reactstrap';
import BackBtn from '../../components/UI/BackButton/BackButton';
import Button from '../../components/UI/Button/CustomButton';
import { reactGAPageview } from '../../helper';
import { MicrofFlowPageNumber } from './MicrofFlow';
import hvacTonnageEnum from '../../assets/js/HvacTonnageEnum';

const SystemSize = props => {
  const {
    email,
    dispatchChangePage,
    dispatchChangeMicrofInfoValue,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    hvacTonnage,
    children,
    isReview,
  } = props;
  const [isMessageHidden, setIsMessageHidden] = useState(
    hvacTonnage === null ? true : !!hvacTonnage,
  );

  useEffect(() => {
    reactGAPageview({
      path: '/ca_systemsize',
      trackerNames: [],
      title: 'ca_systemsize',
      did,
      dealerUserId,
    });

    // eslint-disable-next-line
  }, []);
  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={isReview ? MicrofFlowPageNumber.Review : MicrofFlowPageNumber.UserAddress}
        />
        <Col>{children}</Col>
        <Col>
          {Object.values(hvacTonnageEnum)
            .filter(obj => obj.weight && obj.size)
            .map((obj, index) => (
              <Row className="mb-3" key={index}>
                <Col xs="12">
                  <Button
                    className={hvacTonnage === obj.value ? '' : 'inactive'}
                    clickHandler={() => {
                      dispatchChangeMicrofInfoValue('hvacTonnage', obj.value);
                      setIsMessageHidden(true);
                    }}
                  >
                    <h6 className="mb-0 ">
                      <span className="fs-20 font-weight-bold">{obj.size}</span> <br /> {obj.weight}
                    </h6>
                  </Button>
                </Col>
              </Row>
            ))}
          <Row>
            <Col>
              <p className="text-danger" hidden={isMessageHidden}>
                You must select an option.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                type="button"
                title="Continue"
                clickHandler={() => {
                  if (hvacTonnage) {
                    dispatchChangePage(MicrofFlowPageNumber.HvacSystemType);
                    dispatchSubmitMindfire({
                      email: email,
                      lastPage: '4124',
                      dealerUserId: dealerUserId,
                      did: did,
                    });
                  } else {
                    setIsMessageHidden(false);
                  }
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  email: state.preQualificationInformation.email,
  isReview: state.microfInformation.isReview,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  hvacTonnage: state.microfInformation.hvacTonnage,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeMicrofInfoValue: (key, value) => dispatch(changeMicrofInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SystemSize);
