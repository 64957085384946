import { Field, Form, withFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import FtlRadioButtons from '../../Form/Radio/FtlRadioButtons';
import * as Yup from 'yup';
import { ftlSelectLoanMoreProgram, provideFundingInfo } from '../../../../redux/actions';
import { connect } from 'react-redux';
import ProvideFundingInfo from '../../Modal/ProvideFundingInfo';
import CustomInput from '../../Form/Input/CustomInput';
import FtlCheckbox from '../../Form/Checkbox/FtlCheckbox';

const ApprovedLoanMore = ({
  application,
  data,
  history,
  dispatchSelectProgram,
  loanDoc,
  ftlOptionsResponse,
  fundingInfoDocuments,
  dispatchProvideFundingInfo,
  ftlFundingInfoResponse,
  auth,
}) => {
  const isNewVersion = data.ftlInformation.newVersion === true;

  const [selected, setSelected] = useState(
    data && data.ftlPrograms && data.ftlPrograms.length > 0
      ? data.ftlPrograms.find(opt => opt.selected === true) !== undefined
      : false,
  );
  const [showProvidingInfoModal, setShowProvidingInfoModal] = useState(false);
  const [achNeeded, setAchNeeded] = useState(null);
  const [achInfo, setAchInfo] = useState(null);

  if (achNeeded === null && ftlOptionsResponse !== null && ftlOptionsResponse.achNeeded !== null) {
    setAchNeeded(ftlOptionsResponse.achNeeded);
  } else if (achNeeded === null) {
    setAchNeeded(data.ftlInformation.achNeeded ? data.ftlInformation.achNeeded : false);
  }

  if (achInfo === null && ftlOptionsResponse !== null && ftlOptionsResponse.achInfo !== null) {
    setAchInfo(ftlOptionsResponse.achInfo);
  } else if (
    achInfo === null &&
    ftlFundingInfoResponse !== null &&
    ftlFundingInfoResponse.ftlApplication.achInfo !== null
  ) {
    setAchInfo(ftlFundingInfoResponse.ftlApplication.achInfo);
  } else if (achInfo === null && data.achInformation !== null) {
    setAchInfo(data.achInformation);
  }

  const toggleModal = () => {
    setShowProvidingInfoModal(!showProvidingInfoModal);
  };

  let myOptions = [];

  myOptions = data.ftlPrograms.map(opt => ({
    key: opt.id,
    id: opt.id,
    value: opt.id,
    description: opt.description,
    name: opt.name,
    estimatedPayment: opt.estimatedPayment,
    contractorFee: opt.contractorFee,
    checked: opt.id,
    selected: opt.selected,
    feeAgreement: opt.feeAgreement,
  }));

  const clickHandler = value => {};

  const submitInfo = data => {
    setAchInfo(null);
    dispatchProvideFundingInfo(fundingInfoDocuments, application.id, data);
    toggleModal();
  };

  const back = () => {
    history.push('/portal/applications-under-review');
  };
  const selectAotherOption = () => {
    setSelected(false);
  };

  function MaskCharacter(str, mask, n = 1) {
    return ('' + str).slice(0, -n).replace(/./g, mask) + ('' + str).slice(-n);
  }

  useEffect(() => {
    if (ftlFundingInfoResponse) {
      setAchInfo(ftlFundingInfoResponse.ftlApplication.achInfo);
    }
  }, [ftlFundingInfoResponse]);

  const FtlOptionsForm = ({ values }) => {
    const consent = values.ftlOptions
      ? myOptions.find(option => option.id === values.ftlOptions).feeAgreement
      : '';

    return (
      <Form noValidate>
        <Field
          optionsComponent={true}
          component={FtlRadioButtons}
          name="ftlOptions"
          options={myOptions}
          clickHandler={clickHandler}
        />
        <input type="hidden" name="isConsent" />
        {consent && (
          <>
            <Field
              formFieldClassName="mt-10"
              component={FtlCheckbox}
              name="consent"
              id="consent"
              label={consent}
              labelClassName="bold mb-0 red"
            />
          </>
        )}
        <Row className="justify-content-center mt-4">
          <Col xs="12" md="auto">
            <Button type="submit">Next</Button>
          </Col>
        </Row>
      </Form>
    );
  };

  const FtlOptionsFormFormik = withFormik({
    mapPropsToValues: () => ({
      ftlOptions: '',
      isConsent: false,
      consent: false,
    }),

    validationSchema: Yup.object({
      ftlOptions: Yup.string().required('Please select an option.'),
      consent: Yup.boolean().when('isConsent', {
        is: true,
        then: Yup.boolean().oneOf([true], 'Please check to continue.'),
        otherwise: Yup.boolean(),
      }),
    }),

    handleSubmit: async values => {
      setSelected(true);
      dispatchSelectProgram(application.id, values.ftlOptions);
    },
  })(FtlOptionsForm);

  const FtlACHInfromationForm = () => (
    <Form>
      <Row className="mt-8">
        <Col xs="12" md="auto">
          <Field
            component={CustomInput}
            label="Bank account holder name"
            name="bankAccountHolderName"
            id="bankAccountHolderName"
            type="text"
            readOnly={true}
          />
          <Field
            component={CustomInput}
            label="Bank city and state"
            name="bankCityAndState"
            id="bankCityAndState"
            type="text"
            readOnly={true}
          />
          <Field
            component={CustomInput}
            label="Account number"
            name="accountNumber"
            id="accountNumber"
            type="text"
            readOnly={true}
          />
        </Col>
        <Col xs="12" md="auto">
          <Field
            component={CustomInput}
            label="Bank name"
            name="bankName"
            id="bankName"
            type="text"
            readOnly={true}
          />

          <Field
            component={CustomInput}
            label="Routing number"
            name="routingNumber"
            id="routingNumber"
            type="text"
            readOnly={true}
          />

          <Field
            component={CustomInput}
            label="Print Name"
            name="printName"
            id="printName"
            type="text"
            readOnly={true}
          />
        </Col>
      </Row>
    </Form>
  );

  const FtlACHInfromationFormFormik = withFormik({
    mapPropsToValues: () => ({
      bankAccountHolderName: achInfo ? achInfo.accountHolderName : '',
      bankName: achInfo ? achInfo.bankName : '',
      bankCityAndState: achInfo ? achInfo.bankCityAndState : '',
      routingNumber: achInfo ? MaskCharacter(achInfo.routingNumber, '*', 4) : '',
      accountNumber: achInfo ? MaskCharacter(achInfo.accountNumber, '*', 4) : '',
      printName: achInfo ? achInfo.printName : '',
    }),
  })(FtlACHInfromationForm);

  return (
    <>
      {!isNewVersion && (
        <div className="ftl-flow-section">
          {selected && data && data.ftlPrograms && data.ftlPrograms.length > 0 ? (
            <>
              <Row className="justify-content-center mb-3 text-align-center">
                <Col xs="auto" sm="8">
                  <p>
                    Your homeowner did not meet our typical financing standards but was approved
                    through one of our LoanMore Partners, Castle Credit.
                  </p>
                </Col>
              </Row>
              {ftlOptionsResponse && ftlOptionsResponse.pfoOptionSelected
                ? myOptions
                    .filter(program => program.id === ftlOptionsResponse.pfoOptionSelected)
                    .map(x => {
                      const hasFee = x.feeAgreement !== null;

                      return (
                        <Row key={x.id} className="justify-content-center">
                          <div className="inner-content ftl-options-table">
                            <table className="selected-program">
                              <tbody>
                                <tr>
                                  <td className={!hasFee ? 'green' : ''}>{x.name}</td>
                                  {x.description ? (
                                    <td className={!hasFee ? 'green' : ''}>{x.description}</td>
                                  ) : (
                                    <td className={!hasFee ? 'green' : 'grey'}>
                                      {`Standard Programs`}
                                    </td>
                                  )}
                                  {x.estimatedPayment && (
                                    <td className={!hasFee ? 'green' : ''}>{x.estimatedPayment}</td>
                                  )}
                                  <td className={!hasFee ? 'green' : 'grey'}>
                                    {x.contractorFee ? x.contractorFee + `%` : 'FREE'}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </Row>
                      );
                    })
                : myOptions
                    .filter(program => program.selected === true)
                    .map(x => {
                      const hasFee = x.feeAgreement !== null;
                      return (
                        <Row key={x.id} className="justify-content-center">
                          <div className="inner-content ftl-options-table">
                            <table className="selected-program">
                              <tbody>
                                <tr>
                                  <td className={!hasFee ? 'green' : ''}>{x.name}</td>
                                  {x.description ? (
                                    <td className={!hasFee ? 'green' : ''}>{x.description}</td>
                                  ) : (
                                    <td className={!hasFee ? 'green' : 'grey'}>
                                      {`Standard Programs`}
                                    </td>
                                  )}
                                  {x.estimatedPayment && (
                                    <td className={!hasFee ? 'green' : ''}>{x.estimatedPayment}</td>
                                  )}
                                  <td className={!hasFee ? 'green' : 'grey'}>
                                    {x.contractorFee ? x.contractorFee + `%` : 'FREE'}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </Row>
                      );
                    })}
              {selected && (
                <>
                  <Row className="justify-content-center">
                    <div className="inner-content">
                      <span className="select-different-program" onClick={selectAotherOption}>
                        SELECT A DIFFERENT PROGRAM
                      </span>
                    </div>
                  </Row>
                  {achNeeded === true && (
                    <>
                      {achInfo ? (
                        <>
                          <Row className="justify-content-center">
                            <div className="inner-content ftl-ach-info-table">
                              <FtlACHInfromationFormFormik />
                            </div>
                          </Row>
                          <Row className="justify-content-center mt-4">
                            <Col xs="12" md="auto">
                              <Button
                                type="button"
                                onClick={() => {
                                  toggleModal();
                                }}
                              >
                                Update Funding Info
                              </Button>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <Row className="justify-content-center mt-4">
                          <Col xs="12" md="auto">
                            <Button
                              type="button"
                              onClick={() => {
                                toggleModal();
                              }}
                            >
                              Provide Funding Info
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <Row className="justify-content-center mb-3 text-align-center">
                <Col xs="auto" sm="8">
                  <p>
                    <b>Please select a program below to offer to the homeowner.</b>
                  </p>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <div className="inner-content ftl-options-table">
                  <FtlOptionsFormFormik />
                </div>
              </Row>
            </>
          )}
        </div>
      )}
      <Row className="justify-content-end">
        <Col sm="12" xs="12" md="auto">
          <Button
            type="submit"
            color="secondary"
            className="rounded-pill w-100 mt-1"
            outline
            onClick={() => {
              back();
            }}
          >
            Back
          </Button>
        </Col>
        {selected && !isNewVersion && (
          <Col sm="12" xs="12" md="auto">
            <Button
              type="submit"
              color="primary"
              className="rounded-pill w-100 mt-1"
              outline
              onClick={() => {
                loanDoc(true);
              }}
            >
              Create Loan Documents
            </Button>
          </Col>
        )}
      </Row>
      <ProvideFundingInfo
        isOpen={showProvidingInfoModal}
        toggleModal={toggleModal}
        onSendClick={submitInfo}
        application={application}
        ftlOptionsResponse={achInfo}
      />
    </>
  );
};

const mapStateToProps = state => ({
  ftlOptionsResponse: state.softPull.ftlOptionsResponse,
  fundingInfoDocuments: state.softPull.fundingInfoDocuments,
  ftlFundingInfoResponse: state.softPull.ftlFundingInfoResponse,
  auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
  dispatchSelectProgram: (appId, optionId) => dispatch(ftlSelectLoanMoreProgram(appId, optionId)),
  dispatchProvideFundingInfo: (appId, optionId, data) =>
    dispatch(provideFundingInfo(appId, optionId, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApprovedLoanMore);
