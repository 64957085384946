import React from 'react';
import Loader from 'react-loader-spinner';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import Button from '../../components/UI/Button/CustomButton';
import Logo from '../../components/UI/Logo/Logo';
import ErrorModal from '../../components/UI/Modal/Notification';
import {
  getIsRheem,
  getIsOptimus,
  getTechnicalSupportNumber,
  getIsMicrof,
  getOptimusContractorRegistrationUrl,
  getIsPool,
} from '../../config/settings';
import { DEALER_PORTAL_TITLE } from '../../constants/document';
import {
  changeGSRegisterAsContractorInfoValue,
  setMicrofOnlyConsumerFlow,
  resetState,
  setMicrofOnlyConsumerSoftPullFlow,
} from '../../redux/actions';
import { setDocumentTitle } from '../../util/documentUtil';
import LoginForm from './LoginForm';
import './Login.scss';
import OptimusHeader from '../../components/UI/Header/OptimusHeader';
import PoolsHeader from '../../components/UI/Header/PoolsHeader';

const isRheem = getIsRheem();
const isMicrof = getIsMicrof();
const isOptimus = getIsOptimus();
const isPools = getIsPool();

const Login = props => {
  const {
    history,
    loading,
    dispatchChangeValue,
    dispatchSetMicrofOnlyConsumerFlow,
    dispatchSetMicrofOnlyConsumerSofPullFlow,
    dispatchResetState,
  } = props;

  setDocumentTitle(DEALER_PORTAL_TITLE);

  return (
    <div className="container-fluid">
      <Row>
        {getIsOptimus() && <OptimusHeader />}
        {getIsPool() && <PoolsHeader />}
        <Col xs="12" md="6" lg="8" className="px-0 left">
          <Row noGutters className="login-image">
            <Logo />
          </Row>
        </Col>
        <>
          {isRheem ? (
            <Col
              xs="12"
              md="6"
              lg="4"
              className={`sign-in-panel ${loading ? 'overflow-hidden' : ''}`}
            >
              <Row className="loader justify-content-center align-items-center" hidden={!loading}>
                <Loader type="Oval" color="#119c4a" height="80" width="80" />
              </Row>
              <div className="rheem-landing-message">
                <h2>
                  Thank you for your interest in the KwikComfort Simplicity Program: We are not
                  currently accepting applications.
                </h2>
              </div>
              <div className="rheem-landing-message">
                <h2>
                  Please contact us at 1-866-480-5945 if you have any questions or need additional
                  information.
                </h2>
              </div>
            </Col>
          ) : (
            <Col
              xs="12"
              md="6"
              lg="4"
              className={`sign-in-panel ${loading ? 'overflow-hidden' : ''}`}
            >
              <Row className="loader justify-content-center align-items-center" hidden={!loading}>
                <Loader type="Oval" color="#119c4a" height="80" width="80" />
              </Row>
              <div className="login-form">
                <h1>
                  <b>Sign In</b>
                </h1>
                <div>
                  {isRheem
                    ? 'Welcome to the KwikComfort Portal'
                    : isOptimus
                    ? 'Welcome to the Optimus Portal'
                    : isPools
                    ? 'Welcome to the AtWater Portal.'
                    : 'Welcome to the Microf Portal.'}
                </div>
                <div className="mb-5">Please enter your login information.</div>
                <LoginForm />
              </div>
              <div className="register-form">
                <Row className="mt-3">
                  <Col>
                    <div className="before-or" />
                  </Col>
                  <Col xs="2">
                    <div className="w-100 text-center">or</div>
                  </Col>
                  <Col>
                    <div className="after-or" />
                  </Col>
                </Row>
                <label className="mt-3">
                  <b>New Here?</b>
                </label>
                <Button
                  clickHandler={() => {
                    if (isOptimus) {
                      window.location.href = getOptimusContractorRegistrationUrl();
                    } else {
                      history.push('/RegisterAsContractor');
                      dispatchChangeValue('ein', null);
                      dispatchChangeValue('firstName', null);
                      dispatchChangeValue('lastName', null);
                      dispatchChangeValue('email', null);
                      dispatchChangeValue('phone', null);
                      dispatchChangeValue('recaptcha', null);
                    }
                  }}
                  className="register-contractor-button btn-secondary"
                  title="Register as Contractor"
                />
                {isMicrof && (
                  <Button
                    clickHandler={() => {
                      dispatchResetState({ history: null });
                      dispatchSetMicrofOnlyConsumerSofPullFlow(history);
                    }}
                    className="register-consumer-button"
                    title="Register as Consumer"
                  />
                )}

                <p className="info text-center mt-5">
                  For Technical Support {getTechnicalSupportNumber()}
                </p>
              </div>
            </Col>
          )}
        </>
      </Row>
      <ErrorModal />
    </div>
  );
};

const mapStateToProps = state => ({
  loading: state.notification.loading,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangeValue: (key, value) => dispatch(changeGSRegisterAsContractorInfoValue(key, value)),
  dispatchSetMicrofOnlyConsumerFlow: history => dispatch(setMicrofOnlyConsumerFlow(history)),
  dispatchSetMicrofOnlyConsumerSofPullFlow: history =>
    dispatch(setMicrofOnlyConsumerSoftPullFlow(history)),
  dispatchResetState: props => dispatch(resetState(props)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Login),
);
