import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import employmentTypes from '../../../assets/js/EmploymentTypeEnum';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import CustomInput from '../../../components/UI/Form/Input/CustomInput';
import CurrencyFormat from '../../../components/UI/Form/NumberFormat/CurrencyFormat';
import SelectWithHandleChange from '../../../components/UI/Form/Select/SelectWithHandleChange';
import { reactGAEvent, reactGAPageview } from '../../../helper';
import { changePage, changePowerPayInfoValue, submitMindfire } from '../../../redux/actions/index';
import { PowerPayFlowPageNumber } from './PowerPayFlow';
import { getCurrentENV } from '../../../config/settings';
import {
  EMPLOYER_NAME_TOOLTIP_TEXT,
  EMPLOYER_ZIPCODE_TOOLTIP_TEXT,
  EMPLOYMENT_TYPE_TOOLTIP_TEXT,
  OCCUPATION_TOOLTIP_TEXT,
  TIME_AT_EMPLOYER_TOOLTIP_TEXT,
  GROSS_ANNUAL_INCOME_TOOLTIP_TEXT,
} from '../../../constants/tooltipTexts';

const AppEmploymentDetails = props => {
  const {
    email,
    dispatchChangePage,
    dispatchChangePowerPayInfoValue,
    powerPayInformation,
    children,
    isReview,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
  } = props;
  let isEmployed = powerPayInformation.appEmploymentType === employmentTypes.Employed.value;
  let isSelfEmployed = powerPayInformation.appEmploymentType === employmentTypes.SelfEmployed.value;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_ppconsumerincome1',
      trackerNames: [],
      title: 'ca_ppconsumerincome1',
      dealerUserId,
      did,
    });
    // eslint-disable-next-line
  }, []);

  const changeValues = values => {
    isEmployed = values.appEmploymentType === employmentTypes.Employed.value;
    isSelfEmployed = values.appEmploymentType === employmentTypes.SelfEmployed.value;

    dispatchChangePowerPayInfoValue('appGrossAnnualIncome', values.appGrossAnnualIncome);
    dispatchChangePowerPayInfoValue(
      'appEmployerName',
      isEmployed || isSelfEmployed ? values.appEmployerName : null,
    );
    dispatchChangePowerPayInfoValue('appEmploymentType', values.appEmploymentType);
    dispatchChangePowerPayInfoValue(
      'appOccupation',
      isEmployed || isSelfEmployed ? values.appOccupation : null,
    );
    dispatchChangePowerPayInfoValue(
      'appEmploymentYears',
      isEmployed ? parseInt(values.appEmploymentYears) : null,
    );
    dispatchChangePowerPayInfoValue(
      'appEmploymentMonths',
      isEmployed ? parseInt(values.appEmploymentMonths) : null,
    );
    dispatchChangePowerPayInfoValue(
      'appEmployerZipCode',
      isEmployed || isSelfEmployed ? values.appEmployerZipCode : null,
    );
  };

  const AppEmploymentDetailsForm = ({ values }) => (
    <Form>
      <Field
        component={CurrencyFormat}
        label="Gross Annual Income (or Benefits) Amount"
        name="appGrossAnnualIncome"
        id="appGrossAnnualIncome"
        type="text"
        tooltipText={GROSS_ANNUAL_INCOME_TOOLTIP_TEXT}
      />
      <Field
        component={SelectWithHandleChange}
        label="Income (or Benefits) Type"
        id="appEmploymentType"
        name="appEmploymentType"
        type="select"
        selectValues={employmentTypes}
        selectFirstName="Select One"
        handleChange={e => changeValues({ ...values, appEmploymentType: e.target.value })}
        tooltipText={EMPLOYMENT_TYPE_TOOLTIP_TEXT}
      />
      {isEmployed ? (
        <>
          <Field
            component={CustomInput}
            label="Employer Name"
            name="appEmployerName"
            id="appEmployerName"
            type="text"
            tooltipText={EMPLOYER_NAME_TOOLTIP_TEXT}
          />
          <Field
            component={CustomInput}
            label="Occupation"
            name="appOccupation"
            id="appOccupation"
            type="text"
            tooltipText={OCCUPATION_TOOLTIP_TEXT}
          />
          <Row className="mt-4">
            <Col>
              <Label>
                <b>Years at employer</b>
              </Label>
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <Field
                component={CustomInput}
                placeholder="Years"
                formGroupClass="mt-0 mb-2"
                labelClassName="d-none"
                label="Years"
                name="appEmploymentYears"
                id="appEmploymentYears"
                type="number"
                min={0}
                tooltipText={TIME_AT_EMPLOYER_TOOLTIP_TEXT}
              />
            </Col>
            <Col sm="6">
              <Field
                component={CustomInput}
                placeholder="Months"
                formGroupClass="mt-0 mb-2"
                labelClassName="d-none"
                label="Months"
                name="appEmploymentMonths"
                id="appEmploymentMonths"
                type="number"
                min={0}
                tooltipText={TIME_AT_EMPLOYER_TOOLTIP_TEXT}
              />
            </Col>
          </Row>
          <Field
            component={CustomInput}
            label="Employer's Zip Code"
            name="appEmployerZipCode"
            id="appEmployerZipCode"
            type="text"
            maxLength={10}
            tooltipText={EMPLOYER_ZIPCODE_TOOLTIP_TEXT}
          />
        </>
      ) : null}

      {isSelfEmployed ? (
        <>
          <Field
            component={CustomInput}
            label="Company Name"
            id="appEmployerName"
            name="appEmployerName"
            type="text"
            tooltipText={EMPLOYER_NAME_TOOLTIP_TEXT}
          />
          <Field
            component={CustomInput}
            label="Title"
            id="appOccupation"
            name="appOccupation"
            type="text"
            tooltipText={OCCUPATION_TOOLTIP_TEXT}
          />
          <Field
            component={CustomInput}
            label="Company Zip Code"
            name="appEmployerZipCode"
            id="appEmployerZipCode"
            type="text"
            maxLength={10}
            tooltipText={EMPLOYER_ZIPCODE_TOOLTIP_TEXT}
          />
        </>
      ) : null}
      <Button type="submit" title={isReview ? 'Review' : 'Next'} />
    </Form>
  );

  const AppEmploymentDetailsFormFormik = withFormik({
    mapPropsToValues: () => ({
      appGrossAnnualIncome: powerPayInformation.appGrossAnnualIncome || '',
      appEmployerName: powerPayInformation.appEmployerName || '',
      appEmploymentType: powerPayInformation.appEmploymentType || '',
      appOccupation: powerPayInformation.appOccupation || '',
      appEmploymentYears:
        powerPayInformation.appEmploymentYears === 0
          ? 0
          : powerPayInformation.appEmploymentYears || '',
      appEmploymentMonths:
        powerPayInformation.appEmploymentMonths === 0
          ? 0
          : powerPayInformation.appEmploymentMonths || '',
      appEmployerZipCode: powerPayInformation.appEmployerZipCode || '',
    }),

    validationSchema: Yup.object({
      appGrossAnnualIncome: Yup.number()
        .required('Please enter your gross annual income.')
        .min(
          powerPayInformation.appFullSsn === '100000106' && getCurrentENV() !== 'PROD'
            ? 40000
            : 42000,
          'The income level entered does not meet the minimum requirement.',
        ),
      appEmployerName:
        isEmployed || isSelfEmployed
          ? Yup.string().required("Please enter your employer's name.")
          : Yup.string(),
      appEmploymentType: Yup.string().required('Please select the employment type.'),
      appOccupation:
        isEmployed || isSelfEmployed
          ? Yup.string().required('Please enter your occupation.')
          : Yup.string(),
      appEmploymentYears: isEmployed
        ? Yup.number()
            .moreThan(-1, 'Please enter a positive number.')
            .integer('Please enter an integer number.')
            .max(70, 'Must be less than or equal to 70.')
            .required('Please enter years.')
        : Yup.number(),
      appEmploymentMonths: isEmployed
        ? Yup.number()
            .moreThan(-1, 'Please enter a positive number.')
            .integer('Please enter an integer number.')
            .max(11, 'Must be less than or equal to 11.')
            .required('Please enter months.')
        : Yup.number(),
      appEmployerZipCode:
        isEmployed || isSelfEmployed
          ? Yup.string()
              .matches(/^\d{5}?$/, {
                message: 'Invalid zip code.',
                excludeEmptyString: true,
              })
              .required('Please enter a zip code.')
          : Yup.string(),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did: did,
        dealerUserId: dealerUserId,
      });
      changeValues(values);
      dispatchChangePage(
        isReview ? PowerPayFlowPageNumber.ApplicationReview : PowerPayFlowPageNumber.HasCoApplicant,
      );
      dispatchSubmitMindfire({
        email: email,
        company: values.appEmployerName,
        lastPage: '6013',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(AppEmploymentDetailsForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={
            isReview
              ? PowerPayFlowPageNumber.ApplicationReview
              : PowerPayFlowPageNumber.PropertyDetails
          }
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <AppEmploymentDetailsFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  powerPayInformation: state.powerPayInformation,
  email: state.preQualificationInformation.email,
  isReview: state.powerPayInformation.isReview,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangePowerPayInfoValue: (key, value) => dispatch(changePowerPayInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

AppEmploymentDetails.propTypes = {
  powerPayInformation: PropTypes.object,
  dispatchChangePage: PropTypes.func,
  dispatchChangePowerPayInfoValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppEmploymentDetails);
