import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  setFlow,
  getReCapthcaKey,
  changeContractorEnrollmentValue,
} from '../../redux/actions/index';
import Header from '../../components/UI/Header/Header';
import Section from '../../components/Section/Section';
import { contractorEnrollmentAtWaterGSFlow } from '../../redux/initialState';
import Flow from './_Flow';

const ContractorEnrollmentPools = ({
  state,
  history,
  contractorEnrollmentAtWaterGSFlowCurrentPage,
  dispatchSetFlow,
  flowName,
  dispatchChangeValue,
  dispatchGetReCapthcaKey,
  dispatchResetGreenSkyState,
  dispatchGetDistributors,
  ...props
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src =
      process.env.NODE_ENV === 'production' ? './js/static_wdp.js' : './js/test_static_wdp.js';
    script.async = true;

    const script2 = document.createElement('script');
    script2.type = 'text/javascript';
    script2.src =
      process.env.NODE_ENV === 'production'
        ? 'https://mpsnare.iesnare.com/snare.js'
        : 'https://ci-mpsnare.iovation.com/snare.js';
    script2.async = true;

    document.body.appendChild(script);
    document.body.appendChild(script2);

    dispatchSetFlow(contractorEnrollmentAtWaterGSFlow);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(script2);
    };

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <input type="hidden" id="fpBlackBox" />
      <input type="hidden" id="ioBlackBox" />

      <Header
        title={Flow[contractorEnrollmentAtWaterGSFlowCurrentPage || 0].props.title}
        pages={Flow.length - 1}
      />
      <Section Component={Flow[contractorEnrollmentAtWaterGSFlowCurrentPage || 0]} {...state} />
    </>
  );
};

const mapStateToProps = state => ({
  state,
  contractorEnrollmentAtWaterGSFlowCurrentPage:
    state.navi.contractorEnrollmentAtWaterGSFlowCurrentPage,
  flowName: state.navi.flowName,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetFlow: flow => dispatch(setFlow(flow)),
  dispatchChangeValue: (key, value) => dispatch(changeContractorEnrollmentValue(key, value)),
  dispatchGetReCapthcaKey: () => dispatch(getReCapthcaKey()),
});

ContractorEnrollmentPools.propTypes = {
  contractorEnrollmentAtWaterGSFlowCurrentPage: PropTypes.number.isRequired,
  dispatchSetFlow: PropTypes.func,
  score: PropTypes.number,
  flowName: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContractorEnrollmentPools);
