import React, { useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { reactGAEvent, reactGAPageview } from '../../helper';
import { WellsFargoFlowPageNumber } from './WellsFargoFlow';
import Button from '../../components/UI/Button/CustomButton';

const Approved = props => {
  const {
    email,
    dispatchChangePage,
    dealerUserId,
    did,
    dispatchSubmitMindfire,
    isConsumer,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/wf_approve',
      trackerNames: [],
      title: 'wf_approve',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const handleClick = () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Continue',
      did,
      dealerUserId,
    });
    dispatchSubmitMindfire({
      email: email,
      lastPage: '5012',
      dealerUserId: dealerUserId,
      did: did,
    });
    dispatchChangePage(WellsFargoFlowPageNumber.CreditConnect);
  };

  return (
    <Container>
      <Row className="justify-content-center mt-2">
        <Col xs="auto">
          <h5>Application Approved</h5>
        </Col>
      </Row>
      {!isConsumer ? (
        <>
          <Row className="justify-content-center mt-2">
            <Col xs="auto">
              <h5>Please hand device back to the contractor to continue</h5>
            </Col>
          </Row>
          <Row className="justify-content-center mt-5">
            <Col xs="auto">
              <h5>Contractor</h5>
            </Col>
          </Row>
          <Row className="justify-content-center mt-2">
            <Col xs="auto">
              <h5>Please press Next to continue</h5>
            </Col>
          </Row>
          <Row className="justify-content-center mt-4">
            <Col xs="12" sm="8" md="6" lg="4">
              <Button type="button" title="Next" clickHandler={handleClick} />
            </Col>
          </Row>
        </>
      ) : (
        <Row className="justify-content-center mt-5">
          <Col xs="12" md="8">
            <h5 className="text-center">
              You will need to contact your contractor to finalize your purchase and complete the
              transaction.
            </h5>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default Approved;
