import React from 'react';
import { IoMdCheckmark } from 'react-icons/io';
import { Col, Row } from 'reactstrap';

const Checkmark = ({ children }) => (
  <Row>
    <Col xs="auto">
      <IoMdCheckmark className="orange" />
    </Col>
    <Col>{children}</Col>
  </Row>
);

export default Checkmark;
