import { Field, Form, withFormik } from 'formik';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { formatDate } from '../../../../util/dateFormatter';
import MultiSelectIdentityVerification from '../../Form/Select/MultiSelectIdentityVerification';
import CustomDatePicker from '../../../../components/UI/Form/DatePicker/DatePicker';
import EquipmentRow from './EquipmentRow';
import { currencyFormat } from '../../../../helper';
import {
  changeSoftPullValue,
  ftlFetchProducts,
  ftlSendEquipmentInfo,
  ftlSetAmount,
} from '../../../../redux/actions';
import Checkbox from '../../Form/Checkbox/Checkbox';
import InputField from '../../../../components/UI/Form/Input/CustomInput';
import CompleteConfirmation from './CompleteConfirmation';
import TextArea from '../../Form/TextArea/TextArea';

const CompleteForm = props => {
  const {
    application,
    ftlData,
    ftlDistributors,
    dispatchFtlSendEquipmentInfo,
    ftlSendEquipmentInfoResponse,
    completeForm,
  } = props;

  const [isRequired, setIsRequired] = useState(false);
  const [selectedDistributor, setSelectedDistributor] = useState(null);
  const [selectedDistributorBrand, setSelectedDistributorBrand] = useState(null);
  const [brands, setBrands] = useState([]);
  const [infoValues, setInfoValues] = useState([]);
  const [jobDate, setJobDate] = useState(null);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [servicingDescription, setServicingDescription] = useState(null);

  const showEquipment = ftlData.ftlInformation.showEquipment === true;

  const manageEquipments = values => {
    let infoValuesTmp = [...infoValues];

    infoValuesTmp.forEach((infoValue, index) => {
      infoValue.systemMake = values[`systemMake${index}`];
      infoValue.systemModel = values[`systemModel${index}`];
      infoValue.systemSerialNumber = values[`systemSerialNumber${index}`];
    });

    infoValuesTmp.push({
      systemMake: selectedDistributorBrand,
      systemModel: '',
      systemSerialNumber: '',
    });

    setInfoValues(infoValuesTmp);
  };

  const manageEquipmentsTemp = values => {
    let infoValuesTmp = [...infoValues];

    infoValuesTmp.forEach((infoValue, index) => {
      infoValue.systemMake = values[`systemMake${index}`];
      infoValue.systemModel = values[`systemModel${index}`];
      infoValue.systemSerialNumber = values[`systemSerialNumber${index}`];
    });

    setInfoValues(infoValuesTmp);
  };

  const addMore = values => {
    if (values.completedDate !== '') {
      setJobDate(values.completedDate);
    }
    if (values.servicingDescription && values.servicingDescription !== '') {
      setServicingDescription(values.servicingDescription);
    }
    manageEquipments(values);
  };

  const addEmail = (email, values) => {
    if (values.servicingDescription && values.servicingDescription !== '') {
      setServicingDescription(values.servicingDescription);
    }
    if (email && email !== '') {
      setSelectedEmail(email);
    }
    if (values.completedDate !== '') {
      setJobDate(values.completedDate);
    }

    let infoValuesTmp = [...infoValues];

    infoValuesTmp.forEach((infoValue, index) => {
      infoValue.systemMake = values[`systemMake${index}`];
      infoValue.systemModel = values[`systemModel${index}`];
      infoValue.systemSerialNumber = values[`systemSerialNumber${index}`];
    });

    setInfoValues(infoValuesTmp);
  };

  const removeUnit = () => {
    if (infoValues.length > 1) {
      setInfoValues(infoValues.slice(0, -1));
    }
  };

  const getBrands = distributor => {
    setSelectedDistributor(distributor.value);
    const distributorBrands = ftlDistributors.find(x => x.id === distributor.value).brands;
    setBrands(distributorBrands);
    setInfoValues([]);
  };

  const setSelectedBrand = brand => {
    setSelectedDistributorBrand(brand.value);
    setInfoValues([
      {
        systemMake: brand.value,
        systemModel: '',
        systemSerialNumber: '',
      },
    ]);
  };

  const getPriorDateLimit = () => {
    let d = new Date();
    d.setMonth(d.getMonth() - 3);
    return d;
  };

  const CompleteProjectFormik = ({ values }) => {
    return (
      <Form noValidate className="fs-14">
        <Row className="p-15">
          <Col sm="7">
            <Row>
              <Col>
                <MultiSelectIdentityVerification
                  //errorMessage={isRequired && 'Please select an option.'}
                  label={'Where was the equipment or parts purchased?'}
                  className={''}
                  key={'distributors-dd'}
                  isMulti={false}
                  options={ftlDistributors.map((distributor, index) => ({
                    value: distributor.id,
                    label: distributor.name,
                  }))}
                  selectedValues={ftlDistributors
                    .filter(x => x.id === selectedDistributor)
                    .map((distributor, index) => ({
                      value: distributor.id,
                      label: distributor.name,
                    }))}
                  onChange={e => getBrands(e)}
                />
              </Col>
            </Row>
            {brands && brands.length > 0 && (
              <Row>
                <Col>
                  <MultiSelectIdentityVerification
                    //errorMessage={isRequired && 'Please select an option.'}
                    label={'What is the brand of the equipment being installed?'}
                    className={''}
                    key={'brands-dd'}
                    isMulti={false}
                    options={brands.map((brand, index) => ({
                      value: brand,
                      label: brand,
                    }))}
                    selectedValues={brands
                      .filter(x => x === selectedDistributorBrand)
                      .map((brand, index) => ({
                        value: brand,
                        label: brand,
                      }))}
                    onChange={e => setSelectedBrand(e)}
                  />
                </Col>
              </Row>
            )}
            {infoValues.length > 0 && (
              <Row className="mt-4">
                <label className="mb-m20">
                  <b>What equipment is being installed? (limit 6)</b>
                </label>
              </Row>
            )}
          </Col>
          <Row>
            <Col>
              {infoValues.length > 0 &&
                infoValues.map((_info, index) => <EquipmentRow index={index} key={index} />)}
              <Row className={'justify-content-center'}>
                {infoValues.length > 1 && (
                  <Button
                    type="button"
                    className={
                      'rounded-pill btn-secondary mr-2' + (isMobile ? ' mt-3 pl-5 pr-5' : '')
                    }
                    onClick={() => {
                      removeUnit();
                    }}
                  >
                    Remove
                  </Button>
                )}
                {infoValues.length > 0 && infoValues.length < 6 && (
                  <Button
                    type="button"
                    className={
                      'rounded-pill btn-primary mr-2' + (isMobile ? ' mt-3 pl-5 pr-5' : '')
                    }
                    onClick={() => {
                      addMore(values);
                    }}
                  >
                    Add More
                  </Button>
                )}
              </Row>
            </Col>
          </Row>
        </Row>
        <Row className="mt-4 pl-30 mb0">
          <label className="mb-m20">
            <b>Servicing description:</b>
          </label>
        </Row>
        <Row className="p-15">
          <Col>
            <Field
              formGroupClassName="mt-0 loan-doc-servicing-description"
              component={TextArea}
              label="Servicing description:"
              name="servicingDescription"
              id="servicingDescription"
              type="text"
              placeholder="Description..."
              maxLength={50}
            />
          </Col>
        </Row>
        <Row className="p-15">
          <Col>
            <Field
              component={CustomDatePicker}
              name="completedDate"
              id="completedDate"
              tooltipText={
                'Date must be before ' +
                formatDate(ftlData.ftlInformation.expiresOn, 'MM/DD/YYYY') +
                ', the expiration date of the approval.'
              }
              label="What date will this project be completed?"
            />
          </Col>
          <Col>
            <MultiSelectIdentityVerification
              errorMessage={isRequired && 'Please select an option.'}
              label={'Send certificate to:'}
              className={''}
              key={'emails-dd'}
              isMulti={false}
              options={ftlData?.ftlReceivers?.map((receiver, index) => ({
                value: receiver.id,
                label: receiver.email,
              }))}
              selectedValues={ftlData?.ftlReceivers
                ?.filter(x => x.id === selectedEmail)
                .map((receiver, index) => ({
                  value: receiver.id,
                  label: receiver.email,
                }))}
              onChange={e => {
                addEmail(e.value, values);
              }}
            />
          </Col>
        </Row>
        <Row className="justify-content-end">
          <Col sm="12" xs="12" md="auto">
            <Button
              type="button"
              color="secondary"
              className="rounded-pill w-100 mt-1"
              outline
              onClick={() => {
                completeForm(false);
              }}
            >
              Back
            </Button>
          </Col>
          {selectedDistributorBrand && (
            <Col sm="12" xs="12" md="auto">
              <Button type="submit" color="primary" className="rounded-pill w-100 mt-1" outline>
                Send
              </Button>
            </Col>
          )}
        </Row>
      </Form>
    );
  };

  const CompleteProjectFormFormik = withFormik({
    mapPropsToValues: () => ({
      ...infoValues.reduce(
        (previous, current, index) =>
          Object.assign(previous, {
            [`systemMake${index}`]: current.systemMake,
            [`systemModel${index}`]: current.systemModel,
            [`systemSerialNumber${index}`]: current.systemSerialNumber,
          }),
        {},
      ),
      completedDate: jobDate ? jobDate : '',
      servicingDescription: servicingDescription ? servicingDescription : '',
    }),

    validationSchema: Yup.object({
      completedDate: Yup.date()
        .required('Please enter the date.')
        .max(
          ftlData.ftlInformation.expiresOn,
          'Date must be earlier than ' + formatDate(ftlData.ftlInformation.expiresOn, 'MM/DD/YYYY'),
        )
        .min(
          formatDate(getPriorDateLimit(), 'MM/DD/YYYY'),
          'Date must be after ' + formatDate(getPriorDateLimit(), 'MM/DD/YYYY'),
        ),
      ...infoValues.reduce(
        (previous, _current, index) =>
          Object.assign(previous, {
            [`systemMake${index}`]: Yup.string().test({
              name: 'required',
              exclusive: false,
              message: 'System make is required.',
              test: function(value) {
                const systemModel = this.resolve(Yup.ref(`systemModel${index}`));
                const systemSerialNumber = this.resolve(Yup.ref(`systemSerialNumber${index}`));
                if (systemModel || systemSerialNumber || !index) return !!value;
                return true;
              },
            }),
            [`systemModel${index}`]: Yup.string().test({
              name: 'required',
              exclusive: false,
              message: 'System model is required.',
              test: function(value) {
                const systemMake = this.resolve(Yup.ref(`systemMake${index}`));
                const systemSerialNumber = this.resolve(Yup.ref(`systemSerialNumber${index}`));
                if (systemMake || systemSerialNumber || !index) return !!value;
                return true;
              },
            }),
            [`systemSerialNumber${index}`]: Yup.string()
              .test({
                name: 'required',
                exclusive: false,
                message: 'System serial number is required.',
                test: function(value) {
                  const systemMake = this.resolve(Yup.ref(`systemMake${index}`));
                  const systemModel = this.resolve(Yup.ref(`systemModel${index}`));
                  if (systemMake || systemModel || !index) return !!value;
                  return true;
                },
              })
              .test({
                name: 'emptyString',
                exclusive: false,
                message: 'Invalid Serial Number.',
                test: function(value) {
                  return value ? value.trim() !== '' : true;
                },
              })
              .test({
                name: 'allCharEqual',
                exclusive: false,
                message: 'Invalid Serial Number.',
                test: function(value) {
                  return value ? !/^(.)\1*$/.test(value.trim()) : true;
                },
              }),
          }),
        {},
      ),
    }),

    handleSubmit: values => {
      let selectedBrands = [];

      infoValues.forEach((_infoValue, index) => {
        if (
          values[`systemMake${index}`] &&
          values[`systemModel${index}`] &&
          values[`systemSerialNumber${index}`]
        )
          selectedBrands.push({
            brand: values[`systemMake${index}`],
            modelNumber: values[`systemModel${index}`],
            serialNumber: values[`systemSerialNumber${index}`],
          });
      });

      if (selectedEmail !== null && selectedEmail !== '') {
        setIsRequired(false);
        if (values.completedDate !== '') {
          setJobDate(values.completedDate);
        }
        if (values.servicingDescription && values.servicingDescription !== '') {
          setServicingDescription(values.servicingDescription);
        }
        manageEquipmentsTemp(values);

        const ftlBodyRequest = {
          equipment: selectedBrands,
          installationDate: values.completedDate,
          selectedDistributor: selectedDistributor,
          receiver: selectedEmail,
          serviceDescription: values.servicingDescription,
        };
        dispatchFtlSendEquipmentInfo(application.id, ftlBodyRequest);
      } else {
        setIsRequired(true);
        if (values.completedDate !== '') {
          setJobDate(values.completedDate);
        }
        if (values.servicingDescription && values.servicingDescription !== '') {
          setServicingDescription(values.servicingDescription);
        }
        manageEquipmentsTemp(values);
      }
    },
  })(CompleteProjectFormik);

  const CompleteProjectNoEquipmentFormik = ({ values }) => {
    return (
      <Form noValidate className="fs-14">
        <Row className="p-15">
          <Col>
            <Row>
              <Field
                formGroupClassName="mt-0 loan-doc-servicing-description"
                component={InputField}
                label="Servicing description:"
                name="servicingDescription"
                id="servicingDescription"
                type="text"
                placeholder="Description..."
                maxLength={50}
              />
            </Row>
          </Col>
        </Row>
        <Row className="p-15">
          <Col>
            <Field
              component={CustomDatePicker}
              name="completedDate"
              id="completedDate"
              tooltipText={
                'Date must be before ' +
                formatDate(ftlData.ftlInformation.expiresOn, 'MM/DD/YYYY') +
                ', the expiration date of the approval.'
              }
              label="What date will this project be completed?"
            />
          </Col>
          <Col>
            <MultiSelectIdentityVerification
              errorMessage={isRequired && 'Please select an option.'}
              label={'Send certificate to:'}
              className={''}
              key={'emails-dd'}
              isMulti={false}
              options={ftlData?.ftlReceivers?.map((receiver, index) => ({
                value: receiver.id,
                label: receiver.email,
              }))}
              selectedValues={ftlData?.ftlReceivers
                ?.filter(x => x.id === selectedEmail)
                .map((receiver, index) => ({
                  value: receiver.id,
                  label: receiver.email,
                }))}
              onChange={e => {
                addEmail(e.value, values);
              }}
            />
          </Col>
        </Row>
        <Row className="justify-content-end">
          <Col sm="12" xs="12" md="auto">
            <Button
              type="button"
              color="secondary"
              className="rounded-pill w-100 mt-1"
              outline
              onClick={() => {
                completeForm(false);
              }}
            >
              Back
            </Button>
          </Col>

          <Col sm="12" xs="12" md="auto">
            <Button type="submit" color="primary" className="rounded-pill w-100 mt-1" outline>
              Send
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  const CompleteProjectNoEquipmentFormFormik = withFormik({
    mapPropsToValues: () => ({
      servicingDescription: servicingDescription ? servicingDescription : '',
      completedDate: jobDate ? jobDate : '',
    }),

    validationSchema: Yup.object({
      servicingDescription: Yup.string().required('Please enter servicing description.'),
      completedDate: Yup.date()
        .required('Please enter the date.')
        .max(
          ftlData.ftlInformation.expiresOn,
          'Date must be earlier than ' + formatDate(ftlData.ftlInformation.expiresOn, 'MM/DD/YYYY'),
        )
        .min(
          formatDate(getPriorDateLimit(), 'MM/DD/YYYY'),
          'Date must be after ' + formatDate(getPriorDateLimit(), 'MM/DD/YYYY'),
        ),
    }),

    handleSubmit: values => {
      const ftlBodyRequest = {
        equipment: [],
        selectedDistributor: null,
        installationDate: values.completedDate,
        serviceDescription: values.servicingDescription,
        receiver: selectedEmail,
        repairsOnly: true,
      };

      dispatchFtlSendEquipmentInfo(application.id, ftlBodyRequest);
    },
  })(CompleteProjectNoEquipmentFormik);

  return (
    ftlData &&
    ftlData.ftlInformation &&
    ftlDistributors &&
    ftlDistributors.length > 0 && (
      <div className="app-panel">
        <div className="app-panel-body">
          <div className="title">
            <span>Application - {application.nameFirst + ' ' + application.nameLast}</span>
          </div>
          <div>
            {ftlSendEquipmentInfoResponse ? (
              <CompleteConfirmation application={application} data={ftlSendEquipmentInfoResponse} />
            ) : (
              <>
                <Row className="p-15">
                  <Col sm="12">
                    <p className="fs-25">
                      <b className="text-success">Project Completed!</b>{' '}
                      <b>
                        Confirm completion and send the homeowner the completion certificate via
                        text or email by clicking below.
                      </b>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col sm="4">
                    <Row className="p-15 fs-14 fw-500">
                      <Col>
                        <Row>
                          <Col>
                            <span>Application ID: {application.id}</span>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <span>
                              Applicant Name: {`${application.nameFirst} ${application.nameLast}`}
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <span>
                              Requested Amount:{' '}
                              {ftlData?.ftlInformation?.requestedAmount
                                ? currencyFormat(parseInt(ftlData.ftlInformation.requestedAmount))
                                : currencyFormat(parseInt(0))}
                            </span>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <span>Term: {ftlData.ftlInformation.term}</span>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <span>APR: {ftlData.ftlInformation.apr}</span>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <span>Monthly Payment: {ftlData.ftlInformation.monthlyPayment}</span>
                          </Col>
                        </Row>
                        <Row className="pt-25">
                          <Col>
                            <span>
                              Contractor Fee:{' '}
                              {ftlData?.ftlInformation?.contractorFee
                                ? currencyFormat(parseInt(ftlData.ftlInformation.contractorFee))
                                : currencyFormat(parseInt(0))}
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <span>
                              Deposit Amount:{' '}
                              {ftlData.ftlInformation.depositedAmount
                                ? currencyFormat(ftlData.ftlInformation.depositedAmount)
                                : currencyFormat(0)}
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="8">
                    {showEquipment ? (
                      <CompleteProjectFormFormik />
                    ) : (
                      <CompleteProjectNoEquipmentFormFormik />
                    )}
                  </Col>
                </Row>
              </>
            )}
          </div>
        </div>
      </div>
    )
  );
};

const mapStateToProps = state => ({
  ftlData: state.softPull.ftlData,
  ftlDistributors: state.softPull.ftlDistributors,
  auth: state.auth,
  ftlBodyRequest: state.softPull.ftlBodyRequest,
  ftlOptionsResponse: state.softPull.ftlOptionsResponse,
  ftlFetchProductsResponse: state.softPull.ftlFetchProductsResponse,
  ftlUploadResponse: state.softPull.ftlUploadResponse,
  ftlSendEquipmentInfoResponse: state.softPull.ftlSendEquipmentInfoResponse,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangeSoftPullValue: (key, value) => dispatch(changeSoftPullValue(key, value)),
  dispatchFtlFetchProducts: (appId, reqAmount) => dispatch(ftlFetchProducts(appId, reqAmount)),
  dispatchFtlSetAmount: (appId, reqAmount) => dispatch(ftlSetAmount(appId, reqAmount)),
  dispatchFtlSendEquipmentInfo: (appId, data) => dispatch(ftlSendEquipmentInfo(appId, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompleteForm);
