const loanOptionEnum = {
  CLOSED_END: { value: 'CE', name: 'CE – Closed-end/Installment' },
  REVOLVING: { value: 'REV', name: 'REV – Revolving Line of Credit' },
};

export const findLoanOptionEnumName = value => {
  for (let key in loanOptionEnum) {
    let loanOption = loanOptionEnum[key];
    if (loanOption.value === value) {
      return loanOption.name;
    }
  }
  return null;
};

export default loanOptionEnum;
