const AirConditioningSystemEnum = {
  AIR_CONDITION_OR_HEATING_SYSTEM: { id: 'A', value: 'Air Conditioner or Heating System' },
  WATER_HEATER: { id: 'W', value: 'Water Heater' },
  AIR_CONDITION_OR_HEATING_SYSTEM_PLUS_WATER_HEATER: {
    id: 'AW',
    value: 'Air Conditioner or Heating System Plus Water Heater',
  },
  OTHER_HOME_IMPROVEMENT: { id: 'O', value: 'Other Home Improvement' },
  REPAIRS: { id: 'R', value: 'Repairs' },
};

export default AirConditioningSystemEnum;
