const hvacTonnage = {
  ONE_AND_HALF_TON: { value: '1.5', name: '1.5 Tons' },
  TWO_TON: { value: '2', name: '2 Tons', size: 'Less than 1200 sq. ft.', weight: '1.5 - 2 Ton' },
  TWO_AND_HALF_TON: { value: '2.5', name: '2.5 Tons' },
  THREE_TON: { value: '3', name: '3 Tons', size: '1200 - 1799 sq. ft.', weight: '2.5 - 3 Ton' },
  THREE_AND_HALF_TON: { value: '3.5', name: '3.5 Tons' },
  FOUR_TON: { value: '4', name: '4 Tons', size: '1800 - 2399 sq. ft.', weight: '3.5 - 4 Ton' },
  FIVE_TON: { value: '5', name: '5 Tons', size: '2400 sq. ft. and above', weight: '5 Ton' },
};

export const getHVACTonnage = tonage => {
  for (let key in hvacTonnage) {
    if (key === tonage) {
      return hvacTonnage[key].value;
    }
  }
  return null;
};

export default hvacTonnage;
