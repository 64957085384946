import React from 'react';
import EmploymentInformation from './Applicant/EmploymentInformation';
import IdentificationInformation from './Applicant/IdentificationInformation';
import ApplicationResult from './ApplicationResult';
import BillingAddress from './BillingAddress';
import CoAppAddress from './CoApplicant/Address';
import CoAppContactInformation from './CoApplicant/ContactInformation';
import CoAppEmploymentInformation from './CoApplicant/EmploymentInformation';
import CoAppIdentificationInformation from './CoApplicant/IdentificationInformation';
import Consent from './Consent';
import HasCoApplicant from './HasCoApplicant';
import LoanAmount from './LoanAmount';
import LoanFor from './LoanFor';
import Review from './Review';

const FTLFlow = [
  <Consent />,
  <LoanFor />,
  <LoanAmount />,
  <BillingAddress>
    <h4>Is your Billing Address the same as the Install Address?</h4>
  </BillingAddress>,
  <EmploymentInformation>
    <h4>Please provide your income (or benefits) information.</h4>
  </EmploymentInformation>,
  <IdentificationInformation>
    <h4>Please provide your identification information.</h4>
  </IdentificationInformation>,
  <HasCoApplicant>
    <h4>Would you like to add a Co-Applicant?</h4>
    <p>
      A co-applicant is <u>not</u> required.
    </p>
  </HasCoApplicant>,
  <CoAppContactInformation>
    <h4>Co-Applicant, enter your name and contact information.</h4>
  </CoAppContactInformation>,
  <CoAppAddress>
    <h4>Co-Applicant, enter your address.</h4>
  </CoAppAddress>,
  <CoAppEmploymentInformation>
    <h4>Co-Applicant, please provide your income (or benefits) information.</h4>
  </CoAppEmploymentInformation>,
  <CoAppIdentificationInformation>
    <h4>Co-Applicant, enter your date of birth and Social Security number.</h4>
  </CoAppIdentificationInformation>,
  <Review>
    <h4>Let's double check the information and submit your application.</h4>
  </Review>,
  <ApplicationResult />,
];

export default FTLFlow;

export const FTLFlowPageNumber = {
  Consent: 0,
  LoanFor: 1,
  LoanAmount: 2,
  BillingAddress: 3,
  EmploymentInformation: 4,
  IdentificationInformation: 5,
  HasCoApplicant: 6,
  CoAppContactInformation: 7,
  CoAppAddress: 8,
  CoAppEmploymentInformation: 9,
  CoAppIdentificationInformation: 10,
  Review: 11,
  ApplicationResult: 12,
};
