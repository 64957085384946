const bankAccountType = {
  CHECKING: { value: 'D', name: 'Checking Account' },
  SAVING: { value: 'S', name: 'Savings Account' },
};

export default bankAccountType;

export const dealerBankAccountType = {
  CHECKING: { value: 'CHECKING', name: 'Checking Account' },
  SAVING: { value: 'SAVING', name: 'Savings Account' },
};
