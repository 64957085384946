import React from 'react';
import BusinessInformation from './BusinessInformation';
import ContactAndBankInformation from './ContactAndBankInformation';
import FinalMessage from './FinalMessage';
import OwnerInformation from './OwnerInformation';
import PersonalInformation from './PersonalInformation';

const Flow = [
  <PersonalInformation>
    <h2>Personal Information</h2>
  </PersonalInformation>,
  <BusinessInformation>
    <h2>Business Information</h2>
  </BusinessInformation>,
  <OwnerInformation>
    <h2>Principal, Partner or Owner Information</h2>
  </OwnerInformation>,
  <ContactAndBankInformation>
    <h2>Contact and Bank Information</h2>
  </ContactAndBankInformation>,
  <FinalMessage title="Contractor Enrollment" />,
];

export default Flow;

export const ContractorEnrollmentAtWaterGSFlowPageNumber = {
  PersonalInformation: 0,
  BusinessInformation: 1,
  OwnerInformation: 2,
  ContactAndBankInformation: 3,
  FinalMessage: 4,
};
