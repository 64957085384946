import React, { useEffect } from 'react';
import { Field, Form, withFormik } from 'formik';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import * as Yup from 'yup';
import usStates from '../../../../assets/js/USStateEnum';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import Input from '../../../../components/UI/Form/Input/CustomInput';
import Select from '../../../../components/UI/Form/Select/Select';
import {
  changePage,
  submitMindfire,
  changeGreenSkyApplicationInfoValue,
} from '../../../../redux/actions';
import { AtWaterGreenSkyFlowPageNumber } from './GreenSkyFlow';
import { changeApplicationValues, reactGAEvent, reactGAPageview } from '../../../../helper';
import {
  CITY_TOOLTIP_TEXT,
  STATE_TOOLTIP_TEXT,
  STREET_TOOLTIP_TEXT,
  UNIT_NUMBER_TOOLTIP_TEXT,
  ZIPCODE_TOOLTIP_TEXT,
} from '../../../../constants/tooltipTexts';
import Disclosure from '../Components/Disclosure';

const AppPrimaryAddress = props => {
  const {
    pqi,
    children,
    email,
    did,
    dealerUserId,
    dispatchChangePage,
    dispatchSubmitMindfire,
    greenSkyApplicationInformation,
    dispatchChangeGreenSkyApplicationInfoValue,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_gsapprimaryaddress',
      trackerNames: [],
      title: 'ca_gsapprimaryaddress',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const setIsSameAsInstalationAddress = bool => {
    dispatchChangeGreenSkyApplicationInfoValue('isSameAsInstalationAddress', bool);
  };

  const ApplicantPrimaryAddressForm = () => (
    <Form>
      <Row>
        <Col sm="6">
          <Button
            className={greenSkyApplicationInformation.isSameAsInstalationAddress ? '' : 'inactive'}
            type="button"
            title="Yes"
            clickHandler={() => setIsSameAsInstalationAddress(true)}
          />
        </Col>
        <Col sm="6">
          <Button
            className={!greenSkyApplicationInformation.isSameAsInstalationAddress ? '' : 'inactive'}
            type="button"
            title="No"
            clickHandler={() => setIsSameAsInstalationAddress(false)}
          />
        </Col>
      </Row>
      <Field
        disabled={greenSkyApplicationInformation.isSameAsInstalationAddress}
        component={Input}
        label="Street"
        name="primaryAddressStreet"
        id="primaryAddressStreet"
        tooltipText={STREET_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={Input}
        label="Unit/Apt. Number"
        name="primaryAddressApartmentNumber"
        id="primaryAddressApartmentNumber"
        tooltipText={UNIT_NUMBER_TOOLTIP_TEXT}
        type="text"
      />
      <Row>
        <Col sm="6">
          <Field
            disabled={greenSkyApplicationInformation.isSameAsInstalationAddress}
            component={Input}
            label="City"
            name="primaryAddressCity"
            id="primaryAddressCity"
            tooltipText={CITY_TOOLTIP_TEXT}
            type="text"
          />
        </Col>
        <Col sm="6">
          <Field
            disabled={greenSkyApplicationInformation.isSameAsInstalationAddress}
            component={Select}
            label="State"
            name="primaryAddressState"
            id="primaryAddressState"
            tooltipText={STATE_TOOLTIP_TEXT}
            type="select"
            selectValues={usStates}
            selectFirstName="Select State"
          />
        </Col>
      </Row>
      <Field
        disabled={greenSkyApplicationInformation.isSameAsInstalationAddress}
        component={Input}
        label="ZIP Code"
        name="primaryAddressZipCode"
        id="primaryAddressZipCode"
        tooltipText={ZIPCODE_TOOLTIP_TEXT}
        type="text"
        maxLength={10}
      />

      <Button type="submit" title="Next" />
      <Disclosure />
    </Form>
  );

  const ApplicantPrimaryAddressFormFormik = withFormik({
    mapPropsToValues: () => ({
      primaryAddressApartmentNumber:
        greenSkyApplicationInformation.primaryAddressApartmentNumber || '',
      primaryAddressStreet:
        greenSkyApplicationInformation.isSameAsInstalationAddress && pqi.street
          ? pqi.street
          : greenSkyApplicationInformation.primaryAddressStreet || '',
      primaryAddressCity:
        greenSkyApplicationInformation.isSameAsInstalationAddress && pqi.city
          ? pqi.city
          : greenSkyApplicationInformation.primaryAddressCity || '',
      primaryAddressZipCode:
        greenSkyApplicationInformation.isSameAsInstalationAddress && pqi.zipcode
          ? pqi.zipcode
          : greenSkyApplicationInformation.primaryAddressZipCode || '',
      primaryAddressState:
        greenSkyApplicationInformation.isSameAsInstalationAddress && pqi.state
          ? pqi.state
          : greenSkyApplicationInformation.primaryAddressState || '',
    }),

    validationSchema: Yup.object({
      primaryAddressApartmentNumber: Yup.string(),
      primaryAddressStreet: Yup.string()
        .matches(/.*([a-zA-Z].*[0-9]|[0-9].*[a-zA-Z]).*/, {
          message: 'The street field must contain the street name and number',
          excludeEmptyString: true,
        })
        .required('Please enter a street.'),
      primaryAddressCity: Yup.string().required('Please enter a city.'),
      primaryAddressZipCode: Yup.string()
        .matches(/^\d{5}?$/, {
          message: 'Invalid zip code.',
          excludeEmptyString: true,
        })
        .required('Please enter a zip code.'),
      primaryAddressState: Yup.string().required('Please select a state.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      changeApplicationValues(dispatchChangeGreenSkyApplicationInfoValue, values);
      dispatchChangePage(AtWaterGreenSkyFlowPageNumber.AdditionalApplicantInformation);
      dispatchSubmitMindfire({
        email: email,
        lastPage: '9011',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(ApplicantPrimaryAddressForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={AtWaterGreenSkyFlowPageNumber.LoanInformation}
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <ApplicantPrimaryAddressFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  pqi: state.preQualificationInformation,
  greenSkyApplicationInformation: state.greenSkyApplicationInformation,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeGreenSkyApplicationInfoValue: (key, value) =>
    dispatch(changeGreenSkyApplicationInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

AppPrimaryAddress.propTypes = {
  pqi: PropTypes.object,
  dispatchChangePage: PropTypes.func,
  greenSkyApplicationInformation: PropTypes.object,
  dispatchChangeGreenSkyApplicationInfoValue: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AppPrimaryAddress),
);
