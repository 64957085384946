import axios from 'axios';
import { resetState } from '.';
import errorCodes from '../../assets/js/ErrorCodesEnum';
import userRoleEnum from '../../assets/js/UserRoleEnum';
import {
  getAddedFrom,
  getDealerPortalURL,
  getIsRheem,
  getMicrofUrl,
  getOptimusUrl,
  getRheemUrl,
} from '../../config/settings';
import { getApplicationByConsumerId, getPrequalificationInformation } from '../actions/index';
import {
  defaultFlow,
  microfFlow,
  mosaicPreQualificationFlow,
  powerPayPreQualificationFlow,
  wellsFargoFlow,
} from '../initialState';
import {
  CHANGE_AUTH_VALUE,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
  PROFILE_UPDATE,
  UPDATE_DEALER_STATS_SUMMARY,
  UPDATE_DEALER_VALUE,
} from './actionTypes';
import { baseURL, handleApiError } from './api';
import { changeGSContractorEnrollmentInfoValue } from './greenskyActions';
import { changeMicrofInfoValue } from './microfActions';
import { changePage, setFlow } from './naviActions';
import { addNotification, endLoading, startLoading } from './notificationActions';
import { changePreQualInfoValue } from './preQualInfoActions';
import { AquaPreQualificationFlowPageNumber } from '../../pages/PreQualification/preQualificationFlow';
import { MosaicPreQualificationFlowPageNumber } from '../../pages/Mosaic/PreQualification/PreQualificationFlow';
import { PowerPayPreQualificationFlowPageNumber } from '../../pages/PowerPay/PreQualification/PreQualificationFlow';
import AddedFromEnum from '../../assets/js/AddedFromEnum';
import selectedFinancierEnum from '../../assets/js/SelectedFinancierEnum';
import ApplicationFlowEnum from '../../assets/js/ApplicationFlowEnum';
import { GreenSkyPreQualificationFlowPageNumber } from '../../pages/Optimus/GreenSky/PreQualification/PreQualificationFlow';
import { FoundationPreQualificationFlowPageNumber } from '../../pages/Optimus/Foundation/PreQualification/PreQualificationFlow';
import { WellsFargoFlowPageNumber } from '../../pages/WellsFargo/WellsFargoFlow';
import { EnerBankPreQualificationFlowPageNumber } from '../../pages/EnerBank/PreQualification/PreQualificationFlow';
import { FTLPreQualificationFlowPageNumber } from '../../pages/FTL/PreQualification/PreQualificationFlow';
import { greenskyValidateMerchant } from '../actions';
import { AtWaterGreenSkyPreQualificationFlowPageNumber } from '../../pages/Pools/Greensky/PreQualification/PreQualificationFlow';
import { UpgradePreQualificationFlowPageNumber } from '../../pages/Upgrade/PreQualification/preQualificationFlow';

const isRheem = getIsRheem();
const isAddedFrom = getAddedFrom();
//const invalidDidRedirectUrl = isRheem ? getMicrofUrl() : getRheemUrl();

export const login = (params, history) => async (dispatch, getState) => {
  const { auth } = getState();

  const customDealerId = auth.customDealerId;
  const isDealerIdCustomLink = auth.isDealerIdCustomLink;

  if (!auth.currentPath) {
    dispatch(resetState({ history: null }));
  }
  dispatch(startLoading());

  const loginForm =
    'username=' +
    encodeURIComponent(params.username) +
    '&password=' +
    encodeURIComponent(params.password) +
    '&addedfrom=' +
    params.addedFrom;

  await axios
    .post(`${baseURL}/api/session`, loginForm)
    .then(response => {
      dispatch(endLoading());
      if (response.data) {
        dispatch(evaluateLogin(response.data, history, customDealerId, isDealerIdCustomLink));
      }
    })
    .catch(error => {
      dispatch(endLoading());
      dispatch({ type: LOGIN_ERROR, payload: error });
      if (error.response && error.response.data) {
        updateErrorWhenUserIsBlocked(error.response.data);
        dispatch(
          addNotification({
            message: handleApiError(error),
            messageType: 'Error',
          }),
        );
      }
    });
};

export const evaluateLogin = (data, history, customDealerId, isDealerIdCustomLink) => (
  dispatch,
  getState,
) => {
  let user = data.user;
  if ((data.addedFrom === 'R' && !isRheem) || (data.addedFrom === 'M' && isRheem)) {
    dispatch(
      addNotification({
        message: 'Full authentication is required to access this resource',
        messageType: 'Error',
      }),
    );
  } else {
    dispatch({ type: LOGIN_SUCCESS, payload: data });
    const { auth } = getState();
    if (auth.currentPath) {
      const { currentPath } = auth;
      dispatch(changeAuthValue('currentPath', null));
      window.location.href = currentPath;
    } else if (
      user.internalUser &&
      (user.internalUser.role === userRoleEnum.DEALER_RELATIONSHIP_REP.value ||
        user.internalUser.role === userRoleEnum.USER_ADMINISTRATOR.value)
    ) {
      history.push('/portal/manage-contractor-users');
    } else if (
      user &&
      user.dealerUser &&
      user.dealerUser.role === userRoleEnum.DEALER_CANDIDATE.value
    ) {
      if (user.dealerUser.dealer) {
        history.push('/portal/candidate-contractor-dashboard');
      } else {
        if (user.dealerUser.greenSky) {
          if (data.ein) {
            dispatch(changeGSContractorEnrollmentInfoValue('ein', data.ein));
          }
          history.push('/CE');
        } else {
          history.push('/ContractorEnrollment');
        }
      }
    } else if (
      user.dealerUser &&
      (user.dealerUser.role === userRoleEnum.DEALER_ADMIN.value ||
        user.dealerUser.role === userRoleEnum.DEALER_EMPLOYEE.value)
    ) {
      if (isDealerIdCustomLink && customDealerId) {
        dispatch(changeAuthValue('isDealerIdCustomLink', isDealerIdCustomLink));
        dispatch(changeAuthValue('customDealerId', customDealerId));
        history.push('/UpdateMerchantNumber');
      } else {
        dispatch(changeAuthValue('dealerUserId', user.dealerUser.userId));
        dispatch(changeAuthValue('dealerId', user.dealerUser.dealer.dealerId));
        history.push('/portal/dashboard');
      }
    } else if (user.consumerUser) {
      dispatch(
        getApplicationByConsumerId(
          user.consumerUser.userId,
          user.consumerUser.email,
          user.consumerUser.consumerWithDealerLink,
          history,
        ),
      );
    }
  }
};
export const validateToken = (token, microfOnly, emulatorId, history, flow) => async dispatch => {
  dispatch(resetState({ history: null }));

  dispatch(startLoading('Validate Token ...'));

  dispatch(changeAuthValue('token', token));
  dispatch(changeAuthValue('dealerUserId', null));
  dispatch(changeAuthValue('dealerId', null));
  dispatch(changeAuthValue('aquaFinanceAssigned', false));
  dispatch(changeMicrofInfoValue('microfOnly', 'true' === microfOnly));
  if (emulatorId) {
    dispatch(changeAuthValue('emulatorId', emulatorId));
  }
};

export const loginConsumerUserAfterCreation = (params, history) => async dispatch => {
  const loginForm =
    'username=' +
    encodeURIComponent(params.email) +
    '&password=' +
    encodeURIComponent(params.password) +
    '&addedfrom=' +
    params.addedFrom;

  await axios
    .post(`${baseURL}/api/session`, loginForm)
    .then(response => {
      dispatch(endLoading());
      if (response.data) {
        const { addedFrom } = response.data;
        if ((addedFrom === 'R' && !isRheem) || (addedFrom === 'M' && isRheem)) {
          dispatch(
            addNotification({
              message: 'Full authentication is required to access this resource',
              messageType: 'Error',
            }),
          );
        } else {
          dispatch({ type: LOGIN_SUCCESS, payload: response.data });
          if (params.page) {
            dispatch(changePage(params.page));
          }
        }
      }
    })
    .catch(error => {
      dispatch(endLoading());
      dispatch({ type: LOGIN_ERROR, payload: error });
      if (error.response && error.response.data) {
        dispatch(
          addNotification({
            message: handleApiError(error),
            messageType: 'Error',
          }),
        );
      }
    });
};

export const logout = history => async dispatch => {
  dispatch(startLoading());

  return axios
    .delete(`${baseURL}/api/session`)
    .then(() => {
      dispatch(endLoading());
      history.push('/login');
      dispatch(resetState({ history: null }));
      dispatch({ type: LOGOUT });
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(endLoading());
    });
};

export const changeAuthValue = (key, value) => ({
  type: CHANGE_AUTH_VALUE,
  key,
  value,
});

export const updateDealerValue = payload => ({
  type: UPDATE_DEALER_VALUE,
  payload,
});

export const handlePoolsCustomLink = (response, history) => dispatch => {
  const {
    defaultFinancingOption,
    secondFinancingOption,
    greenskyAssigned,
    foundationAssigned,
    enerBankAssigned,
    mosaicAssigned,
    ftlassigned,
    dealerId,
  } = response;

  if (
    greenskyAssigned &&
    defaultFinancingOption === selectedFinancierEnum.GREENSKY.value.toUpperCase()
  ) {
    dispatch(changeAuthValue('greenskyAssigned', true));
    dispatch(setFlow(ApplicationFlowEnum.ATWATER_GREENSKY_PREQUALIFICATION.value));
    dispatch(changePage(AtWaterGreenSkyPreQualificationFlowPageNumber.AccountEmail));
    history.push(ApplicationFlowEnum.ATWATER_GREENSKY_PREQUALIFICATION.path);
  }
};

export const handleOptimusCustomLink = (response, history) => dispatch => {
  const {
    defaultFinancingOption,
    secondFinancingOption,
    greenskyAssigned,
    foundationAssigned,
    enerBankAssigned,
    mosaicAssigned,
    ftlassigned,
    dealerId,
    dealerUserId,
  } = response;
  if (
    mosaicAssigned &&
    defaultFinancingOption === selectedFinancierEnum.MOSAIC.value.toUpperCase()
  ) {
    dispatch(changeAuthValue('mosaicAssigned', true));
    dispatch(setFlow(ApplicationFlowEnum.MOSAIC_PREQUALIFICATION.value));
    dispatch(changePage(MosaicPreQualificationFlowPageNumber.AccountEmail));
    history.push(ApplicationFlowEnum.MOSAIC_PREQUALIFICATION.path);
  } else if (
    greenskyAssigned &&
    defaultFinancingOption === selectedFinancierEnum.GREENSKY.value.toUpperCase()
  ) {
    dispatch(greenskyValidateMerchant(dealerId, dealerUserId, history));
    dispatch(changeAuthValue('greenskyAssigned', true));
    if (
      foundationAssigned &&
      secondFinancingOption === selectedFinancierEnum.FOUNDATION.value.toUpperCase()
    ) {
      dispatch(changeAuthValue('foundationAssigned', true));
    }
    if (
      mosaicAssigned &&
      secondFinancingOption === selectedFinancierEnum.MOSAIC.value.toUpperCase()
    ) {
      dispatch(changeAuthValue('mosaicAssigned', true));
    }
    if (ftlassigned && secondFinancingOption === selectedFinancierEnum.FTL.value.toUpperCase()) {
      dispatch(changeAuthValue('ftlAssigned', true));
    }
    dispatch(setFlow(ApplicationFlowEnum.GREENSKY_PREQUALIFICATION.value));
    dispatch(changePage(GreenSkyPreQualificationFlowPageNumber.AccountEmail));
    history.push(ApplicationFlowEnum.GREENSKY_PREQUALIFICATION.path);
  } else if (
    enerBankAssigned &&
    defaultFinancingOption === selectedFinancierEnum.ENERBANK.value.toUpperCase()
  ) {
    dispatch(changeAuthValue('enerBankAssigned', true));
    if (
      foundationAssigned &&
      secondFinancingOption === selectedFinancierEnum.FOUNDATION.value.toUpperCase()
    ) {
      dispatch(changeAuthValue('foundationAssigned', true));
    }
    if (
      mosaicAssigned &&
      secondFinancingOption === selectedFinancierEnum.MOSAIC.value.toUpperCase()
    ) {
      dispatch(changeAuthValue('mosaicAssigned', true));
    }
    if (ftlassigned && secondFinancingOption === selectedFinancierEnum.FTL.value.toUpperCase()) {
      dispatch(changeAuthValue('ftlAssigned', true));
    }
    dispatch(setFlow(ApplicationFlowEnum.ENERBANK_PREQUALIFICATION_FLOW.value));
    dispatch(changePage(EnerBankPreQualificationFlowPageNumber.AccountEmail));
    history.push(ApplicationFlowEnum.ENERBANK_PREQUALIFICATION_FLOW.path);
  } else if (
    enerBankAssigned &&
    defaultFinancingOption === selectedFinancierEnum.ENERBANK.value.toUpperCase()
  ) {
    dispatch(changeAuthValue('enerBankAssigned', true));
    if (
      foundationAssigned &&
      secondFinancingOption === selectedFinancierEnum.FOUNDATION.value.toUpperCase()
    ) {
      dispatch(changeAuthValue('foundationAssigned', true));
    }
    if (
      mosaicAssigned &&
      secondFinancingOption === selectedFinancierEnum.MOSAIC.value.toUpperCase()
    ) {
      dispatch(changeAuthValue('mosaicAssigned', true));
    }
    if (ftlassigned && secondFinancingOption === selectedFinancierEnum.FTL.value.toUpperCase()) {
      dispatch(changeAuthValue('ftlAssigned', true));
    }
    dispatch(setFlow(ApplicationFlowEnum.ENERBANK_PREQUALIFICATION_FLOW.value));
    dispatch(changePage(EnerBankPreQualificationFlowPageNumber.AccountEmail));
    history.push(ApplicationFlowEnum.ENERBANK_PREQUALIFICATION_FLOW.path);
  } else if (
    ftlassigned &&
    defaultFinancingOption === selectedFinancierEnum.FTL.value.toUpperCase()
  ) {
    dispatch(changeAuthValue('ftlAssigned', true));
    if (
      foundationAssigned &&
      secondFinancingOption === selectedFinancierEnum.FOUNDATION.value.toUpperCase()
    ) {
      dispatch(changeAuthValue('foundationAssigned', true));
    }
    if (
      mosaicAssigned &&
      secondFinancingOption === selectedFinancierEnum.MOSAIC.value.toUpperCase()
    ) {
      dispatch(changeAuthValue('mosaicAssigned', true));
    }
    dispatch(setFlow(ApplicationFlowEnum.FTL_PREQUALIFICATION_FLOW.value));
    dispatch(changePage(FTLPreQualificationFlowPageNumber.AccountEmail));
    history.push(ApplicationFlowEnum.FTL_PREQUALIFICATION_FLOW.path);
  } else {
    dispatch(changeAuthValue('foundationAssigned', true));
    dispatch(setFlow(ApplicationFlowEnum.FOUNDATION_PREQUALIFICATION.value));
    dispatch(changePage(FoundationPreQualificationFlowPageNumber.AccountEmail));
    history.push(ApplicationFlowEnum.FOUNDATION_PREQUALIFICATION.path);
  }
};

export const handleMicrofCustomLink = (response, history) => dispatch => {
  const {
    defaultFinancingOption,
    secondFinancingOption,
    aquaFinanceAssigned,
    powerPayAssigned,
    wellsFargoAssigned,
    mosaicAssigned,
    ftlassigned,
    upgradeAssigned,
  } = response;

  if (powerPayAssigned && defaultFinancingOption === 'POWER_PAY') {
    if (aquaFinanceAssigned && secondFinancingOption === 'AQUA_FINANCE') {
      dispatch(changeAuthValue('aquaFinanceAssigned', true));
    }
    dispatch(changeAuthValue('powerPayAssigned', true));
    dispatch(setFlow(powerPayPreQualificationFlow));
    dispatch(changePage(PowerPayPreQualificationFlowPageNumber.Landing));
    history.push('/powerpay-prequalification');
  } else if (mosaicAssigned && defaultFinancingOption === 'MOSAIC') {
    if (aquaFinanceAssigned && secondFinancingOption === 'AQUA_FINANCE') {
      dispatch(changeAuthValue('aquaFinanceAssigned', true));
    }
    if (powerPayAssigned && secondFinancingOption === 'POWER_PAY') {
      dispatch(changeAuthValue('powerPayAssigned', true));
    }
    dispatch(changeAuthValue('mosaicAssigned', true));
    dispatch(setFlow(mosaicPreQualificationFlow));
    dispatch(changePage(MosaicPreQualificationFlowPageNumber.Landing));
    history.push('/mosaic-prequalification');
  } else if (aquaFinanceAssigned && defaultFinancingOption === 'AQUA_FINANCE') {
    if (powerPayAssigned && secondFinancingOption === 'POWER_PAY') {
      dispatch(changeAuthValue('powerPayAssigned', true));
    }
    dispatch(changeAuthValue('aquaFinanceAssigned', true));
    dispatch(changePage(AquaPreQualificationFlowPageNumber.AccountEmail));
    history.push('/');
  } else if (ftlassigned && defaultFinancingOption === 'FTL') {
    if (aquaFinanceAssigned && secondFinancingOption === 'AQUA_FINANCE') {
      dispatch(changeAuthValue('aquaFinanceAssigned', true));
    }
    if (powerPayAssigned && secondFinancingOption === 'POWER_PAY') {
      dispatch(changeAuthValue('powerPayAssigned', true));
    }
    dispatch(changeAuthValue('ftlAssigned', true));
    dispatch(setFlow(ApplicationFlowEnum.FTL_PREQUALIFICATION_FLOW.value));
    dispatch(changePage(FTLPreQualificationFlowPageNumber.AccountEmail));
    history.push(ApplicationFlowEnum.FTL_PREQUALIFICATION_FLOW.path);
  } else if (upgradeAssigned && defaultFinancingOption === 'UPGRADE') {
    dispatch(changeAuthValue('upgradeAssigned', true));
    dispatch(setFlow(ApplicationFlowEnum.UPGRADE_PREQUALIFICATION.value));
    dispatch(changePage(UpgradePreQualificationFlowPageNumber.AccountEmail));
    history.push(ApplicationFlowEnum.UPGRADE_PREQUALIFICATION.path);
  } else if (wellsFargoAssigned) {
    dispatch(changeAuthValue('wellsFargoAssigned', true));
    dispatch(setFlow(wellsFargoFlow));
    dispatch(changePage(WellsFargoFlowPageNumber.Terms));
    history.push('/WellsFargo');
  } else {
    dispatch(changeMicrofInfoValue('microfOnly', true));
    dispatch(changeAuthValue('aquaFinanceAssigned', false));
    dispatch(setFlow(defaultFlow));
    dispatch(changePage(AquaPreQualificationFlowPageNumber.AccountEmail));
    history.push('/');
  }
};

export const setApplicationFlow = (response, history) => (dispatch, getState) => {
  debugger;
  const { auth } = getState();
  const { addedFrom } = response;
  if (auth.did && isAddedFrom !== addedFrom && AddedFromEnum.OPTIMUS_PLUS.value !== addedFrom) {
    const invalidDidRedirectUrl =
      addedFrom === AddedFromEnum.OPTIMUS.value
        ? getOptimusUrl()
        : addedFrom === AddedFromEnum.RHEEM.value
        ? getRheemUrl()
        : getMicrofUrl();
    window.location = invalidDidRedirectUrl + '?did=' + auth.did;
  } else if (isAddedFrom === AddedFromEnum.RHEEM.value) {
    dispatch(changePage(AquaPreQualificationFlowPageNumber.RheemLanding));
    history.push('/');
  } else if (isAddedFrom === AddedFromEnum.OPTIMUS.value) {
    dispatch(handleOptimusCustomLink(response, history));
  } else if (isAddedFrom === AddedFromEnum.POOLS.value) {
    dispatch(handlePoolsCustomLink(response, history));
  } else {
    dispatch(handleMicrofCustomLink(response, history));
  }
};

export const validateCustomLink = (did, history, isRTOOnly) => async dispatch => {
  dispatch(resetState({ history }));
  dispatch(startLoading('Validate Dealer ID ...'));
  dispatch(changePreQualInfoValue('isConsumer', true));
  await validateDealerID(did)
    .then(async response => {
      const validateDealerResponse = response.data;
      const { dealerId } = validateDealerResponse;
      if (dealerId) {
        await getDealerById(parseInt(dealerId))
          .then(getDealerByIdResponse => {
            const { dealerName, dealerActive } = getDealerByIdResponse.data;
            dispatch(
              mapValidateDealerResponseToAuthState({ ...validateDealerResponse, dealerName, did }),
            );
            if (isRTOOnly) {
              if (!dealerActive) {
                history.push('/deactivated-dealer');
              } else {
                dispatch(changeMicrofInfoValue('microfOnly', true));
                dispatch(setFlow(microfFlow));
                history.push('/RTO');
              }
            } else {
              if (!dealerActive) {
                history.push('/deactivated-dealer');
              } else {
                dispatch(setApplicationFlow(validateDealerResponse, history));
              }
            }
          })
          .catch(error => {
            dispatch(
              addNotification({
                message: handleApiError(error),
                messageType: 'Error',
              }),
            );
          });
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      history.push('/login');
    });

  dispatch(endLoading());
};

export const validateCustomLinkDealerId = (dealerId, history) => async (dispatch, getState) => {
  dispatch(startLoading('Validate Dealer ID ...'));

  const { auth } = getState();

  dispatch(changeAuthValue('isDealerIdCustomLink', true));
  dispatch(changeAuthValue('customDealerId', dealerId));

  const isUserLoggedIn = auth.user && auth.user.dealerUser;

  if (isUserLoggedIn) {
    history.push('/UpdateMerchantNumber');
  } else {
    history.push('/login');
  }

  dispatch(endLoading());
};

export const mapValidateDealerResponseToAuthState = ({
  did,
  dealerId,
  dealerName,
  dealerUserId,
  powerPayAssigned,
  wellsFargoAssigned,
  aquaFinanceAssigned,
  enerBankAssigned,
  greenskyPlanNumber,
  greenskyMerchantNumber,
  enerBankProgramNumber,
  enerBankContractorId,
}) => dispatch => {
  dispatch(changeAuthValue('did', did));
  dispatch(changeAuthValue('dealerId', parseInt(dealerId)));
  dispatch(changeAuthValue('dealerName', dealerName));
  dispatch(changeAuthValue('dealerUserId', parseInt(dealerUserId)));
  dispatch(changeAuthValue('aquaFinanceAssigned', aquaFinanceAssigned));
  dispatch(changeAuthValue('wellsFargoAssigned', wellsFargoAssigned));
  dispatch(changeAuthValue('powerPayAssigned', powerPayAssigned));
  dispatch(changeAuthValue('enerBankAssigned', enerBankAssigned));
  dispatch(changeAuthValue('greenskyPlanNumber', greenskyPlanNumber));
  dispatch(changeAuthValue('greenskyMerchantNumber', greenskyMerchantNumber));
  dispatch(changeAuthValue('enerBankProgramNumber', enerBankProgramNumber));
  dispatch(changeAuthValue('enerBankContractorId', enerBankContractorId));
};

export const validateDealerID = did =>
  axios.get(`${baseURL}/api/soft_pull/validateDealerID?did=${encodeURIComponent(did)}`);

export const getDealerById = dealerId =>
  axios.get(`${baseURL}/api/soft_pull/getDealerById?dealerId=${encodeURIComponent(dealerId)}`);

export const passwordRecovery = (data, history) => async dispatch => {
  dispatch(startLoading('Set Your Password ...'));

  dispatch(changeAuthValue('passwordToken', data.token));
  dispatch(changeAuthValue('candidatePassword', data.candidatePassword));

  await axios
    .put(`${baseURL}/api/users/passwordRecovery`, data)
    .then(async response => {
      history.push('/password-update-successful');
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });

  dispatch(endLoading());
};

export const passwordReset = (data, history) => async dispatch => {
  dispatch(startLoading('Reset Password ...'));

  dispatch(changeAuthValue('passwordRetrievalDTO', data));

  await axios
    .post(`${baseURL}/api/users/passwordRecovery`, data)
    .then(async response => {
      dispatch(changeAuthValue('forgotPasswordMessage', 'success'));
    })
    .catch(error => {
      if (
        error.response &&
        error.response.statusText &&
        error.response.statusText === 'Unauthorized'
      ) {
        dispatch(changeAuthValue('forgotPasswordMessage', 'Unauthorized'));
      } else {
        dispatch(
          addNotification({
            message: handleApiError(error),
            messageType: 'Error',
          }),
        );
      }
    });

  dispatch(endLoading());
};

export const getVersion = () => async dispatch => {
  dispatch(startLoading('Get Version ...'));

  dispatch(changeAuthValue('version', { buildNumber: null, scmCommitNumber: null, version: null }));

  await axios
    .get(`${baseURL}/api/system/version`)
    .then(async response => {
      dispatch(changeAuthValue('version', response.data));
      dispatch(changeAuthValue('showAbout', true));
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });

  dispatch(endLoading());
};

export const setProfile = profile => async dispatch => {
  dispatch(startLoading('Set Profile ...'));

  await axios
    .put(`${baseURL}/api/soft_pull/profile`, profile)
    .then(async response => {
      dispatch({ type: PROFILE_UPDATE, payload: response.data });
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });

  dispatch(endLoading());
};

export const changeYourPassword = changePass => async dispatch => {
  dispatch(startLoading('Change Your Password ...'));

  await axios
    .put(`${baseURL}/api/users/passwordRecovery`, changePass)
    .then(async response => {
      dispatch(
        addNotification({
          message: 'Password Successfully Updated!',
          messageType: 'Success',
        }),
      );
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });

  dispatch(endLoading());
};

export const createDealershipApplication = dealer => async dispatch => {
  dispatch(startLoading('Create Dealership Application ...'));
  if (dealer.sameMailingAddress === null) {
    dealer.sameMailingAddress = false;
  }

  await axios
    .post(`${baseURL}/api/soft_pull/applications`, dealer)
    .then(response => {
      dispatch(changeAuthValue('dealerDTOCreateDealershipApplication', true));
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const getDealerStats = () => async dispatch => {
  dispatch(startLoading('Get Dealer Stats ...'));

  await axios
    .get(`${baseURL}/api/self/dealer/stats`)
    .then(response => {
      dispatch({ type: UPDATE_DEALER_STATS_SUMMARY, payload: response.data });
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });
  dispatch(endLoading());
};

export const emulateStart = (emulateeUserId, emulatorId, history) => async dispatch => {
  dispatch(startLoading('Emulate Start ...'));

  await axios
    .post(`${baseURL}/api/soft_pull/emulate?emulateeUserId=${emulateeUserId}`)
    .then(async response => {
      dispatch(endLoading());
      if (response.data) {
        dispatch(evaluateLogin(response.data, history));
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(endLoading());
    });
};

export const emulateStop = history => async dispatch => {
  dispatch(startLoading('Emulate Stop ...'));

  await axios
    .delete(`${baseURL}/api/soft_pull/emulate`)
    .then(async response => {
      dispatch(endLoading());
      if (response.data) {
        dispatch(evaluateLogin(response.data, history));
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch(endLoading());
    });
};

export const getSession = history => async dispatch => {
  await axios
    .get(`${baseURL}/api/soft_pull/session`)
    .then(response => {
      if (response.data) {
        const { addedFrom } = response.data;
        if ((addedFrom === 'R' && !isRheem) || (addedFrom === 'M' && isRheem)) {
          dispatch(endLoading());
          dispatch(
            addNotification({
              message: 'Full authentication is required to access this resource',
              messageType: 'Error',
            }),
          );
        } else {
          dispatch({ type: LOGIN_SUCCESS, payload: response.data });
        }
      }
    })
    .catch(error => {
      dispatch(endLoading());
      window.location.href = getDealerPortalURL();
    });
};

const updateErrorWhenUserIsBlocked = error => {
  if (error.errorCode === errorCodes.BLOCKED) {
    error.message = 'Your account is blocked. Please wait 15 minutes and try again.';
  }
};
