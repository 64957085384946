import React, { useState } from 'react';
import { GoInfo } from 'react-icons/go';
import NumberFormat from 'react-number-format';
import { Col, FormGroup, Label, Row, Tooltip } from 'reactstrap';
import InputFeedback from '../InputFeedback/InputFeedback';

const DatePicker = ({
  field: { name, value, onBlur },
  form: { errors, touched, setFieldValue },
  id,
  label,
  className,
  labelClassName,
  disabled,
  maxDate,
  minDate,
  placeholder,
  formGroupClass,
  tooltipText,
  hideTooltip,
  ...props
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <FormGroup className={formGroupClass}>
      <Label for={id} className={labelClassName}>
        <b>{label}</b>
      </Label>
      <Row className="align-items-center">
        <Col>
          <NumberFormat
            name={name}
            id={id}
            disabled={disabled}
            onBlur={onBlur}
            value={value}
            className={`form-control ${className}`}
            format={'##/##/####'}
            placeholder="MM/DD/YYYY"
            mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
            type="text"
            pattern="\d*"
            onValueChange={e => {
              setFieldValue(name, e.formattedValue);
            }}
            autoComplete="off"
          />
        </Col>
        {!hideTooltip ? (
          <Col xs="auto" className="pl-0">
            <div id={`tooltip-${id}`} className="cursor-pointer">
              <GoInfo size={20} />
            </div>
          </Col>
        ) : null}
      </Row>
      {touched[name] && <InputFeedback error={errors[name]} />}
      {!hideTooltip ? (
        <Tooltip
          placement="right"
          target={`tooltip-${id}`}
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          autohide={false}
        >
          {tooltipText ? tooltipText : `Please insert '${label}'`}
        </Tooltip>
      ) : null}
    </FormGroup>
  );
};

export default DatePicker;
