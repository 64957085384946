import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import employmentTypes from '../../../assets/js/EmploymentTypeEnum';
import idTypes from '../../../assets/js/IdTypeEnum';
import terms from '../../../assets/pdf/Microf Statement of Terms and Conditions.pdf';
import statementOfUse from '../../../assets/pdf/Microf Statement on Use of Electronic Records.pdf';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import Checkbox from '../../../components/UI/Form/Checkbox/Checkbox';
import { getPrivacyStatementURL } from '../../../config/settings';
import { getAddress, reactGAEvent, reactGAPageview } from '../../../helper';
import {
  changePage,
  changePowerPayInfoValue,
  submitMindfire,
  submitPowerPayApplication,
} from '../../../redux/actions/index';
import selectedFinancierEnum from '../../../assets/js/SelectedFinancierEnum';
import { PowerPayFlowPageNumber } from './PowerPayFlow';
import { isAquaDeclined, isMosaicDeclined } from '../../../assets/js/ApplicationStatusEnum';

const ApplicationReview = props => {
  const {
    dispatchSubmitPowerPayApplication,
    children,
    dispatchChangePage,
    dispatchChangePowerPayInfoValue,
    pqi,
    powerPayInformation,
    softPull,
    dealerUserId,
    did,
    app,
    emulatorId,
    track_id,
    applicantAddress,
    dispatchSubmitMindfire,
    consumerId,
  } = props;
  const isEditable = !app || isMosaicDeclined(app.status) || isAquaDeclined(app.status);

  useEffect(() => {
    reactGAPageview({
      path: '/ca_ppconfirminfo',
      trackerNames: [],
      title: 'ca_ppconfirminfo',
      did,
      dealerUserId,
    });
    dispatchChangePowerPayInfoValue('isReview', false);
    // eslint-disable-next-line
  }, []);

  const editPage = page => {
    dispatchChangePowerPayInfoValue('isReview', true);
    dispatchChangePage(page);
  };

  const mindfireRequest = {
    email: pqi.email,
    lastPage: '6017',
    dealerUserId: dealerUserId,
    did: did,
  };

  const appAddress = getAddress({
    street: pqi.street,
    city: pqi.city,
    state: pqi.state,
    zip: pqi.zipcode,
  });

  const coApplicantAddress = powerPayInformation.hasCoApp
    ? getAddress({
        street: powerPayInformation.coAppStreet,
        city: powerPayInformation.coAppCity,
        state: powerPayInformation.coAppState,
        zip: powerPayInformation.coAppZipCode,
      })
    : 'No address';

  const ApplicationReviewForm = () => (
    <Form>
      <Row className="review">
        <Col>
          <p>
            <h5>
              <b>I agree to Microf’s Terms & Conditions.</b>
            </h5>
            You authorize Microf, the technology platform provider, to immediately share all
            application information with participating lenders and you understand and agree that you
            are accepting Microf’s{' '}
            <Link to={terms} target="_blank">
              Statement of Terms and Conditions
            </Link>
            ,{' '}
            <Link to={statementOfUse} target="_blank">
              Statement on Use of Electronic Records
            </Link>
            , and{' '}
            <a href={getPrivacyStatementURL()} target="_blank" rel="noopener noreferrer">
              Privacy Statement
            </a>
            .
          </p>
          <Field
            component={Checkbox}
            name="applicantMicrofTerms"
            id="applicantMicrofTerms"
            label={`Applicant`}
            labelClassName="bold"
          />

          {powerPayInformation.hasCoApp && (
            <Field
              component={Checkbox}
              name="coApplicantMicrofTerms"
              id="coApplicantMicrofTerms"
              label={`Co-Applicant`}
              labelClassName="bold"
            />
          )}
          <p>
            <h5>
              <b>I authorize PowerPay to access my info under the Fair Credit Reporting Act.</b>
            </h5>
            <span>
              You understand and agree that you are providing instructions to PowerPay under the
              Fair Credit Reporting Act (“FCRA”) to access information from your personal credit
              profile or other information from one or more consumer reporting agencies such as
              Equifax, Experian, or TransUnion. This “hard pull” may impact your credit score.
            </span>
          </p>
          <Field
            component={Checkbox}
            name="applicantPowerPayTerms"
            id="applicantPowerPayTerms"
            label={`Applicant`}
            labelClassName="bold"
          />
          {powerPayInformation.hasCoApp && (
            <Field
              component={Checkbox}
              name="coApplicantPowerPayTerms"
              id="coApplicantPowerPayTerms"
              label={`Co-Applicant`}
              labelClassName="bold"
            />
          )}
        </Col>
      </Row>
      <Button
        type="submit"
        title="Find the best financing option"
        disabled={powerPayInformation.submited}
      />
    </Form>
  );

  const ApplicationReviewFormFormik = withFormik({
    mapPropsToValues: () => ({
      applicantMicrofTerms: false,
      coApplicantMicrofTerms: false,
      applicantPowerPayTerms: false,
      coApplicantPowerPayTerms: false,
    }),

    validationSchema: Yup.object({
      applicantMicrofTerms: Yup.boolean().oneOf([true], 'Please check to continue.'),
      coApplicantMicrofTerms: powerPayInformation.hasCoApp
        ? Yup.boolean().oneOf([true], 'Please check to continue.')
        : Yup.boolean(),
      applicantPowerPayTerms: Yup.boolean().oneOf([true], 'Please check to continue.'),
      coApplicantPowerPayTerms: powerPayInformation.hasCoApp
        ? Yup.boolean().oneOf([true], 'Please check to continue.')
        : Yup.boolean(),
    }),

    handleSubmit: () => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Find the best financing option',
        did,
        dealerUserId,
      });
      dispatchChangePowerPayInfoValue('submited', true);
      const softPullRequest = {
        consumerId: consumerId,
        email: softPull.softPullResponse.email || null,
        borrowedAmount:
          powerPayInformation.borrowedAmount !== null
            ? Math.floor(powerPayInformation.borrowedAmount)
            : null,
        firstName: softPull.softPullResponse.firstName || null,
        lastName: softPull.softPullResponse.lastName || null,
        middleName: softPull.softPullResponse.middleName || null,
        phoneNumber: softPull.softPullResponse.phoneNumber || null,
        street: softPull.softPullResponse.street || null,
        city: softPull.softPullResponse.city || null,
        zipcode: softPull.softPullResponse.zipcode || null,
        state: softPull.softPullResponse.state || null,
        poBox: pqi.poBox || null,
        applicantStreet: applicantAddress ? applicantAddress.street : null,
        applicantCity: applicantAddress ? applicantAddress.city : null,
        applicantZip: applicantAddress ? applicantAddress.zip : null,
        applicantState: applicantAddress ? applicantAddress.state : null,
        birthday: softPull.softPullResponse.birthday || null,
        ssnLastDigits: softPull.softPullResponse.ssnLastDigits || null,
        airConditioningSystem: softPull.softPullResponse.airConditioningSystem,
        isHomeowner: softPull.softPullResponse.isHomeowner || pqi.isHomeowner,

        dealerUserId: dealerUserId || null,
        prequalificationId: softPull.softPullResponse.prequalificationId || null,
        idType: idTypes.DrivesLicense.value,
        driversLicenseState: powerPayInformation.appDriversLicenseState || null,
        idNumber: powerPayInformation.appDriversLicenseNumber || null,
        ssn: powerPayInformation.appFullSsn,
        employerType: employmentTypes[
          Object.keys(employmentTypes).find(
            key => employmentTypes[key].value === powerPayInformation.appEmploymentType,
          )
        ]
          ? employmentTypes[
              Object.keys(employmentTypes).find(
                key => employmentTypes[key].value === powerPayInformation.appEmploymentType,
              )
            ].powerPayValue
          : null,
        occupation:
          powerPayInformation.appEmploymentType === employmentTypes.Retired.value
            ? employmentTypes.Retired.powerPayValue
            : powerPayInformation.appOccupation || null,
        employerName: powerPayInformation.appEmployerName || null,
        employmentYears: powerPayInformation.appEmploymentYears || 0,
        employmentMonths: powerPayInformation.appEmploymentMonths || 1,
        appEmployerZipCode:
          powerPayInformation.appEmploymentType === employmentTypes.Retired.value
            ? softPull.softPullResponse.zipcode
            : powerPayInformation.appEmployerZipCode,

        appGrossAnnualIncome: powerPayInformation.appGrossAnnualIncome,
        isAppMilitaryActive: powerPayInformation.isAppMilitaryActive,
        isAppUsCitizen: powerPayInformation.isAppUsCitizen,
        propertyMonths: powerPayInformation.propertyMonths,
        propertyType: powerPayInformation.propertyType,
        propertyYears: powerPayInformation.propertyYears,

        coapplicantEmployerType: powerPayInformation.hasCoApp
          ? employmentTypes[
              Object.keys(employmentTypes).find(
                key => employmentTypes[key].value === powerPayInformation.coAppEmploymentType,
              )
            ]
            ? employmentTypes[
                Object.keys(employmentTypes).find(
                  key => employmentTypes[key].value === powerPayInformation.coAppEmploymentType,
                )
              ].powerPayValue
            : null
          : null,

        coAppOccupation: powerPayInformation.hasCoApp
          ? powerPayInformation.coAppEmploymentType === employmentTypes.Retired.value
            ? employmentTypes.Retired.powerPayValue
            : powerPayInformation.coAppOccupation || null
          : null,
        coapplicantEmployerName: powerPayInformation.hasCoApp
          ? powerPayInformation.coAppEmployerName
          : null,
        coapplicantEmploymentYears: powerPayInformation.hasCoApp
          ? powerPayInformation.coAppEmploymentYears || 0
          : null,
        coapplicantEmploymentMonths: powerPayInformation.hasCoApp
          ? powerPayInformation.coAppEmploymentMonths || 1
          : null,
        coAppEmployerZipCode: powerPayInformation.hasCoApp
          ? powerPayInformation.coAppEmploymentType === employmentTypes.Retired.value
            ? powerPayInformation.coAppZipCode
            : powerPayInformation.coAppEmployerZipCode
          : null,
        coAppGrossAnnualIncome: powerPayInformation.hasCoApp
          ? powerPayInformation.coAppGrossAnnualIncome
          : null,

        isCoAppMilitaryActive: powerPayInformation.hasCoApp
          ? powerPayInformation.isCoAppMilitaryActive
          : null,

        isCoAppUsCitizen: powerPayInformation.hasCoApp
          ? powerPayInformation.isCoAppUsCitizen
          : null,

        coapplicantSsn: powerPayInformation.hasCoApp ? powerPayInformation.coAppFullSsn : null,
        coapplicantIdType: idTypes.DrivesLicense.value,
        coapplicantIdNumber: powerPayInformation.hasCoApp
          ? powerPayInformation.coAppDriversLicenseNumber
          : null,
        coapplicantDriversLicenseState: powerPayInformation.hasCoApp
          ? powerPayInformation.coAppDriversLicenseState || null
          : null,

        relationshipWithCoapplicant: powerPayInformation.hasCoApp
          ? powerPayInformation.relationshipWithCoapplicant || null
          : null,
        coapplicantFirstName: powerPayInformation.hasCoApp
          ? powerPayInformation.coAppFirstName || null
          : null,
        coapplicantLastName: powerPayInformation.hasCoApp
          ? powerPayInformation.coAppLastName || null
          : null,
        coapplicantMiddleName: powerPayInformation.hasCoApp
          ? powerPayInformation.coAppMiddleInitial || null
          : null,
        coapplicantPhoneNumber: powerPayInformation.hasCoApp
          ? powerPayInformation.coAppPhoneNumber || null
          : null,
        coapplicantEmail: powerPayInformation.hasCoApp
          ? powerPayInformation.coAppEmail || null
          : null,
        coapplicantStreet: powerPayInformation.hasCoApp
          ? powerPayInformation.coAppStreet || null
          : null,
        coAppPoBox: powerPayInformation.hasCoApp ? powerPayInformation.coAppPOBox || null : null,
        coapplicantCity: powerPayInformation.hasCoApp
          ? powerPayInformation.coAppCity || null
          : null,
        coapplicantZipcode: powerPayInformation.hasCoApp
          ? powerPayInformation.coAppZipCode || null
          : null,
        coapplicantState: powerPayInformation.hasCoApp
          ? powerPayInformation.coAppState || null
          : null,
        coapplicantBirthday: powerPayInformation.hasCoApp
          ? powerPayInformation.coAppBirthday || null
          : null,

        selectedFinancier: selectedFinancierEnum.POWER_PAY.value,
        emulatorId: emulatorId,
        track_id: track_id,
      };

      dispatchSubmitMindfire(mindfireRequest);
      dispatchSubmitPowerPayApplication(
        softPullRequest,
        PowerPayFlowPageNumber.ApplicationResult,
        app,
      );
    },
  })(ApplicationReviewForm);

  return (
    <Container>
      <Row>
        {isEditable ? (
          <BackBtn
            changePage={dispatchChangePage}
            page={
              powerPayInformation.hasCoApp
                ? PowerPayFlowPageNumber.CoAppEmploymentDetails
                : PowerPayFlowPageNumber.HasCoApplicant
            }
          />
        ) : (
          <Col sm="1" />
        )}
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <Row className="review">
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col xs="8" lg="9">
                  <h5>Applicant’s Information</h5>
                </Col>
                {isEditable ? (
                  <Col xs="4" lg="3">
                    <Button
                      className="edit"
                      clickHandler={() => editPage(PowerPayFlowPageNumber.AppIdentificationInfo)}
                      title="Edit"
                    />
                  </Col>
                ) : null}
              </Row>
              <Row>
                <Col xs="6">
                  <b>Name</b>
                  {(pqi.firstName ? pqi.firstName + ' ' : '') +
                    (pqi.middleName ? pqi.middleName + ' ' : '') +
                    (pqi.lastName || '')}
                </Col>
                <Col xs="6">
                  <b>Phone Number</b>
                  {!!pqi.phoneNumber
                    ? pqi.phoneNumber.length > 6
                      ? '(' +
                        pqi.phoneNumber.substring(0, 3) +
                        ') ' +
                        pqi.phoneNumber.substring(3, 6) +
                        '-' +
                        pqi.phoneNumber.substring(6)
                      : pqi.phoneNumber
                    : 'N/A'}
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <b>Email Address</b>
                  <span className="text-break">{pqi.email || 'N/A'}</span>
                </Col>
                <Col xs="6">
                  <b>Date of Birth</b>
                  {pqi.birthday || 'N/A'}
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <b>Full SSN</b>
                  {powerPayInformation.appFullSsn
                    ? 'xxxxx' + powerPayInformation.appFullSsn.slice(5, 9)
                    : 'N/A'}
                </Col>
                <Col xs="6">
                  <b>Photo ID Information (Drivers License)</b>
                  <span className="text-break">{powerPayInformation.appDriversLicenseNumber}</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <b>Applicant’s Address</b>
                  {appAddress}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="review">
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col xs="8" lg="9">
                  <h5>Applicant’s Income Information</h5>
                </Col>
                {isEditable ? (
                  <Col xs="4" lg="3">
                    <Button
                      className="edit"
                      clickHandler={() => editPage(PowerPayFlowPageNumber.AppEmploymentDetails)}
                      title="Edit"
                    />
                  </Col>
                ) : null}
              </Row>
              <Row>
                <Col xs="6">
                  <b>Income (or Benefits) Type</b>
                  {Object.keys(employmentTypes).find(
                    key => key === powerPayInformation.appEmploymentType,
                  )}
                </Col>
                <Col xs="6">
                  <b>Annual Income (or Benefits) Amount</b>
                  <NumberFormat
                    value={powerPayInformation.appGrossAnnualIncome}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={'$'}
                    displayType="text"
                  />
                </Col>
              </Row>
              {powerPayInformation.appEmploymentType === employmentTypes.Employed.value && (
                <>
                  <Row>
                    <Col xs="6">
                      <b>Employer Name</b>
                      {powerPayInformation.appEmployerName}
                    </Col>
                    <Col xs="6">
                      <b>Employment Time</b>
                      {powerPayInformation.appEmploymentYears +
                        ' Year(s)' +
                        (powerPayInformation.appEmploymentMonths &&
                        powerPayInformation.appEmploymentMonths !== '0'
                          ? ' and ' + powerPayInformation.appEmploymentMonths + ' Month(s)'
                          : '')}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">
                      <b>Employer Zip Code</b>
                      {powerPayInformation.appEmployerZipCode}
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
          <Row className="review">
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col xs="8" lg="9">
                  <h5>Co-Applicant’s Information</h5>
                </Col>
                {isEditable ? (
                  <Col xs="4" lg="3">
                    <Button
                      className="edit"
                      clickHandler={() =>
                        editPage(
                          powerPayInformation.hasCoApp
                            ? PowerPayFlowPageNumber.CoAppInfo
                            : PowerPayFlowPageNumber.HasCoApplicant,
                        )
                      }
                      title={powerPayInformation.hasCoApp ? 'Edit' : 'Add'}
                    />
                  </Col>
                ) : null}
              </Row>
              {powerPayInformation.hasCoApp && (
                <>
                  <Row>
                    <Col xs="6">
                      <b>Name</b>
                      {(powerPayInformation.coAppFirstName
                        ? powerPayInformation.coAppFirstName + ' '
                        : '') +
                        (powerPayInformation.coAppMiddleName
                          ? powerPayInformation.coAppMiddleName + ' '
                          : '') +
                        (powerPayInformation.coAppLastName || '')}
                    </Col>
                    <Col xs="6">
                      <b>Phone Number</b>
                      {!!powerPayInformation.coAppPhoneNumber
                        ? powerPayInformation.coAppPhoneNumber.length > 6
                          ? '(' +
                            powerPayInformation.coAppPhoneNumber.substring(0, 3) +
                            ') ' +
                            powerPayInformation.coAppPhoneNumber.substring(3, 6) +
                            '-' +
                            powerPayInformation.coAppPhoneNumber.substring(6)
                          : powerPayInformation.coAppPhoneNumber
                        : 'N/A'}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">
                      <b>Email Address</b>
                      <span className="text-break">{powerPayInformation.coAppEmail || 'N/A'}</span>
                    </Col>
                    <Col xs="6">
                      <b>Date of Birth</b>
                      {powerPayInformation.coAppBirthday || 'N/A'}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">
                      <b>Full SSN</b>
                      {powerPayInformation.coAppFullSsn
                        ? 'xxxxx' + powerPayInformation.coAppFullSsn.slice(5, 9)
                        : 'N/A'}
                    </Col>
                    <Col xs="6">
                      <b>Photo ID Information (Drivers License)</b>
                      <span className="text-break">
                        {powerPayInformation.coAppDriversLicenseNumber || 'N/A'}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <b>Co-Applicant’s Address</b>
                      {coApplicantAddress}
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
          {powerPayInformation.hasCoApp && (
            <Row className="review">
              <Col>
                <Row className="align-items-center justify-content-between">
                  <Col xs="8" lg="9">
                    <h5>Co-Applicant’s Income Information</h5>
                  </Col>
                  {isEditable ? (
                    <Col xs="4" lg="3">
                      <Button
                        className="edit"
                        clickHandler={() => editPage(PowerPayFlowPageNumber.CoAppEmploymentDetails)}
                        title="Edit"
                      />
                    </Col>
                  ) : null}
                </Row>
                <Row>
                  <Col xs="6">
                    <b>Income (or Benefits) Type</b>
                    {Object.keys(employmentTypes).find(
                      key => key === powerPayInformation.coAppEmploymentType,
                    )}
                  </Col>
                  <Col xs="6">
                    <b>Annual Income (or Benefits) Amount</b>
                    <NumberFormat
                      value={powerPayInformation.coAppGrossAnnualIncome}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix={'$'}
                      displayType="text"
                    />
                  </Col>
                </Row>
                {powerPayInformation.coAppEmploymentType === employmentTypes.Employed.value && (
                  <>
                    <Row>
                      <Col xs="6">
                        <b>Employer Name</b>
                        {powerPayInformation.coAppEmployerName}
                      </Col>
                      <Col xs="6">
                        <b>Employment Time</b>
                        {powerPayInformation.coAppEmploymentYears +
                          ' Year(s)' +
                          (powerPayInformation.coAppEmploymentMonths &&
                          powerPayInformation.coAppEmploymentMonths !== '0'
                            ? ' and ' + powerPayInformation.coAppEmploymentMonths + ' Month(s)'
                            : '')}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6">
                        <b>Employer Zip Code</b>
                        {powerPayInformation.coAppEmployerZipCode}
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
          )}
          <ApplicationReviewFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  consumerId: state.auth.consumerId,
  aquaFinanceAssigned: state.auth.aquaFinanceAssigned,
  applicantAddress: state.powerPayInformation.applicantAddress,
  powerPayInformation: state.powerPayInformation,
  pqi: state.preQualificationInformation,
  softPull: state.softPull,
  app: state.softPull.applicationSubmitDTO,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  emulatorId: state.auth.emulatorId,
  track_id: state.auth.track_id,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangePowerPayInfoValue: (key, value) => dispatch(changePowerPayInfoValue(key, value)),
  dispatchSubmitPowerPayApplication: (params, page, app) =>
    dispatch(submitPowerPayApplication(params, page, app)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

ApplicationReview.propTypes = {
  dispatchChangePage: PropTypes.func,
  dispatchChangePowerPayInfoValue: PropTypes.func,
  dispatchSubmitSoftPullAquaApplication: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApplicationReview);
