import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  setFlow,
  getReCapthcaKey,
  changeGSRegisterAsContractorInfoValue,
  getUser,
} from '../../redux/actions/index';
import queryString from 'query-string';

const Aqua = ({
  app,
  score,
  history,
  dispatchSetFlow,
  flowName,
  dispatchChangeValue,
  dispatchGetReCapthcaKey,
  dispatchGetUser,
  ...props
}) => {
  useEffect(() => {
    const userId = queryString.parse(window.location.search).userId;
    const guid = queryString.parse(window.location.search).guid;

    /*console.log('----------- userId', userId);
    console.log('----------- guid', guid);*/

    if (userId && guid) {
      dispatchGetUser(userId, guid, history);
    }

    // eslint-disable-next-line
  }, []);

  return <></>;
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  dispatchSetFlow: flow => dispatch(setFlow(flow)),
  dispatchChangeValue: (key, value) => dispatch(changeGSRegisterAsContractorInfoValue(key, value)),
  dispatchGetReCapthcaKey: () => dispatch(getReCapthcaKey()),
  dispatchGetUser: (userId, guid, history) => dispatch(getUser(userId, guid, history)),
});

Aqua.propTypes = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Aqua);
