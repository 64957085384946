import React, { useState } from 'react';
import InputFeedback from '../InputFeedback/InputFeedback';
import { Input } from 'reactstrap';
import { IoMdLock, IoMdEyeOff } from 'react-icons/io';
import punycode from 'punycode';

const InputFieldAll = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched, setFieldValue },
  icon,
  max,
  maxLength,
  className,
  pattern,
  disabled,
  number,
  label,
  labelClassName,
  type,
  id,
  formGroupClassName,
  readOnly,
  ...props
}) => {
  const [inputType, setType] = useState(type);
  return inputType !== 'hidden' ? (
    <>
      <div className="input-with-icon">
        <Input
          name={name}
          id={id}
          type={inputType === 'password' ? 'text' : inputType}
          value={value}
          disabled={disabled}
          readOnly={readOnly}
          checked={value}
          onChange={e =>
            number
              ? /^\d*$/.test(e.currentTarget.value)
                ? setFieldValue(name, e.currentTarget.value)
                : null
              : !/[?]/.test(e.currentTarget.value) &&
                !/[À-ÖØ-öø-ÿ]/.test(punycode.toUnicode(e.currentTarget.value))
              ? setFieldValue(name, punycode.toUnicode(e.currentTarget.value))
              : null
          }
          onBlur={onBlur}
          pattern={pattern}
          className={
            'form-control ' + (className || '') + (inputType === 'password' ? ' password' : '')
          }
          autoComplete="off"
          maxLength={maxLength}
          max={max}
        />
        {icon === 'eye' ? (
          <IoMdEyeOff
            onClick={() => {
              setType(inputType === 'text' ? 'password' : 'text');
            }}
            className="icon"
          />
        ) : null}
        {icon === 'lock' ? (
          <IoMdLock
            onClick={() => {
              setType(inputType === 'text' ? 'password' : 'text');
            }}
            className="icon"
          />
        ) : null}
      </div>
      {touched[name] && <InputFeedback error={errors[name]} />}
    </>
  ) : (
    <InputFeedback error={errors[name]} />
  );
};

export default InputFieldAll;
