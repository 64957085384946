import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import AirConditioningSystemEnum from '../../assets/js/AirConditioningSystemEnum';
import BackBtn from '../../components/UI/BackButton/BackButton';
import Button from '../../components/UI/Button/CustomButton';
import { reactGAEvent } from '../../helper';
import { AquaPreQualificationFlowPageNumber } from './preQualificationFlow';

const LoanForMicrof = props => {
  const {
    email,
    dispatchChangePage,
    dispatchChangeValue,
    dealerUserId,
    did,
    children,
    aquaFinanceAssigned,
    dispatchSubmitMindfire,
    app,
  } = props;

  const mindfireRequest = {
    email: email,
    lastPage: '0011',
    dealerUserId: dealerUserId,
    did: did,
  };

  return (
    <Container>
      <Row>
        {app == null ? (
          <BackBtn
            changePage={dispatchChangePage}
            page={
              !!dealerUserId && did === null
                ? AquaPreQualificationFlowPageNumber.AccountEmail
                : AquaPreQualificationFlowPageNumber.AccountPassword
            }
          />
        ) : (
          <Col sm="1" />
        )}
        <Col sm="5">
          {children}
          {aquaFinanceAssigned ? (
            <p>{''}</p>
          ) : (
            <p>
              Answer some questions so we can find the best payment options. We offer both credit
              and lease purchase products. Not all contractors offer all products.
            </p>
          )}
        </Col>
        <Col sm="6">
          <Button
            className="btn btn-primary"
            title="HVAC and/or Water Heater"
            clickHandler={() => {
              reactGAEvent({
                category: 'Button',
                action: 'Click',
                label: 'HVAC and/or Water Heater',
                did,
                dealerUserId,
              });
              dispatchChangeValue(
                'airConditioningSystem',
                AirConditioningSystemEnum.AIR_CONDITION_OR_HEATING_SYSTEM.id,
              );
              dispatchChangePage(AquaPreQualificationFlowPageNumber.LoanAmount);
              dispatchSubmitMindfire(mindfireRequest);
            }}
          />
          {aquaFinanceAssigned ? (
            <Button
              className="btn btn-primary"
              pClassName="top-20"
              title="Other Home Improvement"
              clickHandler={() => {
                reactGAEvent({
                  category: 'Button',
                  action: 'Click',
                  label: 'Other Home Improvement',
                  did,
                  dealerUserId,
                });
                dispatchChangeValue(
                  'airConditioningSystem',
                  AirConditioningSystemEnum.OTHER_HOME_IMPROVEMENT.id,
                );
                dispatchChangePage(AquaPreQualificationFlowPageNumber.LoanAmount);
                dispatchSubmitMindfire(mindfireRequest);
              }}
            />
          ) : null}
        </Col>
      </Row>
    </Container>
  );
};

export default LoanForMicrof;
