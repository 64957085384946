import UserTypeEnum from './UserTypeEnum';
import UserRoleEnum from './UserRoleEnum';

const userOperationEnum = {
  CREATE: [
    {
      userType: UserTypeEnum.USER_INTERNAL,
      userRoles: [UserRoleEnum.ADMINISTRATOR, UserRoleEnum.USER_ADMINISTRATOR],
    },
    {
      userType: UserTypeEnum.USER_DEALER,
      userRoles: [UserRoleEnum.DEALER_ADMIN],
    },
  ],
  SEARCH: [
    {
      userType: UserTypeEnum.USER_INTERNAL,
      userRoles: [UserRoleEnum.ADMINISTRATOR, UserRoleEnum.USER_ADMINISTRATOR],
    },
    {
      userType: UserTypeEnum.USER_DEALER,
      userRoles: [
        UserRoleEnum.DEALER_RELATIONSHIP_REP,
        UserRoleEnum.USER_ADMINISTRATOR,
        UserRoleEnum.ADMINISTRATOR,
        UserRoleEnum.DEALER_ADMIN,
        UserRoleEnum.DEALER_EMPLOYEE,
      ],
    },
    {
      userType: UserTypeEnum.USER_CONSUMER,
      userRoles: [
        UserRoleEnum.RELATIONSHIP_MANAGER,
        UserRoleEnum.USER_ADMINISTRATOR,
        UserRoleEnum.ADMINISTRATOR,
      ],
    },
  ],
  VIEW: [
    {
      userType: UserTypeEnum.USER_INTERNAL,
      userRoles: [UserRoleEnum.ADMINISTRATOR, UserRoleEnum.USER_ADMINISTRATOR],
    },
    {
      userType: UserTypeEnum.USER_DEALER,
      userRoles: [
        UserRoleEnum.DEALER_RELATIONSHIP_REP,
        UserRoleEnum.USER_ADMINISTRATOR,
        UserRoleEnum.ADMINISTRATOR,
        UserRoleEnum.DEALER_ADMIN,
      ],
    },
    {
      userType: UserTypeEnum.USER_CONSUMER,
      userRoles: [
        UserRoleEnum.RELATIONSHIP_MANAGER,
        UserRoleEnum.USER_ADMINISTRATOR,
        UserRoleEnum.ADMINISTRATOR,
      ],
    },
  ],
  UPDATE: [
    {
      userType: UserTypeEnum.USER_INTERNAL,
      userRoles: [UserRoleEnum.USER_ADMINISTRATOR],
    },
    {
      userType: UserTypeEnum.USER_DEALER,
      userRoles: [
        UserRoleEnum.DEALER_RELATIONSHIP_REP,
        UserRoleEnum.USER_ADMINISTRATOR,
        UserRoleEnum.DEALER_ADMIN,
      ],
    },
    {
      userType: UserTypeEnum.USER_CONSUMER,
      userRoles: [UserRoleEnum.RELATIONSHIP_MANAGER, UserRoleEnum.USER_ADMINISTRATOR],
    },
  ],
  EMULATE: [
    {
      userType: UserTypeEnum.USER_DEALER,
      userRoles: [UserRoleEnum.DEALER_RELATIONSHIP_REP],
    },
    {
      userType: UserTypeEnum.USER_CONSUMER,
      userRoles: [UserRoleEnum.RELATIONSHIP_MANAGER],
    },
  ],
  DELETE: [
    {
      userType: UserTypeEnum.USER_INTERNAL,
      userRoles: [UserRoleEnum.USER_ADMINISTRATOR],
    },
    {
      userType: UserTypeEnum.USER_DEALER,
      userRoles: [UserRoleEnum.DEALER_ADMIN, UserRoleEnum.USER_ADMINISTRATOR],
    },
  ],
};

export default userOperationEnum;

export const isOperationAuthorized = (operation, actorRole, userType) => {
  if (!operation || !actorRole || !userType) {
    return false;
  }

  for (let key in operation) {
    if (operation[key].userType === userType) {
      for (let keyRole in operation[key].userRoles) {
        if (operation[key].userRoles[keyRole] === actorRole) {
          return true;
        }
      }
    }
  }
  return false;
};
