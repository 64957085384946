import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import YesNoComponent from '../../../../components/UI/YesNoComponent/YesNoComponent';
import { reactGAEvent, reactGAPageview } from '../../../../helper';
import {
  changePage,
  changeFoundationInfoValue,
  submitMindfire,
} from '../../../../redux/actions/index';
import { FoundationFlowPageNumber } from './FoundationFlow';

const HasCoApplicant = props => {
  const {
    isReview,
    dispatchChangePage,
    dispatchChangeFoundationInfoValue,
    children,
    dispatchSubmitMindfire,
    email,
    did,
    dealerUserId,
    coApplicantAdded,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_fdcoappoption',
      trackerNames: [],
      title: 'ca_fdcoappoption',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const mindFireRequest = {
    email: email,
    lastPage: '10015',
    dealerUserId: dealerUserId,
    did: did,
  };

  return (
    <YesNoComponent
      backpage={
        isReview
          ? FoundationFlowPageNumber.ApplicationReview
          : FoundationFlowPageNumber.AppOtherSourceOfIncome
      }
      upperButtonText="Yes"
      lowerButtonText="No"
      upperBtnClickHandler={() => {
        reactGAEvent({
          category: 'Button',
          action: 'Click',
          label: 'Yes',
          did,
          dealerUserId,
        });
        dispatchChangePage(FoundationFlowPageNumber.CoAppInfo);
        dispatchChangeFoundationInfoValue('hasCoApp', true);
        dispatchSubmitMindfire(mindFireRequest);
      }}
      lowerBtnClickHandler={() => {
        reactGAEvent({
          category: 'Button',
          action: 'Click',
          label: 'No',
          did,
          dealerUserId,
        });
        dispatchChangePage(FoundationFlowPageNumber.ApplicationReview);
        dispatchChangeFoundationInfoValue('isReview', null);
        dispatchChangeFoundationInfoValue('hasCoApp', false);
        dispatchSubmitMindfire(mindFireRequest);
      }}
      lowerButtonDisabled={!!coApplicantAdded}
    >
      {children}
    </YesNoComponent>
  );
};

const mapStateToProps = state => ({
  isReview: state.foundationInformation.isReview,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  coApplicantAdded:
    state.softPull.applicationSubmitDTO !== null
      ? state.softPull.applicationSubmitDTO.applicantNonPrimary
      : null,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeFoundationInfoValue: (key, value) =>
    dispatch(changeFoundationInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

HasCoApplicant.propTypes = {
  dispatchChangePage: PropTypes.func,
  dispatchChangeFoundationInfoValue: PropTypes.func,
  isReview: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HasCoApplicant);
