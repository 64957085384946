import { ADD_NOTIFICATION, REMOVE_NOTIFICATION, START_LOADING, END_LOADING } from './actionTypes';

export const addNotification = params => ({
  type: ADD_NOTIFICATION,
  params,
});

export const removeNotification = () => ({
  type: REMOVE_NOTIFICATION,
});

export const startLoading = loadingMessage => ({
  type: START_LOADING,
  loadingMessage,
});

export const endLoading = () => ({
  type: END_LOADING,
});
