import React from 'react';
import NumberFormat from 'react-number-format';
import { Col, Row } from 'reactstrap';
import './AquaProductOffer.scss';

const AquaProductOffer = ({ children }) => (
  <div className="aqua-product-offer">
    <h6>AQUA PRODUCT OFFER</h6>
    {children}
    <div className="mt-4">
      <Row className="align-items-end">
        <Col sm="6">
          <label className="d-block">Product Description</label>
        </Col>
        <Col sm="6">
          <label className="d-block">Repayment factor</label>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm="6">Standard line of credit </Col>
        <Col sm="6">
          <NumberFormat
            value={1.55}
            thousandSeparator={true}
            fixedDecimalScale={true}
            decimalScale={2}
            displayType="text"
          />
          %
        </Col>
      </Row>
      <Row className="align-items-end">
        <Col sm="6">
          <label className="d-block">Payment on a $7000 Loan</label>
        </Col>
        <Col sm="6">
          <label className="d-block">APR</label>
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          <NumberFormat
            value={1235.6}
            thousandSeparator={true}
            fixedDecimalScale={true}
            decimalScale={2}
            prefix={'$'}
            displayType="text"
          />
        </Col>
        <Col sm="6">
          <NumberFormat
            value={12.56}
            thousandSeparator={true}
            fixedDecimalScale={true}
            decimalScale={2}
            displayType="text"
          />
          %
        </Col>
      </Row>
    </div>
  </div>
);

export default AquaProductOffer;
