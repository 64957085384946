import React from 'react';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import { searchContractorUsers } from '../../../redux/actions';
import ContractorUsersSearch from '../ManageContractorUsers/ContractorUsersSearch';
import ContractorUsersTable from '../ManageContractorUsers/ContractorUsersTable';

const SearchContractor = props => {
  const {
    setPageIndex,
    application,
    contractorUsers,
    pageIndex,
    dispatchSearchContractorUsers,
    searchOptionUsers,
  } = props;
  const pageLimit = 20;
  let pageCount = contractorUsers && contractorUsers.total / pageLimit;

  const handlePageClick = pageNumber => {
    dispatchSearchContractorUsers({
      ...searchOptionUsers,
      pageOffset: pageNumber.selected * pageLimit,
    });
  };

  const style = {
    width: ((pageIndex + 1) * 33).toString() + '%',
  };

  return (
    <Col>
      <h2 id="panelTitle">Search Contractor</h2>
      <Row noGutters>
        <Col xs="12" className="progress">
          <div className="active" style={style} />
        </Col>
      </Row>
      <Row noGutters className="panel">
        <Col className="panel-body larger">
          <Row noGutters>
            <table className="table mb-0">
              <thead className="thead-light">
                <tr>
                  <th>Application data</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>RTO-Number</td>
                  <td>{application.rtoNumber}</td>
                </tr>
                <tr>
                  <td>App ID</td>
                  <td>{application.applicationId}</td>
                </tr>
                <tr>
                  <td>Current Contractor</td>
                  <td>{application.dealer ? application.dealer.name : 'N/A'}</td>
                </tr>
                <tr>
                  <td>Current Contractor User</td>
                  <td>
                    {application.dealerUser && application.dealerUser.dealerUser
                      ? application.dealerUser.dealerUser.email
                      : 'N/A'}
                  </td>
                </tr>
              </tbody>
            </table>
          </Row>
          <Row noGutters>
            <Col className="text-right">
              <Button
                type="submit"
                color="primary"
                className="rounded-pill"
                onClick={() => {
                  setPageIndex(0);
                }}
              >
                Previous
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <ContractorUsersSearch
        searchOptionUsers={searchOptionUsers}
        searchContractorUsers={dispatchSearchContractorUsers}
      />
      <Row noGutters className="panel">
        <Col className="panel-body larger">
          <Row>
            <Col className="right-bordered">
              {contractorUsers && contractorUsers.total > 0 ? (
                <>
                  <Row className="overflow-auto">
                    <ContractorUsersTable
                      contractorUsers={contractorUsers}
                      hasAction={true}
                      setPageIndex={setPageIndex}
                    />
                  </Row>
                  <Row className="justify-content-center align-items-center mt-4">
                    <ReactPaginate
                      previousLabel={'<'}
                      nextLabel={'>'}
                      breakClassName={'break-me'}
                      breakLinkClassName={'page-link'}
                      breakLabel={'...'}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={`pagination ${pageCount <= 1 ? 'd-none' : ''}`}
                      subContainerClassName={'pages pagination'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      activeClassName={'active'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                    />
                  </Row>
                </>
              ) : (
                <>No users found.</>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

const mapStateToProps = state => ({
  dealerId: state.auth.dealerId,
  application: state.softPull.applicationMoveApp,
  contractorUsers: state.softPull.contractorUsers,
  searchOptionUsers: state.softPull.searchOptionUsers,
});

const mapDispatchToProps = dispatch => ({
  dispatchSearchContractorUsers: searchOptions => dispatch(searchContractorUsers(searchOptions)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchContractor);
