const HvacSystemTypeEnum = {
  SPLIT_SYSTEM: {
    value: 'SS',
    name: 'Split System',
    description: 'The equipment is located inside and outside of the home.',
  },
  PACKAGE_SYSTEM: {
    value: 'PS',
    name: 'Package System',
    description:
      'The heating and cooling unit is located outside of the home, either on the ground or the roof.',
  },
  MINI_SPLIT_SYSTEM: {
    value: 'MS',
    name: 'Mini-Split System (Ductless)',
    description:
      'The equipment is located inside and outside of the home without any ductwork inside.',
  },
};

export default HvacSystemTypeEnum;
