import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
import { initialState } from '../initialState';

const loginSuccess = (state, action) => {
  const updatedValues = {
    isAuthenticated: true,
    user: {
      user: action.payload,
      consumerUser:
        action.payload.consumerUser ||
        (action.payload.user ? action.payload.user.consumerUser : null),
      dealerUser:
        action.payload.dealerUser || (action.payload.user ? action.payload.user.dealerUser : null),
      internalUser:
        action.payload.internalUser ||
        (action.payload.user ? action.payload.user.internalUser : null),
    },
  };
  return updateObject(state, updatedValues);
};

const profileUpdate = (state, action) => {
  const updatedValues = {
    ...state,
    user: {
      ...state.user,
      user: {
        ...state.user.user,
        user: {
          ...state.user.user.user,
          consumerUser: action.payload.consumerUser || null,
          dealerUser: action.payload.dealerUser || null,
          internalUser: action.payload.internalUser || null,
        },
        consumerUser: action.payload.consumerUser || null,
        dealerUser: action.payload.dealerUser || null,
        internalUser: action.payload.internalUser || null,
      },
      consumerUser: action.payload.consumerUser || null,
      dealerUser: action.payload.dealerUser || null,
      internalUser: action.payload.internalUser || null,
    },
  };

  return updateObject(state, updatedValues);
};

const loginError = (state, action) => {
  const updatedValues = {
    error: action.payload,
  };
  return updateObject(state, updatedValues);
};

const logout = state => {
  const updatedValues = {
    isAuthenticated: false,
    user: {
      user: null,
      consumerUser: null,
      dealerUser: null,
    },
  };
  return updateObject(state, updatedValues);
};

const changeValue = (state, action) => {
  const updatedValues = {
    [action.key]: action.value,
  };
  return updateObject(state, updatedValues);
};

const updateDealerStateSummary = (state, action) => {
  const updatedValues = {
    ...state,
    user: {
      ...state.user,
      user: {
        ...state.user.user,
        dealerStatsSummaryDTO: action.payload,
      },
    },
  };
  return updateObject(state, updatedValues);
};

const updateCurrentDealer = (state, action) => {
  const updatedValues = {
    ...state,
    user: {
      ...state.user,
      dealerUser: action.payload,
    },
  };
  return updateObject(state, updatedValues);
};

const authReducer = (state, action) => {
  if (!state) {
    state = initialState.auth;
  }
  switch (action.type) {
    case actionTypes.UPDATE_DEALER_STATS_SUMMARY:
      return updateDealerStateSummary(state, action);
    case actionTypes.CHANGE_AUTH_VALUE:
      return changeValue(state, action);
    case actionTypes.LOGIN_SUCCESS:
      return loginSuccess(state, action);
    case actionTypes.PROFILE_UPDATE:
      return profileUpdate(state, action);
    case actionTypes.LOGIN_ERROR:
      return loginError(state, action);
    case actionTypes.LOGOUT:
      return logout(state);
    case actionTypes.RESET_STATE_AUTH:
      state = initialState.auth;
      return state;
    case actionTypes.UPDATE_DEALER_VALUE:
      return updateCurrentDealer(state, action);
    default:
      return state;
  }
};

export default authReducer;
