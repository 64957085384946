import AccountEmail from './AccountEmail';
import React from 'react';
import AccountPassword from './AccountPassword';
import LoanFor from './LoanFor';
import NotAllowed from './NotAllowed';
import ScoreError from './ScoreError';
import UserAddress from './UserAddress';
import UserInfo from './UserInfo';
import UserLastInfo from './UserLastInfo';
import InvalidResponse from './InvalidResponse';

const applicantInformationTitle = `Applicant Information`;

const PreQualificationFlow = [
  //1
  <AccountEmail title={applicantInformationTitle}>
    <h4>Let’s start with your email address.</h4>
  </AccountEmail>,
  //2
  <AccountPassword title={applicantInformationTitle}>
    <h4>Choose a password that you can remember.</h4>
  </AccountPassword>,
  //3
  <LoanFor title={applicantInformationTitle} />,
  //4
  <UserInfo title={applicantInformationTitle}>
    <h4>What’s your name and number?</h4>
  </UserInfo>,
  //5
  <UserAddress title={applicantInformationTitle}>
    <h4>What is the address where the service will be completed?</h4>
  </UserAddress>,
  //6
  <UserLastInfo title={applicantInformationTitle} />,
  //7
  <NotAllowed title={applicantInformationTitle} />,
  //8
  <ScoreError title={applicantInformationTitle}>
    <h4>We could not return a Soft Pull score.</h4>
  </ScoreError>,
  <InvalidResponse />,
];

export default PreQualificationFlow;

export const EnerBankPreQualificationFlowPageNumber = {
  AccountEmail: 0,
  AccountPassword: 1,
  LoanFor: 2,
  UserInfo: 3,
  UserAddress: 4,
  UserLastInfo: 5,
  NotAllowed: 6,
  ScoreError: 7,
  InvalidResponse: 8,
};
