import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
import { initialState } from '../initialState';

const changeValue = (state, action) => {
  const updatedValues = {
    [action.key]: action.value,
  };
  return updateObject(state, updatedValues);
};

const aquaFinanceLogin = (state, action) => {
  const updatedValues = {
    samlResponse: action.payload,
    error: null,
  };
  return updateObject(state, updatedValues);
};

const setError = (state, action) => {
  const updatedValues = {
    error: action.payload,
  };
  return updateObject(state, updatedValues);
};

const aquaReducer = (state, action) => {
  if (!state) {
    state = initialState.aquaInformation;
  }
  switch (action.type) {
    case actionTypes.CHANGE_AQUA_INFO_VALUE:
      return changeValue(state, action);
    case actionTypes.RESET_STATE_AQUA:
      state = initialState.aquaInformation;
      return state;
    case actionTypes.AQUA_FINANCE_LOGIN_SUCCESS:
      return aquaFinanceLogin(state, action);
    case actionTypes.AQUA_FINANCE_LOGIN_ERROR:
      return setError(state, action);
    default:
      return state;
  }
};

export default aquaReducer;
