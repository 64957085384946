import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import otherSourcesOfIncome from '../../../assets/js/OtherSourceOfIncomeEnum';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import CurrencyFormat from '../../../components/UI/Form/NumberFormat/CurrencyFormat';
import Select from '../../../components/UI/Form/Select/Select';
import { changeAquaInfoValue, changePage, submitMindfire } from '../../../redux/actions/index';
import PropTypes from 'prop-types';
import { reactGAPageview, reactGAEvent } from '../../../helper';
import { AquaFlowPageNumber } from '../AquaFlow';
import {
  COAPP_ADDITIONAL_INCOME_TOOLTIP_TEXT,
  COAPP_OTHER_SOURCES_OF_INCOME_TOOLTIP_TEXT,
} from '../../../constants/tooltipTexts';

const CoAppOtherIncome = props => {
  const {
    dispatchChangePage,
    dispatchChangeValue,
    aquaInformation,
    children,
    hasCoAppOtherIncome,
    email,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_aqcoappincome2',
      trackerNames: [],
      title: 'ca_aqcoappincome2',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  let otherSourcesOfIncomeChanged = { ...otherSourcesOfIncome };
  delete otherSourcesOfIncomeChanged[aquaInformation.appIncomeSource];

  const CoAppOtherIncomeForm = () => (
    <Form>
      <label>
        <b>Do you have other sources of income (or benefits)?</b>
      </label>
      <Row>
        <Col sm="6">
          <Button
            className={hasCoAppOtherIncome ? '' : 'inactive'}
            type="button"
            title="Yes"
            clickHandler={() => dispatchChangeValue('hasCoAppOtherIncome', true)}
          />
        </Col>
        <Col sm="6">
          <Button
            className={!hasCoAppOtherIncome ? '' : 'inactive'}
            type="button"
            title="No"
            clickHandler={() => dispatchChangeValue('hasCoAppOtherIncome', false)}
          />
        </Col>
      </Row>
      <div className={hasCoAppOtherIncome ? '' : 'opacity-05'}>
        <Field
          disabled={!hasCoAppOtherIncome}
          component={Select}
          label="Source of other income (or benefits)?"
          name="coAppOtherSourcesOfIncome"
          id="coAppOtherSourcesOfIncome"
          tooltipText={COAPP_OTHER_SOURCES_OF_INCOME_TOOLTIP_TEXT}
          type="select"
          selectValues={otherSourcesOfIncomeChanged}
          selectFirstName="Select One"
        />
        <p className="info">
          If you have multiple other sources, select the one that generates the highest income (or
          benefits).
        </p>
      </div>
      <br />
      <br />
      <div className="position-relative">
        <div className="position-absolute annual-income-text-left">
          Now, please enter the total income (or benefits) sources
        </div>
        <Field
          component={CurrencyFormat}
          label="Co-Applicant’s Total income (or benefits)"
          name="coAppAdditionalIncome"
          id="coAppAdditionalIncome"
          tooltipText={COAPP_ADDITIONAL_INCOME_TOOLTIP_TEXT}
          type="text"
        />
      </div>
      <p className="info">
        Make sure the amount includes both your primary and other sources of income (or benefits)
        you earn before taxes.
      </p>
      <Button type="submit" title={'Next'} />
    </Form>
  );

  const CoAppOtherIncomeFormFormik = withFormik({
    mapPropsToValues: () => ({
      coAppOtherSourcesOfIncome: aquaInformation.coAppOtherSourcesOfIncome || '',
      coAppAdditionalIncome: aquaInformation.coAppAdditionalIncome || 0,
    }),

    validationSchema: Yup.object({
      coAppOtherSourcesOfIncome: hasCoAppOtherIncome
        ? Yup.string().required('Please select another source of income.')
        : Yup.string(),
      coAppAdditionalIncome: Yup.number()
        .required('Please enter the Co-Applicant’s annual income.')
        .moreThan(-1, 'Please enter a positive number.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      dispatchChangeValue('coAppOtherSourcesOfIncome', values.coAppOtherSourcesOfIncome);
      dispatchChangeValue('coAppAdditionalIncome', values.coAppAdditionalIncome);
      dispatchChangePage(AquaFlowPageNumber.CoAppAddress);
      dispatchSubmitMindfire({
        email: email,
        lastPage: '0050',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(CoAppOtherIncomeForm);

  return (
    <Container>
      <Row>
        <BackBtn changePage={dispatchChangePage} page={AquaFlowPageNumber.CoAppEmploymentDetails} />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <CoAppOtherIncomeFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  aquaInformation: state.aquaInformation,
  email: state.preQualificationInformation.email,
  hasCoAppOtherIncome: state.aquaInformation.hasCoAppOtherIncome,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) => dispatch(changeAquaInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

CoAppOtherIncome.propTypes = {
  aquaInformation: PropTypes.object,
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CoAppOtherIncome);
