import React, { useState } from 'react';
import { connect } from 'react-redux';
import Col from 'reactstrap/lib/Col';
import Button from '../../../../components/UI/Button/CustomButton';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import MultiSelectIdentityVerification from '../../../../components/UI/Form/Select/MultiSelectIdentityVerification';
import {
  changeGreenSkyApplicationInfoValue,
  postAtWaterGreenSkyIdentityVerification,
} from '../../../../redux/actions';
import { reactGAEvent } from '../../../../helper';
import Disclosure from '../Components/Disclosure';

const Questions = props => {
  const {
    did,
    dealerUserId,
    dispatchChangeGreenSkyApplicationInfoValue,
    dispatchGreenSkyIdentityVerification,
    questionsData,
    greenskyInformation,
    applicationId,
    last4SSN,
    isSpanish,
  } = props;

  const [isRequired, setIsRequired] = useState(false);

  const getUpdatedChoices = (e, question, questions) => {
    questions.find(obj => obj.questionId === question.questionId).selectedChoices = e;

    return questions;
  };

  const handleClick = () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Return to Dashboard',
      did,
      dealerUserId,
    });
    //dispatchSubmitMindfire(mindFireRequest);
    window.location.href = process.env.REACT_APP_DEALER_PORTAL_URL + '/portal/dashboard';
  };
  const getIdentityVerificationRequest = () => ({
    applicationId: greenskyInformation.greenskyApplicationId,
    last4SSN: last4SSN,
    transactionId: questionsData.transactionId,
    quiz: {
      quizId: questionsData.quiz.quizId,
      answer: questionsData.quiz.question.map(question => ({
        questionId: question.questionId,
        choiceId: question.selectedChoices.value,
      })),
    },
  });

  const formValidation = () => {
    let isValid = true;
    questionsData.quiz.question.forEach(obj => {
      if (obj.selectedChoices.length === 0) isValid = false;
    });
    return isValid;
  };

  return questionsData && questionsData.quiz ? (
    <Container>
      <Row className="justify-content-center text-align-center">
        <Col>
          <h4>Identity Verification</h4>
          <span>
            Please read the following question and select the correct answer from the options
            provided.
          </span>
        </Col>
      </Row>
      <Row className="justify-content-center text-align-center">
        <Col sm="6">
          {/* <IdentityVerificationFormFormik /> */}
          <>
            {questionsData.quiz.question.map(question => (
              <MultiSelectIdentityVerification
                errorMessage={
                  isRequired &&
                  questionsData.quiz.question[0].find(obj => obj.questionId === question.questionId)
                    .selectedChoices.length === 0 &&
                  'Please select an option.'
                }
                label={question.text}
                key={question.questionId}
                isMulti={false}
                options={question.choice?.map(obj => ({
                  value: obj.choiceId,
                  label: obj.text,
                }))}
                // selectedValues={
                //   questionsData.quiz.question.find(obj => obj.questionId === question.questionId)
                //     ?.selectedChoices || []
                // }
                onChange={e =>
                  dispatchChangeGreenSkyApplicationInfoValue('identityVerification', {
                    dataQuestions: getUpdatedChoices(e, question, [...questionsData.quiz.question]),
                  })
                }
              />
            ))}
          </>
          <Button
            title="Next"
            className="mt-4"
            clickHandler={() => {
              if (formValidation()) {
                if (isRequired) setIsRequired(false);
                // dispatchSubmitMindfire(mindFireRequest);
                dispatchGreenSkyIdentityVerification(
                  applicationId,
                  getIdentityVerificationRequest(),
                  last4SSN,
                  isSpanish,
                  greenskyInformation.greenskyApplicationId,
                );
              } else {
                setIsRequired(true);
              }
            }}
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col sm="6">
          <Disclosure />
        </Col>
      </Row>
    </Container>
  ) : (
    <Container>
      <Row className="justify-content-center text-align-center">
        <Col xs="auto" md="8">
          <h4>Identity Verification</h4>
          <h5>{questionsData.error.message}</h5>
        </Col>
      </Row>
      <Row className="justify-content-center mt-2">
        <Col xs="auto">
          <Button type="button" title="Return to Dashboard" clickHandler={handleClick} />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  questionsData: state.softPull.questionsResponse,
  last4SSN: state.preQualificationInformation.ssnLastDigits,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  user: state.auth.user,
  applicationId: state.softPull.applicationSubmitDTO.applicationId,
  greenSkyLoanAgreement: state.softPull.greenSkyLoanAgreement,
  greenskyInformation: state.softPull.applicationSubmitDTO.greenskyInformation,
  isSpanish: state.softPull.isSpanish,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangeGreenSkyApplicationInfoValue: (key, value) =>
    dispatch(changeGreenSkyApplicationInfoValue(key, value)),
  dispatchGreenSkyIdentityVerification: (
    applicationId,
    request,
    last4Ssn,
    isSpanish,
    greenskyApplicationId,
  ) =>
    dispatch(
      postAtWaterGreenSkyIdentityVerification(
        applicationId,
        request,
        last4Ssn,
        isSpanish,
        greenskyApplicationId,
      ),
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Questions);
