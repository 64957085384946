import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { IoMdCheckmark } from 'react-icons/io';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import Button from '../../components/UI/Button/CustomButton';
import { changePage, submitMindfire } from '../../redux/actions/index';
import { reactGAPageview, reactGAEvent } from '../../helper';
import { AquaFlowPageNumber } from './AquaFlow';
import AquaFinanceLogo from '../../assets/img/logo_aqua.jpg';

const AquaStartPage = ({
  dispatchChangePage,
  children,
  email,
  dispatchSubmitMindfire,
  did,
  dealerUserId,
  score,
  scoreLimit,
}) => {
  useEffect(() => {
    reactGAPageview({
      path: '/ca_aqrequirements',
      trackerNames: [],
      title: 'ca_aqrequirements',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);
  return (
    <Container>
      <Row>
        <Col sm="1" />
        <Col sm="5">
          <h4>Nice! You are eligible for financing options!</h4>
          {children}
          <div className="logo-aqua">
            <img src={AquaFinanceLogo} alt="Aqua Finance Logo" />
          </div>
        </Col>
        <Col sm="6">
          <Row className="readyToFinish">
            <Col>
              <h4>Here’s what you’ll need to finish the application:</h4>
              <p className="mt-3">
                <IoMdCheckmark className="ok" />
                The estimated value of your home
              </p>
              <p>
                <IoMdCheckmark className="ok" />
                Valid photo ID
              </p>
              <p>
                <IoMdCheckmark className="ok" />
                Social Security Number
              </p>
              <p>
                <IoMdCheckmark className="ok" />
                Your annual income (or benefits) information
              </p>
              <p>
                <IoMdCheckmark className="ok" />
                Co-Applicant’s information (if applicable)
              </p>
            </Col>
          </Row>
          <Button
            className="mt-3"
            clickHandler={() => {
              reactGAEvent({
                category: 'Button',
                action: 'Click',
                label: "Let's keep going",
                did,
                dealerUserId,
              });
              dispatchChangePage(AquaFlowPageNumber.HvacDetails);
              dispatchSubmitMindfire({
                email: email,
                lastPage: score >= scoreLimit ? '0026' : '0028',
                dealerUserId: dealerUserId,
                did: did,
              });
            }}
            title="Let's keep going"
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  score: state.softPull.softPullResponse.score,
  scoreLimit: state.softPull.softPullResponse.scoreLimit,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

AquaStartPage.propTypes = {
  dispatchChangePage: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AquaStartPage);
