import React, { useState } from 'react';
import { GoInfo } from 'react-icons/go';
import { IoMdEyeOff, IoMdLock } from 'react-icons/io';
import NumberFormat from 'react-number-format';
import { Col, FormGroup, Label, Row, Tooltip } from 'reactstrap';
import { ssnFormatting } from '../../../../helper';
import InputFeedback from '../InputFeedback/InputFeedback';

const SsnFormat = ({
  field: { name, value, onBlur },
  form: { errors, touched, setFieldValue },
  icon,
  className,
  disabled,
  label,
  labelClassName,
  type,
  id,
  tooltipText,
  hideTooltip,
  ...props
}) => {
  const [inputType, setType] = useState(type);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  return inputType !== 'hidden' ? (
    <FormGroup>
      <Label for={id} className={labelClassName}>
        <b>{label}</b>
      </Label>
      <Row className="align-items-center">
        <Col>
          <div className="input-with-icon">
            <NumberFormat
              name={name}
              id={id}
              type={inputType === 'password' ? 'tel' : inputType}
              value={value}
              disabled={disabled}
              onValueChange={e => {
                setFieldValue(name, e.formattedValue);
              }}
              onBlur={onBlur}
              className={
                'form-control ' + (className || '') + (inputType === 'password' ? ' password' : '')
              }
              autoComplete="off"
              format={ssnFormatting}
              placeholder={inputType !== 'password' ? 'xxx-xx-xxxx' : ''}
            />
            {icon === 'eye' ? (
              <IoMdEyeOff
                onClick={() => {
                  setType(inputType === 'tel' ? 'password' : 'tel');
                }}
                className="icon"
              />
            ) : null}
            {icon === 'lock' ? (
              <IoMdLock
                onClick={() => {
                  setType(inputType === 'tel' ? 'password' : 'tel');
                }}
                className="icon"
              />
            ) : null}
          </div>
        </Col>
        {!hideTooltip ? (
          <Col xs="auto" className="pl-0">
            <div id={`tooltip-${id}`} className="cursor-pointer">
              <GoInfo size={20} />
            </div>
          </Col>
        ) : null}
      </Row>
      {touched[name] && <InputFeedback error={errors[name]} />}
      {!hideTooltip ? (
        <Tooltip
          placement="right"
          target={`tooltip-${id}`}
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          autohide={false}
        >
          {tooltipText ? tooltipText : `Please insert '${label}'`}
        </Tooltip>
      ) : null}
    </FormGroup>
  ) : (
    <InputFeedback error={errors[name]} />
  );
};

export default SsnFormat;
