import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import InputFeedback from '../InputFeedback/InputFeedback';

const TextArea = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched },
  id,
  placeholder,
}) => (
  <FormGroup>
    <Input
      type="textarea"
      name={name}
      id={id}
      value={value}
      checked={value}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder ? placeholder : ''}
    />
    {touched[name] && <InputFeedback error={errors[name]} />}
  </FormGroup>
);

export default TextArea;
