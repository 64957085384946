import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import dealerRanks from '../../../assets/js/DealerRankEnum';
import Button from '../../../components/UI/Button/CustomButton';
import {
  changeAuthValue,
  changeMicrofInfoValue,
  changePreQualInfoValue,
  getDealerStats,
  resetNewAppState,
  changePage,
  setFlow,
  setDefaultTheme,
  greenskyValidateMerchant,
} from '../../../redux/actions';
import {
  defaultFlow,
  microfFlow,
  preQualificationFlow,
  powerPayPreQualificationFlow,
  mosaicPreQualificationFlow,
  wellsFargoFlow,
  foundationPreQualificationFlow,
  greenSkyPreQualificationFlow,
  enerBankPreQualificationFlow,
  atWaterPreQualificationFlow,
  atWaterGreenSkyPreQualificationFlow,
} from '../../../redux/initialState';
import { ArrowRight } from './ArrowRight';
import { ArrowUp } from './ArrowUp';
import './Dashboard.scss';
import { SummaryCard } from './SummaryCard';
import { ContractorStatusTable } from './ContractorStatusTable';
import { getIsMicrof, getIsOptimus, getIsPool, getIsRheem } from '../../../config/settings';
import SelectedFinancierEnum from '../../../assets/js/SelectedFinancierEnum';
import { AquaPreQualificationFlowPageNumber } from '../../PreQualification/preQualificationFlow';
import { MosaicPreQualificationFlowPageNumber } from '../../Mosaic/PreQualification/PreQualificationFlow';
import { PowerPayPreQualificationFlowPageNumber } from '../../PowerPay/PreQualification/PreQualificationFlow';
import { FoundationPreQualificationFlowPageNumber } from '../../Optimus/Foundation/PreQualification/PreQualificationFlow';
import { MicrofFlowPageNumber } from '../../Microf/MicrofFlow';
import { WellsFargoFlowPageNumber } from '../../WellsFargo/WellsFargoFlow';
import { GreenSkyPreQualificationFlowPageNumber } from '../../Optimus/GreenSky/PreQualification/PreQualificationFlow';
import { EnerBankPreQualificationFlowPageNumber } from '../../EnerBank/PreQualification/PreQualificationFlow';
import ApplicationFlowEnum from '../../../assets/js/ApplicationFlowEnum';
import { FTLPreQualificationFlowPageNumber } from '../../FTL/PreQualification/PreQualificationFlow';
import { AtWaterPreQualificationFlowPageNumber } from '../../Pools/AtWater/PreQualification/PreQualificationFlow';
import { AtWaterGreenSkyPreQualificationFlowPageNumber } from '../../Pools/Greensky/PreQualification/PreQualificationFlow';
import EmailConsumerForm from '../EmailConsumerForm/EmailConsumerForm';
import AddedFromEnum from '../../../assets/js/AddedFromEnum';
import { UpgradePreQualificationFlowPageNumber } from '../../Upgrade/PreQualification/preQualificationFlow';

const DashboardPage = props => {
  let progress = 'progress-background-0';
  const {
    dealerStatsSummary,
    history,
    dealerId,
    dealerUserId,
    dispatchChangeMicrofInfoValue,
    dispatchChangePage,
    dispatchSetFlow,
    dispatchChangeAuthValue,
    dispatchGetDealerStats,
    dispatchResetNewAppState,
    dispatchSetDefaultTheme,
    dispatchGreenskyValidateMerchant,
    theme,
    greenskyMerchantNumber,
    user,
    workUser,
  } = props;

  const isRheem = getIsRheem();
  const isMicrof = getIsMicrof();
  const isOptimus = getIsOptimus();
  const isPools = getIsPool();

  useEffect(() => {
    dispatchSetDefaultTheme(theme);

    dispatchResetNewAppState();
    if (!dealerStatsSummary) {
      history.push('/login');
    } else {
      dispatchGetDealerStats();
    }
    // eslint-disable-next-line
  }, []);

  const dealerRank =
    dealerRanks[
      Object.keys(dealerRanks).find(
        key =>
          dealerStatsSummary &&
          dealerStatsSummary.dealerStats &&
          dealerRanks[key].value === dealerStatsSummary.dealerStats.rank,
      )
    ];

  if (dealerStatsSummary) {
    if (
      dealerStatsSummary.openApplicationCount > 0 &&
      dealerStatsSummary.openApplicationCount < 4
    ) {
      progress = 'progress-background-25';
    } else if (
      dealerStatsSummary.openApplicationCount >= 4 &&
      dealerStatsSummary.openApplicationCount < 7
    ) {
      progress = 'progress-background-50';
    } else if (
      dealerStatsSummary.openApplicationCount >= 7 &&
      dealerStatsSummary.openApplicationCount < 10
    ) {
      progress = 'progress-background-75';
    } else if (dealerStatsSummary.openApplicationCount >= 10) {
      progress = 'progress-background-100';
    }
  }

  const showNewAppButtonEmulatorMode = workUser?.addedFrom === AddedFromEnum.MICROF.value;

  const isMaintenance =
    dealerStatsSummary?.siteMessages &&
    dealerStatsSummary?.siteMessages.length > 0 &&
    dealerStatsSummary?.siteMessages.find(x => x.message.includes('under maintenance'))
      ? true
      : false;

  return (
    <Col>
      {dealerStatsSummary?.siteMessages?.length > 0 && (
        <Row className="border rounded mb-5 bg-white pb-3 pt-3">
          <Col>
            <Row>
              <Col>
                <h3>Site Messages:</h3>
              </Col>
            </Row>
            {Object.keys(dealerStatsSummary.siteMessages).map(key => (
              <Row key={key}>
                <Col>{dealerStatsSummary.siteMessages[key].message}</Col>
              </Row>
            ))}
          </Col>
        </Row>
      )}
      {user && user.emulatorId ? (
        <Row noGutters>
          <Col md="12" xl="7">
            <h2>
              Platform:{' '}
              {AddedFromEnum[
                Object.keys(AddedFromEnum).find(
                  key => AddedFromEnum[key].value === workUser?.addedFrom,
                )
              ]?.name || 'N/A'}
            </h2>
          </Col>
        </Row>
      ) : null}
      <Row noGutters>
        <Col md="12" xl="7">
          <h1 className="dashboard-dealer-name text-break">
            {dealerStatsSummary ? dealerStatsSummary.dealerName : 'N/A'}
            {dealerStatsSummary && dealerStatsSummary.rtoOnlyDealerYn === 1 && (
              <span className="dot" />
            )}
          </h1>
        </Col>
        <Col md="12" xl="5">
          <Row className="justify-content-end">
            {isRheem && (
              <Col xs="12" md="6" className="mt-1">
                <Button
                  className="h-100"
                  clickHandler={() => {
                    dispatchChangeMicrofInfoValue('microfOnly', false);
                    dispatchChangeAuthValue('aquaFinanceAssigned', false);
                    dispatchSetFlow(preQualificationFlow);
                    dispatchChangePage(AquaPreQualificationFlowPageNumber.RheemLanding);
                    history.push('/');
                  }}
                  title="Simplicity Application"
                />
              </Col>
            )}
            {isOptimus && (
              <Col xs="12" md="6" className="mt-1">
                <Button
                  className="h-100"
                  disabled={isMaintenance}
                  clickHandler={() => {
                    if (!dealerStatsSummary?.dealerActive) {
                      history.push('/portal/deactivated-dealer');
                    } else {
                      if (
                        dealerStatsSummary.mosaicAssigned &&
                        dealerStatsSummary.defaultBankId === SelectedFinancierEnum.MOSAIC.id
                      ) {
                        dispatchChangeAuthValue('mosaicAssigned', true);
                        dispatchSetFlow(mosaicPreQualificationFlow);
                        dispatchChangePage(
                          isOptimus
                            ? MosaicPreQualificationFlowPageNumber.AccountEmail
                            : MosaicPreQualificationFlowPageNumber.Landing,
                        );
                        history.push('/mosaic-prequalification');
                      } else if (
                        dealerStatsSummary.foundationAssigned &&
                        dealerStatsSummary.defaultBankId === SelectedFinancierEnum.FOUNDATION.id
                      ) {
                        dispatchChangeAuthValue('foundationAssigned', true);
                        dispatchSetFlow(foundationPreQualificationFlow);
                        dispatchChangePage(FoundationPreQualificationFlowPageNumber.AccountEmail);
                        history.push('/foundation-prequalification');
                      } else if (
                        dealerStatsSummary.greenskyAssigned &&
                        dealerStatsSummary.defaultBankId === SelectedFinancierEnum.GREENSKY.id
                      ) {
                        dispatchChangeAuthValue('greenskyAssigned', true);
                        dispatchSetFlow(greenSkyPreQualificationFlow);
                        dispatchGreenskyValidateMerchant(
                          dealerId,
                          dealerUserId,
                          history,
                          greenskyMerchantNumber,
                        );
                        dispatchChangePage(GreenSkyPreQualificationFlowPageNumber.AccountEmail);
                        history.push('/greensky-prequalification');
                      } else if (
                        dealerStatsSummary.enerBankAssigned &&
                        dealerStatsSummary.defaultBankId === SelectedFinancierEnum.ENERBANK.id
                      ) {
                        dispatchChangeAuthValue('enerBankAssigned', true);
                        dispatchSetFlow(enerBankPreQualificationFlow);
                        dispatchChangePage(EnerBankPreQualificationFlowPageNumber.AccountEmail);
                        history.push('/enerbank-prequalification');
                      } else if (
                        dealerStatsSummary.ftlAssigned &&
                        dealerStatsSummary.defaultBankId === SelectedFinancierEnum.FTL.id
                      ) {
                        dispatchChangeAuthValue('ftlAssigned', true);
                        dispatchSetFlow(ApplicationFlowEnum.FTL_PREQUALIFICATION_FLOW.value);
                        dispatchChangePage(FTLPreQualificationFlowPageNumber.AccountEmail);
                        history.push(ApplicationFlowEnum.FTL_PREQUALIFICATION_FLOW.path);
                      } else {
                        dispatchChangeMicrofInfoValue('microfOnly', true);
                        dispatchChangeAuthValue('aquaFinanceAssigned', false);
                        dispatchSetFlow(microfFlow);
                        dispatchChangePage(MicrofFlowPageNumber.Landing);
                        history.push('/RTO');
                      }
                    }
                  }}
                  title="New Application"
                />
              </Col>
            )}
            {isMicrof && (
              <>
                {dealerStatsSummary && !isRheem && (
                  <Col xs="12" md="6" className="m-mb-15">
                    {user && user.emulatorId ? (
                      showNewAppButtonEmulatorMode ? (
                        <Button
                          className="mb-1 h-100"
                          disabled={isMaintenance}
                          clickHandler={() => {
                            if (
                              dealerStatsSummary.powerPayAssigned &&
                              dealerStatsSummary.defaultBankId ===
                                SelectedFinancierEnum.POWER_PAY.id
                            ) {
                              dispatchChangeAuthValue('powerPayAssigned', true);
                              dispatchSetFlow(powerPayPreQualificationFlow);
                              dispatchChangePage(PowerPayPreQualificationFlowPageNumber.Landing);
                              history.push('/powerpay-prequalification');
                            } else if (
                              dealerStatsSummary.mosaicAssigned &&
                              dealerStatsSummary.defaultBankId === SelectedFinancierEnum.MOSAIC.id
                            ) {
                              dispatchChangeAuthValue('mosaicAssigned', true);
                              dispatchSetFlow(mosaicPreQualificationFlow);
                              dispatchChangePage(MosaicPreQualificationFlowPageNumber.Landing);
                              history.push('/mosaic-prequalification');
                            } else if (
                              dealerStatsSummary.ftlAssigned &&
                              dealerStatsSummary.defaultBankId === SelectedFinancierEnum.FTL.id
                            ) {
                              dispatchChangeAuthValue('ftlAssigned', true);
                              dispatchSetFlow(ApplicationFlowEnum.FTL_PREQUALIFICATION_FLOW.value);
                              dispatchChangePage(FTLPreQualificationFlowPageNumber.AccountEmail);
                              history.push(ApplicationFlowEnum.FTL_PREQUALIFICATION_FLOW.path);
                            } else if (
                              dealerStatsSummary.aquaFinanceAssigned &&
                              dealerStatsSummary.defaultBankId ===
                                SelectedFinancierEnum.AQUA_FINANCE.id
                            ) {
                              dispatchChangeAuthValue('aquaFinanceAssigned', true);
                              dispatchSetFlow(defaultFlow);
                              dispatchChangePage(AquaPreQualificationFlowPageNumber.AccountEmail);
                              history.push('/');
                            } else if (
                              dealerStatsSummary.upgradeAssigned &&
                              dealerStatsSummary.defaultBankId === SelectedFinancierEnum.UPGRADE.id
                            ) {
                              dispatchChangeAuthValue('upgradeAssigned', true);
                              dispatchSetFlow(ApplicationFlowEnum.UPGRADE_PREQUALIFICATION.value);
                              dispatchChangePage(
                                UpgradePreQualificationFlowPageNumber.AccountEmail,
                              );
                              history.push('/upgrade-prequalification');
                            } else {
                              dispatchChangeMicrofInfoValue('microfOnly', true);
                              dispatchChangeAuthValue('aquaFinanceAssigned', false);
                              dispatchSetFlow(defaultFlow);
                              dispatchChangePage(AquaPreQualificationFlowPageNumber.AccountEmail);
                              history.push('/');
                            }
                          }}
                          title="Begin New Application"
                        />
                      ) : null
                    ) : (
                      <Button
                        className="mb-1 h-100"
                        disabled={isMaintenance}
                        clickHandler={() => {
                          if (
                            dealerStatsSummary.powerPayAssigned &&
                            dealerStatsSummary.defaultBankId === SelectedFinancierEnum.POWER_PAY.id
                          ) {
                            dispatchChangeAuthValue('powerPayAssigned', true);
                            dispatchSetFlow(powerPayPreQualificationFlow);
                            dispatchChangePage(PowerPayPreQualificationFlowPageNumber.Landing);
                            history.push('/powerpay-prequalification');
                          } else if (
                            dealerStatsSummary.mosaicAssigned &&
                            dealerStatsSummary.defaultBankId === SelectedFinancierEnum.MOSAIC.id
                          ) {
                            dispatchChangeAuthValue('mosaicAssigned', true);
                            dispatchSetFlow(mosaicPreQualificationFlow);
                            dispatchChangePage(MosaicPreQualificationFlowPageNumber.Landing);
                            history.push('/mosaic-prequalification');
                          } else if (
                            dealerStatsSummary.ftlAssigned &&
                            dealerStatsSummary.defaultBankId === SelectedFinancierEnum.FTL.id
                          ) {
                            dispatchChangeAuthValue('ftlAssigned', true);
                            dispatchSetFlow(ApplicationFlowEnum.FTL_PREQUALIFICATION_FLOW.value);
                            dispatchChangePage(FTLPreQualificationFlowPageNumber.AccountEmail);
                            history.push(ApplicationFlowEnum.FTL_PREQUALIFICATION_FLOW.path);
                          } else if (
                            dealerStatsSummary.aquaFinanceAssigned &&
                            dealerStatsSummary.defaultBankId ===
                              SelectedFinancierEnum.AQUA_FINANCE.id
                          ) {
                            dispatchChangeAuthValue('aquaFinanceAssigned', true);
                            dispatchSetFlow(defaultFlow);
                            dispatchChangePage(AquaPreQualificationFlowPageNumber.AccountEmail);
                            history.push('/');
                          } else if (
                            dealerStatsSummary.upgradeAssigned &&
                            dealerStatsSummary.defaultBankId === SelectedFinancierEnum.UPGRADE.id
                          ) {
                            dispatchChangeAuthValue('upgradeAssigned', true);
                            dispatchSetFlow(ApplicationFlowEnum.UPGRADE_PREQUALIFICATION.value);
                            dispatchChangePage(UpgradePreQualificationFlowPageNumber.AccountEmail);
                            history.push('/upgrade-prequalification');
                          } else {
                            dispatchChangeMicrofInfoValue('microfOnly', true);
                            dispatchChangeAuthValue('aquaFinanceAssigned', false);
                            dispatchSetFlow(defaultFlow);
                            dispatchChangePage(AquaPreQualificationFlowPageNumber.AccountEmail);
                            history.push('/');
                          }
                        }}
                        title="Begin New Application"
                      />
                    )}
                  </Col>
                )}
                {dealerStatsSummary &&
                  dealerStatsSummary.wellsFargoAssigned &&
                  !isRheem &&
                  (user && user.emulatorId ? (
                    showNewAppButtonEmulatorMode ? (
                      <Col xs="12" md="6">
                        <Button
                          className="mb-1 h-100"
                          disabled={isMaintenance}
                          clickHandler={() => {
                            dispatchChangeAuthValue('wellsFargoAssigned', true);
                            dispatchSetFlow(wellsFargoFlow);
                            dispatchChangePage(WellsFargoFlowPageNumber.Landing);
                            history.push('/WellsFargo');
                          }}
                          title="Begin Wells Fargo Application"
                        />
                      </Col>
                    ) : null
                  ) : (
                    <Col xs="12" md="6">
                      <Button
                        className="mb-1 h-100"
                        disabled={isMaintenance}
                        clickHandler={() => {
                          dispatchChangeAuthValue('wellsFargoAssigned', true);
                          dispatchSetFlow(wellsFargoFlow);
                          dispatchChangePage(WellsFargoFlowPageNumber.Landing);
                          history.push('/WellsFargo');
                        }}
                        title="Begin Wells Fargo Application"
                      />
                    </Col>
                  ))}
              </>
            )}
            {isPools && (
              <>
                <Col xs="12" md="6" className="mt-1">
                  <Button
                    className="h-100"
                    disabled={isMaintenance}
                    clickHandler={() => {
                      if (
                        dealerStatsSummary.greenskyAssigned &&
                        dealerStatsSummary.defaultBankId === SelectedFinancierEnum.GREENSKY.id
                      ) {
                        dispatchChangeAuthValue('greenskyAssigned', true);
                        dispatchSetFlow(atWaterGreenSkyPreQualificationFlow);
                        dispatchGreenskyValidateMerchant(
                          dealerId,
                          dealerUserId,
                          history,
                          greenskyMerchantNumber,
                        );
                        dispatchChangePage(
                          AtWaterGreenSkyPreQualificationFlowPageNumber.AccountEmail,
                        );
                        history.push('/atwater-greensky-prequalification');
                      } else {
                        dispatchChangeMicrofInfoValue('microfOnly', false);
                        dispatchChangeAuthValue('serviceFinanceAssigned', true);
                        dispatchSetFlow(atWaterPreQualificationFlow);
                        dispatchChangePage(AtWaterPreQualificationFlowPageNumber.AccountEmail);
                        history.push('/atwater-prequalification');
                      }
                    }}
                    title="New Application"
                  />
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
      <Row className="border rounded mt-5 bg-white pt-3 pb-3">
        {!isPools && (
          <SummaryCard
            className="border-right"
            dealerStat={dealerStatsSummary ? dealerStatsSummary.openApplicationCount : null}
            buttonTitle="Review Now"
            buttonClickHandler={() => history.push('/portal/open-applications')}
          >
            Lease Applications
          </SummaryCard>
        )}

        {dealerStatsSummary &&
          (dealerStatsSummary.aquaFinanceAssigned ||
            dealerStatsSummary.wellsFargoAssigned ||
            dealerStatsSummary.powerPayAssigned ||
            dealerStatsSummary.mosaicAssigned ||
            dealerStatsSummary.greenskyAssigned ||
            dealerStatsSummary.foundationAssigned ||
            dealerStatsSummary.enerBankAssigned ||
            dealerStatsSummary.ftlAssigned ||
            dealerStatsSummary.serviceFinanceAssigned ||
            dealerStatsSummary.upgradeAssigned) && (
            <SummaryCard
              arrow={<ArrowRight />}
              className="border-right"
              dealerStat={
                dealerStatsSummary ? dealerStatsSummary.underReviewApplicationsCount : null
              }
              buttonTitle="Revisit"
              buttonClickHandler={() => history.push('/portal/applications-under-review')}
            >
              Loan Applications
            </SummaryCard>
          )}

        {!isPools && (
          <SummaryCard
            arrow={<ArrowRight />}
            className="border-right"
            dealerStat={dealerStatsSummary ? dealerStatsSummary.unsubmittedApplicationsCount : null}
            buttonTitle="Revisit"
            buttonClickHandler={() => history.push('/portal/unsubmitted-leases')}
          >
            Unsubmitted Leases
          </SummaryCard>
        )}

        <SummaryCard
          arrow={<ArrowUp />}
          dealerStat={dealerStatsSummary ? dealerStatsSummary.addedByAPICount : null}
          buttonTitle="Review"
          buttonClickHandler={() => history.push('/portal/web-service-applications')}
        >
          Application Leads
        </SummaryCard>
      </Row>
      <Row className="border rounded mt-5 bg-white pt-3 pb-3">
        <ContractorStatusTable dealerStatsSummary={dealerStatsSummary} />
      </Row>
      {dealerStatsSummary?.dealerActive ? (
        <Row className="border rounded mt-5 mb-5 bg-white pt-3 pb-3">
          <Col>
            <Row>
              <Col xs="12" md="4">
                <p>
                  <b>Consumer Registration URL:</b>
                </p>
              </Col>
              <Col xs="12" md="8">
                <input
                  className="form-control"
                  defaultValue={
                    window.location.protocol +
                    '//' +
                    window.location.host +
                    '/?did=' +
                    (dealerStatsSummary ? dealerStatsSummary.dealerSfId : '')
                  }
                />
                <p className="text-danger info">
                  {`Have your website administrator place this link in the financing ${
                    isPools ? '' : 'and leasing'
                  } section
                of your website. This will allow your customers to submit applications and have the
                opportunity to get pre-approved for a ${isPools ? 'new loan' : 'new system'}.`}
                </p>
              </Col>
            </Row>

            {isPools && (
              <Row>
                <EmailConsumerForm dealerId={dealerId} />
              </Row>
            )}
          </Col>
        </Row>
      ) : null}
    </Col>
  );
};

const mapStateToProps = state => ({
  dealerStatsSummary:
    state.auth.user && state.auth.user.user ? state.auth.user.user.dealerStatsSummaryDTO : null,
  theme: state.theme.theme,
  dealerUserId: state.auth.dealerUserId,
  dealerId: state.auth.dealerId,
  greenskyMerchantNumber: state.auth.user?.dealerUser?.dealer?.greenskyMerchantNumber,
  user: state.auth.user.user,
  workUser:
    state.auth.user.dealerUser || state.auth.user.internalUser || state.auth.user.consumerUser,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePreQualInfoValue: (key, value) => dispatch(changePreQualInfoValue(key, value)),
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeMicrofInfoValue: (key, value) => dispatch(changeMicrofInfoValue(key, value)),
  dispatchSetFlow: flow => dispatch(setFlow(flow)),
  dispatchChangeAuthValue: (key, value) => dispatch(changeAuthValue(key, value)),
  dispatchGetDealerStats: () => dispatch(getDealerStats()),
  dispatchResetNewAppState: () => dispatch(resetNewAppState()),
  dispatchSetDefaultTheme: theme => dispatch(setDefaultTheme(theme)),
  dispatchGreenskyValidateMerchant: (dealerId, dealerUserId, history, greenskyMerchantNumber) =>
    dispatch(greenskyValidateMerchant(dealerId, dealerUserId, history, greenskyMerchantNumber)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DashboardPage),
);
