import { Field, Form, withFormik } from 'formik';
import queryString from 'query-string';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import usStates from '../../../assets/js/USStateEnum';
import Select from '../../../components/UI/Form/Select/Select';
import { updateDealerStates } from '../../../redux/actions';

const EditContractorStates = props => {
  let state = null;
  const { dealerSearchCriteriaResult, history, dispatchUpdateDealerStates } = props;
  const dealerId = queryString.parse(window.location.search).id;

  let dealer = dealerSearchCriteriaResult?.dealers?.find(
    dealerToFind => dealerToFind.dealerId === parseInt(dealerId),
  );

  const [dealerStates, setDealerStates] = useState(dealer ? [...dealer?.dealerStates] : []);

  const addState = dealerState => {
    let dealerStatesTmp = dealerStates;
    if (!dealerStatesTmp.find(stateToFind => stateToFind.state === dealerState)) {
      dealerStatesTmp.push({ dealerStateId: 0, state: dealerState });
      setDealerStates([...dealerStatesTmp]);
    }
  };

  const removeState = index => {
    let dealerStatesTmp = dealerStates;
    dealerStatesTmp.splice(index, 1);
    setDealerStates([...dealerStatesTmp]);
  };

  const AddStateForm = () => (
    <Form>
      <Row className="mt-5">
        <Col sm="4" className="text-right pt-1">
          State:
        </Col>
        <Col sm="4" className="text-center">
          <Field
            className="mb-0"
            labelClassName="d-none"
            component={Select}
            label="State"
            name="stateToAdd"
            id="stateToAdd"
            type="select"
            selectValues={usStates}
            selectFirstName="Select State"
          />
        </Col>
        <Col sm="4" className="text-left">
          <Button type="submit" color="primary" className="rounded-pill">
            Add State
          </Button>
        </Col>
      </Row>
    </Form>
  );

  const AddStateFormFormik = withFormik({
    mapPropsToValues: () => ({
      state: state || '',
    }),
    handleSubmit: ({ stateToAdd }) => stateToAdd && addState(stateToAdd),
  })(AddStateForm);

  return (
    <Col>
      <h2 id="panelTitle">{dealer?.name}</h2>
      <div className="panel">
        <div className="panel-body larger">
          <Row>
            <Col className="right-bordered">
              {dealerStates
                ? dealerStates.map((dealerState, index) => (
                    <Row key={index} className="mt-1">
                      <Col sm="4" className="text-right pt-1">
                        State:
                      </Col>
                      <Col sm="4" className="text-center pt-1">
                        {dealerState.state}
                      </Col>
                      <Col sm="4" className="text-left">
                        <Button
                          type="reset"
                          className="btn-secondary rounded-pill"
                          onClick={() => {
                            removeState(index);
                          }}
                        >
                          Remove
                        </Button>
                      </Col>
                    </Row>
                  ))
                : null}
            </Col>
          </Row>
          <AddStateFormFormik />
          <Row>
            <Col className="text-right">
              <Button
                type="reset"
                color="secondary"
                className="rounded-pill"
                outline
                onClick={() => {
                  history.push('/portal/manage-contractor-states');
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                className="rounded-pill"
                onClick={() => {
                  let dealerDto = {
                    dealerId: dealer.dealerId,
                    dealerStates: dealerStates,
                  };
                  if (
                    JSON.stringify(dealer.dealerStates.sort()) !==
                    JSON.stringify(dealerStates.sort())
                  ) {
                    dispatchUpdateDealerStates(dealerDto, history);
                  }
                }}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </Col>
  );
};

const mapStateToProps = state => ({
  dealerSearchCriteriaResult: state.softPull.dealerSearchCriteriaResult,
});

const mapDispatchToProps = dispatch => ({
  dispatchUpdateDealerStates: (dealerDto, history) =>
    dispatch(updateDealerStates(dealerDto, history)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(EditContractorStates),
);
