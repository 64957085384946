import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
import { initialState } from '../initialState';

const changeValue = (state, action) => {
  const updatedValues = {
    [action.key]: action.value,
  };
  return updateObject(state, updatedValues);
};

const submitSoftPullSuccess = (state, action) => {
  const updatedValues = {
    softPullResponse: action.payload,
    error: null,
  };
  return updateObject(state, updatedValues);
};

const submitApplicationSoftPullSuccess = (state, action) => {
  const updatedValues = {
    applicationSubmitDTO: action.payload,
    error: null,
  };
  return updateObject(state, updatedValues);
};

const submitApplicationSoftPull2Success = (state, action) => {
  const updatedValues = {
    applicationSubmitDTO2: action.payload,
    error: null,
  };
  return updateObject(state, updatedValues);
};

const updateAquaStatusAndPromotions = (state, action) => {
  const updatedValues = {
    applicationSubmitDTO: {
      ...state.applicationSubmitDTO,
      aquaFinanceApplication: action.payload.aquaFinanceApplication,
      aquaFinanceInformation: action.payload.aquaFinanceInformation,
      aquaPromotions: action.payload.aquaPromotions,
      status: action.payload.status,
    },
  };
  return updateObject(state, updatedValues);
};

const setError = (state, action) => {
  const updatedValues = {
    error: action.payload,
    errorMessage: action.errorMessage,
  };
  return updateObject(state, updatedValues);
};

const softPullReducer = (state, action) => {
  if (!state) {
    state = initialState.softPull;
  }
  switch (action.type) {
    case actionTypes.CHANGE_SOFTPULL_VALUE:
      return changeValue(state, action);
    case actionTypes.GET_SCORE_SOFT_PULL_SUCCESS:
      return submitSoftPullSuccess(state, action);
    case actionTypes.GET_SCORE_SOFT_PULL_ERROR:
      return setError(state, action);
    case actionTypes.SUBMIT_APPLICATION_SOFT_PULL_SUCCESS:
      return submitApplicationSoftPullSuccess(state, action);
    case actionTypes.SUBMIT_APPLICATION_SOFT_PULL_2_SUCCESS:
      return submitApplicationSoftPull2Success(state, action);
    case actionTypes.UPDATE_AQUA_STATUS_AND_PROMOTIONS:
      return updateAquaStatusAndPromotions(state, action);
    case actionTypes.SUBMIT_APPLICATION_SOFT_PULL_ERROR:
      return setError(state, action);
    case actionTypes.RESET_STATE_SOFT_PULL:
      state = initialState.softPull;
      return state;
    default:
      return state;
  }
};

export default softPullReducer;
