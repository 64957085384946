import axios from 'axios';
import {
  CHANGE_WELLS_FARGO_INFO_VALUE,
  SUBMIT_APPLICATION_SOFT_PULL_ERROR,
  SUBMIT_APPLICATION_SOFT_PULL_SUCCESS,
} from './actionTypes';
import { baseURL, handleApiError } from './api';
import { addNotification, endLoading, startLoading } from './notificationActions';
import { changeMicrofInfoValue } from './microfActions';
import { isSameAddress } from '../../helper';
import { changePreQualInfoValue } from './preQualInfoActions';
import { checkErrors } from './softPullActions';

const qs = require('qs');

export const changeWellsFargoValue = (key, value) => ({
  type: CHANGE_WELLS_FARGO_INFO_VALUE,
  key,
  value,
});

export const mapAppToWellsFargo = app => dispatch => {
  dispatch(changePreQualInfoValue('isConsumer', !!app.consumerUser));
  dispatch(changeWellsFargoValue('airConditioningSystem', app.airConditioningSystem));
  dispatch(changeWellsFargoValue('isHomeowner', app.isHomeowner));
  dispatch(changeWellsFargoValue('borrowedAmount', app.totalFinancingAmount));
  dispatch(changeWellsFargoValue('terms', true));
  dispatch(changeWellsFargoValue('email', app.email));

  dispatch(changeWellsFargoValue('city', app.propertyCity));
  dispatch(changeWellsFargoValue('state', app.propertyState));
  dispatch(changeWellsFargoValue('street', app.propertyStreet));
  dispatch(changeWellsFargoValue('zipcode', app.propertyZip));
  dispatch(changeWellsFargoValue('poBox', app.poBox));

  if (app.applicantPrimary) {
    const applicant = app.applicantPrimary;
    dispatch(
      changeWellsFargoValue(
        'phoneNumber',
        applicant.mobilePhone ? applicant.mobilePhone.number : null,
      ),
    );

    dispatch(changeWellsFargoValue('firstName', applicant.nameFirst));
    dispatch(changeWellsFargoValue('middleName', applicant.nameMiddle));
    dispatch(changeWellsFargoValue('lastName', applicant.nameLast));
  }

  if (app.applicantNonPrimary) {
    const coapp = app.applicantNonPrimary;
    dispatch(changeWellsFargoValue('hasCoApp', true));
    dispatch(changeWellsFargoValue('coAppEmail', coapp.email ? coapp.email.email : null));
    dispatch(changeWellsFargoValue('coAppFirstName', coapp.nameFirst));
    dispatch(changeWellsFargoValue('coAppMiddleInitial', coapp.nameMiddle));
    dispatch(changeWellsFargoValue('coAppLastName', coapp.nameLast));

    if (coapp.address) {
      const address = coapp.address;
      dispatch(
        changeWellsFargoValue(
          'isSameAddress',
          isSameAddress(address, app.applicantPrimary.address),
        ),
      ); //check
      dispatch(changeWellsFargoValue('coAppCity', address.city));
      dispatch(changeWellsFargoValue('coAppState', address.state));
      dispatch(changeWellsFargoValue('coAppStreet', address.street));
      dispatch(changeWellsFargoValue('coAppZipCode', address.zipcode));
      dispatch(changeWellsFargoValue('coAppPoBox', address.poBox)); //check
    }
  }
};

export const mapWellsFargoToMicrof = wellsFargoInformation => dispatch => {
  dispatch(
    changeMicrofInfoValue('airConditioningSystem', wellsFargoInformation.airConditioningSystem),
  );
  dispatch(changeMicrofInfoValue('isHomeowner', wellsFargoInformation.isHomeowner));
  dispatch(changeMicrofInfoValue('borrowedAmount', wellsFargoInformation.borrowedAmount));

  dispatch(changeMicrofInfoValue('email', wellsFargoInformation.email));
  dispatch(changeMicrofInfoValue('password', wellsFargoInformation.password));

  dispatch(changeMicrofInfoValue('isSameAddress', wellsFargoInformation.isSameAddress));
  dispatch(changeMicrofInfoValue('city', wellsFargoInformation.city));
  dispatch(changeMicrofInfoValue('state', wellsFargoInformation.state));
  dispatch(changeMicrofInfoValue('street', wellsFargoInformation.street));
  dispatch(changeMicrofInfoValue('zipcode', wellsFargoInformation.zipcode));
  dispatch(changeMicrofInfoValue('phoneNumber', wellsFargoInformation.phoneNumber));

  dispatch(changeMicrofInfoValue('firstName', wellsFargoInformation.firstName));
  dispatch(changeMicrofInfoValue('middleName', wellsFargoInformation.middleName));
  dispatch(changeMicrofInfoValue('lastName', wellsFargoInformation.lastName));

  if (wellsFargoInformation.hasCoApp) {
    dispatch(changeMicrofInfoValue('hasCoApp', wellsFargoInformation.hasCoApp));
    dispatch(changeMicrofInfoValue('coAppCity', wellsFargoInformation.coAppCity));
    dispatch(changeMicrofInfoValue('coAppEmail', wellsFargoInformation.coAppEmail));
    dispatch(changeMicrofInfoValue('coAppFirstName', wellsFargoInformation.coAppFirstName));
    dispatch(changeMicrofInfoValue('coAppLastName', wellsFargoInformation.coAppLastName));
    dispatch(changeMicrofInfoValue('coAppMiddleInitial', wellsFargoInformation.coAppMiddleInitial));
    dispatch(changeMicrofInfoValue('coAppState', wellsFargoInformation.coAppState));
    dispatch(changeMicrofInfoValue('coAppStreet', wellsFargoInformation.coAppStreet));
    dispatch(changeMicrofInfoValue('coAppZipCode', wellsFargoInformation.coAppZipCode));
  }
};

export const submitWellsFargoApplication = (params, app) => async dispatch => {
  dispatch(startLoading());
  if (app !== null) {
    await axios
      .post(
        `${baseURL}/api/soft_pull/resubmit_wellsfargo`,
        qs.stringify({
          appToken: `${encodeURIComponent(app.consumerToken)}`,
          appId: `${encodeURIComponent(app.applicationId)}`,
          data: '' + JSON.stringify(params),
        }),
      )
      .then(async response => {
        const { redirectUrl } = response.data;
        dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_SUCCESS, payload: response.data });
        if (dispatch(checkErrors(response))) {
          window.open(decodeURIComponent(redirectUrl), '_self');
        }
      })
      .catch(error => {
        dispatch(
          addNotification({
            message: handleApiError(error),
            messageType: 'Error',
          }),
        );
        dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_ERROR, payload: error });
        dispatch(endLoading());
      });
  } else {
    await axios
      .post(
        `${baseURL}/api/soft_pull/wellsfargo_app`,
        qs.stringify({ data: '' + JSON.stringify(params) }),
      )
      .then(async response => {
        const { redirectUrl } = response.data;
        dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_SUCCESS, payload: response.data });
        if (dispatch(checkErrors(response))) {
          window.open(decodeURIComponent(redirectUrl), '_self');
        }
      })
      .catch(error => {
        dispatch(
          addNotification({
            message: handleApiError(error),
            messageType: 'Error',
          }),
        );
        dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_ERROR, payload: error });
        dispatch(endLoading());
      });
  }
};
