import React from 'react';
import { connect } from 'react-redux';
import { Button, Col, Container, Row } from 'reactstrap';
import { changePage, submitMindfire, changeTheme, setFlow } from '../../../redux/actions';
import { reactGAEvent } from '../../../helper';
import { withRouter } from 'react-router-dom';
import ApplicationFlowEnum from '../../../assets/js/ApplicationFlowEnum';
const FTLDeclineToMicrof = props => {
  const { dispatchSubmitMindfire, did, dealerUserId, email, history, dispatchSetFlow } = props;

  return (
    <Container>
      <Row className="justify-content-center mt-2">
        <Col xs="12" md="auto" lg="8" className="text-center">
          <p className="fs-20">
            EGIA was unable to prequalify you for any financing offers from platform lenders or
            lessors. You will receive notification via email within 30 days.
          </p>
        </Col>
      </Row>
      <Row className="justify-content-center mt-2">
        <Col xs="12" md="auto" lg="6" className="text-center">
          <p className="fs-20">
            But wait! You may still qualify for a lease purchase option. A lease purchase option is
            not financing.
          </p>
        </Col>
      </Row>
      <Row className="justify-content-center ">
        <Col className="text-center">
          <h3>Microf Lease Purchase Option</h3>
        </Col>
      </Row>
      <Row className="justify-content-center ">
        <Col xs="auto" lg="8" sm="12" md="12">
          <ul className="microf-lease-purchase-options">
            <li>
              Monthly renewals up to 60 months to own.{' '}
              <p>Not all term options are available in all states.</p>
            </li>
            <li>
              You will own the item after the total of payments to ownership is paid in full or is
              paid by prepayment.{' '}
              <p>
                Save up to 40% or more off your remaining payments to ownership. Early Purchase
                Options vary by state, so call us at 855-642-7631 for details.
              </p>
            </li>
            <li>
              Damaged credit? Not a problem!{' '}
              <p>Microf regularly approves applicants with less than perfect credit.</p>
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="mt-3 justify-content-center">
        <Col xs="12" md="auto">
          <Button
            color="primary"
            onClick={() => {
              reactGAEvent({
                category: 'Button',
                action: 'Click',
                label: 'Start Application',
                did,
                dealerUserId,
              });
              dispatchSetFlow(ApplicationFlowEnum.MICROF_FLOW.value);
              dispatchSubmitMindfire({
                email: email,
                lastPage: '4066',
                dealerUserId: dealerUserId,
                did: did,
              });
            }}
          >
            Continue to Microf
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchChangeTheme: theme => dispatch(changeTheme(theme)),
  dispatchSetFlow: flow => dispatch(setFlow(flow)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(FTLDeclineToMicrof),
);
