import { AgeFromDateString } from 'age-calculator';
import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import { withRouter } from 'react-router-dom';
import idTypes from '../../../assets/js/IdTypeEnum';
import usStates from '../../../assets/js/USStateEnum';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import CustomDatePicker from '../../../components/UI/Form/DatePicker/DatePicker';
import CustomInput from '../../../components/UI/Form/Input/CustomInput';
import SsnFormat from '../../../components/UI/Form/NumberFormat/SsnFormat';
import Select from '../../../components/UI/Form/Select/Select';
import SelectWithHandleChange from '../../../components/UI/Form/Select/SelectWithHandleChange';
import {
  reactGAEvent,
  reactGAPageview,
  validDateFormat,
  isSSNanITIN,
  isStateAllowed,
} from '../../../helper';
import {
  changeAquaInfoValue,
  changePage,
  submitMindfire,
  setFlow,
} from '../../../redux/actions/index';
import { microfFlow, preQualificationFlow } from '../../../redux/initialState';
import { AquaFlowPageNumber } from '../AquaFlow';
import { AquaPreQualificationFlowPageNumber } from '../../PreQualification/preQualificationFlow';
import { MicrofFlowPageNumber } from '../../Microf/MicrofFlow';
import AirConditioningSystemEnum from '../../../assets/js/AirConditioningSystemEnum';
import {
  COAPPDOB_TOOLTIP_TEXT,
  COAPPFULLSSN_TOOLTIP_TEXT,
  COAPP_DRIVER_LICENSE_STATE_TOOLTIP_TEXT,
  COAPP_ID_EXPIRATION_DATE_TOOLTIP_TEXT,
  COAPP_ID_ISSUE_DATE_TOOLTIP_TEXT,
  COAPP_ID_NUMBER_TOOLTIP_TEXT,
  COAPP_ID_TYPE_TOOLTIP_TEXT,
} from '../../../constants/tooltipTexts';

const CoAppAdditionalInfo = props => {
  const {
    dispatchChangePage,
    dispatchChangeValue,
    aquaInformation,
    children,
    firstName,
    appFullSsn,
    email,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    dispatchSetFlow,
    history,
    pqi,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_aqcoapplicantid',
      trackerNames: [],
      title: 'ca_aqcoapplicantid',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line no-extend-native
  Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  const handleIdTypeChange = (e, values) => {
    dispatchChangeValue('coAppIdType', e.target.value);
    dispatchChangeValue('coAppDoB', values.coAppDoB);
    dispatchChangeValue('coAppDriversLicenseState', values.coAppDriversLicenseState);
    dispatchChangeValue('coAppIdNumber', values.coAppIdNumber);
    dispatchChangeValue('coAppIdIssueDate', values.coAppIdIssueDate);
    dispatchChangeValue('coAppIdExpirationDate', values.coAppIdExpirationDate);
    dispatchChangeValue('coAppFullSsn', values.coAppFullSsn);
  };

  const CoAppAdditionalInfoForm = ({ values }) => (
    <Form noValidate autoComplete="off">
      <Field
        component={CustomDatePicker}
        name="coAppDoB"
        id="coAppDoB"
        tooltipText={COAPPDOB_TOOLTIP_TEXT}
        label="Co-Applicant's Date of Birth"
        onChange
      />
      <Field
        component={SelectWithHandleChange}
        label="Co-Applicant's ID Type"
        name="coAppIdType"
        id="coAppIdType"
        tooltipText={COAPP_ID_TYPE_TOOLTIP_TEXT}
        type="select"
        selectValues={idTypes}
        selectFirstName="Select One"
        handleChange={e =>
          handleIdTypeChange(e, {
            coAppDoB: values.coAppDoB,
            coAppDriversLicenseState: values.coAppDriversLicenseState,
            coAppIdNumber: values.coAppIdNumber,
            coAppIdIssueDate: values.coAppIdIssueDate,
            coAppIdExpirationDate: values.coAppIdExpirationDate,
            coAppFullSsn: values.coAppFullSsn,
          })
        }
      />
      {aquaInformation.coAppIdType === 'D' ? (
        <Field
          component={Select}
          label="Issue State"
          name="coAppDriversLicenseState"
          id="coAppDriversLicenseState"
          tooltipText={COAPP_DRIVER_LICENSE_STATE_TOOLTIP_TEXT}
          type="select"
          selectValues={usStates}
          selectFirstName="Select One"
        />
      ) : null}
      <Field
        maxLength={30}
        component={CustomInput}
        label="Co-Applicant's ID Number"
        name="coAppIdNumber"
        id="coAppIdNumber"
        tooltipText={COAPP_ID_NUMBER_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={CustomDatePicker}
        name="coAppIdIssueDate"
        id="coAppIdIssueDate"
        tooltipText={COAPP_ID_ISSUE_DATE_TOOLTIP_TEXT}
        label="Issue Date"
      />
      <Field
        component={CustomDatePicker}
        name="coAppIdExpirationDate"
        id="coAppIdExpirationDate"
        tooltipText={COAPP_ID_EXPIRATION_DATE_TOOLTIP_TEXT}
        label="Expiration Date"
      />
      <Field
        component={SsnFormat}
        label="Co-Applicant's full SSN"
        name="coAppFullSsn"
        id="coAppFullSsn"
        tooltipText={COAPPFULLSSN_TOOLTIP_TEXT}
        type="password"
        icon="lock"
      />
      <Button type="submit" title="Review" />
    </Form>
  );

  const CoAppAdditionalInfoFormFormik = withFormik({
    mapPropsToValues: () => ({
      coAppDoB: aquaInformation.coAppDoB || '',
      coAppIdType: aquaInformation.coAppIdType || '',
      coAppDriversLicenseState: aquaInformation.coAppDriversLicenseState || '',
      coAppIdNumber: aquaInformation.coAppIdNumber || '',
      coAppIdIssueDate: aquaInformation.coAppIdIssueDate || '',
      coAppIdExpirationDate: aquaInformation.coAppIdExpirationDate || '',
      coAppFullSsn: aquaInformation.coAppFullSsn || '',
    }),

    validationSchema: Yup.object({
      coAppDoB: Yup.string()
        .required("Please enter the Co-Applicant's date of birth.")
        .test(
          'incomplete',
          'Incomplete date',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 8,
        )
        .test('valid', 'Invalid date format.', val => val && !validDateFormat(val))
        .test(
          'age',
          'The Co-Applicant must be at least 18 years old.',
          val => val && new AgeFromDateString(val).age >= 18,
        ),
      coAppIdType: Yup.string().required('Please select your ID type.'),
      coAppDriversLicenseState:
        aquaInformation.coAppIdType === 'D'
          ? Yup.string().required('Please select your issue state.')
          : Yup.string(),
      coAppIdNumber: Yup.string().required('Please enter your ID number.'),
      coAppIdIssueDate: Yup.string()
        .required('Please enter the issue date.')
        .test(
          'incomplete',
          'Incomplete date',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 8,
        )
        .test('valid', 'Invalid date format.', val => val && !validDateFormat(val))
        .test('maxDate', 'Invalid date.', val => val && new Date(val) < new Date()),
      coAppIdExpirationDate: Yup.string()
        .required('Please enter the expiration date.')
        .test(
          'incomplete',
          'Incomplete date',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 8,
        )
        .test('valid', 'Invalid date format.', val => val && !validDateFormat(val))
        .test(
          'minDate',
          'Invalid date',
          val => val && new Date(val) >= new Date().setHours(0, 0, 0, 0),
        ),
      coAppFullSsn: Yup.string()
        .required("Please enter the Co-Applicant's full SSN.")
        .test(
          'len',
          'Must have 9 digits',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 9,
        )
        .test(
          'unique',
          "Same SSN as the applicant's is not allowed",
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('') !== appFullSsn,
        )
        .typeError('Only numbers allowed.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Review',
        did,
        dealerUserId,
      });
      let ssn = values.coAppFullSsn.match(/\d/g).join('');
      dispatchChangeValue('coAppDoB', values.coAppDoB);
      dispatchChangeValue('coAppIdType', values.coAppIdType);
      dispatchChangeValue('coAppDriversLicenseState', values.coAppDriversLicenseState);
      dispatchChangeValue('coAppIdNumber', values.coAppIdNumber);
      dispatchChangeValue('coAppIdIssueDate', values.coAppIdIssueDate);
      dispatchChangeValue('coAppIdExpirationDate', values.coAppIdExpirationDate);
      dispatchChangeValue('coAppFullSsn', ssn);
      dispatchChangeValue('isReview', false);
      if (isSSNanITIN(ssn)) {
        if (
          !isStateAllowed(pqi.state) ||
          pqi.airConditioningSystem !== AirConditioningSystemEnum.AIR_CONDITION_OR_HEATING_SYSTEM.id
        ) {
          dispatchSetFlow(preQualificationFlow);
          dispatchChangePage(AquaPreQualificationFlowPageNumber.NotAllowed);
          history.push('/');
        } else {
          dispatchSetFlow(microfFlow);
          dispatchChangePage(MicrofFlowPageNumber.Landing);
          history.push('/RTO');
        }
      } else {
        dispatchChangePage(AquaFlowPageNumber.ApplicationReview);
      }
      dispatchSubmitMindfire({
        email: email,
        lastPage: '0054',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(CoAppAdditionalInfoForm);

  return (
    <Container>
      <Row>
        <BackBtn changePage={dispatchChangePage} page={AquaFlowPageNumber.CoAppAddress} />
        <Col sm="5">
          {children}
          <p>
            {firstName}, we’ll run a credit score pull to find you the best financing options. This{' '}
            <b>will</b> impact your credit score.
          </p>
        </Col>
        <Col sm="6">
          <CoAppAdditionalInfoFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  firstName: state.aquaInformation.coAppFirstName,
  aquaInformation: state.aquaInformation,
  pqi: state.preQualificationInformation,
  email: state.preQualificationInformation.email,
  appFullSsn: state.aquaInformation.appFullSsn,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetFlow: flowName => dispatch(setFlow(flowName)),
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) => dispatch(changeAquaInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

CoAppAdditionalInfo.propTypes = {
  firstName: PropTypes.string,
  aquaInformation: PropTypes.object,
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
  dispatchSubmitMindfire: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CoAppAdditionalInfo),
);
