import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  changePage,
  submitMindfire,
  changeGreenSkyApplicationInfoValue,
} from '../../../../redux/actions/index';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import { reactGAPageview } from '../../../../helper';
import { AtWaterGreenSkyFlowPageNumber } from './GreenSkyFlow';

const CoApplicantMarried = props => {
  const {
    email,
    dispatchChangePage,
    dispatchChangeGreenSkyApplicationInfoValue,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
  } = props;

  const handleClick = () => {
    dispatchSubmitMindfire({
      email: email,
      lastPage: '9021',
      dealerUserId: dealerUserId,
      did: did,
    });
  };

  useEffect(() => {
    reactGAPageview({
      path: '/ca_gscoapplicantmarried',
      trackerNames: [],
      title: 'ca_gscoapplicantmarried',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);
  return (
    <Container>
      <Row>
        <Col>
          <Row>
            <BackBtn
              changePage={dispatchChangePage}
              page={AtWaterGreenSkyFlowPageNumber.CoAppGrossAnnualIncome}
            />
            <Col sm="5">
              <h4>Co-Applicant, are you married?</h4>
            </Col>
            <Col sm="6">
              <Row>
                <Col sm="6">
                  <Button
                    title="Yes"
                    clickHandler={() => {
                      dispatchChangeGreenSkyApplicationInfoValue('isCoAppMarried', true);
                      dispatchChangePage(AtWaterGreenSkyFlowPageNumber.CoApplicantSpouse);
                      handleClick();
                    }}
                  />
                </Col>
                <Col sm="6">
                  <Button
                    title="No"
                    clickHandler={() => {
                      dispatchChangeGreenSkyApplicationInfoValue('isCoAppMarried', false);
                      dispatchChangePage(AtWaterGreenSkyFlowPageNumber.Review);
                      handleClick();
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeGreenSkyApplicationInfoValue: (key, value) =>
    dispatch(changeGreenSkyApplicationInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

CoApplicantMarried.propTypes = {
  dispatchChangePage: PropTypes.func,
  dispatchChangeGreenSkyApplicationInfoValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CoApplicantMarried);
