import React from 'react';
import Consent from './Consent';
import LoanAmount from './LoanAmount';
import AppIdentificationInfo from './AppIdentificationInfo';
import PropertyDetails from './PropertyDetails';
import AppEmploymentDetails from './AppEmploymentDetails';
import HasCoApplicant from './HasCoApplicant';
import CoAppInfo from './CoAppInfo';
import CoAppAddress from './CoAppAddress';
import CoAppIdentificationInfo from './CoAppIdentificationInfo';
import CoAppEmploymentDetails from './CoAppEmploymentDetails';
import ApplicationReview from './ApplicationReview';
import ApplicationResult from './ApplicationResult';
import ApprovedRedirect from './ApprovedRedirect';

const applicantInformationTitle = `Applicant Information`;
const coApplicantInformationTitle = 'Co-Applicant Information';
const applicationStatusTitle = 'Application Status';

const PowerPayFlow = [
  //1
  <Consent title={applicationStatusTitle} />,
  //2
  <LoanAmount title={applicantInformationTitle}>
    <h4>What is your desired loan amount?</h4>
  </LoanAmount>,
  //3
  <AppIdentificationInfo title={applicantInformationTitle}>
    <h4>Please provide your identification information.</h4>
    <p>
      PowerPay needs additional information. You may have provided the last 4 digits of your Social
      Security Number together with your date of birth earlier in this application, which was used
      solely for a “soft pull” of your credit report to try to prequalify you for financing. This
      should not have had any impact on your credit score. Providing your full Social Security
      Number here will help further verify your identity.
    </p>
  </AppIdentificationInfo>,
  //4
  <PropertyDetails title={applicantInformationTitle}>
    <h4> Tell us a little more about your home.</h4>
  </PropertyDetails>,
  //5
  <AppEmploymentDetails title={applicantInformationTitle}>
    <h4>Please provide your income (or benefits) information.</h4>
    <p>
      Alimony, child support, or separate maintenance income need not be revealed if you do not wish
      to have it considered as a basis for repayment. You may include income from any source. If you
      are relying on the income or assets of a spouse, domestic partner, or another individual,
      please complete a joint applicant.
    </p>
    <p className="text-error">
      The total income for the applicant(s) must be a minimum of $42,000 to be eligible for this
      loan.
    </p>
  </AppEmploymentDetails>,
  //6
  <HasCoApplicant title={coApplicantInformationTitle}>
    <h4>Would you like to add a Co-Applicant?</h4>
    <p>
      A co-applicant is <u>not</u> required.
    </p>
  </HasCoApplicant>,
  //7
  <CoAppInfo title={coApplicantInformationTitle}>
    <h4>Co-Applicant, enter your name and contact information.</h4>
  </CoAppInfo>,
  //8
  <CoAppAddress title={coApplicantInformationTitle}>
    <h4>Co-Applicant, enter your address.</h4>
  </CoAppAddress>,
  //9
  <CoAppIdentificationInfo title={coApplicantInformationTitle}>
    <h4>Co-Applicant, enter your date of birth and identification information.</h4>
    <p>
      We need some more information about your identification due to federal laws and protections.
      You may have provided the last 4 digits of your Social Security Number together with your date
      of birth earlier in this application, which was used solely for a “soft pull” of your credit
      score to try and pre-qualify you for credit with a participating lender. This should not have
      had any impact on your credit score. We are asking you to enter your full social security
      number here to further verify your identity and access your credit report from consumer
      reporting agencies due to federal laws and protections. This “hard pull” may impact your
      credit score.
    </p>
  </CoAppIdentificationInfo>,
  //10
  <CoAppEmploymentDetails title={coApplicantInformationTitle}>
    <h4>
      Co-Applicant, enter your income (or benefits) information.Tell us about your Co-Applicant's
      income.
    </h4>
    <p>
      Alimony, child support, or separate maintenance income need not be revealed if you do not wish
      to have it considered as a basis for repayment. You may include income from any source. If you
      are relying on the income or assets of a spouse, domestic partner, or another individual,
      please complete a joint applicant.
    </p>
  </CoAppEmploymentDetails>,
  //11
  <ApplicationReview title={applicantInformationTitle}>
    <h4>Let’s double check your information and submit your application.</h4>
  </ApplicationReview>,
  //12
  <ApplicationResult title={applicationStatusTitle} />,
  //13
  <ApprovedRedirect title={applicationStatusTitle} />,
];

export default PowerPayFlow;

export const PowerPayFlowPageNumber = {
  Consent: 0,
  LoanAmount: 1,
  AppIdentificationInfo: 2,
  PropertyDetails: 3,
  AppEmploymentDetails: 4,
  HasCoApplicant: 5,
  CoAppInfo: 6,
  CoAppAddress: 7,
  CoAppIdentificationInfo: 8,
  CoAppEmploymentDetails: 9,
  ApplicationReview: 10,
  ApplicationResult: 11,
  ApprovedRedirect: 12,
};
