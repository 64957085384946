import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import statementOfUse from '../../assets/pdf/SpectrumAC Statement on Use of Electronic Records.pdf';
import termsAndConditions from '../../assets/pdf/SpectrumAC Statement of Terms and Conditions.pdf';
import BackBtn from '../../components/UI/BackButton/BackButton';
import Button from '../../components/UI/Button/CustomButton';
import Checkbox from '../../components/UI/Form/Checkbox/Checkbox';
import { getPrivacyStatementURL } from '../../config/settings';
import { changePage, submitMindfire, changeWellsFargoValue } from '../../redux/actions';
import { reactGAEvent, reactGAPageview } from '../../helper';
import { WellsFargoFlowPageNumber } from './WellsFargoFlow';

const Terms = props => {
  const {
    dispatchChangePage,
    terms,
    dispatchChangeWellsFargoValue,
    did,
    dealerUserId,
    dispatchSubmitMindfire,
    isConsumer,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/wf_terms',
      trackerNames: [],
      title: 'wf_terms',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Continue',
      did,
      dealerUserId,
    });
    dispatchSubmitMindfire({
      lastPage: '5001',
      dealerUserId: dealerUserId,
      did: did,
    });
    dispatchChangeWellsFargoValue('terms', true);
    dispatchChangePage(WellsFargoFlowPageNumber.AccountEmail);
  };

  const TermsForm = () => (
    <Form noValidate autoComplete="off">
      <Field
        component={Checkbox}
        name="terms"
        id="terms"
        label="I agree to Microf’s Terms & Conditions"
        labelClassName="bold mb-0"
      />
      <p>
        You authorize Microf (the technology platform provider) to immediately share all application
        information with participating lenders and you understand and acknowledge that you are
        accepting Microf’s{' '}
        <Link to={termsAndConditions} target="_blank">
          Statement of Terms & Conditions
        </Link>
        ,{' '}
        <Link to={statementOfUse} target="_blank">
          Statement on Use of Electronic Records
        </Link>
        , and{' '}
        <a href={getPrivacyStatementURL()} target="_blank" rel="noopener noreferrer">
          Privacy Statement
        </a>
        .
      </p>
      <Button type="submit" title={'Continue'} />
    </Form>
  );

  const TermsFormFormik = withFormik({
    mapPropsToValues: () => ({
      terms: terms,
    }),

    validationSchema: Yup.object({
      terms: Yup.boolean().oneOf([true], 'Please check to continue.'),
    }),

    handleSubmit,
  })(TermsForm);

  return (
    <Container>
      <Row className="justify-content-center">
        {!isConsumer ? (
          <BackBtn changePage={dispatchChangePage} page={WellsFargoFlowPageNumber.Landing} />
        ) : (
          <Col sm="1" />
        )}
        <Col xs="auto" sm="11">
          <Row className="justify-content-center">
            <Col md="8">
              <p>
                You are about to provide your personal information in the SpectrumAC platform in an
                attempt to receive a credit offer from <b>Wells Fargo Bank, N.A. (“Wells Fargo”)</b>
                . The decision to grant credit is solely theirs. If Wells Fargo is unable to approve
                your application, you can choose to apply through the SpectrumAC platform for
                Microf's lease purchase program
              </p>
              <TermsFormFormik />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  isConsumer: state.preQualificationInformation.isConsumer,
  terms: state.wellsFargoInformation.terms,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeWellsFargoValue: (key, value) => dispatch(changeWellsFargoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

Terms.propTypes = {
  dispatchChangePage: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Terms),
);
