import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Table } from 'reactstrap';
import { connect } from 'react-redux';
import {
  getSingleTransaction,
  getAtWaterGreenskySingleTransaction,
} from '../../../redux/actions/index';
import queryString from 'query-string';
import { formatDate } from '../../../util/dateFormatter';
import ReactToPdf from 'react-to-pdf';
import greensky from '../../../assets/img/greenSkyLogo.png';
import { getIsPool } from '../../../config/settings';

const SingleTransaction = props => {
  const {
    auth,
    singleTransaction,
    dispatchGetSingleTransaction,
    dispatchGetAtWaterGreenskySingleTransaction,
    history,
  } = props;
  const { dealerUser } = auth.user;

  const [isReceipt, setIsReceipt] = useState(false);
  const dealer = dealerUser?.dealer;

  useEffect(() => {
    if (!auth.user || !dealerUser) {
      history.push('/login');
    }
    const transactionId = queryString.parse(window.location.search).transactionId;
    if (transactionId) {
      if (getIsPool()) {
        dispatchGetAtWaterGreenskySingleTransaction(transactionId);
      } else {
        dispatchGetSingleTransaction(transactionId);
      }
    } else {
      history.push('/portal/greensky-transaction-history');
    }

    // eslint-disable-next-line
  }, []);

  let transactionResult;
  let openToBuy;
  if (singleTransaction && singleTransaction.transactionSearchResults.length > 0) {
    transactionResult = singleTransaction.transactionSearchResults[0];
    openToBuy = singleTransaction.openToBuy !== null ? singleTransaction.openToBuy : '';
  }

  const ref = React.createRef();
  const options = {
    orientation: 'portrait',
  };

  if (isReceipt) {
    return (
      <Col>
        <Row>
          <Col md="auto">
            <button
              onClick={() => setIsReceipt(false)}
              className="btn download-receipt border rounded-pill w-100"
            >
              Back
            </button>
          </Col>
          <ReactToPdf
            targetRef={ref}
            options={options}
            filename={transactionResult.applicationId + '_Receipt.pdf'}
            x={0.5}
            y={0.5}
            scale={0.8}
            onComplete={() => setIsReceipt(false)}
          >
            {({ toPdf }) => (
              <button onClick={toPdf} className="download-receipt py-2 px-4">
                Download Receipt
              </button>
            )}
          </ReactToPdf>
          <Col />
        </Row>
        <div style={{ padding: 25, width: '1000px' }} className="app-panel mt-25" ref={ref}>
          <Row>
            <Col>
              <img src={greensky} alt="" />
            </Col>
            <Col />
          </Row>
          <Row className="mt-40">
            <Col>
              <h1>Transaction Receipt</h1>
            </Col>
          </Row>
          <Row className="mt-40">
            <Col>
              <h2>Borrower Information</h2>
            </Col>
            <Col>
              <h2>Merchant Information</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table bordered={false}>
                <tbody>
                  <tr>
                    <td>Borrower(s) Name(s):</td>
                    <td>{transactionResult.firstName + ' ' + transactionResult.lastName}</td>
                  </tr>
                  <tr>
                    <td>Phone Number:</td>
                    <td>
                      {transactionResult.notificationDetails &&
                      transactionResult.notificationDetails.sms
                        ? transactionResult.notificationDetails.sms[0]?.toAddess
                        : ''}
                    </td>
                  </tr>
                  <tr>
                    <td>Email Address:</td>
                    <td>
                      {transactionResult.notificationDetails &&
                      transactionResult.notificationDetails.emails
                        ? transactionResult.notificationDetails.emails[0]?.toAddess
                        : ''}
                    </td>
                  </tr>
                  <tr>
                    <td>Application ID Number:</td>
                    <td>{transactionResult.applicationId}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col>
              <Table bordered={false}>
                <tbody>
                  <tr>
                    <td>Merchant Name:</td>
                    <td>{dealer.name}</td>
                  </tr>
                  <tr>
                    <td>Merchant ID Number:</td>
                    <td>{dealer.greenskyMerchantNumber}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row className="mt-40">
            <Col>
              <h2>Transaction Details</h2>
            </Col>
            <Col />
          </Row>
          <Row>
            <Col>
              <Table bordered={true}>
                <tbody>
                  <tr>
                    <td>Txn Amount</td>
                    <td>{transactionResult.txnAmount}</td>
                  </tr>
                  <tr>
                    <td>Txn Post Date</td>
                    <td>{formatDate(transactionResult.statusDate, 'MM/DD/YYYY')}</td>
                  </tr>
                  <tr>
                    <td>Txn Number</td>
                    <td>{transactionResult.txnNumber}</td>
                  </tr>
                  <tr>
                    <td>Charge Type</td>
                    <td>{transactionResult.chargeType}</td>
                  </tr>
                  <tr>
                    <td>Open to Buy</td>
                    <td>{openToBuy ? openToBuy : ''}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col>
              <Table bordered={true}>
                <tbody>
                  <tr>
                    <td>Charge Request Number</td>
                    <td>{transactionResult.linkedCharges[0]?.txnNumber}</td>
                  </tr>
                  <tr>
                    <td>Charge Request Submitted Date</td>
                    <td>
                      {transactionResult.linkedCharges.length > 0
                        ? formatDate(
                            transactionResult.linkedCharges[0]?.submissionDate,
                            'MM/DD/YYYY',
                          )
                        : ''}
                    </td>
                  </tr>
                  <tr>
                    <td>Charge Request Accepted Date</td>
                    <td>
                      {formatDate(
                        transactionResult.dispositionDetails?.authorizedTime,
                        'MM/DD/YYYY',
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Authorized Source</td>
                    <td>{transactionResult.dispositionDetails?.authorizedSource}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Col>
    );
  }

  return (
    <Col>
      <Row>
        <Col>
          <h1>Loan Applications</h1>
        </Col>
      </Row>
      <Row>
        {transactionResult ? (
          <div className="app-panel">
            <div className="app-panel-body transaction-initiation-container">
              <div className="title mb-3">
                <span>{`Application - ${transactionResult.firstName} ${
                  transactionResult.lastName
                }`}</span>
              </div>
              <Row>
                <Col>
                  <h5>Transaction Initiation</h5>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={12} md={6} lg={4} xl={3}>
                  <h6>Merchant</h6>
                  <p>
                    {transactionResult.firstName} {transactionResult.lastName}
                  </p>
                  <h6>Transaction Date</h6>
                  <p>
                    {transactionResult.submissionDate
                      ? formatDate(transactionResult.submissionDate, 'MM/DD/YYYY hh:mm A')
                      : '-'}
                  </p>
                  <h6>Charge Request #</h6>
                  <p>{transactionResult.txnNumber}</p>
                </Col>
                <Col xs={12} md={6} lg={4} xl={3}>
                  {transactionResult.notificationDetails &&
                    (transactionResult.notificationDetails.emails ||
                      transactionResult.notificationDetails.sms) && (
                      <>
                        <h6>Customer Text & Email</h6>
                        {transactionResult.notificationDetails.emails &&
                          transactionResult.notificationDetails.emails.length > 0 &&
                          transactionResult.notificationDetails.emails.map((email, index) => (
                            <p key={'email-' + index}>
                              {`Last Email sent (${index + 1}): ${
                                email.sentDate
                                  ? formatDate(email.sentDate, 'MM/DD/YYYY hh:mm A')
                                  : email.status
                              }`}
                              <br />
                              {`Emailed to: ${email.toAddess}`} <br />
                              {`Email Status: ${email.status}`}
                            </p>
                          ))}

                        {transactionResult.notificationDetails.sms &&
                          transactionResult.notificationDetails.sms.length > 0 &&
                          transactionResult.notificationDetails.sms.map((sms, index) => (
                            <p key={'sms-' + index}>
                              {`Last Text sent (${index + 1}): ${
                                sms.sentDate
                                  ? formatDate(sms.sentDate, 'MM/DD/YYYY hh:mm A')
                                  : sms.status
                              }`}
                              <br />
                              {`Texted to: ${sms.toAddess}`} <br />
                              {`Customer Text Status: ${sms.status}`}
                            </p>
                          ))}
                      </>
                    )}
                </Col>
              </Row>

              {transactionResult.status === 'POSTED' && (
                <Row className="mb-4">
                  <Col>
                    <p>
                      <button
                        onClick={e => setIsReceipt(true)}
                        className="download-receipt py-2 px-4"
                      >
                        Download Receipt
                      </button>
                    </p>
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <h5>Customer Response</h5>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={12} md={6} lg={4} xl={3}>
                  <h6>Customer Response</h6>
                  <p>{transactionResult.nextStepsDescription}</p>
                  {transactionResult.dispositionDetails?.authorizedTime && (
                    <>
                      <h6>Customer Response Date</h6>
                      <p>
                        {formatDate(
                          transactionResult.dispositionDetails?.authorizedTime,
                          'MM/DD/YYYY hh:mm A',
                        )}
                      </p>
                    </>
                  )}
                  <h6>Authorized Source</h6>
                  <p>{transactionResult.dispositionDetails?.authorizedSource}</p>
                </Col>
                {transactionResult.status !== 'POSTED' &&
                transactionResult.dispositionDetails?.expirationDate ? (
                  <Col xs={12} md={6} lg={4} xl={3}>
                    <h6>Expiration Date</h6>
                    <p>
                      {transactionResult.txnExpirationDate
                        ? formatDate(transactionResult.txnExpirationDate, 'MM/DD/YYYY hh:mm A')
                        : '-'}
                    </p>
                  </Col>
                ) : (
                  <Col xs={12} md={6} lg={4} xl={3}>
                    <h6>Customer Job Complete Response</h6>
                    <p>
                      {transactionResult.dispositionDetails?.expirationDate
                        ? formatDate(
                            transactionResult.dispositionDetails?.expirationDate,
                            'MM/DD/YYYY hh:mm A',
                          )
                        : '-'}
                    </p>
                  </Col>
                )}
              </Row>
              <Row>
                <Col>
                  <h5 className="d-flex align-items-center">
                    <span
                      className={`current-status ${
                        transactionResult.status === 'POSTED' // ToDo: check if the statues we get from API are: POSTED / PENDING AUTH / etc
                          ? 'green'
                          : transactionResult.status === 'PENDING'
                          ? 'yellow'
                          : 'red'
                      }`}
                    />
                    Current Status
                  </h5>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <h6>Transaction Status</h6>
                  <p>{transactionResult.status}</p>
                </Col>
              </Row>
              <Row className="justify-content-end">
                <Col xs="auto">
                  <Button
                    type="button"
                    color="secondary"
                    className="rounded-pill"
                    outline
                    onClick={() => history.push('/portal/greensky-transaction-history')}
                  >
                    Back
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        ) : (
          <div className="app-panel">No applications found.</div>
        )}
      </Row>
    </Col>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  applications: state.softPull.applications,
  singleTransaction: state.softPull.singleTransaction,
});

const mapDispatchToProps = dispatch => ({
  dispatchGetSingleTransaction: transactionId => dispatch(getSingleTransaction(transactionId)),
  dispatchGetAtWaterGreenskySingleTransaction: transactionId =>
    dispatch(getAtWaterGreenskySingleTransaction(transactionId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SingleTransaction);
