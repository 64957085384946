import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
import { initialState } from '../initialState';

const setFlow = (state, action) => {
  const updatedValues = {
    flowName: action.flowName,
  };
  return updateObject(state, updatedValues);
};

const changeValue = (state, action) => {
  const updatedValues = {
    [action.key]: action.value,
  };
  return updateObject(state, updatedValues);
};

const changePage = (state, action) => {
  const updatedValues = {
    [`${state.flowName}CurrentPage`]: action.page,
  };

  return updateObject(state, updatedValues);
};

const changePageTitle = (state, action) => {
  const updatedValues = {
    [state.flowName]: {
      [`page${state[`${state.flowName}CurrentPage`]}`]: {
        title: action.value,
      },
    },
  };
  return updateObject(state, updatedValues);
};

const naviReducer = (state, action) => {
  if (!state) {
    state = initialState.navi;
  }
  switch (action.type) {
    case actionTypes.CHANGE_NAVI_VALUE:
      return changeValue(state, action);
    case actionTypes.CHANGE_PAGE_TITLE:
      return changePageTitle(state, action);
    case actionTypes.SET_FLOW:
      return setFlow(state, action);
    case actionTypes.CHANGE_PAGE:
      return changePage(state, action);
    case actionTypes.RESET_STATE_NAVI:
      state = initialState.navi;
      return state;
    default:
      return state;
  }
};

export default naviReducer;
