import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import requireAuth from '../components/hoc/requireAuth';
import Login from './Login/Index';
import Portal from './Portal/Index';
import PreQualification from '../pages/PreQualification/Index';
import NotFound from '../components/NotFound/NotFound';
import Aqua from './Aqua/Index';
import Rheem from './Rheem';
import Microf from '../pages/Microf/Index';
import CE from '../pages/Greensky/ContractorEnrollment/Index';
import API from '../pages/API/Index';
import RegisterAsContractor from '../pages/RegisterAsContractor/Index';
import SetYourPassword from '../pages/SetYourPassword/Index';
import ForgotPassword from '../pages/ForgotPassword';
import ContractorEnrollment from '../pages/ContractorEnrollment/Index';
import ContractorEnrollmentPools from '../pages/ContractorEnrollmentPools/Index';
import UpdateMerchantNumber from '../pages/UpdateMerchantNumber/Index';
import { basePath, getIsPool, getIsRheem } from '../config/settings';
import PasswordUpdateSuccessful from '../pages/PasswordUpdateSuccessful';
import { connect } from 'react-redux';
import { getSession } from '../redux/actions/index';
import { withRouter } from 'react-router-dom';
import PowerPayPreQualification from './PowerPay/PreQualification';
import PowerPay from './PowerPay/Flow';
import WellsFargo from './WellsFargo';
import MosaicPreQualification from './Mosaic/PreQualification';
import GreenSkyPreQualification from './Optimus/GreenSky/PreQualification';
import Mosaic from './Mosaic/Flow';
import FoundationPreQualification from './Optimus/Foundation/PreQualification';
import Foundation from './Optimus/Foundation/Flow';
import GreenSky from './Optimus/GreenSky/Flow';
import { renderFavicon } from '../helper';
import EnerBankPreQualification from './EnerBank/PreQualification';
import EnerBank from './EnerBank/Flow';
import FTLPreQualification from './FTL/PreQualification';
import FTL from './FTL/Flow';
import InvalidMerchant from './Optimus/GreenSky/PreQualification/InvalidMerchant';
import AtWaterPreQualification from './Pools/AtWater/PreQualification';
import AtWater from './Pools/AtWater/Flow';
import AtWaterGreenSkyPreQualification from './Pools/Greensky/PreQualification';
import AtWaterGreenSky from './Pools/Greensky/Flow';
import DeactivatedConsumerUsers from './DeactivatedConsumerUsers';
import UpgradePreQualification from './Upgrade/PreQualification';

const isRheem = getIsRheem();

const Router = props => {
  const { workUser, dispatchGetSession, history, theme } = props;

  renderFavicon();

  useEffect(() => {
    if (workUser) {
      dispatchGetSession(history);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={'theme-' + theme}>
      <BrowserRouter basename={basePath}>
        <Switch>
          <Route path="/" exact component={PreQualification} />
          <Route path="/RTO" exact component={Microf} />
          <Route path="/MicrofOK" exact component={requireAuth(Microf)} />
          <Route path="/Aqua" exact component={Aqua} />
          <Route
            path="/GDE"
            exact
            render={() =>
              isRheem ? <Redirect to="/login" /> : <RegisterAsContractor isGreenSky={true} />
            }
          />
          <Route path="/CE" exact component={CE} />
          <Route path="/api" exact component={API} />
          <Route path="/login" exact component={Login} />
          <Route path="/portal" component={Portal} />
          <Route
            path="/RegisterAsContractor"
            exact
            render={() => <RegisterAsContractor isGreenSky={false} />}
          />
          <Route path="/SetYourPassword" exact component={SetYourPassword} />
          <Route
            path="/ContractorEnrollment"
            exact
            component={getIsPool() ? ContractorEnrollmentPools : ContractorEnrollment}
          />
          <Route path="/UpdateMerchantNumber" exact component={UpdateMerchantNumber} />
          <Route path="/ForgotPassword" exact component={ForgotPassword} />
          <Route path="/password-update-successful" exact component={PasswordUpdateSuccessful} />
          <Route path="/rheem" component={Rheem} />
          <Route path="/WellsFargo" exact component={WellsFargo} />
          <Route path="/powerpay-prequalification" exact component={PowerPayPreQualification} />
          <Route path="/powerpay" exact component={PowerPay} />
          <Route path="/mosaic-prequalification" exact component={MosaicPreQualification} />
          <Route path="/mosaic" exact component={Mosaic} />
          <Route path="/foundation-prequalification" exact component={FoundationPreQualification} />
          <Route path="/foundation" exact component={Foundation} />
          <Route path="/greensky-prequalification" exact component={GreenSkyPreQualification} />
          <Route path="/greensky" exact component={GreenSky} />
          <Route path="/greensky-invalid-merchant" exact component={InvalidMerchant} />
          <Route path="/enerbank-prequalification" exact component={EnerBankPreQualification} />
          <Route path="/enerbank" exact component={EnerBank} />
          <Route path="/ftl-prequalification" exact component={FTLPreQualification} />
          <Route path="/ftl" exact component={FTL} />
          <Route path="/atwater-prequalification" exact component={AtWaterPreQualification} />
          <Route path="/atWater" exact component={AtWater} />
          <Route path="/upgrade-prequalification" exact component={UpgradePreQualification} />
          <Route
            path="/atwater-greensky-prequalification"
            exact
            component={AtWaterGreenSkyPreQualification}
          />
          <Route path="/atWater-greensky" exact component={AtWaterGreenSky} />
          <Route path="/deactivated-dealer" exact component={DeactivatedConsumerUsers} />

          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

const mapStateToProps = state => ({
  theme: state.theme.theme,
  workUser:
    state.auth.user.consumerUser || state.auth.user.dealerUser || state.auth.user.internalUser,
});

const mapDispatchToProps = dispatch => ({
  dispatchGetSession: history => dispatch(getSession(history)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Router),
);
