import { Form, withFormik } from 'formik';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Col, Container, Input, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import { changeSoftPullValue, completeMicrofWork } from '../../redux/actions/softPullActions';
import { isMobile } from 'react-device-detect';
import HvacRowHvacWh from './HvacRowHvacWh';
import SystemTypeCompleteFundEnum from '../../assets/js/SystemTypeCompleteFundEnum';
import ErrorModal from '../../components/UI/Modal/ErrorModal';

const CompleteFundMicrofApplication = props => {
  const {
    application,
    dispatchCompleteMicrofWork,
    hcBrands,
    onCancel,
    history,
    auth,
    dispatchChangeSoftPullValue,
  } = props;

  const [isError, setIsError] = useState(false);

  const [infoValues, setInfoValues] = useState(
    application.systemType === SystemTypeCompleteFundEnum.HVAC.value ||
      application.systemType === SystemTypeCompleteFundEnum.WH.value
      ? [
          {
            systemType:
              application.systemType === SystemTypeCompleteFundEnum.HVAC.value
                ? 'HVAC'
                : application.systemType === SystemTypeCompleteFundEnum.WH.value
                ? 'Water Heater'
                : '',
            systemMake: '',
            systemModel: '',
            systemSerialNumber: '',
          },
        ]
      : [
          {
            systemType: SystemTypeCompleteFundEnum.HVAC.value,
            systemMake: '',
            systemModel: '',
            systemSerialNumber: '',
          },
          {
            systemType: SystemTypeCompleteFundEnum.WH.value,
            systemMake: '',
            systemModel: '',
            systemSerialNumber: '',
          },
        ],
  );

  const addMore = values => {
    let infoValuesTmp = [...infoValues];

    infoValuesTmp.forEach((infoValue, index) => {
      infoValue.systemType = values[`systemType${index}`];
      infoValue.systemMake = values[`systemMake${index}`];
      infoValue.systemModel = values[`systemModel${index}`];
      infoValue.systemSerialNumber = values[`systemSerialNumber${index}`];
    });

    infoValuesTmp.push({
      systemType:
        application.systemType === SystemTypeCompleteFundEnum.HVAC.value
          ? 'HVAC'
          : application.systemType === SystemTypeCompleteFundEnum.WH.value
          ? 'Water Heater'
          : 'HVAC',
      systemMake: '',
      systemModel: '',
      systemSerialNumber: '',
    });

    setInfoValues(infoValuesTmp);
  };

  const CompleteFundMicrofApplicationForm = ({ values }) => (
    <Form>
      <h3 className="mb-4">Application</h3>
      <Row className="align-items-center">
        <Col sm="4" className="no-padding">
          <Label className="mb-0">Consumer Name</Label>
        </Col>
        <Col sm="4" className="no-padding">
          <Input
            type="text"
            value={application ? application.nameFirst + ' ' + application.nameLast : ''}
            disabled
            className="form-control h-35p"
          />
        </Col>
      </Row>
      {application.coapplicantNameFirst && (
        <Row className="mt-4">
          <Col sm="4" className="no-padding">
            <Label className="mb-0">Coapplicant Name</Label>
          </Col>
          <Col sm="4" className="no-padding">
            <Input
              type="text"
              value={application.coapplicantNameFirst + ' ' + application.coapplicantNameLast}
              disabled
              className="form-control h-35p"
            />
          </Col>
        </Row>
      )}
      <Row className="mt-4">
        <Col sm="4" className="no-padding">
          <Label className="mb-0">Consumer Address</Label>
        </Col>
        <Col sm="4" className="no-padding">
          <Input
            type="text"
            value={
              application
                ? application.street + ' ' + application.state + ' ' + application.zipcode
                : ''
            }
            disabled
            className="form-control h-35p"
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm="4" className="no-padding">
          <Label className="mb-0">Total Loan or Lease Amount</Label>
        </Col>
        <Col sm="4" className="no-padding">
          <Input
            type="text"
            value={application.totalFinancing || ''}
            disabled
            className="form-control h-35p"
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="no-padding">
          <b>Enter each serialized component of the installed equipment</b>
          <p>
            <i>Example: Furnace, Condenser, Coil, Etc.</i>
          </p>
        </Col>
      </Row>
      {infoValues.map((_info, index) => (
        <HvacRowHvacWh
          hcBrands={hcBrands}
          index={index}
          key={index}
          systemType={application.systemType}
        />
      ))}
      <Row className={'mt-5 ' + (!isMobile ? 'justify-content-end' : 'justify-content-center')}>
        {infoValues.length < 10 && (
          <Button
            type="submit"
            className={'rounded-pill btn-secondary mr-2' + (isMobile ? ' mt-3 pl-5 pr-5' : '')}
            onClick={() => {
              addMore(values);
            }}
          >
            Add More
          </Button>
        )}
        <Button
          color="secondary"
          className={'rounded-pill mr-2' + (isMobile ? ' mt-3 pl-5 pr-5' : '')}
          outline
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          className={'rounded-pill w-auto' + (isMobile ? ' mt-3 pl-5 pr-5' : '')}
        >
          Submit
        </Button>
      </Row>
    </Form>
  );

  const CompleteFundMicrofApplicationFormFormik = withFormik({
    mapPropsToValues: () => ({
      ...infoValues.reduce(
        (previous, current, index) =>
          Object.assign(previous, {
            [`systemType${index}`]: current.systemType,
            [`systemMake${index}`]: current.systemMake,
            [`systemModel${index}`]: current.systemModel,
            [`systemSerialNumber${index}`]: current.systemSerialNumber,
          }),
        {},
      ),
    }),

    validationSchema: Yup.object({
      ...infoValues.reduce(
        (previous, _current, index) =>
          Object.assign(previous, {
            [`systemMake${index}`]: Yup.string().test({
              name: 'required',
              exclusive: false,
              message: 'System make is required.',
              test: function(value) {
                const systemModel = this.resolve(Yup.ref(`systemModel${index}`));
                const systemSerialNumber = this.resolve(Yup.ref(`systemSerialNumber${index}`));
                if (systemModel || systemSerialNumber || !index) return !!value;
                return true;
              },
            }),
            [`systemModel${index}`]: Yup.string().test({
              name: 'required',
              exclusive: false,
              message: 'System model is required.',
              test: function(value) {
                const systemMake = this.resolve(Yup.ref(`systemMake${index}`));
                const systemSerialNumber = this.resolve(Yup.ref(`systemSerialNumber${index}`));
                if (systemMake || systemSerialNumber || !index) return !!value;
                return true;
              },
            }),
            [`systemSerialNumber${index}`]: Yup.string()
              .test({
                name: 'required',
                exclusive: false,
                message: 'System serial number is required.',
                test: function(value) {
                  const systemMake = this.resolve(Yup.ref(`systemMake${index}`));
                  const systemModel = this.resolve(Yup.ref(`systemModel${index}`));
                  if (systemMake || systemModel || !index) return !!value;
                  return true;
                },
              })
              .test({
                name: 'emptyString',
                exclusive: false,
                message: 'Invalid Serial Number.',
                test: function(value) {
                  return value ? value.trim() !== '' : true;
                },
              })
              .test({
                name: 'allCharEqual',
                exclusive: false,
                message: 'Invalid Serial Number.',
                test: function(value) {
                  return value ? !/^(.)\1*$/.test(value.trim()) : true;
                },
              }),
          }),
        {},
      ),
    }),

    handleSubmit: values => {
      let selectedBrands = [];

      infoValues.forEach((_infoValue, index) => {
        if (
          values[`systemType${index}`] &&
          values[`systemMake${index}`] &&
          values[`systemModel${index}`] &&
          values[`systemSerialNumber${index}`]
        )
          selectedBrands.push({
            applicationAcBrandId: 0,
            systemType: values[`systemType${index}`],
            brandId: hcBrands.find(
              brand => brand.name.trim() === values[`systemMake${index}`].trim(),
            ).acHeaterBrandId,
            systemMake: values[`systemMake${index}`],
            modelName: values[`systemModel${index}`],
            serialNumber: values[`systemSerialNumber${index}`],
            brandName: null,
            systemName: `Unit ${index + 1}`,
            systemSize: null,
            opportunityId: null,
            salesforceId: null,
            isDeleted: false,
          });
      });

      let applicationDTO = application;
      applicationDTO = {
        ...applicationDTO,
        selectedBrands: selectedBrands,
      };

      let infoValuesTmp = [...infoValues];

      infoValuesTmp.forEach((infoValue, index) => {
        infoValue.systemType = values[`systemType${index}`];
        infoValue.systemMake = values[`systemMake${index}`];
        infoValue.systemModel = values[`systemModel${index}`];
        infoValue.systemSerialNumber = values[`systemSerialNumber${index}`];
      });

      if (auth.user.user) {
        if (
          application.systemType === SystemTypeCompleteFundEnum.HVAC.value ||
          application.systemType === SystemTypeCompleteFundEnum.WH.value
        ) {
          dispatchChangeSoftPullValue('application', applicationDTO);
          history.push(
            '/portal/open-applications/installation-certification?appId=' + applicationDTO.id,
          );
        } else {
          const isHvac = selectedBrands.findIndex(
            x => x.systemType === SystemTypeCompleteFundEnum.HVAC.value,
          );
          const isWh = selectedBrands.findIndex(
            x => x.systemType === SystemTypeCompleteFundEnum.WH.value,
          );
          if (isHvac < 0 || isWh < 0) {
            setIsError(true);
          } else {
            dispatchChangeSoftPullValue('application', applicationDTO);
            history.push(
              '/portal/open-applications/installation-certification?appId=' + applicationDTO.id,
            );
          }
        }
      } else {
        if (
          application.systemType === SystemTypeCompleteFundEnum.HVAC.value ||
          application.systemType === SystemTypeCompleteFundEnum.WH.value
        ) {
          dispatchCompleteMicrofWork(applicationDTO, history, '/Login');
        } else {
          const isHvac = selectedBrands.findIndex(
            x => x.systemType === SystemTypeCompleteFundEnum.HVAC.value,
          );
          const isWh = selectedBrands.findIndex(
            x => x.systemType === SystemTypeCompleteFundEnum.WH.value,
          );
          if (isHvac < 0 || isWh < 0) {
            setIsError(true);
          } else {
            dispatchCompleteMicrofWork(applicationDTO, history, '/Login');
          }
        }
      }
    },
  })(CompleteFundMicrofApplicationForm);

  return (
    <Container>
      <Row>
        <Col>
          <CompleteFundMicrofApplicationFormFormik />
        </Col>
      </Row>
      <ErrorModal
        isOpen={isError}
        messageType=""
        message={<>Please enter at least one asset for HVAC and one asset for Water Heater.</>}
        onClick={() => setIsError(false)}
      />
    </Container>
  );
};

const mapStateToProps = state => ({
  application: state.softPull.application,
  hcBrands: state.softPull.hcBrands,
  auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
  dispatchCompleteMicrofWork: (application, history, props) =>
    dispatch(completeMicrofWork(application, history, props)),
  dispatchChangeSoftPullValue: (key, value) => dispatch(changeSoftPullValue(key, value)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CompleteFundMicrofApplication),
);
