import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import userRoleEnum from '../../../assets/js/UserRoleEnum';
import { searchDealerUsers, changeSoftPullValue } from '../../../redux/actions';
import { withRouter } from 'react-router-dom';

const SearchContractorUsers = props => {
  const {
    history,
    dispatchSearchDealerUsers,
    dispatchChangeSoftPullValue,
    searchDealerUser,
  } = props;

  useEffect(() => {
    if (!searchDealerUser) {
      dispatchSearchDealerUsers();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Col>
      <Row>
        <Col>
          <h1>{`Users - ${searchDealerUser ? searchDealerUser.dealerName : 'N/A'}`}</h1>
        </Col>
      </Row>
      <Row className="search-users panel p-3">
        <Col>
          {searchDealerUser && searchDealerUser.users
            ? searchDealerUser.users.map((user, index) => (
                <Row
                  key={index}
                  className="contractor-user-view-row border-bottom cursor-pointer"
                  onClick={() => {
                    dispatchChangeSoftPullValue('selectedContractorUser', user);
                    history.push('/portal/manage-contractor-user');
                  }}
                >
                  <Col xs="6">
                    <div>{user.firstName + ' ' + user.lastName}</div>
                  </Col>
                  <Col xs="6">
                    <div>{user.role ? userRoleEnum[user.role].name : 'N/A'}</div>
                  </Col>
                </Row>
              ))
            : 'No users found'}
        </Col>
      </Row>
    </Col>
  );
};

const mapStateToProps = state => ({
  searchDealerUser: state.softPull.searchDealerUser,
});

const mapDispatchToProps = dispatch => ({
  dispatchSearchDealerUsers: () => dispatch(searchDealerUsers()),
  dispatchChangeSoftPullValue: (field, value) => dispatch(changeSoftPullValue(field, value)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SearchContractorUsers),
);
