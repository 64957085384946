import axios from 'axios';
import AirConditioningSystemEnum from '../../assets/js/AirConditioningSystemEnum';
import { isMicrofAddedByAPI } from '../../assets/js/ApplicationStatusEnum';
import employmentTypes from '../../assets/js/EmploymentTypeEnum';
import { filterSelectedQuestions, isHvacProjectType, isSameAddress } from '../../helper';
import {
  CHANGE_ENER_BANK_INFO_VALUE,
  SUBMIT_APPLICATION_SOFT_PULL_ERROR,
  SUBMIT_APPLICATION_SOFT_PULL_SUCCESS,
} from './actionTypes';
import { baseURL, handleApiError } from './api';
import { changePage } from './naviActions';
import { addNotification, endLoading, startLoading } from './notificationActions';
import { changePreQualInfoValue } from './preQualInfoActions';
import { checkErrors } from './index';
import { mapApplicationToPrequalification } from './softPullActions';

const errorMessageHvacTypes =
  'Unfortunately, there are no project types available for Air Conditioning System.';
const errorMessageEmptyProjectTypes =
  'Unfortunately, there are no project types available for the provided Loan Code. Please contact your contractor.';

const qs = require('qs');
export const changeEnerBankInfoValue = (key, value) => ({
  type: CHANGE_ENER_BANK_INFO_VALUE,
  key,
  value,
});

const mapIdentityVerification = app => dispatch =>
  dispatch(
    changeEnerBankInfoValue('identityVerification', {
      applicant:
        filterSelectedQuestions(app.enerBankApplicantQuestion)?.map(question => ({
          questionId: question.questionId,
          selectedChoices: [],
        })) || [],
      coApplicant:
        filterSelectedQuestions(app.enerBankCoApplicantQuestion)?.map(question => ({
          questionId: question.questionId,
          selectedChoices: [],
        })) || [],
    }),
  );

export const mapEnerBankToState = app => dispatch => {
  dispatch(changePreQualInfoValue('applicationId', app.applicationId));
  dispatch(mapApplicationToPrequalification(app));

  // EnerBank
  dispatch(changeEnerBankInfoValue('borrowedAmount', app.totalFinancingAmount));
  dispatch(changeEnerBankInfoValue('propertyType', app.propertyType));
  dispatch(changeEnerBankInfoValue('propertyMonths', app.numberOfMonthsAtAddress));
  dispatch(changeEnerBankInfoValue('propertyYears', app.numberOfYearsAtAddress));
  dispatch(mapIdentityVerification(app));

  dispatch(changeEnerBankInfoValue('homeownerInsurance', app.homeownerInsurance));
  dispatch(changeEnerBankInfoValue('isHomeowner', true));
  const { aquaFinanceInformation } = app;

  if (app.applicantPrimary) {
    const applicant = app.applicantPrimary;
    dispatch(changePreQualInfoValue('firstName', applicant.nameFirst));
    dispatch(changePreQualInfoValue('middleName', applicant.nameMiddle));
    dispatch(changePreQualInfoValue('lastName', applicant.nameLast));
    dispatch(changePreQualInfoValue('phoneNumber', applicant.mobilePhone?.number));
    dispatch(changePreQualInfoValue('birthday', app.dob));
    if (applicant.address && isMicrofAddedByAPI(app.status)) {
      const address = applicant.address;
      dispatch(
        changeEnerBankInfoValue('applicantAddress', {
          city: address.city,
          state: address.state,
          street: address.street,
          zip: address.zipcode,
        }),
      );
    }

    const employmentType = Object.keys(employmentTypes).find(
      key =>
        employmentTypes[key].enerBankValue === applicant.employmentType ||
        employmentTypes[key].aquaValue === applicant.employmentType,
    );

    dispatch(changeEnerBankInfoValue('appEmploymentType', employmentTypes[employmentType]?.value));
    dispatch(
      changeEnerBankInfoValue(
        'appOccupation',
        app.occupation || aquaFinanceInformation?.employmentPosition,
      ),
    );
    dispatch(changeEnerBankInfoValue('appEmployerName', app.employer));
    dispatch(changeEnerBankInfoValue('appEmploymentYears', app.employmentYears));
    dispatch(changeEnerBankInfoValue('appEmploymentMonths', app.employmentMonths));
    dispatch(changeEnerBankInfoValue('appEmployerZipCode', app.appEmployerZipCode));
    dispatch(changeEnerBankInfoValue('isAppMilitaryActive', applicant.militaryActive));
    dispatch(changeEnerBankInfoValue('isAppUsCitizen', applicant.uscitizen));
    dispatch(changeEnerBankInfoValue('appDriversLicenseNumber', applicant.idNumber));
    dispatch(changeEnerBankInfoValue('appDriversLicenseState', applicant.driversLicenseSOI));
    dispatch(changeEnerBankInfoValue('appGrossAnnualIncome', applicant.grossYearlyIncome));
    dispatch(changeEnerBankInfoValue('appFullSsn', applicant.ssn));
  }
  if (app.applicantNonPrimary) {
    const coapp = app.applicantNonPrimary;
    dispatch(changeEnerBankInfoValue('hasCoApp', true));
    dispatch(changeEnerBankInfoValue('coAppIdNumber', coapp.idNumber));
    dispatch(changeEnerBankInfoValue('coAppDriversLicenseState', coapp.driversLicenseSOI));
    dispatch(changeEnerBankInfoValue('grossYearlyIncome', coapp.grossYearlyIncome));
    dispatch(changeEnerBankInfoValue('coAppFullSsn', coapp.ssn));
    dispatch(changeEnerBankInfoValue('coapplicantEmployerType', app.coapplicantEmployerType));
    dispatch(
      changeEnerBankInfoValue(
        'coAppOccupation',
        coapp.occupation || aquaFinanceInformation?.coapplicantEmploymentPosition,
      ),
    );
    dispatch(changeEnerBankInfoValue('coapplicantEmployerName', app.coapplicantEmployerName));
    dispatch(changeEnerBankInfoValue('coapplicantEmploymentYears', app.coapplicantEmploymentYears));
    dispatch(
      changeEnerBankInfoValue('coapplicantEmploymentMonths', app.coapplicantEmploymentMonths),
    );
    dispatch(changeEnerBankInfoValue('coAppEmployerZipCode', app.coAppEmployerZipCode));
    dispatch(changeEnerBankInfoValue('isCoAppMilitaryActive', coapp.militaryActive));
    dispatch(changeEnerBankInfoValue('isCoAppUsCitizen', coapp.uscitizen));

    dispatch(changeEnerBankInfoValue('relationshipWithCoapplicant', coapp.relationshipToPrimary));
    dispatch(changeEnerBankInfoValue('coAppFirstName', coapp.nameFirst));
    dispatch(changeEnerBankInfoValue('coAppLastName', coapp.nameLast));
    dispatch(changeEnerBankInfoValue('coAppMiddleInitial', coapp.nameMiddle));
    dispatch(changeEnerBankInfoValue('coAppPhoneNumber', coapp.mobilePhone?.number));
    dispatch(changeEnerBankInfoValue('coAppEmail', coapp.email?.email));

    const { address } = coapp;
    if (address) {
      dispatch(
        changeEnerBankInfoValue(
          'isSameAddress',
          isSameAddress(app.applicantPrimary.address, address),
        ),
      );
      dispatch(changeEnerBankInfoValue('coAppStreet', address.street));
      dispatch(changeEnerBankInfoValue('coAppPOBox', address.poBox));
      dispatch(changeEnerBankInfoValue('coAppCity', address.city));
      dispatch(changeEnerBankInfoValue('coAppZipCode', address.zipcode));
      dispatch(changeEnerBankInfoValue('coAppState', address.state));
    }

    dispatch(changeEnerBankInfoValue('coAppBirthday', coapp.birthDate));
    dispatch(changeEnerBankInfoValue('coAppDriversLicenseNumber', coapp.idNumber));
    dispatch(changeEnerBankInfoValue('coAppGrossAnnualIncome', coapp.grossYearlyIncome));

    const employmentType = Object.keys(employmentTypes).find(
      key =>
        employmentTypes[key].enerBankValue === coapp.employmentTypee ||
        employmentTypes[key].aquaValue === coapp.employmentType,
    );
    dispatch(
      changeEnerBankInfoValue('coAppEmploymentType', employmentTypes[employmentType]?.value),
    );
    dispatch(changeEnerBankInfoValue('coAppEmployerName', coapp.employer));
    dispatch(changeEnerBankInfoValue('coAppEmploymentMonths', coapp.employmentMonths));
    dispatch(changeEnerBankInfoValue('coAppEmploymentYears', coapp.employmentYears));
    dispatch(changeEnerBankInfoValue('coAppEmployerZipCode', coapp.employerZipcode));
  }

  const mailingAddress = {
    street: app.mailingAddressStreet,
    city: app.mailingAddressCity,
    zipcode: app.mailingAddressZipcode,
    state: app.mailingAddressState,
    apartmentNumber: app.mailingAddressApartmentNumber,
  };
  dispatch(changeEnerBankInfoValue('mailingAddress', mailingAddress));
  dispatch(changeEnerBankInfoValue('ownership', app.enerBankOwnership));
  dispatch(changeEnerBankInfoValue('mortgage', app.mortgage));
  dispatch(changeEnerBankInfoValue('programPhoneNumber', app.programPhoneNumber));
  dispatch(changeEnerBankInfoValue('loanCode', app.loanCode));
  dispatch(changeEnerBankInfoValue('contractorId', app.contractorId));
  dispatch(changeEnerBankInfoValue('projectTypeId', app.projectType));
  dispatch(changeEnerBankInfoValue('coApplicantType', app.coApplicantType));
};

export const setEnerBankApplicationPromotion = () => {};

export const getEnerBankProgram = page => async (dispatch, getState) => {
  let errorMessage = '';
  const {
    enerBankInformation: { programPhoneNumber, loanCode, contractorId },
    preQualificationInformation: { airConditioningSystem },
  } = getState();

  dispatch(changeEnerBankInfoValue('programProduct', undefined));
  dispatch(changeEnerBankInfoValue('programProjectTypes', undefined));

  dispatch(startLoading());

  await axios
    .get(
      `${baseURL}/api/soft_pull/enerbank/sponsorprogram/${programPhoneNumber}/${loanCode}/${contractorId}`,
    )
    .then(async response => {
      const { product, projectTypes, errors } = response.data;
      if (!errors) {
        if (
          !projectTypes ||
          (airConditioningSystem === AirConditioningSystemEnum.AIR_CONDITION_OR_HEATING_SYSTEM.id &&
            !projectTypes.some(isHvacProjectType))
        ) {
          dispatch(
            addNotification({
              message: !projectTypes ? errorMessageEmptyProjectTypes : errorMessageHvacTypes,
              messageType: 'Error',
            }),
          );
          return;
        }
        dispatch(changeEnerBankInfoValue('programProduct', product));
        dispatch(changeEnerBankInfoValue('programProjectTypes', projectTypes));
        dispatch(changePage(page));
      } else {
        errors.forEach(error => (errorMessage += error.message + ` `));
        dispatch(
          addNotification({
            message: errorMessage,
            messageType: 'Error',
          }),
        );
      }
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
    });

  dispatch(endLoading());
};

export const submitEnerBankApplication = (params, page, app) => async dispatch => {
  dispatch(
    startLoading(
      'Give us a second while we find the best ' +
        params.selectedFinancier +
        ' financing option for you.',
    ),
  );

  if (app && app.enerBankInformation?.refrenceNumber) {
    await axios
      .put(
        `${baseURL}/api/soft_pull/enerbank/update?appToken=${app.consumerToken}&appId=${
          app.applicationId
        }`,
        params,
      )
      .then(async response => {
        dispatch(getAppResponseAndSubmission(response, page));
      })
      .catch(error => {
        dispatch(
          addNotification({
            message: handleApiError(error),
            messageType: 'Error',
          }),
        );
        dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_ERROR, payload: error });
        dispatch(endLoading());
      });
  } else if (app) {
    await axios
      .post(
        `${baseURL}/api/soft_pull/resubmit?appToken=${app.consumerToken}&appId=${
          app.applicationId
        }`,
        params,
      )
      .then(async response => {
        dispatch(getAppResponseAndSubmission(response, page));
      })
      .catch(error => {
        dispatch(
          addNotification({
            message: handleApiError(error),
            messageType: 'Error',
          }),
        );
        dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_ERROR, payload: error });
        dispatch(endLoading());
      });
  } else {
    await axios
      .post(
        `${baseURL}/api/soft_pull/enerbank/submit`,
        qs.stringify({ data: '' + JSON.stringify(params) }),
      )
      .then(async response => {
        dispatch(getAppResponseAndSubmission(response, page));
      })
      .catch(error => {
        dispatch(
          addNotification({
            message: handleApiError(error),
            messageType: 'Error',
          }),
        );
        dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_ERROR, payload: error });
        dispatch(endLoading());
      });
  }
};

const getAppResponseAndSubmission = (response, page) => async dispatch => {
  dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_SUCCESS, payload: response.data });
  if (dispatch(checkErrors(response))) {
    if (response.data.applicationId) {
      await axios
        .post(
          `${baseURL}/api/soft_pull/enerbank/submission`,
          qs.stringify({ applicationId: '' + response.data.applicationId }),
        )
        .then(async response1 => {
          const { data } = response1;
          dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_SUCCESS, payload: data });
          dispatch(mapIdentityVerification(data));

          if (dispatch(checkErrors(response))) {
            dispatch(changePage(page));
            dispatch(endLoading());
          }
        })
        .catch(error => {
          dispatch(
            addNotification({
              message: handleApiError(error),
              messageType: 'Error',
            }),
          );
          dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_ERROR, payload: error });
          dispatch(endLoading());
        });
    } else {
      let error = 'Missing Application Id';
      dispatch(
        addNotification({
          message: handleApiError(),
          messageType: 'Error',
        }),
      );
      dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_ERROR, payload: error });
      dispatch(endLoading());
    }
  }
};

export const postIdentityVerification = (appId, request) => async dispatch => {
  const postUrl = `${baseURL}/api/soft_pull/enerbank/identityverification?appId=${appId}`;
  dispatch(startLoading());

  return axios
    .post(postUrl, request)
    .then(async response => {
      const { data } = response;
      dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_SUCCESS, payload: data });
      dispatch(mapIdentityVerification(data));
      dispatch(endLoading());
    })
    .catch(error => {
      dispatch(
        addNotification({
          message: handleApiError(error),
          messageType: 'Error',
        }),
      );
      dispatch({ type: SUBMIT_APPLICATION_SOFT_PULL_ERROR, payload: error });
      dispatch(endLoading());
    });
};
