import React, { useState } from 'react';
import InputFeedback from '../InputFeedback/InputFeedback';
import { Label, FormGroup, Input, Tooltip, Row, Col } from 'reactstrap';
import { IoMdLock, IoMdEyeOff } from 'react-icons/io';
import punycode from 'punycode';
import { GoInfo } from 'react-icons/go';

const CustomInput = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched, setFieldValue },
  icon,
  max,
  min,
  maxLength,
  className,
  pattern,
  disabled,
  number,
  label,
  labelClassName,
  type,
  id,
  placeholder,
  formGroupClassName,
  readOnly,
  tooltipText,
  ...props
}) => {
  const [inputType, setType] = useState(type);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const autoComplete = inputType === 'password' ? 'off' : 'on';
  const rowClassName = `${icon ? 'input-with-icon' : ''} align-items-center`;
  const fieldType = inputType === 'password' ? 'text' : inputType;
  const inputOnChange = e =>
    number
      ? /^\d*$/.test(e.currentTarget.value)
        ? setFieldValue(name, e.currentTarget.value)
        : null
      : !/[?]/.test(e.currentTarget.value) &&
        !/[À-ÖØ-öø-ÿ]/.test(punycode.toUnicode(e.currentTarget.value))
      ? setFieldValue(name, punycode.toUnicode(e.currentTarget.value))
      : null;

  return inputType !== 'hidden' ? (
    <FormGroup className={formGroupClassName}>
      <Label for={id} className={labelClassName}>
        <b>{label}</b>
      </Label>
      <Row className={rowClassName}>
        <Col>
          <Input
            placeholder={placeholder}
            name={name}
            id={id}
            type={fieldType}
            value={value}
            disabled={disabled}
            readOnly={readOnly}
            checked={value}
            onChange={inputOnChange}
            onBlur={onBlur}
            pattern={pattern}
            className={
              'form-control ' + (className || '') + (inputType === 'password' ? ' password' : '')
            }
            autoComplete={autoComplete}
            maxLength={maxLength}
            max={max}
            min={min}
          />
          {icon === 'eye' && (
            <IoMdEyeOff
              onClick={() => {
                setType(inputType === 'text' ? 'password' : 'text');
              }}
              className="icon"
            />
          )}
          {icon === 'lock' && (
            <IoMdLock
              onClick={() => {
                setType(inputType === 'text' ? 'password' : 'text');
              }}
              className="icon"
            />
          )}
        </Col>
        <Col xs="auto" className="pl-0">
          <div id={`tooltip-${id}`} className="cursor-pointer">
            <GoInfo size={20} />
          </div>
        </Col>
        <Tooltip
          placement="right"
          target={`tooltip-${id}`}
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          autohide={false}
        >
          {tooltipText ? tooltipText : `Please insert '${label}'`}
        </Tooltip>
      </Row>
      {touched[name] && <InputFeedback error={errors[name]} />}
    </FormGroup>
  ) : (
    <InputFeedback error={errors[name]} />
  );
};

export default CustomInput;
