import React from 'react';
import { connect } from 'react-redux';
import { Col, Row, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { resetNewAppState } from '../../../redux/actions/index';

const FundingSubmitted = props => {
  const { history, dispatchResetNewAppState } = props;

  const returnToOpenApplications = () => {
    dispatchResetNewAppState();
    history.push('/portal/open-applications');
  };
  return (
    <Col>
      <Row className="panel p-3 pt-5">
        <Col className="text-center">
          <Button
            type="submit"
            color="primary"
            className="rounded-pill"
            onClick={() => {
              returnToOpenApplications();
            }}
          >
            Return
          </Button>
        </Col>
      </Row>
    </Col>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  dispatchResetNewAppState: () => dispatch(resetNewAppState()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(FundingSubmitted),
);
