import AddedFromEnum from '../assets/js/AddedFromEnum';

export const getBaseURL = () => {
  return process.env.REACT_APP_API_URL;
};

export const getConsumerUrl = () => {
  return process.env.REACT_APP_AQUA_CONSUMER_URL;
};

export const getOrbitUrl = () => {
  return process.env.REACT_APP_AQUA_ORBIT_URL;
};

export const getPrivacyStatementURL = () => {
  return process.env.REACT_APP_PRIVACY_STATEMENT_URL;
};

export const getUpgradePrivacyStatementURL = () => {
  return process.env.REACT_APP_PRIVACY_STATEMENT_URL_UPGRADE;
};

export const getPrivacyStatementMicrofURL = () => {
  return process.env.REACT_APP_PRIVACY_STATEMENT_MICROF_URL;
};

export const basePath = process.env.REACT_APP_BASE_PATH;

export const getDealerPortalName = () => {
  return process.env.REACT_APP_DEALER_PORTAL_NAME;
};

export const getDealerPortalURL = () => {
  return process.env.REACT_APP_DEALER_PORTAL_URL + '/login';
};

export const getMicrofUrl = () => {
  return process.env.REACT_APP_MICROF_URL;
};

export const getRheemUrl = () => {
  return process.env.REACT_APP_RHEEM_URL;
};

export const getOptimusUrl = () => {
  return process.env.REACT_APP_OPTIMUS_URL;
};

export const getIsMicrof = () => {
  return process.env.REACT_APP_ADDED_FROM === AddedFromEnum.MICROF.value;
};

export const getIsRheem = () => {
  return process.env.REACT_APP_ADDED_FROM === AddedFromEnum.RHEEM.value;
};

export const getIsOptimus = () => {
  return process.env.REACT_APP_ADDED_FROM === AddedFromEnum.OPTIMUS.value;
};

export const getIsPool = () => {
  return process.env.REACT_APP_ADDED_FROM === AddedFromEnum.POOLS.value;
};

export const getTheme = () => {
  return process.env.REACT_APP_THEME;
};

export const getAppName = () => {
  return process.env.REACT_APP_NAME;
};

export const getAddedFrom = () => {
  return process.env.REACT_APP_ADDED_FROM;
};

export const getResourcesUrl = () => {
  return process.env.REACT_APP_RESOURCES_URL;
};

export const getSFCPortalUrl = () => {
  return process.env.REACT_APP_SFC_PORTAL_URL;
};

export const getWFCreditConnectUrl = () => {
  return process.env.REACT_APP_WELLS_FARGO_CREDIT_CONNECT_URL;
};

export const getPowerPayContractorURL = () => {
  return process.env.REACT_APP_POWER_PAY_CONTRACTOR_URL;
};

export const getMosaicUrl = () => {
  return process.env.REACT_APP_MOSAIC_URL;
};

export const getMosaicLoginUrl = () => {
  return process.env.REACT_APP_MOSAIC_LOGIN_URL;
};

export const getMicrofStoryUrl = () => {
  return process.env.REACT_APP_THE_MICROF_STORY_URL;
};

export const getCurrentENV = () => {
  return process.env.REACT_APP_ENV;
};

export const getGreenSkyDisclosuresUrl = () => {
  return process.env.REACT_APP_GREEN_SKY_DISCLOSURES_URL;
};

export const getGreenSkyDisclosuresType = () => {
  return process.env.REACT_APP_GREEN_SKY_DISCLOSURES_TYPE;
};

export const getGreenSkyDisclosuresProgram = () => {
  return process.env.REACT_APP_GREEN_SKY_DISCLOSURES_PROGRAM;
};

export const getGreenSkyApiKey = () => {
  return process.env.REACT_APP_GREEN_SKY_API_KEY;
};

export const getGreenSkyLoginUrl = () => {
  return process.env.REACT_APP_GREENSKY_LOGIN_URL;
};

export const getTechnicalSupportNumber = () => {
  return process.env.REACT_APP_TECHNICAL_SUPPORT_NUMBER;
};

export const getFoundationUrl = () => {
  return process.env.REACT_APP_FOUNDATION_URL;
};

export const getFoundationDisclosuresUrl = () => {
  return process.env.REACT_APP_FOUNDATION_DISCLOSURES_URL;
};

export const getGreenSkyTechnicalSupportNumber = () =>
  process.env.REACT_APP_GREEN_SKY_TECHNICAL_SUPPORT_NUMBER;

export const getPixelSiteScoutUrl = () => {
  return process.env.REACT_APP_VS_PIXEL_SITE_SCOUT_URL + getVitalStormTagId();
};

export const getVitalStormTagId = () => {
  return process.env.REACT_APP_VS_PIXEL_ID;
};

export const getOptimusNewLenderUrl = () => {
  return process.env.REACT_APP_OPTIMUS_NEW_LENDER_URL;
};

export const getOptimusContractorRegistrationUrl = () => {
  return process.env.REACT_APP_OPTIMUS_CONTRACTOR_REGISTRATION_URL;
};

export const getFTLContinueApplicationUrl = () => {
  return process.env.REACT_APP_FTL_CONTINUE_APPLICATION_URL;
};

export const getEnerBankTechSupport = () => {
  return process.env.REACT_APP_ENERBANK_TECH_SUPPORT;
};
