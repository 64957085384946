const USStateEnum = {
  Alabama: { value: 'AL', name: 'Alabama', type: 'A', code: '60' },
  Alaska: { value: 'AK', name: 'Alaska', type: 'A', code: '2' },
  Arizona: { value: 'AZ', name: 'Arizona', type: 'A', code: '4' },
  Arkansas: { value: 'AR', name: 'Arkansas', type: 'A', code: '5' },
  California: { value: 'CA', name: 'California', type: 'F', code: '6' },
  Colorado: { value: 'CO', name: 'Colorado', type: 'A', code: '7' },
  Connecticut: {
    value: 'CT',
    name: 'Connecticut',
    type: 'D',
    code: '8',
  },
  Delaware: { value: 'DE', name: 'Delaware', type: 'B', code: '9' },
  DistrictOfColumbia: {
    value: 'DC',
    name: 'District of Columbia',
    type: 'A',
    code: '10',
  },
  Florida: { value: 'FL', name: 'Florida', type: 'A', code: '12' },
  Georgia: { value: 'GA', name: 'Georgia', type: 'A', code: '13' },
  Hawaii: { value: 'HI', name: 'Hawaii', type: 'A', code: '15' },
  Idaho: { value: 'ID', name: 'Idaho', type: 'A', code: '16' },
  Illinois: { value: 'IL', name: 'Illinois', type: 'A', code: '17' },
  Indiana: { value: 'IN', name: 'Indiana', type: 'A', code: '18' },
  Iowa: { value: 'IA', name: 'Iowa', type: 'C', code: '19' },
  Kansas: { value: 'KS', name: 'Kansas', type: 'A', code: '20' },
  Kentucky: { value: 'KY', name: 'Kentucky', type: 'A', code: '21' },
  Louisiana: { value: 'LA', name: 'Louisiana', type: 'A', code: '22' },
  Maine: { value: 'ME', name: 'Maine', type: 'D', code: '23' },
  Maryland: { value: 'MD', name: 'Maryland', type: 'A', code: '25' },
  Massachusetts: {
    value: 'MA',
    name: 'Massachusetts',
    type: 'A',
    code: '26',
  },
  Michigan: { value: 'MI', name: 'Michigan', type: 'E', code: '27' },
  Minnesota: { value: 'MN', name: 'Minnesota', type: 'A', code: '28' },
  Mississippi: {
    value: 'MS',
    name: 'Mississippi',
    type: 'A',
    code: '29',
  },
  Missouri: { value: 'MO', name: 'Missouri', type: 'A', code: '30' },
  Montana: { value: 'MT', name: 'Montana', type: 'A', code: '31' },
  Nebraska: { value: 'NE', name: 'Nebraska', type: 'B', code: '32' },
  Nevada: { value: 'NV', name: 'Nevada', type: 'A', code: '33' },
  NewHampshire: {
    value: 'NH',
    name: 'New Hampshire',
    type: 'A',
    code: '34',
  },
  NewJersey: { value: 'NJ', name: 'New Jersey', type: 'A', code: '35' },
  NewMexico: { value: 'NM', name: 'New Mexico', type: 'A', code: '36' },
  NewYork: { value: 'NY', name: 'New York', type: 'F', code: '37' },
  NorthCarolina: {
    value: 'NC',
    name: 'North Carolina',
    type: 'A',
    code: '38',
  },
  NorthDakota: {
    value: 'ND',
    name: 'North Dakota',
    type: 'A',
    code: '38',
  },
  Ohio: { value: 'OH', name: 'Ohio', type: 'D', code: '41' },
  Oklahoma: { value: 'OK', name: 'Oklahoma', type: 'A', code: '42' },
  Oregon: { value: 'OR', name: 'Oregon', type: 'A', code: '43' },
  Pennsylvania: {
    value: 'PA',
    name: 'Pennsylvania',
    type: 'A',
    code: '45',
  },
  PuertoRico: { value: 'PR', name: 'Puerto Rico', type: 'A', code: '46' },
  RhodeIsland: {
    value: 'RI',
    name: 'Rhode Island',
    type: 'A',
    code: '47',
  },
  SouthCarolina: {
    value: 'SC',
    name: 'South Carolina',
    type: 'B',
    code: '48',
  },
  SouthDakota: {
    value: 'SD',
    name: 'South Dakota',
    type: 'A',
    code: '49',
  },
  Tennessee: { value: 'TN', name: 'Tennessee', type: 'A', code: '50' },
  Texas: { value: 'TX', name: 'Texas', type: 'A', code: '51' },
  Utah: { value: 'UT', name: 'Utah', type: 'A', code: '52' },
  Vermont: { value: 'VT', name: 'Vermont', type: 'D', code: '53' },
  Virginia: { value: 'VA', name: 'Virginia', type: 'A', code: '55' },
  Washington: { value: 'WA', name: 'Washington', type: 'A', code: '56' },
  WestVirginia: {
    value: 'WV',
    name: 'West Virginia',
    type: 'F',
    code: '57',
  },
  Wisconsin: { value: 'WI', name: 'Wisconsin', type: 'A', code: '58' },
  Wyoming: { value: 'WY', name: 'Wyoming', type: 'A', code: '59' },
};

export default USStateEnum;
