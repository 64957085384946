import React from 'react';
import AccountEmail from './AccountEmail';
import AccountPassword from './AccountPassword';
import LoanAmount from './LoanAmount';
import LoanFor from './LoanFor';
import UserAddress from './UserAddress';
import UserInfo from './UserInfo';
import UserLastInfo from './UserLastInfo';
import UserType from './UserType';
import NotAllowed from './NotAllowed';
import ScoreError from './ScoreError';
import RheemLanding from '../Rheem/RheemLanding';
import ApplicationType from './ApplicationType';
import InvalidResponse from './InvalidResponse';

const PreQualificationFlow = [
  <UserType title="Pre-Qualification Information">
    <h4>Let’s find you the best financing option.</h4>
    <p>&nbsp;</p>
  </UserType>,
  <AccountEmail title="Account Details">
    <h4>Let’s start with your email address.</h4>
  </AccountEmail>,
  <AccountPassword title="Account Details">
    <h4>Choose a password that you can remember.</h4>
  </AccountPassword>,
  <LoanFor title="Pre-Qualification Information" />,
  <LoanAmount title="Pre-Qualification Information">
    <h4>What is your desired financing amount?</h4>
  </LoanAmount>,
  <UserInfo title="Pre-Qualification Information">
    <h4>What’s your name and number?</h4>
  </UserInfo>,
  <UserAddress title="Pre-Qualification Information">
    <h4>What address will you be making the home improvement at?</h4>
  </UserAddress>,
  <UserLastInfo title="Pre-Qualification Information" />,
  <NotAllowed />,
  <ScoreError>
    <h4>We could not return a Soft Pull score.</h4>
  </ScoreError>,
  <RheemLanding />,
  <ApplicationType />,
  <InvalidResponse />,
];

export default PreQualificationFlow;

export const AquaPreQualificationFlowPageNumber = {
  UserType: 0,
  AccountEmail: 1,
  AccountPassword: 2,
  LoanFor: 3,
  LoanAmount: 4,
  UserInfo: 5,
  UserAddress: 6,
  UserLastInfo: 7,
  NotAllowed: 8,
  ScoreError: 9,
  RheemLanding: 10,
  ApplicationType: 11,
  InvalidResponse: 12,
};
