import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import BackBtn from '../../components/UI/BackButton/BackButton';
import Button from '../../components/UI/Button/CustomButton';
import CurrencyFormat from '../../components/UI/Form/NumberFormat/CurrencyFormat';
import { BORROWEDAMOUNTT_TOOLTIP_TEXT } from '../../constants/tooltipTexts';
import { reactGAEvent, reactGAPageview } from '../../helper';
import { changePage, submitMindfire, changeWellsFargoValue } from '../../redux/actions';
import { WellsFargoFlowPageNumber } from './WellsFargoFlow';

const LoanAmount = props => {
  const {
    borrowedAmount,
    dispatchChangePage,
    dispatchChangeWellsFargoValue,
    did,
    dealerUserId,
    email,
    dispatchSubmitMindfire,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/wf_amount',
      trackerNames: [],
      title: 'wf_amount',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const LoanAmountForm = () => (
    <Form>
      <Field
        component={CurrencyFormat}
        label="Estimated Purchase Amount, if known"
        name="borrowedAmount"
        id="borrowedAmount"
        tooltipText={BORROWEDAMOUNTT_TOOLTIP_TEXT}
      />
      <Button type="submit" title="Next" />
    </Form>
  );

  const handleSubmit = values => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Next',
      did,
      dealerUserId,
    });
    dispatchChangeWellsFargoValue('borrowedAmount', values.borrowedAmount || null);
    dispatchChangePage(WellsFargoFlowPageNumber.UserInfo);
    dispatchSubmitMindfire({
      email: email,
      borrowedAmount: values.borrowedAmount,
      lastPage: '5005',
      dealerUserId: dealerUserId,
      did: did,
    });
  };

  const LoanAmountFormFormik = withFormik({
    mapPropsToValues: () => ({
      borrowedAmount: borrowedAmount || '',
    }),

    validationSchema: Yup.object({
      borrowedAmount: Yup.number()
        .moreThan(1499, `Amount should be at least $1500.`)
        .lessThan(100000, 'Amount too high.'),
    }),

    handleSubmit,
  })(LoanAmountForm);

  return (
    <Container>
      <Row>
        <BackBtn changePage={dispatchChangePage} page={WellsFargoFlowPageNumber.LoanFor} />
        <Col sm="5" />
        <Col sm="6">
          <LoanAmountFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  borrowedAmount: state.wellsFargoInformation.borrowedAmount,
  email: state.wellsFargoInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeWellsFargoValue: (key, value) => dispatch(changeWellsFargoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

LoanAmount.propTypes = {
  borrowedAmount: PropTypes.number,
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoanAmount);
