import queryString from 'query-string';
import React, { useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import Alert from '../../../components/UI/Modal/Alert';
import {
  assignApplication,
  changeSoftPullValue,
  getApplicationViewDTO,
  searchContractors,
} from '../../../redux/actions/index';
import SearchDealerCriteria from './SearchDealerCriteria';
import SearchDealerTable from './SearchDealerTable';

const AssignApplication = props => {
  const pageLimit = 20;
  const {
    auth,
    history,
    application,
    dispatchGetApplicationViewDTO,
    dealerSearchCriteriaResult,
    assignApplicationMessage,
    dispatchAssignApplication,
    dispatchChangeSoftPullValue,
    searchOptionContractors,
    dispatchSearchContractors,
  } = props;
  const pageCount = dealerSearchCriteriaResult && dealerSearchCriteriaResult.total / pageLimit;
  const appId = queryString.parse(window.location.search).id;

  useEffect(() => {
    if (!auth.user && (!auth.user.dealerUser || !auth.user.internalUser)) {
      history.push('/login');
    }
    if (appId) {
      if (!assignApplicationMessage) {
        dispatchGetApplicationViewDTO(appId);
      }
    } else {
      history.push('/portal/search-application');
    }
    // eslint-disable-next-line
  }, []);

  const handlePageClick = pageNumber => {
    dispatchSearchContractors({
      ...searchOptionContractors,
      pageOffset: pageNumber.selected * pageLimit,
    });
  };

  const ApplicationData = () => (
    <>
      <Row>
        <Col className="title">Application data</Col>
      </Row>
      {application && application.applicants && application.applicants[0] && application.address ? (
        <Row className="mt-2">
          <Col sm="6">
            {application.applicants[0].nameFirst + ' ' + application.applicants[0].nameLast}
            <br />
            {application.address.street}
            <br />
            {application.address.city +
              ', ' +
              application.address.state +
              ' ' +
              application.address.zipcode}
          </Col>
          <Col sm="6">
            {application.applicants[0].email.email === 'noreply@microf.com'
              ? ''
              : application.applicants[0].email.email}
            <br />
            {application.applicants[0].mobilePhone
              ? application.applicants[0].mobilePhone.number.length > 6
                ? '(' +
                  application.applicants[0].mobilePhone.number.substring(0, 3) +
                  ') ' +
                  application.applicants[0].mobilePhone.number.substring(3, 6) +
                  '-' +
                  application.applicants[0].mobilePhone.number.substring(6)
                : application.applicants[0].mobilePhone.number
              : application.applicants[0].homePhone
              ? application.applicants[0].homePhone.number.length > 6
                ? '(' +
                  application.applicants[0].homePhone.number.substring(0, 3) +
                  ') ' +
                  application.applicants[0].homePhone.number.substring(3, 6) +
                  '-' +
                  application.applicants[0].homePhone.number.substring(6)
                : application.applicants[0].homePhone.number
              : 'N/A'}
            <br />
            {application.insertedTime}
          </Col>
        </Row>
      ) : null}
    </>
  );
  return (
    <>
      <Col>
        <Row className="mt-5">
          <h1 id="panelTitle">Search Contractor</h1>
          <div className="panel">
            <div className="panel-body larger">
              <div className="table-simple">
                <ApplicationData />
              </div>
            </div>
          </div>
          <SearchDealerCriteria
            searchContractors={dispatchSearchContractors}
            searchOptionContractors={searchOptionContractors}
            application={application}
          />
          <div className="panel">
            <div className="panel-body larger">
              <Row>
                <Col className="right-bordered">
                  {dealerSearchCriteriaResult && dealerSearchCriteriaResult.total > 0 ? (
                    <>
                      <Row className="overflow-auto">
                        <SearchDealerTable
                          dealerSearchCriteriaResult={dealerSearchCriteriaResult}
                          history={history}
                          application={application}
                          assignApplication={dispatchAssignApplication}
                        />
                      </Row>
                      {pageCount > 1 && (
                        <Row className="justify-content-between align-items-center">
                          <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakClassName={'break-me'}
                            breakLinkClassName={'page-link'}
                            breakLabel={'...'}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            activeClassName={'active'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                          />
                        </Row>
                      )}
                    </>
                  ) : (
                    <>No users found.</>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Row>
      </Col>
      <Alert
        headerText={'Assign'}
        bodyText={assignApplicationMessage}
        show={!!assignApplicationMessage}
        clickEventHandler={() => {
          dispatchChangeSoftPullValue('assignApplicationMessage', null);
          history.push('/portal/search-application?search=true');
        }}
      />
    </>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  application: state.softPull.applicationViewDTO,
  dealerSearchCriteriaResult: state.softPull.dealerSearchCriteriaResult,
  assignApplicationMessage: state.softPull.assignApplicationMessage,
  searchOptionContractors: state.softPull.searchOptionContractors,
});

const mapDispatchToProps = dispatch => ({
  dispatchGetApplicationViewDTO: appId => dispatch(getApplicationViewDTO(appId)),
  dispatchChangeSoftPullValue: (field, value) => dispatch(changeSoftPullValue(field, value)),
  dispatchAssignApplication: (application, dealer) =>
    dispatch(assignApplication(application, dealer)),
  dispatchSearchContractors: searchOptions => dispatch(searchContractors(searchOptions)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AssignApplication),
);
