import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import usStates from '../../../../assets/js/USStateEnum';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import CustomInput from '../../../../components/UI/Form/Input/CustomInput';
import SsnFormat from '../../../../components/UI/Form/NumberFormat/SsnFormat';
import Select from '../../../../components/UI/Form/Select/Select';
import { isSSNanITIN, isStateAllowed, reactGAEvent, reactGAPageview } from '../../../../helper';
import {
  changePage,
  setFlow,
  submitMindfire,
  changeFoundationInfoValue,
} from '../../../../redux/actions/index';
import { microfFlow, preQualificationFlow } from '../../../../redux/initialState';
import { FoundationFlowPageNumber } from './FoundationFlow';
import { MicrofFlowPageNumber } from '../../../Microf/MicrofFlow';
import { FoundationPreQualificationFlowPageNumber } from '../PreQualification/PreQualificationFlow';
import AirConditioningSystemEnum from '../../../../assets/js/AirConditioningSystemEnum';
import {
  APPFULLSSN_TOOLTIP_TEXT,
  APP_DRIVER_LICENSE_NUMBER_TOOLTIP_TEXT,
  APP_DRIVER_LICENSE_STATE_TOOLTIP_TEXT,
  BIRTHDAY_TOOLTIP_TEXT,
} from '../../../../constants/tooltipTexts';

const AppIdentificationInfo = props => {
  const {
    email,
    dispatchChangePage,
    dispatchChangeFoundationInfoValue,
    foundationInformation,
    children,
    ssnLastDigits,
    isReview,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    dispatchSetFlow,
    history,
    pqi,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_fdconsumerid',
      trackerNames: [],
      title: 'ca_fdconsumerid',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const AppIdentificationInfoForm = () => (
    <Form noValidate autoComplete="off">
      <Field
        maxLength={30}
        component={CustomInput}
        label="Driver's License Number"
        name="appDriversLicenseNumber"
        id="appDriversLicenseNumber"
        tooltipText={APP_DRIVER_LICENSE_NUMBER_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={Select}
        label="Driver's License State"
        name="appDriversLicenseState"
        id="appDriversLicenseState"
        tooltipText={APP_DRIVER_LICENSE_STATE_TOOLTIP_TEXT}
        type="select"
        selectValues={usStates}
        selectFirstName="Select One"
      />
      <Field
        disabled={true}
        maxLength={30}
        component={CustomInput}
        label="Date of Birth"
        name="appDateOfBirth"
        id="appDateOfBirth"
        tooltipText={BIRTHDAY_TOOLTIP_TEXT}
        type="text"
      />

      <Field
        component={SsnFormat}
        label="Your full Social Security Number"
        name="appFullSsn"
        id="appFullSsn"
        tooltipText={APPFULLSSN_TOOLTIP_TEXT}
        type="password"
        icon="lock"
      />
      <p className="info">Click on the padlock to view the hidden text</p>
      <Button type="submit" title={isReview ? 'Review' : 'Next'} />
    </Form>
  );

  const AppIdentificationInfoFormFormik = withFormik({
    mapPropsToValues: () => ({
      appDriversLicenseState: foundationInformation.appDriversLicenseState || '',
      appDriversLicenseNumber: foundationInformation.appDriversLicenseNumber || '',
      appFullSsn: foundationInformation.appFullSsn || '',
      appDateOfBirth: pqi.birthday || '',
    }),

    validationSchema: Yup.object({
      appDriversLicenseState: Yup.string(),
      appDriversLicenseNumber: Yup.string(),
      appFullSsn: Yup.string()
        .required('Please enter your full SSN.')
        .test(
          'len',
          'Must have 9 digits',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 9,
        )
        .test(
          'match',
          'Last 4 digits must match.',
          val =>
            ssnLastDigits === '' ||
            (val &&
              val
                .toString()
                .match(/\d/g)
                .join('')
                .substr(
                  val
                    .toString()
                    .match(/\d/g)
                    .join('').length - 4,
                ) === ssnLastDigits),
        )
        .typeError('Numbers only.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      let ssn = values.appFullSsn.match(/\d/g).join('');
      dispatchChangeFoundationInfoValue('appDriversLicenseState', values.appDriversLicenseState);
      dispatchChangeFoundationInfoValue('appDriversLicenseNumber', values.appDriversLicenseNumber);
      dispatchChangeFoundationInfoValue('appFullSsn', ssn);

      if (isSSNanITIN(ssn)) {
        if (
          !isStateAllowed(pqi.state) ||
          pqi.airConditioningSystem !== AirConditioningSystemEnum.AIR_CONDITION_OR_HEATING_SYSTEM.id
        ) {
          dispatchSetFlow(preQualificationFlow);
          dispatchChangePage(FoundationPreQualificationFlowPageNumber.NotAllowed);
          history.push('/');
        } else {
          dispatchSetFlow(microfFlow);
          dispatchChangePage(MicrofFlowPageNumber.Landing);
          history.push('/RTO');
        }
      } else {
        dispatchChangePage(
          isReview
            ? FoundationFlowPageNumber.ApplicationReview
            : FoundationFlowPageNumber.PropertyDetails,
        );
      }
      dispatchSubmitMindfire({
        email: email,
        lastPage: '10012',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(AppIdentificationInfoForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={
            isReview
              ? FoundationFlowPageNumber.ApplicationReview
              : FoundationFlowPageNumber.LoanAmount
          }
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <AppIdentificationInfoFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  foundationInformation: state.foundationInformation,
  pqi: state.preQualificationInformation,
  ssnLastDigits: state.preQualificationInformation.ssnLastDigits || '',
  email: state.preQualificationInformation.email,
  isReview: state.foundationInformation.isReview,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetFlow: flowName => dispatch(setFlow(flowName)),
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeFoundationInfoValue: (key, value) =>
    dispatch(changeFoundationInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

AppIdentificationInfo.propTypes = {
  foundationInformation: PropTypes.object,
  ssnLastDigits: PropTypes.string,
  dispatchChangePage: PropTypes.func,
  dispatchChangeFoundationInfoValue: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AppIdentificationInfo),
);
