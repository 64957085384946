import { Field, Form, withFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import CustomInput from '../../../../components/UI/Form/Input/CustomInput';
import CurrencyFormat from '../../../../components/UI/Form/NumberFormat/CurrencyFormat';
import CustomDatePicker from '../../../../components/UI/Form/DatePicker/DatePicker';
import { changeApplicationValues, reactGAEvent, reactGAPageview } from '../../../../helper';
import usStates from '../../../../assets/js/USStateEnum';
import {
  changePage,
  submitMindfire,
  changeAtWaterApplicationInfoValue,
  getAtWaterProgramsAndProducts,
} from '../../../../redux/actions/index';
import { AtWaterFlowPageNumber } from './AtWaterFlow';
import SelectWithCustomOnChange from '../../../../components/UI/Form/Select/SelectWithCustomOnChange';
import Select from '../../../../components/UI/Form/Select/Select';
import { STATE_TOOLTIP_TEXT } from '../../../../constants/tooltipTexts';

const ProgramInformation = props => {
  const {
    programsAndProducts,
    dispatchChangePage,
    dispatchChangeAtWaterApplicationInfoValue,
    children,
    did,
    dealerUserId,
    dispatchGetAtWaterProductsAndPrograms,
    dealerId,
    productId,
    salesAmount,
    downPayment,
    amountFinanced,
    state,
    programId,
  } = props;

  const [productsIds, setProductsIds] = useState();
  const [programIds, setProgramIds] = useState();

  useEffect(() => {
    reactGAPageview({
      path: '/ca_gsloaninformation',
      trackerNames: [],
      title: 'ca_gsloaninformation',
      did,
      dealerUserId,
    });

    if (programsAndProducts == null) {
      dispatchGetAtWaterProductsAndPrograms(dealerId);
    } else {
      if (
        programsAndProducts &&
        programsAndProducts.programs &&
        programsAndProducts.programs.length > 0
      ) {
        let valueAndName = programsAndProducts.programs.map(program => ({
          value: program.Value,
          name: program.Text,
        }));
        setProgramIds(valueAndName);
      }

      if (
        programsAndProducts &&
        programsAndProducts.products &&
        programsAndProducts.products.length > 0
      ) {
        let valueAndName = programsAndProducts.products.map(product => ({
          value: product.Name,
          name: product.Name,
        }));
        setProductsIds(valueAndName);
      }
    }
    // eslint-disable-next-line
  }, []);

  const handleSelectedProduct = value => {};

  const handleSelectedProgram = value => {};

  const ProgramInformationForm = ({ values }) => (
    <Form>
      <Field
        component={SelectWithCustomOnChange}
        label="Improvement"
        name="productId"
        id="productId"
        type="select"
        tooltipText={''}
        selectValues={productsIds ? productsIds : ''}
        selectFirstName="Select..."
        handleChange={e => handleSelectedProduct(e.target.value)}
      />
      <Field
        component={CurrencyFormat}
        label="Sales Amount"
        name="salesAmount"
        id="salesAmount"
        tooltipText={''}
      />
      <Field
        component={CurrencyFormat}
        name="downPayment"
        id="downPayment"
        tooltipText={''}
        label="Down Payment"
      />
      <Field
        component={CurrencyFormat}
        label="Amount Financed"
        name="amountFinanced"
        id="amountFinanced"
        tooltipText={''}
      />
      <Field
        component={Select}
        label="State"
        name="state"
        id="state"
        tooltipText={STATE_TOOLTIP_TEXT}
        type="select"
        selectValues={usStates}
        selectFirstName="Select a State..."
      />
      <Field
        component={SelectWithCustomOnChange}
        label="Program"
        name="programId"
        id="programId"
        type="select"
        tooltipText={''}
        selectValues={programIds ? programIds : ''}
        selectFirstName="Select..."
        handleChange={e => handleSelectedProgram(e.target.value)}
      />
      <Button type="submit" title="Next" />
    </Form>
  );

  const ProgramInformationFormFormik = withFormik({
    mapPropsToValues: () => ({
      productId: productId || '',
      salesAmount: salesAmount || '',
      downPayment: downPayment || '',
      amountFinanced: amountFinanced || '',
      state: state || '',
      programId: programId || '',
    }),

    validationSchema: Yup.object({
      productId: Yup.string().required('Please select a Product.'),
      salesAmount: Yup.number().required('Please enter an amount.'),
      downPayment: Yup.number().required('Please enter an amount.'),
      amountFinanced: Yup.number()
        .moreThan(499, `Amount should be at least $500.`)
        .lessThan(100000, 'Amount too high.')
        .required('Please enter an amount.'),
      state: Yup.string().required('Please select a state.'),
      programId: Yup.string().required('Please select a Program.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });

      changeApplicationValues(dispatchChangeAtWaterApplicationInfoValue, values);
      dispatchChangePage(AtWaterFlowPageNumber.IdentificationInformation);
    },
  })(ProgramInformationForm);

  return (
    <Container>
      <Row>
        <BackBtn changePage={dispatchChangePage} page={AtWaterFlowPageNumber.Consent} />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <ProgramInformationFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  dealerId: state.auth.dealerId,
  programsAndProducts: state.atWaterApplicationInformation.programsAndProducts,
  productId: state.atWaterApplicationInformation.productId,
  salesAmount: state.atWaterApplicationInformation.salesAmount,
  downPayment: state.atWaterApplicationInformation.downPayment,
  amountFinanced: state.atWaterApplicationInformation.amountFinanced,
  state: state.atWaterApplicationInformation.state,
  programId: state.atWaterApplicationInformation.programId,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeAtWaterApplicationInfoValue: (key, value) =>
    dispatch(changeAtWaterApplicationInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchGetAtWaterProductsAndPrograms: dealerId =>
    dispatch(getAtWaterProgramsAndProducts(dealerId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProgramInformation);
