import React from 'react';
import NumberFormat from 'react-number-format';
import { Col, Row } from 'reactstrap';
import { getIsOptimus } from '../../../../config/settings';
import { reactGAEvent } from '../../../../helper';
import Button from '../../Button/CustomButton';
import './LeasingOffer.scss';

const LeasingOffer = props => {
  const {
    optionNumber,
    monthsToLeasing,
    payment,
    totalOfPayments,
    changePage,
    changeValue,
    nextPage,
    buttonText,
    acceptTermsMicrofApplication,
    acceptTermsMicrofPartnerApplication,
    applicationId,
    termYears,
    submitMindfire,
    did,
    dealerUserId,
    capitalizedCostReduction,
    appToken,
    addedBy,
    pqi,
    setIsPixelMounted,
    initialTotalFinancingAmount,
    initialTotalJobPrice,
    totalFinancingAmount,
    totalJobPrice,
  } = props;

  const isOptimus = getIsOptimus();

  const isMicrofFinalOption = () =>
    pqi?.score &&
    ((pqi.scoreLimit && pqi.score >= pqi.scoreLimit) ||
      (pqi.scoreLimit2 && pqi.score >= pqi.scoreLimit2));

  const acceptTerms = () => {
    if ((addedBy || isMicrofFinalOption()) && appToken) {
      if (!!acceptTermsMicrofPartnerApplication) {
        acceptTermsMicrofPartnerApplication(applicationId, termYears, appToken);
      } else {
        changeValue('selectedLeasingOffer', optionNumber);
        changePage(nextPage);
      }
    } else {
      if (!!acceptTermsMicrofApplication) {
        acceptTermsMicrofApplication(applicationId, termYears, nextPage);
      } else {
        changeValue('selectedLeasingOffer', optionNumber);
        changePage(nextPage);
      }
    }
  };

  const totalCostToOwn = totalFinancingAmount + totalOfPayments + capitalizedCostReduction;

  return (
    <Row className={`leasingOffer p-2 border border-primary`}>
      <Col>
        {isOptimus ? (
          <>
            <Row className="mt-25" noGutters>
              <Col className="d-flex justify-content-between" xs="12">
                <b>Monthly Payment</b>
                <NumberFormat
                  value={payment}
                  thousandSeparator={true}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  prefix={'$'}
                  displayType="text"
                />
              </Col>
            </Row>
            <Row noGutters>
              <Col className="d-flex justify-content-between" xs="12">
                <b>Months to Own</b>
                <span>{monthsToLeasing}</span>
              </Col>
            </Row>
            <Row noGutters>
              <Col className="d-flex justify-content-between" xs="12">
                <b>Retail (Cash) Price</b>
                <NumberFormat
                  value={totalJobPrice}
                  thousandSeparator={true}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  prefix={'$'}
                  displayType="text"
                />
              </Col>
            </Row>
            <Row noGutters>
              <Col className="d-flex justify-content-between" xs="12">
                <b>Capitalized Cost Reduction</b>
                <NumberFormat
                  value={capitalizedCostReduction}
                  thousandSeparator={true}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  prefix={'$'}
                  displayType="text"
                />
              </Col>
            </Row>
            <Row noGutters>
              <Col className="d-flex justify-content-between" xs="12">
                <b>Cost of Lease Services</b>
                <NumberFormat
                  value={totalOfPayments}
                  thousandSeparator={true}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  prefix={'$'}
                  displayType="text"
                />
              </Col>
            </Row>
            <Row noGutters>
              <Col className="d-flex justify-content-between" xs="12">
                <b>Total Cost To Own</b>
                <NumberFormat
                  value={totalCostToOwn}
                  thousandSeparator={true}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  prefix={'$'}
                  displayType="text"
                />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row className="mt-25" noGutters>
              <Col className="d-flex justify-content-between" xs="12">
                <b>Lease Amount</b>
                <NumberFormat
                  value={totalFinancingAmount}
                  thousandSeparator={true}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  prefix={'$'}
                  displayType="text"
                />
              </Col>
            </Row>
            <Row className="mt-25" noGutters>
              <Col className="d-flex justify-content-between" xs="12">
                <b>Monthly Payment</b>
                <NumberFormat
                  value={payment}
                  thousandSeparator={true}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  prefix={'$'}
                  displayType="text"
                />
              </Col>
            </Row>
            <Row noGutters>
              <Col className="d-flex justify-content-between" xs="12">
                <b>Months to Own</b>
                <span>{monthsToLeasing}</span>
              </Col>
            </Row>
            <Row noGutters>
              <Col className="d-flex justify-content-between" xs="12">
                <b>Retail (Cash) Price</b>
                <NumberFormat
                  value={totalJobPrice}
                  thousandSeparator={true}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  prefix={'$'}
                  displayType="text"
                />
              </Col>
            </Row>
            <Row noGutters>
              <Col className="d-flex justify-content-between" xs="12">
                <b>Capitalized Cost Reduction</b>
                <NumberFormat
                  value={capitalizedCostReduction}
                  thousandSeparator={true}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  prefix={'$'}
                  displayType="text"
                />
              </Col>
            </Row>
            <Row noGutters>
              <Col className="d-flex justify-content-between" xs="12">
                <b>Cost of Lease Services</b>
                <NumberFormat
                  value={totalOfPayments}
                  thousandSeparator={true}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  prefix={'$'}
                  displayType="text"
                />
              </Col>
            </Row>
            <Row noGutters>
              <Col className="d-flex justify-content-between" xs="12">
                <b>Total Cost To Own</b>
                <NumberFormat
                  value={totalCostToOwn}
                  thousandSeparator={true}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  prefix={'$'}
                  displayType="text"
                />
              </Col>
            </Row>
          </>
        )}

        <Row>
          <Col>
            <Button
              className="mt-5"
              type="submit"
              clickHandler={() => {
                setIsPixelMounted(true);
                reactGAEvent({
                  category: 'Button',
                  action: 'Click',
                  label: buttonText ? buttonText : 'I want this option',
                  dealerUserId: dealerUserId,
                  did: did,
                });
                submitMindfire();
                setTimeout(acceptTerms, 1);
              }}
            >
              {buttonText ? buttonText : 'I want this option'}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default LeasingOffer;
