import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import { useVSPixelScript } from '../../../assets/hooks';
import relationshipEnum from '../../../assets/js/RelationshipEnum';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import Input from '../../../components/UI/Form/Input/CustomInput';
import CustomPhoneNumber from '../../../components/UI/Form/PhoneNumber/PhoneNumber';
import Select from '../../../components/UI/Form/Select/Select';
import {
  COAPPEMAIL_TOOLTIP_TEXT,
  COAPPFIRSTNAME_TOOLTIP_TEXT,
  COAPPLASTNAME_TOOLTIP_TEXT,
  COAPPMIDDLEINITIAL_TOOLTIP_TEXT,
  COAPPPHONENUMBER_TOOLTIP_TEXT,
  COAPP_RELATIONSHIP_TOOLTIP_TEXT,
} from '../../../constants/tooltipTexts';
import { isNoreplyEmail, reactGAEvent, reactGAPageview } from '../../../helper';
import { changePage, changePowerPayInfoValue, submitMindfire } from '../../../redux/actions/index';
import { PowerPayFlowPageNumber } from './PowerPayFlow';

const CoAppInfo = props => {
  const {
    coAppFirstName,
    coAppMiddleInitial,
    coAppLastName,
    coAppPhoneNumber,
    coAppEmail,
    appEmail,
    dispatchChangeInfoValue,
    relationshipWithCoapplicant,
    dispatchChangePage,
    children,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    isReview,
  } = props;

  useVSPixelScript();

  useEffect(() => {
    reactGAPageview({
      path: '/ca_coappinfo1',
      trackerNames: [],
      title: 'ca_coappinfo1',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const CoAppInfoForm = () => {
    return (
      <Form>
        <Field
          component={Select}
          label="Your relationship with the Applicant?"
          name="relationshipWithCoapplicant"
          id="relationshipWithCoapplicant"
          type="select"
          selectValues={relationshipEnum}
          selectFirstName=""
          tooltipText={COAPP_RELATIONSHIP_TOOLTIP_TEXT}
        />
        <Field
          component={Input}
          label="Co-Applicant's First Name"
          name="coAppFirstName"
          id="coAppFirstName"
          type="text"
          tooltipText={COAPPFIRSTNAME_TOOLTIP_TEXT}
        />
        <Field
          component={Input}
          label="Co-Applicant's Middle Initial"
          name="coAppMiddleInitial"
          id="coAppMiddleInitial"
          type="text"
          maxLength={2}
          tooltipText={COAPPMIDDLEINITIAL_TOOLTIP_TEXT}
        />
        <Field
          component={Input}
          label="Co-Applicant's Last Name"
          name="coAppLastName"
          id="coAppLastName"
          type="text"
          tooltipText={COAPPLASTNAME_TOOLTIP_TEXT}
        />
        <Field
          component={Input}
          label="Co-Applicant's Email Address"
          name="coAppEmail"
          id="coAppEmail"
          type="email"
          tooltipText={COAPPEMAIL_TOOLTIP_TEXT}
        />
        <p className="small-text">
          Please enter the email address for the co-applicant (we recommend a different email
          address than the primary applicant's email address). If you need to create an email
          address,{' '}
          <a
            href="https://edu.gcfglobal.org/en/gmail/setting-up-a-gmail-account/1/"
            target="_blank"
            rel="noopener noreferrer"
          >
            click here
          </a>{' '}
          for instructions.
        </p>
        <Field
          component={CustomPhoneNumber}
          label="Co-Applicant's Phone Number (Mobile Preferred)"
          name="coAppPhoneNumber"
          id="coAppPhoneNumber"
          tooltipText={COAPPPHONENUMBER_TOOLTIP_TEXT}
        />
        <Button type="submit" title="Next" />
      </Form>
    );
  };

  const CoAppInfoFormFormik = withFormik({
    mapPropsToValues: () => ({
      relationshipWithCoapplicant: relationshipWithCoapplicant || '', //make sure data pases from aqua to microf
      coAppFirstName: coAppFirstName || '',
      coAppMiddleInitial: coAppMiddleInitial || '',
      coAppLastName: coAppLastName || '',
      coAppPhoneNumber: coAppPhoneNumber || '',
      coAppEmail: coAppEmail || '',
    }),

    validationSchema: Yup.object({
      relationshipWithCoapplicant: Yup.string().required(
        'Please select the relationship with the Co-Applicant.',
      ),
      coAppFirstName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .required("Please enter the Co-Applicant's first name."),
      coAppMiddleInitial: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .max(2, 'Middle initial is too long.'),
      coAppLastName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .required("Please enter the Co-Applicant's last name."),
      coAppPhoneNumber: Yup.string()
        .test(
          'required',
          "Please enter the Co-Applicant's phone number.",
          val => val !== '+' && val,
        )
        .test('len', 'Invalid phone number', val => val && val.match(/\d/g).join('').length === 10),
      coAppEmail: Yup.string()
        .test(
          'sameEmail',
          "The co-applicant's email must be different than the primary applicant's email address.",
          val => val !== appEmail,
        )
        .email('Please enter a valid email.')
        .required('Please enter an email.')
        .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      dispatchChangeInfoValue('relationshipWithCoapplicant', values.relationshipWithCoapplicant);
      dispatchChangeInfoValue('coAppFirstName', values.coAppFirstName.trim());
      dispatchChangeInfoValue('coAppMiddleInitial', values.coAppMiddleInitial.trim());
      dispatchChangeInfoValue('coAppLastName', values.coAppLastName.trim());
      dispatchChangeInfoValue('coAppPhoneNumber', values.coAppPhoneNumber.match(/\d/g).join(''));
      dispatchChangeInfoValue('coAppEmail', values.coAppEmail);
      dispatchChangePage(PowerPayFlowPageNumber.CoAppAddress);
      dispatchSubmitMindfire({
        email: appEmail,
        lastPage: '6015',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(CoAppInfoForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={
            isReview
              ? PowerPayFlowPageNumber.ApplicationReview
              : PowerPayFlowPageNumber.HasCoApplicant
          }
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <CoAppInfoFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  relationshipWithCoapplicant: state.powerPayInformation.relationshipWithCoapplicant,
  coAppFirstName: state.powerPayInformation.coAppFirstName,
  coAppMiddleInitial: state.powerPayInformation.coAppMiddleInitial,
  coAppLastName: state.powerPayInformation.coAppLastName,
  coAppPhoneNumber: state.powerPayInformation.coAppPhoneNumber,
  coAppEmail: state.powerPayInformation.coAppEmail,
  appEmail: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  isReview: state.powerPayInformation.isReview,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeInfoValue: (key, value) => dispatch(changePowerPayInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

CoAppInfo.propTypes = {
  relationshipWithCoapplicant: PropTypes.string,
  coAppFirstName: PropTypes.string,
  coAppMiddleInitial: PropTypes.string,
  coAppLastName: PropTypes.string,
  coAppPhoneNumber: PropTypes.string,
  coAppEmail: PropTypes.string,
  appEmail: PropTypes.string,
  aquaInformation: PropTypes.object,
  dispatchChangePage: PropTypes.func,
  dispatchChangeInfoValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CoAppInfo);
