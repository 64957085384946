import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import usStates from '../../../../assets/js/USStateEnum';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import Input from '../../../../components/UI/Form/Input/CustomInput';
import Select from '../../../../components/UI/Form/Select/Select';
import {
  SPOUSE_CITY_TOOLTIP_TEXT,
  SPOUSE_FIRST_NAME_TOOLTIP_TEXT,
  SPOUSE_LAST_NAME_TOOLTIP_TEXT,
  SPOUSE_MIDDLE_INITIAL_NAME_TOOLTIP_TEXT,
  SPOUSE_STATE_TOOLTIP_TEXT,
  SPOUSE_STREET_TOOLTIP_TEXT,
  SPOUSE_UNIT_NUMBER_TOOLTIP_TEXT,
  SPOUSE_ZIP_TOOLTIP_TEXT,
} from '../../../../constants/tooltipTexts';
import { changeApplicationValues, reactGAEvent, reactGAPageview } from '../../../../helper';
import {
  changePage,
  submitMindfire,
  changeGreenSkyApplicationInfoValue,
} from '../../../../redux/actions';
import { GreenSkyFlowPageNumber } from './GreenSkyFlow';

const ApplicantSpouse = props => {
  const {
    greenSkyApplicationInformation,
    dispatchChangeGreenSkyApplicationInfoValue,
    pqi,
    dispatchChangePage,
    children,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_gsapplicantspouse',
      trackerNames: [],
      title: 'ca_gsapplicantspouse',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const setIsSameAddress = bool => {
    dispatchChangeGreenSkyApplicationInfoValue('isSameAddress', bool);
  };

  const CoApplicantAddressForm = ({
    values: { applicantSpouseFirstName, applicantSpouseMiddleInitial, applicantSpouseLastName },
  }) => (
    <Form>
      <Field
        component={Input}
        label="Spouse’s First Name"
        name="applicantSpouseFirstName"
        id="applicantSpouseFirstName"
        tooltipText={SPOUSE_FIRST_NAME_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={Input}
        label="Spouse’s Middle Initial"
        name="applicantSpouseMiddleInitial"
        id="applicantSpouseMiddleInitial"
        tooltipText={SPOUSE_MIDDLE_INITIAL_NAME_TOOLTIP_TEXT}
        type="text"
        maxLength="2"
      />
      <Field
        component={Input}
        label="Spouse’s Last Name"
        name="applicantSpouseLastName"
        id="applicantSpouseLastName"
        tooltipText={SPOUSE_LAST_NAME_TOOLTIP_TEXT}
        type="text"
      />
      <Label>
        <b>The Mailing Address is the same as the applicant’s.</b>
      </Label>
      <Row>
        <Col sm="6">
          <Button
            className={greenSkyApplicationInformation.isSameAddress ? '' : 'inactive'}
            type="button"
            title="Yes"
            clickHandler={() => {
              changeApplicationValues(dispatchChangeGreenSkyApplicationInfoValue, {
                applicantSpouseFirstName,
                applicantSpouseMiddleInitial,
                applicantSpouseLastName,
              });
              setIsSameAddress(true);
            }}
          />
        </Col>
        <Col sm="6">
          <Button
            className={!greenSkyApplicationInformation.isSameAddress ? '' : 'inactive'}
            type="button"
            title="No"
            clickHandler={() => {
              changeApplicationValues(dispatchChangeGreenSkyApplicationInfoValue, {
                applicantSpouseFirstName,
                applicantSpouseMiddleInitial,
                applicantSpouseLastName,
              });
              setIsSameAddress(false);
            }}
          />
        </Col>
      </Row>
      <Field
        disabled={greenSkyApplicationInformation.isSameAddress}
        component={Input}
        label="Street"
        name="applicantSpouseStreet"
        id="applicantSpouseStreet"
        tooltipText={SPOUSE_STREET_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={Input}
        label="Unit/Apt. Number"
        name="applicantSpouseApartmentNumber"
        id="applicantSpouseApartmentNumber"
        tooltipText={SPOUSE_UNIT_NUMBER_TOOLTIP_TEXT}
        type="text"
      />
      <Row>
        <Col sm="6">
          <Field
            disabled={greenSkyApplicationInformation.isSameAddress}
            component={Input}
            label="City"
            name="applicantSpouseCity"
            id="applicantSpouseCity"
            tooltipText={SPOUSE_CITY_TOOLTIP_TEXT}
            type="text"
          />
        </Col>
        <Col sm="6">
          <Field
            disabled={greenSkyApplicationInformation.isSameAddress}
            component={Select}
            label="State"
            name="applicantSpouseState"
            id="applicantSpouseState"
            tooltipText={SPOUSE_STATE_TOOLTIP_TEXT}
            type="select"
            selectValues={usStates}
            selectFirstName="Select State"
          />
        </Col>
      </Row>
      <Field
        disabled={greenSkyApplicationInformation.isSameAddress}
        component={Input}
        label="ZIP Code"
        name="applicantSpouseZipCode"
        id="applicantSpouseZipCode"
        tooltipText={SPOUSE_ZIP_TOOLTIP_TEXT}
        type="text"
        maxLength={10}
      />

      <Button type="submit" title="Next" />
    </Form>
  );

  const CoApplicantAddressFormFormik = withFormik({
    mapPropsToValues: () => ({
      applicantSpouseFirstName: greenSkyApplicationInformation.applicantSpouseFirstName || '',
      applicantSpouseMiddleInitial:
        greenSkyApplicationInformation.applicantSpouseMiddleInitial || '',
      applicantSpouseLastName: greenSkyApplicationInformation.applicantSpouseLastName || '',
      applicantSpouseApartmentNumber:
        greenSkyApplicationInformation.isSameAddress &&
        greenSkyApplicationInformation.primaryAddressApartmentNumber
          ? greenSkyApplicationInformation.primaryAddressApartmentNumber
          : greenSkyApplicationInformation.applicantSpouseApartmentNumber || '',
      applicantSpouseStreet:
        greenSkyApplicationInformation.isSameAddress &&
        greenSkyApplicationInformation.primaryAddressStreet
          ? greenSkyApplicationInformation.primaryAddressStreet
          : greenSkyApplicationInformation.applicantSpouseStreet || '',
      applicantSpouseCity:
        greenSkyApplicationInformation.isSameAddress &&
        greenSkyApplicationInformation.primaryAddressCity
          ? greenSkyApplicationInformation.primaryAddressCity
          : greenSkyApplicationInformation.applicantSpouseCity || '',
      applicantSpouseZipCode:
        greenSkyApplicationInformation.isSameAddress &&
        greenSkyApplicationInformation.primaryAddressZipCode
          ? greenSkyApplicationInformation.primaryAddressZipCode
          : greenSkyApplicationInformation.applicantSpouseZipCode || '',
      applicantSpouseState:
        greenSkyApplicationInformation.isSameAddress &&
        greenSkyApplicationInformation.primaryAddressState
          ? greenSkyApplicationInformation.primaryAddressState
          : greenSkyApplicationInformation.applicantSpouseState || '',
    }),

    validationSchema: Yup.object({
      applicantSpouseFirstName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .required('Please enter your spouse’s first name.'),
      applicantSpouseMiddleInitial: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .max(2, 'Middle initial is too long.'),
      applicantSpouseLastName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .required('Please enter your spouse’s last name.'),
      applicantSpouseApartmentNumber: Yup.string(),
      applicantSpouseStreet: Yup.string().required('Please enter a street.'),
      applicantSpouseCity: Yup.string().required('Please enter a city.'),
      applicantSpouseZipCode: Yup.string()
        .matches(/^\d{5}?$/, {
          message: 'Invalid zip code.',
          excludeEmptyString: true,
        })
        .required('Please enter a zip code.'),
      applicantSpouseState: Yup.string().required('Please select a state.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      changeApplicationValues(dispatchChangeGreenSkyApplicationInfoValue, values);
      dispatchChangePage(GreenSkyFlowPageNumber.HasCoApplicant);
      dispatchSubmitMindfire({
        email: pqi.email,
        lastPage: '9015',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(CoApplicantAddressForm);

  return (
    <Container>
      <Row>
        <BackBtn changePage={dispatchChangePage} page={GreenSkyFlowPageNumber.ApplicantMarried} />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <CoApplicantAddressFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  pqi: state.preQualificationInformation,
  greenSkyApplicationInformation: state.greenSkyApplicationInformation,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeGreenSkyApplicationInfoValue: (key, value) =>
    dispatch(changeGreenSkyApplicationInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

ApplicantSpouse.propTypes = {
  dispatchChangePage: PropTypes.func,
  greenSkyApplicationInformation: PropTypes.object,
  dispatchChangeGreenSkyApplicationInfoValue: PropTypes.func,
  pqi: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApplicantSpouse);
