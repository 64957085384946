import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Container, FormGroup, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import Input from '../../../../components/UI/Form/Input/CustomInput';
import CurrencyFormat from '../../../../components/UI/Form/NumberFormat/CurrencyFormat';
import {} from '../../../../constants/tooltipTexts';
import { changeApplicationValues, reactGAEvent, reactGAPageview } from '../../../../helper';
import {
  changePage,
  changePreQualInfoValue,
  submitMindfire,
  changeAtWaterApplicationInfoValue,
} from '../../../../redux/actions/index';
import { AtWaterFlowPageNumber } from './AtWaterFlow';

const EmploymentInformation = props => {
  const {
    dispatchChangePage,
    children,
    did,
    dealerUserId,
    dispatchChangeAtWaterApplicationInfoValue,
    coAppEmployerName,
    coAppGrossMonthlyIncome,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_gsconsumerinfo2',
      trackerNames: [],
      title: 'ca_gsconsumerinfo2',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const [isCoAppUsCitizen, setIsCoAppUsCitizen] = useState(true);

  const EmploymentInformationForm = () => (
    <Form>
      <Field
        component={Input}
        label="Employer Name"
        name="coAppEmployerName"
        id="coAppEmployerName"
        tooltipText={''}
        type="text"
        maxLength={150}
      />

      <Field
        component={CurrencyFormat}
        name="coAppGrossMonthlyIncome"
        id="coAppGrossMonthlyIncome"
        tooltipText={''}
        label="Gross Monthly Income"
      />

      <FormGroup>
        <Row>
          <Col>
            <Label>
              <b>Are you a US citizen?</b>
            </Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              className={isCoAppUsCitizen ? '' : 'inactive'}
              type="button"
              title="Yes"
              clickHandler={() => {
                setIsCoAppUsCitizen(true);
              }}
            />
          </Col>
          <Col>
            <Button
              className={!isCoAppUsCitizen ? '' : 'inactive'}
              type="button"
              title="No"
              clickHandler={() => {
                setIsCoAppUsCitizen(false);
              }}
            />
          </Col>
        </Row>
      </FormGroup>
      <Button type="submit" title="Next" />
    </Form>
  );

  const EmploymentInformationFormFormik = withFormik({
    mapPropsToValues: () => ({
      coAppEmployerName: coAppEmployerName || '',
      coAppGrossMonthlyIncome: coAppGrossMonthlyIncome || '',
    }),

    validationSchema: Yup.object({
      coAppEmployerName: Yup.string().required('Please enter a employer name.'),
      coAppGrossMonthlyIncome: Yup.string().required('Please enter your gross monthly income.'),
    }),

    handleSubmit: async values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      dispatchChangeAtWaterApplicationInfoValue('isCoAppUsCitizen', isCoAppUsCitizen);
      changeApplicationValues(dispatchChangeAtWaterApplicationInfoValue, values);

      dispatchChangePage(AtWaterFlowPageNumber.ApplicationConsent);
    },
  })(EmploymentInformationForm);

  return (
    <Container>
      <Row>
        <BackBtn changePage={dispatchChangePage} page={AtWaterFlowPageNumber.CoApplicantAddress} />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <EmploymentInformationFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  coAppEmployerName: state.atWaterApplicationInformation.coAppEmployerName,
  coAppGrossMonthlyIncome: state.atWaterApplicationInformation.coAppGrossMonthlyIncome,
  isCoAppUsCitizen: state.atWaterApplicationInformation.isCoAppUsCitizen,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) => dispatch(changePreQualInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchChangeAtWaterApplicationInfoValue: (key, value) =>
    dispatch(changeAtWaterApplicationInfoValue(key, value)),
});

EmploymentInformation.propTypes = {
  street: PropTypes.string,
  city: PropTypes.string,
  zipcode: PropTypes.string,
  state: PropTypes.string,
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmploymentInformation);
