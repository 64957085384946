import React from 'react';
import NumberFormat from 'react-number-format';
import InputFeedback from '../InputFeedback/InputFeedback';
import { Label, FormGroup } from 'reactstrap';

const EINFormat = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched, setFieldValue },
  id,
  labelClassName,
  label,
  propValue,
  disabled,
  placeholder,
  ...props
}) => (
  <FormGroup>
    <Label for={id} className={labelClassName}>
      <b>{label}</b>
    </Label>
    <NumberFormat
      className="form-control"
      name={name}
      id={id}
      value={propValue ? propValue : value}
      disabled={disabled}
      decimalScale={0}
      allowNegative={false}
      allowEmptyFormatting={false}
      placeholder=""
      onValueChange={e => {
        setFieldValue(name, e.floatValue);
      }}
      onBlur={onBlur}
      fixedDecimalScale
      format="##-#######"
    />
    {touched[name] && <InputFeedback error={errors[name]} />}
  </FormGroup>
);

export default EINFormat;
