import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Col, Container, FormGroup, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import hvacSystemEnum, { isACUnit } from '../../assets/js/HvacSystemEnum';
import hvacTonnageEnum from '../../assets/js/HvacTonnageEnum';
import Button from '../../components/UI/Button/CustomButton';
import CustomDatePicker from '../../components/UI/Form/DatePicker/DatePicker';
import CustomInput from '../../components/UI/Form/Input/CustomInput';
import Select from '../../components/UI/Form/Select/Select';
import {
  changeMicrofInfoValue,
  submitMindfire,
  changePage,
  changePreQualInfoValue,
} from '../../redux/actions/index';
import { getAppName } from '../../config/settings';
import { MicrofFlowPageNumber } from './MicrofFlow';
import BackBtn from '../../components/UI/BackButton/BackButton';
import {
  APPBANKACCOUNTNUMBER_TOOLTIP_TEXT,
  APPBANKACCOUNTROUTINGNUMBER_TOOLTIP_TEXT,
  COAPPBANKACCOUNTNUMBER_TOOLTIP_TEXT,
  COAPPBANKACCOUNTROUTINGNUMBER_TOOLTIP_TEXT,
  COAPPEMAIL_TOOLTIP_TEXT,
  EMAIL_TOOLTIP_TEXT,
  HVACSYSTEM_TOOLTIP_TEXT,
  HVACTONNAGE_TOOLTIP_TEXT,
  INSTALLMENTDATE_TOOLTIP_TEXT,
  SECONDHVACSYSTEM_TOOLTIP_TEXT,
  SECONDHVACTONNAGE_TOOLTIP_TEXT,
} from '../../constants/tooltipTexts';
import { isNoreplyEmail } from '../../helper';
import SystemTypesList from '../../assets/js/AieConditioningSystemTypesList';
import SelectWithCustomOnChange from '../../components/UI/Form/Select/SelectWithCustomOnChange';
import WaterHeaterTypeEnum from '../../assets/js/WaterHeaterTypeEnum';
import onlyHvacSystem from '../../assets/js/OnlyHvacSystemEnum';

const appName = getAppName();

const ServiceFinance = props => {
  const {
    appBankAccountNumber,
    appBankAccountRoutingNumber,
    hvacSystem,
    projectType,
    hvacTonnage,
    installmentDate,
    secondHvacSystem,
    secondHvacTonnage,
    dispatchChangePreQualInfoValue,
    dispatchChangeInfoValue,
    children,
    homeownerInsurance,
    microfInformation,
    dispatchChangePage,
    coAppBankAccountNumber,
    coAppBankAccountRoutingNumber,
    coAppEmail,
    appEmail,
    isFTL,
  } = props;

  const [hasSecondHVAC, setHasSecondHVAC] = useState(!!secondHvacSystem);
  const [selectedProjectType, setSelectedProjectType] = useState(projectType ? projectType : null);

  const setValuesToState = values => {
    dispatchChangePreQualInfoValue('email', values.email);
    dispatchChangeInfoValue('hvacSystem', values.hvacSystem);
    dispatchChangeInfoValue('projectType', values.projectType);
    dispatchChangeInfoValue('hvacTonnage', isACUnit(values.hvacSystem) ? values.hvacTonnage : null);
    dispatchChangeInfoValue('installmentDate', values.installmentDate);
    dispatchChangeInfoValue('secondHvacSystem', hasSecondHVAC ? values.secondHvacSystem : null);
    dispatchChangeInfoValue(
      'secondHvacTonnage',
      hasSecondHVAC ? (isACUnit(values.secondHvacSystem) ? values.secondHvacTonnage : null) : null,
    );
    dispatchChangeInfoValue('appBankAccountNumber', values.appBankAccountNumber);
    dispatchChangeInfoValue('appBankAccountRoutingNumber', values.appBankAccountRoutingNumber);
    dispatchChangeInfoValue(
      'coAppBankAccountRoutingNumber',
      microfInformation.hasCoApp ? values.coAppBankAccountRoutingNumber : null,
    );
    dispatchChangeInfoValue(
      'coAppBankAccountNumber',
      microfInformation.hasCoApp ? values.coAppBankAccountNumber : null,
    );
    dispatchChangeInfoValue('coAppEmail', microfInformation.hasCoApp ? values.coAppEmail : null);
  };

  const handleSelectedPlan = (value, values) => {
    setValuesToState({ ...values, projectType: value });
    dispatchChangeInfoValue('hvacSystem', null);
    dispatchChangeInfoValue('hvacTonnage', null);
    dispatchChangeInfoValue('secondHvacSystem', null);
    dispatchChangeInfoValue('secondHvacTonnage', null);
    setSelectedProjectType(value);
  };

  const ServiceFinanceForm = ({ values }) => {
    return (
      <>
        <FormGroup>
          <Row>
            <Col>
              <Label>
                <b>Do you have homeowner’s insurance?</b>
              </Label>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                className={
                  homeownerInsurance === null ? 'inactive' : homeownerInsurance ? '' : 'inactive'
                }
                type="button"
                title="Yes"
                clickHandler={() => {
                  dispatchChangeInfoValue('homeownerInsurance', true);
                  setValuesToState(values);
                }}
              />
            </Col>
            <Col>
              <Button
                className={
                  homeownerInsurance === null ? 'inactive' : !homeownerInsurance ? '' : 'inactive'
                }
                type="button"
                title="No"
                clickHandler={() => {
                  dispatchChangeInfoValue('homeownerInsurance', false);
                  setValuesToState(values);
                }}
              />
            </Col>
          </Row>
        </FormGroup>
        <p className="text-danger mt-2" hidden={homeownerInsurance !== null}>
          You must select an option.
        </p>
        <Form noValidate>
          {!isFTL ? (
            <>
              <Field
                component={SelectWithCustomOnChange}
                label="Project Type"
                name="projectType"
                id="projectType"
                tooltipText={'Please select Project Type'}
                type="select"
                selectValues={
                  SystemTypesList && SystemTypesList.length > 0
                    ? SystemTypesList.filter(x => x.value !== 'AW')
                    : []
                }
                selectFirstName="Select One"
                handleChange={e => handleSelectedPlan(e.target.value, values)}
              />
              {selectedProjectType &&
                (selectedProjectType === 'A' ? (
                  <>
                    <Field
                      component={Select}
                      label="HVAC System Type"
                      name="hvacSystem"
                      id="hvacSystem"
                      tooltipText={HVACSYSTEM_TOOLTIP_TEXT}
                      type="select"
                      selectValues={onlyHvacSystem}
                      selectFirstName="Select One"
                    />
                    <Field
                      component={Select}
                      label="Estimated HVAC System Size"
                      name="hvacTonnage"
                      id="hvacTonnage"
                      tooltipText={HVACTONNAGE_TOOLTIP_TEXT}
                      type="select"
                      selectValues={hvacTonnageEnum}
                      selectFirstName="Select One"
                    />
                  </>
                ) : (
                  <Field
                    component={Select}
                    label="Water Heater System Type"
                    name="hvacSystem"
                    id="hvacSystem"
                    tooltipText={HVACSYSTEM_TOOLTIP_TEXT}
                    type="select"
                    selectValues={WaterHeaterTypeEnum}
                    selectFirstName="Select One"
                  />
                ))}
            </>
          ) : null}
          <Field
            component={CustomDatePicker}
            name="installmentDate"
            id="installmentDate"
            tooltipText={INSTALLMENTDATE_TOOLTIP_TEXT}
            label="Estimated installation Date"
          />
          {!isFTL && (
            <>
              <FormGroup>
                <Row>
                  <Col>
                    <Label>
                      <b>Is there a second system being installed?</b>
                    </Label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      className={hasSecondHVAC ? '' : 'inactive'}
                      type="button"
                      title="Yes"
                      clickHandler={() => {
                        setHasSecondHVAC(true);
                        setValuesToState(values);
                      }}
                    />
                  </Col>
                  <Col>
                    <Button
                      className={!hasSecondHVAC ? '' : 'inactive'}
                      type="button"
                      title="No"
                      clickHandler={() => {
                        setHasSecondHVAC(false);
                        setValuesToState(values);
                      }}
                    />
                  </Col>
                </Row>
              </FormGroup>
              {selectedProjectType &&
                (selectedProjectType === 'A' ? (
                  <>
                    <Field
                      component={Select}
                      label="Second HVAC System Type"
                      name="secondHvacSystem"
                      id="secondHvacSystem"
                      tooltipText={SECONDHVACSYSTEM_TOOLTIP_TEXT}
                      type="select"
                      selectValues={onlyHvacSystem}
                      selectFirstName="Select One"
                      className={hasSecondHVAC ? '' : 'hidden'}
                    />
                    <Field
                      component={Select}
                      label="Second HVAC Estimated System Size"
                      name="secondHvacTonnage"
                      id="secondHvacTonnage"
                      tooltipText={SECONDHVACTONNAGE_TOOLTIP_TEXT}
                      type="select"
                      selectValues={hvacTonnageEnum}
                      selectFirstName="Select One"
                      className={hasSecondHVAC ? '' : 'hidden'}
                    />
                  </>
                ) : (
                  <Field
                    component={Select}
                    label="Second HVAC System Type"
                    name="secondHvacSystem"
                    id="secondHvacSystem"
                    tooltipText={SECONDHVACSYSTEM_TOOLTIP_TEXT}
                    type="select"
                    selectValues={WaterHeaterTypeEnum}
                    selectFirstName="Select One"
                    className={hasSecondHVAC ? '' : 'hidden'}
                  />
                ))}
            </>
          )}
          <Field
            component={CustomInput}
            label="Your Bank Account Routing Number"
            name="appBankAccountRoutingNumber"
            id="appBankAccountRoutingNumber"
            tooltipText={APPBANKACCOUNTROUTINGNUMBER_TOOLTIP_TEXT}
            type="password"
            icon="lock"
            number
            pattern="\d*"
            maxLength={9}
          />
          <p className="info">
            {appName} uses security measures that comply with federal law, including data encryption
            technology, to protect your personal information from unauthorized access and use.
          </p>
          <Field
            component={CustomInput}
            label="Your Bank Account Number"
            name="appBankAccountNumber"
            id="appBankAccountNumber"
            tooltipText={APPBANKACCOUNTNUMBER_TOOLTIP_TEXT}
            type="password"
            icon="lock"
            pattern="\d*"
            maxLength={25}
          />
          <p className="info">Click on the padlocks to read hidden text</p>
          {microfInformation.hasCoApp && (
            <>
              <Field
                component={CustomInput}
                label="Co-Applicant Bank Account Routing Number"
                name="coAppBankAccountRoutingNumber"
                id="coAppBankAccountRoutingNumber"
                tooltipText={COAPPBANKACCOUNTROUTINGNUMBER_TOOLTIP_TEXT}
                type="password"
                icon="lock"
                number
                pattern="\d*"
                maxLength={9}
              />
              <p className="info">
                {appName} uses security measures that comply with federal law, including data
                encryption technology, to protect your personal information from unauthorized access
                and use.
              </p>
              <Field
                component={CustomInput}
                label="Co-Applicant Bank Account Number"
                name="coAppBankAccountNumber"
                id="coAppBankAccountNumber"
                tooltipText={COAPPBANKACCOUNTNUMBER_TOOLTIP_TEXT}
                type="password"
                icon="lock"
                pattern="\d*"
                maxLength={25}
              />
              <p className="info">Click on the padlocks to read hidden text</p>
            </>
          )}
          <Field
            component={CustomInput}
            label="Applicant Email Address"
            name="email"
            id="email"
            tooltipText={EMAIL_TOOLTIP_TEXT}
            type="email"
          />
          {microfInformation.hasCoApp && (
            <Field
              component={CustomInput}
              label="Co-Applicant Email Address"
              name="coAppEmail"
              id="coAppEmail"
              tooltipText={COAPPEMAIL_TOOLTIP_TEXT}
              type="email"
            />
          )}
          <Button type="submit" title={'Next'} disabled={!!microfInformation.submited} />
        </Form>
      </>
    );
  };

  const ServiceFinanceFormFormik = withFormik({
    mapPropsToValues: () => ({
      projectType: projectType || '',
      hvacSystem: hvacSystem || '',
      hvacTonnage: hvacTonnage || '',
      installmentDate: installmentDate || '',
      secondHvacSystem: secondHvacSystem || '',
      secondHvacTonnage: secondHvacTonnage || '',
      appBankAccountRoutingNumber: appBankAccountRoutingNumber || '',
      appBankAccountNumber: appBankAccountNumber || '',
      coAppBankAccountRoutingNumber: coAppBankAccountRoutingNumber || '',
      coAppBankAccountNumber: coAppBankAccountNumber || '',
      email: appEmail || '',
      coAppEmail: coAppEmail || '',
    }),

    validationSchema: Yup.object({
      projectType: !isFTL ? Yup.string().required('Please select the Project Type.') : Yup.string(),
      hvacSystem: !isFTL
        ? Yup.string().required('Please select the HVAC System Type.')
        : Yup.string(),
      hvacTonnage: Yup.string().when('hvacSystem', {
        is: value => isACUnit(value) && !isFTL,
        then: Yup.string().required('Please select the Estimated HVAC System Size.'),
        otherwise: Yup.string().notRequired(),
      }),
      installmentDate: Yup.string()
        .required('Please enter the estimated installment date.')
        .test(
          'minDate',
          'Invalid date.',
          val => val && new Date(val) >= new Date().setHours(0, 0, 0, 0),
        ),
      secondHvacSystem:
        !isFTL && hasSecondHVAC
          ? Yup.string().required('Please select the Second HVAC System Type.')
          : Yup.string(),
      secondHvacTonnage: Yup.string().when('secondHvacSystem', {
        is: value => isACUnit(value) && !isFTL && hasSecondHVAC,
        then: Yup.string().required('Please select the Estimated HVAC System Size.'),
        otherwise: Yup.string().notRequired(),
      }),
      appBankAccountNumber: Yup.string().required('Please enter your Bank Account Number.'),
      appBankAccountRoutingNumber: Yup.string()
        .min(9, 'Please enter just 9 digits.')
        .max(9, 'Please enter just 9 digits.')
        .required('Please enter your Bank Account Routing Number.'),
      coAppBankAccountNumber: !microfInformation.hasCoApp
        ? Yup.string()
        : Yup.string().required('Please enter the Bank Account Number.'),
      coAppBankAccountRoutingNumber: !microfInformation.hasCoApp
        ? Yup.string()
        : Yup.string()
            .min(9, 'Please enter just 9 digits.')
            .max(9, 'Please enter just 9 digits.')
            .required('Please enter the Bank Account Routing Number.'),
      email: Yup.string()
        .email('Please enter a valid email.')
        .required('Please enter an email.')
        .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),
      coAppEmail: microfInformation.hasCoApp
        ? Yup.string()
            .test({
              name: 'sameEmail',
              exclusive: false,
              message:
                "The co-applicant's email must be different than the primary applicant's email address.",
              test: function(val) {
                return val !== this.resolve(Yup.ref('email'));
              },
            })
            .email('Please enter a valid email.')
            .required('Please enter an email.')
            .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val))
        : Yup.string(),
    }),

    handleSubmit: values => {
      setValuesToState(values);

      if (homeownerInsurance !== null) {
        dispatchChangePage(MicrofFlowPageNumber.Review);
      }
    },
  })(ServiceFinanceForm);
  return (
    <Container>
      <Row>
        {isFTL ? (
          <BackBtn changePage={dispatchChangePage} page={MicrofFlowPageNumber.LandingFTL} />
        ) : (
          <Col sm="1" />
        )}

        <Col sm="5">{children}</Col>
        <Col sm="6">
          <ServiceFinanceFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  coAppBankAccountNumber: state.microfInformation.coAppBankAccountNumber,
  coAppBankAccountRoutingNumber: state.microfInformation.coAppBankAccountRoutingNumber,
  microfInformation: state.microfInformation,
  pqi: state.preQualificationInformation,
  appBankAccountNumber: state.microfInformation.appBankAccountNumber,
  appBankAccountRoutingNumber: state.microfInformation.appBankAccountRoutingNumber,
  homeownerInsurance: state.microfInformation.homeownerInsurance,
  hvacSystem: state.microfInformation.hvacSystem,
  projectType: state.microfInformation.projectType,
  hvacTonnage: state.microfInformation.hvacTonnage,
  installmentDate: state.microfInformation.installmentDate,
  secondHvacSystem: state.microfInformation.secondHvacSystem,
  secondHvacTonnage: state.microfInformation.secondHvacTonnage,
  coAppEmail: state.microfInformation.coAppEmail,
  appStatus: state.softPull.applicationSubmitDTO.status,
  appEmail: state.preQualificationInformation.email,
  isFTL: state.microfInformation.isFTL,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangeInfoValue: (key, value) => dispatch(changeMicrofInfoValue(key, value)),
  dispatchChangePreQualInfoValue: (key, value) => dispatch(changePreQualInfoValue(key, value)),
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

ServiceFinance.propTypes = {
  hvacSystem: PropTypes.string,
  hvacTonnage: PropTypes.string,
  secondHvacSystem: PropTypes.string,
  secondHvacTonnage: PropTypes.string,
  dispatchChangePage: PropTypes.func,
  dispatchChangeInfoValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ServiceFinance);
