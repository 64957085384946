import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import SsnFormat from '../../../components/UI/Form/NumberFormat/SsnFormat';
import {
  isSSNanITIN,
  isStateAllowed,
  reactGAEvent,
  reactGAPageview,
  validDateFormat,
} from '../../../helper';
import {
  changePage,
  setFlow,
  submitMindfire,
  changeEnerBankInfoValue,
} from '../../../redux/actions/index';
import { microfFlow, preQualificationFlow } from '../../../redux/initialState';
import CustomDatePicker from '../../../components/UI/Form/DatePicker/DatePicker';
import { AgeFromDateString } from 'age-calculator';
import { EnerBankFlowPageNumber } from './EnerBankFlow';
import { MicrofFlowPageNumber } from '../../Microf/MicrofFlow';
import { AquaPreQualificationFlowPageNumber } from '../../PreQualification/preQualificationFlow';
import AirConditioningSystemEnum from '../../../assets/js/AirConditioningSystemEnum';
import { COAPPDOB_TOOLTIP_TEXT, COAPPFULLSSN_TOOLTIP_TEXT } from '../../../constants/tooltipTexts';

const CoAppIdentificationInfo = props => {
  const {
    email,
    dispatchChangePage,
    dispatchChangeInfoValue,
    information,
    children,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    dispatchSetFlow,
    history,
    pqi,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_ebcoappidentificationinfo',
      trackerNames: [],
      title: 'ca_ebcoappidentificationinfo',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const CoAppIdentificationInfoForm = () => (
    <Form noValidate autoComplete="off">
      <Field
        component={CustomDatePicker}
        name="coAppBirthday"
        id="coAppBirthday"
        tooltipText={COAPPDOB_TOOLTIP_TEXT}
        label="Co-Applicant's Date of Birth"
      />
      <Field
        component={SsnFormat}
        label="Co-Applicant's Full SSN"
        name="coAppFullSsn"
        id="coAppFullSsn"
        tooltipText={COAPPFULLSSN_TOOLTIP_TEXT}
        type="password"
        icon="lock"
      />
      <p className="info">
        Optimus uses security measures that comply with federal law, including data encryption
        technology, to protect your personal information from unauthorized access and use.
      </p>
      <Button type="submit" title="Next" />
    </Form>
  );

  const CoAppIdentificationInfoFormFormik = withFormik({
    mapPropsToValues: () => ({
      coAppBirthday: information.coAppBirthday || '',
      coAppFullSsn: information.coAppFullSsn || '',
    }),

    validationSchema: Yup.object({
      coAppBirthday: Yup.string()
        .required("Please enter the Co-Applicant's date of birth.")
        .test(
          'incomplete',
          'Incomplete date',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 8,
        )
        .test('valid', 'Invalid date format.', val => val && !validDateFormat(val))
        .test(
          'age',
          'The Co-Applicant must be at least 18 years old.',
          val => val && new AgeFromDateString(val).age >= 18,
        ),
      coAppFullSsn: Yup.string()
        .required("Please enter Co-Applicant's full SSN.")
        .test(
          'len',
          'Must have 9 digits',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 9,
        )
        .test(
          'unique',
          "Same SSN as the applicant's is not allowed",
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('') !== information.appFullSsn,
        )
        .typeError('Numbers only.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      let ssn = values.coAppFullSsn.match(/\d/g).join('');
      dispatchChangeInfoValue('coAppBirthday', values.coAppBirthday);
      dispatchChangeInfoValue('coAppFullSsn', ssn);

      if (isSSNanITIN(ssn)) {
        if (
          !isStateAllowed(pqi.state) ||
          pqi.airConditioningSystem !== AirConditioningSystemEnum.AIR_CONDITION_OR_HEATING_SYSTEM.id
        ) {
          dispatchSetFlow(preQualificationFlow);
          dispatchChangePage(AquaPreQualificationFlowPageNumber.NotAllowed);
          history.push('/');
        } else {
          dispatchSetFlow(microfFlow);
          dispatchChangePage(MicrofFlowPageNumber.Landing);
          history.push('/RTO');
        }
      } else {
        dispatchChangePage(EnerBankFlowPageNumber.CoAppEmploymentDetails);
      }
      dispatchSubmitMindfire({
        email: email,
        lastPage: '11019',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(CoAppIdentificationInfoForm);

  return (
    <Container>
      <Row>
        <BackBtn changePage={dispatchChangePage} page={EnerBankFlowPageNumber.CoAppAddress} />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <CoAppIdentificationInfoFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  information: state.enerBankInformation,
  pqi: state.preQualificationInformation,
  ssnLastDigits: state.preQualificationInformation.ssnLastDigits || '',
  email: state.preQualificationInformation.email,
  isReview: state.enerBankInformation.isReview,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetFlow: flowName => dispatch(setFlow(flowName)),
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeInfoValue: (key, value) => dispatch(changeEnerBankInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

CoAppIdentificationInfo.propTypes = {
  information: PropTypes.object,
  ssnLastDigits: PropTypes.string,
  dispatchChangePage: PropTypes.func,
  dispatchChangeInfoValue: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CoAppIdentificationInfo),
);
