import React from 'react';
import MerchantNumber from './MerchantNumber';

const Flow = [
  <MerchantNumber>
    <h2>Update Merchant Number</h2>
  </MerchantNumber>,
];

export default Flow;

export const UpdateMerchantNumberFlowPageNumber = {
  PersonalInformation: 0,
};
