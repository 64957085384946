import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import Button from '../../components/UI/Button/CustomButton';
import KwickComfortLogoTransparent from '../../components/UI/Rheem/KwickComfortLogo/KwickComfortLogoTransparent';
import { changePage } from '../../redux/actions/naviActions';

const RheemLanding = ({ page, dispatchChangePage }) => (
  <Container>
    <Row>
      <Col xs="12" md="auto">
        <h2 className="kwik-comfort-flexpay">KwikComfort Simplicity</h2>
      </Col>
    </Row>
    <Row>
      <Col xs="12" lg="6" className="d-flex justify-content-center mt-5">
        <Row className="kwick-comfort-landing">
          <Col>
            <KwickComfortLogoTransparent className="w-200px" />
            <h4>KwikComfort Simplicity</h4>
            <p className="primary px-5">
              is a revolutionary HVAC lease purchase solution to create peace of mind for
              homeowners. Own the equipment after paying one low monthly payment covering all HVAC
              costs, including maintenance and repairs, for 10 years or by prepayment.
            </p>
          </Col>
        </Row>
      </Col>
      <Col xs="12" lg="6" className="mt-5">
        <Row noGutters>
          <Col className="rheem-ul landing">
            <Row>
              <Col>
                <h5>Low Monthly Payments</h5>
              </Col>
            </Row>
            <Row className="ml-35px">
              <Col>
                <h5>Easy Approvals</h5>
              </Col>
            </Row>
            <Row className="ml-50px">
              <Col>
                <h5>Zero Upfront Costs</h5>
              </Col>
            </Row>
            <Row className="ml-50px">
              <Col>
                <h5>Annual Maintenance Included</h5>
              </Col>
            </Row>
            <Row className="ml-35px">
              <Col>
                <h5>Supply of Filters Included</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <h5>
                  Full 10 Year Warranty <span className="fs-14">(All Parts and Labor)</span>
                </h5>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
    <Row className="justify-content-center mt-5">
      <Col xs="12" md="auto">
        <Button
          type="submit"
          title="Apply Now"
          clickHandler={() => {
            dispatchChangePage(page - 9);
          }}
        />
      </Col>
    </Row>
  </Container>
);

const mapStateToProps = state => ({
  page: state.navi.preQualificationFlowCurrentPage,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RheemLanding);
