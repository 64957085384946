import PropTypes from 'prop-types';
import React from 'react';
import { Field, Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import CustomInput from '../../../components/UI/Form/Input/CustomInput';
import Button from '../../../components/UI/Button/CustomButton';
import Select from '../../../components/UI/Form/Select/Select';
import EINFormat from '../../../components/UI/Form/NumberFormat/EINFormat';
import CustomPhoneNumber from '../../../components/UI/Form/PhoneNumber/PhoneNumber';
import Checkbox from '../../../components/UI/Form/Checkbox/Checkbox';
import {
  changePage,
  changeGSContractorEnrollmentInfoValue,
  submitMindfire,
  endLoading,
} from '../../../redux/actions/index';
import businessType from '../../../assets/js/BusinessTypeEnum';
import usStates from '../../../assets/js/USStateEnum';
import { GreenskyContractorEnrollmentFlowPageNumber } from './_Flow';
import {
  CONTRACTOR_BUSINESS_NAME_TOOLTIP_TEXT,
  CONTRACTOR_BUSINESS_TYPE_TOOLTIP_TEXT,
  CONTRACTOR_CITY_TOOLTIP_TEXT,
  CONTRACTOR_DBA_NAME_TOOLTIP_TEXT,
  CONTRACTOR_EIN_TOOLTIP_TEXT,
  CONTRACTOR_PHONE_TOOLTIP_TEXT,
  CONTRACTOR_STATE_TOOLTIP_TEXT,
  CONTRACTOR_STREET_TOOLTIP_TEXT,
  CONTRACTOR_WEBSITE_TOOLTIP_TEXT,
  CONTRACTOR_ZIP_CODE_TOOLTIP_TEXT,
} from '../../../constants/tooltipTexts';

const DealershipInformation = props => {
  const { gsi, dispatchChangeValue, dispatchChangePage, children } = props;

  const setIsSameAddress = values => {
    dispatchChangeValue('dealerName', values.dealerName);
    dispatchChangeValue('dbaName', values.dbaName);
    dispatchChangeValue('businessType', values.businessType);
    dispatchChangeValue('phoneNumber', values.phoneNumber);
    dispatchChangeValue('website', values.website);
    dispatchChangeValue('propertyStreet', values.propertyStreet);
    dispatchChangeValue('propertyCity', values.propertyCity);
    dispatchChangeValue('propertyZipcode', values.propertyZipcode);
    dispatchChangeValue('propertyState', values.propertyState);
    if (!gsi.sameMailingAddr) {
      dispatchChangeValue('street', values.propertyStreet);
      dispatchChangeValue('city', values.propertyCity);
      dispatchChangeValue('zipcode', values.propertyZipcode);
      dispatchChangeValue('state', values.propertyState);
    } else {
      dispatchChangeValue('street', values.street);
      dispatchChangeValue('city', values.city);
      dispatchChangeValue('zipcode', values.zipcode);
      dispatchChangeValue('state', values.state);
    }
    dispatchChangeValue('sameMailingAddr', !gsi.sameMailingAddr);
  };

  const DealershipInformationForm = ({ values, errors, touched }) => (
    <Form autoComplete="off">
      <h3>General</h3>
      <Field
        maxLength={10}
        component={EINFormat}
        label="Federal Tax ID (EIN)"
        name="ein"
        id="ein"
        tooltipText={CONTRACTOR_EIN_TOOLTIP_TEXT}
        type="text"
        disabled={true}
      />
      <Field
        component={CustomInput}
        label="Legal Business Name *"
        name="dealerName"
        id="dealerName"
        tooltipText={CONTRACTOR_BUSINESS_NAME_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={CustomInput}
        label="DBA Name"
        name="dbaName"
        id="dbaName"
        tooltipText={CONTRACTOR_DBA_NAME_TOOLTIP_TEXT}
        type="text"
      />{' '}
      <Field
        component={Select}
        label="Business Type *"
        name="businessType"
        id="businessType"
        tooltipText={CONTRACTOR_BUSINESS_TYPE_TOOLTIP_TEXT}
        type="select"
        selectValues={businessType}
        selectFirstName="Select One"
      />
      <h3 className="top-20">Contact Information</h3>
      <Field
        component={CustomPhoneNumber}
        label="Phone *"
        name="phoneNumber"
        id="phoneNumber"
        tooltipText={CONTRACTOR_PHONE_TOOLTIP_TEXT}
      />
      <Field
        component={CustomInput}
        label="Website"
        name="website"
        id="website"
        tooltipText={CONTRACTOR_WEBSITE_TOOLTIP_TEXT}
        type="text"
      />
      <h3 className="top-20">Property Address</h3>
      <Field
        component={CustomInput}
        label="Street *"
        name="propertyStreet"
        id="propertyStreet"
        tooltipText={CONTRACTOR_STREET_TOOLTIP_TEXT}
        type="text"
      />
      <Row>
        <Col sm="6">
          <Field
            component={CustomInput}
            label="City *"
            name="propertyCity"
            id="propertyCity"
            tooltipText={CONTRACTOR_CITY_TOOLTIP_TEXT}
            type="text"
          />
        </Col>
        <Col sm="6">
          <Field
            component={Select}
            label="State *"
            name="propertyState"
            id="propertyState"
            tooltipText={CONTRACTOR_STATE_TOOLTIP_TEXT}
            type="select"
            selectValues={usStates}
            selectFirstName="Select State"
          />
        </Col>
      </Row>
      <Field
        component={CustomInput}
        label="ZIP Code *"
        name="propertyZipcode"
        id="propertyZipcode"
        tooltipText={CONTRACTOR_ZIP_CODE_TOOLTIP_TEXT}
        type="text"
        maxLength={10}
      />
      <h3 className="top-20">Mailing Address</h3>
      <Field
        component={Checkbox}
        name="sameMailingAddr"
        id="sameMailingAddr"
        label="Same as property address"
        labelClassName="bold mb-0"
        clickHandler={() => {
          setIsSameAddress(values);
        }}
      />
      {gsi.sameMailingAddr ? null : (
        <>
          <Field
            component={CustomInput}
            label="Street *"
            name="street"
            id="street"
            tooltipText={CONTRACTOR_STREET_TOOLTIP_TEXT}
            type="text"
          />
          <Row>
            <Col sm="6">
              <Field
                component={CustomInput}
                label="City *"
                name="city"
                id="city"
                tooltipText={CONTRACTOR_CITY_TOOLTIP_TEXT}
                type="text"
              />
            </Col>
            <Col sm="6">
              <Field
                component={Select}
                label="State *"
                name="state"
                id="state"
                tooltipText={CONTRACTOR_STATE_TOOLTIP_TEXT}
                type="select"
                selectValues={usStates}
                selectFirstName="Select State"
              />
            </Col>
          </Row>
          <Field
            component={CustomInput}
            label="ZIP Code *"
            name="zipcode"
            id="zipcode"
            tooltipText={CONTRACTOR_ZIP_CODE_TOOLTIP_TEXT}
            type="text"
            maxLength={10}
          />
        </>
      )}
      <Button type="submit" title="Next" />
    </Form>
  );

  const DealershipInformationFormFormik = withFormik({
    mapPropsToValues: () => ({
      ein: gsi.ein || '',
      dealerName: gsi.dealerName || '',
      dbaName: gsi.dbaName || '',
      businessType: gsi.businessType || '',
      phoneNumber: gsi.phoneNumber || '',
      website: gsi.website || '',
      propertyStreet: gsi.propertyStreet || '',
      propertyCity: gsi.propertyCity || '',
      propertyZipcode: gsi.propertyZipcode || '',
      propertyState: gsi.propertyState || '',
      sameMailingAddr: gsi.sameMailingAddr || '',
      street: gsi.street || '',
      city: gsi.city || '',
      zipcode: gsi.zipcode || '',
      state: gsi.state || '',
    }),

    validationSchema: Yup.object({
      dealerName: Yup.string().required('Please enter Legal Business Name.'),
      businessType: Yup.string().required('Please select one Business Type.'),
      phoneNumber: Yup.string()
        .test('required', 'Please enter Phone number.', val => val !== '+' && val)
        .test(
          'len',
          'Invalid Phone number.',
          val => val && val.match(/\d/g).join('').length === 10,
        ),
      propertyStreet: Yup.string().required('Please enter a Property Street.'),
      propertyCity: Yup.string().required('Please enter a Property City.'),
      propertyZipcode: Yup.string()
        .matches(/^\d{5}?$/, {
          message: 'Invalid Property ZIP Code.',
          excludeEmptyString: true,
        })
        .required('Please enter a Property ZIP Code.'),
      propertyState: Yup.string().required('Please select a Property State.'),

      street: gsi.sameMailingAddr ? Yup.string() : Yup.string().required('Please enter a Street.'),
      city: gsi.sameMailingAddr ? Yup.string() : Yup.string().required('Please enter a City.'),
      zipcode: gsi.sameMailingAddr
        ? Yup.string()
        : Yup.string()
            .matches(/^\d{5}?$/, {
              message: 'Invalid ZIP Code.',
              excludeEmptyString: true,
            })
            .required('Please enter a ZIP Code.'),
      state: gsi.sameMailingAddr ? Yup.string() : Yup.string().required('Please select a State.'),
    }),

    handleSubmit: values => {
      dispatchChangeValue('dealerName', values.dealerName);
      dispatchChangeValue('dbaName', values.dbaName);
      dispatchChangeValue('businessType', values.businessType);
      dispatchChangeValue('phoneNumber', values.phoneNumber);
      dispatchChangeValue('website', values.website);
      dispatchChangeValue('propertyStreet', values.propertyStreet);
      dispatchChangeValue('propertyCity', values.propertyCity);
      dispatchChangeValue('propertyZipcode', values.propertyZipcode);
      dispatchChangeValue('propertyState', values.propertyState);
      if (values.sameMailingAddr) {
        dispatchChangeValue('street', values.propertyStreet);
        dispatchChangeValue('city', values.propertyCity);
        dispatchChangeValue('zipcode', values.propertyZipcode);
        dispatchChangeValue('state', values.propertyState);
      } else {
        dispatchChangeValue('street', values.street);
        dispatchChangeValue('city', values.city);
        dispatchChangeValue('zipcode', values.zipcode);
        dispatchChangeValue('state', values.state);
      }
      dispatchChangePage(GreenskyContractorEnrollmentFlowPageNumber.BusinessManager);
    },
  })(DealershipInformationForm);

  return (
    <Container>
      <Row>
        <Col sm="1" />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <DealershipInformationFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  gsi: state.greenskyInformation.contractorEnrollment,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) => dispatch(changeGSContractorEnrollmentInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchEndLoading: () => dispatch(endLoading()),
});

DealershipInformation.propTypes = {
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
  dispatchSubmitSoftPullAquaApplication: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DealershipInformation);
