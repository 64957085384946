import React from 'react';
import AccountEmail from './AccountEmail';
import AccountPassword from './AccountPassword';
import LoanAmount from './LoanAmount';
import Offers from './Offers';
import UserAddress from './UserAddress';
import UserInfo from './UserInfo';
import UserLastInfo from './UserLastInfo';
import InvalidResponse from './InvalidResponse';

const PreQualificationFlow = [
  <AccountEmail>
    <h4>Let’s start with your email address.</h4>
  </AccountEmail>,
  <AccountPassword>
    <h4>Choose a password that you can remember.</h4>
  </AccountPassword>,
  <UserInfo>
    <h4>What’s your name and number?</h4>
  </UserInfo>,
  <UserAddress>
    <h4>What address will you be making the home improvement at?</h4>
  </UserAddress>,
  <LoanAmount>
    <h4>Provide your income and the job price.</h4>
  </LoanAmount>,
  <UserLastInfo title="Pre-Qualification Information" />,
  <Offers />,
  <InvalidResponse />,
];

export default PreQualificationFlow;

export const UpgradePreQualificationFlowPageNumber = {
  AccountEmail: 0,
  AccountPassword: 1,
  UserInfo: 2,
  UserAddress: 3,
  LoanAmount: 4,
  UserLastInfo: 5,
  Offers: 6,
  InvalidResponse: 7,
};
