import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import usStates from '../../../assets/js/USStateEnum';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import Input from '../../../components/UI/Form/Input/CustomInput';
import Select from '../../../components/UI/Form/Select/Select';
import {
  COAPPCITY_TOOLTIP_TEXT,
  COAPPSTATE_TOOLTIP_TEXT,
  COAPPSTREET_TOOLTIP_TEXT,
  COAPPZIPCODE_TOOLTIP_TEXT,
} from '../../../constants/tooltipTexts';
import { isStateAllowed, reactGAEvent, reactGAPageview } from '../../../helper';
import { changePage, submitMindfire, changeRheemInfoValue } from '../../../redux/actions';
import { RheemFlowPageNumber } from '../RheemFlow';

const CoApplicantAddress = props => {
  const {
    rheemInformation,
    dispatchChangeRheemInfoValue,
    pqi,
    dispatchChangePage,
    children,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_coappinfo2',
      trackerNames: [],
      title: 'ca_coappinfo2',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const setIsSameAddress = bool => {
    dispatchChangeRheemInfoValue('isSameAddress', bool);
  };

  const CoApplicantAddressForm = () => (
    <Form>
      <Label>
        <b>The address is the same as the applicant's.</b>
      </Label>
      <Row>
        <Col sm="6">
          <Button
            className={rheemInformation.isSameAddress ? '' : 'inactive'}
            type="button"
            title="Yes"
            clickHandler={() => setIsSameAddress(true)}
          />
        </Col>
        <Col sm="6">
          <Button
            className={!rheemInformation.isSameAddress ? '' : 'inactive'}
            type="button"
            title="No"
            clickHandler={() => setIsSameAddress(false)}
          />
        </Col>
      </Row>
      <Field
        disabled={rheemInformation.isSameAddress}
        component={Input}
        label="Street"
        name="coAppStreet"
        id="coAppStreet"
        tooltipText={COAPPSTREET_TOOLTIP_TEXT}
        type="text"
      />
      <Row>
        <Col sm="6">
          <Field
            disabled={rheemInformation.isSameAddress}
            component={Input}
            label="City"
            name="coAppCity"
            id="coAppCity"
            tooltipText={COAPPCITY_TOOLTIP_TEXT}
            type="text"
          />
        </Col>
        <Col sm="6">
          <Field
            disabled={rheemInformation.isSameAddress}
            component={Select}
            label="State"
            name="coAppState"
            id="coAppState"
            tooltipText={COAPPSTATE_TOOLTIP_TEXT}
            type="select"
            selectValues={usStates}
            selectFirstName="Select State"
          />
        </Col>
      </Row>
      <Field
        disabled={rheemInformation.isSameAddress}
        component={Input}
        label="ZIP Code"
        name="coAppZipCode"
        id="coAppZipCode"
        tooltipText={COAPPZIPCODE_TOOLTIP_TEXT}
        type="text"
        maxLength={10}
      />

      <Button type="submit" title="Next" />
    </Form>
  );

  const CoApplicantAddressFormFormik = withFormik({
    mapPropsToValues: () => ({
      coAppStreet:
        rheemInformation.isSameAddress && pqi.street
          ? pqi.street
          : rheemInformation.coAppStreet || '',
      coAppCity:
        rheemInformation.isSameAddress && pqi.city ? pqi.city : rheemInformation.coAppCity || '',
      coAppZipCode:
        rheemInformation.isSameAddress && pqi.zipcode
          ? pqi.zipcode
          : rheemInformation.coAppZipCode || '',
      coAppState:
        rheemInformation.isSameAddress && pqi.state ? pqi.state : rheemInformation.coAppState || '',
    }),

    validationSchema: Yup.object({
      coAppStreet: Yup.string()
        .matches(/.*([a-zA-Z].*[0-9]|[0-9].*[a-zA-Z]).*/, {
          message: 'The street field must contain the street name and number',
          excludeEmptyString: true,
        })
        .required('Please enter a street.'),
      coAppCity: Yup.string().required('Please enter a city.'),
      coAppZipCode: Yup.string()
        .matches(/^\d{5}?$/, {
          message: 'Invalid zip code.',
          excludeEmptyString: true,
        })
        .required('Please enter a zip code.'),
      coAppState: Yup.string()
        .required('Please select a state.')
        .test('isStateAllowed', 'Out of Trade Area.', val => isStateAllowed(val)),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      dispatchChangeRheemInfoValue('coAppStreet', values.coAppStreet);
      dispatchChangeRheemInfoValue('coAppCity', values.coAppCity);
      dispatchChangeRheemInfoValue('coAppZipCode', values.coAppZipCode);
      dispatchChangeRheemInfoValue('coAppState', values.coAppState);
      dispatchChangePage(RheemFlowPageNumber.CoApplicantGrossAnnualIncome);
      dispatchSubmitMindfire({
        email: pqi.email,
        lastPage: '0088',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(CoApplicantAddressForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={RheemFlowPageNumber.CoApplicantInformation}
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <CoApplicantAddressFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  pqi: state.preQualificationInformation,
  rheemInformation: state.rheemInformation,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeRheemInfoValue: (key, value) => dispatch(changeRheemInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

CoApplicantAddress.propTypes = {
  dispatchChangePage: PropTypes.func,
  rheemInformation: PropTypes.object,
  dispatchChangeRheemInfoValue: PropTypes.func,
  pqi: PropTypes.object,
  aquaInformation: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CoApplicantAddress);
