import React from 'react';
import { connect } from 'react-redux';
import { Col } from 'reactstrap';

import Dropzone from './Dropzone';

const UploadSection = props => {
  const { application } = props;
  return (
    <Col>
      <div className="dropzone-container">
        <Dropzone
          application={application}
          label="Upload documents for your homeowner"
          filesListName="homeownerDocuments"
          {...props}
        />
      </div>
    </Col>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UploadSection);
