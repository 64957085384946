import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import CompleteHomeownerInterview from './CompleteHomeownerInterview';
import MicrofWorkingHours from './MicrofWorkingHours';

const ServiceFinanceComplete = () => (
  <Container>
    <Row>
      <Col className="text-center">
        <h3>Applicant</h3>
      </Col>
    </Row>
    <CompleteHomeownerInterview />
    <Row className="text-center justify-content-center mt-3">
      <Col>
        <h3>Contractors</h3>
        <h4>
          To check the status of the application,
          <br /> please log into your Contractor Portal
          <br /> and select the customer’s account
        </h4>
      </Col>
    </Row>
    <MicrofWorkingHours />
  </Container>
);

export default ServiceFinanceComplete;
