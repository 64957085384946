import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
import { initialState } from '../initialState';

const changeValue = (state, action) => {
  const updatedValues = {
    [action.key]: action.value,
  };
  return updateObject(state, updatedValues);
};

const changeRegisterAsContractorValue = (state, action) => {
  const updatedValues = {
    ...state,
    registerAsContractor: {
      ...state.registerAsContractor,
      [action.key]: action.value,
    },
  };
  return updateObject(state, updatedValues);
};

const changeContractorEnrollmentValue = (state, action) => {
  const updatedValues = {
    ...state,
    contractorEnrollment: {
      ...state.contractorEnrollment,
      [action.key]: action.value,
    },
  };
  return updateObject(state, updatedValues);
};

const setError = (state, action) => {
  const updatedValues = {
    error: action.payload,
  };
  return updateObject(state, updatedValues);
};

const greenskyReducer = (state, action) => {
  if (!state) {
    state = initialState.greenskyInformation;
  }
  switch (action.type) {
    case actionTypes.CHANGE_GREENSKY_INFO_VALUE:
      return changeValue(state, action);
    case actionTypes.CHANGE_GREENSKY_REGISTER_AS_CONTRACTOR_INFO_VALUE:
      return changeRegisterAsContractorValue(state, action);
    case actionTypes.CHANGE_GREENSKY_CONTRACTOR_ENROLLMENT_INFO_VALUE:
      return changeContractorEnrollmentValue(state, action);
    case actionTypes.RESET_STATE_GREENSKY:
      state = initialState.greenskyInformation;
      return state;
    case actionTypes.GREENSKY_FINANCE_LOGIN_ERROR:
      return setError(state, action);
    default:
      return state;
  }
};

export default greenskyReducer;
