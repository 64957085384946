import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { useVSPixelScript } from '../../assets/hooks';
import AirConditioningSystemEnum from '../../assets/js/AirConditioningSystemEnum';
import applicationStatusEnum, {
  isStatusAquaApprove,
  isStatusAquaDecline,
  isStatusAquaIntermediate,
} from '../../assets/js/ApplicationStatusEnum';
import selectedFinancierEnum from '../../assets/js/SelectedFinancierEnum';
import AquaPromotion from '../../components/UI/AquaPromotion/AquaPromotion';
import CustomButton from '../../components/UI/Button/CustomButton';
import Button from '../../components/UI/Button/CustomButton';
import { isStateAllowed, nextFinancingOption, reactGAEvent, reactGAPageview } from '../../helper';
import {
  changePage,
  changePageTitle,
  mapApplicationToMicrof,
  mapPowerPayToState,
  resetNewAppState,
  resetState,
  setApplicationPromotion,
  setFlow,
  submitMindfire,
  updateApplicationStatus,
  updateSelectedFinancier,
} from '../../redux/actions/index';
import { defaultFlow, microfFlow, powerPayFlow } from '../../redux/initialState';
import { AquaPreQualificationFlowPageNumber } from '../PreQualification/preQualificationFlow';
import AquaFinanceLogin from './AquaFinanceLogin';
import { AquaFlowPageNumber } from './AquaFlow';

const AquaApplicationResult = props => {
  let pendingStatus = applicationStatusEnum.aquaFinanceIntermediates.AQUA_FINANCE_PENDING.value;
  let body;
  const {
    children,
    history,
    aquaFinanceApplication,
    aquaPromotions,
    applicationId,
    selectedApplicationPromotion,
    dispatchSetApplicationPromotion,
    dispatchUpdateApplicationStatus,
    dispatchResetState,
    dispatchSetFlow,
    did,
    consumerToken,
    dispatchChangePageTitle,
    errorMessage,
    dealerUserId,
    amountFinanced,
    email,
    dispatchSubmitMindfire,
    dispatchResetNewAppState,
    dispatchChangePage,
    user,
    application,
    consumerUserId,
    dispatchMapApplicationToMicrof,
    dispatchMapPowerPayToState,
    dispatchUpdateSelectedFinancier,
  } = props;

  const mindFireRequest = { email: email, lastPage: '0065', dealerUserId: dealerUserId, did: did };

  const goToHomeHandlerSaveAndExit = () => {
    (async function updateAppStatus() {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Reject',
        did,
        dealerUserId,
      });
      await dispatchUpdateApplicationStatus(
        applicationStatusEnum.microfMissedOpportunity.OFFER_REJECTED.value,
        consumerToken,
      );
      if (user && user.dealerUser) {
        dispatchSetFlow(defaultFlow);
        dispatchResetNewAppState();
        dispatchChangePage(AquaPreQualificationFlowPageNumber.UserType);
      } else {
        dispatchResetState(props);
        dispatchSetFlow(defaultFlow);
        dispatchChangePage(AquaPreQualificationFlowPageNumber.UserType);
      }
    })();
  };

  let nextFO = nextFinancingOption(application, selectedFinancierEnum.AQUA_FINANCE);

  const score = application?.prequalificationInformation?.score;

  useVSPixelScript();

  useEffect(() => {
    if (isStatusAquaApprove(aquaFinanceApplication.processingStatus)) {
      if (selectedApplicationPromotion) {
        reactGAPageview({
          path: '/ca_aqselect',
          trackerNames: [],
          title: 'ca_aqselect',
          did,
          dealerUserId,
        });
      } else {
        reactGAPageview({
          path: '/ca_aqsuboptions',
          trackerNames: [],
          title: 'ca_aqsuboptions',
          did,
          dealerUserId,
        });
      }
    } else {
      reactGAPageview({
        path: '/ca_aqsuboptions',
        trackerNames: [],
        title: 'ca_aqsuboptions',
        did,
        dealerUserId,
      });
    }
    // eslint-disable-next-line
  }, []);

  if (isStatusAquaDecline(aquaFinanceApplication.processingStatus)) {
    body = (
      <>
        <p>
          Thank you for applying for an Aqua Finance Credit Account, issued by First Electronic
          Bank, Member FDIC. Unfortunately, your application has been declined.
        </p>
        <p>
          You will receive a notice via email within 30 days regarding the reason(s) your
          application was declined. It will include a telephone number for you to call if you have
          additional questions.
        </p>
        {nextFO ? (
          <>
            <p>
              <b>But wait!</b>,You may still qualify for a lease purchase option. A lease purchase
              option is different than financing.
            </p>
            {nextFO === selectedFinancierEnum.MICROF && (
              <Container>
                <Row className="justify-content-center mt-2">
                  <Col xs="auto" sm="12" className="text-center">
                    <p className="fs-25">
                      <b className="primary">
                        Congratulations! You've prequalified for a Microf Lease Offer
                      </b>
                    </p>
                  </Col>
                </Row>
                <Row className="justify-content-center ">
                  <Col xs="auto" lg="8" sm="12" md="12">
                    <p className="fs-22 fw-500 pl-40">
                      Microf’s lease purchase option is different than financing:
                    </p>
                    <ul className="powerpay-microf-lease-purchase-options-arrow">
                      <li>
                        Pay as you go!{' '}
                        <span className="fw-normal">
                          Monthly renewals up to 60 months to own in most states.
                        </span>
                      </li>
                      <li>
                        Own the item(s) after paying the total of payments to ownership.{' '}
                        <span className="fw-normal">
                          Substantial savings are available through Early Purchase Options!
                        </span>
                      </li>
                      <li>
                        Damaged credit?{' '}
                        <span className="fw-normal">
                          Microf regularly approves applicants with less than perfect credit.
                        </span>
                      </li>
                      <li>
                        Have questions?{' '}
                        <span className="fw-normal">
                          Call us at 855-642-7631 or email us at info@microf.com.
                        </span>
                      </li>
                    </ul>
                  </Col>
                </Row>
                <Row className="justify-content-center ">
                  <Col xs="auto" lg="8" sm="12" md="12">
                    <p className="fs-22 fw-500 pl-40">
                      Here is what you will need to complete the application:
                    </p>
                    <ul className="microf-lease-purchase-options">
                      <li>Social Security Number</li>
                      <li>Bank account information (routing and account numbers)</li>
                      <li>Income or benefits information (e.g., paystub, bank statement, etc.)</li>
                      <li>Co-applicant information (not required)</li>
                    </ul>
                  </Col>
                </Row>
                <Row className="justify-content-center ">
                  <Col xs="auto" lg="8" sm="12" md="12">
                    <p className="fs-22 fw-500 pl-40">Not eligible for lease purchase:</p>
                    <ul className="powerpay-microf-lease-purchase-options-x">
                      <li>Homes on rented land (e.g., mobile homes in a park)</li>
                      <li>Reverse mortgages</li>
                      <li>Rent-to-own or lease-to-own properties</li>
                      <li>Homeowners with active or dismissed bankruptcies</li>
                    </ul>
                  </Col>
                </Row>
              </Container>
            )}

            <Button
              type="button"
              title={
                nextFO === selectedFinancierEnum.POWER_PAY
                  ? 'Continue to PowerPay'
                  : 'Apply for lease purchase program'
              }
              clickHandler={() => {
                reactGAEvent({
                  category: 'Button',
                  action: 'Click',
                  label:
                    nextFO === selectedFinancierEnum.POWER_PAY
                      ? 'Continue to PowerPay'
                      : 'Apply for lease purchase program',
                  did,
                  dealerUserId,
                });
                dispatchSubmitMindfire(mindFireRequest);

                dispatchUpdateSelectedFinancier(nextFO.value, consumerToken, () => {
                  if (nextFO === selectedFinancierEnum.POWER_PAY) {
                    dispatchMapPowerPayToState(application);
                    dispatchSetFlow(powerPayFlow);
                    history.push('/powerpay');
                  } else {
                    dispatchMapApplicationToMicrof(application);
                    dispatchSetFlow(microfFlow);
                    history.push('/RTO');
                  }
                });
              }}
            />
            <Row className="justify-content-center mt-2">
              <Link
                to={user && user.dealerUser ? '/portal/dashboard' : ''}
                onClick={goToHomeHandlerSaveAndExit}
                style={{ textDecoration: 'none' }}
                className="w-75"
              >
                <Button
                  className="reject-and-exit text-dark btn-secondary w-100"
                  type="button"
                  title="Reject and Exit"
                />
              </Link>
            </Row>
          </>
        ) : (
          <div className="margin-top-20">
            <Link
              to={user && user.dealerUser ? '/portal/dashboard' : ''}
              onClick={() => !(user && user.dealerUser) && dispatchResetState({})}
              style={{ textDecoration: 'none' }}
              className="block"
            >
              <Button
                color="secondary"
                size="lg"
                block
                type="button"
                title={user && user.dealerUser ? 'Back to dashboard' : 'Exit'}
              />
            </Link>
          </div>
        )}
      </>
    );
  } else if (isStatusAquaIntermediate(aquaFinanceApplication.processingStatus)) {
    if (
      aquaFinanceApplication.processingStatus === pendingStatus ||
      ('' + errorMessage).includes('Network')
    ) {
      dispatchChangePageTitle('Application Results');
    }

    body = (
      <>
        <p>
          Thank you for applying for a Aqua Finance Credit Account, issued by First Electronic Bank,
          Member FDIC.{' '}
          {!('' + errorMessage).includes('Network')
            ? 'We have received your application and it needs further review.'
            : 'We are experiencing high volumes and need more time to process your application.'}
        </p>
        {aquaFinanceApplication.processingStatus !== pendingStatus &&
        !('' + errorMessage).includes('Network') ? (
          <>
            {consumerUserId ? (
              <p>
                Please ask your contractor to log into their Microf portal to continue/complete the
                application.
              </p>
            ) : (
              <>
                <p>
                  Please have your contractor call our Partner Support team while you’re present to
                  complete this review or press the Aqua Finance button to login to the Aqua Finance
                  Portal and continue the application.
                </p>
                <AquaFinanceLogin />
              </>
            )}
          </>
        ) : (
          <p>
            Please have your contractor check the portal in the next 10-15 minutes and get the final
            results.
          </p>
        )}
        <div className="margin-top-20">
          <Link
            to={user && user.dealerUser ? '/portal/dashboard' : ''}
            onClick={() => !(user && user.dealerUser) && dispatchResetState({})}
            style={{ textDecoration: 'none' }}
            className="block"
          >
            <Button
              color="secondary"
              size="lg"
              block
              type="button"
              title={user && user.dealerUser ? 'Back to dashboard' : 'Exit'}
            />
          </Link>
        </div>
      </>
    );
  } else if (isStatusAquaApprove(aquaFinanceApplication.processingStatus)) {
    body = (
      <>
        <b className="text-success fs-20">Congratulations!</b>
        <b className="primary fs-20"> You have been approved for the following loan amount: </b>
        <p className="fs-20">
          <NumberFormat
            value={amountFinanced || aquaFinanceApplication.amountFinanced || 0}
            thousandSeparator={true}
            fixedDecimalScale={true}
            decimalScale={2}
            prefix={'$'}
            displayType="text"
          />
        </p>
        {!!aquaPromotions && aquaPromotions.length > 0 ? (
          did || !!selectedApplicationPromotion ? (
            <>
              {did !== null ? (
                <p>Please contact your contractor to go through your product options.</p>
              ) : (
                <>
                  {consumerUserId ? (
                    <p>
                      Please ask your contractor to log into their Microf portal to
                      continue/complete the application.
                    </p>
                  ) : (
                    <>
                      <p>
                        Please press the Aqua Finance button to login to the Aqua Finance Portal and
                        continue the application.
                      </p>
                      <AquaFinanceLogin />
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            Object.keys(aquaPromotions).map(key => (
              <AquaPromotion
                key={aquaPromotions[key].id}
                name={aquaPromotions[key].name}
                apr={aquaPromotions[key].apr}
                monthlyPayment={aquaPromotions[key].monthlyPayment}
                paymentFactor={aquaPromotions[key].paymentFactor}
                discount={aquaPromotions[key].discount}
                buttonText="I want this option"
                applicationId={applicationId}
                promotionId={aquaPromotions[key].id}
                nextPage={AquaFlowPageNumber.AquaApplicationResult}
                submitMindfire={() => dispatchSubmitMindfire(mindFireRequest)}
                setApplicationPromotion={dispatchSetApplicationPromotion}
              />
            ))
          )
        ) : (
          <>
            <p>
              Aqua Finance is still processing your application. Please revisit this application
              from the contractor portal so you can see the decision and select the approved
              product(s) for the customer.
            </p>
          </>
        )}
      </>
    );
  } else {
    body = (
      <>
        <p>
          Thank you for applying for a Aqua Finance Credit Account, issued by First Electronic Bank,
          Member FDIC. We have received your application and it needs further review.
        </p>
        <>
          {consumerUserId ? (
            <p>
              Please ask your contractor to log into their Microf portal to continue/complete the
              application.
            </p>
          ) : (
            <>
              <p>
                Please have your contractor call our Partner Support team while you’re present to
                complete this review or press the Aqua Finance button to login to the Aqua Finance
                Portal and continue the application.
              </p>
              <AquaFinanceLogin />
            </>
          )}
        </>
      </>
    );
  }

  const OutOfTradeArea = () => {
    return (
      <Container>
        <Row className="justify-content-center mt-2">
          <Col xs="auto" sm="8" className="text-center">
            <h5>
              <b>
                Aqua was unable to approve your credit application at this time. You will receive a
                notification via email within 30 days.
              </b>
            </h5>
          </Col>
        </Row>
        <Row className="justify-content-center mt-5">
          <Col xs="10" md="3">
            <Link
              to={user?.dealerUser ? '/portal/dashboard' : ''}
              onClick={() => !user?.dealerUser && dispatchResetState({})}
              style={{ textDecoration: 'none' }}
              className="block"
            >
              <CustomButton
                color="secondary"
                size="lg"
                block
                type="button"
                title={user?.dealerUser ? 'Return to dashboard' : 'Exit'}
              />
            </Link>
          </Col>
        </Row>
      </Container>
    );
  };

  if (
    (nextFO === selectedFinancierEnum.MICROF &&
      !isStateAllowed(application?.propertyAddress?.state)) ||
    (nextFO === selectedFinancierEnum.MICROF &&
      application.airConditioningSystem !==
        AirConditioningSystemEnum.AIR_CONDITION_OR_HEATING_SYSTEM.id)
  ) {
    return OutOfTradeArea();
  } else {
    return (
      <Container>
        <Row>
          <Col sm="1" />
          <Col sm="5">
            {aquaFinanceApplication.processingStatus !== pendingStatus &&
            !('' + errorMessage).includes('Network') ? (
              children
            ) : (
              <h4>Need more time to review your application</h4>
            )}
          </Col>
          <Col sm="6">{body}</Col>
        </Row>
      </Container>
    );
  }
};

const mapStateToProps = state => ({
  email: state.preQualificationInformation.email,
  selectedDealer: state.aquaInformation.selectedDealer,
  aquaFinanceApplication: state.softPull.applicationSubmitDTO.aquaFinanceApplication,
  amountFinanced: state.softPull.applicationSubmitDTO.aquaFinanceInformation.amountFinanced,
  dealerUserId: state.auth.dealerUserId,
  applicationNumber: state.softPull.applicationSubmitDTO.aquaApplicationNumber,
  aquaPromotions: state.softPull.applicationSubmitDTO.aquaPromotions,
  applicationId: state.softPull.applicationSubmitDTO.applicationId,
  selectedApplicationPromotion: state.aquaInformation.selectedApplicationPromotion,
  did: state.auth.did,
  consumerToken: state.softPull.applicationSubmitDTO.consumerToken,
  errorMessage: state.softPull.errorMessage,
  user: state.auth.user,
  application: state.softPull.applicationSubmitDTO,
  consumerUserId: state.auth.consumerId,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetFlow: flowName => dispatch(setFlow(flowName)),
  dispatchResetState: props => dispatch(resetState(props)),
  dispatchSetApplicationPromotion: (applicationId, promotionId, page) =>
    dispatch(setApplicationPromotion(applicationId, promotionId, page)),
  dispatchUpdateApplicationStatus: (applicationId, applicationStatus) =>
    dispatch(updateApplicationStatus(applicationId, applicationStatus)),
  dispatchUpdateSelectedFinancier: (applicationId, selectedFinancier, successCallback) =>
    dispatch(updateSelectedFinancier(applicationId, selectedFinancier, successCallback)),
  dispatchChangePageTitle: value => dispatch(changePageTitle(value)),
  dispatchResetNewAppState: () => dispatch(resetNewAppState()),
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchMapApplicationToMicrof: app => dispatch(mapApplicationToMicrof(app)),
  dispatchMapPowerPayToState: app => dispatch(mapPowerPayToState(app)),
});

AquaApplicationResult.propTypes = {
  selectedDealer: PropTypes.string,
  dispatchChangePage: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AquaApplicationResult),
);
