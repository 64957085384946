import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import Button from '../../../components/UI/Button/CustomButton';
import './index.scss';

const Resources = ({ state, history, flowName, gsi, auth, ...props }) => {
  if (!auth.user || !auth.user.dealerUser) {
    history.push('/login');
  }

  return (
    <Col>
      <Row>
        <Col>
          <h1>Training and Marketing Resources</h1>
        </Col>
      </Row>
      <Row className="panel">
        <Col className="panel-body larger">
          <Row>
            <Col xs="12" md="6" className="mt-1">
              <Button
                className="h-100"
                clickHandler={() => {
                  window.open('https://estimator.microf.com', '_blank');
                }}
                title="Lease payment calculator"
              />
            </Col>
            <Col xs="12" md="6" className="mt-1">
              <Button
                className="h-100"
                clickHandler={() => {
                  window.open('https://microf.com/resources/', '_blank');
                }}
                title="Training Material"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Resources);
