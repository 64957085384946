import { AgeFromDateString } from 'age-calculator';
import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import DatePicker from '../../../../components/UI/Form/DatePicker/DatePicker';
import SsnFormat from '../../../../components/UI/Form/NumberFormat/SsnFormat';
import { reactGAEvent, reactGAPageview, validDateFormat } from '../../../../helper';
import { changeFtlInfoValue, changePage, submitMindfire } from '../../../../redux/actions/index';
import { getAppName } from '../../../../config/settings';
import { FTLFlowPageNumber } from '../FTLFlow';
import {
  COAPPDOB_TOOLTIP_TEXT,
  COAPPFULLSSN_TOOLTIP_TEXT,
} from '../../../../constants/tooltipTexts';

const appName = getAppName();

const IdentificationInformation = props => {
  const {
    dispatchCangeFtlInfoValue,
    children,
    dispatchChangePage,
    ftlInformation: { coAppDoB, coAppFullSsn },
    appFullSsn,
    dispatchSubmitMindfire,
    email,
    did,
    dealerUserId,
    isReview,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_ftlcoappssn',
      trackerNames: [],
      title: 'ca_ftlcoappssn',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const IdentificationInformationForm = () => {
    return (
      <Form noValidate autoComplete="off">
        <Field
          component={DatePicker}
          name="coAppDoB"
          id="coAppDoB"
          tooltipText={COAPPDOB_TOOLTIP_TEXT}
          label="Co-Applicant's Date of Birth"
        />
        <Field
          component={SsnFormat}
          label="Co-Applicant’s Full SSN"
          name="coAppFullSsn"
          id="coAppFullSsn"
          tooltipText={COAPPFULLSSN_TOOLTIP_TEXT}
          type="password"
          icon="lock"
        />
        <p className="info">
          {appName} uses security measures that comply with federal law, including data encryption
          technology, to protect your personal information from unauthorized access and use.
        </p>
        <Button type="submit" title="Continue" />
      </Form>
    );
  };

  const IdentificationInformationFormFormik = withFormik({
    mapPropsToValues: () => ({
      coAppDoB: coAppDoB || '',
      coAppFullSsn: coAppFullSsn || '',
    }),

    validationSchema: Yup.object({
      coAppDoB: Yup.string()
        .required("Please enter the Co-Applicant's date of birth.")
        .test(
          'incomplete',
          'Incomplete date',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 8,
        )
        .test('valid', 'Invalid date format.', val => val && !validDateFormat(val))
        .test(
          'age',
          'The Co-Applicant must be at least 18 years old.',
          val => val && new AgeFromDateString(val).age >= 18,
        ),
      coAppFullSsn: Yup.string()
        .required("Please enter the Co-Applicant's full SSN.")
        .typeError('Only numbers allowed.')
        .test(
          'len',
          'Must have 9 digits',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 9,
        )
        .test(
          'unique',
          "Same SSN as the applicant's is not allowed.",
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('') !== appFullSsn,
        ),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Continue',
        did,
        dealerUserId,
      });
      dispatchCangeFtlInfoValue('coAppDoB', values.coAppDoB);
      dispatchCangeFtlInfoValue(
        'coAppFullSsn',
        values.coAppFullSsn
          .toString()
          .match(/\d/g)
          .join(''),
      );
      dispatchChangePage(FTLFlowPageNumber.Review);
      dispatchSubmitMindfire({
        email: email,
        lastPage: '12017',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(IdentificationInformationForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={
            isReview ? FTLFlowPageNumber.CoAppAddress : FTLFlowPageNumber.CoAppEmploymentInformation
          }
        />
        <Col sm="5">
          {children}
          <p>This information is needed to verify your identity.</p>
        </Col>
        <Col sm="6">
          <IdentificationInformationFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  ftlInformation: state.ftlInformation,
  appFullSsn: state.ftlInformation.appFullSSN,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  isReview: state.ftlInformation.isReview,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchCangeFtlInfoValue: (key, value) => dispatch(changeFtlInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

IdentificationInformation.propTypes = {
  dispatchChangePage: PropTypes.func,
  microfInformation: PropTypes.object,
  dispatchCangeFtlInfoValue: PropTypes.func,
  aquaInformation: PropTypes.object,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(IdentificationInformation),
);
