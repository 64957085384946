import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import BackBtn from '../../components/UI/BackButton/BackButton';
import Button from '../../components/UI/Button/CustomButton';
import Checkbox from '../../components/UI/Form/Checkbox/Checkbox';
import { getPrivacyStatementURL, getAppName } from '../../config/settings';
import incomeSource from '../../assets/js/IncomeSourceEnum';
import otherSourcesOfIncome from '../../assets/js/OtherSourceOfIncomeEnum';
import {
  changePage,
  submitAquaApplication,
  changeAquaInfoValue,
  submitMindfire,
} from '../../redux/actions/index';
import terms from '../../assets/pdf/Microf Statement of Terms and Conditions.pdf';
import statementOfUse from '../../assets/pdf/Microf Statement on Use of Electronic Records.pdf';
import NumberFormat from 'react-number-format';
import idTypes from '../../assets/js/IdTypeEnum';
import { reactGAPageview, reactGAEvent } from '../../helper';
import selectedFinancierEnum from '../../assets/js/SelectedFinancierEnum';
import { AquaFlowPageNumber } from './AquaFlow';
import {
  AUTHORIZE_TOOLTIP_TEXT,
  APPLICANTCONSENT_TOOLTIP_TEXT,
} from '../../constants/tooltipTexts';

const appName = getAppName();

const ApplicationReview = props => {
  const {
    dispatchSubmitAquaApplication,
    children,
    dispatchChangePage,
    dispatchChangeValue,
    pqi,
    aquaInformation,
    softPull,
    dealerUserId,
    did,
    app,
    emulatorId,
    track_id,
    aquaFinanceAssigned,
    applicantAddress,
    dispatchSubmitMindfire,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_aqconfirminfo',
      trackerNames: [],
      title: 'ca_aqconfirminfo',
      did,
      dealerUserId,
    });
    dispatchChangeValue('isReview', false);
    // eslint-disable-next-line
  }, []);

  const editPage = page => {
    dispatchChangeValue('isReview', true);
    dispatchChangePage(page);
  };

  const mindfireRequest = {
    email: pqi.email,
    lastPage: '0056',
    dealerUserId: dealerUserId,
    did: did,
  };

  const ApplicationReviewForm = () => (
    <Form>
      <Row className="review">
        <Col>
          <p>
            <h5>
              <b>I agree to Microf’s Terms & Conditions.</b>
            </h5>
            You authorize {appName}, the technology platform provider, to immediately share all
            application information with participating lenders and you understand and agree that you
            are accepting {appName}’s{' '}
            <Link to={terms} target="_blank">
              Statement of Terms and Conditions
            </Link>
            ,{' '}
            <Link to={statementOfUse} target="_blank">
              Statement on Use of Electronic Records
            </Link>
            , and{' '}
            <a href={getPrivacyStatementURL()} target="_blank" rel="noopener noreferrer">
              Privacy Statement
            </a>
            .
          </p>
          <Field
            component={Checkbox}
            name="applicantMicrofTerms"
            id="applicantMicrofTerms"
            tooltipText={APPLICANTCONSENT_TOOLTIP_TEXT}
            label={`Applicant`}
            labelClassName="bold"
          />

          {aquaInformation.hasCoApp && (
            <Field
              component={Checkbox}
              name="coApplicantMicrofTerms"
              id="coApplicantMicrofTerms"
              tooltipText={APPLICANTCONSENT_TOOLTIP_TEXT}
              label={`Co-Applicant`}
              labelClassName="bold"
            />
          )}
          <p>
            <h5>
              <b>I authorize Aqua Finance to access my info under the Fair Credit Reporting Act.</b>
            </h5>
            <span>
              You understand and agree that you are providing instructions to Aqua Finance under the
              Fair Credit Reporting Act (“FCRA”) to access information from your personal credit
              profile or other information from one or more consumer reporting agencies such as
              Equifax, Experian, or TransUnion. This “hard pull” may impact your credit score.
            </span>
          </p>
          <Field
            component={Checkbox}
            name="applicantAquaTerms"
            id="applicantAquaTerms"
            tooltipText={AUTHORIZE_TOOLTIP_TEXT}
            label={`Applicant`}
            labelClassName="bold"
          />
          {aquaInformation.hasCoApp && (
            <Field
              component={Checkbox}
              name="coApplicantAquaTerms"
              id="coApplicantAquaTerms"
              tooltipText={AUTHORIZE_TOOLTIP_TEXT}
              label={`Co-Applicant`}
              labelClassName="bold"
            />
          )}
        </Col>
      </Row>
      <Button
        type="submit"
        title="Find the best financing option"
        disabled={aquaInformation.submited}
      />
    </Form>
  );

  const ApplicationReviewFormFormik = withFormik({
    mapPropsToValues: () => ({
      applicantMicrofTerms: false,
      coApplicantMicrofTerms: false,
      applicantAquaTerms: false,
      coApplicantAquaTerms: false,
    }),

    validationSchema: Yup.object({
      applicantMicrofTerms: Yup.boolean().oneOf([true], 'Please check to continue.'),
      coApplicantMicrofTerms: aquaInformation.hasCoApp
        ? Yup.boolean().oneOf([true], 'Please check to continue.')
        : Yup.boolean(),
      applicantAquaTerms: Yup.boolean().oneOf([true], 'Please check to continue.'),
      coApplicantAquaTerms: aquaInformation.hasCoApp
        ? Yup.boolean().oneOf([true], 'Please check to continue.')
        : Yup.boolean(),
    }),

    handleSubmit: () => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Find the best financing option',
        did,
        dealerUserId,
      });
      dispatchChangeValue('submited', true);
      const softPullRequest = {
        email: softPull.softPullResponse.email || null,
        // password: null,
        borrowedAmount: Math.floor(
          softPull.softPullResponse.borrowedAmount ||
            pqi.borrowedAmount ||
            aquaInformation.borrowedAmount,
        ),
        firstName: softPull.softPullResponse.firstName || null,
        lastName: softPull.softPullResponse.lastName || null,
        middleName: softPull.softPullResponse.middleName || null,
        phoneNumber: softPull.softPullResponse.phoneNumber || null,
        street: softPull.softPullResponse.street || null,
        city: softPull.softPullResponse.city || null,
        zipcode: softPull.softPullResponse.zipcode || null,
        state: softPull.softPullResponse.state || null,
        applicantStreet: applicantAddress ? applicantAddress.street : null,
        applicantCity: applicantAddress ? applicantAddress.city : null,
        applicantZip: applicantAddress ? applicantAddress.zip : null,
        applicantState: applicantAddress ? applicantAddress.state : null,
        birthday: softPull.softPullResponse.birthday || null,
        ssnLastDigits: softPull.softPullResponse.ssnLastDigits || null,
        airConditioningSystem: softPull.softPullResponse.airConditioningSystem,
        isHomeowner: softPull.softPullResponse.isHomeowner || pqi.isHomeowner,
        dealerUserId: dealerUserId || null,
        prequalificationId: softPull.softPullResponse.prequalificationId || null,
        ownOrRent: aquaInformation.ownOrRent || null,
        monthlyPayment: aquaInformation.hvacMonthlyPayment,
        homeValue: aquaInformation.homeValue || null,
        installmentDate: aquaInformation.hvacInstallmentDate || null,
        idType: aquaInformation.appIdType || null,
        idNumber: aquaInformation.appIdNumber || null,
        idIssueDate: aquaInformation.appIdIssueDate || null,
        idExpirationDate: aquaInformation.appIdExpirationDate || null,
        ssn: aquaInformation.appFullSsn,
        employerType: incomeSource[
          Object.keys(incomeSource).find(
            key => incomeSource[key].value === aquaInformation.appIncomeSource,
          )
        ]
          ? incomeSource[
              Object.keys(incomeSource).find(
                key => incomeSource[key].value === aquaInformation.appIncomeSource,
              )
            ].aquaValue
          : null,
        employerName: aquaInformation.appEmployerName || null,
        employmentPosition: aquaInformation.appEmploymentPosition || null,
        employmentYears: aquaInformation.appEmploymentYears,
        employmentMonths: aquaInformation.appEmploymentMonths || 1,
        employerPhoneNumber: aquaInformation.appEmployerPhoneNumber || null,
        appGrossAnnualIncome: aquaInformation.appSalaryIncome || null,
        otherIncomeSource: aquaInformation.appOtherSourcesOfIncome || null,
        otherIncomeSourceValue: aquaInformation.appAdditionalIncome || null,

        relationshipWithCoapplicant: aquaInformation.hasCoApp
          ? aquaInformation.appCoAppRelationship
          : null,
        coapplicantFirstName: aquaInformation.hasCoApp ? aquaInformation.coAppFirstName : null,
        coapplicantLastName: aquaInformation.hasCoApp ? aquaInformation.coAppLastName : null,
        coapplicantMiddleName: aquaInformation.hasCoApp ? aquaInformation.coAppMiddleInitial : null,
        coapplicantPhoneNumber: aquaInformation.hasCoApp ? aquaInformation.coAppPhoneNumber : null,
        coapplicantEmail: aquaInformation.hasCoApp ? aquaInformation.coAppEmail : null,
        coapplicantEmployerType: aquaInformation.hasCoApp
          ? incomeSource[
              Object.keys(incomeSource).find(
                key => incomeSource[key].value === aquaInformation.coAppIncomeSource,
              )
            ]
            ? incomeSource[
                Object.keys(incomeSource).find(
                  key => incomeSource[key].value === aquaInformation.coAppIncomeSource,
                )
              ].aquaValue
            : null
          : null,
        coapplicantEmployerName: aquaInformation.hasCoApp
          ? aquaInformation.coAppEmployerName
          : null,
        coapplicantEmploymentPosition: aquaInformation.hasCoApp
          ? aquaInformation.coAppEmploymentPosition
          : null,
        coapplicantEmploymentYears: aquaInformation.hasCoApp
          ? aquaInformation.coAppEmploymentYears
          : null,
        coapplicantEmploymentMonths: aquaInformation.hasCoApp
          ? aquaInformation.coAppEmploymentMonths || 1
          : null,
        coapplicantEmployerPhoneNumber: aquaInformation.hasCoApp
          ? aquaInformation.coAppEmployerPhoneNumber
          : null,
        coAppGrossAnnualIncome: aquaInformation.hasCoApp
          ? aquaInformation.coAppSalaryIncome || null
          : null,
        coapplicantOtherIncomeSource: aquaInformation.hasCoApp
          ? aquaInformation.coAppOtherSourcesOfIncome
          : null,
        coapplicantOtherIncomeSourceValue: aquaInformation.hasCoApp
          ? aquaInformation.coAppAdditionalIncome || null
          : null,
        coapplicantStreet: aquaInformation.hasCoApp ? aquaInformation.coAppStreet : null,
        coapplicantCity: aquaInformation.hasCoApp ? aquaInformation.coAppCity : null,
        coapplicantZipcode: aquaInformation.hasCoApp ? aquaInformation.coAppZipCode : null,
        coapplicantState: aquaInformation.hasCoApp ? aquaInformation.coAppState : null,
        coapplicantBirthday: aquaInformation.hasCoApp ? aquaInformation.coAppDoB : null,
        coapplicantSsn: aquaInformation.hasCoApp ? aquaInformation.coAppFullSsn : null,
        coapplicantIdType: aquaInformation.hasCoApp ? aquaInformation.coAppIdType : null,
        coapplicantIdNumber: aquaInformation.hasCoApp ? aquaInformation.coAppIdNumber : null,
        coapplicantIdIssueDate: aquaInformation.hasCoApp ? aquaInformation.coAppIdIssueDate : null,
        coapplicantIdExpirationDate: aquaInformation.hasCoApp
          ? aquaInformation.coAppIdExpirationDate
          : null,
        driversLicenseState:
          aquaInformation.appIdType === 'D' ? aquaInformation.appDriversLicenseState || null : null,
        coapplicantDriversLicenseState: aquaInformation.hasCoApp
          ? aquaInformation.coAppIdType === 'D'
            ? aquaInformation.coAppDriversLicenseState || null
            : null
          : null,

        county: '',
        selectedFinancier: selectedFinancierEnum.AQUA_FINANCE.value,
        emulatorId: emulatorId,
        track_id: track_id,
        aquaFinanceAssigned: aquaFinanceAssigned ? aquaFinanceAssigned : false,
      };
      dispatchSubmitMindfire(mindfireRequest);
      dispatchSubmitAquaApplication(softPullRequest, AquaFlowPageNumber.AquaApplicationResult, app);
    },
  })(ApplicationReviewForm);

  return (
    <Container>
      <Row>
        {!app ? (
          <BackBtn
            changePage={dispatchChangePage}
            page={
              aquaInformation.hasCoApp
                ? AquaFlowPageNumber.CoAppAdditionalInfo
                : AquaFlowPageNumber.AppOtherIncome
            }
          />
        ) : (
          <Col sm="1" />
        )}
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <Row className="review">
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col xs="8" lg="9">
                  <h5>The HVAC job</h5>
                </Col>
                {!app ? (
                  <Col xs="4" lg="3">
                    <Button
                      className="edit"
                      clickHandler={() => editPage(AquaFlowPageNumber.HvacDetails)}
                      title="Edit"
                    />
                  </Col>
                ) : null}
              </Row>
              <Row>
                <Col xs="6">
                  <b>Monthly Payment</b>
                  <NumberFormat
                    value={aquaInformation.hvacMonthlyPayment}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={'$'}
                    displayType="text"
                  />
                </Col>
                <Col xs="6">
                  <b>Home Value</b>
                  <NumberFormat
                    value={aquaInformation.homeValue}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={'$'}
                    displayType="text"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <b>Your home</b>
                  {aquaInformation.ownOrRent === 'Own' ? 'Own' : 'Rent'}
                </Col>
                <Col xs="6">
                  <b>Installation Date</b>
                  {aquaInformation.hvacInstallmentDate}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="review">
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col xs="8" lg="9">
                  <h5>Applicant’s Information</h5>
                </Col>
                {!app ? (
                  <Col xs="4" lg="3">
                    <Button
                      className="edit"
                      clickHandler={() => editPage(AquaFlowPageNumber.AppIdentificationInfo)}
                      title="Edit"
                    />
                  </Col>
                ) : null}
              </Row>
              <Row>
                <Col xs="6">
                  <b>Name</b>
                  {(pqi.firstName ? pqi.firstName + ' ' : '') +
                    (pqi.middleName ? pqi.middleName + ' ' : '') +
                    (pqi.lastName || '')}
                </Col>
                <Col xs="6">
                  <b>Phone Number</b>
                  {!!pqi.phoneNumber
                    ? pqi.phoneNumber.length > 6
                      ? '(' +
                        pqi.phoneNumber.substring(0, 3) +
                        ') ' +
                        pqi.phoneNumber.substring(3, 6) +
                        '-' +
                        pqi.phoneNumber.substring(6)
                      : pqi.phoneNumber
                    : 'N/A'}
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <b>Email Address</b>
                  <span className="text-break">{pqi.email || 'N/A'}</span>
                </Col>
                <Col xs="6">
                  <b>Date of Birth</b>
                  {pqi.birthday || 'N/A'}
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <b>Full SSN</b>
                  {aquaInformation.appFullSsn
                    ? 'xxxxx' + aquaInformation.appFullSsn.slice(5, 9)
                    : 'N/A'}
                </Col>
                <Col xs="6">
                  <b>
                    Photo ID Information (
                    {Object.keys(idTypes).map(key =>
                      idTypes[key].value === aquaInformation.appIdType ? idTypes[key].name : null,
                    )}
                    )
                  </b>
                  <span className="text-break">{aquaInformation.appIdNumber}</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <b>Applicant’s Address</b>
                  {(pqi.street ? pqi.street + ', ' : '') +
                    (pqi.city ? pqi.city + ', ' : '') +
                    (pqi.state ? pqi.state + ', ' : '') +
                    (pqi.zipcode || '')}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="review">
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col xs="8" lg="9">
                  <h5>Applicant’s Income (or Benefits) Information</h5>
                </Col>
                {!app ? (
                  <Col xs="4" lg="3">
                    <Button
                      className="edit"
                      clickHandler={() => editPage(AquaFlowPageNumber.AppEmploymentDetails)}
                      title="Edit"
                    />
                  </Col>
                ) : null}
              </Row>
              {aquaInformation.isEmployed === true ? (
                <React.Fragment>
                  <Row>
                    <Col xs="6">
                      <b>Employer Name</b>
                      {aquaInformation.appEmployerName}
                    </Col>
                    <Col xs="6">
                      <b>Employment Position</b>
                      {aquaInformation.appEmploymentPosition}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">
                      <b>Employment Time</b>
                      {aquaInformation.appEmploymentYears +
                        ' Year(s)' +
                        (aquaInformation.appEmploymentMonths &&
                        aquaInformation.appEmploymentMonths !== '0'
                          ? ' and ' + aquaInformation.appEmploymentMonths + ' Month(s)'
                          : '')}
                    </Col>
                    <Col xs="6">
                      <b>Employer Phone Number</b>
                      {!!aquaInformation.appEmployerPhoneNumber
                        ? aquaInformation.appEmployerPhoneNumber.length > 6
                          ? '(' +
                            aquaInformation.appEmployerPhoneNumber.substring(0, 3) +
                            ') ' +
                            aquaInformation.appEmployerPhoneNumber.substring(3, 6) +
                            '-' +
                            aquaInformation.appEmployerPhoneNumber.substring(6)
                          : aquaInformation.appEmployerPhoneNumber
                        : 'N/A'}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">
                      <b>Income (or Benefits) Source</b>
                      {Object.keys(incomeSource).map(key =>
                        incomeSource[key].value === aquaInformation.appIncomeSource
                          ? incomeSource[key].name
                          : null,
                      )}
                    </Col>
                    <Col xs="6">
                      <b>Salary Income</b>
                      <NumberFormat
                        value={aquaInformation.appSalaryIncome}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        prefix={'$'}
                        displayType="text"
                      />
                    </Col>
                  </Row>
                </React.Fragment>
              ) : aquaInformation.appIncomeSource ? (
                <Row>
                  <Col xs="6">
                    <b>Income (or Benefits) Source</b>
                    {Object.keys(incomeSource).map(key =>
                      incomeSource[key].value === aquaInformation.appIncomeSource
                        ? incomeSource[key].name
                        : null,
                    )}
                  </Col>
                  <Col xs="6">
                    <b>
                      {Object.keys(incomeSource).map(key =>
                        incomeSource[key].value === aquaInformation.appIncomeSource
                          ? incomeSource[key].name
                          : null,
                      )}{' '}
                      Income
                    </b>
                    <NumberFormat
                      value={aquaInformation.appSalaryIncome}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix={'$'}
                      displayType="text"
                    />
                  </Col>
                </Row>
              ) : null}
              {aquaInformation.appOtherSourcesOfIncome ? (
                <Row>
                  <Col xs="6">
                    <b>Other Income Source</b>
                    {Object.keys(otherSourcesOfIncome).map(key =>
                      otherSourcesOfIncome[key].value === aquaInformation.appOtherSourcesOfIncome
                        ? otherSourcesOfIncome[key].name
                        : null,
                    )}
                  </Col>
                  <Col xs="6">
                    <b>Your Total Income</b>
                    <NumberFormat
                      value={aquaInformation.appAdditionalIncome}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix={'$'}
                      displayType="text"
                    />
                  </Col>
                </Row>
              ) : null}
            </Col>
          </Row>
          <Row className="review">
            <Col>
              <Row className="align-items-center justify-content-between">
                <Col xs="8" lg="9">
                  <h5>Co-Applicant’s Information</h5>
                </Col>
                {!app ? (
                  <Col xs="4" lg="3">
                    <Button
                      className="edit"
                      clickHandler={() => editPage(AquaFlowPageNumber.HasCoApplicant)}
                      title={aquaInformation.hasCoApp ? 'Edit' : 'Add'}
                    />
                  </Col>
                ) : null}
              </Row>
              {aquaInformation.hasCoApp ? (
                <React.Fragment>
                  <Row>
                    <Col xs="6">
                      <b>Name</b>
                      {(aquaInformation.coAppFirstName
                        ? aquaInformation.coAppFirstName + ' '
                        : '') +
                        (aquaInformation.coAppMiddleInitial
                          ? aquaInformation.coAppMiddleInitial + ' '
                          : '') +
                        (aquaInformation.coAppLastName || '')}
                    </Col>
                    <Col xs="6">
                      <b>Phone Number</b>
                      {!!aquaInformation.coAppPhoneNumber
                        ? aquaInformation.coAppPhoneNumber.length > 6
                          ? '(' +
                            aquaInformation.coAppPhoneNumber.substring(0, 3) +
                            ') ' +
                            aquaInformation.coAppPhoneNumber.substring(3, 6) +
                            '-' +
                            aquaInformation.coAppPhoneNumber.substring(6)
                          : aquaInformation.coAppPhoneNumber
                        : 'N/A'}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">
                      <b>Email Address</b>
                      {aquaInformation.coAppEmail || 'N/A'}
                    </Col>
                    <Col xs="6">
                      <b>Date of Birth</b>
                      {aquaInformation.coAppDoB || 'N/A'}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">
                      <b>Full SSN</b>
                      {aquaInformation.coAppFullSsn
                        ? 'xxxxx' + aquaInformation.coAppFullSsn.slice(5, 9)
                        : 'N/A'}
                    </Col>
                    <Col xs="6">
                      <b>
                        Photo ID Information (
                        {Object.keys(idTypes).map(key =>
                          idTypes[key].value === aquaInformation.coAppIdType
                            ? idTypes[key].name
                            : null,
                        )}
                        )
                      </b>
                      <span className="text-break">{aquaInformation.coAppIdNumber || 'N/A'}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <b>Co-Applicant’s Address</b>
                      {(aquaInformation.coAppStreet ? aquaInformation.coAppStreet + ', ' : '') +
                        (aquaInformation.coAppCity ? aquaInformation.coAppCity + ', ' : '') +
                        (aquaInformation.coAppState ? aquaInformation.coAppState + ', ' : '') +
                        (aquaInformation.coAppZipCode || '')}
                    </Col>
                  </Row>
                </React.Fragment>
              ) : null}
            </Col>
          </Row>
          {aquaInformation.hasCoApp ? (
            <Row className="review">
              <Col>
                <Row className="align-items-center justify-content-between">
                  <Col xs="8" lg="9">
                    <h5>Co-Applicant’s Income (or Benefits) Information</h5>
                  </Col>
                  {!app ? (
                    <Col xs="4" lg="3">
                      <Button
                        className="edit"
                        clickHandler={() => editPage(AquaFlowPageNumber.CoAppEmploymentDetails)}
                        title="Edit"
                      />
                    </Col>
                  ) : null}
                </Row>
                {aquaInformation.isCoAppEmployed === true ? (
                  <>
                    <Row>
                      <Col xs="6">
                        <b>Employer Name</b>
                        {aquaInformation.coAppEmployerName}
                      </Col>
                      <Col xs="6">
                        <b>Employment Position</b>
                        {aquaInformation.coAppEmploymentPosition}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6">
                        <b>Employment Time</b>
                        {aquaInformation.coAppEmploymentYears +
                          ' Year(s)' +
                          (aquaInformation.coAppEmploymentMonths &&
                          aquaInformation.coAppEmploymentMonths !== '0'
                            ? ' and ' + aquaInformation.coAppEmploymentMonths + ' Month(s)'
                            : '')}
                      </Col>
                      <Col xs="6">
                        <b>Employer Phone Number</b>
                        {!!aquaInformation.coAppEmployerPhoneNumber
                          ? aquaInformation.coAppEmployerPhoneNumber.length > 6
                            ? '(' +
                              aquaInformation.coAppEmployerPhoneNumber.substring(0, 3) +
                              ') ' +
                              aquaInformation.coAppEmployerPhoneNumber.substring(3, 6) +
                              '-' +
                              aquaInformation.coAppEmployerPhoneNumber.substring(6)
                            : aquaInformation.coAppEmployerPhoneNumber
                          : 'N/A'}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6">
                        <b>Income (or Benefits) Source</b>
                        {Object.keys(incomeSource).map(key =>
                          incomeSource[key].value === aquaInformation.coAppIncomeSource
                            ? incomeSource[key].name
                            : null,
                        )}
                      </Col>
                      <Col xs="6">
                        <b>Salary Income</b>
                        <NumberFormat
                          value={aquaInformation.coAppSalaryIncome}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                          decimalScale={2}
                          prefix={'$'}
                          displayType="text"
                        />
                      </Col>
                    </Row>
                  </>
                ) : aquaInformation.coAppIncomeSource ? (
                  <Row>
                    <Col xs="6">
                      <b>Income (or Benefits) Source</b>
                      {Object.keys(incomeSource).map(key =>
                        incomeSource[key].value === aquaInformation.coAppIncomeSource
                          ? incomeSource[key].name
                          : null,
                      )}
                    </Col>
                    <Col xs="6">
                      <b>
                        {Object.keys(incomeSource).map(key =>
                          incomeSource[key].value === aquaInformation.coAppIncomeSource
                            ? incomeSource[key].name
                            : null,
                        )}{' '}
                        Income
                      </b>
                      <NumberFormat
                        value={aquaInformation.coAppSalaryIncome}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        prefix={'$'}
                        displayType="text"
                      />
                    </Col>
                  </Row>
                ) : null}
                {aquaInformation.coAppOtherSourcesOfIncome ? (
                  <Row>
                    <Col xs="6">
                      <b>Other Income Source</b>
                      {Object.keys(otherSourcesOfIncome).map(key =>
                        otherSourcesOfIncome[key].value ===
                        aquaInformation.coAppOtherSourcesOfIncome
                          ? otherSourcesOfIncome[key].name
                          : null,
                      )}
                    </Col>
                    <Col xs="6">
                      <b>Your Total Income</b>
                      <NumberFormat
                        value={aquaInformation.coAppAdditionalIncome}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        prefix={'$'}
                        displayType="text"
                      />
                    </Col>
                  </Row>
                ) : null}
              </Col>
            </Row>
          ) : null}
          <ApplicationReviewFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  aquaFinanceAssigned: state.auth.aquaFinanceAssigned,
  applicantAddress: state.aquaInformation.applicantAddress,
  aquaInformation: state.aquaInformation,
  pqi: state.preQualificationInformation,
  softPull: state.softPull,
  app: state.softPull.applicationSubmitDTO,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  emulatorId: !!state.auth.user.user ? state.auth.user.user.emulatorId : null,
  track_id: state.auth.track_id,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) => dispatch(changeAquaInfoValue(key, value)),
  dispatchSubmitAquaApplication: (params, page, app) =>
    dispatch(submitAquaApplication(params, page, app)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

ApplicationReview.propTypes = {
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
  dispatchSubmitSoftPullAquaApplication: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApplicationReview);
