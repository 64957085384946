import React from 'react';
import { Col, Row } from 'reactstrap';

const Disclosure = () => {
  return (
    <Row className="mt-10 disclosure">
      <Col>
        <span>© 2006 - 2022 GreenSky, LLC. All Rights Reserved.</span> | GreenSky<sup>®</sup> and
        GreenSky Patient Solutions<sup>®</sup> are loan program names for certain consumer credit
        plans extended by participating lenders to borrowers for the purchase of goods and/or
        services from participating merchants/providers. Participating lenders are federally
        insured, federal and state chartered financial institutions providing credit without regard
        to age, race, color, religion, national origin, gender or familial status. GreenSky
        <sup>®</sup> and GreenSky Patient Solutions<sup>®</sup> are registered trademarks of
        GreenSky, LLC. GreenSky Servicing, LLC services the loans on behalf of participating
        lenders. NMLS #1416362. GreenSky, LLC and GreenSky Servicing, LLC are subsidiaries of
        Goldman Sachs Bank USA. Loans originated by Goldman Sachs are issued by Goldman Sachs Bank
        USA, Salt Lake City Branch
        <img
          className="disclosure-image"
          src="https://cms.uat.greensky.com/images/home-lender.svg"
          alt="Equal Opportunity Lender"
        />
      </Col>
    </Row>
  );
};

export default Disclosure;
