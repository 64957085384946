import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import CurrencyFormat from '../../../../components/UI/Form/NumberFormat/CurrencyFormat';
import { reactGAEvent, reactGAPageview } from '../../../../helper';
import {
  changePage,
  changeFoundationInfoValue,
  submitMindfire,
} from '../../../../redux/actions/index';
import { FoundationFlowPageNumber } from './FoundationFlow';
import Select from '../../../../components/UI/Form/Select/Select';
import loanOptionEnum from '../../../../assets/js/LoanOptionEnum';
import AirConditioningSystemEnum from '../../../../assets/js/AirConditioningSystemEnum';
import {
  DOWN_PAYMENT_TOOLTIP_TEXT,
  LOAN_OPTION_TOOLTIP_TEXT,
  REQUESTEDLOANAMOUNT_TOOLTIP_TEXT,
  SALE_AMOUNT_TOOLTIP_TEXT,
} from '../../../../constants/tooltipTexts';

const LoanAmount = props => {
  const {
    email,
    dispatchChangePage,
    dispatchChangeFoundationInfoValue,
    children,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    saleAmount,
    downPayment,
    loanAmountRequested,
    loanOption,
    airConditioningSystem,
    isReview,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_fdloanamount',
      trackerNames: [],
      title: 'ca_fdloanamount',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const LoanAmountForm = ({ values, setFieldValue }) => (
    <Form>
      <Field
        component={CurrencyFormat}
        label="Sale Amount"
        name="saleAmount"
        id="saleAmount"
        tooltipText={SALE_AMOUNT_TOOLTIP_TEXT}
        handleChange={e => setFieldValue('loanAmountRequested', e.floatValue - values.downPayment)}
      />
      <Field
        component={CurrencyFormat}
        label="Down Payment"
        name="downPayment"
        id="downPayment"
        tooltipText={DOWN_PAYMENT_TOOLTIP_TEXT}
        handleChange={e => setFieldValue('loanAmountRequested', values.saleAmount - e.floatValue)}
      />
      <Field
        component={CurrencyFormat}
        label="Loan Amount Requested"
        name="loanAmountRequested"
        id="loanAmountRequested"
        tooltipText={REQUESTEDLOANAMOUNT_TOOLTIP_TEXT}
      />
      <Field
        component={Select}
        label="Loan Option"
        name="loanOption"
        id="loanOption"
        tooltipText={LOAN_OPTION_TOOLTIP_TEXT}
        type="select"
        selectValues={loanOptionEnum}
        selectFirstName="Select Loan Option"
      />
      <Button type="submit" title={isReview ? 'Review' : 'Next'} />
    </Form>
  );

  const LoanAmountFormFormik = withFormik({
    mapPropsToValues: () => ({
      saleAmount: saleAmount || '',
      downPayment: downPayment || 0,
      loanAmountRequested: loanAmountRequested || 0,
      loanOption: loanOption || '',
    }),

    validationSchema: Yup.object({
      saleAmount: Yup.number()
        .moreThan(1499, `Amount should be at least $1500.`)
        .lessThan(100000, 'Amount too high.')
        .required('Please enter an amount.'),
      downPayment: Yup.number()
        .test({
          name: 'lowerThan',
          exclusive: false,
          message: 'Amount cannot be higher than Sale Amount.',
          test: function(value) {
            return value <= this.resolve(Yup.ref('saleAmount'));
          },
        })
        .lessThan(100000, 'Amount too high.')
        .required('Please enter an amount.'),
      loanAmountRequested: Yup.number()
        .moreThan(1499, `Amount should be at least $1500.`)
        .lessThan(100000, 'Amount too high.')
        .required('Please enter an amount.'),
      loanOption: Yup.string().required('Please select a Loan Option.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });
      dispatchChangeFoundationInfoValue('saleAmount', values.saleAmount);
      dispatchChangeFoundationInfoValue('downPayment', values.downPayment);
      dispatchChangeFoundationInfoValue('loanAmountRequested', values.loanAmountRequested);
      dispatchChangeFoundationInfoValue('loanOption', values.loanOption);
      dispatchChangePage(
        isReview
          ? FoundationFlowPageNumber.ApplicationReview
          : FoundationFlowPageNumber.AppIdentificationInfo,
      );
      dispatchSubmitMindfire({
        email: email,
        borrowedAmount: values.borrowedAmount,
        lastPage: '10010',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(LoanAmountForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={
            isReview
              ? FoundationFlowPageNumber.ApplicationReview
              : airConditioningSystem ===
                AirConditioningSystemEnum.AIR_CONDITION_OR_HEATING_SYSTEM.id
              ? FoundationFlowPageNumber.Consent
              : FoundationFlowPageNumber.HomeImprovementProductType
          }
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <LoanAmountFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  isReview: state.foundationInformation.isReview,
  saleAmount: state.foundationInformation.saleAmount,
  downPayment: state.foundationInformation.downPayment,
  loanAmountRequested: state.foundationInformation.loanAmountRequested,
  loanOption: state.foundationInformation.loanOption,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  airConditioningSystem: state.preQualificationInformation.airConditioningSystem,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeFoundationInfoValue: (key, value) =>
    dispatch(changeFoundationInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoanAmount);
