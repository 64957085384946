import React from 'react';
import { Button } from 'reactstrap';
import { getBaseURL } from '../../../config/settings';

export const baseURL = getBaseURL();
const SearchTable = props => {
  const { uploadedDocuments } = props;

  return (
    <table className="table table-hover table-striped mt-2">
      <thead>
        <tr>
          <th>Dealer Name</th>
          <th>File Name</th>
          <th>Insert Date</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {uploadedDocuments && uploadedDocuments.total > 0 ? (
          uploadedDocuments.uploadedDocuments.map((uploadedDocument, index) => (
            <tr key={index}>
              <td>{uploadedDocument.dealerName}</td>
              <td>{uploadedDocument.fileName}</td>
              <td>{uploadedDocument.createdDate}</td>
              <td>
                <Button
                  type="reset"
                  color="primary"
                  className="rounded-pill"
                  onClick={() => {
                    window.open(
                      `${baseURL}/api/soft_pull/upload-document/${
                        uploadedDocument.uploadDocumentId
                      }`,
                      '_blank',
                    );
                  }}
                >
                  Download
                </Button>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="4">No uploaded document available...</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default SearchTable;
