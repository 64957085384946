import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import CustomInput from '../../../components/UI/Form/Input/CustomInput';
import {
  COAPPEMAIL_TOOLTIP_TEXT,
  COAPPFIRSTNAME_TOOLTIP_TEXT,
  COAPPLASTNAME_TOOLTIP_TEXT,
  COAPPMIDDLEINITIAL_TOOLTIP_TEXT,
} from '../../../constants/tooltipTexts';
import { isNoreplyEmail, reactGAEvent, reactGAPageview } from '../../../helper';
import { changePage, submitMindfire, changeWellsFargoValue } from '../../../redux/actions';
import { WellsFargoFlowPageNumber } from '../WellsFargoFlow';

const CoAppDetails = props => {
  const {
    email,
    dispatchChangePage,
    wellsFargoInformation,
    children,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    dispatchChangeWellsFargoValue,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/wf_coappinfo1',
      trackerNames: [],
      title: 'wf_coappinfo1',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const handleSubmit = values => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Next',
      did,
      dealerUserId,
    });
    dispatchChangeWellsFargoValue('coAppFirstName', values.coAppFirstName.trim());
    dispatchChangeWellsFargoValue('coAppMiddleInitial', values.coAppMiddleInitial.trim());
    dispatchChangeWellsFargoValue('coAppLastName', values.coAppLastName.trim());
    dispatchChangeWellsFargoValue('coAppEmail', values.coAppEmail);
    dispatchChangePage(WellsFargoFlowPageNumber.CoAppAddress);
    dispatchSubmitMindfire({
      email: email,
      lastPage: '5009',
      dealerUserId: dealerUserId,
      did: did,
    });
  };

  const CoAppDetailsForm = () => (
    <Form>
      <Field
        component={CustomInput}
        label="Co-Applicant's First Name"
        name="coAppFirstName"
        id="coAppFirstName"
        tooltipText={COAPPFIRSTNAME_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={CustomInput}
        label="Co-Applicant's Middle Initial (If Applicable – Optional Field)"
        name="coAppMiddleInitial"
        id="coAppMiddleInitial"
        tooltipText={COAPPMIDDLEINITIAL_TOOLTIP_TEXT}
        type="text"
        maxLength={1}
      />
      <Field
        component={CustomInput}
        label="Co-Applicant's Last Name"
        name="coAppLastName"
        id="coAppLastName"
        tooltipText={COAPPLASTNAME_TOOLTIP_TEXT}
        type="text"
      />
      <Field
        component={CustomInput}
        label="Co-Applicant's Email Address"
        name="coAppEmail"
        id="coAppEmail"
        tooltipText={COAPPEMAIL_TOOLTIP_TEXT}
        type="email"
      />
      <p className="small-text">
        Please enter the email address for the co-applicant (we recommend a different email address
        than the primary applicant's email address). If you need to create an email address,{' '}
        <a
          href="https://edu.gcfglobal.org/en/gmail/setting-up-a-gmail-account/1/"
          target="_blank"
          rel="noopener noreferrer"
        >
          click here
        </a>{' '}
        for instructions.
      </p>

      <Button type="submit" title="Next" />
    </Form>
  );

  const CoAppDetailsFormFormik = withFormik({
    mapPropsToValues: () => ({
      coAppFirstName: wellsFargoInformation.coAppFirstName || '',
      coAppMiddleInitial: wellsFargoInformation.coAppMiddleInitial || '',
      coAppLastName: wellsFargoInformation.coAppLastName || '',
      coAppEmail: wellsFargoInformation.coAppEmail || '',
    }),

    validationSchema: Yup.object({
      coAppFirstName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .required("Please enter the Co-Applicant's first name."),
      coAppLastName: Yup.string()
        .matches(/^(?=.*[a-zA-Z])([a-zA-Z]+)$/, 'Special characters are not allowed.')
        .required("Please enter the Co-Applicant's last name."),
      coAppEmail: Yup.string()
        .email('Please enter a valid email.')
        .required('Please enter an email.')
        .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),
    }),

    handleSubmit,
  })(CoAppDetailsForm);

  return (
    <Container>
      <Row>
        <BackBtn changePage={dispatchChangePage} page={WellsFargoFlowPageNumber.HasCoApplicant} />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <CoAppDetailsFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  currentFlow: state.navi.flowName,
  wellsFargoInformation: state.wellsFargoInformation,
  email: state.wellsFargoInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeWellsFargoValue: (key, value) => dispatch(changeWellsFargoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

CoAppDetails.propTypes = {
  wellsFargoInformation: PropTypes.object,
  dispatchChangePage: PropTypes.func,
  dispatchChangeWellsFargoValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CoAppDetails);
