import { Field, Form, withFormik } from 'formik';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import PrimaryUsersRadioButtons from '../../../components/UI/Form/Radio/PrimaryUsersRadioButtons';
import { searchUsers, setPrimaryUser } from '../../../redux/actions';

const EditPrimaryUsers = props => {
  const { dealer, history, searchUsers, setPrimaryUser } = props;

  const users = dealer && dealer.users ? dealer.users : null;
  const oldPrimaryUser = users?.length > 0 ? users.find(u => u.primary === true)?.id : null;

  const orderedUsers = users
    ? users.sort((a, b) => (a.primary < b.primary ? 1 : b.primary < a.primary ? -1 : 0))
    : [];

  const search = queryString.parse(window.location.search).id;
  const [selected, setSelected] = useState(null);

  const clickHandler = value => {};

  let optionsUser = [];

  if (selected) {
    optionsUser =
      orderedUsers.length > 0
        ? orderedUsers.map(opt => ({
            key: opt.id,
            id: opt.id,
            value: opt.id,
            checked: selected === opt.id ? true : false,
            name: `${opt.firstName} ${opt.lastName}`,
          }))
        : optionsUser;
  } else {
    optionsUser =
      orderedUsers.length > 0
        ? orderedUsers.map(opt => ({
            key: opt.id,
            id: opt.id,
            value: opt.id,
            checked: oldPrimaryUser === opt.id ? true : false,
            name: `${opt.firstName} ${opt.lastName}`,
          }))
        : optionsUser;
  }

  const UsersOptionsForm = ({ values }) => {
    return (
      <Form noValidate>
        <Row>
          <Col>
            <table className="table table-hover table-striped">
              <thead>
                <tr>
                  <th>User</th>
                  <th>Primary</th>
                </tr>
              </thead>
              <tbody>
                <Field
                  optionsComponent={true}
                  component={PrimaryUsersRadioButtons}
                  name="primaryUsers"
                  options={optionsUser}
                  clickHandler={setSelected}
                />
              </tbody>
            </table>
          </Col>
        </Row>

        <Row>
          <Col className="text-right">
            <Button
              type="reset"
              color="secondary"
              className="rounded-pill"
              outline
              onClick={() => {
                history.push('/portal/manage-primary-users');
              }}
            >
              Cancel
            </Button>
            <Button type="submit" color="primary" className="rounded-pill" onClick={() => {}}>
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  const UsersOptionsFormFormik = withFormik({
    mapPropsToValues: () => ({
      primaryUsers: selected || '',
    }),

    validationSchema: Yup.object({}),

    handleSubmit: async values => {
      const data = {
        newPrimaryUserId: values.primaryUsers ? values.primaryUsers : null,
        oldPrimaryUserId: oldPrimaryUser || null,
      };

      if (values.primaryUsers) {
        setPrimaryUser(data, history, window.location.href);
      }
    },
  })(UsersOptionsForm);

  useEffect(() => {
    if (search) {
      searchUsers(search);
    }
    // eslint-disable-next-line
  }, []);

  return users && users.length > 0 ? (
    <Col>
      <h2 id="panelTitle">{dealer?.dealerName}</h2>
      <div className="panel">
        <div className="panel-body larger">
          <UsersOptionsFormFormik />
        </div>
      </div>
    </Col>
  ) : (
    <Col>
      <h2 id="panelTitle">{dealer?.dealerName}</h2>
      <div className="panel">
        <div className="panel-body larger">
          <Row>
            <Col>
              <span>{users?.length === 0 ? 'No users.' : 'Loading users...'}</span>
            </Col>
          </Row>
          <Row>
            <Col className="text-right">
              <Button
                type="reset"
                color="secondary"
                className="rounded-pill"
                outline
                onClick={() => {
                  history.push('/portal/manage-primary-users');
                }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </Col>
  );
};

const mapStateToProps = state => ({
  dealer: state.softPull.usersSearchCriteriaResult,
});

const mapDispatchToProps = dispatch => ({
  searchUsers: (dealerId, searchOptions) => dispatch(searchUsers(dealerId, searchOptions)),
  setPrimaryUser: (data, history, url) => dispatch(setPrimaryUser(data, history, url)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(EditPrimaryUsers),
);
