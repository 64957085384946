import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import { reactGAPageview, reactGAEvent } from '../../../helper';
import {
  changePage,
  submitMindfire,
  resetState,
  changePageTitle,
  resetNewAppState,
} from '../../../redux/actions/index';
import { getPowerPayContractorURL, getDealerPortalURL } from '../../../config/settings';
import { getPowerPayStatus } from '../../../assets/js/ApplicationStatusEnum';
import { PowerPayFlowPageNumber } from './PowerPayFlow';
import Button from '../../../components/UI/Button/CustomButton';

const ApprovedRedirect = props => {
  const {
    email,
    dispatchChangePage,
    dealerUserId,
    did,
    dispatchSubmitMindfire,
    dispatchResetState,
    dispatchResetNewAppState,
    status,
    dispatchChangePageTitle,
    consumerId,
    user,
    history,
  } = props;

  const isUserLoggedIn = !did && user && user.dealerUser;
  const buttonTitle = isUserLoggedIn ? 'Return to Dashboard' : 'Return to Login Page';
  const returnUrl = isUserLoggedIn ? '/portal/dashboard' : getDealerPortalURL();

  useEffect(() => {
    dispatchChangePageTitle('Approved Application');
    reactGAPageview({
      path: '/ca_ppapprovedredirect',
      trackerNames: [],
      title: 'ca_ppapprovedredirect',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const mindFireRequest = {
    email: email,
    lastPage: '6021',
    dealerUserId: dealerUserId,
    did: did,
  };

  const handleAnchorClick = () => {
    dispatchSubmitMindfire(mindFireRequest);
    window.open(getPowerPayContractorURL(), '_blank');
  };

  const handleButtonClick = () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Done',
      did,
      dealerUserId,
    });
    dispatchSubmitMindfire(mindFireRequest);
    if (!isUserLoggedIn) {
      dispatchResetState(props);
      window.location.href = returnUrl;
    } else {
      dispatchResetNewAppState();
      history.push(returnUrl);
    }
  };

  const st = getPowerPayStatus(status);

  return (
    <Container>
      <Row className="justify-content-center">
        {consumerId ? (
          <Col sm="1" />
        ) : (
          <BackBtn
            changePage={dispatchChangePage}
            page={PowerPayFlowPageNumber.ApplicationResult}
          />
        )}
        <Col xs="auto" sm="11">
          <Row className="justify-content-center mt-2">
            <Col xs="auto">
              <h3 className="text-center">
                <b>{st ? st.description : 'UNKNOWN STATUS'}</b>
              </h3>
            </Col>
          </Row>
          <Row className="justify-content-center mt-2">
            <Col xs="auto">
              <h5>
                <b>Applicant</b>
              </h5>
            </Col>
          </Row>
          <Row className="justify-content-center mt-2">
            <b xs="auto">
              <h5 className="text-center">
                <b>
                  Please hand device back to the contractor to continue. The documents have not been
                  sent out. Product still needs to be selected.
                </b>
              </h5>
            </b>
          </Row>
          <Row className="justify-content-center mt-2">
            <Col xs="auto">
              <h5>
                <b>Contractor</b>
              </h5>
            </Col>
          </Row>
          <Row className="justify-content-center mt-2">
            <Col xs="auto">
              <h5 className="text-center">
                <b>
                  Please complete the PowerPay documents to create the opportunity for the applicant
                  at:{' '}
                  <a href="##" onClick={handleAnchorClick}>
                    <b>{getPowerPayContractorURL()}</b>
                  </a>
                </b>
              </h5>
            </Col>
          </Row>
          <Row className="justify-content-center mt-2">
            <Col xs="auto">
              <Button type="button" title={buttonTitle} clickHandler={handleButtonClick} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  email: state.powerPayInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  status: state.softPull.applicationSubmitDTO && state.softPull.applicationSubmitDTO.status,
  consumerId: state.auth.consumerId,
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchResetState: props => dispatch(resetState(props)),
  dispatchChangePageTitle: value => dispatch(changePageTitle(value)),
  dispatchResetNewAppState: () => dispatch(resetNewAppState()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ApprovedRedirect),
);
