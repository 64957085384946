import React from 'react';
import Loader from 'react-loader-spinner';
import { Row } from 'reactstrap';

import './NotFound.scss';

const NotFound = () => (
  <section id="content" className="finished">
    <div className="finish">
      <Row className="h-100 justify-content-center align-items-center no-gutters">
        <Loader type="Oval" color="#119c4a" height="226" width="226" className="loader" />
        <h2 className="w-50 position-absolute">404 Page Not Found!</h2>
      </Row>
    </div>
  </section>
);

export default NotFound;
