import React from 'react';

const DeclinedConsumerApplicationsTable = props => {
  const { declinedConsumerApplications } = props;

  return (
    <table className="table table-hover table-striped">
      <thead>
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Inserted Time</th>
          <th>Street</th>
          <th>City</th>
          <th>State</th>
          <th>Zipcode</th>
          <th>Phone Home</th>
          <th>Phone Mobile</th>
        </tr>
      </thead>
      <tbody>
        {declinedConsumerApplications.map((application, index) => (
          <tr key={index}>
            <td>{application.nameFirst}</td>
            <td>{application.nameLast}</td>
            <td>{new Date(application.insertedDate).toLocaleDateString()}</td>
            <td>{application.street}</td>
            <td>{application.city}</td>
            <td>{application.state}</td>
            <td>{application.zipcode}</td>
            <td>{application.homePhone}</td>
            <td>{application.mobilePhone}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DeclinedConsumerApplicationsTable;
