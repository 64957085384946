import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import EmptySection from '../../../components/Section/EmptySection';
import { contractorEnrollmentFlow } from '../../../redux/initialState';
import Flow from './ContractorEnrollment/_Flow';
import './index.scss';
import { setFlow, changeAuthValue, getDistributors, getDealer } from '../../../redux/actions/index';

const NewLender = ({
  state,
  history,
  contractorEnrollmentFlowCurrentPage,
  dispatchSetFlow,
  flowName,
  dispatchChangeAuthValue,
  gsi,
  dispatchGetDistributors,
  auth,
  dispatchGetDealer,
  ...props
}) => {
  if (!auth.user || !auth.user.dealerUser) {
    history.push('/login');
  }

  useEffect(() => {
    dispatchSetFlow(contractorEnrollmentFlow);
    dispatchGetDistributors();
    dispatchGetDealer(auth.user.dealerUser.dealer.dealerId, history);
    dispatchChangeAuthValue('dealerDTOCreateDealershipApplication', false);
    // eslint-disable-next-line
  }, []);

  return (
    <Col>
      <Row>
        <Col>
          <h1>Contractor Enrollment</h1>
        </Col>
      </Row>
      <Row className="panel">
        <Col className="panel-body larger">
          {auth.dealerDTO ? (
            <EmptySection Component={Flow[contractorEnrollmentFlowCurrentPage || 0]} {...state} />
          ) : null}
        </Col>
      </Row>
    </Col>
  );
};

const mapStateToProps = state => ({
  state,
  contractorEnrollmentFlowCurrentPage: state.navi.contractorEnrollmentFlowCurrentPage,
  flowName: state.navi.flowName,
  gsi: state.greenskyInformation.contractorEnrollment,
  auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetFlow: flow => dispatch(setFlow(flow)),
  dispatchChangeAuthValue: (key, value) => dispatch(changeAuthValue(key, value)),
  dispatchGetDistributors: () => dispatch(getDistributors()),
  dispatchGetDealer: (dealerId, history) => dispatch(getDealer(dealerId, history)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewLender);
