import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import USStateEnum from '../../../../assets/js/USStateEnum';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import CurrencyFormat from '../../../../components/UI/Form/NumberFormat/CurrencyFormat';
import { GROSS_ANNUAL_INCOME_TOOLTIP_TEXT } from '../../../../constants/tooltipTexts';
import { reactGAEvent, reactGAPageview } from '../../../../helper';
import {
  changePage,
  submitMindfire,
  changeGreenSkyApplicationInfoValue,
} from '../../../../redux/actions';
import Disclosure from '../Components/Disclosure';
import { AtWaterGreenSkyFlowPageNumber } from './GreenSkyFlow';

const GrossAnnualIncome = props => {
  const {
    email,
    appGrossAnnualIncome,
    dispatchChangePage,
    dispatchChangeGreenSkyApplicationInfoValue,
    children,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    isReview,
    state,
  } = props;

  const isStateOfWisconsin = USStateEnum.Wisconsin.value === state;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_gsappgrossincome',
      trackerNames: [],
      title: 'ca_gsappgrossincome',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const GrossAnnualIncomeForm = () => (
    <Form>
      <Field
        component={CurrencyFormat}
        label="Gross Annual Income"
        name="appGrossAnnualIncome"
        id="appGrossAnnualIncome"
        tooltipText={GROSS_ANNUAL_INCOME_TOOLTIP_TEXT}
      />
      <br />
      <p>
        <b>Examples: </b>Annual salary (before taxes), alimony, child support, investment income,
        social security, etc.
      </p>
      <p>
        <b>Notice: </b>Alimony, child support or separate maintenance income need not be revealed if
        you do not wish to have it considered as a basis for repaying this obligation.
      </p>
      <p>
        <b>Married WI Residents only: </b>If you are applying for an individual account and your
        spouse also is a Wisconsin resident, combine your and your spouse’s financial information.
      </p>
      <Button type="submit" title={isReview ? 'Review' : 'Next'} />
      <Disclosure />
    </Form>
  );

  const GrossAnnualIncomeFormFormik = withFormik({
    mapPropsToValues: () => ({
      appGrossAnnualIncome: appGrossAnnualIncome || '',
    }),

    validationSchema: Yup.object({
      appGrossAnnualIncome: Yup.number()
        .moreThan(999, `Amount should be at least $1000.`)
        .required('Please enter an amount.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: isReview ? 'Review' : 'Next',
        did,
        dealerUserId,
      });
      dispatchChangeGreenSkyApplicationInfoValue(
        'appGrossAnnualIncome',
        values.appGrossAnnualIncome,
      );
      dispatchChangePage(
        isReview
          ? AtWaterGreenSkyFlowPageNumber.Review
          : isStateOfWisconsin
          ? AtWaterGreenSkyFlowPageNumber.ApplicantMarried
          : AtWaterGreenSkyFlowPageNumber.HasCoApplicant,
      );
      dispatchSubmitMindfire({
        email: email,
        appGrossAnnualIncome: values.appGrossAnnualIncome,
        lastPage: '9013',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(GrossAnnualIncomeForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={
            isReview
              ? AtWaterGreenSkyFlowPageNumber.Review
              : AtWaterGreenSkyFlowPageNumber.AdditionalApplicantInformation
          }
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <GrossAnnualIncomeFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  isReview: state.greenSkyApplicationInformation.isReview,
  state: state.preQualificationInformation.state,
  appGrossAnnualIncome: state.greenSkyApplicationInformation.appGrossAnnualIncome,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeGreenSkyApplicationInfoValue: (key, value) =>
    dispatch(changeGreenSkyApplicationInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

GrossAnnualIncome.propTypes = {
  appGrossAnnualIncome: PropTypes.number,
  dispatchChangePage: PropTypes.func,
  dispatchChangeGreenSkyApplicationInfoValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GrossAnnualIncome);
