import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { Button, Col, Form, Input, Row } from 'reactstrap';
import ApplicationCategoryEnum from '../../../assets/js/ApplicationCategoryEnum';
import applicationStatusEnum, {
  allApplicationStatusEnum,
  getApplicationStatus,
  isGreenSkyApproved,
  isGreenSkyAssigned,
  isGreenSkyCancelled,
  isMosaicDeclined,
  isPowerPayDeclined,
  isStatusAquaDecline,
} from '../../../assets/js/ApplicationStatusEnum';
import messageRecipientEnum from '../../../assets/js/MessageRecipientEnum';
import {
  sendEmailToRelationshipManager,
  sendEmailToSalesPerson,
  changeTheme,
  changeGreenSkyApplicationInfoValue,
  getGreenSkyApplication,
  changeSoftPullValue,
  provideFundingInfo,
  getAtWaterGreenSkyApplication,
} from '../../../redux/actions';
import EmailModal from '../Modal/EmailModal';
import './Detail.scss';
import OuterContent from './OuterContent';
import { getIsOptimus, getIsPool, getIsRheem } from '../../../config/settings';
import { formatDate } from '../../../util/dateFormatter';
import selectedFinancierEnum from '../../../assets/js/SelectedFinancierEnum';
import ThemeEnum from '../../../assets/js/ThemeEnum';
import AddedByAPIEnum from '../../../assets/js/AddedByAPIEnum';
import ApplicationFtl from './OptimusFtlAppFlow/ApplicationFtl';
import CreateLoanDocuments from './OptimusFtlAppFlow/CreateLoanDocuments';
import CompleteForm from './OptimusFtlAppFlow/CompleteForm';
import { Field, withFormik } from 'formik';
import CustomInput from '../Form/Input/CustomInput';
import ProvideFundingInfo from '../Modal/ProvideFundingInfo';

const isRheem = getIsRheem();

const Application = ({
  dispatchSendEmailToSalesPerson,
  dispatchSendEmailToRelationshipManager,
  application,
  greenSkyApplication,
  isAccountCreated,
  parentPath,
  history,
  dispatchChangeTheme,
  dispatchGetGreenSkyApplication,
  dispatchChangeGreenSkyApplicationInfoValue,
  dispatchChangeSoftPullValue,
  dispatchGetAtWaterGreenSkyApplication,
  ftlData,
  fundingInfoDocuments,
  dispatchProvideFundingInfo,
}) => {
  let appStatus = getApplicationStatus(application.status);

  const isCompleteProject = ftlData?.ftlInformation.completeProject === true;

  const [revisitApp, setRevisitApp] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [sendTo, setSendTo] = useState({});
  const [showLoanDocForm, setShowLoanDocForm] = useState(false);
  const [showCompleteForm, setShowCompleteForm] = useState(false);
  const [achInfo, setAchInfo] = useState(null);
  const [showProvidingInfoModal, setShowProvidingInfoModal] = useState(false);

  const toggleModal = () => setShowEmailModal(!showEmailModal);

  const toggleACHModal = () => {
    setShowProvidingInfoModal(!showProvidingInfoModal);
  };

  const submitInfo = data => {
    setAchInfo(null);
    dispatchProvideFundingInfo(fundingInfoDocuments, application.id, data);
    toggleACHModal();
  };

  const cancel = () => {
    history.push(parentPath);
  };

  if (isAccountCreated === null) {
    dispatchChangeGreenSkyApplicationInfoValue();
  }

  const showFtlAppFlow =
    application.status === 'FTL_APPROVE_MORE' ||
    application.status === 'FTL_APPROVED_WITH_INFO' ||
    application.status === 'FTL_APPROVED_MORE_WITH_INFO' ||
    application.status === 'FTL_APPROVE_LOAN_MORE' ||
    application.status === 'FTL_APPROVED';

  const isOptimus = getIsOptimus();
  const isPools = getIsPool();

  if (achInfo === null && ftlData && ftlData.achInformation !== null) {
    setAchInfo(ftlData.achInformation);
  }

  useEffect(() => {
    if (
      isOptimus &&
      (isGreenSkyApproved(application.status) || isGreenSkyAssigned(application.status))
    ) {
      dispatchGetGreenSkyApplication(application.id);
    }
    if (
      isPools &&
      (isGreenSkyApproved(application.status) || isGreenSkyAssigned(application.status))
    ) {
      dispatchGetAtWaterGreenSkyApplication(application.id);
    }
    // eslint-disable-next-line
  }, []);

  const completeFundMicrof = () => {
    if (application.selectedFinancier === selectedFinancierEnum.RHEEM.value) {
      history.push(
        '/portal/open-applications/complete-fund-rheem-application?appId=' + application.id,
      );
    } else {
      history.push(
        '/portal/open-applications/complete-fund-microf-application?appId=' + application.id,
      );
      if (isRheem) {
        dispatchChangeTheme(ThemeEnum.MICROF.value);
      }
    }
  };

  function MaskCharacter(str, mask, n = 1) {
    return ('' + str).slice(0, -n).replace(/./g, mask) + ('' + str).slice(-n);
  }

  const sendEmail = emailText =>
    sendTo.role === messageRecipientEnum.DRR.name
      ? dispatchSendEmailToRelationshipManager(application.id, { emailText: emailText })
      : dispatchSendEmailToSalesPerson(application.id, { emailText: emailText });

  const FtlACHInfromationForm = () => (
    <Form>
      <Row className="mt-8">
        <Col xs="12" md="auto">
          <Field
            component={CustomInput}
            label="Bank account holder name"
            name="bankAccountHolderName"
            id="bankAccountHolderName"
            type="text"
            readOnly={true}
          />
          <Field
            component={CustomInput}
            label="Bank city and state"
            name="bankCityAndState"
            id="bankCityAndState"
            type="text"
            readOnly={true}
          />
          <Field
            component={CustomInput}
            label="Account number"
            name="accountNumber"
            id="accountNumber"
            type="text"
            readOnly={true}
          />
        </Col>
        <Col xs="12" md="auto">
          <Field
            component={CustomInput}
            label="Bank name"
            name="bankName"
            id="bankName"
            type="text"
            readOnly={true}
          />

          <Field
            component={CustomInput}
            label="Routing number"
            name="routingNumber"
            id="routingNumber"
            type="text"
            readOnly={true}
          />

          <Field
            component={CustomInput}
            label="Print Name"
            name="printName"
            id="printName"
            type="text"
            readOnly={true}
          />
        </Col>
      </Row>
    </Form>
  );

  const FtlACHInfromationFormFormik = withFormik({
    mapPropsToValues: () => ({
      bankAccountHolderName: achInfo ? achInfo.accountHolderName : '',
      bankName: achInfo ? achInfo.bankName : '',
      bankCityAndState: achInfo ? achInfo.bankCityAndState : '',
      routingNumber: achInfo ? MaskCharacter(achInfo.routingNumber, '*', 4) : '',
      accountNumber: achInfo ? MaskCharacter(achInfo.accountNumber, '*', 4) : '',
      printName: achInfo ? achInfo.printName : '',
    }),
  })(FtlACHInfromationForm);

  if (
    greenSkyApplication &&
    (greenSkyApplication.activationStatus === 'Activated' ||
      !greenSkyApplication.verified ||
      greenSkyApplication.activationStatus === 'Closed')
  ) {
    return (
      <div className="app-panel">
        <div className="app-panel-body">
          <div className="title">
            <span>
              Application - {greenSkyApplication.firstName + ' ' + greenSkyApplication.lastName}
            </span>
          </div>
          <div className="table-simple">
            <OuterContent fieldName="Applicant info" />
            {greenSkyApplication.applicationId ? (
              <OuterContent fieldName="Application ID">
                <span>{greenSkyApplication.applicationId}</span>
              </OuterContent>
            ) : null}
            {greenSkyApplication.decision ? (
              <OuterContent fieldName="Decision">
                <span>{greenSkyApplication.decision}</span>
              </OuterContent>
            ) : null}
            {greenSkyApplication.activationStatus ? (
              <OuterContent fieldName="Loan status">
                <span
                  className={
                    greenSkyApplication.activationStatus === 'Blocked' ? 'status-cancelled' : ''
                  }
                >
                  {greenSkyApplication.activationStatus === 'Closed'
                    ? 'Not Activated'
                    : greenSkyApplication.activationStatus}
                </span>
              </OuterContent>
            ) : null}
            {greenSkyApplication.openToBuy ? (
              <OuterContent fieldName="Open To Buy">
                <span>
                  {greenSkyApplication.openToBuy ? (
                    <NumberFormat
                      value={greenSkyApplication.openToBuy}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix={'$'}
                      displayType="text"
                    />
                  ) : (
                    '-'
                  )}
                </span>
              </OuterContent>
            ) : null}
            {greenSkyApplication.creditLimit ? (
              <OuterContent fieldName="Credit Limit">
                <span>
                  {greenSkyApplication.creditLimit ? (
                    <NumberFormat
                      value={greenSkyApplication.creditLimit}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix={'$'}
                      displayType="text"
                    />
                  ) : (
                    '-'
                  )}
                </span>
              </OuterContent>
            ) : null}
            <OuterContent fieldName="Application Date">
              <span>
                {greenSkyApplication.applicationDate
                  ? formatDate(greenSkyApplication.applicationDate, 'MM/DD/YYYY')
                  : '-'}
              </span>
            </OuterContent>
            <OuterContent fieldName="Purchase Window Expiration Date">
              <span>
                {greenSkyApplication.purchaseWindowExpirationDate
                  ? formatDate(greenSkyApplication.purchaseWindowExpirationDate, 'MM/DD/YYYY')
                  : '-'}
              </span>
            </OuterContent>
            {greenSkyApplication.referenceNumber ? (
              <OuterContent fieldName="Reference Number">
                <span>{greenSkyApplication.referenceNumber}</span>
              </OuterContent>
            ) : null}
            <Row className="mt-3 justify-content-end">
              <Col sm="12" xs="12" md="auto">
                <Button
                  type="submit"
                  color="secondary"
                  className="rounded-pill w-100 mt-1"
                  outline
                  onClick={() => {
                    cancel();
                  }}
                >
                  Cancel
                </Button>
              </Col>
              {greenSkyApplication.decision === 'Approved' &&
                greenSkyApplication.activationStatus === 'Activated' && (
                  <Col sm="12" xs="12" md="auto">
                    <Button
                      type="submit"
                      color="primary"
                      id="newTransactionBtn"
                      className="rounded-pill w-100 mt-1"
                      onClick={() => {
                        history.push('/portal/transaction-authorization-request');
                      }}
                    >
                      New transaction
                    </Button>
                  </Col>
                )}
            </Row>
          </div>
        </div>
      </div>
    );
  }

  if (isOptimus && showFtlAppFlow && showLoanDocForm) {
    return (
      ftlData && (
        <CreateLoanDocuments
          application={application}
          history={history}
          loanDoc={setShowLoanDocForm}
        />
      )
    );
  }

  if (isOptimus && showCompleteForm) {
    return (
      ftlData && (
        <CompleteForm
          application={application}
          history={history}
          completeForm={setShowCompleteForm}
        />
      )
    );
  }

  if (
    isOptimus &&
    application.selectedFinancier === selectedFinancierEnum.FTL.name &&
    application
  ) {
    return (
      <div className="app-panel">
        <div className="app-panel-body">
          <div className="title">
            <span>Application - {application.nameFirst + ' ' + application.nameLast}</span>
          </div>
          <div className="table-simple">
            <OuterContent fieldName="Applicant info" />
            {application.id ? (
              <OuterContent fieldName="Application ID">
                <span>{application.id}</span>
              </OuterContent>
            ) : null}
            {application.status ? (
              <OuterContent fieldName="Status">
                <span>{appStatus ? appStatus.description || appStatus.name : null}</span>
              </OuterContent>
            ) : null}

            {application.totalFinancing ? (
              <OuterContent fieldName="Approved Loan Amount">
                <span>
                  {application.totalFinancing ? (
                    <NumberFormat
                      value={application.totalFinancing}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix={'$'}
                      displayType="text"
                    />
                  ) : (
                    '-'
                  )}
                </span>
              </OuterContent>
            ) : null}
            <OuterContent fieldName="Expiration Date">
              <span>
                {application.expirationDate
                  ? formatDate(application.expirationDate, 'MM/DD/YYYY')
                  : '-'}
              </span>
            </OuterContent>
            <OuterContent fieldName="Required Document">
              <span>{application.documentNeeded ? application.documentNeeded : '-'}</span>
            </OuterContent>
            <OuterContent fieldName="DocuSign Status">
              <span>{application.docusignStatus ? application.docusignStatus : '-'}</span>
            </OuterContent>
            {application.progressStatus && (
              <OuterContent fieldName="Progress Status">
                <span>{application.progressStatus ? application.progressStatus : '-'}</span>
              </OuterContent>
            )}

            {isOptimus && showFtlAppFlow ? (
              <>
                {ftlData && ftlData.ftlInformation?.achNeeded === true && (
                  <>
                    {achInfo ? (
                      <>
                        <Row className="justify-content-center">
                          <div className="inner-content ftl-ach-info-table">
                            <FtlACHInfromationFormFormik />
                          </div>
                        </Row>
                        <Row className="justify-content-center mt-4">
                          <Col xs="12" md="auto">
                            <Button
                              type="button"
                              onClick={() => {
                                toggleACHModal();
                              }}
                            >
                              Update Funding Info
                            </Button>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <Row className="justify-content-center mt-4">
                        <Col xs="12" md="auto">
                          <Button
                            type="button"
                            onClick={() => {
                              toggleACHModal();
                            }}
                          >
                            Provide Funding Info
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </>
                )}
                <ProvideFundingInfo
                  isOpen={showProvidingInfoModal}
                  toggleModal={toggleACHModal}
                  onSendClick={submitInfo}
                  application={application}
                  ftlOptionsResponse={achInfo}
                />
                {isCompleteProject ? (
                  <>
                    <Row className="mt-3 justify-content-end">
                      <Col sm="12" xs="12" md="auto">
                        <Button
                          type="submit"
                          color="primary"
                          className="rounded-pill w-100 mt-1"
                          outline
                          onClick={() => {
                            dispatchChangeSoftPullValue('ftlSendEquipmentInfoResponse', null);
                            setShowCompleteForm(true);
                          }}
                        >
                          Complete Project
                        </Button>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ftlData && (
                    <ApplicationFtl
                      application={application}
                      history={history}
                      data={ftlData}
                      loanDoc={setShowLoanDocForm}
                    />
                  )
                )}
              </>
            ) : (
              <Row className="mt-3 justify-content-end">
                <Col sm="12" xs="12" md="auto">
                  <Button
                    type="submit"
                    color="secondary"
                    className="rounded-pill w-100 mt-1"
                    outline
                    onClick={() => {
                      cancel();
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col sm="12" xs="12" md="auto">
                  {appStatus &&
                    (appStatus.name === applicationStatusEnum.ftlIntermediate.FTL_NEW.name ||
                      appStatus.name === applicationStatusEnum.ftlDeclined.FTL_DECLINED.name ||
                      appStatus.name === allApplicationStatusEnum.STARTED.name ||
                      appStatus.name ===
                        applicationStatusEnum.enerBankDecline.ENERBANK_DENIED.name ||
                      appStatus.name ===
                        applicationStatusEnum.greenSkyDeclined.GREENSKY_DECLINED.name ||
                      appStatus.name ===
                        applicationStatusEnum.mosaicDeclined.MOSAIC_DECLINED.name) && (
                      <Form
                        title="formRevisitMicrofApp"
                        method="get"
                        action="/"
                        className="inline"
                        onSubmit={() => setRevisitApp(true)}
                      >
                        <Input name="appId" hidden value={application.id || ''} readOnly={true} />
                        <Input
                          name="appSelectedFinancier"
                          hidden
                          value={application.selectedFinancier || ''}
                          readOnly={true}
                        />
                        <Button
                          type="submit"
                          color="primary"
                          className="rounded-pill w-100 mt-1"
                          disabled={revisitApp}
                        >
                          {revisitApp ? 'Revisit Application ...' : 'Revisit Application'}
                        </Button>
                      </Form>
                    )}
                </Col>
              </Row>
            )}
          </div>
        </div>
      </div>
    );
  }

  return application ? (
    <div className="app-panel">
      <div className="app-panel-body">
        <div className="title">
          <span>Application - {application.nameFirst + ' ' + application.nameLast}</span>
        </div>
        <div className="table-simple">
          {application.rtoNumber ? (
            <OuterContent fieldName="RTO Number">
              <span>{application.rtoNumber}</span>
            </OuterContent>
          ) : null}
          {isPools && (
            <OuterContent fieldName="Application Id">
              {application.greenskyAppId ? (
                <span>{application.greenskyAppId}</span>
              ) : (
                <span>{application.id}</span>
              )}
            </OuterContent>
          )}
          <OuterContent fieldName="Applicant's Information">
            <span>{application.nameFirst + ' ' + application.nameLast}</span>
            <br />
            <span>{application.street}</span> <br />
            <span>
              {application.city}, {application.state} {application.zipcode}
            </span>{' '}
            <br />
            <span href="mailto:{application.email}" className="text-break">
              {application.email}
            </span>
            <br />
            <span href="tel:{application.phone}">
              {application.phone ? (
                application.phone.length > 6 ? (
                  <div>
                    {'(' +
                      application.phone.substring(0, 3) +
                      ') ' +
                      application.phone.substring(3, 6) +
                      '-' +
                      application.phone.substring(6)}
                  </div>
                ) : (
                  <div>{application.phone}</div>
                )
              ) : null}
            </span>
          </OuterContent>
          {!application.consumerApplication ? (
            <OuterContent fieldName="Total Job Price">
              <span>
                {application.totalPrice ? (
                  <NumberFormat
                    value={application.totalPrice}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={'$'}
                    displayType="text"
                  />
                ) : (
                  '-'
                )}
              </span>
            </OuterContent>
          ) : null}
          {!application.consumerApplication ? (
            <OuterContent fieldName="Total Loan or Lease Amount">
              <span>
                {application.totalFinancing ? (
                  <NumberFormat
                    value={application.totalFinancing}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={'$'}
                    displayType="text"
                  />
                ) : (
                  '-'
                )}
              </span>
            </OuterContent>
          ) : null}
          {!application.consumerApplication && !isPools ? (
            <OuterContent fieldName="Capitalized Cost Reduction">
              <span>
                {application.downPaymentAmount ? (
                  <NumberFormat
                    value={application.downPaymentAmount}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={'$'}
                    displayType="text"
                  />
                ) : (
                  '-'
                )}
              </span>
            </OuterContent>
          ) : null}
          <OuterContent fieldName="Status">
            <span>{appStatus ? appStatus.description || appStatus.name : null}</span>
          </OuterContent>
          {application.applicationStatus ? (
            <OuterContent fieldName="Application Status">
              <span>{application.applicationStatus}</span>
            </OuterContent>
          ) : null}
          <OuterContent fieldName="Salesperson">
            {application.salesperson && application.salesperson !== 'None' ? (
              <span
                className="text-primary cursor-pointer"
                onClick={() => {
                  setSendTo({
                    role: messageRecipientEnum.SALESPERSON.name,
                    name: application.salesperson,
                  });
                  toggleModal();
                }}
              >
                {application.salesperson}
              </span>
            ) : (
              <span>{application.salesperson}</span>
            )}
          </OuterContent>
          {!application.consumerApplication ? (
            <OuterContent fieldName="Relationship Manager">
              {application.relationshipManager && application.relationshipManager !== 'None' ? (
                <span
                  className="text-primary cursor-pointer"
                  onClick={() => {
                    setSendTo({
                      role: messageRecipientEnum.DRR.name,
                      name: application.relationshipManager,
                    });
                    toggleModal();
                  }}
                >
                  {application.relationshipManager}
                </span>
              ) : (
                <span>{application.relationshipManager}</span>
              )}
            </OuterContent>
          ) : null}
          <OuterContent fieldName="Contractor">
            <span className="text-break">{application.dealer}</span>
          </OuterContent>
          <OuterContent fieldName="Submitted">
            <span className="text-break">{formatDate(application.submitted)}</span>
          </OuterContent>
          {!isPools && (
            <>
              <OuterContent fieldName="First Payment">
                <span>{application.firstPayment ? formatDate(application.firstPayment) : '-'}</span>
              </OuterContent>
              <OuterContent fieldName="Is Agreement Signed">
                <span>{application.agreementSigned ? 'Yes' : 'No'}</span>
              </OuterContent>
            </>
          )}

          <Row className="mt-3 justify-content-end">
            <Col sm="12" xs="12" md="auto">
              <Button
                type="submit"
                color="secondary"
                className="rounded-pill w-100 mt-1"
                outline
                onClick={() => {
                  cancel();
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col sm="12" xs="12" md="auto">
              {application.consumerApplication ? (
                appStatus &&
                !(
                  application.selectedFinancier === selectedFinancierEnum.AQUA_FINANCE.value ||
                  (application.selectedFinancier === selectedFinancierEnum.MICROF.value &&
                    isStatusAquaDecline(appStatus.value))
                ) ? (
                  <Form
                    target="_blank"
                    title="formRevisitMicrofApp"
                    method="get"
                    action="/"
                    className="inline"
                    onSubmit={() => setRevisitApp(true)}
                  >
                    <Input name="appId" hidden value={application.id || ''} readOnly={true} />
                    <Input name="appToken" hidden value={application.token || ''} readOnly={true} />
                    <Button
                      type="submit"
                      color="primary"
                      className="rounded-pill w-100 mt-1"
                      disabled={revisitApp}
                    >
                      {revisitApp ? 'Complete Application ...' : 'Complete Application'}
                    </Button>
                  </Form>
                ) : null
              ) : appStatus &&
                appStatus.value !== allApplicationStatusEnum.GREENSKY_CANCELLED.value &&
                ApplicationCategoryEnum.OPEN !== appStatus.category &&
                (application.selectedFinancier !== selectedFinancierEnum.AQUA_FINANCE.value ||
                  isMosaicDeclined(appStatus.value) ||
                  isPowerPayDeclined(appStatus.value)) ? (
                <Form
                  title="formRevisitMicrofApp"
                  method="get"
                  action="/"
                  className="inline"
                  onSubmit={() => setRevisitApp(true)}
                >
                  <Input name="appId" hidden value={application.id || ''} readOnly={true} />
                  <Input
                    name="appSelectedFinancier"
                    hidden
                    value={application.selectedFinancier || ''}
                    readOnly={true}
                  />
                  <Button
                    type="submit"
                    color="primary"
                    className="rounded-pill w-100 mt-1"
                    disabled={revisitApp}
                  >
                    {revisitApp ? 'Revisit Application ...' : 'Revisit Application'}
                  </Button>
                </Form>
              ) : null}
              {appStatus &&
                ApplicationCategoryEnum.OPEN === appStatus.category &&
                application.addedBy !== AddedByAPIEnum.SERVICEFINANCE.name &&
                (application.selectedFinancier === selectedFinancierEnum.MICROF.value ||
                  application.selectedFinancier === selectedFinancierEnum.RHEEM.value) &&
                application.contractorInitials == null && (
                  <Button
                    type="submit"
                    color="primary"
                    id="completeFundMicrofBtn"
                    className="rounded-pill w-100 mt-1"
                    onClick={() => {
                      completeFundMicrof();
                    }}
                  >
                    Complete / Fund
                  </Button>
                )}
            </Col>
          </Row>
        </div>
      </div>
      <EmailModal
        isOpen={showEmailModal}
        toggleModal={toggleModal}
        sendTo={sendTo}
        onSendClick={sendEmail}
      />
    </div>
  ) : null;
};

const mapStateToProps = state => ({
  greenSkyApplication: state.softPull.greenSkyApplication,
  isAccountCreated: state.greenSkyApplicationInformation.isAccountCreated,
  ftlData: state.softPull.ftlData,
  fundingInfoDocuments: state.softPull.fundingInfoDocuments,
});

const mapDispatchToProps = dispatch => ({
  dispatchSendEmailToSalesPerson: (applicationId, data) =>
    dispatch(sendEmailToSalesPerson(applicationId, data)),
  dispatchSendEmailToRelationshipManager: (applicationId, data) =>
    dispatch(sendEmailToRelationshipManager(applicationId, data)),
  dispatchChangeTheme: theme => dispatch(changeTheme(theme)),
  dispatchChangeGreenSkyApplicationInfoValue: () =>
    dispatch(changeGreenSkyApplicationInfoValue('isAccountCreated', null)),
  dispatchGetGreenSkyApplication: applicationId => dispatch(getGreenSkyApplication(applicationId)),
  dispatchGetAtWaterGreenSkyApplication: applicationId =>
    dispatch(getAtWaterGreenSkyApplication(applicationId)),
  dispatchChangeSoftPullValue: (key, value) => dispatch(changeSoftPullValue(key, value)),
  dispatchProvideFundingInfo: (appId, optionId, data) =>
    dispatch(provideFundingInfo(appId, optionId, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Application);
