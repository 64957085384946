import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  changeAuthValue,
  changeMicrofInfoValue,
  changePage,
  resetNewAppState,
  setFlow,
} from '../../../redux/actions';
import NewApplicationDefault from './NewApplication';

const NewApplication = props => {
  const { auth, history } = props;
  useEffect(() => {
    if (!auth.user || !auth.user.dealerUser) {
      history.push('/login');
    }
    // eslint-disable-next-line
  }, []);

  return <NewApplicationDefault {...props} />;
};

const mapStateToPops = state => ({
  auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeMicrofInfoValue: (key, value) => dispatch(changeMicrofInfoValue(key, value)),
  dispatchChangeAuthValue: (key, value) => dispatch(changeAuthValue(key, value)),
  dispatchSetFlow: flowName => dispatch(setFlow(flowName)),
  dispatchResetNewAppState: () => dispatch(resetNewAppState()),
});

export default connect(
  mapStateToPops,
  mapDispatchToProps,
)(NewApplication);
