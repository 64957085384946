import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { reactGAPageview } from '../../helper';
import { resetState, resetNewAppState } from '../../redux/actions/index';
import { withRouter } from 'react-router-dom';
import Button from '../../components/UI/Button/CustomButton';
import { getDealerPortalURL } from '../../config/settings';

const Pending = props => {
  const {
    dealerUserId,
    did,
    user,
    history,
    dispatchResetNewAppState,
    dispatchResetState,
    isConsumer,
  } = props;

  const isUserLoggedIn = !did && user && user.dealerUser;
  const buttonTitle = isUserLoggedIn ? 'Return to Dashboard' : 'Return to Login Page';
  const returnUrl = isUserLoggedIn ? '/portal/dashboard' : getDealerPortalURL();

  useEffect(() => {
    reactGAPageview({
      path: '/wf_pending',
      trackerNames: [],
      title: 'wf_pending',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Row className="justify-content-center mt-2">
        <Col xs="auto">
          <h5>Your application is currently pending</h5>
        </Col>
      </Row>
      {!isConsumer ? (
        <>
          <Row className="justify-content-center mt-2">
            <Col xs="auto">
              <h5>Please hand your device back to the contractor to continue</h5>
            </Col>
          </Row>
          <Row className="justify-content-center mt-5">
            <Col xs="auto">
              <h5>Contractor</h5>
            </Col>
          </Row>
          <Row className="justify-content-center mt-2">
            <Col xs="auto">
              <h5 className="text-center">
                The application has been received and requires further review. If we need additional
                information, Wells Fargo will contact your customer within 15 minutes on the phone
                number(s) they provided on the application. If you have not received a decision in a
                timely manner and the customer is present, please contact Client Processing at
                1-800-551-5111 during normal business hours (Monday – Saturday 8 a.m. – 10 p.m.
                Central Time & Sunday 10 a.m. – 10 p.m. Central Time).
              </h5>
            </Col>
          </Row>
          <Row className="justify-content-center mt-2">
            <Col xs="auto">
              <Button
                type="button"
                title={buttonTitle}
                clickHandler={() => {
                  if (!isUserLoggedIn) {
                    dispatchResetState(props);
                    window.location.href = returnUrl;
                  } else {
                    dispatchResetNewAppState();
                    history.push(returnUrl);
                  }
                }}
              />
            </Col>
          </Row>
        </>
      ) : (
        <Row className="justify-content-center mt-5">
          <Col xs="12" md="8">
            <h5 className="text-center">
              Thank you for applying. The application has been received and requires further review.
              Wells Fargo may be contacting you on the phone number(s) you provided if additional
              information is needed. A credit decision will be mailed within 30 days. Thank you for
              the opportunity to serve you.
            </h5>
          </Col>
        </Row>
      )}
    </Container>
  );
};

const mapStateToProps = state => ({
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchResetState: props => dispatch(resetState(props)),
  dispatchResetNewAppState: () => dispatch(resetNewAppState()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Pending),
);
