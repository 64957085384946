const SystemTypesList = [
  { value: 'A', name: 'Air Conditioner or Heating System' },
  { value: 'W', name: 'Water Heater' },
  {
    value: 'AW',
    name: 'Air Conditioner or Heating System Plus Water Heater',
  },
];

export default SystemTypesList;
