import React from 'react';
import AdditionalApplicantInformation from './AdditionalApplicantInformation';
import AdditionalCoApplicantInformation from './AdditionalCoApplicantInformation';
import AppGrossAnnualIncome from './AppGrossAnnualIncome';
import ApplicantMarried from './ApplicantMarried';
import CoAppGrossAnnualIncome from './CoAppGrossAnnualIncome';
import CoApplicantAddress from './CoApplicantAddress';
import CoApplicantMarried from './CoApplicantMarried';
import CoApplicantName from './CoApplicantName';
import Consent from './Consent';
import HasCoApplicant from './HasCoApplicant';
import LoanInformation from './LoanInformation';
import Review from './Review';
import ApplicantSpouse from './ApplicantSpouse';
import ApplicationConsent from './ApplicationConsent';
import ApplicationResult from './ApplicationResult';
import CoApplicantSpouse from './CoApplicantSpouse';
import AppPrimaryAddress from './AppPrimaryAddress';
import LoanDocument from './LoanDocument';
import Questions from './Questions';
import ActivationPage from './ActivationPage';
import ErrorPage from './ErrorPage';

const GreenSkyFlow = [
  <Consent />,
  <LoanInformation>
    <h4>Please provide some information about the loan.</h4>
  </LoanInformation>,
  <AppPrimaryAddress>
    <h4>Is your Primary Address the same as the Installation Address?</h4>
  </AppPrimaryAddress>,
  <AdditionalApplicantInformation />,
  <AppGrossAnnualIncome>
    <h4>Primary Applicant Income</h4>
    <p>
      Provide all sources of income you have reasonable access to including, but not limited to,
      annual salary (before taxes), social security, retirement, annuity, rental income, etc.
    </p>
  </AppGrossAnnualIncome>,
  <ApplicantMarried />,
  <ApplicantSpouse>
    <h4> What is your spouse’s Name and Address?</h4>
  </ApplicantSpouse>,
  <HasCoApplicant>
    <h4>Would you like to add a Co-Applicant?</h4>
    <p>
      A co-applicant is <u>not</u> required.
    </p>
  </HasCoApplicant>,
  <CoApplicantName>
    <h4>Co-Applicant, enter your name.</h4>
  </CoApplicantName>,
  <CoApplicantAddress>
    <h4>Co-Applicant, enter your address.</h4>
  </CoApplicantAddress>,
  <AdditionalCoApplicantInformation />,
  <CoAppGrossAnnualIncome>
    <h4>Co-Applicant, enter your Income (or Benefits) Information.</h4>
    <p>
      Provide all sources of income you have reasonable access to including, but not limited to,
      annual salary (before taxes), social security, retirement, annuity, rental income, etc.
    </p>
  </CoAppGrossAnnualIncome>,
  <CoApplicantMarried />,
  <CoApplicantSpouse>
    <h4> What is your spouse’s Name and Address?</h4>
  </CoApplicantSpouse>,
  <Review>
    <h4>Let's double check the information and submit your application.</h4>
  </Review>,
  <ApplicationConsent />,
  <ApplicationResult />,
  <LoanDocument />,
  <Questions />,
  <ErrorPage />,
  <ActivationPage />,
];

export default GreenSkyFlow;

export const AtWaterGreenSkyFlowPageNumber = {
  Consent: 0,
  LoanInformation: 1,
  AppPrimaryAddress: 2,
  AdditionalApplicantInformation: 3,
  AppGrossAnnualIncome: 4,
  ApplicantMarried: 5,
  ApplicantSpouse: 6,
  HasCoApplicant: 7,
  CoApplicantName: 8,
  CoApplicantAddress: 9,
  AdditionalCoApplicantInformation: 10,
  CoAppGrossAnnualIncome: 11,
  CoApplicantMarried: 12,
  CoApplicantSpouse: 13,
  Review: 14,
  ApplicationConsent: 15,
  ApplicationResult: 16,
  LoanDocument: 17,
  Questions: 18,
  ErrorPage: 19,
  ActivationPage: 20,
};
