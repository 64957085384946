import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import { getIsOptimus } from '../../../../config/settings';
import Button from '../../../../components/UI/Button/CustomButton';
import Checkbox from '../../../../components/UI/Form/Checkbox/Checkbox';
import { reactGAEvent, reactGAPageview } from '../../../../helper';
import {
  changePage,
  submitMindfire,
  changeAtWaterApplicationInfoValue,
} from '../../../../redux/actions/index';
import { AtWaterFlowPageNumber } from './AtWaterFlow';
import serviceFinanceLogo from '../../../../assets/img/service-finance-company-logo.png';

const Consent = props => {
  const {
    dispatchChangePage,
    consent,
    dispatchChangeAtWaterApplicationInfoValue,
    did,
    dealerUserId,
    dispatchSubmitMindfire,
  } = props;
  const isOptimus = getIsOptimus();

  useEffect(() => {
    reactGAPageview({
      path: '/ca_gsconsent',
      trackerNames: [],
      title: 'ca_gsconsent',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Continue',
      did,
      dealerUserId,
    });
    dispatchSubmitMindfire({
      lastPage: '9009',
      dealerUserId: dealerUserId,
      did: did,
    });
    dispatchChangeAtWaterApplicationInfoValue('consent', true);
    dispatchChangePage(AtWaterFlowPageNumber.ProgramInformation);
  };

  const ConsentForm = () => (
    <Form noValidate autoComplete="off">
      <Field
        component={Checkbox}
        name="consent"
        id="consent"
        label="Applicant"
        labelClassName="bold mb-0"
      />
      <Button type="submit" title={'Continue'} />
    </Form>
  );

  const ConsentFormFormik = withFormik({
    mapPropsToValues: () => ({
      consent: consent || false,
    }),

    validationSchema: Yup.object({
      consent: Yup.boolean().oneOf([true], 'Please check to continue.'),
    }),

    handleSubmit,
  })(ConsentForm);

  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs="auto" sm="12">
          <Row className="justify-content-center text-align-center ">
            <Col xs="auto">
              <h3>Your application will be reviewed by:</h3>
              <div className="logo-service-finance">
                <img src={serviceFinanceLogo} alt="Service Finance Logo" />
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center mt-5">
            <Col md="8">
              <h4>Consent</h4>
              <p>
                I acknowledge that by checking the box below and pressing continue that, upon
                completion, the information on the application will be sent to Service Finance
                Company, LLC.
              </p>
              <ConsentFormFormik />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  consent: state.greenSkyApplicationInformation.consent,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeAtWaterApplicationInfoValue: (key, value) =>
    dispatch(changeAtWaterApplicationInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Consent),
);
