import React, { useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { getDealers } from '../../../redux/actions';
import DealersTable from './DealersTable';

const UpdateMerchantNumber = props => {
  const pageLimit = 20;
  const { auth, history, dealers, dispatchGetDealers } = props;

  const pageCount = dealers && dealers.length / pageLimit;

  useEffect(() => {
    if (!auth.user && (!auth.user.dealerUser || !auth.user.internalUser)) {
      history.push('/login');
    }

    if (dealers === null) {
      dispatchGetDealers();
    }
    // eslint-disable-next-line
  }, []);

  const handlePageClick = pageNumber => {
    // dispatchSearchContractorUsers({
    //   ...searchOptionUsers,
    //   pageOffset: pageNumber.selected * pageLimit,
    // });
  };

  return (
    <Col>
      <Row className="mt-5">
        <h1 id="panelTitle">Update Merchant Number</h1>

        <div className="panel">
          <div className="panel-body larger">
            <Row>
              <Col className="right-bordered">
                {dealers && dealers.length > 0 ? (
                  <>
                    <Row className="overflow-auto">
                      <DealersTable dealersData={dealers} history={history} />
                    </Row>
                    {pageCount > 1 && (
                      <Row className="justify-content-between align-items-center">
                        <ReactPaginate
                          previousLabel={'<'}
                          nextLabel={'>'}
                          breakClassName={'break-me'}
                          breakLinkClassName={'page-link'}
                          breakLabel={'...'}
                          pageCount={pageCount}
                          pageLimitDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageClick}
                          containerClassName={'pagination'}
                          subContainerClassName={'pages pagination'}
                          pageClassName={'page-item'}
                          pageLinkClassName={'page-link'}
                          activeClassName={'active'}
                          nextClassName={'page-item'}
                          nextLinkClassName={'page-link'}
                          previousClassName={'page-item'}
                          previousLinkClassName={'page-link'}
                        />
                      </Row>
                    )}
                  </>
                ) : (
                  <>No dealers found.</>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Row>
    </Col>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  dealers: state.softPull.dealers,
});

const mapDispatchToProps = dispatch => ({
  dispatchGetDealers: param => dispatch(getDealers()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(UpdateMerchantNumber),
);
