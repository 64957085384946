import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import usStates from '../../../assets/js/USStateEnum';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import Input from '../../../components/UI/Form/Input/CustomInput';
import Select from '../../../components/UI/Form/Select/Select';
import ErrorModal from '../../../components/UI/Modal/ErrorModal';
import { getDealerPortalName, getDealerPortalURL } from '../../../config/settings';
import {
  CITY_TOOLTIP_TEXT,
  STATE_TOOLTIP_TEXT,
  STREET_TOOLTIP_TEXT,
  ZIPCODE_TOOLTIP_TEXT,
} from '../../../constants/tooltipTexts';
import { reactGAEvent, reactGAPageview } from '../../../helper';
import { changePage, changePreQualInfoValue, submitMindfire } from '../../../redux/actions/index';
import { EnerBankPreQualificationFlowPageNumber } from './PreQualificationFlow';

const UserAddress = props => {
  const {
    email,
    street,
    city,
    zipcode,
    state,
    dispatchChangePage,
    dispatchChangeValue,
    children,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    pqi,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_ebconsumerinfo2',
      trackerNames: [],
      title: 'ca_ebconsumerinfo2',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const UserAddressForm = () => (
    <Form>
      <Field
        component={Input}
        label="Street"
        name="street"
        id="street"
        tooltipText={STREET_TOOLTIP_TEXT}
        type="text"
      />
      <Row>
        <Col sm="6">
          <Field
            component={Input}
            label="City"
            name="city"
            id="city"
            tooltipText={CITY_TOOLTIP_TEXT}
            type="text"
          />
        </Col>
        <Col sm="6">
          <Field
            component={Select}
            label="State"
            name="state"
            id="state"
            tooltipText={STATE_TOOLTIP_TEXT}
            type="select"
            selectValues={usStates}
            selectFirstName="Select State"
          />
        </Col>
      </Row>
      <Field
        component={Input}
        label="ZIP Code"
        name="zipcode"
        id="zipcode"
        tooltipText={ZIPCODE_TOOLTIP_TEXT}
        type="text"
        maxLength={10}
      />

      <Button type="submit" title="Next" />
    </Form>
  );

  const UserAddressFormFormik = withFormik({
    mapPropsToValues: () => ({
      street: street || '',
      city: city || '',
      zipcode: zipcode || '',
      state: state || '',
    }),

    validationSchema: Yup.object({
      street: Yup.string()
        .matches(/.*([a-zA-Z].*[0-9]|[0-9].*[a-zA-Z]).*/, {
          message: 'The street field must contain the street name and number',
          excludeEmptyString: true,
        })
        .required('Please enter a street.'),
      city: Yup.string().required('Please enter a city.'),
      zipcode: Yup.string()
        .matches(/^\d{5}?$/, {
          message: 'Invalid zip code.',
          excludeEmptyString: true,
        })
        .required('Please enter a zip code.'),
      state: Yup.string().required('Please select a state.'),
    }),

    handleSubmit: async values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Continue',
        did,
        dealerUserId,
      });
      dispatchChangeValue('street', values.street);
      dispatchChangeValue('city', values.city);
      dispatchChangeValue('zipcode', values.zipcode);
      dispatchChangeValue('state', values.state);
      dispatchSubmitMindfire({
        email: email,
        city: values.city,
        zip: values.zipcode,
        state: values.state,
        street: values.street,
        lastPage: '11005',
        dealerUserId: dealerUserId,
        did: did,
      });

      dispatchChangePage(EnerBankPreQualificationFlowPageNumber.UserLastInfo);
    },
  })(UserAddressForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={EnerBankPreQualificationFlowPageNumber.UserInfo}
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <UserAddressFormFormik />
        </Col>
      </Row>
      <ErrorModal
        isOpen={pqi.preQualInfoExists}
        messageType="User Exists"
        message={
          <>
            Your email address already exists on our system. Please log in using{' '}
            <a href={getDealerPortalURL()}>{getDealerPortalName()}</a> and complete the application
            that is already in progress.
          </>
        }
        onClick={() => {
          dispatchChangeValue('preQualInfoExists', false);
          window.location.href = getDealerPortalURL();
        }}
      />
    </Container>
  );
};

const mapStateToProps = state => ({
  pqi: state.preQualificationInformation,
  street: state.preQualificationInformation.street,
  city: state.preQualificationInformation.city,
  zipcode: state.preQualificationInformation.zipcode,
  state: state.preQualificationInformation.state,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) => dispatch(changePreQualInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

UserAddress.propTypes = {
  street: PropTypes.string,
  city: PropTypes.string,
  zipcode: PropTypes.string,
  state: PropTypes.string,
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserAddress);
