import React from 'react';
import NumberFormat from 'react-number-format';
import { Col, Row } from 'reactstrap/';

const ApprovedWithLoanMore = ({ applicantName, borrowedAmount }) => (
  <>
    <Row className="justify-content-center mb-3">
      <Col xs="auto">
        <h5 className="text-center">
          <b>Application Approved with the LoanMore Program!</b>
        </h5>
      </Col>
    </Row>
    <Row className="justify-content-center mb-3">
      <Col xs="auto" sm="11">
        <h5 className="text-center">
          The loan application for {applicantName} did not meet our standard credit requirements but
          has been approved through LoanMore for{' '}
          <NumberFormat
            value={borrowedAmount}
            thousandSeparator={true}
            fixedDecimalScale={true}
            decimalScale={2}
            prefix={'$'}
            displayType="text"
          />
          . The homeowner has also received notification of their approval.
        </h5>
      </Col>
    </Row>
    <Row className="justify-content-center mb-3">
      <Col xs="auto" sm="11">
        <h5 className="text-center">
          <b>LOANMORE IS FREE TO THE CONTRACTOR.</b>
        </h5>
      </Col>
    </Row>
  </>
);

export default ApprovedWithLoanMore;
