import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isFTLApproved, isFTLDeclined } from '../../../assets/js/ApplicationStatusEnum';
import {
  changePageTitle,
  mapPowerPayToState,
  resetState,
  setFlow,
  submitMindfire,
  updateSelectedFinancier,
} from '../../../redux/actions/index';
import { changeMicrofInfoValue } from '../../../redux/actions/microfActions';
import Pending from './Pending';
import Approved from './Approved';
import Declined from './Declined';
import { getIsOptimus } from '../../../config/settings';

const ApplicationResult = props => {
  const { status } = props;

  const isOptimus = getIsOptimus();

  if (isFTLApproved(status) && !isOptimus) {
    return <Approved {...props} />;
  } else if (isFTLDeclined(status)) {
    return <Declined {...props} />;
  } else {
    return <Pending {...props} />;
  }
};

const mapStateToProps = state => ({
  pqi: state.preQualificationInformation,
  consumerToken:
    state.softPull.applicationSubmitDTO && state.softPull.applicationSubmitDTO.consumerToken,
  status: state.softPull.applicationSubmitDTO && state.softPull.applicationSubmitDTO.status,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  ftlInformation: state.ftlInformation,
  ftlExternalId: state.softPull.applicationSubmitDTO.ftlinformation?.ftlExternalId,
});

const mapDispatchToProps = dispatch => ({
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchUpdateSelectedFinancier: (selectedFinancier, applicationId, successCallBack) =>
    dispatch(updateSelectedFinancier(selectedFinancier, applicationId, successCallBack)),
  dispatchSetFlow: flowName => dispatch(setFlow(flowName)),
  dispatchChangeMicrofInfoValue: (key, value) => dispatch(changeMicrofInfoValue(key, value)),
  dispatchResetState: props => dispatch(resetState(props)),
  dispatchChangePageTitle: value => dispatch(changePageTitle(value)),
  dispatchMapPowerPayToState: () => dispatch(mapPowerPayToState()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ApplicationResult),
);
