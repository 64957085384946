import React from 'react';
import { Col, Row } from 'reactstrap';
import logo_sf_call from '../../assets/img/logo-sf-call.png';

const CompleteHomeownerInterview = () => (
  <>
    <Row>
      <Col className="text-center">
        <a href="tel:855-642-7631">
          <img src={logo_sf_call} alt="Call" className="logo-sf-call" />
        </a>
      </Col>
    </Row>
    <Row>
      <Col>
        <h4 className="text-center">
          Call here
          <br />
          or
          <br /> dial{' '}
          <a href="tel:855-642-7631" className="sf-tel">
            <u>855-642-7631</u>
          </a>{' '}
          to
          <br /> complete homeowner interview
        </h4>
      </Col>
    </Row>
  </>
);

export default CompleteHomeownerInterview;
