import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { reactGAPageview, reactGAEvent } from '../../../../helper';
import { getDealerPortalURL, getGreenSkyTechnicalSupportNumber } from '../../../../config/settings';
import { getApplicationStatus } from '../../../../assets/js/ApplicationStatusEnum';
import Button from '../../../../components/UI/Button/CustomButton';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { resetState, resetNewAppState } from '../../../../redux/actions/index';

const Pending = props => {
  const {
    dealerUserId,
    did,
    dispatchSubmitMindfire,
    email,
    status,
    pendingDescription,
    history,
    user,
    dispatchResetNewAppState,
    dispatchResetState,
  } = props;

  const isUserLoggedIn = !did && user && user.dealerUser;
  const buttonTitle = isUserLoggedIn ? 'Return to Dashboard' : 'Return to Login Page';
  const returnUrl = isUserLoggedIn ? '/portal/dashboard' : getDealerPortalURL();
  useEffect(() => {
    reactGAPageview({
      path: '/ca_gspending',
      trackerNames: [],
      title: 'ca_gspending',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const handleClick = () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: buttonTitle,
      did,
      dealerUserId,
    });
    dispatchSubmitMindfire({
      email: email,
      lastPage: '9027',
      dealerUserId: dealerUserId,
      did: did,
    });
    if (!isUserLoggedIn) {
      dispatchResetState(props);
      window.location.href = returnUrl;
    } else {
      dispatchResetNewAppState();
      history.push(returnUrl);
    }
  };

  const st = getApplicationStatus(status);
  return (
    <Container>
      <Row className="justify-content-center mt-2">
        <Col xs="auto" md="8">
          <h3 className="text-center">
            <b>{st ? st.description : 'UNKNOWN STATUS'}</b>
          </h3>
        </Col>
      </Row>

      {pendingDescription && pendingDescription?.length > 0 ? (
        <>
          <Row className="justify-content-center mt-2">
            <Col xs="auto" md="8">
              <h5 className="text-center">
                Your application is currently under review for the following reason:
              </h5>
            </Col>
          </Row>
          <Row className="justify-content-center mt-2">
            <Col xs="auto" md="8">
              <h5 className="text-center">
                <i>{pendingDescription}</i>
              </h5>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row className="justify-content-center mt-2">
            <Col xs="auto" md="8">
              <h5 className="text-center">Your application is currently under review.</h5>
            </Col>
          </Row>
        </>
      )}

      <Row className="justify-content-center mt-2">
        <Col xs="auto" md="8">
          <h5 className="text-center">
            To complete the application process, please call GreenSky® at{' '}
            {getGreenSkyTechnicalSupportNumber()}
          </h5>
        </Col>
      </Row>
      <Row className="justify-content-center mt-2">
        <Col xs="auto">
          <Button type="button" title={buttonTitle} clickHandler={handleClick} />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  email: state.preQualificationInformation.email,
  user: state.auth.user,
  pendingDescription:
    state.softPull.applicationSubmitDTO && state.softPull.applicationSubmitDTO.pendingDescription,
});

const mapDispatchToProps = dispatch => ({
  dispatchResetState: props => dispatch(resetState(props)),
  dispatchResetNewAppState: () => dispatch(resetNewAppState()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Pending),
);
