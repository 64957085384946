import { Field, Form, withFormik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, FormGroup, Row } from 'reactstrap';
import * as Yup from 'yup';
import Button from '../../components/UI/Button/CustomButton';
import Input from '../../components/UI/Form/Input/CustomInput';
import { login } from '../../redux/actions';
import { getAddedFrom } from '../../config/settings';
import Password from '../../components/UI/Form/Password/Password';
import { EMAIL_TOOLTIP_TEXT, PASSWORD_TOOLTIP_TEXT } from '../../constants/tooltipTexts';
import { isNoreplyEmail } from '../../helper';

const LoginForm = props => {
  const { dispatchLogin, history } = props;

  const addedFrom = getAddedFrom();
  const LoginFormForm = () => (
    <Form>
      <Field
        component={Input}
        label="Email Address"
        name="email"
        id="email"
        tooltipText={EMAIL_TOOLTIP_TEXT}
        type="email"
      />
      <Field
        component={Password}
        label="Password"
        name="password"
        id="password"
        tooltipText={PASSWORD_TOOLTIP_TEXT}
        type="password"
      />
      <Row className="align-items-center">
        <Col sm="6">
          <Button className="login-button" type="submit" title="Sign In" />
        </Col>
        <Col sm="6">
          <FormGroup className="d-flex justify-content-center">
            <a href="/ForgotPassword">Forgot Password</a>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );

  const LoginFormFormik = withFormik({
    mapPropsToValues: () => ({
      email: '',
      password: '',
    }),

    validationSchema: Yup.object({
      email: Yup.string()
        .email('Please enter a valid email.')
        .required('Please enter an email.')
        .test('valid', 'Please enter a valid email.', val => val && isNoreplyEmail(val)),
      password: Yup.string().required('Please enter a password.'),
    }),

    handleSubmit: values => {
      dispatchLogin(
        { username: values.email, password: values.password, addedFrom: addedFrom },
        history,
      );
    },
  })(LoginFormForm);

  return <LoginFormFormik />;
};

const mapDispatchToProps = dispatch => ({
  dispatchLogin: (email, password, addedFrom) => dispatch(login(email, password, addedFrom)),
});

export default withRouter(
  connect(
    null,
    mapDispatchToProps,
  )(LoginForm),
);
