import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import BackBtn from '../../../components/UI/BackButton/BackButton';
import Button from '../../../components/UI/Button/CustomButton';
import { changePage, changePreQualInfoValue, submitMindfire } from '../../../redux/actions/index';
import { reactGAPageview, reactGAEvent } from '../../../helper';
import { MosaicPreQualificationFlowPageNumber } from './PreQualificationFlow';
import { getIsOptimus } from '../../../config/settings';
import AirConditioningSystemEnum from '../../../assets/js/AirConditioningSystemEnum';
const isOptimus = getIsOptimus();

const LoanFor = props => {
  const {
    email,
    dispatchChangePage,
    dispatchChangePreQualInfoValue,
    dealerUserId,
    did,
    dispatchSubmitMindfire,
    airConditioningSystem,
    isHomeowner,
    verifyAddressChecked,
  } = props;

  const [isRequired, setIsRequired] = useState(false);

  useEffect(() => {
    reactGAPageview({
      path: '/ca_ppprequaloption',
      trackerNames: [],
      title: 'ca_ppprequaloption',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const mindfireRequest = {
    email: email,
    lastPage: '6003',
    dealerUserId: dealerUserId,
    did: did,
  };

  return (
    <Container>
      <Row>
        {!verifyAddressChecked ? (
          <BackBtn
            changePage={dispatchChangePage}
            page={
              !!dealerUserId && did === null
                ? MosaicPreQualificationFlowPageNumber.AccountEmail
                : MosaicPreQualificationFlowPageNumber.AccountPassword
            }
          />
        ) : (
          <Col sm="1" />
        )}
        <Col sm="5">
          <h4>What type of project would you like to finance today?</h4>
        </Col>
        <Col sm="6">
          <Button
            className={
              'btn btn-primary mt-3 ' +
              (airConditioningSystem === null
                ? 'inactive'
                : airConditioningSystem ===
                  AirConditioningSystemEnum.AIR_CONDITION_OR_HEATING_SYSTEM.id
                ? ''
                : 'inactive')
            }
            title="HVAC and/or Water Heater"
            clickHandler={() => {
              reactGAEvent({
                category: 'Button',
                action: 'Click',
                label: 'HVAC and/or Water Heater',
                did,
                dealerUserId,
              });
              dispatchChangePreQualInfoValue(
                'airConditioningSystem',
                AirConditioningSystemEnum.AIR_CONDITION_OR_HEATING_SYSTEM.id,
              );
            }}
          />
          <Button
            className={
              'btn btn-primary mt-3 ' +
              (airConditioningSystem === null
                ? 'inactive'
                : airConditioningSystem === AirConditioningSystemEnum.OTHER_HOME_IMPROVEMENT.id
                ? ''
                : 'inactive')
            }
            pClassName="top-20"
            title="Other Home Improvement"
            clickHandler={() => {
              reactGAEvent({
                category: 'Button',
                action: 'Click',
                label: 'Other Home Improvement',
                did,
                dealerUserId,
              });
              dispatchChangePreQualInfoValue(
                'airConditioningSystem',
                AirConditioningSystemEnum.OTHER_HOME_IMPROVEMENT.id,
              );
            }}
          />
          <p
            className="text-danger mt-2 position-absolute"
            hidden={!(isRequired && airConditioningSystem === null)}
          >
            You must select an option.
          </p>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col />
        <Col sm="5">
          <h4>Are you the homeowner?</h4>
          <p>For mobile or manufactured homes, the applicant must own the land.</p>
        </Col>
        <Col sm="6">
          <Row>
            <Col sm="6">
              <Button
                className={
                  'btn btn-primary mt-3 ' +
                  (isHomeowner === null ? 'inactive' : isHomeowner ? '' : 'inactive')
                }
                title="Yes"
                clickHandler={() => {
                  reactGAEvent({
                    category: 'Button',
                    action: 'Click',
                    label: 'Yes',
                    did,
                    dealerUserId,
                  });
                  dispatchChangePreQualInfoValue('isHomeowner', true);
                }}
              />
            </Col>
            <Col sm="6">
              <Button
                className={
                  'btn btn-primary mt-3 ' +
                  (isHomeowner === null ? 'inactive' : isHomeowner ? 'inactive' : '')
                }
                pClassName="top-20"
                title="No"
                clickHandler={() => {
                  reactGAEvent({
                    category: 'Button',
                    action: 'Click',
                    label: 'No',
                    did,
                    dealerUserId,
                  });
                  dispatchChangePreQualInfoValue('isHomeowner', false);
                }}
              />
            </Col>
          </Row>
          <p className="text-danger mt-2" hidden={!isRequired ? true : isHomeowner}>
            You must be the homeowner to apply.
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm="6" />
        <Col sm="6">
          <Button
            className="mt-3"
            type="button"
            title="Next"
            clickHandler={() => {
              if (isHomeowner && airConditioningSystem !== null) {
                if (isRequired) setIsRequired(false);
                dispatchChangePage(MosaicPreQualificationFlowPageNumber.UserInfo);
                dispatchSubmitMindfire(mindfireRequest);
              } else {
                if (!isRequired) setIsRequired(true);
              }
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  airConditioningSystem: state.preQualificationInformation.airConditioningSystem,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  app: state.softPull.applicationSubmitDTO,
  isHomeowner: state.preQualificationInformation.isHomeowner,
  verifyAddressChecked: state.preQualificationInformation.verifyAddressChecked,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangePreQualInfoValue: (key, value) => dispatch(changePreQualInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

LoanFor.propTypes = {
  dealerUserId: PropTypes.number,
  did: PropTypes.string,
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoanFor);
