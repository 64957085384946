import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Field, Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Col, Container, FormGroup, Label, Row } from 'reactstrap';
import CustomInput from '../../components/UI/Form/Input/CustomInput';
import Button from '../../components/UI/Button/CustomButton';
import {
  changePage,
  submitMindfire,
  endLoading,
  applyToBeDealer,
  updateMerchantNumber,
} from '../../redux/actions/index';
import { withRouter } from 'react-router-dom';

import { changeContractorEnrollmentAtWaterGSValue } from '../../redux/actions/contractorEnrollmentAtWaterGSActions';

const MerchantNumber = props => {
  const {
    dispatchChangeValue,
    dispatchChangePage,
    children,
    isDealerIdCustomLink,
    customDealerId,
    dispatchUpdateMerchantNumber,
    history,
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  const MerchantNumberForm = ({ values, errors, touched }) => (
    <Form autoComplete="off">
      <Field
        component={CustomInput}
        label="Merchant Number"
        name="merchantNumber"
        id="merchantNumber"
        type="text"
        number
        maxLength={8}
      />

      <Button type="submit" title="Submit" />
    </Form>
  );

  const MerchantNumberFormFormik = withFormik({
    mapPropsToValues: () => ({
      merchantNumber: '',
    }),

    validationSchema: Yup.object({
      merchantNumber: Yup.string().required('Please enter Merchant Number.'),
    }),

    handleSubmit: values => {
      dispatchUpdateMerchantNumber(values.merchantNumber, customDealerId, history);
    },
  })(MerchantNumberForm);

  return (
    <Container>
      <Row>
        <Col sm="6">{children}</Col>
        <Col sm="6">
          <MerchantNumberFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  isDealerIdCustomLink: state.auth.isDealerIdCustomLink,
  customDealerId: state.auth.customDealerId,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeValue: (key, value) =>
    dispatch(changeContractorEnrollmentAtWaterGSValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
  dispatchEndLoading: () => dispatch(endLoading()),
  dispatchUpdateMerchantNumber: (merchantNumber, dealerId, history) =>
    dispatch(updateMerchantNumber(merchantNumber, dealerId, history)),
});

MerchantNumber.propTypes = {
  dispatchChangePage: PropTypes.func,
  dispatchChangeValue: PropTypes.func,
  dispatchSubmitSoftPullAquaApplication: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(MerchantNumber),
);
