import { Field, Form, withFormik } from 'formik';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { GoSearch } from 'react-icons/go';
import { connect } from 'react-redux';
import { Button, Col, Label, Row } from 'reactstrap';
import DatePicker from '../../../components/UI/Form/DatePicker/DatePickerField';
import InputField from '../../../components/UI/Form/Input/InputField';
import { changeSoftPullValue } from '../../../redux/actions/index';

const SearchApplicationCriteria = props => {
  const {
    searchApplications,
    searchApplicationsCriteria,
    dispatchChangeSoftPullValue,
    showAllSearchOptions,
  } = props;
  const [show, setShow] = useState(true);
  const [showAllOptions, setShowAllOptions] = useState(false);
  const search = queryString.parse(window.location.search).search;

  const toggleOptions = () => {
    setShowAllOptions(!showAllOptions);
    dispatchChangeSoftPullValue('showAllSearchOptions', !showAllOptions);
  };

  const resetSearchOptions = () => {
    dispatchChangeSoftPullValue('searchApplicationsCriteria', {
      assignmentStatus: '',
      firstName: '',
      lastName: '',
      applyDate: '',
      dealer: '',
    });
  };

  useEffect(() => {
    setShowAllOptions(showAllSearchOptions);
    if (search) {
      searchApplications(searchApplicationsCriteria);
    }
    // eslint-disable-next-line
  }, []);

  const SearchApplicationCriteriaForm = ({ values }) => (
    <div className="search-panel">
      <Form>
        <Row>
          <Col sm={{ size: 4, offset: 4 }}>
            <div>
              <Label for="assignmentStatus">Assignment Status</Label>
              <Field
                component="select"
                id="assignmentStatus"
                name="assignmentStatus"
                className="form-control"
              >
                <option value="" />
                <option value="Assigned">Assigned</option>
                <option value="Unassigned">Unassigned</option>
              </Field>
            </div>
            <div className="form-group text-center">
              <a href="#top" onClick={toggleOptions}>
                {showAllOptions ? 'Less search options' : 'More search options'}
              </a>
            </div>
          </Col>
          {showAllOptions ? (
            <>
              <Col sm="6">
                <div className="form-group">
                  <Label for="lastName">Last Name</Label>
                  <Field component={InputField} name="lastName" id="lastName" type="text" />
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group">
                  <Label for="firstName">First Name</Label>
                  <Field component={InputField} name="firstName" id="firstName" type="text" />
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group">
                  <Label for="applyDate">Apply Date</Label>
                  <Field component={DatePicker} name="applyDate" id="applyDate" onChange />
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group">
                  <Label for="dealer">Contractor</Label>
                  <Field component={InputField} name="dealer" id="dealer" type="text" />
                </div>
              </Col>
            </>
          ) : null}
        </Row>
        <Row>
          <Col className="text-right">
            <Button
              onClick={() => resetSearchOptions()}
              color="secondary"
              className="rounded-pill"
              outline
            >
              Clear
            </Button>
            <Button type="submit" color="primary" className="rounded-pill">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );

  const SearchApplicationCriteriaFormFormik = withFormik({
    mapPropsToValues: () => ({
      assignmentStatus: searchApplicationsCriteria.assignmentStatus,
      lastName: searchApplicationsCriteria.lastName,
      firstName: searchApplicationsCriteria.firstName,
      applyDate: searchApplicationsCriteria.applyDate,
      dealer: searchApplicationsCriteria.dealer,
    }),

    handleSubmit: values => {
      const searchOptions = {
        ...searchApplicationsCriteria,
        assignmentStatus: values.assignmentStatus,
        firstName: values.firstName,
        lastName: values.lastName,
        applyDate: values.applyDate,
        dealer: values.dealer,
      };

      dispatchChangeSoftPullValue('searchApplicationsCriteria', searchOptions);
      searchApplications(searchOptions);
    },
  })(SearchApplicationCriteriaForm);

  return (
    <div className="search-form">
      <div className="search-btn">
        <span onClick={() => setShow(!show)}>
          <GoSearch size={18} />
          <span>Search Criteria</span>
        </span>
      </div>
      {show ? <SearchApplicationCriteriaFormFormik /> : null}
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  showAllSearchOptions: state.softPull.showAllSearchOptions,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangeSoftPullValue: (key, value) => dispatch(changeSoftPullValue(key, value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchApplicationCriteria);
