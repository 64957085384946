import AccountEmail from './AccountEmail';
import React from 'react';
import AccountPassword from './AccountPassword';
import LoanFor from './LoanFor';
import NotAllowed from './NotAllowed';
import ScoreError from './ScoreError';
import UserAddress from './UserAddress';
import UserInfo from './UserInfo';
import UserLastInfo from './UserLastInfo';
import Landing from './Landing';
import Review from './Review';
import Declined from './Declined';
import InvalidResponse from './InvalidResponse';

const PreQualificationFlow = [
  //1
  <Landing />,
  //2
  <AccountEmail>
    <h4>Let’s start with your email address.</h4>
  </AccountEmail>,
  //3
  <AccountPassword>
    <h4>Choose a password that you can remember.</h4>
  </AccountPassword>,
  //4
  <LoanFor />,
  //5
  <UserInfo>
    <h4>What’s your name and number?</h4>
  </UserInfo>,
  //6
  <UserAddress>
    <h4>What is your address?</h4>
  </UserAddress>,
  //7
  <Review>
    <h4>Let's double check the information you've provided.</h4>
  </Review>,
  //8
  <UserLastInfo />,
  //9
  <NotAllowed />,
  //10
  <ScoreError>
    <h4>We could not return a Soft Pull score.</h4>
  </ScoreError>,
  <InvalidResponse />,
];

export default PreQualificationFlow;

export const MosaicPreQualificationFlowPageNumber = {
  Landing: 0,
  AccountEmail: 1,
  AccountPassword: 2,
  LoanFor: 3,
  UserInfo: 4,
  UserAddress: 5,
  Review: 6,
  UserLastInfo: 7,
  NotAllowed: 8,
  ScoreError: 9,
  InvalidResponse: 10,
};
