import React from 'react';
import logo_kwick_comfort_call from '../../assets/img/logo_kwick_comfort_call.jpg';

import { Col, Container, Row } from 'reactstrap';
import MicrofWorkingHours from '../Microf/MicrofWorkingHours';

const ContactInformation = () => (
  <Container>
    <Row>
      <Col className="text-center">
        <a href="tel:1-866-480-5945">
          <img src={logo_kwick_comfort_call} alt="Call" className="logo-sf-call" />
        </a>
      </Col>
    </Row>
    <Row className="justify-content-center">
      <Col xs="12">
        <h4 className="text-center">
          Click above
          <br />
          or
          <br /> dial{' '}
          <a href="tel:1-866-480-5945" className="kwik-comfort-tel">
            <u>1-866-480-5945</u>
          </a>{' '}
          to
          <br /> complete homeowner interview
        </h4>
      </Col>
    </Row>
    <MicrofWorkingHours />
  </Container>
);

export default ContactInformation;
