import React, { useEffect } from 'react';
import Section from '../../components/Section/Section';
import Header from '../../components/UI/Header/Header';
import AquaFlow from './AquaFlow';
import { connect } from 'react-redux';
import { setFlow } from '../../redux/actions/index';
import {
  aquaFlow,
  microfFlow,
  wellsFargoFlow,
  powerPayFlow,
  rheemFlow,
  powerPayPreQualificationFlow,
  mosaicPreQualificationFlow,
  mosaicFlow,
  enerBankPreQualificationFlow,
  enerBankFlow,
} from '../../redux/initialState';
import PropTypes from 'prop-types';

const Aqua = ({
  app,
  score,
  history,
  aquaFlowCurrentPage,
  dispatchSetFlow,
  flowName,
  ...props
}) => {
  useEffect(() => {
    if (app != null) {
      if (flowName !== aquaFlow) {
        dispatchSetFlow(microfFlow);
        history.push('/RTO');
      }
    } else if (score === null) {
      history.push('/');
    } else if (flowName === microfFlow) {
      history.push('/RTO');
    } else if (flowName === rheemFlow) {
      history.push('/rheem');
    } else if (flowName === powerPayFlow) {
      history.push('/powerpay');
    } else if (flowName === wellsFargoFlow) {
      history.push('/WellsFargo');
    } else if (flowName === powerPayPreQualificationFlow) {
      history.push('/powerpay-prequalification');
    } else if (flowName === mosaicPreQualificationFlow) {
      history.push('/mosaic-prequalification');
    } else if (flowName === mosaicFlow) {
      history.push('/mosaic');
    } else if (flowName === enerBankPreQualificationFlow) {
      history.push('/enerbank-prequalification');
    } else if (flowName === enerBankFlow) {
      history.push('/enerbank');
    } else {
      dispatchSetFlow(aquaFlow);
    }
    // eslint-disable-next-line
  }, []);

  if (props)
    return (
      <React.Fragment>
        <Header
          title={AquaFlow[aquaFlowCurrentPage ? aquaFlowCurrentPage : 0].props.title}
          pages={AquaFlow.length - 1}
        />
        <Section Component={AquaFlow[aquaFlowCurrentPage ? aquaFlowCurrentPage : 0]} />
      </React.Fragment>
    );
};

const mapStateToProps = state => ({
  aquaFlowCurrentPage: state.navi.aquaFlowCurrentPage,
  score: state.softPull.softPullResponse.score,
  flowName: state.navi.flowName,
  app: state.softPull.applicationSubmitDTO,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetFlow: flow => dispatch(setFlow(flow)),
});

Aqua.propTypes = {
  aquaFlowCurrentPage: PropTypes.number.isRequired,
  dispatchSetFlow: PropTypes.func,
  score: PropTypes.number,
  flowName: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Aqua);
