import React from 'react';
import { GoInfo } from 'react-icons/go';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import { moveAppToContractor } from '../../../redux/actions';

const Confirm = props => {
  const {
    dispatchMoveAppToContractor,
    setPageIndex,
    application,
    targetContractor,
    pageIndex,
  } = props;

  const style = {
    width: ((pageIndex + 1) * 33).toString() + '%',
  };

  return (
    <Col>
      <h2 id="panelTitle">Move an APP to Another Contractor</h2>
      <Row noGutters>
        <Col xs="12" className="progress">
          <div className="active" style={style} />
        </Col>
      </Row>
      <Row noGutters className="panel">
        <Col className="panel-body larger">
          <Row>
            <Col className="right-bordered">
              <table className="table mb-0">
                <thead className="thead-light">
                  <tr>
                    <th>Current Contractor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{application.rtoNumber}</td>
                  </tr>
                  <tr>
                    <td>App ID: {application.applicationId}</td>
                  </tr>
                  <tr>
                    <td>
                      Current Contractor: {application.dealer ? application.dealer.name : 'N/A'}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Current Contractor User:{' '}
                      {application.dealerUser && application.dealerUser.dealerUser
                        ? application.dealerUser.dealerUser.email
                        : 'N/A'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col className="right-bordered">
              <table className="table mb-0">
                <thead className="thead-light">
                  <tr>
                    <th>Target Contractor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{application.rtoNumber}</td>
                  </tr>
                  <tr>
                    <td>App ID: {application.applicationId}</td>
                  </tr>
                  <tr>
                    <td>Current Contractor: {targetContractor.dealer}</td>
                  </tr>
                  <tr>
                    <td>Current Contractor User: {targetContractor.email}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
          <p className="mt-4 text-center">
            <GoInfo /> Warning: This application is about to be moved in Rojos and in OFSLL to the
            target dealer.
          </p>
          <Row noGutters>
            <Col className="text-right">
              <Button
                color="primary"
                className="rounded-pill"
                onClick={() => {
                  setPageIndex(1);
                }}
              >
                Previous
              </Button>
              <Button
                type="submit"
                color="primary"
                className="rounded-pill"
                onClick={() => {
                  dispatchMoveAppToContractor(
                    application.applicationId,
                    targetContractor.id,
                    setPageIndex,
                  );
                }}
              >
                Move the application
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

const mapStateToProps = state => ({
  dealerId: state.auth.dealerId,
  application: state.softPull.applicationMoveApp,
  targetContractor: state.softPull.moveAppTargetContractor,
});

const mapDispatchToProps = dispatch => ({
  dispatchMoveAppToContractor: (applicationId, targetContractorId, successCallback) =>
    dispatch(moveAppToContractor(applicationId, targetContractorId, successCallback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Confirm);
