import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col, Alert } from 'reactstrap';
import Checkmark from '../../../components/UI/Checkmark/Checkmark';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { getAppName } from '../../../config/settings';

const appName = getAppName();

const NextSteps = props => {
  const { dealer, user, history } = props;
  useEffect(() => {
    if (!user || !user.dealerUser) {
      history.push('/login');
    }
    // eslint-disable-next-line
  }, []);

  const hasDealer = !!dealer;
  let dfaSigned = dealer && dealer.dfaSigned;

  return (
    <Col>
      <Row>
        <Col>
          <h1>Contractor Enrollment</h1>
        </Col>
      </Row>
      <Row>
        <div className="app-panel">
          <div className="app-panel-body">
            <h2>Next Steps</h2>

            <Checkmark text="Complete Contractor Application" isOK={hasDealer} />
            {hasDealer && !dfaSigned ? (
              <Alert color="success">
                <IoIosInformationCircleOutline className="ok size-24" />
                Your application is under review. {appName} will contact you when the contractor
                application is approved. Thank you for your patience.
              </Alert>
            ) : null}

            <Checkmark text="Sign and Complete Onboarding Package" isOK={dfaSigned} />

            <Checkmark text="Wait for Contracting Vetting to Complete" isOK={false} />

            <Checkmark text={'Complete Required ' + appName + ' Training'} isOK={false} />
          </div>
        </div>
      </Row>
    </Col>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
  dealer: state.auth.user && state.auth.user.dealerUser ? state.auth.user.dealerUser.dealer : null,
});

export default withRouter(
  connect(
    mapStateToProps,
    null,
  )(NextSteps),
);
