import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import CustomInput from '../../../../components/UI/Form/Input/CustomInput';
import CustomDatePicker from '../../../../components/UI/Form/DatePicker/DatePicker';
import {
  changeApplicationValues,
  reactGAEvent,
  reactGAPageview,
  validDateFormat,
} from '../../../../helper';
import usStates from '../../../../assets/js/USStateEnum';
import idTypes from '../../../../assets/js/IdTypeEnum';
import {
  changePage,
  submitMindfire,
  changeAtWaterApplicationInfoValue,
} from '../../../../redux/actions/index';
import { AtWaterFlowPageNumber } from './AtWaterFlow';
import Select from '../../../../components/UI/Form/Select/Select';
import SelectWithCustomOnChange from '../../../../components/UI/Form/Select/SelectWithCustomOnChange';
import { AgeFromDateString } from 'age-calculator';

const CoApplicantIdentificationInformation = props => {
  const {
    dispatchChangePage,
    dispatchChangeAtWaterApplicationInfoValue,
    children,
    did,
    dealerUserId,
    coAppSsn,
    coAppDoB,
    coAppIdType,
    coAppIdNumber,
    coAppIdIssuedState,
    coAppIdIssueDate,
    coAppIdExpirationDate,
    ssnLastDigits,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_gsloaninformation',
      trackerNames: [],
      title: 'ca_gsloaninformation',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const handleIdTypeChange = value => {};

  const IdentificationInformationForm = ({ values }) => (
    <Form noValidate>
      <Field
        maxLength={9}
        component={CustomInput}
        label="Full SSN"
        id="coAppSsn"
        name="coAppSsn"
        tooltipText={''}
        type="password"
        number
        icon="lock"
      />
      <Field
        component={CustomDatePicker}
        name="coAppDoB"
        id="coAppDoB"
        tooltipText={''}
        label="Your Date of Birth"
      />
      <Field
        component={SelectWithCustomOnChange}
        label="ID Type"
        name="coAppIdType"
        id="coAppIdType"
        tooltipText={''}
        type="select"
        selectValues={idTypes}
        selectFirstName="Select One"
        handleChange={e => handleIdTypeChange(values)}
      />
      <Field
        maxLength={30}
        component={CustomInput}
        label="ID #"
        name="coAppIdNumber"
        id="coAppIdNumber"
        tooltipText={''}
        type="text"
      />
      <Field
        component={Select}
        label="Issue State"
        name="coAppIdIssuedState"
        id="coAppIdIssuedState"
        tooltipText={''}
        type="select"
        selectValues={usStates}
        selectFirstName="Select One"
      />
      <Field
        component={CustomDatePicker}
        name="coAppIdIssueDate"
        id="coAppIdIssueDate"
        tooltipText={''}
        label="Issue Date"
      />
      <Field
        component={CustomDatePicker}
        name="coAppIdExpirationDate"
        id="coAppIdExpirationDate"
        tooltipText={''}
        label="Expiration Date"
      />
      <Button type="submit" title="Next" />
    </Form>
  );

  const IdentificationInformationFormFormik = withFormik({
    mapPropsToValues: () => ({
      coAppSsn: coAppSsn || '',
      coAppDoB: coAppDoB || '',
      coAppIdType: coAppIdType || '',
      coAppIdNumber: coAppIdNumber || '',
      coAppIdIssuedState: coAppIdIssuedState || '',
      coAppIdIssueDate: coAppIdIssueDate || '',
      coAppIdExpirationDate: coAppIdExpirationDate || '',
    }),

    validationSchema: Yup.object({
      coAppSsn: Yup.string()
        .required('Please enter your full SSN.')
        .test(
          'len',
          'Must have 9 digits',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 9,
        )
        .typeError('Numbers only.'),
      coAppDoB: Yup.string()
        .required('Please enter your date of birth.')
        .test(
          'incomplete',
          'Incomplete date',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 8,
        )
        .test('valid', 'Invalid date format.', val => val && !validDateFormat(val))
        .test(
          'age',
          `You must be at least ${18} years old.`,
          val => val && new AgeFromDateString(val).age >= 18,
        ),
      coAppIdType: Yup.string().required('Please select your ID type.'),
      coAppIdNumber: Yup.string().required('Please enter your ID number.'),
      coAppIdIssuedState: Yup.string().required('Please select a state.'),
      coAppIdIssueDate: Yup.string()
        .required('Please enter the issue date.')
        .test(
          'incomplete',
          'Incomplete date',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 8,
        )
        .test('valid', 'Invalid date format.', val => val && !validDateFormat(val))
        .test('maxDate', 'Invalid date.', val => val && new Date(val) < new Date()),
      coAppIdExpirationDate: Yup.string()
        .required('Please enter the expiration date.')
        .test(
          'incomplete',
          'Incomplete date',
          val =>
            val &&
            val
              .toString()
              .match(/\d/g)
              .join('').length === 8,
        )
        .test('valid', 'Invalid date format.', val => val && !validDateFormat(val))
        .test(
          'minDate',
          'Invalid date.',
          val => val && new Date(val) >= new Date().setHours(0, 0, 0, 0),
        ),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Next',
        did,
        dealerUserId,
      });

      changeApplicationValues(dispatchChangeAtWaterApplicationInfoValue, values);
      dispatchChangePage(AtWaterFlowPageNumber.CoApplicantAddress);
    },
  })(IdentificationInformationForm);

  return (
    <Container>
      <Row>
        <BackBtn changePage={dispatchChangePage} page={AtWaterFlowPageNumber.CoApplicantName} />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <IdentificationInformationFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  ssnLastDigits: state.preQualificationInformation.ssnLastDigits || '',
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  dealerId: state.auth.dealerId,
  programsAndProducts: state.atWaterApplicationInformation.programsAndProducts,
  coAppSsn: state.atWaterApplicationInformation.coAppSsn,
  coAppDoB: state.atWaterApplicationInformation.coAppDoB,
  coAppIdType: state.atWaterApplicationInformation.coAppIdType,
  coAppIdNumber: state.atWaterApplicationInformation.coAppIdNumber,
  coAppIdIssuedState: state.atWaterApplicationInformation.coAppIdIssuedState,
  coAppIdIssueDate: state.atWaterApplicationInformation.coAppIdIssueDate,
  coAppIdExpirationDate: state.atWaterApplicationInformation.coAppIdExpirationDate,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeAtWaterApplicationInfoValue: (key, value) =>
    dispatch(changeAtWaterApplicationInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CoApplicantIdentificationInformation);
