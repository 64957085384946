import { FTLFlowPageNumber } from '../../pages/FTL/Flow/FTLFlow';
import { FTLPreQualificationFlowPageNumber } from '../../pages/FTL/PreQualification/PreQualificationFlow';
import { MicrofFlowPageNumber } from '../../pages/Microf/MicrofFlow';
import { AtWaterPreQualificationFlowPageNumber } from '../../pages/Pools/AtWater/PreQualification/PreQualificationFlow';
import { UpgradePreQualificationFlowPageNumber } from '../../pages/Upgrade/PreQualification/preQualificationFlow';
import {
  mosaicPreQualificationFlow,
  powerPayPreQualificationFlow,
  preQualificationFlow,
  greenSkyPreQualificationFlow,
  microfFlow,
  aquaFlow,
  rheemFlow,
  wellsFargoFlow,
  powerPayFlow,
  mosaicFlow,
  greenSkyFlow,
  foundationPreQualificationFlow,
  foundationFlow,
  enerBankPreQualificationFlow,
  enerBankFlow,
  ftlPreQualificationFlow,
  ftlFlow,
  atWaterPreQualificationFlow,
  atWaterFlow,
  atWaterGreenSkyPreQualificationFlow,
  atWaterGreenSkyFlow,
  upgradePreQualificationFlow,
} from '../../redux/initialState';

const ApplicationFlowEnum = {
  AQUA_PREQUALIFICATION: { value: preQualificationFlow, path: '/' },
  POWERPAY_PREQUALIFICATION: {
    value: powerPayPreQualificationFlow,
    path: '/powerpay-prequalification',
  },
  MOSAIC_PREQUALIFICATION: { value: mosaicPreQualificationFlow, path: '/mosaic-prequalification' },
  GREENSKY_PREQUALIFICATION: {
    value: greenSkyPreQualificationFlow,
    path: '/greensky-prequalification',
  },
  MICROF_FLOW: {
    value: microfFlow,
    path: '/RTO',
    progressList: [
      { value: 'Personal Info', activeIndex: MicrofFlowPageNumber.AccountEmail },
      { value: 'Household', activeIndex: MicrofFlowPageNumber.Homeowner },
      { value: 'Equipment', activeIndex: MicrofFlowPageNumber.HvacSystemType },
      { value: 'Financial', activeIndex: MicrofFlowPageNumber.AppIncomeInfo },
      { value: 'Co-Applicant', activeIndex: MicrofFlowPageNumber.HasCoApplicant },
      { value: 'Final Review', activeIndex: MicrofFlowPageNumber.Review },
    ],
  },
  AQUA_FLOW: { value: aquaFlow, path: '/Aqua' },
  RHEEM_FLOW: { value: rheemFlow, path: '/rheem' },
  WELLSFARGO_FLOW: { value: wellsFargoFlow, path: '/WellsFargo' },
  POWERPAY_FLOW: { value: powerPayFlow, path: '/powerpay' },
  MOSAIC_FLOW: { value: mosaicFlow, path: '/mosaic' },
  GREENSKY_FLOW: { value: greenSkyFlow, path: '/greensky' },
  FOUNDATION_PREQUALIFICATION: {
    value: foundationPreQualificationFlow,
    path: '/foundation-prequalification',
  },
  FOUNDATION_FLOW: { value: foundationFlow, path: '/foundation' },
  ENERBANK_PREQUALIFICATION_FLOW: {
    value: enerBankPreQualificationFlow,
    path: '/enerbank-prequalification',
  },
  ENERBANK_FLOW: { value: enerBankFlow, path: '/enerbank' },
  FTL_PREQUALIFICATION_FLOW: {
    value: ftlPreQualificationFlow,
    path: '/ftl-prequalification',
    progressList: [
      { value: 'Personal Info', activeIndex: FTLPreQualificationFlowPageNumber.AccountEmail },
      { value: 'General Info', activeIndex: FTLPreQualificationFlowPageNumber.LoanFor },
      { value: 'Soft Pull', activeIndex: FTLPreQualificationFlowPageNumber.UserLastInfo },
    ],
  },
  FTL_FLOW: {
    value: ftlFlow,
    path: '/ftl',
    progressList: [
      { value: 'Financing Info', activeIndex: FTLFlowPageNumber.LoanAmount },
      { value: 'Household Info', activeIndex: FTLFlowPageNumber.BillingAddress },
      { value: 'Income Info', activeIndex: FTLFlowPageNumber.EmploymentInformation },
      { value: 'Identification Info', activeIndex: FTLFlowPageNumber.IdentificationInformation },
      { value: 'Co-Applicant', activeIndex: FTLFlowPageNumber.HasCoApplicant },
      { value: 'Final Review', activeIndex: FTLFlowPageNumber.Review },
    ],
  },
  ATWATER_PREQUALIFICATION: {
    value: atWaterPreQualificationFlow,
    path: '/atwater-prequalification',
    progressList: [
      { value: 'Personal Info', activeIndex: AtWaterPreQualificationFlowPageNumber.AccountEmail },
      { value: 'Soft Pull', activeIndex: AtWaterPreQualificationFlowPageNumber.UserLastInfo },
    ],
  },
  ATWATER_FLOW: { value: atWaterFlow, path: '/atWater' },
  ATWATER_GREENSKY_PREQUALIFICATION: {
    value: atWaterGreenSkyPreQualificationFlow,
    path: '/atwater-greensky-prequalification',
  },
  ATWATER_GREENSKY_FLOW: { value: atWaterGreenSkyFlow, path: '/atWater-greensky' },

  UPGRADE_PREQUALIFICATION: {
    value: upgradePreQualificationFlow,
    path: '/upgrade-prequalification',
  },
};

export default ApplicationFlowEnum;
