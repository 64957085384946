import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import YesNoComponent from '../../components/UI/YesNoComponent/YesNoComponent';
import { reactGAEvent, reactGAPageview } from '../../helper';
import { changePage, submitMindfire, changeWellsFargoValue } from '../../redux/actions';
import { WellsFargoFlowPageNumber } from './WellsFargoFlow';

const HasCoApplicant = props => {
  const {
    email,
    dispatchChangePage,
    dispatchChangeWellsFargoValue,
    isReview,
    dispatchSubmitMindfire,
    children,
    did,
    dealerUserId,
    coApplicantAdded,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/wf_coapplicant',
      trackerNames: [],
      title: 'wf_coapplicant',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const mindFireRequest = { email: email, lastPage: '5008', dealerUserId: dealerUserId, did: did };

  return (
    <YesNoComponent
      isWellsFargo={true}
      backpage={isReview ? WellsFargoFlowPageNumber.Review : WellsFargoFlowPageNumber.UserAddress}
      upperButtonText="Yes"
      lowerButtonText="No"
      upperBtnClickHandler={() => {
        reactGAEvent({
          category: 'Button',
          action: 'Click',
          label: 'Yes',
          did,
          dealerUserId,
        });
        dispatchChangePage(WellsFargoFlowPageNumber.CoAppDetails);
        dispatchChangeWellsFargoValue('hasCoApp', true);
        dispatchSubmitMindfire(mindFireRequest);
      }}
      lowerBtnClickHandler={() => {
        reactGAEvent({
          category: 'Button',
          action: 'Click',
          label: 'No',
          did,
          dealerUserId,
        });
        dispatchChangePage(WellsFargoFlowPageNumber.Review);
        dispatchChangeWellsFargoValue('hasCoApp', false);
        dispatchSubmitMindfire(mindFireRequest);
      }}
      lowerButtonDisabled={coApplicantAdded ? true : false}
    >
      {children}
    </YesNoComponent>
  );
};

const mapStateToProps = state => ({
  email: state.wellsFargoInformation.email,
  isReview: state.wellsFargoInformation.isReview,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
  coApplicantAdded:
    state.softPull.applicationSubmitDTO !== null
      ? state.softPull.applicationSubmitDTO.applicantNonPrimary
      : null,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeWellsFargoValue: (key, value) => dispatch(changeWellsFargoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

HasCoApplicant.propTypes = {
  dispatchChangePage: PropTypes.func,
  dispatchChangeWellsFargoValue: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HasCoApplicant);
