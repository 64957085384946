import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import FTLEmploymentTypeEnum from '../../../../assets/js/FTLEmploymentTypeEnum';
import USStateEnum from '../../../../assets/js/USStateEnum';
import BackBtn from '../../../../components/UI/BackButton/BackButton';
import Button from '../../../../components/UI/Button/CustomButton';
import CustomInput from '../../../../components/UI/Form/Input/CustomInput';
import CurrencyFormat from '../../../../components/UI/Form/NumberFormat/CurrencyFormat';
import PhoneNumber from '../../../../components/UI/Form/PhoneNumber/PhoneNumber';
import Select from '../../../../components/UI/Form/Select/Select';
import SelectWithHandleChange from '../../../../components/UI/Form/Select/SelectWithHandleChange';
import {
  ANNUAL_INCOME_TOOLTIP_TEXT,
  EMPLOYER_CITY_TOOLTIP_TEXT,
  EMPLOYER_NAME_TOOLTIP_TEXT,
  EMPLOYER_PHONE_NUMBER_TOOLTIP_TEXT,
  EMPLOYER_STATE_TOOLTIP_TEXT,
  EMPLOYER_STREET_TOOLTIP_TEXT,
  EMPLOYER_ZIPCODE_TOOLTIP_TEXT,
  EMPLOYMENT_STATUS_TOOLTIP_TEXT,
  OCCUPATION_TOOLTIP_TEXT,
} from '../../../../constants/tooltipTexts';
import { isApplicantEmployedFTL, reactGAEvent, reactGAPageview } from '../../../../helper';
import { changeFtlInfoValue, changePage, submitMindfire } from '../../../../redux/actions/index';
import { FTLFlowPageNumber } from '../FTLFlow';

const EmploymentInformation = props => {
  const {
    email,
    dispatchChangePage,
    dispatchChangeFtlInfoValue,
    ftlInformation: {
      appEmploymentStatus,
      appEmployerName,
      appOccupation,
      appEmployerStreet,
      appEmployerCity,
      appEmployerZipCode,
      appEmployerState,
      appEmployerPhoneNumber,
      appAnnualIncome,
    },
    children,
    dispatchSubmitMindfire,
    did,
    dealerUserId,
    isReview,
  } = props;
  let isEmployed = isApplicantEmployedFTL(appEmploymentStatus);

  useEffect(() => {
    reactGAPageview({
      path: '/ca_ftlconsumerincome1',
      trackerNames: [],
      title: 'ca_ftlconsumerincome1',
      dealerUserId,
      did,
    });
    // eslint-disable-next-line
  }, []);

  const changeValues = values => {
    isEmployed = isApplicantEmployedFTL(appEmploymentStatus);

    dispatchChangeFtlInfoValue('appEmployerName', isEmployed ? values.appEmployerName : null);
    dispatchChangeFtlInfoValue('appEmploymentStatus', values.appEmploymentStatus);
    dispatchChangeFtlInfoValue('appOccupation', isEmployed ? values.appOccupation : null);
    dispatchChangeFtlInfoValue(
      'appEmploymentYears',
      isEmployed ? parseInt(values.appEmploymentYears) : null,
    );
    dispatchChangeFtlInfoValue(
      'appEmploymentMonths',
      isEmployed ? parseInt(values.appEmploymentMonths) : null,
    );
    dispatchChangeFtlInfoValue('appAnnualIncome', values.appAnnualIncome);
    dispatchChangeFtlInfoValue('isAppEmployed', isEmployed);

    dispatchChangeFtlInfoValue('appEmployerStreet', isEmployed ? values.appEmployerStreet : null);
    dispatchChangeFtlInfoValue('appEmployerCity', isEmployed ? values.appEmployerCity : null);
    dispatchChangeFtlInfoValue('appEmployerState', isEmployed ? values.appEmployerState : null);
    dispatchChangeFtlInfoValue('appEmployerZipCode', isEmployed ? values.appEmployerZipCode : null);
    dispatchChangeFtlInfoValue(
      'appEmployerPhoneNumber',
      isEmployed ? values.appEmployerPhoneNumber : null,
    );
  };

  const EmploymentInformationForm = ({ values }) => (
    <Form>
      <Field
        component={SelectWithHandleChange}
        label="Income (or Benefits) Type"
        id="appEmploymentStatus"
        tooltipText={EMPLOYMENT_STATUS_TOOLTIP_TEXT}
        name="appEmploymentStatus"
        type="select"
        selectValues={FTLEmploymentTypeEnum}
        selectFirstName="Select One"
        handleChange={e => changeValues({ ...values, appEmploymentStatus: e.target.value })}
      />
      {isEmployed && (
        <>
          <Field
            component={CustomInput}
            label="Employer"
            name="appEmployerName"
            id="appEmployerName"
            tooltipText={EMPLOYER_NAME_TOOLTIP_TEXT}
            type="text"
          />
          <Field
            component={CustomInput}
            label="Title/Occupation"
            name="appOccupation"
            id="appOccupation"
            tooltipText={OCCUPATION_TOOLTIP_TEXT}
            type="text"
          />
          <Field
            component={CustomInput}
            label="Employer Street"
            name="appEmployerStreet"
            id="appEmployerStreet"
            tooltipText={EMPLOYER_STREET_TOOLTIP_TEXT}
            type="text"
          />
          <Row>
            <Col sm="6">
              <Field
                component={CustomInput}
                label="Employer City"
                name="appEmployerCity"
                id="appEmployerCity"
                tooltipText={EMPLOYER_CITY_TOOLTIP_TEXT}
                type="text"
              />
            </Col>
            <Col sm="6">
              <Field
                component={Select}
                label="Employer State"
                name="appEmployerState"
                id="appEmployerState"
                tooltipText={EMPLOYER_STATE_TOOLTIP_TEXT}
                type="select"
                selectValues={USStateEnum}
                selectFirstName="Select State"
              />
            </Col>
          </Row>
          <Field
            component={CustomInput}
            label="Employer ZIP Code"
            name="appEmployerZipCode"
            id="appEmployerZipCode"
            tooltipText={EMPLOYER_ZIPCODE_TOOLTIP_TEXT}
            type="text"
            maxLength={10}
          />
          <Field
            component={PhoneNumber}
            label="Employer Phone Number"
            name="appEmployerPhoneNumber"
            id="appEmployerPhoneNumber"
            tooltipText={EMPLOYER_PHONE_NUMBER_TOOLTIP_TEXT}
          />
        </>
      )}
      <Field
        component={CurrencyFormat}
        label="Annual Income (or Benefits) amount"
        name="appAnnualIncome"
        id="appAnnualIncome"
        tooltipText={ANNUAL_INCOME_TOOLTIP_TEXT}
        type="text"
      />
      <Button type="submit" title="Continue" />
    </Form>
  );

  const EmploymentInformationFormFormik = withFormik({
    mapPropsToValues: () => ({
      appEmploymentStatus: appEmploymentStatus || '',
      appEmployerName: appEmployerName || '',
      appOccupation: appOccupation || '',
      appEmployerStreet: appEmployerStreet || '',
      appEmployerCity: appEmployerCity || '',
      appEmployerZipCode: appEmployerZipCode || '',
      appEmployerState: appEmployerState || '',
      appEmployerPhoneNumber: appEmployerPhoneNumber || '',
      appAnnualIncome: appAnnualIncome || '',
    }),

    validationSchema: Yup.object({
      appEmploymentStatus: Yup.string().required('Please select the employment status.'),
      appEmployerName: isEmployed
        ? Yup.string().required("Please enter your employer's name.")
        : Yup.string(),
      appOccupation: isEmployed
        ? Yup.string().required('Please enter your occupation.')
        : Yup.string(),
      appEmployerStreet: isEmployed
        ? Yup.string().required('Please enter a street.')
        : Yup.string(),
      appEmployerCity: isEmployed ? Yup.string().required('Please enter a city.') : Yup.string(),
      appEmployerZipCode: isEmployed
        ? Yup.string()
            .matches(/^\d{5}?$/, {
              message: 'Invalid zip code.',
              excludeEmptyString: true,
            })
            .required('Please enter a zip code.')
        : Yup.string(),
      appEmployerState: isEmployed ? Yup.string().required('Please select a state.') : Yup.string(),
      appEmployerPhoneNumber: isEmployed
        ? Yup.string()
            .test(
              'required',
              "Please enter the Employer's phone number.",
              val => val !== '+' && val,
            )
            .test(
              'len',
              'Invalid phone number',
              val => val && val.match(/\d/g).join('').length === 10,
            )
        : Yup.string(),
      appAnnualIncome: Yup.number().required('Please enter your annual income.'),
    }),

    handleSubmit: values => {
      reactGAEvent({
        category: 'Button',
        action: 'Click',
        label: 'Continue',
        did: did,
        dealerUserId: dealerUserId,
      });
      changeValues(values);
      dispatchChangePage(
        isReview ? FTLFlowPageNumber.Review : FTLFlowPageNumber.IdentificationInformation,
      );
      dispatchSubmitMindfire({
        email: email,
        company: values.appEmployerName,
        lastPage: '12011',
        dealerUserId: dealerUserId,
        did: did,
      });
    },
  })(EmploymentInformationForm);

  return (
    <Container>
      <Row>
        <BackBtn
          changePage={dispatchChangePage}
          page={isReview ? FTLFlowPageNumber.Review : FTLFlowPageNumber.BillingAddress}
        />
        <Col sm="5">{children}</Col>
        <Col sm="6">
          <EmploymentInformationFormFormik />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  isReview: state.ftlInformation.isReview,
  ftlInformation: state.ftlInformation,
  email: state.preQualificationInformation.email,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangeFtlInfoValue: (key, value) => dispatch(changeFtlInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmploymentInformation);
