const productTypeEnum = {
  BASEMENT_FINISHING: { value: 'BasementFinishing', name: 'Basement Finishing' },
  BASEMENT_WATERPROOFING: { value: 'BasementWaterproofing', name: 'Basement Waterproofing' },
  BATHROOM_REMODEL: { value: 'BathroomRemodel', name: 'Bathroom Remodel' },
  BLINDS_SHADES: { value: 'BlindsShades', name: 'Blinds/Shades' },
  CEMENT_CONCRETE: { value: 'CementConcrete', name: 'Cement/Concrete' },
  DOG_FENCES: { value: 'DogFences', name: 'Dog Fences' },
  DOORS: { value: 'Doors', name: 'Doors' },
  DRYWALL: { value: 'Drywall', name: 'Drywall' },
  ELECTRICAL: { value: 'Electrical', name: 'Electrical' },
  FENCES: { value: 'Fences', name: 'Fences' },
  FIREPLACES_CHIMNEYS_STOVES: {
    value: 'FireplacesChimneysStoves',
    name: 'Fireplaces/Chimneys/Stoves',
  },
  FLOORING: { value: 'Flooring', name: 'Flooring' },
  FOUNDATION: { value: 'Foundation', name: 'Foundation' },
  GARAGE_STORAGE_SHEDS_BUILDINGS: {
    value: 'GarageStorageShedsBuildings',
    name: 'Garage/Storage/Sheds/Buildings',
  },
  GUTTERS: { value: 'Gutters', name: 'Gutters' },
  HVAC: { value: 'HVAC', name: 'HVAC' },
  INSULATION: { value: 'Insulation', name: 'Insulation' },
  KITCHEN_REMODEL: { value: 'KitchenRemodel', name: 'Kitchen/Remodel' },
  LANDSCAPING: { value: 'Landscaping', name: 'Landscaping' },
  MOLD_REMEDIATION: { value: 'MoldRemediation', name: 'Mold/Remediation' },
  NON_INSTALLED: { value: 'NonInstalled', name: 'Non-Installed' },
  OTHER: { value: 'Other', name: 'Other' },
  OTHER_EXTERIOR: { value: 'OtherExterior', name: 'Other Exterior' },
  OTHER_INTERIOR: { value: 'OtherInterior', name: 'Other Interior' },
  OUTDOOR_LIGHTING: { value: 'OutdoorLighting', name: 'Outdoor Lighting' },
  PAINT: { value: 'Paint', name: 'Paint' },
  PATIOS_DECKS: { value: 'PatiosDecks', name: 'Patios/Decks' },
  PLUMBING: { value: 'Plumbing', name: 'Plumbing' },
  POOLS_SPAS: { value: 'PoolSpas', name: 'Pool/Spas' },
  ROOFING: { value: 'Roofing', name: 'Roofing' },
  ROOM_ADDITIONS: { value: 'RoomAdditions', name: 'Room Additions' },
  SHELVING: { value: 'Shelving', name: 'Shelving' },
  SIDING: { value: 'Siding', name: 'Siding' },
  SOLAR_ENERGY_EFFICIENCY: { value: 'SolarEnergyEfficiency', name: 'Solar/Energy Efficiency' },
  STAIRLIFTS_MOBILITY: { value: 'StairliftsMobility', name: 'Stairlifts/Mobility' },
  UNKNOWN: { value: 'Unknown', name: 'Unknown' },
  WALK_IN_TUBS_SHOWERS: { value: 'WalkInTubsShowers', name: 'Walk-in Tubs/Showers' },
  WATER_TREATMENT: { value: 'WaterTreatment', name: 'Water treatment' },
  WINDOWS: { value: 'Windows', name: 'Windows' },
};

export const findFoundationProductTypeEnumName = value => {
  for (let key in productTypeEnum) {
    let productType = productTypeEnum[key];
    if (productType.value === value) {
      return productType.name;
    }
  }
  return null;
};

export default productTypeEnum;
