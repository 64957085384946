import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
import { initialState } from '../initialState';

const changeValue = (state, action) => {
  const updatedValues = {
    [action.key]: action.value,
  };
  return updateObject(state, updatedValues);
};

const mosaicReducer = (state, action) => {
  if (!state) {
    state = initialState.mosaicInformation;
  }
  switch (action.type) {
    case actionTypes.CHANGE_MOSAIC_INFO_VALUE:
      return changeValue(state, action);
    case actionTypes.RESET_STATE_MOSAIC:
      state = initialState.mosaicInformation;
      return state;
    default:
      return state;
  }
};

export default mosaicReducer;
