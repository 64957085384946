import { Field, Form, withFormik } from 'formik';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { GoSearch } from 'react-icons/go';
import { connect } from 'react-redux';
import { Button, Col, Label, Row } from 'reactstrap';
import { searchInternalUserRoleEnum } from '../../../assets/js/UserRoleEnum';
import DatePicker from '../../../components/UI/Form/DatePicker/DatePickerField';
import InputField from '../../../components/UI/Form/Input/InputField';
import SelectField from '../../../components/UI/Form/Select/SelectField';
import { EMAIL_TOOLTIP_TEXT } from '../../../constants/tooltipTexts';
import { changeSoftPullValue } from '../../../redux/actions/index';

const SearchCriteria = props => {
  const { searchInternalUsers, searchOptionUsers, dispatchChangeSoftPullValue } = props;
  const [show, setShow] = useState(true);

  const search = queryString.parse(window.location.search).search;

  useEffect(() => {
    if (search) {
      searchInternalUsers(searchOptionUsers);
    }
    // eslint-disable-next-line
  }, []);

  const SearchCriteriaForm = () => (
    <div className="search-panel">
      <Form>
        <Row>
          <Col sm="6">
            <div className="form-group">
              <Label for="lastName">Last Name</Label>
              <Field component={InputField} name="lastName" id="lastName" type="text" />
            </div>
          </Col>
          <Col sm="6">
            <div className="form-group">
              <Label for="firstName">First Name</Label>
              <Field component={InputField} name="firstName" id="firstName" type="text" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <div className="form-group">
              <Label for="email">Email</Label>
              <Field
                component={InputField}
                name="email"
                id="email"
                tooltipText={EMAIL_TOOLTIP_TEXT}
                type="text"
              />
            </div>
          </Col>
          <Col sm="6">
            <div className="form-group">
              <Label for="role">Role</Label>
              <Field
                component={SelectField}
                name="role"
                id="role"
                type="select"
                selectValues={searchInternalUserRoleEnum}
                selectFirstName=" "
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="form-group">
              <Label for="lastLoginFromTime">Last Login</Label>
              <Row>
                <Col sm="4">
                  <Label for="lastLoginFromTime" className="mr-sm-2 inline">
                    From
                  </Label>
                  <Field
                    component={DatePicker}
                    name="lastLoginFromTime"
                    id="lastLoginFromTime"
                    onChange
                    maxDate={new Date()}
                  />
                </Col>
                <Col sm="4">
                  <Label for="lastLoginToTime" className="mr-sm-2 inline">
                    To
                  </Label>
                  <Field
                    component={DatePicker}
                    name="lastLoginToTime"
                    id="lastLoginToTime"
                    onChange
                    maxDate={new Date()}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="text-right">
            <Button
              type="reset"
              color="secondary"
              className="rounded-pill"
              outline
              onClick={() => {
                dispatchChangeSoftPullValue('searchOptionInternalUsers', {
                  lastName: null,
                  firstName: null,
                  email: null,
                  dealer: null,
                  role: null,
                  lastLoginFromTime: null,
                  lastLoginToTime: null,
                });
              }}
            >
              Clear
            </Button>
            <Button type="submit" color="primary" className="rounded-pill">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );

  const SearchCriteriaFormFormik = withFormik({
    mapPropsToValues: () => ({
      lastName: searchOptionUsers.lastName,
      firstName: searchOptionUsers.firstName,
      email: searchOptionUsers.email,
      role: searchOptionUsers.role,
      lastLoginFromTime: searchOptionUsers.lastLoginFromTime,
      lastLoginToTime: searchOptionUsers.lastLoginToTime,
    }),

    handleSubmit: values => {
      const searchOptions = {
        ...searchOptionUsers,
        firstName: values.firstName,
        lastName: values.lastName,
        role: values.role !== '' ? values.role : undefined,
        lastLoginFromTime: values.lastLoginFromTime,
        lastLoginToTime: values.lastLoginToTime,
        email: values.email,
        dealer: undefined,
      };

      dispatchChangeSoftPullValue('searchOptionUsers', searchOptions);
      searchInternalUsers(searchOptions);
    },
  })(SearchCriteriaForm);

  return (
    <div className="search-form">
      <div className="search-btn">
        <span onClick={() => setShow(!show)}>
          <GoSearch size={18} />
          <span>Search Criteria</span>
        </span>
      </div>
      {show ? <SearchCriteriaFormFormik /> : null}
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  application: state.softPull.application,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangeSoftPullValue: (key, value) => dispatch(changeSoftPullValue(key, value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchCriteria);
