import { Field, Form, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import Button from '../../../components/UI/Button/CustomButton';
import Checkbox from '../../../components/UI/Form/Checkbox/Checkbox';
import { reactGAEvent, reactGAPageview } from '../../../helper';
import { changePage, submitMindfire, changePowerPayInfoValue } from '../../../redux/actions/index';
import { PowerPayFlowPageNumber } from './PowerPayFlow';
import PowerPayLogo from '../../../assets/img/logo_prowepay.png';

const Consent = props => {
  const {
    dispatchChangePage,
    consent,
    dispatchChangePowerPayInfoValue,
    did,
    dealerUserId,
    dispatchSubmitMindfire,
  } = props;

  useEffect(() => {
    reactGAPageview({
      path: '/ca_ppconsent',
      trackerNames: [],
      title: 'ca_ppconsent',
      did,
      dealerUserId,
    });
    // eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    reactGAEvent({
      category: 'Button',
      action: 'Click',
      label: 'Continue',
      did,
      dealerUserId,
    });
    dispatchSubmitMindfire({
      lastPage: '6009',
      dealerUserId: dealerUserId,
      did: did,
    });
    dispatchChangePowerPayInfoValue('consent', true);
    dispatchChangePage(PowerPayFlowPageNumber.LoanAmount);
  };

  const ConsentForm = () => (
    <Form noValidate autoComplete="off">
      <Field
        component={Checkbox}
        name="consent"
        id="consent"
        label="Applicant"
        labelClassName="bold mb-0"
      />
      <Button type="submit" title={'Continue'} />
    </Form>
  );

  const ConsentFormFormik = withFormik({
    mapPropsToValues: () => ({
      consent: consent,
    }),

    validationSchema: Yup.object({
      consent: Yup.boolean().oneOf([true], 'Please check to continue.'),
    }),

    handleSubmit,
  })(ConsentForm);

  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs="auto" sm="11">
          <Row className="justify-content-center">
            <Col xs="auto">
              <h3>Nice! You are eligible for financing options with:</h3>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs="auto">
              <img src={PowerPayLogo} />
            </Col>
          </Row>
          <Row className="justify-content-center mt-5">
            <Col md="8">
              <h4>Consent</h4>
              <p>
                By selecting this checkbox, I, the applicant, agree to the following: I've read,
                understand, accept, and Consent to the terms of the 
                <a href="https://getpowerpay.com/account_opening_disclosures/">
                  Account Opening Disclosures
                </a>
                ,{' '}
                <a href="https://getpowerpay.com/esign_consent/">
                  Electronic Records and Communication
                </a>{' '}
                , <a href="https://getpowerpay.com/privacy-policy/">Privacy Policy</a>, and agree to
                receive electronic disclosures with any loan obtained as a result of this
                application. If a joint application is filed, these Terms of Use apply to each of
                the applicants equally: I authorize PowerPay and/or any Lender in its Lender
                Network, its representatives, successors, and assigns to investigate my
                creditworthiness and obtain a credit report from me for any lawful purpose,
                including, but not limited to, any extension of credit, renewal, servicing and
                collections. If you are applying for a home improvement loan, you declare under
                penalty of perjury that this declaration of occupancy is true and correct. Upon
                request, Lender will inform me of whether a credit report was obtained and if a
                report was obtained, the names and addresses of the credit reporting agencies that
                furnished the report. I authorize PowerPay to contact me at the phone number I
                provided via text, an automated telephone dialing system, or artificial or
                prerecorded voice messages, for any purpose. This is not required to apply. I can
                opt-out by contacting PowerPay at 1-800-397-4485.
              </p>
              <ConsentFormFormik />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  consent: state.powerPayInformation.consent,
  dealerUserId: state.auth.dealerUserId,
  did: state.auth.did,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangePage: page => dispatch(changePage(page)),
  dispatchChangePowerPayInfoValue: (key, value) => dispatch(changePowerPayInfoValue(key, value)),
  dispatchSubmitMindfire: params => dispatch(submitMindfire(params)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Consent),
);
